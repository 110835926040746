import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Lottie from 'react-lottie';
import { Icon, Collapse } from '@blueprintjs/core';

import * as Component from './style';
import { Controller } from '../../Menu'
import { IconeHeaderSideBar, MenuItemAcompanhamentoDeSala, MenuItemBordero, MenuItemMeusContratos, MenuItemDisponibilidade, MenuItemLeads, MenuItemNFSe, MenuItemPropostasPendentes, MenuItemProspeccao, MenuItemVenda, MenuItemControleDeComissao, MenuItemGestaoDeComissao } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import * as LottieFiles from '../../../Data/Lottie/index'
import History from "../../../Routers";
import { NotificacaoInterna } from '../../../Services/Notification';

const View: React.FC = () => {
    /* #region  Variáveis  */
    const History = useHistory();
    const [getExibirSideBar, setExibirSideBar] = useState<boolean>(false);
    const [getAnalyticsClicked, setAnalyticsClicked] = useState<boolean>(false);
    const [getGeralClicked, setGeralClicked] = useState<boolean>(false);
    /* #endregion */

    /* #region  Funções  */
    function AcessarPaginaAnalytics(path: string): void {
        if (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 2) {
            History.push(`/Menu/Analytics/${path}`);
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    function AcessarPaginaGeral(path: string): void {
        if (ContextSignIn.getContext().UltimoGrupoDeEmpresasAcessado == "GAV Resorts") {
            History.replace(`/Menu/Geral/${path}`);
            window.location.reload();
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    useEffect(() => {
        async function componentDidMount() { }
        componentDidMount();
    }, []);
    return (
        <Fragment>
            <Component.Header style={getExibirSideBar == true ? { marginLeft: "120px", transition: "margin-left 1s" } : { marginLeft: "0px", transition: "margin-left 1s" }}>
                <img style={{ width: "20px", height: "20px", cursor: "pointer" }} src={IconeHeaderSideBar} alt=""
                    onClick={async () => { await setExibirSideBar(getExibirSideBar == false); }} />
            </Component.Header>
            <Component.Container style={getExibirSideBar == true ? { marginLeft: "0", transition: "margin-left 1s" } : { marginLeft: "-250px", transition: "margin-left 1s" }}>
                <Component.HeaderContent>
                    {/* Para Tudo e Botão Abrir/Fechar */}
                    <div style={getExibirSideBar == true ? { width: "250px", height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "40px 0" } : { width: "250px", height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "40px 0" }}>
                        {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div style={{ width: "100%" }}>
                            <div className="element" style={{ height: "70px", padding: "0 35px" }} onClick={() => getGeralClicked ? setGeralClicked(false) : setGeralClicked(true)}>
                                <Icon icon="cog" />
                                <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Geral</div>
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: LottieFiles.SwipeWhite,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice"
                                        }
                                    }}
                                    height={30}
                                    width={30}
                                    style={getGeralClicked ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }} />
                            </div>
                            <Collapse isOpen={getGeralClicked}>
                                <div onClick={() => { AcessarPaginaGeral("Cadastros") }} className="element" style={{ height: "30px", fontSize: "13px" }}>
                                    <Icon icon="list" iconSize={15} />
                                    <div style={{ width: "60%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Cadastros</div>
                                </div>
                                <div onClick={() => { AcessarPaginaGeral("Gatilhos") }} className="element" style={{ height: "30px", fontSize: "13px" }}>
                                    <Icon icon="list" iconSize={15} />
                                    <div style={{ width: "60%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Gatilhos</div>
                                </div>
                            </Collapse>
                        </div>}
                        {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div className="element" onClick={() => Controller.AcessarPaginaAcompanhamentoDeSala(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemAcompanhamentoDeSala} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Acompanhamento de sala</div>
                        </div>}
                        {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div className="element" onClick={() => Controller.AcessarPaginaControleDeSala(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemAcompanhamentoDeSala} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Controle de sala</div>
                        </div>}
                        {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div className="element" onClick={() => Controller.AcessarPaginaBordero(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemBordero} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Borderô</div>
                        </div>}
                        {(ContextSignIn.getContext().pessoa.id == 1 || window.location.href.includes("onmoney") == true) && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div className="element" onClick={() => Controller.AcessarPaginaControleDeComissao(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemControleDeComissao} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Controle de comissão</div>
                        </div>}
                        {window.location.href.includes("onmoney") == false && <div className="element" onClick={() => Controller.AcessarPaginaDisponibilidade(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemDisponibilidade} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Disponibilidade</div>
                        </div>}
                        {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div className="element" onClick={() => Controller.AcessarPaginaLeads(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemLeads} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Gestão de leads</div>
                        </div>}
                        {(ContextSignIn.getContext().pessoa.id == 1 || window.location.href.includes("onmoney") == true) && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div className="element" onClick={() => Controller.AcessarPaginaGestaoDeComissao(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemGestaoDeComissao} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Gestão de comissão</div>
                        </div>}
                        {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div className="element" onClick={() => Controller.AcessarPaginaMeusContratos(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemMeusContratos} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Meus Contratos</div>
                        </div>}
                        {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div className="element" onClick={() => Controller.AcessarPaginaPropostasPendentes(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemPropostasPendentes} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Propostas Pendentes</div>
                        </div>}
                        {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div className="element" onClick={() => Controller.AcessarPaginaProspeccao(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemProspeccao} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Prospecção</div>
                        </div>}
                        {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && <div className="element" onClick={() => Controller.AcessarPaginaVendaDireta(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemVenda} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Venda Direta</div>
                        </div>}
                        {ContextSignIn.getContext().pessoa.id != 18856 && <div className="element" onClick={() => Controller.AcessarPaginaMapas(History)}>
                            <img style={{ width: "20px", height: "20px", margin: "0" }} src={MenuItemDisponibilidade} />
                            <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Mapa do loteamento</div>
                        </div>}
                        {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <div style={{ width: "100%" }}>
                            <div className="element" style={{ height: "70px", padding: "0 35px" }} onClick={() => getAnalyticsClicked ? setAnalyticsClicked(false) : setAnalyticsClicked(true)}>
                                <Icon icon="chart" />
                                <div style={{ width: "70%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Analytics</div>
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: LottieFiles.SwipeWhite,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice"
                                        }
                                    }}
                                    height={30}
                                    width={30}
                                    style={getAnalyticsClicked ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }} />
                            </div>
                            <Collapse isOpen={getAnalyticsClicked}>
                                <div onClick={() => { AcessarPaginaAnalytics("Resumo") }} className="element" style={{ height: "30px", fontSize: "13px" }}>
                                    <Icon icon="presentation" iconSize={15} />
                                    <div style={{ width: "60%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Resumo</div>
                                </div>
                                <div onClick={() => { AcessarPaginaAnalytics("Vendas") }} className="element" style={{ height: "30px", fontSize: "13px" }}>
                                    <Icon icon="home" iconSize={15} />
                                    <div style={{ width: "60%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Vendas</div>
                                </div>
                                <div onClick={() => { AcessarPaginaAnalytics("Cessao") }} className="element" style={{ height: "30px", fontSize: "13px" }}>
                                    <Icon icon="changes" iconSize={15} />
                                    <div style={{ width: "60%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Cessão</div>
                                </div>
                                <div onClick={() => { AcessarPaginaAnalytics("Distratos") }} className="element" style={{ height: "30px", fontSize: "13px" }}>
                                    <Icon icon="remove-column" iconSize={15} />
                                    <div style={{ width: "60%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Distratos</div>
                                </div>
                                <div onClick={() => { AcessarPaginaAnalytics("Deposito") }} className="element" style={{ height: "30px", fontSize: "13px" }}>
                                    <Icon icon="dollar" iconSize={15} />
                                    <div style={{ width: "60%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Depósito</div>
                                </div>
                                <div onClick={() => { AcessarPaginaAnalytics("VendaNoTempo") }} className="element" style={{ height: "30px", fontSize: "13px" }}>
                                    <Icon icon="stopwatch" iconSize={15} />
                                    <div style={{ width: "60%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Venda no tempo</div>
                                </div>
                                <div onClick={() => { AcessarPaginaAnalytics("Fluxo") }} className="element" style={{ height: "30px", fontSize: "13px" }}>
                                    <Icon icon="series-search" iconSize={15} />
                                    <div style={{ width: "60%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Fluxo</div>
                                </div>
                                <div onClick={() => { AcessarPaginaAnalytics("Planejamento") }} className="element" style={{ height: "30px", fontSize: "13px" }}>
                                    <Icon icon="gantt-chart" iconSize={15} />
                                    <div style={{ width: "60%", height: "30px", display: "flex", alignItems: "center", marginLeft: "1vw" }}>Planejamento</div>
                                </div>
                            </Collapse>
                        </div>}
                    </div>
                </Component.HeaderContent>
            </Component.Container>
        </Fragment>
    );
    /* #endregion */
};

export default View;