import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';
import CamelCase from 'axios-case-converter';
import moment from "moment";

class Venda {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Consulta os dados vinculados ao código do casal (id)
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} IdCasal Informe o código do casal (id)"
     **/
    public async ConsultIdCasal(Token: string, IdCasal: string): Promise<AxiosResponse<any>> {
        return await Context.get<any>(`/Venda/ConsultIdCasal/${Token}/${IdCasal}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Exibir todos os contratos pendentes de aprovação referentes a empresa e centro de custo informado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     **/
    public async ContratosPendentesDeAprovacao(Token: string, Empresa: number, CentroDeCusto: string): Promise<AxiosResponse<Array<Objeto.PropostaDeVenda>>> {
        return await Context.get<{ arquivo: string, descricao: string, extensao: string }>(`/Venda/ContratosPendentesDeAprovacao/${Token}/${Empresa}/${CentroDeCusto}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Exibir todos os contratos pendentes de assinatura referentes a empresa e centro de custo informado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     **/
    public async ContratosPendentesDeAssinatura(Token: string, Empresa: number, CentroDeCusto: string): Promise<AxiosResponse<Array<Objeto.PropostaDeVenda>>> {
        return await Context.get<{ arquivo: string, descricao: string, extensao: string }>(`/Venda/ContratosPendentesDeAssinatura/${Token}/${Empresa}/${CentroDeCusto}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Lista os logs das vendas realizadas
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {Date} PeriodoInicial Informe a data de inicio das vendas no formato: AAAA-MM-DD
     * @param {Date} PeriodoFinal Informe a data de finalização das vendas no formato: AAAA-MM-DD
     **/
    public async LogsDeVendas(Token: string, Empresa: number, CentroDeCusto: string, PeriodoInicial: Date, PeriodoFinal: Date): Promise<AxiosResponse<Array<Objeto.PropostaDeVenda>>> {
        return await CamelCase(Context).get<Array<Objeto.PropostaDeVenda>>(`/Venda/LogsDeVendas/${Token}/${Empresa}/${CentroDeCusto}/${moment(PeriodoInicial).format("yyyy-MM-DD")}/${moment(PeriodoFinal).format("yyyy-MM-DD")}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Exibir todos os contratos gerados referentes a empresa e centro de custo informado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {Date} PeriodoInicial Informe a data de inicio das vendas no formato: AAAA-MM-DD
     * @param {Date} PeriodoFinal Informe a data de finalização das vendas no formato: AAAA-MM-DD
     **/
    public async MeusContratos(Token: string, Empresa: number, CentroDeCusto: string, PeriodoInicial: Date, PeriodoFinal: Date ): Promise<AxiosResponse<{ filtros: Array<Objeto.Filtro>, dados: Array<Objeto.PropostaDeVenda> }>> {
        return await CamelCase(Context).get<{ filtros: Array<Objeto.Filtro>, dados: Array<Objeto.PropostaDeVenda> }>(`/Venda/MeusContratos/${Token}/${Empresa}/${CentroDeCusto}?PeriodoInicial=${moment(PeriodoInicial).format("yyyy-MM-DD")}&PeriodoFinal=${moment(PeriodoFinal).format("yyyy-MM-DD")}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Valida o usuário e gerar o token de acesso para o mesmo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {number} Venda Informe o número da venda
     **/
    public async ConsultarContrato(Token: string, Empresa: number, CentroDeCusto: string, Venda: number): Promise<AxiosResponse<Array<{ arquivo: string, descricao: string, extensao: string }>>> {
        return await Context.get<{ arquivo: string, descricao: string, extensao: string }>(`/Venda/ConsultarContrato/${Token}/${Empresa}/${CentroDeCusto}/${Venda}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Valida o usuário e gerar o token de acesso para o mesmo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {number} Venda Informe o número da venda
     * @param {number} Descricao Informe o nome (descrição) do documento
     **/
    public async ConsultarDocumentoDoContrato(Token: string, Empresa: number, CentroDeCusto: string, Venda: number, Descricao: string): Promise<AxiosResponse<{ arquivo: string, descricao: string, extensao: string }>> {
        return await Context.get<{ arquivo: string, descricao: string, extensao: string }>(`/Venda/ConsultarDocumentoDoContrato/${Token}/${Empresa}/${CentroDeCusto}/${Venda}/${Descricao}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Valida o usuário e gerar o token de acesso para o mesmo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {number} Venda Informe o número da venda
     **/
    public async ConsultarECompactarContrato(Token: string, Empresa: number, CentroDeCusto: string, Venda: number, Compactar: boolean): Promise<AxiosResponse<{ arquivo: string, descricao: string, extensao: string }>> {
        return await Context.get<{ arquivo: string, descricao: string, extensao: string }>(`/Venda/ConsultarECompactarContrato/${Token}/${Empresa}/${CentroDeCusto}/${Venda}/${Compactar}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Lista as possíveis finalidades de venda pré definidas no sistema
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {string} TokenBancosDeDados Informe o toke de acesso ao banco de dados. A lista de tokens poderá ser obtida ao executar a requisição "/Identificador/{Token}/{Empresa}/{CentroDeCusto}
     **/
    public async FinalidadesDeCompra(Token: string, Empresa: number, CentroDeCusto: string, TokenBancosDeDados: string): Promise<AxiosResponse<Array<{ id: number, descricao: string }>>> {
        return await Context.get<{ arquivo: string, descricao: string, extensao: string }>(`/Venda/FinalidadesDeCompra/${Token}/${Empresa}/${CentroDeCusto}/${TokenBancosDeDados}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Lista os possíveis veículos de divulgação pré definidos no sistema
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {string} TokenBancosDeDados Informe o toke de acesso ao banco de dados. A lista de tokens poderá ser obtida ao executar a requisição "/Identificador/{Token}/{Empresa}/{CentroDeCusto}
     **/
    public async VeiculosDeDivulgacao(Token: string, Empresa: number, CentroDeCusto: string, TokenBancosDeDados: string): Promise<AxiosResponse<Array<{ id: number, descricao: string }>>> {
        return await Context.get<{ arquivo: string, descricao: string, extensao: string }>(`/Venda/VeiculosDeDivulgacao/${Token}/${Empresa}/${CentroDeCusto}/${TokenBancosDeDados}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };


    /* Sobre esta função *//**
     * Lista os possíveis veículos de divulgação pré definidos no sistema
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {string} TokenBancosDeDados Informe o toke de acesso ao banco de dados. A lista de tokens poderá ser obtida ao executar a requisição "/Identificador/{Token}/{Empresa}/{CentroDeCusto}
     **/
    public async TerminalDePagamento(Token: string, Empresa: number, CentroDeCusto: string): Promise<AxiosResponse<Array<{ id: number, descricao: string }>>> {
        return await Context.get<{ arquivo: string, descricao: string, extensao: string }>(`/Venda/TerminalDePagamento/${Token}/${Empresa}/${CentroDeCusto}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Valida o usuário e gerar o token de acesso para o mesmo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.PropostaDeVenda} PropostaDeVenda Insira um objeto com todos os dados da venda
     **/
    public async NovoContrato(Token: string, PropostaDeVenda: Objeto.PropostaDeVenda): Promise<AxiosResponse<Array<{ arquivo: string, descricao: string, extensao: string }>>> {
        return await Context.post<{ arquivo: string, descricao: string, extensao: string }>(`/Venda/NovoContrato/${Token}`, PropostaDeVenda)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Adiciona novos documentos ao contrato de comrpa e venda
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {number} Venda Informe o número da venda
     * @param {number} Arquivos Insira um objeto com todos os dados dos arquivos
     **/
    public async NovosDocumentos(Token: string, Empresa: number, CentroDeCusto: string, Venda: number, Arquivos: Array<{ Nome: string, Conteudo: File | null | undefined }>): Promise<AxiosResponse<{ Mensagem: string }>> {
        let File = new FormData();
        Arquivos.map((Item) => {
            File.append("Arquivos", new Blob([Item.Conteudo || {} as File], { type: Item.Conteudo?.type }), Item.Nome);
        });
        return await Context.post<{ arquivo: string, descricao: string, extensao: string }>(`/Venda/NovosDocumentos/${Token}/${Empresa}/${CentroDeCusto}/${Venda}`, File, { headers: { "content-type": "multipart/form-data" } })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception.response; });
    };
    /* Sobre esta função *//**
     * Valida o usuário e gerar o token de acesso para o mesmo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.PropostaDeVenda} PropostaDeVenda Insira um objeto com todos os dados da venda
     **/
    public async NovaProposta(Token: string, PropostaDeVenda: Objeto.PropostaDeVenda): Promise<AxiosResponse<Objeto.PropostaDeVenda>> {
        return await Context.post<Objeto.PropostaDeVenda>(`/Venda/NovaProposta/${Token}`, PropostaDeVenda)
            .then((Response) => { return Response; })
            .catch((Exception) => {
                return Exception.response; });
    };
    /* Sobre esta função *//**
     * Valida o usuário e gerar o token de acesso para o mesmo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {number} Venda Informe o número da venda
     **/
    public async AprovarProposta(Token: string, Empresa: number, CentroDeCusto: string, Venda: number): Promise<AxiosResponse<Objeto.PropostaDeVenda>> {
        return await Context.post<Objeto.PropostaDeVenda>(`/Venda/AprovarProposta/${Token}/${Empresa}/${CentroDeCusto}/${Venda}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Desaprova a proposta de venda já cadastrada e ainda pendente
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {number} Venda Informe o número da venda
     **/
    public async DesaprovarProposta(Token: string, Empresa: number, CentroDeCusto: string, Venda: number): Promise<AxiosResponse<Objeto.PropostaDeVenda>> {
        return await Context.post<Objeto.PropostaDeVenda>(`/Venda/DesaprovarProposta/${Token}/${Empresa}/${CentroDeCusto}/${Venda}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new Venda();