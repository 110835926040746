import React, { memo } from 'react';
import { Marker } from 'react-leaflet-rotate/react-leaflet';
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';

function App({ areas, getMarkersSize, getTileLayer }: any) {

  return areas.centros.features.map((centro: any, centroIndex: any) => {
    if (centro.geometry.type === "Point") {
      return <Marker icon={divIcon({
        html: renderToStaticMarkup(
          <div style={{ backgroundColor: "0", opacity: `${getMarkersSize[3]}`, color: getTileLayer === 1 ? "#ffffff" : "#1d1313", fontSize: `${getMarkersSize[2]}px`, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "unset", width: `${getMarkersSize[1] + 10}px`, height: `${getMarkersSize[1]}px`, border: "unset" }}>
            <span style={{ fontWeight: "bold" }}>{centro.properties.name}</span>
          </div>
        )
      })} position={[(centro.geometry.coordinates[1] * 1 + 0), (centro.geometry.coordinates[0] * 1 + 0)]} />
    }
  })
}

export default App;