import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import Lottie from 'react-lottie';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import io from "socket.io-client";
import { Button, Card, FormGroup, Icon, MenuItem, Switch, Menu, Collapse, Position, Popover } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select, MultiSelect } from "@blueprintjs/select";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn, SignIn, WidthCheck as ContextWidthCheck } from '../../../Context';
import { Localization } from '../../../Data/Listas';
import { CentroDeCusto, Identificador } from '../../../Services/Controllers';
import api from '../../../Services/node';
import { Moeda } from '../../../Themes/TextFormat';
import { Objeto } from '../../../Services/Models';
import { NotificacaoInterna } from '../../../Services/Notification';
import * as LottieFiles from '../../../Data/Lottie';

import './App.css';
import Map from './leaflet';
import { image } from './image';
import * as Component from './style';
import './style.css';
import viewModel from './viewModel';

const View: React.FC = () => {
    let dataStorage: Array<StoreSintetico>;
    let dataStorageCategorias: any;
    let listaSintetica: Array<Objeto.IdentificadorSintetico>;

    /* #region  Variáveis  */
    const [getExibirMapa, setExibirMapa] = useStateWithCallbackLazy<boolean>(false);
    const [getWidth, setWidth] = useState<number>();

    /* #region  Variáveis da tabela de disponibilidade */
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCustoDisponibilidade, setListaPickerCentroDeCustoDisponibilidade] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const [getListaDeUnidades, setListaDeUnidades] = useState<Array<Objeto.Identificador>>([]);
    const [getListaDeUnidadesSintetico, setListaDeUnidadesSintetico] = useState<Array<Objeto.IdentificadorSintetico>>([]);
    const [getListaDeDisponibilidade, setListaDeDisponibilidade] = useState<Array<Store>>([]);
    const [getListaDeUnidadesMemo, setListaDeUnidadesMemo] = useState<Array<Objeto.IdentificadorSintetico>>([]);
    const [getListaDeDisponibilidadeSintetico, setListaDeDisponibilidadeSintetico] = useState<Array<StoreSintetico>>([]);
    const [getListaDeCategorias, setListaDeCategorias] = useState<Array<any>>([]);
    const timelineLoaded = useRef<boolean>(false);
    const [getCarregandoListaDeUnidades, setCarregandoListaDeUnidades] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    /* #endregion */

    /* #region  Variáveis do mapa */
    const [getListaPickerCentroDeCustoMapa, setListaPickerCentroDeCustoMapa] = useState<Array<Objeto.CentroDeCusto>>([]);
    interface Store {
        empreendimento: string;
        unidade: string;
        valorUnidade: number;
        torre: string;
        apto: number;
        pavimento: string;
        qtdQuartos: number;
        qtdSemanas: number;
        cota: string;
        observacao: string;
        vista: string;
        frente: string;
        frenteConf: string;
        fundo: string;
        fundoConf: string;
        esquerda: string;
        esquerdaConf: string;
        direita: string;
        direitaConf: string;
        id: number;
        status: number;
        unidades: Objeto.Identificador;
        sigla: string;
        lista: Array<Objeto.Identificador>;
        store: Array<Store>;
        categorias: Array<any>;
    };
    interface StoreSintetico {
        empreendimento: string;
        unidade: string;
        valorUnidade: number;
        torre: string;
        apto: number;
        pavimento: string;
        qtdQuartos: number;
        qtdSemanas: number;
        cota: string;
        observacao: string;
        vista: string;
        frente: string;
        frenteConf: string;
        fundo: string;
        fundoConf: string;
        esquerda: string;
        esquerdaConf: string;
        direita: string;
        direitaConf: string;
        id: number;
        status: number;
        unidades: Objeto.IdentificadorSintetico;
        sigla: string;
        lista: Array<Objeto.IdentificadorSintetico>;
        store: Array<StoreSintetico>;
        categorias: Array<any>;
    };
    const [getEmpresaObra, setEmpresaObra] = useState<any>([]);
    const [getRefreshData, setRefreshData] = useState<any>();
    const [getMapRefreshed, setMapRefreshed] = useState<any>(true);
    const [getListaDeMarcacoes, setListaDeMarcacoes] = useState<any>();
    const [getTiposInadimplencia, setTiposInadimplencia] = useState<any>();
    const [getListaPontosCentrais, setListaPontosCentrais] = useState<any>();
    const [getListaAreas, setListaAreas] = useState<any>();
    const [getVisaoMapa, setVisaoMapa] = useState<number>(0);
    const [getCarregandoListaDeMarcacoes, setCarregandoListaDeMarcacoes] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getCollapse, setCollapse] = useState(false);
    const [getFullscreen, setFullscreen] = useState(false);
    const handle = useFullScreenHandle();
    /* #endregion */
    /* #endregion */

    window.onresize = function () {
        ContextWidthCheck.setContext({ width: document.body.clientWidth })
        setWidth(document.body.clientWidth)
    }
    function WidthCheck() {
        if (window.location.pathname == "/Disponibilidade") {
            if (window.matchMedia("(min-width: 1024px && max-width: 2560px)").matches == true) {
                return { display: "flex", alignItems: "flex-end", flexWrap: "wrap", backgroundColor: "0", width: "97%", margin: "20px auto", justifyContent: "space-between" } as React.CSSProperties
            } else if (window.matchMedia("(max-width: 1024px)").matches == true) {
                return { display: "flex", alignItems: "flex-end", flexWrap: "wrap", backgroundColor: "0", width: "97%", margin: "20px auto", justifyContent: "center" } as React.CSSProperties
            }
        }
    }
    async function RefreshData(Item: Objeto.CentroDeCusto) {
        if(getRefreshData) {
            RemoveRefreshData();
        }
        await api.get(`/allMaps?empresaMae=Cosentino&obra=${Item?.sigla}`).then(async results => {
            await setMapRefreshed(false);
            await setListaDeMarcacoes(results.data);
            await setMapRefreshed(true);
            await setCarregandoListaDeMarcacoes(false, () => { });
            setRefreshData(setInterval(async function () {
                await api.get(`/allMaps?empresaMae=Cosentino&obra=${Item?.sigla}`).then(async results => {
                    await setMapRefreshed(false);
                    await setListaDeMarcacoes(results.data);
                    await setMapRefreshed(true);
                })
            }, 20000));
        });
    }
    function RemoveRefreshData() {
        clearInterval(getRefreshData);
        setRefreshData(undefined);
    }

    useEffect(() => {
        WidthCheck();
    }, [document.body.clientWidth]);
    useEffect(() => {
        async function componentDidMount() {

            switch (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado) {
                case 1:
                    await api.get(`/Mapas/Empreendimentos?empresaMae=CasaETerra`).then(async results => await setListaPickerCentroDeCustoMapa(results.data.empreendimentos));
                    break;
                case 9:
                    await api.get(`/Mapas/Empreendimentos?empresaMae=Cosentino`).then(async results => await setListaPickerCentroDeCustoMapa(results.data.empreendimentos));
                    break;
                default:
    
            }
        }
        componentDidMount();
    }, []);

    return (
        <Component.Container>
            <FullScreen handle={handle}>
                <Collapse isOpen={getCollapse}>
                    <div className="endereco" style={WidthCheck()}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={IconeHome} alt="" style={{ width: "20px", height: "20px" }} />
                            <div className="bar">/</div>
                            <div className="place">Mapas</div>
                            <div className="bar">/</div>
                            <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCentroDeCustoMapa} onItemSelect={() => { }} noResults={<MenuItem style={{ position: "relative", zIndex: 20000 }} disabled={getCarregandoListaDeMarcacoes} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem style={{ position: "relative", zIndex: 90000 }}
                                    onClick={async () => {
                                        await setItemPickerCentroDeCusto(Item)
                                        await setCarregandoListaDeMarcacoes(true, async () => { })
                                        RemoveRefreshData();
                                        await setListaDeMarcacoes(undefined);
                                        await setTiposInadimplencia(undefined);
                                        await setListaAreas(undefined);

                                        await api.get(`/allMaps?empresaMae=${Item.empresa}&obra=${Item.sigla}`/*'/Maps?pastaEmp=ChapadaoDoSul&empresaMae=CasaETerra&empresa=12&obra=0814V'*/).then(async results => {
                                            await setListaDeMarcacoes(/*Response.data*/results.data);
                                            await setTiposInadimplencia(results.data.tiposInadimplencia);
                                            await setListaAreas(results.data.others);
                                            await setCarregandoListaDeMarcacoes(false, () => { });
                                        })
                                        if (getExibirMapa === false) {
                                            await setExibirMapa(!getExibirMapa, async () => { });
                                        }
                                    }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button disabled={getCarregandoListaDeMarcacoes} className="selector" style={{ background: "#ffffff", position: "relative", zIndex: 90000, color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerCentroDeCusto?.descricao.substring(0, 10).concat("...") ?? "Empreendimento"} rightIcon="double-caret-vertical" />
                            </PickerCentroDeCusto>
                        </div>
                        <div style={{ display: "flex", background: "0", alignItems: "center", justifyContent: "center" }}>

                            <div style={{ width: "30px", height: "30px", borderRadius: "5px", marginRight: "1vw", cursor: "pointer", backgroundColor: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => {
                                if (getFullscreen) {
                                    setFullscreen(!getFullscreen);
                                    setCollapse(true);
                                    handle.exit();
                                }
                                else {
                                    setFullscreen(!getFullscreen);
                                    setCollapse(false);
                                    handle.enter();
                                }
                            }}>
                                <Icon iconSize={20} icon="presentation" />
                            </div>

                            <div style={{ cursor: "pointer", display: getExibirMapa ? "flex" : "none", fontSize: "14px", width: "600px", height: "30px", background: "0", fontWeight: 500, transition: "width 1.5s", justifyContent: "center", marginTop: "0px" }}>
                                <div onClick={() => setVisaoMapa(0)} style={{ display: "flex", alignItems: "center", justifyContent: "space-around", padding: "0 10px", borderTopLeftRadius: "5px", borderRight: "1px solid #dbdbdb", borderBottomLeftRadius: "5px", width: window.matchMedia("(max-width: 650px)").matches == true ? "10%" : "25%", height: "100%", cursor: "pointer", visibility: "visible", opacity: "1", background: getVisaoMapa === 0 ? "#99acff" : "white", color: getVisaoMapa === 0 ? "#ffffff" : "#000000", transition: "background-color 1s, visibility 2s, opacity 5s, color 1s" }}>
                                    <Icon icon="path-search" />
                                    <span style={{ pointerEvents: "none", display: window.matchMedia("(max-width: 650px)").matches == true ? "none" : "initial" }}>Disponibilidade</span>
                                </div>
                                <div onClick={() => setVisaoMapa(1)} style={{ display: "flex", alignItems: "center", justifyContent: "space-around", padding: "0 10px", width: window.matchMedia("(max-width: 650px)").matches == true ? "10%" : "25%", height: "100%", cursor: "pointer", visibility: "visible", opacity: "1", background: getVisaoMapa === 1 ? "#99acff" : "white", color: getVisaoMapa === 1 ? "#ffffff" : "#000000", transition: "background-color 1s, visibility 2s, opacity 5s, color 1s" }}>
                                    <Icon icon="dollar" />
                                    <span style={{ pointerEvents: "none", display: window.matchMedia("(max-width: 650px)").matches == true ? "none" : "initial" }}>Inadimplência</span>
                                </div>
                                <div onClick={() => setVisaoMapa(2)} style={{ display: "flex", alignItems: "center", justifyContent: "space-around", padding: "0 10px", borderLeft: "1px solid #dbdbdb", width: window.matchMedia("(max-width: 650px)").matches == true ? "10%" : "25%", height: "100%", cursor: "pointer", visibility: "visible", opacity: "1", background: getVisaoMapa === 2 ? "#99acff" : "white", color: getVisaoMapa === 2 ? "#ffffff" : "#000000", transition: "background-color 1s, visibility 2s, opacity 5s, color 1s" }}>
                                    <Icon icon="double-caret-horizontal" />
                                    <span style={{ pointerEvents: "none", display: window.matchMedia("(max-width: 650px)").matches == true ? "none" : "initial" }}>Faixas Preço</span>
                                </div>
                                <div onClick={() => setVisaoMapa(3)} style={{ display: "flex", alignItems: "center", justifyContent: "space-around", padding: "0 10px", borderTopRightRadius: "5px", borderLeft: "1px solid #dbdbdb", borderBottomRightRadius: "5px", width: window.matchMedia("(max-width: 650px)").matches == true ? "10%" : "25%", height: "100%", cursor: "pointer", visibility: "visible", opacity: "1", background: getVisaoMapa === 3 ? "#99acff" : "white", color: getVisaoMapa === 3 ? "#ffffff" : "#000000", transition: "background-color 1s, visibility 2s, opacity 5s, color 1s" }}>
                                    <Icon icon="comparison" />
                                    <span style={{ pointerEvents: "none", display: window.matchMedia("(max-width: 650px)").matches == true ? "none" : "initial" }}>Categorias</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
                {/* Abridor Colapse */}
                <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: LottieFiles.SwipeDown,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={30}
                        width={30}
                        style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                    />
                </div>
                {getExibirMapa == false && <div>
                    {getCarregandoListaDeUnidades != true && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: LottieFiles.LoadMaps,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            height={500}
                            width={500}
                            style={{ marginTop: "122px" }}
                        />
                        <div className="loading" style={{ margin: "-100px auto 0 auto", fontWeight: "bold" }}>{(getCarregandoListaDeUnidades == undefined) ? "Por favor, selecione um empreendimento para exibir a tabela de disponibilidade" : ((getCarregandoListaDeUnidades == false) ? "Aguarde, estamos carregando seu empreendimento" : "Pronto, sua tabela de disponibilidade será exibida!")}</div>
                    </div>}
                </div>}
                {getExibirMapa == true &&
                    <div onClick={() => setCollapse(false)} style={{ height: "calc(100% - 20px)" }}>
                        {getCarregandoListaDeMarcacoes !== false && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.LoadMaps,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height={500}
                                width={500}
                                style={{ marginTop: "200px" }}
                            />
                            <div className="loading" style={{ margin: "-100px auto 0 auto", fontWeight: "bold" }}>{(getCarregandoListaDeMarcacoes == undefined) ? "Por favor, selecione um empreendimento para exibir o mapa" : ((getCarregandoListaDeMarcacoes == true) ? "Aguarde, estamos carregando seu empreendimento" : "Pronto, seu mapa logo será exibido!")}</div>
                        </div>}
                        {!getCarregandoListaDeMarcacoes && <Map getRefreshData={getRefreshData} RefreshData={RefreshData} RemoveRefreshData={RemoveRefreshData} visao={getVisaoMapa} refresh={getMapRefreshed} setRefresh={setMapRefreshed} polygonsData={getListaDeMarcacoes} setListaDeMarcacoes={setListaDeMarcacoes} tiposInadimplencia={getTiposInadimplencia} areas={getListaAreas} points={getListaPontosCentrais} />}
                    </div>}
            </FullScreen>
        </Component.Container >
    );
    /* #endregion */
};

// const App: React.FC<viewModel> = (viewModel) => {
//     const queryClient = new QueryClient();

//     return (
//         <QueryClientProvider client = {queryClient}>
//             <View />
//         </QueryClientProvider>
//     );
// }

export default View;