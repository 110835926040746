import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

class Nacao {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista as nações
     **/
    public async Get(Token: string): Promise<AxiosResponse<Array<Objeto.Nacao>>> {
        return await Context.get(`/Nacao/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new Nacao();