import { AxiosResponse } from 'axios';
import axios from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class FunilDeVenda {
    
    /* #region GET Controller  */
    /* Sobre esta função *//**
     * Consulta o(s) funis(s) vinculado(s) ao grupo de empresa informado.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async Get(Token: string): Promise<AxiosResponse<Array<{ id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>}>>> {
        return await Context.get(`/FunilDeVenda/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region GET Controller  */
    /* Sobre esta função *//**
     * Retorna as informações da pessoa tenha permissão de acesso ao funil de vendas
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} IdFunil Informe o ID do funil"
    **/
    public async Equipe(Token: string, IdFunil: number): Promise<AxiosResponse<any>> {
        return await Context.get(`/FunilDeVenda/PermissaoDeAcesso/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${IdFunil}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region GET Controller  */
    /* Sobre esta função *//**
     * Autoriza a pessoa, atrelada ao usuário, o acesso ao funil de vendas
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} IdSala Informe o código da sala de venda (id).
     * @param {string} IdArea nforme o código do departamento (id).
     * @param {string} IdFunil Informe o ID do funil".
     * @param {string} IdPessoa Informe o ID da pessoa".
    **/
    public async AutorizarPermissaoDeAcesso(Token: string, IdSala: number, IdArea: number, IdFunil: number, IdPessoa: number): Promise<AxiosResponse<any>> {
        return await Context.post(`/FunilDeVenda/AutorizarPermissaoDeAcesso/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${IdSala}/${IdArea}/${IdFunil}/${IdPessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region POST Controller  */
    /* Sobre esta função *//**
     * Cadastrar o funil de venda.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} IdSala Informe o código da sala de venda (id).
     * @param {string} IdArea nforme o código do departamento (id).
     **/
    public async Post(Token: string, Posicoes: { id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}> }): Promise<AxiosResponse<{ id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}> }>> {
        return await Context.post(`/FunilDeVenda/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Posicoes)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Altera o funil de vendas
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} IdSala Informe o código da sala de venda (id).
     * @param {string} IdArea nforme o código do departamento (id).
     **/
    public async Put(Token: string, Posicoes: { id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}> }): Promise<AxiosResponse<{ id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}> }>> {
        return await Context.put(`/FunilDeVenda/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Posicoes)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o funil por sala de vendas e area
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} IdSala Informe o código da sala de venda (id).
     * @param {string} IdArea nforme o código do departamento (id).
     **/
    public async Delete(Token: string, Funil: {id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string}): Promise<any> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/FunilDeVenda/' + Token + "/" + ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado,
            data: Funil,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Desautoriza a pessoa, atrelada ao usuário, o acesso ao funil de vendas.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}".
     * @param {number} IdSala Informe o código da sala de venda (id).
     * @param {string} IdArea nforme o código do departamento (id).
     * @param {string} IdFunil Informe o ID do funil".
     * @param {string} IdPessoa Informe o ID da pessoa".
    **/
    public async RemoverPermissaoDeAcesso(Token: string, IdSala: number, IdArea: number, IdFunil: number, IdPessoa: number): Promise<AxiosResponse<any>> {
        return await Context.delete(`/FunilDeVenda/RemoverPermissaoDeAcesso/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${IdSala}/${IdArea}/${IdFunil}/${IdPessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new FunilDeVenda();