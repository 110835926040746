import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class Logon {
    
    /* #region  GET Controller */
    /* Sobre esta função *//**
     * Valida o usuário e gerar o token de acesso para o mesmo
     * @param {string} CPF Informe o CPF do usuário
     * @param {string} Senha Informe a senha do usuário
     **/
    public async Get(CPF: string, Senha: string): Promise<AxiosResponse<{token: string, pessoa: Objeto.Pessoa, empresasComPermissaoDeAcesso: Array<Objeto.Empresa>, cargosPorGrupoDeEmpresa: Array<{grupoDeEmpresas: { id: number, descricao: string, empresas: Objeto.Empresa}, cargo: {id: number, nome: string}}>}>> {
        return await Context.get<{Token: string, Pessoa: any}>(`/Logon/${CPF}/${Senha}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller */
    /* Sobre esta função *//**
     * Lista todos os usuários por empresa
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async PermissaoDeAcessoPorEmpresa(Token: string, Empresa: number | string): Promise<AxiosResponse<Array<{ usuario : {id: number, nome: string, cargo: {id: number, nome: string}, email: {classificacao: number, descricao: string}}, empresa: Objeto.Empresa }>>> {
        return await Context.get(`/Logon/PermissaoDeAcessoPorEmpresa/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?Empresa=${Empresa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller */
    /* Sobre esta função *//**
     * Lista todos os usuários por empresa
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async PermissaoDeAcessoPorCentroDeCusto(Token: string, Empresa: number | string, CentroDeCusto: string): Promise<AxiosResponse<Array<{ usuario : {id: number, nome: string, cargo: {id: number, nome: string}, email: {classificacao: number, descricao: string}}, centroDeCusto: Objeto.CentroDeCusto }>>> {
        return await Context.get(`/Logon/PermissaoDeAcessoPorCentroDeCusto/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller */
    /* Sobre esta função *//**
     * Lista todos os usuários por funil de venda
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async PermissaoDeAcessoPorFunilDeVenda(Token: string, SalaDeVenda: number | string, Area: number | string, FunilDeVenda: number | string): Promise<AxiosResponse<Array<{ usuario : {id: number, nome: string, cargo: {id: number, nome: string}, email: {classificacao: number, descricao: string}}, salaDeVenda: {id: number, descricao: string}, area: {id: number, descricao: string}, funil: Objeto.FunilDeVendas }>>> {
        return await Context.get(`/Logon/PermissaoDeAcessoPorFunilDeVenda/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?SalaDeVenda=${SalaDeVenda}&Area=${Area}&FunilDeVenda=${FunilDeVenda}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller */
    /* Sobre esta função *//**
     * Lista todos os usuários por controle de sala
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async PermissaoDeAcessoPorControleDeSala(Token: string, SalaDeVenda: number | string, ControleDeSala: number | string): Promise<AxiosResponse<Array<{ usuario : {id: number, nome: string, cargo: {id: number, nome: string}, email: {classificacao: number, descricao: string}}, salaDeVenda: {id: number, descricao: string}, controleDeSala: Objeto.ControleDeSala }>>> {
        return await Context.get(`/Logon/PermissaoDeAcessoPorControleDeSala/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?SalaDeVenda=${SalaDeVenda}&ControleDeSala=${ControleDeSala}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region GET Controller */
    /* Sobre esta função *//**
     * Lista todos os usuários por software externo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}".
     * @param {number} Empresa Informe o id da empresa, parametro opcional.
     * @param {string} CentroDeCusto Informe a sigla do centro de custo, parametro opcional.
     * @param {number} Pessoa Informe o id da pessoa, parametro opcional.
     **/
    public async PermissaoDeAcessoPorSoftwareExterno(Token: string, Empresa: number | string, CentroDeCusto: string, Pessoa: number | string): Promise<AxiosResponse<Array<{ usuario : {id: number, nome: string}, centroDeCusto: Objeto.CentroDeCusto, softwareExterno: {id: number, descricao: string, token: string, urlapi: string, tokenAPI: string}}>>> {
        return await Context.get(`/Logon/PermissaoDeAcessoPorSoftwareExterno/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}&Pessoa=${Pessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller */
    /* Sobre esta função *//**
     * Cadastra um novo usuário
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} Pessoa Informe o código da pessoa (Id)
     * @param {string} Senha Informe a senha do usuário
     **/
    public async Post(Token: string, Pessoa: number, Senha: string): Promise<AxiosResponse<any>> {
        return await Context.post<any>(`/Logon/${Token}/${Pessoa}/${Senha}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region POST Controller */
    /* Sobre esta função *//**
     * Cadastra um novo usuário
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} Usuario Informe os dados do usuário
     **/
    public async NovoUsuario(Token: string, Usuario: {id: number, nome: string, cargos: Array<{id: number, nome: string}>, email: {classificacao: number, descricao: string}, senha: string, tokenNotificacao: string | null, empresasPermitidas: Array<Objeto.Empresa>, centroDeCustoPermitidos: Array<Objeto.CentroDeCusto>, controlesDeSalaPermitidos: Array<Objeto.ControleDeSala>, funisPermitidos: Array<Objeto.FunilDeVendas>}): Promise<AxiosResponse<{id: number, nome: string, cargo: Array<{id: number, nome: string}>, email: {classificacao: number, descricao: string}, senha: string, tokenNotificacao: string, empresasPermitidas: Array<Objeto.Empresa>, centroDeCustoPermitidos: Array<Objeto.CentroDeCusto>, controlesDeSalaPermitidos: Array<Objeto.ControleDeSala>, funisPermitidos: Array<Objeto.FunilDeVendas>}>> {
        return await Context.post(`/Logon/${Token}`, Usuario)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region POST Controller */
    /* Sobre esta função *//**
     * Solicitar a alteração da senha atual
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} CPF Informe o CPF (somente números)
     **/
    public async NovaSenha(CPF: string): Promise<AxiosResponse<{ remetente: string, destinatario: string }>> {
        return await Context.post<{ remetente: string, destinatario: string }>(`/Logon/NovaSenha/${CPF}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region POST Controller */
    /* Sobre esta função *//**
     * Vincular usuário ao cargo já cadastrado.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} Pessoa Informe o código da pessoa (Id)
     * @param {string} Cargo Informe o código do cargo (Id)
     **/
    public async VincularUsuarioAoCargo(Token: string, Pessoa: number, Cargo: number): Promise<AxiosResponse<any>> {
        return await Context.post<any>(`/Logon/VincularUsuarioAoCargo/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Pessoa}/${Cargo}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller */
    /* Sobre esta função *//**
     * Alterar cargo vinculado ao usuário.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} Pessoa Informe o código da pessoa (Id)
     * @param {string} Cargo Informe o código do cargo (Id)
     **/
    public async AlterarCargoDoUsuario(Token: string, Pessoa: number, Cargo: number): Promise<AxiosResponse<any>> {
        return await Context.put<any>(`/Logon/AlterarCargoDoUsuario/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Pessoa}/${Cargo}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region GET Controller */
    /* Sobre esta função *//**
     * Buscar a lista de usuários vinculados a uma empresa
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
    **/
    public async Usuarios(Token: string, Cargo: number | string): Promise<AxiosResponse<Array<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>>> {
        return await Context.get(`/Logon/Usuarios/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?Cargos=${Cargo}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller */
    /* Sobre esta função *//**
     * Altera a senha atual do usuário
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} SenhaAtual Informe a senha atual do usuário
     * @param {string} NovaSenha Informe a nova senha do usuário
    **/
    public async Put(Token: string, SenhaAtual: string, NovaSenha: string): Promise<AxiosResponse<any>> {
        return await Context.put<any>(`/Logon/${Token}/${SenhaAtual}/${NovaSenha}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller */
    /* Sobre esta função *//**
     * Deletar o usuário.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} Pessoa Informe o id
    **/
    public async Delete(Token: string, Pessoa: number): Promise<AxiosResponse<any>> {
        return await Context.put<any>(`/Logon/${Token}/${Pessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller */
    /* Sobre esta função *//**
     * Desvincular usuário do cargo.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} Pessoa Informe o código da pessoa (Id)
     * @param {string} Cargo Informe o código do cargo (Id)
     **/
    public async DesvincularUsuarioDoCargo(Token: string, Pessoa: number, Cargo: number): Promise<AxiosResponse<any>> {
        return await Context.delete(`/Logon/DesvincularUsuarioDoCargo/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Pessoa}/${Cargo}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new Logon();