import React, { memo } from 'react';
import { Marker, Polygon, Popup } from 'react-leaflet-rotate/react-leaflet';
import { divIcon } from 'react-leaflet-rotate/leaflet-rotate-map';
import { renderToStaticMarkup } from 'react-dom/server';
import { PopUpLine, PopUpLineTitle, PopUpReservaButton } from '../index'
import { Moeda } from '../../../../../Themes/TextFormat';

function App({ polygons, setLocalRefresh, setPolygons, visaoSelecionada, getLocalData, refresh, setRefresh, localRefresh }: any) {

    if (visaoSelecionada === 0) {
        return polygons.lista.map((quadra: any, quadraIndex: any) => {
            return quadra.lotes.map((lote: any, loteIndex: any) => {
                const positions: any = [];
                lote.coordinates.map((array: any) => positions.push([array.longitude, array.latitude]))

                let fillColor = lote.dadosUau.status === 0 ? "green" :
                    lote.dadosUau.status === 1 ? "red" :
                        lote.dadosUau.status === 2 ? "yellow" :
                            lote.dadosUau.status === 3 ? "darkorange" :
                                lote.dadosUau.status === 4 ? "red" :
                                    lote.dadosUau.status === 5 ? "gray" :
                                        lote.dadosUau.status === 6 ? "black" :
                                            lote.dadosUau.status === 7 ? "darkcyan" :
                                                lote.dadosUau.status === 8 ? "#D7D5D2" :
                                                    lote.dadosUau.status === 9 ? "pink" :
                                                        lote.dadosUau.status === 10 ? "darkblue" : "white"
                let loteStatus = `${lote.dadosUau.status === 0 ? "Disponível" :
                    lote.dadosUau.status === 1 ? "Vendido" :
                        lote.dadosUau.status === 2 ? "Reservado" :
                            lote.dadosUau.status === 3 ? "Proposta" :
                                lote.dadosUau.status === 4 ? "Quitado" :
                                    lote.dadosUau.status === 5 ? "Escriturado" :
                                        lote.dadosUau.status === 6 ? "Em Venda" :
                                            lote.dadosUau.status === 7 ? "Suspenso venda" :
                                                lote.dadosUau.status === 8 ? "Fora de venda" :
                                                    lote.dadosUau.status === 9 ? "Em acerto" :
                                                        lote.dadosUau.status === 10 ? "Dação" : "white"}`

                return (
                    <Polygon
                        key={`${quadra.quadra}${lote.lote}`}
                        opacity={1}
                        fillOpacity={0.5}
                        fillColor={fillColor}
                        weight={1}
                        color={fillColor}
                        positions={positions}
                        eventHandlers={{
                            click: () => { }
                        }}>
                        <Popup minWidth={220}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", background: "0", height: "120px", fontWeight: "bold" }}>
                                <PopUpLineTitle value={lote.dadosUau.descricao} icon={"map-marker"} />
                                <PopUpLine label={"Área"} value={`${lote.dadosUau.area} m²`} icon={"control"} />
                                <PopUpLine label={"Valor"} value={`R$ ${lote.dadosUau.valorAVista ?? "0"}`} icon={"dollar"} />
                                <PopUpLine label={"Status"} value={loteStatus} icon={"segmented-control"} />
                                {(lote.dadosUau.status === 0 || lote.dadosUau.status === 2) && <PopUpReservaButton setRefresh={setRefresh} setPolygons={setPolygons} polygons={polygons} quadraIndex={quadraIndex} loteIndex={loteIndex} lote={lote} />}
                            </div>
                        </Popup>
                    </Polygon>
                )
            })
        })
    }
    else if (visaoSelecionada === 1) {
        return polygons.lista.map((quadra: any, quadraIndex: any) => {
            return quadra.lotes.map((lote: any, loteIndex: any) => {
                const positions: any = [];
                lote.coordinates.map((array: any) => positions.push([array.longitude, array.latitude]))
                return (
                    <Polygon
                        key={`${quadra.quadra}${lote.lote}`}
                        opacity={1}
                        fillOpacity={0.5}
                        fillColor={lote.dadosUau.colorInadimplencia}
                        weight={1}
                        color={lote.dadosUau.colorInadimplencia}
                        positions={positions}
                        eventHandlers={{
                            click: () => { }
                        }}>
                        <Popup minWidth={220}>
                            {lote.dadosUau.statusDaInadimplencia !== "ADIMPLENTE" && lote.dadosUau.statusDaInadimplencia !== "UNIDADE EM ESTOQUE" &&
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", background: "0", height: "220px", fontWeight: "bold" }}>
                                    <PopUpLineTitle value={lote.dadosUau.descricao} icon={"map-marker"} />
                                    <PopUpLine label={"Área"} value={`${lote.dadosUau.area} m²`} icon={"control"} />
                                    <PopUpLine label={"Valor"} value={`${Moeda.FormatarTexto(lote.dadosUau.valorAVista !== null ? lote.dadosUau.valorAVista : 0)}`} icon={"dollar"} />
                                    <PopUpLine label={"Inadimplência"} value={`${Moeda.FormatarTexto(lote.dadosUau.valorInadimplente)} (${/*lote.qtDeParcelasInadimplente*/""} parcela(s))`} icon={"dollar"} />
                                    <PopUpLine label={"Carteira futura"} value={Moeda.FormatarTexto(lote.dadosUau.valorAdimplente)} icon={"dollar"} />
                                    {lote.dadosUau.statusDaVistoria && <PopUpLine label={"Vistoria"} value={lote.dadosUau.statusDaVistoria} icon={"doughnut-chart"} />}
                                    <PopUpLine label={"Status"} value={lote.dadosUau.statusDaInadimplencia} icon={"segmented-control"} />
                                </div>
                            }
                            {(lote.dadosUau.statusDaInadimplencia === "ADIMPLENTE" || lote.dadosUau.statusDaInadimplencia === "UNIDADE EM ESTOQUE") &&
                                <div>Nenhum conteúdo referente a inadimplência.</div>
                            }
                        </Popup>
                    </Polygon>
                )
            })
        })
    }
    else if (visaoSelecionada === 2) {
        return getLocalData.lista.map((quadra: any, quadraIndex: any) => {
            return quadra.lotes.map((lote: any, loteIndex: any) => {
                const positions: any = [];
                lote.coordinates.map((array: any) => positions.push([array.longitude, array.latitude]))

                let loteStatus = `${lote.dadosUau.status === 0 ? "Disponível" :
                    lote.dadosUau.status === 1 ? "Vendido" :
                        lote.dadosUau.status === 2 ? "Reservado" :
                            lote.dadosUau.status === 3 ? "Proposta" :
                                lote.dadosUau.status === 4 ? "Quitado" :
                                    lote.dadosUau.status === 5 ? "Escriturado" :
                                        lote.dadosUau.status === 6 ? "Em Venda" :
                                            lote.dadosUau.status === 7 ? "Suspenso venda" :
                                                lote.dadosUau.status === 8 ? "Fora de venda" :
                                                    lote.dadosUau.status === 9 ? "Em acerto" :
                                                        lote.dadosUau.status === 10 ? "Dação" : "white"}`

                return (
                    <Polygon
                        key={`${quadra.quadra}${lote.lote}`}
                        opacity={1}
                        fillOpacity={0.5}
                        fillColor={lote.faixaValor.cor}
                        weight={1}
                        color={lote.faixaValor.cor}
                        positions={positions}
                        eventHandlers={{
                            click: () => { }
                        }}>
                        <Popup minWidth={220}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", background: "0", height: "120px", fontWeight: "bold" }}>
                                <PopUpLineTitle value={lote.dadosUau.descricao} icon={"map-marker"} />
                                <PopUpLine label={"Área"} value={`${lote.dadosUau.area} m²`} icon={"control"} />
                                <PopUpLine label={"Valor"} value={`R$ ${lote.dadosUau.valorAVista ?? "0"}`} icon={"dollar"} />
                                <PopUpLine label={"Status"} value={loteStatus} icon={"segmented-control"} />
                            </div>
                        </Popup>
                    </Polygon>
                )
            })
        })
    }
    else {
        return getLocalData.lista.map((quadra: any, quadraIndex: any) => {
            return quadra.lotes.map((lote: any, loteIndex: any) => {
                const positions: any = [];
                lote.coordinates.map((array: any) => positions.push([array.longitude, array.latitude]))

                let loteStatus = `${lote.dadosUau.status === 0 ? "Disponível" :
                    lote.dadosUau.status === 1 ? "Vendido" :
                        lote.dadosUau.status === 2 ? "Reservado" :
                            lote.dadosUau.status === 3 ? "Proposta" :
                                lote.dadosUau.status === 4 ? "Quitado" :
                                    lote.dadosUau.status === 5 ? "Escriturado" :
                                        lote.dadosUau.status === 6 ? "Em Venda" :
                                            lote.dadosUau.status === 7 ? "Suspenso venda" :
                                                lote.dadosUau.status === 8 ? "Fora de venda" :
                                                    lote.dadosUau.status === 9 ? "Em acerto" :
                                                        lote.dadosUau.status === 10 ? "Dação" : "white"}`

                return (
                    <Polygon
                        key={`${quadra.quadra}${lote.lote}`}
                        opacity={1}
                        fillOpacity={0.5}
                        fillColor={(lote.dadosUau.Categoria_de_preco == null) ? "#93E4FE" : lote.corCategoria}
                        weight={1}
                        color={(lote.dadosUau.Categoria_de_preco == null) ? "#93E4FE" : lote.corCategoria}
                        positions={positions}
                        eventHandlers={{
                            click: () => { }
                        }}>
                        <Popup minWidth={220}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", background: "0", height: "120px", fontWeight: "bold" }}>
                                <PopUpLineTitle value={lote.dadosUau.descricao} icon={"map-marker"} />
                                <PopUpLine label={"Área"} value={`${lote.dadosUau.area} m²`} icon={"control"} />
                                <PopUpLine label={"Valor"} value={`R$ ${lote.dadosUau.valorAVista ?? "0"}`} icon={"dollar"} />
                                <PopUpLine label={"Status"} value={loteStatus} icon={"segmented-control"} />
                                <PopUpLine label={"Categoria"} value={lote.dadosUau.Categoria_de_preco} icon={"control"} />
                            </div>
                        </Popup>
                    </Polygon>
                )
            })
        })
    }
}

export default App;