import React, { useEffect, useState, useRef } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from "axios";
import moment from 'moment';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { lighten, modularScale } from 'polished';
import Lottie from 'react-lottie';
import { Button, Card, FormGroup, MenuItem, FileInput, Menu, Icon, Collapse, Divider, Popover, Position, Dialog, AnchorButton, Classes, Code, H5, Intent, InputGroup, HTMLSelect, Radio, Drawer, Switch, TextArea } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select, MultiSelect } from "@blueprintjs/select";
import { DateInput, TimePicker, TimePrecision  } from "@blueprintjs/datetime";
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { extend } from '@syncfusion/ej2-base';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-kanban";
import * as TreeGrid from '@syncfusion/ej2-react-treegrid';
import { IFilter } from '@syncfusion/ej2-react-grids';
import { QueryCellInfoEventArgs, ActionEventArgs, getObject } from '@syncfusion/ej2-grids';
import { addClass, removeClass, Browser, isNullOrUndefined } from '@syncfusion/ej2-base';
import { ToolbarSettingsModel, FileManager, FileManagerSettingsModel, QuickToolbarSettingsModel } from '@syncfusion/ej2-react-richtexteditor';
import CodeMirror from 'codemirror';
import { createElement } from '@syncfusion/ej2-base';
import * as dataSource from './datasource.json';
import * as dadosLista from './listaKanban.json';
import { countries } from './dataSourceTreeView';

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import { Localization, TiposComponentes } from '../../../Data/Listas';
import { CentroDeCusto, Venda, Prospect, Correios, D4sign, Identificador, Lead, Nacao, Pessoa, TabelaDeVenda, FunilDeVendas } from '../../../Services/Controllers';
import { Objeto } from '../../../Services/Models';
import { CPF, CEP, Moeda, Numero, Telefone, CNPJ, Data } from '../../../Themes/TextFormat';
import { NotificacaoInterna } from '../../../Services/Notification';
import * as LottieFiles from '../../../Data/Lottie';
import { Banco, Bandeira, MaquinaDeCartao, MeioDePagamento, Operacao, UF } from '../../../Data/Listas';
import History from "../../../Routers";
import { Tooltip } from 'react-tippy';

import './App.css';
import { image } from './image';
import * as Component from './style';
import viewModel from './viewModel';
import { stringify } from "querystring";
import { numberFormatting } from "@syncfusion/ej2-react-pivotview";
import Terry from '../../../Assets/Images/Icone/terry.png';
import transitions from "@material-ui/core/styles/transitions";
import { ICON } from "@blueprintjs/core/lib/esm/common/classes";
import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";

loadCldr(
    require('../../../../node_modules/cldr-data/main/pt/currencies.json'),
    require('../../../../node_modules/cldr-data/main/pt/numbers.json'),
    require('../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
    require('../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
    require('../../../../node_modules/cldr-data/supplemental/numberingSystems.json'));
setCulture('pt');
setCurrencyCode("BRL");
L10n.load({
    'pt': {
        'kanban': {
            'items': 'itens',
            'min': 'Min',
            'max': 'Max',
            'cardsSelected': 'Cards selecionados',
            'addTitle': 'adicionar titulo',
            'editTitle': 'editar titulo',
            'deleteTitle': 'detelar titulo',
            'deleteContent': 'deletar conteudo',
            'save': 'salvar',
            'delete': 'deletar',
            'cancel': 'cancelar',
            'yes': 'Sim',
            'no': 'Não',
            'close': 'Fechar',
            'noCard': 'sem card',
            'unassigned': 'sem assinatura'
        }
    }
});

var fonteDeDados: any; 

let info: Object[];

interface KanbanDataModel {
    Id?: string;
    Title?: string;
    Status?: string;
    Summary?: string;
    Type?: string;
    Priority?: string;
    Tags?: string;
    Estimate?: number;
    Assignee?: string;
    RankId?: number;
    Color?: string;
}

interface IDialogExampleState {
    autoFocus: boolean;
    canEscapeKeyClose: boolean;
    canOutsideClickClose: boolean;
    enforceFocus: boolean;
    isOpen: boolean;
    usePortal: boolean;
}

const View: React.FC<viewModel> = (viewModel) => {

    /* #region  Variáveis  */
    const PropriedadesDeCalendario = {
        months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    };
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const [getCPFEmConsulta, setCPFEmConsulta] = useState<boolean>(false);
    const [getFormularioID, setformularioID] = useState<number>(-1);
    const [getFormularioBairro, setFormularioBairro] = useState<string>("");
    const [getFormularioCEP, setFormularioCEP] = useState<string>("");
    const [getFormularioCidade, setFormularioCidade] = useState<string>("");
    const [getFormularioComplemento, setFormularioComplemento] = useState<string>("");
    const [getFormularioCPF, setFormularioCPF] = useState<string>("");
    const [getFormularioDataDeNascimento, setFormularioDataDeNascimento] = useState<Date | null | undefined>();
    const [getFormularioEstadoCivil, setFormularioEstadoCivil] = useState<{ id: number, descricao: string }>();
    const [getFormularioEmails, setFormularioEmails] = useState<Array<Objeto.Email>>([]);
    const [getFormularioLogradouro, setFormularioLogradouro] = useState<string>("");
    const [getFormularioNome, setFormularioNome] = useState<string>("");
    const [getFormularioNacionalidade, setFormulacaoNacionalidade] = useState<Objeto.Nacao>();
    const [getFormularioNumeroDeEndereco, setFormularioNumeroDeEndereco] = useState<string>("");
    const [getFormularioProfissao, setFormularioProfissao] = useState<string>("");
    const [getFormularioRG, setFormularioRG] = useState<string>("");
    const [getFormularioRGOrgaoEmissor, setFormularioRGOrgaoEmissor] = useState<string>("");
    const [getFormularioRGUF, setFormularioRGUF] = useState<string>("");
    const [getFormularioTelefones, setFormularioTelefones] = useState<Array<Objeto.Telefone>>([]);
    const [getFormularioTelefoneCelular, setFormularioTelefoneCelular] = useState<string>("");
    const [getFormularioUF, setFormularioUF] = useState<string>("");

    const [getTelefones, setTelefones] = useState<Array<{descricao: string}>>([]);
    const [getListaDeProspects, setListaDeProspects] = useState<Array<any>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const PickerNacionalidade = Select.ofType<Objeto.Nacao>();
    const [getListaMeioDeContato, setListaMeioDeContato] = useState<Array<{id: number, descricao: string}>>([
        {
            id: 0,
            descricao: "Email",
        },
        {
            id: 1,
            descricao: "Telefone",
        }
    ]);

    const [getCollapseDadosPessoais, setCollapseDadosPesssoais] = useState(false);
    const [getCollapseDadosEndereco, setCollapseDadosEndereco] = useState(false);

    const [getFormularioMeioDeContato, setFormularioMeioDeContato] = useState<{id: number, descricao: string}>();
    const [getFormularioListaAcoes, setFormularioListaAcoes] = useState<any>([]);
    const [getFormularioListaAtividades, setFormularioListaAtividades] = useState<any>([]);
    const [getFormularioListaTarefas, setFormularioListaTarefas] = useState<any>([]);
    const [getFormularioListaAnotacoes, setFormularioListaAnotacoes] = useState<any>([]);
    const [getFormularioListaEmails, setFormularioListaEmails] = useState<any>([]);
    const [getFormularioPosicaoFunil, setFormularioPosicaoFunil] = useState<{id: number, descricao: string}>();

    const [getItemPickerSala, setItemPickerSala] = useState<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();
    const [getListaPickerNacionalidade, setListaPickerNacionalidade] = useState<Array<Objeto.Nacao>>([]);
    const [getFormularioListaEstadoCivil, setFormularioListaEstadoCivil] = useState<Array<{ id: number, descricao: string }>>();
    const [getListaPickerSala, setListaPickerSala] = useState<Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>>();

    const PickerSala = Select.ofType<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();

    const PickerPrioridade = Select.ofType<{ id: number, descricao: string }>();
    const PickerSolicitante = Select.ofType<Objeto.Pessoa>();
    const PickerResponsavel = Select.ofType<Objeto.Pessoa>();

    const [getFormularioNomeTarefa, setFormularioNomeTarefa] = useState<string>("");
    const [getEditandoTarefa, setEditandoTarefa] = useState<boolean>(false);
    const [getFormularioDescricaoTarefa, setFormularioDescricaoTarefa] = useState<string>("");

    const [getFormularioParaEmail, setFormularioParaEmail] = useState<string>("");
    const [getFormularioCcEmail, setFormularioCcEmail] = useState<string>("");
    const [getFormularioAssuntoEmail, setFormularioAssuntoEmail] = useState<string>("");

    const [getFormularioPrevisaoDeInicio, setFormularioPrevisaoDeInicio] = useState<Date>(moment(new Date()).subtract(1, "day").toDate());
    const [getFormularioPrevisaoDeTermino, setFormularioPrevisaoDeTermino] = useState<Date>(moment(new Date()).subtract(0, "day").toDate());
    const [getFormularioDataDeTermino, setFormularioDataDeTermino] = useState<Date>(moment(new Date()).subtract(0, "day").toDate());
    
    const [getListaHistoricoDoFunil, setListaHistoricoDoFunil] = useState<Array<{id: number, descricao: string}>>([
        {
            id: 1,
            descricao: "Sem contato"
        },
        {
            id: 2,
            descricao: "Contato feito"
        },
        {
            id: 3,
            descricao: "Identificação de interesse"
        },
        {
            id: 4,
            descricao: "Apresentação"
        },
        {
            id: 5,
            descricao: "Proposta enviada"
        }
    ]);

    const [getListaMudarDeFase, setListaMudarDeFase] = useState<Array<{id: number, descricao: string}>>([
        {
            id: 1,
            descricao: "Sem contato"
        },
        {
            id: 2,
            descricao: "Contato feito"
        },
        {
            id: 3,
            descricao: "Identificação de interesse"
        },
        {
            id: 4,
            descricao: "Apresentação"
        },
        {
            id: 5,
            descricao: "Proposta enviada"
        }
    ]);

    const [getTiposDeComponentesFormulario, setTiposDeComponenesFormulario] = useState<Array<{ id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string }>>(TiposComponentes);

    const [getSelectCustomComponent, setSelectCustomComponent] = useState<{id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string }>();

    const [getListaDeLeads, setListaDeLeads] = useState<{kanbanData: Array<Objeto.LeadKanban>}>({kanbanData: []});

    const [getListaFunilDeVendas, setListaFunilDeVendas] = useState<{id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string}>({
        id: 0,
        posicoesDoFunil: [
            {
                id: 0,
                descricao: ""
            }
        ],
        descricao: ""
    });
    const [getListaFasesDoFunil, setListaFasesDoFunil] = useState<Array<{id: number, descricao: string}>>([]);
    const [getItemFunil, setItemFunil] = useState<string>("");
    const [getAtualizandoFunil, setAtualizandoFunil] = useState<boolean>(false);
    const [getItemFunilSelecionado, setItemFunilSelecionado] = useState<{id: number, descricao: string}>();

    const [getListaDeMembros, setListaDeMembros] = useState<Array<{id: number, tipo: {id: number, descricao: string}, pessoa: Objeto.Pessoa}>>([]);

    const [getCarregandoListaDeContratos, setCarregandoListaDeContratos] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getCollapse, setCollapse] = useState(false);

    const [getSalvarFunil, setSalvarFunil] = useState<boolean>(false);
    const [getAlterarItemFunil, setAlterarItemFunil] = useState<boolean>(false);
    const [getDragStart, setDragStart] = useState(false);

    const [getOptionCustomizedFields, setOptionCustomizedFields] = useState(false);
    const [getOptionSitucaoNoFunil, setOptionSitucaoNoFunil] = useState(true);
    const [getOptionMudarDeFase, setOptionMudarDeFase] = useState(false);
    const [getOptionPerfil, setOptionPerfil] = useState(true);
    const [getOptionPermissoes, setOptionPermissoes] = useState(false);
    /* #endregion */

    let data: Object[] = extend([], (getListaDeLeads as { [key: string]: Object }).kanbanData, true) as Object[];

    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
       **/
    async function Buscar() {}

    let kanbanObj: any;

    const [getDialogState, setDialogState] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDialogCreateFase, setDialogCreateFase] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDialogCustomComponent, setDialogCustomComponent] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpen = () => setDialogState({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleClose = () => setDialogState({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleDialogCreateFaseOpen = () => setDialogCreateFase({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCreateFaseClose = () => setDialogCreateFase({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleDialogCustomComponentOpen = () => setDialogCustomComponent({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCustomComponentClose = () => setDialogCustomComponent({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    var rteObj: any;

    var hostUrl: string = 'https://ej2-aspcore-service.azurewebsites.net/';

    var items: string[] = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', 'SuperScript', 'SubScript', '|',
      'CreateTable', 'CreateLink', 'Image', 'FileManager', '|', 'ClearFormat', 'Print',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
    ];
  
    var fileManagerSettings: FileManagerSettingsModel = {
      enable: true,
      path: '/Pictures/Food',
      ajaxSettings: {
        url: hostUrl + 'api/FileManager/FileOperations',
        getImageUrl: hostUrl + 'api/FileManager/GetImage',
        uploadUrl: hostUrl + 'api/FileManager/Upload',
        downloadUrl: hostUrl + 'api/FileManager/Download'
      }
    }

    var quickToolbarSettings: QuickToolbarSettingsModel = {
      table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles']
    }

    var toolbarSettings: ToolbarSettingsModel = {
      items: items
    };

    var textArea: any;
    var myCodeMirror: any;

    const mirrorConversion = (e?: any) => {
      textArea = rteObj.contentModule.getEditPanel() as HTMLTextAreaElement;
      let id: string = (rteObj as any).getID() + 'mirror-view';
      let mirrorView: HTMLElement = rteObj.element.querySelector('#' + id) as HTMLElement;
      let charCount: HTMLElement = rteObj.element.querySelector('.e-rte-character-count') as HTMLElement;
      if (e.targetItem === 'Preview') {
        textArea.style.display = 'block';
        mirrorView.style.display = 'none';
        textArea.innerHTML = myCodeMirror.getValue();
        charCount.style.display = 'block';
      } else {
        if (!mirrorView) {
          mirrorView = createElement('div', { className: 'e-content' });
          mirrorView.id = id;
          textArea.parentNode.appendChild(mirrorView);
        } else {
          mirrorView.innerHTML = '';
        }
        textArea.style.display = 'none';
        mirrorView.style.display = 'block';
        renderCodeMirror(mirrorView, (rteObj as any).value);
        charCount.style.display = 'none';
      }
    }

    const renderCodeMirror = (mirrorView: HTMLElement, content: string) => {
      myCodeMirror = CodeMirror(mirrorView, {
        value: content,
        lineNumbers: true,
        mode: 'text/html',
        lineWrapping: true,
  
      });
    }

    const handleFullScreen = (e: any) => {
      let sbCntEle: any = document.querySelector('.sb-content.e-view');
      let sbHdrEle: any = document.querySelector('.sb-header.e-view');
      let leftBar: any;
      let transformElement: any;
      if (Browser.isDevice) {
        leftBar = document.querySelector('#right-sidebar');
        transformElement = document.querySelector('.sample-browser.e-view.e-content-animation');
      } else {
        leftBar = document.querySelector('#left-sidebar');
        transformElement = document.querySelector('#right-pane');
      }
      if (e.targetItem === 'Maximize') {
        if (Browser.isDevice && Browser.isIos) {
          addClass([sbCntEle, sbHdrEle], ['hide-header']);
        }
        addClass([leftBar], ['e-close']);
        removeClass([leftBar], ['e-open']);
        if (!Browser.isDevice) { transformElement.style.marginLeft = '0px'; }
        transformElement.style.transform = 'inherit';
      } else if (e.targetItem === 'Minimize') {
        if (Browser.isDevice && Browser.isIos) {
          removeClass([sbCntEle, sbHdrEle], ['hide-header']);
        }
        removeClass([leftBar], ['e-close']);
        if (!Browser.isDevice) {
          addClass([leftBar], ['e-open']);
          transformElement.style.marginLeft = leftBar.offsetWidth + 'px';
        }
        transformElement.style.transform = 'translateX(0px)';
      }
    }

    const actionCompleteHandler = (e: any) => {
      if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
        (rteObj.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display = 'none';
        mirrorConversion(e);
      } else {
        setTimeout(() => { (rteObj as any).toolbarModule.refreshToolbarOverflow(); }, 400);
      }
    }

    const gridTemplate = (props: any) => { 
        return (<div style={{display: 'inline'}}><div style={{display: 'inline-block'}}>
        <img className='e-image'></img>     
        </div><div style={{ display: 'inline-block', paddingLeft: '6px'}}>{props.name}</div></div>);
    }
    
    const treegridTemplate = (props: any) => {
        return (<div className='statustemp'>
            <span className='statustxt'>{props.gdp}</span>
        </div>);
    }
    
    const treeratingTemplate = (props: any) => {
        if (props.rating) {
          return (<div className='rating'>
          <span className="star"></span>
          <span className="star"></span>
          <span className="star"></span>
          <span className="star"></span>
          <span className="star"></span>
        </div>);
        }
        else {
          return(<span></span>);
        }
    }
    
    const treeunemployTemplate = (props: any) => {
        return (<div id='myProgress' className='pbar'>
          <div id='myBar' className='bar'>
            <div id='label' className='barlabel'></div>
          </div>
        </div>);
    }
    
    const treelocationTemplate = (props: any) => {
        var locationsrc = 'src/treegrid/images/Map.png';
    
        return (<div>
          <img src={locationsrc} className='e-image' alt={props.coordinates} />
          <a target='_blank' href='https://www.google.com/maps/place/'>{props.coordinates}</a>
        </div>);
    }
    
    const treeareaTemplate = (props: any) => {
        return (<span>{props.area} km<sup>2</sup></span>);
    }
    
    const treezoneTemplate = (props: any) => {
        let classValue = '';
        if (props.timezone.indexOf('-') !== -1) {
          classValue = 'negativeTimeZone';
        }
        return (<div><img src='src/treegrid/images/__Normal.png' className={classValue}></img><span style={{ paddingLeft: '7px'}}>{props.timezone}</span>)</div>);
    }

    const Filter: any = {
        type: 'Excel', 
        itemTemplate: '#flagtemplate'
    }

    const populationValue = (field: string, data: Object | any) => {
        return data[field] / 1000000;
    }
    const queryCellinfo = (args: QueryCellInfoEventArgs | any) => {
        if (args.column?.field === 'gdp') {
            if (args.data[args.column?.field] < 2) {
                args.cell?.querySelector('.statustxt').classList.add('e-lowgdp');
                args.cell?.querySelector('.statustemp').classList.add('e-lowgdp');
            }
        }
        if (args.column?.field === 'rating') {
            if (args.column.field === 'rating') {
                for (let i: number = 0; i < args.data[args.column.field]; i++) {
                    args.cell.querySelectorAll('span')[i].classList.add('checked');
                }
            }
        }
        if (args.column?.field === 'unemployment') {
            if (args.data[args.column.field] <= 4) {
                addClass([args.cell.querySelector('.bar')], ['progressdisable']);
            }
            (args.cell.querySelector('.bar')as HTMLElement).style.width = args.data[args.column.field] * 10 + '%';
            args.cell.querySelector('.barlabel').textContent = args.data[args.column.field] + '%';
        }
            
        if (args.column?.field === 'name') {
            let parentItem: TreeGrid.ITreeData = getObject('parentItem', args.data);
            let imageElement = (args.cell as Element).querySelector('.e-image') as HTMLImageElement;
            if (isNullOrUndefined(parentItem)) {
            let name: string = getObject('name', args.data);
            imageElement.src = "src/treegrid/images/" + name + ".png";
            } else {
            let name: string = getObject('name', parentItem);
            imageElement.src = "src/treegrid/images/" + name + ".png";
            }
        }
    }

    const flagtemplate: any = gridTemplate;
    const gdptemplate: any = treegridTemplate;
    const ratingtemplate: any = treeratingTemplate;
    const unemploymentTemplate: any = treeunemployTemplate;
    const locationtemplate: any = treelocationTemplate;
    const areatemplate: any = treeareaTemplate;
    const timezonetemplate: any = treezoneTemplate;

    useEffect(() => {
        async function componentDidMount() {
            await setFormularioListaEstadoCivil((await Pessoa.EstadoCivil(ContextSignIn.getContext().token)).data);
            await setListaPickerNacionalidade((await Nacao.Get(ContextSignIn.getContext().token)).data);
            await setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? []);
            
            var Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                .get(`/Identificador/VisaoGAV02/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}`))
                .data as Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>;
            await setListaPickerSala(Response);

            var ResponseListaProspects = await (await Prospect.Get(ContextSignIn.getContext().token))
            if (Math.floor(ResponseListaProspects.status / 100) == 2) {}
        }
        componentDidMount();
    }, []);

    return (
        <Component.Container style = {{backgroundColor: "#FFFFFF"}}>

            {/** Dialog criando nova fase */}
            <Dialog
                style = {{width: '40%', backgroundColor: "#FFFFFF"}}
                icon="filter"
                onClose={() => {
                    setItemFunil("");
                    setAlterarItemFunil(false);
                    setItemFunilSelecionado(undefined);
                    handleDialogCreateFaseClose();
                }}
                title={getAlterarItemFunil == false ? "Criando uma nova fase" : "Atualizando a fase"}
                autoFocus = {true}
                isOpen = {getDialogCreateFase.isOpen}
                canEscapeKeyClose = {getDialogCreateFase.canEscapeKeyClose}
                canOutsideClickClose = {getDialogCreateFase.canOutsideClickClose}
                enforceFocus = {getDialogCreateFase.enforceFocus}
                usePortal = {getDialogCreateFase.usePortal} 
            >
                <div style = {{display: "flex", alignItems: "center"}}>
                    <div style = {{marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRight: "0.5px solid #00000050", paddingBottom: "20px"}}>
                        
                        <div style = {{width: "95%", marginTop: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Titulo da fase`}</div>
                            <Component.InputTask style = {{width: "100%"}} id="CustomLabel" placeholder={"Nome da fase..."} value={getItemFunil}
                                onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                    var TextoDigitado = event.currentTarget.value;

                                    setItemFunil(TextoDigitado)

                                }}>
                            </Component.InputTask>
                        </div>

                    </div>
                </div>
                
                <div style = {{ width: "100%", paddingRight: "2.5%", paddingLeft: "2.5%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center", borderTop: "0.5px solid #00000050"}}>
                    <Button
                        style = {{
                            flex: 1,
                            background: "#1098F7", 
                            color: "#FFFFFF",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px",
                            height: "15px",
                            marginTop: "20px"
                        }}
                        text = {getAlterarItemFunil == true ? "Atualizar" :  "Criar"}
                        onClick = {() => {

                            if (getAlterarItemFunil == false) {
                                if (getListaFasesDoFunil.filter((item, index) => item.descricao == getItemFunil).length == 0)
                                {
    
                                    getListaFasesDoFunil.push({
                                        id: getListaFasesDoFunil.length,
                                        descricao: getItemFunil
                                    });
    
                                    getListaDeLeads.kanbanData.push(
                                        {
                                            id: (13 + getListaDeLeads.kanbanData.length),
                                            cpf: "51123762449",
                                            nome: "Fulano A",
                                            statusKanban: `${getItemFunil}`,
                                            summary: "...",
                                            tags: "Contactar",
                                            color: "#E64A19",
                                            className: "e-story, e-low, e-nancy-davloio",
                                            dataDeNascimento: new Date("1970-06-02T00:00:00"),
                                            idade: undefined,
                                            nacionalidade: undefined,
                                            sexo: undefined,
                                            emails: [
                                                {
                                                    classificacao: 1,
                                                    descricao: "josiedson@cardial.br"
                                                },
                                                {
                                                    classificacao: 1,
                                                    descricao: "josiedson@cardial.br"
                                                }
                                            ],
                                            fotoDoLead: null,
                                            documentoPessoal: null,
                                            rg: null,
                                            estadoCivil: undefined,
                                            documentoDeEstadoCivil: null,
                                            regimeDeBens: undefined,
                                            ocupacao: undefined,
                                            renda: undefined,
                                            dadosDosVeiculos: undefined,
                                            dependentes: null,
                                            endereco: null,
                                            documentoEndereco: null,
                                            telefones: [
                                                {
                                                    classificacao: 1,
                                                    ddi: "55",
                                                    ddd: "81",
                                                    numero: "991595874",
                                                    observacao: ""
                                                },
                                                {
                                                    classificacao: 1,
                                                    ddi: "55",
                                                    ddd: "81",
                                                    numero: "991595874",
                                                    observacao: ""
                                                }
                                            ],
                                            localDeCaptacao: "SITE",
                                            status: 0,
                                            observacoes: [
                                                {
                                                    nome: "Washington Souza",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Izacc Moreira",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Calixto",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Hugo Moreira",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Lucas Moreira Assis",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                }
                                            ],
                                            responsavel: undefined,
                                            posicaoDoFunil: {
                                                id: 3,
                                                descricao: "Identificação do interesse"
                                            },
                                            atividades: [
                                                {
                                                  classificacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Email"
                                                  },
                                                  posicaoDoFunil: {
                                                    id: 1,
                                                    descricao: "Sem contato"
                                                  },
                                                  situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                  },
                                                  executor: {
                                                    id: 1,
                                                    cpf: null,
                                                    nome: "Washington dos Santos",
                                                    natureza: 0,
                                                    dataDeNascimento: null,
                                                    emails: null,
                                                    documentoPessoal: null,
                                                    rg: null,
                                                    creci: null,
                                                    estadoCivil: null,
                                                    documentoDeEstadoCivil: null,
                                                    regimeDeBens: null,
                                                    ocupacao: null,
                                                    necessarioAssinaturaDoConjuge: false,
                                                    conjuge: null,
                                                    endereco: null,
                                                    documentoEndereco: null,
                                                    telefones: null,
                                                    observacao: null
                                                  },
                                                  dataDoEvento: "2021-06-11T00:00:00-03:00",
                                                  descricao: "Enviado email de boas vindas"
                                                },
                                                {
                                                  classificacaoDaAtividade: {
                                                    id: 2,
                                                    descricao: "Tarefa"
                                                  },
                                                  posicaoDoFunil: {
                                                    id: 1,
                                                    descricao: "Sem contato"
                                                  },
                                                  situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                  },
                                                  executor: {
                                                    id: 2,
                                                    cpf: null,
                                                    nome: "Izacc Moreira",
                                                    natureza: 0,
                                                    dataDeNascimento: null,
                                                    emails: null,
                                                    documentoPessoal: null,
                                                    rg: null,
                                                    creci: null,
                                                    estadoCivil: null,
                                                    documentoDeEstadoCivil: null,
                                                    regimeDeBens: null,
                                                    ocupacao: null,
                                                    necessarioAssinaturaDoConjuge: false,
                                                    conjuge: null,
                                                    endereco: null,
                                                    documentoEndereco: null,
                                                    telefones: null,
                                                    observacao: null
                                                  },
                                                  dataDoEvento: "2021-07-01T00:00:00-03:00",
                                                  descricao: "Tentativa de contato sem sucesso"
                                                },
                                                {
                                                  classificacaoDaAtividade: {
                                                    id: 2,
                                                    descricao: "Tarefa"
                                                  },
                                                  posicaoDoFunil: {
                                                    id: 2,
                                                    descricao: "Contato feito"
                                                  },
                                                  situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                  },
                                                  executor: {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "dataDoEvento": "2021-07-21T00:00:00-03:00",
                                                  "descricao": "Contato com sucesso, cliente agendado para o dia 11/07/2021"
                                                },
                                                {
                                                  "classificacaoDaAtividade": {
                                                    "id": 2,
                                                    "descricao": "Tarefa"
                                                  },
                                                  "posicaoDoFunil": {
                                                    "id": 3,
                                                    "descricao": "Identificação do interesse"
                                                  },
                                                  "situacaoDaAtividade": {
                                                    "id": 0,
                                                    "descricao": "Aberta"
                                                  },
                                                  "executor": {
                                                    "id": 3,
                                                    "cpf": null,
                                                    "nome": "Hugo Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "dataDoEvento": "2021-07-16T00:00:00-03:00",
                                                  "descricao": "Tentativa de contato sem sucesso"
                                                }
                                            ],
                                            "tarefas": [
                                                {
                                                  "prioridade": {
                                                    "id": 2,
                                                    "descricao": "Média"
                                                  },
                                                  "solicitante": null,
                                                  "executor": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "descricao": "Ligar para o lead",
                                                  "previsaoDeInicio": "2021-07-01T00:00:00-03:00",
                                                  "previsaoDeTermino": "2021-07-01T00:00:00-03:00",
                                                  "dataDeTermino": "2021-07-01T00:00:00-03:00",
                                                  "dataCancelamento": null,
                                                  "posicaoDoFunil": {
                                                    "id": 1,
                                                    "descricao": "Sem contato"
                                                  }
                                                },
                                                {
                                                  "prioridade": {
                                                    "id": 2,
                                                    "descricao": "Média"
                                                  },
                                                  "solicitante": null,
                                                  "executor": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "descricao": "Contato com sucesso, cliente agendado para o dia 21/07/2021",
                                                  "previsaoDeInicio": "2021-07-11T00:00:00-03:00",
                                                  "previsaoDeTermino": "2021-07-11T00:00:00-03:00",
                                                  "dataDeTermino": "2021-07-11T00:00:00-03:00",
                                                  "dataCancelamento": null,
                                                  "posicaoDoFunil": {
                                                    "id": 2,
                                                    "descricao": "Contato feito"
                                                  }
                                                },
                                                {
                                                  "prioridade": {
                                                    "id": 1,
                                                    "descricao": "Alta"
                                                  },
                                                  "solicitante": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "executor": {
                                                    "id": 3,
                                                    "cpf": null,
                                                    "nome": "Hugo Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "descricao": "Tentativa de contato sem sucesso",
                                                  "previsaoDeInicio": "2021-07-16T00:00:00-03:00",
                                                  "previsaoDeTermino": "2021-07-16T00:00:00-03:00",
                                                  "dataDeTermino": null,
                                                  "dataCancelamento": null,
                                                  "posicaoDoFunil": {
                                                    "id": 3,
                                                    "descricao": "Identificação do interesse"
                                                  }
                                                }
                                            ],
                                            anotacoes: [],
                                            listaEmails: []
                                        },
                                        {
                                            id: (14 + getListaDeLeads.kanbanData.length),
                                            cpf: "51123762449",
                                            nome: "Fulano B",
                                            statusKanban: `${getItemFunil}`,
                                            summary: "...",
                                            tags: "Contactar",
                                            color: "#E6AAAA",
                                            className: "e-story, e-low, e-nancy-davloio",
                                            dataDeNascimento: new Date("1970-06-02T00:00:00"),
                                            idade: undefined,
                                            nacionalidade: undefined,
                                            sexo: undefined,
                                            emails: [
                                                {
                                                    classificacao: 1,
                                                    descricao: "josiedson@cardial.br"
                                                },
                                                {
                                                    classificacao: 1,
                                                    descricao: "josiedson@cardial.br"
                                                }
                                            ],
                                            fotoDoLead: null,
                                            documentoPessoal: null,
                                            rg: null,
                                            estadoCivil: undefined,
                                            documentoDeEstadoCivil: null,
                                            regimeDeBens: undefined,
                                            ocupacao: undefined,
                                            renda: undefined,
                                            dadosDosVeiculos: undefined,
                                            dependentes: null,
                                            endereco: null,
                                            documentoEndereco: null,
                                            telefones: [
                                                {
                                                    classificacao: 1,
                                                    ddi: "55",
                                                    ddd: "81",
                                                    numero: "991595874",
                                                    observacao: ""
                                                },
                                                {
                                                    classificacao: 1,
                                                    ddi: "55",
                                                    ddd: "81",
                                                    numero: "991595874",
                                                    observacao: ""
                                                }
                                            ],
                                            localDeCaptacao: "SITE",
                                            status: 0,
                                            observacoes: [
                                                {
                                                    nome: "Washington Souza",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Izacc Moreira",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Calixto",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Hugo Moreira",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Lucas Moreira Assis",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                }
                                            ],
                                            responsavel: undefined,
                                            posicaoDoFunil: {
                                                id: 3,
                                                descricao: "Identificação do interesse"
                                            },
                                            atividades: [
                                                {
                                                classificacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Email"
                                                },
                                                posicaoDoFunil: {
                                                    id: 1,
                                                    descricao: "Sem contato"
                                                },
                                                situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                },
                                                executor: {
                                                    id: 1,
                                                    cpf: null,
                                                    nome: "Washington dos Santos",
                                                    natureza: 0,
                                                    dataDeNascimento: null,
                                                    emails: null,
                                                    documentoPessoal: null,
                                                    rg: null,
                                                    creci: null,
                                                    estadoCivil: null,
                                                    documentoDeEstadoCivil: null,
                                                    regimeDeBens: null,
                                                    ocupacao: null,
                                                    necessarioAssinaturaDoConjuge: false,
                                                    conjuge: null,
                                                    endereco: null,
                                                    documentoEndereco: null,
                                                    telefones: null,
                                                    observacao: null
                                                },
                                                dataDoEvento: "2021-06-11T00:00:00-03:00",
                                                descricao: "Enviado email de boas vindas"
                                                },
                                                {
                                                classificacaoDaAtividade: {
                                                    id: 2,
                                                    descricao: "Tarefa"
                                                },
                                                posicaoDoFunil: {
                                                    id: 1,
                                                    descricao: "Sem contato"
                                                },
                                                situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                },
                                                executor: {
                                                    id: 2,
                                                    cpf: null,
                                                    nome: "Izacc Moreira",
                                                    natureza: 0,
                                                    dataDeNascimento: null,
                                                    emails: null,
                                                    documentoPessoal: null,
                                                    rg: null,
                                                    creci: null,
                                                    estadoCivil: null,
                                                    documentoDeEstadoCivil: null,
                                                    regimeDeBens: null,
                                                    ocupacao: null,
                                                    necessarioAssinaturaDoConjuge: false,
                                                    conjuge: null,
                                                    endereco: null,
                                                    documentoEndereco: null,
                                                    telefones: null,
                                                    observacao: null
                                                },
                                                dataDoEvento: "2021-07-01T00:00:00-03:00",
                                                descricao: "Tentativa de contato sem sucesso"
                                                },
                                                {
                                                classificacaoDaAtividade: {
                                                    id: 2,
                                                    descricao: "Tarefa"
                                                },
                                                posicaoDoFunil: {
                                                    id: 2,
                                                    descricao: "Contato feito"
                                                },
                                                situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                },
                                                executor: {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "dataDoEvento": "2021-07-21T00:00:00-03:00",
                                                "descricao": "Contato com sucesso, cliente agendado para o dia 11/07/2021"
                                                },
                                                {
                                                "classificacaoDaAtividade": {
                                                    "id": 2,
                                                    "descricao": "Tarefa"
                                                },
                                                "posicaoDoFunil": {
                                                    "id": 3,
                                                    "descricao": "Identificação do interesse"
                                                },
                                                "situacaoDaAtividade": {
                                                    "id": 0,
                                                    "descricao": "Aberta"
                                                },
                                                "executor": {
                                                    "id": 3,
                                                    "cpf": null,
                                                    "nome": "Hugo Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "dataDoEvento": "2021-07-16T00:00:00-03:00",
                                                "descricao": "Tentativa de contato sem sucesso"
                                                }
                                            ],
                                            "tarefas": [
                                                {
                                                "prioridade": {
                                                    "id": 2,
                                                    "descricao": "Média"
                                                },
                                                "solicitante": null,
                                                "executor": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "descricao": "Ligar para o lead",
                                                "previsaoDeInicio": "2021-07-01T00:00:00-03:00",
                                                "previsaoDeTermino": "2021-07-01T00:00:00-03:00",
                                                "dataDeTermino": "2021-07-01T00:00:00-03:00",
                                                "dataCancelamento": null,
                                                "posicaoDoFunil": {
                                                    "id": 1,
                                                    "descricao": "Sem contato"
                                                }
                                                },
                                                {
                                                "prioridade": {
                                                    "id": 2,
                                                    "descricao": "Média"
                                                },
                                                "solicitante": null,
                                                "executor": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "descricao": "Contato com sucesso, cliente agendado para o dia 21/07/2021",
                                                "previsaoDeInicio": "2021-07-11T00:00:00-03:00",
                                                "previsaoDeTermino": "2021-07-11T00:00:00-03:00",
                                                "dataDeTermino": "2021-07-11T00:00:00-03:00",
                                                "dataCancelamento": null,
                                                "posicaoDoFunil": {
                                                    "id": 2,
                                                    "descricao": "Contato feito"
                                                }
                                                },
                                                {
                                                "prioridade": {
                                                    "id": 1,
                                                    "descricao": "Alta"
                                                },
                                                "solicitante": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "executor": {
                                                    "id": 3,
                                                    "cpf": null,
                                                    "nome": "Hugo Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "descricao": "Tentativa de contato sem sucesso",
                                                "previsaoDeInicio": "2021-07-16T00:00:00-03:00",
                                                "previsaoDeTermino": "2021-07-16T00:00:00-03:00",
                                                "dataDeTermino": null,
                                                "dataCancelamento": null,
                                                "posicaoDoFunil": {
                                                    "id": 3,
                                                    "descricao": "Identificação do interesse"
                                                }
                                                }
                                            ],
                                            anotacoes: [],
                                            listaEmails: []
                                    })
    
                                    setListaFasesDoFunil(getListaFasesDoFunil);
                                    setItemFunil("");
                                    handleDialogCreateFaseClose();
                                }
                                else {
                                    NotificacaoInterna.ExibirNotificacao("Nome da fase existente", "Preencha com um nome válido", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                }
                            }
                            else {

                                if (getListaFasesDoFunil.filter((item, index) => item.descricao == getItemFunil).length == 0)
                                {

                                    getListaFasesDoFunil.map((item, index) => {
                                        if (item.id == getItemFunilSelecionado?.id)
                                        {
                                            console.log('bbbb')
                                            item.descricao = getItemFunil
                                        }
                                    })

                                    setListaFasesDoFunil(getListaFasesDoFunil)
                                    setItemFunil("");
                                    setAlterarItemFunil(false)
                                    handleDialogCreateFaseClose();
                                }
                                else {
                                    NotificacaoInterna.ExibirNotificacao("Nome da fase existente", "Preencha com um nome válido", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                }
                            }
                        }}
                    />
                </div>

            </Dialog>

            {/** Dialog customizando component formulario start */}
            <Dialog
                style = {{width: '40%', backgroundColor: "#FFFFFF"}}
                icon="annotation"
                onClose={() => {
                    setDragStart(false)
                    handleDialogCustomComponentClose()
                }}
                title="Customizando componente"
                autoFocus = {true}
                isOpen = {getDialogCustomComponent.isOpen}
                canEscapeKeyClose = {getDialogCustomComponent.canEscapeKeyClose}
                canOutsideClickClose = {getDialogCustomComponent.canOutsideClickClose}
                enforceFocus = {getDialogCustomComponent.enforceFocus}
                usePortal = {getDialogCustomComponent.usePortal} 
            >
                <div style = {{display: "flex", alignItems: "center"}}>
                    <div style = {{marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRight: "0.5px solid #00000050", paddingBottom: "20px"}}>

                        <div style = {{fontWeight: "bold", fontSize: "16px", width: "95%"}}>{getSelectCustomComponent?.classificacao.descricao}</div>
                        
                        <div style = {{width: "95%", marginTop: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Titulo`}</div>
                            <Component.InputTask style = {{width: "100%"}} id="CustomLabel" placeholder={"Titulo do campo..."} value={getSelectCustomComponent?.titulo}
                                onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                    var TextoDigitado = event.currentTarget.value

                                    if (getSelectCustomComponent)
                                    {
                                        let selectCustomComponente = {...getSelectCustomComponent}
                                        selectCustomComponente.titulo = TextoDigitado
                                        setSelectCustomComponent(selectCustomComponente);
                                    }
                                }}>
                            </Component.InputTask>
                        </div>

                        <div style = {{display: "flex", flexDirection: "column", width: "95%", marginTop: "20px"}}>
                            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                                <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px", marginRight: "10px"}}>Este campo é obrigatório</div>
                                <Switch/>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div style = {{ width: "100%", paddingRight: "2.5%", paddingLeft: "2.5%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center", borderTop: "0.5px solid #00000050"}}>
                    <Button
                        style = {{
                            flex: 1,
                            background: "#1098F7", 
                            color: "#FFFFFF",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px",
                            height: "15px",
                            marginTop: "20px"
                        }}
                        text = {"Salvar"}
                        onClick = {() => {

                            if (getSelectCustomComponent && getSelectCustomComponent.titulo != "") {

                                getTiposDeComponentesFormulario.push(
                                    {
                                        id: getTiposDeComponentesFormulario.length,
                                        titulo: getSelectCustomComponent.titulo,
                                        classificacao: getSelectCustomComponent.classificacao,
                                        obrigatorio: getSelectCustomComponent.obrigatorio,
                                        resposta: getSelectCustomComponent.resposta
                                    }
                                );
    
                                setTiposDeComponenesFormulario(getTiposDeComponentesFormulario)
                                
                                setDragStart(false) 
                                handleDialogCustomComponentClose()
                            }
                            else {}
                        }}
                    />
                </div>

            </Dialog>

            {/* Collapse */}
            <Collapse isOpen={getCollapse}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "0", width: "100%", backgroundColor: "#EDEFF5" }}>
                    <div style={{ width: "65%", minWidth: "919px", display: "flex", alignItems: "center", justifyContent: "space-between", background: "0", marginLeft: "2%" }}>
                        <div className="endereco">
                            <img src={IconeHome} style={{cursor: "pointer"}} alt="" onClick={() => {History.goBack();}} />
                            <div className="bar">/</div>
                            <div className="place">Configurações</div>
                            <div className="bar">/</div>
                            <div className="place">Perfil</div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", background: "0", width: "90%", minWidth: "777px"}}>
                            <Divider style={{ width: "1px", height: "26px", backgroundColor: "#afafaf" }} />
                            <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => { await setItemPickerCentroDeCusto(Item); }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "12px", height: "15px", marginRight: '10px', marginLeft: "10px" }} text={getItemPickerCentroDeCusto?.descricao.substring(0, 10).concat("...") ?? "Empresa"} rightIcon="double-caret-vertical" />
                            </PickerCentroDeCusto>
                            {getItemPickerCentroDeCusto && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer", marginRight: '10px' }} iconSize={15} onClick={async () => { setItemPickerCentroDeCusto(undefined) }} />}
                        </div>
                    </div>
                    <div className="hoverize" style={{ width: "110px", minWidth: "110px", height: "30px", marginRight: "90px", color: "#175372", boxShadow: "0px 0px 0px 1px #d8d8d8", padding: "0 5px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer" }}
                        onClick={Buscar}>
                        <Icon color="#175372" style={{/*{ transform: getFilterClicked ? "rotate(0deg)" : "rotate(-360deg)", transition: getFilterClicked ? "transform 2s" : "" }*/ }} icon="search" />
                        <span style = {{fontSize: "12px"}}>Pesquisar</span>
                    </div>
                </div>
            </Collapse>

            {/* Abridor Colapse */}
            <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>

            {false && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.Funil,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={500}
                    width={600}
                    style={{ marginTop: "100px" }}
                />
                <div className="loading" style={{ margin: "0 auto 0 auto", fontWeight: "bold"}}>{(getCarregandoListaDeContratos == undefined) ? (getItemPickerCentroDeCusto == undefined ? "Por favor, selecione um empreendimento" : ("Por favor, clique no botão de busca para exibir as configuracoes do funil")) : ((getCarregandoListaDeContratos == false) ? "Aguarde, estamos carregando suas configurações de funil" : "Pronto, suas configurações logo serão exibidas!")}</div>
            </div>}

            {true && <Card style={{ backgroundColor: "0", width: "100%", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none" }}>
                <div style = {{height: "30px", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center", marginBottom: "5px"}}>
                    <div style = {{display: "flex", marginTop: "10px", justifyContent: "flex-start", alignItems: "flex-end", width: "100%", borderBottom: "0.5px solid #00000020", paddingRight: "2%", paddingLeft: "2%"}}>
                        <Button
                            style = {{
                                background: "0",
                                color: "#000000",
                                borderLeft: getOptionPerfil == true ? "0.5px dotted #00000050" : "none",
                                borderRight: getOptionPerfil == true ? "0.5px dotted #00000050" : "none",
                                borderTop: getOptionPerfil == true ? "0.5px dotted #00000050" : "none",
                                borderBottom: getOptionPerfil == true ? "3px solid #1098F7" : "0.5px solid #00000000",
                                marginBottom: getOptionPerfil == true ? "-2px" : "-0.5px",
                                fontWeight: getOptionPerfil == true ? "bolder" : "unset",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center",
                                fontSize: "12px", 
                                height: "15px",
                                paddingLeft: "20px",
                                paddingRight: "20px"
                            }}
                            text = {"Perfil"}
                            onClick = {() => {
                                setOptionPerfil(true)
                                setOptionPermissoes(false)
                            }}
                        />
                        <Button
                            style = {{
                                background: "0",
                                color: "#000000",
                                borderLeft: getOptionPermissoes == true ? "0.5px dotted #00000050" : "none",
                                borderRight: getOptionPermissoes == true ? "0.5px dotted #00000050" : "none",
                                borderTop: getOptionPermissoes == true ? "0.5px dotted #00000050" : "none",
                                borderBottom: getOptionPermissoes == true ? "3px solid #1098F7" : "0.5px solid #00000000",
                                marginBottom: getOptionPermissoes == true ? "-2px" : "-0.5px",
                                fontWeight: getOptionPermissoes == true ? "bolder" : "unset",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex",
                                alignItems: "center", 
                                fontSize: "12px", 
                                height: "15px",
                                paddingLeft: "20px",
                                paddingRight: "20px"
                            }}
                            text = {"Permissões"}
                            onClick = {() => {
                                setOptionPermissoes(true)
                                setOptionPerfil(false)
                            }}
                        />
                    </div>
                </div>

                {getOptionPerfil === true && <div style = {{width: "100%", opacity: getOptionPerfil == true ? 1 : 0, transition: "opacity 0.5s"}}>
                    <div style={{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll', justifyContent: "center"}}>
                        <div style = {{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "95%", paddingTop: "20px"}}>
                            <div style = {{marginBottom: "10px"}}>
                                <div style = {{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                    <div style = {{fontSize: "12px", marginBottom: "10px", fontWeight: "bold"}}>Foto do perfil</div>
                                    <Popover content={
                                        <Menu>
                                            <MenuItem text="Carregar uma imagem" onClick = {() => {
                                                document.getElementById("profileFile")?.click()
                                            }}/>
                                            <MenuItem text="Remover uma imagem"/>
                                            <FileInput id = "profileFile" style={{ width: "calc(99.5% - 200px)", minWidth: "300px", display: "none" }} inputProps={{ accept: "image/png, image/jpeg" }} disabled={false} buttonText="Abrir" text={"Anexe o documento do cliente"} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                if (event.currentTarget.files?.item(0)?.type.includes("jpeg") == true || event.currentTarget.files?.item(0)?.type.includes("png") == true) {
                                                    console.log('arquivo')
                                                }
                                                else {
                                                    NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                                }
                                            }} onClick={(e: any) => (e.target.value = null)} />
                                        </Menu>
                                    } position={Position.BOTTOM_RIGHT}>
                                        <img style = {{borderRadius: 60}} height = {60} width = {60} src = {Terry}/>
                                    </Popover>
                                </div>
                                {/** Abrindo collapse dados do pessoais */}
                                <div
                                    style = {{
                                        borderBottom: getCollapseDadosPessoais == true ? "0px" : '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: getCollapseDadosPessoais == true ? "0px" : "5px", 
                                        borderBottomRightRadius: getCollapseDadosPessoais == true ? "0px" : "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center", 
                                        width: "calc(27vw + 50px)",
                                        cursor: "pointer"
                                }}
                                    onClick = { async () => {  getCollapseDadosPessoais === true ? setCollapseDadosPesssoais(false) : setCollapseDadosPesssoais(true) }}>
                                    <div style={{height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapseDadosPessoais === true ? setCollapseDadosPesssoais(false) : setCollapseDadosPesssoais(true)}>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: LottieFiles.SwipeDown,
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={30}
                                            width={30}
                                            style={getCollapseDadosPessoais ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                                        />
                                    </div>
                                    <Button
                                        style = {{backgroundColor: "#FFFFFF", boxShadow: "none", border: "none", verticalAlign: "center", fontSize: "12px"}}
                                        text = {"Dados pessoais"}
                                        onClick = {() => getCollapseDadosPessoais === true ? setCollapseDadosPesssoais(false) : setCollapseDadosPesssoais(true)}
                                    />
                                </div>

                                {/** Collapse dados pessoais */}
                                <Collapse isOpen = {getCollapseDadosPessoais}>
                                    <Component.ContentCard style = {{ backgroundColor: "#FFFFFF"}}>
                                        <Component.ContentFormHeader style = {{padding: "0px", marginTop: "0px"}}>
                                            <div
                                                style = {{
                                                    borderLeft: '1px solid #00000020', 
                                                    borderRight: '1px solid #00000020', 
                                                    padding: "5px 10px",
                                                    width: "calc(27vw + 50px)"
                                            }}>
                                                <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                    <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "Nome" : "Razão social"}</div>
                                                    <Component.Input id="Nome" placeholder="Nome" value={getFormularioNome} style={{ width: "calc(27vw + 20px)", fontSize: "12px" }}
                                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                            var TextoDigitado = event.currentTarget.value;
                                                            setFormularioNome(TextoDigitado)
                                                        }}>
                                                    </Component.Input>
                                                </div>
                                                <div style = {{marginTop: "10px"}}>
                                                    <div style = {{display: 'flex', alignItems: 'center', marginBottom: "5px", fontSize: "12px"}}>
                                                        <div style = {{marginRight: "5px", fontWeight: 'bold'}}>Email</div>
                                                        <Icon iconSize = {15} icon='add' onClick = { async () => {
                                                            let ListaDeEmails = [...getFormularioEmails]
                                                            ListaDeEmails.push({
                                                                classificacao: 1,
                                                                descricao: "",
                                                            })
                                                            setFormularioEmails(ListaDeEmails)
                                                        }}/>
                                                    </div>
                                                    {getFormularioEmails.map((item, index) => (
                                                        <div style = {{display: 'flex', alignItems: 'center', marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                            <Component.Input id="Email" placeholder="Email" value={item.descricao} style={{ width: "calc(27vw + 20px)", fontSize: "12px"}}
                                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                                    var TextoDigitado = event.currentTarget.value;

                                                                    let ListaDeEmails = [...getFormularioEmails]
                                                                    ListaDeEmails.map((Item, Index) => {
                                                                        if (index == Index) {
                                                                            Item.descricao = TextoDigitado
                                                                        }
                                                                    })

                                                                    setFormularioEmails(ListaDeEmails)
                                                            }}>
                                                            </Component.Input>
                                                            {getFormularioEmails.length > 1 &&
                                                                <div style = {{marginLeft: '3px'}}>
                                                                    <Icon iconSize = {15} icon='remove' onClick = { async () => { setFormularioEmails(getFormularioEmails.filter((Item, Index) => Index !== index)) }}/>  
                                                            </div>}
                                                        </div>
                                                    ))}
                                                </div>
                                                <div style = {{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                                                    <div style = {{marginBottom: "10px", fontWeight: "bold", marginRight: "20px", fontSize: "12px"}}>
                                                        <div style = {{marginBottom: "5px", fontSize: "12px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "CPF" : "CNPJ"}</div>
                                                        <Component.Input id="CPF" style = {{fontSize: "12px", width: "150px"}} placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormularioCPF)}
                                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                                setFormularioCPF(CPF.DesformatarTexto(event.currentTarget.value))
                                                            }}>
                                                        </Component.Input>
                                                    </div>
                                                    <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                        <div style = {{marginBottom: "5px", fontSize: "12px"}}>Data de nascimento</div>
                                                        <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                                            placeholder={"DD/MM/AAAA"} onChange = { async (DataSelecionada: Date) => { setFormularioDataDeNascimento(DataSelecionada) }}
                                                            value = {new Date(String(getFormularioDataDeNascimento))} maxDate = {new Date()} 
                                                            inputProps = {{style: {fontSize: "12px", backgroundColor: lighten(0.1, '#D3D3D3')}}} 
                                                            minDate = {new Date((new Date().getFullYear() - 100).toString() + "-01-01")} 
                                                        >
                                                        </DateInput>
                                                    </div>
                                                </div>
                                            </div>
                                        </Component.ContentFormHeader>
                                        <Component.FormContent>
                                            <Component.ContentFormHeader>
                                                <Component.FormContent>
                                                    <div
                                                        style = {{
                                                            width: "100%",
                                                            alignItems: "center",
                                                            borderRadius: "2px",
                                                            marginTop: "-20px"
                                                    }}>
                                                        <div 
                                                            style = {{
                                                                borderLeft: '1px solid #00000020', 
                                                                borderRight: '1px solid #00000020', 
                                                                padding: "5px 10px",
                                                                width: "calc(27vw + 50px)"
                                                        }}>
                                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                <div style = {{display: 'flex', alignItems: "center", marginBottom: "5px"}}>
                                                                    <div style = {{marginRight: "5px", fontSize: "12px"}}>Telefone</div>
                                                                    <Icon 
                                                                        iconSize = {15} 
                                                                        icon = {'add'}
                                                                        onClick = {() => {
                                                                            let ListaDeTelefones = [...getTelefones]
                                                                            ListaDeTelefones.push({
                                                                                descricao: ""
                                                                            })
                                                                            setTelefones(ListaDeTelefones)
                                                                    }}/>
                                                                </div>
                                                                {getTelefones.map((item, index) => {
                                                                    return (
                                                                        <div style = {{marginBottom: "10px"}}>
                                                                            <Component.Input id="TelefoneComercial" style = {{fontSize: 12, width: "calc(27vw + 20px)"}} placeholder={Telefone.FormatarTexto("00000000000")} value={Telefone.FormatarTexto(item.descricao)}
                                                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                                                    
                                                                                    var TextoDigitado = event.currentTarget.value

                                                                                    let ListaDeTelefones = [...getTelefones]

                                                                                    ListaDeTelefones.map((Item, Index) => {
                                                                                        if (index == Index) {
                                                                                            Item.descricao = TextoDigitado
                                                                                        }
                                                                                    })

                                                                                    setTelefones(ListaDeTelefones)
                                                                                }}>
                                                                            </Component.Input>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        {CPF.DesformatarTexto(getFormularioCPF).length <= 11 &&
                                                            <div
                                                                style = {{
                                                                    display: 'flex', 
                                                                    alignItems: 'center', 
                                                                    borderLeft: '1px solid #00000020', 
                                                                    borderRight: '1px solid #00000020', 
                                                                    padding: "5px 10px", 
                                                                    width: "calc(27vw + 50px)"
                                                            }}>
                                                                <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px", marginRight: "10px"}}>
                                                                    <div style = {{marginBottom: "5px"}}>Profissão</div>
                                                                    <Component.Input style={{ width: "calc(21.7vw + -120px)", fontSize: 12 }} id="profissao" size={30} placeholder="Informe a profissão" value={getFormularioProfissao}
                                                                        onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                                                            var TextoDigitado = event.currentTarget.value;
                                                                            setFormularioProfissao(TextoDigitado)
                                                                        }}>
                                                                    </Component.Input>
                                                                </div>
                                                                <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                    <div style = {{marginBottom: "5px"}}>Nacionalidade</div>
                                                                    {getListaPickerNacionalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                                                    <PickerNacionalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNacionalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                                        itemRenderer={(Item: Objeto.Nacao) => <MenuItem onClick={async () => {setFormulacaoNacionalidade(Item)}} text={Item.nacionalidade ?? ""} />}
                                                                        itemPredicate={(Texto: string, Item: Objeto.Nacao) => { return (Item.nacionalidade ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                                        <Button text={getFormularioNacionalidade?.nacionalidade ?? "Selecione a nacionalidade"} style = {{fontSize: 12}} rightIcon="double-caret-vertical" />
                                                                    </PickerNacionalidade>
                                                                    {getFormularioNacionalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {await setFormulacaoNacionalidade(undefined)}} />}
                                                                </div>
                                                        </div>}
                                                    </div>
                                                </Component.FormContent>
                                                <FormGroup labelFor = "EstadoCivil" style = {{ backgroundColor: "0" }}>
                                                    <div 
                                                        style = {{
                                                            borderLeft: '1px solid #00000020',
                                                            borderRight: '1px solid #00000020',
                                                            padding: "5px 10px", 
                                                            width: "calc(27vw + 50px)"
                                                    }}>
                                                        <div style = {{fontWeight: "bold", marginBottom: "10px", marginTop: "10px", fontSize: "12px"}}>Estado civil</div>
                                                        <div style = {{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                                                            {getFormularioListaEstadoCivil?.map((Item) =>
                                                                <Radio 
                                                                    style = {{ 
                                                                        display: "flex", 
                                                                        alignItems: "center", 
                                                                        fontSize: "12px" 
                                                                    }} 
                                                                    name = "EstadoCivil" 
                                                                    label = {Item.descricao} 
                                                                    value = {Item.id}
                                                                    onChange = { async () => setFormularioEstadoCivil(Item)} 
                                                                    checked = {Item == getFormularioEstadoCivil}>
                                                                </Radio>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        style = {{
                                                            borderLeft: '1px solid #00000020',
                                                            borderRight: '1px solid #00000020',
                                                            borderBottom: '1px solid #00000020', 
                                                            width: "calc(27vw + 50px)", 
                                                            marginBottom: "20px", 
                                                            display: "flex", 
                                                            flexDirection: "column", 
                                                            alignItems: "flex-end",
                                                            borderBottomLeftRadius: "5px", 
                                                            borderBottomRightRadius: "5px", 
                                                    }}>
                                                        <Button
                                                            icon = {<Icon icon = "refresh" iconSize = {14} color = {"#FFFFFF"}/>} 
                                                            style = {{
                                                                background: "#1098F7", 
                                                                color: "#FFFFFF", 
                                                                border: "none", 
                                                                boxShadow: "none", 
                                                                borderRadius: "5px", 
                                                                outline: "none", 
                                                                display: "flex", 
                                                                alignItems: "center", 
                                                                fontSize: "12px",
                                                                marginRight: '10px',
                                                                marginBottom: "10px"
                                                            }}  
                                                            onClick = { async () => {}}>Atualizar</Button>
                                                    </div>
                                                </FormGroup>
                                            </Component.ContentFormHeader>
                                        </Component.FormContent>
                                    </Component.ContentCard>
                                </Collapse>

                                {/** Abrindo collapse dados do endereço */}
                                <div
                                    style = {{
                                        borderBottom: getCollapseDadosEndereco == true ? "0px" : '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: getCollapseDadosEndereco == true ? "0px" : "5px", 
                                        borderBottomRightRadius: getCollapseDadosEndereco == true ? "0px" : "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px", 
                                        backgroundColor: "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center", 
                                        width: "calc(27vw + 50px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = {() => getCollapseDadosEndereco === true ? setCollapseDadosEndereco(false) : setCollapseDadosEndereco(true) }>
                                    <div style={{height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapseDadosEndereco === true ? setCollapseDadosEndereco(false) : setCollapseDadosEndereco(true)}>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: LottieFiles.SwipeDown,
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={30}
                                            width={30}
                                            style={getCollapseDadosEndereco ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                                        />
                                    </div>
                                    <Button
                                        style = {{backgroundColor: "#FFFFFF", boxShadow: "none", border: "none", verticalAlign: "center", fontSize: "12px"}}
                                        text = {"Dados complementares"}
                                        onClick = {() => getCollapseDadosEndereco === true ? setCollapseDadosEndereco(false) : setCollapseDadosEndereco(true) }
                                    />
                                </div>
                                
                                {/** Collapse dados do endereço */}
                                <Collapse isOpen = {getCollapseDadosEndereco}>
                                    <Component.ContentCard style = {{backgroundColor: "#FFFFFF"}}>
                                        <Component.ContentFormHeader
                                            style = {{
                                                borderBottom: '1px solid #00000020',
                                                borderLeft: '1px solid #00000020',
                                                borderRight: '1px solid #00000020',
                                                padding: "5px 10px",
                                                borderBottomLeftRadius: "5px",
                                                borderBottomRightRadius: "5px", 
                                                width: "calc(27vw + 50px)",
                                                marginTop: 0
                                        }}>
                                            <div
                                                style = {{
                                                    marginBottom: "10px", fontWeight: "bold", fontSize: "12px"
                                            }}>
                                                <div style = {{marginBottom: "5px"}}>CEP</div>
                                                <Component.Input id="CEP" placeholder="Informe o CEP" value={CEP.FormatarTexto(getFormularioCEP)} style={{ width: "calc(27vw + 0px)", fontSize: "12px" }}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                        var CEPDesformatado = CEP.DesformatarTexto(event.currentTarget.value);
                                                        setFormularioCEP(CEPDesformatado);
                                                    }}>
                                                </Component.Input>
                                            </div>
                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px", marginRight: "20px"}}>
                                                    <div style = {{marginBottom: "5px"}}>Logradouro</div>
                                                    <Component.Input id="Logradouro:" placeholder="Informe o logradouro" value={getFormularioLogradouro} style={{ width: "calc(15vw + 0px)", cursor: "auto", fontSize: "12px" }}
                                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                            setFormularioLogradouro(event.currentTarget.value);
                                                        }}>
                                                    </Component.Input>
                                                </div>
                                                <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                    <div style = {{marginBottom: "5px"}}>Número</div>
                                                    <Component.Input id="NumeroDeEndereco" maxLength={10} placeholder={Numero.FormatarTextoParaInteiro(0)} value={getFormularioNumeroDeEndereco} style={{ width: "calc(10vw + 10px)", marginLeft: "0", cursor: "auto", fontSize: "12px" }}
                                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                            setFormularioNumeroDeEndereco(event.currentTarget.value);
                                                        }}>
                                                    </Component.Input>
                                                </div>
                                            </div>
                                            <div style = {{ marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                <div style = {{marginBottom: "5px"}}>Complemento</div>
                                                <Component.Input id="Complemento" placeholder="Informe o complemento" value={getFormularioComplemento} style={{ width: "calc(27vw + 0px)", cursor: "auto", fontSize: "12px" }}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                        setFormularioComplemento(event.currentTarget.value);
                                                    }}>
                                                </Component.Input>
                                            </div>
                                            <div style = {{ marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                <div style = {{marginBottom: "5px"}}>Bairro</div>
                                                <Component.Input id="Bairro" placeholder="Informe o bairro" value={getFormularioBairro} style={{ width: "calc(27vw + 0px)", cursor: "auto", fontSize: "12px" }}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                        setFormularioBairro(event.currentTarget.value);
                                                    }}>
                                                </Component.Input>
                                            </div>
                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                    <div style = {{marginBottom: "5px"}}>Cidade</div>
                                                    <Component.Input id="Cidade" placeholder="Informe o cidade" value={getFormularioCidade} style={{ width: "calc(15.5vw + 0px)", marginRight: "0.6vw", cursor: "auto", fontSize: "12px" }}
                                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                            setFormularioCidade(event.currentTarget.value)
                                                        }}>
                                                    </Component.Input>
                                                </div>
                                                <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                    <div style = {{marginBottom: "5px"}}>UF</div>
                                                    <HTMLSelect style={{ fontSize: "12px" }} id="uf" name="uf" value={getFormularioUF}
                                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                            setFormularioUF(event.currentTarget.value)
                                                        }}>
                                                        <option selected value="">Selecione a UF</option>
                                                        {UF.map((Item) =>
                                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                                        )}
                                                    </HTMLSelect>
                                                    {getFormularioUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {setFormularioUF("");}} />}
                                                </div>
                                            </div>
                                            <div 
                                                style = {{ 
                                                    width: "calc(27vw + 50px)", 
                                                    display: "flex", 
                                                    flexDirection: "column", 
                                                    alignItems: "flex-end",
                                                    marginTop: "10px"
                                            }}>
                                                <Button
                                                    icon = {<Icon icon = "refresh" iconSize = {14} color = {"#FFFFFF"}/>} 
                                                    style = {{
                                                        background: "#1098F7", 
                                                        color: "#FFFFFF", 
                                                        border: "none", 
                                                        boxShadow: "none", 
                                                        borderRadius: "5px", 
                                                        outline: "none", 
                                                        display: "flex", 
                                                        alignItems: "center", 
                                                        fontSize: "12px",
                                                        marginRight: '20px',
                                                        marginBottom: "5px"
                                                    }}  
                                                    onClick = { async () => {
                                                }}>Atualizar</Button>
                                            </div>
                                        </Component.ContentFormHeader>
                                    </Component.ContentCard>
                                </Collapse>

                            </div>
                        </div>
                    </div>
                </div>}

                {getOptionPermissoes === true && <div style = {{width: "100%", opacity: getOptionPermissoes == true ? 1 : 0, transition: "opacity 0.5s"}}>
                    <div style={{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                        <div style = {{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "40px"}}>
                        <div className='control-pane'>
                            <div className='control-section'>
                            <TreeGrid.TreeGridComponent 
                                dataSource={countries} 
                                childMapping='states' 
                                height={'100%'} 
                                allowReordering={true}
                                allowFiltering={true} 
                                allowSorting={true} 
                                filterSettings={{ type:'Menu', hierarchyMode:'Parent'}}>
                                    <TreeGrid.ColumnsDirective>
                                    <TreeGrid.ColumnDirective field='name' headerText='Province' width='190' />
                                    <TreeGrid.ColumnDirective field='population' headerText='Populationf (Million)' allowFiltering={false} textAlign='Right' width='200'/>
                                    <TreeGrid.ColumnDirective field='gdp' headerText='GDP Rate %' width='145'/>
                                    <TreeGrid.ColumnDirective field='rating' headerText='Credit Rating' width='190' />
                                    <TreeGrid.ColumnDirective field='unemployment' headerText='Unemployment Rate' width='200' allowFiltering={false}/>
                                    <TreeGrid.ColumnDirective field='coordinates' headerText='Coordinates' allowSorting={false} width='220' />
                                    <TreeGrid.ColumnDirective field='area' headerText='Area' width='140' />
                                    <TreeGrid.ColumnDirective field='timezone' headerText='Time Zone' width='150' />
                                </TreeGrid.ColumnsDirective>
                                <TreeGrid.Inject services={[TreeGrid.Filter, TreeGrid.Sort, TreeGrid.Reorder]} />
                            </TreeGrid.TreeGridComponent>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>}

            </Card>}

        </Component.Container>
    );
    /* #endregion */
};

export default View;