import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class Empresa {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Consulta os CNAEs definidos pelo IBGE
     **/
    public async CNAEsIBGE(Token: string): Promise<AxiosResponse<any>> {
        return await Context.get(`/Empresa/CNAEsIBGE/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Retorna as empresas por usuário
     **/
    public async Get(Token: string, MinhasEmpresas: boolean, Empresa: number | string): Promise<AxiosResponse<Array<Objeto.Empresa>>> {
        return await Context.get(`/Empresa/${Token}?MinhasEmpresas=${MinhasEmpresas}&GrupoDeEmpresa=${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}&Empresa=${Empresa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Consulta os grupos de empresa
     **/
    public async GruposDeEmpresas(Token: string, MeusGrupos: boolean): Promise<AxiosResponse<Array<{id: number, descricao: string, empresas: Array<Objeto.Empresa>}>>> {
        return await Context.get(`/Empresa/GruposDeEmpresas/NzAyNjEyMzExNDYkYzI5ekpHNWxkRE15?MeusGrupos=${MeusGrupos}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Retorna as empresas por grupos
     **/
    public async GruposDeEmpresasUsuario(Token: string, MeusGrupos: boolean): Promise<AxiosResponse<Array<{id: number, descricao: string, empresas: Array<Objeto.Empresa>}>>> {
        return await Context.get(`/Empresa/GruposDeEmpresas/${Token}?MeusGrupos=${MeusGrupos}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Autoriza a pessoa, atrelada ao usuário, o acesso a uma determinada empresa
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} Pessoa Informe o código da pessoa (Id). Esta pessoa deverá estar cadastrada como um usuário do sistema!
     **/
    public async AutorizarPermissaoDeAcesso(Token: string, GrupoDeEmpresas: number, Empresa: number, Pessoa: number): Promise<AxiosResponse<any>> {
        return await Context.post(`/Empresa/AutorizarPermissaoDeAcesso/${Token}/${GrupoDeEmpresas}/${Empresa}/${Pessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Cadastra uma nova empresa
     **/
    public async Post(Token: string, NomeFantasia: string, RazaoSocial: string, CNPJ: string): Promise<AxiosResponse<Objeto.Empresa>> {
        return await Context.post(`/Empresa/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${NomeFantasia}/${RazaoSocial}/${CNPJ}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Altera os dados da empresa
     **/
    public async Put(Token: string, NomeFantasia: string, RazaoSocial: string, CNPJ: string): Promise<AxiosResponse<Objeto.Empresa>> {
        return await Context.put(`/Empresa/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${NomeFantasia}/${RazaoSocial}/${CNPJ}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar os dados da empresa
    **/
    public async Delete(Token: string, Empresa: number): Promise<AxiosResponse<Objeto.Empresa>> {
        return await Context.delete(`/Empresa/${Token}/${Empresa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Remover a pessoa, atrelada ao usuário, o acesso a uma determinada empresa.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {number} Pessoa Informe o código da pessoa (Id). Esta pessoa deverá estar cadastrada como um usuário do sistema!
     **/
    public async RemoverPermissaoDeAcesso(Token: string, Empresa: number, Pessoa: number): Promise<AxiosResponse<any>> {
        return await Context.delete(`/Empresa/RemoverPermissaoDeAcesso/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Empresa}/${Pessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new Empresa();