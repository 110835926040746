import React, { memo } from 'react';
import { Polygon, Popup } from 'react-leaflet-rotate/react-leaflet';

function App({ areas }: any) {

    return areas.polygons.features.map((area: any, quadraIndex: any) => {
        const positions: any = [];
        area.geometry.geometries.map((array: any) => positions.push([array.coordinates[0][1], array.coordinates[0][0]]))
        return (
          <Polygon
            opacity={1}
            fillOpacity={0.5}
            fillColor={area.color}
            weight={1}
            color={area.color}
            positions={positions}
            eventHandlers={{
              click: () => { }
            }}>
            <Popup minWidth={100}>
              <div style={{ display: "flex", pointerEvents: "none", flexDirection: "row", justifyContent: "center", width: "100%", background: "0", height: "20px", fontWeight: "bold" }}>
                <span>{area.name}</span>
              </div>
            </Popup>
          </Polygon>
        )
      })
}

export default memo(App);