import { AxiosResponse } from 'axios';
import axios from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class ControleDeSala {

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Retorna as acoes que poderão ser executadas em cada etapa do fluxo de sala.
     **/
    public async Acoes(Token: string): Promise<AxiosResponse<Array<{id: number, descricao: string, fluxoDeSala?: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}> | null, salasDeVenda?: Array<{ id: number, descricao: string }> | null}>>> {
        return await Context.get(`/ControleDeSala/Acao/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Retorna os controles de sala
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} SalaDeVenda Informe o Id da sala de venda
     * @param {boolean} MeuControleDeSala Informe se deve ser retornado apenas os controles de sala que o usuário tem permissão.
     **/
    public async Get(Token: string, SalaDeVenda: number | string, MeuControleDeSala: boolean): Promise<AxiosResponse<Array<{id: number, descricao: string, fluxoDeSala: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}>, salasDeVenda: Array<{ id: number, descricao: string }>}>>> {
        return await Context.get(`/ControleDeSala/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?SalaDeVenda=${SalaDeVenda}&MeuControleDeSala=${MeuControleDeSala}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastrar um novo controle de sala
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} ControleDeSala Insira um objeto com todos os dados do controle de sala"
     **/
    public async Post(Token: string, ControleDeSala: {id: number, descricao: string, fluxoDeSala: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}>, salasDeVenda: Array<{ id: number, descricao: string }>}): Promise<AxiosResponse<{ id: number, descricao: string, fluxoDeSala: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}>, salasDeVenda: Array<{ id: number, descricao: string }>}>> {
        return await Context.post(`/ControleDeSala/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, ControleDeSala)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o controle de sala 
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} ControleDeSala Insira um objeto com todos os dados do controle de sala"
     **/
    public async Delete(Token: string, ControleDeSala: {id: number, descricao: string, fluxoDeSala: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}>, salasDeVenda: Array<{ id: number, descricao: string }>}): Promise<any> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/ControleDeSala/' + Token + "/" + ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado,
            data: ControleDeSala,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar os dados do controle de sala
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} ControleDeSala Insira um objeto com todos os dados do controle de sala"
     **/
    public async Put(Token: string, ControleDeSala: { id: number, descricao: string, fluxoDeSala: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}>, salasDeVenda: Array<{ id: number, descricao: string }>}): Promise<AxiosResponse<{ id: number, descricao: string, fluxoDeSala: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}>, salasDeVenda: Array<{ id: number, descricao: string }>}>> {
        return await Context.put(`/ControleDeSala/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, ControleDeSala)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Retorna as informações da pessoa tenha permissão de acesso ao controle de sala
     * @param {number} IdControleDeSala Informe o código do controle de sala (id).
     **/
    public async Equipe(Token: string, IdControleDeSala: number): Promise<AxiosResponse<Array<any>>> {
        return await Context.get(`/ControleDeSala/PermissaoDeAcesso/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${IdControleDeSala}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Autorizar a pessoa informada, o acesso ao controle de sala.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} IdControleDeSala Insira o id do controle de sala"
     * @param {object} IdSalaDeVenda Insira o id da sala de venda"
     * @param {object} IdPessoa Insira o id da pessoa"
     **/
    public async AutorizarPermissaoDeAcesso(Token: string, IdSalaDeVenda: number, IdControleDeSala: number, IdPessoa: number): Promise<AxiosResponse<any>> {
        return await Context.post(`/ControleDeSala/AutorizarPermissaoDeAcesso/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${IdSalaDeVenda}/${IdControleDeSala}/${IdPessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Desautorizar a pessoa informada, o acesso ao controle de sala.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} IdControleDeSala Insira o id do controle de sala"
     * @param {object} IdSalaDeVenda Insira o id da sala de venda"
     * @param {object} IdPessoa Insira o id da pessoa"
     **/
    public async RemoverPermissaoDeAcesso(Token: string, IdSalaDeVenda: number, IdControleDeSala: number, IdPessoa: number): Promise<AxiosResponse<any>> {
        return await Context.delete(`/ControleDeSala/RemoverPermissaoDeAcesso/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${IdSalaDeVenda}/${IdControleDeSala}/${IdPessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */


}

export default new ControleDeSala();