import Banco from './Banco/index.json';
import Bandeira from './Bandeira/index.json';
import Contratos from './Contratos/index.json';
import MaquinaDeCartao from './MaquinaDeCartao/index.json';
import MeioDePagamento from './MeioDePagamento/index.json';
import Localization from '../Localization/index.json';
import NFSe from './NFSe/index.json';
import Operacao from './Operacao/index.json';
import TiposComponentes from './TiposComponentes/index.json';
import UF from './UF/index.json';

export {
    Banco,
    Bandeira,
    Contratos,
    Localization,
    MaquinaDeCartao,
    MeioDePagamento,
    NFSe,
    Operacao,
    TiposComponentes,
    UF,
}