import moment from 'moment';
import React, { memo, useEffect, useState, useRef } from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import Lottie from 'react-lottie';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import io from "socket.io-client";
import { Button, Drawer, Card, FormGroup, Icon, MenuItem, Switch, Menu, Collapse, Position, Popover } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select, MultiSelect } from "@blueprintjs/select";
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Group,
    Inject,
    GroupSettingsModel,
    Aggregate,
    Toolbar,
    ToolbarItems,
    PdfExport,
    Grid,
    Filter,
    FilterSettingsModel,
    Reorder,
    Column,
    Sort,
    SortSettingsModel,
    PdfHeaderQueryCellInfoEventArgs,
    Resize
} from "@syncfusion/ej2-react-grids";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { IconeHome } from '../../../../Assets';
import { SignIn as ContextSignIn, SignIn, WidthCheck as ContextWidthCheck } from '../../../../Context';
import { Localization } from '../../../../Data/Listas';
import { CentroDeCusto, Identificador } from '../../../../Services/Controllers';
import api from '../../../../Services/node';
import { Moeda } from '../../../../Themes/TextFormat';
import { Objeto } from '../../../../Services/Models';
import { NotificacaoInterna } from '../../../../Services/Notification';
import * as LottieFiles from '../../../../Data/Lottie';
import VendaDireta from '../../VendaDireta';

import './App.css';
import Map from '../leaflet';
import { image } from './image';
import * as Component from '../style';
import './style.css';
import viewModel from './viewModel';
import viewModelObjetoPropostaDeVenda from '../../VendaDireta/viewModel';
import context from 'react-bootstrap/esm/AccordionContext';

loadCldr(
    require('../../../../../node_modules/cldr-data/main/pt/currencies.json'),
    require('../../../../../node_modules/cldr-data/main/pt/numbers.json'),
    require('../../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
    require('../../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
    require('../../../../../node_modules/cldr-data/supplemental/numberingSystems.json')
);
setCulture('pt');
setCurrencyCode("BRL");
L10n.load(Localization);
var socket: any;

interface Store {
    empreendimento: string;
    unidade: string;
    valorUnidade: number;
    torre: string;
    apto: string;
    pavimento: string;
    qtdQuartos: number;
    qtdSemanas: number;
    cota: string;
    observacao: string;
    vista: string;
    frente: string;
    frenteConf: string;
    fundo: string;
    fundoConf: string;
    esquerda: string;
    esquerdaConf: string;
    direita: string;
    direitaConf: string;
    id: number;
    status: number;
    unidades: Objeto.Identificador;
    sigla: string;
    lista: Array<Objeto.Identificador>;
    store: Array<Store>;
    categorias: Array<any>;
};
interface StoreSintetico {
    empreendimento: string;
    unidade: string;
    valorUnidade: number;
    torre: string;
    apto: string;
    pavimento: string;
    qtdQuartos: number;
    qtdSemanas: number;
    cota: string;
    observacao: string;
    vista: string;
    frente: string;
    frenteConf: string;
    fundo: string;
    fundoConf: string;
    esquerda: string;
    esquerdaConf: string;
    direita: string;
    direitaConf: string;
    id: number;
    status: number;
    unidades: Objeto.IdentificadorSintetico;
    sigla: string;
    lista: Array<Objeto.IdentificadorSintetico>;
    store: Array<StoreSintetico>;
    categorias: Array<any>;
};

function App({ listaunidades, ItemEmpreendimento }: { listaunidades: Array<Objeto.IdentificadorSintetico>, ItemEmpreendimento: Objeto.CentroDeCusto | undefined }) {
    let dataStorage: Array<StoreSintetico>;
    let dataStorageCategorias: any;

    const [getExibirMapa, setExibirMapa] = useStateWithCallbackLazy<boolean>(false);
    const [getWidth, setWidth] = useState<number>();

    /* #region  Variáveis da tabela de disponibilidade */
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCustoDisponibilidade, setListaPickerCentroDeCustoDisponibilidade] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const [getListaDeUnidades, setListaDeUnidades] = useState<Array<Objeto.Identificador>>([]);
    const [getListaDeUnidadesSintetico, setListaDeUnidadesSintetico] = useState<Array<Objeto.IdentificadorSintetico>>([]);
    const [getListaDeDisponibilidade, setListaDeDisponibilidade] = useState<Array<Store>>([]);
    const [getListaDeDisponibilidadeSintetico, setListaDeDisponibilidadeSintetico] = useState<Array<StoreSintetico>>([]);
    const [getListaDeCategorias, setListaDeCategorias] = useState<Array<any>>([]);
    const timelineLoaded = useRef<boolean>(false);
    const [getCarregandoListaDeUnidades, setCarregandoListaDeUnidades] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    /* #endregion */

    /* #region  Variáveis do mapa */
    const [getListaPickerCentroDeCustoMapa, setListaPickerCentroDeCustoMapa] = useState<Array<Objeto.CentroDeCusto>>([]);
    const [getObjetoPropostaDeVenda, setObjetoPropostaDeVenda] = useState<viewModelObjetoPropostaDeVenda | undefined>();
    const [getEmpresaObra, setEmpresaObra] = useState<any>([]);
    const [getRefreshData, setRefreshData] = useState<any>();
    const [getMapRefreshed, setMapRefreshed] = useState<any>(true);
    const [getListaDeMarcacoes, setListaDeMarcacoes] = useState<any>();
    const [getTiposInadimplencia, setTiposInadimplencia] = useState<any>();
    const [getListaPontosCentrais, setListaPontosCentrais] = useState<any>();
    const [getListaAreas, setListaAreas] = useState<any>();
    const [getVisaoMapa, setVisaoMapa] = useState<number>(0);
    const [getCarregandoListaDeMarcacoes, setCarregandoListaDeMarcacoes] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getCollapse, setCollapse] = useState(false);
    const [getFullscreen, setFullscreen] = useState(false);
    const [getDrawerTelaDeVendaDireta, setDrawerTelaDeVendaDireta] = useState<{
        autoFocus: boolean;
        canEscapeKeyClose: boolean;
        canOutsideClickClose: boolean;
        enforceFocus: boolean;
        isOpen: boolean;
        usePortal: boolean;
    }>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const handle = useFullScreenHandle();
    var Cordenadas: Array<number>;
    const toolbarOptions: ToolbarItems[] = ['PdfExport']
    const groupOptions: GroupSettingsModel = {
        showGroupedColumn: true,
        showUngroupButton: true,
    }
    const filterOptions: FilterSettingsModel = {
        ignoreAccent: true,
        immediateModeDelay: 200,
        mode: "Immediate",
        type: 'Excel',
    }
    const sortOptions: SortSettingsModel = {}
    const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs | any) => {
        (args.cell as any).row.pdfGrid.repeatHeader = true;
        args.cell.gridRow.pdfGrid.columns.getColumn(0).width = 100;
        args.cell.gridRow.pdfGrid.columns.getColumn(0).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(1).width = 100;
        args.cell.gridRow.pdfGrid.columns.getColumn(1).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(2).width = 60;
        args.cell.gridRow.pdfGrid.columns.getColumn(2).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(3).width = 40;
        args.cell.gridRow.pdfGrid.columns.getColumn(3).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(4).width = 80;
        args.cell.gridRow.pdfGrid.columns.getColumn(4).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(5).width = 50;
        args.cell.gridRow.pdfGrid.columns.getColumn(5).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(6).width = 50;
        args.cell.gridRow.pdfGrid.columns.getColumn(6).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(7).width = 90;
        args.cell.gridRow.pdfGrid.columns.getColumn(7).textAlign = "Left";
    }
    let grid: Grid | null;
    const toolbarClick = (args: any) => {
        if (grid) {
            if (args.item.id === 'grid_pdfexport') {
                (grid.columns[9] as Column).visible = false;
                grid.pdfExport({
                    fileName: `Disponibilidade ${moment(new Date(), true).format("YYYY-MM-DD")} - ${((ItemEmpreendimento?.empresa.id == 20) ? "PRE" : ((ItemEmpreendimento?.empresa.id == 21) ? "SPR" : ((ItemEmpreendimento?.empresa.id == 22) ? "SER" : ((ItemEmpreendimento?.empresa.id == 23) ? "PAR" : (ItemEmpreendimento?.empresa.id == 26 ? "PYR" : ItemEmpreendimento?.sigla)))))}.pdf`,
                    pageOrientation: "Landscape",
                    header: {
                        fromTop: 0,
                        height: 100,
                        contents: [
                            {
                                type: 'Text',
                                value: `${ItemEmpreendimento?.descricao}`,
                                position: { x: 390, y: 50 },
                                style: { textBrushColor: "#000000", fontSize: 20, hAlign: "Right" },
                            },
                            {
                                position: { x: 0, y: 45 },
                                size: { height: 40, width: 80 },
                                src: image,
                                type: 'Image',
                            },
                            {
                                type: 'Text',
                                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:MM:SS")}`,
                                position: { x: 880, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 13 },
                            },
                        ],
                    },
                    footer: {
                        contents: [
                            {
                                type: 'Text',
                                value: `Powered by DigitalDEV`,
                                position: { x: 0, y: 0 },
                                style: { textBrushColor: "#000000", fontSize: 10 },
                            },
                            {
                                format: 'Página {$current} de {$total}',
                                pageNumberType: "Numeric",
                                position: { x: 910, y: 0 },
                                style: {
                                    fontSize: 13,
                                    hAlign: "Right",
                                    textBrushColor: '#000000',
                                },
                                type: 'PageNumber',
                            }
                        ],
                        fromBottom: 0,
                        height: 20,
                    },
                    theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                        },
                        record: {
                            bold: false,
                            fontColor: "#000000",
                            fontSize: 8,
                        },
                        caption: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                        }
                    },
                    allowHorizontalOverflow: false,
                });
            }
        }
    }
    const pdfExportComplete = () => {
        if (grid) {
            (grid.columns[9] as Column).visible = true;
        }
    }
    const templateBotao = (props: any) => (
        <>
            {props.categorias.length > 0 && props.unidades.status == 0 && <Popover content={
                <Menu>
                    <MenuItem icon="list" text="Categorias...">
                        {props.categorias.map((item: any, index: number) => (
                            <>
                                <MenuItem icon="symbol-circle" text={`${item.Descricao}`}
                                    onClick={async () => {
                                        if (props.unidades?.status === 0) {
                                            if (!props.unidades._ReservandoUnidade) {
                                                let ListaDeContratosExibidos = [...props.lista];
                                                props.unidades._ReservandoUnidade = true;
                                                await setListaDeUnidades(ListaDeContratosExibidos);
                                                NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                            }
                                            else {
                                                return;
                                            }
                                            let Response = await Identificador.ReservarUnidadesComTimer(ContextSignIn.getContext().token, item.Tempo, [props.unidades]);
                                            let ListaDeContratosExibidos = [...props.lista];
                                            props.unidades._ReservandoUnidade = ((Math.floor(Response.status / 100) == 2) ? false : undefined);
                                            props.unidades.status = ((Math.floor(Response.status / 100) == 2) ? 2 : 0);
                                            await setListaDeUnidades(ListaDeContratosExibidos);
                                            if (Math.floor(Response.status / 100) == 2) {
                                                sendUnidade(props.unidades, props.sigla, props.lista, props.store);
                                                NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            }
                                            else {
                                                NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            }
                                        }
                                        else if (props.unidades?.status === 2) {
                                            if (!props.unidades._DeletandoReserva) {
                                                let ListaDeContratosExibidos = [...props.lista];
                                                props.unidades._DeletandoReserva = true;
                                                await setListaDeUnidades(ListaDeContratosExibidos);
                                                NotificacaoInterna.ExibirNotificacao("Disponibilizando unidade", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                            }
                                            else {
                                                return;
                                            }
                                            let Response = await Identificador.DeletarReservas(ContextSignIn.getContext().token, [props.unidades]);
                                            let ListaDeContratosExibidos = [...props.lista];
                                            props.unidades._DeletandoReserva = ((Math.floor(Response.status / 100) == 2) ? false : undefined);
                                            props.unidades.status = ((Math.floor(Response.status / 100) == 2) ? 0 : 2);
                                            await setListaDeUnidades(ListaDeContratosExibidos);

                                            if (Math.floor(Response.status / 100) == 2) {
                                                sendUnidade(props.unidades, props.sigla, props.lista, props.store);
                                                NotificacaoInterna.ExibirNotificacao("Unidade disponibilizada com sucesso", "Sua unidade já se encontra disponível em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            }
                                            else {
                                                NotificacaoInterna.ExibirNotificacao("Falha ao diponibilizar a unidade", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            }
                                        }
                                    }} />
                            </>
                        ))}
                    </MenuItem>
                </Menu>
            } position={Position.BOTTOM_RIGHT}>
                <Button
                    disabled={props.unidades.status === 3 ? true : false}
                    intent={props.unidades.status === 2 ? 'success' : 'danger'}
                    style={{ height: "5px", width: "8vw", borderRadius: '5px', cursor: "pointer", outline: "none", display: "flex", padding: "0" }}
                ><Icon iconSize={(document.documentElement.clientWidth / 100) - 4} style={{ marginBottom: "4px" }} icon={(props.unidades.status === 2 || props.unidades.status === 3) ? "unlock" : "lock"} />
                    <strong style={{ fontSize: "0.8vw" }}>{props.unidades.status === 2 ? ' Disponibilizar' : (props.unidades.status === 3 ? 'Reservado' : ' Reservar')}</strong>
                </Button>
            </Popover>}
            {(props.categorias.length == 0 || (props.categorias.length > 0 && (props.unidades.status === 3 || props.unidades.status === 2))) && <Button
                disabled={props.unidades.status === 3 ? true : false}
                intent={props.unidades.status === 2 ? 'success' : 'danger'}
                style={{ height: "5px", width: "8vw", borderRadius: '5px', cursor: "pointer", outline: "none", display: "flex", padding: "0" }}
                onClick={async () => {
                    if (props.unidades?.status === 0) {
                        if (!props.unidades._ReservandoUnidade) {
                            let ListaDeContratosExibidos = [...props.lista];
                            props.unidades._ReservandoUnidade = true;
                            await setListaDeUnidadesSintetico(ListaDeContratosExibidos);
                            NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);
                        }
                        else {
                            return;
                        }

                        let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, props.unidades.empresaId ?? 0, props.unidades.centroDeCustoSigla ?? "", props.unidades.localId, props.unidades.subLocalId)

                        if (Math.floor(ResponseUnidade.status / 100) == 2) {
                            let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);
                            let ListaDeContratosExibidos = [...props.lista];
                            props.unidades._ReservandoUnidade = ((Math.floor(Response.status / 100) == 2) ? false : undefined);
                            props.unidades.status = ((Math.floor(Response.status / 100) == 2) ? 2 : 0);
                            await setListaDeUnidadesSintetico(ListaDeContratosExibidos);
                            if (Math.floor(Response.status / 100) == 2) {
                                sendUnidadeSintetico(props.unidades, props.sigla, props.lista, props.store);
                                NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }
                    }
                    else if (props.unidades?.status === 2) {
                        if (!props.unidades._DeletandoReserva) {
                            let ListaDeContratosExibidos = [...props.lista];
                            props.unidades._DeletandoReserva = true;
                            await setListaDeUnidadesSintetico(ListaDeContratosExibidos);
                            NotificacaoInterna.ExibirNotificacao("Disponibilizando unidade", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);
                        }
                        else {
                            return;
                        }

                        let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, props.unidades.empresaId ?? 0, props.unidades.centroDeCustoSigla ?? "", props.unidades.localId, props.unidades.subLocalId)

                        if (Math.floor(ResponseUnidade.status / 100) == 2) {
                            let Response = await Identificador.DeletarReservas(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);
                            let ListaDeContratosExibidos = [...props.lista];
                            props.unidades._DeletandoReserva = ((Math.floor(Response.status / 100) == 2) ? false : undefined);
                            props.unidades.status = ((Math.floor(Response.status / 100) == 2) ? 0 : 2);
                            await setListaDeUnidadesSintetico(ListaDeContratosExibidos);

                            if (Math.floor(Response.status / 100) == 2) {
                                sendUnidadeSintetico(props.unidades, props.sigla, props.lista, props.store);
                                NotificacaoInterna.ExibirNotificacao("Unidade disponibilizada com sucesso", "Sua unidade já se encontra disponível em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Falha ao diponibilizar a unidade", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }
                    }
                }}><Icon iconSize={(document.documentElement.clientWidth / 100) - 4} style={{ marginBottom: "4px" }} icon={(props.unidades.status === 2 || props.unidades.status === 3) ? "unlock" : "lock"} />
                <strong style={{ fontSize: "0.8vw" }}>{props.unidades.status === 2 ? ' Disponibilizar' : (props.unidades.status === 3 ? 'Reservado' : ' Reservar')}</strong>
            </Button>}
        </>
    );
    /* #endregion */
    /* #endregion */

    window.onresize = function () {
        ContextWidthCheck.setContext({ width: document.body.clientWidth })
        setWidth(document.body.clientWidth)
    }
    function WidthCheck() {
        if (window.location.pathname == "/Disponibilidade") {
            if (window.matchMedia("(min-width: 1024px && max-width: 2560px)").matches == true) {
                return { display: "flex", alignItems: "flex-end", flexWrap: "wrap", backgroundColor: "0", width: "97%", margin: "20px auto", justifyContent: "space-between" } as React.CSSProperties
            } else if (window.matchMedia("(max-width: 1024px)").matches == true) {
                return { display: "flex", alignItems: "flex-end", flexWrap: "wrap", backgroundColor: "0", width: "97%", margin: "20px auto", justifyContent: "center" } as React.CSSProperties
            }
        }
    }
    useEffect(() => {
        WidthCheck()
    }, [document.body.clientWidth]);

    useEffect(() => {
        async function componentDidMount() {
            socket = io(String(process.env.REACT_APP_CONNECTION_PORT), { transports: ['websocket', 'polling', 'flashsocket'] });
        }
        componentDidMount();
    }, [process.env.REACT_APP_CONNECTION_PORT]);
    useEffect(() => {
        if (!timelineLoaded.current) {
            socket.on("receive_unidade", (data: any) => {
                let IndexUnidades = getListaDeUnidadesSintetico.findIndex((contrato) => contrato.subLocalId == data.unidade.subLocalId);
                let IndexDisponibilidade: any = getListaDeDisponibilidadeSintetico.findIndex((contrato) => contrato.unidades.subLocalId == data.unidade.subLocalId);

                if (IndexUnidades >= 0) {
                    let ListaDeUnidades = [...getListaDeUnidadesSintetico];
                    let ListaDeDisponibilidade = [...getListaDeDisponibilidadeSintetico];

                    ListaDeUnidades[IndexUnidades] = data.unidade;
                    ListaDeDisponibilidade[IndexDisponibilidade].unidades.status = data.status;

                    timelineLoaded.current = true;
                    setListaDeUnidadesSintetico(ListaDeUnidades);
                    timelineLoaded.current = false;

                    socket.off('receive_unidade');
                    socket.on('receive_unidade', (data: any) => {
                        let IndexUnidades = getListaDeUnidadesSintetico.findIndex((contrato) => contrato.subLocalId == data.unidade.subLocalId);
                        let IndexDisponibilidade: any = getListaDeDisponibilidadeSintetico.findIndex((contrato) => contrato.unidades.subLocalId == data.unidade.subLocalId);

                        if (IndexUnidades >= 0) {
                            let ListaDeUnidades = [...getListaDeUnidadesSintetico];
                            let ListaDeDisponibilidade = [...getListaDeDisponibilidadeSintetico];

                            ListaDeUnidades[IndexUnidades] = data.unidade;
                            ListaDeDisponibilidade[IndexDisponibilidade].unidades.status = data.status;

                            timelineLoaded.current = true;
                            setListaDeUnidadesSintetico(ListaDeUnidades);
                            timelineLoaded.current = false;
                        }
                    });
                }
            })
        }
    }, [getListaDeUnidadesSintetico])

    const connectToRoom = (room: string | undefined) => {
        socket.emit('join_room', room)
        console.log(`Conectado a sala: ${room}`)
    }
    const sendUnidade = async (unidade: Objeto.Identificador, sigla: string, lista: Array<Objeto.Identificador>, store: Array<Store>) => {
        let UnidadeContent = {
            room: sigla,
            content: {
                id: unidade.indice,
                unidade: unidade,
                status: unidade.status === 2 ? 3 : unidade.status,
            },
        }
        await socket.emit("send_unidade", UnidadeContent);
        socket.off('receive_unidade');
        socket.on('receive_unidade', (data: any) => {
            let Index = (lista).findIndex((contrato: any) => contrato.subLocal.id == data.unidade.subLocal.id);
            let IndexStore: any = (store).findIndex((contrato) => contrato.unidades.subLocal.id == data.unidade.subLocal.id);
            let IndexStoreExibido: any = (store).findIndex((contrato) => contrato.unidades.subLocal.id == data.unidade.subLocal.id);

            if (Index >= 0) {
                let ListaDeContratosExibidos = [...lista];
                let UnidadeStore = [...store];
                let UnidadeStoreExibidos = [...store];

                ListaDeContratosExibidos[Index] = data.unidade;
                UnidadeStore[IndexStore].unidades.status = data.status;
                UnidadeStoreExibidos[IndexStoreExibido].unidades.status = data.status;

                timelineLoaded.current = true;
                setListaDeUnidades(ListaDeContratosExibidos);
                timelineLoaded.current = false;
            }
        });
    }
    const sendUnidadeSintetico = async (unidade: Objeto.IdentificadorSintetico, sigla: string, lista: Array<Objeto.IdentificadorSintetico>, store: Array<StoreSintetico>) => {
        let UnidadeContent = {
            room: sigla,
            content: {
                id: 0,
                unidade: unidade,
                status: unidade.status === 2 ? 3 : unidade.status,
            },
        }
        await socket.emit("send_unidade", UnidadeContent);
        socket.off('receive_unidade');
        socket.on('receive_unidade', (data: any) => {
            let Index = (lista).findIndex((contrato: any) => contrato.subLocalId == data.unidade.subLocalId);
            let IndexStore: any = (store).findIndex((contrato) => contrato.unidades.subLocalId == data.unidade.subLocalId);
            let IndexStoreExibido: any = (store).findIndex((contrato) => contrato.unidades.subLocalId == data.unidade.subLocalId);

            if (Index >= 0) {
                let ListaDeContratosExibidos = [...lista];
                let UnidadeStore = [...store];
                let UnidadeStoreExibidos = [...store];

                ListaDeContratosExibidos[Index] = data.unidade;
                UnidadeStore[IndexStore].unidades.status = data.status;
                UnidadeStoreExibidos[IndexStoreExibido].unidades.status = data.status;

                timelineLoaded.current = true;
                setListaDeUnidadesSintetico(ListaDeContratosExibidos);
                timelineLoaded.current = false;
            }
        });
    }

    /* Sobre esta função *//**
     * Calcula o cenário a ser exibido na legenda
     * @param {string} status Informe o status da unidade"
     * @param {string} NivelDeVisualizacao Informe o tipo da operação a ser realizada (1: área, 2: Qtd, 3: valor)"
     **/
    /* Sobre esta função *//**
       * Calcula o cenário a ser exibido na legenda
       * @param {number} longitude Informe a longitude do marcador"
       * @param {number} latitude Informe a latitude do marcador"
       **/
    function CalcularCoordenadasDoMarcador(longitude: number, latitude: number) {
        let origem: Array<number> = [38.231544, -122.400049];
        let escalaM: number = 24 / 2;
        let contaLong: number = -1 * ((longitude - origem[0]) * (111.11 * 1000)) / escalaM;
        let contaLat: number = ((latitude - origem[1]) * (111.11 * 1000)) / escalaM;
        Cordenadas = [contaLat, contaLong];
    }
    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
       **/

    useEffect(() => {
        if (listaunidades) {
            connectToRoom(ItemEmpreendimento?.sigla ?? "")
            dataStorage = [];
            listaunidades.filter((Item) => Item.status == 0 || (Item.status == 2 && Item.reservaVinculadaVendedorId == ContextSignIn.getContext().pessoa.id)).map((contrato, Index) => {
                contrato.observacoes.map((obs, obsIndex) => {
                    obs.substring(obs.indexOf(":"))
                })
                dataStorage.push(
                    {
                        empreendimento: `${contrato.centroDeCustoDescricao}`,
                        unidade: `${contrato.subLocalDescricao}`,
                        torre: `${(contrato.observacoes.find((item) => item.includes("Torre: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Torre: ")) ?? "").indexOf(": ") + 2)}`,
                        apto: ((contrato.observacoes.find((item) => item.includes("Apartamento: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Apartamento: ")) ?? "").indexOf(": ") + 2)),
                        pavimento: `${(contrato.observacoes.find((item) => item.includes("Pavimento: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Pavimento: ")) ?? "").indexOf(": ") + 2)}`,
                        qtdQuartos: parseInt((contrato.observacoes.find((item) => item.includes("Qtde. de quartos: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Qtde. de quartos: ")) ?? "").indexOf(": ") + 2)),
                        qtdSemanas: parseInt((contrato.observacoes.find((item) => item.includes("Qtde. de semanas: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Qtde. de semanas: ")) ?? "").indexOf(": ") + 2)),
                        valorUnidade: contrato.valorAVista,
                        cota: `${(contrato.observacoes.find((item) => item.includes("Cota: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Cota: ")) ?? "").indexOf(": ") + 2)}`,
                        observacao: `${(contrato.observacoes.find((item) => item.includes("Observação: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Observação: ")) ?? "").indexOf(": ") + 2)}`,
                        vista: `${(contrato.observacoes.find((item) => item.includes("Vista: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Vista: ")) ?? "").indexOf(": ") + 2)}`,
                        frente: `${(contrato.observacoes.find((item) => item.includes("Frente: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Frente: ")) ?? "").indexOf(": ") + 2)}`,
                        frenteConf: `${(contrato.observacoes.find((item) => item.includes("Conf. Frente   : ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Conf. Frente   : ")) ?? "").indexOf(": ") + 2)}`,
                        fundo: `${(contrato.observacoes.find((item) => item.includes("Fundo: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Fundo: ")) ?? "").indexOf(": ") + 2)}`,
                        fundoConf: `${(contrato.observacoes.find((item) => item.includes("Conf. Fundo: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Conf. Fundo: ")) ?? "").indexOf(": ") + 2)}`,
                        esquerda: `${(contrato.observacoes.find((item) => item.includes("L. Esquerdo: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("L. Esquerdo: ")) ?? "").indexOf(": ") + 2)}`,
                        esquerdaConf: `${(contrato.observacoes.find((item) => item.includes("Conf. Esquerdo: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Conf. Esquerdo: ")) ?? "").indexOf(": ") + 2)}`,
                        direita: `${(contrato.observacoes.find((item) => item.includes("L. Direito: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("L. Direito: ")) ?? "").indexOf(": ") + 2)}`,
                        direitaConf: `${(contrato.observacoes.find((item) => item.includes("Conf. Direito: ")) ?? "").substring((contrato.observacoes.find((item) => item.includes("Conf. Direito: ")) ?? "").indexOf(": ") + 2)}`,
                        id: 0,
                        status: contrato.status,
                        unidades: contrato,
                        sigla: ItemEmpreendimento ? ItemEmpreendimento.sigla : "",
                        lista: listaunidades.filter((Item) => Item.status == 0 || (Item.status == 2 && Item.reservaVinculadaVendedorId == ContextSignIn.getContext().pessoa.id)),
                        store: [],
                        categorias: [],
                    }
                )
            });
            console.log(1)
            console.log(dataStorage);

            dataStorage.map((item, index) => {
                item.store = dataStorage
            })
            setListaDeDisponibilidadeSintetico((dataStorage));
            setListaDeUnidadesSintetico(listaunidades.filter((Item) => Item.status == 0 || (Item.status == 2 && Item.reservaVinculadaVendedorId == ContextSignIn.getContext().pessoa.id)));
            setCarregandoListaDeUnidades(true, () => { });
        }
        else {

        }
    }, [listaunidades])

    return (
        <div style={{ maxWidth: "100%" }}>
            {getObjetoPropostaDeVenda && <Drawer
                style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                icon={"people"}
                onClose={() => {
                    setDrawerTelaDeVendaDireta({
                        autoFocus: true,
                        canEscapeKeyClose: true,
                        canOutsideClickClose: true,
                        enforceFocus: true,
                        isOpen: false,
                        usePortal: true
                    });
                }}
                title={"Proposta de venda"}
                autoFocus={true}
                isOpen={getDrawerTelaDeVendaDireta.isOpen}
                canEscapeKeyClose={getDrawerTelaDeVendaDireta.canEscapeKeyClose}
                canOutsideClickClose={getDrawerTelaDeVendaDireta.canOutsideClickClose}
                enforceFocus={getDrawerTelaDeVendaDireta.enforceFocus}
                usePortal={getDrawerTelaDeVendaDireta.usePortal}
            >
                <div style={{ display: "flex", width: "100%", height: "100%" }}>
                    <VendaDireta {...getObjetoPropostaDeVenda}></VendaDireta>
                </div>

            </Drawer>}
            {listaunidades && <GridComponent
                id='grid'
                dataSource={getListaDeDisponibilidadeSintetico}
                ref={g => grid = g}
                allowResizing={true}
                allowPaging={true}
                allowGrouping={true}
                allowPdfExport={true}
                allowFiltering={true}
                allowReordering={true}
                allowSorting={false}
                sortSettings={sortOptions}
                toolbar={toolbarOptions}
                pdfHeaderQueryCellInfo={pdfHeaderQueryCellInfo}
                toolbarClick={toolbarClick}
                groupSettings={groupOptions}
                filterSettings={filterOptions}
                actionBegin={
                    (args: any) => {
                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                            args.filterChoiceCount = 100000;
                        }
                    }
                }
                pdfExportComplete={pdfExportComplete}
                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                locale={"pt"}
                currencyCode={"BRL"}
            >
                {(SignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 || SignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <ColumnsDirective>
                    <ColumnDirective field='unidade' headerText='Unidade' textAlign='Left' isPrimaryKey={true} />
                    <ColumnDirective field='torre' headerText='Torre' textAlign='Left' />
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='pavimento' headerText='Pavimento' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='apto' headerText='Apto' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='valorUnidade' headerText='Valor unidade' textAlign='Left' format='C2' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='qtdQuartos' headerText='Quartos' textAlign='Left' />}
                    <ColumnDirective field='qtdSemanas' headerText='Semanas' textAlign='Left' />
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='vista' headerText='Vista' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='observacao' headerText='Descrição' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective headerText='' textAlign='Center' template={templateBotao} />}
                </ColumnsDirective>}
                {SignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 && <ColumnsDirective>
                    <ColumnDirective field='unidade' headerText='Unidade' textAlign='Left' isPrimaryKey={true} />
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='frente' headerText='frente' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='frenteConf' headerText='Conf.' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 &&  <ColumnDirective field='fundo' headerText='fundo' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='fundoConf' headerText='Conf.' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='esquerda' headerText='esquerda' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='esquerdaConf' headerText='Conf.' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='direita' headerText='direita' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective field='direitaConf' headerText='Conf.' textAlign='Left' />}
                    {ContextSignIn.getContext().pessoa.id != 18856 && <ColumnDirective headerText='' textAlign='Center' template={templateBotao} />}
                </ColumnsDirective>}
                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, Resize]} />
            </GridComponent>}
        </div>
    );
}

export default memo(App);