import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

class Pessoa {
    /* #region  GET Controller  */
    /* Localiza a pessoa através do CPF *//**
     * Lista os estados civis existentes
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async EstadoCivil(Token: string): Promise<AxiosResponse<Array<{id: number, descricao: string}>>> {
        return await Context.get(`/Pessoa/EstadoCivil/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Lista os estados civis existentes
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}
     * @param {string} CPF Informe o CPF (somente números)
     **/
    public async Get(Token: string, CPF: string): Promise<AxiosResponse<Objeto.Pessoa>> {
        return await Context.get(`/Pessoa/${Token}/${CPF}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* Sobre esta função *//**
     * Lista os estados civis existentes
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}
     * @param {string} IdPessoa Informe o id da pessoa
     * @param {string} CPF Informe o CPF (somente números)
     **/
        public async BuscaPersonalizada(Token: string, IdPessoa: number, CPF: string): Promise<AxiosResponse<Objeto.Pessoa>> {
            return await Context.get(`/Pessoa/${Token}?Id=${IdPessoa}&CPF=${CPF}`)
                .then((Response) => { return Response; })
                .catch((Exception) => { return Exception; });
        };
        /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Lista os estados civis existentes
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async Post(Token: string, Pessoa: Objeto.Pessoa): Promise<AxiosResponse<Objeto.Pessoa>> {
        return await Context.post(`/Pessoa/${Token}`, Pessoa)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

        /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Alterar os dados de uma pessoa
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async Put(Token: string, Pessoa: Objeto.Pessoa): Promise<AxiosResponse<Objeto.Pessoa>> {
        return await Context.put(`/Pessoa/${Token}`, Pessoa)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  DELETE Controller  */
    /* #endregion */

    /* #region  PUT Controller  */
    /* #endregion */
};

export default new Pessoa();