import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class SoftwareExterno {
    /* #region GET Controller  */
    /* Sobre esta função *//**
     * Lista os centros de custos vinculados
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async CentroDeCustoVinculado(Token: string, Empresa: number | string, CentroDeCusto: string, TokenSoftwareExterno: string, EmpresaVinculada: string, CentroDeCustoVinculado: string): Promise<AxiosResponse<Array<{centroDeCusto: Objeto.CentroDeCusto, centroDeCustoVinculado: {empresaVinculada: string, centroDeCustoVinculado: string}, empresa: Objeto.Empresa, softwareExterno: {id: number, descricao: string, token: string, tokenAPI: string, urlapi: string}}>>> {
        return await Context.get(`/SoftwareExterno/CentroDeCustoVinculado/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}&TokenSoftwareExterno=${TokenSoftwareExterno}&EmpresaVinculada=${EmpresaVinculada}&CentroDeCustoVinculado=${CentroDeCustoVinculado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Consulta o código da pessoa no software externo.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async ConsultarPessoaVinculada(Token: string, Empresa: number | string, CentroDeCusto: string, TokenSoftwareExterno: string, EmpresaVinculada: string, CentroDeCustoVinculado: string): Promise<AxiosResponse<any>> {
        return await Context.get(`/SoftwareExterno/ConsultarPessoaVinculada/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}&TokenSoftwareExterno=${TokenSoftwareExterno}&EmpresaVinculada=${EmpresaVinculada}&CentroDeCustoVinculado=${CentroDeCustoVinculado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Lista os software externos cadastrados
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async Get(Token: string, Empresa: number | string, CentroDeCusto: string): Promise<AxiosResponse<any>> {
        return await Context.post(`/SoftwareExterno/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Lista os software ao qual a solução tem integração habilitada
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async SoftwaresIntegrados(Token: string): Promise<AxiosResponse<any>> {
        return await Context.get(`/SoftwareExterno/SoftwaresIntegrados/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Autoriza a pessoa o acesso ao software externo.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async AutorizarPermissaoDeAcesso(Token: string, GrupoDeEmpresas: number, Empresa: number, CentroDeCusto: string, TokenSoftwareExterno: string, Pessoa: number, PessoaVinculada: string): Promise<AxiosResponse<any>> {
        return await Context.post(`/SoftwareExterno/AutorizarPermissaoDeAcesso/${Token}/${GrupoDeEmpresas}/${Empresa}/${CentroDeCusto}/${TokenSoftwareExterno}/${Pessoa}/${PessoaVinculada}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Importar os identificadores presentes no software externo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async ImportarIdentificadoresVinculados(Token: string, Empresa: number, CentroDeCusto: string, TokenSoftwareExterno: string): Promise<AxiosResponse<any>> {
        return await Context.post(`/SoftwareExterno/ImportarIdentificadoresVinculados/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Empresa}/${CentroDeCusto}/${TokenSoftwareExterno}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Cadastrar um novo centro de custo vinculado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async NovoCentroDeCustoVinculado(Token: string, Empresa: number, CentroDeCusto: string, TokenSoftwareExterno: string, EmpresaVinculada: string, CentroDeCustoVinculado: string): Promise<AxiosResponse<any>> {
        return await Context.post(`/SoftwareExterno/NovoCentroDeCustoVinculado/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Empresa}/${CentroDeCusto}/${TokenSoftwareExterno}/${EmpresaVinculada}/${CentroDeCustoVinculado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Cadastrar um novo software externo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async NovoSoftwareExterno(Token: string, Empresa: number, CentroDeCusto: string, IP: string, Usuario: string, Senha: string, OnePlus: string, SoftwareExterno: number, URLAPI: string, TokenAPI: string): Promise<AxiosResponse<any>> {
        return await Context.post(`/SoftwareExterno/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Empresa}/${CentroDeCusto}/${IP}/${Usuario}/${Senha}/${OnePlus}/${SoftwareExterno}?URLAPI=${URLAPI}&TokenAPI=${TokenAPI}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Vincula o usuario local ao usuário no software externo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {string} TokenSoftwareExterno Informe o token do software externo (token). A lista das tokens cadastrados poderá ser obtida ao executar a requisição "/SoftwareExterno/BancoDeDados/{Token}"
     * @param {number} Pessoa Informe o código da pessoa (Id). Esta pessoa deverá estar cadastrada como um usuário do sistema!
     * @param {string} PessoaVinculada Informe o código da pessoa no software externo
     **/
    public async NovoUsuarioExterno(Token: string, Empresa: number, CentroDeCusto: string, TokenSoftwareExterno: string, Pessoa: number, PessoaVinculada: string): Promise<AxiosResponse<any>> {
        return await Context.post(`/SoftwareExterno/NovoUsuarioExterno/${Token}/${Empresa}/${CentroDeCusto}/${TokenSoftwareExterno}/${Pessoa}/${PessoaVinculada}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deleta o software externo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async Delete(Token: string, Empresa: number, CentroDeCusto: string, TokenSoftwareExterno: string): Promise<AxiosResponse<any>> {
        return await Context.delete(`/SoftwareExterno/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Empresa}/${CentroDeCusto}/${TokenSoftwareExterno}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Remover o centro de custo vinculado.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async RemoverCentroDeCustoVinculado(Token: string, Empresa: number, CentroDeCusto: string, TokenSoftwareExterno: string, EmpresaVinculada: string, CentroDeCustoVinculado: string): Promise<AxiosResponse<any>> {
        return await Context.delete(`/SoftwareExterno/RemoverCentroDeCustoVinculado/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Empresa}/${CentroDeCusto}/${TokenSoftwareExterno}/${EmpresaVinculada}/${CentroDeCustoVinculado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Desautoriza a pessoa o acesso ao software externo informado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async RemoverPermissaoDeAcesso(Token: string, Empresa: number, CentroDeCusto: string, TokenSoftwareExterno: string, Pessoa: number): Promise<AxiosResponse<any>> {
        return await Context.delete(`/SoftwareExterno/RemoverPermissaoDeAcesso/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Empresa}/${CentroDeCusto}/${TokenSoftwareExterno}/${Pessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new SoftwareExterno();