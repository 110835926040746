import React from 'react';

function App({ getExibirLegenda, setExibirLegenda, getLegendaOption, setLegendaOption, optionsToChoose, Icon }: any) {

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: getExibirLegenda ? "flex-start" : "center", width: "100%", background: "0", margin: "0 auto", height: "40px", borderBottom: getExibirLegenda ? "1px solid #C2C2C2" : "0px" }}>
            {getExibirLegenda && optionsToChoose.map((option: { id: number, name: string, width: number }) => (
                <div onClick={() => setLegendaOption(option.id)} style={{ cursor: "pointer", background: "0", paddingTop: "12px", width: `${option.width}px`, height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                    <span>{option.name}</span>
                    <div style={{ background: getLegendaOption === option.id ? "black" : "unset", width: "100%", height: "2px" }}></div>
                </div>
            ))}
            {!getExibirLegenda && <span style={{ marginLeft: "50px" }}>Opções</span>}
            <Icon style={{ marginLeft: "auto", marginRight: "10px", cursor: "pointer" }} onClick={() => { getExibirLegenda === false ? setExibirLegenda(true) : setExibirLegenda(false) }} icon="send-to-map" iconSize={20} />
        </div>
    );
}

export default App;