import axios from 'axios';
import moment from "moment";
import "moment/locale/pt-br"
import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import { pv, pmt } from 'financial';
import io from "socket.io-client";
import { Collapse } from 'react-collapse';
import Lottie from 'react-lottie';
import { Tooltip } from 'react-tippy';
import { toast as Toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, Classes, Card, FileInput, FormGroup, HTMLSelect, InputGroup, Icon, MenuItem, Radio, Switch, Menu, Collapse as CollapseBlueprint, Dialog } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select } from "@blueprintjs/select";

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import { Banco, Bandeira, MaquinaDeCartao, MeioDePagamento, Operacao, UF } from '../../../Data/Listas';
import * as LottieFiles from '../../../Data/Lottie';
import History from "../../../Routers";
import { CentroDeCusto, Correios, D4sign, Identificador, Lead, Nacao, Pessoa, Prospect, TabelaDeVenda, Venda } from '../../../Services/Controllers';
import { Objeto } from '../../../Services/Models';
import { NotificacaoInterna } from '../../../Services/Notification';
import { Data, CPF, CEP, CNPJ, Moeda, Numero, Telefone, Percentual } from '../../../Themes/TextFormat';

import * as Component from './style';
import './style.css';
import viewModel from './viewModel';
import PickerIdentificadorMemo from './Components';

var ItemPickerCentroDeCusto: Objeto.CentroDeCusto | undefined = undefined;

var ItemPickerIdentificador: Objeto.Identificador | undefined = undefined;
var ItemPickerIdentificadorSinteticao: Objeto.IdentificadorSintetico | undefined = undefined;

var socket: any;

var habilitarQuery: boolean = false;

interface Store {
    empreendimento: string;
    unidade: string;
    valorUnidade: number;
    torre: string;
    apto: number;
    pavimento: string;
    qtdQuartos: number;
    qtdSemanas: number;
    cota: string;
    observacao: string;
    vista: string;
    id: number;
    status: number;
    unidades: Objeto.Identificador;
    sigla: string;
    lista: Array<Objeto.Identificador>;
    store: Array<Store>;
    categorias: Array<any>;
};

const ViewQuery: React.FC<viewModel> = (viewModel) => {

    const { data, isLoading, isSuccess, isError, error } = useQuery(
        "/Venda",
        async () => {

            console.log('iniciando agendador na tela de venda', ItemPickerCentroDeCusto);

            let Response = (await Identificador.FastGetSintetico(ContextSignIn.getContext().token, true, ItemPickerCentroDeCusto?.empresa.id ?? 0, ItemPickerCentroDeCusto?.sigla ?? "")).data.filter((_Item) => _Item.status == 0 || (_Item.status == 2 && _Item.reservaVinculadaVendedorId == ContextSignIn.getContext().pessoa.id))

            return Response;
        },
        {
            onSuccess: async (data) => {
                console.log('finalizando agendador na tela de venda');
                let Unidades = [...data];
                let UnidadeFiltrada = Unidades.filter((lote: Objeto.IdentificadorSintetico, index: number) => (lote.subLocalId == getItemPickerIdentificadorSintetico?.subLocalId))
                if (UnidadeFiltrada.length == 0 && getItemPickerIdentificadorSintetico && getObjetoPropostaDeVenda == null) { }
                else if (UnidadeFiltrada.length == 0 && getItemPickerIdentificadorSintetico && (getObjetoPropostaDeVenda != null && (getObjetoPropostaDeVenda.identificador.subLocal.id != getItemPickerIdentificadorSintetico.subLocalId))) { }
                setListaPickerIdentificadorSintetico(data);
            },
            onError: async (error) => { },
            retry: true,
            refetchOnWindowFocus: true,
            refetchInterval: 20000,
            refetchIntervalInBackground: true,
        }
    );

    /* #region  Variáveis  */
    const PropriedadesDeCalendario = { months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"], weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'] };
    const RefPagina = useRef<HTMLDivElement>(null);
    const [GetTerminais, setTerminais] = useState<Array<{ id: number, descricao: string }>>([]);
    const [getFormularioAnexoCheckListDeCadastro, setFormularioAnexoCheckListDeCadastro] = useState<File>();
    const [getFormularioAnexoCliente, setFormularioAnexoCliente] = useState<File>();
    const [getFormularioNome, setFormularioNome] = useState<string>("");
    const [getFormularioEmail, setFormularioEmail] = useState<string>("");
    const [getCPFEmConsulta, setCPFEmConsulta] = useState<boolean>(false);
    const [getFormularioCPF, setFormularioCPF] = useState<string>("");
    const [getFormularioRG, setFormularioRG] = useState<string>("");
    const [getFormularioRGOrgaoEmissor, setFormularioRGOrgaoEmissor] = useState<string>("");
    const [getFormularioRGUF, setFormularioRGUF] = useState<string>("");
    const [getFormularioDataDeNascimento, setFormularioDataDeNascimento] = useState<Date | null | undefined>(undefined);
    const [getFormularioExibirCalendarioDataDeNascimento, setFormularioExibirCalendarioDataDeNascimento] = useState<boolean>(false);
    const [getItemPickerFormularioNacionalidade, setItemPickerFormularioNacionalidade] = useState<Objeto.Nacao | undefined>();
    const [getListaPickerNacionalidade, setListaPickerNacionalidade] = useState<Array<Objeto.Nacao>>([]);
    const PickerNacionalidade = Select.ofType<Objeto.Nacao>();

    const [getItemPickerFormularioNaturalidade, setItemPickerFormularioNaturalidade] = useState<{ id: number, cidade: string, uf: string } | undefined>();
    const [getListaPickerNaturalidade, setListaPickerNaturalidade] = useState<Array<{ id: number, cidade: string, uf: string }>>([]);
    const PickerNaturalidade = Select.ofType<{ id: number, cidade: string, uf: string }>();
    const [getFormularioPai, setFormularioPai] = useState<string>("");
    const [getFormularioMae, setFormularioMae] = useState<string>("");
    const [getFormularioCNH, setFormularioCNH] = useState<string>("");
    const [getFormularioCNHExpedicao, setFormularioCNHExpedicao] = useState<Date | null | undefined>(undefined);
    const [getFormularioExibirCalendarioCNHExpedicao, setFormularioExibirCalendarioCNHExpedicao] = useState<boolean>(false);
    const [getFormularioCNHUF, setFormularioCNHUF] = useState<string>("");

    const [getFormularioProfissao, setFormularioProfissao] = useState<string>("");
    const [getFormularioListaEstadoCivil, setFormularioListaEstadoCivil] = useState<Array<{ id: number, descricao: string }>>();
    const [getFormularioEstadoCivil, setFormularioEstadoCivil] = useState<{ id: number, descricao: string }>();
    const [getFormularioTelefoneCelular, setFormularioTelefoneCelular] = useState<string>("");
    const [getFormularioTelefoneComercial, setFormularioTelefoneComercial] = useState<string>("");
    const [getFormularioAnexoConjuge, setFormularioAnexoConjuge] = useState<File>();
    const [getFormularioConjugeNome, setFormularioConjugeNome] = useState<string>("");
    const [getFormularioConjugeEmail, setFormularioConjugeEmail] = useState<string>("");
    const [getFormularioConjugeCPF, setFormularioConjugeCPF] = useState<string>("");
    const [getFormularioConjugeRG, setFormularioConjugeRG] = useState<string>("");
    const [getFormularioConjugeRGOrgaoEmissor, setFormularioConjugeRGOrgaoEmissor] = useState<string>("");
    const [getFormularioConjugeRGUF, setFormularioConjugeRGUF] = useState<string>("");
    const [getFormularioConjugeDataDeNascimento, setFormularioConjugeDataDeNascimento] = useState<Date>();
    const [getFormularioExibirCalendarioConjugeDataDeNascimento, setFormularioExibirCalendarioConjugeDataDeNascimento] = useState<boolean>(false);
    const [getItemPickerFormularioConjugeNacionalidade, setItemPickerFormularioConjugeNacionalidade] = useState<Objeto.Nacao>();

    const [getItemPickerFormularioConjugeNaturalidade, setItemPickerFormularioConjugeNaturalidade] = useState<{ id: number, cidade: string, uf: string } | undefined>();
    const [getFormularioConjugePai, setFormularioConjugePai] = useState<string>("");
    const [getFormularioConjugeMae, setFormularioConjugeMae] = useState<string>("");
    const [getFormularioConjugeCNH, setFormularioConjugeCNH] = useState<string>("");
    const [getFormularioConjugeCNHExpedicao, setFormularioConjugeCNHExpedicao] = useState<Date | null | undefined>(undefined);
    const [getFormularioConjugeExibirCalendarioCNHExpedicao, setFormularioConjugeExibirCalendarioCNHExpedicao] = useState<boolean>(false);
    const [getFormularioConjugeCNHUF, setFormularioConjugeCNHUF] = useState<string>("");

    const [getFormularioConjugeProfissao, setFormularioConjugeProfissao] = useState<string>("");
    const [getFormularioConjugeTelefoneCelular, setFormularioConjugeTelefoneCelular] = useState<string>("");
    const [getFormularioConjugeTelefoneComercial, setFormularioConjugeTelefoneComercial] = useState<string>("");
    const [getFormularioExibir02Comprador, setFormularioExibir02Comprador] = useState<boolean>(false);
    const [getFormulario02CompradorAnexoCliente, setFormulario02CompradorAnexoCliente] = useState<File>();
    const [getFormulario02CompradorNome, setFormulario02CompradorNome] = useState<string>("");
    const [getFormulario02CompradorEmail, setFormulario02CompradorEmail] = useState<string>("");
    const [getFormulario02CompradorCPF, setFormulario02CompradorCPF] = useState<string>("");
    const [getFormulario02CompradorRG, setFormulario02CompradorRG] = useState<string>("");
    const [getFormulario02CompradorRGOrgaoEmissor, setFormulario02CompradorRGOrgaoEmissor] = useState<string>("");
    const [getFormulario02CompradorRGUF, setFormulario02CompradorRGUF] = useState<string>("");
    const [getFormulario02CompradorDataDeNascimento, setFormulario02CompradorDataDeNascimento] = useState<Date>();
    const [getFormulario02ExibirCalendarioCompradorDataDeNascimento, setFormulario02ExibirCalendarioCompradorDataDeNascimento] = useState<boolean>(false);
    const [getItemPickerFormulario02CompradorNacionalidade, setItemPickerFormulario02CompradorNacionalidade] = useState<Objeto.Nacao>();

    const [getItemPickerFormulario02CompradorNaturalidade, setItemPickerFormulario02CompradorNaturalidade] = useState<{ id: number, cidade: string, uf: string } | undefined>();
    const [getFormulario02CompradorPai, setFormulario02CompradorPai] = useState<string>("");
    const [getFormulario02CompradorMae, setFormulario02CompradorMae] = useState<string>("");
    const [getFormulario02CompradorCNH, setFormulario02CompradorCNH] = useState<string>("");
    const [getFormulario02CompradorCNHExpedicao, setFormulario02CompradorCNHExpedicao] = useState<Date | null | undefined>(undefined);
    const [getFormulario02CompradorExibirCalendarioCNHExpedicao, setFormulario02CompradorExibirCalendarioCNHExpedicao] = useState<boolean>(false);
    const [getFormulario02CompradorCNHUF, setFormulario02CompradorCNHUF] = useState<string>("");

    const [getFormulario02CompradorProfissao, setFormulario02CompradorProfissao] = useState<string>("");
    const [getFormulario02CompradorEstadoCivil, setFormulario02CompradorEstadoCivil] = useState<{ id: number, descricao: string }>();
    const [getFormulario02CompradorTelefoneCelular, setFormulario02CompradorTelefoneCelular] = useState<string>("");
    const [getFormulario02CompradorTelefoneComercial, setFormulario02CompradorTelefoneComercial] = useState<string>("");
    const [getFormulario02CompradorAnexoConjuge, setFormulario02CompradorAnexoConjuge] = useState<File>();
    const [getFormulario02CompradorConjugeNome, setFormulario02CompradorConjugeNome] = useState<string>("");
    const [getFormulario02CompradorConjugeEmail, setFormulario02CompradorConjugeEmail] = useState<string>("");
    const [getFormulario02CompradorConjugeCPF, setFormulario02CompradorConjugeCPF] = useState<string>("");
    const [getFormulario02CompradorConjugeRG, setFormulario02CompradorConjugeRG] = useState<string>("");
    const [getFormulario02CompradorConjugeRGOrgaoEmissor, setFormulario02CompradorConjugeRGOrgaoEmissor] = useState<string>("");
    const [getFormulario02CompradorConjugeRGUF, setFormulario02CompradorConjugeRGUF] = useState<string>("");
    const [getFormulario02CompradorConjugeDataDeNascimento, setFormulario02CompradorConjugeDataDeNascimento] = useState<Date>();
    const [getFormulario02ExibirCalendarioCompradorConjugeDataDeNascimento, setFormulario02ExibirCalendarioCompradorConjugeDataDeNascimento] = useState<boolean>(false);
    const [getItemPickerFormulario02CompradorConjugeNacionalidade, setItemPickerFormulario02CompradorConjugeNacionalidade] = useState<Objeto.Nacao>();

    const [getItemPickerFormulario02CompradorConjugeNaturalidade, setItemPickerFormulario02CompradorConjugeNaturalidade] = useState<{ id: number, cidade: string, uf: string } | undefined>();
    const [getFormulario02CompradorConjugePai, setFormulario02CompradorConjugePai] = useState<string>("");
    const [getFormulario02CompradorConjugeMae, setFormulario02CompradorConjugeMae] = useState<string>("");
    const [getFormulario02CompradorConjugeCNH, setFormulario02CompradorConjugeCNH] = useState<string>("");
    const [getFormulario02CompradorConjugeCNHExpedicao, setFormulario02CompradorConjugeCNHExpedicao] = useState<Date | null | undefined>(undefined);
    const [getFormulario02CompradorConjugeExibirCalendarioCNHExpedicao, setFormulario02CompradorConjugeExibirCalendarioCNHExpedicao] = useState<boolean>(false);
    const [getFormulario02CompradorConjugeCNHUF, setFormulario02CompradorConjugeCNHUF] = useState<string>("");

    const [getFormulario02CompradorConjugeProfissao, setFormulario02CompradorConjugeProfissao] = useState<string>("");
    const [getFormulario02CompradorConjugeTelefoneCelular, setFormulario02CompradorConjugeTelefoneCelular] = useState<string>("");
    const [getFormulario02CompradorConjugeTelefoneComercial, setFormulario02CompradorConjugeTelefoneComercial] = useState<string>("");
    const [getCEPEmConsulta, setCEPEmConsulta] = useState<boolean>(false);
    const [getFormularioCEP, setFormularioCEP] = useState<string>("");
    const [getFormularioAnexoEndereco, setFormularioAnexoEndereco] = useState<File>();
    const [getFormularioLogradouro, setFormularioLogradouro] = useState<string>("");
    const [getFormularioComplemento, setFormularioComplemento] = useState<string>("");
    const [getFormularioNumeroDeEndereco, setFormularioNumeroDeEndereco] = useState<string>("");
    const [getFormularioBairro, setFormularioBairro] = useState<string>("");
    const [getFormularioCidade, setFormularioCidade] = useState<string>("");
    const [getFormularioUF, setFormularioUF] = useState<string>("");
    const [getFormulario02CompradorCEP, setFormulario02CompradorCEP] = useState<string>("");
    const [getFormulario02CompradorAnexoEndereco, setFormulario02CompradorAnexoEndereco] = useState<File>();
    const [getFormulario02CompradorLogradouro, setFormulario02CompradorLogradouro] = useState<string>("");
    const [getFormulario02CompradorComplemento, setFormulario02CompradorComplemento] = useState<string>("");
    const [getFormulario02CompradorNumeroDeEndereco, setFormulario02CompradorNumeroDeEndereco] = useState<string>("");
    const [getFormulario02CompradorBairro, setFormulario02CompradorBairro] = useState<string>("");
    const [getFormulario02CompradorCidade, setFormulario02CompradorCidade] = useState<string>("");
    const [getFormulario02CompradorUF, setFormulario02CompradorUF] = useState<string>("");
    const [getFormularioLocalDeCaptacao, setFormularioLocalDeCaptacao] = useState<string>("SITE");
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const [getItemPickerIdentificador, setItemPickerIdentificador] = useState<Objeto.Identificador>();
    const [getListaPickerIdentificador, setListaPickerIdentificador] = useState<Array<Objeto.Identificador>>([]);
    const [getItemPickerIdentificadorSintetico, setItemPickerIdentificadorSintetico] = useState<Objeto.IdentificadorSintetico>();
    const [getListaPickerIdentificadorSintetico, setListaPickerIdentificadorSintetico] = useState<Array<Objeto.IdentificadorSintetico>>([]);
    const PickerIdentificador = Select.ofType<Objeto.IdentificadorSintetico>();
    const [getItemPickerCondicaoDaTabelaDeVenda, setItemPickerCondicaoDaTabelaDeVenda] = useState<Objeto.CondicaoDaTabelaDeVenda>();
    const [getListaPickerCondicaoDaTabelaDeVenda, setListaPickerCondicaoDaTabelaDeVenda] = useState<Array<Objeto.CondicaoDaTabelaDeVenda>>([]);
    const PickerCondicaoDaTabelaDeVenda = Select.ofType<Objeto.CondicaoDaTabelaDeVenda>();
    const [getListaCondicaoDaTabelaDeVenda, setListaCondicaoDaTabelaDeVenda] = useState<Array<Objeto.ClassificacaoDoTituloDaTabelaDeVenda>>([]);
    const [getItemPickerBanco, setItemPickerBanco] = useState<{ chave: string, Valor: string }>();
    const [getListaPickerBanco, setListaPickerBanco] = useState<Array<{ chave: string, Valor: string }>>(Banco);
    const PickerBanco = Select.ofType<{ chave: string, Valor: string }>();
    const PickerMaquina = Select.ofType<{ chave: string, Valor: string }>();
    const PickerTerminal = Select.ofType<{ id: number, descricao: string }>();
    const [getFormularioAnexoComprovantePgtoSinal, setFormularioAnexoComprovantePgtoSinal] = useState<File>();
    const [getFormularioSinalQtde, setFormularioSinalQtde] = useState<number>(0);
    const [getFormularioSinalPercentual, setFormularioSinalPercentual] = useState<number>(0);
    const [getFormularioListaDeSinais, setFormularioListaDeSinais] = useState<Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined, CodAutorizacao?: string | undefined }>>([]);
    const [getFormularioAnexoComprovantePgtoEntrada, setFormularioAnexoComprovantePgtoEntrada] = useState<File>();
    const [getFormularioEntradaQtde, setFormularioEntradaQtde] = useState<number>(0);
    const [getFormularioEntradaPercentual, setFormularioEntradaPercentual] = useState<number>(0);
    const [getFormularioListaDeEntradas, setFormularioListaDeEntradas] = useState<Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined, CodAutorizacao?: string | undefined }>>([]);
    const [getFormularioAnexoComprovantePgtoParcela, setFormularioAnexoComprovantePgtoParcela] = useState<File>();
    const [getFormularioIntermediariaQtde, setFormularioIntermediariaQtde] = useState<number>(0);
    const [getFormularioIntermediariaPercentual, setFormularioIntermediariaPercentual] = useState<number>(0);
    const [getFormularioIntermediariaValor, setFormularioIntermediariaValor] = useState<number>(0);
    const [getFormularioIntermediariaValorComJuros, setFormularioIntermediariaValorComJuros] = useState<number>(0);
    const [getFormularioIntermediariaVencimento, setFormularioIntermediariaVencimento] = useState<Date | null | undefined>();
    const [getFormularioIntermediariaExibirCalendarioVencimento, setFormularioIntermediariaExibirCalendarioVencimento] = useState<boolean>(false);
    const [getFormularioIntermediariaMeioDePagamento, setFormularioIntermediariaMeioDePagamento] = useState<string>("");
    const [getFormularioIntermediariaBanco, setFormularioIntermediariaBanco] = useState<{ chave: string, Valor: string } | undefined>();
    const [getFormularioIntermediariaAgencia, setFormularioIntermediariaAgencia] = useState<string>();
    const [getFormularioIntermediariaConta, setFormularioIntermediariaConta] = useState<string>();
    const [getFormularioIntermediariaDigitoDaConta, setFormularioIntermediariaDigitoDaConta] = useState<string>();
    const [getFormularioIntermediariaTitular, setFormularioIntermediariaTitular] = useState<string>();
    const [getFormularioIntermediariaNumeroCheque, setFormularioIntermediariaNumeroCheque] = useState<string>();
    const [getFormularioIntermediariaMaquina, setFormularioIntermediariaMaquina] = useState<string>();
    const [getFormularioIntermediariaDigitoCartao, setFormularioIntermediariaDigitoCartao] = useState<string>();
    const [getFormularioIntermediariaBandeira, setFormularioIntermediariaBandeira] = useState<string>();
    const [getFormularioIntermediariaOperacao, setFormularioIntermediariaOperacao] = useState<string>();
    const [getFormularioIntermediariaNSU, setFormularioIntermediariaNSU] = useState<string>();
    const [getFormularioIntermediariaNumeroDaOperacao, setFormularioIntermediariaNumeroDaOperacao] = useState<string>();
    const [getFormularioListaDeIntermediarias, setFormularioListaDeIntermediarias] = useState<Array<{ Quantidade: number, Valor: number, ValorPMT: number, ValorTotal: number, ValorTotalJuros: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>>([]);
    const [getHabilitarInclusaoDeIntermediarias, setHabilitarInclusaoDeIntermediarias] = useState<boolean>(false);
    const [getFormularioParcelaValorTotal, setFormularioParcelaValorTotal] = useState<number>(0);
    const [getFormularioParcelaValorComJuros, setFormularioParcelaValorComJuros] = useState<number>(0);
    const [getFormularioParcelaValorTotalJuros, setFormularioParcelaValorTotalJuros] = useState<number>(0);
    const [getFormularioParcelaValor, setFormularioParcelaValor] = useState<number>(0);
    const [getFormularioParcelaQtde, setFormularioParcelaQtde] = useState<number>(0);
    const [getFormularioParcelaPercentual, setFormularioParcelaPercentual] = useState<number>(0);
    const [getFormularioParcelaVencimento, setFormularioParcelaVencimento] = useState<Date | null | undefined>();
    const [getFormularioParcelaExibirCalendarioVencimento, setFormularioParcelaExibirCalendarioVencimento] = useState<boolean>(false);
    const [getFormularioParcelaMeioDePagamento, setFormularioParcelaMeioDePagamento] = useState<string>("");
    const [getFormularioParcelaBanco, setFormularioParcelaBanco] = useState<{ chave: string, Valor: string } | undefined>();
    const [getFormularioParcelaAgencia, setFormularioParcelaAgencia] = useState<string>();
    const [getFormularioParcelaConta, setFormularioParcelaConta] = useState<string>();
    const [getFormularioParcelaDigitoDaConta, setFormularioParcelaDigitoDaConta] = useState<string>();
    const [getFormularioParcelaTitular, setFormularioParcelaTitular] = useState<string>();
    const [getFormularioParcelaNumeroCheque, setFormularioParcelaNumeroCheque] = useState<string>();
    const [getFormularioParcelaMaquina, setFormularioParcelaMaquina] = useState<string>();
    const [getFormularioParcelaDigitoCartao, setFormularioParcelaDigitoCartao] = useState<string>();
    const [getFormularioParcelaBandeira, setFormularioParcelaBandeira] = useState<string>();
    const [getFormularioParcelaOperacao, setFormularioParcelaOperacao] = useState<string>();
    const [getFormularioParcelaNSU, setFormularioParcelaNSU] = useState<string>();
    const [getFormularioParcelaNumeroDaOperacao, setFormularioParcelaNumeroDaOperacao] = useState<string>();
    const [getFormularioListaDeParcelas, setFormularioListaDeParcelas] = useState<Array<{ Quantidade: number, Valor: number, ValorPMT: number, ValorTotal: number, ValorTotalJuros: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>>([]);
    const [getHabilitarInclusaoDeParcelas, setHabilitarInclusaoDeParcelas] = useState<boolean>(false);
    const [getFormularioIdCasal, setFormularioIdCasal] = useState<string>("");
    const [getBuscandoIdCasal, setBuscandoIdCasal] = useState<boolean | undefined>();
    const [getItemPickerSala, setItemPickerSala] = useState<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();
    const [getListaPickerSala, setListaPickerSala] = useState<Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>>();
    const PickerSala = Select.ofType<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();
    const [getItemPickerPromotor, setItemPickerPromotor] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerPromotor, setListaPickerPromotor] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerPromotor = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerAssessorTlmkt, setItemPickerAssessorTlmkt] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerAssessorTlmkt, setListaPickerAssessorTlmkt] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerAssessorTlmkt = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerLiner, setItemPickerLiner] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerLiner, setListaPickerLiner] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerLiner = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerCloser, setItemPickerCloser] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerCloser, setListaPickerCloser] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerCloser = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerPEP, setItemPickerPEP] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerPEP, setListaPickerPEP] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerPEP = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerSubGerenteDeSala, setItemPickerSubGerenteDeSala] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerSubGerenteDeSala, setListaPickerSubGerenteDeSala] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerSubGerenteDeSala = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerGerenteDeSala, setItemPickerGerenteDeSala] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerGerenteDeSala, setListaPickerGerenteDeSala] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerGerenteDeSala = Select.ofType<{ descricao: string, cpf: string }>();
    const [getEnviandoPropostaDeVenda, setEnviandoPropostaDeVenda] = useState<boolean>(false);
    const [getEnviandoContratoParaAssinatura, setEnviandoContratoParaAssinatura] = useState<boolean>(false);
    const [getObjetoPropostaDeVenda, setObjetoPropostaDeVenda] = useState<Objeto.PropostaDeVenda>();
    const [getObjetoContrato, setObjetoContrato] = useState<Array<{ arquivo: string, descricao: string, extensao: string }>>();
    const [getFormularioDataDaProposta, setFormularioDataDaProposta] = useState<Date>(new Date(new Date().toDateString()));
    const [getFormularioExibirDataDaProposta, setFormularioExibirDataDaProposta] = useState<boolean>(false);
    const [getFormularioAnexoFichaDeNegociacao, setFormularioAnexoFichaDeNegociacao] = useState<File>();
    const [getFormularioAnexoFichaDeAtendimento, setFormularioAnexoFichaDeAtendimento] = useState<File>();
    const [getFormularioAnexoDocumentacaoComplementar, setFormularioAnexoDocumentacaoComplementar] = useState<File>();
    const [getItemPickerFinalidadeDeCompra, setItemPickerFinalidadeDeCompra] = useState<{ id: number, descricao: string }>();
    const [getListaPickerFinalidadeDeCompra, setListaPickerFinalidadeDeCompra] = useState<Array<{ id: number, descricao: string }>>();
    const PickerFinalidadeDeCompra = Select.ofType<{ id: number, descricao: string }>();
    const [getLimpandoDocumento, setLimpandoDocumento] = useStateWithCallbackLazy<boolean>(false);
    const [getCollapse, setCollapse] = useState(false);
    const timelineLoaded = useRef<boolean>(false);
    const [getUnidadeVendida, setUnidadeVendida] = useState<boolean>(false);
    const [getExibirPermitirEnvioComAnexoPendente, setExibirPermitirEnvioComAnexoPendente] = useState<boolean>();
    const [getPermitirEnvioComAnexoPendente, setPermitirEnvioComAnexoPendente] = useState<boolean>();
    useEffect(() => {
        if (getPermitirEnvioComAnexoPendente == true) {
            setPermitirEnvioComAnexoPendente(undefined);
            EnviarPropostaDeVenda(1);
        }
    }, [getPermitirEnvioComAnexoPendente])
    const [getExibirPermitirParcelasNaoSubsequentes, setExibirPermitirParcelasNaoSubsequentes] = useState<boolean>();
    const [getPermitirParcelasNaoSubsequentes, setPermitirParcelasNaoSubsequentes] = useState<boolean>();
    useEffect(() => {
        if (getPermitirParcelasNaoSubsequentes == true) {
            setPermitirParcelasNaoSubsequentes(undefined);
            EnviarPropostaDeVenda(2);
        }
    }, [getPermitirParcelasNaoSubsequentes])
    const [getExibirPermitirEmailInvalido, setExibirPermitirEmailInvalido] = useState<boolean>();
    const [getPermitirEmailInvalido, setPermitirEmailInvalido] = useState<boolean>();
    useEffect(() => {
        if (getPermitirEmailInvalido == true) {
            setPermitirEmailInvalido(undefined);
            EnviarPropostaDeVenda(3);
        }
    }, [getPermitirEmailInvalido])

    useEffect(() => {

    }, [])
    /* #endregion */

    /* #region  Funções  */
    function PV(I: any, NPER: any, PMT: any) {
        return -(PMT * (Math.pow(1 + I, NPER) - 1) / (I * Math.pow(1 + I, NPER)));
    }
    function NPER(I: any, PMT: any, PV: any) {
        var type = 0;
        var FV = 0;
        I = eval(I);
        return Math.round(Math.log((PMT * (1 + I * type) - FV * I) / (PV * I + PMT * (1 + I * type))) / Math.log(1 + I));
    }
    function PMT(ir: number, np: number, pv: number, fv: number, type: number) {
        /*
         * ir   - interest rate per month
         * np   - number of periods (months)
         * pv   - present value
         * fv   - future value
         * type - when the payments are due:
         *        0: end of the period, e.g. end of month (default)
         *        1: beginning of period
         */
        var pmt, pvif;

        fv || (fv = 0);
        type || (type = 0);

        if (ir === 0)
            return -(pv + fv) / np;

        pvif = Math.pow(1 + ir, np);
        pmt = -ir * (pv * pvif + fv) / (pvif - 1);

        if (type === 1)
            pmt /= (1 + ir);

        return pmt;
    }
    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem ao cadastro do primeiro comprador
     **/
    function QtdeDeIntermediarias(IntermediariaVencimento: Date | null | undefined): number {
        let ParcelaVencimento = (getFormularioListaDeParcelas && getFormularioListaDeParcelas.length > 0) ? getFormularioListaDeParcelas.reduce((total, parcela) => total.Vencimento < parcela.Vencimento ? total : parcela).Vencimento : new Date();
        IntermediariaVencimento = IntermediariaVencimento || ParcelaVencimento;
        let ParcelaQtde = Math.floor((getFormularioListaDeParcelas.reduce((total, parcela) => (total + parcela.Quantidade), 0)) / 12);
        {
            return ParcelaQtde + Math.floor(((ParcelaVencimento.getMonth() + 12 * ParcelaVencimento.getFullYear()) - (IntermediariaVencimento.getMonth() + 12 * IntermediariaVencimento.getFullYear())) / 12);
        }
    };

    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem ao cadastro do primeiro comprador
     **/
    function QtdeDeIntermediariasModeloDeVendas(IntermediariaVencimento: Date | null | undefined, Parcelas: Array<{ Quantidade: number, Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>): number {
        let ParcelaVencimento = (Parcelas && Parcelas.length > 0) ? Parcelas.reduce((total, parcela) => total.Vencimento < parcela.Vencimento ? total : parcela).Vencimento : new Date();
        IntermediariaVencimento = IntermediariaVencimento || ParcelaVencimento;
        let ParcelaQtde = Math.floor((Parcelas.reduce((total, parcela) => (total + parcela.Quantidade), 0)) / 12);
        {
            return ParcelaQtde + Math.floor(((ParcelaVencimento.getMonth() + 12 * ParcelaVencimento.getFullYear()) - (IntermediariaVencimento.getMonth() + 12 * IntermediariaVencimento.getFullYear())) / 12);
        }
    };
    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem ao cadastro do primeiro comprador
     **/
    async function ValidarLead(): Promise<Boolean> {
        if (CPF.DesformatarTexto(getFormularioCPF).length <= 11) {
            if (!getFormularioNome) {
                NotificacaoInterna.ExibirNotificacao("Nome não preenchido", "Informe o nome do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioEmail) {
                NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioCPF) {
                NotificacaoInterna.ExibirNotificacao("CPF não preenchido", "Informe o CPF do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            else {
                if (getFormularioCPF == getFormularioConjugeCPF ||
                    getFormularioCPF == getFormulario02CompradorCPF ||
                    getFormularioCPF == getFormulario02CompradorConjugeCPF) {
                    NotificacaoInterna.ExibirNotificacao("CPF já informado anteriormente", "Não é possivel adicionar o mesmo comprador mais de uma vez", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
            }
            if (getFormularioCPF && CPF.TextoValido(getFormularioCPF) != true) {
                NotificacaoInterna.ExibirNotificacao("CPF invalido", "Verifique se o CPF do cliente foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getItemPickerFormularioNacionalidade) {
                NotificacaoInterna.ExibirNotificacao("Nacionalidade não definida", "Informe a nacionalidade do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (false && !getItemPickerFormularioNaturalidade) {
                NotificacaoInterna.ExibirNotificacao("Naturalidade não definida", "Informe a naturalidade do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioTelefoneComercial && !getFormularioTelefoneCelular) {
                NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao menos um número para contato com o cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (((getFormularioTelefoneCelular ?? "") != "" || (getFormularioTelefoneComercial ?? "") != "") && getFormularioTelefoneCelular == getFormularioTelefoneComercial) {
                NotificacaoInterna.ExibirNotificacao("Telefone repetido", "Confira as informações de contato do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (((getFormularioConjugeTelefoneCelular ?? "") != "" || (getFormularioConjugeTelefoneComercial ?? "") != "") && getFormularioConjugeTelefoneCelular == getFormularioConjugeTelefoneComercial) {
                NotificacaoInterna.ExibirNotificacao("Telefone repetido", "Confira as informações de contato do conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if ((getFormularioPai ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Pai não definido", "É necessário preencher o nome do pai do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if ((getFormularioMae ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Mãe não definido", "É necessário preencher o nome da mãe do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if ((getFormularioCNH ?? "") != "" && (getFormularioCNHExpedicao || undefined) != undefined && (getFormularioCNHUF ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Dados da CNH não preenchidos incorretamente", "É necessário preencher o número da CNH, data de expedição e UF do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (((getFormularioConjugeNome ?? "") != "" || (getFormularioConjugeEmail ?? "") != "" || (getFormularioConjugeCPF ?? "") != "") && !((getFormularioConjugeNome ?? "") != "" && (getFormularioConjugeEmail ?? "") != "" && (getFormularioConjugeCPF ?? "") != "")) {
                NotificacaoInterna.ExibirNotificacao("Conjugê preenchido incorretamente", "É necessário preencher o nome, email e CPF do conjugê ou deixar todos os campos em branco!", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (((getFormularioConjugeNome ?? "") != "" && (getFormularioConjugeEmail ?? "") != "" && (getFormularioConjugeCPF ?? "") != "") && !getFormularioConjugeTelefoneComercial && !getFormularioConjugeTelefoneCelular) {
                NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao menos um número para contato com o conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if ((getFormularioConjugeCPF ?? "") != "" && CPF.TextoValido(getFormularioConjugeCPF) != true) {
                NotificacaoInterna.ExibirNotificacao("CPF invalido", "Verifique se o CPF do conjugê foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if ((getFormularioConjugePai ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Pai não definido", "É necessário preencher o nome do pai do conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if ((getFormularioConjugeMae ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Mãe não definido", "É necessário preencher o nome da mãe do conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if ((getFormularioConjugeCNH ?? "") != "" && (getFormularioConjugeCNHExpedicao || undefined) != undefined && (getFormularioConjugeCNHUF ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Dados da CNH não preenchidos incorretamente", "É necessário preencher o número da CNH, data de expedição e UF do conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioEstadoCivil) {
                NotificacaoInterna.ExibirNotificacao("Estado civil não informado", "Informe o estado civil do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioCEP || CEP.DesformatarTexto(getFormularioCEP).length != 8) {
                NotificacaoInterna.ExibirNotificacao("CEP inválido", "Certifique-se de que o CEP digitado está correto", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioLogradouro) {
                NotificacaoInterna.ExibirNotificacao("Logradouro não preenchido", "Informe o logradouro do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioBairro) {
                NotificacaoInterna.ExibirNotificacao("Bairro não preenchido", "Informe o bairro do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioCidade) {
                NotificacaoInterna.ExibirNotificacao("Cidade não preenchido", "Informe a cidade do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioUF) {
                NotificacaoInterna.ExibirNotificacao("UF não preenchido", "Informe a UF do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            return true;
        }
        else {
            if (!getFormularioNome) {
                NotificacaoInterna.ExibirNotificacao("Razão social não preenchida", "Informe a razão social do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioEmail) {
                NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioCPF) {
                NotificacaoInterna.ExibirNotificacao("CNPJ não preenchido", "Informe o CNPJ do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            else {
                if (getFormularioCPF == getFormularioConjugeCPF ||
                    getFormularioCPF == getFormulario02CompradorCPF ||
                    getFormularioCPF == getFormulario02CompradorConjugeCPF) {
                    NotificacaoInterna.ExibirNotificacao("CNPJ já informado anteriormente", "Não é possivel adicionar o mesmo comprador mais de uma vez", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
            }
            if (getFormularioCPF && CNPJ.TextoValido(getFormularioCPF) != true) {
                NotificacaoInterna.ExibirNotificacao("CNPJ invalido", "Verifique se o CNPJ do cliente foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioTelefoneComercial) {
                NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao número de contato comercial", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            return true;
        }
    };
    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem ao cadastro do segundo comprador
     **/
    async function Validar02Lead(): Promise<Boolean> {
        if (getFormularioExibir02Comprador == true) {
            if (CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11) {
                if (!getFormulario02CompradorNome) {
                    NotificacaoInterna.ExibirNotificacao("Nome não preenchido", "Informe o nome do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorEmail) {
                    NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorCPF) {
                    NotificacaoInterna.ExibirNotificacao("CPF não preenchido", "Informe o CPF do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                else {
                    if (getFormulario02CompradorCPF == getFormularioConjugeCPF ||
                        getFormulario02CompradorCPF == getFormularioCPF ||
                        getFormulario02CompradorCPF == getFormulario02CompradorConjugeCPF) {
                        NotificacaoInterna.ExibirNotificacao("CPF já informado anteriormente", "Não é possivel adicionar o mesmo comprador mais de uma vez", NotificacaoInterna.TipoDeNotificacao.Erro);
                        return false;
                    }
                }
                if (CPF.TextoValido(getFormulario02CompradorCPF) != true) {
                    NotificacaoInterna.ExibirNotificacao("CPF invalido", "Verifique se o CPF do 02º cliente foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getItemPickerFormulario02CompradorNacionalidade) {
                    NotificacaoInterna.ExibirNotificacao("Nacionalidade não definida", "Informe a nacionalidade do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorTelefoneComercial && !getFormulario02CompradorTelefoneCelular) {
                    NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao menos um número para contato com o 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (((getFormulario02CompradorTelefoneCelular ?? "") != "" || (getFormulario02CompradorTelefoneComercial ?? "") != "") && getFormulario02CompradorTelefoneCelular == getFormulario02CompradorTelefoneComercial) {
                    NotificacaoInterna.ExibirNotificacao("Telefone repetido", "Confira as informações de contato do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (((getFormulario02CompradorConjugeTelefoneCelular ?? "") != "" || (getFormulario02CompradorConjugeTelefoneComercial ?? "") != "") && getFormulario02CompradorConjugeTelefoneCelular == getFormulario02CompradorConjugeTelefoneComercial) {
                    NotificacaoInterna.ExibirNotificacao("Telefone repetido", "Confira as informações de contato do conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (((getFormulario02CompradorConjugeNome ?? "") != "" || (getFormulario02CompradorConjugeEmail ?? "") != "" || (getFormulario02CompradorConjugeCPF ?? "") != "") && !((getFormulario02CompradorConjugeNome ?? "") != "" && (getFormulario02CompradorConjugeEmail ?? "") != "" && (getFormulario02CompradorConjugeCPF ?? "") != "")) {
                    NotificacaoInterna.ExibirNotificacao("Conjugê preenchido incorretamente", "É necessário preencher o nome, email e CPF do conjugê ou deixar todos os campos em branco!", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorConjugeTelefoneComercial && !getFormulario02CompradorConjugeTelefoneCelular) {
                    NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao menos um número para contato com o conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if ((getFormulario02CompradorConjugeCPF ?? "") != "" && CPF.TextoValido(getFormulario02CompradorConjugeCPF) != true) {
                    NotificacaoInterna.ExibirNotificacao("CPF invalido", "Verifique se o CPF do conjugê foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorEstadoCivil) {
                    NotificacaoInterna.ExibirNotificacao("Estado civil não informado", "Informe o estado civil do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorCEP || CEP.DesformatarTexto(getFormulario02CompradorCEP).length != 8) {
                    NotificacaoInterna.ExibirNotificacao("CEP inválido", "Certifique-se de que o CEP digitado está correto", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorLogradouro) {
                    NotificacaoInterna.ExibirNotificacao("Logradouro não preenchido", "Informe o logradouro do endereço do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorBairro) {
                    NotificacaoInterna.ExibirNotificacao("Bairro não preenchido", "Informe o bairro do endereço do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorCidade) {
                    NotificacaoInterna.ExibirNotificacao("Cidade não preenchido", "Informe a cidade do endereço do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorUF) {
                    NotificacaoInterna.ExibirNotificacao("UF não preenchido", "Informe a UF do endereço do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
            }
            else {
                if (!getFormulario02CompradorNome) {
                    NotificacaoInterna.ExibirNotificacao("Razão social não preenchida", "Informe a razão social do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorEmail) {
                    NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorCPF) {
                    NotificacaoInterna.ExibirNotificacao("CNPJ não preenchido", "Informe o CNPJ do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                else {
                    if (getFormulario02CompradorCPF == getFormularioConjugeCPF ||
                        getFormulario02CompradorCPF == getFormularioCPF ||
                        getFormulario02CompradorCPF == getFormulario02CompradorConjugeCPF) {
                        NotificacaoInterna.ExibirNotificacao("CNPJ já informado anteriormente", "Não é possivel adicionar o mesmo comprador mais de uma vez", NotificacaoInterna.TipoDeNotificacao.Erro);
                        return false;
                    }
                }
                if (getFormularioCPF && CPF.TextoValido(getFormulario02CompradorCPF) != true) {
                    NotificacaoInterna.ExibirNotificacao("CNPJ invalido", "Verifique se o CNPJ do 02º cliente foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorTelefoneComercial) {
                    NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao número de contato comercial", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
            }
        }
        return true;
    };
    /* Sobre esta função *//**
     * Realiza o cadastro do primeiro comprador
     **/
    async function AdicionarLead(): Promise<Objeto.Lead | null> {
        var ObjetoLead = {} as Objeto.Lead;
        if (CPF.DesformatarTexto(getFormularioCPF).length <= 11) {
            ObjetoLead = {
                id: 0,
                cpf: getFormularioCPF,
                rg: {
                    numero: getFormularioRG,
                    orgaoemissor: getFormularioRGOrgaoEmissor,
                    uf: getFormularioRGUF
                },
                cnhNumero: getFormularioCNH,
                cnhExpedicao: getFormularioCNHExpedicao,
                cnhUF: getFormularioCNHUF,
                pai: getFormularioPai,
                mae: getFormularioMae,
                naturalidade: getItemPickerFormularioNaturalidade?.id,
                dataDeNascimento: getFormularioDataDeNascimento,
                ocupacao: {
                    nome: getFormularioProfissao
                },
                nacionalidade: getItemPickerFormularioNacionalidade,
                nome: getFormularioNome,
                emails: [
                    {
                        classificacao: 1,
                        descricao: getFormularioEmail
                    }
                ],
                telefones: [
                    !getFormularioTelefoneCelular ? null : {
                        classificacao: 1,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioTelefoneCelular)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioTelefoneCelular)),
                        observacao: ""
                    },
                    !getFormularioTelefoneComercial ? null : {
                        classificacao: 2,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioTelefoneComercial)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioTelefoneComercial)),
                        observacao: ""
                    }
                ],
                endereco: {
                    classificacao: 0,
                    cep: getFormularioCEP,
                    logradouro: getFormularioLogradouro,
                    numero: getFormularioNumeroDeEndereco,
                    complemento: getFormularioComplemento,
                    bairro: getFormularioBairro,
                    cidade: getFormularioCidade,
                    uf: getFormularioUF
                },
                estadoCivil: getFormularioEstadoCivil?.id,
                dependentes: [
                    (getFormularioEstadoCivil?.id != 2 && getFormularioEstadoCivil?.id != 7) ? null : {
                        classificacao: 2,
                        cpf: getFormularioConjugeCPF,
                        nome: getFormularioConjugeNome,
                        dataDeNascimento: getFormularioConjugeDataDeNascimento,
                        rg: {
                            numero: getFormularioConjugeRG,
                            orgaoemissor: getFormularioConjugeRGOrgaoEmissor,
                            uf: getFormularioConjugeRGUF
                        },
                        cnhNumero: getFormularioConjugeCNH,
                        cnhExpedicao: getFormularioConjugeCNHExpedicao,
                        cnhUF: getFormularioConjugeCNHUF,
                        pai: getFormularioConjugePai,
                        mae: getFormularioConjugeMae,
                        naturalidade: getItemPickerFormularioConjugeNaturalidade?.id,
                        ocupacao: {
                            nome: getFormularioConjugeProfissao
                        },
                        nacionalidade: getItemPickerFormularioConjugeNacionalidade,
                        email: getFormularioConjugeEmail,
                        telefones: [
                            !getFormularioConjugeTelefoneCelular ? null : {
                                classificacao: 1,
                                ddi: "55",
                                ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioConjugeTelefoneCelular)),
                                numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioConjugeTelefoneCelular)),
                                observacao: ""
                            },
                            !getFormularioConjugeTelefoneComercial ? null : {
                                classificacao: 2,
                                ddi: "55",
                                ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioConjugeTelefoneComercial)),
                                numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioConjugeTelefoneComercial)),
                                observacao: ""
                            }
                        ],
                    } as Objeto.Dependente
                ],
                localDeCaptacao: getFormularioLocalDeCaptacao
            } as Objeto.Lead;
            ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
            ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;

            try {
                ObjetoLead.dependentes?.forEach(Dependente => {
                    Dependente.telefones = Dependente.telefones?.filter((Item) => Item) ?? null;
                });
            }
            catch { }
        }
        else {
            ObjetoLead = {
                id: 0,
                cpf: getFormularioCPF,
                nome: getFormularioNome,
                emails: [
                    {
                        classificacao: 1,
                        descricao: getFormularioEmail
                    }
                ],
                telefones: [
                    !getFormularioTelefoneCelular ? null : {
                        classificacao: 1,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioTelefoneCelular)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioTelefoneCelular)),
                        observacao: ""
                    },
                    !getFormularioTelefoneComercial ? null : {
                        classificacao: 2,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioTelefoneComercial)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioTelefoneComercial)),
                        observacao: ""
                    }
                ],
                endereco: {
                    classificacao: 0,
                    cep: getFormularioCEP,
                    logradouro: getFormularioLogradouro,
                    numero: getFormularioNumeroDeEndereco,
                    complemento: getFormularioComplemento,
                    bairro: getFormularioBairro,
                    cidade: getFormularioCidade,
                    uf: getFormularioUF
                },
                localDeCaptacao: getFormularioLocalDeCaptacao
            } as Objeto.Lead;
            ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
            ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;
        }
        console.log(JSON.stringify(ObjetoLead));
        var Response = await Lead.Post(ContextSignIn.getContext().token, ObjetoLead);
        if (Math.floor(Response.status / 100) == 2) {
            NotificacaoInterna.ExibirNotificacao("Lead cadastrado com sucesso", "Registro devidamente cadastrado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao cadastrar o lead", "😱 Houve uma falha ao cadastrar o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
        }
        ObjetoLead.id = Response.data.id;
        return (Math.floor(Response.status / 100) == 2) ? ObjetoLead : null;
    };
    /* Sobre esta função *//**
     * Realiza o cadastro do segundo comprador
     **/
    async function Adicionar02Lead(): Promise<Objeto.Lead | null> {
        var ObjetoLead = {} as Objeto.Lead;

        if (CPF.DesformatarTexto(getFormularioCPF).length <= 11) {
            ObjetoLead = {
                id: 0,
                cpf: getFormulario02CompradorCPF,
                rg: {
                    numero: getFormulario02CompradorRG,
                    orgaoemissor: getFormulario02CompradorRGOrgaoEmissor,
                    uf: getFormulario02CompradorRGUF
                },
                cnhNumero: getFormulario02CompradorCNH,
                cnhExpedicao: getFormulario02CompradorCNHExpedicao,
                cnhUF: getFormulario02CompradorCNHUF,
                pai: getFormulario02CompradorPai,
                mae: getFormulario02CompradorMae,
                naturalidade: getItemPickerFormulario02CompradorNaturalidade?.id,
                dataDeNascimento: getFormulario02CompradorDataDeNascimento,
                ocupacao: {
                    nome: getFormulario02CompradorProfissao
                },
                nacionalidade: getItemPickerFormulario02CompradorNacionalidade,
                nome: getFormulario02CompradorNome,
                emails: [
                    {
                        classificacao: 1,
                        descricao: getFormulario02CompradorEmail
                    }
                ],
                telefones: [
                    !getFormulario02CompradorTelefoneCelular ? null : {
                        classificacao: 1,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneCelular)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneCelular)),
                        observacao: ""
                    },
                    !getFormulario02CompradorTelefoneComercial ? null : {
                        classificacao: 2,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneComercial)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneComercial)),
                        observacao: ""
                    }
                ],
                endereco: {
                    classificacao: 0,
                    cep: getFormulario02CompradorCEP,
                    logradouro: getFormulario02CompradorLogradouro,
                    numero: getFormulario02CompradorNumeroDeEndereco,
                    complemento: getFormulario02CompradorComplemento,
                    bairro: getFormulario02CompradorBairro,
                    cidade: getFormulario02CompradorCidade,
                    uf: getFormulario02CompradorUF
                },
                estadoCivil: getFormulario02CompradorEstadoCivil?.id,
                dependentes: [
                    (getFormulario02CompradorEstadoCivil?.id != 2 && getFormulario02CompradorEstadoCivil?.id != 7) ? null : {
                        classificacao: 2,
                        cpf: getFormulario02CompradorConjugeCPF,
                        nome: getFormulario02CompradorConjugeNome,
                        dataDeNascimento: getFormulario02CompradorConjugeDataDeNascimento,
                        rg: {
                            numero: getFormulario02CompradorConjugeRG,
                            orgaoemissor: getFormulario02CompradorConjugeRGOrgaoEmissor,
                            uf: getFormulario02CompradorConjugeRGUF
                        },
                        cnhNumero: getFormulario02CompradorConjugeCNH,
                        cnhExpedicao: getFormulario02CompradorConjugeCNHExpedicao,
                        cnhUF: getFormulario02CompradorConjugeCNHUF,
                        pai: getFormulario02CompradorConjugePai,
                        mae: getFormulario02CompradorConjugeMae,
                        naturalidade: getItemPickerFormulario02CompradorConjugeNaturalidade?.id,
                        ocupacao: {
                            nome: getFormulario02CompradorConjugeProfissao
                        },
                        nacionalidade: getItemPickerFormulario02CompradorConjugeNacionalidade,
                        email: getFormulario02CompradorConjugeEmail,
                        telefones: [
                            !getFormulario02CompradorConjugeTelefoneCelular ? null : {
                                classificacao: 1,
                                ddi: "55",
                                ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorConjugeTelefoneCelular)),
                                numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorConjugeTelefoneCelular)),
                                observacao: ""
                            },
                            !getFormulario02CompradorConjugeTelefoneComercial ? null : {
                                classificacao: 2,
                                ddi: "55",
                                ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorConjugeTelefoneComercial)),
                                numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorConjugeTelefoneComercial)),
                                observacao: ""
                            }
                        ],
                    } as Objeto.Dependente
                ],
                localDeCaptacao: getFormularioLocalDeCaptacao
            } as Objeto.Lead;
            ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
            ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;
            try {
                ObjetoLead.dependentes?.forEach(Dependente => {
                    Dependente.telefones = Dependente.telefones?.filter((Item) => Item) ?? null;
                });
            }
            catch { }
        }
        else {
            ObjetoLead = {
                id: 0,
                cpf: getFormulario02CompradorCPF,
                nome: getFormulario02CompradorNome,
                emails: [
                    {
                        classificacao: 1,
                        descricao: getFormulario02CompradorEmail
                    }
                ],
                telefones: [
                    !getFormulario02CompradorTelefoneCelular ? null : {
                        classificacao: 1,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneCelular)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneCelular)),
                        observacao: ""
                    },
                    !getFormulario02CompradorTelefoneComercial ? null : {
                        classificacao: 2,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneComercial)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneComercial)),
                        observacao: ""
                    }
                ],
                endereco: {
                    classificacao: 0,
                    cep: getFormulario02CompradorCEP,
                    logradouro: getFormulario02CompradorLogradouro,
                    numero: getFormulario02CompradorNumeroDeEndereco,
                    complemento: getFormulario02CompradorComplemento,
                    bairro: getFormulario02CompradorBairro,
                    cidade: getFormulario02CompradorCidade,
                    uf: getFormulario02CompradorUF
                },
                localDeCaptacao: getFormularioLocalDeCaptacao
            } as Objeto.Lead;
            ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
            ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;
        }
        console.log(JSON.stringify(ObjetoLead));
        var Response = await Lead.Post(ContextSignIn.getContext().token, ObjetoLead);
        if (Math.floor(Response.status / 100) == 2) {
            NotificacaoInterna.ExibirNotificacao("02ª Lead cadastrado com sucesso", "Registro devidamente cadastrado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao cadastrar o 02ª lead", "😱 Houve uma falha ao cadastrar o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
        }

        ObjetoLead.id = Response.data.id;
        return (Math.floor(Response.status / 100) == 2) ? ObjetoLead : null;
    };
    /* Sobre esta função *//**
     * Realiza o cadastro de um novo prospect
     **/
    async function AdicionarProspect(Lead: Objeto.Lead): Promise<Objeto.Prospect | null> {
        if (await ValidarLead() == true) {
            var Response = await Prospect.Post(ContextSignIn.getContext().token, Lead);
            if (Math.floor(Response.status / 100) == 2) {
                NotificacaoInterna.ExibirNotificacao("Lead convertido em prospect", "Registro devidamente cadastrado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
            }
            else {
                NotificacaoInterna.ExibirNotificacao("Falha ao converter lead em prospect", "😱 Houve uma falha ao converter o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
            }
            return (Math.floor(Response.status / 100) == 2) ? Lead as Objeto.Prospect : null;
        }
        else {
            return null;
        }
    };
    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem a proposta de venda
     **/
    async function ValidarPropostaDeVenda(): Promise<Boolean> {
        if (!getItemPickerCentroDeCusto) {
            NotificacaoInterna.ExibirNotificacao("Empreendimento não selecionada", "Informe o empreendimento", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItemPickerIdentificador) {
            NotificacaoInterna.ExibirNotificacao("Unidade não selecionada", "Informe a unidade desejada", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItemPickerCondicaoDaTabelaDeVenda) {
            NotificacaoInterna.ExibirNotificacao("Nenhum modelo de venda selecionado", "Selecione o modelo de vendas", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        {
            let ValorTotalEntrada = getListaCondicaoDaTabelaDeVenda.find((Item) => Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((Item) => Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0;
            let ValorSomadoEntrada = 0;
            let VencimentoInvalido = 0;
            let MeioDePagamentoInvalido = 0;
            getFormularioListaDeEntradas.forEach((Item) => {
                ValorSomadoEntrada += Item.Valor;
                VencimentoInvalido += ((Item.Vencimento < getFormularioDataDaProposta) ? 1 : 0);
                MeioDePagamentoInvalido += ((Item.MeioDePagamento == "") ? 1 : 0);
            });
            if (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && ValorTotalEntrada.toFixed(2) != ValorSomadoEntrada.toFixed(2)) {
                NotificacaoInterna.ExibirNotificacao("Valor gerado de entrada diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(ValorSomadoEntrada) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(ValorTotalEntrada), NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (VencimentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (MeioDePagamentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existe meios de pagamento referente a entrada que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
        }
        {
            let ValorTotalSinal = getListaCondicaoDaTabelaDeVenda.find((Item) => Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((Item) => Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0;
            let ValorSomadoSinal = 0;
            let VencimentoInvalido = 0;
            let MeioDePagamentoInvalido = 0;

            getFormularioListaDeSinais.forEach((Item) => {
                ValorSomadoSinal += Item.Valor;
                VencimentoInvalido += ((Item.Vencimento < getFormularioDataDaProposta) ? 1 : 0);
                MeioDePagamentoInvalido += ((Item.MeioDePagamento == "") ? 1 : 0);
            });
            if (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && getItemPickerCondicaoDaTabelaDeVenda.descricao.toUpperCase().includes("VISTA") == false && ValorTotalSinal.toFixed(2) != ValorSomadoSinal.toFixed(2)) {
                NotificacaoInterna.ExibirNotificacao("Valor gerado de sinal diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(ValorSomadoSinal) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(ValorTotalSinal), NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (VencimentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (MeioDePagamentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existem meios de pagamento referente ao sinal que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
        }
        {
            let ValorTotalIntermediaria = (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? ((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0) * (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0)) : getListaCondicaoDaTabelaDeVenda.find((Item) => Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((Item) => Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.valorTotal ?? 0
            let ValorSomadoIntermediaria = 0;
            let VencimentoInvalido = 0;
            let MeioDePagamentoInvalido = 0;
            getFormularioListaDeIntermediarias.forEach((Item) => {
                ValorSomadoIntermediaria += Item.Valor * Item.Quantidade;
                VencimentoInvalido += ((Item.Vencimento < getFormularioDataDaProposta) ? 1 : 0);
                MeioDePagamentoInvalido += ((Item.MeioDePagamento == "") ? 1 : 0);
            });
            if (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && getItemPickerCondicaoDaTabelaDeVenda?.descricao.toLowerCase().includes("vista") == false
                && (ValorTotalIntermediaria.toFixed(2) != ValorSomadoIntermediaria.toFixed(2))) {
                NotificacaoInterna.ExibirNotificacao("Valor gerado de intermediárias diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(ValorSomadoIntermediaria) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(ValorTotalIntermediaria), NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (VencimentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (MeioDePagamentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existem meios de pagamento referente a intermediária que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
        }
        {
            let ValorTotalParcela = (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? ((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0) * (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0)) : getListaCondicaoDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((Item) => Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.valorTotal ?? 0
            let ValorSomadoParcela = 0;
            let VencimentoInvalido = 0;
            let MeioDePagamentoInvalido = 0;
            getFormularioListaDeParcelas.forEach((Item) => {
                ValorSomadoParcela += Item.Valor * Item.Quantidade;
                VencimentoInvalido += ((Item.Vencimento < getFormularioDataDaProposta) ? 1 : 0);
                MeioDePagamentoInvalido += ((Item.MeioDePagamento == "") ? 1 : 0);
            });
            if (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && getItemPickerCondicaoDaTabelaDeVenda?.descricao.toLowerCase().includes("vista") == false
                && ValorTotalParcela.toFixed(2) != ValorSomadoParcela.toFixed(2)) {
                NotificacaoInterna.ExibirNotificacao("Valor gerado de saldo diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(ValorSomadoParcela) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(ValorTotalParcela), NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (VencimentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (MeioDePagamentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existem meios de pagamento referente ao saldo que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
        }
        if (!getFormularioIdCasal && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4) {
            NotificacaoInterna.ExibirNotificacao(`Id casal não informado`, "Preencha todos os campos para prosseguir", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItemPickerLiner?.cpf && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4) {
            NotificacaoInterna.ExibirNotificacao("Liner não informado", "Informe o Liner", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItemPickerPEP?.cpf && !getItemPickerCloser?.cpf && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4) {
            NotificacaoInterna.ExibirNotificacao("Vendedor não informado", "Informe o PEP ou Closer", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItemPickerFinalidadeDeCompra) {
            NotificacaoInterna.ExibirNotificacao("Nenhuma finalidade de compra", "Selecione a finalidade de compra", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        return true;
    };
    /* Sobre esta função *//**
     * Realiza o cadastro de uma nova proposta de venda
    * @param {Array<Objeto.Prospect>} Prospects Informe os dados do prospect
     **/
    async function AdicionarPropostaDeVenda(Prospects: Array<Objeto.Prospect>): Promise<Objeto.PropostaDeVenda | null> {
        var PropostaDeVenda = {
            empresa: getItemPickerIdentificador?.empresa ?? {} as Objeto.Empresa,
            centroDeCusto: getItemPickerIdentificador?.centroDeCusto ?? {} as Objeto.CentroDeCusto,
            numero: 0,
            contratoCEF: getFormularioIdCasal,
            dataDaVenda: getFormularioDataDaProposta,
            finalidadeDaCompra: getItemPickerFinalidadeDeCompra?.id,
            prospects: Prospects,
            modeloDeVenda: {} as Objeto.ModeloDeVenda,
            identificador: getItemPickerIdentificador ?? {} as Objeto.Identificador,
            titulosDeCorretagem: new Array<Objeto.TituloDeCorretagem>(),
            titulosDeIntermediacao: new Array<Objeto.TituloDeIntermediacao>(),
            titulosDeIntermediaria: new Array<Objeto.TituloDeIntermediaria>(),
            titulosDeSinal: new Array<Objeto.TituloDeSinal>(),
            titulosDeEntrada: new Array<Objeto.TituloDeEntrada>(),
            titulosDeParcela: new Array<Objeto.TituloDeParcela>(),
            titulosConsolidados: new Array<Objeto.Titulo>(),
            salaDeVenda: getItemPickerSala?.cidade ?? " ",
            salaDeVendaNome: getItemPickerSala?.descricao ?? " ",
            estruturaDeComissao: new Array<Objeto.Comissionado>()
        } as Objeto.PropostaDeVenda;
        var Index = 0;
        var ObjetoTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, PropostaDeVenda.empresa.id, PropostaDeVenda.centroDeCusto.sigla, PropostaDeVenda.identificador.local.id, PropostaDeVenda.identificador.subLocal.id)).data;
        ObjetoTabelaDeVenda.modeloDeVenda.modeloDeVendaExterno = getItemPickerCondicaoDaTabelaDeVenda?.descricao;
        PropostaDeVenda.modeloDeVenda = ObjetoTabelaDeVenda.modeloDeVenda;
        for (let index = 0; index < getFormularioEntradaQtde; index++) {
            PropostaDeVenda.titulosDeEntrada.push({
                id: index + 1,
                vencimento: getFormularioListaDeEntradas[index].Vencimento,
                valor: getFormularioListaDeEntradas[index].Valor,
                formaDePagamento: getFormularioListaDeEntradas[index].MeioDePagamento,
                banco: getFormularioListaDeEntradas[index].Banco?.Valor,
                agencia: getFormularioListaDeEntradas[index].Agencia,
                conta: getFormularioListaDeEntradas[index].Conta,
                digitoDaConta: getFormularioListaDeEntradas[index].DigitoDaConta,
                titular: getFormularioListaDeEntradas[index].Titular,
                numeroCheque: getFormularioListaDeEntradas[index].NumeroCheque,
                maquina: getFormularioListaDeEntradas[index].Maquina,
                bandeira: getFormularioListaDeEntradas[index].Bandeira,
                digitoCartao: getFormularioListaDeEntradas[index].DigitoCartao,
                operacao: getFormularioListaDeEntradas[index].Operacao,
                nsu: getFormularioListaDeEntradas[index].NSU,
                numeroDaOperacao: getFormularioListaDeEntradas[index].NumeroDaOperacao,
                codAutorizacao: getFormularioListaDeEntradas[index]?.CodAutorizacao
            } as Objeto.TituloDeEntrada);
            PropostaDeVenda.titulosConsolidados.push({
                classificacao: ObjetoTabelaDeVenda.classificacoesDosTitulosDaTabelaDeVenda.find((Item) => Item.classificacao.id == 3)?.classificacao,
                numero: index + 1,
                numeroDeGeracao: ++Index,
                dataDeVencimento: getFormularioListaDeEntradas[index].Vencimento,
                grupo: 1,
                principal: getFormularioListaDeEntradas[index].Valor,
                juros: 0,
                correcao: 0,
                multa: 0,
                jurosPorAtraso: 0,
                correcaoPorAtraso: 0,
                acrescimoAvulso: 0,
                descontoAvulso: 0,
                descontoDeAntecipacao: 0,
                descontoDePontualidade: 0,
                codAutorizacao: getFormularioListaDeEntradas[index]?.CodAutorizacao
            } as Objeto.Titulo);
        }
        for (let index = 0; index < getFormularioSinalQtde; index++) {
            PropostaDeVenda.titulosDeSinal.push({
                id: index + 1,
                vencimento: getFormularioListaDeSinais[index].Vencimento,
                valor: getFormularioListaDeSinais[index].Valor,
                formaDePagamento: getFormularioListaDeSinais[index].MeioDePagamento,
                banco: getFormularioListaDeSinais[index].Banco?.Valor,
                agencia: getFormularioListaDeSinais[index].Agencia,
                conta: getFormularioListaDeSinais[index].Conta,
                digitoDaConta: getFormularioListaDeSinais[index].DigitoDaConta,
                titular: getFormularioListaDeSinais[index].Titular,
                numeroCheque: getFormularioListaDeSinais[index].NumeroCheque,
                maquina: getFormularioListaDeSinais[index].Maquina,
                bandeira: getFormularioListaDeSinais[index].Bandeira,
                digitoCartao: getFormularioListaDeSinais[index].DigitoCartao,
                operacao: getFormularioListaDeSinais[index].Operacao,
                nsu: getFormularioListaDeSinais[index].NSU,
                numeroDaOperacao: getFormularioListaDeSinais[index].NumeroDaOperacao,
                codAutorizacao: getFormularioListaDeEntradas[index]?.CodAutorizacao
            } as Objeto.TituloDeParcela);
            PropostaDeVenda.titulosConsolidados.push({
                classificacao: ObjetoTabelaDeVenda.classificacoesDosTitulosDaTabelaDeVenda.find((Item) => Item.classificacao.id == 2)?.classificacao,
                numero: index + 1,
                numeroDeGeracao: ++Index,
                dataDeVencimento: getFormularioListaDeSinais[index].Vencimento,
                grupo: 1,
                principal: getFormularioListaDeSinais[index].Valor,
                juros: 0,
                correcao: 0,
                multa: 0,
                jurosPorAtraso: 0,
                correcaoPorAtraso: 0,
                acrescimoAvulso: 0,
                descontoAvulso: 0,
                descontoDeAntecipacao: 0,
                descontoDePontualidade: 0,
                codAutorizacao: getFormularioListaDeEntradas[index]?.CodAutorizacao
            } as Objeto.Titulo);
        }
        getFormularioListaDeIntermediarias.forEach((Item) => {
            for (let index = 0; index < Item.Quantidade; index++) {
                let Vencimento = new Date(Item.Vencimento);
                Vencimento.setFullYear(Vencimento.getFullYear() + index);
                PropostaDeVenda.titulosDeIntermediaria.push({
                    id: index + 1,
                    vencimento: Vencimento,
                    valor: (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? Item.ValorPMT : Item.Valor,
                    formaDePagamento: Item.MeioDePagamento,
                    banco: Item.Banco?.Valor,
                    agencia: Item.Agencia,
                    conta: Item.Conta,
                    digitoDaConta: Item.DigitoDaConta,
                    titular: Item.Titular,
                    numeroCheque: Item.NumeroCheque,
                    maquina: Item.Maquina,
                    bandeira: Item.Bandeira,
                    digitoCartao: Item.DigitoCartao,
                    operacao: Item.Operacao,
                    nsu: Item.NSU,
                    numeroDaOperacao: Item.NumeroDaOperacao,
                    codAutorizacao: Item?.CodAutorizacao
                } as Objeto.TituloDeIntermediaria);
                PropostaDeVenda.titulosConsolidados.push({
                    classificacao: ObjetoTabelaDeVenda.classificacoesDosTitulosDaTabelaDeVenda.find((Item) => Item.classificacao.id == 14)?.classificacao,
                    numero: index + 1,
                    numeroDeGeracao: ++Index,
                    dataDeVencimento: Vencimento,
                    grupo: 1,
                    principal: (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? Item.ValorPMT : Item.Valor,
                    juros: 0,
                    correcao: 0,
                    multa: 0,
                    jurosPorAtraso: 0,
                    correcaoPorAtraso: 0,
                    acrescimoAvulso: 0,
                    descontoAvulso: 0,
                    descontoDeAntecipacao: 0,
                    descontoDePontualidade: 0,
                    codAutorizacao: Item?.CodAutorizacao
                } as Objeto.Titulo);
            }
        });
        getFormularioListaDeParcelas.forEach((Item) => {
            for (let index = 0; index < Item.Quantidade; index++) {
                let Vencimento = new Date(Item.Vencimento);
                if (getItemPickerCondicaoDaTabelaDeVenda?.descricao.includes("Anual")) {
                    Vencimento.setFullYear(Vencimento.getFullYear() + index);
                }
                else if (getItemPickerCondicaoDaTabelaDeVenda?.descricao.includes("Semestral")) {
                    Vencimento.setMonth(Vencimento.getMonth() + index * 6);
                } else {
                    Vencimento.setMonth(Vencimento.getMonth() + index);
                }
                PropostaDeVenda.titulosDeParcela.push({
                    id: index + 1,
                    vencimento: Vencimento,
                    valor: (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? Item.ValorPMT : Item.Valor,
                    formaDePagamento: Item.MeioDePagamento,
                    banco: Item.Banco?.Valor,
                    agencia: Item.Agencia,
                    conta: Item.Conta,
                    digitoDaConta: Item.DigitoDaConta,
                    titular: Item.Titular,
                    numeroCheque: Item.NumeroCheque,
                    maquina: Item.Maquina,
                    bandeira: Item.Bandeira,
                    digitoCartao: Item.DigitoCartao,
                    operacao: Item.Operacao,
                    nsu: Item.NSU,
                    numeroDaOperacao: Item.NumeroDaOperacao,
                    codAutorizacao: Item?.CodAutorizacao
                } as Objeto.TituloDeParcela);
                PropostaDeVenda.titulosConsolidados.push({
                    classificacao: ObjetoTabelaDeVenda.classificacoesDosTitulosDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.classificacao,
                    numero: index + 1,
                    numeroDeGeracao: ++Index,
                    dataDeVencimento: Vencimento,
                    grupo: 1,
                    principal: (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? Item.ValorPMT : Item.Valor,
                    juros: 0,
                    correcao: 0,
                    multa: 0,
                    jurosPorAtraso: 0,
                    correcaoPorAtraso: 0,
                    acrescimoAvulso: 0,
                    descontoAvulso: 0,
                    descontoDeAntecipacao: 0,
                    descontoDePontualidade: 0,
                    codAutorizacao: Item?.CodAutorizacao
                } as Objeto.Titulo);
            }
        });
        PropostaDeVenda.estruturaDeComissao.push({
            cpf: getItemPickerLiner ? getItemPickerLiner?.cpf : "",
            nome: getItemPickerLiner ? getItemPickerLiner?.descricao : "",
            cargo: "Liner"
        } as Objeto.Comissionado, {
            cpf: getItemPickerCloser ? getItemPickerCloser?.cpf : "",
            nome: getItemPickerCloser ? getItemPickerCloser?.descricao : "",
            cargo: "Closer"
        } as Objeto.Comissionado, {
            cpf: getItemPickerPEP ? getItemPickerPEP?.cpf : "",
            nome: getItemPickerPEP ? getItemPickerPEP?.descricao : "",
            cargo: "PEP"
        } as Objeto.Comissionado, {
            cpf: getItemPickerSubGerenteDeSala ? getItemPickerSubGerenteDeSala?.cpf : "",
            nome: getItemPickerSubGerenteDeSala ? getItemPickerSubGerenteDeSala?.descricao : "",
            cargo: "Sub gerente"
        } as Objeto.Comissionado, {
            cpf: getItemPickerGerenteDeSala ? getItemPickerGerenteDeSala?.cpf : "",
            nome: getItemPickerGerenteDeSala ? getItemPickerGerenteDeSala?.descricao : "",
            cargo: "Gerente"
        } as Objeto.Comissionado, {
            cpf: getItemPickerPromotor ? getItemPickerPromotor?.cpf : "",
            nome: getItemPickerPromotor ? getItemPickerPromotor?.descricao : "",
            cargo: "Captador/Promotor"
        } as Objeto.Comissionado, {
            cpf: getItemPickerAssessorTlmkt ? getItemPickerAssessorTlmkt?.cpf : "",
            nome: getItemPickerAssessorTlmkt ? getItemPickerAssessorTlmkt?.descricao : "",
            cargo: "Assessor Tlmkt"
        } as Objeto.Comissionado);
        NotificacaoInterna.ExibirNotificacao("Proposta a caminho do sistema", "🤝 Aguarde um pouco enquanto inserimos a proposta no sistema e geramos o contrato", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        var Response = await Venda.NovaProposta(ContextSignIn.getContext().token, PropostaDeVenda);
        if (Math.floor(Response.status / 100) == 2) {
            NotificacaoInterna.ExibirNotificacao("Proposta devidamente cadastrada", "Aguarde enquanto gero o contrato de compra e venda", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao cadastrar a proposta", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao cadastrar a proposta, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
        }
        console.log(JSON.stringify(PropostaDeVenda));
        return ((Response.status / 100) == 2) ? Response.data as Objeto.PropostaDeVenda : null;
    };
    /* Sobre esta função *//**
     * Encaminhar proposta de venda e gerar o contrato
     **/
    async function EnviarPropostaDeVenda(Validacao?: number): Promise<boolean> {
        await setEnviandoPropostaDeVenda(true);
        await setObjetoPropostaDeVenda(undefined);
        await setObjetoContrato(undefined);

        if ((Validacao ?? 0) <= 0 && (getFormularioAnexoCheckListDeCadastro == undefined || getFormularioAnexoCheckListDeCadastro == null ||
            getFormularioAnexoCliente == undefined || getFormularioAnexoCliente == null ||
            getFormularioAnexoConjuge == undefined || getFormularioAnexoConjuge == null ||
            getFormularioAnexoEndereco == undefined || getFormularioAnexoEndereco == null ||
            getFormulario02CompradorAnexoCliente == undefined || getFormulario02CompradorAnexoCliente == null ||
            getFormulario02CompradorAnexoConjuge == undefined || getFormulario02CompradorAnexoConjuge == null ||
            getFormulario02CompradorAnexoEndereco == undefined || getFormulario02CompradorAnexoEndereco == null ||
            getFormularioAnexoComprovantePgtoEntrada == undefined || getFormularioAnexoComprovantePgtoEntrada == null ||
            getFormularioAnexoFichaDeNegociacao == undefined || getFormularioAnexoFichaDeNegociacao == null ||
            getFormularioAnexoFichaDeAtendimento == undefined || getFormularioAnexoFichaDeAtendimento == null ||
            getFormularioAnexoDocumentacaoComplementar == undefined || getFormularioAnexoDocumentacaoComplementar == null ||
            getPermitirEnvioComAnexoPendente == undefined || getPermitirEnvioComAnexoPendente == null)) {
            setExibirPermitirEnvioComAnexoPendente(true);
            await setEnviandoPropostaDeVenda(false);
            return false;
        }
        function verificarMesesAnteriores(dates: Date[]): boolean {
            let Resultado = true;
            for (let i = 1; i < dates.length; i++) {
                const currentMonth = dates[i].getMonth();
                const previousMonth = dates[i - 1].getMonth();

                if (currentMonth === (previousMonth + 1) % 12) { }
                else {
                    Resultado = false;
                }
            }
            return Resultado;
        }
        if ((Validacao ?? 0) <= 1 &&
            (verificarMesesAnteriores(getFormularioListaDeEntradas.map((Item) => Item.Vencimento)) == false
                || verificarMesesAnteriores(getFormularioListaDeSinais.map((Item) => Item.Vencimento)) == false)) {
            setExibirPermitirParcelasNaoSubsequentes(true);
            await setEnviandoPropostaDeVenda(false);
            return false;
        }
        function validarEmails(emails: string[]): boolean {
            let Resultado = true;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            emails.forEach((email, index) => {
                if ((email ?? "") == "" || emailRegex.test(email)) { } else {
                    Resultado = false;
                }
            });
            return Resultado;
        }
        if ((Validacao ?? 0) <= 2 && validarEmails([getFormularioEmail, getFormularioConjugeEmail]) == false) {
            setExibirPermitirEmailInvalido(true);
            await setEnviandoPropostaDeVenda(false);
            return false;
        }
        if (await ValidarLead() == true && await Validar02Lead() == true && await ValidarPropostaDeVenda() == true) {
            var Lead = await AdicionarLead();
            if (Lead != null) {

                var Prospects = [] as Array<Objeto.Prospect>;
                var Prospect = await AdicionarProspect(Lead);
                if (Prospect != null) {
                    Prospects.push(Prospect);
                }
                if (getFormularioExibir02Comprador == true) {
                    let Lead02 = await Adicionar02Lead();
                    if (Lead02 != null) {
                        let Prospect02 = await AdicionarProspect(Lead02);
                        if (Prospect02 != null) {
                            Prospects.push(Prospect02);
                        }
                    }
                }
                if (Prospect != null) {
                    var PropostaDeVenda = await AdicionarPropostaDeVenda(Prospects);
                    await setObjetoPropostaDeVenda(PropostaDeVenda ?? undefined);
                    if (PropostaDeVenda != null) {
                        if (await GerarContrato(PropostaDeVenda) != null) {
                            await setEnviandoPropostaDeVenda(false);
                            return true;
                        }
                        else {
                            await setEnviandoPropostaDeVenda(false);
                            return false;
                        }
                    }
                    else {
                        await setEnviandoPropostaDeVenda(false);
                        return false;
                    }
                }
                else {
                    await setEnviandoPropostaDeVenda(false);
                    return false;
                }
            }
            else {
                await setEnviandoPropostaDeVenda(false);
                return false;
            }
        }
        else {
            await setEnviandoPropostaDeVenda(false);
            return false;
        }
    };
    /* Sobre esta função *//**
     * Reimprimir documento atrelados ao contrato
     **/
    async function ReimprimirDocumento(): Promise<boolean> {
        await setEnviandoPropostaDeVenda(true);
        await setObjetoContrato(undefined);
        if (getObjetoPropostaDeVenda && await GerarContrato(getObjetoPropostaDeVenda) != null) {
            await setEnviandoPropostaDeVenda(false);
            return true;
        }
        else {
            await setEnviandoPropostaDeVenda(false);
            return false;
        }
    };
    /* Sobre esta função *//**
     * Imprimir o contrato
    @param {Objeto.PropostaDeVenda} PropostaDeVenda Informe os dados da proposta
     **/
    async function GerarContrato(PropostaDeVenda: Objeto.PropostaDeVenda): Promise<Array<{ arquivo: string, descricao: string, extensao: string }> | null> {
        var ObjetoContrato = getObjetoContrato;
        var Response = await Venda.NovoContrato(ContextSignIn.getContext().token, PropostaDeVenda);
        if (Math.floor(Response.status / 100) == 2) {
            ObjetoContrato = Response.data
            await setObjetoContrato(ObjetoContrato);
            NotificacaoInterna.ExibirNotificacao("Contrato devidamente gerado", "O(s) documento(s) se encontra disponivel para download logo a baixo no campo 'Documento(s) gerado(s)'", NotificacaoInterna.TipoDeNotificacao.Sucesso);
            return ObjetoContrato;
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao gerar o contrato", "😱 Houve uma falha ao baixar o contrato, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
            return null;
        }
    };
    /* Sobre esta função *//**
     * Imprimir o contrato
    @param {string} descricao Informe o nome do arquivo
    @param {string} extensao Informe a extensão do arquivo
    @param {string} arquivo Informe a string do arquivo base64
     **/
    async function ImprimirContrato(descricao: string, extensao: string, arquivo: string): Promise<void> {
        try {
            window.open("")?.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(arquivo) + "'></iframe>");
            NotificacaoInterna.ExibirNotificacao("Seu contrato foi baixado com sucesso", `O documento encontra-se em uma nova jane-la deste mesmo navegador`, NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
        catch
        {
            let downloadLink = document.createElement("a");
            downloadLink.href = "data:application/octet-stream;charset=utf-16le;base64," + encodeURI(arquivo);
            downloadLink.download = descricao + "." + extensao;
            downloadLink.click();
            NotificacaoInterna.ExibirNotificacao("Seu contrato foi baixado com sucesso", `🖨️ Acesse a sua pasta de downloads e procure pelo o arquivo ˜${descricao}.${extensao}`, NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
    };
    /* Sobre esta função *//**
     * Imprimir o contrato
     **/
    async function NovoDocumento(): Promise<void> {
        NotificacaoInterna.ExibirNotificacao("Limpando o documento", "Aguarde enquanto limpamos todos os dados preenchidos", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        await setLimpandoDocumento(true, async () => {
            await setFormularioAnexoCheckListDeCadastro(undefined);
            await setFormularioAnexoCliente(undefined);
            await setFormularioNome("");
            await setFormularioEmail("");
            await setCPFEmConsulta(false);
            await setFormularioCPF("");
            await setFormularioRG("");
            await setFormularioRGUF("");
            await setFormularioDataDeNascimento(undefined);
            await setItemPickerFormularioNacionalidade(undefined);
            await setFormularioProfissao("");
            await setFormularioEstadoCivil(undefined);
            await setFormularioEstadoCivil(undefined);
            await setFormularioTelefoneCelular("");
            await setFormularioTelefoneComercial("");
            await setFormularioConjugeNome("");
            await setFormularioConjugeEmail("");
            await setFormularioConjugeCPF("");
            await setFormularioConjugeRG("");
            await setFormularioConjugeRGOrgaoEmissor("");
            await setFormularioConjugeRGUF("");
            await setFormularioConjugeDataDeNascimento(undefined);
            await setItemPickerFormularioConjugeNacionalidade(undefined);
            await setFormularioConjugeProfissao("");
            await setFormularioConjugeTelefoneCelular("");
            await setFormularioConjugeTelefoneComercial("");
            await setFormularioExibir02Comprador(false);
            await setFormulario02CompradorAnexoCliente(undefined);
            await setFormulario02CompradorNome("");
            await setFormulario02CompradorEmail("");
            await setFormulario02CompradorCPF("");
            await setFormulario02CompradorRG("");
            await setFormulario02CompradorRGOrgaoEmissor("");
            await setFormulario02CompradorRGUF("");
            await setFormulario02CompradorDataDeNascimento(undefined);
            await setItemPickerFormulario02CompradorNacionalidade(undefined);
            await setFormulario02CompradorProfissao("");
            await setFormulario02CompradorEstadoCivil(undefined);
            await setFormulario02CompradorTelefoneCelular("");
            await setFormulario02CompradorTelefoneComercial("");
            await setFormulario02CompradorAnexoConjuge(undefined);
            await setFormulario02CompradorConjugeNome("");
            await setFormulario02CompradorConjugeEmail("");
            await setFormulario02CompradorConjugeCPF("");
            await setFormulario02CompradorConjugeRG("");
            await setFormulario02CompradorConjugeRGOrgaoEmissor("");
            await setFormulario02CompradorConjugeRGUF("");
            await setFormulario02CompradorConjugeDataDeNascimento(undefined);
            await setItemPickerFormulario02CompradorConjugeNacionalidade(undefined);
            await setFormulario02CompradorConjugeProfissao("");
            await setFormulario02CompradorConjugeTelefoneCelular("");
            await setFormulario02CompradorConjugeTelefoneComercial("");
            await setCEPEmConsulta(false);
            await setFormularioCEP("");
            await setFormularioAnexoEndereco(undefined);
            await setFormularioLogradouro("");
            await setFormularioComplemento("");
            await setFormularioNumeroDeEndereco("");
            await setFormularioBairro("");
            await setFormularioCidade("");
            await setFormularioUF("");
            await setFormulario02CompradorCEP("");
            await setFormulario02CompradorAnexoEndereco(undefined);
            await setFormulario02CompradorLogradouro("");
            await setFormulario02CompradorComplemento("");
            await setFormulario02CompradorNumeroDeEndereco("");
            await setFormulario02CompradorBairro("");
            await setFormulario02CompradorCidade("");
            await setFormulario02CompradorUF("");
            await setFormularioLocalDeCaptacao("");
            await setItemPickerCentroDeCusto(undefined);
            await setItemPickerIdentificador(undefined);
            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
            await setFormularioAnexoComprovantePgtoSinal(undefined);
            await setFormularioSinalQtde(0);
            await setFormularioListaDeSinais([]);
            await setFormularioAnexoComprovantePgtoEntrada(undefined);
            await setFormularioEntradaQtde(0);
            await setFormularioListaDeEntradas([]);
            await setFormularioAnexoComprovantePgtoParcela(undefined);
            await setFormularioParcelaValor(0);
            await setFormularioParcelaQtde(0);
            await setFormularioParcelaVencimento(undefined);
            await setFormularioParcelaMeioDePagamento("");
            await setFormularioParcelaBanco(undefined);
            await setFormularioParcelaAgencia("");
            await setFormularioParcelaConta("");
            await setFormularioParcelaDigitoDaConta("");
            await setFormularioParcelaTitular("");
            await setFormularioParcelaNumeroCheque("");
            await setFormularioParcelaMaquina("");
            await setFormularioParcelaDigitoCartao("");
            await setFormularioParcelaBandeira("");
            await setFormularioParcelaOperacao("");
            await setFormularioParcelaNSU("");
            await setFormularioParcelaNumeroDaOperacao("");
            await setFormularioListaDeParcelas([]);
            await setFormularioIdCasal("");
            await setItemPickerSala(undefined);
            await setItemPickerPromotor(undefined);
            await setItemPickerAssessorTlmkt(undefined);
            await setItemPickerLiner(undefined);
            await setItemPickerCloser(undefined);
            await setItemPickerPEP(undefined);
            await setItemPickerSubGerenteDeSala(undefined);
            await setItemPickerGerenteDeSala(undefined);
            await setEnviandoPropostaDeVenda(false);
            await setEnviandoContratoParaAssinatura(false);
            await setObjetoPropostaDeVenda(undefined);
            await setObjetoContrato(undefined);
            await setFormularioDataDaProposta(new Date(new Date().toDateString()));
            await setFormularioAnexoFichaDeNegociacao(undefined);
            await setFormularioAnexoFichaDeAtendimento(undefined);
            await setFormularioAnexoDocumentacaoComplementar(undefined);
            await setItemPickerFinalidadeDeCompra(undefined);
            await setHabilitarInclusaoDeParcelas(false);
            NotificacaoInterna.ExibirNotificacao("Documento limpo 😜", "Preencha o formulario para realizar uma nova proposta", NotificacaoInterna.TipoDeNotificacao.Sucesso);
            await setLimpandoDocumento(false, () => {
                (RefPagina.current ?? {} as any).scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            });

        });
    };
    /* Sobre esta função *//**
     * Encaminhar o documento para assinatura
     **/
    async function EncaminharDocumentoParaAssinatura(): Promise<void> {
        await setEnviandoContratoParaAssinatura(true);
        {
            let Anexos = [] as Array<{ Nome: string, Conteudo: File | null | undefined }>;
            if (getFormularioAnexoCheckListDeCadastro != undefined && getFormularioAnexoCheckListDeCadastro != null) {
                Anexos.push({ Nome: "01. Check list de cadastro.pdf", Conteudo: getFormularioAnexoCheckListDeCadastro });
            }
            if (getFormularioAnexoCliente != undefined && getFormularioAnexoCliente != null) {
                Anexos.push({ Nome: "08. Doc cliente 01 comprador.pdf", Conteudo: getFormularioAnexoCliente });
            }
            if (getFormularioAnexoConjuge != undefined && getFormularioAnexoConjuge != null) {
                Anexos.push({ Nome: "09. Doc conjuge 01 comprador.pdf", Conteudo: getFormularioAnexoConjuge });
            }
            if (getFormularioAnexoEndereco != undefined && getFormularioAnexoEndereco != null) {
                Anexos.push({ Nome: "10. Comp endereco 01 comprador.pdf", Conteudo: getFormularioAnexoEndereco });
            }
            if (getFormulario02CompradorAnexoCliente != undefined && getFormulario02CompradorAnexoCliente != null) {
                Anexos.push({ Nome: "11. Doc cliente 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoCliente });
            }
            if (getFormulario02CompradorAnexoConjuge != undefined && getFormulario02CompradorAnexoConjuge != null) {
                Anexos.push({ Nome: "12. Doc conjuge 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoConjuge });
            }
            if (getFormulario02CompradorAnexoEndereco != undefined && getFormulario02CompradorAnexoEndereco != null) {
                Anexos.push({ Nome: "13. Comp endereco 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoEndereco });
            }
            if (getFormularioAnexoComprovantePgtoEntrada != undefined && getFormularioAnexoComprovantePgtoEntrada != null) {
                Anexos.push({ Nome: "14. Comp de pagamento da entrada.pdf", Conteudo: getFormularioAnexoComprovantePgtoEntrada });
            }
            if (getFormularioAnexoFichaDeNegociacao != undefined && getFormularioAnexoFichaDeNegociacao != null) {
                Anexos.push({ Nome: "15. Ficha de negociação.pdf", Conteudo: getFormularioAnexoFichaDeNegociacao });
            }
            if (getFormularioAnexoFichaDeAtendimento != undefined && getFormularioAnexoFichaDeAtendimento != null) {
                Anexos.push({ Nome: "19. Ficha de atendimento.pdf", Conteudo: getFormularioAnexoFichaDeAtendimento });
            }
            if (getFormularioAnexoDocumentacaoComplementar != undefined && getFormularioAnexoDocumentacaoComplementar != null) {
                Anexos.push({ Nome: "20. Documentação complementar.pdf", Conteudo: getFormularioAnexoDocumentacaoComplementar });
            }
            let Response = await Venda.NovosDocumentos(ContextSignIn.getContext().token, getObjetoPropostaDeVenda?.empresa.id ?? 0, getObjetoPropostaDeVenda?.centroDeCusto.sigla ?? "", getObjetoPropostaDeVenda?.numero ?? 0, Anexos);
            if (Math.floor(Response.status / 100) == 2) { }
            else {
                NotificacaoInterna.ExibirNotificacao("Falha ao enviar anexos do cliente", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao enviar o contrato para assinatura, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                await setEnviandoContratoParaAssinatura(false);
                return;
            }
        }
        if (getObjetoPropostaDeVenda) {
            NotificacaoInterna.ExibirNotificacao("Enviando contrato para assinatura", "Aguarde enquanto o documento é cadastrado no D4sign'", NotificacaoInterna.TipoDeNotificacao.Sucesso);
            let Response = await D4sign.Post(ContextSignIn.getContext().token, getItemPickerSala?.descricao ?? "undefined", getObjetoPropostaDeVenda);
            if (Math.floor(Response.status / 100) == 2) {
                NotificacaoInterna.ExibirNotificacao("Contrato devidamente enviado para assinatura", JSON.stringify(Response.data), NotificacaoInterna.TipoDeNotificacao.Sucesso);
            }
            else {
                NotificacaoInterna.ExibirNotificacao("Falha ao enviar o contrato para assinatura", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao enviar o contrato para assinatura, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
            }
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao enviar o contrato para assinatura", "O contrato não foi devidamente gerado, envie o contrato antes de prosseguir", NotificacaoInterna.TipoDeNotificacao.Erro);
        }
        await setEnviandoContratoParaAssinatura(false);
    };

    useEffect(() => {
        async function componentDidMount() {
            socket = io(String(process.env.REACT_APP_CONNECTION_PORT), { transports: ['websocket', 'polling', 'flashsocket'] })
        }
        componentDidMount();
    }, [process.env.REACT_APP_CONNECTION_PORT]);

    useEffect(() => {
        socket.on("receive_unidade", (data: any) => {
            if ((ItemPickerIdentificadorSinteticao?.subLocalId == data.unidade.subLocalId) && data.status != 0) {
                NotificacaoInterna.ExibirNotificacao("Caro Usuário", "A unidade selecionada, foi vendida ou reservada no momento, por favor selecione outra unidade ou disponibilidade a unidade para venda.", NotificacaoInterna.TipoDeNotificacao.Alerta);
            }
            else if ((ItemPickerIdentificadorSinteticao?.subLocalId != data.unidade.subLocalId) && (data.status == 2 || data.status == 3)) {
                let ListaDeUnidades = [...getListaPickerIdentificadorSintetico];
                let ListaFiltrada = ListaDeUnidades.filter(lote => lote.subLocalId != data.unidade.subLocalId)
                timelineLoaded.current = true;
                setListaPickerIdentificadorSintetico(ListaFiltrada);
                timelineLoaded.current = false;
            }
        })
        return () => { socket.off('receive_unidade') }
    }, [getListaPickerIdentificadorSintetico]);

    useEffect(() => {
        async function componentDidMount() {
            let Response = await Venda.TerminalDePagamento(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "");
            if (Math.floor(Response.status / 100) == 2) {
                setTerminais(Response.data)
            }
            else {
                //NotificacaoInterna.ExibirNotificacao("Falha ao enviar anexos do cliente", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao enviar o contrato para assinatura, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                return;
            }
        }
        componentDidMount();
    }, [getItemPickerCentroDeCusto]);
    const connectToRoom = (room: string | undefined) => {
        socket.emit('join_room', room)
        console.log(`Conectado a sala: ${room}`)
    }

    const sendUnidadeSintetico = async (unidade: Objeto.IdentificadorSintetico, sigla: string, lista: Array<Objeto.IdentificadorSintetico>, store: Array<Store>) => {
        let UnidadeContent = {
            room: sigla,
            content: {
                id: 0,
                unidade: unidade,
                status: unidade.status === 2 ? 3 : unidade.status,
            },
        }
        await socket.emit("send_unidade", UnidadeContent);
        socket.off('receive_unidade');
        socket.on("receive_unidade", (data: any) => {
            if ((ItemPickerIdentificadorSinteticao?.subLocalId == data.unidade.subLocalId) && data.status != 0) {
                NotificacaoInterna.ExibirNotificacao("Caro Usuário", "A unidade selecionada, foi vendida ou reservada no momento, por favor selecione outra unidade ou disponibilidade a unidade para venda.", NotificacaoInterna.TipoDeNotificacao.Alerta);
            }
            else if ((ItemPickerIdentificadorSinteticao?.subLocalId != data.unidade.subLocalId) && (data.status == 2 || data.status == 3)) {
                let ListaDeUnidades = [...getListaPickerIdentificadorSintetico];
                let ListaFiltrada = ListaDeUnidades.filter(lote => lote.subLocalId != data.unidade.subLocalId)

                timelineLoaded.current = true;
                setListaPickerIdentificadorSintetico(ListaFiltrada);
                timelineLoaded.current = false;
            }
        })
    }

    const sendUnidade = async (unidade: Objeto.Identificador, sigla: string, lista: Array<Objeto.Identificador>, store: Array<Store>) => {
        let UnidadeContent = {
            room: sigla,
            content: {
                id: unidade.indice,
                unidade: unidade,
                status: unidade.status === 2 ? 3 : unidade.status,
            },
        }
        await socket.emit("send_unidade", UnidadeContent);
        socket.off('receive_unidade');
        socket.on("receive_unidade", (data: any) => {
            if ((ItemPickerIdentificador?.subLocal.id == data.unidade.subLocal.id) && data.status != 0) {
                NotificacaoInterna.ExibirNotificacao("Caro Usuário", "A unidade selecionada, foi vendida ou reservada no momento, por favor selecione outra unidade ou disponibilidade a unidade para venda.", NotificacaoInterna.TipoDeNotificacao.Alerta);
            }
            else if ((ItemPickerIdentificador?.subLocal.id != data.unidade.subLocal.id) && (data.status == 2 || data.status == 3)) {
                let ListaDeUnidades = [...getListaPickerIdentificador];
                let ListaFiltrada = ListaDeUnidades.filter(lote => lote.subLocal.id != data.unidade.subLocal.id)

                timelineLoaded.current = true;
                setListaPickerIdentificador(ListaFiltrada);
                timelineLoaded.current = false;
            }
        })
    }

    useEffect(() => {
        async function componentDidMount() {
            try {
                Toast.configure();
                await setListaPickerNaturalidade((await Lead.Naturalidades(ContextSignIn.getContext().token)).data);
                await setFormularioListaEstadoCivil((await Pessoa.EstadoCivil(ContextSignIn.getContext().token)).data);
                await setListaPickerNacionalidade((await Nacao.Get(ContextSignIn.getContext().token)).data);


                let ListaPickerCentroDeCusto = (await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data;
                await setListaPickerCentroDeCusto(ListaPickerCentroDeCusto);
                if (ListaPickerCentroDeCusto.find((Item) => Item.empresa.id == viewModel.identificador.empresa.id && Item.sigla == viewModel.identificador.centroDeCusto.sigla)) {
                    connectToRoom(viewModel.identificador.centroDeCusto.sigla);
                    setItemPickerCentroDeCusto(ListaPickerCentroDeCusto.find((Item) => Item.empresa.id == viewModel.identificador.empresa.id && Item.sigla == viewModel.identificador.centroDeCusto.sigla));
                }
                let UnidadesSinteticas = (await Identificador.FastGetSintetico(ContextSignIn.getContext().token, true, viewModel.identificador.empresa.id ?? 0, viewModel.identificador.centroDeCusto.sigla ?? "")).data
                let UnidadeSintetica = UnidadesSinteticas.find((Item) => Item.localId == viewModel.identificador.local.id && Item.subLocalId == viewModel.identificador.subLocal.id);
                if (viewModel.identificador && UnidadeSintetica) {
                    setItemPickerIdentificador(viewModel.identificador);
                    setItemPickerIdentificadorSintetico(UnidadeSintetica);
                    setListaPickerIdentificador([viewModel.identificador]);
                    setListaPickerIdentificadorSintetico([UnidadeSintetica]);
                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, viewModel.identificador.empresa.id ?? 0, viewModel.identificador.centroDeCusto.sigla ?? "", viewModel.identificador.local.id ?? 0, viewModel.identificador.subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                    await setFormularioEntradaQtde(0);
                    await setFormularioListaDeEntradas([]);
                    await setFormularioSinalQtde(0);
                    await setFormularioListaDeSinais([]);
                    await setFormularioParcelaValor(0);
                    await setFormularioParcelaQtde(0);
                    await setFormularioParcelaVencimento(undefined);
                    await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, viewModel.identificador.empresa.id ?? 0, viewModel.identificador.centroDeCusto.sigla ?? "", viewModel.identificador.identificadorVinculado?.softwareExterno.token ?? "")).data);
                    await setItemPickerFinalidadeDeCompra(undefined);
                }

                var Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                    .get(`/Identificador/VisaoGAV02/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}`))
                    .data as Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>;
                await setListaPickerSala(Response);
            }
            catch { }
        }
        componentDidMount();
        console.log()
    }, []);

    return (
        <Component.Container ref={RefPagina}>
            <CollapseBlueprint isOpen={getCollapse}>
                <div className="endereco">
                    <img src={IconeHome} style={{ cursor: "pointer" }} alt="" onClick={() => { History.goBack(); }} />
                    <div className="bar">/</div>
                    <div className="place">Venda Direta</div>
                </div>
            </CollapseBlueprint>
            <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>
            <Card style={{ width: "88%", backgroundColor: "#ffffff", margin: "40px auto 0 auto", borderRadius: "5px", boxShadow: "none", padding: "40px 3% 40px 3% " }}>
                <Component.ContentCard style={{ padding: "0 3%" }} >
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }} />
                </Component.ContentCard>
                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && <Component.ContentCard style={{ padding: "0 3%", paddingBottom: "10px" }} >
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Check list de cadastro</h3>
                        </div>
                        <FileInput style={{ width: "50vw", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoCheckListDeCadastro == undefined ? "Anexe o check list de cadastro" : getFormularioAnexoCheckListDeCadastro.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                setFormularioAnexoCheckListDeCadastro(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoCheckListDeCadastro && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoCheckListDeCadastro(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                </Component.ContentCard>}
                <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Dados do cliente</h3>
                        </div>
                        <FileInput style={{ width: "calc(100% - 235px)", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoCliente == undefined ? "Anexe o documento do cliente" : getFormularioAnexoCliente.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                setFormularioAnexoCliente(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoCliente && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoCliente(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <div className="Conteiner">
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "CPF:" : "CNPJ:"} inline={true}>
                                    <InputGroup id="CPF" placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormularioCPF)} leftIcon={getCPFEmConsulta ? "geosearch" : false}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                            await setFormularioCPF(TextoDigitado);
                                            if (CPF.TextoValido(TextoDigitado) || CNPJ.TextoValido(TextoDigitado)) {
                                                await setCPFEmConsulta(true);
                                                switch (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado) {
                                                    case 4:
                                                        try {
                                                            let Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                                                                .get(`/Pessoa/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}/${TextoDigitado}`))
                                                                .data as any;
                                                            await setFormularioNome(Response.nome ?? "");
                                                            await setFormularioEmail(Response.emails[0].descricao ?? "");
                                                            await setFormularioDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                            try {
                                                                let TelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                                await setFormularioTelefoneCelular(Telefone.FormatarTexto((TelefoneCelular?.ddd ?? "") + (TelefoneCelular?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try {
                                                                let TelefoneComercial = Response.telefones.find((Item: any) => Item.classificacao == 2);
                                                                await setFormularioTelefoneComercial(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try { await setFormularioRG(Response.rg.numero ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGOrgaoEmissor(Response.rg.orgaoEmissor ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGUF(Response.rg.uf ?? ""); }
                                                            catch { }
                                                            await setFormularioProfissao(Response.observacao ?? "");
                                                            await setItemPickerFormularioNacionalidade(getListaPickerNacionalidade.filter(x => (x.masculino.toUpperCase() ?? "Brasileiro".toUpperCase()) == Response.nacionalidade.toUpperCase())[0]);
                                                            await setFormularioCEP(Response.endereco.cep ?? "");
                                                            await setFormularioLogradouro(Response.endereco.logradouro ?? "");
                                                            await setFormularioComplemento(Response.endereco.complemento ?? "");
                                                            await setFormularioNumeroDeEndereco(Response.endereco.numero ?? "");
                                                            await setFormularioBairro(Response.endereco.bairro ?? "");
                                                            await setFormularioCidade(Response.endereco.cidade ?? "");
                                                            await setFormularioUF(Response.endereco.uf ?? "");
                                                            await setFormularioEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                            if (Response.conjuge) {
                                                                await setFormularioConjugeNome(Response.conjuge.nome ?? "");
                                                                await setFormularioConjugeCPF(Response.conjuge.cpf ?? "");
                                                                await setFormularioConjugeEmail(Response.conjuge.emails[0].descricao ?? "");
                                                                await setFormularioConjugeDataDeNascimento(new Date(Response.conjuge.dataDeNascimento) ?? "");
                                                                try { await setFormularioConjugeRG(Response.conjuge.rg.numero ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGOrgaoEmissor(Response.conjuge.rg.orgaoEmissor ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGUF(Response.conjuge.rg.uf ?? ""); }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneCelular = Response.conjuge.telefones.find((Item: any) => Item.classificacao == 1);
                                                                    await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneComercial = Response.Dados.conjuge.telefones.find((Item: any) => Item.classificacao == 2);
                                                                    await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                await setFormularioConjugeProfissao(Response.conjuge.observacao ?? "");
                                                            }
                                                        }
                                                        catch { }
                                                        finally { await setCPFEmConsulta(false); }
                                                        break;
                                                    case 6:
                                                        try {
                                                            let Response = (await Pessoa.Get(ContextSignIn.getContext().token, TextoDigitado)).data;
                                                            await setFormularioNome(Response.nome ?? "");
                                                            await setFormularioEmail(Response.emails[0].descricao ?? "");
                                                            await setFormularioDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                            try {
                                                                let TelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                                await setFormularioTelefoneCelular(Telefone.FormatarTexto((TelefoneCelular?.ddd ?? "") + (TelefoneCelular?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try {
                                                                let TelefoneComercial = Response.telefones.find((Item: any) => Item.classificacao == 2);
                                                                await setFormularioTelefoneComercial(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try { await setFormularioRG(Response.rg?.numero ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGOrgaoEmissor(Response.rg?.orgaoemissor ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGUF(Response.rg?.uf ?? ""); }
                                                            catch { }
                                                            await setFormularioProfissao(Response.ocupacao?.nome ?? "");
                                                            await setItemPickerFormularioNacionalidade(getListaPickerNacionalidade.filter(x => (x.masculino.toUpperCase() ?? "Brasileiro".toUpperCase()) == Response.nacionalidade?.masculino.toUpperCase())[0]);
                                                            await setFormularioCEP(Response.endereco?.cep ?? "");
                                                            await setFormularioLogradouro(Response.endereco?.logradouro ?? "");
                                                            await setFormularioComplemento(Response.endereco?.complemento ?? "");
                                                            await setFormularioNumeroDeEndereco(Response.endereco?.numero ?? "");
                                                            await setFormularioBairro(Response.endereco?.bairro ?? "");
                                                            await setFormularioCidade(Response.endereco?.cidade ?? "");
                                                            await setFormularioUF(Response.endereco?.uf ?? "");
                                                            await setFormularioEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                            if (Response.conjuge) {
                                                                await setFormularioConjugeNome(Response.conjuge.nome ?? "");
                                                                await setFormularioConjugeCPF(Response.conjuge.cpf ?? "");
                                                                await setFormularioConjugeEmail(Response.conjuge.emails[0].descricao ?? "");
                                                                await setFormularioConjugeDataDeNascimento(new Date(Response.conjuge.datadenascimento) ?? "");
                                                                try { await setFormularioConjugeRG(Response.conjuge.rg.numero ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGOrgaoEmissor(Response.conjuge.rg.orgaoemissor ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGUF(Response.conjuge.rg.uf ?? ""); }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneCelular = Response.conjuge.telefone?.find((Item: any) => Item.classificacao == 1);
                                                                    await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneComercial = Response.conjuge.telefone?.find((Item: any) => Item.classificacao == 2);
                                                                    await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                await setFormularioConjugeProfissao(Response.conjuge.ocupacao?.nome ?? "");
                                                            }
                                                        }
                                                        catch { }
                                                        finally { await setCPFEmConsulta(false); }
                                                        break;
                                                    case 9:
                                                        try {
                                                            let Response = (await Pessoa.Get(ContextSignIn.getContext().token, TextoDigitado)).data;
                                                            await setFormularioNome(Response.nome ?? "");
                                                            await setFormularioEmail(Response.emails[0].descricao ?? "");
                                                            await setFormularioDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                            try {
                                                                let TelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                                await setFormularioTelefoneCelular(Telefone.FormatarTexto((TelefoneCelular?.ddd ?? "") + (TelefoneCelular?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try {
                                                                let TelefoneComercial = Response.telefones.find((Item: any) => Item.classificacao == 2);
                                                                await setFormularioTelefoneComercial(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try { await setFormularioRG(Response.rg?.numero ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGOrgaoEmissor(Response.rg?.orgaoemissor ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGUF(Response.rg?.uf ?? ""); }
                                                            catch { }
                                                            await setFormularioProfissao(Response.ocupacao?.nome ?? "");
                                                            await setItemPickerFormularioNacionalidade(getListaPickerNacionalidade.filter(x => (x.masculino.toUpperCase() ?? "Brasileiro".toUpperCase()) == Response.nacionalidade?.masculino.toUpperCase())[0]);
                                                            await setFormularioCEP(Response.endereco?.cep ?? "");
                                                            await setFormularioLogradouro(Response.endereco?.logradouro ?? "");
                                                            await setFormularioComplemento(Response.endereco?.complemento ?? "");
                                                            await setFormularioNumeroDeEndereco(Response.endereco?.numero ?? "");
                                                            await setFormularioBairro(Response.endereco?.bairro ?? "");
                                                            await setFormularioCidade(Response.endereco?.cidade ?? "");
                                                            await setFormularioUF(Response.endereco?.uf ?? "");
                                                            await setFormularioEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                            if (Response.conjuge) {
                                                                await setFormularioConjugeNome(Response.conjuge.nome ?? "");
                                                                await setFormularioConjugeCPF(Response.conjuge.cpf ?? "");
                                                                await setFormularioConjugeEmail(Response.conjuge.emails[0].descricao ?? "");
                                                                await setFormularioConjugeDataDeNascimento(new Date(Response.conjuge.datadenascimento) ?? "");
                                                                try { await setFormularioConjugeRG(Response.conjuge.rg.numero ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGOrgaoEmissor(Response.conjuge.rg.orgaoemissor ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGUF(Response.conjuge.rg.uf ?? ""); }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneCelular = Response.conjuge.telefone?.find((Item: any) => Item.classificacao == 1);
                                                                    await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneComercial = Response.conjuge.telefone?.find((Item: any) => Item.classificacao == 2);
                                                                    await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                await setFormularioConjugeProfissao(Response.conjuge.ocupacao?.nome ?? "");
                                                            }
                                                        }
                                                        catch { }
                                                        finally { await setCPFEmConsulta(false); }
                                                        break;
                                                    case 10:
                                                        try {
                                                            let Response = (await Pessoa.Get(ContextSignIn.getContext().token, TextoDigitado)).data;
                                                            await setFormularioNome(Response.nome ?? "");
                                                            await setFormularioEmail(Response.emails[0].descricao ?? "");
                                                            await setFormularioDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                            try {
                                                                let TelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                                await setFormularioTelefoneCelular(Telefone.FormatarTexto((TelefoneCelular?.ddd ?? "") + (TelefoneCelular?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try {
                                                                let TelefoneComercial = Response.telefones.find((Item: any) => Item.classificacao == 2);
                                                                await setFormularioTelefoneComercial(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try { await setFormularioRG(Response.rg?.numero ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGOrgaoEmissor(Response.rg?.orgaoemissor ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGUF(Response.rg?.uf ?? ""); }
                                                            catch { }
                                                            await setFormularioProfissao(Response.ocupacao?.nome ?? "");
                                                            await setItemPickerFormularioNacionalidade(getListaPickerNacionalidade.filter(x => (x.masculino.toUpperCase() ?? "Brasileiro".toUpperCase()) == Response.nacionalidade?.masculino.toUpperCase())[0]);
                                                            await setFormularioCEP(Response.endereco?.cep ?? "");
                                                            await setFormularioLogradouro(Response.endereco?.logradouro ?? "");
                                                            await setFormularioComplemento(Response.endereco?.complemento ?? "");
                                                            await setFormularioNumeroDeEndereco(Response.endereco?.numero ?? "");
                                                            await setFormularioBairro(Response.endereco?.bairro ?? "");
                                                            await setFormularioCidade(Response.endereco?.cidade ?? "");
                                                            await setFormularioUF(Response.endereco?.uf ?? "");
                                                            await setFormularioEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                            if (Response.conjuge) {
                                                                await setFormularioConjugeNome(Response.conjuge.nome ?? "");
                                                                await setFormularioConjugeCPF(Response.conjuge.cpf ?? "");
                                                                await setFormularioConjugeEmail(Response.conjuge.emails[0].descricao ?? "");
                                                                await setFormularioConjugeDataDeNascimento(new Date(Response.conjuge.datadenascimento) ?? "");
                                                                try { await setFormularioConjugeRG(Response.conjuge.rg.numero ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGOrgaoEmissor(Response.conjuge.rg.orgaoemissor ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGUF(Response.conjuge.rg.uf ?? ""); }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneCelular = Response.conjuge.telefone?.find((Item: any) => Item.classificacao == 1);
                                                                    await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneComercial = Response.conjuge.telefone?.find((Item: any) => Item.classificacao == 2);
                                                                    await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                await setFormularioConjugeProfissao(Response.conjuge.ocupacao?.nome ?? "");
                                                            }
                                                        }
                                                        catch { }
                                                        finally { await setCPFEmConsulta(false); }
                                                        break;
                                                    default:
                                                        await setCPFEmConsulta(false);
                                                        break;
                                                }
                                            }
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "Nome: " : "Razão social: "} inline={true}>
                                    <InputGroup id="Nome" placeholder="Nome" value={getFormularioNome}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioNome(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "Nascimento:" : "Data de abertura:"} labelFor="Nascimento" inline={true}>
                                    <DateInput inputProps={{ style: { width: "244px", marginLeft: "15px" }, onClick: () => setFormularioExibirCalendarioDataDeNascimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{ disabled: !getFormularioExibirCalendarioDataDeNascimento, onClosed: () => setFormularioExibirCalendarioDataDeNascimento(false) }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormularioDataDeNascimento(DataSelecionada)}
                                        value={getFormularioDataDeNascimento} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                    </DateInput>
                                </FormGroup>
                                <FormGroup label="Nacionalidade:" labelFor="Nacionalidade" inline={true}>
                                    {getListaPickerNacionalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                    <PickerNacionalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNacionalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: Objeto.Nacao) => <MenuItem onClick={async () => await setItemPickerFormularioNacionalidade(Item)} text={Item.nacionalidade ?? ""} />}
                                        itemPredicate={(Texto: string, Item: Objeto.Nacao) => { return (Item.nacionalidade ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button id="Nacionalidade" text={getItemPickerFormularioNacionalidade?.nacionalidade ?? "Selecione a nacionalidade"} rightIcon="double-caret-vertical" />
                                    </PickerNacionalidade>
                                    {getItemPickerFormularioNacionalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormularioNacionalidade(undefined); }} />}
                                </FormGroup>
                                {false && <FormGroup label="Naturalidade:" labelFor="Naturalidade" inline={true}>
                                    {getListaPickerNaturalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                    <PickerNaturalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNaturalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { id: number, cidade: string, uf: string }) => <MenuItem onClick={async () => await setItemPickerFormularioNaturalidade(Item)} text={`${Item.cidade}-${Item.uf}` ?? ""} />}
                                        itemPredicate={(Texto: string, Item: { id: number, cidade: string, uf: string }) => { return (`${Item.cidade}-${Item.uf}` ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button id="Naturalidade" text={getItemPickerFormularioNaturalidade ? `${getItemPickerFormularioNaturalidade?.cidade}-${getItemPickerFormularioNaturalidade?.uf}` : "Selecione a naturalidade"} rightIcon="double-caret-vertical" />
                                    </PickerNaturalidade>
                                    {getItemPickerFormularioNaturalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormularioNaturalidade(undefined); }} />}
                                </FormGroup>}
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                <FormGroup label="Email:" inline={true}>
                                    <InputGroup id="Email" placeholder="Email" value={getFormularioEmail}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioEmail(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && <div>
                                    <FormGroup label="Tel. celular:" inline={true}>
                                        <InputGroup id="TelefoneCelular" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormularioTelefoneCelular}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioTelefoneCelular(Telefone.FormatarTexto(event.currentTarget.value))}>
                                        </InputGroup>
                                    </FormGroup>
                                </div>}
                                <FormGroup label="Tel. comercial:" inline={true}>
                                    <InputGroup id="TelefoneComercial" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormularioTelefoneComercial}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioTelefoneComercial(Telefone.FormatarTexto(event.currentTarget.value))}>
                                    </InputGroup>
                                </FormGroup>
                                {CPF.DesformatarTexto(getFormularioCPF).length <= 11 &&
                                    <FormGroup label="Profissão:" labelFor="profissão" inline={true}>
                                        <div id="profissão">
                                            <InputGroup size={30} placeholder="Informe a profissão" leftElement={<Icon icon="briefcase" />} value={getFormularioProfissao}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioProfissao(event.currentTarget.value)}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>}
                                {false && CPF.DesformatarTexto(getFormularioCPF).length <= 11 &&
                                    <FormGroup label="Pai:" labelFor="Pai" inline={true}>
                                        <div id="Pai">
                                            <InputGroup size={30} placeholder="Informe a pai" leftElement={<Icon icon="briefcase" />} value={getFormularioPai}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioPai(event.currentTarget.value)}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>}
                                {false && CPF.DesformatarTexto(getFormularioCPF).length <= 11 &&
                                    <FormGroup label="Mãe:" labelFor="Mae" inline={true}>
                                        <div id="Mae">
                                            <InputGroup size={30} placeholder="Informe a mãe" leftElement={<Icon icon="briefcase" />} value={getFormularioMae}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioMae(event.currentTarget.value)}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>}
                            </div>
                            {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && <div>
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                    <FormGroup label="RG:" inline={true}>
                                        <InputGroup id="RG" placeholder={"00000000000"} value={getFormularioRG}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioRG(event.currentTarget.value)}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Orgão emissor:" inline={true} >
                                        <InputGroup id="OrgaoEmissor" placeholder={"Informe o orgão emissor"} value={getFormularioRGOrgaoEmissor}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioRGOrgaoEmissor(event.currentTarget.value)}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                        <HTMLSelect id="RGUF" name="RGUF" value={getFormularioRGUF}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormularioRGUF(event.currentTarget.value)}>
                                            <option selected value="">Selecione a UF</option>
                                            {UF.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                        {getFormularioRGUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormularioRGUF(""); }} />}
                                    </FormGroup>
                                    {false && <FormGroup label="CNH:" inline={true}>
                                        <InputGroup id="CNH" placeholder={"00000000000"} value={getFormularioCNH}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioCNH(event.currentTarget.value)}>
                                        </InputGroup>
                                    </FormGroup>}
                                    {false && <FormGroup label="Data exp.:" labelFor="DataExpedicao" inline={true}>
                                        <DateInput inputProps={{ style: { width: "244px", marginLeft: "40px" }, onClick: () => setFormularioExibirCalendarioCNHExpedicao(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{ disabled: !getFormularioExibirCalendarioCNHExpedicao, onClosed: () => setFormularioExibirCalendarioCNHExpedicao(false) }}
                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormularioCNHExpedicao(DataSelecionada)}
                                            value={getFormularioCNHExpedicao} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                        </DateInput>
                                    </FormGroup>}
                                    {false && <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                        <HTMLSelect id="CNHUF" name="CNHUF" value={getFormularioCNHUF}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormularioCNHUF(event.currentTarget.value)}>
                                            <option selected value="">Selecione a UF</option>
                                            {UF.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                        {getFormularioCNHUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormularioCNHUF(""); }} />}
                                    </FormGroup>}
                                </div>
                            </div>}
                        </div>
                    </Component.ContentFormHeader>
                    {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && <Component.FormContent>
                        <Component.ContentFormHeader>
                            <Component.FormContent>
                                <FormGroup labelFor="EstadoCivil" style={{ backgroundColor: "0" }}>
                                    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", marginLeft: "-10px" }}>
                                        {getFormularioListaEstadoCivil?.map((Item) =>
                                            <Radio style={{ display: "flex", alignItems: "center", marginLeft: "10px" }} name="EstadoCivil" label={Item.descricao} value={Item.id}
                                                onChange={async () => await setFormularioEstadoCivil(Item)}
                                                checked={Item == getFormularioEstadoCivil}>
                                            </Radio>
                                        )}
                                    </div>
                                </FormGroup>
                            </Component.FormContent>
                        </Component.ContentFormHeader>
                    </Component.FormContent>}
                </Component.ContentCard>
                {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && (getFormularioEstadoCivil?.id == 2 || getFormularioEstadoCivil?.id == 7) && (<Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Dados do conjugê</h3>
                        </div>
                        <FileInput style={{ width: "calc(100% - 248px)", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoConjuge == undefined ? "Anexe o documento do conjugê" : getFormularioAnexoConjuge.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoConjuge(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoConjuge && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoConjuge(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <div className="Conteiner">
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioConjugeCPF).length <= 11 ? "CPF:" : "CNPJ:"} inline={true}>
                                    <InputGroup id="CPF" placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormularioConjugeCPF)} leftIcon={getCPFEmConsulta ? "geosearch" : false}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                            await setFormularioConjugeCPF(TextoDigitado);
                                            if (CPF.TextoValido(TextoDigitado)) {
                                                await setCPFEmConsulta(true);
                                                try {
                                                    var Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                                                        .get(`/Pessoa/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}/${TextoDigitado}`))
                                                        .data as any;
                                                    await setFormularioConjugeNome(Response.nome ?? "");
                                                    await setFormularioConjugeEmail(Response.emails[0].descricao ?? "");
                                                    await setFormularioConjugeDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                    try { await setFormularioConjugeRG(Response.rg.numero ?? ""); }
                                                    catch { }
                                                    try { await setFormularioConjugeRGOrgaoEmissor(Response.rg.orgaoEmissor ?? ""); }
                                                    catch { }
                                                    try { await setFormularioConjugeRGUF(Response.rg.uf ?? ""); }
                                                    catch { }
                                                    try {
                                                        let ConjugeTelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                        await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                    }
                                                    catch { }
                                                    try {
                                                        let ConjugeTelefoneComercial = Response.Dados.telefones.find((Item: any) => Item.classificacao == 2);
                                                        await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                    }
                                                    catch { }
                                                    await setFormularioConjugeProfissao(Response.observacao ?? "");
                                                }
                                                catch { }
                                                finally { await setCPFEmConsulta(false); }
                                            }
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioConjugeCPF).length <= 11 ? "Nome:" : "Razão social:"} inline={true}>
                                    <InputGroup id="Nome" placeholder="Nome" value={getFormularioConjugeNome}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeNome(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioConjugeCPF).length <= 11 ? "Nascimento:" : "Data de abertura:"} labelFor="EntradaVencimento" inline={true}>
                                    <DateInput inputProps={{ style: { width: "244px", marginLeft: "15px" }, onClick: () => setFormularioExibirCalendarioConjugeDataDeNascimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{ disabled: !getFormularioExibirCalendarioConjugeDataDeNascimento, onClosed: () => setFormularioExibirCalendarioConjugeDataDeNascimento(false) }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormularioConjugeDataDeNascimento(DataSelecionada)}
                                        value={getFormularioConjugeDataDeNascimento} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                    </DateInput>
                                </FormGroup>
                                <FormGroup label="Nacionalidade:" labelFor="Nacionalidade" inline={true}>
                                    {getListaPickerNacionalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                    <PickerNacionalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNacionalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: Objeto.Nacao) => <MenuItem onClick={async () => await setItemPickerFormularioConjugeNacionalidade(Item)} text={Item.nacionalidade ?? ""} />}
                                        itemPredicate={(Texto: string, Item: Objeto.Nacao) => { return (Item.nacionalidade ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={getItemPickerFormularioConjugeNacionalidade?.nacionalidade ?? "Selecione a nacionalidade"} rightIcon="double-caret-vertical" />
                                    </PickerNacionalidade>
                                    {getItemPickerFormularioConjugeNacionalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormularioConjugeNacionalidade(undefined); }} />}
                                </FormGroup>
                                {false && <FormGroup label="Naturalidade:" labelFor="Naturalidade" inline={true}>
                                    {getListaPickerNaturalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                    <PickerNaturalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNaturalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { id: number, cidade: string, uf: string }) => <MenuItem onClick={async () => await setItemPickerFormularioConjugeNaturalidade(Item)} text={`${Item.cidade}-${Item.uf}` ?? ""} />}
                                        itemPredicate={(Texto: string, Item: { id: number, cidade: string, uf: string }) => { return (`${Item.cidade}-${Item.uf}` ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button id="Naturalidade" text={getItemPickerFormularioConjugeNaturalidade ? `${getItemPickerFormularioConjugeNaturalidade?.cidade}-${getItemPickerFormularioConjugeNaturalidade?.uf}` : "Selecione a naturalidade"} rightIcon="double-caret-vertical" />
                                    </PickerNaturalidade>
                                    {getItemPickerFormularioConjugeNaturalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormularioConjugeNaturalidade(undefined); }} />}
                                </FormGroup>}
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                <FormGroup label="Email:" inline={true}>
                                    <InputGroup id="Email" placeholder="Email" value={getFormularioConjugeEmail}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeEmail(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Tel. celular:" inline={true}>
                                    <InputGroup id="TelefoneCelular" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormularioConjugeTelefoneCelular}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto(event.currentTarget.value))}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Tel. comercial:" inline={true}>
                                    <InputGroup id="TelefoneComercial" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormularioConjugeTelefoneComercial}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto(event.currentTarget.value))}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Profissão:" labelFor="profissão" inline={true}>
                                    <div id="profissão">
                                        <InputGroup id="profissao" size={30} placeholder="Informe a profissão" leftElement={<Icon icon="briefcase" />} value={getFormularioConjugeProfissao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeProfissao(event.currentTarget.value)}>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                                {false && CPF.DesformatarTexto(getFormularioConjugeCPF).length <= 11 &&
                                    <FormGroup label="Pai:" labelFor="Pai" inline={true}>
                                        <div id="Pai">
                                            <InputGroup size={30} placeholder="Informe a pai" leftElement={<Icon icon="briefcase" />} value={getFormularioConjugePai}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugePai(event.currentTarget.value)}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>}
                                {false && CPF.DesformatarTexto(getFormularioConjugeCPF).length <= 11 &&
                                    <FormGroup label="Mãe:" labelFor="Mae" inline={true}>
                                        <div id="Mae">
                                            <InputGroup size={30} placeholder="Informe a mãe" leftElement={<Icon icon="briefcase" />} value={getFormularioConjugeMae}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeMae(event.currentTarget.value)}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>}
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                <FormGroup label="RG:" inline={true}>
                                    <InputGroup id="RG" placeholder={"00000000000"} value={getFormularioConjugeRG}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeRG(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Orgão emissor:" inline={true} >
                                    <InputGroup id="OrgaoEmissor" placeholder={"Informe o orgão emissor"} value={getFormularioConjugeRGOrgaoEmissor}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeRGOrgaoEmissor(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                    <HTMLSelect id="RGUF" name="RGUF" value={getFormularioConjugeRGUF}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormularioConjugeRGUF(event.currentTarget.value)}>
                                        <option selected value="">Selecione a UF</option>
                                        {UF.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                    {getFormularioConjugeRGUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormularioConjugeRGUF(""); }} />}
                                </FormGroup>
                                {false && <FormGroup label="CNH:" inline={true}>
                                    <InputGroup id="CNH" placeholder={"00000000000"} value={getFormularioConjugeCNH}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeCNH(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>}
                                {false && <FormGroup label="Data exp.:" labelFor="DataExpedicao" inline={true}>
                                    <DateInput inputProps={{ style: { width: "244px", marginLeft: "40px" }, onClick: () => setFormularioConjugeExibirCalendarioCNHExpedicao(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{ disabled: !getFormularioConjugeExibirCalendarioCNHExpedicao, onClosed: () => setFormularioConjugeExibirCalendarioCNHExpedicao(false) }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormularioConjugeCNHExpedicao(DataSelecionada)}
                                        value={getFormularioConjugeCNHExpedicao} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                    </DateInput>
                                </FormGroup>}
                                {false && <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                    <HTMLSelect id="CNHUF" name="CNHUF" value={getFormularioConjugeCNHUF}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormularioConjugeCNHUF(event.currentTarget.value)}>
                                        <option selected value="">Selecione a UF</option>
                                        {UF.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                    {getFormularioConjugeCNHUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormularioConjugeCNHUF(""); }} />}
                                </FormGroup>}
                            </div>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>)}
                <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="map-marker" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Endereço</h3>
                        </div>
                        <FileInput style={{ width: "calc(100% - 150px)", minWidth: "250px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoEndereco == undefined ? "Anexe o comprovante de endereço" : getFormularioAnexoEndereco.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoEndereco(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoEndereco && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoEndereco(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <div className="Conteiner">
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0", flexDirection: "column" }}>
                                <FormGroup label="CEP:" inline={true}>
                                    <InputGroup id="CEP" placeholder="Informe o CEP" value={CEP.FormatarTexto(getFormularioCEP)} leftIcon={getCEPEmConsulta ? "geosearch" : false}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            var CEPDesformatado = CEP.DesformatarTexto(event.currentTarget.value);
                                            await setFormularioCEP(CEPDesformatado);
                                            if (CEPDesformatado.length == 8) {
                                                try {
                                                    await setCEPEmConsulta(true);
                                                    var Response = (await Correios.Get(ContextSignIn.getContext().token, CEPDesformatado)).data;
                                                    await setFormularioLogradouro(Response.Logradouro);
                                                    await setFormularioComplemento(Response.Complemento);
                                                    await setFormularioBairro(Response.Bairro);
                                                    await setFormularioCidade(Response.Cidade);
                                                    await setFormularioUF(Response.UF);
                                                }
                                                catch { }
                                                finally { await setCEPEmConsulta(false); }
                                            }
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Logradouro:" inline={true}>
                                    <InputGroup id="Logradouro" placeholder="Informe o logradouro" value={getFormularioLogradouro} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioLogradouro(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Número:" inline={true}>
                                    <InputGroup id="NumeroDeEndereco" maxLength={10} placeholder={Numero.FormatarTextoParaInteiro(0)} value={getFormularioNumeroDeEndereco} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioNumeroDeEndereco(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0", flexDirection: "column" }}>
                                <FormGroup label="Bairro:" inline={true}>
                                    <InputGroup id="Bairro" placeholder="Informe o bairro" value={getFormularioBairro} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioBairro(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Cidade:" inline={true}>
                                    <InputGroup id="Cidade" placeholder="Informe o cidade" value={getFormularioCidade} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioCidade(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                    <HTMLSelect id="uf" name="uf" value={getFormularioUF}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormularioUF(event.currentTarget.value)}>
                                        <option selected value="">Selecione a UF</option>
                                        {UF.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                    {getFormularioUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormularioUF(""); }} />}
                                </FormGroup>
                            </div>
                            <FormGroup label="Complemento:" inline={true}>
                                <InputGroup id="Complemento" placeholder="Informe o complemento" value={getFormularioComplemento} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioComplemento(event.currentTarget.value)}>
                                </InputGroup>
                            </FormGroup>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>
                <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>{getFormularioExibir02Comprador ? "Dados do 02º comprador" : "Esta venda possui 02º comprador?"}</h3>
                            <Switch style={{ margin: "10px 5px 0px 10px", alignContent: "center" }} checked={getFormularioExibir02Comprador} onChange={async () => { await setFormularioExibir02Comprador(!getFormularioExibir02Comprador) }} />
                        </div>
                        {getFormularioExibir02Comprador == true && <FileInput style={{ width: "calc(100% - 375px)", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormulario02CompradorAnexoCliente == undefined ? "Anexe o documento do 02º comprador" : getFormulario02CompradorAnexoCliente.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormulario02CompradorAnexoCliente(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />}
                        {getFormularioExibir02Comprador == true && getFormulario02CompradorAnexoCliente && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormulario02CompradorAnexoCliente(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    {getFormularioExibir02Comprador == true && <div style={{ width: "calc(100% + 15px)" }}>
                        <Component.ContentFormHeader>
                            <div className="Conteiner">
                                <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                    <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 ? "CPF:" : "CNPJ:"} inline={true}>
                                        <InputGroup id="CPF" placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormulario02CompradorCPF)} leftIcon={getCPFEmConsulta ? "geosearch" : false}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                                await setFormulario02CompradorCPF(TextoDigitado);
                                                if (CPF.TextoValido(TextoDigitado)) {
                                                    await setCPFEmConsulta(true);
                                                    try {
                                                        var Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                                                            .get(`/Pessoa/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}/${TextoDigitado}`))
                                                            .data as any;
                                                        await setFormulario02CompradorNome(Response.nome ?? "");
                                                        await setFormulario02CompradorEmail(Response.emails[0].descricao ?? "");
                                                        await setFormulario02CompradorDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                        try {
                                                            let TelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                            await setFormulario02CompradorTelefoneCelular(Telefone.FormatarTexto((TelefoneCelular?.ddd ?? "") + (TelefoneCelular?.numero ?? "")));
                                                        }
                                                        catch { }
                                                        try {
                                                            let TelefoneComercial = Response.telefones.find((Item: any) => Item.classificacao == 2);
                                                            await setFormulario02CompradorTelefoneComercial(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                        }
                                                        catch { }
                                                        try { await setFormulario02CompradorRG(Response.rg.numero ?? ""); }
                                                        catch { }
                                                        try { await setFormulario02CompradorRGOrgaoEmissor(Response.rg.orgaoEmissor ?? ""); }
                                                        catch { }
                                                        try { await setFormulario02CompradorRGUF(Response.rg.uf ?? ""); }
                                                        catch { }
                                                        await setFormulario02CompradorProfissao(Response.observacao ?? "");
                                                        await setFormulario02CompradorCEP(Response.endereco.cep ?? "");
                                                        await setFormulario02CompradorLogradouro(Response.endereco.logradouro ?? "");
                                                        await setFormulario02CompradorComplemento(Response.endereco.complemento ?? "");
                                                        await setFormulario02CompradorNumeroDeEndereco(Response.endereco.numero ?? "");
                                                        await setFormulario02CompradorBairro(Response.endereco.bairro ?? "");
                                                        await setFormulario02CompradorCidade(Response.endereco.cidade ?? "");
                                                        await setFormulario02CompradorUF(Response.endereco.uf ?? "");
                                                        await setFormulario02CompradorEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                        if (Response.conjuge) {
                                                            await setFormulario02CompradorConjugeNome(Response.conjuge.nome ?? "");
                                                            await setFormulario02CompradorConjugeCPF(Response.conjuge.cpf ?? "");
                                                            await setFormulario02CompradorConjugeEmail(Response.conjuge.emails[0].descricao ?? "");
                                                            await setFormulario02CompradorConjugeDataDeNascimento(new Date(Response.conjuge.dataDeNascimento) ?? "");
                                                            try { await setFormulario02CompradorConjugeRG(Response.conjuge.rg.numero ?? ""); }
                                                            catch { }
                                                            try { await setFormulario02CompradorConjugeRGOrgaoEmissor(Response.conjuge.rg.orgaoEmissor ?? ""); }
                                                            catch { }
                                                            try { await setFormulario02CompradorConjugeRGUF(Response.conjuge.rg.uf ?? ""); }
                                                            catch { }
                                                            try {
                                                                let ConjugeTelefoneCelular = Response.conjuge.telefones.find((Item: any) => Item.classificacao == 1);
                                                                await setFormulario02CompradorConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try {
                                                                let ConjugeTelefoneComercial = Response.Dados.conjuge.telefones.find((Item: any) => Item.classificacao == 2);
                                                                await setFormulario02CompradorConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            await setFormulario02CompradorConjugeProfissao(Response.conjuge.observacao ?? "");
                                                        }
                                                    }
                                                    catch { }
                                                    finally { await setCPFEmConsulta(false); }
                                                }
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 ? "Nome:" : "Razão social:"} inline={true} >
                                        <InputGroup id="Nome" placeholder="Nome" value={getFormulario02CompradorNome}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorNome(event.currentTarget.value)}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 ? "Nascimento:" : "Data de abertura:"} labelFor="EntradaVencimento" inline={true}>
                                        <DateInput inputProps={{ style: { width: "244px", marginLeft: "15px" }, onClick: () => setFormulario02ExibirCalendarioCompradorDataDeNascimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{ disabled: !getFormulario02ExibirCalendarioCompradorDataDeNascimento, onClosed: () => setFormulario02ExibirCalendarioCompradorDataDeNascimento(false) }}
                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormulario02CompradorDataDeNascimento(DataSelecionada)}
                                            value={getFormulario02CompradorDataDeNascimento} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                        </DateInput>
                                    </FormGroup>
                                    <FormGroup label="Nacionalidade:" labelFor="Nacionalidade" inline={true}>
                                        {getListaPickerNacionalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                        <PickerNacionalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNacionalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(Item: Objeto.Nacao) => <MenuItem onClick={async () => await setItemPickerFormulario02CompradorNacionalidade(Item)} text={Item.nacionalidade ?? ""} />}
                                            itemPredicate={(Texto: string, Item: Objeto.Nacao) => { return (Item.nacionalidade ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button id="Nacionalidade" text={getItemPickerFormulario02CompradorNacionalidade?.nacionalidade ?? "Selecione a nacionalidade"} rightIcon="double-caret-vertical" />
                                        </PickerNacionalidade>
                                        {getItemPickerFormulario02CompradorNacionalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormularioNacionalidade(undefined); }} />}
                                    </FormGroup>
                                    <FormGroup label="Naturalidade:" labelFor="Naturalidade" inline={true}>
                                        {getListaPickerNaturalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                        <PickerNaturalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNaturalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(Item: { id: number, cidade: string, uf: string }) => <MenuItem onClick={async () => await setItemPickerFormulario02CompradorNaturalidade(Item)} text={`${Item.cidade}-${Item.uf}` ?? ""} />}
                                            itemPredicate={(Texto: string, Item: { id: number, cidade: string, uf: string }) => { return (`${Item.cidade}-${Item.uf}` ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button id="Naturalidade" text={getItemPickerFormulario02CompradorNaturalidade ? `${getItemPickerFormulario02CompradorNaturalidade?.cidade}-${getItemPickerFormulario02CompradorNaturalidade?.uf}` : "Selecione a naturalidade"} rightIcon="double-caret-vertical" />
                                        </PickerNaturalidade>
                                        {getItemPickerFormulario02CompradorNaturalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormulario02CompradorNaturalidade(undefined); }} />}
                                    </FormGroup>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                    <FormGroup label="Email:" inline={true}>
                                        <InputGroup id="Email" placeholder="Email" value={getFormulario02CompradorEmail}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorEmail(event.currentTarget.value)}>
                                        </InputGroup>
                                    </FormGroup>
                                    {CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 && <div>
                                        <FormGroup label="Tel. celular:" inline={true}>
                                            <InputGroup id="TelefoneCelular" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormulario02CompradorTelefoneCelular}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorTelefoneCelular(Telefone.FormatarTexto(event.currentTarget.value))}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                    <FormGroup label="Tel. comercial:" inline={true}>
                                        <InputGroup id="TelefoneComercial" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormulario02CompradorTelefoneComercial}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorTelefoneComercial(Telefone.FormatarTexto(event.currentTarget.value))}>
                                        </InputGroup>
                                    </FormGroup>
                                    {CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 &&
                                        <FormGroup label="Profissão:" labelFor="profissão" inline={true}>
                                            <div id="profissão">
                                                <InputGroup id="profissao" size={30} placeholder="Informe a profissão" leftElement={<Icon icon="briefcase" />} value={getFormulario02CompradorProfissao}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorProfissao(event.currentTarget.value)}>
                                                </InputGroup>
                                            </div>
                                        </FormGroup>}
                                    {CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 &&
                                        <FormGroup label="Pai:" labelFor="Pai" inline={true}>
                                            <div id="Pai">
                                                <InputGroup size={30} placeholder="Informe a pai" leftElement={<Icon icon="briefcase" />} value={getFormulario02CompradorPai}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorPai(event.currentTarget.value)}>
                                                </InputGroup>
                                            </div>
                                        </FormGroup>}
                                    {CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 &&
                                        <FormGroup label="Mãe:" labelFor="Mae" inline={true}>
                                            <div id="Mae">
                                                <InputGroup size={30} placeholder="Informe a mãe" leftElement={<Icon icon="briefcase" />} value={getFormulario02CompradorMae}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorMae(event.currentTarget.value)}>
                                                </InputGroup>
                                            </div>
                                        </FormGroup>}
                                </div>
                                {CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 && <div>
                                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                        <FormGroup label="RG:" inline={true}>
                                            <InputGroup id="RG" placeholder={"00000000000"} value={getFormulario02CompradorRG}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorRG(event.currentTarget.value)}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup style={{ marginRight: "1vw", minWidth: "180px" }} label="Orgão emissor:" inline={true} >
                                            <InputGroup id="OrgaoEmissor" placeholder={"Informe o orgão emissor"} value={getFormulario02CompradorRGOrgaoEmissor}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorRGOrgaoEmissor(event.currentTarget.value)}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                            <HTMLSelect id="RGUF" name="RGUF" value={getFormulario02CompradorRGUF}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormulario02CompradorRGUF(event.currentTarget.value)}>
                                                <option selected value="">Selecione a UF</option>
                                                {UF.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                            {getFormulario02CompradorRGUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormulario02CompradorRGUF(""); }} />}
                                        </FormGroup>
                                        <FormGroup label="CNH:" inline={true}>
                                            <InputGroup id="CNH" placeholder={"00000000000"} value={getFormulario02CompradorCNH}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorCNH(event.currentTarget.value)}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="Data exp.:" labelFor="DataExpedicao" inline={true}>
                                            <DateInput inputProps={{ style: { width: "244px", marginLeft: "40px" }, onClick: () => setFormulario02CompradorExibirCalendarioCNHExpedicao(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                                popoverProps={{ disabled: !getFormulario02CompradorExibirCalendarioCNHExpedicao, onClosed: () => setFormulario02CompradorExibirCalendarioCNHExpedicao(false) }}
                                                parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                                placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormulario02CompradorCNHExpedicao(DataSelecionada)}
                                                value={getFormulario02CompradorCNHExpedicao} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                            </DateInput>
                                        </FormGroup>
                                        <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                            <HTMLSelect id="CNHUF" name="CNHUF" value={getFormulario02CompradorCNHUF}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormulario02CompradorCNHUF(event.currentTarget.value)}>
                                                <option selected value="">Selecione a UF</option>
                                                {UF.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                            {getFormulario02CompradorCNHUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormulario02CompradorCNHUF(""); }} />}
                                        </FormGroup>
                                    </div>
                                </div>}
                            </div>
                        </Component.ContentFormHeader>
                        {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && <Component.FormContent>
                            <Component.ContentFormHeader>
                                <Component.FormContent>
                                    <FormGroup labelFor="EstadoCivil" style={{ backgroundColor: "0" }}>
                                        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", marginLeft: "-10px" }}>
                                            {getFormularioListaEstadoCivil?.map((Item) =>
                                                <Radio name="02CompradorEstadoCivil" style={{ display: "flex", alignItems: "center", marginLeft: "10px" }} label={Item.descricao} value={Item.id}
                                                    onChange={async () => await setFormulario02CompradorEstadoCivil(Item)}
                                                    checked={Item == getFormulario02CompradorEstadoCivil}>
                                                </Radio>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Component.FormContent>
                            </Component.ContentFormHeader>
                        </Component.FormContent>}
                    </div>}
                </Component.ContentCard>
                {getFormularioExibir02Comprador == true && CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 && (getFormulario02CompradorEstadoCivil?.id == 2 || getFormulario02CompradorEstadoCivil?.id == 7) && (<Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Dados do conjugê do 02ª comprador</h3>
                        </div>
                        <FileInput style={{ width: "calc(100% - 449px)", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormulario02CompradorAnexoConjuge == undefined ? "Anexe o documento do conjugê" : getFormulario02CompradorAnexoConjuge.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormulario02CompradorAnexoConjuge(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormulario02CompradorAnexoConjuge && <Button type="submit" intent="none" onClick={async () => {
                            await setFormulario02CompradorAnexoConjuge(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <div className="Conteiner">
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorConjugeCPF).length <= 11 ? "CPF:" : "CNPJ:"} inline={true}>
                                    <InputGroup id="CPF" placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormulario02CompradorConjugeCPF)} leftIcon={getCPFEmConsulta ? "geosearch" : false}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                            await setFormulario02CompradorConjugeCPF(TextoDigitado);
                                            if (CPF.TextoValido(TextoDigitado)) {
                                                await setCPFEmConsulta(true);
                                                try {
                                                    var Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                                                        .get(`/Pessoa/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}/${TextoDigitado}`))
                                                        .data as any;
                                                    await setFormulario02CompradorConjugeNome(Response.nome ?? "");
                                                    await setFormulario02CompradorConjugeEmail(Response.emails[0].descricao ?? "");
                                                    await setFormulario02CompradorConjugeDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                    try { await setFormulario02CompradorConjugeRG(Response.rg.numero ?? ""); }
                                                    catch { }
                                                    try { await setFormulario02CompradorConjugeRGOrgaoEmissor(Response.rg.orgaoEmissor ?? ""); }
                                                    catch { }
                                                    try { await setFormulario02CompradorConjugeRGUF(Response.rg.uf ?? ""); }
                                                    catch { }
                                                    try {
                                                        let ConjugeTelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                        await setFormulario02CompradorConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                    }
                                                    catch { }
                                                    try {
                                                        let ConjugeTelefoneComercial = Response.Dados.telefones.find((Item: any) => Item.classificacao == 2);
                                                        await setFormulario02CompradorConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                    }
                                                    catch { }
                                                    await setFormulario02CompradorConjugeProfissao(Response.observacao ?? "");
                                                }
                                                catch { }
                                                finally { await setCPFEmConsulta(false); }
                                            }
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 ? "Nome:" : "Razão social:"} inline={true}>
                                    <InputGroup id="Nome" placeholder="Nome" value={getFormulario02CompradorConjugeNome}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeNome(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 ? "Nascimento:" : "Data de abertura:"} labelFor="EntradaVencimento" inline={true}>
                                    <DateInput inputProps={{ style: { width: "244pxx", marginLeft: "15px" }, onClick: () => setFormulario02ExibirCalendarioCompradorConjugeDataDeNascimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{ disabled: !getFormulario02ExibirCalendarioCompradorConjugeDataDeNascimento, onClosed: () => setFormulario02ExibirCalendarioCompradorConjugeDataDeNascimento(false) }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormulario02CompradorConjugeDataDeNascimento(DataSelecionada)}
                                        value={getFormulario02CompradorConjugeDataDeNascimento} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                    </DateInput>
                                </FormGroup>
                                <FormGroup label="Nacionalidade:" labelFor="Nacionalidade" inline={true}>
                                    {getListaPickerNacionalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                    <PickerNacionalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNacionalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: Objeto.Nacao) => <MenuItem onClick={async () => await setItemPickerFormulario02CompradorConjugeNacionalidade(Item)} text={Item.nacionalidade ?? ""} />}
                                        itemPredicate={(Texto: string, Item: Objeto.Nacao) => { return (Item.nacionalidade ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button id="Nacionalidade" text={getItemPickerFormulario02CompradorConjugeNacionalidade?.nacionalidade ?? "Selecione a nacionalidade"} rightIcon="double-caret-vertical" />
                                    </PickerNacionalidade>
                                    {getItemPickerFormulario02CompradorConjugeNacionalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormularioNacionalidade(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Naturalidade:" labelFor="Naturalidade" inline={true}>
                                    {getListaPickerNaturalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                    <PickerNaturalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNaturalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { id: number, cidade: string, uf: string }) => <MenuItem onClick={async () => await setItemPickerFormulario02CompradorConjugeNaturalidade(Item)} text={`${Item.cidade}-${Item.uf}` ?? ""} />}
                                        itemPredicate={(Texto: string, Item: { id: number, cidade: string, uf: string }) => { return (`${Item.cidade}-${Item.uf}` ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button id="Naturalidade" text={getItemPickerFormulario02CompradorConjugeNaturalidade ? `${getItemPickerFormulario02CompradorConjugeNaturalidade?.cidade}-${getItemPickerFormulario02CompradorConjugeNaturalidade?.uf}` : "Selecione a naturalidade"} rightIcon="double-caret-vertical" />
                                    </PickerNaturalidade>
                                    {getItemPickerFormulario02CompradorConjugeNaturalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormulario02CompradorConjugeNaturalidade(undefined); }} />}
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>

                                <FormGroup label="Email:" inline={true}>
                                    <InputGroup id="Email" placeholder="Email" value={getFormulario02CompradorConjugeEmail}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeEmail(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Tel. celular:" inline={true}>
                                    <InputGroup id="TelefoneCelular" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormulario02CompradorConjugeTelefoneCelular}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeTelefoneCelular(Telefone.FormatarTexto(event.currentTarget.value))}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Tel. comercial:" inline={true}>
                                    <InputGroup id="TelefoneComercial" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormulario02CompradorConjugeTelefoneComercial}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeTelefoneComercial(Telefone.FormatarTexto(event.currentTarget.value))}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Profissão:" labelFor="profissão" inline={true}>
                                    <div id="profissão">
                                        <InputGroup id="profissao" size={30} placeholder="Informe a profissão" leftElement={<Icon icon="briefcase" />} value={getFormulario02CompradorConjugeProfissao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeProfissao(event.currentTarget.value)}>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                                {CPF.DesformatarTexto(getFormulario02CompradorConjugeCPF).length <= 11 &&
                                    <FormGroup label="Pai:" labelFor="Pai" inline={true}>
                                        <div id="Pai">
                                            <InputGroup size={30} placeholder="Informe a pai" leftElement={<Icon icon="briefcase" />} value={getFormulario02CompradorConjugePai}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugePai(event.currentTarget.value)}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>}
                                {CPF.DesformatarTexto(getFormulario02CompradorConjugeCPF).length <= 11 &&
                                    <FormGroup label="Mãe:" labelFor="Mae" inline={true}>
                                        <div id="Mae">
                                            <InputGroup size={30} placeholder="Informe a mãe" leftElement={<Icon icon="briefcase" />} value={getFormulario02CompradorConjugeMae}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeMae(event.currentTarget.value)}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>}
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                <FormGroup label="RG:" inline={true}>
                                    <InputGroup id="RG" placeholder={"00000000000"} value={getFormulario02CompradorConjugeRG}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeRG(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Orgão emissor:" inline={true} >
                                    <InputGroup id="OrgaoEmissor" placeholder={"Informe o orgão emissor"} value={getFormulario02CompradorConjugeRGOrgaoEmissor}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeRGOrgaoEmissor(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                    <HTMLSelect id="RGUF" name="RGUF" value={getFormulario02CompradorConjugeRGUF}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormulario02CompradorConjugeRGUF(event.currentTarget.value)}>
                                        <option selected value="">Selecione a UF</option>
                                        {UF.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                    {getFormulario02CompradorConjugeRGUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormulario02CompradorConjugeRGUF(""); }} />}
                                </FormGroup>
                                <FormGroup label="CNH:" inline={true}>
                                    <InputGroup id="CNH" placeholder={"00000000000"} value={getFormulario02CompradorConjugeCNH}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeCNH(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Data exp.:" labelFor="DataExpedicao" inline={true}>
                                    <DateInput inputProps={{ style: { width: "244px", marginLeft: "40px" }, onClick: () => setFormulario02CompradorConjugeExibirCalendarioCNHExpedicao(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{ disabled: !getFormulario02CompradorConjugeExibirCalendarioCNHExpedicao, onClosed: () => setFormulario02CompradorConjugeExibirCalendarioCNHExpedicao(false) }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormulario02CompradorConjugeCNHExpedicao(DataSelecionada)}
                                        value={getFormulario02CompradorConjugeCNHExpedicao} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                    </DateInput>
                                </FormGroup>
                                <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                    <HTMLSelect id="CNHUF" name="CNHUF" value={getFormulario02CompradorConjugeCNHUF}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormulario02CompradorConjugeCNHUF(event.currentTarget.value)}>
                                        <option selected value="">Selecione a UF</option>
                                        {UF.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                    {getFormulario02CompradorConjugeCNHUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormulario02CompradorConjugeCNHUF(""); }} />}
                                </FormGroup>
                            </div>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>)}
                {getFormularioExibir02Comprador == true && <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="map-marker" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Endereço do 02ª comprador</h3>
                        </div>
                        <FileInput style={{ width: "calc(100% - 355px)", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormulario02CompradorAnexoEndereco == undefined ? "Anexe o comprovante de endereço" : getFormulario02CompradorAnexoEndereco.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormulario02CompradorAnexoEndereco(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormulario02CompradorAnexoEndereco && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormulario02CompradorAnexoEndereco(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <div className="Conteiner">
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0", flexDirection: "column" }}>
                                <FormGroup label="CEP:" inline={true}>
                                    <InputGroup id="CEP" placeholder="Informe o CEP" value={CEP.FormatarTexto(getFormulario02CompradorCEP)} leftIcon={getCEPEmConsulta ? "geosearch" : false}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            var CEPDesformatado = CEP.DesformatarTexto(event.currentTarget.value);
                                            await setFormulario02CompradorCEP(CEPDesformatado);
                                            if (CEPDesformatado.length == 8) {
                                                try {
                                                    await setCEPEmConsulta(true);
                                                    var Response = (await Correios.Get(ContextSignIn.getContext().token, CEPDesformatado)).data;
                                                    await setFormulario02CompradorLogradouro(Response.Logradouro);
                                                    await setFormulario02CompradorComplemento(Response.Complemento);
                                                    await setFormulario02CompradorBairro(Response.Bairro);
                                                    await setFormulario02CompradorCidade(Response.Cidade);
                                                    await setFormulario02CompradorUF(Response.UF);
                                                }
                                                catch { }
                                                finally { await setCEPEmConsulta(false); }
                                            }
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Logradouro:" inline={true}>
                                    <InputGroup id="Logradouro" placeholder="Informe o logradouro" value={getFormulario02CompradorLogradouro} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorLogradouro(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Número:" inline={true}>
                                    <InputGroup id="NumeroDeEndereco" placeholder={Numero.FormatarTextoParaInteiro(0)} value={getFormulario02CompradorNumeroDeEndereco} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorNumeroDeEndereco(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0", flexDirection: "column" }}>
                                <FormGroup label="Bairro:" inline={true}>
                                    <InputGroup id="Bairro" placeholder="Informe o bairro" value={getFormulario02CompradorBairro} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorBairro(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Cidade:" inline={true}>
                                    <InputGroup id="Cidade" placeholder="Informe o cidade" value={getFormulario02CompradorCidade} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorCidade(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                    <HTMLSelect id="uf" name="uf" value={getFormulario02CompradorUF}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormulario02CompradorUF(event.currentTarget.value)}>
                                        <option selected value="">Selecione a UF</option>
                                        {UF.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                    {getFormulario02CompradorUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormulario02CompradorUF(""); }} />}
                                </FormGroup>
                            </div>
                            <FormGroup label="Complemento:" inline={true}>
                                <InputGroup id="Complemento" placeholder="Informe o complemento" value={getFormulario02CompradorComplemento} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorComplemento(event.currentTarget.value)}>
                                </InputGroup>
                            </FormGroup>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>}
                <Component.ContentCard>
                    <Component.Title>
                        <Icon icon="office" iconSize={25} />
                        <h3>Dados do Produto</h3>
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <FormGroup label="Empreendimento:" labelFor="Empreendimento" inline={true} style={{ flexWrap: "wrap" }}>
                            <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => {
                                    console.log(Item)
                                    ItemPickerCentroDeCusto = Item;
                                    connectToRoom(Item.sigla);
                                    setItemPickerCentroDeCusto(Item);
                                    setItemPickerIdentificador(undefined);
                                    setItemPickerIdentificadorSintetico(undefined);
                                    setListaPickerIdentificador([]);
                                    setListaPickerIdentificadorSintetico([]);
                                    setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                    setListaPickerCondicaoDaTabelaDeVenda([]);
                                    await setListaPickerIdentificadorSintetico((await Identificador.FastGetSintetico(ContextSignIn.getContext().token, true, Item.empresa.id ?? 0, Item.sigla ?? "")).data.filter((_Item) => _Item.status == 0 || (_Item.status == 2 && _Item.reservaVinculadaVendedorId == ContextSignIn.getContext().pessoa.id)));
                                }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button id="Empreendimento" text={getItemPickerCentroDeCusto?.descricao ?? "Selecione o empreendimento"} rightIcon="double-caret-vertical" />
                                {getListaPickerCentroDeCusto.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px" }} />}
                            </PickerCentroDeCusto>
                            {getItemPickerCentroDeCusto && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerCentroDeCusto(undefined); }} />}
                        </FormGroup>
                        <div style={{ display: "flex", padding: "0" }}>
                            <FormGroup label="Unidade:" labelFor="Unidade" inline={true}>
                                <Component.FormContent>

                                    <PickerIdentificadorMemo
                                        listaIdentificadorSintetico={getListaPickerIdentificadorSintetico}
                                        ItemPickerCentroDeCusto={getItemPickerCentroDeCusto}
                                        ItemPickerIdentificadorSintetico={getItemPickerIdentificadorSintetico}
                                        ItemRender={(Item: Objeto.IdentificadorSintetico) =>
                                            <MenuItem onClick={async () => {

                                                if (getItemPickerIdentificadorSintetico && getItemPickerIdentificadorSintetico.subLocalId != Item.subLocalId) {

                                                    if (getObjetoPropostaDeVenda != null && getObjetoPropostaDeVenda.identificador.subLocal.id == getItemPickerIdentificadorSintetico.subLocalId && getObjetoPropostaDeVenda.identificador.status == getItemPickerIdentificadorSintetico.status) {
                                                        ItemPickerIdentificadorSinteticao = Item;
                                                        await setItemPickerIdentificadorSintetico(Item);

                                                        if (Item.status == 0) {

                                                            NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                            let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                            if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);

                                                                if (Math.floor(Response.status / 100) == 2) {
                                                                    let ListaDeUnidadesDisponibilidade = [...getListaPickerIdentificadorSintetico];
                                                                    Item.status = 2;

                                                                    ItemPickerIdentificadorSinteticao = Item;
                                                                    await setItemPickerIdentificadorSintetico(Item);
                                                                    ResponseUnidade.data[0].status = 2;
                                                                    await setItemPickerIdentificador(ResponseUnidade.data[0]);

                                                                    await setListaPickerIdentificadorSintetico(ListaDeUnidadesDisponibilidade);
                                                                    sendUnidadeSintetico(Item, Item.centroDeCustoSigla, ListaDeUnidadesDisponibilidade, []);
                                                                    NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                    if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                        Toast(
                                                                            <div>
                                                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            await setFormularioEntradaQtde(0);
                                                                                            await setFormularioListaDeEntradas([]);
                                                                                            await setFormularioSinalQtde(0);
                                                                                            await setFormularioListaDeSinais([]);
                                                                                            await setFormularioParcelaValor(0);
                                                                                            await setFormularioParcelaQtde(0);
                                                                                            await setFormularioParcelaVencimento(undefined);
                                                                                            await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                            await setItemPickerFinalidadeDeCompra(undefined);
                                                                                            await setListaCondicaoDaTabelaDeVenda([]);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        }}>Sim</Button>
                                                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                            const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                            NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                        }}>Não</Button>
                                                                                </div>
                                                                            </div>, {
                                                                            position: Toast.POSITION.TOP_CENTER,
                                                                            autoClose: false,
                                                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                        });
                                                                    }
                                                                    else {
                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                        await setFormularioEntradaQtde(0);
                                                                        await setFormularioListaDeEntradas([]);
                                                                        await setFormularioSinalQtde(0);
                                                                        await setFormularioListaDeSinais([]);
                                                                        await setFormularioParcelaValor(0);
                                                                        await setFormularioParcelaQtde(0);
                                                                        await setFormularioParcelaVencimento(undefined);
                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                    }
                                                                }
                                                                else {
                                                                    NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            Item.status = 2;

                                                            let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                            if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                NotificacaoInterna.ExibirNotificacao("Reservado", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                    Toast(
                                                                        <div>
                                                                            <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        await setFormularioEntradaQtde(0);
                                                                                        await setFormularioListaDeEntradas([]);
                                                                                        await setFormularioSinalQtde(0);
                                                                                        await setFormularioListaDeSinais([]);
                                                                                        await setFormularioParcelaValor(0);
                                                                                        await setFormularioParcelaQtde(0);
                                                                                        await setFormularioParcelaVencimento(undefined);
                                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                                        await setListaCondicaoDaTabelaDeVenda([]);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                    }}>Sim</Button>
                                                                                <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                        const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda((CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []).filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                        NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                    }}>Não</Button>
                                                                            </div>
                                                                        </div>, {
                                                                        position: Toast.POSITION.TOP_CENTER,
                                                                        autoClose: false,
                                                                        style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                    });
                                                                }
                                                                else {
                                                                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                    await setFormularioEntradaQtde(0);
                                                                    await setFormularioListaDeEntradas([]);
                                                                    await setFormularioSinalQtde(0);
                                                                    await setFormularioListaDeSinais([]);
                                                                    await setFormularioParcelaValor(0);
                                                                    await setFormularioParcelaQtde(0);
                                                                    await setFormularioParcelaVencimento(undefined);
                                                                    await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                    await setItemPickerFinalidadeDeCompra(undefined);
                                                                }
                                                            }
                                                        }

                                                    }
                                                    else {

                                                        NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                        let ResponseDesfazendoReserva = await Identificador.DeletarReservas(ContextSignIn.getContext().token, [getItemPickerIdentificador ?? {} as Objeto.Identificador])

                                                        if (Math.floor(ResponseDesfazendoReserva.status / 100) == 2) {

                                                            let ListaDosIdentificadores = [...getListaPickerIdentificadorSintetico];

                                                            ListaDosIdentificadores.map(lote => {
                                                                if (lote.subLocalId == getItemPickerIdentificadorSintetico.subLocalId) {
                                                                    lote.status = 0;
                                                                }
                                                            });

                                                            let IdentificadorAnterior = { ...getItemPickerIdentificadorSintetico };

                                                            IdentificadorAnterior.status = 0;

                                                            setListaPickerIdentificadorSintetico(ListaDosIdentificadores);

                                                            console.log(IdentificadorAnterior);

                                                            sendUnidadeSintetico(IdentificadorAnterior, IdentificadorAnterior.centroDeCustoSigla, ListaDosIdentificadores, []);

                                                            ItemPickerIdentificadorSinteticao = Item;
                                                            await setItemPickerIdentificadorSintetico(Item);

                                                            if (Item.status == 0) {

                                                                let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                                if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                    let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);

                                                                    if (Math.floor(Response.status / 100) == 2) {
                                                                        let ListaDeUnidadesDisponibilidade = [...getListaPickerIdentificadorSintetico];
                                                                        Item.status = 2;

                                                                        ItemPickerIdentificadorSinteticao = Item;
                                                                        await setItemPickerIdentificadorSintetico(Item);
                                                                        ResponseUnidade.data[0].status = 2;
                                                                        await setItemPickerIdentificador(ResponseUnidade.data[0]);

                                                                        await setListaPickerIdentificadorSintetico(ListaDeUnidadesDisponibilidade);
                                                                        sendUnidadeSintetico(Item, Item.centroDeCustoSigla, ListaDeUnidadesDisponibilidade, []);
                                                                        NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                        if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                            Toast(
                                                                                <div>
                                                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                            onClick={async () => {
                                                                                                await setFormularioEntradaQtde(0);
                                                                                                await setFormularioListaDeEntradas([]);
                                                                                                await setFormularioSinalQtde(0);
                                                                                                await setFormularioListaDeSinais([]);
                                                                                                await setFormularioParcelaValor(0);
                                                                                                await setFormularioParcelaQtde(0);
                                                                                                await setFormularioParcelaVencimento(undefined);
                                                                                                await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                                await setItemPickerFinalidadeDeCompra(undefined);
                                                                                                await setListaCondicaoDaTabelaDeVenda([]);
                                                                                                await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                                await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                                const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                                await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                                await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                            }}>Sim</Button>
                                                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                            onClick={async () => {
                                                                                                NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                                const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                                const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                                await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                                await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                                await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                                NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                            }}>Não</Button>
                                                                                    </div>
                                                                                </div>, {
                                                                                position: Toast.POSITION.TOP_CENTER,
                                                                                autoClose: false,
                                                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                            });
                                                                        }
                                                                        else {
                                                                            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                            await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                            await setFormularioEntradaQtde(0);
                                                                            await setFormularioListaDeEntradas([]);
                                                                            await setFormularioSinalQtde(0);
                                                                            await setFormularioListaDeSinais([]);
                                                                            await setFormularioParcelaValor(0);
                                                                            await setFormularioParcelaQtde(0);
                                                                            await setFormularioParcelaVencimento(undefined);
                                                                            await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                            await setItemPickerFinalidadeDeCompra(undefined);
                                                                        }
                                                                    }
                                                                    else {
                                                                        NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                Item.status = 2;

                                                                let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                                if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                    ResponseUnidade.data[0].status = 2;
                                                                    await setItemPickerIdentificador(ResponseUnidade.data[0]);
                                                                    NotificacaoInterna.ExibirNotificacao("Reservado", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                    if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                        Toast(
                                                                            <div>
                                                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            await setFormularioEntradaQtde(0);
                                                                                            await setFormularioListaDeEntradas([]);
                                                                                            await setFormularioSinalQtde(0);
                                                                                            await setFormularioListaDeSinais([]);
                                                                                            await setFormularioParcelaValor(0);
                                                                                            await setFormularioParcelaQtde(0);
                                                                                            await setFormularioParcelaVencimento(undefined);
                                                                                            await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                            await setItemPickerFinalidadeDeCompra(undefined);
                                                                                            await setListaCondicaoDaTabelaDeVenda([]);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        }}>Sim</Button>
                                                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                            const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                            NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                        }}>Não</Button>
                                                                                </div>
                                                                            </div>, {
                                                                            position: Toast.POSITION.TOP_CENTER,
                                                                            autoClose: false,
                                                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                        });
                                                                    }
                                                                    else {
                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                        await setFormularioEntradaQtde(0);
                                                                        await setFormularioListaDeEntradas([]);
                                                                        await setFormularioSinalQtde(0);
                                                                        await setFormularioListaDeSinais([]);
                                                                        await setFormularioParcelaValor(0);
                                                                        await setFormularioParcelaQtde(0);
                                                                        await setFormularioParcelaVencimento(undefined);
                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else { }

                                                    }

                                                }
                                                else {

                                                    ItemPickerIdentificadorSinteticao = Item;
                                                    await setItemPickerIdentificadorSintetico(Item);

                                                    if (Item.status == 0) {

                                                        NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                        let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                        if (Math.floor(ResponseUnidade.status / 100) == 2) {

                                                            let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);

                                                            if (Math.floor(Response.status / 100) == 2) {
                                                                let ListaDeUnidadesDisponibilidade = [...getListaPickerIdentificadorSintetico];
                                                                Item.status = 2;
                                                                ResponseUnidade.data[0].status = 2;

                                                                await setListaPickerIdentificadorSintetico(ListaDeUnidadesDisponibilidade);
                                                                await setItemPickerIdentificador(ResponseUnidade.data[0]);
                                                                sendUnidadeSintetico(Item, Item.centroDeCustoSigla, ListaDeUnidadesDisponibilidade, []);
                                                                NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                    Toast(
                                                                        <div>
                                                                            <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        await setFormularioEntradaQtde(0);
                                                                                        await setFormularioListaDeEntradas([]);
                                                                                        await setFormularioSinalQtde(0);
                                                                                        await setFormularioListaDeSinais([]);
                                                                                        await setFormularioParcelaValor(0);
                                                                                        await setFormularioParcelaQtde(0);
                                                                                        await setFormularioParcelaVencimento(undefined);
                                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                                        await setListaCondicaoDaTabelaDeVenda([]);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                    }}>Sim</Button>
                                                                                <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                        const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                        NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                    }}>Não</Button>
                                                                            </div>
                                                                        </div>, {
                                                                        position: Toast.POSITION.TOP_CENTER,
                                                                        autoClose: false,
                                                                        style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                    });
                                                                }
                                                                else {
                                                                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                    await setFormularioEntradaQtde(0);
                                                                    await setFormularioListaDeEntradas([]);
                                                                    await setFormularioSinalQtde(0);
                                                                    await setFormularioListaDeSinais([]);
                                                                    await setFormularioParcelaValor(0);
                                                                    await setFormularioParcelaQtde(0);
                                                                    await setFormularioParcelaVencimento(undefined);
                                                                    await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                    await setItemPickerFinalidadeDeCompra(undefined);
                                                                }
                                                            }
                                                            else {
                                                                NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                            }
                                                        }

                                                    }
                                                    else {
                                                        Item.status = 2;
                                                        NotificacaoInterna.ExibirNotificacao("Reservado", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                                        let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                        if (Math.floor(ResponseUnidade.status / 100) == 2) {

                                                            await setItemPickerIdentificador(ResponseUnidade.data[0]);

                                                            if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                Toast(
                                                                    <div>
                                                                        <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                            <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => {
                                                                                    await setFormularioEntradaQtde(0);
                                                                                    await setFormularioListaDeEntradas([]);
                                                                                    await setFormularioSinalQtde(0);
                                                                                    await setFormularioListaDeSinais([]);
                                                                                    await setFormularioParcelaValor(0);
                                                                                    await setFormularioParcelaQtde(0);
                                                                                    await setFormularioParcelaVencimento(undefined);
                                                                                    await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                    await setItemPickerFinalidadeDeCompra(undefined);
                                                                                    await setListaCondicaoDaTabelaDeVenda([]);
                                                                                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                }}>Sim</Button>
                                                                            <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => {
                                                                                    NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                    const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                    await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                    NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                }}>Não</Button>
                                                                        </div>
                                                                    </div>, {
                                                                    position: Toast.POSITION.TOP_CENTER,
                                                                    autoClose: false,
                                                                    style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                });
                                                            }
                                                            else {
                                                                await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                await setFormularioEntradaQtde(0);
                                                                await setFormularioListaDeEntradas([]);
                                                                await setFormularioSinalQtde(0);
                                                                await setFormularioListaDeSinais([]);
                                                                await setFormularioParcelaValor(0);
                                                                await setFormularioParcelaQtde(0);
                                                                await setFormularioParcelaVencimento(undefined);
                                                                await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                await setItemPickerFinalidadeDeCompra(undefined);
                                                            }

                                                        }
                                                    }

                                                }

                                            }} text={Item.subLocalDescricao ? Item.subLocalDescricao : ""} />}
                                        ItemPredicate={(Texto: string, Item: Objeto.IdentificadorSintetico) => { return (Item.subLocalDescricao ? Item.subLocalDescricao : "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }} />

                                </Component.FormContent>
                            </FormGroup>
                            {getItemPickerIdentificadorSintetico?.valorAVista &&
                                <FormGroup style={{ marginLeft: "5px" }} label="Valor a vista:" labelFor="UnidadeValorAVista" inline={true}>
                                    <div id="UnidadeValorAVista">
                                        <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(getItemPickerIdentificadorSintetico?.valorAVista)} leftIcon="dollar" />
                                    </div>
                                </FormGroup>}
                            {getItemPickerIdentificadorSintetico?.valorAVista &&
                                <FormGroup style={{ marginLeft: "5px" }} label="Comissão:" labelFor="UnidadeValorAVista" inline={true}>
                                    <div id="UnidadeValorAVista">
                                        <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(getItemPickerIdentificadorSintetico?.valorAVista * 0.04)} leftIcon="dollar" />
                                    </div>
                                </FormGroup>}
                        </div>
                        <FormGroup label="Modelo de venda:" labelFor="CondicaoDaTabelaDeVenda" inline={true}>
                            <PickerCondicaoDaTabelaDeVenda popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCondicaoDaTabelaDeVenda} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: Objeto.CondicaoDaTabelaDeVenda) => <MenuItem onClick={async () => {

                                    try {

                                        await setItemPickerCondicaoDaTabelaDeVenda(Item);
                                        var ListaDeEntradas = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                        var EntradaValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.valorTotal ?? 0;
                                        var EntradaQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.qtdeDeTitulos ?? 0;
                                        var EntradaVencimento = new Date(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.primeiroVencimento ?? new Date());
                                        await setFormularioEntradaQtde(EntradaQtde);
                                        for (let i = 0; i < EntradaQtde; i++) {
                                            let Vencimento = new Date(EntradaVencimento);
                                            Vencimento.setMonth(Vencimento.getMonth() + (i == 0 ? 0 : 1));
                                            ListaDeEntradas.push({
                                                Valor: (i == EntradaQtde - 1) ? (EntradaValor - Moeda.DesformatarTexto(Moeda.FormatarTexto(EntradaValor / (EntradaQtde))) * (EntradaQtde - 1)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(EntradaValor / EntradaQtde)),
                                                Vencimento: Item.descricao.includes("À Vista") ? getFormularioDataDaProposta : Vencimento,
                                                ExibirCalendarioVencimento: false,
                                                MeioDePagamento: ""
                                            });
                                            EntradaVencimento = Vencimento;
                                        }
                                        await setFormularioListaDeEntradas(ListaDeEntradas);

                                        var ListaDeSinais = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                        var SinalValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.valorTotal ?? 0;
                                        var SinalQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.qtdeDeTitulos ?? 0;
                                        var SinalVencimento = new Date(EntradaVencimento);
                                        await setFormularioSinalQtde(SinalQtde);
                                        for (let i = 0; i < SinalQtde; i++) {
                                            let Vencimento = new Date(SinalVencimento);
                                            Vencimento.setMonth(SinalVencimento.getMonth() + 1);
                                            ListaDeSinais.push({
                                                Valor: (i == SinalQtde - 1) ? (SinalValor - Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / (SinalQtde))) * (SinalQtde - 1)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / SinalQtde)),
                                                Vencimento: Item.descricao.includes("À Vista") ? getFormularioDataDaProposta : Vencimento,
                                                ExibirCalendarioVencimento: false,
                                                MeioDePagamento: "Boleto"
                                            });
                                            SinalVencimento = Vencimento;
                                        }
                                        await setFormularioListaDeSinais(ListaDeSinais);

                                        var ListaDeParcelas = [] as Array<{ Quantidade: number, Valor: number, ValorPMT: number, ValorTotal: number, ValorTotalJuros: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                        var ParcelaQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.qtdeDeTitulos ?? 0;
                                        var ParcelaVencimento = new Date(SinalVencimento);
                                        var ParcelaValorTotalJuros = (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (Item?.descricao ?? ""))?.valorTotal ?? 0) : 0;
                                        var ParcelaValorTotal = (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? Moeda.DesformatarTexto(Moeda.FormatarTexto(pv((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (Item?.descricao ?? ""))?.jurosDeTabela ?? 0), ParcelaQtde, - Moeda.DesformatarTexto(Moeda.FormatarTexto(ParcelaValorTotalJuros / ParcelaQtde))))) : (Moeda.DesformatarTexto(Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((Item) => Item.descricao == (Item?.descricao ?? ""))?.principal ?? 0))) * (getListaCondicaoDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (Item?.descricao ?? ""))?.qtdeDeTitulos ?? 0))
                                        var ParcelaValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.principal ?? 0;
                                        var ParcelaValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(ParcelaValorTotalJuros / ParcelaQtde))

                                        if (Item.descricao.includes("Anual") == true) {
                                            ParcelaVencimento = new Date();
                                            ParcelaVencimento.setDate(ParcelaVencimento.getDate() > 5 ? 15 : 5);
                                            ParcelaVencimento.setMonth(ParcelaVencimento.getMonth() + 12);
                                        }
                                        else if (Item.descricao.includes("Semestral") == true) {
                                            ParcelaVencimento = new Date();
                                            ParcelaVencimento.setDate(ParcelaVencimento.getDate() > 5 ? 15 : 5);
                                            ParcelaVencimento.setMonth(ParcelaVencimento.getMonth() + 6);
                                        }
                                        else {
                                            ParcelaVencimento.setMonth(ParcelaVencimento.getMonth() + 1);
                                        }
                                        {
                                            ListaDeParcelas.push({
                                                Quantidade: ParcelaQtde,
                                                Valor: ParcelaValor,
                                                ValorPMT: ParcelaValorPMT,
                                                ValorTotal: ParcelaValorTotal,
                                                ValorTotalJuros: ParcelaValorTotalJuros,
                                                Vencimento: Item.descricao.includes("À Vista") ? getFormularioDataDaProposta : ParcelaVencimento,
                                                ExibirCalendarioVencimento: false,
                                                MeioDePagamento: "Boleto"
                                            });
                                        }
                                        await setFormularioListaDeParcelas(ListaDeParcelas);

                                        var ListaDeIntermediarias = [] as Array<{ Quantidade: number, Valor: number, ValorPMT: number, ValorTotal: number, ValorTotalJuros: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                        var IntermediariaVencimento = new Date(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.primeiroVencimento ?? new Date());
                                        var IntermediariaQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (Item?.descricao ?? ""))?.qtdeDeTitulos ?? 0;
                                        var IntermediariaValorTotalJuros = (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (Item?.descricao ?? ""))?.valorTotal ?? 0) : 0;
                                        var IntermediariaValor = (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? Moeda.DesformatarTexto(Moeda.FormatarTexto(pv((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (Item?.descricao ?? ""))?.jurosDeTabela ?? 0), IntermediariaQtde, - Moeda.DesformatarTexto(Moeda.FormatarTexto(IntermediariaValorTotalJuros / IntermediariaQtde))))) : getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (Item?.descricao ?? ""))?.valorTotal ?? 0;
                                        var IntermediariaValorPMT = (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) ? Moeda.DesformatarTexto(Moeda.FormatarTexto(IntermediariaValorTotalJuros / IntermediariaQtde)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(IntermediariaValor / IntermediariaQtde))

                                        if (IntermediariaQtde > 0) {

                                            let Vencimento = new Date(IntermediariaVencimento);
                                            Vencimento.setMonth(IntermediariaVencimento.getMonth() + 1);
                                            ListaDeIntermediarias = [
                                                {
                                                    Quantidade: IntermediariaQtde,
                                                    Valor: Moeda.DesformatarTexto(Moeda.FormatarTexto(IntermediariaValor / (IntermediariaQtde))),
                                                    ValorPMT: IntermediariaValorPMT,
                                                    ValorTotal: IntermediariaValor,
                                                    ValorTotalJuros: (IntermediariaValorPMT * IntermediariaQtde),
                                                    Vencimento: Item.descricao.includes("À Vista") ? getFormularioDataDaProposta : Vencimento,
                                                    ExibirCalendarioVencimento: false,
                                                    MeioDePagamento: "Boleto"
                                                }
                                            ]
                                        }
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);



                                        await setFormularioEntradaPercentual(ListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) /
                                            (ListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) +
                                                ListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) +
                                                ListaDeParcelas.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) +
                                                ListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0)));
                                        await setFormularioSinalPercentual(ListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) /
                                            (ListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) +
                                                ListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) +
                                                ListaDeParcelas.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) +
                                                ListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0)));
                                        await setFormularioParcelaPercentual(ListaDeParcelas.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) /
                                            (ListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) +
                                                ListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) +
                                                ListaDeParcelas.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) +
                                                ListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0)));
                                        await setFormularioIntermediariaPercentual(ListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) /
                                            (ListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) +
                                                ListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) +
                                                ListaDeParcelas.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) +
                                                ListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0)));
                                    }
                                    catch { }
                                }} text={Item.descricao ?? ""} />}
                                itemPredicate={(Texto: string, Item: Objeto.CondicaoDaTabelaDeVenda) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button text={getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? "Selecione o modelo"} className="CondiçãoDeVendas" rightIcon="double-caret-vertical" />
                                {getItemPickerIdentificadorSintetico && getListaPickerCondicaoDaTabelaDeVenda.length == 0 && <Icon icon="geosearch" style={{ marginLeft: "10px" }} />}
                            </PickerCondicaoDaTabelaDeVenda>
                            {getItemPickerCondicaoDaTabelaDeVenda && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerCondicaoDaTabelaDeVenda(undefined); }} />}
                        </FormGroup>
                    </Component.ContentFormHeader>
                </Component.ContentCard>
                {getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao) && <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="form" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Sobre a entrada</h3>
                            {(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) > 0 && <h6 style={{ marginTop: "30px", marginLeft: "10px" }}>(taxa de juros de {Percentual.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) * 100)}  a.m.)</h6>}
                        </div>
                        <FileInput style={{ width: "calc(100% - 215px)", minWidth: "300px", marginLeft: "-10px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoComprovantePgtoEntrada == undefined ? "Anexe o comprovante de pagamento" : getFormularioAnexoComprovantePgtoEntrada.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoComprovantePgtoEntrada(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoComprovantePgtoEntrada && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoComprovantePgtoEntrada(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <FormGroup label="Quantidade de parcelas:" labelFor="EntradaQtde" inline={true} >
                            <InputGroup id="EntradaQtde" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(getFormularioEntradaQtde)} leftIcon="numerical"
                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                    var ListaDeEntradas = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                    var EntradaValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0;
                                    var EntradaQtde = Numero.DesformatarTextoParaInteiro(event.currentTarget.value);
                                    var EntradaVencimento = new Date(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.primeiroVencimento ?? new Date());
                                    await setFormularioEntradaQtde(EntradaQtde);

                                    for (let i = 0; i < EntradaQtde; i++) {
                                        let Vencimento = new Date(EntradaVencimento);
                                        Vencimento.setMonth(Vencimento.getMonth() + (i == 0 ? 0 : 1));
                                        ListaDeEntradas.push({
                                            Valor: (i == EntradaQtde - 1) ? (EntradaValor - Moeda.DesformatarTexto(Moeda.FormatarTexto(EntradaValor / (EntradaQtde))) * (EntradaQtde - 1)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(EntradaValor / EntradaQtde)),
                                            Vencimento: Vencimento,
                                            ExibirCalendarioVencimento: false,
                                            MeioDePagamento: ""
                                        });
                                        EntradaVencimento = Vencimento;
                                    }
                                    await setFormularioListaDeEntradas(ListaDeEntradas);

                                    var ListaDeSinais = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                    var SinalValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0;
                                    var SinalQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0;
                                    var SinalVencimento = new Date(EntradaVencimento);

                                    await setFormularioSinalQtde(SinalQtde);
                                    for (let i = 0; i < SinalQtde; i++) {
                                        let Vencimento = new Date(SinalVencimento);
                                        Vencimento.setMonth(SinalVencimento.getMonth() + 1);
                                        ListaDeSinais.push({
                                            Valor: (i == SinalQtde - 1) ? (SinalValor - Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / (SinalQtde))) * (SinalQtde - 1)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / SinalQtde)),
                                            Vencimento: Vencimento,
                                            ExibirCalendarioVencimento: false,
                                            MeioDePagamento: "Boleto"
                                        });
                                        SinalVencimento = Vencimento;
                                    }
                                    await setFormularioListaDeSinais(ListaDeSinais);

                                    var ParcelaQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0;
                                    var ParcelaVencimento = new Date(SinalVencimento);
                                    ParcelaVencimento.setMonth(ParcelaVencimento.getMonth() + 1);
                                    await setFormularioParcelaValor(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0);
                                    await setFormularioParcelaQtde(ParcelaQtde);
                                    await setFormularioParcelaVencimento(new Date(ParcelaVencimento));
                                }}>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup label="% sobre o valor a vista:" labelFor="EntradaPercentual" inline={true} >
                            <InputGroup id="EntradaPercentual" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaDecimal(getFormularioEntradaPercentual * 100)} leftIcon="percentage"
                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                    var EntradaPercentual = Numero.DesformatarTextoParaDecimal(event.currentTarget.value) / 100;
                                    for (let i = 0; i < getFormularioListaDeEntradas.length; i++) {
                                        getFormularioListaDeEntradas[i].Valor = (getItemPickerIdentificador?.valorAVista ?? 0) / getFormularioEntradaQtde * EntradaPercentual;
                                    }
                                    await setFormularioListaDeEntradas(getFormularioListaDeEntradas);
                                    await setFormularioEntradaPercentual(EntradaPercentual);

                                    let ParcelaPercentual = ((getItemPickerIdentificador?.valorAVista ?? 0) - ((getFormularioListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                        (getFormularioListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                        (getFormularioListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0))) / (getItemPickerIdentificador?.valorAVista ?? 0)
                                    await setFormularioParcelaPercentual(ParcelaPercentual);
                                    for (let i = 0; i < getFormularioListaDeParcelas.length; i++) {
                                        getFormularioListaDeParcelas[i].Valor = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].Valor * ParcelaPercentual / getFormularioParcelaPercentual));
                                        getFormularioListaDeParcelas[i].ValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorPMT * ParcelaPercentual / getFormularioParcelaPercentual));
                                        getFormularioListaDeParcelas[i].ValorTotal = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotal * ParcelaPercentual / getFormularioParcelaPercentual));
                                        getFormularioListaDeParcelas[i].ValorTotalJuros = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotalJuros * ParcelaPercentual / getFormularioParcelaPercentual));
                                    }
                                }}>
                            </InputGroup>
                        </FormGroup>
                        {getFormularioEntradaQtde > 0 && <div style={{ display: "flex" }}>
                            <div style={{ width: "10vw", textAlign: "center" }}>
                                <h5>Valor da parcela</h5>
                            </div>
                            <div style={{ width: "10vw", marginLeft: "10px", textAlign: "center" }}>
                                <h5>Vencimento</h5>
                            </div>
                            <div style={{ width: "17vw", marginLeft: "10px", textAlign: "center" }}>
                                <h5>Meio de pagamento</h5>
                            </div>
                        </div>}
                        {getFormularioListaDeEntradas.map((Item, Index) =>
                            <div style={{ display: "block" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "10vw", marginRight: "10px" }}>
                                        <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.Valor)} leftIcon="dollar"
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Valor = (Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00);
                                                setFormularioListaDeEntradas(ListaDeEntradas);

                                                await setFormularioEntradaPercentual((getFormularioListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) / (getItemPickerIdentificador?.valorAVista ?? 0));
                                                let ParcelaPercentual = ((getItemPickerIdentificador?.valorAVista ?? 0) - ((getFormularioListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                                    (getFormularioListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                                    (getFormularioListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0))) / (getItemPickerIdentificador?.valorAVista ?? 0);
                                                await setFormularioParcelaPercentual(ParcelaPercentual);
                                                for (let i = 0; i < getFormularioListaDeParcelas.length; i++) {
                                                    getFormularioListaDeParcelas[i].Valor = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].Valor * ParcelaPercentual / getFormularioParcelaPercentual));
                                                    getFormularioListaDeParcelas[i].ValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorPMT * ParcelaPercentual / getFormularioParcelaPercentual));
                                                    getFormularioListaDeParcelas[i].ValorTotal = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotal * ParcelaPercentual / getFormularioParcelaPercentual));
                                                    getFormularioListaDeParcelas[i].ValorTotalJuros = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotalJuros * ParcelaPercentual / getFormularioParcelaPercentual));
                                                }
                                            }}>
                                        </InputGroup>
                                    </div>
                                    <div style={{ width: "10vw", marginRight: "30px" }}>
                                        <DateInput inputProps={{
                                            onClick: () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].ExibirCalendarioVencimento = true;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }
                                        }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{
                                                disabled: !Item.ExibirCalendarioVencimento, onClosed: () => {
                                                    let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                    ListaDeEntradas[Index].ExibirCalendarioVencimento = false;
                                                    setFormularioListaDeEntradas(ListaDeEntradas);
                                                }
                                            }}
                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} value={Item.Vencimento} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                            onChange={async (DataSelecionada: Date, AlteracaoDeUsuario: boolean) => {
                                                if (AlteracaoDeUsuario == true) {
                                                    let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                    if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                        ListaDeEntradas[Index].Vencimento = (getFormularioDataDaProposta ?? new Date());
                                                    }
                                                    else {
                                                        if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "CreditoParcelado") {
                                                            ListaDeEntradas.map((Item_, Index_) => {
                                                                if (Index_ >= Index) {
                                                                    let Vencimento = new Date(DataSelecionada);
                                                                    Vencimento.setDate(Vencimento.getDate() + (Index_ - Index) * 30);
                                                                    Item_.Vencimento = Vencimento;
                                                                }
                                                            });
                                                        }
                                                        else {
                                                            ListaDeEntradas[Index].Vencimento = DataSelecionada;
                                                        }
                                                    }
                                                    setFormularioListaDeEntradas(ListaDeEntradas);
                                                }
                                            }}>
                                        </DateInput>
                                    </div>
                                    <div style={{ width: "20vw" }}>
                                        <Component.ContentFormHeader style={{ margin: "0" }}>
                                            <FormGroup labelFor="MeioDePagamento" inline={true}>
                                                <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={Item.MeioDePagamento}
                                                    onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                        ListaDeEntradas[Index].MeioDePagamento = event.currentTarget.value;
                                                        ListaDeEntradas[Index].Banco = undefined;
                                                        ListaDeEntradas[Index].Agencia = undefined;
                                                        ListaDeEntradas[Index].Conta = undefined;
                                                        ListaDeEntradas[Index].DigitoDaConta = undefined;
                                                        ListaDeEntradas[Index].Titular = undefined;
                                                        ListaDeEntradas[Index].NumeroCheque = undefined;
                                                        ListaDeEntradas[Index].Maquina = undefined;
                                                        ListaDeEntradas[Index].Bandeira = undefined;
                                                        ListaDeEntradas[Index].DigitoCartao = undefined;
                                                        ListaDeEntradas[Index].Operacao = undefined;
                                                        ListaDeEntradas[Index].NSU = undefined;
                                                        ListaDeEntradas[Index].NumeroDaOperacao = undefined;
                                                        setFormularioListaDeEntradas(ListaDeEntradas);
                                                    }}>
                                                    <option selected value="">Selecione o meio de pagamento</option>
                                                    {MeioDePagamento.map((Item) =>
                                                        <option selected value={Item.chave}>{Item.Valor}</option>
                                                    )}
                                                </HTMLSelect>
                                            </FormGroup>
                                        </Component.ContentFormHeader>
                                    </div>
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cheque" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        ListaDeEntradas[Index].DetalharMeioDePagamento = !(ListaDeEntradas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeEntradas(ListaDeEntradas);
                                    }}>
                                        <strong>Sobre o cheque:</strong> Banco: {Item.Banco?.chave ?? "--"}, Agência: {Item.Agencia ?? "--"}, Conta: {Item.Conta ?? "--"}{Item.DigitoDaConta == undefined ? "" : ("-" + Item.DigitoDaConta)}, Titular: {Item.Titular ?? "--"}, Nº do cheque: {Item.NumeroCheque ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cartao" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        ListaDeEntradas[Index].DetalharMeioDePagamento = !(ListaDeEntradas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeEntradas(ListaDeEntradas);
                                    }}>
                                        <strong>Sobre o cartão:</strong> Máquina: {Item.Maquina ?? "--"}, Digito: {Item.DigitoCartao ?? "--"}, Bandeira: {Item.Bandeira ?? "--"}, Operação: {Item.Operacao ?? "--"}, NSU: {Item.NSU ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        ListaDeEntradas[Index].DetalharMeioDePagamento = !(ListaDeEntradas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeEntradas(ListaDeEntradas);
                                    }}>
                                        <strong>Sobre o {Item.MeioDePagamento}:</strong> Nº da operação: {Item.NumeroDaOperacao ?? "--"}
                                    </Component.DescriptionContent>}
                                </div>
                                {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cheque" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Banco:" className="banco" labelFor="Banco" inline={true}>
                                        <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Banco = _Item;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Banco ? ((Item.Banco).chave + ": " + (Item.Banco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                        </PickerBanco>
                                    </FormGroup>
                                    <FormGroup label="Agência:" className="agência" labelFor="Agencia" inline={true} >
                                        <InputGroup id="Agencia" placeholder={"00000"} value={Item.Agencia}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Agencia = event.currentTarget.value ?? "";
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Component.FormContent>
                                        <FormGroup label="Conta:" className="conta" labelFor="Conta" inline={true} >
                                            <InputGroup id="Conta" placeholder={"00000"} value={Item.Conta}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                    ListaDeEntradas[Index].Conta = (event.currentTarget.value ?? "");
                                                    setFormularioListaDeEntradas(ListaDeEntradas);
                                                }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="- " labelFor="DigitoDaConta" inline={true} style={{ marginLeft: "10px", width: "50px" }} >
                                            <InputGroup id="DigitoDaConta" placeholder={"00"} value={Item.DigitoDaConta}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                    ListaDeEntradas[Index].DigitoDaConta = (event.currentTarget.value ?? "");
                                                    setFormularioListaDeEntradas(ListaDeEntradas);
                                                }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </Component.FormContent>
                                    <FormGroup label="Titular:" className="titular" labelFor="Titular" inline={true} >
                                        <InputGroup id="Titular" placeholder={"Informe o nominal"} value={Item.Titular}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Titular = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Nº do cheque:" labelFor="NumeroCheque" inline={true} >
                                        <InputGroup id="NumeroCheque" placeholder={"Informe o número"} value={Item.NumeroCheque}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].NumeroCheque = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        let EntradasVazias = []
                                        EntradasVazias = ListaDeEntradas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == ""))
                                        var Texto = `Existe mais de uma entrada com o meio de pagamento ${String(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        ListaDeEntradas.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Banco ?? "") == "" && (Item__.Agencia ?? "") == "" && (Item__.Conta ?? "") == "" && (Item__.DigitoDaConta ?? "") == "" && (Item__.Titular ?? "") == "" && (Item__.NumeroCheque ?? "") == "")) {
                                                Texto += `\n ${Numero.FormatarTextoParaInteiro(Index__ + 1)}ª entrada, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`;
                                            }
                                        })
                                        Texto += "\n";
                                        if (EntradasVazias.length > 0) {
                                            Toast(
                                                <div>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == "") {
                                                                        Item_.Banco = Item.Banco
                                                                        Item_.Agencia = Item.Agencia
                                                                        Item_.Conta = Item.Conta
                                                                        Item_.DigitoDaConta = Item.DigitoDaConta
                                                                        Item_.Titular = Item.Titular
                                                                        Item_.NumeroCheque = Item.NumeroCheque
                                                                        Item.DetalharMeioDePagamento = false
                                                                    }
                                                                });
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            });
                                        }
                                        else {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeEntradas(ListaDeEntradas);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                                {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cartao" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Máquina:" className="máquina" labelFor="Maquina" inline={true} >
                                        <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Maquina = _Item.Valor;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Maquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                        </PickerMaquina>
                                    </FormGroup>
                                    <FormGroup label="Dígito do cartão:" labelFor="DigitoCartao" inline={true} >
                                        <InputGroup id="DigitoCartao" placeholder={"Informe os últimos 04 dígitos do cartão"} value={Item.DigitoCartao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].DigitoCartao = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Bandeira:" className="bandeira" labelFor="Bandeira" inline={true} >
                                        <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Bandeira = _Item.Valor;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Bandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                        </PickerMaquina>
                                    </FormGroup>
                                    <FormGroup label="Terminal:" className="terminal" labelFor="Terminal" inline={true} >
                                        <PickerTerminal popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={GetTerminais} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { id: number, descricao: string }) => <MenuItem onClick={async () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Terminal = _Item.id;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }} text={_Item.descricao} />} itemPredicate={(Texto: string, _Item: { id: number, descricao: string }) => { return ((_Item.descricao) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                           <Button text={GetTerminais.find((_Item) => _Item.id == Item.Terminal)?.descricao ?? "Selecione o terminal"} rightIcon="double-caret-vertical" />
                                        </PickerTerminal>
                                    </FormGroup>
                                    <FormGroup label="Cód. autorização:" labelFor="CodAutorizacao" inline={true} >
                                        <InputGroup id="CodAutorizacao" placeholder={"Informe o código de autorização"} value={Item?.CodAutorizacao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].CodAutorizacao = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Operação:" className="operação" labelFor="Operacao" inline={true} >
                                        <HTMLSelect id="Operacao" name="Operacao" value={Item.Operacao == undefined ? "" : Item.Operacao}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Operacao = (event.currentTarget.value ?? "");
                                                if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "Debito") {
                                                    const EhDiaUtil = require('eh-dia-util');
                                                    let Vencimento = new Date();
                                                    Vencimento.setDate(Vencimento.getDate() + 1);
                                                    while (EhDiaUtil(Vencimento) == false) {
                                                        Vencimento.setDate(Vencimento.getDate() + 1);
                                                    }
                                                    ListaDeEntradas[Index].Vencimento = Vencimento;
                                                }
                                                if (Item.MeioDePagamento == "Cartao" && Item.Operacao != "Debito") {
                                                    ListaDeEntradas.map((Item_, Index_) => {
                                                        if (Index_ >= Index) {
                                                            let Vencimento = new Date(getFormularioDataDaProposta);
                                                            Vencimento.setDate(Vencimento.getDate() + (1 + Index_ - Index) * 30);
                                                            Item_.Vencimento = Vencimento;
                                                        }
                                                    });
                                                }
                                                else {
                                                    ListaDeEntradas[Index].Vencimento = ListaDeEntradas[Index].Vencimento;
                                                }
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                            <option selected value="">Selecione a operação</option>
                                            {Operacao.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                    </FormGroup>
                                    <FormGroup label="NSU:" className="nsu" labelFor="NSU" inline={true} >
                                        <InputGroup id="NSU" placeholder={"Informe o NSU"} value={Item.NSU}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].NSU = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        let EntradasVazias = []
                                        EntradasVazias = await ListaDeEntradas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == ""))
                                        var Texto = `Existe mais de uma entrada com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        await ListaDeEntradas.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Maquina ?? "") == "" && (Item__.DigitoCartao ?? "") == "" && (Item__.Bandeira ?? "") == "" && (Item__.Operacao ?? "") == "" && (Item__.NSU ?? "") == "")) {
                                                Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}ª entrada, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                            }
                                        })
                                        Texto += "\n";
                                        if (EntradasVazias.length > 0) {
                                            Toast(
                                                <div style={{}}>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == "") {
                                                                        Item_.Maquina = Item.Maquina
                                                                        Item_.DigitoCartao = Item.DigitoCartao
                                                                        Item_.Bandeira = Item.Bandeira
                                                                        Item_.Operacao = Item.Operacao
                                                                        Item_.NSU = Item.NSU
                                                                        Item.DetalharMeioDePagamento = false
                                                                        await setFormularioListaDeEntradas(ListaDeEntradas);
                                                                    }
                                                                })
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            })
                                        }
                                        else {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeEntradas(ListaDeEntradas);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                                {Item.DetalharMeioDePagamento == true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Nº da operação:" labelFor="NumeroDaOperacao" inline={true} >
                                        <InputGroup id="NumeroDaOperacao" placeholder={"00000"} value={Item.NSU}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].NumeroDaOperacao = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        let EntradasVazias = []
                                        EntradasVazias = ListaDeEntradas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == ""))
                                        var Texto = `Existe mais de uma entrada com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        ListaDeEntradas.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.NumeroDaOperacao ?? "") == "")) {
                                                Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}ª entrada, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                            }
                                        })
                                        Texto += "\n";
                                        if (EntradasVazias.length > 0) {
                                            Toast(
                                                <div>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == "") {
                                                                        Item_.NumeroDaOperacao = Item.NumeroDaOperacao
                                                                        Item.DetalharMeioDePagamento = false
                                                                        await setFormularioListaDeEntradas(ListaDeEntradas);
                                                                    }
                                                                })
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            })
                                        }
                                        else {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeEntradas(ListaDeEntradas);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                            </div>
                        )}
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <FormGroup label="Total gerado:" labelFor="EntradaValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                                <InputGroup id="EntradaValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto(getFormularioListaDeEntradas.reduce((total, parcela) => (total + parcela.Valor), 0))} leftIcon="numerical" />
                            </FormGroup>
                            <FormGroup style={{ marginRight: "10px" }} label="Total esperado:" labelFor="EntradaValorTotalEsperado" inline={true} >
                                <InputGroup id="EntradaValorTotalEsperado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0)} leftIcon="numerical" />
                            </FormGroup>
                            <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeEntradas.reduce((total, parcela) => (total + parcela.Valor), 0)) ? "A distribuir:" : "Excedido"} labelFor="EntradaValorTotalADistribuir" inline={true}>
                                <InputGroup id="EntradaValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) - getFormularioListaDeEntradas.reduce((total, parcela) => (total + parcela.Valor), 0))} leftIcon="numerical" />
                            </FormGroup>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>}
                {getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2) && <Component.ContentCard>
                    <Component.Title>
                        <Icon icon="form" iconSize={25} />
                        <h3>Sobre o sinal</h3>
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <FormGroup label="Quantidade de parcelas:" labelFor="SinalQtde" inline={true} >
                            <InputGroup id="SinalQtde" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(getFormularioSinalQtde)} leftIcon="numerical"
                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                    var ListaDeSinais = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                    var SinalValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0;
                                    var SinalQtde = Numero.DesformatarTextoParaInteiro(event.currentTarget.value);
                                    var SinalVencimento = new Date(getFormularioListaDeEntradas[getFormularioListaDeEntradas.length - 1] != undefined ? getFormularioListaDeEntradas[getFormularioListaDeEntradas.length - 1].Vencimento : new Date());

                                    await setFormularioSinalQtde(SinalQtde);
                                    for (let i = 0; i < SinalQtde; i++) {
                                        let Vencimento = new Date(SinalVencimento);
                                        Vencimento.setMonth(SinalVencimento.getMonth() + 1);
                                        ListaDeSinais.push({
                                            Valor: (i == SinalQtde - 1) ? (SinalValor - Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / (SinalQtde))) * (SinalQtde - 1)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / SinalQtde)),
                                            Vencimento: Vencimento,
                                            ExibirCalendarioVencimento: false,
                                            MeioDePagamento: ""
                                        });
                                        SinalVencimento = Vencimento;
                                    }
                                    await setFormularioListaDeSinais(ListaDeSinais);

                                    var ParcelaQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0;
                                    var ParcelaVencimento = new Date(SinalVencimento);
                                    ParcelaVencimento.setMonth(ParcelaVencimento.getMonth() + 1);
                                    await setFormularioParcelaValor(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0);
                                    await setFormularioParcelaQtde(ParcelaQtde);
                                    await setFormularioParcelaVencimento(new Date(ParcelaVencimento));
                                }}>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup label="% sobre o valor a vista:" labelFor="SinalPercentual" inline={true} >
                            <InputGroup id="SinalPercentual" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaDecimal(getFormularioSinalPercentual * 100)} leftIcon="percentage"
                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                    var SinalPercentual = Numero.DesformatarTextoParaDecimal(event.currentTarget.value) / 100;
                                    for (let i = 0; i < getFormularioListaDeSinais.length; i++) {
                                        getFormularioListaDeSinais[i].Valor = (getItemPickerIdentificador?.valorAVista ?? 0) / getFormularioSinalQtde * SinalPercentual;
                                    }
                                    await setFormularioListaDeSinais(getFormularioListaDeSinais);
                                    await setFormularioSinalPercentual(SinalPercentual);

                                    let ParcelaPercentual = ((getItemPickerIdentificador?.valorAVista ?? 0) - ((getFormularioListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                        (getFormularioListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                        (getFormularioListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0))) / (getItemPickerIdentificador?.valorAVista ?? 0)
                                    await setFormularioParcelaPercentual(ParcelaPercentual);
                                    for (let i = 0; i < getFormularioListaDeParcelas.length; i++) {
                                        getFormularioListaDeParcelas[i].Valor = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].Valor * ParcelaPercentual / getFormularioParcelaPercentual));
                                        getFormularioListaDeParcelas[i].ValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorPMT * ParcelaPercentual / getFormularioParcelaPercentual));
                                        getFormularioListaDeParcelas[i].ValorTotal = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotal * ParcelaPercentual / getFormularioParcelaPercentual));
                                        getFormularioListaDeParcelas[i].ValorTotalJuros = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotalJuros * ParcelaPercentual / getFormularioParcelaPercentual));
                                    }
                                }}>
                            </InputGroup>
                        </FormGroup>
                        {getFormularioSinalQtde > 0 && <div style={{ display: "flex" }}>
                            <div style={{ width: "10vw", textAlign: "center" }}>
                                <h5>Valor da parcela</h5>
                            </div>
                            <div style={{ width: "10vw", marginLeft: "10px", textAlign: "center" }}>
                                <h5>Vencimento</h5>
                            </div>
                            <div style={{ width: "20vw", marginLeft: "10px", textAlign: "center" }}>
                                <h5>Meio de pagamento</h5>
                            </div>
                        </div>}
                        {getFormularioListaDeSinais.map((Item, Index) =>
                            <div style={{ display: "block" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "10vw", marginRight: "10px" }}>
                                        <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.Valor)} leftIcon="dollar"
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Valor = (Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00);
                                                await setFormularioListaDeSinais(ListaDeSinais);

                                                await setFormularioSinalPercentual((getFormularioListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) / (getItemPickerIdentificador?.valorAVista ?? 0));
                                                let ParcelaPercentual = ((getItemPickerIdentificador?.valorAVista ?? 0) - ((getFormularioListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                                    (getFormularioListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                                    (getFormularioListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0))) / (getItemPickerIdentificador?.valorAVista ?? 0);
                                                await setFormularioParcelaPercentual(ParcelaPercentual);
                                                for (let i = 0; i < getFormularioListaDeParcelas.length; i++) {
                                                    getFormularioListaDeParcelas[i].Valor = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].Valor * ParcelaPercentual / getFormularioParcelaPercentual));
                                                    getFormularioListaDeParcelas[i].ValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorPMT * ParcelaPercentual / getFormularioParcelaPercentual));
                                                    getFormularioListaDeParcelas[i].ValorTotal = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotal * ParcelaPercentual / getFormularioParcelaPercentual));
                                                    getFormularioListaDeParcelas[i].ValorTotalJuros = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotalJuros * ParcelaPercentual / getFormularioParcelaPercentual));
                                                }
                                            }}>
                                        </InputGroup>
                                    </div>
                                    <div style={{ width: "10vw", marginRight: "30px" }}>
                                        <DateInput inputProps={{
                                            onClick: () => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].ExibirCalendarioVencimento = true;
                                                setFormularioListaDeSinais(ListaDeSinais);
                                            }
                                        }}
                                            dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{
                                                disabled: !Item.ExibirCalendarioVencimento, onClosed: () => {
                                                    let ListaDeSinais = [...getFormularioListaDeSinais];
                                                    ListaDeSinais[Index].ExibirCalendarioVencimento = false;
                                                    setFormularioListaDeSinais(ListaDeSinais);
                                                }
                                            }} parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} value={Item.Vencimento} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                            onChange={async (DataSelecionada: Date, AlteracaoDeUsuario: boolean) => {
                                                if (AlteracaoDeUsuario == true) {

                                                    let ListaDeSinais = [...getFormularioListaDeSinais];
                                                    if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                        ListaDeSinais[Index].Vencimento = (getFormularioDataDaProposta ?? new Date());
                                                    }
                                                    else {
                                                        if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "CreditoParcelado") {
                                                            ListaDeSinais.map((Item_, Index_) => {
                                                                if (Index_ >= Index && Item_.MeioDePagamento == "Cartao" && Item_.Operacao == "CreditoParcelado") {
                                                                    let Vencimento = new Date(DataSelecionada);
                                                                    Vencimento.setDate(Vencimento.getDate() + (Index_ - Index) * 30);
                                                                    Item_.Vencimento = Vencimento;
                                                                }
                                                            });
                                                            await setFormularioListaDeSinais(ListaDeSinais);
                                                        }
                                                        else {
                                                            if (DataSelecionada.getDate() != 5 && DataSelecionada.getDate() != 15 && ListaDeSinais[Index].MeioDePagamento == "Boleto") {
                                                                NotificacaoInterna.ExibirNotificacao("Data invalida", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);

                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais[Index].Vencimento = ListaDeSinais[Index].Vencimento;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                                return;
                                                            }
                                                            else {
                                                                if (ListaDeSinais[Index].MeioDePagamento == "Boleto" && ListaDeSinais.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == "Boleto")).length > 0) {
                                                                    Toast(
                                                                        <div style={{}}>
                                                                            <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`Existem sinais do tipo boleto com o dia de vencimento diferente da primeira parcela, deseja igualar o dia de vencimento de todos os sinais do tipo boleto?`}</p>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                                        ListaDeSinais[Index].Vencimento = DataSelecionada;
                                                                                        await setFormularioListaDeSinais(ListaDeSinais);
                                                                                        ListaDeSinais.map(async (Item_, Index_) => {
                                                                                            ListaDeSinais = [...getFormularioListaDeSinais];
                                                                                            let Vencimento = new Date(Item_.Vencimento);
                                                                                            Vencimento.setDate(ListaDeSinais[Index].Vencimento.getDate());
                                                                                            Vencimento.setFullYear(ListaDeSinais[Index].Vencimento.getFullYear());
                                                                                            Vencimento.setMonth(ListaDeSinais[Index].Vencimento.getMonth() + Index_ - Index);
                                                                                            if (Index_ > Index && Item_.MeioDePagamento == "Boleto") {
                                                                                                Item_.Vencimento = Vencimento;
                                                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                                                            }
                                                                                            if (Index_ == ListaDeSinais.length - 1) {
                                                                                                Vencimento = new Date(Vencimento);
                                                                                                Vencimento.setMonth(Vencimento.getMonth() + 1);
                                                                                                await setFormularioParcelaVencimento(Vencimento);
                                                                                            }
                                                                                        });
                                                                                    }}>Sim</Button>
                                                                                <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => { }}>Não</Button>
                                                                            </div>
                                                                        </div>, {
                                                                        position: Toast.POSITION.TOP_CENTER,
                                                                        autoClose: false,
                                                                        style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                    });
                                                                }
                                                                else {
                                                                    let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                    ListaDeSinais[Index].Vencimento = DataSelecionada;
                                                                    await setFormularioListaDeSinais(ListaDeSinais);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }}>
                                        </DateInput>
                                    </div>
                                    <div style={{ width: "20vw" }}>
                                        <Component.ContentFormHeader style={{ margin: "0" }}>
                                            <FormGroup labelFor="MeioDePagamento" inline={true}>
                                                <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={Item.MeioDePagamento}
                                                    onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                                        ListaDeSinais[Index].MeioDePagamento = event.currentTarget.value;
                                                        ListaDeSinais[Index].Banco = undefined;
                                                        ListaDeSinais[Index].Agencia = undefined;
                                                        ListaDeSinais[Index].Conta = undefined;
                                                        ListaDeSinais[Index].DigitoDaConta = undefined;
                                                        ListaDeSinais[Index].Titular = undefined;
                                                        ListaDeSinais[Index].NumeroCheque = undefined;
                                                        ListaDeSinais[Index].Maquina = undefined;
                                                        ListaDeSinais[Index].Bandeira = undefined;
                                                        ListaDeSinais[Index].DigitoCartao = undefined;
                                                        ListaDeSinais[Index].Operacao = undefined;
                                                        ListaDeSinais[Index].NSU = undefined;
                                                        ListaDeSinais[Index].NumeroDaOperacao = undefined;
                                                        await setFormularioListaDeSinais(ListaDeSinais);
                                                    }}>
                                                    <option selected value="">Selecione o meio de pagamento</option>
                                                    {MeioDePagamento.map((Item) =>
                                                        <option selected value={Item.chave}>{Item.Valor}</option>
                                                    )}
                                                </HTMLSelect>
                                            </FormGroup>
                                        </Component.ContentFormHeader>
                                    </div>
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cheque" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        ListaDeSinais[Index].DetalharMeioDePagamento = !(ListaDeSinais[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeSinais(ListaDeSinais);
                                    }}>
                                        <strong>Sobre o cheque:</strong> Banco: {Item.Banco?.chave ?? "--"}, Agência: {Item.Agencia ?? "--"}, Conta: {Item.Conta ?? "--"}{Item.DigitoDaConta == undefined ? "" : ("-" + Item.DigitoDaConta)}, Titular: {Item.Titular ?? "--"}, Nº do cheque: {Item.NumeroCheque ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cartao" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        ListaDeSinais[Index].DetalharMeioDePagamento = !(ListaDeSinais[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeSinais(ListaDeSinais);
                                    }}>
                                        <strong>Sobre o cartão:</strong> Máquina: {Item.Maquina ?? "--"}, Digito: {Item.DigitoCartao ?? "--"}, Bandeira: {Item.Bandeira ?? "--"}, Operação: {Item.Operacao ?? "--"}, NSU: {Item.NSU ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        ListaDeSinais[Index].DetalharMeioDePagamento = !(ListaDeSinais[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeSinais(ListaDeSinais);
                                    }}>
                                        <strong>Sobre o {Item.MeioDePagamento}:</strong> Nº da operação: {Item.NumeroDaOperacao ?? "--"}
                                    </Component.DescriptionContent>}
                                </div>
                                {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cheque" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Banco:" className="banco" labelFor="Banco" inline={true}>
                                        <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Banco = _Item;
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Banco ? ((Item.Banco).chave + ": " + (Item.Banco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                        </PickerBanco>
                                    </FormGroup>
                                    <FormGroup label="Agência:" className="agência" labelFor="Agencia" inline={true} >
                                        <InputGroup id="Agencia" placeholder={"00000"} value={Item.Agencia}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Agencia = event.currentTarget.value ?? "";
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Component.FormContent>
                                        <FormGroup label="Conta:" className="conta" labelFor="Conta" inline={true} >
                                            <InputGroup id="Conta" placeholder={"00000"} value={Item.Conta}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let ListaDeSinais = [...getFormularioListaDeSinais];
                                                    ListaDeSinais[Index].Conta = (event.currentTarget.value ?? "");
                                                    await setFormularioListaDeSinais(ListaDeSinais);
                                                }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="- " labelFor="DigitoDaConta" inline={true} style={{ marginLeft: "20px" }} >
                                            <InputGroup id="DigitoDaConta" placeholder={"00"} value={Item.DigitoDaConta}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let ListaDeSinais = [...getFormularioListaDeSinais];
                                                    ListaDeSinais[Index].DigitoDaConta = (event.currentTarget.value ?? "");
                                                    await setFormularioListaDeSinais(ListaDeSinais);
                                                }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </Component.FormContent>
                                    <FormGroup label="Titular:" className="titular" labelFor="Titular" inline={true} >
                                        <InputGroup id="Titular" placeholder={"Informe o nominal"} value={Item.Titular}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Titular = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Nº do cheque:" labelFor="NumeroCheque" inline={true} >
                                        <InputGroup id="NumeroCheque" placeholder={"Informe o número"} value={Item.NumeroCheque}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].NumeroCheque = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        let SinaisVazios = []
                                        SinaisVazios = ListaDeSinais.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == ""))

                                        var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        ListaDeSinais.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Banco ?? "") == "" && (Item__.Agencia ?? "") == "" && (Item__.Conta ?? "") == "" && (Item__.DigitoDaConta ?? "") == "" && (Item__.Titular ?? "") == "" && (Item__.NumeroCheque ?? "") == "")) {
                                                Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                            }
                                        })
                                        Texto += "\n";
                                        if (SinaisVazios.length > 0) {
                                            Toast(
                                                <div style={{}}>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let SinaisVazios = [...getFormularioListaDeSinais];
                                                                SinaisVazios.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == "") {
                                                                        Item_.Banco = Item.Banco
                                                                        Item_.Agencia = Item.Agencia
                                                                        Item_.Conta = Item.Conta
                                                                        Item_.DigitoDaConta = Item.DigitoDaConta
                                                                        Item_.Titular = Item.Titular
                                                                        Item_.NumeroCheque = Item.NumeroCheque
                                                                        Item.DetalharMeioDePagamento = false
                                                                        await setFormularioListaDeSinais(ListaDeSinais);
                                                                    }
                                                                })
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            })
                                        }
                                        else {
                                            let ListaDeSinais = [...getFormularioListaDeSinais];
                                            ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeSinais(ListaDeSinais);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                                {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cartao" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Máquina:" className="máquina" labelFor="Maquina" inline={true} >
                                        <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Maquina = _Item.Valor;
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Maquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                        </PickerMaquina>
                                    </FormGroup>
                                    <FormGroup label="Dígito do cartão:" labelFor="DigitoCartao" inline={true} >
                                        <InputGroup id="DigitoCartao" placeholder={"Informe os últimos 04 dígitos do cartão"} value={Item.DigitoCartao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].DigitoCartao = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Bandeira:" className="bandeira" labelFor="Bandeira" inline={true} >
                                        <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Bandeira = _Item.Valor;
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Bandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                        </PickerMaquina>
                                    </FormGroup>
                                    <FormGroup label="Terminal:" className="terminal" labelFor="Terminal" inline={true} >
                                        <PickerTerminal popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={GetTerminais} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { id: number, descricao: string }) => <MenuItem onClick={async () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Terminal = _Item.id;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }} text={_Item.descricao} />} itemPredicate={(Texto: string, _Item: { id: number, descricao: string }) => { return ((_Item.descricao) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={GetTerminais.find((_Item) => _Item.id == Item.Terminal)?.descricao ?? "Selecione o terminal"} rightIcon="double-caret-vertical" />
                                        </PickerTerminal>
                                    </FormGroup>
                                    <FormGroup label="Cód. autorização:" labelFor="CodAutorizacao" inline={true} >
                                        <InputGroup id="CodAutorizacao" placeholder={"Informe o código de autorização"} value={Item?.CodAutorizacao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].CodAutorizacao = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Operação:" className="operação" labelFor="Operacao" inline={true} >
                                        <HTMLSelect id="Operacao" name="Operacao" value={Item.Operacao == undefined ? "" : Item.Operacao}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Operacao = (event.currentTarget.value ?? "");
                                                if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "Debito") {
                                                    const EhDiaUtil = require('eh-dia-util');
                                                    let Vencimento = new Date();
                                                    Vencimento.setDate(Vencimento.getDate() + 1);
                                                    while (EhDiaUtil(Vencimento) == false) {
                                                        Vencimento.setDate(Vencimento.getDate() + 1);
                                                    }
                                                    ListaDeSinais[Index].Vencimento = Vencimento;
                                                }
                                                if (Item.MeioDePagamento == "Cartao" && Item.Operacao != "Debito") {
                                                    ListaDeSinais.map((Item_, Index_) => {
                                                        if (Index_ >= Index) {
                                                            let Vencimento = new Date(getFormularioDataDaProposta);
                                                            Vencimento.setDate(Vencimento.getDate() + (1 + Index_ - Index) * 30);
                                                            Item_.Vencimento = Vencimento;
                                                        }
                                                    });
                                                }
                                                else {
                                                    ListaDeSinais[Index].Vencimento = ListaDeSinais[Index].Vencimento;
                                                }
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                            <option selected value="">Selecione a operação</option>
                                            {Operacao.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                    </FormGroup>
                                    <FormGroup label="NSU:" className="nsu" labelFor="NSU" inline={true} >
                                        <InputGroup id="NSU" placeholder={"Informe o NSU"} value={Item.NSU}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].NSU = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        let SinaisVazios = []
                                        SinaisVazios = ListaDeSinais.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == ""))
                                        var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        ListaDeSinais.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Maquina ?? "") == "" && (Item__.DigitoCartao ?? "") == "" && (Item__.Bandeira ?? "") == "" && (Item__.Operacao ?? "") == "" && (Item__.NSU ?? "") == "")) {
                                                Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                            }
                                        })
                                        Texto += "\n";
                                        if (SinaisVazios.length > 0) {
                                            Toast(
                                                <div style={{}}>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == "") {
                                                                        Item_.Maquina = Item.Maquina
                                                                        Item_.DigitoCartao = Item.DigitoCartao
                                                                        Item_.Bandeira = Item.Bandeira
                                                                        Item_.Operacao = Item.Operacao
                                                                        Item_.NSU = Item.NSU
                                                                        Item.DetalharMeioDePagamento = false
                                                                        await setFormularioListaDeSinais(ListaDeSinais);
                                                                    }
                                                                })
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            })
                                        }
                                        else {
                                            let ListaDeSinais = [...getFormularioListaDeSinais];
                                            ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeSinais(ListaDeSinais);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                                {Item.DetalharMeioDePagamento == true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Nº da operação:" labelFor="NumeroDaOperacao" inline={true} >
                                        <InputGroup id="NumeroDaOperacao" placeholder={"00000"} value={Item.NSU}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].NumeroDaOperacao = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        let SinaisVazios = []
                                        SinaisVazios = ListaDeSinais.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == ""))
                                        var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        ListaDeSinais.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.NumeroDaOperacao ?? "") == "")) {
                                                Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                            }
                                        })
                                        Texto += "\n";
                                        if (SinaisVazios.length > 0) {
                                            Toast(
                                                <div style={{}}>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == "") {
                                                                        Item_.NumeroDaOperacao = Item.NumeroDaOperacao
                                                                        Item.DetalharMeioDePagamento = false
                                                                        await setFormularioListaDeSinais(ListaDeSinais);
                                                                    }
                                                                })
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            });
                                        }
                                        else {
                                            let ListaDeSinais = [...getFormularioListaDeSinais];
                                            ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeSinais(ListaDeSinais);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                            </div>
                        )}
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <FormGroup label="Total gerado:" labelFor="SinalValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                                <InputGroup id="SinalValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                    value={Moeda.FormatarTexto(getFormularioListaDeSinais.reduce((total, parcela) => (total + parcela.Valor), 0))} leftIcon="numerical" />
                            </FormGroup>
                            <FormGroup style={{ marginRight: "10px" }} label="Total esperado:" labelFor="SinalValorTotalEsperado" inline={true} >
                                <InputGroup id="SinalValorTotalEsperado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                    value={Moeda.FormatarTexto(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0)} leftIcon="numerical" />
                            </FormGroup>
                            <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeSinais.reduce((total, parcela) => (total + parcela.Valor), 0)) ? "A distribuir" : "Excedido"} labelFor="SinalValorTotalADistribuir" inline={true}>
                                <InputGroup id="SinalValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                    value={Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) - getFormularioListaDeSinais.reduce((total, parcela) => (total + parcela.Valor), 0))} leftIcon="numerical" />
                            </FormGroup>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>}
                {getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao) && <Component.ContentCard>
                    <Component.Title>
                        <Icon icon={getHabilitarInclusaoDeIntermediarias == true ? "remove" : "add"} style={{ cursor: "pointer" }} iconSize={20} onClick={async () => { await setHabilitarInclusaoDeIntermediarias(!getHabilitarInclusaoDeIntermediarias); }} />
                        <h3>Sobre a intermediária</h3>
                        {(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) > 0 && <h6 style={{ marginTop: "30px", marginLeft: "10px" }}>(taxa de juros de {Percentual.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) * 100)}  a.m.)</h6>}
                    </Component.Title>
                    <FormGroup label="% sobre o valor a vista:" labelFor="IntermediariaPercentual" inline={true} >
                        <InputGroup id="IntermediariaPercentual" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaDecimal(getFormularioIntermediariaPercentual * 100)} leftIcon="percentage"
                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                var IntermediariaPercentual = Numero.DesformatarTextoParaDecimal(event.currentTarget.value) / 100;
                                for (let i = 0; i < getFormularioListaDeIntermediarias.length; i++) {
                                    getFormularioListaDeIntermediarias[i].Valor = (getItemPickerIdentificador?.valorAVista ?? 0) / getFormularioListaDeIntermediarias[i].Quantidade * IntermediariaPercentual;
                                    getFormularioListaDeIntermediarias[i].ValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), getFormularioListaDeIntermediarias[i].Quantidade, getFormularioListaDeIntermediarias[i].Quantidade * getFormularioListaDeIntermediarias[i].Valor, 0)));
                                    getFormularioListaDeIntermediarias[i].ValorTotal = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeIntermediarias[i].Valor * getFormularioListaDeIntermediarias[i].Quantidade));
                                    getFormularioListaDeIntermediarias[i].ValorTotalJuros = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeIntermediarias[i].ValorPMT * getFormularioListaDeIntermediarias[i].Quantidade));
                                }
                                await setFormularioListaDeIntermediarias(getFormularioListaDeIntermediarias);
                                await setFormularioIntermediariaPercentual(IntermediariaPercentual);

                                let ParcelaPercentual = ((getItemPickerIdentificador?.valorAVista ?? 0) - ((getFormularioListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                    (getFormularioListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                    (getFormularioListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0))) / (getItemPickerIdentificador?.valorAVista ?? 0)
                                await setFormularioParcelaPercentual(ParcelaPercentual);
                                for (let i = 0; i < getFormularioListaDeParcelas.length; i++) {
                                    getFormularioListaDeParcelas[i].Valor = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].Valor * ParcelaPercentual / getFormularioParcelaPercentual));
                                    getFormularioListaDeParcelas[i].ValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorPMT * ParcelaPercentual / getFormularioParcelaPercentual));
                                    getFormularioListaDeParcelas[i].ValorTotal = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotal * ParcelaPercentual / getFormularioParcelaPercentual));
                                    getFormularioListaDeParcelas[i].ValorTotalJuros = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotalJuros * ParcelaPercentual / getFormularioParcelaPercentual));
                                }
                            }}>
                        </InputGroup>
                    </FormGroup>
                    <Collapse isOpened={getHabilitarInclusaoDeIntermediarias} theme={{ collapse: 'foo', content: 'bar' }}>
                        <div style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", padding: "10px" }}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <FormGroup label="Quantidade de Intermediarias:" labelFor="IntermediariaQtde" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <InputGroup id="IntermediariaQtde" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(getFormularioIntermediariaQtde)} leftIcon="numerical" style={{ width: "235px" }}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let Valor = Numero.DesformatarTextoParaInteiro(event.currentTarget.value) ?? 0;
                                                await setFormularioIntermediariaQtde(Valor)
                                                await setFormularioIntermediariaValor(getFormularioIntermediariaValor * Valor / getFormularioIntermediariaQtde);
                                                await setFormularioIntermediariaValorComJuros(getFormularioIntermediariaValorComJuros * Valor / getFormularioIntermediariaQtde);
                                            }} />
                                    </FormGroup>
                                    <FormGroup label="Valor principal:" labelFor="IntermediariaValor" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="IntermediariaValor" style={{ display: "flex" }}>
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(getFormularioIntermediariaValor)} leftIcon="dollar"
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let Valor = Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00;
                                                    await setFormularioIntermediariaValor(Valor);
                                                    await setFormularioIntermediariaValorComJuros(Moeda.DesformatarTexto(Moeda.FormatarTexto(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), getFormularioIntermediariaQtde, getFormularioIntermediariaQtde * Valor, 0))));
                                                }}
                                                style={{ width: "235px" }}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>
                                    {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <FormGroup label="Valor principal C/Juros:" labelFor="IntermediariaValorTotal" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="IntermediariaValorPrincipalJuros">
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(getFormularioIntermediariaValorComJuros)} leftIcon="dollar" style={{ width: "235px" }}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let Valor = Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00;
                                                    await setFormularioIntermediariaValor(Moeda.DesformatarTexto(Moeda.FormatarTexto(pv((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), getFormularioIntermediariaQtde, Valor, 0) / getFormularioIntermediariaQtde)));
                                                    await setFormularioIntermediariaValorComJuros(Valor);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>}
                                    {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <FormGroup label="Valor total:" labelFor="IntermediariaValorTotal" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="IntermediariaValorTotal">
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(getFormularioIntermediariaValor * getFormularioIntermediariaQtde)} leftIcon="dollar" style={{ width: "235px" }} />
                                        </div>
                                    </FormGroup>}
                                    {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <FormGroup label="Valor total C/Juros:" labelFor="IntermediariaValorTotal" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="IntermediariaValorTotalJuros">
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto((getFormularioIntermediariaQtde > 0 ? Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (getFormularioIntermediariaQtde), (getFormularioIntermediariaQtde * getFormularioIntermediariaValor), 0)))) : 0) * getFormularioIntermediariaQtde)} leftIcon="dollar" style={{ width: "235px" }} />
                                        </div>
                                    </FormGroup>}
                                    <FormGroup label="Primeiro vencimento:" labelFor="IntermediariaVencimento" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <DateInput inputProps={{ style: { marginLeft: "18px", width: "235px" }, onClick: () => setFormularioIntermediariaExibirCalendarioVencimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{ disabled: !getFormularioIntermediariaExibirCalendarioVencimento, onClosed: () => setFormularioIntermediariaExibirCalendarioVencimento(false) }}
                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} value={getFormularioIntermediariaVencimento} maxDate={new Date((new Date().getFullYear() + 40).toString() + "-01-01")}
                                            onChange={async (DataSelecionada: Date) => {
                                                if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                    await setFormularioIntermediariaVencimento(getFormularioDataDaProposta ?? new Date());
                                                }
                                                else {
                                                    if (DataSelecionada.getDate() != 5 && DataSelecionada.getDate() != 15 && getFormularioIntermediariaMeioDePagamento == "Boleto") {
                                                        NotificacaoInterna.ExibirNotificacao("Data invalida", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        await setFormularioIntermediariaVencimento(getFormularioIntermediariaVencimento);
                                                        return;
                                                    }
                                                    await setFormularioIntermediariaVencimento(new Date(DataSelecionada));
                                                }
                                            }}>
                                        </DateInput>
                                    </FormGroup>
                                </div>
                                <div style={{ marginLeft: "20px" }}>
                                    <FormGroup label="Meio de pagamento:" labelFor="MeioDePagamento" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={getFormularioIntermediariaMeioDePagamento}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => { await setFormularioIntermediariaMeioDePagamento(event.currentTarget.value); }}>
                                            <option selected value="">Selecione o meio de pagamento</option>
                                            {MeioDePagamento.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                    </FormGroup>
                                    {getFormularioIntermediariaMeioDePagamento == "Cheque" && <div>
                                        <FormGroup label="Banco:" style={{ color: "black", fontSize: "14px" }} labelFor="Banco" inline={true}>
                                            <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioIntermediariaBanco(_Item); }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "84px", width: "12vw" }} text={getFormularioIntermediariaBanco ? ((getFormularioIntermediariaBanco).chave + ": " + (getFormularioIntermediariaBanco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                            </PickerBanco>
                                        </FormGroup>
                                        <FormGroup label="Agência:" style={{ color: "black", fontSize: "14px" }} labelFor="Agencia" inline={true} >
                                            <InputGroup id="Agencia" style={{ marginLeft: "73px", width: "12vw" }} placeholder={"00000"} value={getFormularioIntermediariaAgencia}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaAgencia(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <Component.FormContent>
                                            <FormGroup label="Conta:" style={{ color: "black", fontSize: "14px" }} labelFor="Conta" inline={true} >
                                                <InputGroup id="Conta" style={{ marginLeft: "86px", width: "12vw" }} placeholder={"00000"} value={getFormularioIntermediariaConta}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaConta(event.currentTarget.value ?? ""); }}>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup label="- " style={{ color: "black", fontSize: "14px", marginLeft: "10px" }} labelFor="DigitoDaConta" inline={true}>
                                                <InputGroup id="DigitoDaConta" style={{ width: "3vw" }} placeholder={"00"} value={getFormularioIntermediariaDigitoDaConta}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaDigitoDaConta(event.currentTarget.value ?? ""); }}>
                                                </InputGroup>
                                            </FormGroup>
                                        </Component.FormContent>
                                        <FormGroup label="Titular:" style={{ color: "black", fontSize: "14px" }} labelFor="Titular" inline={true} >
                                            <InputGroup style={{ marginLeft: "82px", width: "12vw" }} placeholder={"Informe o nominal"} value={getFormularioIntermediariaTitular}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaTitular(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="Nº do cheque:" style={{ color: "black", fontSize: "14px" }} labelFor="NumeroCheque" inline={true} >
                                            <InputGroup style={{ marginLeft: "38px", width: "12vw" }} placeholder={"Informe o número"} value={getFormularioIntermediariaNumeroCheque}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaNumeroCheque(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                    {getFormularioIntermediariaMeioDePagamento == "Cartao" && <div>
                                        <FormGroup label="Máquina:" style={{ color: "black", fontSize: "14px" }} labelFor="Maquina" inline={true} >
                                            <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioIntermediariaMaquina(_Item.Valor); }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "70px", width: "12vw" }} text={getFormularioIntermediariaMaquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                            </PickerMaquina>
                                        </FormGroup>
                                        <FormGroup label="Dígito do cartão:" style={{ color: "black", fontSize: "14px" }} labelFor="DigitoCartao" inline={true} >
                                            <InputGroup style={{ marginLeft: "25px", width: "12vw" }} placeholder={"Informe os últimos 04 dígitos do cartão"} value={getFormularioIntermediariaDigitoCartao}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaDigitoCartao(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="Bandeira:" style={{ color: "black", fontSize: "14px" }} labelFor="Bandeira" inline={true} >
                                            <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioIntermediariaBandeira(_Item.Valor); }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "69px", width: "12vw" }} text={getFormularioIntermediariaBandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                            </PickerMaquina>
                                        </FormGroup>
                                        <FormGroup label="Operação:" style={{ color: "black", fontSize: "14px" }} labelFor="Operacao" inline={true} >
                                            <HTMLSelect name="Operacao" style={{ marginLeft: "64px", width: "12vw" }} value={getFormularioIntermediariaOperacao == undefined ? "" : getFormularioIntermediariaOperacao}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => { await setFormularioIntermediariaOperacao(event.currentTarget.value ?? ""); }}>
                                                <option selected value="">Selecione a operação</option>
                                                {Operacao.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                        </FormGroup>
                                        <FormGroup label="NSU:" style={{ color: "black", fontSize: "14px" }} labelFor="NSU" inline={true} >
                                            <InputGroup style={{ marginLeft: "95px", width: "12vw" }} placeholder={"Informe o NSU"} value={getFormularioIntermediariaNSU}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaNSU(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                    {(getFormularioIntermediariaMeioDePagamento == "Deposito" || getFormularioIntermediariaMeioDePagamento == "Transferencia" || getFormularioIntermediariaMeioDePagamento == "PIX") && <div>
                                        <FormGroup label="Nº da operação:" style={{ color: "black", fontSize: "14px" }} labelFor="NumeroDaOperacao" inline={true} >
                                            <InputGroup style={{ marginLeft: "27px", width: "12vw" }} placeholder={"00000"} value={getFormularioIntermediariaNSU}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaNSU(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: "right" }}>
                                <Button type="submit" intent="primary" style={{ marginRight: "50px", width: '100px' }}
                                    onClick={async () => {
                                        if (!getItemPickerCondicaoDaTabelaDeVenda) {
                                            NotificacaoInterna.ExibirNotificacao("Nenhum modelo de venda selecionado", "Selecione o modelo de vendas", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (QtdeDeIntermediarias(getFormularioIntermediariaVencimento) <= 0) {
                                            NotificacaoInterna.ExibirNotificacao("Quantidade de Intermediarias é invalida", "Informe uma quantidade de Intermediarias maior ou igual a 01", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (getItemPickerCondicaoDaTabelaDeVenda.descricao.toUpperCase().includes("VISTA") == false && getFormularioIntermediariaValor.toFixed(2) != getFormularioIntermediariaValor.toFixed(2)) {
                                            NotificacaoInterna.ExibirNotificacao("Valor gerado de sinal diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(getFormularioIntermediariaValor) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(getFormularioIntermediariaValor), NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (!getFormularioIntermediariaVencimento || getFormularioIntermediariaVencimento == null || getFormularioIntermediariaVencimento < getFormularioDataDaProposta) {
                                            NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (getFormularioIntermediariaVencimento.getDate() != 5 && getFormularioIntermediariaVencimento.getDate() != 15 && getFormularioIntermediariaMeioDePagamento == "Boleto") {
                                            NotificacaoInterna.ExibirNotificacao("Vencimento invalido", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                            await setFormularioIntermediariaVencimento(getFormularioIntermediariaVencimento);
                                            return;
                                        }
                                        if (getFormularioIntermediariaMeioDePagamento == "") {
                                            NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existem meios de pagamento referente ao sinal que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias.push({
                                            Quantidade: getFormularioIntermediariaQtde,
                                            ValorPMT: getFormularioIntermediariaQtde > 0 ? Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (getFormularioIntermediariaQtde), (getFormularioIntermediariaQtde * getFormularioIntermediariaValor), 0)))) : 0,
                                            ValorTotal: (getFormularioIntermediariaValor * getFormularioIntermediariaQtde),
                                            ValorTotalJuros: getFormularioIntermediariaQtde > 0 ? (Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (getFormularioIntermediariaQtde), (getFormularioIntermediariaQtde * getFormularioIntermediariaValor), 0)))) * getFormularioIntermediariaQtde) : 0,
                                            Valor: getFormularioIntermediariaValor,
                                            Vencimento: getFormularioIntermediariaVencimento,
                                            Banco: getFormularioIntermediariaBanco,
                                            Agencia: getFormularioIntermediariaAgencia,
                                            Conta: getFormularioIntermediariaConta,
                                            DigitoDaConta: getFormularioIntermediariaDigitoDaConta,
                                            Titular: getFormularioIntermediariaTitular,
                                            NumeroCheque: getFormularioIntermediariaNumeroCheque,
                                            Maquina: getFormularioIntermediariaMaquina,
                                            Bandeira: getFormularioIntermediariaBandeira,
                                            DigitoCartao: getFormularioIntermediariaDigitoCartao,
                                            Operacao: getFormularioIntermediariaOperacao,
                                            NSU: getFormularioIntermediariaNSU,
                                            NumeroDaOperacao: getFormularioIntermediariaNumeroDaOperacao,
                                            MeioDePagamento: getFormularioIntermediariaMeioDePagamento,
                                            DetalharMeioDePagamento: false,
                                        } as { Quantidade: number, ValorPMT: number, ValorTotal: number, ValorTotalJuros: number, Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined })
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        setHabilitarInclusaoDeIntermediarias(false);

                                        await setFormularioIntermediariaPercentual((ListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0) / (getItemPickerIdentificador?.valorAVista ?? 0));
                                        let ParcelaPercentual = ((getItemPickerIdentificador?.valorAVista ?? 0) - ((getFormularioListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                            (getFormularioListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                            (ListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0))) / (getItemPickerIdentificador?.valorAVista ?? 0);
                                        await setFormularioParcelaPercentual(ParcelaPercentual);
                                        for (let i = 0; i < getFormularioListaDeParcelas.length; i++) {
                                            getFormularioListaDeParcelas[i].Valor = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].Valor * ParcelaPercentual / getFormularioParcelaPercentual));
                                            getFormularioListaDeParcelas[i].ValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorPMT * ParcelaPercentual / getFormularioParcelaPercentual));
                                            getFormularioListaDeParcelas[i].ValorTotal = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotal * ParcelaPercentual / getFormularioParcelaPercentual));
                                            getFormularioListaDeParcelas[i].ValorTotalJuros = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotalJuros * ParcelaPercentual / getFormularioParcelaPercentual));
                                        }

                                    }}>Salvar</Button>
                                <Button type="submit" intent="danger" style={{ width: '100px' }}
                                    onClick={async () => {
                                        await setFormularioIntermediariaValor(0);
                                        await setFormularioIntermediariaVencimento(undefined);
                                        await setFormularioIntermediariaBanco(undefined);
                                        await setFormularioIntermediariaAgencia(undefined);
                                        await setFormularioIntermediariaConta(undefined);
                                        await setFormularioIntermediariaDigitoDaConta(undefined);
                                        await setFormularioIntermediariaTitular(undefined);
                                        await setFormularioIntermediariaNumeroCheque(undefined);
                                        await setFormularioIntermediariaMaquina(undefined);
                                        await setFormularioIntermediariaBandeira(undefined);
                                        await setFormularioIntermediariaDigitoCartao(undefined);
                                        await setFormularioIntermediariaOperacao(undefined);
                                        await setFormularioIntermediariaNSU(undefined);
                                        await setFormularioIntermediariaNumeroDaOperacao(undefined);
                                    }}>Limpar</Button>
                            </div>
                        </div>
                    </Collapse>
                    {getFormularioListaDeIntermediarias.length > 0 && <div style={{ display: "flex" }}>
                        <div style={{ width: "20px", textAlign: "center" }}>
                            <h5></h5>
                        </div>
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Qtde</h5>
                        </div>
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Valor principal</h5>
                        </div>
                        {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Principal C/Juros</h5>
                        </div>}
                        {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Total C/Juros</h5>
                        </div>}
                        {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Valor total</h5>
                        </div>}
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>01º venc</h5>
                        </div>
                        <div style={{ width: "20vw", textAlign: "center" }}>
                            <h5>Meio de pagamento</h5>
                        </div>
                    </div>}
                    {getFormularioListaDeIntermediarias.map((Item, Index) =>
                        <div style={{ display: "block" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "20px", marginRight: "10px", paddingTop: "5px" }}>
                                    <Icon icon="remove" iconSize={20} onClick={async () => {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias.splice(Index, 1);
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }} />
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(Item.Quantidade)} leftIcon="numerical"
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Quantidade = (parseInt(event.currentTarget.value) ?? 0.00);
                                            console.log(ListaDeIntermediarias[Index].Quantidade)
                                            ListaDeIntermediarias[Index].ValorPMT = ListaDeIntermediarias[Index].Quantidade > 0 ? Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), ListaDeIntermediarias[Index].Quantidade, (ListaDeIntermediarias[Index].Quantidade * ListaDeIntermediarias[Index].Valor), 0)))) : 0;
                                            console.log(ListaDeIntermediarias[Index].ValorPMT)
                                            ListaDeIntermediarias[Index].Valor = (ListaDeIntermediarias[Index].Quantidade > 0 ? ListaDeIntermediarias[Index].Valor : 0);
                                            ListaDeIntermediarias[Index].ValorTotalJuros = ListaDeIntermediarias[Index].Quantidade > 0 ? (ListaDeIntermediarias[Index].Quantidade * ListaDeIntermediarias[Index].ValorPMT) : 0;
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }} />
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.Valor)} leftIcon="dollar"
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Valor = (Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00);
                                            ListaDeIntermediarias[Index].ValorPMT = ListaDeIntermediarias[Index].Quantidade > 0 ? Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), ListaDeIntermediarias[Index].Quantidade, (ListaDeIntermediarias[Index].Quantidade * ListaDeIntermediarias[Index].Valor), 0)))) : 0;
                                            ListaDeIntermediarias[Index].ValorTotalJuros = ListaDeIntermediarias[Index].Quantidade > 0 ? (ListaDeIntermediarias[Index].Quantidade * ListaDeIntermediarias[Index].ValorPMT) : 0;
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);

                                            await setFormularioIntermediariaPercentual((getFormularioListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0) / (getItemPickerIdentificador?.valorAVista ?? 0));
                                            let ParcelaPercentual = ((getItemPickerIdentificador?.valorAVista ?? 0) - ((getFormularioListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                                (getFormularioListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                                (getFormularioListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0))) / (getItemPickerIdentificador?.valorAVista ?? 0);
                                            await setFormularioParcelaPercentual(ParcelaPercentual);
                                            for (let i = 0; i < getFormularioListaDeParcelas.length; i++) {
                                                getFormularioListaDeParcelas[i].Valor = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].Valor * ParcelaPercentual / getFormularioParcelaPercentual));
                                                getFormularioListaDeParcelas[i].ValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorPMT * ParcelaPercentual / getFormularioParcelaPercentual));
                                                getFormularioListaDeParcelas[i].ValorTotal = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotal * ParcelaPercentual / getFormularioParcelaPercentual));
                                                getFormularioListaDeParcelas[i].ValorTotalJuros = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotalJuros * ParcelaPercentual / getFormularioParcelaPercentual));
                                            }
                                        }}>
                                    </InputGroup>
                                </div>
                                {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.ValorPMT)} leftIcon="dollar"
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].ValorPMT = (Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00);

                                            ListaDeIntermediarias[Index].Valor = ListaDeIntermediarias[Index].Quantidade > 0 ? Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pv((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (ListaDeIntermediarias[Index].Quantidade), ListaDeIntermediarias[Index].ValorPMT, 0) / ListaDeIntermediarias[Index].Quantidade))) : 0;
                                            ListaDeIntermediarias[Index].ValorTotal = ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 ? ListaDeIntermediarias[Index].ValorTotal : (ListaDeIntermediarias[Index].Quantidade * ListaDeIntermediarias[Index].Valor)
                                            ListaDeIntermediarias[Index].ValorTotalJuros = (ListaDeIntermediarias[Index].Quantidade * ListaDeIntermediarias[Index].ValorPMT)
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);

                                            await setFormularioIntermediariaPercentual((ListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0) / (getItemPickerIdentificador?.valorAVista ?? 0));
                                            let ParcelaPercentual = ((getItemPickerIdentificador?.valorAVista ?? 0) - ((getFormularioListaDeEntradas.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                                (getFormularioListaDeSinais.reduce((total, Titulo) => (total + Titulo.Valor), 0) ?? 0) +
                                                (getFormularioListaDeIntermediarias.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0))) / (getItemPickerIdentificador?.valorAVista ?? 0);
                                            await setFormularioParcelaPercentual(ParcelaPercentual);
                                            for (let i = 0; i < getFormularioListaDeParcelas.length; i++) {
                                                getFormularioListaDeParcelas[i].Valor = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].Valor * ParcelaPercentual / getFormularioParcelaPercentual));
                                                getFormularioListaDeParcelas[i].ValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorPMT * ParcelaPercentual / getFormularioParcelaPercentual));
                                                getFormularioListaDeParcelas[i].ValorTotal = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotal * ParcelaPercentual / getFormularioParcelaPercentual));
                                                getFormularioListaDeParcelas[i].ValorTotalJuros = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotalJuros * ParcelaPercentual / getFormularioParcelaPercentual));
                                            }
                                        }}>
                                    </InputGroup>
                                </div>}
                                {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(Item.ValorTotalJuros)} leftIcon="dollar" />
                                </div>}
                                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(Item.Valor * Item.Quantidade)} leftIcon="dollar" />
                                </div>}
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <DateInput inputProps={{
                                        onClick: () => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].ExibirCalendarioVencimento = true;
                                            setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }
                                    }}
                                        popoverProps={{
                                            disabled: !Item.ExibirCalendarioVencimento, onClosed: () => {
                                                let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                ListaDeIntermediarias[Index].ExibirCalendarioVencimento = false;
                                                setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                            }
                                        }}
                                        dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} value={Item.Vencimento} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                        onChange={async (DataSelecionada: Date, AlteracaoDeUsuario: boolean) => {
                                            if (AlteracaoDeUsuario == true) {
                                                let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                    ListaDeIntermediarias[Index].Vencimento = (getFormularioDataDaProposta ?? new Date());
                                                }
                                                else {
                                                    if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "CreditoIntermediariado") {
                                                        ListaDeIntermediarias.map((Item_, Index_) => {
                                                            if (Index_ >= Index && Item_.MeioDePagamento == "Cartao" && Item_.Operacao == "CreditoIntermediariado") {
                                                                let Vencimento = new Date(DataSelecionada);
                                                                Vencimento.setDate(Vencimento.getDate() + (Index_ - Index) * 30);
                                                                Item_.Vencimento = Vencimento;
                                                            }
                                                        });
                                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                    }
                                                    else {
                                                        if (DataSelecionada.getDate() != 5 && DataSelecionada.getDate() != 15 && ListaDeIntermediarias[Index].MeioDePagamento == "Boleto") {
                                                            NotificacaoInterna.ExibirNotificacao("Data invalida", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);

                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias[Index].Vencimento = ListaDeIntermediarias[Index].Vencimento;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                            return;
                                                        }
                                                        else {
                                                            if (ListaDeIntermediarias[Index].MeioDePagamento == "Boleto" && ListaDeIntermediarias.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == "Boleto")).length > 0) {
                                                                Toast(
                                                                    <div style={{}}>
                                                                        <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`Existem Intermediarias do tipo boleto com o dia de vencimento diferente da primeira Intermediaria, deseja igualar o dia de vencimento de todos os Intermediarias do tipo boleto?`}</p>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                            <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => {
                                                                                    let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                                                    ListaDeIntermediarias[Index].Vencimento = DataSelecionada;
                                                                                    await setFormularioListaDeIntermediarias(ListaDeIntermediarias);

                                                                                    ListaDeIntermediarias.map(async (Item_, Index_) => {
                                                                                        ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                                                        let Vencimento = new Date(Item_.Vencimento);
                                                                                        Vencimento.setDate(ListaDeIntermediarias[Index].Vencimento.getDate());
                                                                                        Vencimento.setFullYear(ListaDeIntermediarias[Index].Vencimento.getFullYear());
                                                                                        Vencimento.setMonth(ListaDeIntermediarias[Index].Vencimento.getMonth() + Index_ - Index);

                                                                                        if (Index_ > Index && Item_.MeioDePagamento == "Boleto") {
                                                                                            Item_.Vencimento = Vencimento;
                                                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                                                        }
                                                                                        if (Index_ == ListaDeIntermediarias.length - 1) {
                                                                                            Vencimento = new Date(Vencimento);
                                                                                            Vencimento.setMonth(Vencimento.getMonth() + 1);
                                                                                            await setFormularioIntermediariaVencimento(Vencimento);
                                                                                        }
                                                                                    });
                                                                                }}>Sim</Button>
                                                                            <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => { }}>Não</Button>
                                                                        </div>
                                                                    </div>, {
                                                                    position: Toast.POSITION.TOP_CENTER,
                                                                    autoClose: false,
                                                                    style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                });
                                                            }
                                                            else {
                                                                let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                                ListaDeIntermediarias[Index].Vencimento = DataSelecionada;
                                                                await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }}>
                                    </DateInput>
                                </div>
                                <div style={{ width: "20vw" }}>
                                    <Component.ContentFormHeader style={{ margin: "0" }}>
                                        <FormGroup labelFor="MeioDePagamento" inline={true}>
                                            <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={Item.MeioDePagamento}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                    ListaDeIntermediarias[Index].MeioDePagamento = event.currentTarget.value;
                                                    ListaDeIntermediarias[Index].Banco = undefined;
                                                    ListaDeIntermediarias[Index].Agencia = undefined;
                                                    ListaDeIntermediarias[Index].Conta = undefined;
                                                    ListaDeIntermediarias[Index].DigitoDaConta = undefined;
                                                    ListaDeIntermediarias[Index].Titular = undefined;
                                                    ListaDeIntermediarias[Index].NumeroCheque = undefined;
                                                    ListaDeIntermediarias[Index].Maquina = undefined;
                                                    ListaDeIntermediarias[Index].Bandeira = undefined;
                                                    ListaDeIntermediarias[Index].DigitoCartao = undefined;
                                                    ListaDeIntermediarias[Index].Operacao = undefined;
                                                    ListaDeIntermediarias[Index].NSU = undefined;
                                                    ListaDeIntermediarias[Index].NumeroDaOperacao = undefined;
                                                    await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                }}>
                                                <option selected value="">Selecione o meio de pagamento</option>
                                                {MeioDePagamento.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                        </FormGroup>
                                    </Component.ContentFormHeader>
                                </div>
                                <div style={{ width: "100px" }}>
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cheque" && <Component.DescriptionContent title="Clique aqui para editar estas informações" style={{ width: "10vw" }} onClick={async () => {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = !(ListaDeIntermediarias[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }}>
                                        <strong>Sobre o cheque:</strong> Banco: {Item.Banco?.chave ?? "--"}, Agência: {Item.Agencia ?? "--"}, Conta: {Item.Conta ?? "--"}{Item.DigitoDaConta == undefined ? "" : ("-" + Item.DigitoDaConta)}, Titular: {Item.Titular ?? "--"}, Nº do cheque: {Item.NumeroCheque ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cartao" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = !(ListaDeIntermediarias[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }}>
                                        <strong>Sobre o cartão:</strong> Máquina: {Item.Maquina ?? "--"}, Digito: {Item.DigitoCartao ?? "--"}, Bandeira: {Item.Bandeira ?? "--"}, Operação: {Item.Operacao ?? "--"}, NSU: {Item.NSU ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = !(ListaDeIntermediarias[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }}>
                                        <strong>Sobre o {Item.MeioDePagamento}:</strong> Nº da operação: {Item.NumeroDaOperacao ?? "--"}
                                    </Component.DescriptionContent>}
                                </div>
                            </div>
                            {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cheque" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Banco:" className="banco" labelFor="Banco" inline={true}>
                                    <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Banco = _Item;
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Banco ? ((Item.Banco).chave + ": " + (Item.Banco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                    </PickerBanco>
                                </FormGroup>
                                <FormGroup label="Agência:" className="agência" labelFor="Agencia" inline={true} >
                                    <InputGroup id="Agencia" placeholder={"00000"} value={Item.Agencia}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Agencia = event.currentTarget.value ?? "";
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Component.FormContent>
                                    <FormGroup label="Conta:" className="conta" labelFor="Conta" inline={true} >
                                        <InputGroup id="Conta" placeholder={"00000"} value={Item.Conta}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                ListaDeIntermediarias[Index].Conta = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="- " labelFor="DigitoDaConta" inline={true} style={{ marginLeft: "20px" }} >
                                        <InputGroup id="DigitoDaConta" placeholder={"00"} value={Item.DigitoDaConta}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                ListaDeIntermediarias[Index].DigitoDaConta = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                </Component.FormContent>
                                <FormGroup label="Titular:" className="titular" labelFor="Titular" inline={true} >
                                    <InputGroup id="Titular" placeholder={"Informe o nominal"} value={Item.Titular}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Titular = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Nº do cheque:" labelFor="NumeroCheque" inline={true} >
                                    <InputGroup id="NumeroCheque" placeholder={"Informe o número"} value={Item.NumeroCheque}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].NumeroCheque = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                    let IntermediariasVazios = []
                                    IntermediariasVazios = ListaDeIntermediarias.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == ""))
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeIntermediarias.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Banco ?? "") == "" && (Item__.Agencia ?? "") == "" && (Item__.Conta ?? "") == "" && (Item__.DigitoDaConta ?? "") == "" && (Item__.Titular ?? "") == "" && (Item__.NumeroCheque ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (IntermediariasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let IntermediariasVazios = [...getFormularioListaDeIntermediarias];
                                                            IntermediariasVazios.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == "") {
                                                                    Item_.Banco = Item.Banco
                                                                    Item_.Agencia = Item.Agencia
                                                                    Item_.Conta = Item.Conta
                                                                    Item_.DigitoDaConta = Item.DigitoDaConta
                                                                    Item_.Titular = Item.Titular
                                                                    Item_.NumeroCheque = Item.NumeroCheque
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                                }
                                                            })
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        })
                                    }
                                    else {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                            {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cartao" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Máquina:" className="máquina" labelFor="Maquina" inline={true} >
                                    <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Maquina = _Item.Valor;
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Maquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                    </PickerMaquina>
                                </FormGroup>
                                <FormGroup label="Dígito do cartão:" labelFor="DigitoCartao" inline={true} >
                                    <InputGroup id="DigitoCartao" placeholder={"Informe os últimos 04 dígitos do cartão"} value={Item.DigitoCartao}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].DigitoCartao = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Bandeira:" className="bandeira" labelFor="Bandeira" inline={true} >
                                    <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Bandeira = _Item.Valor;
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Bandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                    </PickerMaquina>
                                </FormGroup>
                                <FormGroup label="Terminal:" className="terminal" labelFor="Terminal" inline={true} >
                                    <PickerTerminal popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={GetTerminais} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { id: number, descricao: string }) => <MenuItem onClick={async () => {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].Terminal = _Item.id;
                                            setFormularioListaDeEntradas(ListaDeEntradas);
                                        }} text={_Item.descricao} />} itemPredicate={(Texto: string, _Item: { id: number, descricao: string }) => { return ((_Item.descricao) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={GetTerminais.find((_Item) => _Item.id == Item.Terminal)?.descricao ?? "Selecione o terminal"} rightIcon="double-caret-vertical" />
                                    </PickerTerminal>
                                </FormGroup>
                                <FormGroup label="Cód. autorização:" labelFor="CodAutorizacao" inline={true} >
                                    <InputGroup id="CodAutorizacao" placeholder={"Informe o código de autorização"} value={Item?.CodAutorizacao}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].CodAutorizacao = (event.currentTarget.value ?? "");
                                            setFormularioListaDeEntradas(ListaDeEntradas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Operação:" className="operação" labelFor="Operacao" inline={true} >
                                    <HTMLSelect id="Operacao" name="Operacao" value={Item.Operacao == undefined ? "" : Item.Operacao}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Operacao = (event.currentTarget.value ?? "");
                                            if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "Debito") {
                                                const EhDiaUtil = require('eh-dia-util');
                                                let Vencimento = new Date();
                                                Vencimento.setDate(Vencimento.getDate() + 1);
                                                while (EhDiaUtil(Vencimento) == false) {
                                                    Vencimento.setDate(Vencimento.getDate() + 1);
                                                }
                                                ListaDeIntermediarias[Index].Vencimento = Vencimento;
                                            }
                                            if (Item.MeioDePagamento == "Cartao" && Item.Operacao != "Debito") {
                                                ListaDeIntermediarias.map((Item_, Index_) => {
                                                    if (Index_ >= Index) {
                                                        let Vencimento = new Date(getFormularioDataDaProposta);
                                                        Vencimento.setDate(Vencimento.getDate() + (1 + Index_ - Index) * 30);
                                                        Item_.Vencimento = Vencimento;
                                                    }
                                                });
                                            }
                                            else {
                                                ListaDeIntermediarias[Index].Vencimento = ListaDeIntermediarias[Index].Vencimento;
                                            }
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                        <option selected value="">Selecione a operação</option>
                                        {Operacao.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                </FormGroup>
                                <FormGroup label="NSU:" className="nsu" labelFor="NSU" inline={true} >
                                    <InputGroup id="NSU" placeholder={"Informe o NSU"} value={Item.NSU}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].NSU = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                    let IntermediariasVazios = [];
                                    IntermediariasVazios = ListaDeIntermediarias.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == ""));
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeIntermediarias.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Maquina ?? "") == "" && (Item__.DigitoCartao ?? "") == "" && (Item__.Bandeira ?? "") == "" && (Item__.Operacao ?? "") == "" && (Item__.NSU ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (IntermediariasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == "") {
                                                                    Item_.Maquina = Item.Maquina
                                                                    Item_.DigitoCartao = Item.DigitoCartao
                                                                    Item_.Bandeira = Item.Bandeira
                                                                    Item_.Operacao = Item.Operacao
                                                                    Item_.NSU = Item.NSU
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                                }
                                                            })
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        })
                                    }
                                    else {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                            {Item.DetalharMeioDePagamento == true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Nº da operação:" labelFor="NumeroDaOperacao" inline={true} >
                                    <InputGroup id="NumeroDaOperacao" placeholder={"00000"} value={Item.NSU}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].NumeroDaOperacao = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                    let IntermediariasVazios = [];
                                    IntermediariasVazios = ListaDeIntermediarias.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == ""));
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeIntermediarias.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.NumeroDaOperacao ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (IntermediariasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == "") {
                                                                    Item_.NumeroDaOperacao = Item.NumeroDaOperacao
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                                }
                                                            })
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        });
                                    }
                                    else {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                        </div>
                    )}
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {false && <FormGroup label="Total gerado:" labelFor="IntermediariaValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                            <InputGroup id="IntermediariaValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.Valor * Intermediaria.Quantidade), 0))} leftIcon="numerical" />
                        </FormGroup>}
                        <FormGroup label="Total gerado:" labelFor="IntermediariaValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                            <InputGroup id="IntermediariaValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.ValorPMT * Math.round(Intermediaria.ValorTotalJuros / Intermediaria.ValorPMT)), 0))} leftIcon="numerical" />
                        </FormGroup>
                        <FormGroup style={{ marginRight: "10px" }} label="Total esperado:" labelFor="IntermediariaValorTotalEsperado" inline={true}>
                            <InputGroup id="IntermediariaValorTotalEsperado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0) *
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0)
                                )} leftIcon="numerical" />
                        </FormGroup>
                        {false && <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.Valor * Intermediaria.Quantidade), 0)) ? "A distribuir: " : "Excedido: "} labelFor="EntradaValorTotalADistribuir" inline={true}>
                            <InputGroup id="IntermediariaValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) - getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.Valor * Intermediaria.Quantidade), 0))} leftIcon="numerical" />
                        </FormGroup>}
                        <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.Valor * Intermediaria.Quantidade), 0)) ? "A distribuir: " : "Excedido: "} labelFor="EntradaValorTotalADistribuir" inline={true}>
                            <InputGroup id="IntermediariaValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(Moeda.DesformatarTexto(Moeda.FormatarTexto(pv((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0), - Moeda.DesformatarTexto(Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) / (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0)))))) - getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.Valor * Intermediaria.Quantidade), 0))} leftIcon="numerical" />
                        </FormGroup>
                    </div>
                </Component.ContentCard>}
                {getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao) && <Component.ContentCard>
                    <Component.Title>
                        <Icon icon={getHabilitarInclusaoDeParcelas == true ? "remove" : "add"} style={{ cursor: "pointer" }} iconSize={20} onClick={async () => { await setHabilitarInclusaoDeParcelas(!getHabilitarInclusaoDeParcelas); }} />
                        <h3>Sobre o saldo</h3>
                        {(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) > 0 && <h6 style={{ marginTop: "30px", marginLeft: "10px" }}>(taxa de juros de {Percentual.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) * 100)}  a.m.)</h6>}
                    </Component.Title>
                    <FormGroup label="% sobre o valor a vista:" labelFor="ParcelaPercentual" inline={true} >
                        <InputGroup id="ParcelaPercentual" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaDecimal(getFormularioParcelaPercentual * 100)} leftIcon="percentage"
                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                var ParcelaPercentual = Numero.DesformatarTextoParaDecimal(event.currentTarget.value) / 100;
                                for (let i = 0; i < getFormularioListaDeParcelas.length; i++) {
                                    getFormularioListaDeParcelas[i].Valor = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].Valor * ParcelaPercentual / getFormularioParcelaPercentual));
                                    getFormularioListaDeParcelas[i].ValorPMT = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorPMT * ParcelaPercentual / getFormularioParcelaPercentual));
                                    getFormularioListaDeParcelas[i].ValorTotal = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotal * ParcelaPercentual / getFormularioParcelaPercentual));
                                    getFormularioListaDeParcelas[i].ValorTotalJuros = Moeda.DesformatarTexto(Moeda.FormatarTexto(getFormularioListaDeParcelas[i].ValorTotalJuros * ParcelaPercentual / getFormularioParcelaPercentual));
                                }
                                await setFormularioParcelaPercentual(ParcelaPercentual);
                            }}>
                        </InputGroup>
                    </FormGroup>
                    <Collapse isOpened={getHabilitarInclusaoDeParcelas} theme={{ collapse: 'foo', content: 'bar' }}>
                        <div style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", padding: "10px" }}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <FormGroup label="Quantidade de parcelas:" labelFor="ParcelaQtde" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <InputGroup id="ParcelaQtde" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(getFormularioParcelaQtde)} leftIcon="numerical"
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaQtde(Numero.DesformatarTextoParaInteiro(event.currentTarget.value) ?? 0); }} style={{ width: "235px" }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Valor principal:" labelFor="ParcelaValor" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="ParcelaValor" style={{ display: "flex" }}>
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(getFormularioParcelaValor)} leftIcon="dollar"
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let Valor = Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00;
                                                    await setFormularioParcelaValor(Valor)
                                                    await setFormularioParcelaValorComJuros(Moeda.DesformatarTexto(Moeda.FormatarTexto(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), getFormularioParcelaQtde, getFormularioParcelaQtde * Valor, 0))));
                                                }}
                                                style={{ width: "235px" }}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>
                                    {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <FormGroup label="Valor principal C/Juros:" labelFor="ParcelaValorPrincipalJuros" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="ParcelaValorPrincipalJuros">
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(getFormularioParcelaValorComJuros)}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let Valor = Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00;
                                                    await setFormularioParcelaValor(Moeda.DesformatarTexto(Moeda.FormatarTexto(pv((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), getFormularioParcelaQtde, Valor, 0) / getFormularioParcelaQtde)));
                                                    await setFormularioParcelaValorComJuros(Valor);
                                                }} leftIcon="dollar" style={{ width: "215px" }} />
                                        </div>
                                    </FormGroup>}
                                    {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <FormGroup label="Valor total:" labelFor="ParcelaValorTotal" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="ParcelaValorTotal" >
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={
                                                Moeda.FormatarTexto(getFormularioParcelaValor * getFormularioParcelaQtde)} leftIcon="dollar"
                                                style={{ width: "235px" }} />
                                        </div>
                                    </FormGroup>}
                                    {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <FormGroup label="Valor total C/Juros:" labelFor="ParcelaValorTotalJuros" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="ParcelaValorTotalJuros">
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto((getFormularioParcelaQtde > 0 ? Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (getFormularioParcelaQtde), (getFormularioParcelaQtde * getFormularioParcelaValor), 0)))) : 0) * getFormularioParcelaQtde)} leftIcon="dollar" style={{ width: "235px" }} />
                                        </div>
                                    </FormGroup>}
                                    <FormGroup label="Primeiro vencimento:" labelFor="ParcelaVencimento" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <DateInput inputProps={{ style: { marginLeft: "18px", width: "235px" }, onClick: () => setFormularioParcelaExibirCalendarioVencimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{ disabled: !getFormularioParcelaExibirCalendarioVencimento, onClosed: () => setFormularioParcelaExibirCalendarioVencimento(false) }}
                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} value={getFormularioParcelaVencimento} maxDate={new Date((new Date().getFullYear() + 40).toString() + "-01-01")}
                                            onChange={async (DataSelecionada: Date) => {
                                                if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                    await setFormularioParcelaVencimento(getFormularioDataDaProposta ?? new Date());
                                                }
                                                else {
                                                    if (DataSelecionada.getDate() != 5 && DataSelecionada.getDate() != 15 && getFormularioParcelaMeioDePagamento == "Boleto") {
                                                        NotificacaoInterna.ExibirNotificacao("Data invalida", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        await setFormularioParcelaVencimento(getFormularioParcelaVencimento);
                                                        return;
                                                    }
                                                    await setFormularioParcelaVencimento(new Date(DataSelecionada));
                                                }
                                            }}>
                                        </DateInput>
                                    </FormGroup>
                                </div>
                                <div style={{ marginLeft: "20px" }}>
                                    <FormGroup label="Meio de pagamento:" labelFor="MeioDePagamento" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={getFormularioParcelaMeioDePagamento}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => { await setFormularioParcelaMeioDePagamento(event.currentTarget.value); }}>
                                            <option selected value="">Selecione o meio de pagamento</option>
                                            {MeioDePagamento.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                    </FormGroup>
                                    {getFormularioParcelaMeioDePagamento == "Cheque" && <div>
                                        <FormGroup label="Banco:" style={{ color: "black", fontSize: "14px" }} labelFor="Banco" inline={true}>
                                            <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioParcelaBanco(_Item); }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "84px", width: "12vw" }} text={getFormularioParcelaBanco ? ((getFormularioParcelaBanco).chave + ": " + (getFormularioParcelaBanco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                            </PickerBanco>
                                        </FormGroup>
                                        <FormGroup label="Agência:" style={{ color: "black", fontSize: "14px" }} labelFor="Agencia" inline={true} >
                                            <InputGroup id="Agencia" style={{ marginLeft: "73px", width: "12vw" }} placeholder={"00000"} value={getFormularioParcelaAgencia}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaAgencia(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <Component.FormContent>
                                            <FormGroup label="Conta:" style={{ color: "black", fontSize: "14px" }} labelFor="Conta" inline={true} >
                                                <InputGroup id="Conta" style={{ marginLeft: "86px", width: "12vw" }} placeholder={"00000"} value={getFormularioParcelaConta}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaConta(event.currentTarget.value ?? ""); }}>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup label="- " style={{ color: "black", fontSize: "14px", marginLeft: "10px" }} labelFor="DigitoDaConta" inline={true}>
                                                <InputGroup id="DigitoDaConta" style={{ width: "3vw" }} placeholder={"00"} value={getFormularioParcelaDigitoDaConta}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaDigitoDaConta(event.currentTarget.value ?? ""); }}>
                                                </InputGroup>
                                            </FormGroup>
                                        </Component.FormContent>
                                        <FormGroup label="Titular:" style={{ color: "black", fontSize: "14px" }} labelFor="Titular" inline={true} >
                                            <InputGroup style={{ marginLeft: "82px", width: "12vw" }} placeholder={"Informe o nominal"} value={getFormularioParcelaTitular}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaTitular(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="Nº do cheque:" style={{ color: "black", fontSize: "14px" }} labelFor="NumeroCheque" inline={true} >
                                            <InputGroup style={{ marginLeft: "38px", width: "12vw" }} placeholder={"Informe o número"} value={getFormularioParcelaNumeroCheque}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaNumeroCheque(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                    {getFormularioParcelaMeioDePagamento == "Cartao" && <div>
                                        <FormGroup label="Máquina:" style={{ color: "black", fontSize: "14px" }} labelFor="Maquina" inline={true} >
                                            <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioParcelaMaquina(_Item.Valor); }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "70px", width: "12vw" }} text={getFormularioParcelaMaquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                            </PickerMaquina>
                                        </FormGroup>
                                        <FormGroup label="Dígito do cartão:" style={{ color: "black", fontSize: "14px" }} labelFor="DigitoCartao" inline={true} >
                                            <InputGroup style={{ marginLeft: "25px", width: "12vw" }} placeholder={"Informe os últimos 04 dígitos do cartão"} value={getFormularioParcelaDigitoCartao}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaDigitoCartao(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="Bandeira:" style={{ color: "black", fontSize: "14px" }} labelFor="Bandeira" inline={true} >
                                            <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioParcelaBandeira(_Item.Valor); }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "69px", width: "12vw" }} text={getFormularioParcelaBandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                            </PickerMaquina>
                                        </FormGroup>
                                        <FormGroup label="Operação:" style={{ color: "black", fontSize: "14px" }} labelFor="Operacao" inline={true} >
                                            <HTMLSelect name="Operacao" style={{ marginLeft: "64px", width: "12vw" }} value={getFormularioParcelaOperacao == undefined ? "" : getFormularioParcelaOperacao}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => { await setFormularioParcelaOperacao(event.currentTarget.value ?? ""); }}>
                                                <option selected value="">Selecione a operação</option>
                                                {Operacao.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                        </FormGroup>
                                        <FormGroup label="NSU:" style={{ color: "black", fontSize: "14px" }} labelFor="NSU" inline={true} >
                                            <InputGroup style={{ marginLeft: "95px", width: "12vw" }} placeholder={"Informe o NSU"} value={getFormularioParcelaNSU}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaNSU(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                    {(getFormularioParcelaMeioDePagamento == "Deposito" || getFormularioParcelaMeioDePagamento == "Transferencia" || getFormularioParcelaMeioDePagamento == "PIX") && <div>
                                        <FormGroup label="Nº da operação:" style={{ color: "black", fontSize: "14px" }} labelFor="NumeroDaOperacao" inline={true} >
                                            <InputGroup style={{ marginLeft: "27px", width: "12vw" }} placeholder={"00000"} value={getFormularioParcelaNSU}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaNSU(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: "right" }}>
                                <Button type="submit" intent="primary" style={{ marginRight: "50px", width: '100px' }}
                                    onClick={async () => {
                                        if (!getItemPickerCondicaoDaTabelaDeVenda) {
                                            NotificacaoInterna.ExibirNotificacao("Nenhum modelo de venda selecionado", "Selecione o modelo de vendas", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (getFormularioParcelaQtde <= 0) {
                                            NotificacaoInterna.ExibirNotificacao("Quantidade de parcelas é invalida", "Informe uma quantidade de parcelas maior ou igual a 01", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (getItemPickerCondicaoDaTabelaDeVenda.descricao.toUpperCase().includes("VISTA") == false && getFormularioParcelaValor.toFixed(2) != getFormularioParcelaValor.toFixed(2)) {
                                            NotificacaoInterna.ExibirNotificacao("Valor gerado de sinal diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(getFormularioParcelaValor) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(getFormularioParcelaValor), NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (!getFormularioParcelaVencimento || getFormularioParcelaVencimento == null || getFormularioParcelaVencimento < getFormularioDataDaProposta) {
                                            NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (getFormularioParcelaVencimento.getDate() != 5 && getFormularioParcelaVencimento.getDate() != 15 && getFormularioParcelaMeioDePagamento == "Boleto") {
                                            NotificacaoInterna.ExibirNotificacao("Vencimento invalido", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                            await setFormularioParcelaVencimento(getFormularioParcelaVencimento);
                                            return;
                                        }
                                        if (getFormularioParcelaMeioDePagamento == "") {
                                            NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existem meios de pagamento referente ao sinal que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas.push({
                                            Quantidade: getFormularioParcelaQtde,
                                            Valor: getFormularioParcelaValor,
                                            ValorPMT: getFormularioParcelaQtde > 0 ? Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (getFormularioParcelaQtde), (getFormularioParcelaQtde * getFormularioParcelaValor), 0)))) : 0,
                                            ValorTotal: (getFormularioParcelaValor * getFormularioParcelaQtde),
                                            ValorTotalJuros: getFormularioParcelaQtde > 0 ? (Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (getFormularioParcelaQtde), (getFormularioParcelaQtde * getFormularioParcelaValor), 0)))) * getFormularioParcelaQtde) : 0,
                                            Vencimento: getFormularioParcelaVencimento,
                                            Banco: getFormularioParcelaBanco,
                                            Agencia: getFormularioParcelaAgencia,
                                            Conta: getFormularioParcelaConta,
                                            DigitoDaConta: getFormularioParcelaDigitoDaConta,
                                            Titular: getFormularioParcelaTitular,
                                            NumeroCheque: getFormularioParcelaNumeroCheque,
                                            Maquina: getFormularioParcelaMaquina,
                                            Bandeira: getFormularioParcelaBandeira,
                                            DigitoCartao: getFormularioParcelaDigitoCartao,
                                            Operacao: getFormularioParcelaOperacao,
                                            NSU: getFormularioParcelaNSU,
                                            NumeroDaOperacao: getFormularioParcelaNumeroDaOperacao,
                                            MeioDePagamento: getFormularioParcelaMeioDePagamento,
                                            DetalharMeioDePagamento: false,
                                        } as { Quantidade: number, Valor: number, ValorPMT: number, ValorTotal: number, ValorTotalJuros: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined })
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                        setHabilitarInclusaoDeParcelas(false);

                                        let ParcelaPercentual = (ListaDeParcelas.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0) / (getItemPickerIdentificador?.valorAVista ?? 0);
                                        await setFormularioParcelaPercentual(ParcelaPercentual);
                                    }}>Salvar</Button>
                                <Button type="submit" intent="danger" style={{ width: '100px' }}
                                    onClick={async () => {
                                        await setFormularioParcelaQtde(0);
                                        await setFormularioParcelaValor(0);
                                        await setFormularioParcelaVencimento(undefined);
                                        await setFormularioParcelaBanco(undefined);
                                        await setFormularioParcelaAgencia(undefined);
                                        await setFormularioParcelaConta(undefined);
                                        await setFormularioParcelaDigitoDaConta(undefined);
                                        await setFormularioParcelaTitular(undefined);
                                        await setFormularioParcelaNumeroCheque(undefined);
                                        await setFormularioParcelaMaquina(undefined);
                                        await setFormularioParcelaBandeira(undefined);
                                        await setFormularioParcelaDigitoCartao(undefined);
                                        await setFormularioParcelaOperacao(undefined);
                                        await setFormularioParcelaNSU(undefined);
                                        await setFormularioParcelaNumeroDaOperacao(undefined);
                                    }}>Limpar</Button>
                            </div>
                        </div>
                    </Collapse>
                    {getFormularioListaDeParcelas.length > 0 && <div style={{ display: "flex" }}>
                        <div style={{ width: "20px", textAlign: "center" }}>
                            <h5></h5>
                        </div>
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Qtde</h5>
                        </div>
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Valor principal</h5>
                        </div>
                        {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Principal C/Juros</h5>
                        </div>}
                        {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Total C/Juros</h5>
                        </div>}
                        {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Valor total</h5>
                        </div>}
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>01º venc</h5>
                        </div>
                        <div style={{ width: "20vw", textAlign: "center" }}>
                            <h5>Meio de pagamento</h5>
                        </div>
                    </div>}
                    {getFormularioListaDeParcelas.map((Item, Index) =>
                        <div style={{ display: "block" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "20px", marginRight: "10px", paddingTop: "5px" }}>
                                    <Icon icon="remove" iconSize={20} onClick={async () => {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas.splice(Index, 1);
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }} />
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(Item.Quantidade)} leftIcon="numerical"
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Quantidade = (Numero.DesformatarTextoParaInteiro(event.currentTarget.value) ?? 0);

                                            var ParcelaValor = ListaDeParcelas[Index].Quantidade > 0 ? (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 ? ((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.qtdeDeTitulos ?? 0) * (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao.includes(getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.principal ?? 0) / ListaDeParcelas[Index].Quantidade) : Moeda.DesformatarTexto(Moeda.FormatarTexto((ListaDeParcelas[Index].ValorTotal / ListaDeParcelas[Index].Quantidade)))) : 0;
                                            ListaDeParcelas[Index].Valor = ParcelaValor
                                            ListaDeParcelas[Index].ValorPMT = ListaDeParcelas[Index].Quantidade > 0 ? Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (ListaDeParcelas[Index].Quantidade), (ListaDeParcelas[Index].ValorTotal), 0)))) : 0;
                                            ListaDeParcelas[Index].ValorTotalJuros = (ListaDeParcelas[Index].Quantidade * ListaDeParcelas[Index].ValorPMT)
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.Valor)} leftIcon="dollar"
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Valor = (Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00);

                                            ListaDeParcelas[Index].ValorTotal = ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 ? ListaDeParcelas[Index].ValorTotal : (ListaDeParcelas[Index].Quantidade * ListaDeParcelas[Index].Valor)
                                            ListaDeParcelas[Index].ValorPMT = ListaDeParcelas[Index].Quantidade > 0 ? Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pmt((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (ListaDeParcelas[Index].Quantidade), ListaDeParcelas[Index].ValorTotal, 0)))) : 0;
                                            ListaDeParcelas[Index].ValorTotalJuros = (ListaDeParcelas[Index].Quantidade * ListaDeParcelas[Index].ValorPMT)
                                            await setFormularioListaDeParcelas(ListaDeParcelas);

                                            let ParcelaPercentual = (ListaDeParcelas.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0) / (getItemPickerIdentificador?.valorAVista ?? 0);
                                            await setFormularioParcelaPercentual(ParcelaPercentual);
                                        }}>
                                    </InputGroup>
                                </div>
                                {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.ValorPMT)} leftIcon="dollar"
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].ValorPMT = (Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00);

                                            ListaDeParcelas[Index].Valor = ListaDeParcelas[Index].Quantidade > 0 ? Moeda.DesformatarTexto(Moeda.FormatarTexto(Math.abs(pv((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (ListaDeParcelas[Index].Quantidade), ListaDeParcelas[Index].ValorPMT, 0) / ListaDeParcelas[Index].Quantidade))) : 0;
                                            ListaDeParcelas[Index].ValorTotal = ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 ? ListaDeParcelas[Index].ValorTotal : (ListaDeParcelas[Index].Quantidade * ListaDeParcelas[Index].Valor)
                                            ListaDeParcelas[Index].ValorTotalJuros = (ListaDeParcelas[Index].Quantidade * ListaDeParcelas[Index].ValorPMT)
                                            await setFormularioListaDeParcelas(ListaDeParcelas);

                                            let ParcelaPercentual = (ListaDeParcelas.reduce((total, Titulo) => (total + Titulo.Valor * Titulo.Quantidade), 0) ?? 0) / (getItemPickerIdentificador?.valorAVista ?? 0);
                                            await setFormularioParcelaPercentual(ParcelaPercentual);
                                        }}>
                                    </InputGroup>
                                </div>}
                                {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(Item.ValorTotalJuros)} leftIcon="dollar" />
                                </div>}
                                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(Item.Valor * Item.Quantidade)} leftIcon="dollar" />
                                </div>}
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <DateInput inputProps={{
                                        onClick: () => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].ExibirCalendarioVencimento = true;
                                            setFormularioListaDeParcelas(ListaDeParcelas);
                                        }
                                    }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{
                                            disabled: !Item.ExibirCalendarioVencimento, onClosed: () => {
                                                let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                ListaDeParcelas[Index].ExibirCalendarioVencimento = false;
                                                setFormularioListaDeParcelas(ListaDeParcelas);
                                            }
                                        }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} value={Item.Vencimento} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                        onChange={async (DataSelecionada: Date, AlteracaoDeUsuario: boolean) => {
                                            if (AlteracaoDeUsuario == true) {
                                                let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                    ListaDeParcelas[Index].Vencimento = (getFormularioDataDaProposta ?? new Date());
                                                }
                                                else {
                                                    if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "CreditoParcelado") {
                                                        ListaDeParcelas.map((Item_, Index_) => {
                                                            if (Index_ >= Index && Item_.MeioDePagamento == "Cartao" && Item_.Operacao == "CreditoParcelado") {
                                                                let Vencimento = new Date(DataSelecionada);
                                                                Vencimento.setDate(Vencimento.getDate() + (Index_ - Index) * 30);
                                                                Item_.Vencimento = Vencimento;
                                                            }
                                                        });
                                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                                    }
                                                    else {
                                                        if (DataSelecionada.getDate() != 5 && DataSelecionada.getDate() != 15 && ListaDeParcelas[Index].MeioDePagamento == "Boleto") {
                                                            NotificacaoInterna.ExibirNotificacao("Data invalida", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);

                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas[Index].Vencimento = ListaDeParcelas[Index].Vencimento;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                            return;
                                                        }
                                                        else {
                                                            if (ListaDeParcelas[Index].MeioDePagamento == "Boleto" && ListaDeParcelas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == "Boleto")).length > 0) {
                                                                Toast(
                                                                    <div style={{}}>
                                                                        <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`Existem Parcelas do tipo boleto com o dia de vencimento diferente da primeira parcela, deseja igualar o dia de vencimento de todos os Parcelas do tipo boleto?`}</p>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                            <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => {
                                                                                    let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                                                    ListaDeParcelas[Index].Vencimento = DataSelecionada;
                                                                                    await setFormularioListaDeParcelas(ListaDeParcelas);

                                                                                    ListaDeParcelas.map(async (Item_, Index_) => {
                                                                                        ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                                                        let Vencimento = new Date(Item_.Vencimento);
                                                                                        Vencimento.setDate(ListaDeParcelas[Index].Vencimento.getDate());
                                                                                        Vencimento.setFullYear(ListaDeParcelas[Index].Vencimento.getFullYear());
                                                                                        Vencimento.setMonth(ListaDeParcelas[Index].Vencimento.getMonth() + Index_ - Index);

                                                                                        if (Index_ > Index && Item_.MeioDePagamento == "Boleto") {
                                                                                            Item_.Vencimento = Vencimento;
                                                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                                                        }
                                                                                        if (Index_ == ListaDeParcelas.length - 1) {
                                                                                            Vencimento = new Date(Vencimento);
                                                                                            Vencimento.setMonth(Vencimento.getMonth() + 1);
                                                                                            await setFormularioParcelaVencimento(Vencimento);
                                                                                        }
                                                                                    });
                                                                                }}>Sim</Button>
                                                                            <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => { }}>Não</Button>
                                                                        </div>
                                                                    </div>, {
                                                                    position: Toast.POSITION.TOP_CENTER,
                                                                    autoClose: false,
                                                                    style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                });
                                                            }
                                                            else {
                                                                let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                                ListaDeParcelas[Index].Vencimento = DataSelecionada;
                                                                await setFormularioListaDeParcelas(ListaDeParcelas);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }}>
                                    </DateInput>
                                </div>
                                <div style={{ width: "20vw" }}>
                                    <Component.ContentFormHeader style={{ margin: "0" }}>
                                        <FormGroup labelFor="MeioDePagamento" inline={true}>
                                            <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={Item.MeioDePagamento}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                    ListaDeParcelas[Index].MeioDePagamento = event.currentTarget.value;
                                                    ListaDeParcelas[Index].Banco = undefined;
                                                    ListaDeParcelas[Index].Agencia = undefined;
                                                    ListaDeParcelas[Index].Conta = undefined;
                                                    ListaDeParcelas[Index].DigitoDaConta = undefined;
                                                    ListaDeParcelas[Index].Titular = undefined;
                                                    ListaDeParcelas[Index].NumeroCheque = undefined;
                                                    ListaDeParcelas[Index].Maquina = undefined;
                                                    ListaDeParcelas[Index].Bandeira = undefined;
                                                    ListaDeParcelas[Index].DigitoCartao = undefined;
                                                    ListaDeParcelas[Index].Operacao = undefined;
                                                    ListaDeParcelas[Index].NSU = undefined;
                                                    ListaDeParcelas[Index].NumeroDaOperacao = undefined;
                                                    await setFormularioListaDeParcelas(ListaDeParcelas);
                                                }}>
                                                <option selected value="">Selecione o meio de pagamento</option>
                                                {MeioDePagamento.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                        </FormGroup>
                                    </Component.ContentFormHeader>
                                </div>
                                <div style={{ width: "100px" }}>
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cheque" && <Component.DescriptionContent title="Clique aqui para editar estas informações" style={{ width: "10vw" }} onClick={async () => {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = !(ListaDeParcelas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }}>
                                        <strong>Sobre o cheque:</strong> Banco: {Item.Banco?.chave ?? "--"}, Agência: {Item.Agencia ?? "--"}, Conta: {Item.Conta ?? "--"}{Item.DigitoDaConta == undefined ? "" : ("-" + Item.DigitoDaConta)}, Titular: {Item.Titular ?? "--"}, Nº do cheque: {Item.NumeroCheque ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cartao" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = !(ListaDeParcelas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }}>
                                        <strong>Sobre o cartão:</strong> Máquina: {Item.Maquina ?? "--"}, Digito: {Item.DigitoCartao ?? "--"}, Bandeira: {Item.Bandeira ?? "--"}, Operação: {Item.Operacao ?? "--"}, NSU: {Item.NSU ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = !(ListaDeParcelas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }}>
                                        <strong>Sobre o {Item.MeioDePagamento}:</strong> Nº da operação: {Item.NumeroDaOperacao ?? "--"}
                                    </Component.DescriptionContent>}

                                </div>
                            </div>
                            {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cheque" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Banco:" className="banco" labelFor="Banco" inline={true}>
                                    <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Banco = _Item;
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Banco ? ((Item.Banco).chave + ": " + (Item.Banco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                    </PickerBanco>
                                </FormGroup>
                                <FormGroup label="Agência:" className="agência" labelFor="Agencia" inline={true} >
                                    <InputGroup id="Agencia" placeholder={"00000"} value={Item.Agencia}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Agencia = event.currentTarget.value ?? "";
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Component.FormContent>
                                    <FormGroup label="Conta:" className="conta" labelFor="Conta" inline={true} >
                                        <InputGroup id="Conta" placeholder={"00000"} value={Item.Conta}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                ListaDeParcelas[Index].Conta = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeParcelas(ListaDeParcelas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="- " labelFor="DigitoDaConta" inline={true} style={{ marginLeft: "20px" }} >
                                        <InputGroup id="DigitoDaConta" placeholder={"00"} value={Item.DigitoDaConta}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                ListaDeParcelas[Index].DigitoDaConta = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeParcelas(ListaDeParcelas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                </Component.FormContent>
                                <FormGroup label="Titular:" className="titular" labelFor="Titular" inline={true} >
                                    <InputGroup id="Titular" placeholder={"Informe o nominal"} value={Item.Titular}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Titular = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Nº do cheque:" labelFor="NumeroCheque" inline={true} >
                                    <InputGroup id="NumeroCheque" placeholder={"Informe o número"} value={Item.NumeroCheque}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].NumeroCheque = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                    let ParcelasVazios = []
                                    ParcelasVazios = ListaDeParcelas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == ""))
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeParcelas.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Banco ?? "") == "" && (Item__.Agencia ?? "") == "" && (Item__.Conta ?? "") == "" && (Item__.DigitoDaConta ?? "") == "" && (Item__.Titular ?? "") == "" && (Item__.NumeroCheque ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (ParcelasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ParcelasVazios = [...getFormularioListaDeParcelas];
                                                            ParcelasVazios.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == "") {
                                                                    Item_.Banco = Item.Banco
                                                                    Item_.Agencia = Item.Agencia
                                                                    Item_.Conta = Item.Conta
                                                                    Item_.DigitoDaConta = Item.DigitoDaConta
                                                                    Item_.Titular = Item.Titular
                                                                    Item_.NumeroCheque = Item.NumeroCheque
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeParcelas(ListaDeParcelas);
                                                                }
                                                            })
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        })
                                    }
                                    else {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                            {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cartao" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Máquina:" className="máquina" labelFor="Maquina" inline={true} >
                                    <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Maquina = _Item.Valor;
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Maquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                    </PickerMaquina>
                                </FormGroup>
                                <FormGroup label="Dígito do cartão:" labelFor="DigitoCartao" inline={true} >
                                    <InputGroup id="DigitoCartao" placeholder={"Informe os últimos 04 dígitos do cartão"} value={Item.DigitoCartao}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].DigitoCartao = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Bandeira:" className="bandeira" labelFor="Bandeira" inline={true} >
                                    <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Bandeira = _Item.Valor;
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Bandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                    </PickerMaquina>
                                </FormGroup>
                                <FormGroup label="Terminal:" className="terminal" labelFor="Terminal" inline={true} >
                                    <PickerTerminal popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={GetTerminais} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { id: number, descricao: string }) => <MenuItem onClick={async () => {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].Terminal = _Item.id;
                                            setFormularioListaDeEntradas(ListaDeEntradas);
                                        }} text={_Item.descricao} />} itemPredicate={(Texto: string, _Item: { id: number, descricao: string }) => { return ((_Item.descricao) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={GetTerminais.find((_Item) => _Item.id == Item.Terminal)?.descricao ?? "Selecione o terminal"} rightIcon="double-caret-vertical" />
                                    </PickerTerminal>
                                </FormGroup>
                                <FormGroup label="Cód. autorização:" labelFor="CodAutorizacao" inline={true} >
                                    <InputGroup id="CodAutorizacao" placeholder={"Informe o código de autorização"} value={Item?.CodAutorizacao}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].CodAutorizacao = (event.currentTarget.value ?? "");
                                            setFormularioListaDeEntradas(ListaDeEntradas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Operação:" className="operação" labelFor="Operacao" inline={true} >
                                    <HTMLSelect id="Operacao" name="Operacao" value={Item.Operacao == undefined ? "" : Item.Operacao}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Operacao = (event.currentTarget.value ?? "");
                                            if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "Debito") {
                                                const EhDiaUtil = require('eh-dia-util');
                                                let Vencimento = new Date();
                                                Vencimento.setDate(Vencimento.getDate() + 1);
                                                while (EhDiaUtil(Vencimento) == false) {
                                                    Vencimento.setDate(Vencimento.getDate() + 1);
                                                }
                                                ListaDeParcelas[Index].Vencimento = Vencimento;
                                            }
                                            if (Item.MeioDePagamento == "Cartao" && Item.Operacao != "Debito") {
                                                ListaDeParcelas.map((Item_, Index_) => {
                                                    if (Index_ >= Index) {
                                                        let Vencimento = new Date(getFormularioDataDaProposta);
                                                        Vencimento.setDate(Vencimento.getDate() + (1 + Index_ - Index) * 30);
                                                        Item_.Vencimento = Vencimento;
                                                    }
                                                });
                                            }
                                            else {
                                                ListaDeParcelas[Index].Vencimento = ListaDeParcelas[Index].Vencimento;
                                            }
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                        <option selected value="">Selecione a operação</option>
                                        {Operacao.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                </FormGroup>
                                <FormGroup label="NSU:" className="nsu" labelFor="NSU" inline={true} >
                                    <InputGroup id="NSU" placeholder={"Informe o NSU"} value={Item.NSU}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].NSU = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                    let ParcelasVazios = [];
                                    ParcelasVazios = ListaDeParcelas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == ""));
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeParcelas.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Maquina ?? "") == "" && (Item__.DigitoCartao ?? "") == "" && (Item__.Bandeira ?? "") == "" && (Item__.Operacao ?? "") == "" && (Item__.NSU ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (ParcelasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == "") {
                                                                    Item_.Maquina = Item.Maquina
                                                                    Item_.DigitoCartao = Item.DigitoCartao
                                                                    Item_.Bandeira = Item.Bandeira
                                                                    Item_.Operacao = Item.Operacao
                                                                    Item_.NSU = Item.NSU
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeParcelas(ListaDeParcelas);
                                                                }
                                                            })
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        })
                                    }
                                    else {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                            {Item.DetalharMeioDePagamento == true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Nº da operação:" labelFor="NumeroDaOperacao" inline={true} >
                                    <InputGroup id="NumeroDaOperacao" placeholder={"00000"} value={Item.NSU}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].NumeroDaOperacao = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                    let ParcelasVazios = [];
                                    ParcelasVazios = ListaDeParcelas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == ""));
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeParcelas.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.NumeroDaOperacao ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (ParcelasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == "") {
                                                                    Item_.NumeroDaOperacao = Item.NumeroDaOperacao
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeParcelas(ListaDeParcelas);
                                                                }
                                                            })
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        });
                                    }
                                    else {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                        </div>
                    )}
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <FormGroup label="Total gerado:" labelFor="ParcelaValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                            <InputGroup id="ParcelaValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto(getFormularioListaDeParcelas.reduce((total, parcela) => (total + (parcela.Valor * parcela.Quantidade)), 0))} leftIcon="numerical" />
                        </FormGroup>}
                        {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <FormGroup label="Total gerado:" labelFor="ParcelaValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                            <InputGroup id="ParcelaValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto(getFormularioListaDeParcelas.reduce((total, parcela) => (total + (parcela.ValorPMT * parcela.Quantidade)), 0))} leftIcon="numerical" />
                        </FormGroup>}
                        {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <FormGroup style={{ marginRight: "10px" }} label="Total esperado:" labelFor="ParcelaValorTotalEsperado" inline={true} >
                            <InputGroup id="ParcelaValorTotalEsperado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0) *
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0)
                                )} leftIcon="numerical" />
                        </FormGroup>}
                        {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <FormGroup style={{ marginRight: "10px" }} label="Total esperado:" labelFor="ParcelaValorTotalEsperado" inline={true} >
                            <InputGroup id="ParcelaValorTotalEsperado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0) *
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0)
                                )} leftIcon="numerical" />
                        </FormGroup>}
                        {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeParcelas.reduce((total, parcela) => (total + parcela.Valor * parcela.Quantidade), 0)) ? "A distribuir: " : "Excedido: "} labelFor="EntradaValorTotalADistribuir" inline={true}>
                            <InputGroup id="ParcelaValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) - getFormularioListaDeParcelas.reduce((total, parcela) => (total + parcela.Valor * parcela.Quantidade), 0))} leftIcon="numerical" />
                        </FormGroup>}
                        {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 || ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10) && <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeParcelas.reduce((total, parcela) => (total + parcela.Valor * parcela.Quantidade), 0)) ? "A distribuir: " : "Excedido: "} labelFor="EntradaValorTotalADistribuir" inline={true}>
                            <InputGroup id="ParcelaValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(Moeda.DesformatarTexto(Moeda.FormatarTexto(pv((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.jurosDeTabela ?? 0), (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0), - Moeda.DesformatarTexto(Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) / (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0)))))) - getFormularioListaDeParcelas.reduce((total, parcela) => (total + parcela.Valor * parcela.Quantidade), 0))} leftIcon="numerical" />
                        </FormGroup>}
                    </div>
                </Component.ContentCard>}
                <Component.ContentCard>
                    <Component.Title>
                        <Icon icon="annotation" iconSize={25} />
                        <h3>Informações complementares</h3>
                    </Component.Title>
                    {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && <Fragment>
                        <FormGroup label={"Id Casal:"} labelFor="IdCasal" inline={true}>
                            <Component.FormContent>
                                <InputGroup style={{ width: "24vw", marginLeft: "81px" }} id="IdCasal" placeholder={`Informe o Id Casal presente no TSE`} value={getFormularioIdCasal}
                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIdCasal(event.currentTarget.value.replace(/\D/g, "")); }}>
                                </InputGroup>
                                {<Button type="submit" intent="none" style={{ marginLeft: "10px", width: "30px", height: "20px" }} onClick={async () => {
                                    try {
                                        await setBuscandoIdCasal(true);
                                        let Response = await Venda.ConsultIdCasal(ContextSignIn.getContext().token, getFormularioIdCasal);
                                        let Item = getListaPickerSala?.find((Item) => Item.descricao == Response.data.descricao);
                                        if (Math.floor(Response.status / 100) == 2) {
                                            if (Response.data) {
                                                await setItemPickerSala(Response.data);
                                            }
                                            if (Response.data.assessorTlmkt) { await setListaPickerAssessorTlmkt([Response.data.promotorTLMKT]) }
                                            if (Response.data.closer) { await setListaPickerCloser([Response.data.closer]); }
                                            if (Response.data.liner) { await setListaPickerLiner([Response.data.liner]); }
                                            if (Response.data.pep) { await setListaPickerPEP([Response.data.pep]); }
                                            if (Response.data.promotor) { await setListaPickerPromotor([Response.data.promotor]); }
                                            await setListaPickerSubGerenteDeSala(Item?.subGerenteDeSala);
                                            if (Response.data.gerenteDeSala) { await setListaPickerGerenteDeSala([Response.data.gerenteDeSala]); }

                                            await setItemPickerAssessorTlmkt(Response.data.promotorTLMKT);
                                            await setItemPickerCloser(Response.data.closer);
                                            await setItemPickerLiner(Response.data.liner);
                                            await setItemPickerPEP(Response.data.pep);
                                            await setItemPickerPromotor(Response.data.promotor);
                                            await setItemPickerGerenteDeSala(Response.data.gerenteDeSala);
                                        }
                                        else {
                                            await setBuscandoIdCasal(undefined);
                                        }
                                        await setBuscandoIdCasal(false);
                                    }
                                    catch {
                                        await setBuscandoIdCasal(undefined);
                                    }
                                }}>
                                    <Icon icon="search" iconSize={15} />
                                </Button>}
                                {getBuscandoIdCasal == true && <Icon icon="geosearch" style={{ marginLeft: "10px" }} />}
                            </Component.FormContent>
                        </FormGroup>
                        <Component.FormContent>
                            {getBuscandoIdCasal == false && <div style={{ display: "block", marginRight: "10px" }}>
                                <FormGroup label="Sala:" labelFor="Sala" inline={true} style={{ flexWrap: "wrap" }}>
                                    <PickerSala disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerSala ?? [] as Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }) => <MenuItem onClick={async () => {
                                            await setItemPickerSala(Item);
                                            await setListaPickerAssessorTlmkt(Item.assessorTlmkt);
                                            await setListaPickerCloser(Item.closer);
                                            await setListaPickerLiner(Item.liner);
                                            await setListaPickerPEP(Item.pep);
                                            await setListaPickerPromotor(Item.promotor);
                                            await setListaPickerSubGerenteDeSala(Item.subGerenteDeSala);
                                            await setListaPickerGerenteDeSala(Item.gerenteDeSala);
                                            await setItemPickerSubGerenteDeSala(Item.subGerenteDeSala[0]);
                                            await setItemPickerGerenteDeSala(Item.gerenteDeSala[0]);
                                        }} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cidade: string, closer: Array<{ descricao: string }>, liner: Array<{ descricao: string }>, pep: Array<{ descricao: string }>, subGerenteDeSala: Array<{ descricao: string }>, gerenteDeSala: Array<{ descricao: string }> }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "105px" }} text={getItemPickerSala?.descricao ?? "Selecione a sala"} rightIcon="double-caret-vertical" />
                                    </PickerSala>
                                    {getItemPickerSala && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerSala(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Captador/Promotor:" labelFor="Promotor" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerPromotor disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerPromotor ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => { await setItemPickerPromotor(Item); }} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "10px" }} text={getItemPickerPromotor?.descricao ?? "Selecione o captador/promotor"} rightIcon="double-caret-vertical" />
                                    </PickerPromotor>
                                    {getItemPickerPromotor && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerPromotor(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Assessor Tlmkt:" labelFor="AssessorTlmkt" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerAssessorTlmkt disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerAssessorTlmkt ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => await setItemPickerAssessorTlmkt(Item)} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "35px" }} text={getItemPickerAssessorTlmkt?.descricao ?? "Selecione o assessor tlmkt"} rightIcon="double-caret-vertical" />
                                    </PickerAssessorTlmkt>
                                    {getItemPickerAssessorTlmkt && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerAssessorTlmkt(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Liner:" labelFor="Liner" inline={true} style={{ flexWrap: "wrap" }}>
                                    <PickerLiner disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerLiner ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => { await setItemPickerLiner(Item); }} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "102px" }} text={getItemPickerLiner?.descricao ?? "Selecione o liner"} rightIcon="double-caret-vertical" />
                                    </PickerLiner>
                                    {getItemPickerLiner && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerLiner(undefined); }} />}
                                </FormGroup>
                            </div>}
                            {getBuscandoIdCasal == false && <div style={{ display: "block" }}>
                                <FormGroup label="Closer:" labelFor="Closer" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerCloser disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCloser ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => await setItemPickerCloser(Item)} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "82px" }} text={getItemPickerCloser?.descricao ?? "Selecione o closer"} rightIcon="double-caret-vertical" />
                                    </PickerCloser>
                                    {getItemPickerCloser && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerCloser(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="PEP:" labelFor="PEP" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerPEP disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerPEP ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => await setItemPickerPEP(Item)} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "97px" }} text={getItemPickerPEP?.descricao ?? "Selecione o PEP"} rightIcon="double-caret-vertical" />
                                    </PickerPEP>
                                    {getItemPickerPEP && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerPEP(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Sub gerente de sala:" labelFor="SubGerenteDeSala" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerSubGerenteDeSala popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerSubGerenteDeSala ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => await setItemPickerSubGerenteDeSala(Item)} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw" }} text={getItemPickerSubGerenteDeSala?.descricao ?? "Selecione o sub gerente"} rightIcon="double-caret-vertical" />
                                    </PickerSubGerenteDeSala>
                                    {getItemPickerSubGerenteDeSala && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerSubGerenteDeSala(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Gerente de sala:" labelFor="GerenteDeSala" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerGerenteDeSala disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerGerenteDeSala ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => await setItemPickerGerenteDeSala(Item)} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "25px" }} text={getItemPickerGerenteDeSala?.descricao ?? "Selecione o gerente de sala"} rightIcon="double-caret-vertical" />
                                    </PickerGerenteDeSala>
                                    {getItemPickerGerenteDeSala && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerGerenteDeSala(undefined); }} />}
                                </FormGroup>
                            </div>}
                            <FormGroup label="Finalidade de compra:" labelFor="FinalidadeDeCompra" inline={true} style={{ flexWrap: "wrap", marginRight: "10px" }} >
                                <PickerFinalidadeDeCompra popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerFinalidadeDeCompra ?? [] as Array<{ id: number, descricao: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    itemRenderer={(Item: { id: number, descricao: string }) => <MenuItem onClick={async () => await setItemPickerFinalidadeDeCompra(Item)} text={Item.descricao} />}
                                    itemPredicate={(Texto: string, Item: { id: number, descricao: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                    <Button style={{ width: "190px" }} text={getItemPickerFinalidadeDeCompra?.descricao ?? "Selecione a finalidade"} rightIcon="double-caret-vertical" />
                                </PickerFinalidadeDeCompra>
                                {getItemPickerFinalidadeDeCompra && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFinalidadeDeCompra(undefined); }} />}
                            </FormGroup>
                            <FormGroup label="Data da proposta:" labelFor="DataDaProposta" inline={true} style={{ display: "flex", justifyContent: "space-between", width: "230px" }}>
                                <DateInput inputProps={{ style: { width: "215px" }, onClick: () => setFormularioExibirDataDaProposta(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                    popoverProps={{ disabled: !getFormularioExibirDataDaProposta, onClosed: () => setFormularioExibirDataDaProposta(false) }}
                                    parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                    placeholder={"DD/MM/AAAA"} value={getFormularioDataDaProposta} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                    onChange={async (DataSelecionada: Date) => await setFormularioDataDaProposta(DataSelecionada)}>
                                </DateInput>
                            </FormGroup>
                        </Component.FormContent>
                    </Fragment>}
                    {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 4 && <Fragment>
                        <Component.FormContent>
                            <FormGroup label="Finalidade de compra:" labelFor="FinalidadeDeCompra" inline={true} style={{ flexWrap: "wrap", marginRight: "10px" }} >
                                <PickerFinalidadeDeCompra popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerFinalidadeDeCompra ?? [] as Array<{ id: number, descricao: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    itemRenderer={(Item: { id: number, descricao: string }) => <MenuItem onClick={async () => await setItemPickerFinalidadeDeCompra(Item)} text={Item.descricao} />}
                                    itemPredicate={(Texto: string, Item: { id: number, descricao: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                    <Button style={{ width: "190px" }} text={getItemPickerFinalidadeDeCompra?.descricao ?? "Selecione a finalidade"} rightIcon="double-caret-vertical" />
                                </PickerFinalidadeDeCompra>
                                {getItemPickerFinalidadeDeCompra && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFinalidadeDeCompra(undefined); }} />}
                            </FormGroup>
                            <FormGroup label="Data da proposta:" labelFor="DataDaProposta" inline={true}>
                                <DateInput inputProps={{ style: { width: "215px" }, onClick: () => setFormularioExibirDataDaProposta(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                    popoverProps={{ disabled: !getFormularioExibirDataDaProposta, onClosed: () => setFormularioExibirDataDaProposta(false) }}
                                    parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                    placeholder={"DD/MM/AAAA"} value={getFormularioDataDaProposta} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                    onChange={async (DataSelecionada: Date) => await setFormularioDataDaProposta(DataSelecionada)}>
                                </DateInput>
                            </FormGroup>
                        </Component.FormContent>
                    </Fragment>}
                </Component.ContentCard>
                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Ficha de negociação</h3>
                        </div>
                        <FileInput style={{ width: "50vw", minWidth: "300px", marginBottom: "10px", marginLeft: "-3px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoFichaDeNegociacao == undefined ? "Anexe a ficha de negociação" : getFormularioAnexoFichaDeNegociacao.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoFichaDeNegociacao(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoFichaDeNegociacao && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoFichaDeNegociacao(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                </Component.ContentCard>}
                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Ficha de atendimento</h3>
                        </div>
                        <FileInput style={{ width: "50vw", minWidth: "300px", marginBottom: "10px", marginLeft: "-3px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoFichaDeAtendimento == undefined ? "Anexe a ficha de negociação" : getFormularioAnexoFichaDeAtendimento.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoFichaDeAtendimento(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoFichaDeAtendimento && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoFichaDeAtendimento(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                </Component.ContentCard>}
                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Documentação complementar</h3>
                        </div>
                        <FileInput style={{ width: "50vw", minWidth: "300px", marginBottom: "10px", marginLeft: "-3px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoDocumentacaoComplementar == undefined ? "Anexe a ficha de negociação" : getFormularioAnexoDocumentacaoComplementar.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoDocumentacaoComplementar(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoDocumentacaoComplementar && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoDocumentacaoComplementar(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                </Component.ContentCard>}
                <Component.ContentCard>
                    <div>
                        <Dialog
                            isOpen={getExibirPermitirEnvioComAnexoPendente == true}
                            onClose={() => setExibirPermitirEnvioComAnexoPendente(false)}
                            title="Alerta">
                            <div className={Classes.DIALOG_BODY}>
                                <p>Nem todos os anexos foram informados, deseja prosseguir com a venda?</p>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button onClick={() => {
                                        setPermitirEnvioComAnexoPendente(false);
                                        setExibirPermitirEnvioComAnexoPendente(false);
                                    }}>Não</Button>
                                    <Button intent="primary" onClick={() => {
                                        setPermitirEnvioComAnexoPendente(true);
                                        setExibirPermitirEnvioComAnexoPendente(false);
                                    }}>Sim</Button>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            isOpen={getExibirPermitirParcelasNaoSubsequentes == true}
                            onClose={() => setExibirPermitirParcelasNaoSubsequentes(false)}
                            title="Alerta">
                            <div className={Classes.DIALOG_BODY}>
                                <p>As datas das parcelas não estão subsequentes, deseja prosseguir com a venda?</p>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button onClick={() => {
                                        setPermitirParcelasNaoSubsequentes(false);
                                        setExibirPermitirParcelasNaoSubsequentes(false);
                                    }}>Não</Button>
                                    <Button intent="primary" onClick={() => {
                                        setPermitirParcelasNaoSubsequentes(true);
                                        setExibirPermitirParcelasNaoSubsequentes(false);
                                    }}>Sim</Button>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            isOpen={getExibirPermitirEmailInvalido == true}
                            onClose={() => setExibirPermitirEmailInvalido(false)}
                            title="Alerta">
                            <div className={Classes.DIALOG_BODY}>
                                <p>O email digitado para o comprador está em um formato invalido, deseja prosseguir com a venda?</p>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button onClick={() => {
                                        setPermitirEmailInvalido(false);
                                        setExibirPermitirEmailInvalido(false);
                                    }}>Não</Button>
                                    <Button intent="primary" onClick={() => {
                                        setPermitirEmailInvalido(true);
                                        setExibirPermitirEmailInvalido(false);
                                    }}>Sim</Button>
                                </div>
                            </div>
                        </Dialog>
                        <Button type="submit" disabled={getEnviandoPropostaDeVenda} intent="primary" style={{ marginLeft: "50px", width: "100px" }} onClick={() => EnviarPropostaDeVenda(undefined)}>Enviar</Button>
                        {getEnviandoPropostaDeVenda == true && <div style={{ width: 100, height: 100 }}>
                            <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                        </div>}
                        <Button type="submit" disabled={getObjetoPropostaDeVenda ? false : true} intent="success" style={{ marginLeft: "20px", width: "100px" }} onClick={ReimprimirDocumento}>Reimprimir</Button>
                        <Button type="submit" intent="danger" style={{ marginLeft: "20px", width: "100px", cursor: (getLimpandoDocumento == true ? "wait" : undefined) }} onClick={NovoDocumento}>Limpar tudo</Button>
                        {getLimpandoDocumento == true && <div style={{ width: 100, height: 100 }}>
                            <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                        </div>}
                    </div>
                </Component.ContentCard>
            </Card>
            <Card style={{ width: "88%", backgroundColor: "#ffffff", margin: "40px auto", borderRadius: "5px", boxShadow: "none", padding: "40px 3% 40px 3% " }}>
                <Component.Title>
                    <Icon icon="form" iconSize={25} />
                    <h3>Documento(s) gerado(s)</h3>
                </Component.Title>
                <Component.ContentFormHeader>
                    {getObjetoContrato ? getObjetoContrato?.map((Item) =>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                            <Button type="submit" intent="success" style={{ marginLeft: "50px", marginRight: "50px", width: "100px", height: "20px" }} onClick={() => {
                                ImprimirContrato(Item.descricao, Item.extensao, Item.arquivo);
                            }}>Imprimir</Button>
                            <label style={{ height: "20px" }}>{Item.descricao + "." + Item.extensao}</label>
                        </div>
                    ) : <label>Nenhum contrato ainda gerado, por favor, gere uma propsta</label>}
                    {getObjetoContrato && <div style={{ display: "flex", marginTop: "10px" }}>
                        <Button type="submit" intent="success" style={{ marginLeft: "50px", marginRight: "50px", width: "100px", height: "20px" }} onClick={async () => {
                            let Tentativas = 0;
                            NotificacaoInterna.ExibirNotificacao("Seu contrato está sendo baixado", `Aguarde enquanto os documentos são unificados e baixados`, NotificacaoInterna.TipoDeNotificacao.Informacao);
                            while (Tentativas < 5) {
                                {
                                    let Anexos = [] as Array<{ Nome: string, Conteudo: File | null | undefined }>;
                                    if (getFormularioAnexoCheckListDeCadastro != undefined && getFormularioAnexoCheckListDeCadastro != null) {
                                        Anexos.push({ Nome: "01. Check list de cadastro.pdf", Conteudo: getFormularioAnexoCheckListDeCadastro });
                                    }
                                    if (getFormularioAnexoCliente != undefined && getFormularioAnexoCliente != null) {
                                        Anexos.push({ Nome: "08. Doc cliente 01 comprador.pdf", Conteudo: getFormularioAnexoCliente });
                                    }
                                    if (getFormularioAnexoConjuge != undefined && getFormularioAnexoConjuge != null) {
                                        Anexos.push({ Nome: "09. Doc conjuge 01 comprador.pdf", Conteudo: getFormularioAnexoConjuge });
                                    }
                                    if (getFormularioAnexoEndereco != undefined && getFormularioAnexoEndereco != null) {
                                        Anexos.push({ Nome: "10. Comp endereco 01 comprador.pdf", Conteudo: getFormularioAnexoEndereco });
                                    }
                                    if (getFormulario02CompradorAnexoCliente != undefined && getFormulario02CompradorAnexoCliente != null) {
                                        Anexos.push({ Nome: "11. Doc cliente 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoCliente });
                                    }
                                    if (getFormulario02CompradorAnexoConjuge != undefined && getFormulario02CompradorAnexoConjuge != null) {
                                        Anexos.push({ Nome: "12. Doc conjuge 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoConjuge });
                                    }
                                    if (getFormulario02CompradorAnexoEndereco != undefined && getFormulario02CompradorAnexoEndereco != null) {
                                        Anexos.push({ Nome: "13. Comp endereco 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoEndereco });
                                    }
                                    if (getFormularioAnexoComprovantePgtoEntrada != undefined && getFormularioAnexoComprovantePgtoEntrada != null) {
                                        Anexos.push({ Nome: "14. Comp de pagamento da entrada.pdf", Conteudo: getFormularioAnexoComprovantePgtoEntrada });
                                    }
                                    if (getFormularioAnexoFichaDeNegociacao != undefined && getFormularioAnexoFichaDeNegociacao != null) {
                                        Anexos.push({ Nome: "15. Ficha de negociação.pdf", Conteudo: getFormularioAnexoFichaDeNegociacao });
                                    }
                                    if (getFormularioAnexoFichaDeAtendimento != undefined && getFormularioAnexoFichaDeAtendimento != null) {
                                        Anexos.push({ Nome: "19. Ficha de atendimento.pdf", Conteudo: getFormularioAnexoFichaDeAtendimento });
                                    }
                                    if (getFormularioAnexoDocumentacaoComplementar != undefined && getFormularioAnexoDocumentacaoComplementar != null) {
                                        Anexos.push({ Nome: "20. Documentação complementar.pdf", Conteudo: getFormularioAnexoDocumentacaoComplementar });
                                    }
                                    let Response = await Venda.NovosDocumentos(ContextSignIn.getContext().token, getObjetoPropostaDeVenda?.empresa.id ?? 0, getObjetoPropostaDeVenda?.centroDeCusto.sigla ?? "", getObjetoPropostaDeVenda?.numero ?? 0, Anexos);
                                    if (Math.floor(Response.status / 100) == 2) {

                                    }
                                    else {
                                        NotificacaoInterna.ExibirNotificacao("Falha ao enviar anexos do cliente", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao enviar o contrato para assinatura, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        await setEnviandoContratoParaAssinatura(false);
                                        return;
                                    }
                                }

                                try {
                                    let Response = await Venda.ConsultarECompactarContrato(ContextSignIn.getContext().token, getObjetoPropostaDeVenda?.empresa.id ?? 0, getObjetoPropostaDeVenda?.centroDeCusto.sigla ?? "", getObjetoPropostaDeVenda?.numero ?? 0, false);
                                    let downloadLink = document.createElement("a");
                                    downloadLink.href = "data:application/octet-stream;charset=utf-16le;base64," + encodeURI(Response.data.arquivo);
                                    downloadLink.download = `${((getObjetoPropostaDeVenda?.empresa.id == 20) ? "PRE" : ((getObjetoPropostaDeVenda?.empresa.id == 21) ? "SPR" : ((getObjetoPropostaDeVenda?.empresa.id == 22) ? "SER" : ((getObjetoPropostaDeVenda?.empresa.id == 23) ? "PAR" : (getObjetoPropostaDeVenda?.empresa.id == 26 ? "PYR" : getObjetoPropostaDeVenda?.centroDeCusto.sigla)))))}_${getObjetoPropostaDeVenda?.identificador.subLocal.descricao}${getObjetoPropostaDeVenda?.prospects[0].nome}.${Response.data.extensao}`;
                                    downloadLink.click();
                                    NotificacaoInterna.ExibirNotificacao("Seu contrato foi baixado com sucesso", `🖨️ Acesse a sua pasta de downloads e procure pelo o arquivo ˜${getObjetoPropostaDeVenda?.centroDeCusto.sigla}_${getObjetoPropostaDeVenda?.identificador.subLocal.descricao}_${getObjetoPropostaDeVenda?.prospects[0].nome}.${Response.data.extensao}`, NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    Tentativas = 6;
                                }
                                catch (Ex) {
                                    Tentativas++;
                                }
                            }
                            if (Tentativas == 5) {
                                NotificacaoInterna.ExibirNotificacao("Erro ao baixar o(s) documento(s)", `Entre em contato com o suporte`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }}>Baixar</Button>
                        <label style={{ height: "20px" }}>Baixar todos os documentos</label>
                    </div>
                    }
                </Component.ContentFormHeader>
            </Card>
            {getObjetoContrato && <Card>
                <Component.Content>
                    <Button type="submit" disabled={getEnviandoContratoParaAssinatura} intent="primary" style={{ marginLeft: "50px", width: "200px" }} onClick={EncaminharDocumentoParaAssinatura}>Submeter a assinatura</Button>
                    {getEnviandoContratoParaAssinatura == true && <div style={{ width: 100, height: 100 }}>
                        <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                    </div>}
                </Component.Content>
            </Card>}
        </Component.Container >
    );
    /* #endregion */
};

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const PropriedadesDeCalendario = { months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"], weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'] };
    const RefPagina = useRef<HTMLDivElement>(null);
    const [GetTerminais, setTerminais] = useState<Array<{ id: number, descricao: string }>>([]);
    const [getFormularioAnexoCheckListDeCadastro, setFormularioAnexoCheckListDeCadastro] = useState<File>();
    const [getFormularioAnexoCliente, setFormularioAnexoCliente] = useState<File>();
    const [getFormularioNome, setFormularioNome] = useState<string>("");
    const [getFormularioEmail, setFormularioEmail] = useState<string>("");
    const [getCPFEmConsulta, setCPFEmConsulta] = useState<boolean>(false);
    const [getFormularioCPF, setFormularioCPF] = useState<string>("");
    const [getFormularioRG, setFormularioRG] = useState<string>("");
    const [getFormularioRGOrgaoEmissor, setFormularioRGOrgaoEmissor] = useState<string>("");
    const [getFormularioRGUF, setFormularioRGUF] = useState<string>("");
    const [getFormularioDataDeNascimento, setFormularioDataDeNascimento] = useState<Date | null | undefined>(undefined);
    const [getFormularioExibirCalendarioDataDeNascimento, setFormularioExibirCalendarioDataDeNascimento] = useState<boolean>(false);
    const [getItemPickerFormularioNacionalidade, setItemPickerFormularioNacionalidade] = useState<Objeto.Nacao | undefined>();
    const [getListaPickerNacionalidade, setListaPickerNacionalidade] = useState<Array<Objeto.Nacao>>([]);
    const PickerNacionalidade = Select.ofType<Objeto.Nacao>();

    const [getItemPickerFormularioNaturalidade, setItemPickerFormularioNaturalidade] = useState<{ id: number, cidade: string, uf: string } | undefined>();
    const [getListaPickerNaturalidade, setListaPickerNaturalidade] = useState<Array<{ id: number, cidade: string, uf: string }>>([]);
    const PickerNaturalidade = Select.ofType<{ id: number, cidade: string, uf: string }>();
    const [getFormularioPai, setFormularioPai] = useState<string>("");
    const [getFormularioMae, setFormularioMae] = useState<string>("");
    const [getFormularioCNH, setFormularioCNH] = useState<string>("");
    const [getFormularioCNHExpedicao, setFormularioCNHExpedicao] = useState<Date | null | undefined>(undefined);
    const [getFormularioExibirCalendarioCNHExpedicao, setFormularioExibirCalendarioCNHExpedicao] = useState<boolean>(false);
    const [getFormularioCNHUF, setFormularioCNHUF] = useState<string>("");

    const [getFormularioProfissao, setFormularioProfissao] = useState<string>("");
    const [getFormularioListaEstadoCivil, setFormularioListaEstadoCivil] = useState<Array<{ id: number, descricao: string }>>();
    const [getFormularioEstadoCivil, setFormularioEstadoCivil] = useState<{ id: number, descricao: string }>();
    const [getFormularioTelefoneCelular, setFormularioTelefoneCelular] = useState<string>("");
    const [getFormularioTelefoneComercial, setFormularioTelefoneComercial] = useState<string>("");
    const [getFormularioAnexoConjuge, setFormularioAnexoConjuge] = useState<File>();
    const [getFormularioConjugeNome, setFormularioConjugeNome] = useState<string>("");
    const [getFormularioConjugeEmail, setFormularioConjugeEmail] = useState<string>("");
    const [getFormularioConjugeCPF, setFormularioConjugeCPF] = useState<string>("");
    const [getFormularioConjugeRG, setFormularioConjugeRG] = useState<string>("");
    const [getFormularioConjugeRGOrgaoEmissor, setFormularioConjugeRGOrgaoEmissor] = useState<string>("");
    const [getFormularioConjugeRGUF, setFormularioConjugeRGUF] = useState<string>("");
    const [getFormularioConjugeDataDeNascimento, setFormularioConjugeDataDeNascimento] = useState<Date>();
    const [getFormularioExibirCalendarioConjugeDataDeNascimento, setFormularioExibirCalendarioConjugeDataDeNascimento] = useState<boolean>(false);
    const [getItemPickerFormularioConjugeNacionalidade, setItemPickerFormularioConjugeNacionalidade] = useState<Objeto.Nacao>();

    const [getItemPickerFormularioConjugeNaturalidade, setItemPickerFormularioConjugeNaturalidade] = useState<{ id: number, cidade: string, uf: string } | undefined>();
    const [getFormularioConjugePai, setFormularioConjugePai] = useState<string>("");
    const [getFormularioConjugeMae, setFormularioConjugeMae] = useState<string>("");
    const [getFormularioConjugeCNH, setFormularioConjugeCNH] = useState<string>("");
    const [getFormularioConjugeCNHExpedicao, setFormularioConjugeCNHExpedicao] = useState<Date | null | undefined>(undefined);
    const [getFormularioConjugeExibirCalendarioCNHExpedicao, setFormularioConjugeExibirCalendarioCNHExpedicao] = useState<boolean>(false);
    const [getFormularioConjugeCNHUF, setFormularioConjugeCNHUF] = useState<string>("");

    const [getFormularioConjugeProfissao, setFormularioConjugeProfissao] = useState<string>("");
    const [getFormularioConjugeTelefoneCelular, setFormularioConjugeTelefoneCelular] = useState<string>("");
    const [getFormularioConjugeTelefoneComercial, setFormularioConjugeTelefoneComercial] = useState<string>("");
    const [getFormularioExibir02Comprador, setFormularioExibir02Comprador] = useState<boolean>(false);
    const [getFormulario02CompradorAnexoCliente, setFormulario02CompradorAnexoCliente] = useState<File>();
    const [getFormulario02CompradorNome, setFormulario02CompradorNome] = useState<string>("");
    const [getFormulario02CompradorEmail, setFormulario02CompradorEmail] = useState<string>("");
    const [getFormulario02CompradorCPF, setFormulario02CompradorCPF] = useState<string>("");
    const [getFormulario02CompradorRG, setFormulario02CompradorRG] = useState<string>("");
    const [getFormulario02CompradorRGOrgaoEmissor, setFormulario02CompradorRGOrgaoEmissor] = useState<string>("");
    const [getFormulario02CompradorRGUF, setFormulario02CompradorRGUF] = useState<string>("");
    const [getFormulario02CompradorDataDeNascimento, setFormulario02CompradorDataDeNascimento] = useState<Date>();
    const [getFormulario02ExibirCalendarioCompradorDataDeNascimento, setFormulario02ExibirCalendarioCompradorDataDeNascimento] = useState<boolean>(false);
    const [getItemPickerFormulario02CompradorNacionalidade, setItemPickerFormulario02CompradorNacionalidade] = useState<Objeto.Nacao>();

    const [getItemPickerFormulario02CompradorNaturalidade, setItemPickerFormulario02CompradorNaturalidade] = useState<{ id: number, cidade: string, uf: string } | undefined>();
    const [getFormulario02CompradorPai, setFormulario02CompradorPai] = useState<string>("");
    const [getFormulario02CompradorMae, setFormulario02CompradorMae] = useState<string>("");
    const [getFormulario02CompradorCNH, setFormulario02CompradorCNH] = useState<string>("");
    const [getFormulario02CompradorCNHExpedicao, setFormulario02CompradorCNHExpedicao] = useState<Date | null | undefined>(undefined);
    const [getFormulario02CompradorExibirCalendarioCNHExpedicao, setFormulario02CompradorExibirCalendarioCNHExpedicao] = useState<boolean>(false);
    const [getFormulario02CompradorCNHUF, setFormulario02CompradorCNHUF] = useState<string>("");

    const [getFormulario02CompradorProfissao, setFormulario02CompradorProfissao] = useState<string>("");
    const [getFormulario02CompradorEstadoCivil, setFormulario02CompradorEstadoCivil] = useState<{ id: number, descricao: string }>();
    const [getFormulario02CompradorTelefoneCelular, setFormulario02CompradorTelefoneCelular] = useState<string>("");
    const [getFormulario02CompradorTelefoneComercial, setFormulario02CompradorTelefoneComercial] = useState<string>("");
    const [getFormulario02CompradorAnexoConjuge, setFormulario02CompradorAnexoConjuge] = useState<File>();
    const [getFormulario02CompradorConjugeNome, setFormulario02CompradorConjugeNome] = useState<string>("");
    const [getFormulario02CompradorConjugeEmail, setFormulario02CompradorConjugeEmail] = useState<string>("");
    const [getFormulario02CompradorConjugeCPF, setFormulario02CompradorConjugeCPF] = useState<string>("");
    const [getFormulario02CompradorConjugeRG, setFormulario02CompradorConjugeRG] = useState<string>("");
    const [getFormulario02CompradorConjugeRGOrgaoEmissor, setFormulario02CompradorConjugeRGOrgaoEmissor] = useState<string>("");
    const [getFormulario02CompradorConjugeRGUF, setFormulario02CompradorConjugeRGUF] = useState<string>("");
    const [getFormulario02CompradorConjugeDataDeNascimento, setFormulario02CompradorConjugeDataDeNascimento] = useState<Date>();
    const [getFormulario02ExibirCalendarioCompradorConjugeDataDeNascimento, setFormulario02ExibirCalendarioCompradorConjugeDataDeNascimento] = useState<boolean>(false);
    const [getItemPickerFormulario02CompradorConjugeNacionalidade, setItemPickerFormulario02CompradorConjugeNacionalidade] = useState<Objeto.Nacao>();

    const [getItemPickerFormulario02CompradorConjugeNaturalidade, setItemPickerFormulario02CompradorConjugeNaturalidade] = useState<{ id: number, cidade: string, uf: string } | undefined>();
    const [getFormulario02CompradorConjugePai, setFormulario02CompradorConjugePai] = useState<string>("");
    const [getFormulario02CompradorConjugeMae, setFormulario02CompradorConjugeMae] = useState<string>("");
    const [getFormulario02CompradorConjugeCNH, setFormulario02CompradorConjugeCNH] = useState<string>("");
    const [getFormulario02CompradorConjugeCNHExpedicao, setFormulario02CompradorConjugeCNHExpedicao] = useState<Date | null | undefined>(undefined);
    const [getFormulario02CompradorConjugeExibirCalendarioCNHExpedicao, setFormulario02CompradorConjugeExibirCalendarioCNHExpedicao] = useState<boolean>(false);
    const [getFormulario02CompradorConjugeCNHUF, setFormulario02CompradorConjugeCNHUF] = useState<string>("");

    const [getFormulario02CompradorConjugeProfissao, setFormulario02CompradorConjugeProfissao] = useState<string>("");
    const [getFormulario02CompradorConjugeTelefoneCelular, setFormulario02CompradorConjugeTelefoneCelular] = useState<string>("");
    const [getFormulario02CompradorConjugeTelefoneComercial, setFormulario02CompradorConjugeTelefoneComercial] = useState<string>("");
    const [getCEPEmConsulta, setCEPEmConsulta] = useState<boolean>(false);
    const [getFormularioCEP, setFormularioCEP] = useState<string>("");
    const [getFormularioAnexoEndereco, setFormularioAnexoEndereco] = useState<File>();
    const [getFormularioLogradouro, setFormularioLogradouro] = useState<string>("");
    const [getFormularioComplemento, setFormularioComplemento] = useState<string>("");
    const [getFormularioNumeroDeEndereco, setFormularioNumeroDeEndereco] = useState<string>("");
    const [getFormularioBairro, setFormularioBairro] = useState<string>("");
    const [getFormularioCidade, setFormularioCidade] = useState<string>("");
    const [getFormularioUF, setFormularioUF] = useState<string>("");
    const [getFormulario02CompradorCEP, setFormulario02CompradorCEP] = useState<string>("");
    const [getFormulario02CompradorAnexoEndereco, setFormulario02CompradorAnexoEndereco] = useState<File>();
    const [getFormulario02CompradorLogradouro, setFormulario02CompradorLogradouro] = useState<string>("");
    const [getFormulario02CompradorComplemento, setFormulario02CompradorComplemento] = useState<string>("");
    const [getFormulario02CompradorNumeroDeEndereco, setFormulario02CompradorNumeroDeEndereco] = useState<string>("");
    const [getFormulario02CompradorBairro, setFormulario02CompradorBairro] = useState<string>("");
    const [getFormulario02CompradorCidade, setFormulario02CompradorCidade] = useState<string>("");
    const [getFormulario02CompradorUF, setFormulario02CompradorUF] = useState<string>("");
    const [getFormularioLocalDeCaptacao, setFormularioLocalDeCaptacao] = useState<string>("SITE");
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const [getItemPickerIdentificador, setItemPickerIdentificador] = useState<Objeto.Identificador>();
    const [getListaPickerIdentificador, setListaPickerIdentificador] = useState<Array<Objeto.Identificador>>([]);
    const [getItemPickerIdentificadorSintetico, setItemPickerIdentificadorSintetico] = useState<Objeto.IdentificadorSintetico>();
    const [getListaPickerIdentificadorSintetico, setListaPickerIdentificadorSintetico] = useState<Array<Objeto.IdentificadorSintetico>>([]);
    const PickerIdentificador = Select.ofType<Objeto.IdentificadorSintetico>();
    const [getItemPickerCondicaoDaTabelaDeVenda, setItemPickerCondicaoDaTabelaDeVenda] = useState<Objeto.CondicaoDaTabelaDeVenda>();
    const [getListaPickerCondicaoDaTabelaDeVenda, setListaPickerCondicaoDaTabelaDeVenda] = useState<Array<Objeto.CondicaoDaTabelaDeVenda>>([]);
    const PickerCondicaoDaTabelaDeVenda = Select.ofType<Objeto.CondicaoDaTabelaDeVenda>();
    const [getListaCondicaoDaTabelaDeVenda, setListaCondicaoDaTabelaDeVenda] = useState<Array<Objeto.ClassificacaoDoTituloDaTabelaDeVenda>>([]);
    const [getItemPickerBanco, setItemPickerBanco] = useState<{ chave: string, Valor: string }>();
    const [getListaPickerBanco, setListaPickerBanco] = useState<Array<{ chave: string, Valor: string }>>(Banco);
    const PickerBanco = Select.ofType<{ chave: string, Valor: string }>();
    const PickerMaquina = Select.ofType<{ chave: string, Valor: string }>();
    const PickerTerminal = Select.ofType<{ id: number, descricao: string }>();
    const [getFormularioAnexoComprovantePgtoSinal, setFormularioAnexoComprovantePgtoSinal] = useState<File>();
    const [getFormularioSinalQtde, setFormularioSinalQtde] = useState<number>(0);
    const [getFormularioListaDeSinais, setFormularioListaDeSinais] = useState<Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>>([]);
    const [getFormularioAnexoComprovantePgtoEntrada, setFormularioAnexoComprovantePgtoEntrada] = useState<File>();
    const [getFormularioEntradaQtde, setFormularioEntradaQtde] = useState<number>(0);
    const [getFormularioListaDeEntradas, setFormularioListaDeEntradas] = useState<Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>>([]);
    const [getFormularioAnexoComprovantePgtoParcela, setFormularioAnexoComprovantePgtoParcela] = useState<File>();
    const [getFormularioIntermediariaValor, setFormularioIntermediariaValor] = useState<number>(0);
    const [getFormularioIntermediariaVencimento, setFormularioIntermediariaVencimento] = useState<Date | null | undefined>();
    const [getFormularioIntermediariaExibirCalendarioVencimento, setFormularioIntermediariaExibirCalendarioVencimento] = useState<boolean>(false);
    const [getFormularioIntermediariaMeioDePagamento, setFormularioIntermediariaMeioDePagamento] = useState<string>("");
    const [getFormularioIntermediariaBanco, setFormularioIntermediariaBanco] = useState<{ chave: string, Valor: string } | undefined>();
    const [getFormularioIntermediariaAgencia, setFormularioIntermediariaAgencia] = useState<string>();
    const [getFormularioIntermediariaConta, setFormularioIntermediariaConta] = useState<string>();
    const [getFormularioIntermediariaDigitoDaConta, setFormularioIntermediariaDigitoDaConta] = useState<string>();
    const [getFormularioIntermediariaTitular, setFormularioIntermediariaTitular] = useState<string>();
    const [getFormularioIntermediariaNumeroCheque, setFormularioIntermediariaNumeroCheque] = useState<string>();
    const [getFormularioIntermediariaMaquina, setFormularioIntermediariaMaquina] = useState<string>();
    const [getFormularioIntermediariaDigitoCartao, setFormularioIntermediariaDigitoCartao] = useState<string>();
    const [getFormularioIntermediariaBandeira, setFormularioIntermediariaBandeira] = useState<string>();
    const [getFormularioIntermediariaOperacao, setFormularioIntermediariaOperacao] = useState<string>();
    const [getFormularioIntermediariaNSU, setFormularioIntermediariaNSU] = useState<string>();
    const [getFormularioIntermediariaNumeroDaOperacao, setFormularioIntermediariaNumeroDaOperacao] = useState<string>();
    const [getFormularioListaDeIntermediarias, setFormularioListaDeIntermediarias] = useState<Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>>([]);
    const [getHabilitarInclusaoDeIntermediarias, setHabilitarInclusaoDeIntermediarias] = useState<boolean>(false);
    const [getFormularioParcelaValorTotal, setFormularioParcelaValorTotal] = useState<number>(0);
    const [getFormularioParcelaValorTotalJuros, setFormularioParcelaValorTotalJuros] = useState<number>(0);
    const [getFormularioParcelaValor, setFormularioParcelaValor] = useState<number>(0);
    const [getFormularioParcelaQtde, setFormularioParcelaQtde] = useState<number>(0);
    const [getFormularioParcelaVencimento, setFormularioParcelaVencimento] = useState<Date | null | undefined>();
    const [getFormularioParcelaExibirCalendarioVencimento, setFormularioParcelaExibirCalendarioVencimento] = useState<boolean>(false);
    const [getFormularioParcelaMeioDePagamento, setFormularioParcelaMeioDePagamento] = useState<string>("");
    const [getFormularioParcelaBanco, setFormularioParcelaBanco] = useState<{ chave: string, Valor: string } | undefined>();
    const [getFormularioParcelaAgencia, setFormularioParcelaAgencia] = useState<string>();
    const [getFormularioParcelaConta, setFormularioParcelaConta] = useState<string>();
    const [getFormularioParcelaDigitoDaConta, setFormularioParcelaDigitoDaConta] = useState<string>();
    const [getFormularioParcelaTitular, setFormularioParcelaTitular] = useState<string>();
    const [getFormularioParcelaNumeroCheque, setFormularioParcelaNumeroCheque] = useState<string>();
    const [getFormularioParcelaMaquina, setFormularioParcelaMaquina] = useState<string>();
    const [getFormularioParcelaDigitoCartao, setFormularioParcelaDigitoCartao] = useState<string>();
    const [getFormularioParcelaBandeira, setFormularioParcelaBandeira] = useState<string>();
    const [getFormularioParcelaOperacao, setFormularioParcelaOperacao] = useState<string>();
    const [getFormularioParcelaNSU, setFormularioParcelaNSU] = useState<string>();
    const [getFormularioParcelaNumeroDaOperacao, setFormularioParcelaNumeroDaOperacao] = useState<string>();
    const [getFormularioListaDeParcelas, setFormularioListaDeParcelas] = useState<Array<{ Quantidade: number, Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>>([]);
    const [getHabilitarInclusaoDeParcelas, setHabilitarInclusaoDeParcelas] = useState<boolean>(false);
    const [getFormularioIdCasal, setFormularioIdCasal] = useState<string>("");
    const [getBuscandoIdCasal, setBuscandoIdCasal] = useState<boolean | undefined>();
    const [getItemPickerSala, setItemPickerSala] = useState<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();
    const [getListaPickerSala, setListaPickerSala] = useState<Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>>();
    const PickerSala = Select.ofType<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();
    const [getItemPickerPromotor, setItemPickerPromotor] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerPromotor, setListaPickerPromotor] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerPromotor = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerAssessorTlmkt, setItemPickerAssessorTlmkt] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerAssessorTlmkt, setListaPickerAssessorTlmkt] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerAssessorTlmkt = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerLiner, setItemPickerLiner] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerLiner, setListaPickerLiner] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerLiner = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerCloser, setItemPickerCloser] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerCloser, setListaPickerCloser] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerCloser = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerPEP, setItemPickerPEP] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerPEP, setListaPickerPEP] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerPEP = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerSubGerenteDeSala, setItemPickerSubGerenteDeSala] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerSubGerenteDeSala, setListaPickerSubGerenteDeSala] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerSubGerenteDeSala = Select.ofType<{ descricao: string, cpf: string }>();
    const [getItemPickerGerenteDeSala, setItemPickerGerenteDeSala] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerGerenteDeSala, setListaPickerGerenteDeSala] = useState<Array<{ descricao: string, cpf: string }>>();
    const PickerGerenteDeSala = Select.ofType<{ descricao: string, cpf: string }>();
    const [getEnviandoPropostaDeVenda, setEnviandoPropostaDeVenda] = useState<boolean>(false);
    const [getEnviandoContratoParaAssinatura, setEnviandoContratoParaAssinatura] = useState<boolean>(false);
    const [getObjetoPropostaDeVenda, setObjetoPropostaDeVenda] = useState<Objeto.PropostaDeVenda>();
    const [getObjetoContrato, setObjetoContrato] = useState<Array<{ arquivo: string, descricao: string, extensao: string }>>();
    const [getFormularioDataDaProposta, setFormularioDataDaProposta] = useState<Date>(new Date(new Date().toDateString()));
    const [getFormularioExibirDataDaProposta, setFormularioExibirDataDaProposta] = useState<boolean>(false);
    const [getFormularioAnexoFichaDeNegociacao, setFormularioAnexoFichaDeNegociacao] = useState<File>();
    const [getFormularioAnexoFichaDeAtendimento, setFormularioAnexoFichaDeAtendimento] = useState<File>();
    const [getFormularioAnexoDocumentacaoComplementar, setFormularioAnexoDocumentacaoComplementar] = useState<File>();
    const [getItemPickerFinalidadeDeCompra, setItemPickerFinalidadeDeCompra] = useState<{ id: number, descricao: string }>();
    const [getListaPickerFinalidadeDeCompra, setListaPickerFinalidadeDeCompra] = useState<Array<{ id: number, descricao: string }>>();
    const PickerFinalidadeDeCompra = Select.ofType<{ id: number, descricao: string }>();
    const [getLimpandoDocumento, setLimpandoDocumento] = useStateWithCallbackLazy<boolean>(false);
    const [getCollapse, setCollapse] = useState(false);
    const timelineLoaded = useRef<boolean>(false);
    const [getUnidadeVendida, setUnidadeVendida] = useState<boolean>(false);
    const [getExibirPermitirEnvioComAnexoPendente, setExibirPermitirEnvioComAnexoPendente] = useState<boolean>();
    const [getPermitirEnvioComAnexoPendente, setPermitirEnvioComAnexoPendente] = useState<boolean>();
    useEffect(() => {
        if (getPermitirEnvioComAnexoPendente == true) {
            setPermitirEnvioComAnexoPendente(undefined);
            EnviarPropostaDeVenda(1);
        }
    }, [getPermitirEnvioComAnexoPendente])
    const [getExibirPermitirParcelasNaoSubsequentes, setExibirPermitirParcelasNaoSubsequentes] = useState<boolean>();
    const [getPermitirParcelasNaoSubsequentes, setPermitirParcelasNaoSubsequentes] = useState<boolean>();
    useEffect(() => {
        if (getPermitirParcelasNaoSubsequentes == true) {
            setPermitirParcelasNaoSubsequentes(undefined);
            EnviarPropostaDeVenda(2);
        }
    }, [getPermitirParcelasNaoSubsequentes])
    const [getExibirPermitirEmailInvalido, setExibirPermitirEmailInvalido] = useState<boolean>();
    const [getPermitirEmailInvalido, setPermitirEmailInvalido] = useState<boolean>();
    useEffect(() => {
        if (getPermitirEmailInvalido == true) {
            setPermitirEmailInvalido(undefined);
            EnviarPropostaDeVenda(3);
        }
    }, [getPermitirEmailInvalido])
    /* #endregion */

    /* #region  Funções  */
    function PV(I: any, NPER: any, PMT: any) {
        return -(PMT * (Math.pow(1 + I, NPER) - 1) / (I * Math.pow(1 + I, NPER)));
    }
    function NPER(I: any, PMT: any, PV: any) {
        var type = 0;
        var FV = 0;
        I = eval(I);
        return Math.round(Math.log((PMT * (1 + I * type) - FV * I) / (PV * I + PMT * (1 + I * type))) / Math.log(1 + I));
    }
    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem ao cadastro do primeiro comprador
     **/
    function QtdeDeIntermediarias(IntermediariaVencimento: Date | null | undefined): number {
        let ParcelaVencimento = (getFormularioListaDeParcelas && getFormularioListaDeParcelas.length > 0) ? getFormularioListaDeParcelas.reduce((total, parcela) => total.Vencimento < parcela.Vencimento ? total : parcela).Vencimento : new Date();
        IntermediariaVencimento = IntermediariaVencimento || ParcelaVencimento;
        let ParcelaQtde = Math.floor((getFormularioListaDeParcelas.reduce((total, parcela) => (total + parcela.Quantidade), 0)) / 12);
        {
            return ParcelaQtde + Math.floor(((ParcelaVencimento.getMonth() + 12 * ParcelaVencimento.getFullYear()) - (IntermediariaVencimento.getMonth() + 12 * IntermediariaVencimento.getFullYear())) / 12);
        }
    };

    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem ao cadastro do primeiro comprador
     **/
    function QtdeDeIntermediariasModeloDeVendas(IntermediariaVencimento: Date | null | undefined, Parcelas: Array<{ Quantidade: number, Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>): number {
        let ParcelaVencimento = (Parcelas && Parcelas.length > 0) ? Parcelas.reduce((total, parcela) => total.Vencimento < parcela.Vencimento ? total : parcela).Vencimento : new Date();
        IntermediariaVencimento = IntermediariaVencimento || ParcelaVencimento;
        let ParcelaQtde = Math.floor((Parcelas.reduce((total, parcela) => (total + parcela.Quantidade), 0)) / 12);
        {
            return ParcelaQtde + Math.floor(((ParcelaVencimento.getMonth() + 12 * ParcelaVencimento.getFullYear()) - (IntermediariaVencimento.getMonth() + 12 * IntermediariaVencimento.getFullYear())) / 12);
        }
    };
    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem ao cadastro do primeiro comprador
     **/
    async function ValidarLead(): Promise<Boolean> {
        if (CPF.DesformatarTexto(getFormularioCPF).length <= 11) {
            if (!getFormularioNome) {
                NotificacaoInterna.ExibirNotificacao("Nome não preenchido", "Informe o nome do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioEmail) {
                NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioCPF) {
                NotificacaoInterna.ExibirNotificacao("CPF não preenchido", "Informe o CPF do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            else {
                if (getFormularioCPF == getFormularioConjugeCPF ||
                    getFormularioCPF == getFormulario02CompradorCPF ||
                    getFormularioCPF == getFormulario02CompradorConjugeCPF) {
                    NotificacaoInterna.ExibirNotificacao("CPF já informado anteriormente", "Não é possivel adicionar o mesmo comprador mais de uma vez", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
            }
            if (getFormularioCPF && CPF.TextoValido(getFormularioCPF) != true) {
                NotificacaoInterna.ExibirNotificacao("CPF invalido", "Verifique se o CPF do cliente foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getItemPickerFormularioNacionalidade) {
                NotificacaoInterna.ExibirNotificacao("Nacionalidade não definida", "Informe a nacionalidade do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (false && !getItemPickerFormularioNaturalidade) {
                NotificacaoInterna.ExibirNotificacao("Naturalidade não definida", "Informe a naturalidade do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioTelefoneComercial && !getFormularioTelefoneCelular) {
                NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao menos um número para contato com o cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (((getFormularioTelefoneCelular ?? "") != "" || (getFormularioTelefoneComercial ?? "") != "") && getFormularioTelefoneCelular == getFormularioTelefoneComercial) {
                NotificacaoInterna.ExibirNotificacao("Telefone repetido", "Confira as informações de contato do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (((getFormularioConjugeTelefoneCelular ?? "") != "" || (getFormularioConjugeTelefoneComercial ?? "") != "") && getFormularioConjugeTelefoneCelular == getFormularioConjugeTelefoneComercial) {
                NotificacaoInterna.ExibirNotificacao("Telefone repetido", "Confira as informações de contato do conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (false && (getFormularioPai ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Pai não definido", "É necessário preencher o nome do pai do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (false && (getFormularioMae ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Mãe não definido", "É necessário preencher o nome da mãe do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (false && (getFormularioCNH ?? "") != "" && (getFormularioCNHExpedicao || undefined) != undefined && (getFormularioCNHUF ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Dados da CNH não preenchidos incorretamente", "É necessário preencher o número da CNH, data de expedição e UF do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (((getFormularioConjugeNome ?? "") != "" || (getFormularioConjugeEmail ?? "") != "" || (getFormularioConjugeCPF ?? "") != "") && !((getFormularioConjugeNome ?? "") != "" && (getFormularioConjugeEmail ?? "") != "" && (getFormularioConjugeCPF ?? "") != "")) {
                NotificacaoInterna.ExibirNotificacao("Conjugê preenchido incorretamente", "É necessário preencher o nome, email e CPF do conjugê ou deixar todos os campos em branco!", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (((getFormularioConjugeNome ?? "") != "" && (getFormularioConjugeEmail ?? "") != "" && (getFormularioConjugeCPF ?? "") != "") && !getFormularioConjugeTelefoneComercial && !getFormularioConjugeTelefoneCelular) {
                NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao menos um número para contato com o conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if ((getFormularioConjugeCPF ?? "") != "" && CPF.TextoValido(getFormularioConjugeCPF) != true) {
                NotificacaoInterna.ExibirNotificacao("CPF invalido", "Verifique se o CPF do conjugê foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (false && (getFormularioConjugePai ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Pai não definido", "É necessário preencher o nome do pai do conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (false && (getFormularioConjugeMae ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Mãe não definido", "É necessário preencher o nome da mãe do conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (false && (getFormularioConjugeCNH ?? "") != "" && (getFormularioConjugeCNHExpedicao || undefined) != undefined && (getFormularioConjugeCNHUF ?? "") != "") {
                NotificacaoInterna.ExibirNotificacao("Dados da CNH não preenchidos incorretamente", "É necessário preencher o número da CNH, data de expedição e UF do conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioEstadoCivil) {
                NotificacaoInterna.ExibirNotificacao("Estado civil não informado", "Informe o estado civil do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioCEP || CEP.DesformatarTexto(getFormularioCEP).length != 8) {
                NotificacaoInterna.ExibirNotificacao("CEP inválido", "Certifique-se de que o CEP digitado está correto", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioLogradouro) {
                NotificacaoInterna.ExibirNotificacao("Logradouro não preenchido", "Informe o logradouro do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioBairro) {
                NotificacaoInterna.ExibirNotificacao("Bairro não preenchido", "Informe o bairro do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioCidade) {
                NotificacaoInterna.ExibirNotificacao("Cidade não preenchido", "Informe a cidade do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioUF) {
                NotificacaoInterna.ExibirNotificacao("UF não preenchido", "Informe a UF do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            return true;
        }
        else {
            if (!getFormularioNome) {
                NotificacaoInterna.ExibirNotificacao("Razão social não preenchida", "Informe a razão social do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioEmail) {
                NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioCPF) {
                NotificacaoInterna.ExibirNotificacao("CNPJ não preenchido", "Informe o CNPJ do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            else {
                if (getFormularioCPF == getFormularioConjugeCPF ||
                    getFormularioCPF == getFormulario02CompradorCPF ||
                    getFormularioCPF == getFormulario02CompradorConjugeCPF) {
                    NotificacaoInterna.ExibirNotificacao("CNPJ já informado anteriormente", "Não é possivel adicionar o mesmo comprador mais de uma vez", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
            }
            if (getFormularioCPF && CNPJ.TextoValido(getFormularioCPF) != true) {
                NotificacaoInterna.ExibirNotificacao("CNPJ invalido", "Verifique se o CNPJ do cliente foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            if (!getFormularioTelefoneComercial) {
                NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao número de contato comercial", NotificacaoInterna.TipoDeNotificacao.Erro);
                return false;
            }
            return true;
        }
    };
    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem ao cadastro do segundo comprador
     **/
    async function Validar02Lead(): Promise<Boolean> {
        if (getFormularioExibir02Comprador == true) {
            if (CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11) {
                if (!getFormulario02CompradorNome) {
                    NotificacaoInterna.ExibirNotificacao("Nome não preenchido", "Informe o nome do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorEmail) {
                    NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorCPF) {
                    NotificacaoInterna.ExibirNotificacao("CPF não preenchido", "Informe o CPF do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                else {
                    if (getFormulario02CompradorCPF == getFormularioConjugeCPF ||
                        getFormulario02CompradorCPF == getFormularioCPF ||
                        getFormulario02CompradorCPF == getFormulario02CompradorConjugeCPF) {
                        NotificacaoInterna.ExibirNotificacao("CPF já informado anteriormente", "Não é possivel adicionar o mesmo comprador mais de uma vez", NotificacaoInterna.TipoDeNotificacao.Erro);
                        return false;
                    }
                }
                if (CPF.TextoValido(getFormulario02CompradorCPF) != true) {
                    NotificacaoInterna.ExibirNotificacao("CPF invalido", "Verifique se o CPF do 02º cliente foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getItemPickerFormulario02CompradorNacionalidade) {
                    NotificacaoInterna.ExibirNotificacao("Nacionalidade não definida", "Informe a nacionalidade do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorTelefoneComercial && !getFormulario02CompradorTelefoneCelular) {
                    NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao menos um número para contato com o 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (((getFormulario02CompradorTelefoneCelular ?? "") != "" || (getFormulario02CompradorTelefoneComercial ?? "") != "") && getFormulario02CompradorTelefoneCelular == getFormulario02CompradorTelefoneComercial) {
                    NotificacaoInterna.ExibirNotificacao("Telefone repetido", "Confira as informações de contato do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (((getFormulario02CompradorConjugeTelefoneCelular ?? "") != "" || (getFormulario02CompradorConjugeTelefoneComercial ?? "") != "") && getFormulario02CompradorConjugeTelefoneCelular == getFormulario02CompradorConjugeTelefoneComercial) {
                    NotificacaoInterna.ExibirNotificacao("Telefone repetido", "Confira as informações de contato do conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (((getFormulario02CompradorConjugeNome ?? "") != "" || (getFormulario02CompradorConjugeEmail ?? "") != "" || (getFormulario02CompradorConjugeCPF ?? "") != "") && !((getFormulario02CompradorConjugeNome ?? "") != "" && (getFormulario02CompradorConjugeEmail ?? "") != "" && (getFormulario02CompradorConjugeCPF ?? "") != "")) {
                    NotificacaoInterna.ExibirNotificacao("Conjugê preenchido incorretamente", "É necessário preencher o nome, email e CPF do conjugê ou deixar todos os campos em branco!", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorConjugeTelefoneComercial && !getFormulario02CompradorConjugeTelefoneCelular) {
                    NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao menos um número para contato com o conjugê", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if ((getFormulario02CompradorConjugeCPF ?? "") != "" && CPF.TextoValido(getFormulario02CompradorConjugeCPF) != true) {
                    NotificacaoInterna.ExibirNotificacao("CPF invalido", "Verifique se o CPF do conjugê foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorEstadoCivil) {
                    NotificacaoInterna.ExibirNotificacao("Estado civil não informado", "Informe o estado civil do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorCEP || CEP.DesformatarTexto(getFormulario02CompradorCEP).length != 8) {
                    NotificacaoInterna.ExibirNotificacao("CEP inválido", "Certifique-se de que o CEP digitado está correto", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorLogradouro) {
                    NotificacaoInterna.ExibirNotificacao("Logradouro não preenchido", "Informe o logradouro do endereço do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorBairro) {
                    NotificacaoInterna.ExibirNotificacao("Bairro não preenchido", "Informe o bairro do endereço do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorCidade) {
                    NotificacaoInterna.ExibirNotificacao("Cidade não preenchido", "Informe a cidade do endereço do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorUF) {
                    NotificacaoInterna.ExibirNotificacao("UF não preenchido", "Informe a UF do endereço do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
            }
            else {
                if (!getFormulario02CompradorNome) {
                    NotificacaoInterna.ExibirNotificacao("Razão social não preenchida", "Informe a razão social do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorEmail) {
                    NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorCPF) {
                    NotificacaoInterna.ExibirNotificacao("CNPJ não preenchido", "Informe o CNPJ do 02º cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                else {
                    if (getFormulario02CompradorCPF == getFormularioConjugeCPF ||
                        getFormulario02CompradorCPF == getFormularioCPF ||
                        getFormulario02CompradorCPF == getFormulario02CompradorConjugeCPF) {
                        NotificacaoInterna.ExibirNotificacao("CNPJ já informado anteriormente", "Não é possivel adicionar o mesmo comprador mais de uma vez", NotificacaoInterna.TipoDeNotificacao.Erro);
                        return false;
                    }
                }
                if (getFormularioCPF && CPF.TextoValido(getFormulario02CompradorCPF) != true) {
                    NotificacaoInterna.ExibirNotificacao("CNPJ invalido", "Verifique se o CNPJ do 02º cliente foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
                if (!getFormulario02CompradorTelefoneComercial) {
                    NotificacaoInterna.ExibirNotificacao("Nenhum número de contato informado", "Informe ao número de contato comercial", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return false;
                }
            }
        }
        return true;
    };
    /* Sobre esta função *//**
     * Realiza o cadastro do primeiro comprador
     **/
    async function AdicionarLead(): Promise<Objeto.Lead | null> {
        var ObjetoLead = {} as Objeto.Lead;
        if (CPF.DesformatarTexto(getFormularioCPF).length <= 11) {
            ObjetoLead = {
                id: 0,
                cpf: getFormularioCPF,
                rg: {
                    numero: getFormularioRG,
                    orgaoemissor: getFormularioRGOrgaoEmissor,
                    uf: getFormularioRGUF
                },
                cnhNumero: getFormularioCNH,
                cnhExpedicao: getFormularioCNHExpedicao,
                cnhUF: getFormularioCNHUF,
                pai: getFormularioPai,
                mae: getFormularioMae,
                naturalidade: getItemPickerFormularioNaturalidade?.id,
                dataDeNascimento: getFormularioDataDeNascimento,
                ocupacao: {
                    nome: getFormularioProfissao
                },
                nacionalidade: getItemPickerFormularioNacionalidade,
                nome: getFormularioNome,
                emails: [
                    {
                        classificacao: 1,
                        descricao: getFormularioEmail
                    }
                ],
                telefones: [
                    !getFormularioTelefoneCelular ? null : {
                        classificacao: 1,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioTelefoneCelular)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioTelefoneCelular)),
                        observacao: ""
                    },
                    !getFormularioTelefoneComercial ? null : {
                        classificacao: 2,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioTelefoneComercial)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioTelefoneComercial)),
                        observacao: ""
                    }
                ],
                endereco: {
                    classificacao: 0,
                    cep: getFormularioCEP,
                    logradouro: getFormularioLogradouro,
                    numero: getFormularioNumeroDeEndereco,
                    complemento: getFormularioComplemento,
                    bairro: getFormularioBairro,
                    cidade: getFormularioCidade,
                    uf: getFormularioUF
                },
                estadoCivil: getFormularioEstadoCivil?.id,
                dependentes: [
                    (getFormularioEstadoCivil?.id != 2 && getFormularioEstadoCivil?.id != 7) ? null : {
                        classificacao: 2,
                        cpf: getFormularioConjugeCPF,
                        nome: getFormularioConjugeNome,
                        dataDeNascimento: getFormularioConjugeDataDeNascimento,
                        rg: {
                            numero: getFormularioConjugeRG,
                            orgaoemissor: getFormularioConjugeRGOrgaoEmissor,
                            uf: getFormularioConjugeRGUF
                        },
                        cnhNumero: getFormularioConjugeCNH,
                        cnhExpedicao: getFormularioConjugeCNHExpedicao,
                        cnhUF: getFormularioConjugeCNHUF,
                        pai: getFormularioConjugePai,
                        mae: getFormularioConjugeMae,
                        naturalidade: getItemPickerFormularioConjugeNaturalidade?.id,
                        ocupacao: {
                            nome: getFormularioConjugeProfissao
                        },
                        nacionalidade: getItemPickerFormularioConjugeNacionalidade,
                        email: getFormularioConjugeEmail,
                        telefones: [
                            !getFormularioConjugeTelefoneCelular ? null : {
                                classificacao: 1,
                                ddi: "55",
                                ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioConjugeTelefoneCelular)),
                                numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioConjugeTelefoneCelular)),
                                observacao: ""
                            },
                            !getFormularioConjugeTelefoneComercial ? null : {
                                classificacao: 2,
                                ddi: "55",
                                ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioConjugeTelefoneComercial)),
                                numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioConjugeTelefoneComercial)),
                                observacao: ""
                            }
                        ],
                    } as Objeto.Dependente
                ],
                localDeCaptacao: getFormularioLocalDeCaptacao
            } as Objeto.Lead;
            ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
            ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;

            try {
                ObjetoLead.dependentes?.forEach(Dependente => {
                    Dependente.telefones = Dependente.telefones?.filter((Item) => Item) ?? null;
                });
            }
            catch { }
        }
        else {
            ObjetoLead = {
                id: 0,
                cpf: getFormularioCPF,
                nome: getFormularioNome,
                emails: [
                    {
                        classificacao: 1,
                        descricao: getFormularioEmail
                    }
                ],
                telefones: [
                    !getFormularioTelefoneCelular ? null : {
                        classificacao: 1,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioTelefoneCelular)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioTelefoneCelular)),
                        observacao: ""
                    },
                    !getFormularioTelefoneComercial ? null : {
                        classificacao: 2,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormularioTelefoneComercial)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormularioTelefoneComercial)),
                        observacao: ""
                    }
                ],
                endereco: {
                    classificacao: 0,
                    cep: getFormularioCEP,
                    logradouro: getFormularioLogradouro,
                    numero: getFormularioNumeroDeEndereco,
                    complemento: getFormularioComplemento,
                    bairro: getFormularioBairro,
                    cidade: getFormularioCidade,
                    uf: getFormularioUF
                },
                localDeCaptacao: getFormularioLocalDeCaptacao
            } as Objeto.Lead;
            ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
            ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;
        }
        console.log(JSON.stringify(ObjetoLead));
        var Response = await Lead.Post(ContextSignIn.getContext().token, ObjetoLead);
        if (Math.floor(Response.status / 100) == 2) {
            NotificacaoInterna.ExibirNotificacao("Lead cadastrado com sucesso", "Registro devidamente cadastrado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao cadastrar o lead", "😱 Houve uma falha ao cadastrar o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
        }
        ObjetoLead.id = Response.data.id;
        return (Math.floor(Response.status / 100) == 2) ? ObjetoLead : null;
    };
    /* Sobre esta função *//**
     * Realiza o cadastro do segundo comprador
     **/
    async function Adicionar02Lead(): Promise<Objeto.Lead | null> {
        var ObjetoLead = {} as Objeto.Lead;

        if (CPF.DesformatarTexto(getFormularioCPF).length <= 11) {
            ObjetoLead = {
                id: 0,
                cpf: getFormulario02CompradorCPF,
                rg: {
                    numero: getFormulario02CompradorRG,
                    orgaoemissor: getFormulario02CompradorRGOrgaoEmissor,
                    uf: getFormulario02CompradorRGUF
                },
                cnhNumero: getFormulario02CompradorCNH,
                cnhExpedicao: getFormulario02CompradorCNHExpedicao,
                cnhUF: getFormulario02CompradorCNHUF,
                pai: getFormulario02CompradorPai,
                mae: getFormulario02CompradorMae,
                naturalidade: getItemPickerFormulario02CompradorNaturalidade?.id,
                dataDeNascimento: getFormulario02CompradorDataDeNascimento,
                ocupacao: {
                    nome: getFormulario02CompradorProfissao
                },
                nacionalidade: getItemPickerFormulario02CompradorNacionalidade,
                nome: getFormulario02CompradorNome,
                emails: [
                    {
                        classificacao: 1,
                        descricao: getFormulario02CompradorEmail
                    }
                ],
                telefones: [
                    !getFormulario02CompradorTelefoneCelular ? null : {
                        classificacao: 1,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneCelular)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneCelular)),
                        observacao: ""
                    },
                    !getFormulario02CompradorTelefoneComercial ? null : {
                        classificacao: 2,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneComercial)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneComercial)),
                        observacao: ""
                    }
                ],
                endereco: {
                    classificacao: 0,
                    cep: getFormulario02CompradorCEP,
                    logradouro: getFormulario02CompradorLogradouro,
                    numero: getFormulario02CompradorNumeroDeEndereco,
                    complemento: getFormulario02CompradorComplemento,
                    bairro: getFormulario02CompradorBairro,
                    cidade: getFormulario02CompradorCidade,
                    uf: getFormulario02CompradorUF
                },
                estadoCivil: getFormulario02CompradorEstadoCivil?.id,
                dependentes: [
                    (getFormulario02CompradorEstadoCivil?.id != 2 && getFormulario02CompradorEstadoCivil?.id != 7) ? null : {
                        classificacao: 2,
                        cpf: getFormulario02CompradorConjugeCPF,
                        nome: getFormulario02CompradorConjugeNome,
                        dataDeNascimento: getFormulario02CompradorConjugeDataDeNascimento,
                        rg: {
                            numero: getFormulario02CompradorConjugeRG,
                            orgaoemissor: getFormulario02CompradorConjugeRGOrgaoEmissor,
                            uf: getFormulario02CompradorConjugeRGUF
                        },
                        cnhNumero: getFormulario02CompradorConjugeCNH,
                        cnhExpedicao: getFormulario02CompradorConjugeCNHExpedicao,
                        cnhUF: getFormulario02CompradorConjugeCNHUF,
                        pai: getFormulario02CompradorConjugePai,
                        mae: getFormulario02CompradorConjugeMae,
                        naturalidade: getItemPickerFormulario02CompradorConjugeNaturalidade?.id,
                        ocupacao: {
                            nome: getFormulario02CompradorConjugeProfissao
                        },
                        nacionalidade: getItemPickerFormulario02CompradorConjugeNacionalidade,
                        email: getFormulario02CompradorConjugeEmail,
                        telefones: [
                            !getFormulario02CompradorConjugeTelefoneCelular ? null : {
                                classificacao: 1,
                                ddi: "55",
                                ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorConjugeTelefoneCelular)),
                                numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorConjugeTelefoneCelular)),
                                observacao: ""
                            },
                            !getFormulario02CompradorConjugeTelefoneComercial ? null : {
                                classificacao: 2,
                                ddi: "55",
                                ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorConjugeTelefoneComercial)),
                                numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorConjugeTelefoneComercial)),
                                observacao: ""
                            }
                        ],
                    } as Objeto.Dependente
                ],
                localDeCaptacao: getFormularioLocalDeCaptacao
            } as Objeto.Lead;
            ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
            ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;
            try {
                ObjetoLead.dependentes?.forEach(Dependente => {
                    Dependente.telefones = Dependente.telefones?.filter((Item) => Item) ?? null;
                });
            }
            catch { }
        }
        else {
            ObjetoLead = {
                id: 0,
                cpf: getFormulario02CompradorCPF,
                nome: getFormulario02CompradorNome,
                emails: [
                    {
                        classificacao: 1,
                        descricao: getFormulario02CompradorEmail
                    }
                ],
                telefones: [
                    !getFormulario02CompradorTelefoneCelular ? null : {
                        classificacao: 1,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneCelular)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneCelular)),
                        observacao: ""
                    },
                    !getFormulario02CompradorTelefoneComercial ? null : {
                        classificacao: 2,
                        ddi: "55",
                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneComercial)),
                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(getFormulario02CompradorTelefoneComercial)),
                        observacao: ""
                    }
                ],
                endereco: {
                    classificacao: 0,
                    cep: getFormulario02CompradorCEP,
                    logradouro: getFormulario02CompradorLogradouro,
                    numero: getFormulario02CompradorNumeroDeEndereco,
                    complemento: getFormulario02CompradorComplemento,
                    bairro: getFormulario02CompradorBairro,
                    cidade: getFormulario02CompradorCidade,
                    uf: getFormulario02CompradorUF
                },
                localDeCaptacao: getFormularioLocalDeCaptacao
            } as Objeto.Lead;
            ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
            ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;
        }
        console.log(JSON.stringify(ObjetoLead));
        var Response = await Lead.Post(ContextSignIn.getContext().token, ObjetoLead);
        if (Math.floor(Response.status / 100) == 2) {
            NotificacaoInterna.ExibirNotificacao("02ª Lead cadastrado com sucesso", "Registro devidamente cadastrado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao cadastrar o 02ª lead", "😱 Houve uma falha ao cadastrar o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
        }

        ObjetoLead.id = Response.data.id;
        return (Math.floor(Response.status / 100) == 2) ? ObjetoLead : null;
    };
    /* Sobre esta função *//**
     * Realiza o cadastro de um novo prospect
     **/
    async function AdicionarProspect(Lead: Objeto.Lead): Promise<Objeto.Prospect | null> {
        if (await ValidarLead() == true) {
            var Response = await Prospect.Post(ContextSignIn.getContext().token, Lead);
            if (Math.floor(Response.status / 100) == 2) {
                NotificacaoInterna.ExibirNotificacao("Lead convertido em prospect", "Registro devidamente cadastrado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
            }
            else {
                NotificacaoInterna.ExibirNotificacao("Falha ao converter lead em prospect", "😱 Houve uma falha ao converter o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
            }
            return (Math.floor(Response.status / 100) == 2) ? Lead as Objeto.Prospect : null;
        }
        else {
            return null;
        }
    };
    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem a proposta de venda
     **/
    async function ValidarPropostaDeVenda(): Promise<Boolean> {
        if (!getItemPickerCentroDeCusto) {
            NotificacaoInterna.ExibirNotificacao("Empreendimento não selecionada", "Informe o empreendimento", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItemPickerIdentificador) {
            NotificacaoInterna.ExibirNotificacao("Unidade não selecionada", "Informe a unidade desejada", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItemPickerCondicaoDaTabelaDeVenda) {
            NotificacaoInterna.ExibirNotificacao("Nenhum modelo de venda selecionado", "Selecione o modelo de vendas", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        {
            let ValorTotalEntrada = getListaCondicaoDaTabelaDeVenda.find((Item) => Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((Item) => Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0;
            let ValorSomadoEntrada = 0;
            let VencimentoInvalido = 0;
            let MeioDePagamentoInvalido = 0;
            getFormularioListaDeEntradas.forEach((Item) => {
                ValorSomadoEntrada += Item.Valor;
                VencimentoInvalido += ((Item.Vencimento < getFormularioDataDaProposta) ? 1 : 0);
                MeioDePagamentoInvalido += ((Item.MeioDePagamento == "") ? 1 : 0);
            });
            if (ValorTotalEntrada.toFixed(2) != ValorSomadoEntrada.toFixed(2)) {
                NotificacaoInterna.ExibirNotificacao("Valor gerado de entrada diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(ValorSomadoEntrada) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(ValorTotalEntrada), NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (VencimentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (MeioDePagamentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existe meios de pagamento referente a entrada que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
        }
        {
            let ValorTotalSinal = getListaCondicaoDaTabelaDeVenda.find((Item) => Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((Item) => Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0;
            let ValorSomadoSinal = 0;
            let VencimentoInvalido = 0;
            let MeioDePagamentoInvalido = 0;

            getFormularioListaDeSinais.forEach((Item) => {
                ValorSomadoSinal += Item.Valor;
                VencimentoInvalido += ((Item.Vencimento < getFormularioDataDaProposta) ? 1 : 0);
                MeioDePagamentoInvalido += ((Item.MeioDePagamento == "") ? 1 : 0);
            });
            if (getItemPickerCondicaoDaTabelaDeVenda.descricao.toUpperCase().includes("VISTA") == false && ValorTotalSinal.toFixed(2) != ValorSomadoSinal.toFixed(2)) {
                NotificacaoInterna.ExibirNotificacao("Valor gerado de sinal diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(ValorSomadoSinal) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(ValorTotalSinal), NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (VencimentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (MeioDePagamentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existem meios de pagamento referente ao sinal que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
        }
        {
            let ValorTotalIntermediaria = getListaCondicaoDaTabelaDeVenda.find((Item) => Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((Item) => Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.valorTotal ?? 0
            let ValorSomadoIntermediaria = 0;
            let VencimentoInvalido = 0;
            let MeioDePagamentoInvalido = 0;
            getFormularioListaDeIntermediarias.forEach((Item) => {
                ValorSomadoIntermediaria += Item.Valor * QtdeDeIntermediarias(Item.Vencimento);
                VencimentoInvalido += ((Item.Vencimento < getFormularioDataDaProposta) ? 1 : 0);
                MeioDePagamentoInvalido += ((Item.MeioDePagamento == "") ? 1 : 0);
            });
            if (getItemPickerCondicaoDaTabelaDeVenda?.descricao.toLowerCase().includes("vista") == false
                && (ValorTotalIntermediaria.toFixed(2) != ValorSomadoIntermediaria.toFixed(2))) {
                NotificacaoInterna.ExibirNotificacao("Valor gerado de intermediárias diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(ValorSomadoIntermediaria) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(ValorTotalIntermediaria), NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (VencimentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (MeioDePagamentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existem meios de pagamento referente a intermediária que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
        }
        {
            let ValorTotalParcela = getListaCondicaoDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((Item) => Item.descricao == (getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? ""))?.valorTotal ?? 0
            let ValorSomadoParcela = 0;
            let VencimentoInvalido = 0;
            let MeioDePagamentoInvalido = 0;
            getFormularioListaDeParcelas.forEach((Item) => {
                ValorSomadoParcela += Item.Valor * Item.Quantidade;
                VencimentoInvalido += ((Item.Vencimento < getFormularioDataDaProposta) ? 1 : 0);
                MeioDePagamentoInvalido += ((Item.MeioDePagamento == "") ? 1 : 0);
            });
            if (getItemPickerCondicaoDaTabelaDeVenda?.descricao.toLowerCase().includes("vista") == false
                && ValorTotalParcela.toFixed(2) != ValorSomadoParcela.toFixed(2)) {
                NotificacaoInterna.ExibirNotificacao("Valor gerado de saldo diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(ValorSomadoParcela) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(ValorTotalParcela), NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (VencimentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
            if (MeioDePagamentoInvalido > 0) {
                NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existem meios de pagamento referente ao saldo que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return false;
            }
        }
        if (!getFormularioIdCasal && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4) {
            NotificacaoInterna.ExibirNotificacao(`Id casal não informado`, "Preencha todos os campos para prosseguir", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItemPickerLiner?.cpf && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4) {
            NotificacaoInterna.ExibirNotificacao("Liner não informado", "Informe o Liner", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItemPickerPEP?.cpf && !getItemPickerCloser?.cpf && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4) {
            NotificacaoInterna.ExibirNotificacao("Vendedor não informado", "Informe o PEP ou Closer", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItemPickerFinalidadeDeCompra) {
            NotificacaoInterna.ExibirNotificacao("Nenhuma finalidade de compra", "Selecione a finalidade de compra", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        return true;
    };
    /* Sobre esta função *//**
     * Realiza o cadastro de uma nova proposta de venda
    * @param {Array<Objeto.Prospect>} Prospects Informe os dados do prospect
     **/
    async function AdicionarPropostaDeVenda(Prospects: Array<Objeto.Prospect>): Promise<Objeto.PropostaDeVenda | null> {
        var PropostaDeVenda = {
            empresa: getItemPickerIdentificador?.empresa ?? {} as Objeto.Empresa,
            centroDeCusto: getItemPickerIdentificador?.centroDeCusto ?? {} as Objeto.CentroDeCusto,
            numero: 0,
            contratoCEF: getFormularioIdCasal,
            dataDaVenda: getFormularioDataDaProposta,
            finalidadeDaCompra: getItemPickerFinalidadeDeCompra?.id,
            prospects: Prospects,
            modeloDeVenda: {} as Objeto.ModeloDeVenda,
            identificador: getItemPickerIdentificador ?? {} as Objeto.Identificador,
            titulosDeCorretagem: new Array<Objeto.TituloDeCorretagem>(),
            titulosDeIntermediacao: new Array<Objeto.TituloDeIntermediacao>(),
            titulosDeIntermediaria: new Array<Objeto.TituloDeIntermediaria>(),
            titulosDeSinal: new Array<Objeto.TituloDeSinal>(),
            titulosDeEntrada: new Array<Objeto.TituloDeEntrada>(),
            titulosDeParcela: new Array<Objeto.TituloDeParcela>(),
            titulosConsolidados: new Array<Objeto.Titulo>(),
            salaDeVenda: getItemPickerSala?.cidade ?? " ",
            salaDeVendaNome: getItemPickerSala?.descricao ?? " ",
            estruturaDeComissao: new Array<Objeto.Comissionado>()
        } as Objeto.PropostaDeVenda;
        var Index = 0;
        var ObjetoTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, PropostaDeVenda.empresa.id, PropostaDeVenda.centroDeCusto.sigla, PropostaDeVenda.identificador.local.id, PropostaDeVenda.identificador.subLocal.id)).data;
        ObjetoTabelaDeVenda.modeloDeVenda.modeloDeVendaExterno = getItemPickerCondicaoDaTabelaDeVenda?.descricao;
        PropostaDeVenda.modeloDeVenda = ObjetoTabelaDeVenda.modeloDeVenda;
        for (let index = 0; index < getFormularioEntradaQtde; index++) {
            PropostaDeVenda.titulosDeEntrada.push({
                id: index + 1,
                vencimento: getFormularioListaDeEntradas[index].Vencimento,
                valor: getFormularioListaDeEntradas[index].Valor,
                formaDePagamento: getFormularioListaDeEntradas[index].MeioDePagamento,
                banco: getFormularioListaDeEntradas[index].Banco?.Valor,
                agencia: getFormularioListaDeEntradas[index].Agencia,
                conta: getFormularioListaDeEntradas[index].Conta,
                digitoDaConta: getFormularioListaDeEntradas[index].DigitoDaConta,
                titular: getFormularioListaDeEntradas[index].Titular,
                numeroCheque: getFormularioListaDeEntradas[index].NumeroCheque,
                maquina: getFormularioListaDeEntradas[index].Maquina,
                bandeira: getFormularioListaDeEntradas[index].Bandeira,
                digitoCartao: getFormularioListaDeEntradas[index].DigitoCartao,
                operacao: getFormularioListaDeEntradas[index].Operacao,
                nsu: getFormularioListaDeEntradas[index].NSU,
                numeroDaOperacao: getFormularioListaDeEntradas[index].NumeroDaOperacao,
                terminal: getFormularioListaDeEntradas[index].Terminal,
                codAutorizacao: getFormularioListaDeEntradas[index]?.CodAutorizacao
            } as Objeto.TituloDeEntrada);
            PropostaDeVenda.titulosConsolidados.push({
                classificacao: ObjetoTabelaDeVenda.classificacoesDosTitulosDaTabelaDeVenda.find((Item) => Item.classificacao.id == 3)?.classificacao,
                numero: index + 1,
                numeroDeGeracao: ++Index,
                dataDeVencimento: getFormularioListaDeEntradas[index].Vencimento,
                grupo: 1,
                principal: getFormularioListaDeEntradas[index].Valor,
                juros: 0,
                correcao: 0,
                multa: 0,
                jurosPorAtraso: 0,
                correcaoPorAtraso: 0,
                acrescimoAvulso: 0,
                descontoAvulso: 0,
                descontoDeAntecipacao: 0,
                descontoDePontualidade: 0
            } as Objeto.Titulo);
        }
        for (let index = 0; index < getFormularioSinalQtde; index++) {
            PropostaDeVenda.titulosDeSinal.push({
                id: index + 1,
                vencimento: getFormularioListaDeSinais[index].Vencimento,
                valor: getFormularioListaDeSinais[index].Valor,
                formaDePagamento: getFormularioListaDeSinais[index].MeioDePagamento,
                banco: getFormularioListaDeSinais[index].Banco?.Valor,
                agencia: getFormularioListaDeSinais[index].Agencia,
                conta: getFormularioListaDeSinais[index].Conta,
                digitoDaConta: getFormularioListaDeSinais[index].DigitoDaConta,
                titular: getFormularioListaDeSinais[index].Titular,
                numeroCheque: getFormularioListaDeSinais[index].NumeroCheque,
                maquina: getFormularioListaDeSinais[index].Maquina,
                bandeira: getFormularioListaDeSinais[index].Bandeira,
                digitoCartao: getFormularioListaDeSinais[index].DigitoCartao,
                operacao: getFormularioListaDeSinais[index].Operacao,
                nsu: getFormularioListaDeSinais[index].NSU,
                numeroDaOperacao: getFormularioListaDeSinais[index].NumeroDaOperacao,
                terminal: getFormularioListaDeSinais[index].Terminal,
                codAutorizacao: getFormularioListaDeSinais[index]?.CodAutorizacao
            } as Objeto.TituloDeParcela);
            PropostaDeVenda.titulosConsolidados.push({
                classificacao: ObjetoTabelaDeVenda.classificacoesDosTitulosDaTabelaDeVenda.find((Item) => Item.classificacao.id == 2)?.classificacao,
                numero: index + 1,
                numeroDeGeracao: ++Index,
                dataDeVencimento: getFormularioListaDeSinais[index].Vencimento,
                grupo: 1,
                principal: getFormularioListaDeSinais[index].Valor,
                juros: 0,
                correcao: 0,
                multa: 0,
                jurosPorAtraso: 0,
                correcaoPorAtraso: 0,
                acrescimoAvulso: 0,
                descontoAvulso: 0,
                descontoDeAntecipacao: 0,
                descontoDePontualidade: 0
            } as Objeto.Titulo);
        }
        getFormularioListaDeIntermediarias.forEach((Item) => {
            for (let index = 0; index < QtdeDeIntermediarias(Item.Vencimento); index++) {
                let Vencimento = new Date(Item.Vencimento);
                if (getItemPickerCondicaoDaTabelaDeVenda?.descricao.includes("Anual")) {
                    Vencimento.setFullYear(Vencimento.getFullYear() + index);
                }
                else if (getItemPickerCondicaoDaTabelaDeVenda?.descricao.includes("Semestral")) {
                    Vencimento.setMonth(Vencimento.getMonth() + index * 6);
                } else {
                    Vencimento.setMonth(Vencimento.getMonth() + index);
                }
                PropostaDeVenda.titulosDeIntermediaria.push({
                    id: index + 1,
                    vencimento: Vencimento,
                    valor: Item.Valor,
                    formaDePagamento: Item.MeioDePagamento,
                    banco: Item.Banco?.Valor,
                    agencia: Item.Agencia,
                    conta: Item.Conta,
                    digitoDaConta: Item.DigitoDaConta,
                    titular: Item.Titular,
                    numeroCheque: Item.NumeroCheque,
                    maquina: Item.Maquina,
                    bandeira: Item.Bandeira,
                    digitoCartao: Item.DigitoCartao,
                    operacao: Item.Operacao,
                    nsu: Item.NSU,
                    numeroDaOperacao: Item.NumeroDaOperacao,
                    terminal: Item.Terminal,
                    codAutorizacao: Item?.CodAutorizacao
                } as Objeto.TituloDeIntermediaria);
                PropostaDeVenda.titulosConsolidados.push({
                    classificacao: ObjetoTabelaDeVenda.classificacoesDosTitulosDaTabelaDeVenda.find((Item) => Item.classificacao.id == 14)?.classificacao,
                    numero: index + 1,
                    numeroDeGeracao: ++Index,
                    dataDeVencimento: Vencimento,
                    grupo: 1,
                    principal: Item.Valor,
                    juros: 0,
                    correcao: 0,
                    multa: 0,
                    jurosPorAtraso: 0,
                    correcaoPorAtraso: 0,
                    acrescimoAvulso: 0,
                    descontoAvulso: 0,
                    descontoDeAntecipacao: 0,
                    descontoDePontualidade: 0
                } as Objeto.Titulo);
            }
        });
        getFormularioListaDeParcelas.forEach((Item) => {
            for (let index = 0; index < Item.Quantidade; index++) {
                let Vencimento = new Date(Item.Vencimento);
                if (getItemPickerCondicaoDaTabelaDeVenda?.descricao.includes("Anual")) {
                    Vencimento.setFullYear(Vencimento.getFullYear() + index);
                }
                else if (getItemPickerCondicaoDaTabelaDeVenda?.descricao.includes("Semestral")) {
                    Vencimento.setMonth(Vencimento.getMonth() + index * 6);
                } else {
                    Vencimento.setMonth(Vencimento.getMonth() + index);
                }
                PropostaDeVenda.titulosDeParcela.push({
                    id: index + 1,
                    vencimento: Vencimento,
                    valor: Item.Valor,
                    formaDePagamento: Item.MeioDePagamento,
                    banco: Item.Banco?.Valor,
                    agencia: Item.Agencia,
                    conta: Item.Conta,
                    digitoDaConta: Item.DigitoDaConta,
                    titular: Item.Titular,
                    numeroCheque: Item.NumeroCheque,
                    maquina: Item.Maquina,
                    bandeira: Item.Bandeira,
                    digitoCartao: Item.DigitoCartao,
                    operacao: Item.Operacao,
                    nsu: Item.NSU,
                    numeroDaOperacao: Item.NumeroDaOperacao,
                    terminal: Item.Terminal,
                    codAutorizacao: Item?.CodAutorizacao
                } as Objeto.TituloDeParcela);
                PropostaDeVenda.titulosConsolidados.push({
                    classificacao: ObjetoTabelaDeVenda.classificacoesDosTitulosDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.classificacao,
                    numero: index + 1,
                    numeroDeGeracao: ++Index,
                    dataDeVencimento: Vencimento,
                    grupo: 1,
                    principal: Item.Valor,
                    juros: 0,
                    correcao: 0,
                    multa: 0,
                    jurosPorAtraso: 0,
                    correcaoPorAtraso: 0,
                    acrescimoAvulso: 0,
                    descontoAvulso: 0,
                    descontoDeAntecipacao: 0,
                    descontoDePontualidade: 0
                } as Objeto.Titulo);
            }
        });
        PropostaDeVenda.estruturaDeComissao.push({
            cpf: getItemPickerLiner ? getItemPickerLiner?.cpf : "",
            nome: getItemPickerLiner ? getItemPickerLiner?.descricao : "",
            cargo: "Liner"
        } as Objeto.Comissionado, {
            cpf: getItemPickerCloser ? getItemPickerCloser?.cpf : "",
            nome: getItemPickerCloser ? getItemPickerCloser?.descricao : "",
            cargo: "Closer"
        } as Objeto.Comissionado, {
            cpf: getItemPickerPEP ? getItemPickerPEP?.cpf : "",
            nome: getItemPickerPEP ? getItemPickerPEP?.descricao : "",
            cargo: "PEP"
        } as Objeto.Comissionado, {
            cpf: getItemPickerSubGerenteDeSala ? getItemPickerSubGerenteDeSala?.cpf : "",
            nome: getItemPickerSubGerenteDeSala ? getItemPickerSubGerenteDeSala?.descricao : "",
            cargo: "Sub gerente"
        } as Objeto.Comissionado, {
            cpf: getItemPickerGerenteDeSala ? getItemPickerGerenteDeSala?.cpf : "",
            nome: getItemPickerGerenteDeSala ? getItemPickerGerenteDeSala?.descricao : "",
            cargo: "Gerente"
        } as Objeto.Comissionado, {
            cpf: getItemPickerPromotor ? getItemPickerPromotor?.cpf : "",
            nome: getItemPickerPromotor ? getItemPickerPromotor?.descricao : "",
            cargo: "Captador/Promotor"
        } as Objeto.Comissionado, {
            cpf: getItemPickerAssessorTlmkt ? getItemPickerAssessorTlmkt?.cpf : "",
            nome: getItemPickerAssessorTlmkt ? getItemPickerAssessorTlmkt?.descricao : "",
            cargo: "Assessor Tlmkt"
        } as Objeto.Comissionado);
        NotificacaoInterna.ExibirNotificacao("Proposta a caminho do sistema", "🤝 Aguarde um pouco enquanto inserimos a proposta no sistema e geramos o contrato", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        var Response = await Venda.NovaProposta(ContextSignIn.getContext().token, PropostaDeVenda);
        if (Math.floor(Response.status / 100) == 2) {
            NotificacaoInterna.ExibirNotificacao("Proposta devidamente cadastrada", "Aguarde enquanto gero o contrato de compra e venda", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao cadastrar a proposta", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao cadastrar a proposta, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
        }
        console.log(JSON.stringify(PropostaDeVenda));
        return ((Response.status / 100) == 2) ? Response.data as Objeto.PropostaDeVenda : null;
    };
    /* Sobre esta função *//**
     * Encaminhar proposta de venda e gerar o contrato
     **/
    async function EnviarPropostaDeVenda(Validacao?: number): Promise<boolean> {
        await setEnviandoPropostaDeVenda(true);
        await setObjetoPropostaDeVenda(undefined);
        await setObjetoContrato(undefined);

        if ((Validacao ?? 0) <= 0 && (getFormularioAnexoCheckListDeCadastro == undefined || getFormularioAnexoCheckListDeCadastro == null ||
            getFormularioAnexoCliente == undefined || getFormularioAnexoCliente == null ||
            getFormularioAnexoConjuge == undefined || getFormularioAnexoConjuge == null ||
            getFormularioAnexoEndereco == undefined || getFormularioAnexoEndereco == null ||
            getFormulario02CompradorAnexoCliente == undefined || getFormulario02CompradorAnexoCliente == null ||
            getFormulario02CompradorAnexoConjuge == undefined || getFormulario02CompradorAnexoConjuge == null ||
            getFormulario02CompradorAnexoEndereco == undefined || getFormulario02CompradorAnexoEndereco == null ||
            getFormularioAnexoComprovantePgtoEntrada == undefined || getFormularioAnexoComprovantePgtoEntrada == null ||
            getFormularioAnexoFichaDeNegociacao == undefined || getFormularioAnexoFichaDeNegociacao == null ||
            getFormularioAnexoFichaDeAtendimento == undefined || getFormularioAnexoFichaDeAtendimento == null ||
            getFormularioAnexoDocumentacaoComplementar == undefined || getFormularioAnexoDocumentacaoComplementar == null ||
            getPermitirEnvioComAnexoPendente == undefined || getPermitirEnvioComAnexoPendente == null)) {
            setExibirPermitirEnvioComAnexoPendente(true);
            await setEnviandoPropostaDeVenda(false);
            return false;
        }
        function verificarMesesAnteriores(dates: Date[]): boolean {
            let Resultado = true;
            for (let i = 1; i < dates.length; i++) {
                const currentMonth = dates[i].getMonth();
                const previousMonth = dates[i - 1].getMonth();

                if (currentMonth === (previousMonth + 1) % 12) { }
                else {
                    Resultado = false;
                }
            }
            return Resultado;
        }
        if ((Validacao ?? 0) <= 1 &&
            (verificarMesesAnteriores(getFormularioListaDeEntradas.map((Item) => Item.Vencimento)) == false
                || verificarMesesAnteriores(getFormularioListaDeSinais.map((Item) => Item.Vencimento)) == false)) {
            setExibirPermitirParcelasNaoSubsequentes(true);
            await setEnviandoPropostaDeVenda(false);
            return false;
        }
        function validarEmails(emails: string[]): boolean {
            let Resultado = true;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            emails.forEach((email, index) => {
                if ((email ?? "") == "" || emailRegex.test(email)) { } else {
                    Resultado = false;
                }
            });
            return Resultado;
        }
        if ((Validacao ?? 0) <= 2 && validarEmails([getFormularioEmail, getFormularioConjugeEmail]) == false) {
            setExibirPermitirEmailInvalido(true);
            await setEnviandoPropostaDeVenda(false);
            return false;
        }
        if (await ValidarLead() == true && await Validar02Lead() == true && await ValidarPropostaDeVenda() == true) {
            var Lead = await AdicionarLead();
            if (Lead != null) {

                var Prospects = [] as Array<Objeto.Prospect>;
                var Prospect = await AdicionarProspect(Lead);
                if (Prospect != null) {
                    Prospects.push(Prospect);
                }
                if (getFormularioExibir02Comprador == true) {
                    let Lead02 = await Adicionar02Lead();
                    if (Lead02 != null) {
                        let Prospect02 = await AdicionarProspect(Lead02);
                        if (Prospect02 != null) {
                            Prospects.push(Prospect02);
                        }
                    }
                }
                if (Prospect != null) {
                    var PropostaDeVenda = await AdicionarPropostaDeVenda(Prospects);
                    await setObjetoPropostaDeVenda(PropostaDeVenda ?? undefined);
                    if (PropostaDeVenda != null) {
                        if (await GerarContrato(PropostaDeVenda) != null) {
                            await setEnviandoPropostaDeVenda(false);
                            return true;
                        }
                        else {
                            await setEnviandoPropostaDeVenda(false);
                            return false;
                        }
                    }
                    else {
                        await setEnviandoPropostaDeVenda(false);
                        return false;
                    }
                }
                else {
                    await setEnviandoPropostaDeVenda(false);
                    return false;
                }
            }
            else {
                await setEnviandoPropostaDeVenda(false);
                return false;
            }
        }
        else {
            await setEnviandoPropostaDeVenda(false);
            return false;
        }
    };
    /* Sobre esta função *//**
     * Reimprimir documento atrelados ao contrato
     **/
    async function ReimprimirDocumento(): Promise<boolean> {
        await setEnviandoPropostaDeVenda(true);
        await setObjetoContrato(undefined);
        if (getObjetoPropostaDeVenda && await GerarContrato(getObjetoPropostaDeVenda) != null) {
            await setEnviandoPropostaDeVenda(false);
            return true;
        }
        else {
            await setEnviandoPropostaDeVenda(false);
            return false;
        }
    };
    /* Sobre esta função *//**
     * Imprimir o contrato
    @param {Objeto.PropostaDeVenda} PropostaDeVenda Informe os dados da proposta
     **/
    async function GerarContrato(PropostaDeVenda: Objeto.PropostaDeVenda): Promise<Array<{ arquivo: string, descricao: string, extensao: string }> | null> {
        var ObjetoContrato = getObjetoContrato;
        var Response = await Venda.NovoContrato(ContextSignIn.getContext().token, PropostaDeVenda);
        if (Math.floor(Response.status / 100) == 2) {
            ObjetoContrato = Response.data
            await setObjetoContrato(ObjetoContrato);
            NotificacaoInterna.ExibirNotificacao("Contrato devidamente gerado", "O(s) documento(s) se encontra disponivel para download logo a baixo no campo 'Documento(s) gerado(s)'", NotificacaoInterna.TipoDeNotificacao.Sucesso);
            return ObjetoContrato;
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao gerar o contrato", "😱 Houve uma falha ao baixar o contrato, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
            return null;
        }
    };
    /* Sobre esta função *//**
     * Imprimir o contrato
    @param {string} descricao Informe o nome do arquivo
    @param {string} extensao Informe a extensão do arquivo
    @param {string} arquivo Informe a string do arquivo base64
     **/
    async function ImprimirContrato(descricao: string, extensao: string, arquivo: string): Promise<void> {
        try {
            window.open("")?.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(arquivo) + "'></iframe>");
            NotificacaoInterna.ExibirNotificacao("Seu contrato foi baixado com sucesso", `O documento encontra-se em uma nova jane-la deste mesmo navegador`, NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
        catch
        {
            let downloadLink = document.createElement("a");
            downloadLink.href = "data:application/octet-stream;charset=utf-16le;base64," + encodeURI(arquivo);
            downloadLink.download = descricao + "." + extensao;
            downloadLink.click();
            NotificacaoInterna.ExibirNotificacao("Seu contrato foi baixado com sucesso", `🖨️ Acesse a sua pasta de downloads e procure pelo o arquivo ˜${descricao}.${extensao}`, NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
    };
    /* Sobre esta função *//**
     * Imprimir o contrato
     **/
    async function NovoDocumento(): Promise<void> {
        NotificacaoInterna.ExibirNotificacao("Limpando o documento", "Aguarde enquanto limpamos todos os dados preenchidos", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        await setLimpandoDocumento(true, async () => {
            await setFormularioAnexoCheckListDeCadastro(undefined);
            await setFormularioAnexoCliente(undefined);
            await setFormularioNome("");
            await setFormularioEmail("");
            await setCPFEmConsulta(false);
            await setFormularioCPF("");
            await setFormularioRG("");
            await setFormularioRGUF("");
            await setFormularioDataDeNascimento(undefined);
            await setItemPickerFormularioNacionalidade(undefined);
            await setFormularioProfissao("");
            await setFormularioEstadoCivil(undefined);
            await setFormularioEstadoCivil(undefined);
            await setFormularioTelefoneCelular("");
            await setFormularioTelefoneComercial("");
            await setFormularioConjugeNome("");
            await setFormularioConjugeEmail("");
            await setFormularioConjugeCPF("");
            await setFormularioConjugeRG("");
            await setFormularioConjugeRGOrgaoEmissor("");
            await setFormularioConjugeRGUF("");
            await setFormularioConjugeDataDeNascimento(undefined);
            await setItemPickerFormularioConjugeNacionalidade(undefined);
            await setFormularioConjugeProfissao("");
            await setFormularioConjugeTelefoneCelular("");
            await setFormularioConjugeTelefoneComercial("");
            await setFormularioExibir02Comprador(false);
            await setFormulario02CompradorAnexoCliente(undefined);
            await setFormulario02CompradorNome("");
            await setFormulario02CompradorEmail("");
            await setFormulario02CompradorCPF("");
            await setFormulario02CompradorRG("");
            await setFormulario02CompradorRGOrgaoEmissor("");
            await setFormulario02CompradorRGUF("");
            await setFormulario02CompradorDataDeNascimento(undefined);
            await setItemPickerFormulario02CompradorNacionalidade(undefined);
            await setFormulario02CompradorProfissao("");
            await setFormulario02CompradorEstadoCivil(undefined);
            await setFormulario02CompradorTelefoneCelular("");
            await setFormulario02CompradorTelefoneComercial("");
            await setFormulario02CompradorAnexoConjuge(undefined);
            await setFormulario02CompradorConjugeNome("");
            await setFormulario02CompradorConjugeEmail("");
            await setFormulario02CompradorConjugeCPF("");
            await setFormulario02CompradorConjugeRG("");
            await setFormulario02CompradorConjugeRGOrgaoEmissor("");
            await setFormulario02CompradorConjugeRGUF("");
            await setFormulario02CompradorConjugeDataDeNascimento(undefined);
            await setItemPickerFormulario02CompradorConjugeNacionalidade(undefined);
            await setFormulario02CompradorConjugeProfissao("");
            await setFormulario02CompradorConjugeTelefoneCelular("");
            await setFormulario02CompradorConjugeTelefoneComercial("");
            await setCEPEmConsulta(false);
            await setFormularioCEP("");
            await setFormularioAnexoEndereco(undefined);
            await setFormularioLogradouro("");
            await setFormularioComplemento("");
            await setFormularioNumeroDeEndereco("");
            await setFormularioBairro("");
            await setFormularioCidade("");
            await setFormularioUF("");
            await setFormulario02CompradorCEP("");
            await setFormulario02CompradorAnexoEndereco(undefined);
            await setFormulario02CompradorLogradouro("");
            await setFormulario02CompradorComplemento("");
            await setFormulario02CompradorNumeroDeEndereco("");
            await setFormulario02CompradorBairro("");
            await setFormulario02CompradorCidade("");
            await setFormulario02CompradorUF("");
            await setFormularioLocalDeCaptacao("");
            await setItemPickerCentroDeCusto(undefined);
            await setItemPickerIdentificador(undefined);
            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
            await setFormularioAnexoComprovantePgtoSinal(undefined);
            await setFormularioSinalQtde(0);
            await setFormularioListaDeSinais([]);
            await setFormularioAnexoComprovantePgtoEntrada(undefined);
            await setFormularioEntradaQtde(0);
            await setFormularioListaDeEntradas([]);
            await setFormularioAnexoComprovantePgtoParcela(undefined);
            await setFormularioParcelaValor(0);
            await setFormularioParcelaQtde(0);
            await setFormularioParcelaVencimento(undefined);
            await setFormularioParcelaMeioDePagamento("");
            await setFormularioParcelaBanco(undefined);
            await setFormularioParcelaAgencia("");
            await setFormularioParcelaConta("");
            await setFormularioParcelaDigitoDaConta("");
            await setFormularioParcelaTitular("");
            await setFormularioParcelaNumeroCheque("");
            await setFormularioParcelaMaquina("");
            await setFormularioParcelaDigitoCartao("");
            await setFormularioParcelaBandeira("");
            await setFormularioParcelaOperacao("");
            await setFormularioParcelaNSU("");
            await setFormularioParcelaNumeroDaOperacao("");
            await setFormularioListaDeParcelas([]);
            await setFormularioIdCasal("");
            await setItemPickerSala(undefined);
            await setItemPickerPromotor(undefined);
            await setItemPickerAssessorTlmkt(undefined);
            await setItemPickerLiner(undefined);
            await setItemPickerCloser(undefined);
            await setItemPickerPEP(undefined);
            await setItemPickerSubGerenteDeSala(undefined);
            await setItemPickerGerenteDeSala(undefined);
            await setEnviandoPropostaDeVenda(false);
            await setEnviandoContratoParaAssinatura(false);
            await setObjetoPropostaDeVenda(undefined);
            await setObjetoContrato(undefined);
            await setFormularioDataDaProposta(new Date(new Date().toDateString()));
            await setFormularioAnexoFichaDeNegociacao(undefined);
            await setFormularioAnexoFichaDeAtendimento(undefined);
            await setFormularioAnexoDocumentacaoComplementar(undefined);
            await setItemPickerFinalidadeDeCompra(undefined);
            await setHabilitarInclusaoDeParcelas(false);
            NotificacaoInterna.ExibirNotificacao("Documento limpo 😜", "Preencha o formulario para realizar uma nova proposta", NotificacaoInterna.TipoDeNotificacao.Sucesso);
            await setLimpandoDocumento(false, () => {
                (RefPagina.current ?? {} as any).scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            });

        });
    };
    /* Sobre esta função *//**
     * Encaminhar o documento para assinatura
     **/
    async function EncaminharDocumentoParaAssinatura(): Promise<void> {
        await setEnviandoContratoParaAssinatura(true);
        {
            let Anexos = [] as Array<{ Nome: string, Conteudo: File | null | undefined }>;
            if (getFormularioAnexoCheckListDeCadastro != undefined && getFormularioAnexoCheckListDeCadastro != null) {
                Anexos.push({ Nome: "01. Check list de cadastro.pdf", Conteudo: getFormularioAnexoCheckListDeCadastro });
            }
            if (getFormularioAnexoCliente != undefined && getFormularioAnexoCliente != null) {
                Anexos.push({ Nome: "08. Doc cliente 01 comprador.pdf", Conteudo: getFormularioAnexoCliente });
            }
            if (getFormularioAnexoConjuge != undefined && getFormularioAnexoConjuge != null) {
                Anexos.push({ Nome: "09. Doc conjuge 01 comprador.pdf", Conteudo: getFormularioAnexoConjuge });
            }
            if (getFormularioAnexoEndereco != undefined && getFormularioAnexoEndereco != null) {
                Anexos.push({ Nome: "10. Comp endereco 01 comprador.pdf", Conteudo: getFormularioAnexoEndereco });
            }
            if (getFormulario02CompradorAnexoCliente != undefined && getFormulario02CompradorAnexoCliente != null) {
                Anexos.push({ Nome: "11. Doc cliente 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoCliente });
            }
            if (getFormulario02CompradorAnexoConjuge != undefined && getFormulario02CompradorAnexoConjuge != null) {
                Anexos.push({ Nome: "12. Doc conjuge 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoConjuge });
            }
            if (getFormulario02CompradorAnexoEndereco != undefined && getFormulario02CompradorAnexoEndereco != null) {
                Anexos.push({ Nome: "13. Comp endereco 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoEndereco });
            }
            if (getFormularioAnexoComprovantePgtoEntrada != undefined && getFormularioAnexoComprovantePgtoEntrada != null) {
                Anexos.push({ Nome: "14. Comp de pagamento da entrada.pdf", Conteudo: getFormularioAnexoComprovantePgtoEntrada });
            }
            if (getFormularioAnexoFichaDeNegociacao != undefined && getFormularioAnexoFichaDeNegociacao != null) {
                Anexos.push({ Nome: "15. Ficha de negociação.pdf", Conteudo: getFormularioAnexoFichaDeNegociacao });
            }
            if (getFormularioAnexoFichaDeAtendimento != undefined && getFormularioAnexoFichaDeAtendimento != null) {
                Anexos.push({ Nome: "19. Ficha de atendimento.pdf", Conteudo: getFormularioAnexoFichaDeAtendimento });
            }
            if (getFormularioAnexoDocumentacaoComplementar != undefined && getFormularioAnexoDocumentacaoComplementar != null) {
                Anexos.push({ Nome: "20. Documentação complementar.pdf", Conteudo: getFormularioAnexoDocumentacaoComplementar });
            }
            let Response = await Venda.NovosDocumentos(ContextSignIn.getContext().token, getObjetoPropostaDeVenda?.empresa.id ?? 0, getObjetoPropostaDeVenda?.centroDeCusto.sigla ?? "", getObjetoPropostaDeVenda?.numero ?? 0, Anexos);
            if (Math.floor(Response.status / 100) == 2) { }
            else {
                NotificacaoInterna.ExibirNotificacao("Falha ao enviar anexos do cliente", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao enviar o contrato para assinatura, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                await setEnviandoContratoParaAssinatura(false);
                return;
            }
        }
        if (getObjetoPropostaDeVenda) {
            NotificacaoInterna.ExibirNotificacao("Enviando contrato para assinatura", "Aguarde enquanto o documento é cadastrado no D4sign'", NotificacaoInterna.TipoDeNotificacao.Sucesso);
            let Response = await D4sign.Post(ContextSignIn.getContext().token, getItemPickerSala?.descricao ?? "undefined", getObjetoPropostaDeVenda);
            if (Math.floor(Response.status / 100) == 2) {
                NotificacaoInterna.ExibirNotificacao("Contrato devidamente enviado para assinatura", JSON.stringify(Response.data), NotificacaoInterna.TipoDeNotificacao.Sucesso);
            }
            else {
                NotificacaoInterna.ExibirNotificacao("Falha ao enviar o contrato para assinatura", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao enviar o contrato para assinatura, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
            }
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao enviar o contrato para assinatura", "O contrato não foi devidamente gerado, envie o contrato antes de prosseguir", NotificacaoInterna.TipoDeNotificacao.Erro);
        }
        await setEnviandoContratoParaAssinatura(false);
    };

    useEffect(() => {
        async function componentDidMount() {
            socket = io(String(process.env.REACT_APP_CONNECTION_PORT), { transports: ['websocket', 'polling', 'flashsocket'] })
        }
        componentDidMount();
    }, [process.env.REACT_APP_CONNECTION_PORT]);

    useEffect(() => {
        socket.on("receive_unidade", (data: any) => {
            if ((ItemPickerIdentificadorSinteticao?.subLocalId == data.unidade.subLocalId) && data.status != 0) {
                NotificacaoInterna.ExibirNotificacao("Caro Usuário", "A unidade selecionada, foi vendida ou reservada no momento, por favor selecione outra unidade ou disponibilidade a unidade para venda.", NotificacaoInterna.TipoDeNotificacao.Alerta);
            }
            else if ((ItemPickerIdentificadorSinteticao?.subLocalId != data.unidade.subLocalId) && (data.status == 2 || data.status == 3)) {
                let ListaDeUnidades = [...getListaPickerIdentificadorSintetico];
                let ListaFiltrada = ListaDeUnidades.filter(lote => lote.subLocalId != data.unidade.subLocalId)
                timelineLoaded.current = true;
                setListaPickerIdentificadorSintetico(ListaFiltrada);
                timelineLoaded.current = false;
            }
        })
        return () => { socket.off('receive_unidade') }
    }, [getListaPickerIdentificadorSintetico]);
    useEffect(() => {
        async function componentDidMount() {
            let Response = await Venda.TerminalDePagamento(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "");
            if (Math.floor(Response.status / 100) == 2) {
                setTerminais(Response.data)
            }
            else {
                //NotificacaoInterna.ExibirNotificacao("Falha ao enviar anexos do cliente", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao enviar o contrato para assinatura, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                return;
            }
        }
        componentDidMount();
    }, [getItemPickerCentroDeCusto]);

    const connectToRoom = (room: string | undefined) => {
        socket.emit('join_room', room)
        console.log(`Conectado a sala: ${room}`)
    }

    const sendUnidadeSintetico = async (unidade: Objeto.IdentificadorSintetico, sigla: string, lista: Array<Objeto.IdentificadorSintetico>, store: Array<Store>) => {
        let UnidadeContent = {
            room: sigla,
            content: {
                id: 0,
                unidade: unidade,
                status: unidade.status === 2 ? 3 : unidade.status,
            },
        }
        await socket.emit("send_unidade", UnidadeContent);
        socket.off('receive_unidade');
        socket.on("receive_unidade", (data: any) => {
            if ((ItemPickerIdentificadorSinteticao?.subLocalId == data.unidade.subLocalId) && data.status != 0) {
                NotificacaoInterna.ExibirNotificacao("Caro Usuário", "A unidade selecionada, foi vendida ou reservada no momento, por favor selecione outra unidade ou disponibilidade a unidade para venda.", NotificacaoInterna.TipoDeNotificacao.Alerta);
            }
            else if ((ItemPickerIdentificadorSinteticao?.subLocalId != data.unidade.subLocalId) && (data.status == 2 || data.status == 3)) {
                let ListaDeUnidades = [...getListaPickerIdentificadorSintetico];
                let ListaFiltrada = ListaDeUnidades.filter(lote => lote.subLocalId != data.unidade.subLocalId)

                timelineLoaded.current = true;
                setListaPickerIdentificadorSintetico(ListaFiltrada);
                timelineLoaded.current = false;
            }
        })
    }

    const sendUnidade = async (unidade: Objeto.Identificador, sigla: string, lista: Array<Objeto.Identificador>, store: Array<Store>) => {
        let UnidadeContent = {
            room: sigla,
            content: {
                id: unidade.indice,
                unidade: unidade,
                status: unidade.status === 2 ? 3 : unidade.status,
            },
        }
        await socket.emit("send_unidade", UnidadeContent);
        socket.off('receive_unidade');
        socket.on("receive_unidade", (data: any) => {
            if ((ItemPickerIdentificador?.subLocal.id == data.unidade.subLocal.id) && data.status != 0) {
                NotificacaoInterna.ExibirNotificacao("Caro Usuário", "A unidade selecionada, foi vendida ou reservada no momento, por favor selecione outra unidade ou disponibilidade a unidade para venda.", NotificacaoInterna.TipoDeNotificacao.Alerta);
            }
            else if ((ItemPickerIdentificador?.subLocal.id != data.unidade.subLocal.id) && (data.status == 2 || data.status == 3)) {
                let ListaDeUnidades = [...getListaPickerIdentificador];
                let ListaFiltrada = ListaDeUnidades.filter(lote => lote.subLocal.id != data.unidade.subLocal.id)

                timelineLoaded.current = true;
                setListaPickerIdentificador(ListaFiltrada);
                timelineLoaded.current = false;
            }
        })
    }

    useEffect(() => {
        async function componentDidMount() {
            try {
                Toast.configure();
                await setListaPickerNaturalidade((await Lead.Naturalidades(ContextSignIn.getContext().token)).data);
                await setFormularioListaEstadoCivil((await Pessoa.EstadoCivil(ContextSignIn.getContext().token)).data);
                await setListaPickerNacionalidade((await Nacao.Get(ContextSignIn.getContext().token)).data);
                await setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data);
                var Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                    .get(`/Identificador/VisaoGAV02/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}`))
                    .data as Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>;
                await setListaPickerSala(Response);
            }
            catch { }
        }
        componentDidMount();
    }, []);

    return (
        <Component.Container ref={RefPagina}>
            <CollapseBlueprint isOpen={getCollapse}>
                <div className="endereco">
                    <img src={IconeHome} style={{ cursor: "pointer" }} alt="" onClick={() => { History.goBack(); }} />
                    <div className="bar">/</div>
                    <div className="place">Venda Direta</div>
                </div>
            </CollapseBlueprint>
            <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>
            <Card style={{ width: "88%", backgroundColor: "#ffffff", margin: "40px auto 0 auto", borderRadius: "5px", boxShadow: "none", padding: "40px 3% 40px 3% " }}>
                <Component.ContentCard style={{ padding: "0 3%" }} >
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }} />
                </Component.ContentCard>
                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && <Component.ContentCard style={{ padding: "0 3%", paddingBottom: "10px" }} >
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Check list de cadastro</h3>
                        </div>
                        <FileInput style={{ width: "50vw", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoCheckListDeCadastro == undefined ? "Anexe o check list de cadastro" : getFormularioAnexoCheckListDeCadastro.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                setFormularioAnexoCheckListDeCadastro(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoCheckListDeCadastro && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoCheckListDeCadastro(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                </Component.ContentCard>}
                <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Dados do cliente</h3>
                        </div>
                        <FileInput style={{ width: "calc(100% - 235px)", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoCliente == undefined ? "Anexe o documento do cliente" : getFormularioAnexoCliente.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                setFormularioAnexoCliente(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoCliente && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoCliente(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <div className="Conteiner">
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "CPF:" : "CNPJ:"} inline={true}>
                                    <InputGroup id="CPF" placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormularioCPF)} leftIcon={getCPFEmConsulta ? "geosearch" : false}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                            await setFormularioCPF(TextoDigitado);
                                            if (CPF.TextoValido(TextoDigitado) || CNPJ.TextoValido(TextoDigitado)) {
                                                await setCPFEmConsulta(true);
                                                switch (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado) {
                                                    case 4:
                                                        try {
                                                            let Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                                                                .get(`/Pessoa/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}/${TextoDigitado}`))
                                                                .data as any;
                                                            await setFormularioNome(Response.nome ?? "");
                                                            await setFormularioEmail(Response.emails[0].descricao ?? "");
                                                            await setFormularioDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                            try {
                                                                let TelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                                await setFormularioTelefoneCelular(Telefone.FormatarTexto((TelefoneCelular?.ddd ?? "") + (TelefoneCelular?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try {
                                                                let TelefoneComercial = Response.telefones.find((Item: any) => Item.classificacao == 2);
                                                                await setFormularioTelefoneComercial(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try { await setFormularioRG(Response.rg.numero ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGOrgaoEmissor(Response.rg.orgaoEmissor ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGUF(Response.rg.uf ?? ""); }
                                                            catch { }
                                                            await setFormularioProfissao(Response.observacao ?? "");
                                                            await setItemPickerFormularioNacionalidade(getListaPickerNacionalidade.filter(x => (x.masculino.toUpperCase() ?? "Brasileiro".toUpperCase()) == Response.nacionalidade.toUpperCase())[0]);
                                                            await setFormularioCEP(Response.endereco.cep ?? "");
                                                            await setFormularioLogradouro(Response.endereco.logradouro ?? "");
                                                            await setFormularioComplemento(Response.endereco.complemento ?? "");
                                                            await setFormularioNumeroDeEndereco(Response.endereco.numero ?? "");
                                                            await setFormularioBairro(Response.endereco.bairro ?? "");
                                                            await setFormularioCidade(Response.endereco.cidade ?? "");
                                                            await setFormularioUF(Response.endereco.uf ?? "");
                                                            await setFormularioEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                            if (Response.conjuge) {
                                                                await setFormularioConjugeNome(Response.conjuge.nome ?? "");
                                                                await setFormularioConjugeCPF(Response.conjuge.cpf ?? "");
                                                                await setFormularioConjugeEmail(Response.conjuge.emails[0].descricao ?? "");
                                                                await setFormularioConjugeDataDeNascimento(new Date(Response.conjuge.dataDeNascimento) ?? "");
                                                                try { await setFormularioConjugeRG(Response.conjuge.rg.numero ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGOrgaoEmissor(Response.conjuge.rg.orgaoEmissor ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGUF(Response.conjuge.rg.uf ?? ""); }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneCelular = Response.conjuge.telefones.find((Item: any) => Item.classificacao == 1);
                                                                    await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneComercial = Response.Dados.conjuge.telefones.find((Item: any) => Item.classificacao == 2);
                                                                    await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                await setFormularioConjugeProfissao(Response.conjuge.observacao ?? "");
                                                            }
                                                        }
                                                        catch { }
                                                        finally { await setCPFEmConsulta(false); }
                                                        break;
                                                    case 6:
                                                        try {
                                                            let Response = (await Pessoa.Get(ContextSignIn.getContext().token, TextoDigitado)).data;
                                                            await setFormularioNome(Response.nome ?? "");
                                                            await setFormularioEmail(Response.emails[0].descricao ?? "");
                                                            await setFormularioDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                            try {
                                                                let TelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                                await setFormularioTelefoneCelular(Telefone.FormatarTexto((TelefoneCelular?.ddd ?? "") + (TelefoneCelular?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try {
                                                                let TelefoneComercial = Response.telefones.find((Item: any) => Item.classificacao == 2);
                                                                await setFormularioTelefoneComercial(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try { await setFormularioRG(Response.rg?.numero ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGOrgaoEmissor(Response.rg?.orgaoemissor ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGUF(Response.rg?.uf ?? ""); }
                                                            catch { }
                                                            await setFormularioProfissao(Response.ocupacao?.nome ?? "");
                                                            await setItemPickerFormularioNacionalidade(getListaPickerNacionalidade.filter(x => (x.masculino.toUpperCase() ?? "Brasileiro".toUpperCase()) == Response.nacionalidade?.masculino.toUpperCase())[0]);
                                                            await setFormularioCEP(Response.endereco?.cep ?? "");
                                                            await setFormularioLogradouro(Response.endereco?.logradouro ?? "");
                                                            await setFormularioComplemento(Response.endereco?.complemento ?? "");
                                                            await setFormularioNumeroDeEndereco(Response.endereco?.numero ?? "");
                                                            await setFormularioBairro(Response.endereco?.bairro ?? "");
                                                            await setFormularioCidade(Response.endereco?.cidade ?? "");
                                                            await setFormularioUF(Response.endereco?.uf ?? "");
                                                            await setFormularioEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                            if (Response.conjuge) {
                                                                await setFormularioConjugeNome(Response.conjuge.nome ?? "");
                                                                await setFormularioConjugeCPF(Response.conjuge.cpf ?? "");
                                                                await setFormularioConjugeEmail(Response.conjuge.emails[0].descricao ?? "");
                                                                await setFormularioConjugeDataDeNascimento(new Date(Response.conjuge.datadenascimento) ?? "");
                                                                try { await setFormularioConjugeRG(Response.conjuge.rg.numero ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGOrgaoEmissor(Response.conjuge.rg.orgaoemissor ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGUF(Response.conjuge.rg.uf ?? ""); }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneCelular = Response.conjuge.telefone?.find((Item: any) => Item.classificacao == 1);
                                                                    await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneComercial = Response.conjuge.telefone?.find((Item: any) => Item.classificacao == 2);
                                                                    await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                await setFormularioConjugeProfissao(Response.conjuge.ocupacao?.nome ?? "");
                                                            }
                                                        }
                                                        catch { }
                                                        finally { await setCPFEmConsulta(false); }
                                                        break;
                                                    case 9:
                                                        try {
                                                            let Response = (await Pessoa.Get(ContextSignIn.getContext().token, TextoDigitado)).data;
                                                            await setFormularioNome(Response.nome ?? "");
                                                            await setFormularioEmail(Response.emails[0].descricao ?? "");
                                                            await setFormularioDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                            try {
                                                                let TelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                                await setFormularioTelefoneCelular(Telefone.FormatarTexto((TelefoneCelular?.ddd ?? "") + (TelefoneCelular?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try {
                                                                let TelefoneComercial = Response.telefones.find((Item: any) => Item.classificacao == 2);
                                                                await setFormularioTelefoneComercial(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try { await setFormularioRG(Response.rg?.numero ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGOrgaoEmissor(Response.rg?.orgaoemissor ?? ""); }
                                                            catch { }
                                                            try { await setFormularioRGUF(Response.rg?.uf ?? ""); }
                                                            catch { }
                                                            await setFormularioProfissao(Response.ocupacao?.nome ?? "");
                                                            await setItemPickerFormularioNacionalidade(getListaPickerNacionalidade.filter(x => (x.masculino.toUpperCase() ?? "Brasileiro".toUpperCase()) == Response.nacionalidade?.masculino.toUpperCase())[0]);
                                                            await setFormularioCEP(Response.endereco?.cep ?? "");
                                                            await setFormularioLogradouro(Response.endereco?.logradouro ?? "");
                                                            await setFormularioComplemento(Response.endereco?.complemento ?? "");
                                                            await setFormularioNumeroDeEndereco(Response.endereco?.numero ?? "");
                                                            await setFormularioBairro(Response.endereco?.bairro ?? "");
                                                            await setFormularioCidade(Response.endereco?.cidade ?? "");
                                                            await setFormularioUF(Response.endereco?.uf ?? "");
                                                            await setFormularioEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                            if (Response.conjuge) {
                                                                await setFormularioConjugeNome(Response.conjuge.nome ?? "");
                                                                await setFormularioConjugeCPF(Response.conjuge.cpf ?? "");
                                                                await setFormularioConjugeEmail(Response.conjuge.emails[0].descricao ?? "");
                                                                await setFormularioConjugeDataDeNascimento(new Date(Response.conjuge.datadenascimento) ?? "");
                                                                try { await setFormularioConjugeRG(Response.conjuge.rg.numero ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGOrgaoEmissor(Response.conjuge.rg.orgaoemissor ?? ""); }
                                                                catch { }
                                                                try { await setFormularioConjugeRGUF(Response.conjuge.rg.uf ?? ""); }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneCelular = Response.conjuge.telefone?.find((Item: any) => Item.classificacao == 1);
                                                                    await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                try {
                                                                    let ConjugeTelefoneComercial = Response.conjuge.telefone?.find((Item: any) => Item.classificacao == 2);
                                                                    await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                                }
                                                                catch { }
                                                                await setFormularioConjugeProfissao(Response.conjuge.ocupacao?.nome ?? "");
                                                            }
                                                        }
                                                        catch { }
                                                        finally { await setCPFEmConsulta(false); }
                                                        break;
                                                    default:
                                                        await setCPFEmConsulta(false);
                                                        break;
                                                }
                                            }
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "Nome: " : "Razão social: "} inline={true}>
                                    <InputGroup id="Nome" placeholder="Nome" value={getFormularioNome}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioNome(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "Nascimento:" : "Data de abertura:"} labelFor="Nascimento" inline={true}>
                                    <DateInput inputProps={{ style: { width: "244px", marginLeft: "15px" }, onClick: () => setFormularioExibirCalendarioDataDeNascimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{ disabled: !getFormularioExibirCalendarioDataDeNascimento, onClosed: () => setFormularioExibirCalendarioDataDeNascimento(false) }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormularioDataDeNascimento(DataSelecionada)}
                                        value={getFormularioDataDeNascimento} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                    </DateInput>
                                </FormGroup>
                                <FormGroup label="Nacionalidade:" labelFor="Nacionalidade" inline={true}>
                                    {getListaPickerNacionalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                    <PickerNacionalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNacionalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: Objeto.Nacao) => <MenuItem onClick={async () => await setItemPickerFormularioNacionalidade(Item)} text={Item.nacionalidade ?? ""} />}
                                        itemPredicate={(Texto: string, Item: Objeto.Nacao) => { return (Item.nacionalidade ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button id="Nacionalidade" text={getItemPickerFormularioNacionalidade?.nacionalidade ?? "Selecione a nacionalidade"} rightIcon="double-caret-vertical" />
                                    </PickerNacionalidade>
                                    {getItemPickerFormularioNacionalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormularioNacionalidade(undefined); }} />}
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                <FormGroup label="Email:" inline={true}>
                                    <InputGroup id="Email" placeholder="Email" value={getFormularioEmail}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioEmail(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && <div>
                                    <FormGroup label="Tel. celular:" inline={true}>
                                        <InputGroup id="TelefoneCelular" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormularioTelefoneCelular}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioTelefoneCelular(Telefone.FormatarTexto(event.currentTarget.value))}>
                                        </InputGroup>
                                    </FormGroup>
                                </div>}
                                <FormGroup label="Tel. comercial:" inline={true}>
                                    <InputGroup id="TelefoneComercial" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormularioTelefoneComercial}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioTelefoneComercial(Telefone.FormatarTexto(event.currentTarget.value))}>
                                    </InputGroup>
                                </FormGroup>
                                {CPF.DesformatarTexto(getFormularioCPF).length <= 11 &&
                                    <FormGroup label="Profissão:" labelFor="profissão" inline={true}>
                                        <div id="profissão">
                                            <InputGroup size={30} placeholder="Informe a profissão" leftElement={<Icon icon="briefcase" />} value={getFormularioProfissao}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioProfissao(event.currentTarget.value)}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>}
                            </div>
                            {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && <div>
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                    <FormGroup label="RG:" inline={true}>
                                        <InputGroup id="RG" placeholder={"00000000000"} value={getFormularioRG}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioRG(event.currentTarget.value)}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Orgão emissor:" inline={true} >
                                        <InputGroup id="OrgaoEmissor" placeholder={"Informe o orgão emissor"} value={getFormularioRGOrgaoEmissor}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioRGOrgaoEmissor(event.currentTarget.value)}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                        <HTMLSelect id="RGUF" name="RGUF" value={getFormularioRGUF}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormularioRGUF(event.currentTarget.value)}>
                                            <option selected value="">Selecione a UF</option>
                                            {UF.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                        {getFormularioRGUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormularioRGUF(""); }} />}
                                    </FormGroup>
                                </div>
                            </div>}
                        </div>
                    </Component.ContentFormHeader>
                    {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && <Component.FormContent>
                        <Component.ContentFormHeader>
                            <Component.FormContent>
                                <FormGroup labelFor="EstadoCivil" style={{ backgroundColor: "0" }}>
                                    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", marginLeft: "-10px" }}>
                                        {getFormularioListaEstadoCivil?.map((Item) =>
                                            <Radio style={{ display: "flex", alignItems: "center", marginLeft: "10px" }} name="EstadoCivil" label={Item.descricao} value={Item.id}
                                                onChange={async () => await setFormularioEstadoCivil(Item)}
                                                checked={Item == getFormularioEstadoCivil}>
                                            </Radio>
                                        )}
                                    </div>
                                </FormGroup>
                            </Component.FormContent>
                        </Component.ContentFormHeader>
                    </Component.FormContent>}
                </Component.ContentCard>
                {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && (getFormularioEstadoCivil?.id == 2 || getFormularioEstadoCivil?.id == 7) && (<Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Dados do conjugê</h3>
                        </div>
                        <FileInput style={{ width: "calc(100% - 248px)", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoConjuge == undefined ? "Anexe o documento do conjugê" : getFormularioAnexoConjuge.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoConjuge(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoConjuge && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoConjuge(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <div className="Conteiner">
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioConjugeCPF).length <= 11 ? "CPF:" : "CNPJ:"} inline={true}>
                                    <InputGroup id="CPF" placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormularioConjugeCPF)} leftIcon={getCPFEmConsulta ? "geosearch" : false}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                            await setFormularioConjugeCPF(TextoDigitado);
                                            if (CPF.TextoValido(TextoDigitado)) {
                                                await setCPFEmConsulta(true);
                                                try {
                                                    var Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                                                        .get(`/Pessoa/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}/${TextoDigitado}`))
                                                        .data as any;
                                                    await setFormularioConjugeNome(Response.nome ?? "");
                                                    await setFormularioConjugeEmail(Response.emails[0].descricao ?? "");
                                                    await setFormularioConjugeDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                    try { await setFormularioConjugeRG(Response.rg.numero ?? ""); }
                                                    catch { }
                                                    try { await setFormularioConjugeRGOrgaoEmissor(Response.rg.orgaoEmissor ?? ""); }
                                                    catch { }
                                                    try { await setFormularioConjugeRGUF(Response.rg.uf ?? ""); }
                                                    catch { }
                                                    try {
                                                        let ConjugeTelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                        await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                    }
                                                    catch { }
                                                    try {
                                                        let ConjugeTelefoneComercial = Response.Dados.telefones.find((Item: any) => Item.classificacao == 2);
                                                        await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                    }
                                                    catch { }
                                                    await setFormularioConjugeProfissao(Response.observacao ?? "");
                                                }
                                                catch { }
                                                finally { await setCPFEmConsulta(false); }
                                            }
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioConjugeCPF).length <= 11 ? "Nome:" : "Razão social:"} inline={true}>
                                    <InputGroup id="Nome" placeholder="Nome" value={getFormularioConjugeNome}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeNome(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormularioConjugeCPF).length <= 11 ? "Nascimento:" : "Data de abertura:"} labelFor="EntradaVencimento" inline={true}>
                                    <DateInput inputProps={{ style: { width: "244px", marginLeft: "15px" }, onClick: () => setFormularioExibirCalendarioConjugeDataDeNascimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{ disabled: !getFormularioExibirCalendarioConjugeDataDeNascimento, onClosed: () => setFormularioExibirCalendarioConjugeDataDeNascimento(false) }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormularioConjugeDataDeNascimento(DataSelecionada)}
                                        value={getFormularioConjugeDataDeNascimento} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                    </DateInput>
                                </FormGroup>
                                <FormGroup label="Nacionalidade:" labelFor="Nacionalidade" inline={true}>
                                    {getListaPickerNacionalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                    <PickerNacionalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNacionalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: Objeto.Nacao) => <MenuItem onClick={async () => await setItemPickerFormularioConjugeNacionalidade(Item)} text={Item.nacionalidade ?? ""} />}
                                        itemPredicate={(Texto: string, Item: Objeto.Nacao) => { return (Item.nacionalidade ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={getItemPickerFormularioConjugeNacionalidade?.nacionalidade ?? "Selecione a nacionalidade"} rightIcon="double-caret-vertical" />
                                    </PickerNacionalidade>
                                    {getItemPickerFormularioConjugeNacionalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormularioConjugeNacionalidade(undefined); }} />}
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                <FormGroup label="Email:" inline={true}>
                                    <InputGroup id="Email" placeholder="Email" value={getFormularioConjugeEmail}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeEmail(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Tel. celular:" inline={true}>
                                    <InputGroup id="TelefoneCelular" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormularioConjugeTelefoneCelular}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeTelefoneCelular(Telefone.FormatarTexto(event.currentTarget.value))}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Tel. comercial:" inline={true}>
                                    <InputGroup id="TelefoneComercial" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormularioConjugeTelefoneComercial}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeTelefoneComercial(Telefone.FormatarTexto(event.currentTarget.value))}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Profissão:" labelFor="profissão" inline={true}>
                                    <div id="profissão">
                                        <InputGroup id="profissao" size={30} placeholder="Informe a profissão" leftElement={<Icon icon="briefcase" />} value={getFormularioConjugeProfissao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeProfissao(event.currentTarget.value)}>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: "column" }}>
                                <FormGroup label="RG:" inline={true}>
                                    <InputGroup id="RG" placeholder={"00000000000"} value={getFormularioConjugeRG}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeRG(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Orgão emissor:" inline={true} >
                                    <InputGroup id="OrgaoEmissor" placeholder={"Informe o orgão emissor"} value={getFormularioConjugeRGOrgaoEmissor}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioConjugeRGOrgaoEmissor(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                    <HTMLSelect id="RGUF" name="RGUF" value={getFormularioConjugeRGUF}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormularioConjugeRGUF(event.currentTarget.value)}>
                                        <option selected value="">Selecione a UF</option>
                                        {UF.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                    {getFormularioConjugeRGUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormularioConjugeRGUF(""); }} />}
                                </FormGroup>
                            </div>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>)}
                <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="map-marker" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Endereço</h3>
                        </div>
                        <FileInput style={{ width: "calc(100% - 150px)", minWidth: "250px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoEndereco == undefined ? "Anexe o comprovante de endereço" : getFormularioAnexoEndereco.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoEndereco(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoEndereco && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoEndereco(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <div className="Conteiner">
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0", flexDirection: "column" }}>
                                <FormGroup label="CEP:" inline={true}>
                                    <InputGroup id="CEP" placeholder="Informe o CEP" value={CEP.FormatarTexto(getFormularioCEP)} leftIcon={getCEPEmConsulta ? "geosearch" : false}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            var CEPDesformatado = CEP.DesformatarTexto(event.currentTarget.value);
                                            await setFormularioCEP(CEPDesformatado);
                                            if (CEPDesformatado.length == 8) {
                                                try {
                                                    await setCEPEmConsulta(true);
                                                    var Response = (await Correios.Get(ContextSignIn.getContext().token, CEPDesformatado)).data;
                                                    await setFormularioLogradouro(Response.Logradouro);
                                                    await setFormularioComplemento(Response.Complemento);
                                                    await setFormularioBairro(Response.Bairro);
                                                    await setFormularioCidade(Response.Cidade);
                                                    await setFormularioUF(Response.UF);
                                                }
                                                catch { }
                                                finally { await setCEPEmConsulta(false); }
                                            }
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Logradouro:" inline={true}>
                                    <InputGroup id="Logradouro" placeholder="Informe o logradouro" value={getFormularioLogradouro} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioLogradouro(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Número:" inline={true}>
                                    <InputGroup id="NumeroDeEndereco" maxLength={10} placeholder={Numero.FormatarTextoParaInteiro(0)} value={getFormularioNumeroDeEndereco} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioNumeroDeEndereco(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0", flexDirection: "column" }}>
                                <FormGroup label="Bairro:" inline={true}>
                                    <InputGroup id="Bairro" placeholder="Informe o bairro" value={getFormularioBairro} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioBairro(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Cidade:" inline={true}>
                                    <InputGroup id="Cidade" placeholder="Informe o cidade" value={getFormularioCidade} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioCidade(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                    <HTMLSelect id="uf" name="uf" value={getFormularioUF}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormularioUF(event.currentTarget.value)}>
                                        <option selected value="">Selecione a UF</option>
                                        {UF.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                    {getFormularioUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormularioUF(""); }} />}
                                </FormGroup>
                            </div>
                            <FormGroup label="Complemento:" inline={true}>
                                <InputGroup id="Complemento" placeholder="Informe o complemento" value={getFormularioComplemento} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioComplemento(event.currentTarget.value)}>
                                </InputGroup>
                            </FormGroup>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>
                <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>{getFormularioExibir02Comprador ? "Dados do 02º comprador" : "Esta venda possui 02º comprador?"}</h3>
                            <Switch style={{ margin: "10px 5px 0px 10px", alignContent: "center" }} checked={getFormularioExibir02Comprador} onChange={async () => { await setFormularioExibir02Comprador(!getFormularioExibir02Comprador) }} />
                        </div>
                        {getFormularioExibir02Comprador == true && <FileInput style={{ width: "calc(100% - 375px)", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormulario02CompradorAnexoCliente == undefined ? "Anexe o documento do 02º comprador" : getFormulario02CompradorAnexoCliente.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormulario02CompradorAnexoCliente(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />}
                        {getFormularioExibir02Comprador == true && getFormulario02CompradorAnexoCliente && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormulario02CompradorAnexoCliente(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    {getFormularioExibir02Comprador == true && <div style={{ width: "calc(100% + 15px)" }}>
                        <Component.ContentFormHeader>
                            <div className="Conteiner">
                                <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                    <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 ? "CPF:" : "CNPJ:"} inline={true}>
                                        <InputGroup id="CPF" placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormulario02CompradorCPF)} leftIcon={getCPFEmConsulta ? "geosearch" : false}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                                await setFormulario02CompradorCPF(TextoDigitado);
                                                if (CPF.TextoValido(TextoDigitado)) {
                                                    await setCPFEmConsulta(true);
                                                    try {
                                                        var Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                                                            .get(`/Pessoa/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}/${TextoDigitado}`))
                                                            .data as any;
                                                        await setFormulario02CompradorNome(Response.nome ?? "");
                                                        await setFormulario02CompradorEmail(Response.emails[0].descricao ?? "");
                                                        await setFormulario02CompradorDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                        try {
                                                            let TelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                            await setFormulario02CompradorTelefoneCelular(Telefone.FormatarTexto((TelefoneCelular?.ddd ?? "") + (TelefoneCelular?.numero ?? "")));
                                                        }
                                                        catch { }
                                                        try {
                                                            let TelefoneComercial = Response.telefones.find((Item: any) => Item.classificacao == 2);
                                                            await setFormulario02CompradorTelefoneComercial(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                        }
                                                        catch { }
                                                        try { await setFormulario02CompradorRG(Response.rg.numero ?? ""); }
                                                        catch { }
                                                        try { await setFormulario02CompradorRGOrgaoEmissor(Response.rg.orgaoEmissor ?? ""); }
                                                        catch { }
                                                        try { await setFormulario02CompradorRGUF(Response.rg.uf ?? ""); }
                                                        catch { }
                                                        await setFormulario02CompradorProfissao(Response.observacao ?? "");
                                                        await setFormulario02CompradorCEP(Response.endereco.cep ?? "");
                                                        await setFormulario02CompradorLogradouro(Response.endereco.logradouro ?? "");
                                                        await setFormulario02CompradorComplemento(Response.endereco.complemento ?? "");
                                                        await setFormulario02CompradorNumeroDeEndereco(Response.endereco.numero ?? "");
                                                        await setFormulario02CompradorBairro(Response.endereco.bairro ?? "");
                                                        await setFormulario02CompradorCidade(Response.endereco.cidade ?? "");
                                                        await setFormulario02CompradorUF(Response.endereco.uf ?? "");
                                                        await setFormulario02CompradorEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                        if (Response.conjuge) {
                                                            await setFormulario02CompradorConjugeNome(Response.conjuge.nome ?? "");
                                                            await setFormulario02CompradorConjugeCPF(Response.conjuge.cpf ?? "");
                                                            await setFormulario02CompradorConjugeEmail(Response.conjuge.emails[0].descricao ?? "");
                                                            await setFormulario02CompradorConjugeDataDeNascimento(new Date(Response.conjuge.dataDeNascimento) ?? "");
                                                            try { await setFormulario02CompradorConjugeRG(Response.conjuge.rg.numero ?? ""); }
                                                            catch { }
                                                            try { await setFormulario02CompradorConjugeRGOrgaoEmissor(Response.conjuge.rg.orgaoEmissor ?? ""); }
                                                            catch { }
                                                            try { await setFormulario02CompradorConjugeRGUF(Response.conjuge.rg.uf ?? ""); }
                                                            catch { }
                                                            try {
                                                                let ConjugeTelefoneCelular = Response.conjuge.telefones.find((Item: any) => Item.classificacao == 1);
                                                                await setFormulario02CompradorConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            try {
                                                                let ConjugeTelefoneComercial = Response.Dados.conjuge.telefones.find((Item: any) => Item.classificacao == 2);
                                                                await setFormulario02CompradorConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                            }
                                                            catch { }
                                                            await setFormulario02CompradorConjugeProfissao(Response.conjuge.observacao ?? "");
                                                        }
                                                    }
                                                    catch { }
                                                    finally { await setCPFEmConsulta(false); }
                                                }
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 ? "Nome:" : "Razão social:"} inline={true} >
                                        <InputGroup id="Nome" placeholder="Nome" value={getFormulario02CompradorNome}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorNome(event.currentTarget.value)}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 ? "Nascimento:" : "Data de abertura:"} labelFor="EntradaVencimento" inline={true}>
                                        <DateInput inputProps={{ style: { width: "244px", marginLeft: "15px" }, onClick: () => setFormulario02ExibirCalendarioCompradorDataDeNascimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{ disabled: !getFormulario02ExibirCalendarioCompradorDataDeNascimento, onClosed: () => setFormulario02ExibirCalendarioCompradorDataDeNascimento(false) }}
                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormulario02CompradorDataDeNascimento(DataSelecionada)}
                                            value={getFormulario02CompradorDataDeNascimento} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                        </DateInput>
                                    </FormGroup>
                                    <FormGroup label="Nacionalidade:" labelFor="Nacionalidade" inline={true}>
                                        {getListaPickerNacionalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                        <PickerNacionalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNacionalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(Item: Objeto.Nacao) => <MenuItem onClick={async () => await setItemPickerFormulario02CompradorNacionalidade(Item)} text={Item.nacionalidade ?? ""} />}
                                            itemPredicate={(Texto: string, Item: Objeto.Nacao) => { return (Item.nacionalidade ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button id="Nacionalidade" text={getItemPickerFormulario02CompradorNacionalidade?.nacionalidade ?? "Selecione a nacionalidade"} rightIcon="double-caret-vertical" />
                                        </PickerNacionalidade>
                                        {getItemPickerFormulario02CompradorNacionalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormularioNacionalidade(undefined); }} />}
                                    </FormGroup>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                    <FormGroup label="Email:" inline={true}>
                                        <InputGroup id="Email" placeholder="Email" value={getFormulario02CompradorEmail}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorEmail(event.currentTarget.value)}>
                                        </InputGroup>
                                    </FormGroup>
                                    {CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 && <div>
                                        <FormGroup label="Tel. celular:" inline={true}>
                                            <InputGroup id="TelefoneCelular" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormulario02CompradorTelefoneCelular}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorTelefoneCelular(Telefone.FormatarTexto(event.currentTarget.value))}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                    <FormGroup label="Tel. comercial:" inline={true}>
                                        <InputGroup id="TelefoneComercial" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormulario02CompradorTelefoneComercial}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorTelefoneComercial(Telefone.FormatarTexto(event.currentTarget.value))}>
                                        </InputGroup>
                                    </FormGroup>
                                    {CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 &&
                                        <FormGroup label="Profissão:" labelFor="profissão" inline={true}>
                                            <div id="profissão">
                                                <InputGroup id="profissao" size={30} placeholder="Informe a profissão" leftElement={<Icon icon="briefcase" />} value={getFormulario02CompradorProfissao}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorProfissao(event.currentTarget.value)}>
                                                </InputGroup>
                                            </div>
                                        </FormGroup>}
                                </div>
                                {CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 && <div>
                                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                        <FormGroup label="RG:" inline={true}>
                                            <InputGroup id="RG" placeholder={"00000000000"} value={getFormulario02CompradorRG}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorRG(event.currentTarget.value)}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup style={{ marginRight: "1vw", minWidth: "180px" }} label="Orgão emissor:" inline={true} >
                                            <InputGroup id="OrgaoEmissor" placeholder={"Informe o orgão emissor"} value={getFormulario02CompradorRGOrgaoEmissor}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorRGOrgaoEmissor(event.currentTarget.value)}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                            <HTMLSelect id="RGUF" name="RGUF" value={getFormulario02CompradorRGUF}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormulario02CompradorRGUF(event.currentTarget.value)}>
                                                <option selected value="">Selecione a UF</option>
                                                {UF.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                            {getFormulario02CompradorRGUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormulario02CompradorRGUF(""); }} />}
                                        </FormGroup>
                                    </div>
                                </div>}
                            </div>
                        </Component.ContentFormHeader>
                        {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && <Component.FormContent>
                            <Component.ContentFormHeader>
                                <Component.FormContent>
                                    <FormGroup labelFor="EstadoCivil" style={{ backgroundColor: "0" }}>
                                        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", marginLeft: "-10px" }}>
                                            {getFormularioListaEstadoCivil?.map((Item) =>
                                                <Radio name="02CompradorEstadoCivil" style={{ display: "flex", alignItems: "center", marginLeft: "10px" }} label={Item.descricao} value={Item.id}
                                                    onChange={async () => await setFormulario02CompradorEstadoCivil(Item)}
                                                    checked={Item == getFormulario02CompradorEstadoCivil}>
                                                </Radio>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Component.FormContent>
                            </Component.ContentFormHeader>
                        </Component.FormContent>}
                    </div>}
                </Component.ContentCard>
                {getFormularioExibir02Comprador == true && CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 && (getFormulario02CompradorEstadoCivil?.id == 2 || getFormulario02CompradorEstadoCivil?.id == 7) && (<Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Dados do conjugê do 02ª comprador</h3>
                        </div>
                        <FileInput style={{ width: "calc(100% - 449px)", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormulario02CompradorAnexoConjuge == undefined ? "Anexe o documento do conjugê" : getFormulario02CompradorAnexoConjuge.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormulario02CompradorAnexoConjuge(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormulario02CompradorAnexoConjuge && <Button type="submit" intent="none" onClick={async () => {
                            await setFormulario02CompradorAnexoConjuge(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <div className="Conteiner">
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorConjugeCPF).length <= 11 ? "CPF:" : "CNPJ:"} inline={true}>
                                    <InputGroup id="CPF" placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormulario02CompradorConjugeCPF)} leftIcon={getCPFEmConsulta ? "geosearch" : false}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                            await setFormulario02CompradorConjugeCPF(TextoDigitado);
                                            if (CPF.TextoValido(TextoDigitado)) {
                                                await setCPFEmConsulta(true);
                                                try {
                                                    var Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                                                        .get(`/Pessoa/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}/${TextoDigitado}`))
                                                        .data as any;
                                                    await setFormulario02CompradorConjugeNome(Response.nome ?? "");
                                                    await setFormulario02CompradorConjugeEmail(Response.emails[0].descricao ?? "");
                                                    await setFormulario02CompradorConjugeDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                    try { await setFormulario02CompradorConjugeRG(Response.rg.numero ?? ""); }
                                                    catch { }
                                                    try { await setFormulario02CompradorConjugeRGOrgaoEmissor(Response.rg.orgaoEmissor ?? ""); }
                                                    catch { }
                                                    try { await setFormulario02CompradorConjugeRGUF(Response.rg.uf ?? ""); }
                                                    catch { }
                                                    try {
                                                        let ConjugeTelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                        await setFormulario02CompradorConjugeTelefoneCelular(Telefone.FormatarTexto((ConjugeTelefoneCelular?.ddd ?? "") + (ConjugeTelefoneCelular?.numero ?? "")));
                                                    }
                                                    catch { }
                                                    try {
                                                        let ConjugeTelefoneComercial = Response.Dados.telefones.find((Item: any) => Item.classificacao == 2);
                                                        await setFormulario02CompradorConjugeTelefoneComercial(Telefone.FormatarTexto((ConjugeTelefoneComercial?.ddd ?? "") + (ConjugeTelefoneComercial?.numero ?? "")));
                                                    }
                                                    catch { }
                                                    await setFormulario02CompradorConjugeProfissao(Response.observacao ?? "");
                                                }
                                                catch { }
                                                finally { await setCPFEmConsulta(false); }
                                            }
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 ? "Nome:" : "Razão social:"} inline={true}>
                                    <InputGroup id="Nome" placeholder="Nome" value={getFormulario02CompradorConjugeNome}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeNome(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label={CPF.DesformatarTexto(getFormulario02CompradorCPF).length <= 11 ? "Nascimento:" : "Data de abertura:"} labelFor="EntradaVencimento" inline={true}>
                                    <DateInput inputProps={{ style: { width: "244pxx", marginLeft: "15px" }, onClick: () => setFormulario02ExibirCalendarioCompradorConjugeDataDeNascimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{ disabled: !getFormulario02ExibirCalendarioCompradorConjugeDataDeNascimento, onClosed: () => setFormulario02ExibirCalendarioCompradorConjugeDataDeNascimento(false) }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setFormulario02CompradorConjugeDataDeNascimento(DataSelecionada)}
                                        value={getFormulario02CompradorConjugeDataDeNascimento} minDate={new Date((new Date().getFullYear() - 100).toString() + "-01-01")} maxDate={new Date()}>
                                    </DateInput>
                                </FormGroup>
                                <FormGroup label="Nacionalidade:" labelFor="Nacionalidade" inline={true}>
                                    {getListaPickerNacionalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                    <PickerNacionalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNacionalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: Objeto.Nacao) => <MenuItem onClick={async () => await setItemPickerFormulario02CompradorConjugeNacionalidade(Item)} text={Item.nacionalidade ?? ""} />}
                                        itemPredicate={(Texto: string, Item: Objeto.Nacao) => { return (Item.nacionalidade ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button id="Nacionalidade" text={getItemPickerFormulario02CompradorConjugeNacionalidade?.nacionalidade ?? "Selecione a nacionalidade"} rightIcon="double-caret-vertical" />
                                    </PickerNacionalidade>
                                    {getItemPickerFormulario02CompradorConjugeNacionalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFormularioNacionalidade(undefined); }} />}
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                <FormGroup label="Email:" inline={true}>
                                    <InputGroup id="Email" placeholder="Email" value={getFormulario02CompradorConjugeEmail}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeEmail(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Tel. celular:" inline={true}>
                                    <InputGroup id="TelefoneCelular" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormulario02CompradorConjugeTelefoneCelular}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeTelefoneCelular(Telefone.FormatarTexto(event.currentTarget.value))}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Tel. comercial:" inline={true}>
                                    <InputGroup id="TelefoneComercial" placeholder={Telefone.FormatarTexto("00000000000")} value={getFormulario02CompradorConjugeTelefoneComercial}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeTelefoneComercial(Telefone.FormatarTexto(event.currentTarget.value))}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Profissão:" labelFor="profissão" inline={true}>
                                    <div id="profissão">
                                        <InputGroup id="profissao" size={30} placeholder="Informe a profissão" leftElement={<Icon icon="briefcase" />} value={getFormulario02CompradorConjugeProfissao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeProfissao(event.currentTarget.value)}>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                                <FormGroup label="RG:" inline={true}>
                                    <InputGroup id="RG" placeholder={"00000000000"} value={getFormulario02CompradorConjugeRG}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeRG(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Orgão emissor:" inline={true} >
                                    <InputGroup id="OrgaoEmissor" placeholder={"Informe o orgão emissor"} value={getFormulario02CompradorConjugeRGOrgaoEmissor}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorConjugeRGOrgaoEmissor(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                    <HTMLSelect id="RGUF" name="RGUF" value={getFormulario02CompradorConjugeRGUF}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormulario02CompradorConjugeRGUF(event.currentTarget.value)}>
                                        <option selected value="">Selecione a UF</option>
                                        {UF.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                    {getFormulario02CompradorConjugeRGUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormulario02CompradorConjugeRGUF(""); }} />}
                                </FormGroup>
                            </div>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>)}
                {getFormularioExibir02Comprador == true && <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="map-marker" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Endereço do 02ª comprador</h3>
                        </div>
                        <FileInput style={{ width: "calc(100% - 355px)", minWidth: "300px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormulario02CompradorAnexoEndereco == undefined ? "Anexe o comprovante de endereço" : getFormulario02CompradorAnexoEndereco.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormulario02CompradorAnexoEndereco(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormulario02CompradorAnexoEndereco && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormulario02CompradorAnexoEndereco(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <div className="Conteiner">
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0", flexDirection: "column" }}>
                                <FormGroup label="CEP:" inline={true}>
                                    <InputGroup id="CEP" placeholder="Informe o CEP" value={CEP.FormatarTexto(getFormulario02CompradorCEP)} leftIcon={getCEPEmConsulta ? "geosearch" : false}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            var CEPDesformatado = CEP.DesformatarTexto(event.currentTarget.value);
                                            await setFormulario02CompradorCEP(CEPDesformatado);
                                            if (CEPDesformatado.length == 8) {
                                                try {
                                                    await setCEPEmConsulta(true);
                                                    var Response = (await Correios.Get(ContextSignIn.getContext().token, CEPDesformatado)).data;
                                                    await setFormulario02CompradorLogradouro(Response.Logradouro);
                                                    await setFormulario02CompradorComplemento(Response.Complemento);
                                                    await setFormulario02CompradorBairro(Response.Bairro);
                                                    await setFormulario02CompradorCidade(Response.Cidade);
                                                    await setFormulario02CompradorUF(Response.UF);
                                                }
                                                catch { }
                                                finally { await setCEPEmConsulta(false); }
                                            }
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Logradouro:" inline={true}>
                                    <InputGroup id="Logradouro" placeholder="Informe o logradouro" value={getFormulario02CompradorLogradouro} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorLogradouro(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Número:" inline={true}>
                                    <InputGroup id="NumeroDeEndereco" placeholder={Numero.FormatarTextoParaInteiro(0)} value={getFormulario02CompradorNumeroDeEndereco} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorNumeroDeEndereco(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0", flexDirection: "column" }}>
                                <FormGroup label="Bairro:" inline={true}>
                                    <InputGroup id="Bairro" placeholder="Informe o bairro" value={getFormulario02CompradorBairro} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorBairro(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Cidade:" inline={true}>
                                    <InputGroup id="Cidade" placeholder="Informe o cidade" value={getFormulario02CompradorCidade} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorCidade(event.currentTarget.value)}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="UF:" labelFor="celphone" inline={true}>
                                    <HTMLSelect id="uf" name="uf" value={getFormulario02CompradorUF}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => await setFormulario02CompradorUF(event.currentTarget.value)}>
                                        <option selected value="">Selecione a UF</option>
                                        {UF.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                    {getFormulario02CompradorUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setFormulario02CompradorUF(""); }} />}
                                </FormGroup>
                            </div>
                            <FormGroup label="Complemento:" inline={true}>
                                <InputGroup id="Complemento" placeholder="Informe o complemento" value={getFormulario02CompradorComplemento} style={{ cursor: getCEPEmConsulta ? "not-allowed" : "auto" }} readOnly={getCEPEmConsulta}
                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormulario02CompradorComplemento(event.currentTarget.value)}>
                                </InputGroup>
                            </FormGroup>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>}
                <Component.ContentCard>
                    <Component.Title>
                        <Icon icon="office" iconSize={25} />
                        <h3>Dados do Produto</h3>
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <FormGroup label="Empreendimento:" labelFor="Empreendimento" inline={true} style={{ flexWrap: "wrap" }}>
                            <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => {
                                    ItemPickerCentroDeCusto = Item;
                                    connectToRoom(Item.sigla);
                                    await setItemPickerCentroDeCusto(Item);
                                    await setItemPickerIdentificador(undefined);
                                    await setItemPickerIdentificadorSintetico(undefined);
                                    await setListaPickerIdentificador([]);
                                    await setListaPickerIdentificadorSintetico([]);
                                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                                    await setListaPickerIdentificadorSintetico((await Identificador.FastGetSintetico(ContextSignIn.getContext().token, true, Item.empresa.id ?? 0, Item.sigla ?? "")).data.filter((_Item) => _Item.status == 0 || (_Item.status == 2 && _Item.reservaVinculadaVendedorId == ContextSignIn.getContext().pessoa.id)));
                                    console.log((await Identificador.FastGetSintetico(ContextSignIn.getContext().token, true, Item.empresa.id ?? 0, Item.sigla ?? "")).data.filter((_Item) => _Item.status == 0 || (_Item.status == 2 && _Item.reservaVinculadaVendedorId == ContextSignIn.getContext().pessoa.id)))
                                }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button id="Empreendimento" text={getItemPickerCentroDeCusto?.descricao ?? "Selecione o empreendimento"} rightIcon="double-caret-vertical" />
                                {getListaPickerCentroDeCusto.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px" }} />}
                            </PickerCentroDeCusto>
                            {getItemPickerCentroDeCusto && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerCentroDeCusto(undefined); }} />}
                        </FormGroup>
                        <div style={{ display: "flex", padding: "0" }}>
                            <FormGroup label="Unidade:" labelFor="Unidade" inline={true}>
                                <Component.FormContent>

                                    <PickerIdentificadorMemo
                                        listaIdentificadorSintetico={getListaPickerIdentificadorSintetico}
                                        ItemPickerCentroDeCusto={getItemPickerCentroDeCusto}
                                        ItemPickerIdentificadorSintetico={getItemPickerIdentificadorSintetico}
                                        ItemRender={(Item: Objeto.IdentificadorSintetico) =>
                                            <MenuItem onClick={async () => {

                                                if (getItemPickerIdentificadorSintetico && getItemPickerIdentificadorSintetico.subLocalId != Item.subLocalId) {
                                                    if (getObjetoPropostaDeVenda != null && getObjetoPropostaDeVenda.identificador.subLocal.id == getItemPickerIdentificadorSintetico.subLocalId && getObjetoPropostaDeVenda.identificador.status == getItemPickerIdentificadorSintetico.status) {
                                                        ItemPickerIdentificadorSinteticao = Item;
                                                        await setItemPickerIdentificadorSintetico(Item);

                                                        if (Item.status == 0) {

                                                            NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                            let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                            if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);

                                                                if (Math.floor(Response.status / 100) == 2) {
                                                                    let ListaDeUnidadesDisponibilidade = [...getListaPickerIdentificadorSintetico];
                                                                    Item.status = 2;

                                                                    ItemPickerIdentificadorSinteticao = Item;
                                                                    await setItemPickerIdentificadorSintetico(Item);
                                                                    ResponseUnidade.data[0].status = 2;
                                                                    await setItemPickerIdentificador(ResponseUnidade.data[0]);

                                                                    await setListaPickerIdentificadorSintetico(ListaDeUnidadesDisponibilidade);
                                                                    sendUnidadeSintetico(Item, Item.centroDeCustoSigla, ListaDeUnidadesDisponibilidade, []);
                                                                    NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                    if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                        Toast(
                                                                            <div>
                                                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            await setFormularioEntradaQtde(0);
                                                                                            await setFormularioListaDeEntradas([]);
                                                                                            await setFormularioSinalQtde(0);
                                                                                            await setFormularioListaDeSinais([]);
                                                                                            await setFormularioParcelaValor(0);
                                                                                            await setFormularioParcelaQtde(0);
                                                                                            await setFormularioParcelaVencimento(undefined);
                                                                                            await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                            await setItemPickerFinalidadeDeCompra(undefined);
                                                                                            await setListaCondicaoDaTabelaDeVenda([]);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        }}>Sim</Button>
                                                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                            const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                            NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                        }}>Não</Button>
                                                                                </div>
                                                                            </div>, {
                                                                            position: Toast.POSITION.TOP_CENTER,
                                                                            autoClose: false,
                                                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                        });
                                                                    }
                                                                    else {
                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                        await setFormularioEntradaQtde(0);
                                                                        await setFormularioListaDeEntradas([]);
                                                                        await setFormularioSinalQtde(0);
                                                                        await setFormularioListaDeSinais([]);
                                                                        await setFormularioParcelaValor(0);
                                                                        await setFormularioParcelaQtde(0);
                                                                        await setFormularioParcelaVencimento(undefined);
                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                    }
                                                                }
                                                                else {
                                                                    NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            Item.status = 2;

                                                            let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                            if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                NotificacaoInterna.ExibirNotificacao("Reservado", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                    Toast(
                                                                        <div>
                                                                            <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        await setFormularioEntradaQtde(0);
                                                                                        await setFormularioListaDeEntradas([]);
                                                                                        await setFormularioSinalQtde(0);
                                                                                        await setFormularioListaDeSinais([]);
                                                                                        await setFormularioParcelaValor(0);
                                                                                        await setFormularioParcelaQtde(0);
                                                                                        await setFormularioParcelaVencimento(undefined);
                                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                                        await setListaCondicaoDaTabelaDeVenda([]);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                    }}>Sim</Button>
                                                                                <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                        const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                        NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                    }}>Não</Button>
                                                                            </div>
                                                                        </div>, {
                                                                        position: Toast.POSITION.TOP_CENTER,
                                                                        autoClose: false,
                                                                        style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                    });
                                                                }
                                                                else {
                                                                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                    await setFormularioEntradaQtde(0);
                                                                    await setFormularioListaDeEntradas([]);
                                                                    await setFormularioSinalQtde(0);
                                                                    await setFormularioListaDeSinais([]);
                                                                    await setFormularioParcelaValor(0);
                                                                    await setFormularioParcelaQtde(0);
                                                                    await setFormularioParcelaVencimento(undefined);
                                                                    await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                    await setItemPickerFinalidadeDeCompra(undefined);
                                                                }
                                                            }
                                                        }

                                                    }
                                                    else {

                                                        NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                        let ResponseDesfazendoReserva = await Identificador.DeletarReservas(ContextSignIn.getContext().token, [getItemPickerIdentificador ?? {} as Objeto.Identificador])

                                                        if (Math.floor(ResponseDesfazendoReserva.status / 100) == 2) {

                                                            let ListaDosIdentificadores = [...getListaPickerIdentificadorSintetico];

                                                            ListaDosIdentificadores.map(lote => {
                                                                if (lote.subLocalId == getItemPickerIdentificadorSintetico.subLocalId) {
                                                                    lote.status = 0;
                                                                }
                                                            });

                                                            let IdentificadorAnterior = { ...getItemPickerIdentificadorSintetico };

                                                            IdentificadorAnterior.status = 0;

                                                            setListaPickerIdentificadorSintetico(ListaDosIdentificadores);

                                                            console.log(IdentificadorAnterior);

                                                            sendUnidadeSintetico(IdentificadorAnterior, IdentificadorAnterior.centroDeCustoSigla, ListaDosIdentificadores, []);

                                                            ItemPickerIdentificadorSinteticao = Item;
                                                            await setItemPickerIdentificadorSintetico(Item);

                                                            if (Item.status == 0) {

                                                                let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                                if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                    let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);

                                                                    if (Math.floor(Response.status / 100) == 2) {
                                                                        let ListaDeUnidadesDisponibilidade = [...getListaPickerIdentificadorSintetico];
                                                                        Item.status = 2;

                                                                        ItemPickerIdentificadorSinteticao = Item;
                                                                        await setItemPickerIdentificadorSintetico(Item);
                                                                        ResponseUnidade.data[0].status = 2;
                                                                        await setItemPickerIdentificador(ResponseUnidade.data[0]);

                                                                        await setListaPickerIdentificadorSintetico(ListaDeUnidadesDisponibilidade);
                                                                        sendUnidadeSintetico(Item, Item.centroDeCustoSigla, ListaDeUnidadesDisponibilidade, []);
                                                                        NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                        if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                            Toast(
                                                                                <div>
                                                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                            onClick={async () => {
                                                                                                await setFormularioEntradaQtde(0);
                                                                                                await setFormularioListaDeEntradas([]);
                                                                                                await setFormularioSinalQtde(0);
                                                                                                await setFormularioListaDeSinais([]);
                                                                                                await setFormularioParcelaValor(0);
                                                                                                await setFormularioParcelaQtde(0);
                                                                                                await setFormularioParcelaVencimento(undefined);
                                                                                                await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                                await setItemPickerFinalidadeDeCompra(undefined);
                                                                                                await setListaCondicaoDaTabelaDeVenda([]);
                                                                                                await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                                await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                                const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                                await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                                await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                            }}>Sim</Button>
                                                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                            onClick={async () => {
                                                                                                NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                                const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                                const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                                await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                                await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                                await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                                NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                            }}>Não</Button>
                                                                                    </div>
                                                                                </div>, {
                                                                                position: Toast.POSITION.TOP_CENTER,
                                                                                autoClose: false,
                                                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                            });
                                                                        }
                                                                        else {
                                                                            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                            await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                            await setFormularioEntradaQtde(0);
                                                                            await setFormularioListaDeEntradas([]);
                                                                            await setFormularioSinalQtde(0);
                                                                            await setFormularioListaDeSinais([]);
                                                                            await setFormularioParcelaValor(0);
                                                                            await setFormularioParcelaQtde(0);
                                                                            await setFormularioParcelaVencimento(undefined);
                                                                            await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                            await setItemPickerFinalidadeDeCompra(undefined);
                                                                        }
                                                                    }
                                                                    else {
                                                                        NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                Item.status = 2;

                                                                let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                                if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                    ResponseUnidade.data[0].status = 2;
                                                                    await setItemPickerIdentificador(ResponseUnidade.data[0]);
                                                                    NotificacaoInterna.ExibirNotificacao("Reservado", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                    if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                        Toast(
                                                                            <div>
                                                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            await setFormularioEntradaQtde(0);
                                                                                            await setFormularioListaDeEntradas([]);
                                                                                            await setFormularioSinalQtde(0);
                                                                                            await setFormularioListaDeSinais([]);
                                                                                            await setFormularioParcelaValor(0);
                                                                                            await setFormularioParcelaQtde(0);
                                                                                            await setFormularioParcelaVencimento(undefined);
                                                                                            await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                            await setItemPickerFinalidadeDeCompra(undefined);
                                                                                            await setListaCondicaoDaTabelaDeVenda([]);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        }}>Sim</Button>
                                                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                            const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                            NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                        }}>Não</Button>
                                                                                </div>
                                                                            </div>, {
                                                                            position: Toast.POSITION.TOP_CENTER,
                                                                            autoClose: false,
                                                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                        });
                                                                    }
                                                                    else {
                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                        await setFormularioEntradaQtde(0);
                                                                        await setFormularioListaDeEntradas([]);
                                                                        await setFormularioSinalQtde(0);
                                                                        await setFormularioListaDeSinais([]);
                                                                        await setFormularioParcelaValor(0);
                                                                        await setFormularioParcelaQtde(0);
                                                                        await setFormularioParcelaVencimento(undefined);
                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else { }

                                                    }
                                                }
                                                else {

                                                    ItemPickerIdentificadorSinteticao = Item;
                                                    await setItemPickerIdentificadorSintetico(Item);

                                                    if (Item.status == 0) {

                                                        NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                        let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                        if (Math.floor(ResponseUnidade.status / 100) == 2) {

                                                            let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);

                                                            if (Math.floor(Response.status / 100) == 2) {
                                                                let ListaDeUnidadesDisponibilidade = [...getListaPickerIdentificadorSintetico];
                                                                Item.status = 2;
                                                                ResponseUnidade.data[0].status = 2;

                                                                await setListaPickerIdentificadorSintetico(ListaDeUnidadesDisponibilidade);
                                                                await setItemPickerIdentificador(ResponseUnidade.data[0]);
                                                                sendUnidadeSintetico(Item, Item.centroDeCustoSigla, ListaDeUnidadesDisponibilidade, []);
                                                                NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                    Toast(
                                                                        <div>
                                                                            <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        await setFormularioEntradaQtde(0);
                                                                                        await setFormularioListaDeEntradas([]);
                                                                                        await setFormularioSinalQtde(0);
                                                                                        await setFormularioListaDeSinais([]);
                                                                                        await setFormularioParcelaValor(0);
                                                                                        await setFormularioParcelaQtde(0);
                                                                                        await setFormularioParcelaVencimento(undefined);
                                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                                        await setListaCondicaoDaTabelaDeVenda([]);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                    }}>Sim</Button>
                                                                                <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                        const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                        NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                    }}>Não</Button>
                                                                            </div>
                                                                        </div>, {
                                                                        position: Toast.POSITION.TOP_CENTER,
                                                                        autoClose: false,
                                                                        style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                    });
                                                                }
                                                                else {
                                                                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                    await setFormularioEntradaQtde(0);
                                                                    await setFormularioListaDeEntradas([]);
                                                                    await setFormularioSinalQtde(0);
                                                                    await setFormularioListaDeSinais([]);
                                                                    await setFormularioParcelaValor(0);
                                                                    await setFormularioParcelaQtde(0);
                                                                    await setFormularioParcelaVencimento(undefined);
                                                                    await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                    await setItemPickerFinalidadeDeCompra(undefined);
                                                                }
                                                            }
                                                            else {
                                                                NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                            }
                                                        }

                                                    }
                                                    else {
                                                        Item.status = 2;
                                                        NotificacaoInterna.ExibirNotificacao("Reservado", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                                        let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                        if (Math.floor(ResponseUnidade.status / 100) == 2) {

                                                            await setItemPickerIdentificador(ResponseUnidade.data[0]);

                                                            if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                Toast(
                                                                    <div>
                                                                        <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                            <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => {
                                                                                    await setFormularioEntradaQtde(0);
                                                                                    await setFormularioListaDeEntradas([]);
                                                                                    await setFormularioSinalQtde(0);
                                                                                    await setFormularioListaDeSinais([]);
                                                                                    await setFormularioParcelaValor(0);
                                                                                    await setFormularioParcelaQtde(0);
                                                                                    await setFormularioParcelaVencimento(undefined);
                                                                                    await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                    await setItemPickerFinalidadeDeCompra(undefined);
                                                                                    await setListaCondicaoDaTabelaDeVenda([]);
                                                                                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                }}>Sim</Button>
                                                                            <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => {
                                                                                    NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                    const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                    await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                    NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                }}>Não</Button>
                                                                        </div>
                                                                    </div>, {
                                                                    position: Toast.POSITION.TOP_CENTER,
                                                                    autoClose: false,
                                                                    style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                });
                                                            }
                                                            else {
                                                                await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                await setFormularioEntradaQtde(0);
                                                                await setFormularioListaDeEntradas([]);
                                                                await setFormularioSinalQtde(0);
                                                                await setFormularioListaDeSinais([]);
                                                                await setFormularioParcelaValor(0);
                                                                await setFormularioParcelaQtde(0);
                                                                await setFormularioParcelaVencimento(undefined);
                                                                await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                await setItemPickerFinalidadeDeCompra(undefined);
                                                            }

                                                        }
                                                    }

                                                }

                                            }} text={Item.subLocalDescricao ? Item.subLocalDescricao : ""} />}
                                        ItemPredicate={(Texto: string, Item: Objeto.IdentificadorSintetico) => {
                                            return (Item.subLocalDescricao ? Item.subLocalDescricao : "").toUpperCase().includes(Texto.toUpperCase()) || Texto == ""
                                        }} />

                                    {/** COMPONENTE SEM MEMO */}
                                    {false && <PickerIdentificador popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerIdentificadorSintetico} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemListRenderer={({ items, itemsParentRef, query, renderItem }) => {
                                            let renderedItems = items.filter((Item) => (Item.subLocalDescricao ? Item.subLocalDescricao : "").toUpperCase().includes(query.toUpperCase()) || query == "").map(renderItem).filter((Item) => Item != null);
                                            return (
                                                <Menu ulRef={itemsParentRef}>
                                                    <MenuItem
                                                        disabled={true}
                                                        text={`${renderedItems.length} ${renderedItems.length > 1 ? "items encontrados" : "item encontrado"}`}
                                                    />
                                                    {renderedItems.slice(0, 99)}
                                                    <MenuItem
                                                        disabled={true}
                                                        text={`${renderedItems.length > 100 ? " (apenas os 100 primeiros itens serão exibidos)" : ""}`}
                                                    />
                                                </Menu>
                                            );
                                        }}
                                        itemRenderer={(Item: Objeto.IdentificadorSintetico) =>
                                            <MenuItem onClick={async () => {

                                                if (getItemPickerIdentificadorSintetico && getItemPickerIdentificadorSintetico.subLocalId != Item.subLocalId) {
                                                    if (getObjetoPropostaDeVenda != null && getObjetoPropostaDeVenda.identificador.subLocal.id == getItemPickerIdentificadorSintetico.subLocalId && getObjetoPropostaDeVenda.identificador.status == getItemPickerIdentificadorSintetico.status) {

                                                        ItemPickerIdentificadorSinteticao = Item;
                                                        await setItemPickerIdentificadorSintetico(Item);

                                                        if (Item.status == 0) {

                                                            NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                            let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                            if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);

                                                                if (Math.floor(Response.status / 100) == 2) {
                                                                    let ListaDeUnidadesDisponibilidade = [...getListaPickerIdentificadorSintetico];
                                                                    Item.status = 2;

                                                                    ItemPickerIdentificadorSinteticao = Item;
                                                                    await setItemPickerIdentificadorSintetico(Item);
                                                                    ResponseUnidade.data[0].status = 2;
                                                                    await setItemPickerIdentificador(ResponseUnidade.data[0]);

                                                                    await setListaPickerIdentificadorSintetico(ListaDeUnidadesDisponibilidade);
                                                                    sendUnidadeSintetico(Item, Item.centroDeCustoSigla, ListaDeUnidadesDisponibilidade, []);
                                                                    NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                    if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                        Toast(
                                                                            <div>
                                                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            await setFormularioEntradaQtde(0);
                                                                                            await setFormularioListaDeEntradas([]);
                                                                                            await setFormularioSinalQtde(0);
                                                                                            await setFormularioListaDeSinais([]);
                                                                                            await setFormularioParcelaValor(0);
                                                                                            await setFormularioParcelaQtde(0);
                                                                                            await setFormularioParcelaVencimento(undefined);
                                                                                            await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                            await setItemPickerFinalidadeDeCompra(undefined);
                                                                                            await setListaCondicaoDaTabelaDeVenda([]);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        }}>Sim</Button>
                                                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                            const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                            NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                        }}>Não</Button>
                                                                                </div>
                                                                            </div>, {
                                                                            position: Toast.POSITION.TOP_CENTER,
                                                                            autoClose: false,
                                                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                        });
                                                                    }
                                                                    else {
                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                        await setFormularioEntradaQtde(0);
                                                                        await setFormularioListaDeEntradas([]);
                                                                        await setFormularioSinalQtde(0);
                                                                        await setFormularioListaDeSinais([]);
                                                                        await setFormularioParcelaValor(0);
                                                                        await setFormularioParcelaQtde(0);
                                                                        await setFormularioParcelaVencimento(undefined);
                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                    }
                                                                }
                                                                else {
                                                                    NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            Item.status = 2;

                                                            let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                            if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                NotificacaoInterna.ExibirNotificacao("Reservado", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                    Toast(
                                                                        <div>
                                                                            <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        await setFormularioEntradaQtde(0);
                                                                                        await setFormularioListaDeEntradas([]);
                                                                                        await setFormularioSinalQtde(0);
                                                                                        await setFormularioListaDeSinais([]);
                                                                                        await setFormularioParcelaValor(0);
                                                                                        await setFormularioParcelaQtde(0);
                                                                                        await setFormularioParcelaVencimento(undefined);
                                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                                        await setListaCondicaoDaTabelaDeVenda([]);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                    }}>Sim</Button>
                                                                                <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                        const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                        NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                    }}>Não</Button>
                                                                            </div>
                                                                        </div>, {
                                                                        position: Toast.POSITION.TOP_CENTER,
                                                                        autoClose: false,
                                                                        style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                    });
                                                                }
                                                                else {
                                                                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                    await setFormularioEntradaQtde(0);
                                                                    await setFormularioListaDeEntradas([]);
                                                                    await setFormularioSinalQtde(0);
                                                                    await setFormularioListaDeSinais([]);
                                                                    await setFormularioParcelaValor(0);
                                                                    await setFormularioParcelaQtde(0);
                                                                    await setFormularioParcelaVencimento(undefined);
                                                                    await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                    await setItemPickerFinalidadeDeCompra(undefined);
                                                                }
                                                            }
                                                        }

                                                    }
                                                    else {

                                                        NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                        console.log(getItemPickerIdentificador)

                                                        let ResponseDesfazendoReserva = await Identificador.DeletarReservas(ContextSignIn.getContext().token, [getItemPickerIdentificador ?? {} as Objeto.Identificador])

                                                        if (Math.floor(ResponseDesfazendoReserva.status / 100) == 2) {

                                                            let ListaDosIdentificadores = [...getListaPickerIdentificadorSintetico];

                                                            ListaDosIdentificadores.map(lote => {
                                                                if (lote.subLocalId == getItemPickerIdentificadorSintetico.subLocalId) {
                                                                    lote.status = 0;
                                                                }
                                                            });

                                                            let IdentificadorAnterior = { ...getItemPickerIdentificadorSintetico };

                                                            IdentificadorAnterior.status = 0;

                                                            setListaPickerIdentificadorSintetico(ListaDosIdentificadores);

                                                            console.log(IdentificadorAnterior);

                                                            sendUnidadeSintetico(IdentificadorAnterior, IdentificadorAnterior.centroDeCustoSigla, ListaDosIdentificadores, []);

                                                            ItemPickerIdentificadorSinteticao = Item;
                                                            await setItemPickerIdentificadorSintetico(Item);

                                                            if (Item.status == 0) {

                                                                let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                                if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                    let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);

                                                                    if (Math.floor(Response.status / 100) == 2) {
                                                                        let ListaDeUnidadesDisponibilidade = [...getListaPickerIdentificadorSintetico];
                                                                        Item.status = 2;

                                                                        ItemPickerIdentificadorSinteticao = Item;
                                                                        await setItemPickerIdentificadorSintetico(Item);
                                                                        ResponseUnidade.data[0].status = 2;
                                                                        await setItemPickerIdentificador(ResponseUnidade.data[0]);

                                                                        await setListaPickerIdentificadorSintetico(ListaDeUnidadesDisponibilidade);
                                                                        sendUnidadeSintetico(Item, Item.centroDeCustoSigla, ListaDeUnidadesDisponibilidade, []);
                                                                        NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                        if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                            Toast(
                                                                                <div>
                                                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                            onClick={async () => {
                                                                                                await setFormularioEntradaQtde(0);
                                                                                                await setFormularioListaDeEntradas([]);
                                                                                                await setFormularioSinalQtde(0);
                                                                                                await setFormularioListaDeSinais([]);
                                                                                                await setFormularioParcelaValor(0);
                                                                                                await setFormularioParcelaQtde(0);
                                                                                                await setFormularioParcelaVencimento(undefined);
                                                                                                await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                                await setItemPickerFinalidadeDeCompra(undefined);
                                                                                                await setListaCondicaoDaTabelaDeVenda([]);
                                                                                                await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                                await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                                const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                                await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                                await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                            }}>Sim</Button>
                                                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                            onClick={async () => {
                                                                                                NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                                const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                                const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                                await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                                await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                                await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                                NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                            }}>Não</Button>
                                                                                    </div>
                                                                                </div>, {
                                                                                position: Toast.POSITION.TOP_CENTER,
                                                                                autoClose: false,
                                                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                            });
                                                                        }
                                                                        else {
                                                                            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                            await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                            await setFormularioEntradaQtde(0);
                                                                            await setFormularioListaDeEntradas([]);
                                                                            await setFormularioSinalQtde(0);
                                                                            await setFormularioListaDeSinais([]);
                                                                            await setFormularioParcelaValor(0);
                                                                            await setFormularioParcelaQtde(0);
                                                                            await setFormularioParcelaVencimento(undefined);
                                                                            await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                            await setItemPickerFinalidadeDeCompra(undefined);
                                                                        }
                                                                    }
                                                                    else {
                                                                        NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                Item.status = 2;

                                                                let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                                if (Math.floor(ResponseUnidade.status / 100) == 2) {
                                                                    ResponseUnidade.data[0].status = 2;
                                                                    await setItemPickerIdentificador(ResponseUnidade.data[0]);
                                                                    NotificacaoInterna.ExibirNotificacao("Reservado", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                    if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                        Toast(
                                                                            <div>
                                                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            await setFormularioEntradaQtde(0);
                                                                                            await setFormularioListaDeEntradas([]);
                                                                                            await setFormularioSinalQtde(0);
                                                                                            await setFormularioListaDeSinais([]);
                                                                                            await setFormularioParcelaValor(0);
                                                                                            await setFormularioParcelaQtde(0);
                                                                                            await setFormularioParcelaVencimento(undefined);
                                                                                            await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                            await setItemPickerFinalidadeDeCompra(undefined);
                                                                                            await setListaCondicaoDaTabelaDeVenda([]);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        }}>Sim</Button>
                                                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                        onClick={async () => {
                                                                                            NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                            const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                            const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                            await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                            await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                            await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                            NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                        }}>Não</Button>
                                                                                </div>
                                                                            </div>, {
                                                                            position: Toast.POSITION.TOP_CENTER,
                                                                            autoClose: false,
                                                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                        });
                                                                    }
                                                                    else {
                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                        await setFormularioEntradaQtde(0);
                                                                        await setFormularioListaDeEntradas([]);
                                                                        await setFormularioSinalQtde(0);
                                                                        await setFormularioListaDeSinais([]);
                                                                        await setFormularioParcelaValor(0);
                                                                        await setFormularioParcelaQtde(0);
                                                                        await setFormularioParcelaVencimento(undefined);
                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else { }

                                                    }
                                                }
                                                else {

                                                    ItemPickerIdentificadorSinteticao = Item;
                                                    await setItemPickerIdentificadorSintetico(Item);

                                                    if (Item.status == 0) {

                                                        NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                        let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                        if (Math.floor(ResponseUnidade.status / 100) == 2) {

                                                            let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);

                                                            if (Math.floor(Response.status / 100) == 2) {
                                                                let ListaDeUnidadesDisponibilidade = [...getListaPickerIdentificadorSintetico];
                                                                Item.status = 2;
                                                                ResponseUnidade.data[0].status = 2;

                                                                await setListaPickerIdentificadorSintetico(ListaDeUnidadesDisponibilidade);
                                                                await setItemPickerIdentificador(ResponseUnidade.data[0]);
                                                                sendUnidadeSintetico(Item, Item.centroDeCustoSigla, ListaDeUnidadesDisponibilidade, []);
                                                                NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                    Toast(
                                                                        <div>
                                                                            <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        await setFormularioEntradaQtde(0);
                                                                                        await setFormularioListaDeEntradas([]);
                                                                                        await setFormularioSinalQtde(0);
                                                                                        await setFormularioListaDeSinais([]);
                                                                                        await setFormularioParcelaValor(0);
                                                                                        await setFormularioParcelaQtde(0);
                                                                                        await setFormularioParcelaVencimento(undefined);
                                                                                        await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                        await setItemPickerFinalidadeDeCompra(undefined);
                                                                                        await setListaCondicaoDaTabelaDeVenda([]);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                    }}>Sim</Button>
                                                                                <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                        const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                        const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                        await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                        await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                        await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                        NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                    }}>Não</Button>
                                                                            </div>
                                                                        </div>, {
                                                                        position: Toast.POSITION.TOP_CENTER,
                                                                        autoClose: false,
                                                                        style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                    });
                                                                }
                                                                else {
                                                                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                    await setFormularioEntradaQtde(0);
                                                                    await setFormularioListaDeEntradas([]);
                                                                    await setFormularioSinalQtde(0);
                                                                    await setFormularioListaDeSinais([]);
                                                                    await setFormularioParcelaValor(0);
                                                                    await setFormularioParcelaQtde(0);
                                                                    await setFormularioParcelaVencimento(undefined);
                                                                    await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                    await setItemPickerFinalidadeDeCompra(undefined);
                                                                }
                                                            }
                                                            else {
                                                                NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                            }
                                                        }

                                                    }
                                                    else {
                                                        Item.status = 2;
                                                        NotificacaoInterna.ExibirNotificacao("Reservado", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                                        let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Item.localId, Item.subLocalId)

                                                        if (Math.floor(ResponseUnidade.status / 100) == 2) {

                                                            await setItemPickerIdentificador(ResponseUnidade.data[0]);

                                                            if (getItemPickerCondicaoDaTabelaDeVenda) {
                                                                Toast(
                                                                    <div>
                                                                        <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{"Deseja apagar os dados financeiros já informados?"}</p>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                            <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => {
                                                                                    await setFormularioEntradaQtde(0);
                                                                                    await setFormularioListaDeEntradas([]);
                                                                                    await setFormularioSinalQtde(0);
                                                                                    await setFormularioListaDeSinais([]);
                                                                                    await setFormularioParcelaValor(0);
                                                                                    await setFormularioParcelaQtde(0);
                                                                                    await setFormularioParcelaVencimento(undefined);
                                                                                    await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                                    await setItemPickerFinalidadeDeCompra(undefined);
                                                                                    await setListaCondicaoDaTabelaDeVenda([]);
                                                                                    await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                                    await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                }}>Sim</Button>
                                                                            <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => {
                                                                                    NotificacaoInterna.ExibirNotificacao("Atualizando dados do modelo de venda", "Por favor, aguarde a finalização do processo.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                    const ItemPickerCondicaoDaTabelaDeVenda = getItemPickerCondicaoDaTabelaDeVenda;
                                                                                    const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                                    await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                                    await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                                    await setItemPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda[0].condicoesDaTabelaDeVenda.filter((Item) => Item.descricao == ItemPickerCondicaoDaTabelaDeVenda.descricao)[0]);
                                                                                    NotificacaoInterna.ExibirNotificacao("Dados atualizados", "Modelo de venda atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                                }}>Não</Button>
                                                                        </div>
                                                                    </div>, {
                                                                    position: Toast.POSITION.TOP_CENTER,
                                                                    autoClose: false,
                                                                    style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                });
                                                            }
                                                            else {
                                                                await setItemPickerCondicaoDaTabelaDeVenda(undefined);
                                                                await setListaPickerCondicaoDaTabelaDeVenda([]);
                                                                const CondicoesDaTabelaDeVenda = (await TabelaDeVenda.Get(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].local.id ?? 0, ResponseUnidade.data[0].subLocal.id ?? 0)).data.classificacoesDosTitulosDaTabelaDeVenda;
                                                                await setListaCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda);
                                                                await setListaPickerCondicaoDaTabelaDeVenda(CondicoesDaTabelaDeVenda.find((Item) => Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda ?? []);
                                                                await setFormularioEntradaQtde(0);
                                                                await setFormularioListaDeEntradas([]);
                                                                await setFormularioSinalQtde(0);
                                                                await setFormularioListaDeSinais([]);
                                                                await setFormularioParcelaValor(0);
                                                                await setFormularioParcelaQtde(0);
                                                                await setFormularioParcelaVencimento(undefined);
                                                                await setListaPickerFinalidadeDeCompra((await Venda.FinalidadesDeCompra(ContextSignIn.getContext().token, ResponseUnidade.data[0].empresa.id ?? 0, ResponseUnidade.data[0].centroDeCusto.sigla ?? "", ResponseUnidade.data[0].identificadorVinculado?.softwareExterno.token ?? "")).data);
                                                                await setItemPickerFinalidadeDeCompra(undefined);
                                                            }
                                                        }
                                                    }
                                                }
                                            }} text={Item.subLocalDescricao ? Item.subLocalDescricao : ""} />
                                        }
                                        itemPredicate={(Texto: string, Item: Objeto.IdentificadorSintetico) => {
                                            return (Item.subLocalDescricao ? Item.subLocalDescricao : "").toUpperCase().includes(Texto.toUpperCase()) || Texto == ""
                                        }}>
                                        <Button id="unidade" text={getItemPickerIdentificadorSintetico?.subLocalDescricao ?? "Selecione a unidade"} rightIcon="double-caret-vertical" />
                                        {getItemPickerCentroDeCusto && getListaPickerIdentificadorSintetico.length == 0 && <Icon icon="geosearch" />}
                                    </PickerIdentificador>}
                                    {false && getItemPickerIdentificadorSintetico && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerIdentificadorSintetico(undefined); }} />}
                                    {/** COMPONENTE SEM MEMO */}
                                </Component.FormContent>
                            </FormGroup>
                            {getItemPickerIdentificadorSintetico?.valorAVista &&
                                <FormGroup style={{ marginLeft: "5px" }} label="Valor a vista:" labelFor="UnidadeValorAVista" inline={true}>
                                    <div id="UnidadeValorAVista">
                                        <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(getItemPickerIdentificadorSintetico?.valorAVista)} leftIcon="dollar" />
                                    </div>
                                </FormGroup>}
                            {getItemPickerIdentificadorSintetico?.valorAVista &&
                                <FormGroup style={{ marginLeft: "5px" }} label="Comissão:" labelFor="UnidadeValorAVista" inline={true}>
                                    <div id="UnidadeValorAVista">
                                        <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(getItemPickerIdentificadorSintetico?.valorAVista * 0.04)} leftIcon="dollar" />
                                    </div>
                                </FormGroup>}
                        </div>
                        <FormGroup label="Modelo de venda:" labelFor="CondicaoDaTabelaDeVenda" inline={true}>
                            <PickerCondicaoDaTabelaDeVenda popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCondicaoDaTabelaDeVenda} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: Objeto.CondicaoDaTabelaDeVenda) => <MenuItem onClick={async () => {
                                    try {
                                        await setItemPickerCondicaoDaTabelaDeVenda(Item);
                                        var ListaDeEntradas = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                        var EntradaValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.valorTotal ?? 0;
                                        var EntradaQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.qtdeDeTitulos ?? 0;
                                        var EntradaVencimento = new Date(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.primeiroVencimento ?? new Date());
                                        await setFormularioEntradaQtde(EntradaQtde);
                                        for (let i = 0; i < EntradaQtde; i++) {
                                            let Vencimento = new Date(EntradaVencimento);
                                            Vencimento.setMonth(Vencimento.getMonth() + (i == 0 ? 0 : 1));
                                            ListaDeEntradas.push({
                                                Valor: (i == EntradaQtde - 1) ? (EntradaValor - Moeda.DesformatarTexto(Moeda.FormatarTexto(EntradaValor / (EntradaQtde))) * (EntradaQtde - 1)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(EntradaValor / EntradaQtde)),
                                                Vencimento: Item.descricao.includes("À Vista") ? getFormularioDataDaProposta : Vencimento,
                                                ExibirCalendarioVencimento: false,
                                                MeioDePagamento: ""
                                            });
                                            EntradaVencimento = Vencimento;
                                        }
                                        await setFormularioListaDeEntradas(ListaDeEntradas);

                                        var ListaDeSinais = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                        var SinalValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.valorTotal ?? 0;
                                        var SinalQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.qtdeDeTitulos ?? 0;
                                        var SinalVencimento = new Date(EntradaVencimento);
                                        await setFormularioSinalQtde(SinalQtde);
                                        for (let i = 0; i < SinalQtde; i++) {
                                            let Vencimento = new Date(SinalVencimento);
                                            Vencimento.setMonth(SinalVencimento.getMonth() + 1);
                                            Vencimento.setDate(SinalVencimento.getDate() > 5 ? 15 : 5);
                                            ListaDeSinais.push({
                                                Valor: (i == SinalQtde - 1) ? (SinalValor - Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / (SinalQtde))) * (SinalQtde - 1)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / SinalQtde)),
                                                Vencimento: Item.descricao.includes("À Vista") ? getFormularioDataDaProposta : Vencimento,
                                                ExibirCalendarioVencimento: false,
                                                MeioDePagamento: "Boleto"
                                            });
                                            SinalVencimento = Vencimento;
                                        }
                                        await setFormularioListaDeSinais(ListaDeSinais);

                                        var ListaDeParcelas = [] as Array<{ Quantidade: number, Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                        var ParcelaValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.principal ?? 0;
                                        var ParcelaQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.qtdeDeTitulos ?? 0;
                                        var ParcelaVencimento = new Date(SinalVencimento);
                                        if (Item.descricao.includes("Anual") == true) {
                                            ParcelaVencimento = new Date();
                                            ParcelaVencimento.setMonth(ParcelaVencimento.getMonth() + 12);
                                            ParcelaVencimento.setDate(ParcelaVencimento.getDate() > 5 ? 15 : 5);
                                        }
                                        else if (Item.descricao.includes("Semestral") == true) {
                                            ParcelaVencimento = new Date();
                                            ParcelaVencimento.setMonth(ParcelaVencimento.getMonth() + 6);
                                            ParcelaVencimento.setDate(ParcelaVencimento.getDate() > 5 ? 15 : 5);
                                        }
                                        else {
                                            ParcelaVencimento.setMonth(ParcelaVencimento.getMonth() + 1);
                                            ParcelaVencimento.setDate(ParcelaVencimento.getDate() > 5 ? 15 : 5);
                                        }
                                        {
                                            ListaDeParcelas.push({
                                                Quantidade: ParcelaQtde,
                                                Valor: ParcelaValor,
                                                Vencimento: Item.descricao.includes("À Vista") ? getFormularioDataDaProposta : ParcelaVencimento,
                                                ExibirCalendarioVencimento: false,
                                                MeioDePagamento: "Boleto"
                                            });
                                        }
                                        await setFormularioListaDeParcelas(ListaDeParcelas);

                                        var ListaDeIntermediarias = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                        var IntermediariaVencimento = new Date(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.primeiroVencimento ?? new Date());
                                        var IntermediariaQtde = QtdeDeIntermediariasModeloDeVendas(IntermediariaVencimento, ListaDeParcelas);
                                        var IntermediariaValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == Item?.descricao)?.valorTotal ?? 0;
                                        if (IntermediariaQtde > 0) {
                                            let Vencimento = new Date(IntermediariaVencimento);
                                            Vencimento.setMonth(IntermediariaVencimento.getMonth() + 1);
                                            ListaDeIntermediarias = [
                                                {
                                                    Valor: Moeda.DesformatarTexto(Moeda.FormatarTexto(IntermediariaValor / (IntermediariaQtde))),
                                                    Vencimento: Item.descricao.includes("À Vista") ? getFormularioDataDaProposta : Vencimento,
                                                    ExibirCalendarioVencimento: false,
                                                    MeioDePagamento: "Boleto"
                                                }
                                            ]
                                        }
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }
                                    catch { }
                                }} text={Item.descricao ?? ""} />}
                                itemPredicate={(Texto: string, Item: Objeto.CondicaoDaTabelaDeVenda) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button text={getItemPickerCondicaoDaTabelaDeVenda?.descricao ?? "Selecione o modelo"} className="CondiçãoDeVendas" rightIcon="double-caret-vertical" />
                                {getItemPickerIdentificadorSintetico && getListaPickerCondicaoDaTabelaDeVenda.length == 0 && <Icon icon="geosearch" style={{ marginLeft: "10px" }} />}
                            </PickerCondicaoDaTabelaDeVenda>
                            {getItemPickerCondicaoDaTabelaDeVenda && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerCondicaoDaTabelaDeVenda(undefined); }} />}
                        </FormGroup>
                    </Component.ContentFormHeader>
                </Component.ContentCard>
                {getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao) && <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="form" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Sobre a entrada</h3>
                            {(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) > 0 && <h6 style={{ marginTop: "30px", marginLeft: "10px" }}>(taxa de juros de {Percentual.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) * 100)}  a.m.)</h6>}
                        </div>
                        <FileInput style={{ width: "calc(100% - 215px)", minWidth: "300px", marginLeft: "-10px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoComprovantePgtoEntrada == undefined ? "Anexe o comprovante de pagamento" : getFormularioAnexoComprovantePgtoEntrada.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoComprovantePgtoEntrada(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoComprovantePgtoEntrada && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoComprovantePgtoEntrada(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <FormGroup label="Quantidade de parcelas:" labelFor="EntradaQtde" inline={true} >
                            <InputGroup id="EntradaQtde" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(getFormularioEntradaQtde)} leftIcon="numerical"
                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                    var ListaDeEntradas = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                    var EntradaValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0;
                                    var EntradaQtde = Numero.DesformatarTextoParaInteiro(event.currentTarget.value);
                                    var EntradaVencimento = new Date(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.primeiroVencimento ?? new Date());
                                    await setFormularioEntradaQtde(EntradaQtde);

                                    for (let i = 0; i < EntradaQtde; i++) {
                                        let Vencimento = new Date(EntradaVencimento);
                                        Vencimento.setMonth(Vencimento.getMonth() + (i == 0 ? 0 : 1));
                                        ListaDeEntradas.push({
                                            Valor: (i == EntradaQtde - 1) ? (EntradaValor - Moeda.DesformatarTexto(Moeda.FormatarTexto(EntradaValor / (EntradaQtde))) * (EntradaQtde - 1)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(EntradaValor / EntradaQtde)),
                                            Vencimento: Vencimento,
                                            ExibirCalendarioVencimento: false,
                                            MeioDePagamento: ""
                                        });
                                        EntradaVencimento = Vencimento;
                                    }
                                    await setFormularioListaDeEntradas(ListaDeEntradas);

                                    var ListaDeSinais = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                    var SinalValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0;
                                    var SinalQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0;
                                    var SinalVencimento = new Date(EntradaVencimento);

                                    await setFormularioSinalQtde(SinalQtde);
                                    for (let i = 0; i < SinalQtde; i++) {
                                        let Vencimento = new Date(SinalVencimento);
                                        Vencimento.setMonth(SinalVencimento.getMonth() + 1);
                                        ListaDeSinais.push({
                                            Valor: (i == SinalQtde - 1) ? (SinalValor - Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / (SinalQtde))) * (SinalQtde - 1)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / SinalQtde)),
                                            Vencimento: Vencimento,
                                            ExibirCalendarioVencimento: false,
                                            MeioDePagamento: "Boleto"
                                        });
                                        SinalVencimento = Vencimento;
                                    }
                                    await setFormularioListaDeSinais(ListaDeSinais);

                                    var ParcelaQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0;
                                    var ParcelaVencimento = new Date(SinalVencimento);
                                    ParcelaVencimento.setMonth(ParcelaVencimento.getMonth() + 1);
                                    await setFormularioParcelaValor(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0);
                                    await setFormularioParcelaQtde(ParcelaQtde);
                                    await setFormularioParcelaVencimento(new Date(ParcelaVencimento));
                                }}>
                            </InputGroup>
                        </FormGroup>
                        {getFormularioEntradaQtde > 0 && <div style={{ display: "flex" }}>
                            <div style={{ width: "10vw", textAlign: "center" }}>
                                <h5>Valor da parcela</h5>
                            </div>
                            <div style={{ width: "10vw", marginLeft: "10px", textAlign: "center" }}>
                                <h5>Vencimento</h5>
                            </div>
                            <div style={{ width: "17vw", marginLeft: "10px", textAlign: "center" }}>
                                <h5>Meio de pagamento</h5>
                            </div>
                        </div>}
                        {getFormularioListaDeEntradas.map((Item, Index) =>
                            <div style={{ display: "block" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "10vw", marginRight: "10px" }}>
                                        <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.Valor)} leftIcon="dollar"
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Valor = (Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00);
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </div>
                                    <div style={{ width: "10vw", marginRight: "30px" }}>
                                        <DateInput inputProps={{
                                            onClick: () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].ExibirCalendarioVencimento = true;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }
                                        }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{
                                                disabled: !Item.ExibirCalendarioVencimento, onClosed: () => {
                                                    let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                    ListaDeEntradas[Index].ExibirCalendarioVencimento = false;
                                                    setFormularioListaDeEntradas(ListaDeEntradas);
                                                }
                                            }}
                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} value={Item.Vencimento} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                            onChange={async (DataSelecionada: Date, AlteracaoDeUsuario: boolean) => {
                                                if (AlteracaoDeUsuario == true) {
                                                    let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                    if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                        ListaDeEntradas[Index].Vencimento = (getFormularioDataDaProposta ?? new Date());
                                                    }
                                                    else {
                                                        if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "CreditoParcelado") {
                                                            ListaDeEntradas.map((Item_, Index_) => {
                                                                if (Index_ >= Index) {
                                                                    let Vencimento = new Date(DataSelecionada);
                                                                    Vencimento.setDate(Vencimento.getDate() + (Index_ - Index) * 30);
                                                                    Item_.Vencimento = Vencimento;
                                                                }
                                                            });
                                                        }
                                                        else {
                                                            ListaDeEntradas[Index].Vencimento = DataSelecionada;
                                                        }
                                                    }
                                                    setFormularioListaDeEntradas(ListaDeEntradas);
                                                }
                                            }}>
                                        </DateInput>
                                    </div>
                                    <div style={{ width: "20vw" }}>
                                        <Component.ContentFormHeader style={{ margin: "0" }}>
                                            <FormGroup labelFor="MeioDePagamento" inline={true}>
                                                <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={Item.MeioDePagamento}
                                                    onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                        ListaDeEntradas[Index].MeioDePagamento = event.currentTarget.value;
                                                        ListaDeEntradas[Index].Banco = undefined;
                                                        ListaDeEntradas[Index].Agencia = undefined;
                                                        ListaDeEntradas[Index].Conta = undefined;
                                                        ListaDeEntradas[Index].DigitoDaConta = undefined;
                                                        ListaDeEntradas[Index].Titular = undefined;
                                                        ListaDeEntradas[Index].NumeroCheque = undefined;
                                                        ListaDeEntradas[Index].Maquina = undefined;
                                                        ListaDeEntradas[Index].Bandeira = undefined;
                                                        ListaDeEntradas[Index].DigitoCartao = undefined;
                                                        ListaDeEntradas[Index].Operacao = undefined;
                                                        ListaDeEntradas[Index].NSU = undefined;
                                                        ListaDeEntradas[Index].NumeroDaOperacao = undefined;
                                                        setFormularioListaDeEntradas(ListaDeEntradas);
                                                    }}>
                                                    <option selected value="">Selecione o meio de pagamento</option>
                                                    {MeioDePagamento.map((Item) =>
                                                        <option selected value={Item.chave}>{Item.Valor}</option>
                                                    )}
                                                </HTMLSelect>
                                            </FormGroup>
                                        </Component.ContentFormHeader>
                                    </div>
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cheque" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        ListaDeEntradas[Index].DetalharMeioDePagamento = !(ListaDeEntradas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeEntradas(ListaDeEntradas);
                                    }}>
                                        <strong>Sobre o cheque:</strong> Banco: {Item.Banco?.chave ?? "--"}, Agência: {Item.Agencia ?? "--"}, Conta: {Item.Conta ?? "--"}{Item.DigitoDaConta == undefined ? "" : ("-" + Item.DigitoDaConta)}, Titular: {Item.Titular ?? "--"}, Nº do cheque: {Item.NumeroCheque ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cartao" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        ListaDeEntradas[Index].DetalharMeioDePagamento = !(ListaDeEntradas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeEntradas(ListaDeEntradas);
                                    }}>
                                        <strong>Sobre o cartão:</strong> Máquina: {Item.Maquina ?? "--"}, Digito: {Item.DigitoCartao ?? "--"}, Bandeira: {Item.Bandeira ?? "--"}, Operação: {Item.Operacao ?? "--"}, NSU: {Item.NSU ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        ListaDeEntradas[Index].DetalharMeioDePagamento = !(ListaDeEntradas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeEntradas(ListaDeEntradas);
                                    }}>
                                        <strong>Sobre o {Item.MeioDePagamento}:</strong> Nº da operação: {Item.NumeroDaOperacao ?? "--"}
                                    </Component.DescriptionContent>}
                                </div>
                                {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cheque" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Banco:" className="banco" labelFor="Banco" inline={true}>
                                        <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Banco = _Item;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Banco ? ((Item.Banco).chave + ": " + (Item.Banco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                        </PickerBanco>
                                    </FormGroup>
                                    <FormGroup label="Agência:" className="agência" labelFor="Agencia" inline={true} >
                                        <InputGroup id="Agencia" placeholder={"00000"} value={Item.Agencia}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Agencia = event.currentTarget.value ?? "";
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Component.FormContent>
                                        <FormGroup label="Conta:" className="conta" labelFor="Conta" inline={true} >
                                            <InputGroup id="Conta" placeholder={"00000"} value={Item.Conta}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                    ListaDeEntradas[Index].Conta = (event.currentTarget.value ?? "");
                                                    setFormularioListaDeEntradas(ListaDeEntradas);
                                                }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="- " labelFor="DigitoDaConta" inline={true} style={{ marginLeft: "10px", width: "50px" }} >
                                            <InputGroup id="DigitoDaConta" placeholder={"00"} value={Item.DigitoDaConta}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                    ListaDeEntradas[Index].DigitoDaConta = (event.currentTarget.value ?? "");
                                                    setFormularioListaDeEntradas(ListaDeEntradas);
                                                }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </Component.FormContent>
                                    <FormGroup label="Titular:" className="titular" labelFor="Titular" inline={true} >
                                        <InputGroup id="Titular" placeholder={"Informe o nominal"} value={Item.Titular}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Titular = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Nº do cheque:" labelFor="NumeroCheque" inline={true} >
                                        <InputGroup id="NumeroCheque" placeholder={"Informe o número"} value={Item.NumeroCheque}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].NumeroCheque = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        let EntradasVazias = []
                                        EntradasVazias = ListaDeEntradas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == ""))
                                        var Texto = `Existe mais de uma entrada com o meio de pagamento ${String(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        ListaDeEntradas.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Banco ?? "") == "" && (Item__.Agencia ?? "") == "" && (Item__.Conta ?? "") == "" && (Item__.DigitoDaConta ?? "") == "" && (Item__.Titular ?? "") == "" && (Item__.NumeroCheque ?? "") == "")) {
                                                Texto += `\n ${Numero.FormatarTextoParaInteiro(Index__ + 1)}ª entrada, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`;
                                            }
                                        })
                                        Texto += "\n";
                                        if (EntradasVazias.length > 0) {
                                            Toast(
                                                <div>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == "") {
                                                                        Item_.Banco = Item.Banco
                                                                        Item_.Agencia = Item.Agencia
                                                                        Item_.Conta = Item.Conta
                                                                        Item_.DigitoDaConta = Item.DigitoDaConta
                                                                        Item_.Titular = Item.Titular
                                                                        Item_.NumeroCheque = Item.NumeroCheque
                                                                        Item.DetalharMeioDePagamento = false
                                                                    }
                                                                });
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            });
                                        }
                                        else {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeEntradas(ListaDeEntradas);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                                {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cartao" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Máquina:" className="máquina" labelFor="Maquina" inline={true} >
                                        <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Maquina = _Item.Valor;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Maquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                        </PickerMaquina>
                                    </FormGroup>
                                    <FormGroup label="Dígito do cartão:" labelFor="DigitoCartao" inline={true} >
                                        <InputGroup id="DigitoCartao" placeholder={"Informe os últimos 04 dígitos do cartão"} value={Item.DigitoCartao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].DigitoCartao = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Bandeira:" className="bandeira" labelFor="Bandeira" inline={true} >
                                        <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Bandeira = _Item.Valor;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Bandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                        </PickerMaquina>
                                    </FormGroup>
                                    <FormGroup label="Terminal:" className="terminal" labelFor="Terminal" inline={true} >
                                        <PickerTerminal popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={GetTerminais} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { id: number, descricao: string }) => <MenuItem onClick={async () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Terminal = _Item.id;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }} text={_Item.descricao} />} itemPredicate={(Texto: string, _Item: { id: number, descricao: string }) => { return ((_Item.descricao) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={GetTerminais.find((_Item) => _Item.id == Item.Terminal)?.descricao ?? "Selecione o terminal"} rightIcon="double-caret-vertical" />
                                        </PickerTerminal>
                                    </FormGroup>
                                    <FormGroup label="Cód. autorização:" labelFor="CodAutorizacao" inline={true} >
                                        <InputGroup id="CodAutorizacao" placeholder={"Informe o código de autorização"} value={Item?.CodAutorizacao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].CodAutorizacao = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Operação:" className="operação" labelFor="Operacao" inline={true} >
                                        <HTMLSelect id="Operacao" name="Operacao" value={Item.Operacao == undefined ? "" : Item.Operacao}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Operacao = (event.currentTarget.value ?? "");
                                                if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "Debito") {
                                                    const EhDiaUtil = require('eh-dia-util');
                                                    let Vencimento = new Date();
                                                    Vencimento.setDate(Vencimento.getDate() + 1);
                                                    while (EhDiaUtil(Vencimento) == false) {
                                                        Vencimento.setDate(Vencimento.getDate() + 1);
                                                    }
                                                    ListaDeEntradas[Index].Vencimento = Vencimento;
                                                }
                                                if (Item.MeioDePagamento == "Cartao" && Item.Operacao != "Debito") {
                                                    ListaDeEntradas.map((Item_, Index_) => {
                                                        if (Index_ >= Index) {
                                                            let Vencimento = new Date(getFormularioDataDaProposta);
                                                            Vencimento.setDate(Vencimento.getDate() + (1 + Index_ - Index) * 30);
                                                            Item_.Vencimento = Vencimento;
                                                        }
                                                    });
                                                }
                                                else {
                                                    ListaDeEntradas[Index].Vencimento = ListaDeEntradas[Index].Vencimento;
                                                }
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                            <option selected value="">Selecione a operação</option>
                                            {Operacao.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                    </FormGroup>
                                    <FormGroup label="NSU:" className="nsu" labelFor="NSU" inline={true} >
                                        <InputGroup id="NSU" placeholder={"Informe o NSU"} value={Item.NSU}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].NSU = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        let EntradasVazias = []
                                        EntradasVazias = await ListaDeEntradas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == ""))
                                        var Texto = `Existe mais de uma entrada com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        await ListaDeEntradas.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Maquina ?? "") == "" && (Item__.DigitoCartao ?? "") == "" && (Item__.Bandeira ?? "") == "" && (Item__.Operacao ?? "") == "" && (Item__.NSU ?? "") == "")) {
                                                Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}ª entrada, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                            }
                                        })
                                        Texto += "\n";
                                        if (EntradasVazias.length > 0) {
                                            Toast(
                                                <div style={{}}>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == "") {
                                                                        Item_.Maquina = Item.Maquina
                                                                        Item_.DigitoCartao = Item.DigitoCartao
                                                                        Item_.Bandeira = Item.Bandeira
                                                                        Item_.Operacao = Item.Operacao
                                                                        Item_.NSU = Item.NSU
                                                                        Item.DetalharMeioDePagamento = false
                                                                        await setFormularioListaDeEntradas(ListaDeEntradas);
                                                                    }
                                                                })
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            })
                                        }
                                        else {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeEntradas(ListaDeEntradas);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                                {Item.DetalharMeioDePagamento == true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Nº da operação:" labelFor="NumeroDaOperacao" inline={true} >
                                        <InputGroup id="NumeroDaOperacao" placeholder={"00000"} value={Item.NSU}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].NumeroDaOperacao = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                        let EntradasVazias = []
                                        EntradasVazias = ListaDeEntradas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == ""))
                                        var Texto = `Existe mais de uma entrada com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        ListaDeEntradas.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.NumeroDaOperacao ?? "") == "")) {
                                                Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}ª entrada, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                            }
                                        })
                                        Texto += "\n";
                                        if (EntradasVazias.length > 0) {
                                            Toast(
                                                <div>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == "") {
                                                                        Item_.NumeroDaOperacao = Item.NumeroDaOperacao
                                                                        Item.DetalharMeioDePagamento = false
                                                                        await setFormularioListaDeEntradas(ListaDeEntradas);
                                                                    }
                                                                })
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                                ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeEntradas(ListaDeEntradas);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            })
                                        }
                                        else {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeEntradas(ListaDeEntradas);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                            </div>
                        )}
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <FormGroup label="Total gerado:" labelFor="EntradaValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                                <InputGroup id="EntradaValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto(getFormularioListaDeEntradas.reduce((total, parcela) => (total + parcela.Valor), 0))} leftIcon="numerical" />
                            </FormGroup>
                            <FormGroup style={{ marginRight: "10px" }} label="Total esperado:" labelFor="EntradaValorTotalEsperado" inline={true} >
                                <InputGroup id="EntradaValorTotalEsperado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0)} leftIcon="numerical" />
                            </FormGroup>
                            <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeEntradas.reduce((total, parcela) => (total + parcela.Valor), 0)) ? "A distribuir:" : "Excedido"} labelFor="EntradaValorTotalADistribuir" inline={true}>
                                <InputGroup id="EntradaValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 3)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) - getFormularioListaDeEntradas.reduce((total, parcela) => (total + parcela.Valor), 0))} leftIcon="numerical" />
                            </FormGroup>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>}
                {getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2) && <Component.ContentCard>
                    <Component.Title>
                        <Icon icon="form" iconSize={25} />
                        <h3>Sobre o sinal</h3>
                    </Component.Title>
                    <Component.ContentFormHeader>
                        <FormGroup label="Quantidade de parcelas:" labelFor="SinalQtde" inline={true} >
                            <InputGroup id="SinalQtde" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(getFormularioSinalQtde)} leftIcon="numerical"
                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                    var ListaDeSinais = [] as Array<{ Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined }>;
                                    var SinalValor = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0;
                                    var SinalQtde = Numero.DesformatarTextoParaInteiro(event.currentTarget.value);
                                    var SinalVencimento = new Date(getFormularioListaDeEntradas[getFormularioListaDeEntradas.length - 1] != undefined ? getFormularioListaDeEntradas[getFormularioListaDeEntradas.length - 1].Vencimento : new Date());

                                    await setFormularioSinalQtde(SinalQtde);
                                    for (let i = 0; i < SinalQtde; i++) {
                                        let Vencimento = new Date(SinalVencimento);
                                        Vencimento.setMonth(SinalVencimento.getMonth() + 1);
                                        ListaDeSinais.push({
                                            Valor: (i == SinalQtde - 1) ? (SinalValor - Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / (SinalQtde))) * (SinalQtde - 1)) : Moeda.DesformatarTexto(Moeda.FormatarTexto(SinalValor / SinalQtde)),
                                            Vencimento: Vencimento,
                                            ExibirCalendarioVencimento: false,
                                            MeioDePagamento: ""
                                        });
                                        SinalVencimento = Vencimento;
                                    }
                                    await setFormularioListaDeSinais(ListaDeSinais);

                                    var ParcelaQtde = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0;
                                    var ParcelaVencimento = new Date(SinalVencimento);
                                    ParcelaVencimento.setMonth(ParcelaVencimento.getMonth() + 1);
                                    await setFormularioParcelaValor(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0);
                                    await setFormularioParcelaQtde(ParcelaQtde);
                                    await setFormularioParcelaVencimento(new Date(ParcelaVencimento));
                                }}>
                            </InputGroup>
                        </FormGroup>
                        {getFormularioSinalQtde > 0 && <div style={{ display: "flex" }}>
                            <div style={{ width: "10vw", textAlign: "center" }}>
                                <h5>Valor da parcela</h5>
                            </div>
                            <div style={{ width: "10vw", marginLeft: "10px", textAlign: "center" }}>
                                <h5>Vencimento</h5>
                            </div>
                            <div style={{ width: "20vw", marginLeft: "10px", textAlign: "center" }}>
                                <h5>Meio de pagamento</h5>
                            </div>
                        </div>}
                        {getFormularioListaDeSinais.map((Item, Index) =>
                            <div style={{ display: "block" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "10vw", marginRight: "10px" }}>
                                        <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.Valor)} leftIcon="dollar"
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Valor = (Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00);
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </div>
                                    <div style={{ width: "10vw", marginRight: "30px" }}>
                                        <DateInput inputProps={{
                                            onClick: () => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].ExibirCalendarioVencimento = true;
                                                setFormularioListaDeSinais(ListaDeSinais);
                                            }
                                        }}
                                            dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{
                                                disabled: !Item.ExibirCalendarioVencimento, onClosed: () => {
                                                    let ListaDeSinais = [...getFormularioListaDeSinais];
                                                    ListaDeSinais[Index].ExibirCalendarioVencimento = false;
                                                    setFormularioListaDeSinais(ListaDeSinais);
                                                }
                                            }} parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} value={Item.Vencimento} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                            onChange={async (DataSelecionada: Date, AlteracaoDeUsuario: boolean) => {
                                                if (AlteracaoDeUsuario == true) {
                                                    let ListaDeSinais = [...getFormularioListaDeSinais];
                                                    if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                        ListaDeSinais[Index].Vencimento = (getFormularioDataDaProposta ?? new Date());
                                                    }
                                                    else {
                                                        if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "CreditoParcelado") {
                                                            ListaDeSinais.map((Item_, Index_) => {
                                                                if (Index_ >= Index && Item_.MeioDePagamento == "Cartao" && Item_.Operacao == "CreditoParcelado") {
                                                                    let Vencimento = new Date(DataSelecionada);
                                                                    Vencimento.setDate(Vencimento.getDate() + (Index_ - Index) * 30);
                                                                    Item_.Vencimento = Vencimento;
                                                                }
                                                            });
                                                            await setFormularioListaDeSinais(ListaDeSinais);
                                                        }
                                                        else {
                                                            if (DataSelecionada.getDate() != 5 && DataSelecionada.getDate() != 15 && ListaDeSinais[Index].MeioDePagamento == "Boleto") {
                                                                NotificacaoInterna.ExibirNotificacao("Data invalida", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);

                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais[Index].Vencimento = ListaDeSinais[Index].Vencimento;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                                return;
                                                            }
                                                            else {
                                                                if (ListaDeSinais[Index].MeioDePagamento == "Boleto" && ListaDeSinais.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == "Boleto")).length > 0) {
                                                                    Toast(
                                                                        <div style={{}}>
                                                                            <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`Existem sinais do tipo boleto com o dia de vencimento diferente da primeira parcela, deseja igualar o dia de vencimento de todos os sinais do tipo boleto?`}</p>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                                <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => {
                                                                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                                        ListaDeSinais[Index].Vencimento = DataSelecionada;
                                                                                        await setFormularioListaDeSinais(ListaDeSinais);
                                                                                        ListaDeSinais.map(async (Item_, Index_) => {
                                                                                            ListaDeSinais = [...getFormularioListaDeSinais];
                                                                                            let Vencimento = new Date(Item_.Vencimento);
                                                                                            Vencimento.setDate(ListaDeSinais[Index].Vencimento.getDate());
                                                                                            Vencimento.setFullYear(ListaDeSinais[Index].Vencimento.getFullYear());
                                                                                            Vencimento.setMonth(ListaDeSinais[Index].Vencimento.getMonth() + Index_ - Index);
                                                                                            if (Index_ > Index && Item_.MeioDePagamento == "Boleto") {
                                                                                                Item_.Vencimento = Vencimento;
                                                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                                                            }
                                                                                            if (Index_ == ListaDeSinais.length - 1) {
                                                                                                Vencimento = new Date(Vencimento);
                                                                                                Vencimento.setMonth(Vencimento.getMonth() + 1);
                                                                                                await setFormularioParcelaVencimento(Vencimento);
                                                                                            }
                                                                                        });
                                                                                    }}>Sim</Button>
                                                                                <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                    onClick={async () => { }}>Não</Button>
                                                                            </div>
                                                                        </div>, {
                                                                        position: Toast.POSITION.TOP_CENTER,
                                                                        autoClose: false,
                                                                        style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                    });
                                                                }
                                                                else {
                                                                    let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                    ListaDeSinais[Index].Vencimento = DataSelecionada;
                                                                    await setFormularioListaDeSinais(ListaDeSinais);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }}>
                                        </DateInput>
                                    </div>
                                    <div style={{ width: "20vw" }}>
                                        <Component.ContentFormHeader style={{ margin: "0" }}>
                                            <FormGroup labelFor="MeioDePagamento" inline={true}>
                                                <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={Item.MeioDePagamento}
                                                    onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                                        ListaDeSinais[Index].MeioDePagamento = event.currentTarget.value;
                                                        ListaDeSinais[Index].Banco = undefined;
                                                        ListaDeSinais[Index].Agencia = undefined;
                                                        ListaDeSinais[Index].Conta = undefined;
                                                        ListaDeSinais[Index].DigitoDaConta = undefined;
                                                        ListaDeSinais[Index].Titular = undefined;
                                                        ListaDeSinais[Index].NumeroCheque = undefined;
                                                        ListaDeSinais[Index].Maquina = undefined;
                                                        ListaDeSinais[Index].Bandeira = undefined;
                                                        ListaDeSinais[Index].DigitoCartao = undefined;
                                                        ListaDeSinais[Index].Operacao = undefined;
                                                        ListaDeSinais[Index].NSU = undefined;
                                                        ListaDeSinais[Index].NumeroDaOperacao = undefined;
                                                        await setFormularioListaDeSinais(ListaDeSinais);
                                                    }}>
                                                    <option selected value="">Selecione o meio de pagamento</option>
                                                    {MeioDePagamento.map((Item) =>
                                                        <option selected value={Item.chave}>{Item.Valor}</option>
                                                    )}
                                                </HTMLSelect>
                                            </FormGroup>
                                        </Component.ContentFormHeader>
                                    </div>
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cheque" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        ListaDeSinais[Index].DetalharMeioDePagamento = !(ListaDeSinais[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeSinais(ListaDeSinais);
                                    }}>
                                        <strong>Sobre o cheque:</strong> Banco: {Item.Banco?.chave ?? "--"}, Agência: {Item.Agencia ?? "--"}, Conta: {Item.Conta ?? "--"}{Item.DigitoDaConta == undefined ? "" : ("-" + Item.DigitoDaConta)}, Titular: {Item.Titular ?? "--"}, Nº do cheque: {Item.NumeroCheque ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cartao" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        ListaDeSinais[Index].DetalharMeioDePagamento = !(ListaDeSinais[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeSinais(ListaDeSinais);
                                    }}>
                                        <strong>Sobre o cartão:</strong> Máquina: {Item.Maquina ?? "--"}, Digito: {Item.DigitoCartao ?? "--"}, Bandeira: {Item.Bandeira ?? "--"}, Operação: {Item.Operacao ?? "--"}, NSU: {Item.NSU ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        ListaDeSinais[Index].DetalharMeioDePagamento = !(ListaDeSinais[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeSinais(ListaDeSinais);
                                    }}>
                                        <strong>Sobre o {Item.MeioDePagamento}:</strong> Nº da operação: {Item.NumeroDaOperacao ?? "--"}
                                    </Component.DescriptionContent>}
                                </div>
                                {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cheque" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Banco:" className="banco" labelFor="Banco" inline={true}>
                                        <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Banco = _Item;
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Banco ? ((Item.Banco).chave + ": " + (Item.Banco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                        </PickerBanco>
                                    </FormGroup>
                                    <FormGroup label="Agência:" className="agência" labelFor="Agencia" inline={true} >
                                        <InputGroup id="Agencia" placeholder={"00000"} value={Item.Agencia}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Agencia = event.currentTarget.value ?? "";
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Component.FormContent>
                                        <FormGroup label="Conta:" className="conta" labelFor="Conta" inline={true} >
                                            <InputGroup id="Conta" placeholder={"00000"} value={Item.Conta}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let ListaDeSinais = [...getFormularioListaDeSinais];
                                                    ListaDeSinais[Index].Conta = (event.currentTarget.value ?? "");
                                                    await setFormularioListaDeSinais(ListaDeSinais);
                                                }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="- " labelFor="DigitoDaConta" inline={true} style={{ marginLeft: "20px" }} >
                                            <InputGroup id="DigitoDaConta" placeholder={"00"} value={Item.DigitoDaConta}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    let ListaDeSinais = [...getFormularioListaDeSinais];
                                                    ListaDeSinais[Index].DigitoDaConta = (event.currentTarget.value ?? "");
                                                    await setFormularioListaDeSinais(ListaDeSinais);
                                                }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </Component.FormContent>
                                    <FormGroup label="Titular:" className="titular" labelFor="Titular" inline={true} >
                                        <InputGroup id="Titular" placeholder={"Informe o nominal"} value={Item.Titular}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Titular = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Nº do cheque:" labelFor="NumeroCheque" inline={true} >
                                        <InputGroup id="NumeroCheque" placeholder={"Informe o número"} value={Item.NumeroCheque}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].NumeroCheque = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        let SinaisVazios = []
                                        SinaisVazios = ListaDeSinais.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == ""))

                                        var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        ListaDeSinais.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Banco ?? "") == "" && (Item__.Agencia ?? "") == "" && (Item__.Conta ?? "") == "" && (Item__.DigitoDaConta ?? "") == "" && (Item__.Titular ?? "") == "" && (Item__.NumeroCheque ?? "") == "")) {
                                                Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                            }
                                        })
                                        Texto += "\n";
                                        if (SinaisVazios.length > 0) {
                                            Toast(
                                                <div style={{}}>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let SinaisVazios = [...getFormularioListaDeSinais];
                                                                SinaisVazios.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == "") {
                                                                        Item_.Banco = Item.Banco
                                                                        Item_.Agencia = Item.Agencia
                                                                        Item_.Conta = Item.Conta
                                                                        Item_.DigitoDaConta = Item.DigitoDaConta
                                                                        Item_.Titular = Item.Titular
                                                                        Item_.NumeroCheque = Item.NumeroCheque
                                                                        Item.DetalharMeioDePagamento = false
                                                                        await setFormularioListaDeSinais(ListaDeSinais);
                                                                    }
                                                                })
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            })
                                        }
                                        else {
                                            let ListaDeSinais = [...getFormularioListaDeSinais];
                                            ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeSinais(ListaDeSinais);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                                {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cartao" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Máquina:" className="máquina" labelFor="Maquina" inline={true} >
                                        <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Maquina = _Item.Valor;
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Maquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                        </PickerMaquina>
                                    </FormGroup>
                                    <FormGroup label="Dígito do cartão:" labelFor="DigitoCartao" inline={true} >
                                        <InputGroup id="DigitoCartao" placeholder={"Informe os últimos 04 dígitos do cartão"} value={Item.DigitoCartao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].DigitoCartao = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Bandeira:" className="bandeira" labelFor="Bandeira" inline={true} >
                                        <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Bandeira = _Item.Valor;
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={Item.Bandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                        </PickerMaquina>
                                    </FormGroup>
                                    <FormGroup label="Terminal:" className="terminal" labelFor="Terminal" inline={true} >
                                        <PickerTerminal popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={GetTerminais} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                            itemRenderer={(_Item: { id: number, descricao: string }) => <MenuItem onClick={async () => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].Terminal = _Item.id;
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }} text={_Item.descricao} />} itemPredicate={(Texto: string, _Item: { id: number, descricao: string }) => { return ((_Item.descricao) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                            <Button text={GetTerminais.find((_Item) => _Item.id == Item.Terminal)?.descricao ?? "Selecione o terminal"} rightIcon="double-caret-vertical" />
                                        </PickerTerminal>
                                    </FormGroup>
                                    <FormGroup label="Cód. autorização:" labelFor="CodAutorizacao" inline={true} >
                                        <InputGroup id="CodAutorizacao" placeholder={"Informe o código de autorização"} value={Item?.CodAutorizacao}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                                ListaDeEntradas[Index].CodAutorizacao = (event.currentTarget.value ?? "");
                                                setFormularioListaDeEntradas(ListaDeEntradas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Operação:" className="operação" labelFor="Operacao" inline={true} >
                                        <HTMLSelect id="Operacao" name="Operacao" value={Item.Operacao == undefined ? "" : Item.Operacao}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].Operacao = (event.currentTarget.value ?? "");
                                                if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "Debito") {
                                                    const EhDiaUtil = require('eh-dia-util');
                                                    let Vencimento = new Date();
                                                    Vencimento.setDate(Vencimento.getDate() + 1);
                                                    while (EhDiaUtil(Vencimento) == false) {
                                                        Vencimento.setDate(Vencimento.getDate() + 1);
                                                    }
                                                    ListaDeSinais[Index].Vencimento = Vencimento;
                                                }
                                                if (Item.MeioDePagamento == "Cartao" && Item.Operacao != "Debito") {
                                                    ListaDeSinais.map((Item_, Index_) => {
                                                        if (Index_ >= Index) {
                                                            let Vencimento = new Date(getFormularioDataDaProposta);
                                                            Vencimento.setDate(Vencimento.getDate() + (1 + Index_ - Index) * 30);
                                                            Item_.Vencimento = Vencimento;
                                                        }
                                                    });
                                                }
                                                else {
                                                    ListaDeSinais[Index].Vencimento = ListaDeSinais[Index].Vencimento;
                                                }
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                            <option selected value="">Selecione a operação</option>
                                            {Operacao.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                    </FormGroup>
                                    <FormGroup label="NSU:" className="nsu" labelFor="NSU" inline={true} >
                                        <InputGroup id="NSU" placeholder={"Informe o NSU"} value={Item.NSU}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].NSU = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        let SinaisVazios = []
                                        SinaisVazios = ListaDeSinais.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == ""))
                                        var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        ListaDeSinais.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Maquina ?? "") == "" && (Item__.DigitoCartao ?? "") == "" && (Item__.Bandeira ?? "") == "" && (Item__.Operacao ?? "") == "" && (Item__.NSU ?? "") == "")) {
                                                Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                            }
                                        })
                                        Texto += "\n";
                                        if (SinaisVazios.length > 0) {
                                            Toast(
                                                <div style={{}}>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == "") {
                                                                        Item_.Maquina = Item.Maquina
                                                                        Item_.DigitoCartao = Item.DigitoCartao
                                                                        Item_.Bandeira = Item.Bandeira
                                                                        Item_.Operacao = Item.Operacao
                                                                        Item_.NSU = Item.NSU
                                                                        Item.DetalharMeioDePagamento = false
                                                                        await setFormularioListaDeSinais(ListaDeSinais);
                                                                    }
                                                                })
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            })
                                        }
                                        else {
                                            let ListaDeSinais = [...getFormularioListaDeSinais];
                                            ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeSinais(ListaDeSinais);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                                {Item.DetalharMeioDePagamento == true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                    <FormGroup label="Nº da operação:" labelFor="NumeroDaOperacao" inline={true} >
                                        <InputGroup id="NumeroDaOperacao" placeholder={"00000"} value={Item.NSU}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                ListaDeSinais[Index].NumeroDaOperacao = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeSinais(ListaDeSinais);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                        let ListaDeSinais = [...getFormularioListaDeSinais];
                                        let SinaisVazios = []
                                        SinaisVazios = ListaDeSinais.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == ""))
                                        var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                        ListaDeSinais.map((Item__, Index__) => {
                                            if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.NumeroDaOperacao ?? "") == "")) {
                                                Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                            }
                                        })
                                        Texto += "\n";
                                        if (SinaisVazios.length > 0) {
                                            Toast(
                                                <div style={{}}>
                                                    <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais.map(async (Item_, Index_) => {
                                                                    if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == "") {
                                                                        Item_.NumeroDaOperacao = Item.NumeroDaOperacao
                                                                        Item.DetalharMeioDePagamento = false
                                                                        await setFormularioListaDeSinais(ListaDeSinais);
                                                                    }
                                                                })
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Sim</Button>
                                                        <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                            onClick={async () => {
                                                                let ListaDeSinais = [...getFormularioListaDeSinais];
                                                                ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                                                await setFormularioListaDeSinais(ListaDeSinais);
                                                            }}>Não</Button>
                                                    </div>
                                                </div>, {
                                                position: Toast.POSITION.TOP_CENTER,
                                                autoClose: false,
                                                style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                            });
                                        }
                                        else {
                                            let ListaDeSinais = [...getFormularioListaDeSinais];
                                            ListaDeSinais[Index].DetalharMeioDePagamento = false;
                                            await setFormularioListaDeSinais(ListaDeSinais);
                                        }
                                    }}>Recolher informações</Button>
                                </Component.ContentFormHeader>}
                            </div>
                        )}
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <FormGroup label="Total gerado:" labelFor="SinalValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                                <InputGroup id="SinalValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                    value={Moeda.FormatarTexto(getFormularioListaDeSinais.reduce((total, parcela) => (total + parcela.Valor), 0))} leftIcon="numerical" />
                            </FormGroup>
                            <FormGroup style={{ marginRight: "10px" }} label="Total esperado:" labelFor="SinalValorTotalEsperado" inline={true} >
                                <InputGroup id="SinalValorTotalEsperado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                    value={Moeda.FormatarTexto(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0)} leftIcon="numerical" />
                            </FormGroup>
                            <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeSinais.reduce((total, parcela) => (total + parcela.Valor), 0)) ? "A distribuir" : "Excedido"} labelFor="SinalValorTotalADistribuir" inline={true}>
                                <InputGroup id="SinalValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                    value={Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 2)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) - getFormularioListaDeSinais.reduce((total, parcela) => (total + parcela.Valor), 0))} leftIcon="numerical" />
                            </FormGroup>
                        </div>
                    </Component.ContentFormHeader>
                </Component.ContentCard>}
                {getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao) && <Component.ContentCard>
                    <Component.Title>
                        <Icon icon={getHabilitarInclusaoDeIntermediarias == true ? "remove" : "add"} style={{ cursor: "pointer" }} iconSize={20} onClick={async () => { await setHabilitarInclusaoDeIntermediarias(!getHabilitarInclusaoDeIntermediarias); }} />
                        <h3>Sobre a intermediária</h3>
                        {(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) > 0 && <h6 style={{ marginTop: "30px", marginLeft: "10px" }}>(taxa de juros de {Percentual.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) * 100)}  a.m.)</h6>}
                    </Component.Title>
                    <Collapse isOpened={getHabilitarInclusaoDeIntermediarias} theme={{ collapse: 'foo', content: 'bar' }}>
                        <div style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", padding: "10px" }}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <FormGroup label="Valor da Intermediaria:" labelFor="IntermediariaValor" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="IntermediariaValor">
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(getFormularioIntermediariaValor)} leftIcon="dollar"
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioIntermediariaValor(Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00)}
                                                style={{ width: "235px" }}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>
                                    <FormGroup label="Quantidade de Intermediarias:" disabled={true} labelFor="IntermediariaQtde" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <InputGroup id="IntermediariaQtde" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(QtdeDeIntermediarias(getFormularioIntermediariaVencimento || (getFormularioParcelaVencimento || new Date())))} leftIcon="numerical" style={{ width: "235px" }} />
                                    </FormGroup>
                                    <FormGroup label="Valor total:" labelFor="IntermediariaValorTotal" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="IntermediariaValorTotal">
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(getFormularioIntermediariaValor * QtdeDeIntermediarias(getFormularioIntermediariaVencimento))} leftIcon="dollar" style={{ width: "235px" }} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup label="Primeiro vencimento:" labelFor="IntermediariaVencimento" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <DateInput inputProps={{ style: { marginLeft: "18px", width: "235px" }, onClick: () => setFormularioIntermediariaExibirCalendarioVencimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{ disabled: !getFormularioIntermediariaExibirCalendarioVencimento, onClosed: () => setFormularioIntermediariaExibirCalendarioVencimento(false) }}
                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} value={getFormularioIntermediariaVencimento} maxDate={new Date((new Date().getFullYear() + 40).toString() + "-01-01")}
                                            onChange={async (DataSelecionada: Date) => {
                                                if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                    await setFormularioIntermediariaVencimento(getFormularioDataDaProposta ?? new Date());
                                                }
                                                else {
                                                    if (DataSelecionada.getDate() != 5 && DataSelecionada.getDate() != 15 && getFormularioIntermediariaMeioDePagamento == "Boleto") {
                                                        NotificacaoInterna.ExibirNotificacao("Data invalida", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        await setFormularioIntermediariaVencimento(getFormularioIntermediariaVencimento);
                                                        return;
                                                    }
                                                    await setFormularioIntermediariaVencimento(new Date(DataSelecionada));
                                                }
                                            }}>
                                        </DateInput>
                                    </FormGroup>
                                </div>
                                <div style={{ marginLeft: "20px" }}>
                                    <FormGroup label="Meio de pagamento:" labelFor="MeioDePagamento" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={getFormularioIntermediariaMeioDePagamento}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => { await setFormularioIntermediariaMeioDePagamento(event.currentTarget.value); }}>
                                            <option selected value="">Selecione o meio de pagamento</option>
                                            {MeioDePagamento.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                    </FormGroup>
                                    {getFormularioIntermediariaMeioDePagamento == "Cheque" && <div>
                                        <FormGroup label="Banco:" style={{ color: "black", fontSize: "14px" }} labelFor="Banco" inline={true}>
                                            <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioIntermediariaBanco(_Item); }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "84px", width: "12vw" }} text={getFormularioIntermediariaBanco ? ((getFormularioIntermediariaBanco).chave + ": " + (getFormularioIntermediariaBanco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                            </PickerBanco>
                                        </FormGroup>
                                        <FormGroup label="Agência:" style={{ color: "black", fontSize: "14px" }} labelFor="Agencia" inline={true} >
                                            <InputGroup id="Agencia" style={{ marginLeft: "73px", width: "12vw" }} placeholder={"00000"} value={getFormularioIntermediariaAgencia}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaAgencia(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <Component.FormContent>
                                            <FormGroup label="Conta:" style={{ color: "black", fontSize: "14px" }} labelFor="Conta" inline={true} >
                                                <InputGroup id="Conta" style={{ marginLeft: "86px", width: "12vw" }} placeholder={"00000"} value={getFormularioIntermediariaConta}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaConta(event.currentTarget.value ?? ""); }}>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup label="- " style={{ color: "black", fontSize: "14px", marginLeft: "10px" }} labelFor="DigitoDaConta" inline={true}>
                                                <InputGroup id="DigitoDaConta" style={{ width: "3vw" }} placeholder={"00"} value={getFormularioIntermediariaDigitoDaConta}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaDigitoDaConta(event.currentTarget.value ?? ""); }}>
                                                </InputGroup>
                                            </FormGroup>
                                        </Component.FormContent>
                                        <FormGroup label="Titular:" style={{ color: "black", fontSize: "14px" }} labelFor="Titular" inline={true} >
                                            <InputGroup style={{ marginLeft: "82px", width: "12vw" }} placeholder={"Informe o nominal"} value={getFormularioIntermediariaTitular}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaTitular(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="Nº do cheque:" style={{ color: "black", fontSize: "14px" }} labelFor="NumeroCheque" inline={true} >
                                            <InputGroup style={{ marginLeft: "38px", width: "12vw" }} placeholder={"Informe o número"} value={getFormularioIntermediariaNumeroCheque}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaNumeroCheque(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                    {getFormularioIntermediariaMeioDePagamento == "Cartao" && <div>
                                        <FormGroup label="Máquina:" style={{ color: "black", fontSize: "14px" }} labelFor="Maquina" inline={true} >
                                            <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioIntermediariaMaquina(_Item.Valor); }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "70px", width: "12vw" }} text={getFormularioIntermediariaMaquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                            </PickerMaquina>
                                        </FormGroup>
                                        <FormGroup label="Dígito do cartão:" style={{ color: "black", fontSize: "14px" }} labelFor="DigitoCartao" inline={true} >
                                            <InputGroup style={{ marginLeft: "25px", width: "12vw" }} placeholder={"Informe os últimos 04 dígitos do cartão"} value={getFormularioIntermediariaDigitoCartao}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaDigitoCartao(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="Bandeira:" style={{ color: "black", fontSize: "14px" }} labelFor="Bandeira" inline={true} >
                                            <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioIntermediariaBandeira(_Item.Valor); }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "69px", width: "12vw" }} text={getFormularioIntermediariaBandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                            </PickerMaquina>
                                        </FormGroup>
                                        <FormGroup label="Operação:" style={{ color: "black", fontSize: "14px" }} labelFor="Operacao" inline={true} >
                                            <HTMLSelect name="Operacao" style={{ marginLeft: "64px", width: "12vw" }} value={getFormularioIntermediariaOperacao == undefined ? "" : getFormularioIntermediariaOperacao}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => { await setFormularioIntermediariaOperacao(event.currentTarget.value ?? ""); }}>
                                                <option selected value="">Selecione a operação</option>
                                                {Operacao.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                        </FormGroup>
                                        <FormGroup label="NSU:" style={{ color: "black", fontSize: "14px" }} labelFor="NSU" inline={true} >
                                            <InputGroup style={{ marginLeft: "95px", width: "12vw" }} placeholder={"Informe o NSU"} value={getFormularioIntermediariaNSU}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaNSU(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                    {(getFormularioIntermediariaMeioDePagamento == "Deposito" || getFormularioIntermediariaMeioDePagamento == "Transferencia" || getFormularioIntermediariaMeioDePagamento == "PIX") && <div>
                                        <FormGroup label="Nº da operação:" style={{ color: "black", fontSize: "14px" }} labelFor="NumeroDaOperacao" inline={true} >
                                            <InputGroup style={{ marginLeft: "27px", width: "12vw" }} placeholder={"00000"} value={getFormularioIntermediariaNSU}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIntermediariaNSU(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: "right" }}>
                                <Button type="submit" intent="primary" style={{ marginRight: "50px", width: '100px' }}
                                    onClick={async () => {
                                        if (!getItemPickerCondicaoDaTabelaDeVenda) {
                                            NotificacaoInterna.ExibirNotificacao("Nenhum modelo de venda selecionado", "Selecione o modelo de vendas", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (QtdeDeIntermediarias(getFormularioIntermediariaVencimento) <= 0) {
                                            NotificacaoInterna.ExibirNotificacao("Quantidade de Intermediarias é invalida", "Informe uma quantidade de Intermediarias maior ou igual a 01", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (getItemPickerCondicaoDaTabelaDeVenda.descricao.toUpperCase().includes("VISTA") == false && getFormularioIntermediariaValor.toFixed(2) != getFormularioIntermediariaValor.toFixed(2)) {
                                            NotificacaoInterna.ExibirNotificacao("Valor gerado de sinal diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(getFormularioIntermediariaValor) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(getFormularioIntermediariaValor), NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (!getFormularioIntermediariaVencimento || getFormularioIntermediariaVencimento == null || getFormularioIntermediariaVencimento < getFormularioDataDaProposta) {
                                            NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (getFormularioIntermediariaVencimento.getDate() != 5 && getFormularioIntermediariaVencimento.getDate() != 15 && getFormularioIntermediariaMeioDePagamento == "Boleto") {
                                            NotificacaoInterna.ExibirNotificacao("Vencimento invalido", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                            await setFormularioIntermediariaVencimento(getFormularioIntermediariaVencimento);
                                            return;
                                        }
                                        if (getFormularioIntermediariaMeioDePagamento == "") {
                                            NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existem meios de pagamento referente ao sinal que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias.push({
                                            Quantidade: QtdeDeIntermediarias(getFormularioIntermediariaVencimento),
                                            ValorPMT: 0,
                                            ValorTotal: 0,
                                            ValorTotalJuros: 0,
                                            Valor: getFormularioIntermediariaValor,
                                            Vencimento: getFormularioIntermediariaVencimento,
                                            Banco: getFormularioIntermediariaBanco,
                                            Agencia: getFormularioIntermediariaAgencia,
                                            Conta: getFormularioIntermediariaConta,
                                            DigitoDaConta: getFormularioIntermediariaDigitoDaConta,
                                            Titular: getFormularioIntermediariaTitular,
                                            NumeroCheque: getFormularioIntermediariaNumeroCheque,
                                            Maquina: getFormularioIntermediariaMaquina,
                                            Bandeira: getFormularioIntermediariaBandeira,
                                            DigitoCartao: getFormularioIntermediariaDigitoCartao,
                                            Operacao: getFormularioIntermediariaOperacao,
                                            NSU: getFormularioIntermediariaNSU,
                                            NumeroDaOperacao: getFormularioIntermediariaNumeroDaOperacao,
                                            MeioDePagamento: getFormularioIntermediariaMeioDePagamento,
                                            DetalharMeioDePagamento: false,
                                        } as { Quantidade: number, ValorPMT: number, ValorTotal: number, ValorTotalJuros: number, Valor: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined })
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        setHabilitarInclusaoDeIntermediarias(false);

                                    }}>Salvar</Button>
                                <Button type="submit" intent="danger" style={{ width: '100px' }}
                                    onClick={async () => {
                                        await setFormularioIntermediariaValor(0);
                                        await setFormularioIntermediariaVencimento(undefined);
                                        await setFormularioIntermediariaBanco(undefined);
                                        await setFormularioIntermediariaAgencia(undefined);
                                        await setFormularioIntermediariaConta(undefined);
                                        await setFormularioIntermediariaDigitoDaConta(undefined);
                                        await setFormularioIntermediariaTitular(undefined);
                                        await setFormularioIntermediariaNumeroCheque(undefined);
                                        await setFormularioIntermediariaMaquina(undefined);
                                        await setFormularioIntermediariaBandeira(undefined);
                                        await setFormularioIntermediariaDigitoCartao(undefined);
                                        await setFormularioIntermediariaOperacao(undefined);
                                        await setFormularioIntermediariaNSU(undefined);
                                        await setFormularioIntermediariaNumeroDaOperacao(undefined);
                                    }}>Limpar</Button>
                            </div>
                        </div>
                    </Collapse>
                    {getFormularioListaDeIntermediarias.length > 0 && <div style={{ display: "flex" }}>
                        <div style={{ width: "20px", textAlign: "center" }}>
                            <h5></h5>
                        </div>
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Qtde</h5>
                        </div>
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Valor da 01º parc.</h5>
                        </div>
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Valor total</h5>
                        </div>
                        <div style={{ width: "10vw", marginLeft: "10px", textAlign: "center" }}>
                            <h5>01º venc</h5>
                        </div>
                        <div style={{ width: "20vw", marginLeft: "10px", textAlign: "center" }}>
                            <h5>Meio de pagamento</h5>
                        </div>
                    </div>}
                    {getFormularioListaDeIntermediarias.map((Item, Index) =>
                        <div style={{ display: "block" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "20px", marginRight: "10px", paddingTop: "5px" }}>
                                    <Icon icon="remove" iconSize={20} onClick={async () => {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias.splice(Index, 1);
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }} />
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(QtdeDeIntermediarias(Item.Vencimento))} leftIcon="numerical" />
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.Valor)} leftIcon="dollar"
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Valor = (Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00);
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(Item.Valor * QtdeDeIntermediarias(Item.Vencimento))} leftIcon="dollar" />
                                </div>
                                <div style={{ width: "10vw", marginRight: "30px" }}>
                                    <DateInput inputProps={{
                                        onClick: () => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].ExibirCalendarioVencimento = true;
                                            setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }
                                    }}
                                        popoverProps={{
                                            disabled: !Item.ExibirCalendarioVencimento, onClosed: () => {
                                                let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                ListaDeIntermediarias[Index].ExibirCalendarioVencimento = false;
                                                setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                            }
                                        }}
                                        dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} value={Item.Vencimento} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                        onChange={async (DataSelecionada: Date, AlteracaoDeUsuario: boolean) => {
                                            if (AlteracaoDeUsuario == true) {
                                                let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                    ListaDeIntermediarias[Index].Vencimento = (getFormularioDataDaProposta ?? new Date());
                                                }
                                                else {
                                                    if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "CreditoIntermediariado") {
                                                        ListaDeIntermediarias.map((Item_, Index_) => {
                                                            if (Index_ >= Index && Item_.MeioDePagamento == "Cartao" && Item_.Operacao == "CreditoIntermediariado") {
                                                                let Vencimento = new Date(DataSelecionada);
                                                                Vencimento.setDate(Vencimento.getDate() + (Index_ - Index) * 30);
                                                                Item_.Vencimento = Vencimento;
                                                            }
                                                        });
                                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                    }
                                                    else {
                                                        if (DataSelecionada.getDate() != 5 && DataSelecionada.getDate() != 15 && ListaDeIntermediarias[Index].MeioDePagamento == "Boleto") {
                                                            NotificacaoInterna.ExibirNotificacao("Data invalida", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);

                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias[Index].Vencimento = ListaDeIntermediarias[Index].Vencimento;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                            return;
                                                        }
                                                        else {
                                                            if (ListaDeIntermediarias[Index].MeioDePagamento == "Boleto" && ListaDeIntermediarias.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == "Boleto")).length > 0) {
                                                                Toast(
                                                                    <div style={{}}>
                                                                        <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`Existem Intermediarias do tipo boleto com o dia de vencimento diferente da primeira Intermediaria, deseja igualar o dia de vencimento de todos os Intermediarias do tipo boleto?`}</p>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                            <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => {
                                                                                    let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                                                    ListaDeIntermediarias[Index].Vencimento = DataSelecionada;
                                                                                    await setFormularioListaDeIntermediarias(ListaDeIntermediarias);

                                                                                    ListaDeIntermediarias.map(async (Item_, Index_) => {
                                                                                        ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                                                        let Vencimento = new Date(Item_.Vencimento);
                                                                                        Vencimento.setDate(ListaDeIntermediarias[Index].Vencimento.getDate());
                                                                                        Vencimento.setFullYear(ListaDeIntermediarias[Index].Vencimento.getFullYear());
                                                                                        Vencimento.setMonth(ListaDeIntermediarias[Index].Vencimento.getMonth() + Index_ - Index);

                                                                                        if (Index_ > Index && Item_.MeioDePagamento == "Boleto") {
                                                                                            Item_.Vencimento = Vencimento;
                                                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                                                        }
                                                                                        if (Index_ == ListaDeIntermediarias.length - 1) {
                                                                                            Vencimento = new Date(Vencimento);
                                                                                            Vencimento.setMonth(Vencimento.getMonth() + 1);
                                                                                            await setFormularioIntermediariaVencimento(Vencimento);
                                                                                        }
                                                                                    });
                                                                                }}>Sim</Button>
                                                                            <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => { }}>Não</Button>
                                                                        </div>
                                                                    </div>, {
                                                                    position: Toast.POSITION.TOP_CENTER,
                                                                    autoClose: false,
                                                                    style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                });
                                                            }
                                                            else {
                                                                let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                                ListaDeIntermediarias[Index].Vencimento = DataSelecionada;
                                                                await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }}>
                                    </DateInput>
                                </div>
                                <div style={{ width: "20vw" }}>
                                    <Component.ContentFormHeader style={{ margin: "0" }}>
                                        <FormGroup labelFor="MeioDePagamento" inline={true}>
                                            <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={Item.MeioDePagamento}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                    ListaDeIntermediarias[Index].MeioDePagamento = event.currentTarget.value;
                                                    ListaDeIntermediarias[Index].Banco = undefined;
                                                    ListaDeIntermediarias[Index].Agencia = undefined;
                                                    ListaDeIntermediarias[Index].Conta = undefined;
                                                    ListaDeIntermediarias[Index].DigitoDaConta = undefined;
                                                    ListaDeIntermediarias[Index].Titular = undefined;
                                                    ListaDeIntermediarias[Index].NumeroCheque = undefined;
                                                    ListaDeIntermediarias[Index].Maquina = undefined;
                                                    ListaDeIntermediarias[Index].Bandeira = undefined;
                                                    ListaDeIntermediarias[Index].DigitoCartao = undefined;
                                                    ListaDeIntermediarias[Index].Operacao = undefined;
                                                    ListaDeIntermediarias[Index].NSU = undefined;
                                                    ListaDeIntermediarias[Index].NumeroDaOperacao = undefined;
                                                    await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                }}>
                                                <option selected value="">Selecione o meio de pagamento</option>
                                                {MeioDePagamento.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                        </FormGroup>
                                    </Component.ContentFormHeader>
                                </div>
                                <div style={{ width: "100px" }}>
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cheque" && <Component.DescriptionContent title="Clique aqui para editar estas informações" style={{ width: "10vw" }} onClick={async () => {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = !(ListaDeIntermediarias[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }}>
                                        <strong>Sobre o cheque:</strong> Banco: {Item.Banco?.chave ?? "--"}, Agência: {Item.Agencia ?? "--"}, Conta: {Item.Conta ?? "--"}{Item.DigitoDaConta == undefined ? "" : ("-" + Item.DigitoDaConta)}, Titular: {Item.Titular ?? "--"}, Nº do cheque: {Item.NumeroCheque ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cartao" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = !(ListaDeIntermediarias[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }}>
                                        <strong>Sobre o cartão:</strong> Máquina: {Item.Maquina ?? "--"}, Digito: {Item.DigitoCartao ?? "--"}, Bandeira: {Item.Bandeira ?? "--"}, Operação: {Item.Operacao ?? "--"}, NSU: {Item.NSU ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = !(ListaDeIntermediarias[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }}>
                                        <strong>Sobre o {Item.MeioDePagamento}:</strong> Nº da operação: {Item.NumeroDaOperacao ?? "--"}
                                    </Component.DescriptionContent>}
                                </div>
                            </div>
                            {/* {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 && <div style = {{display: 'flex', marginBottom: '10px'}}>
                                <div style={{ width: "20px", marginRight: "10px", paddingTop: "5px" }}>
                                    
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <h5>C/Juros</h5>
                                    <InputGroup disabled={true} placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.ValorPMT)} leftIcon="dollar"/>
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <h5>C/Juros</h5>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(Item.ValorTotalJuros)} leftIcon="dollar" />
                                </div>
                                <div style={{ width: "10vw", marginRight: "30px" }}>
                                    
                                </div>
                                <div style={{ width: "20vw" }}>
                                    
                                </div>
                                <div style={{ width: "100px" }}>

                                </div>
                            </div>} */}
                            {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cheque" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Banco:" className="banco" labelFor="Banco" inline={true}>
                                    <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Banco = _Item;
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Banco ? ((Item.Banco).chave + ": " + (Item.Banco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                    </PickerBanco>
                                </FormGroup>
                                <FormGroup label="Agência:" className="agência" labelFor="Agencia" inline={true} >
                                    <InputGroup id="Agencia" placeholder={"00000"} value={Item.Agencia}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Agencia = event.currentTarget.value ?? "";
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Component.FormContent>
                                    <FormGroup label="Conta:" className="conta" labelFor="Conta" inline={true} >
                                        <InputGroup id="Conta" placeholder={"00000"} value={Item.Conta}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                ListaDeIntermediarias[Index].Conta = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="- " labelFor="DigitoDaConta" inline={true} style={{ marginLeft: "20px" }} >
                                        <InputGroup id="DigitoDaConta" placeholder={"00"} value={Item.DigitoDaConta}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                ListaDeIntermediarias[Index].DigitoDaConta = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                </Component.FormContent>
                                <FormGroup label="Titular:" className="titular" labelFor="Titular" inline={true} >
                                    <InputGroup id="Titular" placeholder={"Informe o nominal"} value={Item.Titular}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Titular = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Nº do cheque:" labelFor="NumeroCheque" inline={true} >
                                    <InputGroup id="NumeroCheque" placeholder={"Informe o número"} value={Item.NumeroCheque}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].NumeroCheque = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                    let IntermediariasVazios = []
                                    IntermediariasVazios = ListaDeIntermediarias.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == ""))
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeIntermediarias.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Banco ?? "") == "" && (Item__.Agencia ?? "") == "" && (Item__.Conta ?? "") == "" && (Item__.DigitoDaConta ?? "") == "" && (Item__.Titular ?? "") == "" && (Item__.NumeroCheque ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (IntermediariasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let IntermediariasVazios = [...getFormularioListaDeIntermediarias];
                                                            IntermediariasVazios.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == "") {
                                                                    Item_.Banco = Item.Banco
                                                                    Item_.Agencia = Item.Agencia
                                                                    Item_.Conta = Item.Conta
                                                                    Item_.DigitoDaConta = Item.DigitoDaConta
                                                                    Item_.Titular = Item.Titular
                                                                    Item_.NumeroCheque = Item.NumeroCheque
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                                }
                                                            })
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        })
                                    }
                                    else {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                            {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cartao" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Máquina:" className="máquina" labelFor="Maquina" inline={true} >
                                    <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Maquina = _Item.Valor;
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Maquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                    </PickerMaquina>
                                </FormGroup>
                                <FormGroup label="Dígito do cartão:" labelFor="DigitoCartao" inline={true} >
                                    <InputGroup id="DigitoCartao" placeholder={"Informe os últimos 04 dígitos do cartão"} value={Item.DigitoCartao}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].DigitoCartao = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Bandeira:" className="bandeira" labelFor="Bandeira" inline={true} >
                                    <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Bandeira = _Item.Valor;
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Bandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                    </PickerMaquina>
                                </FormGroup>
                                <FormGroup label="Terminal:" className="terminal" labelFor="Terminal" inline={true} >
                                    <PickerTerminal popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={GetTerminais} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { id: number, descricao: string }) => <MenuItem onClick={async () => {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].Terminal = _Item.id;
                                            setFormularioListaDeEntradas(ListaDeEntradas);
                                        }} text={_Item.descricao} />} itemPredicate={(Texto: string, _Item: { id: number, descricao: string }) => { return ((_Item.descricao) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={GetTerminais.find((_Item) => _Item.id == Item.Terminal)?.descricao ?? "Selecione o terminal"} rightIcon="double-caret-vertical" />
                                    </PickerTerminal>
                                </FormGroup>
                                <FormGroup label="Cód. autorização:" labelFor="CodAutorizacao" inline={true} >
                                    <InputGroup id="CodAutorizacao" placeholder={"Informe o código de autorização"} value={Item?.CodAutorizacao}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].CodAutorizacao = (event.currentTarget.value ?? "");
                                            setFormularioListaDeEntradas(ListaDeEntradas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Operação:" className="operação" labelFor="Operacao" inline={true} >
                                    <HTMLSelect id="Operacao" name="Operacao" value={Item.Operacao == undefined ? "" : Item.Operacao}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].Operacao = (event.currentTarget.value ?? "");
                                            if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "Debito") {
                                                const EhDiaUtil = require('eh-dia-util');
                                                let Vencimento = new Date();
                                                Vencimento.setDate(Vencimento.getDate() + 1);
                                                while (EhDiaUtil(Vencimento) == false) {
                                                    Vencimento.setDate(Vencimento.getDate() + 1);
                                                }
                                                ListaDeIntermediarias[Index].Vencimento = Vencimento;
                                            }
                                            if (Item.MeioDePagamento == "Cartao" && Item.Operacao != "Debito") {
                                                ListaDeIntermediarias.map((Item_, Index_) => {
                                                    if (Index_ >= Index) {
                                                        let Vencimento = new Date(getFormularioDataDaProposta);
                                                        Vencimento.setDate(Vencimento.getDate() + (1 + Index_ - Index) * 30);
                                                        Item_.Vencimento = Vencimento;
                                                    }
                                                });
                                            }
                                            else {
                                                ListaDeIntermediarias[Index].Vencimento = ListaDeIntermediarias[Index].Vencimento;
                                            }
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                        <option selected value="">Selecione a operação</option>
                                        {Operacao.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                </FormGroup>
                                <FormGroup label="NSU:" className="nsu" labelFor="NSU" inline={true} >
                                    <InputGroup id="NSU" placeholder={"Informe o NSU"} value={Item.NSU}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].NSU = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                    let IntermediariasVazios = [];
                                    IntermediariasVazios = ListaDeIntermediarias.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == ""));
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeIntermediarias.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Maquina ?? "") == "" && (Item__.DigitoCartao ?? "") == "" && (Item__.Bandeira ?? "") == "" && (Item__.Operacao ?? "") == "" && (Item__.NSU ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (IntermediariasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == "") {
                                                                    Item_.Maquina = Item.Maquina
                                                                    Item_.DigitoCartao = Item.DigitoCartao
                                                                    Item_.Bandeira = Item.Bandeira
                                                                    Item_.Operacao = Item.Operacao
                                                                    Item_.NSU = Item.NSU
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                                }
                                                            })
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        })
                                    }
                                    else {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                            {Item.DetalharMeioDePagamento == true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Nº da operação:" labelFor="NumeroDaOperacao" inline={true} >
                                    <InputGroup id="NumeroDaOperacao" placeholder={"00000"} value={Item.NSU}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                            ListaDeIntermediarias[Index].NumeroDaOperacao = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                    let IntermediariasVazios = [];
                                    IntermediariasVazios = ListaDeIntermediarias.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == ""));
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeIntermediarias.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.NumeroDaOperacao ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (IntermediariasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == "") {
                                                                    Item_.NumeroDaOperacao = Item.NumeroDaOperacao
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                                }
                                                            })
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                                            ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        });
                                    }
                                    else {
                                        let ListaDeIntermediarias = [...getFormularioListaDeIntermediarias];
                                        ListaDeIntermediarias[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeIntermediarias(ListaDeIntermediarias);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                        </div>
                    )}

                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {false && <FormGroup label="Total gerado:" labelFor="IntermediariaValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                            <InputGroup id="IntermediariaValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.Valor * QtdeDeIntermediarias(Intermediaria.Vencimento)), 0))} leftIcon="numerical" />
                        </FormGroup>}
                        {/* <FormGroup label="Total gerado:" labelFor="IntermediariaValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                            <InputGroup id="IntermediariaValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.ValorPMT * Math.round(Intermediaria.ValorTotalJuros/Intermediaria.ValorPMT)), 0))} leftIcon="numerical" />
                        </FormGroup> */}
                        <FormGroup style={{ marginRight: "10px" }} label="Total esperado:" labelFor="IntermediariaValorTotalEsperado" inline={true}>
                            <InputGroup id="IntermediariaValorTotalEsperado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0) *
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0)
                                )} leftIcon="numerical" />
                        </FormGroup>
                        {false && <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.Valor * QtdeDeIntermediarias(Intermediaria.Vencimento)), 0)) ? "A distribuir: " : "Excedido: "} labelFor="EntradaValorTotalADistribuir" inline={true}>
                            <InputGroup id="IntermediariaValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) - getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.Valor * QtdeDeIntermediarias(Intermediaria.Vencimento)), 0))} leftIcon="numerical" />
                        </FormGroup>}
                        {/* <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.Valor * QtdeDeIntermediarias(Intermediaria.Vencimento)), 0)) ? "A distribuir: " : "Excedido: "} labelFor="EntradaValorTotalADistribuir" inline={true}>
                            <InputGroup id="IntermediariaValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 14)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) - getFormularioListaDeIntermediarias.reduce((total, Intermediaria) => (total + Intermediaria.Valor * QtdeDeIntermediarias(Intermediaria.Vencimento)), 0))} leftIcon="numerical" />
                        </FormGroup> */}
                    </div>
                </Component.ContentCard>}
                {getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao) && <Component.ContentCard>
                    <Component.Title>
                        <Icon icon={getHabilitarInclusaoDeParcelas == true ? "remove" : "add"} style={{ cursor: "pointer" }} iconSize={20} onClick={async () => { await setHabilitarInclusaoDeParcelas(!getHabilitarInclusaoDeParcelas); }} />
                        <h3>Sobre o saldo</h3>
                        {(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) > 0 && <h6 style={{ marginTop: "30px", marginLeft: "10px" }}>(taxa de juros de {Percentual.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0) * 100)}  a.m.)</h6>}
                    </Component.Title>
                    <Collapse isOpened={getHabilitarInclusaoDeParcelas} theme={{ collapse: 'foo', content: 'bar' }}>
                        <div style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", padding: "10px" }}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <FormGroup label="Valor da parcela:" labelFor="ParcelaValor" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="ParcelaValor">
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(getFormularioParcelaValor)} leftIcon="dollar"
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var Valor = Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00;
                                                    await setFormularioParcelaValor(Valor);
                                                    if ((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.sistemaDeAmortizacao || 0) == 1) {
                                                        var I = (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.jurosDeTabela || 0);
                                                        var PMT = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0;
                                                        var N = getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0;
                                                        await setFormularioParcelaQtde(NPER(I, Valor, PV(I, N, PMT)));
                                                    }
                                                }}
                                                style={{ width: "235px" }}>
                                            </InputGroup>
                                        </div>
                                    </FormGroup>
                                    <FormGroup label="Quantidade de parcelas:" labelFor="ParcelaQtde" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <InputGroup id="ParcelaQtde" placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(getFormularioParcelaQtde)} leftIcon="numerical"
                                            disabled={(getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.sistemaDeAmortizacao || 0) == 1}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaQtde(Numero.DesformatarTextoParaInteiro(event.currentTarget.value) ?? 0); }} style={{ width: "235px" }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="Valor total:" labelFor="ParcelaValorTotal" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <div id="ParcelaValorTotal" >
                                            <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={
                                                Moeda.FormatarTexto(getFormularioParcelaValor * getFormularioParcelaQtde)} leftIcon="dollar"
                                                style={{ width: "235px" }} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup label="Primeiro vencimento:" labelFor="ParcelaVencimento" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <DateInput inputProps={{ style: { marginLeft: "18px", width: "235px" }, onClick: () => setFormularioParcelaExibirCalendarioVencimento(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                            popoverProps={{ disabled: !getFormularioParcelaExibirCalendarioVencimento, onClosed: () => setFormularioParcelaExibirCalendarioVencimento(false) }}
                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                            placeholder={"DD/MM/AAAA"} value={getFormularioParcelaVencimento} maxDate={new Date((new Date().getFullYear() + 40).toString() + "-01-01")}
                                            onChange={async (DataSelecionada: Date) => {
                                                if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                    await setFormularioParcelaVencimento(getFormularioDataDaProposta ?? new Date());
                                                }
                                                else {
                                                    if (DataSelecionada.getDate() != 5 && DataSelecionada.getDate() != 15 && getFormularioParcelaMeioDePagamento == "Boleto") {
                                                        NotificacaoInterna.ExibirNotificacao("Data invalida", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        await setFormularioParcelaVencimento(getFormularioParcelaVencimento);
                                                        return;
                                                    }
                                                    await setFormularioParcelaVencimento(new Date(DataSelecionada));
                                                }
                                            }}>
                                        </DateInput>
                                    </FormGroup>
                                </div>
                                <div style={{ marginLeft: "20px" }}>
                                    <FormGroup label="Meio de pagamento:" labelFor="MeioDePagamento" inline={true} style={{ color: "black", fontSize: "14px" }}>
                                        <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={getFormularioParcelaMeioDePagamento}
                                            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => { await setFormularioParcelaMeioDePagamento(event.currentTarget.value); }}>
                                            <option selected value="">Selecione o meio de pagamento</option>
                                            {MeioDePagamento.map((Item) =>
                                                <option selected value={Item.chave}>{Item.Valor}</option>
                                            )}
                                        </HTMLSelect>
                                    </FormGroup>
                                    {getFormularioParcelaMeioDePagamento == "Cheque" && <div>
                                        <FormGroup label="Banco:" style={{ color: "black", fontSize: "14px" }} labelFor="Banco" inline={true}>
                                            <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioParcelaBanco(_Item); }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "84px", width: "12vw" }} text={getFormularioParcelaBanco ? ((getFormularioParcelaBanco).chave + ": " + (getFormularioParcelaBanco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                            </PickerBanco>
                                        </FormGroup>
                                        <FormGroup label="Agência:" style={{ color: "black", fontSize: "14px" }} labelFor="Agencia" inline={true} >
                                            <InputGroup id="Agencia" style={{ marginLeft: "73px", width: "12vw" }} placeholder={"00000"} value={getFormularioParcelaAgencia}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaAgencia(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <Component.FormContent>
                                            <FormGroup label="Conta:" style={{ color: "black", fontSize: "14px" }} labelFor="Conta" inline={true} >
                                                <InputGroup id="Conta" style={{ marginLeft: "86px", width: "12vw" }} placeholder={"00000"} value={getFormularioParcelaConta}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaConta(event.currentTarget.value ?? ""); }}>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup label="- " style={{ color: "black", fontSize: "14px", marginLeft: "10px" }} labelFor="DigitoDaConta" inline={true}>
                                                <InputGroup id="DigitoDaConta" style={{ width: "3vw" }} placeholder={"00"} value={getFormularioParcelaDigitoDaConta}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaDigitoDaConta(event.currentTarget.value ?? ""); }}>
                                                </InputGroup>
                                            </FormGroup>
                                        </Component.FormContent>
                                        <FormGroup label="Titular:" style={{ color: "black", fontSize: "14px" }} labelFor="Titular" inline={true} >
                                            <InputGroup style={{ marginLeft: "82px", width: "12vw" }} placeholder={"Informe o nominal"} value={getFormularioParcelaTitular}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaTitular(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="Nº do cheque:" style={{ color: "black", fontSize: "14px" }} labelFor="NumeroCheque" inline={true} >
                                            <InputGroup style={{ marginLeft: "38px", width: "12vw" }} placeholder={"Informe o número"} value={getFormularioParcelaNumeroCheque}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaNumeroCheque(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                    {getFormularioParcelaMeioDePagamento == "Cartao" && <div>
                                        <FormGroup label="Máquina:" style={{ color: "black", fontSize: "14px" }} labelFor="Maquina" inline={true} >
                                            <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioParcelaMaquina(_Item.Valor); }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "70px", width: "12vw" }} text={getFormularioParcelaMaquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                            </PickerMaquina>
                                        </FormGroup>
                                        <FormGroup label="Dígito do cartão:" style={{ color: "black", fontSize: "14px" }} labelFor="DigitoCartao" inline={true} >
                                            <InputGroup style={{ marginLeft: "25px", width: "12vw" }} placeholder={"Informe os últimos 04 dígitos do cartão"} value={getFormularioParcelaDigitoCartao}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaDigitoCartao(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup label="Bandeira:" style={{ color: "black", fontSize: "14px" }} labelFor="Bandeira" inline={true} >
                                            <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => { await setFormularioParcelaBandeira(_Item.Valor); }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button style={{ marginLeft: "69px", width: "12vw" }} text={getFormularioParcelaBandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                            </PickerMaquina>
                                        </FormGroup>
                                        <FormGroup label="Operação:" style={{ color: "black", fontSize: "14px" }} labelFor="Operacao" inline={true} >
                                            <HTMLSelect name="Operacao" style={{ marginLeft: "64px", width: "12vw" }} value={getFormularioParcelaOperacao == undefined ? "" : getFormularioParcelaOperacao}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => { await setFormularioParcelaOperacao(event.currentTarget.value ?? ""); }}>
                                                <option selected value="">Selecione a operação</option>
                                                {Operacao.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                        </FormGroup>
                                        <FormGroup label="NSU:" style={{ color: "black", fontSize: "14px" }} labelFor="NSU" inline={true} >
                                            <InputGroup style={{ marginLeft: "95px", width: "12vw" }} placeholder={"Informe o NSU"} value={getFormularioParcelaNSU}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaNSU(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                    {(getFormularioParcelaMeioDePagamento == "Deposito" || getFormularioParcelaMeioDePagamento == "Transferencia" || getFormularioParcelaMeioDePagamento == "PIX") && <div>
                                        <FormGroup label="Nº da operação:" style={{ color: "black", fontSize: "14px" }} labelFor="NumeroDaOperacao" inline={true} >
                                            <InputGroup style={{ marginLeft: "27px", width: "12vw" }} placeholder={"00000"} value={getFormularioParcelaNSU}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioParcelaNSU(event.currentTarget.value ?? ""); }}>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: "right" }}>
                                <Button type="submit" intent="primary" style={{ marginRight: "50px", width: '100px' }}
                                    onClick={async () => {
                                        if (!getItemPickerCondicaoDaTabelaDeVenda) {
                                            NotificacaoInterna.ExibirNotificacao("Nenhum modelo de venda selecionado", "Selecione o modelo de vendas", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (getFormularioParcelaQtde <= 0) {
                                            NotificacaoInterna.ExibirNotificacao("Quantidade de parcelas é invalida", "Informe uma quantidade de parcelas maior ou igual a 01", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (getItemPickerCondicaoDaTabelaDeVenda.descricao.toUpperCase().includes("VISTA") == false && getFormularioParcelaValor.toFixed(2) != getFormularioParcelaValor.toFixed(2)) {
                                            NotificacaoInterna.ExibirNotificacao("Valor gerado de sinal diferente do modelo", "O valor total gerado foi de " + Moeda.FormatarTexto(getFormularioParcelaValor) + ", enquanto o modelo consta o valor de " + Moeda.FormatarTexto(getFormularioParcelaValor), NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (!getFormularioParcelaVencimento || getFormularioParcelaVencimento == null || getFormularioParcelaVencimento < getFormularioDataDaProposta) {
                                            NotificacaoInterna.ExibirNotificacao("Vencimento invalido", "Existe um vencimento anterior a data da proposta", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        if (getFormularioParcelaVencimento.getDate() != 5 && getFormularioParcelaVencimento.getDate() != 15 && getFormularioParcelaMeioDePagamento == "Boleto") {
                                            NotificacaoInterna.ExibirNotificacao("Vencimento invalido", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                            await setFormularioParcelaVencimento(getFormularioParcelaVencimento);
                                            return;
                                        }
                                        if (getFormularioParcelaMeioDePagamento == "") {
                                            NotificacaoInterna.ExibirNotificacao("Nem todos os meios de pagamento foram informados", "Existem meios de pagamento referente ao sinal que constam vazio", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return false;
                                        }
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas.push({
                                            Quantidade: getFormularioParcelaQtde,
                                            Valor: getFormularioParcelaValor,
                                            ValorPMT: 0,
                                            ValorTotal: 0,
                                            ValorTotalJuros: 0,
                                            Vencimento: getFormularioParcelaVencimento,
                                            Banco: getFormularioParcelaBanco,
                                            Agencia: getFormularioParcelaAgencia,
                                            Conta: getFormularioParcelaConta,
                                            DigitoDaConta: getFormularioParcelaDigitoDaConta,
                                            Titular: getFormularioParcelaTitular,
                                            NumeroCheque: getFormularioParcelaNumeroCheque,
                                            Maquina: getFormularioParcelaMaquina,
                                            Bandeira: getFormularioParcelaBandeira,
                                            DigitoCartao: getFormularioParcelaDigitoCartao,
                                            Operacao: getFormularioParcelaOperacao,
                                            NSU: getFormularioParcelaNSU,
                                            NumeroDaOperacao: getFormularioParcelaNumeroDaOperacao,
                                            MeioDePagamento: getFormularioParcelaMeioDePagamento,
                                            DetalharMeioDePagamento: false,
                                        } as { Quantidade: number, Valor: number, ValorPMT: number, ValorTotal: number, ValorTotalJuros: number, Vencimento: Date, ExibirCalendarioVencimento: boolean, MeioDePagamento: string, DetalharMeioDePagamento?: boolean | undefined, Banco?: { chave: string, Valor: string } | undefined, Agencia?: string | undefined, Conta?: string | undefined, DigitoDaConta?: string | undefined, Titular?: string | undefined, NumeroCheque?: string | undefined, Maquina?: string | undefined, Terminal?: number | undefined, CodAutorizacao?: string | undefined, Bandeira?: string | undefined, DigitoCartao?: string | undefined, Operacao?: string | undefined, NSU?: string | undefined, NumeroDaOperacao?: string | undefined })
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                        setHabilitarInclusaoDeParcelas(false);

                                    }}>Salvar</Button>
                                <Button type="submit" intent="danger" style={{ width: '100px' }}
                                    onClick={async () => {
                                        await setFormularioParcelaQtde(0);
                                        await setFormularioParcelaValor(0);
                                        await setFormularioParcelaVencimento(undefined);
                                        await setFormularioParcelaBanco(undefined);
                                        await setFormularioParcelaAgencia(undefined);
                                        await setFormularioParcelaConta(undefined);
                                        await setFormularioParcelaDigitoDaConta(undefined);
                                        await setFormularioParcelaTitular(undefined);
                                        await setFormularioParcelaNumeroCheque(undefined);
                                        await setFormularioParcelaMaquina(undefined);
                                        await setFormularioParcelaBandeira(undefined);
                                        await setFormularioParcelaDigitoCartao(undefined);
                                        await setFormularioParcelaOperacao(undefined);
                                        await setFormularioParcelaNSU(undefined);
                                        await setFormularioParcelaNumeroDaOperacao(undefined);
                                    }}>Limpar</Button>
                            </div>
                        </div>
                    </Collapse>
                    {getFormularioListaDeParcelas.length > 0 && <div style={{ display: "flex" }}>
                        <div style={{ width: "20px", textAlign: "center" }}>
                            <h5></h5>
                        </div>
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Qtde</h5>
                        </div>
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Valor da 01ª parc.</h5>
                        </div>
                        <div style={{ width: "10vw", textAlign: "center" }}>
                            <h5>Valor total</h5>
                        </div>
                        <div style={{ width: "10vw", marginLeft: "10px", textAlign: "center" }}>
                            <h5>01º venc.</h5>
                        </div>
                        <div style={{ width: "20vw", marginLeft: "10px", textAlign: "center" }}>
                            <h5>Meio de pagamento</h5>
                        </div>
                    </div>}
                    {getFormularioListaDeParcelas.map((Item, Index) =>
                        <div style={{ display: "block" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "20px", marginRight: "10px", paddingTop: "5px" }}>
                                    <Icon icon="remove" iconSize={20} onClick={async () => {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas.splice(Index, 1);
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }} />
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Numero.FormatarTextoParaInteiro(0)} value={Numero.FormatarTextoParaInteiro(Item.Quantidade)} leftIcon="numerical"
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Quantidade = (Numero.DesformatarTextoParaInteiro(event.currentTarget.value) ?? 0);
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.Valor)} leftIcon="dollar"
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Valor = (Moeda.DesformatarTexto(event.currentTarget.value) ?? 0.00);
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(Item.Valor * Item.Quantidade)} leftIcon="dollar" />
                                </div>
                                <div style={{ width: "10vw", marginRight: "30px" }}>
                                    <DateInput inputProps={{
                                        onClick: () => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].ExibirCalendarioVencimento = true;
                                            setFormularioListaDeParcelas(ListaDeParcelas);
                                        }
                                    }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{
                                            disabled: !Item.ExibirCalendarioVencimento, onClosed: () => {
                                                let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                ListaDeParcelas[Index].ExibirCalendarioVencimento = false;
                                                setFormularioListaDeParcelas(ListaDeParcelas);
                                            }
                                        }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} value={Item.Vencimento} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                        onChange={async (DataSelecionada: Date, AlteracaoDeUsuario: boolean) => {
                                            if (AlteracaoDeUsuario == true) {
                                                let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                if (DataSelecionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
                                                    ListaDeParcelas[Index].Vencimento = (getFormularioDataDaProposta ?? new Date());
                                                }
                                                else {
                                                    if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "CreditoParcelado") {
                                                        ListaDeParcelas.map((Item_, Index_) => {
                                                            if (Index_ >= Index && Item_.MeioDePagamento == "Cartao" && Item_.Operacao == "CreditoParcelado") {
                                                                let Vencimento = new Date(DataSelecionada);
                                                                Vencimento.setDate(Vencimento.getDate() + (Index_ - Index) * 30);
                                                                Item_.Vencimento = Vencimento;
                                                            }
                                                        });
                                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                                    }
                                                    else {
                                                        if (DataSelecionada.getDate() != 5 && DataSelecionada.getDate() != 15 && ListaDeParcelas[Index].MeioDePagamento == "Boleto") {
                                                            NotificacaoInterna.ExibirNotificacao("Data invalida", `Para este empreendimento, apenas serão aceitas datas com vencimento para o dia 05 ou 15`, NotificacaoInterna.TipoDeNotificacao.Erro);

                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas[Index].Vencimento = ListaDeParcelas[Index].Vencimento;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                            return;
                                                        }
                                                        else {
                                                            if (ListaDeParcelas[Index].MeioDePagamento == "Boleto" && ListaDeParcelas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == "Boleto")).length > 0) {
                                                                Toast(
                                                                    <div style={{}}>
                                                                        <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`Existem Parcelas do tipo boleto com o dia de vencimento diferente da primeira parcela, deseja igualar o dia de vencimento de todos os Parcelas do tipo boleto?`}</p>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                            <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => {
                                                                                    let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                                                    ListaDeParcelas[Index].Vencimento = DataSelecionada;
                                                                                    await setFormularioListaDeParcelas(ListaDeParcelas);

                                                                                    ListaDeParcelas.map(async (Item_, Index_) => {
                                                                                        ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                                                        let Vencimento = new Date(Item_.Vencimento);
                                                                                        Vencimento.setDate(ListaDeParcelas[Index].Vencimento.getDate());
                                                                                        Vencimento.setFullYear(ListaDeParcelas[Index].Vencimento.getFullYear());
                                                                                        Vencimento.setMonth(ListaDeParcelas[Index].Vencimento.getMonth() + Index_ - Index);

                                                                                        if (Index_ > Index && Item_.MeioDePagamento == "Boleto") {
                                                                                            Item_.Vencimento = Vencimento;
                                                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                                                        }
                                                                                        if (Index_ == ListaDeParcelas.length - 1) {
                                                                                            Vencimento = new Date(Vencimento);
                                                                                            Vencimento.setMonth(Vencimento.getMonth() + 1);
                                                                                            await setFormularioParcelaVencimento(Vencimento);
                                                                                        }
                                                                                    });
                                                                                }}>Sim</Button>
                                                                            <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                                                onClick={async () => { }}>Não</Button>
                                                                        </div>
                                                                    </div>, {
                                                                    position: Toast.POSITION.TOP_CENTER,
                                                                    autoClose: false,
                                                                    style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                                                });
                                                            }
                                                            else {
                                                                let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                                ListaDeParcelas[Index].Vencimento = DataSelecionada;
                                                                await setFormularioListaDeParcelas(ListaDeParcelas);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }}>
                                    </DateInput>
                                </div>
                                <div style={{ width: "15vw" }}>
                                    <Component.ContentFormHeader style={{ margin: "0" }}>
                                        <FormGroup labelFor="MeioDePagamento" inline={true}>
                                            <HTMLSelect id="MeioDePagamento" name="MeioDePagamento" value={Item.MeioDePagamento}
                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                    ListaDeParcelas[Index].MeioDePagamento = event.currentTarget.value;
                                                    ListaDeParcelas[Index].Banco = undefined;
                                                    ListaDeParcelas[Index].Agencia = undefined;
                                                    ListaDeParcelas[Index].Conta = undefined;
                                                    ListaDeParcelas[Index].DigitoDaConta = undefined;
                                                    ListaDeParcelas[Index].Titular = undefined;
                                                    ListaDeParcelas[Index].NumeroCheque = undefined;
                                                    ListaDeParcelas[Index].Maquina = undefined;
                                                    ListaDeParcelas[Index].Bandeira = undefined;
                                                    ListaDeParcelas[Index].DigitoCartao = undefined;
                                                    ListaDeParcelas[Index].Operacao = undefined;
                                                    ListaDeParcelas[Index].NSU = undefined;
                                                    ListaDeParcelas[Index].NumeroDaOperacao = undefined;
                                                    await setFormularioListaDeParcelas(ListaDeParcelas);
                                                }}>
                                                <option selected value="">Selecione o meio de pagamento</option>
                                                {MeioDePagamento.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                        </FormGroup>
                                    </Component.ContentFormHeader>
                                </div>
                                <div style={{ width: "100px" }}>
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cheque" && <Component.DescriptionContent title="Clique aqui para editar estas informações" style={{ width: "10vw" }} onClick={async () => {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = !(ListaDeParcelas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }}>
                                        <strong>Sobre o cheque:</strong> Banco: {Item.Banco?.chave ?? "--"}, Agência: {Item.Agencia ?? "--"}, Conta: {Item.Conta ?? "--"}{Item.DigitoDaConta == undefined ? "" : ("-" + Item.DigitoDaConta)}, Titular: {Item.Titular ?? "--"}, Nº do cheque: {Item.NumeroCheque ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && Item.MeioDePagamento == "Cartao" && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = !(ListaDeParcelas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }}>
                                        <strong>Sobre o cartão:</strong> Máquina: {Item.Maquina ?? "--"}, Digito: {Item.DigitoCartao ?? "--"}, Bandeira: {Item.Bandeira ?? "--"}, Operação: {Item.Operacao ?? "--"}, NSU: {Item.NSU ?? "--"}
                                    </Component.DescriptionContent>}
                                    {Item.DetalharMeioDePagamento != true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.DescriptionContent title="Clique aqui para editar estas informações" onClick={async () => {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = !(ListaDeParcelas[Index].DetalharMeioDePagamento ?? false);
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }}>
                                        <strong>Sobre o {Item.MeioDePagamento}:</strong> Nº da operação: {Item.NumeroDaOperacao ?? "--"}
                                    </Component.DescriptionContent>}

                                </div>
                            </div>
                            {/* {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 && <div style = {{display: 'flex', marginBottom: '10px'}}>
                                <div style={{ width: "20px", marginRight: "10px", paddingTop: "5px" }}>
                                    
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <h5>C/Juros</h5>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(Item.ValorPMT)} leftIcon="dollar"/>
                                </div>
                                <div style={{ width: "10vw", marginRight: "10px" }}>
                                    <h5>C/Juros</h5>
                                    <InputGroup placeholder={Moeda.FormatarTexto(0)} disabled={true} value={Moeda.FormatarTexto(Item.ValorTotalJuros)} leftIcon="dollar" />
                                </div>
                                <div style={{ width: "10vw", marginRight: "30px" }}>
                                    
                                </div>
                                <div style={{ width: "20vw" }}>
                                    
                                </div>
                                <div style={{ width: "100px" }}>

                                </div>
                            </div>} */}
                            {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cheque" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Banco:" className="banco" labelFor="Banco" inline={true}>
                                    <PickerBanco popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerBanco} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Banco = _Item;
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }} text={_Item.chave + ": " + _Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.chave + ": " + _Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Banco ? ((Item.Banco).chave + ": " + (Item.Banco).Valor) : "Selecione o banco"} rightIcon="double-caret-vertical" />
                                    </PickerBanco>
                                </FormGroup>
                                <FormGroup label="Agência:" className="agência" labelFor="Agencia" inline={true} >
                                    <InputGroup id="Agencia" placeholder={"00000"} value={Item.Agencia}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Agencia = event.currentTarget.value ?? "";
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Component.FormContent>
                                    <FormGroup label="Conta:" className="conta" labelFor="Conta" inline={true} >
                                        <InputGroup id="Conta" placeholder={"00000"} value={Item.Conta}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                ListaDeParcelas[Index].Conta = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeParcelas(ListaDeParcelas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label="- " labelFor="DigitoDaConta" inline={true} style={{ marginLeft: "20px" }} >
                                        <InputGroup id="DigitoDaConta" placeholder={"00"} value={Item.DigitoDaConta}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                ListaDeParcelas[Index].DigitoDaConta = (event.currentTarget.value ?? "");
                                                await setFormularioListaDeParcelas(ListaDeParcelas);
                                            }}>
                                        </InputGroup>
                                    </FormGroup>
                                </Component.FormContent>
                                <FormGroup label="Titular:" className="titular" labelFor="Titular" inline={true} >
                                    <InputGroup id="Titular" placeholder={"Informe o nominal"} value={Item.Titular}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Titular = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Nº do cheque:" labelFor="NumeroCheque" inline={true} >
                                    <InputGroup id="NumeroCheque" placeholder={"Informe o número"} value={Item.NumeroCheque}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].NumeroCheque = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                    let ParcelasVazios = []
                                    ParcelasVazios = ListaDeParcelas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == ""))
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeParcelas.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Banco ?? "") == "" && (Item__.Agencia ?? "") == "" && (Item__.Conta ?? "") == "" && (Item__.DigitoDaConta ?? "") == "" && (Item__.Titular ?? "") == "" && (Item__.NumeroCheque ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (ParcelasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ParcelasVazios = [...getFormularioListaDeParcelas];
                                                            ParcelasVazios.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Banco ?? "") == "" && (Item_.Agencia ?? "") == "" && (Item_.Conta ?? "") == "" && (Item_.DigitoDaConta ?? "") == "" && (Item_.Titular ?? "") == "" && (Item_.NumeroCheque ?? "") == "") {
                                                                    Item_.Banco = Item.Banco
                                                                    Item_.Agencia = Item.Agencia
                                                                    Item_.Conta = Item.Conta
                                                                    Item_.DigitoDaConta = Item.DigitoDaConta
                                                                    Item_.Titular = Item.Titular
                                                                    Item_.NumeroCheque = Item.NumeroCheque
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeParcelas(ListaDeParcelas);
                                                                }
                                                            })
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        })
                                    }
                                    else {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                            {Item.DetalharMeioDePagamento == true && Item.MeioDePagamento == "Cartao" && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Máquina:" className="máquina" labelFor="Maquina" inline={true} >
                                    <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={MaquinaDeCartao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Maquina = _Item.Valor;
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Maquina ?? "Selecione a maquina"} rightIcon="double-caret-vertical" />
                                    </PickerMaquina>
                                </FormGroup>
                                <FormGroup label="Dígito do cartão:" labelFor="DigitoCartao" inline={true} >
                                    <InputGroup id="DigitoCartao" placeholder={"Informe os últimos 04 dígitos do cartão"} value={Item.DigitoCartao}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].DigitoCartao = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Bandeira:" className="bandeira" labelFor="Bandeira" inline={true} >
                                    <PickerMaquina popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={Bandeira} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { chave: string, Valor: string }) => <MenuItem onClick={async () => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Bandeira = _Item.Valor;
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }} text={_Item.Valor} />} itemPredicate={(Texto: string, _Item: { chave: string, Valor: string }) => { return ((_Item.Valor) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={Item.Bandeira ?? "Selecione a bandeira"} rightIcon="double-caret-vertical" />
                                    </PickerMaquina>
                                </FormGroup>
                                <FormGroup label="Terminal:" className="terminal" labelFor="Terminal" inline={true} >
                                    <PickerTerminal popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={GetTerminais} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(_Item: { id: number, descricao: string }) => <MenuItem onClick={async () => {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].Terminal = _Item.id;
                                            setFormularioListaDeEntradas(ListaDeEntradas);
                                        }} text={_Item.descricao} />} itemPredicate={(Texto: string, _Item: { id: number, descricao: string }) => { return ((_Item.descricao) ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button text={GetTerminais.find((_Item) => _Item.id == Item.Terminal)?.descricao ?? "Selecione o terminal"} rightIcon="double-caret-vertical" />
                                    </PickerTerminal>
                                </FormGroup>
                                <FormGroup label="Cód. autorização:" labelFor="CodAutorizacao" inline={true} >
                                    <InputGroup id="CodAutorizacao" placeholder={"Informe o código de autorização"} value={Item?.CodAutorizacao}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeEntradas = [...getFormularioListaDeEntradas];
                                            ListaDeEntradas[Index].CodAutorizacao = (event.currentTarget.value ?? "");
                                            setFormularioListaDeEntradas(ListaDeEntradas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup label="Operação:" className="operação" labelFor="Operacao" inline={true} >
                                    <HTMLSelect id="Operacao" name="Operacao" value={Item.Operacao == undefined ? "" : Item.Operacao}
                                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].Operacao = (event.currentTarget.value ?? "");
                                            if (Item.MeioDePagamento == "Cartao" && Item.Operacao == "Debito") {
                                                const EhDiaUtil = require('eh-dia-util');
                                                let Vencimento = new Date();
                                                Vencimento.setDate(Vencimento.getDate() + 1);
                                                while (EhDiaUtil(Vencimento) == false) {
                                                    Vencimento.setDate(Vencimento.getDate() + 1);
                                                }
                                                ListaDeParcelas[Index].Vencimento = Vencimento;
                                            }
                                            if (Item.MeioDePagamento == "Cartao" && Item.Operacao != "Debito") {
                                                ListaDeParcelas.map((Item_, Index_) => {
                                                    if (Index_ >= Index) {
                                                        let Vencimento = new Date(getFormularioDataDaProposta);
                                                        Vencimento.setDate(Vencimento.getDate() + (1 + Index_ - Index) * 30);
                                                        Item_.Vencimento = Vencimento;
                                                    }
                                                });
                                            }
                                            else {
                                                ListaDeParcelas[Index].Vencimento = ListaDeParcelas[Index].Vencimento;
                                            }
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                        <option selected value="">Selecione a operação</option>
                                        {Operacao.map((Item) =>
                                            <option selected value={Item.chave}>{Item.Valor}</option>
                                        )}
                                    </HTMLSelect>
                                </FormGroup>
                                <FormGroup label="NSU:" className="nsu" labelFor="NSU" inline={true} >
                                    <InputGroup id="NSU" placeholder={"Informe o NSU"} value={Item.NSU}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].NSU = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                    let ParcelasVazios = [];
                                    ParcelasVazios = ListaDeParcelas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == ""));
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeParcelas.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.Maquina ?? "") == "" && (Item__.DigitoCartao ?? "") == "" && (Item__.Bandeira ?? "") == "" && (Item__.Operacao ?? "") == "" && (Item__.NSU ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (ParcelasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.Maquina ?? "") == "" && (Item_.DigitoCartao ?? "") == "" && (Item_.Bandeira ?? "") == "" && (Item_.Operacao ?? "") == "" && (Item_.NSU ?? "") == "") {
                                                                    Item_.Maquina = Item.Maquina
                                                                    Item_.DigitoCartao = Item.DigitoCartao
                                                                    Item_.Bandeira = Item.Bandeira
                                                                    Item_.Operacao = Item.Operacao
                                                                    Item_.NSU = Item.NSU
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeParcelas(ListaDeParcelas);
                                                                }
                                                            })
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        })
                                    }
                                    else {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                            {Item.DetalharMeioDePagamento == true && (Item.MeioDePagamento == "Deposito" || Item.MeioDePagamento == "Transferencia" || Item.MeioDePagamento == "PIX") && <Component.ContentFormHeader style={{ boxShadow: "4px 7px 10px rgba(0, 0, 0, 0.25)", marginBottom: "20px", marginLeft: "22vw", marginTop: "-10px", padding: "10px" }}>
                                <FormGroup label="Nº da operação:" labelFor="NumeroDaOperacao" inline={true} >
                                    <InputGroup id="NumeroDaOperacao" placeholder={"00000"} value={Item.NSU}
                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                            ListaDeParcelas[Index].NumeroDaOperacao = (event.currentTarget.value ?? "");
                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                        }}>
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" intent="primary" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "10px", width: "175px" }} onClick={async () => {
                                    let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                    let ParcelasVazios = [];
                                    ParcelasVazios = ListaDeParcelas.filter((Item_, Index_) => (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == ""));
                                    var Texto = `Existe mais de um sinal com o meio de pagamento ${(Item.MeioDePagamento).toLowerCase()}, deseja manter os mesmos dados de pagamentos?\n`;
                                    ListaDeParcelas.map((Item__, Index__) => {
                                        if ((Index__ > Index && Item__.MeioDePagamento == Item.MeioDePagamento && (Item__.NumeroDaOperacao ?? "") == "")) {
                                            Texto += `\n${Numero.FormatarTextoParaInteiro(Index__ + 1)}º sinal, Vencimento: ${moment(Item__.Vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}, Valor: ${Moeda.FormatarTexto(Item__.Valor)}\n`
                                        }
                                    })
                                    Texto += "\n";
                                    if (ParcelasVazios.length > 0) {
                                        Toast(
                                            <div style={{}}>
                                                <p style={{ color: '#FFFFFF', whiteSpace: 'pre-wrap' }}>{`${Texto}`}</p>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <Button type="submit" intent="primary" style={{ marginLeft: "0px", marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas.map(async (Item_, Index_) => {
                                                                if (Index_ > Index && Item_.MeioDePagamento == Item.MeioDePagamento && (Item_.NumeroDaOperacao ?? "") == "") {
                                                                    Item_.NumeroDaOperacao = Item.NumeroDaOperacao
                                                                    Item.DetalharMeioDePagamento = false
                                                                    await setFormularioListaDeParcelas(ListaDeParcelas);
                                                                }
                                                            })
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Sim</Button>
                                                    <Button type="submit" intent="primary" style={{ marginRight: "5px", marginBottom: "5px", width: '100px' }}
                                                        onClick={async () => {
                                                            let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                                            ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                                            await setFormularioListaDeParcelas(ListaDeParcelas);
                                                        }}>Não</Button>
                                                </div>
                                            </div>, {
                                            position: Toast.POSITION.TOP_CENTER,
                                            autoClose: false,
                                            style: { color: '#FFFFFF', borderRadius: 5, background: '#166088', width: '400px' },
                                        });
                                    }
                                    else {
                                        let ListaDeParcelas = [...getFormularioListaDeParcelas];
                                        ListaDeParcelas[Index].DetalharMeioDePagamento = false;
                                        await setFormularioListaDeParcelas(ListaDeParcelas);
                                    }
                                }}>Recolher informações</Button>
                            </Component.ContentFormHeader>}
                        </div>
                    )}
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <FormGroup label="Total gerado:" labelFor="ParcelaValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                            <InputGroup id="ParcelaValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto(getFormularioListaDeParcelas.reduce((total, parcela) => (total + (parcela.Valor * parcela.Quantidade)), 0))} leftIcon="numerical" />
                        </FormGroup>}
                        {/* {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 && <FormGroup label="Total gerado:" labelFor="ParcelaValorTotalGerado" inline={true} style={{ marginRight: "10px" }}>
                            <InputGroup id="ParcelaValorTotalGerado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto(getFormularioListaDeParcelas.reduce((total, parcela) => (total + (parcela.ValorPMT * parcela.Quantidade)), 0))} leftIcon="numerical" />
                        </FormGroup>} */}
                        {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <FormGroup style={{ marginRight: "10px" }} label="Total esperado:" labelFor="ParcelaValorTotalEsperado" inline={true} >
                            <InputGroup id="ParcelaValorTotalEsperado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.principal ?? 0) *
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.qtdeDeTitulos ?? 0)
                                )} leftIcon="numerical" />
                        </FormGroup>}
                        {/* {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 && <FormGroup style={{ marginRight: "10px" }} label="Total esperado:" labelFor="ParcelaValorTotalEsperado" inline={true} >
                            <InputGroup id="ParcelaValorTotalEsperado" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true}
                                value={Moeda.FormatarTexto(
                                    (getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0)
                                )} leftIcon="numerical" />
                        </FormGroup>} */}
                        {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 10 && <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeParcelas.reduce((total, parcela) => (total + parcela.Valor * parcela.Quantidade), 0)) ? "A distribuir: " : "Excedido: "} labelFor="EntradaValorTotalADistribuir" inline={true}>
                            <InputGroup id="ParcelaValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) - getFormularioListaDeParcelas.reduce((total, parcela) => (total + parcela.Valor * parcela.Quantidade), 0))} leftIcon="numerical" />
                        </FormGroup>}
                        {/* {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 && <FormGroup style={{ marginRight: "10px" }} label={((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) >= getFormularioListaDeParcelas.reduce((total, parcela) => (total + parcela.Valor * parcela.Quantidade), 0)) ? "A distribuir: " : "Excedido: "} labelFor="EntradaValorTotalADistribuir" inline={true}>
                            <InputGroup id="ParcelaValorTotalADistribuir" placeholder={Numero.FormatarTextoParaInteiro(0)} disabled={true} value={Moeda.FormatarTexto((getListaCondicaoDaTabelaDeVenda.find((_Item) => _Item.classificacao.id == 4)?.condicoesDaTabelaDeVenda.find((_Item) => _Item.descricao == getItemPickerCondicaoDaTabelaDeVenda?.descricao)?.valorTotal ?? 0) - getFormularioListaDeParcelas.reduce((total, parcela) => (total + parcela.ValorPMT * parcela.Quantidade), 0))} leftIcon="numerical" />
                        </FormGroup>} */}
                    </div>
                </Component.ContentCard>}
                <Component.ContentCard>
                    <Component.Title>
                        <Icon icon="annotation" iconSize={25} />
                        <h3>Informações complementares</h3>
                    </Component.Title>
                    {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && <Fragment>
                        <FormGroup label={"Id Casal:"} labelFor="IdCasal" inline={true}>
                            <Component.FormContent>
                                <InputGroup style={{ width: "24vw", marginLeft: "81px" }} id="IdCasal" placeholder={`Informe o Id Casal presente no TSE`} value={getFormularioIdCasal}
                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => { await setFormularioIdCasal(event.currentTarget.value.replace(/\D/g, "")); }}>
                                </InputGroup>
                                {<Button type="submit" intent="none" style={{ marginLeft: "10px", width: "30px", height: "20px" }} onClick={async () => {
                                    try {
                                        await setBuscandoIdCasal(true);
                                        let Response = await Venda.ConsultIdCasal(ContextSignIn.getContext().token, getFormularioIdCasal);
                                        let Item = getListaPickerSala?.find((Item) => Item.descricao == Response.data.descricao);
                                        if (Math.floor(Response.status / 100) == 2) {
                                            if (Response.data) {
                                                await setItemPickerSala(Response.data);
                                            }
                                            if (Response.data.assessorTlmkt) { await setListaPickerAssessorTlmkt([Response.data.promotorTLMKT]) }
                                            if (Response.data.closer) { await setListaPickerCloser([Response.data.closer]); }
                                            if (Response.data.liner) { await setListaPickerLiner([Response.data.liner]); }
                                            if (Response.data.pep) { await setListaPickerPEP([Response.data.pep]); }
                                            if (Response.data.promotor) { await setListaPickerPromotor([Response.data.promotor]); }
                                            await setListaPickerSubGerenteDeSala(Item?.subGerenteDeSala);
                                            if (Response.data.gerenteDeSala) { await setListaPickerGerenteDeSala([Response.data.gerenteDeSala]); }

                                            await setItemPickerAssessorTlmkt(Response.data.promotorTLMKT);
                                            await setItemPickerCloser(Response.data.closer);
                                            await setItemPickerLiner(Response.data.liner);
                                            await setItemPickerPEP(Response.data.pep);
                                            await setItemPickerPromotor(Response.data.promotor);
                                            await setItemPickerGerenteDeSala(Response.data.gerenteDeSala);
                                        }
                                        else {
                                            await setBuscandoIdCasal(undefined);
                                        }
                                        await setBuscandoIdCasal(false);
                                    }
                                    catch {
                                        await setBuscandoIdCasal(undefined);
                                    }
                                }}>
                                    <Icon icon="search" iconSize={15} />
                                </Button>}
                                {getBuscandoIdCasal == true && <Icon icon="geosearch" style={{ marginLeft: "10px" }} />}
                            </Component.FormContent>
                        </FormGroup>
                        <Component.FormContent>
                            {getBuscandoIdCasal == false && <div style={{ display: "block", marginRight: "10px" }}>
                                <FormGroup label="Sala:" labelFor="Sala" inline={true} style={{ flexWrap: "wrap" }}>
                                    <PickerSala disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerSala ?? [] as Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }) => <MenuItem onClick={async () => {
                                            await setItemPickerSala(Item);
                                            await setListaPickerAssessorTlmkt(Item.assessorTlmkt);
                                            await setListaPickerCloser(Item.closer);
                                            await setListaPickerLiner(Item.liner);
                                            await setListaPickerPEP(Item.pep);
                                            await setListaPickerPromotor(Item.promotor);
                                            await setListaPickerSubGerenteDeSala(Item.subGerenteDeSala);
                                            await setListaPickerGerenteDeSala(Item.gerenteDeSala);
                                            await setItemPickerSubGerenteDeSala(Item.subGerenteDeSala[0]);
                                            await setItemPickerGerenteDeSala(Item.gerenteDeSala[0]);
                                        }} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cidade: string, closer: Array<{ descricao: string }>, liner: Array<{ descricao: string }>, pep: Array<{ descricao: string }>, subGerenteDeSala: Array<{ descricao: string }>, gerenteDeSala: Array<{ descricao: string }> }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "105px" }} text={getItemPickerSala?.descricao ?? "Selecione a sala"} rightIcon="double-caret-vertical" />
                                    </PickerSala>
                                    {getItemPickerSala && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerSala(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Captador/Promotor:" labelFor="Promotor" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerPromotor disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerPromotor ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => { await setItemPickerPromotor(Item); }} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "10px" }} text={getItemPickerPromotor?.descricao ?? "Selecione o captador/promotor"} rightIcon="double-caret-vertical" />
                                    </PickerPromotor>
                                    {getItemPickerPromotor && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerPromotor(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Assessor Tlmkt:" labelFor="AssessorTlmkt" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerAssessorTlmkt disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerAssessorTlmkt ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => await setItemPickerAssessorTlmkt(Item)} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "35px" }} text={getItemPickerAssessorTlmkt?.descricao ?? "Selecione o assessor tlmkt"} rightIcon="double-caret-vertical" />
                                    </PickerAssessorTlmkt>
                                    {getItemPickerAssessorTlmkt && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerAssessorTlmkt(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Liner:" labelFor="Liner" inline={true} style={{ flexWrap: "wrap" }}>
                                    <PickerLiner disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerLiner ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => { await setItemPickerLiner(Item); }} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "102px" }} text={getItemPickerLiner?.descricao ?? "Selecione o liner"} rightIcon="double-caret-vertical" />
                                    </PickerLiner>
                                    {getItemPickerLiner && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerLiner(undefined); }} />}
                                </FormGroup>
                            </div>}
                            {getBuscandoIdCasal == false && <div style={{ display: "block" }}>
                                <FormGroup label="Closer:" labelFor="Closer" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerCloser disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCloser ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => await setItemPickerCloser(Item)} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "82px" }} text={getItemPickerCloser?.descricao ?? "Selecione o closer"} rightIcon="double-caret-vertical" />
                                    </PickerCloser>
                                    {getItemPickerCloser && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerCloser(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="PEP:" labelFor="PEP" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerPEP disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerPEP ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => await setItemPickerPEP(Item)} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "97px" }} text={getItemPickerPEP?.descricao ?? "Selecione o PEP"} rightIcon="double-caret-vertical" />
                                    </PickerPEP>
                                    {getItemPickerPEP && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerPEP(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Sub gerente de sala:" labelFor="SubGerenteDeSala" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerSubGerenteDeSala popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerSubGerenteDeSala ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => await setItemPickerSubGerenteDeSala(Item)} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw" }} text={getItemPickerSubGerenteDeSala?.descricao ?? "Selecione o sub gerente"} rightIcon="double-caret-vertical" />
                                    </PickerSubGerenteDeSala>
                                    {getItemPickerSubGerenteDeSala && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerSubGerenteDeSala(undefined); }} />}
                                </FormGroup>
                                <FormGroup label="Gerente de sala:" labelFor="GerenteDeSala" inline={true} style={{ flexWrap: "wrap" }} >
                                    <PickerGerenteDeSala disabled={getBuscandoIdCasal == false} popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerGerenteDeSala ?? [] as Array<{ descricao: string, cpf: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer={(Item: { descricao: string, cpf: string }) => <MenuItem onClick={async () => await setItemPickerGerenteDeSala(Item)} text={Item.descricao} />}
                                        itemPredicate={(Texto: string, Item: { descricao: string, cpf: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button style={{ width: "24vw", marginLeft: "25px" }} text={getItemPickerGerenteDeSala?.descricao ?? "Selecione o gerente de sala"} rightIcon="double-caret-vertical" />
                                    </PickerGerenteDeSala>
                                    {getItemPickerGerenteDeSala && getBuscandoIdCasal != false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerGerenteDeSala(undefined); }} />}
                                </FormGroup>
                            </div>}
                            <FormGroup label="Finalidade de compra:" labelFor="FinalidadeDeCompra" inline={true} style={{ flexWrap: "wrap", marginRight: "10px" }} >
                                <PickerFinalidadeDeCompra popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerFinalidadeDeCompra ?? [] as Array<{ id: number, descricao: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    itemRenderer={(Item: { id: number, descricao: string }) => <MenuItem onClick={async () => await setItemPickerFinalidadeDeCompra(Item)} text={Item.descricao} />}
                                    itemPredicate={(Texto: string, Item: { id: number, descricao: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                    <Button style={{ width: "190px" }} text={getItemPickerFinalidadeDeCompra?.descricao ?? "Selecione a finalidade"} rightIcon="double-caret-vertical" />
                                </PickerFinalidadeDeCompra>
                                {getItemPickerFinalidadeDeCompra && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFinalidadeDeCompra(undefined); }} />}
                            </FormGroup>
                            <FormGroup label="Data da proposta:" labelFor="DataDaProposta" inline={true} style={{ display: "flex", justifyContent: "space-between", width: "230px" }}>
                                <DateInput inputProps={{ style: { width: "215px" }, onClick: () => setFormularioExibirDataDaProposta(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                    popoverProps={{ disabled: !getFormularioExibirDataDaProposta, onClosed: () => setFormularioExibirDataDaProposta(false) }}
                                    parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                    placeholder={"DD/MM/AAAA"} value={getFormularioDataDaProposta} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                    onChange={async (DataSelecionada: Date) => await setFormularioDataDaProposta(DataSelecionada)}>
                                </DateInput>
                            </FormGroup>
                        </Component.FormContent>
                    </Fragment>}
                    {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 4 && <Fragment>
                        <Component.FormContent>
                            <FormGroup label="Finalidade de compra:" labelFor="FinalidadeDeCompra" inline={true} style={{ flexWrap: "wrap", marginRight: "10px" }} >
                                <PickerFinalidadeDeCompra popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerFinalidadeDeCompra ?? [] as Array<{ id: number, descricao: string }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    itemRenderer={(Item: { id: number, descricao: string }) => <MenuItem onClick={async () => await setItemPickerFinalidadeDeCompra(Item)} text={Item.descricao} />}
                                    itemPredicate={(Texto: string, Item: { id: number, descricao: string }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                    <Button style={{ width: "190px" }} text={getItemPickerFinalidadeDeCompra?.descricao ?? "Selecione a finalidade"} rightIcon="double-caret-vertical" />
                                </PickerFinalidadeDeCompra>
                                {getItemPickerFinalidadeDeCompra && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerFinalidadeDeCompra(undefined); }} />}
                            </FormGroup>
                            <FormGroup label="Data da proposta:" labelFor="DataDaProposta" inline={true}>
                                <DateInput inputProps={{ style: { width: "215px" }, onClick: () => setFormularioExibirDataDaProposta(true) }} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                    popoverProps={{ disabled: !getFormularioExibirDataDaProposta, onClosed: () => setFormularioExibirDataDaProposta(false) }}
                                    parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                    placeholder={"DD/MM/AAAA"} value={getFormularioDataDaProposta} maxDate={new Date((new Date().getFullYear() + 10).toString() + "-01-01")}
                                    onChange={async (DataSelecionada: Date) => await setFormularioDataDaProposta(DataSelecionada)}>
                                </DateInput>
                            </FormGroup>
                        </Component.FormContent>
                    </Fragment>}
                </Component.ContentCard>
                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Ficha de negociação</h3>
                        </div>
                        <FileInput style={{ width: "50vw", minWidth: "300px", marginBottom: "10px", marginLeft: "-3px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoFichaDeNegociacao == undefined ? "Anexe a ficha de negociação" : getFormularioAnexoFichaDeNegociacao.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoFichaDeNegociacao(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoFichaDeNegociacao && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoFichaDeNegociacao(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                </Component.ContentCard>}
                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Ficha de atendimento</h3>
                        </div>
                        <FileInput style={{ width: "50vw", minWidth: "300px", marginBottom: "10px", marginLeft: "-3px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoFichaDeAtendimento == undefined ? "Anexe a ficha de negociação" : getFormularioAnexoFichaDeAtendimento.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoFichaDeAtendimento(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoFichaDeAtendimento && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoFichaDeAtendimento(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                </Component.ContentCard>}
                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && <Component.ContentCard>
                    <Component.Title style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon="id-number" iconSize={25} />
                            <h3 style={{ marginRight: "10px", marginTop: "10px" }}>Documentação complementar</h3>
                        </div>
                        <FileInput style={{ width: "50vw", minWidth: "300px", marginBottom: "10px", marginLeft: "-3px" }} inputProps={{ accept: "application/pdf" }} disabled={false} buttonText="Abrir" text={getFormularioAnexoDocumentacaoComplementar == undefined ? "Anexe a ficha de negociação" : getFormularioAnexoDocumentacaoComplementar.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.item(0)?.type.includes("pdf") == true) {
                                await setFormularioAnexoDocumentacaoComplementar(event.currentTarget.files?.item(0) || { name: "" } as File);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }} onClick={(e: any) => (e.target.value = null)} />
                        {getFormularioAnexoDocumentacaoComplementar && <Button type="submit" intent="none" style={{ marginLeft: "10px", width: "80px" }} onClick={async () => {
                            await setFormularioAnexoDocumentacaoComplementar(undefined)
                        }}>Limpar</Button>}
                    </Component.Title>
                </Component.ContentCard>}
                <Component.ContentCard>
                    <div>
                        <Dialog
                            isOpen={getExibirPermitirEnvioComAnexoPendente == true}
                            onClose={() => setExibirPermitirEnvioComAnexoPendente(false)}
                            title="Alerta">
                            <div className={Classes.DIALOG_BODY}>
                                <p>Nem todos os anexos foram informados, deseja prosseguir com a venda?</p>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button onClick={() => {
                                        setPermitirEnvioComAnexoPendente(false);
                                        setExibirPermitirEnvioComAnexoPendente(false);
                                    }}>Não</Button>
                                    <Button intent="primary" onClick={async () => {
                                        setPermitirEnvioComAnexoPendente(true);
                                        setExibirPermitirEnvioComAnexoPendente(false);
                                    }}>Sim</Button>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            isOpen={getExibirPermitirParcelasNaoSubsequentes == true}
                            onClose={() => setExibirPermitirParcelasNaoSubsequentes(false)}
                            title="Alerta">
                            <div className={Classes.DIALOG_BODY}>
                                <p>As datas das parcelas não estão subsequentes, deseja prosseguir com a venda?</p>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button onClick={() => {
                                        setPermitirParcelasNaoSubsequentes(false);
                                        setExibirPermitirParcelasNaoSubsequentes(false);
                                    }}>Não</Button>
                                    <Button intent="primary" onClick={() => {
                                        setPermitirParcelasNaoSubsequentes(true);
                                        setExibirPermitirParcelasNaoSubsequentes(false);
                                    }}>Sim</Button>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            isOpen={getExibirPermitirEmailInvalido == true}
                            onClose={() => setExibirPermitirEmailInvalido(false)}
                            title="Alerta">
                            <div className={Classes.DIALOG_BODY}>
                                <p>O email digitado para o comprador está em um formato invalido, deseja prosseguir com a venda?</p>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button onClick={() => {
                                        setPermitirEmailInvalido(false);
                                        setExibirPermitirEmailInvalido(false);
                                    }}>Não</Button>
                                    <Button intent="primary" onClick={() => {
                                        setPermitirEmailInvalido(true);
                                        setExibirPermitirEmailInvalido(false);
                                    }}>Sim</Button>
                                </div>
                            </div>
                        </Dialog>
                        <Button type="submit" disabled={getEnviandoPropostaDeVenda} intent="primary" style={{ marginLeft: "50px", width: "100px" }} onClick={() => EnviarPropostaDeVenda(undefined)}>Enviar</Button>
                        {getEnviandoPropostaDeVenda == true && <div style={{ width: 100, height: 100 }}>
                            <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                        </div>}
                        <Button type="submit" disabled={getObjetoPropostaDeVenda ? false : true} intent="success" style={{ marginLeft: "20px", width: "100px" }} onClick={ReimprimirDocumento}>Reimprimir</Button>
                        <Button type="submit" intent="danger" style={{ marginLeft: "20px", width: "100px", cursor: (getLimpandoDocumento == true ? "wait" : undefined) }} onClick={NovoDocumento}>Limpar tudo</Button>
                        {getLimpandoDocumento == true && <div style={{ width: 100, height: 100 }}>
                            <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                        </div>}
                    </div>
                </Component.ContentCard>
            </Card>
            <Card style={{ width: "88%", backgroundColor: "#ffffff", margin: "40px auto", borderRadius: "5px", boxShadow: "none", padding: "40px 3% 40px 3% " }}>
                <Component.Title>
                    <Icon icon="form" iconSize={25} />
                    <h3>Documento(s) gerado(s)</h3>
                </Component.Title>
                <Component.ContentFormHeader>
                    {getObjetoContrato ? getObjetoContrato?.map((Item) =>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                            <Button type="submit" intent="success" style={{ marginLeft: "50px", marginRight: "50px", width: "100px", height: "20px" }} onClick={() => {
                                ImprimirContrato(Item.descricao, Item.extensao, Item.arquivo);
                            }}>Imprimir</Button>
                            <label style={{ height: "20px" }}>{Item.descricao + "." + Item.extensao}</label>
                        </div>
                    ) : <label>Nenhum contrato ainda gerado, por favor, gere uma propsta</label>}
                    {getObjetoContrato && <div style={{ display: "flex", marginTop: "10px" }}>
                        <Button type="submit" intent="success" style={{ marginLeft: "50px", marginRight: "50px", width: "100px", height: "20px" }} onClick={async () => {
                            let Tentativas = 0;
                            NotificacaoInterna.ExibirNotificacao("Seu contrato está sendo baixado", `Aguarde enquanto os documentos são unificados e baixados`, NotificacaoInterna.TipoDeNotificacao.Informacao);
                            while (Tentativas < 5) {
                                {
                                    let Anexos = [] as Array<{ Nome: string, Conteudo: File | null | undefined }>;
                                    if (getFormularioAnexoCheckListDeCadastro != undefined && getFormularioAnexoCheckListDeCadastro != null) {
                                        Anexos.push({ Nome: "01. Check list de cadastro.pdf", Conteudo: getFormularioAnexoCheckListDeCadastro });
                                    }
                                    if (getFormularioAnexoCliente != undefined && getFormularioAnexoCliente != null) {
                                        Anexos.push({ Nome: "08. Doc cliente 01 comprador.pdf", Conteudo: getFormularioAnexoCliente });
                                    }
                                    if (getFormularioAnexoConjuge != undefined && getFormularioAnexoConjuge != null) {
                                        Anexos.push({ Nome: "09. Doc conjuge 01 comprador.pdf", Conteudo: getFormularioAnexoConjuge });
                                    }
                                    if (getFormularioAnexoEndereco != undefined && getFormularioAnexoEndereco != null) {
                                        Anexos.push({ Nome: "10. Comp endereco 01 comprador.pdf", Conteudo: getFormularioAnexoEndereco });
                                    }
                                    if (getFormulario02CompradorAnexoCliente != undefined && getFormulario02CompradorAnexoCliente != null) {
                                        Anexos.push({ Nome: "11. Doc cliente 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoCliente });
                                    }
                                    if (getFormulario02CompradorAnexoConjuge != undefined && getFormulario02CompradorAnexoConjuge != null) {
                                        Anexos.push({ Nome: "12. Doc conjuge 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoConjuge });
                                    }
                                    if (getFormulario02CompradorAnexoEndereco != undefined && getFormulario02CompradorAnexoEndereco != null) {
                                        Anexos.push({ Nome: "13. Comp endereco 02 comprador.pdf", Conteudo: getFormulario02CompradorAnexoEndereco });
                                    }
                                    if (getFormularioAnexoComprovantePgtoEntrada != undefined && getFormularioAnexoComprovantePgtoEntrada != null) {
                                        Anexos.push({ Nome: "14. Comp de pagamento da entrada.pdf", Conteudo: getFormularioAnexoComprovantePgtoEntrada });
                                    }
                                    if (getFormularioAnexoFichaDeNegociacao != undefined && getFormularioAnexoFichaDeNegociacao != null) {
                                        Anexos.push({ Nome: "15. Ficha de negociação.pdf", Conteudo: getFormularioAnexoFichaDeNegociacao });
                                    }
                                    if (getFormularioAnexoFichaDeAtendimento != undefined && getFormularioAnexoFichaDeAtendimento != null) {
                                        Anexos.push({ Nome: "19. Ficha de atendimento.pdf", Conteudo: getFormularioAnexoFichaDeAtendimento });
                                    }
                                    if (getFormularioAnexoDocumentacaoComplementar != undefined && getFormularioAnexoDocumentacaoComplementar != null) {
                                        Anexos.push({ Nome: "20. Documentação complementar.pdf", Conteudo: getFormularioAnexoDocumentacaoComplementar });
                                    }
                                    let Response = await Venda.NovosDocumentos(ContextSignIn.getContext().token, getObjetoPropostaDeVenda?.empresa.id ?? 0, getObjetoPropostaDeVenda?.centroDeCusto.sigla ?? "", getObjetoPropostaDeVenda?.numero ?? 0, Anexos);
                                    if (Math.floor(Response.status / 100) == 2) {

                                    }
                                    else {
                                        NotificacaoInterna.ExibirNotificacao("Falha ao enviar anexos do cliente", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao enviar o contrato para assinatura, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        await setEnviandoContratoParaAssinatura(false);
                                        return;
                                    }
                                }

                                try {
                                    let Response = await Venda.ConsultarECompactarContrato(ContextSignIn.getContext().token, getObjetoPropostaDeVenda?.empresa.id ?? 0, getObjetoPropostaDeVenda?.centroDeCusto.sigla ?? "", getObjetoPropostaDeVenda?.numero ?? 0, false);
                                    let downloadLink = document.createElement("a");
                                    downloadLink.href = "data:application/octet-stream;charset=utf-16le;base64," + encodeURI(Response.data.arquivo);
                                    downloadLink.download = `${((getObjetoPropostaDeVenda?.empresa.id == 20) ? "PRE" : ((getObjetoPropostaDeVenda?.empresa.id == 21) ? "SPR" : ((getObjetoPropostaDeVenda?.empresa.id == 22) ? "SER" : ((getObjetoPropostaDeVenda?.empresa.id == 23) ? "PAR" : (getObjetoPropostaDeVenda?.empresa.id == 26 ? "PYR" : getObjetoPropostaDeVenda?.centroDeCusto.sigla)))))}_${getObjetoPropostaDeVenda?.identificador.subLocal.descricao}${getObjetoPropostaDeVenda?.prospects[0].nome}.${Response.data.extensao}`;
                                    downloadLink.click();
                                    NotificacaoInterna.ExibirNotificacao("Seu contrato foi baixado com sucesso", `🖨️ Acesse a sua pasta de downloads e procure pelo o arquivo ˜${getObjetoPropostaDeVenda?.centroDeCusto.sigla}_${getObjetoPropostaDeVenda?.identificador.subLocal.descricao}_${getObjetoPropostaDeVenda?.prospects[0].nome}.${Response.data.extensao}`, NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    Tentativas = 6;
                                }
                                catch (Ex) {
                                    Tentativas++;
                                }
                            }
                            if (Tentativas == 5) {
                                NotificacaoInterna.ExibirNotificacao("Erro ao baixar o(s) documento(s)", `Entre em contato com o suporte`, NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }}>Baixar</Button>
                        <label style={{ height: "20px" }}>Baixar todos os documentos</label>
                    </div>
                    }
                </Component.ContentFormHeader>
            </Card>
            {getObjetoContrato && <Card>
                <Component.Content>
                    <Button type="submit" disabled={getEnviandoContratoParaAssinatura} intent="primary" style={{ marginLeft: "50px", width: "200px" }} onClick={EncaminharDocumentoParaAssinatura}>Submeter a assinatura</Button>
                    {getEnviandoContratoParaAssinatura == true && <div style={{ width: 100, height: 100 }}>
                        <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                    </div>}
                </Component.Content>
            </Card>}
        </Component.Container >
    );
    /* #endregion */
};

const App: React.FC<viewModel> = (viewModel) => {
    const queryClient = new QueryClient();

    return (viewModel._AtivarQuery == false || viewModel._AtivarQuery == undefined) ? (
        <QueryClientProvider client={queryClient}>
            <ViewQuery {...viewModel} />
        </QueryClientProvider>
    ) : (
        <View {...viewModel} />
    );
}

export default App;