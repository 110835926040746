import axios from 'axios'

const api = axios.create({
  baseURL: (window.location.href.includes("test") || window.location.href.includes("casaeterra")) ? "http://testapi.oneplus.dev.br" : "http://localhost:3003"
})

const apiPost = axios.create({
  baseURL:"http://testapi.oneplus.dev.br"
})

export {
  api,
  apiPost
};