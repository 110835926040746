import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from "axios";
import moment from 'moment';

import { lighten, math, modularScale } from 'polished';
import Lottie from 'react-lottie';
import { Button, Card, FormGroup, MenuItem, FileInput, Menu, Icon, Collapse, Divider, Popover, Position, Dialog, AnchorButton, Classes, Code, H5, Intent, InputGroup, HTMLSelect, Radio, Drawer, Switch, TextArea } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select, MultiSelect } from "@blueprintjs/select";
import { extend } from '@syncfusion/ej2-base';
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Group, Inject, GroupSettingsModel, Aggregate, AggregateDirective, AggregatesDirective, AggregateColumnsDirective, AggregateColumnDirective, Toolbar, ToolbarItems, PdfExport, Grid, Filter, FilterSettingsModel, Reorder, Column, Sort, SortSettingsModel, PdfHeaderQueryCellInfoEventArgs, ColumnMenu, } from "@syncfusion/ej2-react-grids";
import { ToolbarSettingsModel, FileManager, FileManagerSettingsModel, QuickToolbarSettingsModel } from '@syncfusion/ej2-react-richtexteditor';
import CodeMirror from 'codemirror';
import { createElement } from '@syncfusion/ej2-base';
import { isMobile } from 'react-device-detect';

import { IconeHome } from '../../../../Assets';
import { SignIn as ContextSignIn } from '../../../../Context';
import { Localization, TiposComponentes } from '../../../../Data/Listas';
import { CentroDeCusto, FunilDeVendas, SalaDeVendas, Areas, Logon } from '../../../../Services/Controllers';
import { Objeto } from '../../../../Services/Models';
import { CPF, CEP, Moeda, Numero, Telefone, CNPJ, Data } from '../../../../Themes/TextFormat';
import { NotificacaoInterna } from '../../../../Services/Notification';
import * as LottieFiles from '../../../../Data/Lottie';
import { Banco, Bandeira, MaquinaDeCartao, MeioDePagamento, Operacao, UF } from '../../../../Data/Listas';

import './App.css';
import { image } from './image';
import * as Component from './style';
import viewModel from './viewModel';

import ProfileLogo from '../../../../Assets/Images/Icone/profile.svg';

loadCldr(
    require('../../../../../node_modules/cldr-data/main/pt/currencies.json'),
    require('../../../../../node_modules/cldr-data/main/pt/numbers.json'),
    require('../../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
    require('../../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
    require('../../../../../node_modules/cldr-data/supplemental/numberingSystems.json'));
setCulture('pt');
setCurrencyCode("BRL");
L10n.load({
    'pt': {
        'kanban': {
            'items': 'itens',
            'min': 'Min',
            'max': 'Max',
            'cardsSelected': 'Cards selecionados',
            'addTitle': 'adicionar titulo',
            'editTitle': 'editar titulo',
            'deleteTitle': 'detelar titulo',
            'deleteContent': 'deletar conteudo',
            'save': 'salvar',
            'delete': 'deletar',
            'cancel': 'cancelar',
            'yes': 'Sim',
            'no': 'Não',
            'close': 'Fechar',
            'noCard': 'sem card',
            'unassigned': 'sem assinatura'
        }
    }
});

var fonteDeDados: any; 

let info: Object[];

interface KanbanDataModel {
    Id?: string;
    Title?: string;
    Status?: string;
    Summary?: string;
    Type?: string;
    Priority?: string;
    Tags?: string;
    Estimate?: number;
    Assignee?: string;
    RankId?: number;
    Color?: string;
}

interface IDialogExampleState {
    autoFocus: boolean;
    canEscapeKeyClose: boolean;
    canOutsideClickClose: boolean;
    enforceFocus: boolean;
    isOpen: boolean;
    usePortal: boolean;
}

const View: React.FC<viewModel> = (viewModel) => {

    /* #region  Variáveis  */
    const PropriedadesDeCalendario = {
        months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    };
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();

    const [getCarregandoListaDeContratos, setCarregandoListaDeContratos] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getCollapse, setCollapse] = useState(false);

    const [getListaDeSalas, setListaDeSalas] = useState<Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>, salas: Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>}>>([]);

    const [getListaDeGatilhos, setListaDeGatilhos] = useState<Array<{
        id: number, 
        empresa: number, 
        centroDeCusto: string, 
        descricao: string, 
        nomeResponsavel: string, 
        responsavel: {id: number, nome: string, email: {classificacao: number, descricao: string}} | undefined, 
        nomeDoFunil: string, 
        faseDoFunil: string, 
        funil: { id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>} | undefined,
        posicaoDoFunil: {id: number, descricao: string} | undefined,
        salaDeVenda: {id: number, descricao: string, areas: Array<{id: number, descricao: string}>} | undefined, 
        area: {id: number, descricao: string} | undefined, 
        tarefa: string, 
        listaDeFunis: Array<{ id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>}>}>>([]);

    const [getListaFunilDeVendas, setListaFunilDeVendas] = useState<Array<{ id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>}>>([]);
    const [getListaDeFasesDoFunil, setListaDeFasesDoFunil] = useState<Array<{id: number, descricao: string}>>([]);
    const [getListaPickerResponsavel, setListaPickerResponsavel] = useState<Array<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>>([]);
    const [getListaPickerSalasDeVendas, setListaPickerSalasDeVendas] = useState<Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>>([]);
    const [getListaPickerAreas, setListaPickerAreas] = useState<Array<{id: number, descricao: string}>>([]);

    const PickerFunilDeVendas = Select.ofType<{id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>}>();
    const PickerFasesDoFunil = Select.ofType<{id: number, descricao: string}>();
    const PickerResponsavel = Select.ofType<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>();
    const PickerAreas = Select.ofType<{id: number, descricao: string}>();
    const PickerSalaDeVendas = Select.ofType<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>();

    const [getIDGatilho, setIDGatilho] = useState<number>(0);
    const [getTipoDeGatilhoEscolhido, setTipoDeGatilhoEscolhido] = useState<string>("Conversão especifíca");
    const [getNomeDaCampanha, setNomeDaCampanha] = useState<string>("");
    const [getNomeDaCampanhaAntigo, setNomeDaCampanhaAntigo] = useState<string>("");
    const [getItemPickerFunilDeVendas, setItemPickerFunilDeVendas] = useState<{ id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>}>();
    const [getItemPickerFasesDoFunil, setItemPickerFasesDoFunil] = useState<{id: number, descricao: string}>();
    const [getItemPickerResponsavel, setItemPickerResponsavel] = useState<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>();
    const [getItemPickerSalaDeVendas, setItemPickerSalaDeVendas] = useState<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>();
    const [getItemPickerAreas, setItemPickerAreas] = useState<{id: number, descricao: string}>();
    const [getDescricaoDaTarefa, setDescricaoDaTarefa] = useState<string>("");

    const [getGatilhoEscolhido, setGatilhoEscolhido] = useState<{
        id: number, 
        empresa: number, 
        centroDeCusto: string, 
        descricao: string, 
        nomeResponsavel: string, 
        responsavel: {id: number, nome: string, email: {classificacao: number, descricao: string}, ocupacao: {id: number, cargo: string, nome: string}} | undefined, 
        nomeDoFunil: string, 
        faseDoFunil: string, 
        funil: { id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>} | undefined,
        posicaoDoFunil: {id: number, descricao: string} | undefined,
        salaDeVenda: {id: number, descricao: string, areas: Array<{id: number, descricao: string}>} | undefined, 
        area: {id: number, descricao: string} | undefined, 
        tarefa: string, 
        listaDeFunis: Array<{ id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>}>}>();
    
    const [getDescriptionSala, setDescriptionSala] = useState<string>("");
    const [getIdSala, setIdSala] = useState<number>(0);

    const [getEmConsulta, setEmConsulta] = useState<boolean>(false);

    const History = useHistory();
    /* #endregion */

    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
    **/
    async function Buscar() {}

    let kanbanObj: any;

    var rteObj: any;

    var hostUrl: string = 'https://ej2-aspcore-service.azurewebsites.net/';

    var items: string[] = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', 'SuperScript', 'SubScript', '|',
      'CreateTable', 'CreateLink', 'Image', 'FileManager', '|', 'ClearFormat', 'Print',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
    ];
  
    var fileManagerSettings: FileManagerSettingsModel = {
      enable: true,
      path: '/Pictures/Food',
      ajaxSettings: {
        url: hostUrl + 'api/FileManager/FileOperations',
        getImageUrl: hostUrl + 'api/FileManager/GetImage',
        uploadUrl: hostUrl + 'api/FileManager/Upload',
        downloadUrl: hostUrl + 'api/FileManager/Download'
      }
    }

    var quickToolbarSettings: QuickToolbarSettingsModel = {
      table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles']
    }

    var toolbarSettings: ToolbarSettingsModel = {
      items: items
    };

    var textArea: any;
    var myCodeMirror: any;

    const mirrorConversion = (e?: any) => {
      textArea = rteObj.contentModule.getEditPanel() as HTMLTextAreaElement;
      let id: string = (rteObj as any).getID() + 'mirror-view';
      let mirrorView: HTMLElement = rteObj.element.querySelector('#' + id) as HTMLElement;
      let charCount: HTMLElement = rteObj.element.querySelector('.e-rte-character-count') as HTMLElement;
      if (e.targetItem === 'Preview') {
        textArea.style.display = 'block';
        mirrorView.style.display = 'none';
        textArea.innerHTML = myCodeMirror.getValue();
        charCount.style.display = 'block';
      } else {
        if (!mirrorView) {
          mirrorView = createElement('div', { className: 'e-content' });
          mirrorView.id = id;
          textArea.parentNode.appendChild(mirrorView);
        } else {
          mirrorView.innerHTML = '';
        }
        textArea.style.display = 'none';
        mirrorView.style.display = 'block';
        renderCodeMirror(mirrorView, (rteObj as any).value);
        charCount.style.display = 'none';
      }
    }

    const renderCodeMirror = (mirrorView: HTMLElement, content: string) => {
      myCodeMirror = CodeMirror(mirrorView, {
        value: content,
        lineNumbers: true,
        mode: 'text/html',
        lineWrapping: true,
  
      });
    }

    const [getDrawerAdicionarGatilho, setDrawerAdicionarGatilho] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDrawerAdicionarGatilho = () => setDrawerAdicionarGatilho({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerAdicionarGatilho = () => {
        setIDGatilho(0);
        setNomeDaCampanhaAntigo("");
        setNomeDaCampanha("");
        setItemPickerFunilDeVendas(undefined);
        setListaDeFasesDoFunil([]);
        setItemPickerFasesDoFunil(undefined);
        setItemPickerResponsavel(undefined);
        setListaPickerSalasDeVendas([]);
        setItemPickerSalaDeVendas(undefined);
        setItemPickerAreas(undefined);
        setDescricaoDaTarefa("");

        setDrawerAdicionarGatilho({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        })
    };

    const [getDialogDeletarGatilho, setDialogDeletarGatilho] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarGatilho = () => setDialogDeletarGatilho({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarGatilho = () => {
        
        setIDGatilho(0);
        setNomeDaCampanhaAntigo("");
        setNomeDaCampanha("");
        setItemPickerFunilDeVendas(undefined);
        setListaDeFasesDoFunil([]);
        setItemPickerFasesDoFunil(undefined);
        setItemPickerResponsavel(undefined);
        setListaPickerSalasDeVendas([]);
        setItemPickerSalaDeVendas(undefined);
        setItemPickerAreas(undefined);
        setDescricaoDaTarefa("");

        setDialogDeletarGatilho({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        })
    };

    async function SelecionarCentroDeCusto(Item: Objeto.CentroDeCusto | undefined) {
        
        let dataStorage: Array<{
            id: number, 
            empresa: number, 
            centroDeCusto: string, 
            descricao: string, 
            nomeResponsavel: string, 
            responsavel: {id: number, nome: string, email: {classificacao: number, descricao: string}} | undefined, 
            nomeDoFunil: string, 
            faseDoFunil: string, 
            funil: { id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>} | undefined,
            posicaoDoFunil: {id: number, descricao: string} | undefined,
            salaDeVenda: {id: number, descricao: string, areas: Array<{id: number, descricao: string}>}, 
            area: {id: number, descricao: string}, tarefa: string, 
            listaDeFunis: Array<{ id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>}>}> = [];

        let ResponseGatilhos = (await axios.get(`http://apirds.oneplus.dev.br/SoftwareExterno/RDStation?Token=${ContextSignIn.getContext().token}&Empresa=${Item?.empresa.id ?? 0}&CentroDeCusto=${Item?.sigla ?? ""}`)).data ?? [];
        
        console.log(ResponseGatilhos)

        ResponseGatilhos.map((gatilho: any, index: number) => {
            dataStorage.push({
                id: (index + 1),
                empresa: gatilho.empresa.id,
                centroDeCusto: gatilho.centroDeCusto.sigla,
                descricao: gatilho.campanha,
                nomeResponsavel: gatilho.responsavel.nome,
                responsavel: {
                    id: gatilho.responsavel.id,
                    nome: gatilho.responsavel.nome,
                    email: {
                        classificacao: gatilho.responsavel.email.classificacao,
                        descricao: gatilho.responsavel.email.email
                    },
                },
                nomeDoFunil: gatilho.funil.descricao, 
                faseDoFunil: gatilho.posicaoDoFunil.descricao,
                funil: {
                    id: gatilho.funil.id,
                    posicoesDoFunil: gatilho.funil.posicoesDoFunil,
                    descricao: gatilho.funil.descricao,
                    hierarquiaDoFunil: gatilho.funil.hierarquiaDoFunil.map((item: any) => {
                        return {
                            salaDeVenda: {
                                id: item.salaDeVenda.id,
                                descricao: item.salaDeVenda.descricao
                            },
                            areas: [item.areas]
                        }
                    })
                },
                posicaoDoFunil: gatilho.posicaoDoFunil,
                salaDeVenda: {
                    id: gatilho.salaDeVenda.id,
                    descricao: gatilho.salaDeVenda.id,
                    areas: gatilho.funil.hierarquiaDoFunil.map((item: any) => {
                        if (item.salaDeVenda.id == gatilho.salaDeVenda.id)
                        {
                            return item.areas;
                        }
                    })
                },
                area: {
                    id: gatilho.area.id,
                    descricao: gatilho.area.descricao
                },
                tarefa: gatilho.descricaoDaTarefa,
                listaDeFunis: getListaFunilDeVendas,
            })
        })

        setListaDeGatilhos(dataStorage)
        setItemPickerCentroDeCusto(Item)
    }

    const DeletarGatilho = (Item: {id: number, descricao: string, nomeDoFunil: string, faseDoFunil: string, funil: { id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>} | undefined} | undefined) => {

        let ListaDeGatilhos = [...getListaDeGatilhos];

        let ListaFiltrada = ListaDeGatilhos.filter(gatilho => gatilho.id != Item?.id);

        setListaDeGatilhos(ListaFiltrada);
    }

    useEffect(() => {
        async function componentDidMount() {

            setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? []);

            var Response = await FunilDeVendas.Get(ContextSignIn.getContext().token)
    
            if (Math.floor(Response.status / 100) == 2)
            {
                setListaFunilDeVendas(Response.data);
            }
            else
            {
                return;
            }

            setListaPickerResponsavel((await Logon.Usuarios(ContextSignIn.getContext().token, "")).data ?? []);
        }
        componentDidMount();
    }, []);

    const toolbarOptions: ToolbarItems[] = ['PdfExport']
    
    const groupOptions: GroupSettingsModel = {
        columns: ['descricao'],
        showGroupedColumn: true,
        showUngroupButton: true,
    }

    const filterOptions: FilterSettingsModel = {
        ignoreAccent: true,
        immediateModeDelay: 200,
        mode: "Immediate",
        type: 'Excel'
    }

    const sortOptions: SortSettingsModel = {

    }

    const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs | any) => {
        (args.cell as any).row.pdfGrid.repeatHeader = true;
        args.cell.gridRow.pdfGrid.columns.getColumn(2).width = 90;
        args.cell.gridRow.pdfGrid.columns.getColumn(2).textAlign = "Right";
    }

    let grid: Grid | null;
    const toolbarClick = (args: any) => {
        if (grid) {
            if (args.item.id === 'grid_pdfexport') {
                (grid.columns[0] as Column).visible = false;
                (grid.columns[1] as Column).visible = false;
                grid.pdfExport({
                    fileName: `Borderô.pdf`,
                    pageOrientation: "Landscape",
                    header: {
                        fromTop: 0,
                        height: 130,
                        contents: [
                            {
                                type: 'Text',
                                value: `${getItemPickerCentroDeCusto?.descricao}`,
                                position: { x: 390, y: 50 },
                                style: { textBrushColor: "#000000", fontSize: 20, hAlign: "Right" },
                            },
                            {
                                position: { x: 0, y: 45 },
                                size: { height: 40, width: 80 },
                                src: image,
                                type: 'Image',
                            },
                            {
                                type: 'Text',
                                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:MM:SS")}`,
                                position: { x: 880, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 13 },
                            },
                        ]
                    },
                    footer: {
                        contents: [
                            {
                                type: 'Text',
                                value: `Powered by DigitalDEV`,
                                position: { x: 0, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 10 },
                            },
                            {
                                format: 'Página {$current} de {$total}',
                                pageNumberType: "Numeric",
                                position: { x: 910, y: 50 },
                                style: {
                                    fontSize: 13,
                                    hAlign: "Right",
                                    textBrushColor: '#000000',
                                },
                                type: 'PageNumber',
                            }
                        ],
                        fromBottom: 0,
                        height: 100,
                    },
                    theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
                        },
                        record: {
                            bold: false,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7)
                        },
                        caption: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
                        }
                    },
                    allowHorizontalOverflow: false,

                });
            }
        }
    }

    const pdfExportComplete = () => {
        if (grid) {
            (grid.columns[0] as Column).visible = true;
            (grid.columns[1] as Column).visible = true;
        }
    }

    return (
        <Component.Container style = {{backgroundColor: "#FFFFFF"}}>

            {/* Drawer adicionar campanha */}
            <Drawer
                style = {{ width: "50%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"people"}
                onClose={handleCloseDrawerAdicionarGatilho}
                title = {getIDGatilho == 0 ? "Adicionar campanha de envio de oportunidade" : "Alterar campanha de envio de oportunidade"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionarGatilho.isOpen}
                canEscapeKeyClose = {getDrawerAdicionarGatilho.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionarGatilho.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionarGatilho.enforceFocus}
                usePortal = {getDrawerAdicionarGatilho.usePortal}
            >
                
                <div style = {{display: "flex", width: "100%", height: "100%", overflowY: "scroll"}}>

                    <div
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY}>

                        <div style = {{marginLeft: "10px", color: '#000000', fontSize: "14px"}}>Escolha quando o lead de marketing se tornará uma oportunidade de vendas</div>
                            
                            <div style = {{display: 'flex', alignItems: 'center', marginLeft: "10px", marginTop: "20px", width: "45vw", justifyContent: 'space-between', flexWrap: 'wrap'}}>

                                {false && <div
                                    style = {{
                                        height: "200px", 
                                        width: "14vw", 
                                        background: getTipoDeGatilhoEscolhido == 'Marcação de oportunidade' ? "#1098f750"  : "#FFFFFF", 
                                        borderRadius: "5px", 
                                        border: getTipoDeGatilhoEscolhido == 'Marcação de oportunidade' ? "1px solid #1098F790" : "1px solid #00000040",
                                        cursor: "pointer",
                                }} onClick = {() => {setTipoDeGatilhoEscolhido('Marcação de oportunidade')}}>
                                    <img style = {{borderRadius: 60, marginTop: "20px", marginLeft: "10px"}} height = {80} width = {110} src = {ProfileLogo}/>
                                    <div style = {{fontWeight: 'bold', marginLeft: "30px", marginTop: "5px"}}>Marcação de oportunidade</div>
                                    <div style = {{marginLeft: '30px', fontSize: '11px'}}>No perfil do lead ou por automação</div>
                                </div>}

                                <div
                                    style = {{
                                        height: "200px", 
                                        width: "45vw", 
                                        background: getTipoDeGatilhoEscolhido == 'Conversão especifíca' ? "#1098f750"  : "#FFFFFF", 
                                        borderRadius: "5px", 
                                        border: getTipoDeGatilhoEscolhido == 'Conversão especifíca' ? "1px solid #1098F790" : "1px solid #00000040",
                                        cursor: "pointer",
                                }} onClick = {() => {setTipoDeGatilhoEscolhido('Conversão especifíca')}}>
                                    <div style = {{display: 'flex', alignItems: 'center', height: "200px", paddingBottom: "10px"}}>
                                        <img style = {{borderRadius: 60, marginTop: "20px", marginLeft: "10px"}} height = {160} width = {220} src = {ProfileLogo}/>
                                        <div style = {{marginTop: "15px"}}>
                                            <div style = {{fontWeight: 'bold', marginLeft: "30px", marginTop: "5px", fontSize: '18px'}}>Criação de campanha</div>
                                            <div style = {{marginLeft: '30px', fontSize: '16px'}}>De uma landing page ou pop up</div>
                                        </div>
                                    </div>
                                </div>

                                {false && <div
                                    style = {{
                                        height: "200px", 
                                        width: "14vw", 
                                        background: getTipoDeGatilhoEscolhido == 'Qualquer conversão' ? "#1098f750"  : "#FFFFFF", 
                                        borderRadius: "5px", 
                                        border: getTipoDeGatilhoEscolhido == 'Qualquer conversão' ? "1px solid #1098F790" : "1px solid #00000040",
                                        cursor: "pointer",
                                }} onClick = {() => {setTipoDeGatilhoEscolhido('Qualquer conversão')}}>
                                    <img style = {{borderRadius: 60, marginTop: "20px", marginLeft: "10px"}} height = {80} width = {110} src = {ProfileLogo}/>
                                    <div style = {{fontWeight: 'bold', marginLeft: "30px", marginTop: "5px"}}>Qualquer conversão</div>
                                    <div style = {{marginLeft: '30px', fontSize: '11px'}}>De qualquer landing page ou pop up</div>
                                </div>}

                            </div>
                            
                            {getTipoDeGatilhoEscolhido == 'Conversão especifíca' &&

                                <div style = {{borderRadius: "5px", boxShadow: "none"}}>
                                    <Component.ContentCard>
                                        <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                <div style = {{marginBottom: "5px"}}>{`Nome da campanha`}</div>
                                                <Component.Input style = {{width: "calc(45vw)", background: lighten(0.1, '#F6F7FF')}} id = {`Conversão`} placeholder="Digite o nome da campanha..." value={getNomeDaCampanha}
                                                    onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                        var TextoDigitado = event.currentTarget.value;
                                                        setNomeDaCampanha(TextoDigitado)
                                                    }}>
                                                </Component.Input>
                                            </div>
                                        </Component.ContentFormHeader>
                                    </Component.ContentCard>
                                    <div style = {{marginLeft: '10px', width: "calc(45vw)", height: "1px", background: '#00000040'}}></div>
                                </div>}

                                <div style = {{marginLeft: "10px", marginTop: "15px"}}>
                                    <div style = {{fontWeight: 'bold', fontSize: "12px", marginBottom: "5px"}}>Quem receberá as oportunidades?</div>
                                    <PickerResponsavel popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerResponsavel} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer = {(Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}) => <MenuItem onClick = { async () => { setItemPickerResponsavel(Item) }} text={Item.nome} />} itemPredicate={(Texto: string, Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button className="selector" style={{height: "40px", background: lighten(0.1, '#F6F7FF'), color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "calc(45vw)", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerResponsavel?.nome ?? "Selecione..."} rightIcon="double-caret-vertical" />
                                    </PickerResponsavel>
                                    <div style = {{padding: "0 10px", width: "calc(45vw)", height: "1px", background: '#00000040'}}></div>
                                </div>

                                <div style = {{borderRadius: "5px", boxShadow: "none", marginBottom: "20px"}}>
                                    <Component.ContentCard>
                                        <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                <div style = {{marginBottom: "5px"}}>{`Tarefa`}</div>
                                                <Component.Input style = {{width: "calc(45vw)", background: lighten(0.1, '#F6F7FF')}} id = {`Tarefa`} placeholder="Digite o nome da tarefa..." value={getDescricaoDaTarefa}
                                                    onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                        var TextoDigitado = event.currentTarget.value;
                                                        setDescricaoDaTarefa(TextoDigitado)
                                                    }}>
                                                </Component.Input>
                                            </div>
                                        </Component.ContentFormHeader>
                                    </Component.ContentCard>
                                    <div style = {{marginLeft: '10px', width: "calc(45vw)", height: "1px", background: '#00000040'}}></div>
                                </div>
                            
                                <div style = {{marginLeft: "10px", marginBottom: "20px"}}>
                                    <div style = {{fontWeight: 'bold', fontSize: "12px", marginBottom: "5px"}}>Criar nova oportunidade em:</div>
                                    <PickerFunilDeVendas popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaFunilDeVendas} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer = {(Item: { id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>}) => <MenuItem onClick = { async () => { 
                                            
                                            setItemPickerFunilDeVendas(Item)
                                            setListaDeFasesDoFunil(Item.posicoesDoFunil)

                                            let ListaPickerDeSalasDeVendas: Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}> = [];

                                            Item.hierarquiaDoFunil.map(sala => {
                                                
                                                ListaPickerDeSalasDeVendas.push({
                                                    id: sala.salaDeVenda.id,
                                                    descricao: sala.salaDeVenda.descricao,
                                                    areas: sala.areas
                                                })
                                            })

                                            setListaPickerSalasDeVendas(ListaPickerDeSalasDeVendas)

                                        }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: { id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>}) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button className="selector" style={{ height: "40px", background: lighten(0.1, '#F6F7FF'), color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "calc(45vw)", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerFunilDeVendas?.descricao ?? "Selecione..."} rightIcon="double-caret-vertical" />
                                    </PickerFunilDeVendas>
                                    <div style = {{padding: "0 10px", width: "calc(45vw)", height: "1px", background: '#00000040'}}></div>
                                </div>

                                <div style = {{marginLeft: "10px", marginBottom: "20px"}}>
                                    <div style = {{fontWeight: 'bold', fontSize: "12px", marginBottom: "5px"}}>Em qual etapa?</div>
                                    <PickerFasesDoFunil popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaDeFasesDoFunil} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer = {(Item: {id: number, descricao: string}) => <MenuItem onClick = { async () => { setItemPickerFasesDoFunil(Item) }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: {id: number, descricao: string}) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button className="selector" style={{height: "40px", background: lighten(0.1, '#F6F7FF'), color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "calc(45vw)", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerFasesDoFunil?.descricao ?? "Selecione..."} rightIcon="double-caret-vertical" />
                                    </PickerFasesDoFunil>
                                    <div style = {{padding: "0 10px", width: "calc(45vw)", height: "1px", background: '#00000040'}}></div>
                                </div>
                                
                                <div style = {{marginLeft: "10px", marginBottom: "20px"}}>
                                    <div style = {{fontWeight: 'bold', fontSize: "12px", marginBottom: "5px"}}>Criar a oportunidade na sala de vendas:</div>
                                    <PickerSalaDeVendas popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerSalasDeVendas} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer = {(Item: {id: number, descricao: string, areas: Array<{id: number, descricao: string}>}) => <MenuItem onClick = { async () => { 
                                            setItemPickerSalaDeVendas(Item)
                                            setListaPickerAreas(Item.areas)
                                        }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: {id: number, descricao: string, areas: Array<{id: number, descricao: string}>}) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button className="selector" style={{ height: "40px", background: lighten(0.1, '#F6F7FF'), color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "calc(45vw)", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerSalaDeVendas?.descricao ?? "Selecione..."} rightIcon="double-caret-vertical" />
                                    </PickerSalaDeVendas>
                                    <div style = {{padding: "0 10px", width: "calc(45vw)", height: "1px", background: '#00000040'}}></div>
                                </div>

                                <div style = {{marginLeft: "10px", marginBottom: "20px"}}>
                                    <div style = {{fontWeight: 'bold', fontSize: "12px", marginBottom: "5px"}}>Criar a oportunidade na area:</div>
                                    <PickerAreas popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerAreas} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                        itemRenderer = {(Item: { id: number, descricao: string}) => <MenuItem onClick = { async () => { setItemPickerAreas(Item) }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: { id: number, descricao: string}) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                        <Button className="selector" style={{ height: "40px", background: lighten(0.1, '#F6F7FF'), color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "calc(45vw)", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerAreas?.descricao ?? "Selecione..."} rightIcon="double-caret-vertical" />
                                    </PickerAreas>
                                    <div style = {{padding: "0 10px", width: "calc(45vw)", height: "1px", background: '#00000040'}}></div>
                                </div>

                        </div>
                        
                        <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                            <Button
                                icon = {<Icon icon = {getIDGatilho == 0 ? "add" : "refresh"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: getIDGatilho == 0 ? "#1098F7" : lighten(0.1, "#D74E09"), 
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "250px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: "30px"
                                }}
                                onClick = { async () => {

                                    let ObjetoCampanha: {
                                        empresa?: Objeto.Empresa, 
                                        centroDeCusto?: Objeto.CentroDeCusto | null, 
                                        campanha: string, 
                                        responsavel?: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}},
                                        funil?: {
                                            id: number, 
                                            posicoesDoFunil: Array<{ id: number, descricao: string }>, 
                                            descricao: string, 
                                            hierarquiaDoFunil: Array<{ salaDeVenda: {id: number, descricao: string }, areas: Array<{ id: number, descricao: string }>}>
                                        },
                                        posicaoDoFunil?: { id: number, descricao: string },
                                        salaDeVenda?: { id: number, descricao: string }, 
                                        area?: { id: number, descricao: string },
                                        tarefa: string
                                    };

                                    if (getNomeDaCampanha != "" && getIDGatilho == 0) {

                                        ObjetoCampanha = {
                                            empresa: getItemPickerCentroDeCusto?.empresa,
                                            centroDeCusto: getItemPickerCentroDeCusto, 
                                            campanha: getNomeDaCampanha, 
                                            responsavel: getItemPickerResponsavel,
                                            funil: getItemPickerFunilDeVendas,
                                            posicaoDoFunil: getItemPickerFasesDoFunil,
                                            salaDeVenda: getItemPickerSalaDeVendas, 
                                            area: getItemPickerAreas,
                                            tarefa: getDescricaoDaTarefa
                                        }

                                        NotificacaoInterna.ExibirNotificacao("Cadastrando nova campanha", "Aguarde enquanto cadastramos nova campanha", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let Response = await axios.create({
                                            baseURL: `http://apirds.oneplus.dev.br`,
                                        }).post(`/SoftwareExterno/RDStation?Token=${ContextSignIn.getContext().token}`, ObjetoCampanha)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaDeGatilhos = [...getListaDeGatilhos]

                                            ListaDeGatilhos.push({
                                                empresa: getItemPickerCentroDeCusto?.empresa.id ?? 0,
                                                centroDeCusto: getItemPickerCentroDeCusto?.sigla ?? "",
                                                id: getListaDeGatilhos.length + 1,
                                                nomeResponsavel: getItemPickerResponsavel ? getItemPickerResponsavel?.nome : '',
                                                responsavel: getItemPickerResponsavel,
                                                descricao: getNomeDaCampanha,
                                                nomeDoFunil: getItemPickerFunilDeVendas ? getItemPickerFunilDeVendas?.descricao : '',
                                                faseDoFunil: getItemPickerFasesDoFunil ? getItemPickerFasesDoFunil?.descricao : '',
                                                funil: getItemPickerFunilDeVendas,
                                                tarefa: getNomeDaCampanha,
                                                posicaoDoFunil: getItemPickerFasesDoFunil,
                                                salaDeVenda: getItemPickerSalaDeVendas, 
                                                area: getItemPickerAreas,
                                                listaDeFunis: getListaFunilDeVendas 
                                            })

                                            setIDGatilho(0);
                                            setNomeDaCampanhaAntigo("");
                                            setNomeDaCampanha("");
                                            setItemPickerFunilDeVendas(undefined);
                                            setListaDeFasesDoFunil([]);
                                            setItemPickerFasesDoFunil(undefined);
                                            setItemPickerResponsavel(undefined);
                                            setListaPickerSalasDeVendas([]);
                                            setItemPickerSalaDeVendas(undefined);
                                            setItemPickerAreas(undefined);
                                            setDescricaoDaTarefa("");
    
                                            setListaDeGatilhos(ListaDeGatilhos)

                                            NotificacaoInterna.ExibirNotificacao("Campanha cadastrada", "Sucesso ao cadastrar nova campanha", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                            handleCloseDrawerAdicionarGatilho()
                                        }
                                        else
                                        {

                                            NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar campanha", "Entre em contato com nossa equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);

                                            handleCloseDrawerAdicionarGatilho()
                                        }
                                       
                                    }
                                    else if (getNomeDaCampanha != "" && getIDGatilho > 0) {

                                        ObjetoCampanha = {
                                            empresa: getItemPickerCentroDeCusto?.empresa,
                                            centroDeCusto: getItemPickerCentroDeCusto, 
                                            campanha: getNomeDaCampanha, 
                                            responsavel: getItemPickerResponsavel,
                                            funil: getItemPickerFunilDeVendas,
                                            posicaoDoFunil: getItemPickerFasesDoFunil,
                                            salaDeVenda: getItemPickerSalaDeVendas, 
                                            area: getItemPickerAreas,
                                            tarefa: getDescricaoDaTarefa
                                        }

                                        NotificacaoInterna.ExibirNotificacao("Atualizando campanha", "Aguarde enquanto atualizamos a campanha", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let Response = await axios.create({
                                            baseURL: `http://apirds.oneplus.dev.br`,
                                        }).put(`/SoftwareExterno/RDStation?Token=${ContextSignIn.getContext().token}&Campanha=${getNomeDaCampanhaAntigo}`, ObjetoCampanha)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaDeGatilhos = [...getListaDeGatilhos];

                                            ListaDeGatilhos.map((item, index) => {
                                                if (item.id == getIDGatilho)
                                                {
                                                    item.empresa = getItemPickerCentroDeCusto?.empresa.id ?? 0
                                                    item.centroDeCusto = getItemPickerCentroDeCusto?.sigla ?? ""
                                                    item.id = getListaDeGatilhos.length + 1
                                                    item.nomeResponsavel = getItemPickerResponsavel ? getItemPickerResponsavel?.nome : ''
                                                    item.responsavel = getItemPickerResponsavel
                                                    item.descricao = getNomeDaCampanha
                                                    item.nomeDoFunil = getItemPickerFunilDeVendas ? getItemPickerFunilDeVendas?.descricao : ''
                                                    item.faseDoFunil = getItemPickerFasesDoFunil ? getItemPickerFasesDoFunil?.descricao : ''
                                                    item.funil = getItemPickerFunilDeVendas
                                                    item.tarefa = getNomeDaCampanha
                                                    item.posicaoDoFunil = getItemPickerFasesDoFunil
                                                    item.salaDeVenda = getItemPickerSalaDeVendas
                                                    item.area = getItemPickerAreas
                                                    item.listaDeFunis = getListaFunilDeVendas  
                                                }
                                            })

                                            setIDGatilho(0);
                                            setNomeDaCampanhaAntigo("");
                                            setNomeDaCampanha("");
                                            setItemPickerFunilDeVendas(undefined);
                                            setListaDeFasesDoFunil([]);
                                            setItemPickerFasesDoFunil(undefined);
                                            setItemPickerResponsavel(undefined);
                                            setListaPickerSalasDeVendas([]);
                                            setItemPickerSalaDeVendas(undefined);
                                            setItemPickerAreas(undefined);
                                            setDescricaoDaTarefa("");

                                            setListaDeGatilhos(ListaDeGatilhos);

                                            NotificacaoInterna.ExibirNotificacao("Campanha atualizada", "Sucesso ao atualizar a campanha", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                            handleCloseDrawerAdicionarGatilho()
                                        }
                                        else 
                                        {

                                            NotificacaoInterna.ExibirNotificacao("Erro ao atualizar campanha", "Entre em contato com nossa equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);

                                            handleCloseDrawerAdicionarGatilho()
                                        }
                                    }
                                    else
                                    {
                                        NotificacaoInterna.ExibirNotificacao("O nome da campanha não foi preenchido", "Prencha o nome da campanha.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    }

                            }}>{getIDGatilho == 0 ? "Adicionar campanha" : "Alterar campanha"}</Button>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/** Drawer deletar campanha */}
            <Dialog
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"delete"}
                onClose={handleCloseDialogDeletarGatilho}
                title = {"Deseja deletar a campanha?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarGatilho.isOpen}
                canEscapeKeyClose = {getDialogDeletarGatilho.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarGatilho.canOutsideClickClose}
                enforceFocus = {getDialogDeletarGatilho.enforceFocus}
                usePortal = {getDialogDeletarGatilho.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "70px", paddingRight: "30px", paddingLeft: "30px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "40px",
                        }}
                        onClick = { async () => {

                            let ObjetoCampanha: {
                                empresa?: Objeto.Empresa, 
                                centroDeCusto?: Objeto.CentroDeCusto | null, 
                                campanha: string, 
                                responsavel?: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}},
                                funil?: {
                                    id: number, 
                                    posicoesDoFunil: Array<{ id: number, descricao: string }>, 
                                    descricao: string, 
                                    hierarquiaDoFunil: Array<{ salaDeVenda: {id: number, descricao: string }, areas: Array<{ id: number, descricao: string }>}>
                                },
                                posicaoDoFunil?: { id: number, descricao: string },
                                salaDeVenda?: { id: number, descricao: string }, 
                                area?: { id: number, descricao: string },
                                tarefa: string
                            };

                            ObjetoCampanha = {
                                empresa: getItemPickerCentroDeCusto?.empresa,
                                centroDeCusto: getItemPickerCentroDeCusto, 
                                campanha: getNomeDaCampanha, 
                                responsavel: getItemPickerResponsavel,
                                funil: getItemPickerFunilDeVendas,
                                posicaoDoFunil: getItemPickerFasesDoFunil,
                                salaDeVenda: getItemPickerSalaDeVendas, 
                                area: getItemPickerAreas,
                                tarefa: getDescricaoDaTarefa
                            }

                            NotificacaoInterna.ExibirNotificacao("Deletando campanha", "Aguarde enquanto deletamos campanha", NotificacaoInterna.TipoDeNotificacao.Informacao);

                            let Response = await axios({
                                method: 'DELETE',
                                baseURL: `http://apirds.oneplus.dev.br/SoftwareExterno/RDStation?Token=${ContextSignIn.getContext().token}`,
                                data: ObjetoCampanha,
                                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            })

                            if (Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeGatilhos = [...getListaDeGatilhos];

                                let ListaFiltrada = ListaDeGatilhos.filter(gatilho => gatilho.id != getIDGatilho);

                                setIDGatilho(0);
                                setNomeDaCampanhaAntigo("");
                                setNomeDaCampanha("");
                                setItemPickerFunilDeVendas(undefined);
                                setListaDeFasesDoFunil([]);
                                setItemPickerFasesDoFunil(undefined);
                                setItemPickerResponsavel(undefined);
                                setListaPickerSalasDeVendas([]);
                                setItemPickerSalaDeVendas(undefined);
                                setItemPickerAreas(undefined);
                                setDescricaoDaTarefa("");

                                setListaDeGatilhos(ListaFiltrada);

                                NotificacaoInterna.ExibirNotificacao("Campanha deletada", "Sucesso ao deletar campanha", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                handleCloseDialogDeletarGatilho();
                            }
                            else
                            {

                                NotificacaoInterna.ExibirNotificacao("Erro ao deletar campanha", "Entre em contato com nossa equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                            }

                    }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "40px",
                        }}
                        intent = {"danger"}
                        onClick = { async () => { handleCloseDialogDeletarGatilho() }}>{"Não"}</Button>
                </div>

            </Dialog>

            {/* Collapse */}
            <Collapse isOpen={getCollapse}>
                <div className = "collapseMenu" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "0", width: "100%", backgroundColor: "#EDEFF5" }}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", background: "0", marginLeft: "2%" }}>
                        <div className="endereco">
                            <div style = {{display: 'flex', alignItems: 'center'}}>
                                <img src={IconeHome} style={{cursor: "pointer"}} alt="" onClick={() => {History.goBack();}} />
                                <div className="bar">/</div>
                                <div className="place">Geral</div>
                                <div className="bar">/</div>
                                <div className="place">Campanhas</div>
                                <div className="bar">/</div>
                            </div>
                        </div>
                    </div>
                    <div style = {{display: "flex", alignItems: "center", justifyContent: isMobile == true ? "center" : "space-between", background: "0", width: "90%"}}>
                        <div className = "pickerCentroDeCusto" style = {{display: 'flex', alignItems: 'center'}}>
                            <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer = {(Item: Objeto.CentroDeCusto) => <MenuItem onClick = { async () => { await SelecionarCentroDeCusto(Item); }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerCentroDeCusto?.descricao.substring(0, 10).concat("...") ?? "Empreendimento"} rightIcon="double-caret-vertical" />
                            </PickerCentroDeCusto>
                        </div>
                        {getItemPickerCentroDeCusto &&
                        <div style = {{display: 'flex', alignItems: 'center'}}>
                        <Divider className = "dividerbutton" style={{ width: "1px", height: "26px", backgroundColor: "#afafaf" }} />
                        <Button
                            className = "buttonAdcionarGatilho"
                            icon = {<Icon icon = "add" iconSize = {15} color = "#FFFFFF"/>}
                            style = {{
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                border: "none", 
                                boxShadow: "none", 
                                borderRadius: "5px",
                                outline: "none",
                                width: "180px", 
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px", 
                                height: "15px",
                                marginRight: "10px",
                            }} 
                            text = {"Adicionar campanha"}
                            onClick = {() => { handleOpenDrawerAdicionarGatilho() }}
                        />
                        </div>}
                    </div>
                </div>
            </Collapse>

            {/* Abridor Colapse */}
            <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>

            {false && <div style = {{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.Funil,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={500}
                    width={600}
                    style={{ marginTop: "100px" }}
                />
                <div className="loading" style={{ margin: "0 auto 0 auto", fontWeight: "bold"}}>{(getCarregandoListaDeContratos == undefined) ? (getItemPickerCentroDeCusto == undefined ? "Por favor, selecione um empreendimento" : ("Por favor, clique no botão de busca para exibir as configuracoes do funil")) : ((getCarregandoListaDeContratos == false) ? "Aguarde, estamos carregando suas configurações de funil" : "Pronto, suas configurações logo serão exibidas!")}</div>
            </div>}

            {true && <Card style = {{ backgroundColor: "0", width: "100%", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none" }}> 
                <div style = {{width: "100%", opacity: getEmConsulta == false ? 1 : 0.5, transition: "opacity 0.5s"}}>
                    <div style = {{display: 'flex', flexDirection: 'row', overflowX: 'scroll'}}>
                        <div style={{ width: "100%", overflowX: 'scroll' }}>
                            <GridComponent
                                dataSource={getListaDeGatilhos}
                                id = {'grid'}
                                ref = {g => grid = g} 
                                width = {"100%"} 
                                height = {"100%"}
                                allowPaging = {true}
                                allowFiltering = {true}
                                allowReordering = {true}
                                allowSorting = {false}
                                showColumnMenu = {true}
                                sortSettings = {sortOptions}
                                filterSettings = {filterOptions}
                                pageSettings = {{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale = {"pt"}
                                currencyCode = {"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='descricao' headerText='Nome da campanha' textAlign='Left'/>
                                    <ColumnDirective field='nomeResponsavel' headerText='Administrador' textAlign='Left'/>
                                    <ColumnDirective field='faseDoFunil' headerText='Fase do funil' textAlign='Left'/>
                                    <ColumnDirective field='nomeDoFunil' headerText='Funil' textAlign='Left'/>
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>   
                                        <Button
                                            icon = {<Icon icon = "refresh" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {

                                                setIDGatilho(props.id)
                                                setNomeDaCampanhaAntigo(props.descricao)
                                                setNomeDaCampanha(props.descricao)
                                                setItemPickerFunilDeVendas(props.listaDeFunis.filter((item: any) => item.id == props.funil.id).length > 0 ? props.listaDeFunis.filter((item: any) => item.id == props.funil.id)[0] : undefined);
                                                setListaDeFasesDoFunil(props.listaDeFunis.filter((item: any) => item.id == props.funil.id).length > 0 ? props.listaDeFunis.filter((item: any) => item.id == props.funil.id)[0].posicoesDoFunil : []);
                                                
                                                setItemPickerFasesDoFunil(props.posicaoDoFunil)
                                                setItemPickerResponsavel(props.responsavel)

                                                setListaPickerSalasDeVendas(props.listaDeFunis.filter((item: any) => item.id == props.funil.id).length > 0 ? props.listaDeFunis.filter((item: any) => item.id == props.funil.id)[0].hierarquiaDoFunil.map((sala: any) => {
                                                    return {
                                                        id: sala.salaDeVenda.id,
                                                        descricao: sala.salaDeVenda.descricao,
                                                        areas: sala.areas
                                                    }
                                                }) : []);

                                                setItemPickerSalaDeVendas(props.listaDeFunis.filter((item: any) => item.id == props.funil.id).length > 0 ? props.listaDeFunis.filter((item: any) => item.id == props.funil.id)[0].hierarquiaDoFunil.map((sala: any) => {
                                                    return {
                                                        id: sala.salaDeVenda.id,
                                                        descricao: sala.salaDeVenda.descricao,
                                                        areas: sala.areas
                                                    }
                                                }).filter((item: any) => item.id == props.salaDeVenda.id)[0] : undefined)

                                                setItemPickerAreas(props.area)
                                                setDescricaoDaTarefa(props.tarefa)

                                                handleOpenDrawerAdicionarGatilho() 
                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {

                                                setIDGatilho(props.id)
                                                setNomeDaCampanhaAntigo(props.descricao)
                                                setNomeDaCampanha(props.descricao)
                                                setItemPickerFunilDeVendas(props.listaDeFunis.filter((item: any) => item.id == props.funil.id).length > 0 ? props.listaDeFunis.filter((item: any) => item.id == props.funil.id)[0] : undefined);
                                                setListaDeFasesDoFunil(props.listaDeFunis.filter((item: any) => item.id == props.funil.id).length > 0 ? props.listaDeFunis.filter((item: any) => item.id == props.funil.id)[0].posicoesDoFunil : []);
                                                
                                                setItemPickerFasesDoFunil(props.posicaoDoFunil)
                                                setItemPickerResponsavel(props.responsavel)

                                                setListaPickerSalasDeVendas(props.listaDeFunis.filter((item: any) => item.id == props.funil.id).length > 0 ? props.listaDeFunis.filter((item: any) => item.id == props.funil.id)[0].hierarquiaDoFunil.map((sala: any) => {
                                                    return {
                                                        id: sala.salaDeVenda.id,
                                                        descricao: sala.salaDeVenda.descricao,
                                                        areas: sala.areas
                                                    }
                                                }) : []);

                                                setItemPickerSalaDeVendas(props.listaDeFunis.filter((item: any) => item.id == props.funil.id).length > 0 ? props.listaDeFunis.filter((item: any) => item.id == props.funil.id)[0].hierarquiaDoFunil.map((sala: any) => {
                                                    return {
                                                        id: sala.salaDeVenda.id,
                                                        descricao: sala.salaDeVenda.descricao,
                                                        areas: sala.areas
                                                    }
                                                }).filter((item: any) => item.id == props.salaDeVenda.id)[0] : undefined)

                                                setItemPickerAreas(props.area)
                                                setDescricaoDaTarefa(props.tarefa)

                                                handleOpenDialogDeletarGatilho();

                                                // NotificacaoInterna.ExibirNotificacao("Caro usuário", "A sala de vendas está sendo deletada.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                // let Response = await SalaDeVendas.Delete(ContextSignIn.getContext().token, {id: props.id, descricao: props.descricao})
                                                // if (Math.floor(Response.status / 100) == 2)
                                                // {
                                                //     let ListaDeSalas = [...props.salas]

                                                //     let ListaFiltrada = ListaDeSalas.filter(item => item.id != props.id)
                                                    
                                                //     let Filtrado = [...ListaFiltrada.map(Item => Item.salas = Item.salas.filter((IItem: any) => IItem.id != props.id))]

                                                //     await setListaDeSalas(Filtrado[0])
                                                //     NotificacaoInterna.ExibirNotificacao("A sala de vendas foi deletada", "Sala de vendas deletada com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                // }
                                                // else 
                                                // {
                                                //     NotificacaoInterna.ExibirNotificacao("Erro ao deletar a sala de vendas", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                // }
                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>

            </Card>}

        </Component.Container>
    );
    /* #endregion */
};

export default View;