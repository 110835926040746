import styled from 'styled-components';

export const MainContent = styled.div`
    width: 100%;
    height: 100%;
`;

export const MapContent = styled.div`
    height: 100%;
`;

export const SliderContent = styled.div`
    width: 70px;
    height: 70px;
    background: unset;
    position: absolute;
    z-index: 10000;
    margin-left: 90px;
    margin-top: 70px;
`;

export const ReactToPrintContent = styled.div`
    width: 40px;
    height: 40px;
    position: absolute;
    margin: 10px 0 0 50px;
    z-index: 20000;
`;

export const RefreshData = styled.div`
    width: 40px;
    height: 40px;
    position: absolute;
    margin: 10px 0 0 100px;
    z-index: 20000;
`;