import styled from 'styled-components';
//import StarJedi1 from '../../Assets/Font/Star_Jedi/starjedi/Starjedi.ttf';

export const Container = styled.div`
    align-items: stretch;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 120%;
    background-color: #00384a;
    h4 {
        color: #222e50;
    }
    a{ 

        color: #222e50;
        display: flex;
        font-size: 12px;
        align-items: center;
        place-content: center;
    }
    @font-face {
    font-family: "StarJedi1";
    }
`;

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    width: 300px;
    height: 100vh;
    overflow: auto;
    #Submit{
        margin-top: 24px;
        padding: 0px 16px;
        width: 286px;
        height: 40px;
        background-color: #2F855A;
        border: 0px;
        border-radius: 5px;
        font-family: 'Roboto',sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
    }
    #Submit:hover{
        background-color: #234E52;
        transition-duration: 0.2s;
    }
    #Nome{
        margin: 20px 0px 0px 0px;
        padding: 0px 12px;
        width: 286px;
        height: 32px;
        background-color: #065666 ;
        border: 0px;
        border-radius: 5px;
        color: #ffffff;
    }
    #Nome:hover{
        background-color: #086F83;
        transition-duration: 0.2s;
    }
    #Nome:focus{
        background-color: transparent;
        outline-color: #38A169;
        border: 0px none;
    }
    #CPF{
        margin: 20px 0px 0px 0px;
        padding: 0px 12px;
        width: 286px;
        height: 32px;
        background-color: #065666 ;
        border: 0px;
        border-radius: 5px;
        color: #ffffff;
    }
    #CPF:hover{
        background-color: #086F83;
        transition-duration: 0.2s;
    }
    #CPF:focus{
        background-color: transparent;
        outline-color: #38A169;
        border: 0px none;
    }
    #Email{
        margin: 20px 0px 0px 0px;
        padding: 0px 12px;
        width: 286px;
        height: 32px;
        background-color: #065666 ;
        border: 0px;
        border-radius: 5px;
        color: #ffffff;
    }
    #Email:hover{
        background-color: #086F83;
        transition-duration: 0.2s;
    }
    #Email:focus{
        background-color: transparent;
        outline-color: #38A169;
        border: 0px none;
    }
    #Senha{
        margin: 20px 0px 0px 0px;
        padding: 0px 12px;
        width: 286px;
        height: 32px;
        background-color: #065666 ;
        border: 0px;
        border-radius: 5px;
        color: #ffffff;
    }
    #Senha:hover{
        background-color: #086F83;
        transition-duration: 0.2s;
    }
    #Senha:focus{
        background-color: transparent;
        outline-color: #38A169;
        border: 0px none;
    }
    img {
        width: 200px;
    }
    a{
        margin-top: 20px;
        font-family: 'Roboto',sans-serif;
        font-size: 12px;
        color: #3e9e6a;
        text-decoration: none;
        justify-content: center;
    }
    h2{
        font-size: 20px;
        margin-top: -15px;
        margin-right: -100px;
        font-family: "StarJedi1";
        font-weight: 200;
        color: #ffffff
    }
    h6 {
        margin: 35px 0px 0px 0px;
        font-family: 'Roboto',sans-serif;
        font-weight: 400;
        font-size: 15.8px;
        text-justify: auto;
        color: #ffffff;
        text-align: center;
        width: 286px;
        height: 24px;
    }
    ul{
        background: #2F855A;
    }

    input::-webkit-input-placeholder{
    color: #ffffff;
    font-family: "Roboto" sans-serif;
    font-size: 14px;
    }
    input:-webkit-autofill {
        box-shadow: 0 0 0 30px #065666 inset; //-webkit-box-shadow: 0 0 0 30px #065666 inset;
    }   
    input:-webkit-autofill {
        -webkit-text-fill-color: #ffffff !important;
    }
    
`;
