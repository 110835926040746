import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Card, Elevation, H2, Icon } from '@blueprintjs/core';

import * as Component from './style';
import viewModel from './viewModel';

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const History = useHistory();
    /* #endregion */

    /* #region  Funções  */
    /** Função de atualização da lista de unidades */
    useEffect(() => {
        async function componentDidMount() {

        }
        componentDidMount();
    }, []);
    return (
        <Component.Container style={{ overflow: "auto" }}>
            <Component.Content style={{ background: "#fafafa" }}>
                <H2 style={{ marginTop: "15px" }}>
                    <Icon icon="application" />
                    Acompanhamento de sala
                    </H2>
            </Component.Content>
            <Card elevation={Elevation.ONE} interactive={true} style={{height: "calc(100% - 100px)", marginLeft: "-20px"}}>
            <iframe style={{position: "absolute", height: "calc(100% - 180px)", width: "100%"}}
                src="https://app.powerbi.com/view?r=eyJrIjoiYTIzM2M2MjItOWZiZS00YmUyLTlhZjAtODEwNzgzZGU5ZGNhIiwidCI6ImYzZDQ1YjVhLWY5YWItNDgxZi1iYzJlLTU5N2VhMWMyM2I3MyJ9"></iframe>
            </Card>
        </Component.Container >
    );
    /* #endregion */
};

export default View;