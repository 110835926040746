import React from 'react';
//import { WidthCheck as ContextWidthCheck } from '../../../../../../Context';

function App({ columns }: any) {

    return (
        <div style={{ color: "#555B53", display: "flex", borderBottom: "1px solid #C2C2C2", borderRight: "0px solid black", width: "100%", height: "40px" }}>
            {columns.map((column: { name: string, width: string }) => (<div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: column.width, height: "100%" }}><strong style={{ marginLeft: "10px" }}>{column.name}</strong></div>))}
            {/* <div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "calc(31%)", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Status</strong></div>
            <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "calc(22% - 0.5px)", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Área(m²)</strong></div>
            <div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "calc(15% + 0px)", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Qtd</strong></div>
            <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", alignItems: "center", width: "32%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Valor(R$)</strong></div> */}
        </div>
    );
}

export default App;