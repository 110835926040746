import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

class Prioridade {
    
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista de todas as prioridades
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
    **/
    public async Get(Token: string): Promise<AxiosResponse<Array<{id: number, descricao: string}>>> {
        return await Context.get(`/Prioridade/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
};

export default new Prioridade();