import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import * as Page from '../Pages';
import History from "../Routers";

const Routes: React.FC = () => {
    /* #region  Variáveis  */
    /* #endregion */

    return (<BrowserRouter>
        <div style={{ display: "block", padding: "0" }}>
            {History.location.pathname != "/" && History.location.pathname != "/Logon/SignUp" && !History.location.pathname.includes("PasswordReset") && <Page.HeaderTopBar />}
        </div>
        <div style={{ display: "flex", padding: "0", width: "100%", height: "calc(100% - 70px)" }}>
            {History.location.pathname != "/" && History.location.pathname != "/Logon/SignUp" && <Page.HeaderSideBar />}
            <Switch>
                <Route path="/" exact component={Page.LogonSignIn} />
                <Route path="/Configuracoes/CategoriasDeReserva" exact component={Page.ConfiguracoesCategoriasDeReserva} />
                <Route path="/Configuracoes/CNAEs" exact component={Page.ConfiguracoesCNAEs} />
                <Route path="/Configuracoes/ControleDeSala" exact component={Page.ConfiguracoesControleDeSala} />
                <Route path="/Configuracoes/Empresas" exact component={Page.ConfiguracoesEmpresas} />
                <Route path="/Configuracoes/FunilDeVendas" exact component={Page.ConfiguracoesFunilDeVendas} />
                <Route path="/Configuracoes/GestaoDeUsuarios" exact component={Page.ConfiguracoesGestaoDeUsuarios} />
                <Route path="/Configuracoes/NFSe" exact component={Page.ConfiguracoesNFSe} />
                <Route path="/Logon/PasswordReset/:Token" exact component={Page.LogonPasswordReset} />
                <Route path="/Logon/SignUp" exact component={Page.LogonSignUp} />
                <Route path="/Menu" exact component={Page.Menu} />
                <Route path="/Menu/Mapas" exact component={Page.ModulosMapas} />
                <Route path="/Menu/AcompanhamentoDeSala" exact component={Page.ModulosAcompanhamentoDeSala} />
                <Route path="/Menu/ControleDeSala" exact component={Page.ModulosControleDeSala} />
                <Route path="/Menu/Analytics" exact component={Page.ModulosAnalytics} />
                <Route path="/Menu/Analytics/Resumo" exact component={Page.ModulosAnalytics} />
                <Route path="/Menu/Analytics/Vendas" exact component={Page.ModulosAnalytics} />
                <Route path="/Menu/Analytics/Cessao" exact component={Page.ModulosAnalytics} />
                <Route path="/Menu/Analytics/Distratos" exact component={Page.ModulosAnalytics} />
                <Route path="/Menu/Analytics/Deposito" exact component={Page.ModulosAnalytics} />
                <Route path="/Menu/Analytics/VendaNoTempo" exact component={Page.ModulosAnalytics} />
                <Route path="/Menu/Analytics/Fluxo" exact component={Page.ModulosAnalytics} />
                <Route path="/Menu/Analytics/Planejamento" exact component={Page.ModulosAnalytics} />
                <Route path="/Menu/Bordero" exact component={Page.ModulosBordero} />
                <Route path="/Menu/ControleDeComissao" exact component={Page.ModulosControleDeComissao} />
                <Route path="/Menu/Disponibilidade" exact component={Page.ModulosDisponibilidade} />
                <Route path="/Menu/Geral/Cadastros" exact component={Page.GeralCadastros} />
                <Route path="/Menu/Geral/Gatilhos" exact component={Page.GeralGatilhos} />
                <Route path="/Menu/GeradorDeDocumentos" exact component={Page.ModulosGeradorDeDocumentos} />
                <Route path="/Menu/GestaoDeComissao" exact component={Page.ModulosGestaoDeComissao} />
                <Route path="/Menu/Leads" exact component={Page.ModulosLeads} />
                <Route path="/Menu/MeusContratos" exact component={Page.ModulosMeusContratos} />
                <Route path="/Menu/PropostasPendentes" exact component={Page.ModulosPropostasPendentes} />
                <Route path="/Menu/VendaDireta" exact component={Page.ModulosVendaDireta} />
            </Switch>
        </div>
    </BrowserRouter>);
};

export default Routes;