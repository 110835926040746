import React from 'react';
import { Icon } from '@blueprintjs/core';
import { NotificacaoInterna } from '../../../../../Services/Notification';
import { SignIn as ContextSignIn } from '../../../../../Context';
import api from '../../../../../Services/node';

function App({ setRefresh, setPolygons, polygons, quadraIndex, loteIndex, lote }: any) {

  return (
    <div onClick={async () => {
      lote.dadosUau.status === 0 ? NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao)
        : NotificacaoInterna.ExibirNotificacao("Disponibilizando unidade", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao)
      try {
        await api.post(`/reservaLote`, { lote: lote.dadosUau.dadosReservaLote, token: ContextSignIn.getContext().token }).then(async (res: any) => {
          console.log(res)
          if (res.data.processed) {
            let object: any = { ...polygons };
            object.lista[quadraIndex].lotes[loteIndex].dadosUau.status = lote.dadosUau.status === 0 ? 2 : 0;
            setPolygons(object);
            lote.dadosUau.status === 0 ? await NotificacaoInterna.ExibirNotificacao("Unidade disponibilizada com sucesso!", res.data.message, NotificacaoInterna.TipoDeNotificacao.Sucesso)
              : NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso!", res.data.message, NotificacaoInterna.TipoDeNotificacao.Sucesso)
          }
          else {
            NotificacaoInterna.ExibirNotificacao("Houve um problema!", "Contate a nossa equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro)
          }
        })
      }
      catch (e) {
      }
      finally {
        setRefresh(false);
        setRefresh(true);
      }
    }} style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", marginTop: "5px", background: lote.dadosUau.status === 0 ? "#3FA511" : "#D41F1F", color: "#ffffff", width: "100%", height: "30px", borderRadius: "5px" }}>{lote.dadosUau.status === 0 ? "Reservar" : "Disponibilizar"}</div>
  );
}

export default App;