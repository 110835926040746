import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';
import CamelCase from 'axios-case-converter';

class D4sign {
    /* #region  POST Controller  */
    /* Sobre esta função *//**
     *  Cadastra o contrato de compra e venda e o encaminha para as devidas asssinaturas
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.PropostaDeVenda} PropostaDeVenda Insira um objeto com todos os dados da venda
     **/
    public async CammelPost(Token: string, Cofre: string, PropostaDeVenda: Objeto.PropostaDeVenda): Promise<AxiosResponse> {
        return await CamelCase(Context).post(`/D4sign/${Token}?Cofre=${Cofre}`, PropostaDeVenda)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception.response; });
    };
    /* Sobre esta função *//**
     *  Cadastra o contrato de compra e venda e o encaminha para as devidas asssinaturas
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.PropostaDeVenda} PropostaDeVenda Insira um objeto com todos os dados da venda
     **/
    public async Post(Token: string, Cofre: string, PropostaDeVenda: Objeto.PropostaDeVenda): Promise<AxiosResponse> {
        return await Context.post(`/D4sign/${Token}?Cofre=${Cofre}`, PropostaDeVenda)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception.response; });
    };
    /* Sobre esta função *//**
     *  Cadastra a NFSe e a encaminha para as devidas asssinaturas
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {any} ParametrosD4sign Insira um objeto com todos os parametros para o D4sign
     **/
    public async SubmeterNFSe(Token: string, Cofre: string, Arquivo: any): Promise<AxiosResponse> {
        return await Context.post(`/D4sign/SubmeterNFSe/${Token}?Cofre=${Cofre}`, Arquivo)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception.response; });
    };
    /* #endregion */
};

export default new D4sign();