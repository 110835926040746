import React from 'react';

class WidthCheck {
    constructor() {
        try {
            var Resultado = (JSON.parse(localStorage.getItem("ContextWidthCheck") ?? "") as { width: number }).width;
        }
        catch {
            localStorage.setItem("ContextWidthCheck", JSON.stringify(<{ width: number }>{
                width: 0,
            }));
        }
    }
    /* Sobre esta função *//**
     * Armazena um registro no repositório local e no cache da aplicação
     * @param {number} Registro Informe o registro a ser armazenado
     **/
    public setContext(Registro: { width: number }) {
        if (Registro)
        {
            console.log(JSON.stringify(Registro) + "Topbar1")
            localStorage.setItem("ContextWidthCheck", JSON.stringify(Registro));
        }
        else
        {
            console.log(document.body.clientWidth + "Topbar2")
            localStorage.setItem("ContextWidthCheck", JSON.stringify(<{ width: number }>{
                width: 0,
            }));
        }
    };
    /* Sobre esta função *//**
     * Retorna o registro armazenado no repositório local
     **/
    public getContext(): { width: number } {
        return JSON.parse(localStorage.getItem("ContextWidthCheck") ?? "") as { width: number };
    };
};

export default new WidthCheck();