import { AxiosResponse } from 'axios';
import axios from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class Area {
    
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Retorna as departamento por grupo
     **/
    public async Get(Token: string): Promise<AxiosResponse<Array<{id: number, descricao: string}>>> {
        return await Context.get(`/Area/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Adicionar departamento por grupo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Sala Informe o objeto sala de venda que será adcionado no banco de dados"
     **/
    public async Post(Token: string, Sala: {id: number, descricao: string}): Promise<AxiosResponse<{id: number, descricao: string}>> {
        return await Context.post(`/Area/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Sala)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar departamento por grupo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Sala Informe o objeto sala de venda que será alterado no banco de dados"
     **/
    public async Put(Token: string, Sala: {id: number, descricao: string}): Promise<AxiosResponse<any>> {
        return await Context.put(`/Area/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Sala)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar departamento por grupo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Area Informe o objeto do deparamento que será deletado no banco de dados"
     **/
    public async Delete(Token: string, Area: {id: number, descricao: string}): Promise<any> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Area/' + Token + "/" + ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado,
            data: Area,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Retorna as departamento vinculados a sala de vendas de um grupo
     **/
    public async Vinculadas(Token: string, IdSala: number): Promise<AxiosResponse<Array<{id: number, descricao: string}>>> {
        return await Context.get(`/Area/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${IdSala}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Vincular departamento a sala de venda de um grupo especifico
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} IdSala Informe o id da sala"
     * @param {object} IdArea Informe o id da area"
     **/
    public async VincularASalaDeVenda(Token: string, IdSala: number, IdArea: number): Promise<AxiosResponse<{id: number, descricao: string}>> {
        return await Context.post(`/Area/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${IdSala}/${IdArea}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new Area();