import { AxiosResponse } from 'axios';
import axios from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class Brinde {

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Consulta os brindes cadastrados
     **/
    public async Get(Token: string): Promise<AxiosResponse<Array<{id: number, descricao: string}>>> {
        return await Context.get(`/Brinde/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo brinde
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Brinde Informe o objeto do brinde que será adcionado no banco de dados"
     **/
    public async Post(Token: string, Brinde: {id: number, descricao: string}): Promise<AxiosResponse<{id: number, descricao: string}>> {
        return await Context.post(`/Brinde/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Brinde)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar dados do brinde
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Brinde Informe o objeto do brinde que será alterado no banco de dados"
     **/
    public async Put(Token: string, Brinde: {id: number, descricao: string}): Promise<AxiosResponse<{id: number, descricao: string}>> {
        return await Context.put(`/Brinde/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Brinde)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o brinde 
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Brinde Informe o objeto do brinde que será deletado no banco de dados"
     **/
    public async Delete(Token: string, Brinde: {id: number, descricao: string}): Promise<AxiosResponse<{id: number, descricao: string}>> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Brinde/' + Token + "/" + ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado,
            data: Brinde,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Consulta os tipos de brinde
     **/
    public async TiposDeBrinde(Token: string): Promise<AxiosResponse<Array<{id: number, descricao: string}>>> {
        return await Context.get(`/Brinde/TiposDeBrinde/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Consulta os tipos de brindes
     **/
    public async Almoxarifado(Token: string): Promise<AxiosResponse<Array<{ brinde: {id: number, descricao: string}, tipo: {id: number, descricao: string}, fornecedor: Objeto.Fornecedor, validadeInicial: Date, validadeFinal: Date, quantidade: number, valorUnitario: number}>>> {
        return await Context.get(`/Brinde/Almoxarifado/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Consulta a movimentação de brindes feitas contra o fornecedor
     **/
    public async ExtratoDoFornecedor(Token: string): Promise<AxiosResponse<Array<{ periodo: Date, data: Array<{id: number, titulo: string, descricao: string, periodo: Date, valor: number, credito: boolean }>}>>> {
        return await Context.get(`/Brinde/ExtratoDoFornecedor/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo tipo de brinde
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Tipo Informe o objeto do tipo de brinde que será adcionado no banco de dados"
     **/
    public async NovoTipoDeBrinde(Token: string, Tipo: {id: number, descricao: string}): Promise<AxiosResponse<{id: number, descricao: string}>> {
        return await Context.post(`/Brinde/NovoTipoDeBrinde/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Tipo)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo item no almoxarifado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} ItemAlmoxarifado Informe o objeto do item do almoxarifado que será adcionado no banco de dados"
     **/
    public async CadastrarItemNoAlmoxarifado(Token: string, ItemAlmoxarifado: { brinde?: {id: number, descricao: string}, tipo?: {id: number, descricao: string}, fornecedor?: Objeto.Fornecedor, validadeInicial?: Date | null | string, validadeFinal?: Date | null | string, quantidade: number, valorUnitario: number}): Promise<AxiosResponse<{ brinde: {id: number, descricao: string}, tipo: {id: number, descricao: string}, fornecedor: Objeto.Fornecedor, validadeInicial: Date, validadeFinal: Date, quantidade: number, valorUnitario: number}>> {
        return await Context.post(`/Brinde/CadastrarItemNoAlmoxarifado/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, ItemAlmoxarifado)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo tipo de brinde
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} QRCode Informe os dados do QRCode"
     **/
    public async ValidarBrinde(Token: string, QRCode: string): Promise<AxiosResponse<any>> {
        return await Context.post(`/Brinde/ValidarBrinde/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?QRCode=${QRCode}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar os dados do tipo de brinde
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Tipo Informe o objeto do tipo de brinde que será alterado no banco de dados"
     **/
    public async AlterarTipoDeBrinde(Token: string, Tipo: {id: number, descricao: string}): Promise<AxiosResponse<{id: number, descricao: string}>> {
        return await Context.put(`/Brinde/AlterarTipoDeBrinde/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Tipo)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar os dados do tipo de brinde
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} ItemAlmoxarifado Informe o objeto do item do almoxarifado que será alterado no banco de dados"
     **/
    public async AlterarItemDoAlmoxarifado(Token: string, ItemAlmoxarifado: { brinde?: {id: number, descricao: string}, tipo?: {id: number, descricao: string}, fornecedor?: Objeto.Fornecedor, validadeInicial?: Date | null | string, validadeFinal?: Date | null | string, quantidade: number, valorUnitario: number}): Promise<AxiosResponse<any>> {
        return await Context.put(`/Brinde/AlterarItemDoAlmoxarifado/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, ItemAlmoxarifado)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o tipo de brinde 
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Tipo Informe o objeto do tipo de brinde que será deletado no banco de dados"
     **/
    public async DeletarTipoDeBrinde(Token: string, Tipo: {id: number, descricao: string}): Promise<AxiosResponse<{id: number, descricao: string}>> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Brinde/DeletarTipoDeBrinde/' + Token + "/" + ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado,
            data: Tipo,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o item do almoxarifado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} ItemAlmoxarifado Informe o objeto do item do almoxarifado que será deletado do banco de dados"
     **/
    public async DeletarItemDoAlmoxarifado(Token: string, ItemAlmoxarifado: { brinde?: {id: number, descricao: string}, tipo?: {id: number, descricao: string}, fornecedor?: Objeto.Fornecedor, validadeInicial?: Date | null | string, validadeFinal?: Date | null | string, quantidade: number, valorUnitario: number}): Promise<any> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Brinde/DeletarItemDoAlmoxarifado/' + Token + "/" + ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado,
            data: ItemAlmoxarifado,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

}

export default new Brinde();