import React from 'react';
import { Icon } from '@blueprintjs/core';

function App({ value, icon }: any) {

  return (
    <div style={{ display: "flex", alignItems: "center", background: "0", marginBottom: "5px" }}>
      <Icon icon={icon} color={"#001346"} iconSize={20} />
      <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{value}</span>
    </div>
  );
}

export default App;