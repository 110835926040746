import moment, { weekdaysShort } from "moment";
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { Button, Card, FormGroup, Elevation, H2, Icon, MenuItem, ButtonGroup, Collapse } from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";
import "moment/locale/pt-br"
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import History from "../../../Routers";
import { CentroDeCusto, Venda } from '../../../Services/Controllers';
import { Moeda, Numero } from '../../../Themes/TextFormat';
import { Objeto } from '../../../Services/Models';
import { NotificacaoInterna } from '../../../Services/Notification';
import * as LottieFiles from '../../../Data/Lottie';

import * as Component from './style';
import viewModel from './viewModel';

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const [getTextoProcurado, setTextoProcurado] = useState<string>("");
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const [getListaDeContratosmConsulta, setListaDeContratosmConsulta] = useState(false);
    const [getFormularioListaDeContratos, setFormularioListaDeContratos] = useState<Array<Objeto.PropostaDeVenda>>([]);
    const [getFormularioListaDeContratosExibidos, setFormularioListaDeContratosExibidos] = useState<Array<Objeto.PropostaDeVenda>>([]);
    const [getFormularioListaDeContratosFiltrados, setFormularioListaDeContratosFiltrados] = useState<Array<Objeto.PropostaDeVenda>>([]);
    const [getCollapse, setCollapse] = useState(false);
    const QtdeDeContratosExibidos = 20;
    /* #endregion */

    /* #region  Funções  */
    /** Função de atualização da lista de contratos */
    async function filtrandoContratos(Texto: string): Promise<void> {
        let contratosFiltrados = getFormularioListaDeContratos.filter((item) => item.identificador.subLocal.descricao.toLocaleUpperCase().includes(Texto.toLocaleUpperCase()));
        await setFormularioListaDeContratosExibidos((contratosFiltrados.length - getFormularioListaDeContratosExibidos.length >= 100) ? contratosFiltrados.slice(0, getFormularioListaDeContratosExibidos.length + QtdeDeContratosExibidos) : contratosFiltrados);
        await setFormularioListaDeContratosFiltrados(contratosFiltrados);
        await setTextoProcurado(Texto);
    };
    /** Função de atualização da lista de unidades */
    useEffect(() => {
        async function componentDidMount() {
            try {
                await setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data);
            }
            catch { }
        }
        componentDidMount();
    }, []);
    return (
        <Component.Container style={{ overflow: "auto" }} onScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
            if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) {
                filtrandoContratos("");
            }
        }}>
            <Collapse isOpen={getCollapse}>
                <div className="endereco">
                    <img src={IconeHome} style={{cursor: "pointer"}} alt="" onClick={() => {History.goBack();}} />
                    <div className="bar">/</div>
                    <div className="place">Propostas pendentes</div>
                </div>
            </Collapse>
            {/* Abridor Colapse */}
            <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>

            <Card style={{ backgroundColor: "0", width: "88%", margin: "40px auto 40px auto", padding: "0 3%", borderRadius: "5px", border: "none", boxShadow: "none" }}>
                <Component.ContentCard>
                    <Component.Title>
                        <h3 style={{ fontSize: "20px" }}>Lista de contratos</h3>
                    </Component.Title>
                    <FormGroup style={{ padding: "0", backgroundColor: "0" }}>
                        {getListaPickerCentroDeCusto.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                        <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => {
                                await setListaDeContratosmConsulta(true);
                                await setItemPickerCentroDeCusto(Item);
                                let Response = (await Venda.ContratosPendentesDeAprovacao(ContextSignIn.getContext().token, Item.empresa.id ?? 0, Item.sigla ?? "")).data;
                                await setFormularioListaDeContratos(Response);
                                await setFormularioListaDeContratosFiltrados(Response);
                                await setFormularioListaDeContratosExibidos((Response.length >= QtdeDeContratosExibidos) ? Response.slice(0, QtdeDeContratosExibidos) : Response);
                                await setListaDeContratosmConsulta(false);
                            }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button text={getItemPickerCentroDeCusto?.descricao ?? "Selecione o empreendimento"} rightIcon="double-caret-vertical" />
                        </PickerCentroDeCusto>
                    </FormGroup>
                    <Component.FormContent style={{ backgroundColor: '0', marginBottom: '20px' }}>
                        <Component.ContentFormHeader>
                            <div style={{ width: "100%" }}>
                                {getFormularioListaDeContratosExibidos.map((contrato, Index) => {
                                    let ValorTotalTitulos = 0, ValorTotalTitulosDeCorretagem = 0, ValorTotalTitulosDeIntermediacao = 0, ValorTotalTitulosDeFinanciamento = 0, ValorTotalTitulosDeEntrada = 0, ValorTotalTitulosDeSinal = 0, ValorTotalTitulosDeParcela = 0, ValorTotalTitulosDeParcelaObra = 0, ValorTotalTitulosDeIntermediaria = 0;
                                    let QtdeTitulos = 0, QtdeTitulosDeCorretagem = 0, QtdeTitulosDeIntermediacao = 0, QtdeTitulosDeFinanciamento = 0, QtdeTitulosDeEntrada = 0, QtdeTitulosDeSinal = 0, QtdeTitulosDeParcela = 0, QtdeTitulosDeParcelaObra = 0, QtdeTitulosDeIntermediaria = 0;
                                    contrato.titulosConsolidados.map((Item) => {
                                        switch (Item.classificacao.id) {
                                            case 1:
                                                ValorTotalTitulosDeIntermediacao += Item.principal;
                                                QtdeTitulosDeIntermediacao += 1;
                                                break;
                                            case 2:
                                                ValorTotalTitulosDeSinal += Item.principal;
                                                QtdeTitulosDeSinal += 1;
                                                break;
                                            case 3:
                                                ValorTotalTitulosDeEntrada += Item.principal;
                                                QtdeTitulosDeEntrada += 1;
                                                break;
                                            case 4:
                                                ValorTotalTitulosDeParcela += Item.principal;
                                                QtdeTitulosDeParcela += 1;
                                                break;
                                            case 5:
                                                break;
                                            case 6:
                                                ValorTotalTitulosDeFinanciamento += Item.principal;
                                                QtdeTitulosDeFinanciamento += 1;
                                                break;
                                            case 7:
                                                break;
                                            case 8:
                                                break;
                                            case 9:
                                                break;
                                            case 10:
                                                break;
                                            case 11:
                                                break;
                                            case 12:
                                                ValorTotalTitulosDeCorretagem += Item.principal;
                                                QtdeTitulosDeCorretagem += 1;
                                                break;
                                            case 13:
                                                ValorTotalTitulosDeParcelaObra += Item.principal;
                                                QtdeTitulosDeParcelaObra += 1;
                                                break;
                                            case 14:
                                                ValorTotalTitulosDeIntermediaria += Item.principal;
                                                QtdeTitulosDeIntermediaria += 1;
                                                break;
                                        }
                                    });
                                    ValorTotalTitulos = ValorTotalTitulosDeCorretagem + ValorTotalTitulosDeIntermediacao + ValorTotalTitulosDeFinanciamento + ValorTotalTitulosDeEntrada + ValorTotalTitulosDeSinal + ValorTotalTitulosDeIntermediaria + ValorTotalTitulosDeParcelaObra + ValorTotalTitulosDeParcela;

                                    return (<Card style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "10px", flexWrap: "wrap" }}>
                                        <ButtonGroup style={{ alignItems: "center", justifyContent: "space-between", width: "100%", flexWrap: "wrap" }} fill={true}>
                                            <div style={{ width: "100%", flexWrap: "wrap" }}>
                                                <div style={{ display: "flex", marginBottom: "5px" }}>
                                                    <FormGroup style={{ fontSize: 20, color: "#222e50", fontWeight: "bold" }} label={`${contrato.prospects[0].nome}`} inline={true} />                                                </div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                    <div style={{ display: "block", width: "30%", minWidth: "100px" }}>
                                                        <div style={{ margin: "5px", color: "#222e50", backgroundColor: "0", width: "90%", fontSize: "14px", minWidth: "100px" }}><strong style={{ color: "#9da9b3" }}>Identificador:</strong>{` ${contrato.identificador.subLocal.descricao}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50", minWidth: "100px" }}><strong style={{ color: "#9da9b3" }}>Data da proposta:</strong>{` ${moment(contrato.dataDaVenda).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50", minWidth: "100px" }}><strong style={{ color: "#9da9b3" }}>Vendedor:</strong>{` ${contrato.respCadastroNome ?? "Não identificado"}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50", minWidth: "100px" }}><strong style={{ color: "#9da9b3" }}>Valor a vista:</strong>{` ${Moeda.FormatarTexto(contrato.identificador.valorAVista)}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50", minWidth: "100px" }}><strong style={{ color: "#9da9b3" }}>Total gerado:</strong>{` ${Moeda.FormatarTexto(ValorTotalTitulos)}`}</div>
                                                    </div>
                                                    <div style={{ display: "block", width: "70%" }}>
                                                        {ValorTotalTitulosDeCorretagem > 0 && <div style={{ margin: "5px", color: "#222e50", fontSize: "10pt" }}><strong>Corretagem:</strong>{` ${Numero.FormatarTextoParaInteiro(QtdeTitulosDeCorretagem)} parcela${QtdeTitulosDeCorretagem > 1 ? "s" : ""} de ${Moeda.FormatarTexto(contrato.titulosDeCorretagem ? contrato.titulosDeCorretagem[0].valor : 0)}, totalizando em ${Moeda.FormatarTexto(ValorTotalTitulosDeCorretagem)}, com primeiro vencimento em ${moment(contrato.titulosDeCorretagem[0].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")} e último vencimento em ${moment(contrato.titulosDeCorretagem[contrato.titulosDeCorretagem.length - 1].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>}
                                                        {ValorTotalTitulosDeIntermediacao > 0 && <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Intermediação:</strong>{` ${Numero.FormatarTextoParaInteiro(QtdeTitulosDeIntermediacao)} parcela${QtdeTitulosDeIntermediacao > 1 ? "s" : ""} de ${Moeda.FormatarTexto(contrato.titulosDeIntermediacao ? contrato.titulosDeIntermediacao[0].valor : 0)}, totalizando em ${Moeda.FormatarTexto(ValorTotalTitulosDeIntermediacao)}, com primeiro vencimento em ${moment(contrato.titulosDeIntermediacao[0].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")} e último vencimento em ${moment(contrato.titulosDeIntermediacao[contrato.titulosDeIntermediacao.length - 1].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>}
                                                        {ValorTotalTitulosDeFinanciamento > 0 && <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Fin. bancário:</strong>{` ${Numero.FormatarTextoParaInteiro(QtdeTitulosDeFinanciamento)} parcela${QtdeTitulosDeFinanciamento > 1 ? "s" : ""} de ${Moeda.FormatarTexto(contrato.titulosDeFinanciamento ? contrato.titulosDeFinanciamento[0].valor : 0)}, totalizando em ${Moeda.FormatarTexto(ValorTotalTitulosDeFinanciamento)}, com primeiro vencimento em ${moment(contrato.titulosDeFinanciamento[0].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")} e último vencimento em ${moment(contrato.titulosDeFinanciamento[contrato.titulosDeFinanciamento.length - 1].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>}
                                                        {ValorTotalTitulosDeEntrada > 0 && <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Entrada</strong>:{` ${Numero.FormatarTextoParaInteiro(QtdeTitulosDeEntrada)} parcela${QtdeTitulosDeEntrada > 1 ? "s" : ""} de ${Moeda.FormatarTexto(contrato.titulosDeEntrada ? contrato.titulosDeEntrada[0].valor : 0)}, totalizando em ${Moeda.FormatarTexto(ValorTotalTitulosDeEntrada)}, com primeiro vencimento em ${moment(contrato.titulosDeEntrada[0].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")} e último vencimento em ${moment(contrato.titulosDeEntrada[contrato.titulosDeEntrada.length - 1].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>}
                                                        {ValorTotalTitulosDeSinal > 0 && <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Sinal</strong>:{` ${Numero.FormatarTextoParaInteiro(QtdeTitulosDeSinal)} parcela${QtdeTitulosDeSinal > 1 ? "s" : ""} de ${Moeda.FormatarTexto(contrato.titulosDeSinal ? contrato.titulosDeSinal[0].valor : 0)}, totalizando em ${Moeda.FormatarTexto(ValorTotalTitulosDeSinal)}, com primeiro vencimento em ${moment(contrato.titulosDeSinal[0].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")} e último vencimento em ${moment(contrato.titulosDeSinal[contrato.titulosDeSinal.length - 1].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>}
                                                        {ValorTotalTitulosDeIntermediaria > 0 && <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Intermediárias:</strong>{` ${Numero.FormatarTextoParaInteiro(QtdeTitulosDeIntermediaria)} parcela${QtdeTitulosDeIntermediaria > 1 ? "s" : ""} de ${Moeda.FormatarTexto(contrato.titulosDeIntermediaria ? contrato.titulosDeIntermediaria[0].valor : 0)}, totalizando em ${Moeda.FormatarTexto(ValorTotalTitulosDeIntermediaria)}, com primeiro vencimento em ${moment(contrato.titulosDeIntermediaria[0].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")} e último vencimento em ${moment(contrato.titulosDeIntermediaria[contrato.titulosDeIntermediaria.length - 1].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>}
                                                        {ValorTotalTitulosDeParcelaObra > 0 && <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Parcela obra:</strong>{` ${Numero.FormatarTextoParaInteiro(QtdeTitulosDeParcelaObra)} parcela${QtdeTitulosDeParcelaObra > 1 ? "s" : ""} de ${Moeda.FormatarTexto(contrato.titulosDeParcelaObra ? contrato.titulosDeParcelaObra[0].valor : 0)}, totalizando em ${Moeda.FormatarTexto(ValorTotalTitulosDeParcelaObra)}, com primeiro vencimento em ${moment(contrato.titulosDeParcelaObra[0].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")} e último vencimento em ${moment(contrato.titulosDeParcelaObra[contrato.titulosDeParcelaObra.length - 1].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>}
                                                        {ValorTotalTitulosDeParcela > 0 && <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Saldo:</strong>{` ${Numero.FormatarTextoParaInteiro(QtdeTitulosDeParcela)} parcela${QtdeTitulosDeParcela > 1 ? "s" : ""} de ${Moeda.FormatarTexto(contrato.titulosDeParcela ? contrato.titulosDeParcela[0].valor : 0)}, totalizando em ${Moeda.FormatarTexto(ValorTotalTitulosDeParcela)}, com primeiro vencimento em ${moment(contrato.titulosDeParcela[0].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")} e último vencimento em ${moment(contrato.titulosDeParcela[contrato.titulosDeParcela.length - 1].vencimento).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>}
                                                    </div>
                                                </div>
                                                <h2></h2>
                                            </div>
                                            <div style={{ display: "flex", marginLeft: "auto", marginTop: "auto", marginBottom: "20px", flexWrap: "wrap" }}>
                                                {(contrato._VendaGerada == false || contrato._VendaCancelada == false) && <div style={{ width: 100, height: 100 }}>
                                                    <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                                                </div>}
                                                {((contrato._VendaGerada == undefined || contrato._VendaGerada == true) && contrato._VendaCancelada == undefined) && <Button type="submit" intent={(contrato._VendaGerada == true) ? "success" : "primary"} disabled={contrato._VendaGerada == true} style={{ height: "30px", width: "140px" }}
                                                    onClick={async () => {
                                                        if (!contrato._VendaGerada) {
                                                            let ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                            ListaDeContratosExibidos[Index]._VendaGerada = false;
                                                            await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);
                                                            NotificacaoInterna.ExibirNotificacao("Venda sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                        }
                                                        else {
                                                            return;
                                                        }
                                                        let Response = await Venda.AprovarProposta(ContextSignIn.getContext().token, contrato.empresa.id, contrato.centroDeCusto.sigla, contrato.numero);
                                                        let ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                        ListaDeContratosExibidos[Index]._VendaGerada = ((Math.floor(Response.status / 100) == 2) ? true : undefined);
                                                        await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);

                                                        if (Math.floor(Response.status / 100) == 2) {
                                                            NotificacaoInterna.ExibirNotificacao("Venda gerada com sucesso", "Sua venda já se encontra disponível em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                        }
                                                        else {
                                                            NotificacaoInterna.ExibirNotificacao("Falha ao gerar a venda", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        }
                                                    }}>{(contrato._VendaGerada == true) ? "Venda aprovada!" : "Aprovar"}</Button>}
                                                {((contrato._VendaCancelada == undefined || contrato._VendaCancelada == true) && contrato._VendaGerada == undefined) && <Button type="submit" intent={(contrato._VendaCancelada == true) ? "success" : "danger"} disabled={contrato._VendaCancelada == true} style={{ marginLeft: "10px", height: "30px", width: "140px" }}
                                                    onClick={async () => {
                                                        if (!contrato._VendaCancelada) {
                                                            let ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                            ListaDeContratosExibidos[Index]._VendaCancelada = false;
                                                            await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);
                                                            NotificacaoInterna.ExibirNotificacao("Venda sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                        }
                                                        else {
                                                            return;
                                                        }
                                                        let Response = await Venda.DesaprovarProposta(ContextSignIn.getContext().token, contrato.empresa.id, contrato.centroDeCusto.sigla, contrato.numero);
                                                        let ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                        ListaDeContratosExibidos[Index]._VendaCancelada = ((Math.floor(Response.status / 100) == 2) ? true : undefined);
                                                        await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);

                                                        if (Math.floor(Response.status / 100) == 2) {
                                                            NotificacaoInterna.ExibirNotificacao("Venda gerada com sucesso", "Sua venda já se encontra disponível em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                        }
                                                        else {
                                                            NotificacaoInterna.ExibirNotificacao("Falha ao gerar a venda", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        }
                                                    }}>{(contrato._VendaCancelada == true) ? "Venda rejeitada!" : "Desaprovar"}</Button>}
                                            </div>
                                        </ButtonGroup>
                                    </Card>);
                                }
                                )}
                            </div>
                        </Component.ContentFormHeader>
                    </Component.FormContent>
                </Component.ContentCard>
            </Card>
            {getListaDeContratosmConsulta == true && <div style={{ width: 100, height: 100, marginLeft: '5%' }}>
                <Lottie options={{ animationData: LottieFiles.Load03, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
            </div>}
        </Component.Container >
    );
    /* #endregion */
};
export default View;