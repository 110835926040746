import { AxiosResponse } from 'axios';
import axios from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class SalaDeVenda {
    
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Retorna as salas por grupo
     **/
    public async Get(Token: string): Promise<AxiosResponse<Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>>> {
        return await Context.get(`/SalaDeVenda/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Adicionar sala de venda por grupo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Sala Informe o objeto sala de venda que será adcionado no banco de dados"
     **/
    public async Post(Token: string, Sala: {id: number, descricao: string}): Promise<AxiosResponse<{id: number, descricao: string}>> {
        return await Context.post(`/SalaDeVenda/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Sala)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar sala de venda por grupo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Sala Informe o objeto sala de venda que será alterado no banco de dados"
     **/
    public async Put(Token: string, Sala: {id: number, descricao: string}): Promise<AxiosResponse<any>> {
        return await Context.put(`/SalaDeVenda/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Sala)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar sala de venda por grupo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Sala Informe o objeto sala de venda que será alterado no banco de dados"
     **/
    public async Delete(Token: string, Sala: {id: number, descricao: string}): Promise<any> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/SalaDeVenda/' + Token + "/" + ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado,
            data: Sala,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new SalaDeVenda();