import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router";
import App from './App';

import History from './Routers';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

ReactDOM.render(
  <Router history={History}>
    <App />
  </Router>,
  document.getElementById('root')
);