import React, { useEffect, useState } from 'react';
import { DashBoardTotalDePropostas, DashBoardPropostasAtivas, DashBoardPropostasConfirmadas, DashBoardPropostasVendidas, DashBoardPropostasExpiradas, DashBoardPropostasCanceladas } from '../../Assets';
import { MenuItemAcompanhamentoDeSala, MenuItemBordero, MenuItemControleDeComissao, MenuItemDisponibilidade, MenuItemLeads, MenuItemGestaoDeComissao, MenuItemProspeccao, MenuItemMeusContratos, MenuItemNFSe, MenuItemPropostasPendentes, MenuItemVenda } from '../../Assets';
import { SignIn as ContextSignIn } from '../../Context';
import History from "../../Routers";
import { Menu } from '../../Services/Controllers';
import { NotificacaoInterna } from '../../Services/Notification';
import { Numero } from '../../Themes/TextFormat';

import * as Component from './style';
import viewModel from './viewModel';

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const [getQuadroResumo, setQuadroResumo] = useState<any>([]);
    /* #endregion */

    /* #region  Funções  */
    useEffect(() => {
        async function componentDidMount() {
            var Response = await Menu.QuadroResumo(ContextSignIn.getContext().token);
            if (Math.floor(Response.status / 100) == 2) {
                await setQuadroResumo(Response.data);
            }
            else {

            }
        }
        componentDidMount();
    }, []);
    return (
        <Component.Container>
            <div className="overview" style={{ marginTop: "40px" }}>
                <Component.Title style={{ marginTop: "20px" }}>Visão Geral</Component.Title>
                {window.location.href.includes("onmoney") == true && <div style={{ width: "100%", display: "flex", overflowX: "auto", backgroundColor: "0", justifyContent: "space-between", marginTop: "30px" }}>
                    <Component.Dashboard style={{ backgroundColor: "#7c9bff" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(0)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Notas emitidas</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardTotalDePropostas} alt="" />
                    </Component.Dashboard>
                    <Component.Dashboard style={{ backgroundColor: "#7c9bff" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(0)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Borderos gerados</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardPropostasAtivas} alt="" />
                    </Component.Dashboard>
                    <Component.Dashboard style={{ backgroundColor: "#67e457" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(0)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Borderos aprovados</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardPropostasConfirmadas} alt="" />
                    </Component.Dashboard>
                    <Component.Dashboard style={{ backgroundColor: "#67e457" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(0)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Comissões pagas</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardPropostasVendidas} alt="" />
                    </Component.Dashboard>
                    <Component.Dashboard style={{ backgroundColor: "#ffbf6b" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(0)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Notas sem processo</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardPropostasExpiradas} alt="" />
                    </Component.Dashboard>
                    <Component.Dashboard style={{ backgroundColor: "#ff6767" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(0)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Pagamentos cancelados</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardPropostasCanceladas} alt="" />
                    </Component.Dashboard>
                </div>}
                {window.location.href.includes("onmoney") == false && <div style={{ width: "100%", display: "flex", overflowX: "auto", backgroundColor: "0", justifyContent: "space-between", marginTop: "30px" }}>
                    <Component.Dashboard style={{ backgroundColor: "#7c9bff" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(getQuadroResumo.totalDePropostas)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Total de Propostas</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardTotalDePropostas} alt="" />
                    </Component.Dashboard>
                    <Component.Dashboard style={{ backgroundColor: "#7c9bff" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(getQuadroResumo.propostasAtivas ?? 0)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Propostas Ativas</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardPropostasAtivas} alt="" />
                    </Component.Dashboard>
                    <Component.Dashboard style={{ backgroundColor: "#67e457" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(getQuadroResumo.propostasConfirmadas)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Propostas Confirmadas</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardPropostasConfirmadas} alt="" />
                    </Component.Dashboard>
                    <Component.Dashboard style={{ backgroundColor: "#67e457" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(getQuadroResumo.propostasVendidas)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Propostas Vendidas</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardPropostasVendidas} alt="" />
                    </Component.Dashboard>
                    <Component.Dashboard style={{ backgroundColor: "#ffbf6b" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(getQuadroResumo.propostasExpiradas)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Propostas Expiradas</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardPropostasExpiradas} alt="" />
                    </Component.Dashboard>
                    <Component.Dashboard style={{ backgroundColor: "#ff6767" }}>
                        <Component.DashboardItem>
                            <Component.DashboardItemTitle>{Numero.FormatarTextoParaInteiro(getQuadroResumo.propostasCanceladas)}</Component.DashboardItemTitle>
                            <Component.DashboardItemValue>Propostas Canceladas</Component.DashboardItemValue>
                        </Component.DashboardItem>
                        <Component.DashboardItemImage src={DashBoardPropostasCanceladas} alt="" />
                    </Component.Dashboard>
                </div>}
            </div>
            <Component.Menu>
                <Component.Title>Acesso Rápido</Component.Title>
                <div className="menuItemDiv">
                    {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemAcompanhamentoDeSala})` }} onClick={() => Controller.AcessarPaginaAcompanhamentoDeSala(History)}>
                        <Component.MenuItemValue>Acompanhamento de sala</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemAcompanhamentoDeSala})` }} onClick={() => Controller.AcessarPaginaControleDeSala(History)}>
                        <Component.MenuItemValue>Controle de sala</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemBordero})` }} onClick={() => Controller.AcessarPaginaBordero(History)}>
                        <Component.MenuItemValue>Borderô</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {false && (ContextSignIn.getContext().pessoa.id == 1 || window.location.href.includes("onmoney") == true) && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemControleDeComissao})` }} onClick={() => Controller.AcessarPaginaControleDeComissao(History)}>
                        <Component.MenuItemValue>Controle de comissão</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {window.location.href.includes("onmoney") == false && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemDisponibilidade})` }} onClick={() => Controller.AcessarPaginaDisponibilidade(History)}>
                        <Component.MenuItemValue>Disponibilidade</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemLeads})` }} onClick={() => Controller.AcessarPaginaLeads(History)}>
                        <Component.MenuItemValue>Leads</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {false && (ContextSignIn.getContext().pessoa.id == 1 || window.location.href.includes("onmoney") == true) && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemGestaoDeComissao})` }} onClick={() => Controller.AcessarPaginaGestaoDeComissao(History)}>
                        <Component.MenuItemValue>Gestão de comissão</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemDisponibilidade})` }} onClick={() => Controller.AcessarPaginaMapas(History)}>
                        <Component.MenuItemValue>Mapa do loteamento</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemMeusContratos})` }} onClick={() => Controller.AcessarPaginaMeusContratos(History)}>
                        <Component.MenuItemValue>Meus contratos</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemPropostasPendentes})` }} onClick={() => Controller.AcessarPaginaPropostasPendentes(History)}>
                        <Component.MenuItemValue>Propostas pendentes</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 9 && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemProspeccao})` }} onClick={() => Controller.AcessarPaginaProspeccao(History)}>
                        <Component.MenuItemValue>Prospecção</Component.MenuItemValue>
                    </Component.MenuItem>}
                    {window.location.href.includes("onmoney") == false && ContextSignIn.getContext().pessoa.id != 18856 && <Component.MenuItem style={{ backgroundImage: `url(${MenuItemVenda})` }} onClick={() => Controller.AcessarPaginaVendaDireta(History)}>
                        <Component.MenuItemValue>Venda direta</Component.MenuItemValue>
                    </Component.MenuItem>}
                </div>
            </Component.Menu>
        </Component.Container>
    );
    /* #endregion */
};

export class Controller {
    public static AcessarPaginaAcompanhamentoDeSala(History: any): void {
        if (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && (window.location.href.includes("oneplus") || window.location.href.includes("localhost") || window.location.href.includes("digitaldev"))) {
            History.push("/Menu/AcompanhamentoDeSala");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaControleDeSala(History: any): void {
        if (window.location.href.includes("localhost")) {
            History.push("/Menu/ControleDeSala");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaBordero(History: any): void {
        if (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && (window.location.href.includes("oneplus") || window.location.href.includes("localhost") || window.location.href.includes("digitaldev"))) {
            History.push("/Menu/Bordero");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaControleDeComissao(History: any): void {
        if (window.location.href.includes("onmoney") || window.location.href.includes("localhost")) {
            History.push("/Menu/ControleDeComissao");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaDisponibilidade(History: any): void {
        if (window.location.href.includes("oneplus") || window.location.href.includes("localhost") || window.location.href.includes("digitaldev")) {
            History.push("/Menu/Disponibilidade");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaGestaoDeComissao(History: any): void {
        if (window.location.href.includes("onmoney") || window.location.href.includes("localhost")) {
            History.push("/Menu/GestaoDeComissao");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaLeads(History: any): void {
        if (window.location.href.includes("localhost")) {
            History.push("/Menu/Leads");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaMeusContratos(History: any): void {
        if (window.location.href.includes("oneplus") == true || window.location.href.includes("localhost") || window.location.href.includes("digitaldev")) {
            History.push("/Menu/MeusContratos");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaPropostasPendentes(History: any): void {
        if (window.location.href.includes("localhost")) {
            History.push("/Menu/PropostasPendentes");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaProspeccao(History: any): void {
        if (window.location.href.includes("localhost")) {
            History.push("/Menu/Prospeccao");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaVendaDireta(History: any): void {
        if (window.location.href.includes("oneplus") == true || window.location.href.includes("localhost") || window.location.href.includes("digitaldev")) {
            History.push("/Menu/VendaDireta");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
    public static AcessarPaginaMapas(History: any): void {
        if (!(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4) && (window.location.href.includes("oneplus") || window.location.href.includes("localhost") || window.location.href.includes("digitaldev"))) {
            History.push("/Menu/Mapas");
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
        }
    };
};

export default View;