import React, { useEffect, useState } from 'react';
import { AiOutlineRocket } from 'react-icons/ai';
import { Button, InputGroup, MenuItem, Switch } from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";

import { LogomarcaCosentinoOriginal as LogomarcaCosentino, LogomarcaDigitalDevBranca as LogomarcaDigitalDev, LogomarcaEvianBranca as LogomarcaEvian, LogomarcaGAVResortsBranca as LogomarcaGAVResorts, LogomarcaHarmoniaUrbanismoOriginal as LogomarcaHarmoniaUrbanismo, LogomarcaOnePlusOriginal as LogomarcaOnePlus, LogomarcaOnMoneyBranca as LogomarcaOnMoney, LogomarcaPattroOriginal as LogomarcaPattro, LogomarcaSilvaBrancoOriginal as LogomarcaSilvaBranco } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import History from "../../../Routers";
import { Empresa, Logon } from '../../../Services/Controllers';
import { NotificacaoInterna } from '../../../Services/Notification';
import { CPF } from '../../../Themes/TextFormat';
import { Objeto } from '../../../Services/Models';

import { Container, Form, Footer } from './style';
import './style.css';
import viewModel from './viewModel';

const View: React.FC<viewModel> = (viewModel) => {

    /* #region  Variáveis  */
    const [getLoginComEmail, setLoginComEmail] = useState<boolean>(false);
    const [getLogin, setLogin] = useState<string>("");
    const [getSenha, setSenha] = useState<string>("");
    const [getItemPickerGrupoDeEmpresas, setItemPickerGrupoDeEmpresas] = useState<{ id: number, descricao: string, empresas: Array<Objeto.Empresa> }>();
    const [getListaPickerGrupoDeEmpresas, setListaPickerGrupoDeEmpresas] = useState<Array<{ id: number, descricao: string, empresas: Array<Objeto.Empresa> }>>([]);
    const PickerGrupoDeEmpresas = Select.ofType<{ id: number, descricao: string, empresas: Array<Objeto.Empresa> }>();
    const [getLoginEfetuado, setLoginEfetuado] = useState<{ Progresso?: number, Resultado?: boolean }>({ Progresso: undefined, Resultado: undefined });
    /* #endregion */

    /* #region  Funções  */
    /* Sobre esta função *//**
     * Valida os dados digitados e tenta efetuar o login do usuário
     **/
    async function EfetuarLogin(): Promise<boolean> {
        if (!getItemPickerGrupoDeEmpresas) {
            NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Informe a empresa ao qual deseja acesso", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        if (!CPF) {
            NotificacaoInterna.ExibirNotificacao("CPF não preenchido", "Informe o CPF", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        if (getLoginComEmail == false && CPF.TextoValido(getLogin) == false) {
            NotificacaoInterna.ExibirNotificacao("CPF inválido!", "Verifique se o CPF digitado é válido. Este passo é necessário para resetar a sua senha", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getSenha) {
            NotificacaoInterna.ExibirNotificacao("Senha vazia", "É necessário digitar a senha!", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        await setLoginEfetuado({ Progresso: 0.5, Resultado: undefined });
        let Resultado = (await Logon.Get(((getLogin == "corretor@evianresidence.com.br") ? "73346291081" : ((getLoginComEmail == true) ? getLogin : CPF.DesformatarTexto(getLogin))), getSenha));
        let UsuarioComPermissaoDeAcesso;

        if (Math.floor(Resultado.status / 100) == 2) {
            getItemPickerGrupoDeEmpresas?.empresas.map((Empresa) => {
                Resultado.data.empresasComPermissaoDeAcesso.map(async (PermissaoDeAcesso) => {
                    if (PermissaoDeAcesso.id == Empresa.id) {
                        UsuarioComPermissaoDeAcesso = true;
                    }
                });
            });

            if (UsuarioComPermissaoDeAcesso == true) {
                NotificacaoInterna.ExibirNotificacao("Seja bem vindo!", "Faça um bom uso de nossa solução 😉.", NotificacaoInterna.TipoDeNotificacao.Sucesso, () => setLoginEfetuado({ Progresso: undefined, Resultado: undefined }));
                await setLoginEfetuado({ Progresso: 1, Resultado: true });
                await ContextSignIn.setContext({ token: Resultado.data.token, pessoa: Resultado.data.pessoa, UltimoGrupoDeEmpresasAcessado: getItemPickerGrupoDeEmpresas?.descricao, IdUltimoGrupoDeEmpresasAcessado: getItemPickerGrupoDeEmpresas?.id, cargosPorGrupoDeEmpresa: Resultado.data.cargosPorGrupoDeEmpresa });
                History.push("/Menu");
                return true;
            }
            else {
                NotificacaoInterna.ExibirNotificacao("Permissão de acesso", "O usuário não possui permissão de acesso a esta empresa!", NotificacaoInterna.TipoDeNotificacao.Erro, () => setLoginEfetuado({ Progresso: undefined, Resultado: undefined }));
                await setLoginEfetuado({ Progresso: 1, Resultado: false });
                return false;
            }
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Houve um problema", "Login ou senha incorretos, tente novamente!", NotificacaoInterna.TipoDeNotificacao.Erro, () => setLoginEfetuado({ Progresso: undefined, Resultado: undefined }));
            setLoginEfetuado({ Progresso: 1, Resultado: false });
            return false;
        }
    };
    async function ResetarMinhaSenha(): Promise<void> {
        if (!CPF) {
            NotificacaoInterna.ExibirNotificacao("CPF não preenchido", "Informe o CPF para que possamos localizar o usuario", NotificacaoInterna.TipoDeNotificacao.Erro);
            return;
        }
        if (getLoginComEmail == false && CPF.TextoValido(getLogin) == false) {
            NotificacaoInterna.ExibirNotificacao("CPF inválido!", "Verifique se o CPF digitado é válido. Este passo é necessário para resetar a sua senha", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return;
        }

        let Response = (await Logon.NovaSenha(CPF.DesformatarTexto(getLogin)));
        if (Math.floor(Response.status / 100) == 2) {
            NotificacaoInterna.ExibirNotificacao("Caro usuário", `Um email foi enviado por ${Response.data.remetente} para ${Response.data.destinatario} para a alteração da senha`, NotificacaoInterna.TipoDeNotificacao.Sucesso);
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Caro usuário", "Houve um erro ao tentar alterar a sua senha em nosso sistema, por favor, tente novamente mais tarde!", NotificacaoInterna.TipoDeNotificacao.Erro);
        }
    };
    useEffect(() => {
        async function componentDidMount() {
            try {
                await setListaPickerGrupoDeEmpresas((await Empresa.GruposDeEmpresas("", true)).data);
            }
            catch { }
        }
        componentDidMount();
    }, []);

    return (
        <Container>
            <Form>
                <img style={{ width: "200px", height: "150px", objectFit: "contain" }} src={window.location.href.includes("onmoney") ? LogomarcaOnMoney :
                    (getItemPickerGrupoDeEmpresas?.id == 4 ? LogomarcaGAVResorts :
                        (getItemPickerGrupoDeEmpresas?.id == 5 ? LogomarcaHarmoniaUrbanismo :
                            (getItemPickerGrupoDeEmpresas?.id == 6 ? LogomarcaPattro :
                                (getItemPickerGrupoDeEmpresas?.id == 8 ? LogomarcaSilvaBranco :
                                    (getItemPickerGrupoDeEmpresas?.id == 9 ? LogomarcaCosentino :
                                        (getItemPickerGrupoDeEmpresas?.id == 10 ? LogomarcaEvian : LogomarcaOnePlus))))))} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <PickerGrupoDeEmpresas inputProps={{ style: { background: "#00384a", color: "#ffffff" } }} className="selectmenu" popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerGrupoDeEmpresas} onItemSelect={() => { }} noResults={<MenuItem style={{ width: "276px", background: "#00384a", color: "#ffffff" }} disabled={true} text="Sem resultados disponíveis" />}
                        itemRenderer={(Item: { id: number, descricao: string, empresas: Array<Objeto.Empresa> }) => <MenuItem style={{ width: "276px" }} onClick={async () => {
                            await setItemPickerGrupoDeEmpresas(Item);
                        }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: { id: number, descricao: string, empresas: Array<Objeto.Empresa> }) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                        <Button style={{ margin: "20px 0px 0px 0px", background: "#00384a", color: "#ffffff", width: "286px", height: "40px", outline: "none", border: "#2F855A 2px solid" }} text={getItemPickerGrupoDeEmpresas?.descricao ?? "Selecione a empresa"} rightIcon="double-caret-vertical" color="#ffffff" />
                    </PickerGrupoDeEmpresas>
                    <InputGroup id="Email" placeholder={(getLoginComEmail == true) ? "Email" : "CPF"} value={getLogin}
                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setLogin((getLoginComEmail == true) ? event.currentTarget.value : CPF.FormatarTexto(event.currentTarget.value))} />
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <InputGroup id="Senha" placeholder="Senha" type="password" value={getSenha} onChange={async (event: React.FormEvent<HTMLInputElement>) => await setSenha(event.currentTarget.value)} />
                    </div>
                </div>
                <div style={{ display: "flex", width: "286px", justifyContent: "space-between", alignItems: 'center' }}>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                        <div style={{ fontWeight: (getLoginComEmail == false ? "bolder" : "lighter"), color: "#3e9e6a" }} className="place">CPF</div>
                        <Switch style={{ marginLeft: "15px" }} checked={getLoginComEmail} onChange={async () => {
                            await setLoginComEmail(!getLoginComEmail);
                            await setLogin("");
                        }} />
                        <div style={{ fontWeight: (getLoginComEmail == false ? "lighter" : "bolder"), color: "#3e9e6a" }} className="place">Email</div>
                    </div>
                    <a id="Forgot_Password" onClick={ResetarMinhaSenha}>Esqueci minha senha</a>
                </div>
                <div>
                    <Button type="submit" intent="primary" onClick={EfetuarLogin} id="Submit">Entrar</Button>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <h5>Não tem uma conta?</h5>
                    <a href="/Logon/SignUp">Cadastrar-se</a>
                </div>
                <Footer>
                    <a>
                        <AiOutlineRocket size={20} />Desenvolvido por &nbsp;<img src={LogomarcaDigitalDev} height={30} alt="DigitalDev" />
                    </a>
                </Footer>
            </Form>
        </Container>
    );
    /* #endregion */
};

export default View;