import { AxiosResponse } from 'axios';
import axios from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class Cargo {

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Retorna todos os cargos.
     **/
    public async Get(Token: string): Promise<AxiosResponse<Array<{id: number, nome: string}>>> {
        return await Context.get(`/Cargo/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo cargo
     * @param {Object} Cargo Informe os dados do objeto a ser cadastrado
     **/
    public async Post(Token: string, Cargo: {id: number, nome: string}): Promise<AxiosResponse<{id: number, nome: string}>> {
        return await Context.post(`/Cargo/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Cargo)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Altere um cargo
     * @param {Object} Cargo Informe os dados do objeto a ser alterado
     **/
    public async Put(Token: string, Cargo: {id: number, nome: string}): Promise<AxiosResponse<{id: number, nome: string}>> {
        return await Context.put(`/Cargo/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Cargo)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o cargo 
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Object} Cargo Informe os dados do objeto a ser deletado
     **/
    public async Delete(Token: string, Cargo: {id: number, nome: string}): Promise<any> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Cargo/' + Token + "/" + ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado,
            data: Cargo,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Retorna todas as ocupações.
     **/
    public async Ocupacao(Token: string): Promise<AxiosResponse<Array<{id: number, nome: string}>>> {
        return await Context.get(`/Cargo/Ocupacao/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

}

export default new Cargo();