import React, { memo, useEffect, useState, useRef } from 'react';
import * as Image from '../../../Assets';
import { useMapEvents } from 'react-leaflet-rotate/react-leaflet';
import CircularSlider from 'react-leaflet-rotate/@fseehawer/react-circular-slider';
import { Icon } from '@blueprintjs/core';
import { Objeto } from '../../../Services/Models';
import './leaflet.css';
import * as Styled from './leafletStyle'
import { WidthCheck as ContextWidthCheck } from '../../../Context';
import ReactToPrint from 'react-to-print';
import { Opcoes, Map } from './map_components';

function App({ polygonsData, setListaDeMarcacoes, areas, visao, refresh, setRefresh, getRefreshData, RefreshData, RemoveRefreshData }: any) {
  const [polygons, setPolygons] = useState<any>(polygonsData);
  const [localRefresh, setLocalRefresh] = useState<boolean>(true);
  const [getExibirLegenda, setExibirLegenda] = useState<boolean>(false);
  const [getLegendaOption, setLegendaOption] = useState<number>(0);
  const [getLocalData, setLocalData] = useState<any>();
  const [getListaFaixa, setListaFaixa] = useState<any>();
  const [getListaCategoria, setListaCategoria] = useState<any>();
  const [getRuaSize, setRuaSize] = useState<Array<number>>([120, 80, 10, 0]);
  const [getMarkersSize, setMarkersSize] = useState<Array<number>>([16, 12, 6, 0.7]);
  const [getCenter, setCenter] = useState<[number, number]>(polygons.center)
  const [getConsumer, setConsumer] = useState<[boolean, [number, number]]>([false, [0, 0]]);
  const [getTileLayer, setTileLayer] = useState<number>(0);
  const [getRotate, setRotate] = useState<[boolean, number]>([false, 0]);
  const [getListaPickerQuadra, setListaPickerQuadra] = useState<Array<Objeto.QuadraPicker>>([]);

  const optionsToChoose: Array<{ id: number, name: string, width: number }> = [
    {
      id: 0,
      name: "Legenda",
      width: 70
    },
    {
      id: 2,
      name: "Visualização",
      width: 100
    },
    {
      id: 1,
      name: "Filtro",
      width: 70
    }
  ]
  const columnsData: Array<{ name: string, width: string }> = [
    {
      name: "Status",
      width: "31%"
    },
    {
      name: "Área(m²)",
      width: "calc(22% - 0.5px)"
    },
    {
      name: "Qtd",
      width: "15%"
    },
    {
      name: "Valor(R$)",
      width: "32%"
    }
  ]
  const statusData: Array<{ id: number, name: string, color: string }> = [
    {
      id: 0,
      name: "Disponível",
      color: "green"
    },
    {
      id: 1,
      name: "Vendido",
      color: "red"
    },
    {
      id: 2,
      name: "Reservado",
      color: "yellow"
    },
    {
      id: 3,
      name: "Proposta",
      color: "darkorange"
    },
    {
      id: 4,
      name: "Quitado",
      color: "orange"
    },
    {
      id: 5,
      name: "Escriturado",
      color: "gray"
    },
    {
      id: 6,
      name: "Em Venda",
      color: "black"
    },
    {
      id: 7,
      name: "Suspenso Venda",
      color: "darkcyan"
    },
    {
      id: 8,
      name: "Fora de Venda",
      color: "#D7D5D2"
    },
    {
      id: 9,
      name: "Em Acerto",
      color: "pink"
    },
    {
      id: 10,
      name: "Dação",
      color: "darkblue"
    }
  ]

  window.onresize = function () {
    ContextWidthCheck.setContext({ width: document.body.clientWidth })
  }

  let componentRef = useRef(null)


  useEffect(() => {
    async function componentDidMount() {
      await setListaFaixa(await getFaixaValor());
      await setListaCategoria(await getCategoria());
      await setListaPickerQuadra(await getQuadras());
    }
    componentDidMount();
  }, [refresh])

  function MyComponent() {
    const map = useMapEvents({
      click: (e: any) => {
        console.log(e.latlng)
      },
      zoomend: (e: any) => {
        setMarkersSize([(21 + 10 * (e.target._zoom - 18)), (15 + 5 * (e.target._zoom - 18)), (8 + 2 * (e.target._zoom - 18)), e.target._zoom <= 16 ? 0 : (1 + 0.2 * (e.target._zoom - 18))])
        setRuaSize([(100 + 40 * (e.target._zoom - 18)), (80 + 20 * (e.target._zoom - 18)), (10 + 2 * (e.target._zoom - 18)), e.target._zoom !== 18 ? 0 : (1 + 0.2 * (e.target._zoom - 18))])
      }
    })
    return null
  }
  function Calcularcenario(status: number | string | undefined, tipo: number) {
    let total = 0;
    switch (tipo) {
      case 0:
        polygons?.lista.map((quadra: any, quadraIndex: any) => {
          quadra.lotes.map((lote: any, loteIndex: any) => {
            if ((visao === 0 ? lote.dadosUau.status : visao === 2 ? lote.faixaValor.faixa : visao === 1 ? lote.dadosUau.statusDaInadimplencia : lote.dadosUau.Categoria_de_preco) === status) {
              total += lote.dadosUau.area;
            }
          })
        })
        break;
      case 1:
        polygons?.lista.map((quadra: any, quadraIndex: any) => {
          quadra.lotes.map((lote: any, loteIndex: any) => {
            if ((visao === 0 ? lote.dadosUau.status : visao === 2 ? lote.faixaValor.faixa : visao === 1 ? lote.dadosUau.statusDaInadimplencia : lote.dadosUau.Categoria_de_preco) === status) {
              total += 1;
            }
          })
        })
        break;
      case 2:
        polygons?.lista.map((quadra: any, quadraIndex: any) => {
          quadra.lotes.map((lote: any, loteIndex: any) => {
            if ((visao === 0 ? lote.dadosUau.status : visao === 2 ? lote.faixaValor.faixa : visao === 1 ? lote.dadosUau.statusDaInadimplencia : lote.dadosUau.Categoria_de_preco) === status) {
              total += lote.dadosUau.valorAVista;
            }
          })
        })
        break;
      case 3:
        polygons?.lista.map((quadra: any, quadraIndex: any) => {
          quadra.lotes.map((lote: any, loteIndex: any) => {
            if ((visao === 0 ? lote.dadosUau.status : visao === 2 ? lote.faixaValor.faixa : visao === 1 ? lote.dadosUau.statusDaInadimplencia : lote.dadosUau.Categoria_de_preco) == status) {
              total += lote.dadosUau.valorAVista;
            }
          })
        })
        break;
    }

    return total;
  }

  async function getFaixaValor() {
    const faixas: any = [[130000, 200000], [115000, 130000], [100000, 115000], [85000, 100000], [60000, 85000], [45000, 60000], [20000, 45000]];
    const colors: any = ["#ff0000", "#FF5721", "#FF9021", "#FFB821", "#FFFC21", "#ABFF21", "#4DFF21", "#21FF5A", "#21FFC2", "#21EBFF", "#2161FF"]
    const faixasValores: any = [];
    await polygons.lista.map(async (quadra: any) => {
      await quadra.lotes.map((lote: any, loteIndex: any) => {
        lote["faixaValor"] = {
          faixa: [],
          cor: "#D7D5D2"
        }
      })
    })
    await faixas.map(async (faixa: any, faixaIndex: number) => {
      faixasValores.push({
        faixa: faixa,
        lotes: [],
        cor: colors[faixaIndex]
      })
      await polygons.lista.map(async (quadra: any) => {
        await quadra.lotes.map((lote: any, loteIndex: any) => {
          if (lote.dadosUau.valorAVista > faixa[0] && lote.dadosUau.valorAVista < faixa[1]) {
            faixasValores[faixaIndex].lotes.push(lote);
            lote.faixaValor = {
              faixa: faixa,
              cor: colors[faixaIndex]
            }
          }
        })
      })
    })
    await setLocalData(polygons)
    return faixasValores
  }
  async function getCategoria() {
    const categoriasDesc: Array<string> = [];
    const categorias: any = [];
    const colors: any = ["#ff0000", "#FF5721", "#FF9021", "#FFB821", "#FFFC21", "#ABFF21", "#4DFF21", "#21FF5A", "#21FFC2", "#21EBFF", "#2161FF", "#a621ff", "#f821ff", "#8f2a86", "#292253", "#225353", "#22532a", "#4e5322", "#8f4f4f", "#709645"]
    let color = 0;
    await polygons.lista.map((quadra: any, quadraIndex: number) => {
      quadra.lotes.map((lote: any, loteIndex: any) => {
        if (!categoriasDesc.find((Item: any) => Item === lote.dadosUau.Categoria_de_preco) && lote.dadosUau.Categoria_de_preco !== null) {
          categoriasDesc.push(lote.dadosUau.Categoria_de_preco)
          categorias.push({
            Categoria_de_preco: lote.dadosUau.Categoria_de_preco,
            corCategoria: colors[color]
          })
          color++
        }
        if (!categoriasDesc.find((Item: any) => Item === "Sem Categoria") && lote.dadosUau.Categoria_de_preco === null) {
          categoriasDesc.push("Sem Categoria")
          categorias.push({
            Categoria_de_preco: "Sem Categoria",
            corCategoria: "#D7D5D2" 
          })
        }
      })
    })
    await polygons.lista.map((quadra: any) => {
      quadra.lotes.map((lote: any) => {
        if(lote.dadosUau.Categoria_de_preco === null) { lote.dadosUau.Categoria_de_preco = "Sem Categoria" }
        categorias.map((categoria: any) => {
          if (categoria.Categoria_de_preco === lote.dadosUau.Categoria_de_preco) {
            lote['corCategoria'] = categoria.corCategoria
          }
        })
      })
    })
    await setLocalData(polygons);
    return categorias
  }
  async function getQuadras() {
    const quadras: any = [];
    polygons.lista.map((quadra: any) => {
      quadras.push({
        quadra: quadra.quadra,
        center: quadra.center
      })
    })
    return quadras
  }

  return (
    <Styled.MainContent>
      {/* OPÇÕES */}
      <Opcoes
        getExibirLegenda={getExibirLegenda}
        setExibirLegenda={setExibirLegenda}
        getLegendaOption={getLegendaOption}
        setLegendaOption={setLegendaOption}
        getTileLayer={getTileLayer}
        setTileLayer={setTileLayer}
        Image={Image}
        getListaPickerQuadra={getListaPickerQuadra}
        setConsumer={setConsumer}
        optionsToChoose={optionsToChoose}
        Icon={Icon}
        tiposInadimplencia={polygons?.tiposInadimplencia}
        getListaFaixa={getListaFaixa}
        getListaCategoria={getListaCategoria}
        Calcularcenario={Calcularcenario}
        columnsData={columnsData}
        visao={visao}
        statusData={statusData}
      />
      {/* SLIDER */}
      {true && <Styled.SliderContent>
        <CircularSlider
          min={0}
          max={360}
          direction={1}
          knobPosition="top"
          appendToValue="°"
          labelFontSize="7px"
          labelColor="transparent"
          valueFontSize="1rem"
          knobSize={20}
          width={70}
          label="ROTAÇÃO"
          onChange={(value: number) => setRotate([true, value])}
        />
      </Styled.SliderContent>}
      {/* PRINT */}
      <Styled.ReactToPrintContent>
        <ReactToPrint
          trigger={() => <button className="cenario" style={{ width: "40px", height: "40px", background: "yellow", border: "none", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", outline: "none", cursor: "pointer" }}><Icon icon="print" size={20} /></button>}
          content={() => componentRef.current}
        />
      </Styled.ReactToPrintContent>
      {/* REFRESH-DATA */}
      <Styled.RefreshData>
        <button onClick={() => getRefreshData ? RemoveRefreshData() : RefreshData()} className="cenario" style={{ width: "40px", height: "40px", background: getRefreshData ? "#47aa2e" : "#e63b3b", border: "none", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", outline: "none", cursor: "pointer", color: "white" }}><Icon icon="refresh" size={20} /></button>
      </Styled.RefreshData>
      {/* MAPA */}
      {polygons &&
        <Map
          getConsumer={getConsumer}
          MyComponent={MyComponent}
          setConsumer={setConsumer}
          setRotate={setRotate}
          getRotate={getRotate}
          polygons={polygons}
          setPolygons={setListaDeMarcacoes}
          areas={areas}
          getTileLayer={getTileLayer}
          componentRef={componentRef}
          localRefresh={localRefresh}
          setLocalRefresh={setLocalRefresh}
          refresh={refresh}
          setRefresh={setRefresh}
          visao={visao}
          getCenter={getCenter}
          getMarkersSize={getMarkersSize}
          getRuaSize={getRuaSize}
          getLocalData={getLocalData}
        />}
    </Styled.MainContent>
  );
}

export default App;