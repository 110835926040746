import React, { useState, useEffect } from 'react';
import json from '../../Auditoria/index.json';
import api from '../../Services/api';
import { Objeto } from '../../../../../Services/Models';
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import moment from 'moment';
import {
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  GridComponent,
  Page,
  Inject,
  Filter,
  Group,
  ExcelExport,
  PdfExport,
  Toolbar,
  ToolbarItems,
  Grid,
  FilterSettingsModel
} from '@syncfusion/ej2-react-grids';
import './style.css'

L10n.load(json)

function App() {
  const [getProjectData, setProjectData] = useState();
  const [getData, setData] = useState<Array<Objeto.ResumoData>>([]);
  const [getLoading, setLoading] = useState<boolean>(true)
  let preTotalValue: number = 0;
  let preData: Array<Objeto.ResumoData> = [];
  const filterOptions: FilterSettingsModel = {
    ignoreAccent: true,
    immediateModeDelay: 200,
    mode: "Immediate",
    type: 'Excel'
  }
  let grid: Grid | null;
  const toolbarOptions: ToolbarItems[] = ['PdfExport']
  const toolbarClick = (args: any) => {
    if (grid) {
      if (args.item.id === 'grid_pdfexport') {
        grid.pdfExport({
          fileName: `Vendas De Cessão ${moment(new Date(), true).format("YYYY-MM-DD")}.pdf`,
          pageOrientation: "Landscape",
          header: {
            fromTop: 0,
            height: 130,
            contents: [
              // {
              //   type: 'Text',
              //   value: `${getItemPickerCentroDeCusto?.descricao}`,
              //   position: { x: 390, y: 50 },
              //   style: { textBrushColor: "#000000", fontSize: 20, hAlign: "Right" },
              // },
              // {
              //   position: { x: 0, y: 45 },
              //   size: { height: 40, width: 80 },
              //   src: image,
              //   type: 'Image',
              // },
              {
                type: 'Text',
                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:mm:ss")}`,
                position: { x: 880, y: 55 },
                style: { textBrushColor: "#000000", fontSize: 13 },
              },
            ]
          },
          footer: {
            contents: [
              {
                type: 'Text',
                value: `Powered by DigitalDEV`,
                position: { x: 0, y: 55 },
                style: { textBrushColor: "#000000", fontSize: 10 },
              },
              {
                format: 'Página {$current} de {$total}',
                pageNumberType: "Numeric",
                position: { x: 910, y: 50 },
                style: {
                  fontSize: 13,
                  hAlign: "Right",
                  textBrushColor: '#000000',
                },
                type: 'PageNumber',
              }
            ],
            fromBottom: 0,
            height: 100,
          },
          theme: {
            header: {
              bold: true,
              fontColor: "#000000",
              fontSize: 8,
              // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
            },
            record: {
              bold: false,
              fontColor: "#000000",
              fontSize: 8,
              // font: new PdfTrueTypeFont(adventProFont, 7)
            },
            caption: {
              bold: true,
              fontColor: "#000000",
              fontSize: 8,
              // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
            }
          },
          allowHorizontalOverflow: false,

        });
      }
    }
  }

  useEffect(() => {
    async function componentDidMount() {
      await api.get('/Resumo').then(async response => {
        await response.data.map((objeto: Objeto.ResumoData, index: number) => {
          preData.push(
            {
              empObra: objeto.empObra,
              VLR_VENDA: objeto.VLR_VENDA,
              TOTAL_CESSAO: objeto.TOTAL_CESSAO,
              TOTAL_DISTRATO: objeto.TOTAL_DISTRATO,
              TOTAL_DEPOSITO: objeto.TOTAL_DEPOSITO,
              CONTAS_RECEBER: objeto.CONTAS_RECEBER
            }
          )
        })
        await setProjectData(response.data)
      })

      await setData(preData)
      await setLoading(false)
    }
    componentDidMount();
  }, [])

  return (
    <>
      <div style={{ maxWidth: '100%' }}>
        <GridComponent
          dataSource={getData}
          id='grid'
          ref={g => grid = g}
          allowPaging={true}
          allowFiltering={true}
          pageSettings={{ pageSizes: [10, 20, 30], pageSize: 10 }}
          filterSettings={filterOptions}
          toolbarClick={toolbarClick}
          toolbar={toolbarOptions}
          allowPdfExport={true}
          style={{opacity:getLoading === true ? "0.5" : "1", pointerEvents:getLoading === true ? "none" : "auto"}}>
          <ColumnsDirective>
            <ColumnDirective field='empObra' headerText='empObra' width='100' />
            <ColumnDirective field='VLR_VENDA' headerText='VLR_VENDA' format="C2" width='100' />
            <ColumnDirective field='TOTAL_CESSAO' headerText='TOTAL_CESSAO' format="C2" width='100' />
          </ColumnsDirective>
          <Inject services={[Page, Filter, Group, ExcelExport, PdfExport, Toolbar, Aggregate]} />
        </GridComponent>
      </div>
    </>
  );
}

export default App;