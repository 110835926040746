import React, { useEffect, useState } from 'react';
import ReactNotification from 'react-notifications-component';

import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';

const App: React.FC = () => {
  useEffect(() => {
    async function componentDidMount() {
      document.title = `${window.location.href.includes("onmoney") ? "OnMoney Plus": "OnePlus"} ${document.title}`;
    }
    componentDidMount();
  }, []);
  return (
    <div style={{ height: "100%" }}>
      <ReactNotification />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
};

export default App;