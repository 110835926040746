import React, { useEffect, useState } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { lighten } from 'polished';
import Lottie from 'react-lottie';
import { Button, Card, MenuItem, Icon, Collapse, Dialog, Classes, Radio, Drawer, Switch } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { MultiSelect, Select } from "@blueprintjs/select";
import { DateInput, TimePicker, TimePrecision  } from "@blueprintjs/datetime";
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { extend } from '@syncfusion/ej2-base';
import * as GridComponent from '@syncfusion/ej2-react-grids';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-kanban";
import { addClass, removeClass, Browser } from '@syncfusion/ej2-base';
import { ToolbarSettingsModel, FileManagerSettingsModel, QuickToolbarSettingsModel } from '@syncfusion/ej2-react-richtexteditor';
import CodeMirror from 'codemirror';
import { createElement } from '@syncfusion/ej2-base';

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import { TiposComponentes } from '../../../Data/Listas';
import { FunilDeVendas, Formulario, SalaDeVendas, Areas, Logon, ControleDeSala, Cargo } from '../../../Services/Controllers';
import { Objeto } from '../../../Services/Models';
import { CPF, Telefone } from '../../../Themes/TextFormat';
import { NotificacaoInterna } from '../../../Services/Notification';
import * as LottieFiles from '../../../Data/Lottie';
import * as TextFormat from '../../../Themes/TextFormat';

import './App.css';
import * as Component from './style';
import viewModel from './viewModel';
import Fila from '../../../Assets/Images/Icone/fila.png';
import TerryLogo from '../../../Assets/Images/Icone/terry.png';
import History from "../../../Routers";

loadCldr(
    require('../../../../node_modules/cldr-data/main/pt/currencies.json'),
    require('../../../../node_modules/cldr-data/main/pt/numbers.json'),
    require('../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
    require('../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
    require('../../../../node_modules/cldr-data/supplemental/numberingSystems.json'));
setCulture('pt');
setCurrencyCode("BRL");
L10n.load({
    'pt': {
        'kanban': {
            'items': 'itens',
            'min': 'Min',
            'max': 'Max',
            'cardsSelected': 'Cards selecionados',
            'addTitle': 'adicionar titulo',
            'editTitle': 'editar titulo',
            'deleteTitle': 'detelar titulo',
            'deleteContent': 'deletar conteudo',
            'save': 'salvar',
            'delete': 'deletar',
            'cancel': 'cancelar',
            'yes': 'Sim',
            'no': 'Não',
            'close': 'Fechar',
            'noCard': 'sem card',
            'unassigned': 'sem assinatura'
        }
    }
});

var fonteDeDados: any; 

let info: Object[];

interface KanbanDataModel {
    Id?: string;
    Title?: string;
    Status?: string;
    Summary?: string;
    Type?: string;
    Priority?: string;
    Tags?: string;
    Estimate?: number;
    Assignee?: string;
    RankId?: number;
    Color?: string;
}

interface IDialogExampleState {
    autoFocus: boolean;
    canEscapeKeyClose: boolean;
    canOutsideClickClose: boolean;
    enforceFocus: boolean;
    isOpen: boolean;
    usePortal: boolean;
}

interface FunilDeVenda {
   id: number;
   posicoesDoFunil: Array<{id: number, descricao: string}>;
   descricao: string;
   hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>;
   listaDeLeads: {kanbanData: Array<Objeto.LeadKanban>};
}

interface FluxoDeSala {
    id: number;
    descricao: string; 
    fluxoDeSala: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}>;
    salasDeVenda: Array<{ id: number, descricao: string }>;
    listaDeLeads: {kanbanData: Array<Objeto.LeadKanban>};
}

const View: React.FC<viewModel> = (viewModel) => {

    /* #region  Variáveis  */
    const PropriedadesDeCalendario = {
        months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    };
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const [getFormularioCPF, setFormularioCPF] = useState<string>("");
    const [getFormularioEmails, setFormularioEmails] = useState<Array<Objeto.Email>>([{classificacao: 1, descricao: ""}]);
    const [getFormularioNome, setFormularioNome] = useState<string>("");

    const [getTelefones, setTelefones] = useState<Array<{descricao: string}>>([{descricao: ""}]);
    const [getListaMeioDeContato, setListaMeioDeContato] = useState<Array<{id: number, descricao: string}>>([
        {
            id: 0,
            descricao: "Email",
        },
        {
            id: 1,
            descricao: "Telefone",
        }
    ]);

    const [getFormularioMeioDeContato, setFormularioMeioDeContato] = useState<{id: number, descricao: string}>({
        id: 1,
        descricao: "Telefone"
    });

    const [getFormularioPrevisaoDeInicio, setFormularioPrevisaoDeInicio] = useState<Date>(moment(new Date()).subtract(1, "day").toDate());
    const [getFormularioPrevisaoDeTermino, setFormularioPrevisaoDeTermino] = useState<Date>(moment(new Date()).subtract(0, "day").toDate());

    const [getTiposDeComponentesFormulario, setTiposDeComponenesFormulario] = useState<Array<{ id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>>(TiposComponentes);

    const [getFormularioInicial, setFormularioInicial] = useState<Array<{id: number, descricao: string, perguntas: Array<{id: number, titulo: string, classificacao: {id: number, descricao:string}, obrigatorio: boolean, resposta: string | null}>}>>([]);

    const [getSelectCustomComponent, setSelectCustomComponent] = useState<{id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>();

    const [getListaDeLeads, setListaDeLeads] = useState<{kanbanData: Array<Objeto.LeadKanban>}>({kanbanData: []});

    const [getIdDoFunil, setIdDoFunil] = useState<number | null>(null);
    const [getIdDoFluxoDeSala, setIdDoFluxoDeSala] = useState<number | null>(null);
    const [getNomeDoFunil, setNomeDoFunil] = useState<string>("");
    const [getNomeDoFluxoDeSala, setNomeDoFluxoDeSala] = useState<string>("");

    const [getListaDeUsuariosDaEquipe, setListaDeUsuariosDaEquipe] = useState<Array<{ usuario : {id: number, nome: string, cargo: {id: number, nome: string}, email: {classificacao: number, descricao: string}}, salaDeVenda: {id: number, descricao: string}, controleDeSala: Objeto.ControleDeSala}>>([]);

    const [getListaDeUsuariosDaEquipeGrid, setListaDeUsuariosDaEquipeGrid] = useState<Array<{nome: string, cargoDescricao: string, salaDeVendaDescricao: string, emailDescricao: string, usuario : {id: number, nome: string, cargo: {id: number, nome: string}, email: {classificacao: number, descricao: string}}, salaDeVenda: {id: number, descricao: string}, controleDeSala: Objeto.ControleDeSala}>>([]);
    const [getUsuarioSelecionadoDaEquipe, setUsuarioSelecionadoDaEquipe] = useState<{nome: string, cargoDescricao: string, salaDeVendaDescricao: string, emailDescricao: string, usuario : {id: number, nome: string, cargo: {id: number, nome: string}, email: {classificacao: number, descricao: string}}, salaDeVenda: {id: number, descricao: string}, controleDeSala: Objeto.ControleDeSala} | undefined>();

    const [getListaPickerUsuariosEmpresa, setListaPickerUsuariosEmpresa] = useState<Array<{id: number, nome: string}>>([]);
    const [getItemPickerUsuariosEmpresa, setItemPickerUsuariosEmpresa] = useState<{id: number, nome: string}>();

    const [getListaFasesDoFunil, setListaFasesDoFunil] = useState<Array<{id: number, descricao: string}>>([]);
    const [getListaFasesDoFluxoDeSala, setListaFasesDoFluxoDeSala] = useState<Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }}>>([]);
    const [getItemFunil, setItemFunil] = useState<string>("");
    const [getItemFluxoDeSala, setItemFluxoDeSala] = useState<string>("");
    const [getAtualizandoFunil, setAtualizandoFunil] = useState<boolean>(false);
    const [getAtualizandoFluxoDeSala, setAtualizandoFluxoDeSala] = useState<boolean>(false);
    const [getItemFunilSelecionado, setItemFunilSelecionado] = useState<{id: number, descricao: string}>();
    const [getItemFluxoDeSalaSelecionado, setItemFluxoDeSalaSelecionado] = useState<{etapa: number, descricao: string, acao: {id: number, descricao: string }}>();

    const PickerSalaDeVendas = Select.ofType<{id: number, descricao: string}>();
    const PickerDepartamentos = Select.ofType<{id: number, descricao: string}>();
    const PickerUsuarios = Select.ofType<any>();
    const PickerAcoes = Select.ofType<{id: number, descricao: string}>();
    const PickerCargos = MultiSelect.ofType<{id: number, nome: string}>();
    const PickerTelas = Select.ofType<{id: number, descricao: string}>();

    const [getListaDeCargos, setListaDeCargos] = useState<Array<{id: number, nome: string}>>([]);
    
    const [getListaDeAcoes, setListaDeAcoes] = useState<Array<{id: number, descricao: string, fluxoDeSala?: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}> | null, salasDeVenda?: Array<{ id: number, descricao: string }> | null}>>([]);
    
    const [getListaDeTelas, setListaDeTelas] = useState<Array<{id: number, descricao: string}>>([
        {
            id: 1,
            descricao: 'Venda direta'
        }
    ]);

    const [getItensSelecionadosPickerDeCargos, setItensSelecionadosPickerDeCargos] = useState<Array<{id: number, nome: string}>>([]);
    const [getItemPickerListaDeAcoes, setItemPickerListaDeAcoes] = useState<{id: number, descricao: string, fluxoDeSala?: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: string, nome: string}>}> | null, salasDeVenda?: Array<{ id: number, descricao: string }> | null} | undefined>(undefined);
    const [getItemPickerListaDeTelas, setItemPickerListaDeTelas] = useState<{id: number, descricao: string} | undefined>(undefined);

    const [getListaPickerSalasDeVendasSelecionadas, setListaPickerSalasDeVendasSelecionadas] = useState<Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>,  hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>, listaAreas: Array<{id: number, descricao: string}>}>>([]);
    const [getListaPickerSalasDeVendas, setListaPickerSalasDeVendas] = useState<Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>, listaAreas: Array<{id: number, descricao: string}>}>>([
        {
            id: 0,
            descricao: "Amazona",
            hierarquiaDoFunil: [],
            areas: [],
            listaAreas: []
        },
        {
            id: 1,
            descricao: "Porto de Galinhas",
            hierarquiaDoFunil: [],
            areas: [],
            listaAreas: []
        }
    ]);
    const [getItemPickerSalaDeVendas, setItemPickerSalaDeVendas] = useState<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>();    
    
    const [getListaPickerSalasDeVendasUsuarios, setListaPickerSalasDeVendasUsuarios] = useState<Array<{id: number, descricao: string}>>([]);
    const [getItemPickerSalaDeVendasUsuarios, setItemPickerSalaDeVendasUsuarios] = useState<{id: number, descricao: string}>();

    const [getListaPickerDepartamentosSelecionados, setListaPickerDepartamentoselecionados] = useState<Array<{id: number, descricao: string}>>([]);
    
    const [getListaPickerDepartamentos, setListaPickerDepartamentos] = useState<Array<{id: number, descricao: string}>>([]);
    const [getItemPickerDepartamentos, setItemPickerDepartamentos] = useState<{id: number, descricao: string}>();
    
    const [getListaPickerDepartamentosUsuarios, setListaPickerDepartamentosUsuarios] = useState<Array<{id: number, descricao: string}>>([]);
    const [getItemPickerDepartamentosUsuarios, setItemPickerDepartamentosUsuarios] = useState<{id: number, descricao: string}>();

    const [getListaDeFunisDeVendas, setListaDeFunisDeVendas] = useState<Array<FunilDeVenda>>([]);

    const [getListaDeFluxosDeSala, setListaDeFluxosDeSala] = useState<Array<FluxoDeSala>>([]);

    const [getFormularioNomeDoFluxo, setFormularioNomeDoFluxo] = useState<string>("");

    const [getCarregandoListaDeContratos, setCarregandoListaDeContratos] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getCollapse, setCollapse] = useState(false);

    const [getSalvarFunil, setSalvarFunil] = useState<boolean>(false);
    const [getSalvarFluxoDeSala, setSalvarFluxoDeSala] = useState<boolean>(false);
    const [getAlterarItemFunil, setAlterarItemFunil] = useState<boolean>(false);
    const [getAlterarItemFluxoDeSala, setAlterarItemFluxoDeSala] = useState<boolean>(false);
    const [getDragStart, setDragStart] = useState(false);

    const [getCollapseListaDeSalas, setCollapseListaDeSalas] = useState<boolean>(false);
    const [getCollapseListaDeDepartamentos, setCollapseListaDeDepartamentos] = useState<boolean>(false);

    const [getOptionCustomizedFields, setOptionCustomizedFields] = useState(false);
    const [getOptionFluxo, setOptionFluxo] = useState(false);
    const [getOptionFormulario, setOptionFormulario] = useState(false);
    const [getOptionFases, setOptionFases] = useState(true);
    const [getOptionMembros, setOptionMembros] = useState(false);
    const [getOptionGeral, setOptionGeral] = useState(false);
    /* #endregion */

    let data: Object[] = extend([], (getListaDeLeads as { [key: string]: Object }).kanbanData, true) as Object[];

    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
       **/
    async function Buscar() {

    }

    let kanbanObj: any;

    const [getDialogState, setDialogState] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDialogCreateFase, setDialogCreateFase] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDialogCustomComponent, setDialogCustomComponent] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDrawerCreateFunilDeVenda, setDrawerCreateFunilDeVenda] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDrawerCreateNewUser, setDrawerCreateNewUser] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpen = () => setDialogState({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleClose = () => setDialogState({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleDialogCreateFaseOpen = () => setDialogCreateFase({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCreateFaseClose = () => setDialogCreateFase({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleDialogCustomComponentOpen = () => setDialogCustomComponent({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCustomComponentClose = () => setDialogCustomComponent({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleDrawerCreateFunilDeVendaOpen = () => setDrawerCreateFunilDeVenda({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDrawerCreateFunilDeVendaClose = () => setDrawerCreateFunilDeVenda({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleDrawerCreateNewUserOpen = () => setDrawerCreateNewUser({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDrawerCreateNewUserClose = () => setDrawerCreateNewUser({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** DELETAR DADOS DA PERMISSÃO DE ACESSO  */}
    const [getDialogDeletarPermissaoUsuario, setDialogDeletarPermissaoUsuario] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarPermissoaUsuario = () => setDialogDeletarPermissaoUsuario({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarPermissaoUsuario = () => setDialogDeletarPermissaoUsuario({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    var rteObj: any;

    var hostUrl: string = 'https://ej2-aspcore-service.azurewebsites.net/';

    var items: string[] = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', 'SuperScript', 'SubScript', '|',
      'CreateTable', 'CreateLink', 'Image', 'FileManager', '|', 'ClearFormat', 'Print',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
    ];
  
    var fileManagerSettings: FileManagerSettingsModel = {
      enable: true,
      path: '/Pictures/Food',
      ajaxSettings: {
        url: hostUrl + 'api/FileManager/FileOperations',
        getImageUrl: hostUrl + 'api/FileManager/GetImage',
        uploadUrl: hostUrl + 'api/FileManager/Upload',
        downloadUrl: hostUrl + 'api/FileManager/Download'
      }
    }

    var quickToolbarSettings: QuickToolbarSettingsModel = {
      table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles']
    }

    var toolbarSettings: ToolbarSettingsModel = {
      items: items
    };

    var textArea: any;
    var myCodeMirror: any;

    const mirrorConversion = (e?: any) => {
      textArea = rteObj.contentModule.getEditPanel() as HTMLTextAreaElement;
      let id: string = (rteObj as any).getID() + 'mirror-view';
      let mirrorView: HTMLElement = rteObj.element.querySelector('#' + id) as HTMLElement;
      let charCount: HTMLElement = rteObj.element.querySelector('.e-rte-character-count') as HTMLElement;
      if (e.targetItem === 'Preview') {
        textArea.style.display = 'block';
        mirrorView.style.display = 'none';
        textArea.innerHTML = myCodeMirror.getValue();
        charCount.style.display = 'block';
      } else {
        if (!mirrorView) {
          mirrorView = createElement('div', { className: 'e-content' });
          mirrorView.id = id;
          textArea.parentNode.appendChild(mirrorView);
        } else {
          mirrorView.innerHTML = '';
        }
        textArea.style.display = 'none';
        mirrorView.style.display = 'block';
        renderCodeMirror(mirrorView, (rteObj as any).value);
        charCount.style.display = 'none';
      }
    }

    const renderCodeMirror = (mirrorView: HTMLElement, content: string) => {
      myCodeMirror = CodeMirror(mirrorView, {
        value: content,
        lineNumbers: true,
        mode: 'text/html',
        lineWrapping: true,
  
      });
    }

    const handleFullScreen = (e: any) => {
      let sbCntEle: any = document.querySelector('.sb-content.e-view');
      let sbHdrEle: any = document.querySelector('.sb-header.e-view');
      let leftBar: any;
      let transformElement: any;
      if (Browser.isDevice) {
        leftBar = document.querySelector('#right-sidebar');
        transformElement = document.querySelector('.sample-browser.e-view.e-content-animation');
      } else {
        leftBar = document.querySelector('#left-sidebar');
        transformElement = document.querySelector('#right-pane');
      }
      if (e.targetItem === 'Maximize') {
        if (Browser.isDevice && Browser.isIos) {
          addClass([sbCntEle, sbHdrEle], ['hide-header']);
        }
        addClass([leftBar], ['e-close']);
        removeClass([leftBar], ['e-open']);
        if (!Browser.isDevice) { transformElement.style.marginLeft = '0px'; }
        transformElement.style.transform = 'inherit';
      } else if (e.targetItem === 'Minimize') {
        if (Browser.isDevice && Browser.isIos) {
          removeClass([sbCntEle, sbHdrEle], ['hide-header']);
        }
        removeClass([leftBar], ['e-close']);
        if (!Browser.isDevice) {
          addClass([leftBar], ['e-open']);
          transformElement.style.marginLeft = leftBar.offsetWidth + 'px';
        }
        transformElement.style.transform = 'translateX(0px)';
      }
    }

    const actionCompleteHandler = (e: any) => {
      if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
        (rteObj.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display = 'none';
        mirrorConversion(e);
      } else {
        setTimeout(() => { (rteObj as any).toolbarModule.refreshToolbarOverflow(); }, 400);
      }
    }

    async function ValidarFluxoDeSala(): Promise<Boolean> {
        if(getFormularioNomeDoFluxo == '')
        {
            NotificacaoInterna.ExibirNotificacao("Nome do fluxo de sala não foi preenchido", "Preencha o nome do fluxo de sala", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if(getListaPickerSalasDeVendasSelecionadas.length == 0)
        {
            NotificacaoInterna.ExibirNotificacao("Sala de venda não foi selecionada", "Selecione ao menos uma sala de vendas", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        return true;
    }

    useEffect(() => {
        async function componentDidMount() {
            await setCarregandoListaDeContratos(false, async () => {

                let ResponseUsuarios = (await Logon.Usuarios(ContextSignIn.getContext().token, "")).data ?? [];

                let ResponseCargos = (await Cargo.Get(ContextSignIn.getContext().token)).data ?? [];
    
                let ResponseAreas = (await Areas.Get(ContextSignIn.getContext().token)).data ?? [];

                let ResponseAcoes = (await ControleDeSala.Acoes(ContextSignIn.getContext().token)).data ?? [];

                console.log(ResponseAcoes);

                let ResponseControleDeSala = (await ControleDeSala.Get(ContextSignIn.getContext().token, "", false)).data ?? [];

                let ListaDeControlesDeSala = [] as Array<FluxoDeSala>;

                ResponseControleDeSala.map((item, index) => {
                    ListaDeControlesDeSala.push({
                        id: item.id,
                        descricao: item.descricao,
                        fluxoDeSala: item.fluxoDeSala,
                        salasDeVenda: item.salasDeVenda,
                        listaDeLeads: {kanbanData: []}
                    })
                });

                ListaDeControlesDeSala.map((item, index) => {
                    item.fluxoDeSala.map((Item, Index) => {
                        item.listaDeLeads.kanbanData.push(
                            {
                                id: (13 + item.listaDeLeads.kanbanData.length),
                                cpf: "51123762449",
                                nome: "Fulano A",
                                statusKanban: `${Item.descricao}`,
                                summary: "...",
                                tags: "Contactar",
                                color: "#E64A19",
                                className: "e-story, e-low, e-nancy-davloio",
                                dataDeNascimento: new Date("1970-06-02T00:00:00"),
                                idade: undefined,
                                nacionalidade: undefined,
                                sexo: undefined,
                                emails: [
                                    {
                                        classificacao: 1,
                                        descricao: "josiedson@cardial.br"
                                    },
                                    {
                                        classificacao: 1,
                                        descricao: "josiedson@cardial.br"
                                    }
                                ],
                                fotoDoLead: null,
                                documentoPessoal: null,
                                rg: null,
                                estadoCivil: undefined,
                                documentoDeEstadoCivil: null,
                                regimeDeBens: undefined,
                                ocupacao: undefined,
                                renda: undefined,
                                dadosDosVeiculos: undefined,
                                dependentes: null,
                                endereco: null,
                                documentoEndereco: null,
                                telefones: [
                                    {
                                        classificacao: 1,
                                        ddi: "55",
                                        ddd: "81",
                                        numero: "991595874",
                                        observacao: ""
                                    },
                                    {
                                        classificacao: 1,
                                        ddi: "55",
                                        ddd: "81",
                                        numero: "991595874",
                                        observacao: ""
                                    }
                                ],
                                localDeCaptacao: "SITE",
                                status: 0,
                                observacoes: [
                                    {
                                        nome: "Washington Souza",
                                        data: new Date("2021-07-14T00:00:00"),
                                        descricao: "observacao numero 01"
                                    },
                                    {
                                        nome: "Izacc Moreira",
                                        data: new Date("2021-07-14T00:00:00"),
                                        descricao: "observacao numero 01"
                                    },
                                    {
                                        nome: "Calixto",
                                        data: new Date("2021-07-14T00:00:00"),
                                        descricao: "observacao numero 01"
                                    },
                                    {
                                        nome: "Hugo Moreira",
                                        data: new Date("2021-07-14T00:00:00"),
                                        descricao: "observacao numero 01"
                                    },
                                    {
                                        nome: "Lucas Moreira Assis",
                                        data: new Date("2021-07-14T00:00:00"),
                                        descricao: "observacao numero 01"
                                    }
                                ],
                                responsavel: undefined,
                                posicaoDoFunil: {
                                    id: 3,
                                    descricao: "Identificação do interesse"
                                },
                                atividades: [
                                    {
                                      classificacaoDaAtividade: {
                                        id: 1,
                                        descricao: "Email"
                                      },
                                      posicaoDoFunil: {
                                        id: 1,
                                        descricao: "Sem contato"
                                      },
                                      situacaoDaAtividade: {
                                        id: 1,
                                        descricao: "Encerrada"
                                      },
                                      executor: {
                                        id: 1,
                                        cpf: null,
                                        nome: "Washington dos Santos",
                                        natureza: 0,
                                        dataDeNascimento: null,
                                        emails: null,
                                        documentoPessoal: null,
                                        rg: null,
                                        creci: null,
                                        estadoCivil: null,
                                        documentoDeEstadoCivil: null,
                                        regimeDeBens: null,
                                        ocupacao: null,
                                        necessarioAssinaturaDoConjuge: false,
                                        conjuge: null,
                                        endereco: null,
                                        documentoEndereco: null,
                                        telefones: null,
                                        observacao: null
                                      },
                                      dataDoEvento: "2021-06-11T00:00:00-03:00",
                                      descricao: "Enviado email de boas vindas"
                                    },
                                    {
                                      classificacaoDaAtividade: {
                                        id: 2,
                                        descricao: "Tarefa"
                                      },
                                      posicaoDoFunil: {
                                        id: 1,
                                        descricao: "Sem contato"
                                      },
                                      situacaoDaAtividade: {
                                        id: 1,
                                        descricao: "Encerrada"
                                      },
                                      executor: {
                                        id: 2,
                                        cpf: null,
                                        nome: "Izacc Moreira",
                                        natureza: 0,
                                        dataDeNascimento: null,
                                        emails: null,
                                        documentoPessoal: null,
                                        rg: null,
                                        creci: null,
                                        estadoCivil: null,
                                        documentoDeEstadoCivil: null,
                                        regimeDeBens: null,
                                        ocupacao: null,
                                        necessarioAssinaturaDoConjuge: false,
                                        conjuge: null,
                                        endereco: null,
                                        documentoEndereco: null,
                                        telefones: null,
                                        observacao: null
                                      },
                                      dataDoEvento: "2021-07-01T00:00:00-03:00",
                                      descricao: "Tentativa de contato sem sucesso"
                                    },
                                    {
                                      classificacaoDaAtividade: {
                                        id: 2,
                                        descricao: "Tarefa"
                                      },
                                      posicaoDoFunil: {
                                        id: 2,
                                        descricao: "Contato feito"
                                      },
                                      situacaoDaAtividade: {
                                        id: 1,
                                        descricao: "Encerrada"
                                      },
                                      executor: {
                                        "id": 2,
                                        "cpf": null,
                                        "nome": "Izacc Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                      },
                                      "dataDoEvento": "2021-07-21T00:00:00-03:00",
                                      "descricao": "Contato com sucesso, cliente agendado para o dia 11/07/2021"
                                    },
                                    {
                                      "classificacaoDaAtividade": {
                                        "id": 2,
                                        "descricao": "Tarefa"
                                      },
                                      "posicaoDoFunil": {
                                        "id": 3,
                                        "descricao": "Identificação do interesse"
                                      },
                                      "situacaoDaAtividade": {
                                        "id": 0,
                                        "descricao": "Aberta"
                                      },
                                      "executor": {
                                        "id": 3,
                                        "cpf": null,
                                        "nome": "Hugo Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                      },
                                      "dataDoEvento": "2021-07-16T00:00:00-03:00",
                                      "descricao": "Tentativa de contato sem sucesso"
                                    }
                                ],
                                "tarefas": [
                                    {
                                      "prioridade": {
                                        "id": 2,
                                        "descricao": "Média"
                                      },
                                      "solicitante": null,
                                      "executor": {
                                        "id": 2,
                                        "cpf": null,
                                        "nome": "Izacc Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                      },
                                      "descricao": "Ligar para o lead",
                                      "previsaoDeInicio": "2021-07-01T00:00:00-03:00",
                                      "previsaoDeTermino": "2021-07-01T00:00:00-03:00",
                                      "dataDeTermino": "2021-07-01T00:00:00-03:00",
                                      "dataCancelamento": null,
                                      "posicaoDoFunil": {
                                        "id": 1,
                                        "descricao": "Sem contato"
                                      }
                                    },
                                    {
                                      "prioridade": {
                                        "id": 2,
                                        "descricao": "Média"
                                      },
                                      "solicitante": null,
                                      "executor": {
                                        "id": 2,
                                        "cpf": null,
                                        "nome": "Izacc Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                      },
                                      "descricao": "Contato com sucesso, cliente agendado para o dia 21/07/2021",
                                      "previsaoDeInicio": "2021-07-11T00:00:00-03:00",
                                      "previsaoDeTermino": "2021-07-11T00:00:00-03:00",
                                      "dataDeTermino": "2021-07-11T00:00:00-03:00",
                                      "dataCancelamento": null,
                                      "posicaoDoFunil": {
                                        "id": 2,
                                        "descricao": "Contato feito"
                                      }
                                    },
                                    {
                                      "prioridade": {
                                        "id": 1,
                                        "descricao": "Alta"
                                      },
                                      "solicitante": {
                                        "id": 2,
                                        "cpf": null,
                                        "nome": "Izacc Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                      },
                                      "executor": {
                                        "id": 3,
                                        "cpf": null,
                                        "nome": "Hugo Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                      },
                                      "descricao": "Tentativa de contato sem sucesso",
                                      "previsaoDeInicio": "2021-07-16T00:00:00-03:00",
                                      "previsaoDeTermino": "2021-07-16T00:00:00-03:00",
                                      "dataDeTermino": null,
                                      "dataCancelamento": null,
                                      "posicaoDoFunil": {
                                        "id": 3,
                                        "descricao": "Identificação do interesse"
                                      }
                                    }
                                ],
                                anotacoes: [],
                                listaEmails: []
                            },
                            {
                                id: (14 + item.listaDeLeads.kanbanData.length),
                                cpf: "51123762449",
                                nome: "Fulano B",
                                statusKanban: `${Item.descricao}`,
                                summary: "...",
                                tags: "Contactar",
                                color: "#E6AAAA",
                                className: "e-story, e-low, e-nancy-davloio",
                                dataDeNascimento: new Date("1970-06-02T00:00:00"),
                                idade: undefined,
                                nacionalidade: undefined,
                                sexo: undefined,
                                emails: [
                                    {
                                        classificacao: 1,
                                        descricao: "josiedson@cardial.br"
                                    },
                                    {
                                        classificacao: 1,
                                        descricao: "josiedson@cardial.br"
                                    }
                                ],
                                fotoDoLead: null,
                                documentoPessoal: null,
                                rg: null,
                                estadoCivil: undefined,
                                documentoDeEstadoCivil: null,
                                regimeDeBens: undefined,
                                ocupacao: undefined,
                                renda: undefined,
                                dadosDosVeiculos: undefined,
                                dependentes: null,
                                endereco: null,
                                documentoEndereco: null,
                                telefones: [
                                    {
                                        classificacao: 1,
                                        ddi: "55",
                                        ddd: "81",
                                        numero: "991595874",
                                        observacao: ""
                                    },
                                    {
                                        classificacao: 1,
                                        ddi: "55",
                                        ddd: "81",
                                        numero: "991595874",
                                        observacao: ""
                                    }
                                ],
                                localDeCaptacao: "SITE",
                                status: 0,
                                observacoes: [
                                    {
                                        nome: "Washington Souza",
                                        data: new Date("2021-07-14T00:00:00"),
                                        descricao: "observacao numero 01"
                                    },
                                    {
                                        nome: "Izacc Moreira",
                                        data: new Date("2021-07-14T00:00:00"),
                                        descricao: "observacao numero 01"
                                    },
                                    {
                                        nome: "Calixto",
                                        data: new Date("2021-07-14T00:00:00"),
                                        descricao: "observacao numero 01"
                                    },
                                    {
                                        nome: "Hugo Moreira",
                                        data: new Date("2021-07-14T00:00:00"),
                                        descricao: "observacao numero 01"
                                    },
                                    {
                                        nome: "Lucas Moreira Assis",
                                        data: new Date("2021-07-14T00:00:00"),
                                        descricao: "observacao numero 01"
                                    }
                                ],
                                responsavel: undefined,
                                posicaoDoFunil: {
                                    id: 3,
                                    descricao: "Identificação do interesse"
                                },
                                atividades: [
                                    {
                                    classificacaoDaAtividade: {
                                        id: 1,
                                        descricao: "Email"
                                    },
                                    posicaoDoFunil: {
                                        id: 1,
                                        descricao: "Sem contato"
                                    },
                                    situacaoDaAtividade: {
                                        id: 1,
                                        descricao: "Encerrada"
                                    },
                                    executor: {
                                        id: 1,
                                        cpf: null,
                                        nome: "Washington dos Santos",
                                        natureza: 0,
                                        dataDeNascimento: null,
                                        emails: null,
                                        documentoPessoal: null,
                                        rg: null,
                                        creci: null,
                                        estadoCivil: null,
                                        documentoDeEstadoCivil: null,
                                        regimeDeBens: null,
                                        ocupacao: null,
                                        necessarioAssinaturaDoConjuge: false,
                                        conjuge: null,
                                        endereco: null,
                                        documentoEndereco: null,
                                        telefones: null,
                                        observacao: null
                                    },
                                    dataDoEvento: "2021-06-11T00:00:00-03:00",
                                    descricao: "Enviado email de boas vindas"
                                    },
                                    {
                                    classificacaoDaAtividade: {
                                        id: 2,
                                        descricao: "Tarefa"
                                    },
                                    posicaoDoFunil: {
                                        id: 1,
                                        descricao: "Sem contato"
                                    },
                                    situacaoDaAtividade: {
                                        id: 1,
                                        descricao: "Encerrada"
                                    },
                                    executor: {
                                        id: 2,
                                        cpf: null,
                                        nome: "Izacc Moreira",
                                        natureza: 0,
                                        dataDeNascimento: null,
                                        emails: null,
                                        documentoPessoal: null,
                                        rg: null,
                                        creci: null,
                                        estadoCivil: null,
                                        documentoDeEstadoCivil: null,
                                        regimeDeBens: null,
                                        ocupacao: null,
                                        necessarioAssinaturaDoConjuge: false,
                                        conjuge: null,
                                        endereco: null,
                                        documentoEndereco: null,
                                        telefones: null,
                                        observacao: null
                                    },
                                    dataDoEvento: "2021-07-01T00:00:00-03:00",
                                    descricao: "Tentativa de contato sem sucesso"
                                    },
                                    {
                                    classificacaoDaAtividade: {
                                        id: 2,
                                        descricao: "Tarefa"
                                    },
                                    posicaoDoFunil: {
                                        id: 2,
                                        descricao: "Contato feito"
                                    },
                                    situacaoDaAtividade: {
                                        id: 1,
                                        descricao: "Encerrada"
                                    },
                                    executor: {
                                        "id": 2,
                                        "cpf": null,
                                        "nome": "Izacc Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                    },
                                    "dataDoEvento": "2021-07-21T00:00:00-03:00",
                                    "descricao": "Contato com sucesso, cliente agendado para o dia 11/07/2021"
                                    },
                                    {
                                    "classificacaoDaAtividade": {
                                        "id": 2,
                                        "descricao": "Tarefa"
                                    },
                                    "posicaoDoFunil": {
                                        "id": 3,
                                        "descricao": "Identificação do interesse"
                                    },
                                    "situacaoDaAtividade": {
                                        "id": 0,
                                        "descricao": "Aberta"
                                    },
                                    "executor": {
                                        "id": 3,
                                        "cpf": null,
                                        "nome": "Hugo Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                    },
                                    "dataDoEvento": "2021-07-16T00:00:00-03:00",
                                    "descricao": "Tentativa de contato sem sucesso"
                                    }
                                ],
                                "tarefas": [
                                    {
                                    "prioridade": {
                                        "id": 2,
                                        "descricao": "Média"
                                    },
                                    "solicitante": null,
                                    "executor": {
                                        "id": 2,
                                        "cpf": null,
                                        "nome": "Izacc Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                    },
                                    "descricao": "Ligar para o lead",
                                    "previsaoDeInicio": "2021-07-01T00:00:00-03:00",
                                    "previsaoDeTermino": "2021-07-01T00:00:00-03:00",
                                    "dataDeTermino": "2021-07-01T00:00:00-03:00",
                                    "dataCancelamento": null,
                                    "posicaoDoFunil": {
                                        "id": 1,
                                        "descricao": "Sem contato"
                                    }
                                    },
                                    {
                                    "prioridade": {
                                        "id": 2,
                                        "descricao": "Média"
                                    },
                                    "solicitante": null,
                                    "executor": {
                                        "id": 2,
                                        "cpf": null,
                                        "nome": "Izacc Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                    },
                                    "descricao": "Contato com sucesso, cliente agendado para o dia 21/07/2021",
                                    "previsaoDeInicio": "2021-07-11T00:00:00-03:00",
                                    "previsaoDeTermino": "2021-07-11T00:00:00-03:00",
                                    "dataDeTermino": "2021-07-11T00:00:00-03:00",
                                    "dataCancelamento": null,
                                    "posicaoDoFunil": {
                                        "id": 2,
                                        "descricao": "Contato feito"
                                    }
                                    },
                                    {
                                    "prioridade": {
                                        "id": 1,
                                        "descricao": "Alta"
                                    },
                                    "solicitante": {
                                        "id": 2,
                                        "cpf": null,
                                        "nome": "Izacc Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                    },
                                    "executor": {
                                        "id": 3,
                                        "cpf": null,
                                        "nome": "Hugo Moreira",
                                        "natureza": 0,
                                        "dataDeNascimento": null,
                                        "emails": null,
                                        "documentoPessoal": null,
                                        "rg": null,
                                        "creci": null,
                                        "estadoCivil": null,
                                        "documentoDeEstadoCivil": null,
                                        "regimeDeBens": null,
                                        "ocupacao": null,
                                        "necessarioAssinaturaDoConjuge": false,
                                        "conjuge": null,
                                        "endereco": null,
                                        "documentoEndereco": null,
                                        "telefones": null,
                                        "observacao": null
                                    },
                                    "descricao": "Tentativa de contato sem sucesso",
                                    "previsaoDeInicio": "2021-07-16T00:00:00-03:00",
                                    "previsaoDeTermino": "2021-07-16T00:00:00-03:00",
                                    "dataDeTermino": null,
                                    "dataCancelamento": null,
                                    "posicaoDoFunil": {
                                        "id": 3,
                                        "descricao": "Identificação do interesse"
                                    }
                                    }
                                ],
                                anotacoes: [],
                                listaEmails: []
                            }
                        )
                    })
                });

                setListaDeAcoes(ResponseAcoes);

                setListaDeCargos(ResponseCargos);

                setListaPickerUsuariosEmpresa(ResponseUsuarios);

                setListaDeFluxosDeSala(ListaDeControlesDeSala);

                console.log(ListaDeControlesDeSala)

                let ResponseSalas = await SalaDeVendas.Get(ContextSignIn.getContext().token)

                let ListaDeSalas = [] as Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>, listaAreas: Array<{id: number, descricao: string}>}>

                if (Math.floor(ResponseSalas.status / 100) == 2)
                {
                    ResponseSalas.data.map((item) => {
                        ListaDeSalas.push({
                            id: item.id,
                            descricao: item.descricao,
                            areas: [],
                            hierarquiaDoFunil: [],
                            listaAreas: ResponseAreas,
                        })
                    })
        
                    setListaPickerSalasDeVendas(ListaDeSalas);
                    setCarregandoListaDeContratos(true, () => { });
                }
                else
                {
                    setCarregandoListaDeContratos(undefined, () => { });
                    NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista do controle de sala", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                    return;
                }
            });
        }
        componentDidMount();
    }, []);

    return (
        <Component.Container>

            {/* Drawer fluxo de sala */}
            <Drawer
                style = {{ width: "25%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"people"}
                onClose = { async () => {
                    
                    let ListaPickerDeSalaDeVendasSelecionadas = [...getListaPickerSalasDeVendasSelecionadas]

                    let ListaPickerDeSalasDeVendas = [...getListaPickerSalasDeVendas]

                    ListaPickerDeSalaDeVendasSelecionadas.map((sala, salaIndex) => {
                        ListaPickerDeSalasDeVendas.push(sala)
                    })

                    let ListaOrdenadaSalas = ListaPickerDeSalasDeVendas.sort(function compare(a, b) {
                        if (a.id < b.id) {
                        return -1;
                        }
                        if (a.id > b.id) {
                        return 1;
                        }
                        return 0;
                    })

                    setListaPickerSalasDeVendas(ListaOrdenadaSalas);
                    setListaPickerSalasDeVendasSelecionadas([]);
                    setListaPickerDepartamentoselecionados([]);
                    setFormularioNomeDoFluxo("");
                    setCollapseListaDeSalas(false);
                    setCollapseListaDeDepartamentos(false);
                    handleDrawerCreateFunilDeVendaClose();
                }}
                title = {"Novo fluxo de sala"}
                autoFocus = {true}
                isOpen = {getDrawerCreateFunilDeVenda.isOpen}
                canEscapeKeyClose = {getDrawerCreateFunilDeVenda.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerCreateFunilDeVenda.canOutsideClickClose}
                enforceFocus = {getDrawerCreateFunilDeVenda.enforceFocus}
                usePortal = {getDrawerCreateFunilDeVenda.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>
                    
                    <div
                        style = {{
                            width: '100%', 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>
                                    
                                    <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Nome do fluxo de sala`}</div>
                                            <Component.Input style = {{width: "calc(20vw + 15px)"}} id = {`Nome`} placeholder="Digite aqui o nome do fluxo de sala..." value={getFormularioNomeDoFluxo}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setFormularioNomeDoFluxo(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>
                                        <>
                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                <div
                                                    style = {{
                                                        borderBottom: getCollapseListaDeSalas == true ? "0px" : '1px solid #00000020',
                                                        borderTop: '1px solid #00000020', 
                                                        borderLeft: '1px solid #00000020', 
                                                        borderRight: '1px solid #00000020',
                                                        borderBottomLeftRadius: getCollapseListaDeSalas == true ? "0px" : "5px", 
                                                        borderBottomRightRadius: getCollapseListaDeSalas == true ? "0px" : "5px", 
                                                        borderTopLeftRadius: "5px", 
                                                        borderTopRightRadius: "5px",
                                                        backgroundColor: "#FFFFFF",
                                                        height: "42px",
                                                        marginTop: "10px", 
                                                        display: "flex", 
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        width: "calc(20vw + 15px)",
                                                        cursor: "pointer"
                                                    }}>
                                                    <div style = {{display: "flex", alignItems: "center", width: "85%"}}
                                                        onClick = { async () => {
                                                            if (getCollapseListaDeSalas == true)
                                                            {
                                                                setCollapseListaDeSalas(false)
                                                            }
                                                            else
                                                            {
                                                                setCollapseListaDeSalas(true)
                                                            }
                                                    }}>
                                                        <div style={{height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapseListaDeSalas === true ? setCollapseListaDeSalas(false) : setCollapseListaDeSalas(true) }>
                                                            <Lottie
                                                                options={{
                                                                    loop: true,
                                                                    autoplay: true,
                                                                    animationData: LottieFiles.SwipeDown,
                                                                    rendererSettings: {
                                                                        preserveAspectRatio: "xMidYMid slice"
                                                                    }
                                                                }}
                                                                height={30}
                                                                width={30}
                                                                style={getCollapseListaDeSalas ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                                                            />
                                                        </div>
                                                        <Button
                                                            style = {{backgroundColor: "#FFFFFF", boxShadow: "none", border: "none", verticalAlign: "center", fontSize: "12px"}}
                                                            text = {"Salas"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse isOpen = {getCollapseListaDeSalas}>
                                                <div 
                                                    style = {{
                                                        borderBottom: '1px solid #00000020',
                                                        borderLeft: '1px solid #00000020', 
                                                        borderRight: '1px solid #00000020',
                                                        borderBottomLeftRadius: "5px", 
                                                        borderBottomRightRadius: "5px", 
                                                        backgroundColor: "#FFFFFF", 
                                                        display: "flex", 
                                                        flexDirection: "column", 
                                                        alignItems: "center",
                                                        padding: "8.5px", 
                                                        marginTop: "0px",
                                                        width: "calc(20vw + 15px)"
                                                }}>

                                                    <div style = {{marginTop: "20px", paddingLeft: (getListaPickerSalasDeVendasSelecionadas.length > 0 || getListaPickerSalasDeVendas.length > 0) ? "20px" : "0px"}}>
                                                        
                                                        {getListaPickerSalasDeVendasSelecionadas.map((item: {id: number, descricao: string, areas: Array<{id: number, descricao: string}>, listaAreas: Array<{id: number, descricao: string}>}, index: number) => (
                                                            <>
                                                                <div key = {item.id} style = {{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                                                                    <div
                                                                        key = {item.id}
                                                                        style = {{
                                                                            border: "1px dotted #00000050", 
                                                                            borderRadius: "5px", 
                                                                            height: "42px", 
                                                                            backgroundColor: "#FFFFFF",
                                                                            display: "flex", 
                                                                            alignItems: "center",
                                                                            width: "calc(19vw)",
                                                                            cursor: "pointer",
                                                                            marginRight: "20px"
                                                                        }}>
                                                                        <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                                                            <div style = {{display: "flex", alignItems: "center", width: "90%"}} onClick = {() => {}}>
                                                                                <div style = {{width: "20px", height: "20px", borderRadius: "20px", border: "0.4px solid #225", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "10px", marginRight: "10px", backgroundColor: "#FFFFFF90"}}>
                                                                                    <div style = {{fontWeight: "bolder", fontSize: "9px", textAlign: "center", verticalAlign: "middle"}}>{TextFormat.Numero.FormatarTextoParaInteiro(item.id + 1)}</div>
                                                                                </div>
                                                                                <div style = {{fontWeight: "bold", fontSize: "12px"}}>{item.descricao}</div>
                                                                            </div>
                                                                            <Icon icon = 'remove' iconSize = {15} color = {"#000"} style = {{marginRight: "5px"}} onClick = {async () => {
                                                                                
                                                                                let ListaPickerDeSalaDeVendasSelecionadas = [...getListaPickerSalasDeVendasSelecionadas]

                                                                                let ListaFiltrada = ListaPickerDeSalaDeVendasSelecionadas.filter((IItem, IIndex) => IItem.descricao != item.descricao)

                                                                                let ListaPickerDeSalasDeVendas = [...getListaPickerSalasDeVendas]

                                                                                ListaPickerDeSalasDeVendas.push({
                                                                                    id: item.id,
                                                                                    descricao: item.descricao,
                                                                                    areas: item.areas,
                                                                                    hierarquiaDoFunil: [],
                                                                                    listaAreas: getListaPickerDepartamentos
                                                                                })

                                                                                let ListaOrdenada = ListaPickerDeSalasDeVendas.sort(function compare(a, b) {
                                                                                    if (a.id < b.id) {
                                                                                    return -1;
                                                                                    }
                                                                                    if (a.id > b.id) {
                                                                                    return 1;
                                                                                    }
                                                                                    return 0;
                                                                                })

                                                                                setListaPickerSalasDeVendasSelecionadas(ListaFiltrada)

                                                                                setListaPickerSalasDeVendas(ListaOrdenada)
                                                                            }}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}

                                                        {getListaPickerSalasDeVendasSelecionadas.length == 0 && <div
                                                            style = {{
                                                                fontSize: "12px",
                                                                border: "1px dotted #00000050", 
                                                                borderRadius: "5px", 
                                                                height: "42px", 
                                                                backgroundColor: "#D3658230",
                                                                display: "flex", 
                                                                alignItems: "center", 
                                                                justifyContent: "center", 
                                                                width: "calc(19vw)",
                                                                cursor: "pointer",
                                                                fontWeight: "bolder"
                                                        }}>{'Não há salas no momento'}</div>}

                                                        <div style = {{display: "flex", flexDirection: "column"}}>
                                                            <div style = {{fontWeight: "bolder", fontSize: "12px", marginTop: "10px", marginBottom: "10px"}}>Lista de salas</div>
                                                            {getListaPickerSalasDeVendas.map((item: {id: number, descricao: string, areas: Array<{id: number, descricao: string}>, listaAreas: Array<{id: number, descricao: string}>}, index: number) => (
                                                                <>
                                                                    <div key = {item.id} style = {{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                                                                        <div
                                                                            key = {item.id}
                                                                            style = {{
                                                                                border: "1px dotted #00000050", 
                                                                                borderRadius: "5px", 
                                                                                height: "42px", 
                                                                                backgroundColor: "#FFFFFF",
                                                                                display: "flex", 
                                                                                alignItems: "center",
                                                                                width: "calc(19vw)",
                                                                                cursor: "pointer",
                                                                                marginRight: "20px"
                                                                            }}>
                                                                            <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                                                                <div style = {{display: "flex", alignItems: "center", width: "90%"}} onClick = {() => {}}>
                                                                                    <div style = {{width: "20px", height: "20px", borderRadius: "20px", border: "0.4px solid #225", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "10px", marginRight: "10px", backgroundColor: "#FFFFFF90"}}>
                                                                                        <div style = {{fontWeight: "bolder", fontSize: "9px", textAlign: "center", verticalAlign: "middle"}}>{TextFormat.Numero.FormatarTextoParaInteiro(item.id + 1)}</div>
                                                                                    </div>
                                                                                    <div style = {{fontWeight: "bold", fontSize: "12px"}}>{item.descricao}</div>
                                                                                </div>
                                                                                <Icon icon = 'add' iconSize = {15} color = {"#000"} style = {{marginRight: "5px"}} onClick = {async () => {

                                                                                    if (getListaPickerSalasDeVendasSelecionadas.filter((IItem, IIndex) => IItem.descricao == item.descricao).length == 0)
                                                                                    {

                                                                                        let ListaPickerSalasDeVendasSelecionadas = [...getListaPickerSalasDeVendasSelecionadas];

                                                                                        ListaPickerSalasDeVendasSelecionadas.push({
                                                                                            id: item.id,
                                                                                            descricao: item.descricao,
                                                                                            hierarquiaDoFunil: [],
                                                                                            areas: item.areas,
                                                                                            listaAreas: item.listaAreas
                                                                                        })

                                                                                        let ListaPickerSalaDeVendas = [...getListaPickerSalasDeVendas];

                                                                                        let ListaFiltrada = ListaPickerSalaDeVendas.filter((IItem, IIndex) => IItem.descricao != item.descricao)

                                                                                        setListaPickerSalasDeVendasSelecionadas(ListaPickerSalasDeVendasSelecionadas)

                                                                                        setListaPickerSalasDeVendas(ListaFiltrada)
                                                                                    }
                                                                                    else
                                                                                    {

                                                                                        NotificacaoInterna.ExibirNotificacao("Sala já foi incluida", "Selecione outra sala.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                                                        
                                                                                    }

                                                                                }}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>

                                                    </div>

                                                </div>
                                            </Collapse>
                                        </>
                                    </Component.ContentFormHeader>

                                    <div style = {{marginTop: "20px", marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                        <Button
                                            icon = {<Icon icon = "add" iconSize = {14} color = {"#FFFFFF"}/>} 
                                            style = {{
                                                background: "#1098F7", 
                                                color: "#FFFFFF", 
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px", 
                                                outline: "none",
                                                width: "80%",
                                                display: "flex", 
                                                alignItems: "center", 
                                                fontSize: "12px", 
                                                height: "40px", 
                                                marginRight: '10px',
                                            }}  
                                            onClick = { async () => {
                                                if (await ValidarFluxoDeSala() == true)
                                                {

                                                    NotificacaoInterna.ExibirNotificacao("O novo fluxo de sala esta sendo cadastrado", "Aguarde enquanto o fluxo está sendo preenchido.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                    let Salas = [] as Array<{id: number, descricao: string}>;

                                                    let Fluxo = {} as {id: number, descricao: string, fluxoDeSala: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}>, salasDeVenda: Array<{ id: number, descricao: string }>};

                                                    getListaPickerSalasDeVendasSelecionadas.map((sala, salaIndex) => {
                                                        Salas.push({
                                                            id: sala.id,
                                                            descricao: sala.descricao
                                                        })
                                                    });

                                                    Fluxo = {
                                                        id: 0,
                                                        descricao: getFormularioNomeDoFluxo,
                                                        fluxoDeSala: [
                                                            {
                                                                etapa: 0,
                                                                descricao: "Recepção",
                                                                acao: {
                                                                    id: getListaDeAcoes[0].id,
                                                                    descricao: getListaDeAcoes[0].descricao
                                                                },
                                                                cargosVinculados: []
                                                            }
                                                        ],
                                                        salasDeVenda: Salas
                                                    };

                                                    let Response = await ControleDeSala.Post(ContextSignIn.getContext().token, Fluxo)

                                                    if (Math.floor(Response.status / 100) == 2)
                                                    {

                                                        getListaDeFluxosDeSala.push({
                                                            id: Response.data.id,
                                                            descricao: Response.data.descricao,
                                                            fluxoDeSala: Response.data.fluxoDeSala,
                                                            salasDeVenda: Response.data.salasDeVenda,
                                                            listaDeLeads: {kanbanData: [
                                                                {
                                                                    id: 13,
                                                                    cpf: "51123762449",
                                                                    nome: "Fulano A",
                                                                    statusKanban: `${getItemFluxoDeSala}`,
                                                                    summary: "...",
                                                                    tags: "Contactar",
                                                                    color: "#E64A19",
                                                                    className: "e-story, e-low, e-nancy-davloio",
                                                                    dataDeNascimento: new Date("1970-06-02T00:00:00"),
                                                                    idade: undefined,
                                                                    nacionalidade: undefined,
                                                                    sexo: undefined,
                                                                    emails: [
                                                                        {
                                                                            classificacao: 1,
                                                                            descricao: "josiedson@cardial.br"
                                                                        },
                                                                        {
                                                                            classificacao: 1,
                                                                            descricao: "josiedson@cardial.br"
                                                                        }
                                                                    ],
                                                                    fotoDoLead: null,
                                                                    documentoPessoal: null,
                                                                    rg: null,
                                                                    estadoCivil: undefined,
                                                                    documentoDeEstadoCivil: null,
                                                                    regimeDeBens: undefined,
                                                                    ocupacao: undefined,
                                                                    renda: undefined,
                                                                    dadosDosVeiculos: undefined,
                                                                    dependentes: null,
                                                                    endereco: null,
                                                                    documentoEndereco: null,
                                                                    telefones: [
                                                                        {
                                                                            classificacao: 1,
                                                                            ddi: "55",
                                                                            ddd: "81",
                                                                            numero: "991595874",
                                                                            observacao: ""
                                                                        },
                                                                        {
                                                                            classificacao: 1,
                                                                            ddi: "55",
                                                                            ddd: "81",
                                                                            numero: "991595874",
                                                                            observacao: ""
                                                                        }
                                                                    ],
                                                                    localDeCaptacao: "SITE",
                                                                    status: 0,
                                                                    observacoes: [
                                                                        {
                                                                            nome: "Washington Souza",
                                                                            data: new Date("2021-07-14T00:00:00"),
                                                                            descricao: "observacao numero 01"
                                                                        },
                                                                        {
                                                                            nome: "Izacc Moreira",
                                                                            data: new Date("2021-07-14T00:00:00"),
                                                                            descricao: "observacao numero 01"
                                                                        },
                                                                        {
                                                                            nome: "Calixto",
                                                                            data: new Date("2021-07-14T00:00:00"),
                                                                            descricao: "observacao numero 01"
                                                                        },
                                                                        {
                                                                            nome: "Hugo Moreira",
                                                                            data: new Date("2021-07-14T00:00:00"),
                                                                            descricao: "observacao numero 01"
                                                                        },
                                                                        {
                                                                            nome: "Lucas Moreira Assis",
                                                                            data: new Date("2021-07-14T00:00:00"),
                                                                            descricao: "observacao numero 01"
                                                                        }
                                                                    ],
                                                                    responsavel: undefined,
                                                                    posicaoDoFunil: {
                                                                        id: 3,
                                                                        descricao: "Identificação do interesse"
                                                                    },
                                                                    atividades: [
                                                                        {
                                                                          classificacaoDaAtividade: {
                                                                            id: 1,
                                                                            descricao: "Email"
                                                                          },
                                                                          posicaoDoFunil: {
                                                                            id: 1,
                                                                            descricao: "Sem contato"
                                                                          },
                                                                          situacaoDaAtividade: {
                                                                            id: 1,
                                                                            descricao: "Encerrada"
                                                                          },
                                                                          executor: {
                                                                            id: 1,
                                                                            cpf: null,
                                                                            nome: "Washington dos Santos",
                                                                            natureza: 0,
                                                                            dataDeNascimento: null,
                                                                            emails: null,
                                                                            documentoPessoal: null,
                                                                            rg: null,
                                                                            creci: null,
                                                                            estadoCivil: null,
                                                                            documentoDeEstadoCivil: null,
                                                                            regimeDeBens: null,
                                                                            ocupacao: null,
                                                                            necessarioAssinaturaDoConjuge: false,
                                                                            conjuge: null,
                                                                            endereco: null,
                                                                            documentoEndereco: null,
                                                                            telefones: null,
                                                                            observacao: null
                                                                          },
                                                                          dataDoEvento: "2021-06-11T00:00:00-03:00",
                                                                          descricao: "Enviado email de boas vindas"
                                                                        },
                                                                        {
                                                                          classificacaoDaAtividade: {
                                                                            id: 2,
                                                                            descricao: "Tarefa"
                                                                          },
                                                                          posicaoDoFunil: {
                                                                            id: 1,
                                                                            descricao: "Sem contato"
                                                                          },
                                                                          situacaoDaAtividade: {
                                                                            id: 1,
                                                                            descricao: "Encerrada"
                                                                          },
                                                                          executor: {
                                                                            id: 2,
                                                                            cpf: null,
                                                                            nome: "Izacc Moreira",
                                                                            natureza: 0,
                                                                            dataDeNascimento: null,
                                                                            emails: null,
                                                                            documentoPessoal: null,
                                                                            rg: null,
                                                                            creci: null,
                                                                            estadoCivil: null,
                                                                            documentoDeEstadoCivil: null,
                                                                            regimeDeBens: null,
                                                                            ocupacao: null,
                                                                            necessarioAssinaturaDoConjuge: false,
                                                                            conjuge: null,
                                                                            endereco: null,
                                                                            documentoEndereco: null,
                                                                            telefones: null,
                                                                            observacao: null
                                                                          },
                                                                          dataDoEvento: "2021-07-01T00:00:00-03:00",
                                                                          descricao: "Tentativa de contato sem sucesso"
                                                                        },
                                                                        {
                                                                          classificacaoDaAtividade: {
                                                                            id: 2,
                                                                            descricao: "Tarefa"
                                                                          },
                                                                          posicaoDoFunil: {
                                                                            id: 2,
                                                                            descricao: "Contato feito"
                                                                          },
                                                                          situacaoDaAtividade: {
                                                                            id: 1,
                                                                            descricao: "Encerrada"
                                                                          },
                                                                          executor: {
                                                                            "id": 2,
                                                                            "cpf": null,
                                                                            "nome": "Izacc Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                          },
                                                                          "dataDoEvento": "2021-07-21T00:00:00-03:00",
                                                                          "descricao": "Contato com sucesso, cliente agendado para o dia 11/07/2021"
                                                                        },
                                                                        {
                                                                          "classificacaoDaAtividade": {
                                                                            "id": 2,
                                                                            "descricao": "Tarefa"
                                                                          },
                                                                          "posicaoDoFunil": {
                                                                            "id": 3,
                                                                            "descricao": "Identificação do interesse"
                                                                          },
                                                                          "situacaoDaAtividade": {
                                                                            "id": 0,
                                                                            "descricao": "Aberta"
                                                                          },
                                                                          "executor": {
                                                                            "id": 3,
                                                                            "cpf": null,
                                                                            "nome": "Hugo Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                          },
                                                                          "dataDoEvento": "2021-07-16T00:00:00-03:00",
                                                                          "descricao": "Tentativa de contato sem sucesso"
                                                                        }
                                                                    ],
                                                                    "tarefas": [
                                                                        {
                                                                          "prioridade": {
                                                                            "id": 2,
                                                                            "descricao": "Média"
                                                                          },
                                                                          "solicitante": null,
                                                                          "executor": {
                                                                            "id": 2,
                                                                            "cpf": null,
                                                                            "nome": "Izacc Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                          },
                                                                          "descricao": "Ligar para o lead",
                                                                          "previsaoDeInicio": "2021-07-01T00:00:00-03:00",
                                                                          "previsaoDeTermino": "2021-07-01T00:00:00-03:00",
                                                                          "dataDeTermino": "2021-07-01T00:00:00-03:00",
                                                                          "dataCancelamento": null,
                                                                          "posicaoDoFunil": {
                                                                            "id": 1,
                                                                            "descricao": "Sem contato"
                                                                          }
                                                                        },
                                                                        {
                                                                          "prioridade": {
                                                                            "id": 2,
                                                                            "descricao": "Média"
                                                                          },
                                                                          "solicitante": null,
                                                                          "executor": {
                                                                            "id": 2,
                                                                            "cpf": null,
                                                                            "nome": "Izacc Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                          },
                                                                          "descricao": "Contato com sucesso, cliente agendado para o dia 21/07/2021",
                                                                          "previsaoDeInicio": "2021-07-11T00:00:00-03:00",
                                                                          "previsaoDeTermino": "2021-07-11T00:00:00-03:00",
                                                                          "dataDeTermino": "2021-07-11T00:00:00-03:00",
                                                                          "dataCancelamento": null,
                                                                          "posicaoDoFunil": {
                                                                            "id": 2,
                                                                            "descricao": "Contato feito"
                                                                          }
                                                                        },
                                                                        {
                                                                          "prioridade": {
                                                                            "id": 1,
                                                                            "descricao": "Alta"
                                                                          },
                                                                          "solicitante": {
                                                                            "id": 2,
                                                                            "cpf": null,
                                                                            "nome": "Izacc Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                          },
                                                                          "executor": {
                                                                            "id": 3,
                                                                            "cpf": null,
                                                                            "nome": "Hugo Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                          },
                                                                          "descricao": "Tentativa de contato sem sucesso",
                                                                          "previsaoDeInicio": "2021-07-16T00:00:00-03:00",
                                                                          "previsaoDeTermino": "2021-07-16T00:00:00-03:00",
                                                                          "dataDeTermino": null,
                                                                          "dataCancelamento": null,
                                                                          "posicaoDoFunil": {
                                                                            "id": 3,
                                                                            "descricao": "Identificação do interesse"
                                                                          }
                                                                        }
                                                                    ],
                                                                    anotacoes: [],
                                                                    listaEmails: []
                                                                },
                                                                {
                                                                    id: (14 + 1),
                                                                    cpf: "51123762449",
                                                                    nome: "Fulano B",
                                                                    statusKanban: `${getItemFluxoDeSala}`,
                                                                    summary: "...",
                                                                    tags: "Contactar",
                                                                    color: "#E6AAAA",
                                                                    className: "e-story, e-low, e-nancy-davloio",
                                                                    dataDeNascimento: new Date("1970-06-02T00:00:00"),
                                                                    idade: undefined,
                                                                    nacionalidade: undefined,
                                                                    sexo: undefined,
                                                                    emails: [
                                                                        {
                                                                            classificacao: 1,
                                                                            descricao: "josiedson@cardial.br"
                                                                        },
                                                                        {
                                                                            classificacao: 1,
                                                                            descricao: "josiedson@cardial.br"
                                                                        }
                                                                    ],
                                                                    fotoDoLead: null,
                                                                    documentoPessoal: null,
                                                                    rg: null,
                                                                    estadoCivil: undefined,
                                                                    documentoDeEstadoCivil: null,
                                                                    regimeDeBens: undefined,
                                                                    ocupacao: undefined,
                                                                    renda: undefined,
                                                                    dadosDosVeiculos: undefined,
                                                                    dependentes: null,
                                                                    endereco: null,
                                                                    documentoEndereco: null,
                                                                    telefones: [
                                                                        {
                                                                            classificacao: 1,
                                                                            ddi: "55",
                                                                            ddd: "81",
                                                                            numero: "991595874",
                                                                            observacao: ""
                                                                        },
                                                                        {
                                                                            classificacao: 1,
                                                                            ddi: "55",
                                                                            ddd: "81",
                                                                            numero: "991595874",
                                                                            observacao: ""
                                                                        }
                                                                    ],
                                                                    localDeCaptacao: "SITE",
                                                                    status: 0,
                                                                    observacoes: [
                                                                        {
                                                                            nome: "Washington Souza",
                                                                            data: new Date("2021-07-14T00:00:00"),
                                                                            descricao: "observacao numero 01"
                                                                        },
                                                                        {
                                                                            nome: "Izacc Moreira",
                                                                            data: new Date("2021-07-14T00:00:00"),
                                                                            descricao: "observacao numero 01"
                                                                        },
                                                                        {
                                                                            nome: "Calixto",
                                                                            data: new Date("2021-07-14T00:00:00"),
                                                                            descricao: "observacao numero 01"
                                                                        },
                                                                        {
                                                                            nome: "Hugo Moreira",
                                                                            data: new Date("2021-07-14T00:00:00"),
                                                                            descricao: "observacao numero 01"
                                                                        },
                                                                        {
                                                                            nome: "Lucas Moreira Assis",
                                                                            data: new Date("2021-07-14T00:00:00"),
                                                                            descricao: "observacao numero 01"
                                                                        }
                                                                    ],
                                                                    responsavel: undefined,
                                                                    posicaoDoFunil: {
                                                                        id: 3,
                                                                        descricao: "Identificação do interesse"
                                                                    },
                                                                    atividades: [
                                                                        {
                                                                        classificacaoDaAtividade: {
                                                                            id: 1,
                                                                            descricao: "Email"
                                                                        },
                                                                        posicaoDoFunil: {
                                                                            id: 1,
                                                                            descricao: "Sem contato"
                                                                        },
                                                                        situacaoDaAtividade: {
                                                                            id: 1,
                                                                            descricao: "Encerrada"
                                                                        },
                                                                        executor: {
                                                                            id: 1,
                                                                            cpf: null,
                                                                            nome: "Washington dos Santos",
                                                                            natureza: 0,
                                                                            dataDeNascimento: null,
                                                                            emails: null,
                                                                            documentoPessoal: null,
                                                                            rg: null,
                                                                            creci: null,
                                                                            estadoCivil: null,
                                                                            documentoDeEstadoCivil: null,
                                                                            regimeDeBens: null,
                                                                            ocupacao: null,
                                                                            necessarioAssinaturaDoConjuge: false,
                                                                            conjuge: null,
                                                                            endereco: null,
                                                                            documentoEndereco: null,
                                                                            telefones: null,
                                                                            observacao: null
                                                                        },
                                                                        dataDoEvento: "2021-06-11T00:00:00-03:00",
                                                                        descricao: "Enviado email de boas vindas"
                                                                        },
                                                                        {
                                                                        classificacaoDaAtividade: {
                                                                            id: 2,
                                                                            descricao: "Tarefa"
                                                                        },
                                                                        posicaoDoFunil: {
                                                                            id: 1,
                                                                            descricao: "Sem contato"
                                                                        },
                                                                        situacaoDaAtividade: {
                                                                            id: 1,
                                                                            descricao: "Encerrada"
                                                                        },
                                                                        executor: {
                                                                            id: 2,
                                                                            cpf: null,
                                                                            nome: "Izacc Moreira",
                                                                            natureza: 0,
                                                                            dataDeNascimento: null,
                                                                            emails: null,
                                                                            documentoPessoal: null,
                                                                            rg: null,
                                                                            creci: null,
                                                                            estadoCivil: null,
                                                                            documentoDeEstadoCivil: null,
                                                                            regimeDeBens: null,
                                                                            ocupacao: null,
                                                                            necessarioAssinaturaDoConjuge: false,
                                                                            conjuge: null,
                                                                            endereco: null,
                                                                            documentoEndereco: null,
                                                                            telefones: null,
                                                                            observacao: null
                                                                        },
                                                                        dataDoEvento: "2021-07-01T00:00:00-03:00",
                                                                        descricao: "Tentativa de contato sem sucesso"
                                                                        },
                                                                        {
                                                                        classificacaoDaAtividade: {
                                                                            id: 2,
                                                                            descricao: "Tarefa"
                                                                        },
                                                                        posicaoDoFunil: {
                                                                            id: 2,
                                                                            descricao: "Contato feito"
                                                                        },
                                                                        situacaoDaAtividade: {
                                                                            id: 1,
                                                                            descricao: "Encerrada"
                                                                        },
                                                                        executor: {
                                                                            "id": 2,
                                                                            "cpf": null,
                                                                            "nome": "Izacc Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                        },
                                                                        "dataDoEvento": "2021-07-21T00:00:00-03:00",
                                                                        "descricao": "Contato com sucesso, cliente agendado para o dia 11/07/2021"
                                                                        },
                                                                        {
                                                                        "classificacaoDaAtividade": {
                                                                            "id": 2,
                                                                            "descricao": "Tarefa"
                                                                        },
                                                                        "posicaoDoFunil": {
                                                                            "id": 3,
                                                                            "descricao": "Identificação do interesse"
                                                                        },
                                                                        "situacaoDaAtividade": {
                                                                            "id": 0,
                                                                            "descricao": "Aberta"
                                                                        },
                                                                        "executor": {
                                                                            "id": 3,
                                                                            "cpf": null,
                                                                            "nome": "Hugo Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                        },
                                                                        "dataDoEvento": "2021-07-16T00:00:00-03:00",
                                                                        "descricao": "Tentativa de contato sem sucesso"
                                                                        }
                                                                    ],
                                                                    "tarefas": [
                                                                        {
                                                                        "prioridade": {
                                                                            "id": 2,
                                                                            "descricao": "Média"
                                                                        },
                                                                        "solicitante": null,
                                                                        "executor": {
                                                                            "id": 2,
                                                                            "cpf": null,
                                                                            "nome": "Izacc Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                        },
                                                                        "descricao": "Ligar para o lead",
                                                                        "previsaoDeInicio": "2021-07-01T00:00:00-03:00",
                                                                        "previsaoDeTermino": "2021-07-01T00:00:00-03:00",
                                                                        "dataDeTermino": "2021-07-01T00:00:00-03:00",
                                                                        "dataCancelamento": null,
                                                                        "posicaoDoFunil": {
                                                                            "id": 1,
                                                                            "descricao": "Sem contato"
                                                                        }
                                                                        },
                                                                        {
                                                                        "prioridade": {
                                                                            "id": 2,
                                                                            "descricao": "Média"
                                                                        },
                                                                        "solicitante": null,
                                                                        "executor": {
                                                                            "id": 2,
                                                                            "cpf": null,
                                                                            "nome": "Izacc Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                        },
                                                                        "descricao": "Contato com sucesso, cliente agendado para o dia 21/07/2021",
                                                                        "previsaoDeInicio": "2021-07-11T00:00:00-03:00",
                                                                        "previsaoDeTermino": "2021-07-11T00:00:00-03:00",
                                                                        "dataDeTermino": "2021-07-11T00:00:00-03:00",
                                                                        "dataCancelamento": null,
                                                                        "posicaoDoFunil": {
                                                                            "id": 2,
                                                                            "descricao": "Contato feito"
                                                                        }
                                                                        },
                                                                        {
                                                                        "prioridade": {
                                                                            "id": 1,
                                                                            "descricao": "Alta"
                                                                        },
                                                                        "solicitante": {
                                                                            "id": 2,
                                                                            "cpf": null,
                                                                            "nome": "Izacc Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                        },
                                                                        "executor": {
                                                                            "id": 3,
                                                                            "cpf": null,
                                                                            "nome": "Hugo Moreira",
                                                                            "natureza": 0,
                                                                            "dataDeNascimento": null,
                                                                            "emails": null,
                                                                            "documentoPessoal": null,
                                                                            "rg": null,
                                                                            "creci": null,
                                                                            "estadoCivil": null,
                                                                            "documentoDeEstadoCivil": null,
                                                                            "regimeDeBens": null,
                                                                            "ocupacao": null,
                                                                            "necessarioAssinaturaDoConjuge": false,
                                                                            "conjuge": null,
                                                                            "endereco": null,
                                                                            "documentoEndereco": null,
                                                                            "telefones": null,
                                                                            "observacao": null
                                                                        },
                                                                        "descricao": "Tentativa de contato sem sucesso",
                                                                        "previsaoDeInicio": "2021-07-16T00:00:00-03:00",
                                                                        "previsaoDeTermino": "2021-07-16T00:00:00-03:00",
                                                                        "dataDeTermino": null,
                                                                        "dataCancelamento": null,
                                                                        "posicaoDoFunil": {
                                                                            "id": 3,
                                                                            "descricao": "Identificação do interesse"
                                                                        }
                                                                        }
                                                                    ],
                                                                    anotacoes: [],
                                                                    listaEmails: []
                                                                }
                                                            ]}
                                                        });
    
                                                        setListaFasesDoFluxoDeSala([]);
    
                                                        let ListaPickerDeSalaDeVendasSelecionadas = [...getListaPickerSalasDeVendasSelecionadas];
    
                                                        let ListaPickerDeSalasDeVendas = [...getListaPickerSalasDeVendas];
    
                                                        ListaPickerDeSalaDeVendasSelecionadas.map((sala, salaIndex) => {
                                                            ListaPickerDeSalasDeVendas.push(sala)
                                                        });
    
                                                        let ListaOrdenadaSalas = ListaPickerDeSalasDeVendas.sort(function compare(a, b) {
                                                            if (a.id < b.id) {
                                                            return -1;
                                                            }
                                                            if (a.id > b.id) {
                                                            return 1;
                                                            }
                                                            return 0;
                                                        });
    
                                                        setListaPickerSalasDeVendas(ListaOrdenadaSalas);
                                                        setListaPickerSalasDeVendasSelecionadas([]);
    
                                                        setListaDeFluxosDeSala(getListaDeFluxosDeSala);
    
                                                        NotificacaoInterna.ExibirNotificacao("O fluxo de sala foi cadastrado", "Fluxo de sala cadastrado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
    
                                                        handleDrawerCreateFunilDeVendaClose();

                                                    }
                                                    else
                                                    {
                                                        NotificacaoInterna.ExibirNotificacao("Error ao cadastrar o fluxo de sala", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                    }

                                                }
                                            }}>Criar novo fluxo de sala</Button>
                                    </div>
                                
                                </Component.ContentCard>
                            </div>
                        </div>
                    </div>

                </div>
            </Drawer>

            {/* Drawer adicionar membro a equipe */}
            <Drawer
                style = {{ width: "25%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"people"}
                onClose = { async () => {handleDrawerCreateNewUserClose()}}
                title = {"Novo usuário da equipe"}
                autoFocus = {true}
                isOpen = {getDrawerCreateNewUser.isOpen}
                canEscapeKeyClose = {getDrawerCreateNewUser.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerCreateNewUser.canOutsideClickClose}
                enforceFocus = {getDrawerCreateNewUser.enforceFocus}
                usePortal = {getDrawerCreateNewUser.usePortal}
            >
                
                <div style = {{display: "flex", width: "100%", height: "100%"}}>
                    
                    <div
                        style = {{
                            width: '100%', 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>

                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>

                                    <div style={{background: "0", width: "90%"}}>

                                        <div>
                                            <div style = {{fontWeight: "bolder", fontSize: "12px", marginBottom: "5px"}}>Usuário</div>
                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                <PickerUsuarios popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerUsuariosEmpresa ?? [] as any} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                    itemRenderer={(Item: any) => <MenuItem onClick={async () => { setItemPickerUsuariosEmpresa(Item) }} text={Item.nome} />}
                                                    itemPredicate={(Texto: string, Item: any) => { return Item.nome.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                    <Button style={{ marginBottom: "10px", background: lighten(0.15, '#D3D3D3'), color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "300px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "5px", fontSize: "12px", height: "42px" }} text={getItemPickerUsuariosEmpresa?.nome.substring(0, 20).concat("...") ?? "Selecione..."} rightIcon="double-caret-vertical" />
                                                </PickerUsuarios>
                                                {getItemPickerUsuariosEmpresa && <Icon icon="filter-remove" style={{cursor: "pointer", marginRight: "10px", marginBottom: "10px" }} iconSize={15} onClick={async () => { setItemPickerUsuariosEmpresa(undefined) }} />}
                                            </div>
                                        </div>

                                        <div>
                                            <div style = {{fontWeight: "bolder", fontSize: "12px", marginBottom: "5px"}}>Sala</div>
                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                <PickerSalaDeVendas popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerSalasDeVendasUsuarios ?? [] as Array<{id: number, descricao: string}>} onItemSelect={() => {}} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                    itemRenderer={(Item: {id: number, descricao: string}) => <MenuItem onClick={async () => { setItemPickerSalaDeVendasUsuarios(Item) }} text={Item.descricao} />}
                                                    itemPredicate={(Texto: string, Item: {id: number, descricao: string}) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                    <Button style={{ marginBottom: "10px", background: lighten(0.15, '#D3D3D3'), color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "300px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "5px", fontSize: "12px", height: "42px" }} text={getItemPickerSalaDeVendasUsuarios?.descricao.substring(0, 20).concat("...") ?? "Selecione..."} rightIcon="double-caret-vertical" />
                                                </PickerSalaDeVendas>
                                                {getItemPickerSalaDeVendasUsuarios && <Icon icon="filter-remove" style={{cursor: "pointer", marginRight: "10px", marginBottom: "10px" }} iconSize={15} onClick = { async () => { setItemPickerSalaDeVendasUsuarios(undefined) }} />}
                                            </div>
                                        </div>

                                    </div>

                                </Component.ContentCard>
                            </div>
                        </div>

                        <div style = {{marginTop: "20px", marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button
                                icon = {<Icon icon = "add" iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: "#1098F7", 
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "80%", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: '10px',
                                }}
                                onClick = { async () => {

                                    if (getItemPickerUsuariosEmpresa && getItemPickerSalaDeVendasUsuarios && getIdDoFluxoDeSala)
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Autorizando permissão de acesso no controle de sala", "Aguarde enquanto autorizamos o usuário", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let Response = await ControleDeSala.AutorizarPermissaoDeAcesso(ContextSignIn.getContext().token, getItemPickerSalaDeVendasUsuarios.id, getIdDoFluxoDeSala, getItemPickerUsuariosEmpresa.id)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {
                                            let ResponseEquipe = await Logon.PermissaoDeAcessoPorControleDeSala(ContextSignIn.getContext().token, "", getIdDoFluxoDeSala)

                                            if (Math.floor(ResponseEquipe.status / 100) == 2)
                                            {
                                                let ListaDeUsuarios = [] as Array<{nome: string, cargoDescricao: string, salaDeVendaDescricao: string, emailDescricao: string, usuario : {id: number, nome: string, cargo: {id: number, nome: string}, email: {classificacao: number, descricao: string}}, salaDeVenda: {id: number, descricao: string}, controleDeSala: Objeto.ControleDeSala}>

                                                ResponseEquipe.data.map(item => {
                                                    ListaDeUsuarios.push({
                                                        nome: item.usuario.nome,
                                                        cargoDescricao: item.usuario.cargo ? item.usuario.cargo.nome : 'Sem cargo',
                                                        salaDeVendaDescricao: item.salaDeVenda.descricao,
                                                        emailDescricao: item.usuario.email ? item.usuario.email.descricao : 'Sem email',
                                                        usuario: item.usuario,
                                                        salaDeVenda: item.salaDeVenda,
                                                        controleDeSala: item.controleDeSala
                                                    })
                                                });

                                                setListaDeUsuariosDaEquipeGrid(ListaDeUsuarios);
                                            }
                                            NotificacaoInterna.ExibirNotificacao("Autorização concedida", "O usuário foi autorizado com sucesso", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            handleDrawerCreateNewUserClose();
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao autorizar o usuário", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else
                                    {
                                        if (!getItemPickerUsuariosEmpresa) {
                                            NotificacaoInterna.ExibirNotificacao("Usuário não selecionado", "Informe o usuário", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return;
                                        }
                                        if (!getItemPickerSalaDeVendasUsuarios) {
                                            NotificacaoInterna.ExibirNotificacao("Sala de vendas não selecionada", "Informe a sala de vendas", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                            return;
                                        }
                                    }
                                }}>Adicionar novo usuário</Button>
                        </div>
                    
                    </div>

                </div>
            
            </Drawer>

            {/** Dialog criando nova fase */}
            <Dialog
                style = {{width: '40%', backgroundColor: "#FFFFFF"}}
                icon="people"
                onClose={() => {
                    setItemFluxoDeSala("");
                    setAlterarItemFluxoDeSala(false);
                    setItemFluxoDeSalaSelecionado(undefined);
                    handleDialogCreateFaseClose();
                }}
                title={getAlterarItemFluxoDeSala == false ? "Criando uma nova fase" : "Atualizando a fase"}
                autoFocus = {true}
                isOpen = {getDialogCreateFase.isOpen}
                canEscapeKeyClose = {getDialogCreateFase.canEscapeKeyClose}
                canOutsideClickClose = {getDialogCreateFase.canOutsideClickClose}
                enforceFocus = {getDialogCreateFase.enforceFocus}
                usePortal = {getDialogCreateFase.usePortal} 
            >
                <div style = {{display: "flex", alignItems: "center"}}>
                    <div style = {{marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRight: "0.5px solid #00000050", paddingBottom: "20px"}}>
                        
                        <div style = {{width: "95%", marginTop: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Titulo da fase`}</div>
                            <Component.InputTask style = {{width: "100%"}} id="CustomLabel" placeholder={"Nome da fase..."} value={getItemFluxoDeSala}
                                onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                    var TextoDigitado = event.currentTarget.value;
                                    setItemFluxoDeSala(TextoDigitado);
                                }}>
                            </Component.InputTask>
                        </div>

                        <div style = {{width: "95%", marginTop: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Ações`}</div>
                            <PickerAcoes popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaDeAcoes} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer = {(Item: {id: number, descricao: string, fluxoDeSala?: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: string, nome: string}>}> | null, salasDeVenda?: Array<{ id: number, descricao: string }> | null}) => <MenuItem onClick = { async () => { await setItemPickerListaDeAcoes(Item) }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: {id: number, descricao: string}) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button className="selector" style={{ background: "#f6f7f8", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: getItemPickerListaDeAcoes ? "calc(35vw + 15px)" : "calc(37vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerListaDeAcoes?.descricao ?? "Selecione..."} rightIcon="double-caret-vertical" />
                            </PickerAcoes>
                            {getItemPickerListaDeAcoes && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerListaDeAcoes(undefined); }} />}
                        </div>

                        {getItemPickerListaDeAcoes?.id == 1 && <div style = {{width: "95%", marginTop: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Vincular um cargo`}</div>
                            <PickerCargos popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true, fill: true}} items={getListaDeCargos} onItemSelect={() => { }} noResults={<MenuItem style = {{maxWidth: "16vw"}} disabled={true} text="Sem resultados disponíveis" />}
                            fill={true} tagRenderer={(Item: {id: number, nome: string}) => Item.nome} selectedItems={getItensSelecionadosPickerDeCargos} placeholder={""}
                            tagInputProps = {{
                                onRemove: (value: React.ReactNode, index: number) => {
                                    let ListaDeItensSelecionadosCargos = [...getItensSelecionadosPickerDeCargos];

                                    let ListaFiltrada = ListaDeItensSelecionadosCargos.filter((Cargos, IndexCargos: number) => IndexCargos != index);

                                    setItensSelecionadosPickerDeCargos(ListaFiltrada);
                                },
                                rightElement: <Button icon="cross" minimal={true} 
                                onClick={() => { setItensSelecionadosPickerDeCargos([])}}
                                />
                            }}
                            itemRenderer={(Item: {id: number, nome: string}) => <MenuItem text={Item.nome} onClick = {() => {

                                let ListaDeItensSelecionadosListaCargos = [...getItensSelecionadosPickerDeCargos];

                                let ListaFiltrada = ListaDeItensSelecionadosListaCargos.filter(cargo => cargo.id == Item.id);

                                if (ListaFiltrada.length == 0)
                                {
                                    ListaDeItensSelecionadosListaCargos.push(Item);
                                    setItensSelecionadosPickerDeCargos(ListaDeItensSelecionadosListaCargos);
                                }

                            }}/>} itemPredicate={(Texto: string, Item: {id: number, nome: string}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}/>
                        </div>}

                    </div>
                </div>
                
                <div style = {{ width: "100%", paddingRight: "2.5%", paddingLeft: "2.5%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center", borderTop: "0.5px solid #00000050"}}>
                    <Button
                        style = {{
                            flex: 1,
                            background: getAlterarItemFluxoDeSala == true ? lighten(0.1, "#D74E09") : "#1098F7", 
                            color: "#FFFFFF",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px",
                            height: "15px",
                            marginTop: "20px"
                        }}
                        text = {getAlterarItemFluxoDeSala == true ? "Atualizar" :  "Criar"}
                        onClick = {() => {

                            if (getAlterarItemFluxoDeSala == false && getItemPickerListaDeAcoes)
                            {
                                let ListaDePosicoes = [] as Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }}>

                                getListaDeFluxosDeSala.map((Fluxo, FluxoIndex) => {
                                    if (Fluxo.id == getIdDoFluxoDeSala)
                                    {
                                        ListaDePosicoes = Fluxo.fluxoDeSala
                                    }
                                });

                                if (ListaDePosicoes.filter((item, index) => item.descricao == getItemFluxoDeSala).length == 0)
                                {
                                    getListaDeFluxosDeSala.map((item, index) => {
                                        if (item.id == getIdDoFluxoDeSala)
                                        {
                                            item.fluxoDeSala.push({
                                                etapa: item.fluxoDeSala.length,
                                                descricao: getItemFluxoDeSala,
                                                acao: {
                                                    id: getItemPickerListaDeAcoes?.id,
                                                    descricao: getItemPickerListaDeAcoes?.descricao,
                                                },
                                                cargosVinculados: getItemPickerListaDeAcoes.id == 1 ? getItensSelecionadosPickerDeCargos : []
                                            });

                                            item.listaDeLeads.kanbanData.push(
                                                {
                                                    id: (13 + item.listaDeLeads.kanbanData.length),
                                                    cpf: "51123762449",
                                                    nome: "Fulano A",
                                                    statusKanban: `${getItemFluxoDeSala}`,
                                                    summary: "...",
                                                    tags: "Contactar",
                                                    color: "#E64A19",
                                                    className: "e-story, e-low, e-nancy-davloio",
                                                    dataDeNascimento: new Date("1970-06-02T00:00:00"),
                                                    idade: undefined,
                                                    nacionalidade: undefined,
                                                    sexo: undefined,
                                                    emails: [
                                                        {
                                                            classificacao: 1,
                                                            descricao: "josiedson@cardial.br"
                                                        },
                                                        {
                                                            classificacao: 1,
                                                            descricao: "josiedson@cardial.br"
                                                        }
                                                    ],
                                                    fotoDoLead: null,
                                                    documentoPessoal: null,
                                                    rg: null,
                                                    estadoCivil: undefined,
                                                    documentoDeEstadoCivil: null,
                                                    regimeDeBens: undefined,
                                                    ocupacao: undefined,
                                                    renda: undefined,
                                                    dadosDosVeiculos: undefined,
                                                    dependentes: null,
                                                    endereco: null,
                                                    documentoEndereco: null,
                                                    telefones: [
                                                        {
                                                            classificacao: 1,
                                                            ddi: "55",
                                                            ddd: "81",
                                                            numero: "991595874",
                                                            observacao: ""
                                                        },
                                                        {
                                                            classificacao: 1,
                                                            ddi: "55",
                                                            ddd: "81",
                                                            numero: "991595874",
                                                            observacao: ""
                                                        }
                                                    ],
                                                    localDeCaptacao: "SITE",
                                                    status: 0,
                                                    observacoes: [
                                                        {
                                                            nome: "Washington Souza",
                                                            data: new Date("2021-07-14T00:00:00"),
                                                            descricao: "observacao numero 01"
                                                        },
                                                        {
                                                            nome: "Izacc Moreira",
                                                            data: new Date("2021-07-14T00:00:00"),
                                                            descricao: "observacao numero 01"
                                                        },
                                                        {
                                                            nome: "Calixto",
                                                            data: new Date("2021-07-14T00:00:00"),
                                                            descricao: "observacao numero 01"
                                                        },
                                                        {
                                                            nome: "Hugo Moreira",
                                                            data: new Date("2021-07-14T00:00:00"),
                                                            descricao: "observacao numero 01"
                                                        },
                                                        {
                                                            nome: "Lucas Moreira Assis",
                                                            data: new Date("2021-07-14T00:00:00"),
                                                            descricao: "observacao numero 01"
                                                        }
                                                    ],
                                                    responsavel: undefined,
                                                    posicaoDoFunil: {
                                                        id: 3,
                                                        descricao: "Identificação do interesse"
                                                    },
                                                    atividades: [
                                                        {
                                                          classificacaoDaAtividade: {
                                                            id: 1,
                                                            descricao: "Email"
                                                          },
                                                          posicaoDoFunil: {
                                                            id: 1,
                                                            descricao: "Sem contato"
                                                          },
                                                          situacaoDaAtividade: {
                                                            id: 1,
                                                            descricao: "Encerrada"
                                                          },
                                                          executor: {
                                                            id: 1,
                                                            cpf: null,
                                                            nome: "Washington dos Santos",
                                                            natureza: 0,
                                                            dataDeNascimento: null,
                                                            emails: null,
                                                            documentoPessoal: null,
                                                            rg: null,
                                                            creci: null,
                                                            estadoCivil: null,
                                                            documentoDeEstadoCivil: null,
                                                            regimeDeBens: null,
                                                            ocupacao: null,
                                                            necessarioAssinaturaDoConjuge: false,
                                                            conjuge: null,
                                                            endereco: null,
                                                            documentoEndereco: null,
                                                            telefones: null,
                                                            observacao: null
                                                          },
                                                          dataDoEvento: "2021-06-11T00:00:00-03:00",
                                                          descricao: "Enviado email de boas vindas"
                                                        },
                                                        {
                                                          classificacaoDaAtividade: {
                                                            id: 2,
                                                            descricao: "Tarefa"
                                                          },
                                                          posicaoDoFunil: {
                                                            id: 1,
                                                            descricao: "Sem contato"
                                                          },
                                                          situacaoDaAtividade: {
                                                            id: 1,
                                                            descricao: "Encerrada"
                                                          },
                                                          executor: {
                                                            id: 2,
                                                            cpf: null,
                                                            nome: "Izacc Moreira",
                                                            natureza: 0,
                                                            dataDeNascimento: null,
                                                            emails: null,
                                                            documentoPessoal: null,
                                                            rg: null,
                                                            creci: null,
                                                            estadoCivil: null,
                                                            documentoDeEstadoCivil: null,
                                                            regimeDeBens: null,
                                                            ocupacao: null,
                                                            necessarioAssinaturaDoConjuge: false,
                                                            conjuge: null,
                                                            endereco: null,
                                                            documentoEndereco: null,
                                                            telefones: null,
                                                            observacao: null
                                                          },
                                                          dataDoEvento: "2021-07-01T00:00:00-03:00",
                                                          descricao: "Tentativa de contato sem sucesso"
                                                        },
                                                        {
                                                          classificacaoDaAtividade: {
                                                            id: 2,
                                                            descricao: "Tarefa"
                                                          },
                                                          posicaoDoFunil: {
                                                            id: 2,
                                                            descricao: "Contato feito"
                                                          },
                                                          situacaoDaAtividade: {
                                                            id: 1,
                                                            descricao: "Encerrada"
                                                          },
                                                          executor: {
                                                            "id": 2,
                                                            "cpf": null,
                                                            "nome": "Izacc Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                          },
                                                          "dataDoEvento": "2021-07-21T00:00:00-03:00",
                                                          "descricao": "Contato com sucesso, cliente agendado para o dia 11/07/2021"
                                                        },
                                                        {
                                                          "classificacaoDaAtividade": {
                                                            "id": 2,
                                                            "descricao": "Tarefa"
                                                          },
                                                          "posicaoDoFunil": {
                                                            "id": 3,
                                                            "descricao": "Identificação do interesse"
                                                          },
                                                          "situacaoDaAtividade": {
                                                            "id": 0,
                                                            "descricao": "Aberta"
                                                          },
                                                          "executor": {
                                                            "id": 3,
                                                            "cpf": null,
                                                            "nome": "Hugo Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                          },
                                                          "dataDoEvento": "2021-07-16T00:00:00-03:00",
                                                          "descricao": "Tentativa de contato sem sucesso"
                                                        }
                                                    ],
                                                    "tarefas": [
                                                        {
                                                          "prioridade": {
                                                            "id": 2,
                                                            "descricao": "Média"
                                                          },
                                                          "solicitante": null,
                                                          "executor": {
                                                            "id": 2,
                                                            "cpf": null,
                                                            "nome": "Izacc Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                          },
                                                          "descricao": "Ligar para o lead",
                                                          "previsaoDeInicio": "2021-07-01T00:00:00-03:00",
                                                          "previsaoDeTermino": "2021-07-01T00:00:00-03:00",
                                                          "dataDeTermino": "2021-07-01T00:00:00-03:00",
                                                          "dataCancelamento": null,
                                                          "posicaoDoFunil": {
                                                            "id": 1,
                                                            "descricao": "Sem contato"
                                                          }
                                                        },
                                                        {
                                                          "prioridade": {
                                                            "id": 2,
                                                            "descricao": "Média"
                                                          },
                                                          "solicitante": null,
                                                          "executor": {
                                                            "id": 2,
                                                            "cpf": null,
                                                            "nome": "Izacc Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                          },
                                                          "descricao": "Contato com sucesso, cliente agendado para o dia 21/07/2021",
                                                          "previsaoDeInicio": "2021-07-11T00:00:00-03:00",
                                                          "previsaoDeTermino": "2021-07-11T00:00:00-03:00",
                                                          "dataDeTermino": "2021-07-11T00:00:00-03:00",
                                                          "dataCancelamento": null,
                                                          "posicaoDoFunil": {
                                                            "id": 2,
                                                            "descricao": "Contato feito"
                                                          }
                                                        },
                                                        {
                                                          "prioridade": {
                                                            "id": 1,
                                                            "descricao": "Alta"
                                                          },
                                                          "solicitante": {
                                                            "id": 2,
                                                            "cpf": null,
                                                            "nome": "Izacc Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                          },
                                                          "executor": {
                                                            "id": 3,
                                                            "cpf": null,
                                                            "nome": "Hugo Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                          },
                                                          "descricao": "Tentativa de contato sem sucesso",
                                                          "previsaoDeInicio": "2021-07-16T00:00:00-03:00",
                                                          "previsaoDeTermino": "2021-07-16T00:00:00-03:00",
                                                          "dataDeTermino": null,
                                                          "dataCancelamento": null,
                                                          "posicaoDoFunil": {
                                                            "id": 3,
                                                            "descricao": "Identificação do interesse"
                                                          }
                                                        }
                                                    ],
                                                    anotacoes: [],
                                                    listaEmails: []
                                                },
                                                {
                                                    id: (14 + item.listaDeLeads.kanbanData.length),
                                                    cpf: "51123762449",
                                                    nome: "Fulano B",
                                                    statusKanban: `${getItemFluxoDeSala}`,
                                                    summary: "...",
                                                    tags: "Contactar",
                                                    color: "#E6AAAA",
                                                    className: "e-story, e-low, e-nancy-davloio",
                                                    dataDeNascimento: new Date("1970-06-02T00:00:00"),
                                                    idade: undefined,
                                                    nacionalidade: undefined,
                                                    sexo: undefined,
                                                    emails: [
                                                        {
                                                            classificacao: 1,
                                                            descricao: "josiedson@cardial.br"
                                                        },
                                                        {
                                                            classificacao: 1,
                                                            descricao: "josiedson@cardial.br"
                                                        }
                                                    ],
                                                    fotoDoLead: null,
                                                    documentoPessoal: null,
                                                    rg: null,
                                                    estadoCivil: undefined,
                                                    documentoDeEstadoCivil: null,
                                                    regimeDeBens: undefined,
                                                    ocupacao: undefined,
                                                    renda: undefined,
                                                    dadosDosVeiculos: undefined,
                                                    dependentes: null,
                                                    endereco: null,
                                                    documentoEndereco: null,
                                                    telefones: [
                                                        {
                                                            classificacao: 1,
                                                            ddi: "55",
                                                            ddd: "81",
                                                            numero: "991595874",
                                                            observacao: ""
                                                        },
                                                        {
                                                            classificacao: 1,
                                                            ddi: "55",
                                                            ddd: "81",
                                                            numero: "991595874",
                                                            observacao: ""
                                                        }
                                                    ],
                                                    localDeCaptacao: "SITE",
                                                    status: 0,
                                                    observacoes: [
                                                        {
                                                            nome: "Washington Souza",
                                                            data: new Date("2021-07-14T00:00:00"),
                                                            descricao: "observacao numero 01"
                                                        },
                                                        {
                                                            nome: "Izacc Moreira",
                                                            data: new Date("2021-07-14T00:00:00"),
                                                            descricao: "observacao numero 01"
                                                        },
                                                        {
                                                            nome: "Calixto",
                                                            data: new Date("2021-07-14T00:00:00"),
                                                            descricao: "observacao numero 01"
                                                        },
                                                        {
                                                            nome: "Hugo Moreira",
                                                            data: new Date("2021-07-14T00:00:00"),
                                                            descricao: "observacao numero 01"
                                                        },
                                                        {
                                                            nome: "Lucas Moreira Assis",
                                                            data: new Date("2021-07-14T00:00:00"),
                                                            descricao: "observacao numero 01"
                                                        }
                                                    ],
                                                    responsavel: undefined,
                                                    posicaoDoFunil: {
                                                        id: 3,
                                                        descricao: "Identificação do interesse"
                                                    },
                                                    atividades: [
                                                        {
                                                        classificacaoDaAtividade: {
                                                            id: 1,
                                                            descricao: "Email"
                                                        },
                                                        posicaoDoFunil: {
                                                            id: 1,
                                                            descricao: "Sem contato"
                                                        },
                                                        situacaoDaAtividade: {
                                                            id: 1,
                                                            descricao: "Encerrada"
                                                        },
                                                        executor: {
                                                            id: 1,
                                                            cpf: null,
                                                            nome: "Washington dos Santos",
                                                            natureza: 0,
                                                            dataDeNascimento: null,
                                                            emails: null,
                                                            documentoPessoal: null,
                                                            rg: null,
                                                            creci: null,
                                                            estadoCivil: null,
                                                            documentoDeEstadoCivil: null,
                                                            regimeDeBens: null,
                                                            ocupacao: null,
                                                            necessarioAssinaturaDoConjuge: false,
                                                            conjuge: null,
                                                            endereco: null,
                                                            documentoEndereco: null,
                                                            telefones: null,
                                                            observacao: null
                                                        },
                                                        dataDoEvento: "2021-06-11T00:00:00-03:00",
                                                        descricao: "Enviado email de boas vindas"
                                                        },
                                                        {
                                                        classificacaoDaAtividade: {
                                                            id: 2,
                                                            descricao: "Tarefa"
                                                        },
                                                        posicaoDoFunil: {
                                                            id: 1,
                                                            descricao: "Sem contato"
                                                        },
                                                        situacaoDaAtividade: {
                                                            id: 1,
                                                            descricao: "Encerrada"
                                                        },
                                                        executor: {
                                                            id: 2,
                                                            cpf: null,
                                                            nome: "Izacc Moreira",
                                                            natureza: 0,
                                                            dataDeNascimento: null,
                                                            emails: null,
                                                            documentoPessoal: null,
                                                            rg: null,
                                                            creci: null,
                                                            estadoCivil: null,
                                                            documentoDeEstadoCivil: null,
                                                            regimeDeBens: null,
                                                            ocupacao: null,
                                                            necessarioAssinaturaDoConjuge: false,
                                                            conjuge: null,
                                                            endereco: null,
                                                            documentoEndereco: null,
                                                            telefones: null,
                                                            observacao: null
                                                        },
                                                        dataDoEvento: "2021-07-01T00:00:00-03:00",
                                                        descricao: "Tentativa de contato sem sucesso"
                                                        },
                                                        {
                                                        classificacaoDaAtividade: {
                                                            id: 2,
                                                            descricao: "Tarefa"
                                                        },
                                                        posicaoDoFunil: {
                                                            id: 2,
                                                            descricao: "Contato feito"
                                                        },
                                                        situacaoDaAtividade: {
                                                            id: 1,
                                                            descricao: "Encerrada"
                                                        },
                                                        executor: {
                                                            "id": 2,
                                                            "cpf": null,
                                                            "nome": "Izacc Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                        },
                                                        "dataDoEvento": "2021-07-21T00:00:00-03:00",
                                                        "descricao": "Contato com sucesso, cliente agendado para o dia 11/07/2021"
                                                        },
                                                        {
                                                        "classificacaoDaAtividade": {
                                                            "id": 2,
                                                            "descricao": "Tarefa"
                                                        },
                                                        "posicaoDoFunil": {
                                                            "id": 3,
                                                            "descricao": "Identificação do interesse"
                                                        },
                                                        "situacaoDaAtividade": {
                                                            "id": 0,
                                                            "descricao": "Aberta"
                                                        },
                                                        "executor": {
                                                            "id": 3,
                                                            "cpf": null,
                                                            "nome": "Hugo Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                        },
                                                        "dataDoEvento": "2021-07-16T00:00:00-03:00",
                                                        "descricao": "Tentativa de contato sem sucesso"
                                                        }
                                                    ],
                                                    "tarefas": [
                                                        {
                                                        "prioridade": {
                                                            "id": 2,
                                                            "descricao": "Média"
                                                        },
                                                        "solicitante": null,
                                                        "executor": {
                                                            "id": 2,
                                                            "cpf": null,
                                                            "nome": "Izacc Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                        },
                                                        "descricao": "Ligar para o lead",
                                                        "previsaoDeInicio": "2021-07-01T00:00:00-03:00",
                                                        "previsaoDeTermino": "2021-07-01T00:00:00-03:00",
                                                        "dataDeTermino": "2021-07-01T00:00:00-03:00",
                                                        "dataCancelamento": null,
                                                        "posicaoDoFunil": {
                                                            "id": 1,
                                                            "descricao": "Sem contato"
                                                        }
                                                        },
                                                        {
                                                        "prioridade": {
                                                            "id": 2,
                                                            "descricao": "Média"
                                                        },
                                                        "solicitante": null,
                                                        "executor": {
                                                            "id": 2,
                                                            "cpf": null,
                                                            "nome": "Izacc Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                        },
                                                        "descricao": "Contato com sucesso, cliente agendado para o dia 21/07/2021",
                                                        "previsaoDeInicio": "2021-07-11T00:00:00-03:00",
                                                        "previsaoDeTermino": "2021-07-11T00:00:00-03:00",
                                                        "dataDeTermino": "2021-07-11T00:00:00-03:00",
                                                        "dataCancelamento": null,
                                                        "posicaoDoFunil": {
                                                            "id": 2,
                                                            "descricao": "Contato feito"
                                                        }
                                                        },
                                                        {
                                                        "prioridade": {
                                                            "id": 1,
                                                            "descricao": "Alta"
                                                        },
                                                        "solicitante": {
                                                            "id": 2,
                                                            "cpf": null,
                                                            "nome": "Izacc Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                        },
                                                        "executor": {
                                                            "id": 3,
                                                            "cpf": null,
                                                            "nome": "Hugo Moreira",
                                                            "natureza": 0,
                                                            "dataDeNascimento": null,
                                                            "emails": null,
                                                            "documentoPessoal": null,
                                                            "rg": null,
                                                            "creci": null,
                                                            "estadoCivil": null,
                                                            "documentoDeEstadoCivil": null,
                                                            "regimeDeBens": null,
                                                            "ocupacao": null,
                                                            "necessarioAssinaturaDoConjuge": false,
                                                            "conjuge": null,
                                                            "endereco": null,
                                                            "documentoEndereco": null,
                                                            "telefones": null,
                                                            "observacao": null
                                                        },
                                                        "descricao": "Tentativa de contato sem sucesso",
                                                        "previsaoDeInicio": "2021-07-16T00:00:00-03:00",
                                                        "previsaoDeTermino": "2021-07-16T00:00:00-03:00",
                                                        "dataDeTermino": null,
                                                        "dataCancelamento": null,
                                                        "posicaoDoFunil": {
                                                            "id": 3,
                                                            "descricao": "Identificação do interesse"
                                                        }
                                                        }
                                                    ],
                                                    anotacoes: [],
                                                    listaEmails: []
                                            });

                                            setListaDeLeads(item.listaDeLeads)
                                        }
                                    })

                                    setListaDeFluxosDeSala(getListaDeFluxosDeSala);
                                    setItemFluxoDeSala("");
                                    setAlterarItemFluxoDeSala(false);
                                    setItemPickerListaDeAcoes(undefined);
                                    setItensSelecionadosPickerDeCargos([]);
                                    setItemPickerListaDeTelas(undefined);
                                    handleDialogCreateFaseClose();
                                }
                                else {
                                    NotificacaoInterna.ExibirNotificacao("Nome da fase existente", "Preencha com um nome válido", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                }
                            }
                            else if(getAlterarItemFluxoDeSala == true && getItemPickerListaDeAcoes)
                            {
                                let ListaDeFluxosDeSala = [...getListaDeFluxosDeSala]

                                let ListaDePosicoes = [] as Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }}>

                                getListaDeFluxosDeSala.map((Fluxo, FluxoIndex) => {
                                    if (Fluxo.id == getIdDoFluxoDeSala)
                                    {
                                        ListaDePosicoes = Fluxo.fluxoDeSala
                                    }
                                });

                                getListaDeFluxosDeSala.map((item, index) => {
                                    if (item.id == getIdDoFluxoDeSala)
                                    {
                                        item.fluxoDeSala.map((item, index) => {
                                            if (item.etapa == getItemFluxoDeSalaSelecionado?.etapa)
                                            {
                                                item.descricao = getItemFluxoDeSala;
                                                item.acao = {
                                                    id: getItemPickerListaDeAcoes?.id,
                                                    descricao: getItemPickerListaDeAcoes?.descricao,
                                                };
                                                item.cargosVinculados = getItemPickerListaDeAcoes.id == 1 ? getItensSelecionadosPickerDeCargos : []
                                            }
                                        });

                                        setListaDeLeads(item.listaDeLeads)
                                    }
                                });

                                setListaDeFluxosDeSala(getListaDeFluxosDeSala);
                                setItemFluxoDeSala("");
                                setAlterarItemFluxoDeSala(false);
                                setItemPickerListaDeAcoes(undefined);
                                setItensSelecionadosPickerDeCargos([]);
                                setItemPickerListaDeTelas(undefined);
                                handleDialogCreateFaseClose();
                            }

                        }}
                    />
                </div>

            </Dialog>

            {/** Dialog customizando component formulario start */}
            <Dialog
                style = {{width: '40%', backgroundColor: "#FFFFFF"}}
                icon="annotation"
                onClose={() => {
                    setDragStart(false)
                    handleDialogCustomComponentClose()
                }}
                title="Customizando componente"
                autoFocus = {true}
                isOpen = {getDialogCustomComponent.isOpen}
                canEscapeKeyClose = {getDialogCustomComponent.canEscapeKeyClose}
                canOutsideClickClose = {getDialogCustomComponent.canOutsideClickClose}
                enforceFocus = {getDialogCustomComponent.enforceFocus}
                usePortal = {getDialogCustomComponent.usePortal} 
            >
                <div style = {{display: "flex", alignItems: "center"}}>
                    <div style = {{marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRight: "0.5px solid #00000050", paddingBottom: "20px"}}>

                        <div style = {{fontWeight: "bold", fontSize: "16px", width: "95%"}}>{getSelectCustomComponent?.classificacao.descricao}</div>
                        
                        <div style = {{width: "95%", marginTop: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Titulo`}</div>
                            <Component.InputTask style = {{width: "100%"}} id="CustomLabel" placeholder={"Titulo do campo..."} value={getSelectCustomComponent?.titulo}
                                onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                    var TextoDigitado = event.currentTarget.value

                                    if (getSelectCustomComponent)
                                    {
                                        let selectCustomComponente = {...getSelectCustomComponent}
                                        selectCustomComponente.titulo = TextoDigitado
                                        setSelectCustomComponent(selectCustomComponente);
                                    }
                                }}>
                            </Component.InputTask>
                        </div>

                        <div style = {{display: "flex", flexDirection: "column", width: "95%", marginTop: "20px"}}>
                            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                                <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px", marginRight: "10px"}}>Este campo é obrigatório</div>
                                <Switch
                                    checked = {getSelectCustomComponent?.obrigatorio}
                                    onChange = {() => {
                                        if(getSelectCustomComponent)
                                        {
                                            let selectCustomComponente = {...getSelectCustomComponent}
                                            selectCustomComponente.obrigatorio = !getSelectCustomComponent?.obrigatorio
                                            setSelectCustomComponent(selectCustomComponente);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                
                <div style = {{ width: "100%", paddingRight: "2.5%", paddingLeft: "2.5%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center", borderTop: "0.5px solid #00000050"}}>
                    <Button
                        style = {{
                            flex: 1,
                            background: "#1098F7", 
                            color: "#FFFFFF",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px",
                            height: "15px",
                            marginTop: "20px"
                        }}
                        text = {"Salvar"}
                        onClick = {() => {

                            if (getSelectCustomComponent && getSelectCustomComponent.titulo != "") {

                                getTiposDeComponentesFormulario.push(
                                    {
                                        id: getTiposDeComponentesFormulario.length,
                                        titulo: getSelectCustomComponent.titulo,
                                        classificacao: getSelectCustomComponent.classificacao,
                                        obrigatorio: getSelectCustomComponent.obrigatorio,
                                        resposta: getSelectCustomComponent.resposta
                                    }
                                );
    
                                setTiposDeComponenesFormulario(getTiposDeComponentesFormulario)
                                
                                setDragStart(false) 
                                handleDialogCustomComponentClose()
                            }
                            else {}
                        }}
                    />
                </div>

            </Dialog>

            {/** Dialog deletar permissão do usuário no controle de sala */}
            <Dialog
                style = {{ width: "30%", minWidth: '400px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarPermissaoUsuario}
                title = {"Deseja remover a permissão do usuário?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarPermissaoUsuario.isOpen}
                canEscapeKeyClose = {getDialogDeletarPermissaoUsuario.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarPermissaoUsuario.canOutsideClickClose}
                enforceFocus = {getDialogDeletarPermissaoUsuario.enforceFocus}
                usePortal = {getDialogDeletarPermissaoUsuario.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            if(getIdDoFluxoDeSala && getUsuarioSelecionadoDaEquipe)
                            {

                                NotificacaoInterna.ExibirNotificacao("Removendo permissão de acesso", "Aguarde enquanto removemos a permissão de acesso.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                let Response = await ControleDeSala.RemoverPermissaoDeAcesso(ContextSignIn.getContext().token, getUsuarioSelecionadoDaEquipe.salaDeVenda.id, getIdDoFluxoDeSala, getUsuarioSelecionadoDaEquipe.usuario.id)

                                if (Math.floor(Response.status / 100) == 2)
                                {
                                    let ListaDeUsuarios = [...getListaDeUsuariosDaEquipeGrid];

                                    let ListaFiltrada = ListaDeUsuarios.filter(Item => Item.usuario.id != getUsuarioSelecionadoDaEquipe.usuario.id);
                                    
                                    NotificacaoInterna.ExibirNotificacao("Permissão de acesso removida", "A permissão de acesso foi removida com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    setListaDeUsuariosDaEquipeGrid(ListaFiltrada);
                                    handleCloseDialogDeletarPermissaoUsuario();
                                }
                                else
                                {
                                    NotificacaoInterna.ExibirNotificacao("Erro ao remover a permissão do usuário", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                }
                            }
                            else
                            {
                                if(!getUsuarioSelecionadoDaEquipe)
                                {
                                    NotificacaoInterna.ExibirNotificacao('Usuário não selecionado', 'Selecione o usuário que será removido', NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => { handleCloseDialogDeletarPermissaoUsuario() }}>{"Não"}</Button>
                </div>

            </Dialog>

            {/* Collapse */}
            <Collapse isOpen={getCollapse}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "0", width: "100%" }}>
                    <div style={{ width: "65%", minWidth: "919px", display: "flex", alignItems: "center", justifyContent: "space-between", background: "0", marginLeft: "2%" }}>
                        <div className="endereco">
                            <img src={IconeHome} style={{cursor: "pointer"}} alt="" onClick={() => {History.goBack();}} />
                            <div className="bar">/</div>
                            <div className="place">Configurações</div>
                            <div className="bar">/</div>
                            <div className="place">Controle de sala</div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", background: "0", width: "90%", minWidth: "777px"}}>
                            
                        </div>
                    </div>
                    {getCarregandoListaDeContratos == true && <div style = {{display: "flex", alignItems: "center", marginRight: "90px"}}>
                        <Button
                            icon = {<Icon icon = "add" iconSize = {15} color = "#FFFFFF"/>}
                            style = {{
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                border: "none", 
                                boxShadow: "none", 
                                borderRadius: "5px",
                                outline: "none",
                                width: "180px", 
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px", 
                                height: "15px",
                            }} 
                            text = {"Criar um fluxo de sala"}
                            onClick = {() => { handleDrawerCreateFunilDeVendaOpen() }}
                        />
                    </div>}
                </div>
            </Collapse>

            {/* Abridor Colapse */}
            <div style = {{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>

            {getCarregandoListaDeContratos != true && <div style = {{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.Fila,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={500}
                    width={600}
                    style={{ marginTop: "100px" }}
                />
                <div className="loading" style={{ margin: "0 auto 0 auto", fontWeight: "bold"}}>{(getCarregandoListaDeContratos == undefined) ? (getItemPickerCentroDeCusto == undefined ? "Por favor, clique no botão de pesquisa para exibir a lista de fluxos de salas" : ("Por favor, clique no botão de pesquisa a lista de fluxos de salas")) : ((getCarregandoListaDeContratos == false) ? "Aguarde, estamos carregando sua lista de fluxos de salas" : "Pronto, sua lista de fluxos de sala logo será exibida!")}</div>
            </div>}

            {getCarregandoListaDeContratos == true && <Card style = {{ backgroundColor: "0", width: "100%", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none", display: "flex" }}>
                
                <div style = {{backgroundColor: "#FFFFFF", width: "25%", display: "flex", flexDirection: "column", minHeight: (window.screen.height - 210), alignItems: "center", padding: "20px", borderRight: "1px solid #00000020", borderTop: "1px solid #00000020"}}>
                    
                    <div style = {{display: "flex", alignItems: "center", borderBottom: "1px solid #00000020"}}>
                        <img height = {70} width = {70} src = {Fila}/>
                        <div 
                            style = {{
                                fontWeight: "bold", 
                                fontSize: "18px",
                                padding: "17px",
                                width: "100%",
                                textAlign: "center",
                                marginLeft: "-20px"
                        }}>Fluxos de sala</div>
                    </div>

                    {getListaDeFluxosDeSala.map((item, index) => (
                        <>
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: getIdDoFluxoDeSala == item.id ? "0px" : '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: getIdDoFluxoDeSala == item.id ? "0px" : "5px", 
                                        borderBottomRightRadius: getIdDoFluxoDeSala == item.id ? "0px" : "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getIdDoFluxoDeSala == item.id ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                }}>
                                    <div style = {{display: "flex", alignItems: "center", width: "85%"}}
                                        onClick = { async () => {
                                            if (getIdDoFluxoDeSala == item.id)
                                            {
                                                let ListaDeFluxoDeSala = [...getListaDeFluxosDeSala];
                                                setIdDoFluxoDeSala(null);
                                                setListaDeFluxosDeSala(ListaDeFluxoDeSala);
                                                setNomeDoFluxoDeSala("");
                                                setListaFasesDoFluxoDeSala([]);
                                                setListaDeLeads({kanbanData: []});
                                                setListaDeUsuariosDaEquipeGrid([]);
                                                setListaPickerSalasDeVendasUsuarios([]);
                                                setListaPickerDepartamentosUsuarios([]);
                                                setItemPickerSalaDeVendasUsuarios(undefined);
                                                setItemPickerDepartamentosUsuarios(undefined);
                                            }
                                            else
                                            {

                                                let ListaDeFluxoDeSala = [...getListaDeFluxosDeSala];
                                                setIdDoFluxoDeSala(item.id);
                                                setListaDeFluxosDeSala(ListaDeFluxoDeSala);
                                                setNomeDoFluxoDeSala(item.descricao);
                                                setListaFasesDoFluxoDeSala(item.fluxoDeSala);
                                                setListaDeLeads(item.listaDeLeads);
                                                setListaPickerSalasDeVendasUsuarios(item.salasDeVenda);
                                                setItemPickerSalaDeVendasUsuarios(undefined);
                                                setItemPickerDepartamentosUsuarios(undefined);
                                                setCollapse(true);

                                                let Response = await Logon.PermissaoDeAcessoPorControleDeSala(ContextSignIn.getContext().token, "", item.id);

                                                if (Math.floor(Response.status / 100) == 2)
                                                {

                                                    let ListaDeUsuarios = [] as Array<{nome: string, cargoDescricao: string, salaDeVendaDescricao: string, emailDescricao: string, usuario : {id: number, nome: string, cargo: {id: number, nome: string}, email: {classificacao: number, descricao: string}}, salaDeVenda: {id: number, descricao: string}, controleDeSala: Objeto.ControleDeSala}>

                                                    Response.data.map(item => {
                                                        ListaDeUsuarios.push({
                                                            nome: item.usuario.nome,
                                                            cargoDescricao: item.usuario.cargo ? item.usuario.cargo.nome : 'Sem cargo',
                                                            salaDeVendaDescricao: item.salaDeVenda.descricao,
                                                            emailDescricao: item.usuario.email ? item.usuario.email.descricao : 'Sem email',
                                                            usuario: item.usuario,
                                                            salaDeVenda: item.salaDeVenda,
                                                            controleDeSala: item.controleDeSala
                                                        })
                                                    })

                                                    setListaDeUsuariosDaEquipeGrid(ListaDeUsuarios);
                                                }
                                                else
                                                {
                                                    NotificacaoInterna.ExibirNotificacao("Erro ao buscar a equipe do fluxo de sala", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                }

                                            }
                                    }}>
                                        <div style={{height: "20px", backgroundColor: "0", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getIdDoFluxoDeSala === item.id ? setIdDoFluxoDeSala(null) : setIdDoFluxoDeSala(item.id) }>
                                            <Lottie
                                                options={{
                                                    loop: true,
                                                    autoplay: true,
                                                    animationData: LottieFiles.SwipeDown,
                                                    rendererSettings: {
                                                        preserveAspectRatio: "xMidYMid slice"
                                                    }
                                                }}
                                                height={30}
                                                width={30}
                                                style={getIdDoFluxoDeSala == item.id ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s", }}
                                            />
                                        </div>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {item.descricao}
                                        />
                                    </div>
                                    <div>
                                        <Icon icon = 'remove' iconSize = {15} color = {"#000"} style = {{cursor: "pointer", marginRight: "15px"}} 
                                            onClick = { async () => {

                                                NotificacaoInterna.ExibirNotificacao("O fluxo de sala está sendo deletado", "Aguarde enquanto o fluxo de sala está sendo deletado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                let Response = await ControleDeSala.Delete(ContextSignIn.getContext().token, item)

                                                if (Math.floor(Response.status / 100) == 2)
                                                {
                                                    let ListaDeFluxoDeSala = [...getListaDeFluxosDeSala];

                                                    let ListaFiltrada = ListaDeFluxoDeSala.filter((Item, Index) => Item.id != item.id)
    
                                                    setIdDoFluxoDeSala(null);
                                                    setNomeDoFluxoDeSala("");
                                                    setListaDeFluxosDeSala([]);
                                                    setListaDeLeads({kanbanData: []});
                                                    setListaDeUsuariosDaEquipe([]);
    
                                                    setListaDeFluxosDeSala(ListaFiltrada);
    
                                                    NotificacaoInterna.ExibirNotificacao("O fluxo de sala foi deletado", "Fluxo de sala deletado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                }
                                                else
                                                {
                                                    NotificacaoInterna.ExibirNotificacao("Erro ao tentar deletar o fluxo de sala", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                }
                                        }}/>
                                    </div>
                                </div>
                            </div>
                            <Collapse isOpen = {getIdDoFluxoDeSala == item.id ? true : false}>
                                <div 
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        backgroundColor: getIdDoFluxoDeSala == item.id ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        display: "flex", 
                                        flexDirection: "column", 
                                        alignItems: "center",
                                        padding: "8.5px", 
                                        marginTop: "0px",
                                        width: "calc(20vw + 7px)"
                                }}>
                                    <div
                                        style = {{
                                            border: "1px dotted #00000050", 
                                            borderRadius: "5px", 
                                            height: "42px", 
                                            backgroundColor: "#FFFFFF",
                                            display: "flex", 
                                            alignItems: "center", 
                                            width: "calc(19vw)",
                                            cursor: "pointer",
                                        }}>
                                        <Button
                                            style = {{backgroundColor: "#FFFFFF", boxShadow: "none", border: "none", verticalAlign: "center", fontSize: "12px", fontWeight: "bolder"}}
                                            onClick = {() => {
                                                setAlterarItemFluxoDeSala(false);
                                                setIdDoFluxoDeSala(item.id);
                                                setItemPickerListaDeAcoes(undefined);
                                                setItemPickerListaDeTelas(undefined);
                                                setItensSelecionadosPickerDeCargos([]);
                                                handleDialogCreateFaseOpen()
                                            }}
                                            icon = {<Icon icon = 'add' iconSize = {19} color = {"#000000"} style = {{marginRight: "10px"}}/>}
                                            text = {"Adicionar uma nova fase"}
                                        />
                                    </div>

                                    <div style = {{marginTop: "20px", paddingLeft: item.fluxoDeSala.length > 0 ? "20px" : "0px"}}>
                                        {item.fluxoDeSala.map((Item: {etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}, index: number) => (
                                            <div key = {Item.etapa} style = {{display: "flex", alignItems: "center"}}>
                                                <div
                                                    key = {Item.etapa}
                                                    style = {{
                                                        border: "1px dotted #00000050", 
                                                        borderRadius: "5px", 
                                                        height: "42px", 
                                                        backgroundColor: "#FFFFFF",
                                                        display: "flex", 
                                                        alignItems: "center",
                                                        width: "calc(19vw)",
                                                        cursor: "pointer",
                                                        marginRight: "20px",
                                                        marginBottom: "10px",
                                                    }}>
                                                    <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                                        <div style = {{display: "flex", alignItems: "center", width: "90%"}}
                                                            onClick = {() => {
                                                                if(Item.etapa != 0)
                                                                {
                                                                    
                                                                    setItemFluxoDeSala(Item.descricao);
                                                                    setItemFluxoDeSalaSelecionado(Item);
                                                                    setAlterarItemFluxoDeSala(true);
                                                                    setItemPickerListaDeAcoes({id: Item.acao.id, descricao: Item.acao.descricao});
                                                                    setItensSelecionadosPickerDeCargos(Item.cargosVinculados ?? []);
                                                                    handleDialogCreateFaseOpen();

                                                                }
                                                        }}>
                                                            <div style = {{width: "20px", height: "20px", borderRadius: "20px", border: "0.4px solid #225", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "10px", marginRight: "10px", backgroundColor: "#FFFFFF90"}}>
                                                                <div style = {{fontWeight: "bolder", fontSize: "9px", textAlign: "center", verticalAlign: "middle"}}>{TextFormat.Numero.FormatarTextoParaInteiro(Item.etapa + 1)}</div>
                                                            </div>
                                                            <div style = {{fontWeight: "bold", fontSize: "12px"}}>{Item.descricao}</div>
                                                        </div>
                                                        {Item.etapa != 0 &&
                                                        <Icon icon = 'remove' iconSize = {15} color = {"#000"} style = {{marginRight: "5px"}}
                                                            onClick = { async () => {
                                                                let ListaDeFluxoDeSala = [...getListaDeFluxosDeSala];

                                                                let PosicoesFiltradas = item.fluxoDeSala.filter((posicao, posicaoIndex) => posicao.etapa != Item.etapa);

                                                                setListaFasesDoFluxoDeSala(PosicoesFiltradas);

                                                                ListaDeFluxoDeSala.map((fluxos, funisIndex) => {
                                                                    if (fluxos.id == item.id)
                                                                    {
                                                                        item.fluxoDeSala = PosicoesFiltradas
                                                                        
                                                                        let ListaFiltrada = item.listaDeLeads.kanbanData.filter((IItem, IIndex) => IItem.statusKanban != Item.descricao)
                                                                        item.listaDeLeads.kanbanData = ListaFiltrada
                                                                    }
                                                                });
                                                                
                                                                setListaDeFluxosDeSala(ListaDeFluxoDeSala);
                                                        }}/>}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {item.fluxoDeSala.length == 0 && <div
                                            style = {{
                                                fontSize: "12px",
                                                border: "1px dotted #00000050", 
                                                borderRadius: "5px", 
                                                height: "42px", 
                                                backgroundColor: "#D3658230",
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: "center", 
                                                width: "calc(19vw)",
                                                cursor: "pointer",
                                                fontWeight: "bolder"
                                        }}>{'Não há fases no momento'}</div>}
                                    </div>

                                    {getSalvarFluxoDeSala == false &&
                                    <div>
                                        <Button
                                            style = {{
                                                flex: 1,
                                                background: "#1098F7", 
                                                color: "#FFFFFF",
                                                boxShadow: "none",
                                                outline: "none",
                                                display: "flex", 
                                                alignItems: "center", 
                                                fontSize: "12px",
                                                marginTop: "20px",
                                                width: "calc(19vw)",
                                            }}
                                            onClick = { async () => {
                                                
                                                setSalvarFluxoDeSala(true)

                                                if (item.id > 0)
                                                {

                                                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "O fluxo de sala está sendo atualizado", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                    let Fluxo = {
                                                        id: item.id,
                                                        descricao: item.descricao,
                                                        fluxoDeSala: item.fluxoDeSala,
                                                        salasDeVenda: item.salasDeVenda
                                                    }

                                                    console.log(JSON.stringify(Fluxo))

                                                    var Response = await ControleDeSala.Put(ContextSignIn.getContext().token, Fluxo)

                                                    if (Math.floor(Response.status / 100) == 2) {
                                                        
                                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Fluxo de sala foi atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                        setSalvarFluxoDeSala(false)
                                                    }
                                                    else {

                                                        NotificacaoInterna.ExibirNotificacao("Erro ao tentar atualizar o fluxo de sala", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        setSalvarFluxoDeSala(false)
                                                    }
                                                }
                                                else {

                                                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "O fluxo de sala está sendo cadastrado", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                    let Fluxo = {
                                                        id: 0,
                                                        descricao: item.descricao,
                                                        fluxoDeSala: item.fluxoDeSala,
                                                        salasDeVenda: item.salasDeVenda
                                                    }

                                                    var ResponsePOST = await ControleDeSala.Post(ContextSignIn.getContext().token, Fluxo)

                                                    if (Math.floor(ResponsePOST.status / 100) == 2) {
                                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O fluxo de sala foi cadastrado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                        setSalvarFluxoDeSala(false)
                                                    }
                                                    else {

                                                        NotificacaoInterna.ExibirNotificacao("Erro ao tentar cadastrar o fluxo de sala", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        setSalvarFluxoDeSala(false)
                                                    }
                                                }

                                            }}
                                            icon = {<Icon icon = 'saved' iconSize = {15} color = {"#FFFFFF "}/>}
                                            text = {getAtualizandoFluxoDeSala == true ? "Atualizar" : "Salvar"}
                                        />
                                    </div>}

                                    {getSalvarFluxoDeSala == true &&
                                    <div
                                        style = {{
                                            flex: 1,
                                            background: "#1098F7", 
                                            color: "#FFFFFF",
                                            boxShadow: "none",
                                            outline: "none",
                                            display: "flex", 
                                            alignItems: "center", 
                                            fontSize: "12px",
                                            borderRadius: "5px",
                                            marginTop: "20px",
                                            height: "15px",
                                            width: "calc(19vw)",
                                    }}>
                                        <Lottie
                                            options = {{
                                                loop: true,
                                                autoplay: true,
                                                animationData: LottieFiles.Load04,
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={30}
                                            width={30}
                                        />
                                    </div>}

                                </div>
                            </Collapse>
                        </>
                    ))}
                    {getListaDeFluxosDeSala.length == 0 && <div
                        style = {{
                            marginTop: "20px",
                            fontSize: "12px",
                            border: "1px dotted #00000050", 
                            borderRadius: "5px", 
                            height: "42px", 
                            backgroundColor: "#D3658230",
                            display: "flex", 
                            alignItems: "center", 
                            justifyContent: "center", 
                            width: "calc(20vw + 5px)",
                            cursor: "pointer",
                            fontWeight: "bolder"
                    }}>{'Não há fluxos de sala no momento'}</div>}

                </div>
                
                <div style = {{width: "75%", backgroundColor: lighten(0.15, '#D3D3D3'), borderTop: "1px solid #00000020"}}>
                    
                    <div style = {{height: "60px", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center", marginTop: "10px", }}>
                        <div style = {{display: "flex", marginTop: "10px", justifyContent: "flex-start", alignItems: "flex-end", width: "100%", borderBottom: "0.5px solid #00000020", paddingRight: "2%", paddingLeft: "2%"}}>
                            {true && <Button
                                style = {{
                                    background: "0", 
                                    color: "#000000",
                                    borderLeft: getOptionMembros == true ? "0.5px dotted #00000050" : "none",
                                    borderRight: getOptionMembros == true ? "0.5px dotted #00000050" : "none",
                                    borderTop: getOptionMembros == true ? "0.5px dotted #00000050" : "none",
                                    borderBottom: getOptionMembros == true ? "3px solid #1098F7" : "0.5px solid #00000000",
                                    marginBottom: getOptionMembros == true ? "-2px" : "-0.5px",
                                    fontWeight: getOptionMembros == true ? "bolder" : "unset",
                                    boxShadow: "none",
                                    outline: "none",
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "15px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px"
                                }}
                                text = {"Equipe"}
                                onClick = {() => {
                                    setOptionMembros(true)
                                    setOptionGeral(false)
                                    setOptionFluxo(false)
                                    setOptionFases(false)
                                    setOptionFormulario(false)
                                }}
                            />}
                            <Button
                                style = {{
                                    background: "0",
                                    color: "#000000",
                                    borderLeft: getOptionFases == true ? "0.5px dotted #00000050" : "none",
                                    borderRight: getOptionFases == true ? "0.5px dotted #00000050" : "none",
                                    borderTop: getOptionFases == true ? "0.5px dotted #00000050" : "none",
                                    borderBottom: getOptionFases == true ? "3px solid #1098F7" : "0.5px solid #00000000",
                                    marginBottom: getOptionFases == true ? "-2px" : "-0.5px",
                                    fontWeight: getOptionFases == true ? "bolder" : "unset",
                                    boxShadow: "none",
                                    outline: "none",
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "15px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px"
                                }}
                                text = {"Fases"}
                                onClick = {() => {
                                    setOptionFases(true)
                                    setOptionMembros(false)
                                    setOptionGeral(false)
                                    setOptionFluxo(false)
                                    setOptionFormulario(false)
                                }}
                            />
                            {false && <Button
                                style = {{
                                    background: "0",
                                    color: "#000000",
                                    borderLeft: getOptionFormulario == true ? "0.5px dotted #00000050" : "none",
                                    borderRight: getOptionFormulario == true ? "0.5px dotted #00000050" : "none",
                                    borderTop: getOptionFormulario == true ? "0.5px dotted #00000050" : "none",
                                    borderBottom: getOptionFormulario == true ? "3px solid #1098F7" : "0.5px solid #00000000",
                                    marginBottom: getOptionFormulario == true ? "-2px" : "-0.5px",
                                    fontWeight: getOptionFormulario == true ? "bolder" : "unset",
                                    boxShadow: "none",
                                    outline: "none",
                                    display: "flex",
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "15px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px"
                                }}
                                text = {"Formulário"}
                                onClick = {() => {
                                    setOptionFormulario(true)
                                    setOptionFases(false)
                                    setOptionMembros(false)
                                    setOptionGeral(false)
                                    setOptionFluxo(false)
                                }}
                            />}
                        </div>
                    </div>

                    {getOptionFluxo == true && <div style = {{width: "100%", opacity: getOptionFluxo == true ? 1 : 0, transition: "opacity 0.5s"}}>
                    <div style = {{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div
                            style = {{
                                fontSize: "12px",
                                border: "1px dotted #00000050", 
                                borderRadius: "5px", 
                                height: "470px", 
                                backgroundColor: lighten(0.16, '#D3D3D3'),
                                display: "flex", 
                                alignItems: "center", 
                                justifyContent: "center", 
                                width: "95%",
                                cursor: "pointer",
                                marginTop: "20px",
                                fontWeight: "bolder"
                            }}>{'Não há fluxo de sala no momento'}</div>
                        </div>
                    </div>}

                    {getOptionFormulario === true && <div style = {{width: "100%", opacity: getOptionFormulario == true ? 1 : 0, transition: "opacity 0.5s"}}>
                        <div style={{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                            
                            <DragDropContext
                                onDragStart = { (result) => {
                                    if(result.source.index <= 4)
                                    {
                                        setDragStart(true) 
                                    }
                                }}
                                onDragEnd = { (result) => {

                                    if(result.source.index <= 4)
                                    {
                                        handleDialogCustomComponentOpen()
                                        setSelectCustomComponent(getTiposDeComponentesFormulario[result.source.index]) 
                                    }
                                    else {
                                        const items = Array.from(getTiposDeComponentesFormulario)
                                        const [reorderingItem] = items.splice(result.source.index, 1);
                                        items.splice(result.destination?.index ?? 0, 0, reorderingItem)

                                        items.map((item, index) => item.id = index);

                                        setTiposDeComponenesFormulario(items)
                                    }
                            }}>

                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div style = {{ borderRight: "0.5px solid #00000020",  width: "50%", display: "flex", flexDirection: "column", alignItems: "center", paddingRight: "40px", paddingLeft: "40px", paddingBottom: "40px", minHeight: "560px", marginTop: "-31px"}}
                                            {...provided.droppableProps} 
                                            ref = {provided.innerRef}>
                                            <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                                                <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                                    <Component.ContentCard>
                                                        <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? `Nome` : `Razão Social`}</div>
                                                                <Component.Input style = {{width: "calc(25vw + 15px)"}} id = {`Nome`} placeholder="Digite aqui..." value={getFormularioNome}
                                                                    onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                                        var TextoDigitado = event.currentTarget.value;
                                                                        setFormularioNome(TextoDigitado)
                                                                    }}>
                                                                </Component.Input>
                                                            </div>
                                                            <div
                                                                style = {{
                                                                    marginBottom: "5px", 
                                                                    fontWeight: "bold", 
                                                                    fontSize: "12px"
                                                            }}>{"Qual o meio de contato?"}</div>
                                                            <div style = {{display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginLeft: "5px"}}>
                                                                {getListaMeioDeContato?.map((Item, Index) =>
                                                                    <Radio size = {50} style={{ display: "flex", alignItems: "center", fontSize: "12px" }} name="EstadoCivil" label={Item.descricao} value={Item.id}
                                                                        onChange={async () => setFormularioMeioDeContato(Item)}
                                                                        checked={Item == getFormularioMeioDeContato}>
                                                                    </Radio>
                                                                )}
                                                            </div>
                                                            {getFormularioMeioDeContato?.id == 0 && 
                                                                <div>
                                                                    <div style = {{display: 'flex', alignItems: 'center', marginBottom: "5px", fontSize: "12px"}}>
                                                                        <div style = {{marginRight: "5px", fontWeight: 'bold'}}>Email de contato</div>
                                                                    </div>
                                                                    {getFormularioEmails.map((item, index) => (
                                                                        <div style = {{display: 'flex', alignItems: 'center', marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                            <Component.Input style = {{width: "calc(25vw + 15px)"}} id="Email" placeholder="example@email.com.br" value={item.descricao} 
                                                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                                                    var TextoDigitado = event.currentTarget.value;

                                                                                    let ListaDeEmails = [...getFormularioEmails]
                                                                                    ListaDeEmails.map((Item, Index) => {
                                                                                        if (index == Index) {
                                                                                            Item.descricao = TextoDigitado
                                                                                        }
                                                                                    })

                                                                                    setFormularioEmails(ListaDeEmails)
                                                                                }}>
                                                                            </Component.Input>
                                                                        </div>
                                                                    ))}
                                                                </div>}
                                                            {getFormularioMeioDeContato?.id == 1 && 
                                                                <div style = {{ fontWeight: "bold", fontSize: "12px"}}>
                                                                    <div style = {{display: 'flex', alignItems: "center", marginBottom: "5px"}}>
                                                                        <div style = {{marginRight: "5px", fontSize: "12px"}}>Telefone</div>
                                                                    </div>
                                                                    {getTelefones.map((item, index) => {
                                                                        return ( 
                                                                            <div style = {{marginBottom: "10px"}}>
                                                                                <Component.Input style = {{width: "calc(25vw + 15px)"}} id="TelefoneComercial" placeholder={Telefone.FormatarTexto("00000000000")} value={Telefone.FormatarTexto(item.descricao)}
                                                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                                                        
                                                                                        var TextoDigitado = event.currentTarget.value

                                                                                        let ListaDeTelefones = [...getTelefones]

                                                                                        ListaDeTelefones.map((Item, Index) => {
                                                                                            if (index == Index) {
                                                                                                Item.descricao = TextoDigitado
                                                                                            }
                                                                                        })

                                                                                        setTelefones(ListaDeTelefones)
                                                                                    }}>
                                                                                </Component.Input>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>}
                                                            {getTiposDeComponentesFormulario.map((item, index) => item.id > 4 && (
                                                                <Draggable
                                                                    key={item.id}
                                                                    draggableId={String(item.id)}
                                                                    index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                            {item.classificacao.id == 1 &&
                                                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                                    <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? `${item.titulo}` : `${item.titulo}`}</div>
                                                                                    <div style = {{display: "flex", alignItems: "center"}}>
                                                                                        <Component.Input style = {{width: "calc(25vw + 15px)"}} id = {`${item.titulo}`} placeholder="Digite aqui..." value={getFormularioNome}
                                                                                            onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                                                                var TextoDigitado = event.currentTarget.value;
                                                                                                setFormularioNome(TextoDigitado)
                                                                                            }}>
                                                                                        </Component.Input>
                                                                                        <Icon 
                                                                                            icon = {"remove"}
                                                                                            iconSize = {15} 
                                                                                            color = {'#000000'} 
                                                                                            style = {{marginLeft: "10px"}} 
                                                                                            onClick = {() => {
                                                                                                let ListaComponentesFormulario = [...getTiposDeComponentesFormulario]

                                                                                                let ListaFiltrada = ListaComponentesFormulario.filter((Item, Index) => Item.id != item.id)

                                                                                                ListaFiltrada.map((IItem, IIndex) => IItem.id == IIndex)

                                                                                                setTiposDeComponenesFormulario(ListaFiltrada)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                            </div>}
                                                                            {item.classificacao.id == 2 &&
                                                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                                    <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? `${item.titulo}` : `${item.titulo}`}</div>
                                                                                    <div style = {{display: "flex", alignItems: "center"}}>
                                                                                        <Component.TextArea style = {{width: "calc(25vw + 15px)"}} id = {`${item.titulo}`} placeholder="" value={getFormularioNome}
                                                                                            onChange = { async (event: React.FormEvent<HTMLTextAreaElement>) => {
                                                                                                var TextoDigitado = event.currentTarget.value;
                                                                                                setFormularioNome(TextoDigitado)
                                                                                            }}
                                                                                        >
                                                                                        </Component.TextArea>
                                                                                        <Icon 
                                                                                            icon = {"remove"}
                                                                                            iconSize = {15} 
                                                                                            color = {'#000000'} 
                                                                                            style = {{marginLeft: "10px"}} 
                                                                                            onClick = {() => {
                                                                                                let ListaComponentesFormulario = [...getTiposDeComponentesFormulario]

                                                                                                let ListaFiltrada = ListaComponentesFormulario.filter((Item, Index) => Item.id != item.id)

                                                                                                ListaFiltrada.map((IItem, IIndex) => IItem.id == IIndex)

                                                                                                setTiposDeComponenesFormulario(ListaFiltrada)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                            </div>}
                                                                            {item.classificacao.id == 3 &&
                                                                            <div style = {{display: "flex", justifyContent: "flex-start", marginBottom: "10px", marginRight: "10px"}}>
                                                                                    <div style = {{display: "flex", alignItems: "center"}}>
                                                                                        <div style = {{marginRight: "10px", fontWeight: "bold", fontSize: "12px"}}>{`${item.titulo}`}</div>
                                                                                    </div>
                                                                                    <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                                                                                        <div style = {{marginTop: "15px"}}>
                                                                                            <Switch/>
                                                                                        </div>
                                                                                        <div>
                                                                                            <Icon 
                                                                                                icon = {"remove"}
                                                                                                iconSize = {15} 
                                                                                                color = {'#000000'}
                                                                                                onClick = {() => {
                                                                                                    let ListaComponentesFormulario = [...getTiposDeComponentesFormulario]

                                                                                                    let ListaFiltrada = ListaComponentesFormulario.filter((Item, Index) => Item.id != item.id)
            
                                                                                                    ListaFiltrada.map((IItem, IIndex) => IItem.id == IIndex)
            
                                                                                                    setTiposDeComponenesFormulario(ListaFiltrada)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                            </div>}
                                                                            {item.classificacao.id == 4 &&
                                                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                                    <div style = {{marginBottom: "5px"}}>{`${item.titulo}`}</div>
                                                                                    <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start", marginRight: "10px"}}>
                                                                                        <DateInput inputProps = {{style: {background: lighten(0.08, "#D3D3D3"), width: "12vw", border: "none", boxShadow: "none", cursor: "pointer"}}} dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format('DD/MM/YYYY')}
                                                                                            parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                                                                            placeholder={"DD/MM/AAAA"} value={getFormularioPrevisaoDeInicio} maxDate={getFormularioPrevisaoDeTermino}
                                                                                            onChange = {async (DataSelecionada: Date) => {
                                                                                                if (DataSelecionada != null) {
                                                                                                    setFormularioPrevisaoDeInicio(DataSelecionada)
                                                                                                }
                                                                                                else {
                                                                                                    setFormularioPrevisaoDeInicio(getFormularioPrevisaoDeInicio)
                                                                                                }
                                                                                            }}>
                                                                                        </DateInput>
                                                                                        <Icon 
                                                                                            icon = {"remove"}
                                                                                            iconSize = {15} 
                                                                                            color = {'#000000'} 
                                                                                            style = {{marginLeft: "10px"}} 
                                                                                            onClick = {() => {
                                                                                                let ListaComponentesFormulario = [...getTiposDeComponentesFormulario]

                                                                                                let ListaFiltrada = ListaComponentesFormulario.filter((Item, Index) => Item.id != item.id)

                                                                                                ListaFiltrada.map((IItem, IIndex) => IItem.id == IIndex)

                                                                                                setTiposDeComponenesFormulario(ListaFiltrada)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                            </div>}
                                                                            {item.classificacao.id == 5 &&
                                                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                                    <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? `${item.titulo}` : `${item.titulo}`}</div>
                                                                                    <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start", marginRight: "10px"}}>
                                                                                        <TimePicker
                                                                                            autoFocus =  {true}
                                                                                            disabled = {false}
                                                                                            precision = {TimePrecision.MINUTE}
                                                                                            selectAllOnFocus = {false}
                                                                                            showArrowButtons = {false}
                                                                                            useAmPm = {false}
                                                                                            onChange = {() => {}}
                                                                                        />
                                                                                        <Icon 
                                                                                            icon = {"remove"}
                                                                                            iconSize = {15} 
                                                                                            color = {'#000000'} 
                                                                                            style = {{marginLeft: "10px"}} 
                                                                                            onClick = {() => {
                                                                                                let ListaComponentesFormulario = [...getTiposDeComponentesFormulario]

                                                                                                let ListaFiltrada = ListaComponentesFormulario.filter((Item, Index) => Item.id != item.id)

                                                                                                ListaFiltrada.map((IItem, IIndex) => IItem.id == IIndex)

                                                                                                setTiposDeComponenesFormulario(ListaFiltrada)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                            </div>}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            <div
                                                                style = {{
                                                                    border: "1px dotted #00000050", 
                                                                    borderRadius: "5px", 
                                                                    height: getDragStart == true ? "70px" : "0px", 
                                                                    opacity: getDragStart == true ? 1 : 0,
                                                                    backgroundColor: "#FFFFFF", 
                                                                    marginTop: getDragStart == true ? "30px" : "0px", 
                                                                    display: "flex", 
                                                                    alignItems: "center", 
                                                                    justifyContent: "center", 
                                                                    width: "calc(25vw + 15px)",
                                                                    cursor: "pointer",
                                                                    color: "#00000040",
                                                                    transition: "height 0.5s, opacity 0.5s"
                                                            }}>Arraste o novo campo!</div>
                                                        </Component.ContentFormHeader>
                                                    </Component.ContentCard>
                                                </div>
                                            </div>
                                            <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                                <Button
                                                    icon = {<Icon icon = "saved" iconSize = {14} color = {"#FFFFFF"}/>}
                                                    style = {{
                                                        background: "#1098F7", 
                                                        color: "#FFFFFF", 
                                                        border: "none", 
                                                        boxShadow: "none", 
                                                        borderRadius: "5px", 
                                                        outline: "none",
                                                        width: "250px", 
                                                        display: "flex", 
                                                        alignItems: "center", 
                                                        fontSize: "12px", 
                                                        height: "40px", 
                                                        marginRight: '10px',
                                                    }}  
                                                    onClick = { async () => {

                                                        if (getFormularioInicial.length == 0)
                                                        {
                                                            NotificacaoInterna.ExibirNotificacao("Cadastrando formulário", "O formulário está sendo cadastrado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                            let Formularios = [] as Array<{ id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>
    
                                                            getTiposDeComponentesFormulario.map((form, formIndex) => {
                                                                if (form.id > 4)
                                                                {
                                                                    Formularios.push({
                                                                        id: (form.id - 5),
                                                                        titulo: form.titulo,
                                                                        classificacao: form.classificacao,
                                                                        obrigatorio: form.obrigatorio,
                                                                        resposta: form.resposta
                                                                    })
                                                                }
                                                            })
                                                            
                                                            let Response = await Formulario.Post(ContextSignIn.getContext().token, {id: 0, descricao: "Padrão", perguntas: Formularios})
    
                                                            if (Math.floor(Response.status / 100) == 2)
                                                            {
                                                                NotificacaoInterna.ExibirNotificacao("O formulário foi cadastrado", "Formulário cadastrado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                            }
                                                            else 
                                                            {
                                                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar cadastrar um formulário", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                            }
                                                        }
                                                        else {

                                                            NotificacaoInterna.ExibirNotificacao("Alterando formulário", "O formulário está sendo alterado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                            let Formularios = [] as Array<{ id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>
    
                                                            getTiposDeComponentesFormulario.map((form, formIndex) => {
                                                                if (form.id > 4)
                                                                {
                                                                    Formularios.push({
                                                                        id: (form.id - 5),
                                                                        titulo: form.titulo,
                                                                        classificacao: form.classificacao,
                                                                        obrigatorio: form.obrigatorio,
                                                                        resposta: form.resposta
                                                                    })
                                                                }
                                                            })
                                                            
                                                            let Response = await Formulario.Put(ContextSignIn.getContext().token, {id: getFormularioInicial[0].id, descricao: getFormularioInicial[0].descricao, perguntas: Formularios})
    
                                                            if (Math.floor(Response.status / 100) == 2)
                                                            {
                                                                NotificacaoInterna.ExibirNotificacao("O formulário foi alterado", "Formulário alterado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                            }
                                                            else 
                                                            {
                                                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar alterar um formulário", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                            }
                                                        }

                                                    }}>{getTiposDeComponentesFormulario.length > 4 ? "Salvar formulário" : "Atualizar formulário"}</Button>
                                            </div>
                                        </div>
                                    )}
                                </Droppable>

                                <Droppable droppableId = "droppable2">
                                    {(provided, snapshot) => (
                                        <div 
                                            ref = {provided.innerRef}
                                            {...provided.droppableProps}
                                            style = {{
                                                width: '50%',
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                transition: "width 0.5s",
                                                backgroundColor: lighten(0.15, '#D3D3D3'),
                                        }}>
                                            <div 
                                                style = {{
                                                    padding: "20px",
                                                    height: "100%",
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    opacity: 1,
                                                    transition: "opacity 0.15s"
                                            }}>
                                                <div 
                                                    style = {{
                                                        display: "flex", 
                                                        alignItems: "center", 
                                                        justifyContent: "space-between"
                                                }}>

                                                    <div
                                                        style = {{
                                                            border: "1px dotted #00000050", 
                                                            borderRadius: "5px", 
                                                            height: "42px", 
                                                            backgroundColor: "#FFFFFF",
                                                            display: "flex", 
                                                            alignItems: "center",
                                                            width: "calc(16vw + 15px)",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick = {() => {
                                                    }}>
                                                        <Button
                                                            style = {{backgroundColor: "#FFFFFF", boxShadow: "none", border: "none", verticalAlign: "center", fontSize: "12px"}}
                                                            onClick = {handleDialogCreateFaseOpen}
                                                            text = {"Escolha um campo de formulário"}
                                                        />
                                                    </div>

                                                </div>
                                                <div style = {{ marginTop: "20px", marginLeft: "0px" }}>
                                                    <div>
                                                        {getTiposDeComponentesFormulario.map((item, index) => item.id <= 4 && (
                                                            <Draggable 
                                                                key = {item.id} 
                                                                draggableId={String(item.id)} 
                                                                index = {index}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef} 
                                                                        {...provided.draggableProps} 
                                                                        {...provided.dragHandleProps}>
                                                                        <div
                                                                            style = {{
                                                                                border: "1px dotted #00000050", 
                                                                                borderRadius: "5px", 
                                                                                height: "42px", 
                                                                                backgroundColor: "#FFFFFF",
                                                                                display: "flex", 
                                                                                alignItems: "center", 
                                                                                width: "calc(16vw + 15px)",
                                                                                cursor: "pointer",
                                                                                marginBottom: "10px"
                                                                            }}>
                                                                            <Button
                                                                                style = {{backgroundColor: "#FFFFFF", boxShadow: "none", border: "none", verticalAlign: "center", fontSize: "12px"}}
                                                                                icon = {<Icon icon = "text-highlight" iconSize = {15} color = {"#000000"}/>}
                                                                                text = {item.classificacao.descricao}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Droppable>

                            </DragDropContext>

                        </div>
                    </div>}

                    {getOptionFases === true && <div style = {{width: "100%", opacity: getOptionFases == true ? 1 : 0, transition: "opacity 0.5s"}}>
                        {getListaFasesDoFluxoDeSala.length > 0 &&<div style={{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                            <div style = {{width: "100%"}}>
                                <div className='kanban-control-section'>
                                    <div className='col-lg-12 control-section'>
                                        <div className='control-wrapper'>
                                            <KanbanComponent width = {"auto"} height = {"auto"} ref = {(kanban) => { kanbanObj = kanban }} id="kanban" keyField="statusKanban" dataSource={data} enableTooltip={true}
                                                cardSettings = {{ contentField: "summary", headerField: "nome", tagsField: 'tags', grabberField: 'color', footerCssField: 'className'}}
                                                dialogOpen = {(args: any) => {
                                                    
                                                    handleClose();
                                                    args.cancel = true;
                                            }}>
                                                <ColumnsDirective>
                                                    {getListaFasesDoFluxoDeSala.map((item, index) => ( <ColumnDirective allowDrag = {false} allowDrop = {false} key = {item.etapa} headerText= {item.descricao} keyField= {item.descricao} allowToggle = {true}/> ))}
                                                </ColumnsDirective>
                                            </KanbanComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {getListaFasesDoFluxoDeSala.length == 0 && <div style = {{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div
                                style = {{
                                    fontSize: "12px",
                                    border: "1px dotted #00000050", 
                                    borderRadius: "5px", 
                                    height: "470px", 
                                    backgroundColor: lighten(0.16, '#D3D3D3'),
                                    display: "flex", 
                                    alignItems: "center", 
                                    justifyContent: "center", 
                                    width: "95%",
                                    cursor: "pointer",
                                    marginTop: "20px",
                                    fontWeight: "bolder"
                            }}>{'Não há fases no fluxo de sala no momento'}</div>
                        </div>}
                    </div>}

                    {getOptionMembros == true && <div style = {{width: "100%", opacity: getOptionMembros == true ? 1 : 0, transition: "opacity 0.5s", backgroundColor: "#edeff5"}}>
                        <div style = {{backgroundColor: lighten(0.15, '#D3D3D3'), height: "560px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style = {{width: "93%", paddingTop: "50px", paddingBottom: "50px"}}>
                                
                                <div>
                                    <div style = {{fontWeight: "bold", fontSize: "14px"}}>{getListaDeUsuariosDaEquipe.length > 0 ? `${TextFormat.Numero.FormatarTextoParaInteiro(getListaDeUsuariosDaEquipe.length)} usuários na equipe` : `${TextFormat.Numero.FormatarTextoParaInteiro(getListaDeUsuariosDaEquipe.length)} usuário na equipe`}</div> 
                                    <div style = {{fontWeight: "bold", fontSize: "12px", color: "#00000020"}}>Gerenciar funções, acessar informações de contato e convidar usuários da equipe.</div> 
                                    <div style = {{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", marginBottom: "40px"}}>
                                        <Button
                                            style = {{
                                                flex: 1,
                                                background: "#1098F7", 
                                                color: "#FFFFFF",
                                                boxShadow: "none",
                                                outline: "none",
                                                display: "flex", 
                                                alignItems: "center", 
                                                fontSize: "12px",
                                                height: "24px",
                                                marginTop: "20px",
                                                fontWeight: "bolder"
                                            }}
                                            text = {"Adicionar usuário na equipe"}
                                            onClick = {async () => {
                                                setItemPickerUsuariosEmpresa(undefined);
                                                setItemPickerSalaDeVendasUsuarios(undefined);
                                                setItemPickerDepartamentosUsuarios(undefined);
                                                handleDrawerCreateNewUserOpen()
                                            }}
                                        />
                                    </div>

                                    {getListaDeUsuariosDaEquipeGrid.length > 0 && <GridComponent.GridComponent
                                        dataSource={getListaDeUsuariosDaEquipeGrid}
                                        allowGrouping = {true}
                                        allowReordering = {true}
                                        allowSorting = {true}
                                        allowFiltering = {true}
                                        filterSettings = {{
                                            ignoreAccent: true,
                                            immediateModeDelay: 200,
                                            mode: "Immediate",
                                            type: 'Excel',
                                        }}
                                        actionBegin={                                    
                                            (args: any) => {
                                                if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                                    args.filterChoiceCount = 100000;
                                                }
                                        }}
                                        groupSettings = {{columns: ['salaDeVendaDescricao'], showGroupedColumn: true, showUngroupButton: true}}>
                                        <GridComponent.ColumnsDirective>
                                            <GridComponent.ColumnDirective field = 'nome' headerText = 'Usuário' textAlign = 'Left' />
                                            <GridComponent.ColumnDirective field = 'cargoDescricao' headerText = 'Cargo/Função' textAlign = 'Left' />
                                            <GridComponent.ColumnDirective field = 'salaDeVendaDescricao' headerText = 'Sala' textAlign = 'Left' />
                                            <GridComponent.ColumnDirective width = {150} headerText = '' textAlign = 'Left' template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                                <Button
                                                    icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                                    style = {{
                                                        color: "#FFFFFF",
                                                        border: "none", 
                                                        boxShadow: "none", 
                                                        borderRadius: "5px",
                                                        outline: "none", 
                                                        display: "flex", 
                                                        alignItems: "center", 
                                                        justifyContent: 'flex-end',
                                                        fontSize: "12px", 
                                                        height: "15px",
                                                        marginRight: "10px"
                                                    }}
                                                    intent = {"danger"}
                                                    text = {'Remover'}
                                                    onClick = { async () => {
                                                        setUsuarioSelecionadoDaEquipe(props);

                                                        handleOpenDialogDeletarPermissoaUsuario();
                                                    }}
                                                />
                                            </div>}/>
                                        </GridComponent.ColumnsDirective>
                                        <GridComponent.Inject services = {[GridComponent.Group, GridComponent.Reorder, GridComponent.Sort, GridComponent.Filter]} />
                                    </GridComponent.GridComponent>}

                                    {getListaDeUsuariosDaEquipeGrid.length == 0 && <div
                                        style = {{
                                            fontSize: "12px",
                                            border: "1px dotted #00000050", 
                                            borderRadius: "5px", 
                                            height: "350px", 
                                            backgroundColor: lighten(0.16, '#D3D3D3'),
                                            display: "flex", 
                                            alignItems: "center", 
                                            justifyContent: "center", 
                                            width: "100%",
                                            cursor: "pointer",
                                            fontWeight: "bolder"
                                    }}>{'Não há usuários na equipe no momento'}</div>}

                                </div>

                            </div>
                        </div>
                    </div>}

                    {getOptionGeral == true && <div style = {{width: "100%", opacity: getOptionFormulario == true ? 1 : 0, transition: "opacity 0.5s"}}></div>}
                
                </div>

            </Card>}

        </Component.Container>
    );
    /* #endregion */
};

export default View;