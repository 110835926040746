import axios from "axios";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Avatar from 'react-avatar-edit';
import Papa from 'papaparse';

import moment from 'moment';
import React, { Children, useEffect, useState } from 'react';
import { lighten } from 'polished';
import Lottie from 'react-lottie';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, Card, FormGroup, MenuItem, Icon, Collapse, Divider, Dialog, Classes, HTMLSelect, Radio, Drawer, Switch, FileInput } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select, MultiSelect } from "@blueprintjs/select";
import { DateInput, TimePicker, TimePrecision  } from "@blueprintjs/datetime";
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import * as GridComponent from '@syncfusion/ej2-react-grids';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-kanban";
import { addClass, removeClass, Browser } from '@syncfusion/ej2-base';
import { RichTextEditorComponent, Toolbar, Inject, Image, Link, HtmlEditor, Count, QuickToolbar, Table } from '@syncfusion/ej2-react-richtexteditor';
import { ToolbarSettingsModel, FileManager, FileManagerSettingsModel, QuickToolbarSettingsModel } from '@syncfusion/ej2-react-richtexteditor';
import CodeMirror from 'codemirror';
import { createElement } from '@syncfusion/ej2-base';
import { isMobile } from 'react-device-detect';

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import { TiposComponentes, UF } from '../../../Data/Listas';
import * as LottieFiles from '../../../Data/Lottie';
import History from "../../../Routers";
import { CentroDeCusto, Venda, Prospect, Correios, D4sign, Identificador, Logon, Lead, Nacao, Pessoa, TabelaDeVenda, FunilDeVendas, Formulario, SalaDeVendas, Areas, Prioridade, Brinde, ControleDeSala } from '../../../Services/Controllers';
import { Objeto } from '../../../Services/Models';
import { CPF, CEP, Moeda, Numero, Telefone, CNPJ, Data } from '../../../Themes/TextFormat';
import { NotificacaoInterna } from '../../../Services/Notification';
import { Tooltip } from 'react-tippy';

import './App.css';
import * as Component from './style';
import viewModel from './viewModel';
import Terry from '../../../Assets/Images/Icone/terry.png';
import RDStation from '../../../Assets/Images/Icone/rd.svg';
import ExcelLogo from '../../../Assets/Images/Icone/excel.png';
import ProfileLogo from '../../../Assets/Images/Icone/profile.svg';

import VendaDireta from '../VendaDireta';

loadCldr(
    require('../../../../node_modules/cldr-data/main/pt/currencies.json'),
    require('../../../../node_modules/cldr-data/main/pt/numbers.json'),
    require('../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
    require('../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
    require('../../../../node_modules/cldr-data/supplemental/numberingSystems.json'));
setCulture('pt');
setCurrencyCode("BRL");
L10n.load({
    'pt': {
        'kanban': {
            'items': 'itens',
            'min': 'Min',
            'max': 'Max',
            'cardsSelected': 'Cards selecionados',
            'addTitle': 'adicionar titulo',
            'editTitle': 'editar titulo',
            'deleteTitle': 'detelar titulo',
            'deleteContent': 'deletar conteudo',
            'save': 'salvar',
            'delete': 'deletar',
            'cancel': 'cancelar',
            'yes': 'Sim',
            'no': 'Não',
            'close': 'Fechar',
            'noCard': 'sem card',
            'unassigned': 'sem assinatura'
        }
    }
});

interface IDialogExampleState {
    autoFocus: boolean;
    canEscapeKeyClose: boolean;
    canOutsideClickClose: boolean;
    enforceFocus: boolean;
    isOpen: boolean;
    usePortal: boolean;
}

interface IBrinde {
    brinde: {id: number, descricao: string}, 
    tipo: {id: number, descricao: string}, 
    fornecedor: Objeto.Fornecedor, 
    validadeInicial: Date, 
    validadeFinal: Date,
    quantidade: number, 
    valorUnitario: number
}

let IDLead: number = 0;
let LeadSelecionado: Objeto.Lead | undefined = undefined;

const View: React.FC<viewModel> = (viewModel) => {

    /* #region  Variáveis  */

    const PropriedadesDeCalendario = {
        months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    };
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const PickerNacionalidade = Select.ofType<Objeto.Nacao>();
    const [getFormularioID, setformularioID] = useState<number>(0);
    const [getFormularioCPF, setFormularioCPF] = useState<string>("");
    const [getFormularioRG, setFormularioRG] = useState<string>("");
    const [getFormularioRGOrgaoEmissor, setFormularioRGOrgaoEmissor] = useState<string>("");
    const [getFormularioRGUF, setFormularioRGUF] = useState<string>("");
    const [getFormularioDataDeNascimento, setFormularioDataDeNascimento] = useState<Date | null | undefined>();
    const [getFormularioNome, setFormularioNome] = useState<string>("");
    const [getFormularioEmails, setFormularioEmails] = useState<Array<Objeto.Email>>([]);
    const [getFormularioTelefones, setFormularioTelefones] = useState<Array<Objeto.Telefone>>([]);
    const [getTelefones, setTelefones] = useState<Array<{descricao: string}>>([]);
    const [getFormularioProfissao, setFormularioProfissao] = useState<string>("");
    const [getFormularioNacionalidade, setFormulacaoNacionalidade] = useState<Objeto.Nacao>();
    const [getFormularioCEP, setFormularioCEP] = useState<string>("");
    const [getFormularioLogradouro, setFormularioLogradouro] = useState<string>("");
    const [getFormularioComplemento, setFormularioComplemento] = useState<string>("");
    const [getFormularioNumeroDeEndereco, setFormularioNumeroDeEndereco] = useState<string>("");
    const [getFormularioBairro, setFormularioBairro] = useState<string>("");
    const [getFormularioCidade, setFormularioCidade] = useState<string>("");
    const [getFormularioUF, setFormularioUF] = useState<string>("");
    const [getFormularioEstadoCivil, setFormularioEstadoCivil] = useState<{ id: number, descricao: string }>();
    const [getListaMeioDeContato, setListaMeioDeContato] = useState<Array<{id: number, descricao: string}>>([
        {
            id: 0,
            descricao: "Email",
        },
        {
            id: 1,
            descricao: "Telefone",
        }
    ]);
    const [getFormularioMeioDeContato, setFormularioMeioDeContato] = useState<{id: number, descricao: string}>();

    const [getFormularioListaAtividades, setFormularioListaAtividades] = useState<any>([]);
    const [getFormularioListaTarefas, setFormularioListaTarefas] = useState<Array<Objeto.NovasTarefas>>([]);
    const [getFormularioListaAnotacoes, setFormularioListaAnotacoes] = useState<Array<Objeto.NovasAnotacoes>>([]);
    
    const [getFormularioListaDadosFormulario, setFormularioListaDadosFormulario] = useState<Array<{id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>>([]);
    
    const [getFormularioListaDadosFluxo, setFormularioListaDadosFluxo] = useState<Array<{id: number, titulo: string, descricao: string, pessoa: string}>>(
        [
            {
                id: 0,
                titulo: "2021-09-30",
                descricao: "Foi designado para o Liner",
                pessoa: "Lucas Moreira Assis"
            },
            {
                id: 1,
                titulo: "2021-09-30",
                descricao: "Foi designado para o Closer",
                pessoa: "Lucas Moreira Assis"
            }
        ]
    );

    const [getFormularioListaEmails, setFormularioListaEmails] = useState<Array<Objeto.NovosEmailsEnviados>>([]);

    const [getFormularioPosicaoFunil, setFormularioPosicaoFunil] = useState<{id: number, descricao: string}>();
    const [getFormularioKeyField, setFormularioKeyField] = useState<string>("");
    const [getFormularioKeyFieldAnterior, setFormularioKeyFieldAnterior] = useState<string>("");
    const [getFormularioPosicaoHistoricoFunil, setFormularioPosicaoHistoricoFunil] = useState<{id: number, descricao: string}>();

    const [getFormularioPosicaoHistoricoDoControleDeSala, setFormularioPosicaoHistoricoDoControleDeSala] = useState<{etapa: number, descricao: string}>();
    const [getFormularioPosicaoControleDeSala, setFormularioPosicaoControleDeSala] = useState<{etapa: number, descricao: string}>();

    const [getCPFEmConsulta, setCPFEmConsulta] = useState<boolean>(false);
    const [getCEPEmConsulta, setCEPEmConsulta] = useState<boolean>(false);
    const [getNomeEmConsulta, setNomeEmConsulta] = useState<boolean>(false);
    const [getEmailEmConsulta, setEmailEmConsulta] = useState<boolean>(false);
    const [getTelefoneEmConsulta, setTelefoneEmConsulta] = useState<boolean>(false);

    const [getFormularioAnexoPlanilha, setFormularioAnexoPlanilha] = useState<File>();

    const [getItemPickerSala, setItemPickerSala] = useState<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();
    const [getListaPickerNacionalidade, setListaPickerNacionalidade] = useState<Array<Objeto.Nacao>>([]);
    const [getFormularioListaEstadoCivil, setFormularioListaEstadoCivil] = useState<Array<{ id: number, descricao: string }>>();
    const [getListaPickerSala, setListaPickerSala] = useState<Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>>();

    const PickerSala = Select.ofType<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();

    const PickerSalaDeVendas = Select.ofType<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>();
    const PickerDepartamentos = Select.ofType<{id: number, descricao: string}>();
    const PickerVinculoDeBrinde = Select.ofType<IBrinde>();

    const PickerPrioridade = Select.ofType<{id: number, descricao: string}>();
    const PickerSolicitante = Select.ofType<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>();
    const PickerResponsavel = Select.ofType<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>();

    const [getListaPickerVincularBrinde, setListaPickerVincularBrinde] = useState<Array<IBrinde>>([]);
    const [getItensPickerSelecionadosVincularBrinde, setItensPickerSelecionadosVincularBrinde] = useState<IBrinde | undefined>(undefined);

    const [getListaDeBrindesVinculados, setListaDeBrindesVinculados] = useState<Array<IBrinde>>([]);

    const [getFormularioIDTarefa, setFormularioIDTarefa] = useState<number>(0);
    const [getFormularioNomeTarefa, setFormularioNomeTarefa] = useState<string>("");
    const [getEditandoTarefa, setEditandoTarefa] = useState<boolean>(false);

    const [getFormularioIDEmailEnviado, setFormularioIDEmailEnviado] = useState<number>(0);
    const [getFormularioParaEmail, setFormularioParaEmail] = useState<string>("");
    const [getFormularioCcEmail, setFormularioCcEmail] = useState<string>("");
    const [getFormularioAssuntoEmail, setFormularioAssuntoEmail] = useState<string>("");

    const [getFormularioIDAnotacao, setFormularioIDAnotacao] = useState<number>(0);

    const [getFormularioPrevisaoDeInicio, setFormularioPrevisaoDeInicio] = useState<Date>(moment(new Date()).subtract(1, "day").toDate());
    const [getFormularioPrevisaoDeTermino, setFormularioPrevisaoDeTermino] = useState<Date>(moment(new Date()).subtract(0, "day").toDate());
    const [getFormularioDataDeTermino, setFormularioDataDeTermino] = useState<Date>();

    const [getItemPickerPrioridade, setItemPickerPrioridade] = useState<{id: number, descricao: string}>();
    const [getItemPickerSolicitante, setItemPickerSolicitante] = useState<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>();
    const [getItemPickerResponsavel, setItemPickerResponsavel] = useState<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>();

    const [getListaPickerPrioridade, setListaPickerPrioridade] = useState<Array<{id: number, descricao: string}>>([]);
    const [getListaPickerSolicitante, setListaPickerSolicitante] = useState<Array<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>>([]);
    const [getListaPickerResponsavel, setListaPickerResponsavel] = useState<Array<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>>([]);

    const [getListaHistoricoDoFunil, setListaHistoricoDoFunil] = useState<Array<{salaDeVenda: { id: number, descricao: string }, area: {id: number, descricao: string}, funil: {id: number, descricao: string}, posicaoDoFunil: {id: number, descricao: string}, datasDeAlteracoes: Array<any>, descricao: string}>>([]);

    const [getListaHistoricaoDoControleDeSala, setListaHistoricoDoControleDeSala] = useState<Array<{ controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}>>([]);

    const [getListaMudarDeFase, setListaMudarDeFase] = useState<Array<{id: number, descricao: string}>>([]);

    const [getListaDeSolicitantes, setListaDeSolicitantes] = useState<Array<Objeto.Pessoa>>([]);

    const [getTiposDeComponentesFormulario, setTiposDeComponenesFormulario] = useState<Array<{ id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>>(TiposComponentes);
    const [getDescricaoFormulario, setDescricaoFormulario] = useState<{id: number, descricao: string}>();
    const [getSelectCustomComponent, setSelectCustomComponent] = useState<{id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>();

    const [getCarregandoListaDeContratos, setCarregandoListaDeContratos] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getCollapse, setCollapse] = useState(false);
    const [getCollapseDadosPessoais, setCollapseDadosPesssoais] = useState(false);
    const [getCollapseDadosEndereco, setCollapseDadosEndereco] = useState(false);
    const [getCollapseMinhasOportunidadesKanban, setCollapseMinhasOportunidadesKanban] = useState(false);
    const [getCollapseMinhasOportunidadesImport, setCollapseMinhasOportunidadesImport] = useState(false);

    const [getListaFunilDeVendas, setListaFunilDeVendas] = useState<{ id: number, posicoesDoFunil: Array<{id: number, descricao: string}>, descricao: string, hierarquiaDoFunil: Array<{salaDeVenda: {id: number, descricao: string}, areas: Array<{id: number, descricao: string}>}>}>();

    const [getListaDeLeads, setListaDeLeads] = useState<Array<Objeto.Lead>>([]);

    const [getLeadSelecionado, setLeadSelecionado] = useState<Objeto.Lead>();

    const [getControleDeSala, setControleDeSala] = useState<Array<{id: number, descricao: string, fluxoDeSala: Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}>, salasDeVenda: Array<{ id: number, descricao: string }>}>>([]);
    const [getFluxoDeSala, setFluxoDeSala] = useState<Array<{etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}>>([]);

    const [getListaDeLeadsCadastro, setListaDeLeadsCadastro] = useState<Array<Objeto.Lead>>([]);

    const [getImportDeLeads, setImportDeLeads] = useState<any>([]);
    const [getFieldImportLeads, setFieldImportLeads] = useState<any>([]);

    const [getListaPickerSalasDeVendas, setListaPickerSalasDeVendas] = useState<Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>>([]);
    const [getItemPickerSalaDeVendas, setItemPickerSalaDeVendas] = useState<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>();
    const [getListaPickerDepartamentos, setListaPickerDepartamentos] = useState<Array<{id: number, descricao: string}>>([]);
    const [getItemPickerDepartamentos, setItemPickerDepartamentos] = useState<{id: number, descricao: string}>();

    const [getDragStart, setDragStart] = useState(false);

    const [getCriandoOportunidade, setCriandoOportunidade] = useState<boolean>(false);
    const [getAtualizandoOportunidade, setAtualizandoOportunidade] = useState<boolean>(false);

    const [getCarregandoAnotacao, setCarregandoAnotacao] = useState<boolean>(false);
    const [getCarregandoEmailEnviado, setCarregandoEmailEnviado] = useState<boolean>(false);
    const [getCarregandoTarefa, setCarregandoTarefa] = useState<boolean>(false);
    const [getCarregandoArquivos, setCarregandoArquivos] = useState<boolean>(false);
    const [getCarregandoImport, setCarregandoImport] = useState<boolean>(false);
    const [getCarregandoArquivoDeAmostra, setCarregandoArquivoDeAmostra] = useState<boolean>(false);
    const [getCarregandoVinculoDeBrinde, setCarregandoVinculoDeBrinde] = useState<boolean>(false);
    const [getCarregandoFluxoDeSala, setCarregandoFluxoDeSala] = useState<boolean>(false);

    const [getOptionFluxoDeSala, setOptionFluxoDeSala] = useState<boolean>(true);
    const [getOptionListaDeLeads, setOptionListaDeLeads] = useState<boolean>(false);
    const [getOptionCustomizedFields, setOptionCustomizedFields] = useState<boolean>(false);
    const [getOptionSitucaoNoFunil, setOptionSitucaoNoFunil] = useState<boolean>(true);
    const [getOptionMudarDeFase, setOptionMudarDeFase] = useState<boolean>(false);
    const [getOptionAtividades, setOptionAtividades] = useState<boolean>(true);
    const [getOptionAnotacoes, setOptionAnotacoes] = useState<boolean>(false);
    const [getOptionEmails, setOptionEmails] = useState<boolean>(false);
    const [getOptionFormulario, setOptionFormulario] = useState<boolean>(false);
    const [getOptionFluxo, setOptionFluxo] = useState<boolean>(false);
    const [getOptionTarefas, setOptionTarefas] = useState<boolean>(false);
    const [getOptionVincularBrinde, setOptionVincularBrinde] = useState<boolean>(false);
    const [getOptionSituacaoNoControleDeSala, setOptionSituacaoNoControleDeSala] = useState<boolean>(true);
    const [getOptionMudarFaseNoControleDeSala, setOptionMudarFaseNoControleDeSala] = useState<boolean>(false);
    const [getAvatar, setAvatar] = useState<string | undefined>(undefined);
    const [getPreviewAvatar, setPreviewAvatar] = useState<string | undefined>(ProfileLogo);

    const PickerUsuariosCargos = Select.ofType<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>();
    const [getListaDeUsuariosRelecionadosAoCargos, setListaDeUsuariosRelecionadosAoCargo] = useState<Array<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>>([]);
    const [getItemListaDeUsuariosRelecionadosAoCargos, setItemListaDeUsuariosRelecionadosAoCargo] = useState<{id: number, nome: string, email: {classificacao: number, descricao: string} | null, cargo: {id: number, nome: string} | null} | undefined>(undefined);
    const [getDescricaoDoCargo, setDescricaoDoCargo] = useState<{id: number, nome: string}>();
    const [getAlterandoCargoVinculado, setAlterandoCargoVinculado] = useState<boolean>(false);

    {/** PROMOTOR */}
    const [getItemPickerPromotor, setItemPickerPromotor] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerPromotor, setListaPickerPromotor] = useState<Array<{ descricao: string, cpf: string }>>([]);
    const PickerPromotor = Select.ofType<{ descricao: string, cpf: string }>();
    
    {/** ASSESSOR TMLKT */}
    const [getItemPickerAssessorTlmkt, setItemPickerAssessorTlmkt] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerAssessorTlmkt, setListaPickerAssessorTlmkt] = useState<Array<{ descricao: string, cpf: string }>>([]);
    const PickerAssessorTlmkt = Select.ofType<{ descricao: string, cpf: string }>();
    
    {/** LINER */}
    const [getItemPickerLiner, setItemPickerLiner] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerLiner, setListaPickerLiner] = useState<Array<{ descricao: string, cpf: string }>>([]);
    const PickerLiner = Select.ofType<{ descricao: string, cpf: string }>();
    
    {/** CLOSER */}
    const [getItemPickerCloser, setItemPickerCloser] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerCloser, setListaPickerCloser] = useState<Array<{ descricao: string, cpf: string }>>([]);
    const PickerCloser = Select.ofType<{ descricao: string, cpf: string }>();
    
    {/** PEP */}
    const [getItemPickerPEP, setItemPickerPEP] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerPEP, setListaPickerPEP] = useState<Array<{ descricao: string, cpf: string }>>([]);
    const PickerPEP = Select.ofType<{ descricao: string, cpf: string }>();

    {/** SUB GERENTE DE SALA */}
    const [getItemPickerSubGerenteDeSala, setItemPickerSubGerenteDeSala] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerSubGerenteDeSala, setListaPickerSubGerenteDeSala] = useState<Array<{ descricao: string, cpf: string }>>([]);
    const PickerSubGerenteDeSala = Select.ofType<{ descricao: string, cpf: string }>();

    {/** GERENTE DE SALA */}
    const [getItemPickerGerenteDeSala, setItemPickerGerenteDeSala] = useState<{ descricao: string, cpf: string }>();
    const [getListaPickerGerenteDeSala, setListaPickerGerenteDeSala] = useState<Array<{ descricao: string, cpf: string }>>([]);
    const PickerGerenteDeSala = Select.ofType<{ descricao: string, cpf: string }>();
    const [getPropostaDeVenda, setPropostaDeVenda] = useState<Objeto.PropostaDeVenda>();
    /* #endregion */

    const [getFormularioExibirCalendarioDataDeNascimento, setFormularioExibirCalendarioDataDeNascimento] = useState<boolean>(false);
    const [getFormularioExibirCalendarioDataFormulario, setFormularioExibirCalendarioDataFormulario] = useState<boolean>(false);
    const [getFormularioExibirCalendarioDataPrevisaoDeInicio, setFormularioExibirCalendarioDataPrevisaoDeInicio] = useState<boolean>(false);
    const [getFormularioExibirCalendarioDataPrevisaoDeTermino, setFormularioExibirCalendarioDataPrevisaoDeTermino] = useState<boolean>(false);
    const [getFormularioExibirCalendarioDataDeTermino, setFormularioExibirCalendarioDataDeTermino] = useState<boolean>(false);
    const [getFormularioExibirCalendarioDataOportunidade, setFormularioExibirCalendarioDataOportunidade] = useState<boolean>(false);

    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
    **/
    async function Buscar() {}

    let kanbanObj: any;

    const [getDialogState, setDialogState] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDialogCreateTask, setDialogCreateTask] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDialogVinculando, setDialogVinculando] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDialogCreateEmail, setDialogCreateEmail] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDialogVincularBrinde, setDialogVincularBrinde] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDialogCreateAnnotation, setDialogCreateAnnotation] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDialogSelectedLead, setDialogSelectedLead] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDialogProfile, setDialogProfile] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDialogCustomComponent, setDialogCustomComponent] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDialogImportOportunites, setDialogImportOportunites] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDrawerAdicionar, setDrawerAdicionarLead] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });
    const [getDrawerTelaDeVendaDireta, setDrawerTelaDeVendaDireta] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDrawerAdicionar = () => setDrawerAdicionarLead({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerAdicionar = () => {
        setDrawerAdicionarLead({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        });
        setOptionCustomizedFields(false);
        setCriandoOportunidade(false);
    }

    const handleOpenDrawerTelaVendaDireta = () => setDrawerTelaDeVendaDireta({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerTelaVendaDireta = () => setDrawerTelaDeVendaDireta({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
    });

    const handleDialogImportOportunitesOpen = () => setDialogImportOportunites({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogImportOportunitesClose = () => setDialogImportOportunites({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpen = () => setDialogState({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleClose = () => {
        setDialogState({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        });
        setCollapseDadosPesssoais(false);
        setCollapseDadosEndereco(false);
        setformularioID(0)
        setFormularioPosicaoHistoricoFunil(undefined)
        setListaHistoricoDoFunil([])
        setFormularioNome("")
        setFormularioEmails([])
        setFormularioEstadoCivil(undefined);
        setFormularioCPF("")
        setFormularioDataDeNascimento(undefined)
        setFormularioDataDeNascimento(undefined)
        setFormularioRG("")
        setFormularioRGOrgaoEmissor("")
        setFormularioRGUF("")
        setTelefones([])
        setFormularioProfissao("")
        setFormulacaoNacionalidade(undefined)
        setFormularioCEP("")
        setFormularioLogradouro("")
        setFormularioNumeroDeEndereco("")
        setFormularioComplemento("")
        setFormularioBairro("")
        setFormularioCidade("")
        setFormularioUF("")
        setFormularioListaAtividades([])
        setFormularioListaTarefas([])
        setFormularioListaAnotacoes([])
        setFormularioListaEmails([])
        setFormularioKeyField("")
    }

    const handleDialogCreateTaskOpen = () => setDialogCreateTask({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCreateTaskClose = () => {
        setDialogCreateTask({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        });
        setEditandoTarefa(false);
    }

    const handleDialogVinculandoOpen = () => setDialogVinculando({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogVinculandoClose = () => {
        let ListaDeLeads = [...getListaDeLeads];

        let ItemIndex = ListaDeLeads.findIndex(item => item.id == getFormularioID);

        ListaDeLeads[ItemIndex].keyField = getFormularioKeyFieldAnterior;

        setListaDeLeads(ListaDeLeads);
        
        setDialogVinculando({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        });
    }

    const handleDialogCreateEmailOpen = () => setDialogCreateEmail({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCreateEmailClose = () => {
        setDialogCreateEmail({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        });
        setEditandoTarefa(false);
    };

    const handleDialogCreateVincularBrindeOpen = () => setDialogVincularBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCreateVincularBrindeClose = () => setDialogVincularBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleDialogCreateAnnotationOpen = () => setDialogCreateAnnotation({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCreateAnnotationClose = () => {
        setDialogCreateAnnotation({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        });
        setEditandoTarefa(false);
    }

    const handleDialogSelectedLeadOpen = () => setDialogSelectedLead({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogSelectedLeadClose = () => {
        setDialogSelectedLead({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        });
        setformularioID(0);
    };

    const handleDialogProfileOpen = () => {
        setDialogProfile({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: true,
            usePortal: true
        });
        if (getPreviewAvatar != undefined && getPreviewAvatar != ProfileLogo)
        {
            setAvatar(getPreviewAvatar);
        }
    }

    const handleDialogProfileClose = () => {
        setDialogProfile({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        });
        setEditandoTarefa(false);
    }

    const handleDialogCustomComponentOpen = () => setDialogCustomComponent({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCustomComponentClose = () => setDialogCustomComponent({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    var rteObj: any;

    var hostUrl: string = 'https://ej2-aspcore-service.azurewebsites.net/';

    var items: string[] = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', 'SuperScript', 'SubScript', '|',
      'CreateTable', 'CreateLink', 'Image', 'FileManager', '|', 'ClearFormat', 'Print',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
    ];

    var fileManagerSettings: FileManagerSettingsModel = {
      enable: true,
      path: '/Pictures/Food',
      ajaxSettings: {
        url: hostUrl + 'api/FileManager/FileOperations',
        getImageUrl: hostUrl + 'api/FileManager/GetImage',
        uploadUrl: hostUrl + 'api/FileManager/Upload',
        downloadUrl: hostUrl + 'api/FileManager/Download'
      }
    }

    var quickToolbarSettings: QuickToolbarSettingsModel = {
      table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles']
    }

    var toolbarSettings: ToolbarSettingsModel = {
      items: items
    };

    var textArea: any;
    var myCodeMirror: any;

    const mirrorConversion = (e?: any) => {
      textArea = rteObj.contentModule.getEditPanel() as HTMLTextAreaElement;
      let id: string = (rteObj as any).getID() + 'mirror-view';
      let mirrorView: HTMLElement = rteObj.element.querySelector('#' + id) as HTMLElement;
      let charCount: HTMLElement = rteObj.element.querySelector('.e-rte-character-count') as HTMLElement;
      if (e.targetItem === 'Preview') {
        textArea.style.display = 'block';
        mirrorView.style.display = 'none';
        textArea.innerHTML = myCodeMirror.getValue();
        charCount.style.display = 'block';
      } else {
        if (!mirrorView) {
          mirrorView = createElement('div', { className: 'e-content' });
          mirrorView.id = id;
          textArea.parentNode.appendChild(mirrorView);
        } else {
          mirrorView.innerHTML = '';
        }
        textArea.style.display = 'none';
        mirrorView.style.display = 'block';
        renderCodeMirror(mirrorView, (rteObj as any).value);
        charCount.style.display = 'none';
      }
    }

    const renderCodeMirror = (mirrorView: HTMLElement, content: string) => {
      myCodeMirror = CodeMirror(mirrorView, {
        value: content,
        lineNumbers: true,
        mode: 'text/html',
        lineWrapping: true,
  
      });
    }

    const handleFullScreen = (e: any) => {
      let sbCntEle: any = document.querySelector('.sb-content.e-view');
      let sbHdrEle: any = document.querySelector('.sb-header.e-view');
      let leftBar: any;
      let transformElement: any;
      if (Browser.isDevice) {
        leftBar = document.querySelector('#right-sidebar');
        transformElement = document.querySelector('.sample-browser.e-view.e-content-animation');
      } else {
        leftBar = document.querySelector('#left-sidebar');
        transformElement = document.querySelector('#right-pane');
      }
      if (e.targetItem === 'Maximize') {
        if (Browser.isDevice && Browser.isIos) {
          addClass([sbCntEle, sbHdrEle], ['hide-header']);
        }
        addClass([leftBar], ['e-close']);
        removeClass([leftBar], ['e-open']);
        if (!Browser.isDevice) { transformElement.style.marginLeft = '0px'; }
        transformElement.style.transform = 'inherit';
      } else if (e.targetItem === 'Minimize') {
        if (Browser.isDevice && Browser.isIos) {
          removeClass([sbCntEle, sbHdrEle], ['hide-header']);
        }
        removeClass([leftBar], ['e-close']);
        if (!Browser.isDevice) {
          addClass([leftBar], ['e-open']);
          transformElement.style.marginLeft = leftBar.offsetWidth + 'px';
        }
        transformElement.style.transform = 'translateX(0px)';
      }
    }

    const actionCompleteHandler = (e: any) => {
      if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
        (rteObj.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display = 'none';
        mirrorConversion(e);
      } else {
        setTimeout(() => { (rteObj as any).toolbarModule.refreshToolbarOverflow(); }, 400);
      }
    }

    /* #region Funções */
    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem ao cadastro de lead
    **/
    async function ValidarLead(): Promise<Boolean> {
        if (!getFormularioNome) {
            NotificacaoInterna.ExibirNotificacao("Nome não preenchido", "Informe o nome do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (!getFormularioMeioDeContato)
        {
            NotificacaoInterna.ExibirNotificacao("Meio de contato não selecionado", "Selecione um meio de contato", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (getFormularioMeioDeContato?.id == 0 && getFormularioEmails.filter((item, index) => item.descricao == "").length > 0) {
            NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (getFormularioMeioDeContato?.id == 1 && getTelefones.filter((item, index) => item.descricao == "").length > 0) {
            NotificacaoInterna.ExibirNotificacao("Telefone não preenchido", "Informe o email do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        return true;
    };

    /* #region Funções */
    /* Sobre esta função *//**
     * Valida os dados preenchidos no que se referem a atualização do lead
    **/
    async function ValidarAtualizacaoDoLead(): Promise<Boolean> {
        if (!getFormularioNome) {
            NotificacaoInterna.ExibirNotificacao("Nome não preenchido", "Informe o nome do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (getFormularioEmails.filter((item, index) => item.descricao == "").length > 0) {
            NotificacaoInterna.ExibirNotificacao("Há emails não preenchidos", "Informe o email", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (getTelefones.filter((item, index) => item.descricao == "").length > 0) {
            NotificacaoInterna.ExibirNotificacao("Há telefones não preenchidos", "Informe o telefone", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (!getFormularioCPF) {
            NotificacaoInterna.ExibirNotificacao("CPF não preenchido", "Informe o CPF do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (getFormularioCPF && CPF.TextoValido(getFormularioCPF) != true) {
            NotificacaoInterna.ExibirNotificacao("CPF invalido", "Verifique se o CPF do cliente foi digitado corretamente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (!getFormularioNacionalidade) {
            NotificacaoInterna.ExibirNotificacao("Nacionalidade não definida", "Informe a nacionalidade do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (!getFormularioEstadoCivil) {
            NotificacaoInterna.ExibirNotificacao("Estado civil não informado", "Informe o estado civil do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (!getFormularioCEP || CEP.DesformatarTexto(getFormularioCEP).length != 8) {
            NotificacaoInterna.ExibirNotificacao("CEP inválido", "Certifique-se de que o CEP digitado está correto", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (!getFormularioLogradouro) {
            NotificacaoInterna.ExibirNotificacao("Logradouro não preenchido", "Informe o logradouro do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (!getFormularioBairro) {
            NotificacaoInterna.ExibirNotificacao("Bairro não preenchido", "Informe o bairro do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (!getFormularioCidade) {
            NotificacaoInterna.ExibirNotificacao("Cidade não preenchido", "Informe a cidade do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        else if (!getFormularioUF) {
            NotificacaoInterna.ExibirNotificacao("UF não preenchido", "Informe a UF do endereço do cliente", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        return true;
    };

    const toolbarOptions: GridComponent.ToolbarItems[] = ['PdfExport']

    const groupOptions: GridComponent.GroupSettingsModel = {
        showGroupedColumn: true,
        showUngroupButton: true,
    }

    const filterOptions: GridComponent.FilterSettingsModel = {
        ignoreAccent: true,
        immediateModeDelay: 200,
        mode: "Immediate",
        type: 'Excel',
    }

    const sortOptions: GridComponent.SortSettingsModel = {

    }

    const selectionOptions: GridComponent.SelectionSettingsModel = {
        checkboxMode: 'ResetOnRowClick'
    }

    const pdfHeaderQueryCellInfo = (args: GridComponent.PdfHeaderQueryCellInfoEventArgs | any) => {
        (args.cell as any).row.pdfGrid.repeatHeader = true;
        args.cell.gridRow.pdfGrid.columns.getColumn(0).width = 100;
        args.cell.gridRow.pdfGrid.columns.getColumn(0).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(1).width = 100;
        args.cell.gridRow.pdfGrid.columns.getColumn(1).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(2).width = 60;
        args.cell.gridRow.pdfGrid.columns.getColumn(2).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(3).width = 40;
        args.cell.gridRow.pdfGrid.columns.getColumn(3).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(4).width = 80;
        args.cell.gridRow.pdfGrid.columns.getColumn(4).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(5).width = 50;
        args.cell.gridRow.pdfGrid.columns.getColumn(5).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(6).width = 50;
        args.cell.gridRow.pdfGrid.columns.getColumn(6).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(7).width = 90;
        args.cell.gridRow.pdfGrid.columns.getColumn(7).textAlign = "Left";
    }

    let grid: GridComponent.Grid | null;
    const toolbarClick = (args: any) => {
        if (grid) {
            if (args.item.id === 'grid_pdfexport') {
                (grid.columns[9] as GridComponent.Column).visible = false;
                grid.pdfExport({
                    fileName: `Disponibilidade ${moment(new Date(), true).format("YYYY-MM-DD")} - ${((getItemPickerCentroDeCusto?.empresa.id == 20) ? "PRE" : ((getItemPickerCentroDeCusto?.empresa.id == 21) ? "SPR" : ((getItemPickerCentroDeCusto?.empresa.id == 22) ? "SER" : ((getItemPickerCentroDeCusto?.empresa.id == 23) ? "PAR" : (getItemPickerCentroDeCusto?.empresa.id == 26 ? "PYR" : getItemPickerCentroDeCusto?.sigla)))))}.pdf`,
                    pageOrientation: "Landscape",
                    header: {
                        fromTop: 0,
                        height: 100,
                        contents: [
                            {
                                type: 'Text',
                                value: `${getItemPickerCentroDeCusto?.descricao}`,
                                position: { x: 390, y: 50 },
                                style: { textBrushColor: "#000000", fontSize: 20, hAlign: "Right" },
                            },
                            {
                                type: 'Text',
                                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:MM:SS")}`,
                                position: { x: 880, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 13 },
                            },
                        ],
                    },
                    footer: {
                        contents: [
                            {
                                type: 'Text',
                                value: `Powered by DigitalDEV`,
                                position: { x: 0, y: 0 },
                                style: { textBrushColor: "#000000", fontSize: 10 },
                            },
                            {
                                format: 'Página {$current} de {$total}',
                                pageNumberType: "Numeric",
                                position: { x: 910, y: 0 },
                                style: {
                                    fontSize: 13,
                                    hAlign: "Right",
                                    textBrushColor: '#000000',
                                },
                                type: 'PageNumber',
                            }
                        ],
                        fromBottom: 0,
                        height: 20,
                    },
                    theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                        },
                        record: {
                            bold: false,
                            fontColor: "#000000",
                            fontSize: 8,
                        },
                        caption: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                        }
                    },
                    allowHorizontalOverflow: false,
                });
            }
        }
    }

    const pdfExportComplete = () => {
        if (grid) {
            (grid.columns[9] as GridComponent.Column).visible = true;
        }
    }

    const templateNome = (props: any) => (
        <>
            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                <img style = {{borderRadius: 60, marginRight: "10px"}} width = {"30px"} height ={"30px"} src = {ProfileLogo}/>
                <div>{props.nome}</div>
            </div>
        </>
    );

    const templateFonte = (props: any) => (
        <>
            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                <img width = {"25%"} src = {ExcelLogo}/>
                {/* <img width = {props.id == 0 ? "40%" : "25%"} src = {props.id == 0 ? RDStation : ExcelLogo}/> */}
            </div>
        </>
    );

    const onClose = () => {
        setPreviewAvatar(undefined);
    }

    const onCrop = (preview: any) => {
        setPreviewAvatar(preview);
    }

    const onBeforeFileLoad = (elem: any) => {
        // if(elem.target.files[0].size > 1048576){
        //   alert("File is too big!");
        //   elem.target.value = "";
        // };
    }

    useEffect(() => {
        async function componentDidMount() {

            setListaPickerSalasDeVendas((await SalaDeVendas.Get(ContextSignIn.getContext().token)).data ?? []);

            setListaPickerVincularBrinde((await Brinde.Almoxarifado(ContextSignIn.getContext().token)).data ?? []);

            setListaPickerPrioridade((await Prioridade.Get(ContextSignIn.getContext().token)).data ?? []);

            setListaPickerSolicitante((await Logon.Usuarios(ContextSignIn.getContext().token, "")).data ?? []);

            setListaPickerResponsavel((await Logon.Usuarios(ContextSignIn.getContext().token, "")).data ?? []);

            let ItemSolicitante = {
                id: ContextSignIn.getContext().pessoa.id,
                nome: ContextSignIn.getContext().pessoa.nome,
                email: ContextSignIn.getContext().pessoa.emails ? {
                    classificacao: ContextSignIn.getContext().pessoa.emails[0].classificacao,
                    descricao: ContextSignIn.getContext().pessoa.emails[0].descricao
                } : {
                    classificacao: 0,
                    descricao: ""
                },
                cargo: {
                    id: ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo.id,
                    nome: ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo.nome
                }
            } as {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}

            setItemPickerSolicitante(ItemSolicitante);

            setItemPickerResponsavel(ItemSolicitante);

            setListaPickerDepartamentos((await Areas.Get(ContextSignIn.getContext().token)).data ?? []);

            setFormularioListaEstadoCivil((await Pessoa.EstadoCivil(ContextSignIn.getContext().token)).data ?? []);

            setListaPickerNacionalidade((await Nacao.Get(ContextSignIn.getContext().token)).data ?? []);

            setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? []);

            let ResponseFormulario = (await Formulario.Get(ContextSignIn.getContext().token)).data ?? [];

            let ListaDeTiposDeComponentesFormulario = [...getTiposDeComponentesFormulario];

            if (ResponseFormulario.length > 0)
            {
                ResponseFormulario[0].perguntas.map((item, index) => {
                    ListaDeTiposDeComponentesFormulario.push({
                        id: item.id + 5,
                        titulo: item.titulo,
                        classificacao: item.classificacao,
                        obrigatorio: item.obrigatorio,
                        resposta: item.resposta ?? ""
                    })
                })

                setDescricaoFormulario({id: ResponseFormulario[0].id, descricao: ResponseFormulario[0].descricao});
            }

            setTiposDeComponenesFormulario(ListaDeTiposDeComponentesFormulario);

            // var ResponseSalas = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
            //     .get(`/Identificador/VisaoGAV02/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}`))
            //     .data as Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }> ?? [];
            //     await setListaPickerSala(ResponseSalas ?? []);
        }
        componentDidMount();
    }, []);

    useEffect(() => {
        setListaDeLeads(getListaDeLeads);
    }, [getListaDeLeads]);

    var ObjetoPropostaDeVenda = {
        empresa: {} as Objeto.Empresa,
        centroDeCusto: {} as Objeto.CentroDeCusto,
        numero: 0,
        contratoCEF: "",
        dataDaVenda: new Date(),
        finalidadeDaCompra: 0,
        prospects: [] as Array<Objeto.Prospect>,
        modeloDeVenda: {} as Objeto.ModeloDeVenda,
        identificador: {} as Objeto.Identificador,
        titulosDeCorretagem: new Array<Objeto.TituloDeCorretagem>(),
        titulosDeIntermediacao: new Array<Objeto.TituloDeIntermediacao>(),
        titulosDeSinal: new Array<Objeto.TituloDeSinal>(),
        titulosDeEntrada: new Array<Objeto.TituloDeEntrada>(),
        titulosDeParcela: new Array<Objeto.TituloDeParcela>(),
        titulosConsolidados: new Array<Objeto.Titulo>(),
        salaDeVenda: getItemPickerSala?.cidade ?? "",
        estruturaDeComissao: new Array<Objeto.Comissionado>()
    } as Objeto.PropostaDeVenda;

    return (
        <Component.Container>

            {/** Dialog upload de image */}
            <Dialog
                style = {{width: '35%', backgroundColor: "#FFFFFF"}}
                icon = {"annotation"}
                onClose = {handleDialogProfileClose}
                title = {"Foto de perfil"}
                autoFocus = {true}
                isOpen = {getDialogProfile.isOpen}
                canEscapeKeyClose = {getDialogProfile.canEscapeKeyClose}
                canOutsideClickClose = {getDialogProfile.canOutsideClickClose}
                enforceFocus = {getDialogProfile.enforceFocus}
                usePortal = {getDialogProfile.usePortal} 
            >
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    
                    <div style = {{width: "300px", marginTop: "30px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Avatar
                            label = {"Escolha um arquivo"}
                            width={300}
                            height={300}
                            onCrop={onCrop}
                            onClose={onClose}
                            onBeforeFileLoad={onBeforeFileLoad}
                            src={getAvatar}
                        />
                    </div>

                </div>
            </Dialog>

            {/* Drawer gestão do lead */}
            <Drawer
                style = {{width: '100%', boxShadow: "none", border: "none"}}
                icon = {"people"}
                onClose = {handleClose}
                title = {"Gestão do lead"}
                autoFocus = {true}
                isOpen = {getDialogState.isOpen}
                canEscapeKeyClose = {getDialogState.canEscapeKeyClose}
                canOutsideClickClose = {getDialogState.canOutsideClickClose}
                enforceFocus = {getDialogState.enforceFocus}
                usePortal = {getDialogState.usePortal}>

                <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex", marginTop: "0px", backgroundColor: "#CCCCCC10", border: "none", boxShadow: "none"}}>

                    <div style = {{backgroundColor: "#FFFFFF", width: "35%"}}>
                        
                        <div style={{minHeight: (window.screen.height - 140), boxShadow: "none", borderRight: "0.1px solid #00000050", width: "100%", backgroundColor: "#FFFFFF", display: "flex", flexDirection: "column", alignItems: "center"}}>

                            <div style = {{height: "120px", borderBottom: "0.1px solid #00000050", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "#FFFFFF", width: "100%"}}>

                                {/** Foto de perfil, nome e email do lead */}
                                <div style = {{display: "flex", alignItems: "center"}}>
                                    <img style = {{borderRadius: 60}} height = {60} width = {60} src = {getPreviewAvatar} alt = "preview" onClick = {handleDialogProfileOpen}/>
                                    <div>
                                        <div style = {{fontWeight: "bold", marginLeft: "10px", fontSize: "18px"}}>{getFormularioNome}</div>
                                        <div style = {{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                            <div style = {{marginLeft: "5px", fontSize: "14px"}}>{getFormularioEmails[0]?.descricao}</div>
                                        </div>
                                    </div>
                                </div>

                                {/** Lista de tooltip`s */}
                                <div style = {{display: "flex", alignItems: "center", marginTop: "10px"}}>
                                    <div style = {{marginRight: "10px", height: "30px", width: "30px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "30px", backgroundColor: "#CCCCCC50"}}>
                                        <Tooltip html={(<div></div>)}>
                                            <Icon 
                                                icon = "application" 
                                                iconSize = {15} 
                                                color = {"#000000"}
                                                onClick = {() => {
                                                    setOptionAtividades(true)
                                                    setOptionAnotacoes(false)
                                                    setOptionTarefas(false)
                                                    setOptionEmails(false)
                                                }} 
                                            />
                                        </Tooltip>
                                    </div>
                                    <div style = {{marginRight: "10px", height: "30px", width: "30px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "30px", backgroundColor: "#CCCCCC50" }}>
                                        <Tooltip html={(<div></div>)}>
                                            <Icon 
                                                icon = "envelope" 
                                                iconSize = {15} 
                                                color = {"#000000"}
                                                onClick = {() => {
                                                    handleDialogCreateEmailOpen()
                                                }} 
                                            />
                                        </Tooltip>
                                    </div>
                                    <div style = {{marginRight: "10px", height: "30px", width: "30px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "30px", backgroundColor: "#CCCCCC50"}}>
                                        <Tooltip html={(<div></div>)}>
                                            <Icon 
                                                icon = "annotation" 
                                                iconSize = {15} 
                                                color = {"#000000"}
                                                onClick = {() => { handleDialogCreateAnnotationOpen() }} 
                                            />
                                        </Tooltip>
                                    </div>
                                    <div style = {{marginRight: "10px", height: "30px", width: "30px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "30px", backgroundColor: "#CCCCCC50"}}>
                                        <Tooltip html={(<div></div>)}>
                                            <Icon 
                                                icon = "bookmark" 
                                                iconSize = {15} 
                                                color = {"#000000"} 
                                                onClick = {() => {
                                                    setFormularioNomeTarefa("");
                                                    setFormularioPrevisaoDeInicio(moment(new Date()).subtract(1, "day").toDate())
                                                    setFormularioPrevisaoDeTermino(moment(new Date()).subtract(0, "day").toDate())
                                                    setFormularioDataDeTermino(undefined)
                                                    setItemPickerPrioridade(undefined)
                                                    setItemPickerSolicitante({
                                                        id: ContextSignIn.getContext().pessoa.id,
                                                        nome: ContextSignIn.getContext().pessoa.nome,
                                                        email: ContextSignIn.getContext().pessoa.emails ? {
                                                            classificacao: ContextSignIn.getContext().pessoa.emails[0].classificacao,
                                                            descricao: ContextSignIn.getContext().pessoa.emails[0].descricao
                                                        } : {
                                                            classificacao: 0,
                                                            descricao: ""
                                                        },
                                                        cargo: {
                                                            id: ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo.id,
                                                            nome: ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo.nome
                                                        }
                                                    })
                                                    setItemPickerSolicitante({
                                                        id: ContextSignIn.getContext().pessoa.id,
                                                        nome: ContextSignIn.getContext().pessoa.nome,
                                                        email: ContextSignIn.getContext().pessoa.emails ? {
                                                            classificacao: ContextSignIn.getContext().pessoa.emails[0].classificacao,
                                                            descricao: ContextSignIn.getContext().pessoa.emails[0].descricao
                                                        } : {
                                                            classificacao: 0,
                                                            descricao: ""
                                                        },
                                                        cargo: {
                                                            id: ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo.id,
                                                            nome: ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo.nome
                                                        }
                                                    })
                                                    setEditandoTarefa(false)
                                                    handleDialogCreateTaskOpen()
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                    <Tooltip html={(<div style = {{backgroundColor: '#CCCCCC', padding: "10px 10px", borderRadius: "5px"}}>
                                        <div style = {{fontSize: "12px", color: "#FFFFFF", fontWeight: "bold"}}>{`Finalizar atendimento`}</div>
                                    </div>)}>
                                        <div
                                            style = {{
                                                marginRight: "10px", 
                                                height: "30px", 
                                                width: "30px", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: "center", 
                                                borderRadius: "30px",
                                                background: "#f0000090", 
                                                color: "#FFFFFF", 
                                                border: "none", 
                                                boxShadow: "none",
                                                outline: "none",
                                        }}>
                                            <Icon 
                                                icon = {"log-out"} 
                                                iconSize = {14} 
                                                color = {"#FFFFFF"}
                                                onClick = { async () => {

                                                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "Lead está sendo retirado do controle de sala", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                    let ObjetoIntegranteControleDeSala = {} as { controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}

                                                    let ListaDeFluxosDeSala = [...getFluxoDeSala];

                                                    let ItemFluxo = {} as { etapa: number, descricao: string }

                                                    try {
                                                        ItemFluxo = {
                                                            etapa: ListaDeFluxosDeSala.filter(item => item.descricao == getFormularioKeyField)[0].etapa,
                                                            descricao: ListaDeFluxosDeSala.filter(item => item.descricao == getFormularioKeyField)[0].descricao
                                                        }
                                                    }
                                                    catch
                                                    {
                                                        ItemFluxo = {
                                                            etapa: 0,
                                                            descricao: "Recepção"
                                                        }
                                                    }

                                                    let CargoUsuario: {id: number, nome: string};

                                                    try {
                                                        CargoUsuario = ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo;
                                                    }
                                                    catch {
                                                        CargoUsuario = {
                                                            id: 0, 
                                                            nome: "" 
                                                        }
                                                    }
                                                    
                                                    ObjetoIntegranteControleDeSala = {
                                                        controleDeSala: {
                                                            id: getControleDeSala[0].id,
                                                            descricao: getControleDeSala[0].descricao
                                                        },
                                                        fluxoDeSala: {
                                                            etapa: ItemFluxo.etapa,
                                                            descricao: ItemFluxo.descricao
                                                        },
                                                        salaDeVenda: {
                                                            id: getItemPickerSalaDeVendas?.id ?? 0,
                                                            descricao: getItemPickerSalaDeVendas?.descricao ?? ""
                                                        },
                                                        pessoa: {
                                                            id: ContextSignIn.getContext().pessoa.id,
                                                            nome: ContextSignIn.getContext().pessoa.nome,
                                                            cargo: CargoUsuario,
                                                            email: ContextSignIn.getContext().pessoa.emails ? ContextSignIn.getContext().pessoa.emails[0] : null
                                                        }
                                                    }

                                                    let Response = await Lead.DeletarIntegrateDoControleDeSala(ContextSignIn.getContext().token, getFormularioID, ObjetoIntegranteControleDeSala)

                                                    if(Math.floor(Response.status / 100) == 2)
                                                    {

                                                        let ListaDeLeads = [...getListaDeLeads];

                                                        let ListaFiltrada = ListaDeLeads.filter(item => item.id != getFormularioID);

                                                        setListaDeLeads(ListaFiltrada);

                                                        NotificacaoInterna.ExibirNotificacao("Lead retirado do controle de sala com sucesso", "O lead não está mais vinculado ao controle de sala", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                        handleClose();
                                                    }
                                                    else
                                                    {
                                                        NotificacaoInterna.ExibirNotificacao("Erro ao desvincular o lead do controle de sala", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                    }

                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>

                            </div>

                            {/** Abrindo collapse dados do pessoais */}
                            <div
                                style = {{
                                    borderBottom: getCollapseDadosPessoais == true ? "0px" : '1px solid #00000020',
                                    borderTop: '1px solid #00000020', 
                                    borderLeft: '1px solid #00000020', 
                                    borderRight: '1px solid #00000020',
                                    borderBottomLeftRadius: getCollapseDadosPessoais == true ? "0px" : "5px", 
                                    borderBottomRightRadius: getCollapseDadosPessoais == true ? "0px" : "5px", 
                                    borderTopLeftRadius: "5px", 
                                    borderTopRightRadius: "5px",
                                    backgroundColor: "#FFFFFF",
                                    height: "42px",
                                    marginTop: "10px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    width: "calc(29vw + 50px)",
                                    cursor: "pointer"
                            }}
                                onClick = { async () => {  getCollapseDadosPessoais === true ? setCollapseDadosPesssoais(false) : setCollapseDadosPesssoais(true) }}>
                                <div style={{height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapseDadosPessoais === true ? setCollapseDadosPesssoais(false) : setCollapseDadosPesssoais(true)}>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: LottieFiles.SwipeDown,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={30}
                                        width={30}
                                        style={getCollapseDadosPessoais ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                                    />
                                </div>
                                <Button
                                    style = {{backgroundColor: "#FFFFFF", boxShadow: "none", border: "none", verticalAlign: "center", fontSize: "12px"}}
                                    text = {"Dados pessoais"}
                                    onClick = {() => getCollapseDadosPessoais === true ? setCollapseDadosPesssoais(false) : setCollapseDadosPesssoais(true)}
                                />
                            </div>

                            {/** Collapse dados pessoais */}
                            <Collapse isOpen = {getCollapseDadosPessoais}>
                                <Component.ContentCard style = {{ backgroundColor: "#FFFFFF"}}>
                                    <Component.ContentFormHeader style = {{padding: "0px", marginTop: "0px"}}>
                                        <div
                                            style = {{
                                                borderLeft: '1px solid #00000020', 
                                                borderRight: '1px solid #00000020', 
                                                padding: "5px 10px",
                                                width: "calc(29vw + 50px)"
                                        }}>
                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "Nome" : "Razão social"}</div>
                                                <Component.Input id="Nome" placeholder="Nome" value={getFormularioNome} style={{ width: "calc(29vw + 20px)", fontSize: "12px" }}
                                                    onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                        var TextoDigitado = event.currentTarget.value;
                                                        setFormularioNome(TextoDigitado)
                                                    }}>
                                                </Component.Input>
                                            </div>
                                            <div style = {{marginTop: "10px"}}>
                                                <div style = {{display: 'flex', alignItems: 'center', marginBottom: "5px", fontSize: "12px"}}>
                                                    <div style = {{marginRight: "5px", fontWeight: 'bold'}}>Email</div>
                                                    <Icon iconSize = {15} icon='add' 
                                                        onClick = { async () => {
                                                            let ListaDeEmails = [...getFormularioEmails]
                                                            ListaDeEmails.push({
                                                                classificacao: 1,
                                                                descricao: "",
                                                            })
                                                            setFormularioEmails(ListaDeEmails)
                                                    }}/>
                                                </div>
                                                {getFormularioEmails.map((item, index) => (
                                                    <div style = {{display: 'flex', alignItems: 'center', marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                        <Component.Input id="Email" placeholder="Email" value={item.descricao} style={{ width: "calc(29vw + 20px)", fontSize: "12px"}}
                                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                                var TextoDigitado = event.currentTarget.value;

                                                                let ListaDeEmails = [...getFormularioEmails]
                                                                ListaDeEmails.map((Item, Index) => {
                                                                    if (index == Index) {
                                                                        Item.descricao = TextoDigitado
                                                                    }
                                                                })

                                                                setFormularioEmails(ListaDeEmails)
                                                        }}>
                                                        </Component.Input>
                                                        {getFormularioEmails.length > 1 &&
                                                            <div style = {{marginLeft: '3px'}}>
                                                                <Icon iconSize = {15} icon='remove' onClick = { async () => { setFormularioEmails(getFormularioEmails.filter((Item, Index) => Index !== index)) }}/>  
                                                        </div>}
                                                    </div>
                                                ))}
                                            </div>
                                            <div style = {{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                                                <div style = {{marginBottom: "10px", fontWeight: "bold", marginRight: "20px", fontSize: "12px"}}>
                                                    <div style = {{marginBottom: "5px", fontSize: "12px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "CPF" : "CNPJ"}</div>
                                                    <div style = {{display: "flex", alignItems: "center"}}>
                                                        <Component.Input id="CPF" style = {{fontSize: "12px", width: "calc(16vw - 10px)"}} placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormularioCPF)}
                                                            onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                                var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                                                setFormularioCPF(TextoDigitado);
                                                                if (CPF.TextoValido(TextoDigitado) || CNPJ.TextoValido(TextoDigitado)) {
                                                                    setCPFEmConsulta(true);
                                                                    switch (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado) {
                                                                        case 4:
                                                                            try {
                                                                                let Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                                                                                    .get(`/Pessoa/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}/${TextoDigitado}`))
                                                                                    .data as any;
                                                                                setFormularioNome(Response.nome ?? "");

                                                                                console.log(Response)
                                                                                try {
                                                                                    setFormularioEmails(Response.emails.filter((thing: any, index: number) => {
                                                                                        const _thing = JSON.stringify(thing);
                                                                                        return index === Response.emails.findIndex((obj: any) => {
                                                                                            return JSON.stringify(obj) === _thing;
                                                                                        })
                                                                                    }))
                                                                                    await setFormularioDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                                                } catch {}

                                                                                try {
                                                                                    let Lista = [];
                                                                                    Lista = Response.telefones.filter((tel: any) => tel != null).filter((thing: any, index: number) => {
                                                                                        let _thing = JSON.stringify(thing);
                                                                                        return index === Response.telefones.findIndex((obj: any) => {
                                                                                            return JSON.stringify(obj) === _thing;
                                                                                        })
                                                                                    })
                                                                                    setFormularioTelefones(Lista);
                                                
                                                                                    let ListaDeTelefones: Array<{descricao: string}> = [];
                                                                                    Lista.map((Item: any, index: any) => {
                                                                                        ListaDeTelefones.push({
                                                                                            descricao: Item.ddd + Item.numero
                                                                                        })
                                                                                    })
                                                                                    setTelefones(ListaDeTelefones ?? [{descricao: ""}]);
                                                                                } catch {}

                                                                                try { setFormularioRG(Response.rg.numero ?? ""); } catch {}
                                                                                try { setFormularioRGOrgaoEmissor(Response.rg.orgaoEmissor ?? ""); }
                                                                                catch { }
                                                                                try { setFormularioRGUF(Response.rg.uf ?? ""); }
                                                                                catch { }
                                                                                setFormularioProfissao(Response.observacao ?? "");
                        
                                                                                setFormulacaoNacionalidade(getListaPickerNacionalidade.filter(x => (x.masculino.toUpperCase() ?? "Brasileiro".toUpperCase()) == Response.nacionalidade.toUpperCase())[0]);
                                                                                setFormularioCEP(Response.endereco.cep ?? "");
                                                                                setFormularioLogradouro(Response.endereco.logradouro ?? "");
                                                                                setFormularioComplemento(Response.endereco.complemento ?? "");
                                                                                setFormularioNumeroDeEndereco(Response.endereco.numero ?? "");
                                                                                setFormularioBairro(Response.endereco.bairro ?? "");
                                                                                setFormularioCidade(Response.endereco.cidade ?? "");
                                                                                setFormularioUF(Response.endereco.uf ?? "");
                                                                                setFormularioEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                                            }
                                                                            catch { }
                                                                            finally { setCPFEmConsulta(false); }
                                                                            break;
                                                                        case 6:
                                                                            try {
                                                                                let Response = (await Pessoa.Get(ContextSignIn.getContext().token, TextoDigitado)).data;
                                                                                await setFormularioNome(Response.nome ?? "");
                                                                                // await setFormularioEmail(Response.emails[0].descricao ?? "");
                                                                                await setFormularioDataDeNascimento(new Date(Response.dataDeNascimento) ?? "");
                                                                                // try {
                                                                                //     let TelefoneCelular = Response.telefones.find((Item: any) => Item.classificacao == 1);
                                                                                //     await setFormularioTelefoneCelular(Telefone.FormatarTexto((TelefoneCelular?.ddd ?? "") + (TelefoneCelular?.numero ?? "")));
                                                                                // }
                                                                                // catch { }
                                                                                // try {
                                                                                //     let TelefoneComercial = Response.telefones.find((Item: any) => Item.classificacao == 2);
                                                                                //     await setFormularioTelefoneComercial(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                                                // }
                                                                                // catch { }
                                                                                try { await setFormularioRG(Response.rg?.numero ?? ""); }
                                                                                catch { }
                                                                                try { await setFormularioRGOrgaoEmissor(Response.rg?.orgaoemissor ?? ""); }
                                                                                catch { }
                                                                                try { await setFormularioRGUF(Response.rg?.uf ?? ""); }
                                                                                catch { }
                                                                                await setFormularioProfissao(Response.ocupacao?.nome ?? "");
                        
                                                                                await setFormulacaoNacionalidade(getListaPickerNacionalidade.filter(x => (x.masculino.toUpperCase() ?? "Brasileiro".toUpperCase()) == Response.nacionalidade?.masculino.toUpperCase())[0]);
                                                                                await setFormularioCEP(Response.endereco?.cep ?? "");
                                                                                await setFormularioLogradouro(Response.endereco?.logradouro ?? "");
                                                                                await setFormularioComplemento(Response.endereco?.complemento ?? "");
                                                                                await setFormularioNumeroDeEndereco(Response.endereco?.numero ?? "");
                                                                                await setFormularioBairro(Response.endereco?.bairro ?? "");
                                                                                await setFormularioCidade(Response.endereco?.cidade ?? "");
                                                                                await setFormularioUF(Response.endereco?.uf ?? "");
                                                                                await setFormularioEstadoCivil(getFormularioListaEstadoCivil?.find((Item) => Item.id == Response.estadoCivil));
                                                                            }
                                                                            catch { }
                                                                            finally { await setCPFEmConsulta(false); }
                                                                            break;
                                                                    }
                                                                }
                                                            }}>
                                                        </Component.Input>
                                                        {getCPFEmConsulta && <Icon icon = "geosearch" style = {{marginLeft: "10px"}} iconSize = {15}/>}
                                                    </div>
                                                </div>
                                                <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                    <div style = {{marginBottom: "5px", fontSize: "12px"}}>Data de nascimento</div>
                                                    <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                                        popoverProps = {{disabled: !getFormularioExibirCalendarioDataDeNascimento, onClosed: () => setFormularioExibirCalendarioDataDeNascimento(false)}}
                                                        placeholder={"DD/MM/AAAA"} onChange = { async (DataSelecionada: Date) => { setFormularioDataDeNascimento(DataSelecionada) }}
                                                        value = {getFormularioDataDeNascimento != undefined ? new Date(String(getFormularioDataDeNascimento)) : getFormularioDataDeNascimento} maxDate = {new Date()} 
                                                        inputProps = {{style: {fontSize: "12px", backgroundColor: lighten(0.1, '#D3D3D3')}, onClick: () => setFormularioExibirCalendarioDataDeNascimento(true)}} 
                                                        minDate = {new Date((new Date().getFullYear() - 100).toString() + "-01-01")}>
                                                    </DateInput>
                                                </div>
                                                {CPF.DesformatarTexto(getFormularioCPF).length <= 11 && <div>
                                                    <div style = {{display: 'flex', alignItems: "center", flexWrap: "wrap"}}>
                                                        <div style = {{ marginBottom: "10px", fontWeight: "bold", marginRight: "10px", fontSize: "12px"}}>
                                                            <div style = {{marginBottom: "5px", fontSize: "12px"}}>RG</div>
                                                            <Component.Input style={{fontSize: "12px", width: "calc(9vw + 15px)"}} id="RG" placeholder={"00000000000"} value={getFormularioRG}
                                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                                    var TextoDigitado = event.currentTarget.value;
                                                                    await setFormularioRG(TextoDigitado)
                                                                }}>
                                                            </Component.Input>
                                                        </div>
                                                        <div style = {{marginBottom: "10px", fontWeight: "bold", marginRight: "10px", fontSize: "12px"}}>
                                                            <div style = {{marginBottom: "5px", fontSize: "12px"}}>Orgão emissor</div>
                                                            <Component.Input id="OrgaoEmissor" style = {{ fontSize: "12px", width: "calc(9vw + 10px)"}} placeholder={"Informe o orgão emissor"} value={getFormularioRGOrgaoEmissor}
                                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                                    var TextoDigitado = event.currentTarget.value;
                                                                    setFormularioRGOrgaoEmissor(TextoDigitado)
                                                                }}>
                                                            </Component.Input>
                                                        </div>
                                                        <div style = {{marginBottom: "10px", fontWeight: "bold", marginRight: "10px", fontSize: "12px"}}>
                                                            <div style = {{marginBottom: "5px", fontSize: "12px"}}>UF</div>
                                                            <HTMLSelect style={{fontSize: "12px", width: "7vw"}} id="RGUF" name="RGUF" value={getFormularioRGUF}
                                                                onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    var TextoDigitado = event.currentTarget.value;
                                                                    setFormularioRGUF(TextoDigitado)
                                                                }}>
                                                                <option selected value="">Selecione</option>
                                                                {UF.map((Item) =>
                                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                                )}
                                                            </HTMLSelect>
                                                            {getFormularioRGUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {await setFormularioRGUF("")}} 
                                                            />}
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </Component.ContentFormHeader>
                                    <Component.FormContent>
                                        <Component.ContentFormHeader>
                                            <Component.FormContent>
                                                <div
                                                    style = {{
                                                        width: "100%",
                                                        alignItems: "center",
                                                        borderRadius: "2px",
                                                        marginTop: "-20px"
                                                }}>
                                                    <div 
                                                        style = {{
                                                            borderLeft: '1px solid #00000020', 
                                                            borderRight: '1px solid #00000020', 
                                                            padding: "5px 10px",
                                                            width: "calc(29vw + 50px)"
                                                    }}>
                                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                            <div style = {{display: 'flex', alignItems: "center", marginBottom: "5px"}}>
                                                                <div style = {{marginRight: "5px", fontSize: "12px"}}>Telefone</div>
                                                                <Icon 
                                                                    iconSize = {15} 
                                                                    icon = {'add'}
                                                                    onClick = {() => {
                                                                        let ListaDeTelefones = [...getTelefones]
                                                                        ListaDeTelefones.push({
                                                                            descricao: ""
                                                                        })
                                                                        setTelefones(ListaDeTelefones)
                                                                }}/>
                                                            </div>
                                                            {getTelefones.map((item, index) => {
                                                                return (
                                                                    <div style = {{marginBottom: "10px"}}>
                                                                        <Component.Input id="TelefoneComercial" style = {{fontSize: 12, width: "calc(29vw + 20px)"}} placeholder={Telefone.FormatarTexto("00000000000")} value={Telefone.FormatarTexto(item.descricao)}
                                                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                                                
                                                                                var TextoDigitado = event.currentTarget.value

                                                                                let ListaDeTelefones = [...getTelefones]

                                                                                ListaDeTelefones.map((Item, Index) => {
                                                                                    if (index == Index) {
                                                                                        Item.descricao = TextoDigitado
                                                                                    }
                                                                                })

                                                                                setTelefones(ListaDeTelefones)
                                                                            }}>
                                                                        </Component.Input>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    {CPF.DesformatarTexto(getFormularioCPF).length <= 11 &&
                                                        <div
                                                            style = {{
                                                                display: 'flex', 
                                                                alignItems: 'center', 
                                                                borderLeft: '1px solid #00000020', 
                                                                borderRight: '1px solid #00000020', 
                                                                padding: "5px 10px", 
                                                                width: "calc(29vw + 50px)"
                                                        }}>
                                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px", marginRight: "10px"}}>
                                                                <div style = {{marginBottom: "5px"}}>Profissão</div>
                                                                <Component.Input style={{ width: "calc(24vw + -120px)", fontSize: 12 }} id="profissao" size={30} placeholder="Informe a profissão" value={getFormularioProfissao}
                                                                    onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                                                        var TextoDigitado = event.currentTarget.value;
                                                                        setFormularioProfissao(TextoDigitado)
                                                                    }}>
                                                                </Component.Input>
                                                            </div>
                                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                <div style = {{marginBottom: "5px"}}>Nacionalidade</div>
                                                                {getListaPickerNacionalidade.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                                                                <PickerNacionalidade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerNacionalidade} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                                    itemRenderer={(Item: Objeto.Nacao) => <MenuItem onClick={async () => {setFormulacaoNacionalidade(Item)}} text={Item.nacionalidade ?? ""} />}
                                                                    itemPredicate={(Texto: string, Item: Objeto.Nacao) => { return (Item.nacionalidade ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                                    <Button text={getFormularioNacionalidade?.nacionalidade ?? "Selecione a nacionalidade"} style = {{fontSize: "12px"}} rightIcon="double-caret-vertical" />
                                                                </PickerNacionalidade>
                                                                {getFormularioNacionalidade && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {await setFormulacaoNacionalidade(undefined)}} />}
                                                            </div>
                                                    </div>}
                                                </div>
                                            </Component.FormContent>
                                            <FormGroup labelFor = "EstadoCivil" style = {{ backgroundColor: "0" }}>
                                                <div
                                                    style = {{
                                                        borderLeft: '1px solid #00000020',
                                                        borderRight: '1px solid #00000020',
                                                        padding: "5px 10px", 
                                                        width: "calc(29vw + 50px)"
                                                }}>
                                                    <div style = {{fontWeight: "bold", marginBottom: "10px", marginTop: "10px", fontSize: "12px"}}>Estado civil</div>
                                                    <div style = {{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                                                        {getFormularioListaEstadoCivil?.map((Item) =>
                                                            <Radio 
                                                                style = {{ 
                                                                    display: "flex", 
                                                                    alignItems: "center", 
                                                                    fontSize: "12px" 
                                                                }} 
                                                                name = "EstadoCivil" 
                                                                label = {Item.descricao} 
                                                                value = {Item.id}
                                                                onChange = { async () => setFormularioEstadoCivil(Item)} 
                                                                checked = {Item == getFormularioEstadoCivil}>
                                                            </Radio>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    style = {{
                                                        borderLeft: '1px solid #00000020',
                                                        borderRight: '1px solid #00000020',
                                                        borderBottom: '1px solid #00000020', 
                                                        width: "calc(29vw + 50px)",
                                                        marginBottom: "20px", 
                                                        display: "flex", 
                                                        flexDirection: "column", 
                                                        alignItems: "flex-end",
                                                        borderBottomLeftRadius: "5px", 
                                                        borderBottomRightRadius: "5px", 
                                                }}>
                                                    <Button
                                                        icon = {<Icon icon = "refresh" iconSize = {14} color = {"#FFFFFF"}/>} 
                                                        style = {{
                                                            background: "#1098F7", 
                                                            color: "#FFFFFF", 
                                                            border: "none", 
                                                            boxShadow: "none", 
                                                            borderRadius: "5px", 
                                                            outline: "none", 
                                                            display: "flex", 
                                                            alignItems: "center", 
                                                            fontSize: "12px",
                                                            marginRight: '10px',
                                                            marginBottom: "10px"
                                                        }}  
                                                        onClick = { async () => {
                                                            if(await ValidarAtualizacaoDoLead() == true)
                                                            {
                                                                setAtualizandoOportunidade(true)
            
                                                                NotificacaoInterna.ExibirNotificacao("Caro usuário", "Lead está sendo atualizado 😁", NotificacaoInterna.TipoDeNotificacao.Informacao);
                
                                                                var ObjetoTelefones = [] as Array<Objeto.Telefone>;
                
                                                                if(getTelefones.length > 0 && getTelefones.filter((item, index) => item.descricao == "").length == 0)
                                                                {
                                                                    getTelefones.map((item, index) => {
                                                                        var TelefoneDesFormatado = Telefone.DesformatarTexto(item?.descricao ?? "")
                                                                        var ObterNumero = Telefone.ObterNumero(TelefoneDesFormatado)
                    
                                                                        if(ObterNumero[3] == "3" || ObterNumero[3] == "4")
                                                                        {
                                                                            ObjetoTelefones.push({
                                                                                classificacao: 2,
                                                                                ddi: "55",
                                                                                ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                                numero: Telefone.ObterNumero(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                                observacao: ""
                                                                            })
                                                                        }
                                                                        else {
                                                                            ObjetoTelefones.push({
                                                                                classificacao: 1,
                                                                                ddi: "55",
                                                                                ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                                numero: Telefone.ObterNumero(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                                observacao: ""
                                                                            })
                                                                        }
                                                                    })
                                                                }
                
                                                                var ObjetoLead = {} as Objeto.Lead;
                                                                ObjetoLead = {
                                                                    id: getFormularioID,
                                                                    cpf: getFormularioCPF != "" ? getFormularioCPF : null,
                                                                    rg: {
                                                                        numero: getFormularioRG,
                                                                        orgaoemissor: getFormularioRGOrgaoEmissor,
                                                                        uf: getFormularioRGUF
                                                                    },
                                                                    dataDeNascimento: getFormularioDataDeNascimento,
                                                                    ocupacao: {
                                                                        id: 0,
                                                                        nome: getFormularioProfissao,
                                                                        cargo: ""
                                                                    },
                                                                    nacionalidade: getFormularioNacionalidade,
                                                                    nome: getFormularioNome != "" ? getFormularioNome : null,
                                                                    emails: (getFormularioEmails.filter((item, index) => item.descricao == "").length == 0) ? getFormularioEmails : null,
                                                                    telefones: (ObjetoTelefones.length > 0) ? ObjetoTelefones : null,
                                                                    endereco: {
                                                                        classificacao: 0,
                                                                        cep: getFormularioCEP,
                                                                        logradouro: getFormularioLogradouro,
                                                                        numero: getFormularioNumeroDeEndereco,
                                                                        complemento: getFormularioComplemento,
                                                                        bairro: getFormularioBairro,
                                                                        cidade: getFormularioCidade,
                                                                        uf: getFormularioUF
                                                                    },
                                                                    estadoCivil: getFormularioEstadoCivil ? getFormularioEstadoCivil?.id : null,
                                                                    dependentes: null,
                                                                    localDeCaptacao: undefined,
                                                                    historicoDoFunil: getListaHistoricoDoFunil,
                                                                    atividades: getFormularioListaAtividades.length > 0 ? getFormularioListaAtividades : null,
                                                                    anotacoes: getFormularioListaAnotacoes.length > 0 ? getFormularioListaAnotacoes : null,
                                                                    emailsEnviados: getFormularioListaEmails.length > 0 ? getFormularioListaEmails : null,
                                                                    tarefas: getFormularioListaTarefas.length > 0 ? getFormularioListaTarefas : null,
                                                                    keyField: getFormularioKeyField,
                                                                    corDoCard: "#ffffff"
                                                                } as Objeto.Lead;

                                                                ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
                                                                ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;

                                                                try {
                                                                    ObjetoLead.dependentes?.forEach(Dependente => {
                                                                        Dependente.telefones = Dependente.telefones?.filter((Item) => Item) ?? null;
                                                                    });
                                                                }
                                                                catch { }

                                                                console.log(JSON.stringify(ObjetoLead))
                                                                var Response = await Lead.Put(ContextSignIn.getContext().token, ObjetoLead);
                                                                if (Math.floor(Response.status / 100) == 2)
                                                                {

                                                                    let ListaDeLeads = [...getListaDeLeads];

                                                                    let IndexLead = ListaDeLeads.findIndex(lead => lead.id == Response.data.id);

                                                                    ListaDeLeads[IndexLead] = Response.data;

                                                                    setListaDeLeads(ListaDeLeads);
                                                                    NotificacaoInterna.ExibirNotificacao("Lead atualizado com sucesso", "Registro devidamente atualizado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                    setAtualizandoOportunidade(false);
                                                                }
                                                                else
                                                                {
                                                                    setAtualizandoOportunidade(false);
                                                                    NotificacaoInterna.ExibirNotificacao("Falha ao atualizar o lead", "😱 Houve uma falha ao atualizar o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                                }
                                                            }
                                                    }}>Atualizar</Button>
                                                </div>
                                            </FormGroup>
                                        </Component.ContentFormHeader>
                                    </Component.FormContent>
                                </Component.ContentCard>
                            </Collapse>

                            {/** Abrindo collapse dados do endereço */}
                            <div
                                style = {{
                                    borderBottom: getCollapseDadosEndereco == true ? "0px" : '1px solid #00000020',
                                    borderTop: '1px solid #00000020', 
                                    borderLeft: '1px solid #00000020', 
                                    borderRight: '1px solid #00000020',
                                    borderBottomLeftRadius: getCollapseDadosEndereco == true ? "0px" : "5px", 
                                    borderBottomRightRadius: getCollapseDadosEndereco == true ? "0px" : "5px", 
                                    borderTopLeftRadius: "5px", 
                                    borderTopRightRadius: "5px", 
                                    backgroundColor: "#FFFFFF",
                                    height: "42px",
                                    marginTop: "10px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    width: "calc(29vw + 50px)",
                                    cursor: "pointer"
                                }}
                                onClick = {() => getCollapseDadosEndereco === true ? setCollapseDadosEndereco(false) : setCollapseDadosEndereco(true) }>
                                <div style={{height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapseDadosEndereco === true ? setCollapseDadosEndereco(false) : setCollapseDadosEndereco(true)}>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: LottieFiles.SwipeDown,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={30}
                                        width={30}
                                        style={getCollapseDadosEndereco ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                                    />
                                </div>
                                <Button
                                    style = {{backgroundColor: "#FFFFFF", boxShadow: "none", border: "none", verticalAlign: "center", fontSize: "12px"}}
                                    text = {"Dados complementares"}
                                    onClick = {() => getCollapseDadosEndereco === true ? setCollapseDadosEndereco(false) : setCollapseDadosEndereco(true) }
                                />
                            </div>

                            {/** Collapse dados do endereço */}
                            <Collapse isOpen = {getCollapseDadosEndereco}>
                                <Component.ContentCard style = {{backgroundColor: "#FFFFFF"}}>
                                    <Component.ContentFormHeader
                                        style = {{
                                            borderBottom: '1px solid #00000020',
                                            borderLeft: '1px solid #00000020',
                                            borderRight: '1px solid #00000020',
                                            padding: "5px 10px",
                                            borderBottomLeftRadius: "5px",
                                            borderBottomRightRadius: "5px", 
                                            width: "calc(29vw + 50px)",
                                            marginTop: 0
                                    }}>
                                        <div
                                            style = {{
                                                marginBottom: "10px", fontWeight: "bold", fontSize: "12px"
                                        }}>
                                            <div style = {{marginBottom: "5px"}}>CEP</div>
                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                <Component.Input id="CEP" placeholder="Informe o CEP" value={CEP.FormatarTexto(getFormularioCEP)} style={{ width: "calc(29vw + 20px)", fontSize: "12px" }}
                                                    onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                        var CEPDesformatado = CEP.DesformatarTexto(event.currentTarget.value);
                                                        setFormularioCEP(CEPDesformatado);
                                                        if (CEPDesformatado.length == 8) {
                                                            try {
                                                                setCEPEmConsulta(true);
                                                                var Response = (await Correios.Get(ContextSignIn.getContext().token, CEPDesformatado)).data;
                                                                setFormularioLogradouro(Response.Logradouro);
                                                                setFormularioComplemento(Response.Complemento);
                                                                setFormularioBairro(Response.Bairro);
                                                                setFormularioCidade(Response.Cidade);
                                                                setFormularioUF(Response.UF);
                                                            }
                                                            catch { }
                                                            finally { setCEPEmConsulta(false); }
                                                        }
                                                    }}>
                                                </Component.Input>
                                                {getCEPEmConsulta && <Icon icon = "geosearch" style = {{marginLeft: "10px"}} iconSize = {15}/>}
                                            </div>
                                        </div>
                                        <div style = {{display: "flex", alignItems: "center"}}>
                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px", marginRight: "20px"}}>
                                                <div style = {{marginBottom: "5px"}}>Logradouro</div>
                                                <Component.Input id="Logradouro:" placeholder="Informe o logradouro" value={getFormularioLogradouro} style={{ width: "calc(17vw + 0px)", cursor: "auto", fontSize: "12px" }}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                        setFormularioLogradouro(event.currentTarget.value);
                                                    }}>
                                                </Component.Input>
                                            </div>
                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                <div style = {{marginBottom: "5px"}}>Número</div>
                                                <Component.Input id="NumeroDeEndereco" maxLength={10} placeholder={Numero.FormatarTextoParaInteiro(0)} value={getFormularioNumeroDeEndereco} style={{ width: "calc(11vw + 15px)", marginLeft: "0", cursor: "auto", fontSize: "12px" }}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                        setFormularioNumeroDeEndereco(event.currentTarget.value);
                                                    }}>
                                                </Component.Input>
                                            </div>
                                        </div>
                                        <div style = {{ marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>Complemento</div>
                                            <Component.Input id="Complemento" placeholder="Informe o complemento" value={getFormularioComplemento} style={{ width: "calc(29vw + 20px)", cursor: "auto", fontSize: "12px" }}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    setFormularioComplemento(event.currentTarget.value);
                                                }}>
                                            </Component.Input>
                                        </div>
                                        <div style = {{ marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>Bairro</div>
                                            <Component.Input id="Bairro" placeholder="Informe o bairro" value={getFormularioBairro} style={{ width: "calc(29vw + 20px)", cursor: "auto", fontSize: "12px" }}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    setFormularioBairro(event.currentTarget.value);
                                                }}>
                                            </Component.Input>
                                        </div>
                                        <div style = {{display: "flex", alignItems: "center"}}>
                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                <div style = {{marginBottom: "5px"}}>Cidade</div>
                                                <Component.Input id="Cidade" placeholder="Informe o cidade" value={getFormularioCidade} style={{ width: "calc(17.5vw + 0px)", marginRight: "0.6vw", cursor: "auto", fontSize: "12px" }}
                                                    onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                        setFormularioCidade(event.currentTarget.value)
                                                    }}>
                                                </Component.Input>
                                            </div>
                                            <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                <div style = {{marginBottom: "5px"}}>UF</div>
                                                <HTMLSelect style={{ fontSize: "12px" }} id="uf" name="uf" value={getFormularioUF}
                                                    onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                        setFormularioUF(event.currentTarget.value)
                                                    }}>
                                                    <option selected value="">Selecione a UF</option>
                                                    {UF.map((Item) =>
                                                        <option selected value={Item.chave}>{Item.Valor}</option>
                                                    )}
                                                </HTMLSelect>
                                                {getFormularioUF && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {setFormularioUF("");}} />}
                                            </div>
                                        </div>
                                        <div
                                            style = {{
                                                width: "calc(29vw + 50px)",
                                                display: "flex", 
                                                flexDirection: "column", 
                                                alignItems: "flex-end",
                                                marginTop: "10px"
                                        }}>
                                            <Button
                                                icon = {<Icon icon = "refresh" iconSize = {14} color = {"#FFFFFF"}/>} 
                                                style = {{
                                                    background: "#1098F7", 
                                                    color: "#FFFFFF", 
                                                    border: "none", 
                                                    boxShadow: "none", 
                                                    borderRadius: "5px", 
                                                    outline: "none", 
                                                    display: "flex", 
                                                    alignItems: "center", 
                                                    fontSize: "12px",
                                                    marginRight: '20px',
                                                    marginBottom: "5px"
                                                }}  
                                                onClick = { async () => {
                                                    if(await ValidarAtualizacaoDoLead() == true)
                                                    {
                                                        setAtualizandoOportunidade(true)
    
                                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Lead está sendo atualizado 😁", NotificacaoInterna.TipoDeNotificacao.Informacao);
        
                                                        var ObjetoTelefones = [] as Array<Objeto.Telefone>;
        
                                                        if(getTelefones.length > 0 && getTelefones.filter((item, index) => item.descricao == "").length == 0)
                                                        {
                                                            getTelefones.map((item, index) => {
                                                                var TelefoneDesFormatado = Telefone.DesformatarTexto(item?.descricao ?? "")
                                                                var ObterNumero = Telefone.ObterNumero(TelefoneDesFormatado)
            
                                                                if(ObterNumero[3] == "3" || ObterNumero[3] == "4")
                                                                {
                                                                    ObjetoTelefones.push({
                                                                        classificacao: 2,
                                                                        ddi: "55",
                                                                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                        observacao: ""
                                                                    })
                                                                }
                                                                else {
                                                                    ObjetoTelefones.push({
                                                                        classificacao: 1,
                                                                        ddi: "55",
                                                                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                        observacao: ""
                                                                    })
                                                                }
                                                            })
                                                        }
        
                                                        var ObjetoLead = {} as Objeto.Lead;
                                                        ObjetoLead = {
                                                            id: getFormularioID,
                                                            cpf: getFormularioCPF != "" ? getFormularioCPF : null,
                                                            rg: {
                                                                numero: getFormularioRG,
                                                                orgaoemissor: getFormularioRGOrgaoEmissor,
                                                                uf: getFormularioRGUF
                                                            },
                                                            dataDeNascimento: getFormularioDataDeNascimento,
                                                            ocupacao: {
                                                                id: 0,
                                                                nome: getFormularioProfissao,
                                                                cargo: ""
                                                            },
                                                            nacionalidade: getFormularioNacionalidade,
                                                            nome: getFormularioNome != "" ? getFormularioNome : null,
                                                            emails: (getFormularioEmails.filter((item, index) => item.descricao == "").length == 0) ? getFormularioEmails : null,
                                                            telefones: (ObjetoTelefones.length > 0) ? ObjetoTelefones : null,
                                                            endereco: {
                                                                classificacao: 0,
                                                                cep: getFormularioCEP,
                                                                logradouro: getFormularioLogradouro,
                                                                numero: getFormularioNumeroDeEndereco,
                                                                complemento: getFormularioComplemento,
                                                                bairro: getFormularioBairro,
                                                                cidade: getFormularioCidade,
                                                                uf: getFormularioUF
                                                            },
                                                            estadoCivil: getFormularioEstadoCivil ? getFormularioEstadoCivil?.id : null,
                                                            dependentes: null,
                                                            localDeCaptacao: undefined,
                                                            historicoDoFunil: getListaHistoricoDoFunil,
                                                            atividades: getFormularioListaAtividades.length > 0 ? getFormularioListaAtividades : null,
                                                            anotacoes: getFormularioListaAnotacoes.length > 0 ? getFormularioListaAnotacoes : null,
                                                            emailsEnviados: getFormularioListaEmails.length > 0 ? getFormularioListaEmails : null,
                                                            tarefas: getFormularioListaTarefas.length > 0 ? getFormularioListaTarefas : null,
                                                            keyField: getFormularioKeyField,
                                                            corDoCard: "#ffffff"
                                                        } as Objeto.Lead;

                                                        ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
                                                        ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;

                                                        try {
                                                            ObjetoLead.dependentes?.forEach(Dependente => {
                                                                Dependente.telefones = Dependente.telefones?.filter((Item) => Item) ?? null;
                                                            });
                                                        }
                                                        catch { }

                                                        console.log(JSON.stringify(ObjetoLead))
                                                        var Response = await Lead.Put(ContextSignIn.getContext().token, ObjetoLead);
                                                        if (Math.floor(Response.status / 100) == 2)
                                                        {

                                                            let ListaDeLeads = [...getListaDeLeads];

                                                            let IndexLead = ListaDeLeads.findIndex(lead => lead.id == Response.data.id);

                                                            ListaDeLeads[IndexLead] = Response.data;

                                                            setListaDeLeads(ListaDeLeads);
                                                            NotificacaoInterna.ExibirNotificacao("Lead atualizado com sucesso", "Registro devidamente atualizado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                            setAtualizandoOportunidade(false);
                                                        }
                                                        else
                                                        {
                                                            setAtualizandoOportunidade(false);
                                                            NotificacaoInterna.ExibirNotificacao("Falha ao atualizar o lead", "😱 Houve uma falha ao atualizar o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        }
                                                    }
                                            }}>Atualizar</Button>
                                        </div>
                                    </Component.ContentFormHeader>
                                </Component.ContentCard>
                            </Collapse>
 
                            {/** Histórico do controle de sala e mudança de fase */}
                            <div style = {{marginTop: "20px", backgroundColor: "#FFFFFF"}}>
                                <div
                                    style = {{
                                        borderTop: '1px solid #00000020',
                                        borderBottom: '1px solid #00000020',
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderRadius: "5px",
                                        backgroundColor: "#FFFFFF",
                                        display: "flex", 
                                        flexDirection: "column",
                                        alignItems: "center",
                                        marginTop: "0px",
                                        width: "calc(29vw + 50px)",
                                }}>

                                    <div style = {{display: "flex", alignItems: "center", marginBottom: "20px", width: "calc(28vw + 50px)", marginTop: "10px"}}>
                                            <Button
                                                style = {{
                                                    background: getOptionSituacaoNoControleDeSala == true ? "#1098F7"  : "#1098F730",
                                                    color: getOptionSituacaoNoControleDeSala == true ? "#FFFFFF" : "#000000",
                                                    fontWeight: "bolder",
                                                    boxShadow: "none",
                                                    outline: "none",
                                                    display: "flex", 
                                                    alignItems: "center",
                                                    fontSize: "12px",
                                                    padding: "3px 5px",
                                                    borderRadius: "5px",
                                                    width: "260px",
                                                    marginRight: "30px",
                                                    transition: "background 0.5s, color 0.5s"
                                                }}
                                                text = {"Histórico do controle de sala"}
                                                onClick = {() => {
                                                    setOptionSituacaoNoControleDeSala(true)
                                                    setOptionMudarFaseNoControleDeSala(false)
                                                }}
                                            />
                                        <div style = {{display: "flex", alignItems: "center"}}>
                                            {<Button
                                                style = {{
                                                    background: getOptionMudarFaseNoControleDeSala == true ? "#D36582" :  "#D3658230", 
                                                    color: getOptionMudarFaseNoControleDeSala == true ? "#FFFFFF" : "#000000",
                                                    fontWeight: "bolder",
                                                    boxShadow: "none",
                                                    outline: "none",
                                                    display: "flex", 
                                                    alignItems: "center", 
                                                    fontSize: "12px",
                                                    padding: "3px 5px",
                                                    borderRadius: "5px",
                                                    width: "200px",
                                                    transition: "background 0.5s, color 0.5s"
                                                }}
                                                text = {"Fases do controle de sala"}
                                                onClick = {() => {
                                                    setOptionMudarFaseNoControleDeSala(true)
                                                    setOptionSituacaoNoControleDeSala(false)
                                                }}
                                            />}
                                        </div>
                                    </div>
                                
                                    {getOptionSituacaoNoControleDeSala == true && <>
                                        {getListaHistoricaoDoControleDeSala.map((item: { controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}, index: number) => (
                                            <div key = {item.fluxoDeSala.etapa} style = {{display: "flex", alignItems: "center"}}>
                                                <div
                                                    key = {item.fluxoDeSala.etapa}
                                                    style = {{
                                                        border: "1px dotted #00000050", 
                                                        borderRadius: "5px", 
                                                        height: "42px", 
                                                        background: getFormularioPosicaoHistoricoFunil?.id == item.fluxoDeSala.etapa ? "#1098F730" : "#FFFFFF", 
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "calc(28vw + 50px)",
                                                        cursor: "pointer",
                                                        marginBottom: "10px",
                                                        marginTop: "10px",
                                                    }}
                                                    onClick = {() => {
                                                        setFormularioPosicaoHistoricoDoControleDeSala({
                                                            etapa: item.fluxoDeSala.etapa,
                                                            descricao: item.fluxoDeSala.descricao
                                                        })
                                                    }}>
                                                    <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "10px", paddingRight: "10px"}}>
                                                        <div style = {{display: "flex", alignItems: "center"}}>
                                                            <div style = {{width: "20px", height: "20px", borderRadius: "20px", border: "0.4px solid #225", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "10px", backgroundColor: "#FFFFFF90"}}>
                                                                <div style = {{fontWeight: "bolder", fontSize: "9px", textAlign: "center", verticalAlign: "middle"}}>{Numero.FormatarTextoParaInteiro(item.fluxoDeSala.etapa + 1)}</div>
                                                            </div>
                                                            <div style = {{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "calc(24vw + 50px)"}}>
                                                                <div style = {{fontWeight: "bold", fontSize: "12px"}}>{item.fluxoDeSala.descricao}</div>
                                                                <div style = {{display: 'flex', alignItems: 'center'}}>
                                                                    <div style = {{fontWeight: "bolder", fontSize: "12px", textAlign: "center", verticalAlign: "middle", color: '#D3D3D3', marginRight: "5px"}}>{item.pessoa.nome}</div>
                                                                    {item.fluxoDeSala.descricao != 'Recepção' && <Icon icon = "edit" size = {12} color = {"#000"} 
                                                                        onClick = {async () => {
                                                                            let FluxosDaSala = [...getFluxoDeSala];

                                                                            let ItemFluxo = FluxosDaSala.filter(IItem => IItem.descricao == item.fluxoDeSala.descricao)[0];
                                
                                                                            if (ItemFluxo.acao.id == 1)
                                                                            {
                                                                                setFormularioKeyField(item.fluxoDeSala.descricao);

                                                                                console.log(ContextSignIn.getContext().token, ItemFluxo.cargosVinculados[0].id);
                                
                                                                                let Response = await (await Logon.Usuarios(ContextSignIn.getContext().token, ItemFluxo.cargosVinculados[0].id)).data ?? [];
                                
                                                                                setListaDeUsuariosRelecionadosAoCargo(Response);

                                                                                setItemListaDeUsuariosRelecionadosAoCargo(item.pessoa);
                                
                                                                                setDescricaoDoCargo(ItemFluxo.cargosVinculados[0]);

                                                                                setAlterandoCargoVinculado(true);
                                
                                                                                handleDialogVinculandoOpen();
                                                                            }
                                                                            else if (ItemFluxo.acao.id == 2)
                                                                            {
                                                                                handleOpenDrawerTelaVendaDireta() 
                                                                            }
                                                                    }}/>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {getListaHistoricaoDoControleDeSala.length == 0 && <div
                                            style = {{
                                                marginTop: "20px",
                                                fontSize: "12px",
                                                border: "1px dotted #00000050", 
                                                borderRadius: "5px", 
                                                height: "122px", 
                                                backgroundColor: "#1098F730",
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: "center", 
                                                width: "calc(28vw + 50px)",
                                                cursor: "pointer",
                                                fontWeight: "bolder",
                                                marginBottom: "20px"
                                        }}>{'Não há histórico no momento'}</div>}
                                    </>}
                                
                                    {getOptionMudarFaseNoControleDeSala == true && <>
                                        {getFluxoDeSala.map((item: {etapa: number, descricao: string, acao: {id: number, descricao: string }, cargosVinculados: Array<{id: number, nome: string}>}, index: number) => (
                                            <div
                                                key = {item.etapa}
                                                style = {{
                                                    border: "1px dotted #00000050", 
                                                    borderRadius: "5px", 
                                                    height: "42px", 
                                                    background: getFormularioKeyField == item.descricao ? "#D3658230" : "#FFFFFF", 
                                                    display: "flex", 
                                                    alignItems: "center",
                                                    width: "calc(28vw + 50px)",
                                                    cursor: "pointer",
                                                    marginBottom: "10px",
                                                    transition: 'background 0.5s'
                                            }}>
                                                <div style = {{display: "flex", alignContent: "center", justifyContent: "space-between", width: "100%", paddingLeft: "10px", paddingRight: "10px"}}>
                                                    <div style = {{display: "flex", alignItems: "center"}}>
                                                        <div style = {{width: "20px", height: "20px", borderRadius: "20px", border: "0.4px solid #225", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "10px", backgroundColor: "#FFFFFF90"}}>
                                                            <div style = {{fontWeight: "bolder", fontSize: "9px", textAlign: "center", verticalAlign: "middle"}}>{Numero.FormatarTextoParaInteiro(item.etapa + 1)}</div>
                                                        </div>
                                                        <div style = {{fontWeight: "bold", fontSize: "12px"}}>{item.descricao}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {getFluxoDeSala.length == 0  && <div
                                            style = {{
                                                marginTop: "20px",
                                                fontSize: "12px",
                                                border: "1px dotted #00000050", 
                                                borderRadius: "5px", 
                                                height: "122px", 
                                                backgroundColor: "#D3658230",
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: "center", 
                                                width: "calc(28vw + 50px)",
                                                cursor: "pointer",
                                                fontWeight: "bolder",
                                                marginBottom: "20px"
                                        }}>{'Não há fases no momento'}</div>}
                                    </>}
                                
                                </div>
                            </div>

                            {/** Histórico do funil e mudança de fase */}
                            <div style = {{marginTop: "20px", backgroundColor: "#FFFFFF"}}>
                                <div 
                                    style = {{
                                        borderTop: '1px solid #00000020',
                                        borderBottom: '1px solid #00000020',
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderRadius: "5px",
                                        backgroundColor: "#FFFFFF",
                                        display: "flex", 
                                        flexDirection: "column",
                                        alignItems: "center",
                                        marginTop: "0px",
                                        width: "calc(29vw + 50px)",
                                }}>

                                    <div style = {{display: "flex", alignItems: "center", marginBottom: "20px", width: "calc(28vw + 50px)", marginTop: "10px"}}>
                                            <Button
                                                style = {{
                                                    background: getOptionSitucaoNoFunil == true ? "#1098F7"  : "#1098F730",
                                                    color: getOptionSitucaoNoFunil == true ? "#FFFFFF" : "#000000",
                                                    fontWeight: "bolder",
                                                    boxShadow: "none",
                                                    outline: "none",
                                                    display: "flex", 
                                                    alignItems: "center",
                                                    fontSize: "12px",
                                                    padding: "3px 5px",
                                                    borderRadius: "5px",
                                                    width: "130px",
                                                    marginRight: "30px",
                                                    transition: "background 0.5s, color 0.5s"
                                                }}
                                                text = {"Histórico do funil"}
                                                onClick = {() => {
                                                    setOptionSitucaoNoFunil(true)
                                                    setOptionMudarDeFase(false)
                                                }}
                                            />
                                        <div style = {{display: "flex", alignItems: "center"}}>
                                            {<Button
                                                style = {{
                                                    background: getOptionMudarDeFase == true ? "#D36582" :  "#D3658230", 
                                                    color: getOptionMudarDeFase == true ? "#FFFFFF" : "#000000",
                                                    fontWeight: "bolder",
                                                    boxShadow: "none",
                                                    outline: "none",
                                                    display: "flex", 
                                                    alignItems: "center", 
                                                    fontSize: "12px",
                                                    padding: "3px 5px",
                                                    borderRadius: "5px",
                                                    width: "130px",
                                                    transition: "background 0.5s, color 0.5s"
                                                }}
                                                text = {"Fases do funil"}
                                                onClick = {() => {
                                                    setOptionMudarDeFase(true)
                                                    setOptionSitucaoNoFunil(false)
                                                }}
                                            />}
                                        </div>
                                    </div>
                                
                                    {getOptionSitucaoNoFunil == true && <>
                                        {getListaHistoricoDoFunil.map((item: {salaDeVenda: { id: number, descricao: string }, area: {id: number, descricao: string}, funil: {id: number, descricao: string}, posicaoDoFunil: {id: number, descricao: string}, datasDeAlteracoes: Array<any>, descricao: string}, index: number) => (
                                            <div key = {item.posicaoDoFunil.id} style = {{display: "flex", alignItems: "center"}}>
                                                <div
                                                    key = {item.posicaoDoFunil.id}
                                                    style = {{
                                                        border: "1px dotted #00000050", 
                                                        borderRadius: "5px", 
                                                        height: "42px", 
                                                        background: getFormularioPosicaoHistoricoFunil?.id == item.posicaoDoFunil.id ? "#1098F730" : "#FFFFFF", 
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "calc(28vw + 50px)",
                                                        cursor: "pointer",
                                                        marginBottom: "10px",
                                                        marginTop: "10px",
                                                    }}
                                                    onClick = {() => {
                                                        setFormularioPosicaoHistoricoFunil({
                                                            id: item.posicaoDoFunil.id,
                                                            descricao: item.posicaoDoFunil.descricao
                                                        })
                                                    }}>
                                                    <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "10px", paddingRight: "10px"}}>
                                                        <div style = {{display: "flex", alignItems: "center"}}>
                                                            <div style = {{width: "20px", height: "20px", borderRadius: "20px", border: "0.4px solid #225", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "10px", backgroundColor: "#FFFFFF90"}}>
                                                                <div style = {{fontWeight: "bolder", fontSize: "9px", textAlign: "center", verticalAlign: "middle"}}>{Numero.FormatarTextoParaInteiro(item.posicaoDoFunil.id + 1)}</div>
                                                            </div>
                                                            <div style = {{fontWeight: "bold", fontSize: "12px"}}>{item.posicaoDoFunil.descricao}</div>
                                                        </div>
                                                        <div style = {{display: "flex", alignItems: "center"}}>
                                                            <Tooltip
                                                                html = {(
                                                                    <div style = {{height: "60px", width: "170px", backgroundColor: "#3C3C3B", borderRadius: "5px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                                                        <div style = {{width: "98%", background: "#FFFFFF", height: "95%", padding: "10px", borderRadius: "5px", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                                                            <div style = {{fontSize: "8px"}}>Responsável: Lucas Moreira Assis</div>
                                                                            {item.datasDeAlteracoes.map((data, dataIndex) => dataIndex == 0 && (<div style = {{fontSize: "8px"}}>Data: {data}</div>))}
                                                                        </div>
                                                                    </div>
                                                            )}>
                                                                <div
                                                                    style = {{
                                                                        marginLeft: "10px", 
                                                                        width: "20px", 
                                                                        height: "20px", 
                                                                        border: "0.5px solid #00000040", 
                                                                        borderRadius: "20px", 
                                                                        textAlign: "center",
                                                                        cursor: "pointer"
                                                                }}>i</div>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {getListaHistoricoDoFunil.length == 0  && <div
                                            style = {{
                                                marginTop: "20px",
                                                fontSize: "12px",
                                                border: "1px dotted #00000050", 
                                                borderRadius: "5px", 
                                                height: "122px", 
                                                backgroundColor: "#1098F730",
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: "center", 
                                                width: "calc(28vw + 50px)",
                                                cursor: "pointer",
                                                fontWeight: "bolder",
                                                marginBottom: "20px"
                                        }}>{'Não há histórico no momento'}</div>}
                                    </>}
                                
                                    {getOptionMudarDeFase == true && <>
                                        {getListaMudarDeFase.map((item: {id: number, descricao: string}, index: number) => (
                                            <div
                                                key = {item.id}
                                                style = {{
                                                    border: "1px dotted #00000050", 
                                                    borderRadius: "5px", 
                                                    height: "42px", 
                                                    background: getFormularioKeyField == item.descricao ? "#D3658230" : "#FFFFFF", 
                                                    display: "flex", 
                                                    alignItems: "center",
                                                    width: "calc(28vw + 50px)",
                                                    cursor: "pointer",
                                                    marginBottom: "10px",
                                                    transition: 'background 0.5s'
                                            }}>
                                                <div style = {{display: "flex", alignContent: "center", justifyContent: "space-between", width: "100%", paddingLeft: "10px", paddingRight: "10px"}}>
                                                    <div style = {{display: "flex", alignItems: "center"}}>
                                                        <div style = {{width: "20px", height: "20px", borderRadius: "20px", border: "0.4px solid #225", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "10px", backgroundColor: "#FFFFFF90"}}>
                                                            <div style = {{fontWeight: "bolder", fontSize: "9px", textAlign: "center", verticalAlign: "middle"}}>{Numero.FormatarTextoParaInteiro(item.id + 1)}</div>
                                                        </div>
                                                        <div style = {{fontWeight: "bold", fontSize: "12px"}}>{item.descricao}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {getListaMudarDeFase.length == 0  && <div
                                            style = {{
                                                marginTop: "20px",
                                                fontSize: "12px",
                                                border: "1px dotted #00000050", 
                                                borderRadius: "5px", 
                                                height: "122px", 
                                                backgroundColor: "#D3658230",
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: "center", 
                                                width: "calc(28vw + 50px)",
                                                cursor: "pointer",
                                                fontWeight: "bolder",
                                                marginBottom: "20px"
                                        }}>{'Não há fases no momento'}</div>}
                                    </>}
                                
                                </div>
                            </div>

                        </div>

                    </div>

                    <div style={{boxShadow: "none", width: "65%"}}>
                        
                        <div style = {{height: "60px", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center"}}>
                            
                            <div style = {{display: "flex", marginTop: "10px", justifyContent: "flex-start", alignItems: "flex-end", width: "100%", paddingLeft: "3.5%", paddingRight: "3.5%", borderBottom: "0.5px solid #00000050"}}>
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "0",
                                        color: "#000000",
                                        borderLeft: getOptionAtividades == true ? "0.5px dotted #00000050" : "none",
                                        borderRight: getOptionAtividades == true ? "0.5px dotted #00000050" : "none",
                                        borderTop: getOptionAtividades == true ? "0.5px dotted #00000050" : "none",
                                        borderBottom: getOptionAtividades == true ? "3px solid #1098F7" : "0.5px solid #00000050",
                                        marginBottom: getOptionAtividades == true ? "-2px" : "-0.5px",
                                        fontWeight: getOptionAtividades == true ? "bolder" : "unset",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex", 
                                        alignItems: "center",
                                        fontSize: "12px", 
                                        height: "15px",
                                    }}
                                    text = {"Atividades"}
                                    onClick = {() => {
                                        setOptionAtividades(true)
                                        setOptionAnotacoes(false)
                                        setOptionTarefas(false)
                                        setOptionEmails(false)
                                        setOptionFormulario(false)
                                        setOptionFluxo(false)
                                        setOptionVincularBrinde(false)
                                    }}
                                />
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "0",
                                        color: "#000000",
                                        borderLeft: getOptionAnotacoes == true ? "0.5px dotted #00000050" : "none",
                                        borderRight: getOptionAnotacoes == true ? "0.5px dotted #00000050" : "none",
                                        borderTop: getOptionAnotacoes == true ? "0.5px dotted #00000050" : "none",
                                        borderBottom: getOptionAnotacoes == true ? "3px solid #1098F7" : "0.5px solid #00000050",
                                        marginBottom: getOptionAnotacoes == true ? "-2px" : "-0.5px",
                                        fontWeight: getOptionAnotacoes == true ? "bolder" : "unset",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex", 
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                    }}
                                    text = {"Anotações"}
                                    onClick = {() => {
                                        setOptionAnotacoes(true)
                                        setOptionAtividades(false)
                                        setOptionTarefas(false)
                                        setOptionEmails(false)
                                        setOptionFormulario(false)
                                        setOptionFluxo(false)
                                        setOptionVincularBrinde(false)
                                    }}
                                />
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "0",
                                        color: "#000000",
                                        borderLeft: getOptionEmails == true ? "0.5px dotted #00000050" : "none",
                                        borderRight: getOptionEmails == true ? "0.5px dotted #00000050" : "none",
                                        borderTop: getOptionEmails == true ? "0.5px dotted #00000050" : "none",
                                        borderBottom: getOptionEmails == true ? "3px solid #1098F7" : "0.5px solid #00000050",
                                        marginBottom: getOptionEmails == true ? "-2px" : "-0.5px",
                                        fontWeight: getOptionEmails == true ? "bolder" : "unset",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex", 
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                    }}
                                    text = {"Emails"}
                                    onClick = {() => {
                                        setOptionEmails(true)
                                        setOptionAtividades(false)
                                        setOptionAnotacoes(false)
                                        setOptionTarefas(false)
                                        setOptionFormulario(false)
                                        setOptionFluxo(false)
                                        setOptionVincularBrinde(false)
                                    }}
                                />
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "0", 
                                        color: "#000000",
                                        borderLeft: getOptionTarefas == true ? "0.5px dotted #00000050" : "none",
                                        borderRight: getOptionTarefas == true ? "0.5px dotted #00000050" : "none",
                                        borderTop: getOptionTarefas == true ? "0.5px dotted #00000050" : "none",
                                        borderBottom: getOptionTarefas == true ? "3px solid #1098F7" : "0.5px solid #00000050",
                                        marginBottom: getOptionTarefas == true ? "-2px" : "-0.5px",
                                        fontWeight: getOptionTarefas == true ? "bolder" : "unset",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex", 
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                    }}
                                    text = {"Tarefas"}
                                    onClick = {() => {
                                        setOptionTarefas(true)
                                        setOptionFormulario(false)
                                        setOptionAtividades(false)
                                        setOptionAnotacoes(false)
                                        setOptionEmails(false)
                                        setOptionFluxo(false)
                                        setOptionVincularBrinde(false)
                                    }}
                                />
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "0", 
                                        color: "#000000",
                                        borderLeft: getOptionFormulario == true ? "0.5px dotted #00000050" : "none",
                                        borderRight: getOptionFormulario == true ? "0.5px dotted #00000050" : "none",
                                        borderTop: getOptionFormulario == true ? "0.5px dotted #00000050" : "none",
                                        borderBottom: getOptionFormulario == true ? "3px solid #1098F7" : "0.5px solid #00000050",
                                        marginBottom: getOptionFormulario == true ? "-2px" : "-0.5px",
                                        fontWeight: getOptionFormulario == true ? "bolder" : "unset",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex", 
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                    }}
                                    text = {"Formulário"}
                                    onClick = {() => {
                                        setOptionFormulario(true)
                                        setOptionTarefas(false)
                                        setOptionAtividades(false)
                                        setOptionAnotacoes(false)
                                        setOptionEmails(false)
                                        setOptionFluxo(false)
                                        setOptionVincularBrinde(false)
                                    }}
                                />
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "0", 
                                        color: "#000000",
                                        borderLeft: getOptionFluxo == true ? "0.5px dotted #00000050" : "none",
                                        borderRight: getOptionFluxo == true ? "0.5px dotted #00000050" : "none",
                                        borderTop: getOptionFluxo == true ? "0.5px dotted #00000050" : "none",
                                        borderBottom: getOptionFluxo == true ? "3px solid #1098F7" : "0.5px solid #00000050",
                                        marginBottom: getOptionFluxo == true ? "-2px" : "-0.5px",
                                        fontWeight: getOptionFluxo == true ? "bolder" : "unset",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex", 
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                    }}
                                    text = {"Fluxo de sala"}
                                    onClick = {() => {
                                        setOptionFluxo(true)
                                        setOptionFormulario(false)
                                        setOptionTarefas(false)
                                        setOptionAtividades(false)
                                        setOptionAnotacoes(false)
                                        setOptionEmails(false)
                                        setOptionVincularBrinde(false)
                                    }}
                                />
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "0", 
                                        color: "#000000",
                                        borderLeft: getOptionVincularBrinde == true ? "0.5px dotted #00000050" : "none",
                                        borderRight: getOptionVincularBrinde == true ? "0.5px dotted #00000050" : "none",
                                        borderTop: getOptionVincularBrinde == true ? "0.5px dotted #00000050" : "none",
                                        borderBottom: getOptionVincularBrinde == true ? "3px solid #1098F7" : "0.5px solid #00000050",
                                        marginBottom: getOptionVincularBrinde == true ? "-2px" : "-0.5px",
                                        fontWeight: getOptionVincularBrinde == true ? "bolder" : "unset",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex", 
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                    }}
                                    text = {"Vincular brinde"}
                                    onClick = {() => {
                                        setOptionVincularBrinde(true)
                                        setOptionFluxo(false)
                                        setOptionFormulario(false)
                                        setOptionTarefas(false)
                                        setOptionAtividades(false)
                                        setOptionAnotacoes(false)
                                        setOptionEmails(false)
                                    }}
                                />
                            </div>

                        </div>

                        {getOptionAtividades == true &&
                        <div style = {{padding: "30px"}}>
                            
                            <div style = {{backgroundColor: "#FFFFFF", borderRadius: "5px", border: "0.3px solid #00000030", padding: "5px", marginTop: "10px"}}>
                                <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "30px", backgroundColor: "#1098F730", width: "110px", padding: "3px 5px", borderRadius: "5px", marginTop: "5px", marginLeft: "10px"}}>Proximas ações</div>
                                <Timeline style = {{alignItems: "start", marginLeft: "-40px", marginTop: "5px"}}>
                                    {getFormularioListaAtividades.filter((item: any) => (item.situacaoDaAtividade.id == 0 && (item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao))).map((item: any, index: number) => (
                                        <>
                                            <TimelineItem style = {{marginTop: "5px"}}>
                                                <TimelineSeparator>
                                                    <Icon iconSize = {15} icon = {item.classificacaoDaAtividade.id == 1 ? "envelope" : item.classificacaoDaAtividade.id == 2 ? "bookmark" : "phone"} color = {"#A0AAB2"}/>
                                                {(getFormularioListaAtividades.filter((item: any) => (item.situacaoDaAtividade.id == 0 && (item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao))).length - 1) != index &&
                                                <TimelineConnector style = {{width: "0.5px"}}/>}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <div style = {{backgroundColor: "#FFFFFF", marginLeft: "0px", marginTop: -20, marginBottom: 30, borderRadius: 5, height: "80px", border: "1px solid #00000020", marginRight: 20, display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: "10px", width: "calc(50vw + 50px)"}}>
                                                        <div style = {{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                <Icon icon = "people" iconSize = {14} style = {{marginRight: "5px"}}/>
                                                                <div style = {{fontWeight: "bold", fontSize: "14px"}}>{item.executor.nome}</div>
                                                            </div>
                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                <Icon icon = "calendar" iconSize = {13} color = {"#1098F7"} style = {{marginRight: "10px"}}/>
                                                                <div style = {{fontSize: "11px", color: "#1098F7"}}>{moment(item.dataDoEvento, true).format('DD [de] MMMM [de] YYYY [às] HH:MM')}</div>
                                                            </div>
                                                        </div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "10px", marginLeft: "10px"}}>{item.descricao}</div>
                                                    </div>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </>
                                    ))}
                                    {getFormularioListaAtividades.filter((item: any) => (item.situacaoDaAtividade.id == 0 && (item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao))).length == 0 && <div style = {{marginLeft: "40px", fontSize: "12px"}}>Não há próximas ações no momento.</div>}
                                </Timeline>
                            </div>

                            <div style = {{backgroundColor: "#FFFFFF", borderRadius: "5px", border: "0.3px solid #00000030", padding: "5px", marginTop: "10px"}}>
                                <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "30px", backgroundColor: lighten(0.1, '#D3D3D3'), width: "70px", padding: "3px 5px", borderRadius: "5px", marginTop: "5px", marginLeft: "10px"}}>Histórico</div>
                                <Timeline  style = {{alignItems: "start", marginLeft: "-40px"}}>
                                    {getFormularioListaAtividades.filter((item: any) => (item.situacaoDaAtividade.id == 1 && (item.posicaoDoFunil.id == getFormularioPosicaoHistoricoFunil?.id))).map((item: any, index: number) => (
                                        <>
                                            <TimelineItem style = {{marginTop: "5px"}}>
                                                <TimelineSeparator>
                                                    <Icon iconSize = {15} icon = {item.classificacaoDaAtividade.id == 1 ? "envelope" : item.classificacaoDaAtividade.id == 2 ? "bookmark" : "phone"} color = {"#A0AAB2"}/>
                                                    {(getFormularioListaAtividades.filter((item: any) => (item.situacaoDaAtividade.id == 1 && (item.posicaoDoFunil.id == getFormularioPosicaoHistoricoFunil?.id))).length - 1) != index &&
                                                        <TimelineConnector style = {{width: "0.5px"}}/>}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <div style = {{backgroundColor: "#FFFFFF", marginLeft: "0px", marginTop: -20, marginBottom: 30, borderRadius: 5, height: "80px", border: "1px solid #00000020", marginRight: 20, display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: "10px", width: "calc(50vw + 50px)"}}>
                                                        <div style = {{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                <Icon icon = "people" iconSize = {14} style = {{marginRight: "5px"}}/>
                                                                <div style = {{fontWeight: "bold", fontSize: "14px"}}>{item.executor.nome}</div>
                                                            </div>
                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                <Icon icon = "calendar" iconSize = {13} color = {"#1098F7"} style = {{marginRight: "10px"}}/>
                                                                <div style = {{fontSize: "11px", color: "#1098F7"}}>{moment(item.dataDoEvento, true).format('DD [de] MMMM [de] YYYY [às] HH:MM')}</div>
                                                            </div>
                                                        </div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "10px", marginLeft: "10px"}}>{item.descricao}</div>
                                                    </div>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </>
                                    ))}
                                    {getFormularioListaAtividades.filter((item: any) => (item.situacaoDaAtividade.id == 1 && (item.posicaoDoFunil.id == getFormularioPosicaoHistoricoFunil?.id))).length == 0 && <div style = {{marginLeft: "40px", fontSize: "12px"}}>Não há histórico no momento.</div>}
                                </Timeline>
                            </div>
                            
                        </div>}

                        {getOptionTarefas == true &&
                        <div style = {{padding: "10px 30px"}}>
                            
                            <div style = {{display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "#1098F7", 
                                        color: "#FFFFFF",
                                        fontWeight: "bolder",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex",
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                        marginRight: "17px"
                                    }}
                                    text = {"Criar tarefa"}
                                    onClick = {() => {
                                        if (getFormularioKeyField != "")
                                        {
                                            setFormularioNomeTarefa("");
                                            setFormularioPrevisaoDeInicio(moment(new Date()).subtract(1, "day").toDate())
                                            setFormularioPrevisaoDeTermino(moment(new Date()).subtract(0, "day").toDate())
                                            setFormularioDataDeTermino(undefined)
                                            setItemPickerPrioridade(undefined)
                                            setItemPickerSolicitante({
                                                id: ContextSignIn.getContext().pessoa.id,
                                                nome: ContextSignIn.getContext().pessoa.nome,
                                                email: ContextSignIn.getContext().pessoa.emails ? {
                                                    classificacao: ContextSignIn.getContext().pessoa.emails[0].classificacao,
                                                    descricao: ContextSignIn.getContext().pessoa.emails[0].descricao
                                                } : {
                                                    classificacao: 0,
                                                    descricao: ""
                                                },
                                                cargo: {
                                                    id: ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo.id,
                                                    nome: ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo.nome
                                                }
                                            })
                                            setItemPickerSolicitante({
                                                id: ContextSignIn.getContext().pessoa.id,
                                                nome: ContextSignIn.getContext().pessoa.nome,
                                                email: ContextSignIn.getContext().pessoa.emails ? {
                                                    classificacao: ContextSignIn.getContext().pessoa.emails[0].classificacao,
                                                    descricao: ContextSignIn.getContext().pessoa.emails[0].descricao
                                                } : {
                                                    classificacao: 0,
                                                    descricao: ""
                                                },
                                                cargo: {
                                                    id: ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo.id,
                                                    nome: ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo.nome
                                                }
                                            })
                                            setEditandoTarefa(false)
                                            handleDialogCreateTaskOpen()
                                        }
                                        else 
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "É necessário que o lead esteja em alguma fase do funil, não podem ser essa a fase de não classificado.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                        }
                                    }}
                                />
                            </div>

                            <div style = {{backgroundColor: "#FFFFFF", borderRadius: "5px", border: "0.3px solid #00000030", padding: "5px", marginTop: "10px"}}>
                                <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "30px", backgroundColor: lighten(0.1, '#D3D3D3'), width: "70px", padding: "3px 5px", borderRadius: "5px", marginTop: "5px", marginLeft: "10px"}}>Histórico</div>
                                <Timeline  style = {{alignItems: "start", marginLeft: "-40px"}}>
                                    {getFormularioListaTarefas.filter((item: any) => item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao).map((item: any, index: number) => (
                                        <>
                                            <TimelineItem style = {{marginTop: "5px"}}>
                                                <TimelineSeparator>
                                                    <Icon iconSize = {15} icon = {"bookmark"} color = {"#A0AAB2"}/>
                                                {(getFormularioListaTarefas.filter((item: any) => item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao).length - 1) != index &&
                                                    <TimelineConnector style = {{width: "0.5px"}}/>}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <div
                                                        style = {{
                                                            backgroundColor: "#FFFFFF", 
                                                            marginLeft: "0px", 
                                                            marginTop: "-20px", 
                                                            marginBottom: "30px", 
                                                            borderRadius: "5px", 
                                                            height: "80px", 
                                                            border: "1px solid #00000020", 
                                                            marginRight: "20px", 
                                                            display: "flex", 
                                                            flexDirection: "column", 
                                                            justifyContent: "flex-start", 
                                                            padding: "10px", 
                                                            width: "calc(50vw + 50px)", 
                                                            cursor: "pointer"
                                                    }}
                                                    onClick = {() => {
                                                        setFormularioIDTarefa(item.numero)
                                                        setFormularioNomeTarefa(item.descricao)
                                                        setFormularioPrevisaoDeInicio(item.previsaoDeInicio ? new Date(item.previsaoDeInicio) : new Date())
                                                        setFormularioPrevisaoDeTermino(item.previsaoDeTermino ? new Date(item.previsaoDeTermino) : new Date())
                                                        setFormularioDataDeTermino(item.dataDeTermino ? new Date(item.dataDeTermino) : undefined)
                                                        setItemPickerPrioridade(item.prioridade ?? undefined)
                                                        setItemPickerSolicitante(item.solicitante ?? undefined)
                                                        setItemPickerResponsavel(item.executor ?? undefined)
                                                        setEditandoTarefa(true)
                                                        handleDialogCreateTaskOpen()
                                                    }}>
                                                        <div style = {{ display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                <Icon icon = "people" iconSize = {14} style = {{marginRight: "5px"}}/>
                                                                <div style = {{fontWeight: "bold", fontSize: "14px"}}>{item.executor.nome}</div>
                                                            </div>
                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                <Icon icon = "calendar" iconSize = {13} color = {"#1098F7"} style = {{marginRight: "10px"}}/>
                                                                <div style = {{fontSize: "11px", color: "#1098F7"}}>{moment(item.previsaoDeInicio, true).format('DD [de] MMMM [de] YYYY [às] HH:MM')}</div>
                                                            </div>
                                                        </div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "10px", marginLeft: "10px"}}>{item.descricao}</div>
                                                    </div>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </>
                                    ))}
                                    {getFormularioListaTarefas.filter((item: any) => item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao).length == 0 && <div style = {{marginLeft: "40px", fontSize: "12px"}}>Não há histórico no momento.</div>}
                                </Timeline>
                            </div>

                        </div>}

                        {getOptionEmails == true &&
                        <div style = {{padding: "10px 30px"}}>
                            
                            <div style = {{display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "#1098F7", 
                                        color: "#FFFFFF",
                                        fontWeight: "bolder",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex",
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                        marginRight: "17px"
                                    }}
                                    text = {"Criar Email"}
                                    onClick = {() => {
                                        if (getFormularioKeyField != "")
                                        {
                                            handleDialogCreateEmailOpen();
                                        }
                                        else 
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "É necessário que o lead esteja em alguma fase do funil, não podem ser essa a fase de não classificado.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                        }
                                    }}
                                />
                            </div>

                            <div style = {{backgroundColor: "#FFFFFF", borderRadius: "5px", border: "0.3px solid #00000030", padding: "5px", marginTop: "10px"}}>
                                <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "30px", backgroundColor: lighten(0.1, '#D3D3D3'), width: "70px", padding: "3px 5px", borderRadius: "5px", marginTop: "5px", marginLeft: "10px"}}>Histórico</div>
                                <Timeline  style = {{alignItems: "start", marginLeft: "-40px"}}>
                                    {getFormularioListaEmails.filter((item: any) => item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao).map((item: any, index: number) => (
                                        <>
                                            <TimelineItem style = {{marginTop: "5px"}}>
                                                <TimelineSeparator>
                                                    <Icon iconSize = {15} icon = {"bookmark"} color = {"#A0AAB2"}/>
                                                {(getFormularioListaEmails.filter((item: any) => item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao).length - 1) != index &&
                                                    <TimelineConnector style = {{width: "0.5px"}}/>}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <div
                                                        style = {{
                                                            backgroundColor: "#FFFFFF", 
                                                            marginLeft: "0px", 
                                                            marginTop: "-20px", 
                                                            marginBottom: "30px", 
                                                            borderRadius: "5px", 
                                                            height: "120px", 
                                                            border: "1px solid #00000020", 
                                                            marginRight: "20px", 
                                                            display: "flex", 
                                                            flexDirection: "column", 
                                                            justifyContent: "flex-start", 
                                                            padding: "10px", 
                                                            width: "calc(50vw)",
                                                    }}>
                                                        <div style = {{ display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                <Icon icon = "envelope" iconSize = {13} style = {{marginRight: "5px"}}/>
                                                                <div style = {{fontWeight: "bold", fontSize: "13px"}}>{`Assunto: ${item.assunto}`}</div>
                                                            </div>
                                                        </div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "10px", marginLeft: "20px"}}>{item.para != null ? `Para: ${item.para}` : ""}</div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "5px", marginLeft: "20px"}}>{item.cc != null ? `Cc: ${item.cc}` : ""}</div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "5px", marginLeft: "20px"}}>{item.descricao != null ? `${item.descricao}` : ""}</div>
                                                    </div>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </>
                                    ))}
                                    {getFormularioListaEmails.filter((item: any) => item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao).length == 0 && <div style = {{marginLeft: "40px", fontSize: "12px"}}>Não há histórico no momento.</div>}
                                </Timeline>
                            </div>
                        
                        </div>}

                        {getOptionAnotacoes == true &&
                        <div style = {{padding: "10px 30px"}}>
                            
                            <div style = {{display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "#1098F7", 
                                        color: "#FFFFFF",
                                        fontWeight: "bolder",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex",
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                        marginRight: "17px"
                                    }}
                                    text = {"Criar anotação"}
                                    onClick = {() => {
                                        if (getFormularioKeyField != "")
                                        {
                                            setFormularioNomeTarefa("");
                                            handleDialogCreateAnnotationOpen();
                                        }
                                        else 
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "É necessário que o lead esteja em alguma fase do funil, não podem ser essa a fase de não classificado.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                        }
                                    }}
                                />
                            </div>

                            <div style = {{backgroundColor: "#FFFFFF", borderRadius: "5px", border: "0.3px solid #00000030", padding: "5px", marginTop: "10px"}}>
                                <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "30px", backgroundColor: lighten(0.1, '#D3D3D3'), width: "70px", padding: "3px 5px", borderRadius: "5px", marginTop: "5px", marginLeft: "10px"}}>Histórico</div>
                                <Timeline  style = {{alignItems: "start", marginLeft: "-40px"}}>
                                    {getFormularioListaAnotacoes.filter((item: any) => item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao).map((item: any, index: number) => (
                                        <>
                                            <TimelineItem style = {{marginTop: "5px"}}>
                                                <TimelineSeparator>
                                                    <Icon iconSize = {15} icon = {"bookmark"} color = {"#A0AAB2"}/>
                                                {(getFormularioListaAnotacoes.filter((item: any) => item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao).length - 1) != index &&
                                                    <TimelineConnector style = {{width: "0.5px"}}/>}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <div
                                                        style = {{
                                                            backgroundColor: "#FFFFFF", 
                                                            marginLeft: "0px", 
                                                            marginTop: "-20px", 
                                                            marginBottom: "30px", 
                                                            borderRadius: "5px", 
                                                            height: "80px", 
                                                            border: "1px solid #00000020", 
                                                            marginRight: "20px", 
                                                            display: "flex", 
                                                            flexDirection: "column", 
                                                            justifyContent: "flex-start", 
                                                            padding: "10px", 
                                                            width: "calc(50vw + 50px)", 
                                                            cursor: "pointer"
                                                    }}
                                                    onClick = {() => {
                                                        setFormularioIDAnotacao(item.numero);
                                                        setEditandoTarefa(true);
                                                        handleDialogCreateAnnotationOpen();
                                                    }}>
                                                        <div style = {{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                <Icon icon = "annotation" iconSize = {14} style = {{marginRight: "5px"}}/>
                                                            </div>
                                                        </div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "10px", marginLeft: "10px"}}>{item.descricao}</div>
                                                    </div>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </>
                                    ))}
                                    {getFormularioListaAnotacoes.filter((item: any) => item.posicaoDoFunil.descricao == getFormularioPosicaoHistoricoFunil?.descricao).length == 0 && <div style = {{marginLeft: "40px", fontSize: "12px"}}>Não há histórico no momento.</div>}
                                </Timeline>
                            </div>

                        </div>}

                        {getOptionFormulario == true &&
                        <div style = {{padding: "10px 30px"}}>

                            {getFormularioListaDadosFormulario.map((item: any, index: number) => (
                                <>
                                    <div style = {{backgroundColor: "#FFFFFF", borderRadius: "5px", border: "0.3px solid #00000030", padding: "5px", marginTop: "10px"}}>
                                        {item.classificacao.id == 1 &&
                                        <div style = {{fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? `${item.titulo}` : `${item.titulo}`}</div>
                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                <Component.Input id = {`${item.titulo}`} style = {{width: "100%"}} disabled = {true} placeholder="Digite aqui..." value={item.resposta}
                                                    onChange = { async (event: React.FormEvent<HTMLInputElement>) => {

                                                        let ListaDeTiposDeComponentesFormulario = [...getTiposDeComponentesFormulario];

                                                        let ItemFiltrado = ListaDeTiposDeComponentesFormulario.filter((Item, Index) => Item.id == item.id)[0]

                                                        ItemFiltrado.resposta = event.currentTarget.value;

                                                        ListaDeTiposDeComponentesFormulario.map((Item, Index) => {
                                                            if(Item.id == ItemFiltrado.id)
                                                            {
                                                                Item = ItemFiltrado
                                                            }
                                                        })

                                                        setTiposDeComponenesFormulario(ListaDeTiposDeComponentesFormulario)
                                                    }}>
                                                </Component.Input>
                                            </div>
                                        </div>}
                                        {item.classificacao.id == 2 &&
                                        <div style = {{fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? `${item.titulo}` : `${item.titulo}`}</div>
                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                <Component.TextArea id = {`${item.titulo}`} style = {{width: "100%"}} disabled = {true} placeholder="" value={item.resposta}
                                                    onChange = { async (event: React.FormEvent<HTMLTextAreaElement>) => {
                                                        
                                                        let ListaDeTiposDeComponentesFormulario = [...getTiposDeComponentesFormulario];

                                                        let ItemFiltrado = ListaDeTiposDeComponentesFormulario.filter((Item, Index) => Item.id == item.id)[0]

                                                        ItemFiltrado.resposta = event.currentTarget.value;

                                                        ListaDeTiposDeComponentesFormulario.map((Item, Index) => {
                                                            if(Item.id == ItemFiltrado.id)
                                                            {
                                                                Item = ItemFiltrado
                                                            }
                                                        })

                                                        setTiposDeComponenesFormulario(ListaDeTiposDeComponentesFormulario)
                                                    }}>
                                                </Component.TextArea>
                                            </div>
                                        </div>}
                                        {item.classificacao.id == 3 &&
                                        <div style = {{display: "flex", justifyContent: "flex-start", marginRight: "10px"}}>
                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                <div style = {{marginRight: "10px", fontWeight: "bold", fontSize: "12px"}}>{`${item.titulo}`}</div>
                                            </div>
                                            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                                                <div style = {{marginTop: "10px"}}>
                                                    <Switch
                                                        disabled = {true}
                                                        checked = {JSON.parse((item.resposta).toLowerCase())}
                                                    />
                                                </div>
                                            </div>
                                        </div>}
                                        {item.classificacao.id == 4 &&
                                        <div style = {{display: "flex", alignItems: "center", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginRight: "5px"}}>{`${item.titulo}`}</div>
                                            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start", marginRight: "10px"}}>
                                                <DateInput disabled = {true} inputProps = {{style: {background: lighten(0.08, "#D3D3D3"), width: "90px", border: "none", boxShadow: "none", cursor: "pointer"}, onClick: () => setFormularioExibirCalendarioDataFormulario(true)}}
                                                    popoverProps = {{disabled: !getFormularioExibirCalendarioDataFormulario, onClosed: () => setFormularioExibirCalendarioDataFormulario(false)}}
                                                    dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format('DD/MM/YYYY')}
                                                    parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                                    placeholder={"DD/MM/AAAA"} value={new Date(item.resposta)}
                                                    onChange = {async (DataSelecionada: Date) => {
                                                        
                                                        if (DataSelecionada != null) {

                                                            let ListaDeTiposDeComponentesFormulario = [...getTiposDeComponentesFormulario];

                                                            let ItemFiltrado = ListaDeTiposDeComponentesFormulario.filter((Item, Index) => Item.id == item.id)[0]

                                                            ItemFiltrado.resposta = String(DataSelecionada);

                                                            ListaDeTiposDeComponentesFormulario.map((Item, Index) => {
                                                                if(Item.id == ItemFiltrado.id)
                                                                {
                                                                    Item = ItemFiltrado
                                                                }
                                                            })

                                                            setTiposDeComponenesFormulario(ListaDeTiposDeComponentesFormulario)
                                                        }
                                                        else {

                                                            let ListaDeTiposDeComponentesFormulario = [...getTiposDeComponentesFormulario];

                                                            let ItemFiltrado = ListaDeTiposDeComponentesFormulario.filter((Item, Index) => Item.id == item.id)[0]

                                                            ItemFiltrado.resposta = String(getFormularioPrevisaoDeInicio);

                                                            ListaDeTiposDeComponentesFormulario.map((Item, Index) => {
                                                                if(Item.id == ItemFiltrado.id)
                                                                {
                                                                    Item = ItemFiltrado
                                                                }
                                                            })

                                                            setTiposDeComponenesFormulario(ListaDeTiposDeComponentesFormulario)
                                                        }
                                                    }}>
                                                </DateInput>
                                            </div>
                                        </div>}
                                        {item.classificacao.id == 5 &&
                                        <div style = {{display: "flex", alignItems: "center", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginRight: "5px"}}>{`${item.titulo}`}</div>
                                            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start", marginRight: "10px"}}>
                                                <TimePicker
                                                    autoFocus =  {true}
                                                    disabled = {true}
                                                    precision = {TimePrecision.MINUTE}
                                                    selectAllOnFocus = {false}
                                                    showArrowButtons = {false}
                                                    useAmPm = {false}
                                                    onChange = {() => {}}
                                                />
                                            </div>
                                        </div>}
                                    </div>
                                </>
                            ))}

                        </div>}

                        {getOptionFluxo == true &&
                        <div style = {{padding: "10px 30px"}}>
                            <>
                                <div style = {{backgroundColor: "#FFFFFF", borderRadius: "5px", border: "0.3px solid #00000030", padding: "5px", marginTop: "10px"}}>
                                    <div style = {{fontWeight: "bold", fontSize: "12px"}}>
                                    <Timeline style = {{alignItems: "start", marginLeft: "-40px", marginTop: "5px"}}>
                                        {getListaHistoricaoDoControleDeSala.map((item: { controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}, index: number) => (
                                            <>
                                                <TimelineItem style = {{marginTop: "5px"}}>
                                                    <TimelineSeparator>
                                                        <Icon iconSize = {15} icon = {"bookmark"} color = {"#A0AAB2"}/>
                                                    {(getListaHistoricaoDoControleDeSala.length - 1) != index &&
                                                    <TimelineConnector style = {{width: "0.5px"}}/>}
                                                    </TimelineSeparator>
                                                    <TimelineContent>
                                                        <div style = {{backgroundColor: "#FFFFFF", marginLeft: "0px", marginTop: "-20px", marginBottom: "30px", borderRadius: "5px", height: "100px", border: "1px solid #00000020", marginRight: "20px", display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: "10px", width: "calc(50vw + 50px)"}}>
                                                            <div style = {{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                                                <div style = {{display: "flex", alignItems: "center"}}>
                                                                    <Icon icon = "people" iconSize = {14} style = {{marginRight: "5px"}}/>
                                                                    <div style = {{fontWeight: "bold", fontSize: "14px"}}>{`${item.fluxoDeSala.descricao}`}</div>
                                                                </div>
                                                                {/* <div style = {{display: "flex", alignItems: "center"}}>
                                                                    <Icon icon = "calendar" iconSize = {13} color = {"#1098F7"} style = {{marginRight: "10px"}}/>
                                                                </div> */}
                                                            </div>
                                                            <div>
                                                                <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "10px", marginLeft: "10px"}}>{`Sala de venda: ${item.salaDeVenda.descricao}`}</div>
                                                                <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "10px", marginLeft: "10px"}}>{`Responsável: ${item.pessoa.nome}`}</div>
                                                            </div>
                                                        </div>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            </>
                                        ))}
                                        {getFormularioListaDadosFluxo.length == 0 && <div style = {{marginLeft: "40px", fontSize: "12px"}}>Não há fluxo no momento.</div>}
                                    </Timeline>
                                    </div>
                                </div>
                            </>

                        </div>}
                        
                        {getOptionVincularBrinde == true &&
                        <div style = {{padding: "10px 30px"}}>
                            
                            <div style = {{display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                                <Button
                                    style = {{
                                        flex: 1,
                                        background: "#1098F7", 
                                        color: "#FFFFFF",
                                        fontWeight: "bolder",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex",
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                        marginRight: "17px"
                                    }}
                                    text = {"Vincular brinde"}
                                    onClick = {() => { handleDialogCreateVincularBrindeOpen() }}
                                />
                            </div>

                            <div style = {{backgroundColor: "#FFFFFF", borderRadius: "5px", border: "0.3px solid #00000030", padding: "5px", marginTop: "10px"}}>
                                <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "30px", backgroundColor: lighten(0.1, '#D3D3D3'), width: "130px", padding: "3px 5px", borderRadius: "5px", marginTop: "5px", marginLeft: "10px"}}>Brindes vinculados</div>
                                <Timeline  style = {{alignItems: "start", marginLeft: "-40px"}}>
                                    {getListaDeBrindesVinculados.map((item: IBrinde, index: number) => (
                                        <>
                                            <TimelineItem style = {{marginTop: "5px"}}>
                                                <TimelineSeparator>
                                                    <Icon iconSize = {15} icon = {"bookmark"} color = {"#A0AAB2"}/>
                                                    {(getListaDeBrindesVinculados.length - 1) != index && <TimelineConnector style = {{width: "0.5px"}}/>}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <div
                                                        style = {{
                                                            backgroundColor: "#FFFFFF", 
                                                            marginLeft: "0px", 
                                                            marginTop: "-20px", 
                                                            marginBottom: "30px", 
                                                            borderRadius: "5px", 
                                                            height: "160px", 
                                                            border: "1px solid #00000020", 
                                                            marginRight: "20px", 
                                                            display: "flex", 
                                                            flexDirection: "column", 
                                                            justifyContent: "flex-start", 
                                                            padding: "10px", 
                                                            width: "calc(50vw)",
                                                    }}>
                                                        <div style = {{ display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                <Icon icon = "people" iconSize = {13} style = {{marginRight: "5px"}}/>
                                                                <div style = {{fontWeight: "bold", fontSize: "13px"}}>{`Nome: ${item.brinde.descricao}`}</div>
                                                            </div>
                                                        </div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "10px", marginLeft: "20px"}}>{`Tipo: ${item.tipo.descricao}`}</div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "10px", marginLeft: "20px"}}>{`Valor: ${Moeda.FormatarTexto(item.valorUnitario)}`}</div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "5px", marginLeft: "20px"}}>{`Fornecedor: ${item.fornecedor.nomeRazaoSocial}`}</div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "5px", marginLeft: "20px"}}>{`Validade inicial: ${moment(new Date(), true).format('DD [de] MMMM [de] 2021')}`}</div>
                                                        <div style = {{fontWeight: "normal", fontSize: "12px", marginTop: "5px", marginLeft: "20px"}}>{`Validade final: ${moment(new Date(), true).format('DD [de] MMMM [de] 2021')}`}</div>
                                                    </div>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </>
                                    ))}
                                    {getListaDeBrindesVinculados.length == 0 && <div style = {{marginLeft: "40px", fontSize: "12px"}}>Não há brindes vinculados no momento.</div>}
                                </Timeline>
                            </div>
                        
                        </div>}

                    </div>

                </div>
            
            </Drawer>

            {/* Drawer oportunidade */}
            <Drawer
                style = {{
                    width: getOptionCustomizedFields == true ? '55%' : "35%", 
                    backgroundColor: "#FFFFFF",
                    transition: "width 0.5s"
                }}
                icon = {"people"}
                onClose={handleCloseDrawerAdicionar}
                title = {"Nova oportunidade"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionar.isOpen}
                canEscapeKeyClose = {getDrawerAdicionar.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionar.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionar.enforceFocus}
                usePortal = {getDrawerAdicionar.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>
                    
                    <DragDropContext
                        onDragStart = {(result) => {
                            if(result.source.index <= 4)
                            {
                                setDragStart(true) 
                            }
                        }}

                        onDragEnd = { (result) => {

                            if(result.source.index <= 4)
                            {
                                handleDialogCustomComponentOpen()
                                setSelectCustomComponent(getTiposDeComponentesFormulario[result.source.index]) 
                            }
                            else {

                                const items = Array.from(getTiposDeComponentesFormulario)
                                const [reorderingItem] = items.splice(result.source.index, 1);
                                items.splice(result.destination?.index ?? 0, 0, reorderingItem)

                                items.map((item, index) => item.id = index);

                                setTiposDeComponenesFormulario(items)
                            }
                    }}>

                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps} 
                                    ref = {provided.innerRef}
                                    style = {{
                                        width: getOptionCustomizedFields == true ? '65%' : "100%", 
                                        height: "100%",
                                        borderRight: getOptionCustomizedFields == true ? "0.5px solid #00000050" : "0px",
                                        display: "flex", 
                                        flexDirection: "column", 
                                        justifyContent: "space-between",
                                        transition: "width 0.5s"
                                }}>

                                    <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                                        <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                            <Component.ContentCard>
                                                <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                                    <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                        <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? `Nome` : `Razão Social`}</div>
                                                        <div style = {{display: 'flex', alignItems: 'center'}}>
                                                            <Component.Input id = {`Nome`} placeholder="Digite aqui..." value={getFormularioNome}
                                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                                    
                                                                    setNomeEmConsulta(true);
                                                                    Lead.Cancel();
                                                                    setNomeEmConsulta(true);
                                                                    var TextoDigitado = event.currentTarget.value;
                                                                    setFormularioNome(TextoDigitado);

                                                                    if(TextoDigitado)
                                                                    {
                                                                        let Response = await Lead.BuscaPersonalizada(ContextSignIn.getContext().token, false, false, "", TextoDigitado ?? "", getTelefones[0].descricao != "" ? getTelefones[0].descricao : "", getFormularioEmails[0].descricao != '' ? getFormularioEmails[0].descricao : "", "", "");

                                                                        if (Math.floor(Response.status / 100) == 2 && Response.data.length > 0)
                                                                        {

                                                                            setListaDeLeadsCadastro(Response.data);

                                                                            setNomeEmConsulta(false);

                                                                            if (getDialogSelectedLead.isOpen == false) { handleDialogSelectedLeadOpen() };

                                                                        }
                                                                        else
                                                                        {
                                                                            setNomeEmConsulta(false);
                                                                        }
                                                                    }

                                                                }}>
                                                            </Component.Input>
                                                            {getNomeEmConsulta && <Icon icon = "geosearch" style = {{marginLeft: "10px"}} iconSize = {15}/>}
                                                        </div>
                                                    </div>
                                                    <div
                                                        style = {{
                                                            marginBottom: "5px", 
                                                            fontWeight: "bold", 
                                                            fontSize: "12px"
                                                    }}>{"Qual o meio de contato?"}</div>
                                                    <div style = {{display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginLeft: "5px"}}>
                                                        {getListaMeioDeContato?.map((Item, Index) =>
                                                            <Radio size = {50} style={{ display: "flex", alignItems: "center", fontSize: "12px" }} name="EstadoCivil" label={Item.descricao} value={Item.id}
                                                                onChange={async () => setFormularioMeioDeContato(Item)}
                                                                checked={Item == getFormularioMeioDeContato}>
                                                            </Radio>
                                                        )}
                                                    </div>
                                                    {getFormularioMeioDeContato?.id == 0 && 
                                                        <div>
                                                            <div style = {{display: 'flex', alignItems: 'center', marginBottom: "5px", fontSize: "12px"}}>
                                                                <div style = {{marginRight: "5px", fontWeight: 'bold'}}>Email de contato</div>
                                                            </div>
                                                            {getFormularioEmails.map((item, index) => (
                                                                <div style = {{display: 'flex', alignItems: 'center', marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                    <Component.Input id="Email" placeholder="example@email.com.br" value={item.descricao} 
                                                                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                                            setEmailEmConsulta(true);
                                                                            Lead.Cancel();
                                                                            setEmailEmConsulta(true);
                                                                            var TextoDigitado = event.currentTarget.value;

                                                                            let ListaDeEmails = [...getFormularioEmails]

                                                                            ListaDeEmails.map((Item, Index) => {
                                                                                if (index == Index) {
                                                                                    Item.descricao = TextoDigitado
                                                                                }
                                                                            })

                                                                            setFormularioEmails(ListaDeEmails);

                                                                            if(TextoDigitado)
                                                                            {
                                                                                let Response = await Lead.BuscaPersonalizada(ContextSignIn.getContext().token, false, false, "", getFormularioNome ?? "", getTelefones[0].descricao != "" ? getTelefones[0].descricao : "", TextoDigitado ?? "", "", "");

                                                                                if (Math.floor(Response.status / 100) == 2 && Response.data.length > 0)
                                                                                {

                                                                                    setListaDeLeadsCadastro(Response.data);

                                                                                    setEmailEmConsulta(false);

                                                                                    if (getDialogSelectedLead.isOpen == false) { handleDialogSelectedLeadOpen() };

                                                                                }
                                                                                else
                                                                                {

                                                                                    setEmailEmConsulta(false);

                                                                                }
                                                                            }
                                                                        }}>
                                                                    </Component.Input>
                                                                    {getEmailEmConsulta && <Icon icon = "geosearch" style = {{marginLeft: "10px"}} iconSize = {15}/>}
                                                                </div>
                                                            ))}
                                                        </div>}
                                                    {getFormularioMeioDeContato?.id == 1 && 
                                                        <div style = {{fontWeight: "bold", fontSize: "12px"}}>
                                                            <div style = {{display: 'flex', alignItems: "center", marginBottom: "5px"}}>
                                                                <div style = {{marginRight: "5px", fontSize: "12px"}}>Telefone</div>
                                                            </div>
                                                            {getTelefones.map((item, index) => {
                                                                return ( 
                                                                    <div style = {{marginBottom: "10px"}}>
                                                                        <Component.Input id="TelefoneComercial" placeholder={Telefone.FormatarTexto("00000000000")} value={Telefone.FormatarTexto(item.descricao)}
                                                                            onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                                                                
                                                                                var TextoDigitado = event.currentTarget.value

                                                                                let ListaDeTelefones = [...getTelefones]

                                                                                ListaDeTelefones.map((Item, Index) => {
                                                                                    if (index == Index) {
                                                                                        Item.descricao = TextoDigitado
                                                                                    }
                                                                                })

                                                                                setTelefones(ListaDeTelefones);

                                                                                if(TextoDigitado)
                                                                                {
                                                                                    let Response = await Lead.BuscaPersonalizada(ContextSignIn.getContext().token, false, false, "", getFormularioNome ?? "", TextoDigitado ?? "", getFormularioEmails[0].descricao != '' ? getFormularioEmails[0].descricao : "", "", "");
    
                                                                                    if (Math.floor(Response.status / 100) == 2 && Response.data.length > 0)
                                                                                    {
    
                                                                                        setListaDeLeadsCadastro(Response.data);

                                                                                        setTelefoneEmConsulta(false);

                                                                                        if (getDialogSelectedLead.isOpen == false) { handleDialogSelectedLeadOpen() };
    
                                                                                    }
                                                                                    else
                                                                                    {
    
                                                                                        setTelefoneEmConsulta(false);
    
                                                                                    }
                                                                                }
                                                                            }}>
                                                                        </Component.Input>
                                                                        {getTelefoneEmConsulta && <Icon icon = "geosearch" style = {{marginLeft: "10px"}} iconSize = {15}/>}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>}
                                                    {getTiposDeComponentesFormulario.map((item, index) => item.id > 4 && (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={String(item.id)}
                                                            index={index}>
                                                            {(provided, snapshot) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    {item.classificacao.id == 1 &&
                                                                    <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                            <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? `${item.titulo}` : `${item.titulo}`}</div>
                                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                                <Component.Input id = {`${item.titulo}`} placeholder="Digite aqui..." value={item.resposta}
                                                                                    onChange = { async (event: React.FormEvent<HTMLInputElement>) => {

                                                                                        let ListaDeTiposDeComponentesFormulario = [...getTiposDeComponentesFormulario];

                                                                                        let ItemFiltrado = ListaDeTiposDeComponentesFormulario.filter((Item, Index) => Item.id == item.id)[0]

                                                                                        ItemFiltrado.resposta = event.currentTarget.value;

                                                                                        ListaDeTiposDeComponentesFormulario.map((Item, Index) => {
                                                                                            if(Item.id == ItemFiltrado.id)
                                                                                            {
                                                                                                Item = ItemFiltrado
                                                                                            }
                                                                                        });

                                                                                        setTiposDeComponenesFormulario(ListaDeTiposDeComponentesFormulario);
                                                                                    }}>
                                                                                </Component.Input>
                                                                                <Icon 
                                                                                    icon = {"remove"}
                                                                                    iconSize = {15} 
                                                                                    color = {'#000000'} 
                                                                                    style = {{marginLeft: "10px"}} 
                                                                                    onClick = {() => {
                                                                                        let ListaComponentesFormulario = [...getTiposDeComponentesFormulario]

                                                                                        let ListaFiltrada = ListaComponentesFormulario.filter((Item, Index) => Item.id != item.id)

                                                                                        ListaFiltrada.map((IItem, IIndex) => IItem.id == IIndex)

                                                                                        setTiposDeComponenesFormulario(ListaFiltrada);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                    </div>}
                                                                    {item.classificacao.id == 2 &&
                                                                    <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                            <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? `${item.titulo}` : `${item.titulo}`}</div>
                                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                                <Component.TextArea id = {`${item.titulo}`} placeholder="" value={item.resposta}
                                                                                    onChange = { async (event: React.FormEvent<HTMLTextAreaElement>) => {
                                                                                        
                                                                                        let ListaDeTiposDeComponentesFormulario = [...getTiposDeComponentesFormulario];

                                                                                        let ItemFiltrado = ListaDeTiposDeComponentesFormulario.filter((Item, Index) => Item.id == item.id)[0]

                                                                                        ItemFiltrado.resposta = event.currentTarget.value;

                                                                                        ListaDeTiposDeComponentesFormulario.map((Item, Index) => {
                                                                                            if(Item.id == ItemFiltrado.id)
                                                                                            {
                                                                                                Item = ItemFiltrado
                                                                                            }
                                                                                        })

                                                                                        setTiposDeComponenesFormulario(ListaDeTiposDeComponentesFormulario)
                                                                                    }}
                                                                                >
                                                                                </Component.TextArea>
                                                                                <Icon 
                                                                                    icon = {"remove"}
                                                                                    iconSize = {15} 
                                                                                    color = {'#000000'} 
                                                                                    style = {{marginLeft: "10px"}} 
                                                                                    onClick = {() => {
                                                                                        let ListaComponentesFormulario = [...getTiposDeComponentesFormulario]

                                                                                        let ListaFiltrada = ListaComponentesFormulario.filter((Item, Index) => Item.id != item.id)

                                                                                        ListaFiltrada.map((IItem, IIndex) => IItem.id == IIndex)

                                                                                        setTiposDeComponenesFormulario(ListaFiltrada)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                    </div>}
                                                                    {item.classificacao.id == 3 &&
                                                                    <div style = {{display: "flex", justifyContent: "flex-start", marginBottom: "10px", marginRight: "10px"}}>
                                                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                                                <div style = {{marginRight: "10px", fontWeight: "bold", fontSize: "12px"}}>{`${item.titulo}`}</div>
                                                                            </div>
                                                                            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                                                                                <div style = {{marginTop: "15px"}}>
                                                                                    <Switch/>
                                                                                </div>
                                                                                <div>
                                                                                    <Icon 
                                                                                        icon = {"remove"}
                                                                                        iconSize = {15} 
                                                                                        color = {'#000000'}
                                                                                        onClick = {() => {
                                                                                            let ListaComponentesFormulario = [...getTiposDeComponentesFormulario]

                                                                                            let ListaFiltrada = ListaComponentesFormulario.filter((Item, Index) => Item.id != item.id)
    
                                                                                            ListaFiltrada.map((IItem, IIndex) => IItem.id == IIndex)
    
                                                                                            setTiposDeComponenesFormulario(ListaFiltrada)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                    </div>}
                                                                    {item.classificacao.id == 4 &&
                                                                    <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                            <div style = {{marginBottom: "5px"}}>{`${item.titulo}`}</div>
                                                                            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start", marginRight: "10px"}}>
                                                                                <DateInput inputProps = {{style: {background: lighten(0.08, "#D3D3D3"), width: "12vw", border: "none", boxShadow: "none", cursor: "pointer"}, onClick: () => setFormularioExibirCalendarioDataOportunidade(true)}} 
                                                                                    dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format('DD/MM/YYYY')}
                                                                                    popoverProps = {{disabled: !getFormularioExibirCalendarioDataOportunidade, onClosed: () => setFormularioExibirCalendarioDataOportunidade(false)}}
                                                                                    parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                                                                    placeholder={"DD/MM/AAAA"} value={new Date(item.resposta)} maxDate={getFormularioPrevisaoDeTermino}
                                                                                    onChange = {async (DataSelecionada: Date) => {
                                                                                        
                                                                                        if (DataSelecionada != null) {

                                                                                            let ListaDeTiposDeComponentesFormulario = [...getTiposDeComponentesFormulario];

                                                                                            let ItemFiltrado = ListaDeTiposDeComponentesFormulario.filter((Item, Index) => Item.id == item.id)[0]

                                                                                            ItemFiltrado.resposta = String(DataSelecionada);

                                                                                            ListaDeTiposDeComponentesFormulario.map((Item, Index) => {
                                                                                                if(Item.id == ItemFiltrado.id)
                                                                                                {
                                                                                                    Item = ItemFiltrado
                                                                                                }
                                                                                            })

                                                                                            setTiposDeComponenesFormulario(ListaDeTiposDeComponentesFormulario)
                                                                                        }
                                                                                        else {

                                                                                            let ListaDeTiposDeComponentesFormulario = [...getTiposDeComponentesFormulario];

                                                                                            let ItemFiltrado = ListaDeTiposDeComponentesFormulario.filter((Item, Index) => Item.id == item.id)[0]

                                                                                            ItemFiltrado.resposta = String(getFormularioPrevisaoDeInicio);

                                                                                            ListaDeTiposDeComponentesFormulario.map((Item, Index) => {
                                                                                                if(Item.id == ItemFiltrado.id)
                                                                                                {
                                                                                                    Item = ItemFiltrado
                                                                                                }
                                                                                            })

                                                                                            setTiposDeComponenesFormulario(ListaDeTiposDeComponentesFormulario)
                                                                                        }
                                                                                    }}>
                                                                                </DateInput>
                                                                                <Icon 
                                                                                    icon = {"remove"}
                                                                                    iconSize = {15} 
                                                                                    color = {'#000000'} 
                                                                                    style = {{marginLeft: "10px"}} 
                                                                                    onClick = {() => {
                                                                                        let ListaComponentesFormulario = [...getTiposDeComponentesFormulario]

                                                                                        let ListaFiltrada = ListaComponentesFormulario.filter((Item, Index) => Item.id != item.id)

                                                                                        ListaFiltrada.map((IItem, IIndex) => IItem.id == IIndex)

                                                                                        setTiposDeComponenesFormulario(ListaFiltrada)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                    </div>}
                                                                    {item.classificacao.id == 5 &&
                                                                    <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                                                            <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? `${item.titulo}` : `${item.titulo}`}</div>
                                                                            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start", marginRight: "10px"}}>
                                                                                <TimePicker
                                                                                    autoFocus =  {true}
                                                                                    disabled = {false}
                                                                                    precision = {TimePrecision.MINUTE}
                                                                                    selectAllOnFocus = {false}
                                                                                    showArrowButtons = {false}
                                                                                    useAmPm = {false}
                                                                                    onChange = {() => {}}
                                                                                />
                                                                                <Icon 
                                                                                    icon = {"remove"}
                                                                                    iconSize = {15} 
                                                                                    color = {'#000000'} 
                                                                                    style = {{marginLeft: "10px"}} 
                                                                                    onClick = {() => {
                                                                                        let ListaComponentesFormulario = [...getTiposDeComponentesFormulario]

                                                                                        let ListaFiltrada = ListaComponentesFormulario.filter((Item, Index) => Item.id != item.id)

                                                                                        ListaFiltrada.map((IItem, IIndex) => IItem.id == IIndex)

                                                                                        setTiposDeComponenesFormulario(ListaFiltrada)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                    </div>}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                </Component.ContentFormHeader>
                                            </Component.ContentCard>
                                        </div>
                                    </div>

                                    {getCriandoOportunidade == true &&
                                    <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                        <div
                                            style = {{
                                                background: "#1098F7", 
                                                color: "#FFFFFF", 
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px", 
                                                outline: "none",
                                                width: "250px", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                fontSize: "12px", 
                                                height: "40px", 
                                                marginRight: '10px',
                                        }}>
                                            <Lottie
                                                options = {{
                                                    loop: true,
                                                    autoplay: true,
                                                    animationData: LottieFiles.Load04,
                                                    rendererSettings: {
                                                        preserveAspectRatio: "xMidYMid slice"
                                                    }
                                                }}
                                                height={30}
                                                width={30}
                                            />
                                        </div>
                                    </div>}
                                    
                                    {getCriandoOportunidade == false &&
                                    <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}> 
                                        <Button
                                            icon = {<Icon icon = "add" iconSize = {14} color = {"#FFFFFF"}/>} 
                                            style = {{
                                                background: "#1098F7", 
                                                color: "#FFFFFF", 
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px", 
                                                outline: "none",
                                                width: "250px", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                fontSize: "12px", 
                                                height: "40px", 
                                                marginRight: '10px',
                                            }}  
                                            onClick = { async () => {

                                                if(await ValidarLead() == true)
                                                {

                                                    if(IDLead == 0)
                                                    {

                                                        setCriandoOportunidade(true)

                                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Lead está sendo cadastrado no controle de sala 😁", NotificacaoInterna.TipoDeNotificacao.Informacao);
    
                                                        var ObjetoTelefones = [] as Array<Objeto.Telefone>;
    
                                                        var ObjetoFormularios = [] as Array<{ id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>
    
                                                        var ObjetoFormulariosReoder = [] as Array<{ id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>
        
                                                        if(getTelefones.length > 0 && getTelefones.filter((item, index) => item.descricao == "").length == 0)
                                                        {
                                                            getTelefones.map((item, index) => {
                                                                var TelefoneDesFormatado = Telefone.DesformatarTexto(item?.descricao ?? "")
                                                                var ObterNumero = Telefone.ObterNumero(TelefoneDesFormatado)
            
                                                                if(ObterNumero[3] == "3" || ObterNumero[3] == "4")
                                                                {
                                                                    ObjetoTelefones.push({
                                                                        classificacao: 2,
                                                                        ddi: "55",
                                                                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                        observacao: ""
                                                                    })
                                                                }
                                                                else
                                                                {
                                                                    ObjetoTelefones.push({
                                                                        classificacao: 1,
                                                                        ddi: "55",
                                                                        ddd: Telefone.ObterDDD(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                        numero: Telefone.ObterNumero(Telefone.DesformatarTexto(item?.descricao ?? "")),
                                                                        observacao: ""
                                                                    })
                                                                }
                                                            });
                                                        }
    
                                                        if(getTiposDeComponentesFormulario.length > 5)
                                                        {
                                                            let Formularios = getTiposDeComponentesFormulario;
    
                                                            Formularios.map(form => {
                                                                if (form.id > 4) {
                                                                    ObjetoFormularios.push({
                                                                        id: form.id,
                                                                        titulo: form.titulo,
                                                                        classificacao: form.classificacao,
                                                                        obrigatorio: form.obrigatorio,
                                                                        resposta: form.resposta
                                                                    })
                                                                }
                                                            })
    
                                                            ObjetoFormulariosReoder = ObjetoFormularios.map((itemReoder: any, indexReorder: number) => { itemReoder.id = indexReorder; return  itemReoder; }) ;
    
                                                            console.log(ObjetoFormulariosReoder);
                                                        }
        
                                                        var ObjetoLead = {} as Objeto.Lead;
                                                        ObjetoLead = {
                                                            id: 0,
                                                            cpf: getFormularioCPF != "" ? getFormularioCPF : null,
                                                            rg: undefined,
                                                            dataDeNascimento: undefined,
                                                            ocupacao: undefined,
                                                            nacionalidade: undefined,
                                                            nome: getFormularioNome != "" ? getFormularioNome : null,
                                                            emails: (getFormularioMeioDeContato?.id == 0 && getFormularioEmails.filter((item, index) => item.descricao == "").length == 0) ? getFormularioEmails : undefined,
                                                            telefones: (getFormularioMeioDeContato?.id == 1 && ObjetoTelefones.length > 0) ? ObjetoTelefones : undefined,
                                                            endereco: undefined,
                                                            estadoCivil: undefined,
                                                            dependentes: undefined,
                                                            localDeCaptacao: undefined,
                                                            historicoDoFunil: [],  
                                                            atividades: [],
                                                            anotacoes: [],
                                                            emailsEnviados: [],
                                                            tarefas: [],
                                                            formularios: ObjetoFormulariosReoder.length > 0 ? [{
                                                                id: getDescricaoFormulario?.id,
                                                                descricao: getDescricaoFormulario?.descricao,
                                                                perguntas: ObjetoFormulariosReoder
                                                            }] : [],
                                                            brindesOfertados: [],
                                                            historicoDoControleDeSala: []
                                                        } as Objeto.Lead;
                                                        ObjetoLead.telefones = ObjetoLead.telefones?.filter((Item) => Item) ?? null;
                                                        ObjetoLead.dependentes = ObjetoLead.dependentes?.filter((Item) => Item) ?? null;
    
                                                        try {
                                                            ObjetoLead.dependentes?.forEach(Dependente => { Dependente.telefones = Dependente.telefones?.filter((Item) => Item) ?? null });
                                                        } catch {}
    
                                                        console.log(JSON.stringify(ObjetoLead))
                                                        
                                                        var Response = await Lead.InserirEmSalaDeVendas(ContextSignIn.getContext().token, getItemPickerSalaDeVendas?.id ?? 0, "", ObjetoLead);
                                                        
                                                        if (Math.floor(Response.status / 100) == 2)
                                                        {

                                                            let ObjetoIntegranteControleDeSala = {} as { controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}

                                                            let CargoUsuario: {id: number, nome: string};

                                                            try {
                                                                CargoUsuario = ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo;
                                                            }
                                                            catch {
                                                                CargoUsuario = {
                                                                    id: 0, 
                                                                    nome: "" 
                                                                }
                                                            }

                                                            ObjetoIntegranteControleDeSala = {
                                                                controleDeSala: {
                                                                    id: getControleDeSala[0].id,
                                                                    descricao: getControleDeSala[0].descricao
                                                                },
                                                                fluxoDeSala: {
                                                                    etapa: getFluxoDeSala[0].etapa,
                                                                    descricao: getFluxoDeSala[0].descricao
                                                                },
                                                                salaDeVenda: {
                                                                    id: getItemPickerSalaDeVendas?.id ?? 0,
                                                                    descricao: getItemPickerSalaDeVendas?.descricao ?? ""
                                                                },
                                                                pessoa: {
                                                                    id: ContextSignIn.getContext().pessoa.id,
                                                                    nome: ContextSignIn.getContext().pessoa.nome,
                                                                    cargo: CargoUsuario,
                                                                    email: ContextSignIn.getContext().pessoa.emails ? ContextSignIn.getContext().pessoa.emails[0] : null
                                                                }
                                                            }

                                                            let ResponseCadastroControleDeSala = await Lead.NovoIntegranteDoControleDeSala(ContextSignIn.getContext().token, Response.data.id, ObjetoIntegranteControleDeSala);

                                                            if (Math.floor(ResponseCadastroControleDeSala.status / 100) == 2)
                                                            {
                                                                let ListaDeLeads = [...getListaDeLeads];

                                                                Response.data.historicoDoControleDeSala.unshift(ResponseCadastroControleDeSala.data)
                                                                Response.data.keyField = ResponseCadastroControleDeSala.data.fluxoDeSala.descricao;

                                                                console.log(Response.data);
    
                                                                ListaDeLeads.push(Response.data);
        
                                                                setListaDeLeads(ListaDeLeads);
                                                                NotificacaoInterna.ExibirNotificacao("Lead cadastrado com no controle de sala", "Registro devidamente cadastrado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                setCriandoOportunidade(false);
                                                                handleCloseDrawerAdicionar();
                                                            }
                                                            else
                                                            {
                                                                setCriandoOportunidade(false);
                                                                NotificacaoInterna.ExibirNotificacao("Falha ao cadastrar o lead no controle de sala", "😱 Houve uma falha ao cadastrar o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                            }
                                                        }
                                                        else
                                                        {
                                                            setCriandoOportunidade(false);
                                                            NotificacaoInterna.ExibirNotificacao("Falha ao cadastrar o lead no controle de sala", "😱 Houve uma falha ao cadastrar o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        }

                                                    }
                                                    else
                                                    {
                                                        setCriandoOportunidade(true);

                                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Lead está sendo vinculado ao controle de sala 😁", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                        
                                                        let ObjetoIntegranteControleDeSala = {} as { controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}

                                                        let CargoUsuario: {id: number, nome: string};

                                                        try {
                                                            CargoUsuario = ContextSignIn.getContext().cargosPorGrupoDeEmpresa.filter(item => item.grupoDeEmpresas.id == ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado)[0].cargo;
                                                        }
                                                        catch {
                                                            CargoUsuario = {
                                                                id: 0, 
                                                                nome: "" 
                                                            }
                                                        }

                                                        ObjetoIntegranteControleDeSala = {
                                                            controleDeSala: {
                                                                id: getControleDeSala[0].id,
                                                                descricao: getControleDeSala[0].descricao
                                                            },
                                                            fluxoDeSala: {
                                                                etapa: getFluxoDeSala[0].etapa,
                                                                descricao: getFluxoDeSala[0].descricao
                                                            },
                                                            salaDeVenda: {
                                                                id: getItemPickerSalaDeVendas?.id ?? 0,
                                                                descricao: getItemPickerSalaDeVendas?.descricao ?? ""
                                                            },
                                                            pessoa: {
                                                                id: ContextSignIn.getContext().pessoa.id,
                                                                nome: ContextSignIn.getContext().pessoa.nome,
                                                                cargo: CargoUsuario,
                                                                email: ContextSignIn.getContext().pessoa.emails ? ContextSignIn.getContext().pessoa.emails[0] : null
                                                            }
                                                        }

                                                        console.log(JSON.stringify(ObjetoIntegranteControleDeSala))

                                                        let ResponseCadastroControleDeSala = await Lead.NovoIntegranteDoControleDeSala(ContextSignIn.getContext().token, IDLead, ObjetoIntegranteControleDeSala);

                                                        if (Math.floor(ResponseCadastroControleDeSala.status / 100) == 2)
                                                        {
                                                            let ListaDeLeads = [...getListaDeLeads];

                                                            if (LeadSelecionado) {

                                                                LeadSelecionado.historicoDoControleDeSala.unshift(ResponseCadastroControleDeSala.data);
                                                                LeadSelecionado.keyField = ResponseCadastroControleDeSala.data.fluxoDeSala.descricao;
                                                                
                                                                console.log(LeadSelecionado);
                                                                
                                                                ListaDeLeads.push(LeadSelecionado);
                                                                setListaDeLeads(ListaDeLeads);

                                                            }
                                                            NotificacaoInterna.ExibirNotificacao("Lead vinculado ao controle de sala", "Registro devidamente vinculado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                            setCriandoOportunidade(false);
                                                            handleCloseDrawerAdicionar();
                                                        }
                                                        else
                                                        {

                                                            setCriandoOportunidade(false);
                                                            NotificacaoInterna.ExibirNotificacao("Falha ao vincular o lead no controle de sala", "😱 Houve uma falha ao vincular o registro, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);

                                                        }
                                                        
                                                    }

                                                }

                                        }}>Criar nova oportunidade</Button>
                                    </div>}

                                </div>
                            )}
                        </Droppable>

                        <Droppable droppableId = "droppable2">
                            {(provided, snapshot) => (
                            <div 
                                ref = {provided.innerRef}
                                {...provided.droppableProps}
                                style = {{
                                    width: getOptionCustomizedFields == true ? '35%' : "0%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    transition: "width 0.5s",
                            }}>
                                <div 
                                    style = {{
                                        padding: "20px",
                                        height: "100%",
                                        width: "100%",
                                        opacity: getOptionCustomizedFields == true ? 1 : 0,
                                        transition: "opacity 0.15s"
                                }}>
                                    <div 
                                        style = {{
                                            display: "flex", 
                                            alignItems: "center", 
                                            justifyContent: "space-between"
                                    }}>
                                        <div style = {{fontWeight: "bold", fontSize: "13px", color: "#000000"}}>Escolha um campo de formulário</div>
                                    </div>
                                    <div style = {{ marginTop: "20px", marginLeft: "0px" }}>
                                        <div>
                                            {getTiposDeComponentesFormulario.map((item, index) => item.id <= 4 && (
                                                <Draggable 
                                                    key = {item.id} 
                                                    draggableId={String(item.id)} 
                                                    index = {index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef} 
                                                            {...provided.draggableProps} 
                                                            {...provided.dragHandleProps}>
                                                            <div 
                                                                style = {{
                                                                    backgroundColor: "#e9f1ff", 
                                                                    width: "210px", 
                                                                    height: "40px", 
                                                                    display: "flex", 
                                                                    alignItems: "center",
                                                                    justifyContent: "flex-start", 
                                                                    marginLeft: "0px", 
                                                                    marginBottom: "10px",
                                                                    borderRadius: "5px",
                                                                    paddingLeft: "10px"
                                                            }}>
                                                                <Icon icon = "text-highlight" iconSize = {15} color = "#000000"/>
                                                                <div
                                                                    style = {{
                                                                        color: "#000000",
                                                                        border: "none", 
                                                                        boxShadow: "none", 
                                                                        borderRadius: "5px",
                                                                        outline: "none",
                                                                        fontSize: "12px",
                                                                        marginLeft: "7px"
                                                                }}>{item.classificacao.descricao}</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                        </Droppable>

                    </DragDropContext>

                </div>
            
            </Drawer>

            {/** Dialog importação de oportunidades */}
            <Dialog
                style = {{width: '40%', backgroundColor: "#FFFFFF"}}
                icon = {"cloud-upload"}
                onClose = {handleDialogImportOportunitesClose}
                title = {"Importando oportunidades"}
                autoFocus = {true}
                isOpen = {getDialogImportOportunites.isOpen}
                canEscapeKeyClose = {getDialogImportOportunites.canEscapeKeyClose}
                canOutsideClickClose = {getDialogImportOportunites.canOutsideClickClose}
                enforceFocus = {getDialogImportOportunites.enforceFocus}
                usePortal = {getDialogImportOportunites.usePortal} 
            >

                <div style = {{display: "flex", flexDirection: "column", alignItems: "center"}}>

                    <div style = {{width: "95%", marginTop: "30px"}}>
                        <div 
                            style = {{
                                border: "1px dotted #00000050", 
                                borderRadius: "5px", 
                                height: "400px", 
                                opacity: 1,
                                backgroundColor: "#FFFFFF", 
                                marginTop: "30px", 
                                display: "flex", 
                                flexDirection: "column",
                                alignItems: "center", 
                                justifyContent: "center", 
                                width: "100%",
                                cursor: "pointer",
                                color: "#00000040",
                                transition: "height 0.5s, opacity 0.5s"
                            }}
                            onClick = {() => {
                                document.getElementById("uploadCSV")?.click()
                            }}>
                                {getCarregandoArquivos == false &&
                                    <>
                                        <Icon icon = "cloud-upload" iconSize = {100} color = {"#00000020"} style = {{marginBottom: "10px"}}/>
                                        <div>Importar .XLSX ou .CSV</div>
                                        <input
                                            id = "uploadCSV"
                                            type ="file"
                                            style = {{display: "none"}}
                                            accept = ".csv,.xlsx,.xls"
                                            onChange = {(e) => {
                                                let files = e.target.files;
                                                if (files)
                                                {

                                                    Papa.parse(files[0], {
                                                        delimiter: "",
                                                        newline: "",
                                                        header: false,
                                                        complete: function(results: any) {
                                                            setFieldImportLeads(results.data[0]);
                                                        }
                                                    })

                                                    Papa.parse(files[0], {
                                                        delimiter: "",
                                                        newline: "",
                                                        header: true,
                                                        complete: function(results: any) {
                                                            setImportDeLeads(results.data); 
                                                        }
                                                    })

                                                    setOptionListaDeLeads(true)
                                                    setOptionFluxoDeSala(false)
                                                    handleDialogImportOportunitesClose()
                                                }
                                            }}
                                        />
                                    </>}
                                {getCarregandoArquivos == true &&
                                <div>
                                    <Lottie
                                        options = {{
                                            loop: true,
                                            autoplay: true,
                                            animationData: LottieFiles.Load05,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height = {100}
                                        width = {100}
                                    />
                                    <div style = {{marginTop: "10px"}}>Baixando arquivo...</div>
                                </div>}
                            </div>
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        
                        {(getCarregandoArquivoDeAmostra == true || getCarregandoImport == true) &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getCarregandoImport == false && 
                        <Button
                            icon = {<Icon icon = {"cloud-upload"} iconSize = {15} color = {"#FFFFFF"}/>}
                            style = {{
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {"Importar"}
                            onClick = {async () => {}}
                        />}
                        
                        {getCarregandoArquivoDeAmostra == false && 
                        <Button
                            icon = {<Icon icon = {"download"} iconSize = {15} color = {"#534B62"}/>}
                            style = {{
                                background: "#F0F0C9", 
                                color: "#534B62",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                                marginLeft: "10px"
                            }}
                            text = {"Baixar uma planilha de amostra"}
                            onClick = {async () => {}}
                        />}

                    </div>

                </div>

            </Dialog>

            {/** Dialog criação de tarefa */}
            <Dialog
                style = {{width: '65%', backgroundColor: "#FFFFFF"}}
                icon = {"bookmark"}
                onClose = {handleDialogCreateTaskClose}
                title = {getEditandoTarefa == true ? "Editando tarefa" : "Criando tarefa"}
                autoFocus = {true}
                isOpen = {getDialogCreateTask.isOpen}
                canEscapeKeyClose = {getDialogCreateTask.canEscapeKeyClose}
                canOutsideClickClose = {getDialogCreateTask.canOutsideClickClose}
                enforceFocus = {getDialogCreateTask.enforceFocus}
                usePortal = {getDialogCreateTask.usePortal} 
            >
            
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    
                    <Component.InputTask style = {{width: "95%", marginTop: "20px"}} id="NomeTask" placeholder={"Criando uma nova tarefa..."} value={getFormularioNomeTarefa}
                        onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                            var TextoDigitado = event.currentTarget.value
                            setFormularioNomeTarefa(TextoDigitado);
                    }}>
                    </Component.InputTask>

                    <div style={{width: "95%", display: "flex", alignItems: "center", justifyContent: "flex-start", background: "0", marginTop: "20px" }}>
                        
                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                            <div style = {{marginBottom: "5px", fontSize: 12}}>Previsão de início</div>
                            <DateInput inputProps = {{style: { background: lighten(0.08, "#D3D3D3"), width: "16vw", border: "none", boxShadow: "none", cursor: "pointer"}, onClick: () => setFormularioExibirCalendarioDataPrevisaoDeInicio(true)}} 
                                dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format('dddd[,] DD [de] MMMM [de] YYYY')}
                                popoverProps = {{disabled: !getFormularioExibirCalendarioDataPrevisaoDeInicio, onClosed: () => setFormularioExibirCalendarioDataPrevisaoDeInicio(false)}}
                                parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                placeholder={"DD/MM/AAAA"} value={getFormularioPrevisaoDeInicio} maxDate={getFormularioPrevisaoDeTermino}
                                onChange={async (DataSelecionada: Date) => {
                                    if (DataSelecionada != null) {
                                        setFormularioPrevisaoDeInicio(DataSelecionada)
                                    }
                                    else {
                                        setFormularioPrevisaoDeInicio(getFormularioPrevisaoDeInicio)
                                    }
                                }}>
                            </DateInput>
                        </div>

                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px", marginLeft: "20px"}}>
                            <div style = {{marginBottom: "5px", fontSize: 12}}>Previsão de termino</div>
                            <DateInput inputProps = {{style: { background: lighten(0.08, "#D3D3D3"), width: "16vw", border: "none", boxShadow: "none", cursor: "pointer"}, onClick: () => setFormularioExibirCalendarioDataPrevisaoDeTermino(true)}} 
                                dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format('dddd[,] DD [de] MMMM [de] YYYY')}
                                popoverProps = {{disabled: !getFormularioExibirCalendarioDataPrevisaoDeTermino, onClosed: () => setFormularioExibirCalendarioDataPrevisaoDeTermino(false)}}
                                parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                placeholder={"DD/MM/AAAA"} value={getFormularioPrevisaoDeTermino} minDate={getFormularioPrevisaoDeInicio}
                                onChange={async (DataSelecionada: Date) => {
                                    if (DataSelecionada != null && DataSelecionada < getFormularioPrevisaoDeInicio) {
                                        setFormularioPrevisaoDeInicio(DataSelecionada)
                                    }
                                    if (DataSelecionada != null) {
                                        setFormularioPrevisaoDeTermino(DataSelecionada)
                                    }
                                    else if (DataSelecionada == null) {
                                        setFormularioPrevisaoDeTermino(getFormularioPrevisaoDeInicio)
                                    }
                                }}>
                            </DateInput>
                        </div>

                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px", marginLeft: "20px"}}>
                            <div style = {{marginBottom: "5px", fontSize: 12}}>Data de termino</div>
                            <DateInput inputProps = {{style: { background: lighten(0.08, "#D3D3D3"), width: "16vw", border: "none", boxShadow: "none", cursor: "pointer"}, onClick: () => setFormularioExibirCalendarioDataDeTermino(true)}} 
                                dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format('dddd[,] DD [de] MMMM [de] YYYY')}
                                popoverProps = {{disabled: !getFormularioExibirCalendarioDataDeTermino, onClosed: () => setFormularioExibirCalendarioDataDeTermino(false)}}
                                parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                placeholder={"DD/MM/AAAA"} value={getFormularioDataDeTermino} minDate={getFormularioPrevisaoDeInicio}
                                onChange={async (DataSelecionada: Date) => {
                                    if (DataSelecionada != null) {
                                        setFormularioDataDeTermino(DataSelecionada)
                                    }
                                    else if (DataSelecionada == null) {
                                        setFormularioDataDeTermino(getFormularioDataDeTermino)
                                    }
                                }}>
                            </DateInput>
                        </div>

                    </div>

                    <div style = {{width: "95%", height: "1px", borderBottom: "1px solid #00000040", marginTop: "20px", marginBottom: "30px"}}></div>

                    <div style = {{width: "95%", display: "flex", alignItems: "center"}}>
                        
                        <div style = {{marginRight: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "5px"}}>Prioridade</div>
                            <PickerPrioridade popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerPrioridade ?? [] as Array<{id: number, descricao: string}>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: {id: number, descricao: string}) => <MenuItem onClick={async () => {
                                    await setItemPickerPrioridade(Item);
                                }} text={Item.descricao} />}
                                itemPredicate={(Texto: string, Item: {id: number, descricao: string}) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button style={{ fontWeight: "bold", background: "#FFFFFF", color: "#000000", border: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "5px", fontSize: "12px", height: "15px" }} text={getItemPickerPrioridade?.descricao.substring(0, 10).concat("...") ?? "Selecione"} rightIcon="double-caret-vertical" />
                            </PickerPrioridade>
                            {getItemPickerPrioridade && <Icon icon="filter-remove" style={{cursor: "pointer", marginRight: "10px" }} iconSize={15} onClick={async () => { await setItemPickerPrioridade(undefined); }} />}
                        </div>

                        <div style = {{marginRight: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "5px"}}>Solicitante</div>
                            <PickerSolicitante popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerSolicitante ?? [] as Array<{id: number, nome: string}>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}) => <MenuItem onClick={async () => {
                                    setItemPickerSolicitante(Item);
                                }} text={Item.nome} />}
                                itemPredicate={(Texto: string, Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}) => { return Item.nome.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button style={{ fontWeight: "bold", background: "#FFFFFF", color: "#000000", border: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "5px", fontSize: "12px", height: "15px" }} text={getItemPickerSolicitante?.nome.substring(0, 10).concat("...") ?? "Selecione"} rightIcon="double-caret-vertical" />
                            </PickerSolicitante>
                            {getItemPickerSolicitante && <Icon icon="filter-remove" style={{cursor: "pointer", marginRight: "10px" }} iconSize={15} onClick={async () => { await setItemPickerSolicitante(undefined); }} />}
                        </div>

                        <div style = {{marginRight: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "5px"}}>Responsável</div>
                            <PickerResponsavel popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerResponsavel ?? [] as Array<{id: number, nome: string}>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}) => <MenuItem onClick={async () => {
                                    setItemPickerResponsavel(Item);
                                }} text={Item.nome} />}
                                itemPredicate={(Texto: string, Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}) => { return Item.nome.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button style={{ fontWeight: "bold", background: "#FFFFFF", color: "#000000", border: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "5px", fontSize: "12px", height: "15px" }} text={getItemPickerResponsavel?.nome.substring(0, 10).concat("...") ?? "Selecione"} rightIcon="double-caret-vertical" />
                            </PickerResponsavel>
                            {getItemPickerResponsavel && <Icon icon="filter-remove" style={{cursor: "pointer", marginRight: "10px" }} iconSize={15} onClick={async () => { await setItemPickerSala(undefined); }} />}
                        </div>
                    
                    </div>

                    <div style = {{width: "95%", height: "1px", borderBottom: "1px solid #00000040", marginTop: "30px", marginBottom: "20px"}}></div>

                    <div style = {{width: "95%", marginTop: "10px"}}>
                        <div className='control-pane'>
                            <div className='control-section' id="rteTools">
                            <div className='rte-control-section'>
                                <RichTextEditorComponent id="toolsRTE" ref={(richtexteditor) => { rteObj = richtexteditor }}
                                    showCharCount={true} actionBegin={handleFullScreen}
                                    actionComplete={actionCompleteHandler} maxLength={2000} toolbarSettings={toolbarSettings}
                                    fileManagerSettings={fileManagerSettings} quickToolbarSettings={quickToolbarSettings}>
                                        <div id = "textotarefa">
                                           
                                        </div>
                                        <Inject services={[Toolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table, FileManager]} />
                                </RichTextEditorComponent>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getCarregandoTarefa == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}

                        {getCarregandoTarefa == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {getEditandoTarefa == true ? "Salvar" : "Criar"}
                            onClick = { async () => {

                                if (getItemPickerPrioridade && getItemPickerSolicitante && getItemPickerResponsavel && getFormularioNomeTarefa != "")
                                {
                                    setCarregandoTarefa(true);

                                    if (getEditandoTarefa == true)  {
    
                                        setCarregandoTarefa(true)
    
                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Uma tarefa está sendo atualizada", NotificacaoInterna.TipoDeNotificacao.Informacao);
    
                                        var descricaoDaTarefa = rteObj.getText();
        
                                        var ObjetoTarefa = {} as Objeto.NovasTarefas;
    
                                        let PosicaoDoFunil = getListaMudarDeFase.filter((item, index) => item.descricao == getFormularioKeyField)[0]
        
                                        ObjetoTarefa = {
                                            numero: getFormularioIDTarefa,
                                            prioridade: getItemPickerPrioridade ? getItemPickerPrioridade : null,
                                            solicitante: getItemPickerSolicitante ?? null,
                                            executor: getItemPickerResponsavel ?? null,
                                            assunto: getFormularioNomeTarefa,
                                            descricao: descricaoDaTarefa.replace("↵↵", "").replace("\n\n", ""),
                                            previsaoDeInicio: getFormularioPrevisaoDeInicio,
                                            previsaoDeTermino: getFormularioPrevisaoDeTermino,
                                            dataDeTermino: getFormularioDataDeTermino ?? null,
                                            dataCancelamento: null,
                                            funil: getListaFunilDeVendas ? getListaFunilDeVendas : null,
                                            posicaoDoFunil: PosicaoDoFunil ? PosicaoDoFunil : null
                                        }

                                        let Response = await Lead.AlterarTarefas(ContextSignIn.getContext().token, getFormularioID, [ ObjetoTarefa ])

                                        if (Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaDeTarefas = [...getFormularioListaTarefas];

                                            let TarefaIndex = ListaDeTarefas.findIndex(item => item.numero == getFormularioIDTarefa);

                                            ListaDeTarefas[TarefaIndex] = ObjetoTarefa;

                                            setFormularioListaTarefas(ListaDeTarefas);

                                            let ResponseLead = await Lead.BuscaPersonalizada(ContextSignIn.getContext().token, false, false, getFormularioID, "", "", "", getItemPickerSalaDeVendas?.id ?? "", getItemPickerDepartamentos?.id ?? "")

                                            if(Math.floor(ResponseLead.status / 100) == 2)
                                            {
                                                let ListaDeLeads = [...getListaDeLeads];

                                                let ItemIndex = ListaDeLeads.findIndex(item => item.id == getFormularioID);

                                                ListaDeLeads[ItemIndex] = ResponseLead.data[0];

                                                setListaDeLeads(ListaDeLeads);

                                                NotificacaoInterna.ExibirNotificacao("Tarefa atualizada com sucesso", "Registro devidamente atualizado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                setCarregandoTarefa(false);
                                                handleDialogCreateTaskClose();
                                            }
                                            else
                                            {
                                                setCarregandoTarefa(false);
                                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar atualizar uma nova tarefa", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            }
                                        }
                                        else
                                        {
                                            setCarregandoTarefa(false);
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar atualizar uma nova tarefa", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
    
                                    }
                                    else
                                    {
    
                                        setCarregandoTarefa(true);
    
                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Uma nova tarefa está sendo cadastrada", NotificacaoInterna.TipoDeNotificacao.Informacao);
    
                                        var descricaoDaTarefa = rteObj.getText();
        
                                        var ObjetoTarefa = {} as Objeto.NovasTarefas;
    
                                        let PosicaoDoFunil = getListaMudarDeFase.filter((item, index) => item.descricao == getFormularioKeyField)[0]
        
                                        ObjetoTarefa = {
                                            numero: 0,
                                            prioridade: getItemPickerPrioridade ? getItemPickerPrioridade : null,
                                            solicitante: getItemPickerSolicitante ?? null,
                                            executor: getItemPickerResponsavel ?? null,
                                            assunto: getFormularioNomeTarefa,
                                            descricao: descricaoDaTarefa.replace("↵↵", "").replace("\n\n", ""),
                                            previsaoDeInicio: getFormularioPrevisaoDeInicio,
                                            previsaoDeTermino: getFormularioPrevisaoDeTermino,
                                            dataDeTermino: getFormularioDataDeTermino ?? null,
                                            dataCancelamento: null,
                                            funil: getListaFunilDeVendas ? getListaFunilDeVendas : null,
                                            posicaoDoFunil: PosicaoDoFunil ? PosicaoDoFunil : null
                                        };
        
                                        var Response = await Lead.NovasTarefas(ContextSignIn.getContext().token, getItemPickerSalaDeVendas?.id ?? 0, getItemPickerDepartamentos?.id ?? 0, getFormularioID, [ ObjetoTarefa ]);
        
                                        if (Math.floor(Response.status / 100) == 2)
                                        {
                                            let ListaDeTarefas = [...getFormularioListaTarefas];
        
                                            ListaDeTarefas.push(...Response.data);
        
                                            setFormularioListaTarefas(ListaDeTarefas);

                                            let ResponseLead = await Lead.BuscaPersonalizada(ContextSignIn.getContext().token, false, false, getFormularioID, "", "", "", getItemPickerSalaDeVendas?.id ?? "", getItemPickerDepartamentos?.id ?? "")

                                            if(Math.floor(ResponseLead.status / 100) == 2)
                                            {
                                                let ListaDeLeads = [...getListaDeLeads];

                                                let ItemIndex = ListaDeLeads.findIndex(item => item.id == getFormularioID)

                                                ListaDeLeads[ItemIndex] = ResponseLead.data[0];

                                                setListaDeLeads(ListaDeLeads);

                                                NotificacaoInterna.ExibirNotificacao("Tarefa cadastrada com sucesso", "Registro devidamente cadastrado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                setCarregandoTarefa(false);
                                                handleDialogCreateTaskClose();
                                            }
                                            else
                                            {
                                                setCarregandoTarefa(false);
                                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar cadastrar uma nova tarefa", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            }
                                        }
                                        else
                                        {
                                            setCarregandoTarefa(false);
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar cadastrar uma nova tarefa", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
    
                                    }
                                }
                                else
                                {
                                    if (!getItemPickerPrioridade)
                                    {
                                        NotificacaoInterna.ExibirNotificacao("Prioridade não selecionada", "Selecione a prioridade", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerSolicitante)
                                    {
                                        NotificacaoInterna.ExibirNotificacao("Solicitante não selecionado", "Selecione o solicitante", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerResponsavel)
                                    {
                                        NotificacaoInterna.ExibirNotificacao("Responsável não selecionado", "Selecione o responsável", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (getFormularioNomeTarefa == "")
                                    {
                                        NotificacaoInterna.ExibirNotificacao("Escreva um nome para sua tarefa", "Informe o nome da sua tarefa", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                }

                            }}
                        />}

                    </div>

                </div>
            
            </Dialog>

            {/** Dialog criação de anotação */}
            <Dialog
                style = {{width: '65%', backgroundColor: "#FFFFFF"}}
                icon = {"annotation"}
                onClose = {handleDialogCreateAnnotationClose}
                title = {getEditandoTarefa == true ? "Editando anotação" : "Criando anotação"}
                autoFocus = {true}
                isOpen = {getDialogCreateAnnotation.isOpen}
                canEscapeKeyClose = {getDialogCreateAnnotation.canEscapeKeyClose}
                canOutsideClickClose = {getDialogCreateAnnotation.canOutsideClickClose}
                enforceFocus = {getDialogCreateAnnotation.enforceFocus}
                usePortal = {getDialogCreateAnnotation.usePortal} 
            >

                <div style = {{display: "flex", flexDirection: "column", alignItems: "center"}}>

                    <div style = {{width: "95%", marginTop: "30px"}}>
                        <div className='control-pane'>
                            <div className='control-section' id="rteTools">
                                <div className='rte-control-section'>
                                    <RichTextEditorComponent id="toolsRTE" ref={(richtexteditor) => { rteObj = richtexteditor }}
                                        showCharCount={true} actionBegin={handleFullScreen}
                                        actionComplete={actionCompleteHandler} maxLength={2000} toolbarSettings={toolbarSettings}
                                        fileManagerSettings={fileManagerSettings} quickToolbarSettings={quickToolbarSettings}>
                                            <div id = "textoannotation">
                                            
                                            </div>
                                            <Inject services={[Toolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table, FileManager]} />
                                    </RichTextEditorComponent>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getCarregandoAnotacao == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getCarregandoAnotacao == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {getEditandoTarefa == true ? "Salvar" : "Criar"}
                            onClick = {async () => {

                                setCarregandoAnotacao(true)

                                if (getEditandoTarefa == true) {

                                    setCarregandoAnotacao(true)

                                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "Uma anotação está sendo atualizada", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    var descricaoDaAnotacao = rteObj.getText();
    
                                    var ObjetoAnotacao = {} as Objeto.NovasAnotacoes;

                                    let PosicaoNoFunil = getListaMudarDeFase.filter((fase, faseIndex) => fase.descricao == getFormularioKeyField)[0]
    
                                    ObjetoAnotacao = {
                                        numero: getFormularioIDAnotacao,
                                        descricao: descricaoDaAnotacao.replace("↵↵", "").replace("\n\n", ""),
                                        funil: getListaFunilDeVendas ? getListaFunilDeVendas : null,
                                        posicaoDoFunil: PosicaoNoFunil ? PosicaoNoFunil : null
                                    }
    
                                    var Response = await Lead.AlterarAnotacoes(ContextSignIn.getContext().token, getFormularioID, [ ObjetoAnotacao ])
    
                                    if (Math.floor(Response.status / 100) == 2)
                                    {
                                        var ListaDeAnotacoes = [...getFormularioListaAnotacoes];

                                        let AnotacaoIndex = ListaDeAnotacoes.findIndex(item => item.numero == getFormularioIDAnotacao);

                                        ListaDeAnotacoes[AnotacaoIndex] = ObjetoAnotacao;
    
                                        setFormularioListaAnotacoes(ListaDeAnotacoes);

                                        let ResponseLead = await Lead.BuscaPersonalizada(ContextSignIn.getContext().token, false, false, getFormularioID, "", "", "", getItemPickerSalaDeVendas?.id ?? "", getItemPickerDepartamentos?.id ?? "")

                                        if(Math.floor(ResponseLead.status / 100) == 2)
                                        {
                                            let ListaDeLeads = [...getListaDeLeads];

                                            let ItemIndex = ListaDeLeads.findIndex(item => item.id == getFormularioID);

                                            ListaDeLeads[ItemIndex] = ResponseLead.data[0];

                                            setListaDeLeads(ListaDeLeads);

                                            NotificacaoInterna.ExibirNotificacao("Anotação atualizada com sucesso", "Registro devidamente atualizado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            setCarregandoAnotacao(false)
                                            handleDialogCreateAnnotationClose()
                                        }
                                        else
                                        {
                                            setCarregandoAnotacao(false)
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar atualizar uma nova anotação", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else
                                    {
                                        setCarregandoAnotacao(false)
                                        NotificacaoInterna.ExibirNotificacao("Erro ao tentar atualizar uma nova anotação", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                    }

                                }
                                else
                                {

                                    setCarregandoAnotacao(true)

                                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "Uma nova anotação está sendo cadastrada", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    var descricaoDaAnotacao = rteObj.getText();
    
                                    var ObjetoAnotacao = {} as Objeto.NovasAnotacoes;

                                    let PosicaoNoFunil = getListaMudarDeFase.filter((fase, faseIndex) => fase.descricao == getFormularioKeyField)[0]
    
                                    ObjetoAnotacao = {
                                        numero: 0,
                                        descricao: descricaoDaAnotacao.replace("↵↵", "").replace("\n\n", ""),
                                        funil: getListaFunilDeVendas ? getListaFunilDeVendas : null,
                                        posicaoDoFunil: PosicaoNoFunil ? PosicaoNoFunil : null
                                    }
    
                                    var Response = await Lead.NovasAnotacoes(ContextSignIn.getContext().token, getItemPickerSalaDeVendas?.id ?? 0, getItemPickerDepartamentos?.id ?? 0, getFormularioID, [ ObjetoAnotacao ])
    
                                    if (Math.floor(Response.status / 100) == 2)
                                    {

                                        var ListaDeAnotacoes = [...getFormularioListaAnotacoes];
    
                                        ListaDeAnotacoes.push(...Response.data);
    
                                        setFormularioListaAnotacoes(ListaDeAnotacoes);

                                        let ResponseLead = await Lead.BuscaPersonalizada(ContextSignIn.getContext().token, false, false, getFormularioID, "", "", "", getItemPickerSalaDeVendas?.id ?? "", getItemPickerDepartamentos?.id ?? "")

                                        if(Math.floor(ResponseLead.status / 100) == 2)
                                        {
                                            let ListaDeLeads = [...getListaDeLeads];

                                            let ItemIndex = ListaDeLeads.findIndex(item => item.id == getFormularioID)

                                            ListaDeLeads[ItemIndex] = ResponseLead.data[0];

                                            setListaDeLeads(ListaDeLeads);

                                            NotificacaoInterna.ExibirNotificacao("Anotação cadastrada com sucesso", "Registro devidamente cadastrado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            setCarregandoAnotacao(false)
                                            handleDialogCreateAnnotationClose()
                                        }
                                        else
                                        {
                                            setCarregandoAnotacao(false)
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar cadastrar uma nova anotação", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else
                                    {
                                        setCarregandoAnotacao(false)
                                        NotificacaoInterna.ExibirNotificacao("Erro ao tentar cadastrar uma nova anotação", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                    }

                                }
                            }}
                        />}

                    </div>

                </div>

            </Dialog>

            {/** Dialog criação de email */}
            <Dialog
                style = {{width: '65%', backgroundColor: "#FFFFFF"}}
                icon = {"envelope"}
                onClose = {handleDialogCreateEmailClose}
                title = { getEditandoTarefa == true ? "Editando email" : "Criando email"}
                autoFocus = {true}
                isOpen = {getDialogCreateEmail.isOpen}
                canEscapeKeyClose = {getDialogCreateEmail.canEscapeKeyClose}
                canOutsideClickClose = {getDialogCreateEmail.canOutsideClickClose}
                enforceFocus = {getDialogCreateEmail.enforceFocus}
                usePortal = {getDialogCreateEmail.usePortal} 
            >

                <div style = {{display: "flex", flexDirection: "column", alignItems: "center"}}>

                    <Component.InputTask style = {{width: "95%",marginTop: "20px"}} id="NomeTask" placeholder={"Para: "} value={getFormularioParaEmail}
                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            var TextoDigitado = event.currentTarget.value
                            setFormularioParaEmail(TextoDigitado);
                    }}>
                    </Component.InputTask>

                    <Component.InputTask style = {{width: "95%",marginTop: "10px"}} id="NomeTask" placeholder={"Cc: "} value={getFormularioCcEmail}
                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            var TextoDigitado = event.currentTarget.value
                            setFormularioCcEmail(TextoDigitado);
                    }}>
                    </Component.InputTask>

                    <Component.InputTask style = {{width: "95%",marginTop: "10px"}} id="NomeTask" placeholder={"Assunto: "} value={getFormularioAssuntoEmail}
                        onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                            var TextoDigitado = event.currentTarget.value
                            setFormularioAssuntoEmail(TextoDigitado);
                    }}>
                    </Component.InputTask>

                    <div style = {{width: "95%", marginTop: "30px"}}>
                        <div className='control-pane'>
                            <div className='control-section' id="rteTools">
                                <div className='rte-control-section'>
                                    <RichTextEditorComponent id="toolsRTE" ref={(richtexteditor) => { rteObj = richtexteditor }}
                                        showCharCount={true} actionBegin={handleFullScreen}
                                        actionComplete={actionCompleteHandler} maxLength={2000} toolbarSettings={toolbarSettings}
                                        fileManagerSettings={fileManagerSettings} quickToolbarSettings={quickToolbarSettings}>
                                            <div id = "textoemail">
                                            
                                            </div>
                                            <Inject services={[Toolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table, FileManager]} />
                                    </RichTextEditorComponent>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getCarregandoEmailEnviado == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getCarregandoEmailEnviado == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {getEditandoTarefa == true ? "Salvar" : "Criar"}
                            onClick = { async () => {
                                
                                setCarregandoEmailEnviado(true)

                                if (getEditandoTarefa == true) {

                                    setCarregandoEmailEnviado(true)

                                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "Um email está sendo atualizado", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    var descricaoDoEmail = rteObj.getText();
    
                                    var ObjetoEmailsEnviados = {} as Objeto.NovosEmailsEnviados;

                                    let PosicaoNoFunil = getListaMudarDeFase.filter((fase, faseIndex) => fase.descricao == getFormularioKeyField)[0]

                                    ObjetoEmailsEnviados = {
                                        numero: getFormularioIDEmailEnviado,
                                        para: getFormularioParaEmail,
                                        cc: getFormularioCcEmail,
                                        assunto: getFormularioAssuntoEmail,
                                        descricao: descricaoDoEmail.replace("↵↵", "").replace("\n\n", ""),
                                        funil: getListaFunilDeVendas ? getListaFunilDeVendas : null,
                                        posicaoDoFunil: PosicaoNoFunil ? PosicaoNoFunil : null
                                    }
    
                                    var Response = await Lead.AlterarEmailsEnviados(ContextSignIn.getContext().token, getFormularioID, [ ObjetoEmailsEnviados ])
    
                                    if (Math.floor(Response.status / 100) == 2)
                                    {
                                        let ListaDeEmailsEnviados = [...getFormularioListaEmails];

                                        let EmailIndex = ListaDeEmailsEnviados.findIndex(item => item.numero == getFormularioIDEmailEnviado);

                                        ListaDeEmailsEnviados[EmailIndex] = ObjetoEmailsEnviados;
    
                                        setFormularioListaEmails(ListaDeEmailsEnviados);

                                        let ResponseLead = await Lead.BuscaPersonalizada(ContextSignIn.getContext().token, false, false, getFormularioID, "", "", "", getItemPickerSalaDeVendas?.id ?? "", getItemPickerDepartamentos?.id ?? "")

                                        if(Math.floor(ResponseLead.status / 100) == 2)
                                        {
                                            let ListaDeLeads = [...getListaDeLeads];

                                            let ItemIndex = ListaDeLeads.findIndex(item => item.id == getFormularioID);

                                            ListaDeLeads[ItemIndex] = ResponseLead.data[0];

                                            setListaDeLeads(ListaDeLeads);

                                            setCarregandoEmailEnviado(false);
                                            handleDialogCreateEmailClose();
                                            NotificacaoInterna.ExibirNotificacao("Email atualizado com sucesso", "Registro devidamente atualizado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                        }
                                        else
                                        {
                                            setCarregandoEmailEnviado(false)
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar atualizar um email", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else
                                    {
                                        setCarregandoEmailEnviado(false)
                                        NotificacaoInterna.ExibirNotificacao("Erro ao tentar atualizar um email", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                    }

                                }
                                else
                                {
                                    setCarregandoEmailEnviado(true)

                                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "Um novo email está sendo cadastrado", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    var descricaoDoEmail = rteObj.getText();

                                    let PosicaoNoFunil = getListaMudarDeFase.filter((fase, faseIndex) => fase.descricao == getFormularioKeyField)[0]
    
                                    var ObjetoEmailsEnviados = {} as Objeto.NovosEmailsEnviados;

                                    ObjetoEmailsEnviados = {
                                        numero: 0,
                                        para: getFormularioParaEmail,
                                        cc: getFormularioCcEmail,
                                        assunto: getFormularioAssuntoEmail,
                                        descricao: descricaoDoEmail.replace("↵↵", "").replace("\n\n", ""),
                                        funil: getListaFunilDeVendas ? getListaFunilDeVendas : null,
                                        posicaoDoFunil: PosicaoNoFunil ? PosicaoNoFunil : null
                                    };

                                    console.log(JSON.stringify([ObjetoEmailsEnviados]))
    
                                    var Response = await Lead.NovosEmailsEnviados(ContextSignIn.getContext().token, getItemPickerSalaDeVendas?.id ?? 0, getItemPickerDepartamentos?.id ?? 0, getFormularioID, [ ObjetoEmailsEnviados ])
    
                                    if (Math.floor(Response.status / 100) == 2)
                                    {
                                        let ListaDeEmailsEnviados = [...getFormularioListaEmails];
    
                                        ListaDeEmailsEnviados.push(...Response.data);
    
                                        setFormularioListaEmails(ListaDeEmailsEnviados);

                                        let ResponseLead = await Lead.BuscaPersonalizada(ContextSignIn.getContext().token, false, false, getFormularioID, "", "", "", getItemPickerSalaDeVendas?.id ?? "", getItemPickerDepartamentos?.id ?? "")

                                        if(Math.floor(ResponseLead.status / 100) == 2)
                                        {
                                            let ListaDeLeads = [...getListaDeLeads];

                                            let ItemIndex = ListaDeLeads.findIndex(item => item.id == getFormularioID)

                                            ListaDeLeads[ItemIndex] = ResponseLead.data[0];

                                            setListaDeLeads(ListaDeLeads);

                                            setCarregandoEmailEnviado(false);
                                            handleDialogCreateEmailClose();
                                            NotificacaoInterna.ExibirNotificacao("Email cadastrado com sucesso", "Registro devidamente cadastrado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                        }
                                        else
                                        {
                                            setCarregandoEmailEnviado(false)
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar cadastrar um novo email", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else
                                    {
                                        setCarregandoEmailEnviado(false)
                                        NotificacaoInterna.ExibirNotificacao("Erro ao tentar cadastrar um novo email", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                    }

                                }

                            }}
                        />}

                    </div>

                </div>

            </Dialog>

            {/** Dialog vinculando brinde */}
            <Dialog
                style = {{width: '30%', backgroundColor: "#FFFFFF"}}
                icon = {"people"}
                onClose = {handleDialogCreateVincularBrindeClose}
                title = {"Vinculando brinde"}
                autoFocus = {true}
                isOpen = {getDialogVincularBrinde.isOpen}
                canEscapeKeyClose = {getDialogVincularBrinde.canEscapeKeyClose}
                canOutsideClickClose = {getDialogVincularBrinde.canOutsideClickClose}
                enforceFocus = {getDialogVincularBrinde.enforceFocus}
                usePortal = {getDialogVincularBrinde.usePortal} 
            >
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center"}}>

                    {<div style = {{width: "95%", marginTop: "20px"}}>
                        <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Vincular um brinde`}</div>
                        <PickerVinculoDeBrinde popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerVincularBrinde} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer={(Item: IBrinde) => <MenuItem onClick = { async () => { setItensPickerSelecionadosVincularBrinde(Item) }} text={Item.brinde.descricao} />}
                            itemPredicate={(Texto: string, Item: IBrinde) => { return Item.brinde.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "calc(28vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItensPickerSelecionadosVincularBrinde?.brinde.descricao ?? "Selecione..."} rightIcon="double-caret-vertical" />
                        </PickerVinculoDeBrinde>
                    </div>}

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getCarregandoVinculoDeBrinde == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getCarregandoVinculoDeBrinde == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {"Vincular"}
                            onClick = { async () => {

                                if(getItensPickerSelecionadosVincularBrinde)
                                {

                                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "O brinde está sendo vinculado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    let Response = await Lead.NovoBrinde(ContextSignIn.getContext().token, getFormularioID, getItensPickerSelecionadosVincularBrinde);

                                    if (Math.floor(Response.status / 100) == 2)
                                    {

                                        let ListaDeLeads = [...getListaDeLeads];

                                        let ListaDeBrindesVinculados = [...getListaDeBrindesVinculados];

                                        let ItemIndex = ListaDeLeads.findIndex(item => item.id == getFormularioID);

                                        ListaDeLeads[ItemIndex].brindesOfertados.push(getItensPickerSelecionadosVincularBrinde);

                                        ListaDeBrindesVinculados.push(getItensPickerSelecionadosVincularBrinde);

                                        setListaDeLeads(ListaDeLeads);

                                        setListaDeBrindesVinculados(ListaDeBrindesVinculados);

                                        NotificacaoInterna.ExibirNotificacao("O brinde foi vinculado", "Brinde vinculado com sucesso", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                        setItensPickerSelecionadosVincularBrinde(undefined);
                                        handleDialogCreateVincularBrindeClose();
                                    }
                                    else
                                    {
                                        NotificacaoInterna.ExibirNotificacao("Erro ao tentar vincular brindes", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                    }
                                }
                                else
                                {
                                    NotificacaoInterna.ExibirNotificacao("Brinde não foi selecionado", "É necessário selecionar um brinde para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                }
                            }}
                        />}

                    </div>

                </div>
            </Dialog>

            {/** Dialog vinculando reponsável no fluxo de sala */}
            <Dialog
                style = {{width: 'calc(39vw + 15px)', backgroundColor: "#FFFFFF"}}
                icon = {"bookmark"}
                onClose = {handleDialogVinculandoClose}
                title = {"Vinculando responsável"}
                autoFocus = {true}
                isOpen = {getDialogVinculando.isOpen}
                canEscapeKeyClose = {getDialogVinculando.canEscapeKeyClose}
                canOutsideClickClose = {getDialogVinculando.canOutsideClickClose}
                enforceFocus = {getDialogVinculando.enforceFocus}
                usePortal = {getDialogVinculando.usePortal} 
            >
            
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center", overflowY: 'scroll', maxHeight: window.screen.height * 0.47}}>
                    
                    <div style = {{width: "95%", marginTop: "20px"}}>
                        <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`${getDescricaoDoCargo?.nome ?? ""}`}</div>
                        <PickerUsuariosCargos popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaDeUsuariosRelecionadosAoCargos} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer = {(Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}) => <MenuItem onClick = { async () => { await setItemListaDeUsuariosRelecionadosAoCargo(Item) }} text={Item.nome} />} itemPredicate={(Texto: string, Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button className="selector" style={{ background: "#f6f7f8", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: getItemListaDeUsuariosRelecionadosAoCargos ? "calc(35vw + 15px)" : "calc(37vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemListaDeUsuariosRelecionadosAoCargos?.nome ?? "Selecione..."} rightIcon="double-caret-vertical" />
                        </PickerUsuariosCargos>
                        {getItemListaDeUsuariosRelecionadosAoCargos && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemListaDeUsuariosRelecionadosAoCargo(undefined); }} />}
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getCarregandoEmailEnviado == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getCarregandoEmailEnviado == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {"Salvar"}
                            onClick = { async () => {

                                if(getItemListaDeUsuariosRelecionadosAoCargos && getAlterandoCargoVinculado == false)
                                {

                                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "Lead está sendo vinculado a próxima fase do controle de sala 😁", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    let ObjetoIntegranteControleDeSala = {} as { controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}

                                    let FluxosDaSala = [...getFluxoDeSala];

                                    let ItemFluxo = FluxosDaSala.filter(item => item.descricao == getFormularioKeyField)[0]; 

                                    ObjetoIntegranteControleDeSala = {
                                        controleDeSala: {
                                            id: getControleDeSala[0].id,
                                            descricao: getControleDeSala[0].descricao
                                        },
                                        fluxoDeSala: {
                                            etapa: ItemFluxo.etapa,
                                            descricao: ItemFluxo.descricao
                                        },
                                        salaDeVenda: {
                                            id: getItemPickerSalaDeVendas?.id ?? 0,
                                            descricao: getItemPickerSalaDeVendas?.descricao ?? ""
                                        },
                                        pessoa: getItemListaDeUsuariosRelecionadosAoCargos
                                    }

                                    console.log(JSON.stringify(ObjetoIntegranteControleDeSala))

                                    let ResponseCadastroControleDeSala = await Lead.NovoIntegranteDoControleDeSala(ContextSignIn.getContext().token, getFormularioID, ObjetoIntegranteControleDeSala);

                                    if (Math.floor(ResponseCadastroControleDeSala.status / 100) == 2)
                                    {
                                        let ListaDeLeads = [...getListaDeLeads];

                                        let IndexLead = ListaDeLeads.findIndex(item => item.id == getFormularioID);

                                        ListaDeLeads[IndexLead].historicoDoControleDeSala.unshift(ResponseCadastroControleDeSala.data);
                                        ListaDeLeads[IndexLead].keyField = ResponseCadastroControleDeSala.data.fluxoDeSala.descricao;
                                        setListaDeLeads(ListaDeLeads);
                                        
                                        NotificacaoInterna.ExibirNotificacao("Lead vinculado a próxima fase do controle de sala", "Registro devidamente vinculado a próxima fase 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                        setCriandoOportunidade(false);
                                        handleDialogVinculandoClose();
                                    }
                                    else
                                    {

                                        setCriandoOportunidade(false);
                                        NotificacaoInterna.ExibirNotificacao("Falha ao vincular o lead a próxima fase do controle de sala", "😱 Houve uma falha ao vincular o registro a próxima fase, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);

                                    }   
                                }
                                else if(!getItemListaDeUsuariosRelecionadosAoCargos) 
                                {
                                    NotificacaoInterna.ExibirNotificacao("Cargo não selecionado", "Por favor selecione uma pessoa para o cargo, para prosseguir 😁", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                }
                                else if (getItemListaDeUsuariosRelecionadosAoCargos && getAlterandoCargoVinculado == true)
                                {

                                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "O responsável pela fase do controle de sala está sendo alterado 😁", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    let ObjetoIntegranteControleDeSala = {} as { controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}

                                    let FluxosDaSala = [...getFluxoDeSala];

                                    let ItemFluxo = FluxosDaSala.filter(item => item.descricao == getFormularioKeyField)[0]; 

                                    ObjetoIntegranteControleDeSala = {
                                        controleDeSala: {
                                            id: getControleDeSala[0].id,
                                            descricao: getControleDeSala[0].descricao
                                        },
                                        fluxoDeSala: {
                                            etapa: ItemFluxo.etapa,
                                            descricao: ItemFluxo.descricao
                                        },
                                        salaDeVenda: {
                                            id: getItemPickerSalaDeVendas?.id ?? 0,
                                            descricao: getItemPickerSalaDeVendas?.descricao ?? ""
                                        },
                                        pessoa: getItemListaDeUsuariosRelecionadosAoCargos
                                    }

                                    console.log(JSON.stringify(ObjetoIntegranteControleDeSala))

                                    let ResponseCadastroControleDeSala = await Lead.AlterarIntegranteDoControleDeSala(ContextSignIn.getContext().token, getFormularioID, ObjetoIntegranteControleDeSala);

                                    if (Math.floor(ResponseCadastroControleDeSala.status / 100) == 2)
                                    {
                                        let ListaDeLeads = [...getListaDeLeads];

                                        let IndexLead = ListaDeLeads.findIndex(item => item.id == getFormularioID);

                                        let IndexHistorico = ListaDeLeads[IndexLead].historicoDoControleDeSala.findIndex(item => item.fluxoDeSala.descricao == getFormularioKeyField);

                                        ListaDeLeads[IndexLead].historicoDoControleDeSala[IndexHistorico] = ResponseCadastroControleDeSala.data;
                                        setListaDeLeads(ListaDeLeads);
                                        
                                        NotificacaoInterna.ExibirNotificacao("Responsável pela fase do controle de sala alterado", "Registro devidamente alterado 😁", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                        setCriandoOportunidade(false);
                                        handleDialogVinculandoClose();
                                    }
                                    else
                                    {

                                        setCriandoOportunidade(false);
                                        NotificacaoInterna.ExibirNotificacao("Falha ao alterar o responsável pela fase do controle de sala", "😱 Houve uma falha ao alterar o responsável da fase, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);

                                    }

                                }
                            }}
                        />}

                    </div>

                </div>
            
            </Dialog>

            {/** Dialog customizando componente */}
            <Dialog
                style = {{width: '40%', backgroundColor: "#FFFFFF"}}
                icon = {"annotation"}
                onClose = {() => {
                    setDragStart(false)
                    handleDialogCustomComponentClose()
                }}
                title = {"Customizando componente"}
                autoFocus = {true}
                isOpen = {getDialogCustomComponent.isOpen}
                canEscapeKeyClose = {getDialogCustomComponent.canEscapeKeyClose}
                canOutsideClickClose = {getDialogCustomComponent.canOutsideClickClose}
                enforceFocus = {getDialogCustomComponent.enforceFocus}
                usePortal = {getDialogCustomComponent.usePortal} 
            >
                <div style = {{display: "flex", alignItems: "center"}}>
                    <div style = {{marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRight: "0.5px solid #00000050", paddingBottom: "20px"}}>

                        <div style = {{fontWeight: "bold", fontSize: "16px", width: "95%"}}>{getSelectCustomComponent?.classificacao.descricao}</div>
                        
                        <div style = {{width: "95%", marginTop: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Titulo`}</div>
                            <Component.InputTask style = {{width: "100%"}} id="CustomLabel" placeholder={"Titulo do campo..."} value={getSelectCustomComponent?.titulo}
                                onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                    var TextoDigitado = event.currentTarget.value

                                    if (getSelectCustomComponent)
                                    {
                                        let selectCustomComponente = {...getSelectCustomComponent}
                                        selectCustomComponente.titulo = TextoDigitado
                                        setSelectCustomComponent(selectCustomComponente);
                                    }
                                }}>
                            </Component.InputTask>
                        </div>

                        <div style = {{display: "flex", flexDirection: "column", width: "95%", marginTop: "20px"}}>
                            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                                <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px", marginRight: "10px"}}>Este campo é obrigatório</div>
                                <Switch
                                    checked = {getSelectCustomComponent?.obrigatorio} 
                                    onChange = {() => {
                                        if(getSelectCustomComponent)
                                        {
                                            let selectCustomComponente = {...getSelectCustomComponent}
                                            selectCustomComponente.obrigatorio = !getSelectCustomComponent?.obrigatorio
                                            setSelectCustomComponent(selectCustomComponente);
                                        }
                                    }}/>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div style = {{ width: "100%", paddingRight: "2.5%", paddingLeft: "2.5%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center", borderTop: "0.5px solid #00000050"}}>
                    <Button
                        style = {{
                            flex: 1,
                            background: "#1098F7", 
                            color: "#FFFFFF",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px",
                            height: "15px",
                            marginTop: "20px"
                        }}
                        text = {"Salvar"}
                        onClick = {() => {

                            if (getSelectCustomComponent && getSelectCustomComponent.titulo != "") {

                                getTiposDeComponentesFormulario.push(
                                    {
                                        id: getTiposDeComponentesFormulario.length,
                                        titulo: getSelectCustomComponent.titulo,
                                        classificacao: getSelectCustomComponent.classificacao,
                                        obrigatorio: getSelectCustomComponent.obrigatorio,
                                        resposta: getSelectCustomComponent.resposta
                                    }
                                );
    
                                setTiposDeComponenesFormulario(getTiposDeComponentesFormulario)
                                
                                setDragStart(false) 
                                handleDialogCustomComponentClose()
                            }
                            else {}
                        }}
                    />
                </div>

            </Dialog>

            {/** Dialog selecionando lead */}
            <Dialog
                style = {{width: '40%', backgroundColor: "#FFFFFF"}}
                icon = {"people"}
                onClose = {handleDialogSelectedLeadClose}
                title = {"Selecionando lead"}
                autoFocus = {true}
                isOpen = {getDialogSelectedLead.isOpen}
                canEscapeKeyClose = {getDialogSelectedLead.canEscapeKeyClose}
                canOutsideClickClose = {getDialogSelectedLead.canOutsideClickClose}
                enforceFocus = {getDialogSelectedLead.enforceFocus}
                usePortal = {getDialogSelectedLead.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "column", alignItems: "center", overflowY: 'scroll'}}>

                    <div style = {{width: "95%", marginTop: "20px", height: window.screen.height * 0.47}}>
                        {getListaDeLeadsCadastro.map((item) => (
                            <div style = {{display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '0.5px solid #00000020', padding: "10px 10px", borderRadius: "2px", marginBottom: '5px'}}>
                                <div style = {{ width: '70%'}}>
                                    <div style = {{fontSize: "13px", marginBottom: '5px', fontWeight: 'bold'}}>{`${item.nome}`}</div>
                                    {item.emails != null && item.emails.length > 0 && <div style = {{fontSize: "11px", marginBottom: '5px', color: '#00000070'}}>{`${item.emails[0].descricao}`}</div>}
                                    {item.telefones != null && item.telefones.length > 0 && <div style = {{fontSize: "11px", marginBottom: '5px', color: '#00000070'}}>{`${Telefone.FormatarTexto(item.telefones[0].ddd + item.telefones[0].numero)}`}</div>}
                                </div>
                                <Button
                                    style = {{
                                        background: "#1098F7", 
                                        color: "#FFFFFF",
                                        boxShadow: "none",
                                        outline: "none",
                                        display: "flex", 
                                        alignItems: "center", 
                                        fontSize: "12px",
                                        height: "15px",
                                        width: "100px"
                                    }}
                                    text = {"Selecione"}
                                    onClick = {() => {

                                        LeadSelecionado = item;

                                        setLeadSelecionado(item);
                                        
                                        setFormularioNome(item.nome ?? "");

                                        setformularioID(item.id);
                                        
                                        IDLead = item.id;
                                        
                                        if (item.emails != null && item.emails.length > 0)
                                        {
                                            setFormularioMeioDeContato({ id: 0, descricao: "Email"});
                                            setFormularioEmails([{ classificacao: item.emails[0].classificacao, descricao: item.emails[0].descricao }]);
                                        }
                                        if (item.telefones != null && item.telefones.length > 0)
                                        {
                                            if (item.emails == null || item.emails.length == 0)
                                            {
                                                setFormularioMeioDeContato({ id: 1, descricao: "Telefone"});
                                            }
                                            setTelefones([{ descricao: `${item.telefones[0].ddd + item.telefones[0].numero}` }]);
                                        }

                                        handleDialogSelectedLeadClose()
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                </div>

            </Dialog>

            {/* Drawer tela de venda direta */}
            <Drawer
                style = {{ width: "100%", backgroundColor: "#FFFFFF" }}
                icon = {"people"}
                onClose={handleCloseDrawerTelaVendaDireta}
                title = {"Proposta de venda"}
                autoFocus = {true}
                isOpen = {getDrawerTelaDeVendaDireta.isOpen}
                canEscapeKeyClose = {getDrawerTelaDeVendaDireta.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerTelaDeVendaDireta.canOutsideClickClose}
                enforceFocus = {getDrawerTelaDeVendaDireta.enforceFocus}
                usePortal = {getDrawerTelaDeVendaDireta.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>
                    <VendaDireta {...ObjetoPropostaDeVenda}></VendaDireta>
                </div>
            
            </Drawer>

            {/* Collapse */}
            <Collapse isOpen = {getCollapse}>
                <div className = "collapseMenu" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "0", width: "100%", backgroundColor: "#EDEFF5" }}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", background: "0", marginLeft: "2%" }}>
                        <div className="endereco">
                            <div style = {{display: 'flex', alignItems: 'center'}}>
                                <img src={IconeHome} style={{cursor: "pointer"}} alt="" onClick={() => {History.goBack()}} />
                                <div className="bar">/</div>
                                <div className="place">Controle de sala</div>
                                {isMobile == false && <div className="bar">/</div>}
                            </div>
                        </div>
                    </div>
                    <div style = {{display: "flex", alignItems: "center", justifyContent: isMobile == true ? "center" : "space-between", background: "0", width: "90%"}}>
                        <div className = "pickerCentroDeCusto" style = {{display: 'flex', alignItems: 'center'}}>
                            <PickerSalaDeVendas popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerSalasDeVendas ?? [] as Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: {id: number, descricao: string, areas: Array<{id: number, descricao: string}>}) => <MenuItem onClick={async () => {
                                    setItemPickerSalaDeVendas(Item);
                                    setCarregandoListaDeContratos(false, () => {});
                                    var Response = await ControleDeSala.Get(ContextSignIn.getContext().token, Item.id, true);

                                    if (Math.floor(Response.status / 100) == 2)
                                    {
                                        setControleDeSala(Response.data);
                                        setFluxoDeSala(Response.data.length > 0 ? Response.data[0].fluxoDeSala : []);
                                    }
                                    else
                                    {
                                        setCarregandoListaDeContratos(undefined, () => { });
                                        NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar os fluxos de sala", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        return;
                                    }

                                    var ResponseLeads = await Lead.BuscaPersonalizada(ContextSignIn.getContext().token, false, true, "", "","","", Item.id, "")
                        
                                    // var ResponseLeads = await Lead.Get(ContextSignIn.getContext().token)
                        
                                    if (Math.floor(ResponseLeads.status / 100) == 2)
                                    {
                                        setListaDeLeads(ResponseLeads.data);
                                        setCarregandoListaDeContratos(true, () => { });
                                    }
                                    else
                                    {
                                        setCarregandoListaDeContratos(undefined, () => { });
                                        NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de fluxos de sala", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        return;
                                    }
                                }} text={Item.descricao} />}
                                itemPredicate={(Texto: string, Item: {id: number, descricao: string, areas: Array<{id: number, descricao: string}>}) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerSalaDeVendas?.descricao ?? "Sala"} rightIcon="double-caret-vertical" />
                            </PickerSalaDeVendas>
                            {getItemPickerSalaDeVendas && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerSalaDeVendas(undefined); }} />}
                        </div>
                        {getCarregandoListaDeContratos == true &&
                            <div style = {{marginRight: isMobile == true ? '0px' : '90px', display: "flex", alignItems: "center"}}>
                                <Button
                                    className = "buttonAdcionarGatilho"
                                    icon = {<Icon icon = "add" iconSize = {15} color = "#FFFFFF"/>}
                                    style = {{
                                        background: "#1098F7", 
                                        color: "#FFFFFF",
                                        border: "none", 
                                        boxShadow: "none", 
                                        borderRadius: "5px",
                                        outline: "none",
                                        width: "180px", 
                                        display: "flex", 
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "15px",
                                    }} 
                                    text = {"Criar nova oportunidade"}
                                    onClick = {() => {
                                        LeadSelecionado = undefined;
                                        IDLead = 0;
                                        setformularioID(0);
                                        setFormularioMeioDeContato(undefined);
                                        setFormularioNome("");
                                        setFormularioEmails([{ classificacao: 1, descricao: "" }]);
                                        setTelefones([{ descricao: "" }]);
                                        handleOpenDrawerAdicionar();
                                    }}
                                />
                            </div>
                        }
                        {false && <div className="hoverize" style={{ width: "110px", minWidth: "110px", height: "30px", marginRight: "90px", color: "#175372", boxShadow: "0px 0px 0px 1px #d8d8d8", padding: "0 5px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer" }}
                            onClick={Buscar}>
                            <Icon color="#175372" style={{/*{ transform: getFilterClicked ? "rotate(0deg)" : "rotate(-360deg)", transition: getFilterClicked ? "transform 2s" : "" }*/ }} icon="search" />
                            <span style = {{fontSize: "12px"}}>Pesquisar</span>
                        </div>}
                    </div>
                </div>
            </Collapse>

            {/* Abrindo Collapse */}
            <div style = {{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick = {() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>

            {getCarregandoListaDeContratos != true && <div style={{width: "100%", display: "flex", flexDirection: "column" }}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.Kanban,
                        rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
                    }}
                    height={400}
                    width={750}
                    style={{ marginTop: "100px" }}
                />
                <div className="loading" style={{ margin: "0 auto 0 auto", fontWeight: "bold" }}>{(getCarregandoListaDeContratos == undefined) ? (getItemPickerSala == undefined ? "Por favor, selecione uma sala para exibir os fluxos de sala" : ("Por favor, selecione uma sala para exibir o acompanhamento de sala")) : ((getCarregandoListaDeContratos == false) ? "Aguarde, estamos carregando os fluxos de sala" : "Pronto, os fluxos de sala logo será exibido!")}</div>
            </div>}

            {getCarregandoListaDeContratos == true && <Card style={{ width: "100%", backgroundColor: "0", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none" }}>
                
                <div style = {{height: "30px", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center", marginBottom: "5px"}}>
                    <div style = {{display: "flex", marginTop: "10px", justifyContent: "flex-start", alignItems: "flex-end", width: "100%", borderBottom: "0.5px solid #00000020", paddingRight: "2%", paddingLeft: "2%"}}>
                        <Button
                            style = {{
                                background: "0",
                                color: "#000000",
                                borderLeft: getOptionFluxoDeSala == true ? "0.5px dotted #00000050" : "none",
                                borderRight: getOptionFluxoDeSala == true ? "0.5px dotted #00000050" : "none",
                                borderTop: getOptionFluxoDeSala == true ? "0.5px dotted #00000050" : "none",
                                borderBottom: getOptionFluxoDeSala == true ? "3px solid #1098F7" : "0.5px solid #00000000",
                                marginBottom: getOptionFluxoDeSala == true ? "-2px" : "-0.5px",
                                fontWeight: getOptionFluxoDeSala == true ? "bolder" : "unset",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center",
                                fontSize: "12px", 
                                height: "15px",
                                paddingLeft: "20px",
                                paddingRight: "20px"
                            }}
                            text = {"Fluxo da sala"}
                            onClick = {() => {
                                setOptionFluxoDeSala(true)
                                setOptionListaDeLeads(false)
                            }}
                        />
                        <Button
                            style = {{
                                background: "0",
                                color: "#000000",                                
                                borderLeft: getOptionListaDeLeads == true ? "0.5px dotted #00000050" : "none",
                                borderRight: getOptionListaDeLeads == true ? "0.5px dotted #00000050" : "none",
                                borderTop: getOptionListaDeLeads == true ? "0.5px dotted #00000050" : "none",
                                borderBottom: getOptionListaDeLeads == true ? "3px solid #1098F7" : "0.5px solid #00000000",
                                marginBottom: getOptionListaDeLeads == true ? "-2px" : "-0.5px",
                                fontWeight: getOptionListaDeLeads == true ? "bolder" : "unset",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex",
                                alignItems: "center", 
                                fontSize: "12px", 
                                height: "15px",
                                paddingLeft: "20px",
                                paddingRight: "20px"
                            }}
                            text = {"Lista de leads"}
                            onClick = {() => {
                                setOptionListaDeLeads(true)
                                setOptionFluxoDeSala(false)
                            }}
                        />
                    </div>
                </div>

                {getOptionFluxoDeSala == true && <div style={{ width: "100%", overflowX: 'scroll'}}>
                    <div className='kanban-control-section' style = {{ display: 'flex', flexDirection: 'row' }}>
                        <div className='col-lg-12 control-section'>
                            <div className='control-wrapper'>
                                <KanbanComponent 
                                    ref={(kanban) => { kanbanObj = kanban }} 
                                    id = {"kanban"}
                                    keyField = {'keyField'}
                                    dataSource = {getListaDeLeads} 
                                    enableTooltip = {true}
                                    cardSettings = {{contentField: "nome", headerField: "nome", grabberField: 'color'}}
                                    dialogOpen = { async (args: any) => {

                                        if (args.data.id != null)
                                        {
                                            setformularioID(args.data.id)
                                        }
                                        if (args.data.nome != null)
                                        {
                                            setFormularioNome(args.data.nome ?? "")
                                        }
                                        if (args.data.estadoCivil != null)
                                        {
                                            getFormularioListaEstadoCivil?.map((item, index) => {
                                                if (item.id = args.data.estadoCivil)
                                                {
                                                    setFormularioEstadoCivil(item)
                                                }
                                            })
                                        }
                                        else
                                        {
                                            setFormularioEstadoCivil(undefined);
                                        }
                                        if (args.data.cpf != null)
                                        {
                                            setFormularioCPF(args.data.cpf ?? "")
                                        }
                                        if (args.data.dataDeNascimento != null)
                                        {
                                            setFormularioDataDeNascimento(args.data.dataDeNascimento ?? undefined)
                                        }
                                        else
                                        {
                                            setFormularioDataDeNascimento(undefined)
                                        }
                                        if(args.data.rg != null)
                                        {
                                            setFormularioRG(args.data.rg.numero ?? "")
                                            setFormularioRGOrgaoEmissor(args.data.rg.orgaoEmissor ?? "")
                                            setFormularioRGUF(args.data.rg.uf ?? "")
                                        }
                                        if (args.data.ocupacao != null)
                                        {
                                            setFormularioProfissao(args.data.ocupacao.nome ?? "");
                                        }
                                        if (args.data.nacionalidade != null)
                                        {
                                            setFormulacaoNacionalidade(args.data.nacionalidade ?? undefined)
                                        }
                                        if (args.data.endereco != null)
                                        {
                                            setFormularioCEP(args.data.endereco.cep ?? "")
                                            setFormularioLogradouro(args.data.endereco.logradouro ?? "")
                                            setFormularioNumeroDeEndereco(args.data.endereco.numero ?? "")
                                            setFormularioComplemento(args.data.endereco.complemento ?? "")
                                            setFormularioBairro(args.data.endereco.bairro ?? "")
                                            setFormularioCidade(args.data.endereco.cidade ?? "")
                                            setFormularioUF(args.data.endereco.uf ?? "")
                                        }
                                        if (args.data.historicoDoFunil != null && args.data.historicoDoFunil.length > 0)
                                        {
                                            setFormularioPosicaoHistoricoFunil(args.data.historicoDoFunil[0].posicaoDoFunil ?? undefined)
                                            setListaHistoricoDoFunil(args.data.historicoDoFunil ?? [])
                                        }
                                        if (args.data.emails != null && args.data.emails.length > 0)
                                        {
                                            setFormularioEmails(args.data.emails.filter((thing: any, index: number) => {
                                                const _thing = JSON.stringify(thing);
                                                return index === args.data.emails.findIndex((obj: any) => {
                                                    return JSON.stringify(obj) === _thing;
                                                })
                                            }))
                                            
                                        }
                                        else if (args.data.emails != null && args.data.emails.length == 0)
                                        {
                                            setFormularioEmails([{
                                                classificacao: 1,
                                                descricao: ""
                                            }])
                                        }
                                        if (args.data.telefones != null && args.data.telefones.length > 0)
                                        {
                                            let Lista = [];
                                            Lista = args.data.telefones.filter((tel: any) => tel != null).filter((thing: any, index: number) => {
                                                let _thing = JSON.stringify(thing);
                                                return index === args.data.telefones.findIndex((obj: any) => {
                                                    return JSON.stringify(obj) === _thing;
                                                })
                                            })
                                            setFormularioTelefones(Lista)
        
                                            let ListaDeTelefones: Array<{descricao: string}> = [];
                                            Lista.map((Item: any, index: any) => {
                                                ListaDeTelefones.push({
                                                    descricao: Item.ddd + Item.numero
                                                })
                                            })
                                            setTelefones(ListaDeTelefones ?? [{descricao: ""}])
                                        }
                                        else if (args.data.telefones != null && args.data.telefones.length == 0)
                                        {
                                            setTelefones([{descricao: ""}])
                                        }
                                        if (args.data.atividades != null  && args.data.atividades.length > 0)
                                        {
                                            setFormularioListaAtividades(args.data.atividades)
                                        }
                                        if (args.data.tarefas != null  && args.data.tarefas.length > 0)
                                        {
                                            setFormularioListaTarefas(args.data.tarefas ?? [])
                                        }
                                        if (args.data.anotacoes != null && args.data.anotacoes.length > 0)
                                        {
                                            setFormularioListaAnotacoes(args.data.anotacoes ?? [])
                                        }
                                        if (args.data.emailsEnviados != null && args.data.emailsEnviados.length > 0)
                                        {
                                            setFormularioListaEmails(args.data.emailsEnviados ?? [])
                                        }
                                        if (args.data.keyField != null)
                                        {
                                            setFormularioKeyField(args.data.keyField)
                                        }
                                        if (args.data.historicoDoControleDeSala != null && args.data.historicoDoControleDeSala.length > 0)
                                        {
                                            setFormularioPosicaoHistoricoDoControleDeSala(args.data.historicoDoControleDeSala[0].fluxoDeSala ?? undefined);
                                            setListaHistoricoDoControleDeSala(args.data.historicoDoControleDeSala);
                                        }
                                        if (args.data.brindesOfertados != null && args.data.brindesOfertados.length > 0)
                                        {
                                            setListaDeBrindesVinculados(args.data.brindesOfertados);
                                        }
                                        if (args.data.formularios != null && args.data.formularios.length > 0)
                                        {
                                            setFormularioListaDadosFormulario(args.data.formularios);
                                        }
                                        handleOpen();
                                        args.cancel = true;

                                    }}
                                    dragStart = { async (args: any) => { 
                                        console.log(args.data[0].keyField)
                                        setFormularioKeyFieldAnterior(args.data[0].keyField)
                                        setFormularioKeyField(args.data[0].keyField) 
                                    }}
                                    dragStop = { async (args: any) => {

                                        let ListaHistoricoDoControleDeSala = [...args.data[0].historicoDoControleDeSala];

                                        if(args.data[0].keyField != '' && ListaHistoricoDoControleDeSala.filter(item => item.fluxoDeSala.descricao == args.data[0].keyField).length == 0 && args.dropIndex != undefined)
                                        {
                                            let FluxosDaSala = [...getFluxoDeSala];

                                            let ItemFluxo = FluxosDaSala.filter(item => item.descricao == args.data[0].keyField)[0];

                                            if (ItemFluxo.acao.id == 1)
                                            {

                                                if (args.data[0].historicoDoControleDeSala != null)
                                                {
                                                    setListaHistoricoDoControleDeSala(args.data[0].historicoDoControleDeSala ?? []);
                                                }

                                                setformularioID(args.data[0].id);
                                                setFormularioKeyField(args.data[0].keyField);

                                                let Response = await (await Logon.Usuarios(ContextSignIn.getContext().token, ItemFluxo.cargosVinculados[0].id)).data ?? [];

                                                setListaDeUsuariosRelecionadosAoCargo(Response);

                                                setDescricaoDoCargo(ItemFluxo.cargosVinculados[0]);

                                                setAlterandoCargoVinculado(false);

                                                handleDialogVinculandoOpen();
                                            }
                                            else if (ItemFluxo.acao.id == 2)
                                            {
                                                handleOpenDrawerTelaVendaDireta();
                                            }
                                        }
                                        else
                                        {
                                            args.data[0].keyField = getFormularioKeyField
                                            setFormularioKeyField("");
                                            NotificacaoInterna.ExibirNotificacao("Não é possível retornar para essa posição, é somente permitido editar os dados existentes desta fase", "Acesse os dados do lead para editar a fase.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        }

                                        return;

                                        var ObjetoLead = {} as Objeto.Lead;

                                        ObjetoLead = {
                                            id: 0,
                                            cpf: args.data[0].cpf != null ? args.data[0].cpf  : "",
                                            rg: {
                                                numero: args.data[0].rg != null ? args.data[0].rg.numero : "",
                                                orgaoemissor: args.data[0].rg != null ? args.data[0].rg.orgaoEmissor : "",
                                                uf: args.data[0].rg != null ? args.data[0].rg.uf : ""
                                            },
                                            dataDeNascimento: args.data[0].dataDeNascimento != null ?  args.data[0].dataDeNascimento : undefined,
                                            ocupacao: {
                                                id: 0,
                                                nome: args.data[0].ocupacao != null ? args.data[0].ocupacao.nome : "",
                                                cargo: ""
                                            },
                                            nacionalidade: args.data[0].nacionalidade != null ? args.data[0].nacionalidade : undefined,
                                            nome: args.data[0].nome != null ? args.data[0].nome : "",
                                            emails: (args.data[0].emails != null && args.data[0].emails.length > 0) ? [
                                                {
                                                    classificacao: 1,
                                                    descricao: args.data[0].emails[0].descricao ?? ""
                                                }
                                            ] : [],
                                            telefones: (args.data[0].telefones != null && args.data[0].telefones.length > 0) ? [
                                                {
                                                    classificacao: 1,
                                                    ddi: "55",
                                                    ddd: args.data[0].telefones != null ? args.data[0].telefones[0].ddd : "",
                                                    numero: args.data[0].telefones != null ? args.data[0].telefones[0].numero : "",
                                                    observacao: ""
                                                }
                                            ] : [],
                                            endereco: args.data[0].endereco != null ? {
                                                classificacao: 0,
                                                cep: args.data[0].endereco != null ? args.data[0].endereco.cep : "",
                                                logradouro: args.data[0].endereco != null ? args.data[0].endereco.logradouro : "",
                                                numero: args.data[0].endereco != null ? args.data[0].endereco.numero : "",
                                                complemento: args.data[0].endereco != null ? args.data[0].endereco.complemento : "",
                                                bairro: args.data[0].endereco != null ? args.data[0].endereco.bairro : "",
                                                cidade: args.data[0].endereco != null ? args.data[0].endereco.cidade : "",
                                                uf: args.data[0].endereco != null ? args.data[0].endereco.uf : ""
                                            } : null,
                                            estadoCivil: args.data[0].estadoCivil != null ? args.data[0].estadoCivil : 0,
                                            dependentes: [],
                                            localDeCaptacao: "",
                                            historicoDoFunil: [],  
                                            atividades: [],
                                            anotacoes: [],
                                            emailsEnviados: [],
                                            tarefas: [],
                                            formularios: [],
                                            brindesOfertados: [],
                                            historicoDoControleDeSala: []

                                        };

                                        ObjetoPropostaDeVenda.prospects.push(ObjetoLead);

                                        console.log(ObjetoPropostaDeVenda)

                                        // handleOpenDrawerTelaVendaDireta() 
                                    }}>
                                    <ColumnsDirective>
                                        {getFluxoDeSala.length == 0 && (<ColumnDirective key = {-1} headerText={"Não há fluxo de sala"} keyField={""} allowToggle = {true}/>)}
                                        {/* <ColumnDirective key = {-1} headerText={"Portaria"} keyField={""} allowToggle = {true}/> */}
                                        {getFluxoDeSala.map(fluxo => (<ColumnDirective keyField={fluxo.descricao} headerText={fluxo.descricao} allowToggle = {true}/>))}
                                    </ColumnsDirective>
                                </KanbanComponent>
                            </div>
                        </div>
                    </div>
                </div>}

                {getOptionListaDeLeads == true && <div style={{ maxWidth: "100%", overflowX: 'scroll'}}>
                    <GridComponent.GridComponent
                        width = {(200 * 12) + 50}
                        id = {'grid'}
                        dataSource = {getListaDeLeads}
                        ref = {g => grid = g}
                        allowPaging = {true}
                        allowPdfExport = {true}
                        allowFiltering = {true}
                        allowReordering = {true}
                        allowResizing = {true}
                        allowSorting = {false}
                        sortSettings = {sortOptions}
                        toolbar = {toolbarOptions}
                        pdfHeaderQueryCellInfo = {pdfHeaderQueryCellInfo}
                        toolbarClick = {toolbarClick}
                        groupSettings = {groupOptions}
                        filterSettings = {filterOptions}
                        selectionSettings = {selectionOptions}
                        actionBegin = {
                            (args: any) => {
                                if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                    args.filterChoiceCount = 100000;
                                }
                            }
                        }
                        pdfExportComplete = {pdfExportComplete}
                        pageSettings = {{ pageSizes: [20, 50, 100], pageSize: 20 }}
                        locale = {"pt"}
                        currencyCode = {"BRL"}
                    >
                        <GridComponent.ColumnsDirective>
                            <GridComponent.ColumnDirective type='checkbox' width='50'/>
                            <GridComponent.ColumnDirective field='idcasal' headerText='IdCasal' textAlign='Left'/>
                            <GridComponent.ColumnDirective field='statusAtendimento' headerText='Status Atend.' textAlign='Left'/>
                            <GridComponent.ColumnDirective headerText='Nome' textAlign='Left' template = {templateNome}/>
                            <GridComponent.ColumnDirective field='cpf' headerText='CPF' textAlign='Left'/>
                            <GridComponent.ColumnDirective field='promotortmkt' headerText='Promotor Tmkt' textAlign='Left'/>
                            <GridComponent.ColumnDirective field='promotomkt' headerText='Promotor Mkt' textAlign='Left'/>
                            <GridComponent.ColumnDirective field='liner' headerText='Liner' textAlign='Left'/>
                            <GridComponent.ColumnDirective field='close' headerText='Close' textAlign='Left'/>
                            <GridComponent.ColumnDirective field='pep' headerText='PEP' textAlign='Left'/>
                            <GridComponent.ColumnDirective field='qualif' headerText='Qualif.' textAlign='Left'/>
                            <GridComponent.ColumnDirective field='motivoQualif' headerText='Motivo Qualif.' textAlign='Left'/>
                            <GridComponent.ColumnDirective field='origemCasal' headerText='Origem Casal' textAlign='Left'/>
                        </GridComponent.ColumnsDirective>
                        <GridComponent.Inject services={[GridComponent.Page, GridComponent.Toolbar, GridComponent.PdfExport, GridComponent.Aggregate, GridComponent.Filter, GridComponent.Reorder, GridComponent.Sort, GridComponent.Resize]} />
                    </GridComponent.GridComponent>
                </div>}

            </Card>}

        </Component.Container>
    );
    /* #endregion */
};

export default View;