import styled from 'styled-components';

export const Container = styled.div`
    display:block;
    height:100%;
    width:100%;
    min-width:450px;
    overflow:auto;
    background-color:#EDEFF5;

    /* width */
    ::-webkit-scrollbar {
    width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius:0px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    }

    svg {
        color: #808080;
        width: 30px;
        height: 30px;
    }
    .endereco {
        display:flex;
        align-items:center;
        background-color:0;
        width:88%;
        margin:20px auto;
        img {
            width:20px;
            height:20px;
        }
        .bar {
            font-size:18px;
            color:#bdbdbd;
            margin:0 15px;
        }
        .place {
            color:#175372;
        }
    }
    .profile {
        width:68%;
        height:150px;
        background:white;
        margin:0 auto;
        margin-top:-70px;
        margin-bottom:40px;
        border-radius:40px;
        display:flex;
        align-items:center;
    }
    .overview {
        width:88%;
        margin:0 auto 20px auto;
        margin-bottom:40px;
        background-color:#ffffff;
        display:flex;
        flex-wrap:wrap;
        flex-direction:column;
        align-items:center;
        justify-content:space-between;
        padding:0 3%;
        background-repeat:repeat;
        background-attachment: fixed;
        background-position: top;
        border-radius:5px;

    }
`;
export const Title = styled.div`
    align-items:flex-end;
    color:#123061;
    font-size:28px;
    font-weight:bold;
    justify-content:flex-start;
    width:100%;
    white-space:nowrap;
    @media (max-width:1250px) and (min-width:1150px) and (max-height:2050px) and (min-height:1950px) {
        align-items:flex-end;
        color:#f48120;
        font-size:4.5vw;
        margin: 0 auto;
        margin-top: 10px;
        justify-content:flex-start;
        width:73vw;
        white-space:nowrap;
    }
`;
export const Dashboard = styled.div`
    border-radius: 5px;
    background-color:#ffff0f;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-bottom:30px;
    margin-right:10px;
    height:130px;
    opacity:1;
    width:15%;
    min-width:150px;
    color:#ffffff;
    transition:background-color 0.25s;
    :hover{
        background-color:#E9E9E9;
    }
`;
export const DashboardItem = styled.div`
    width:65%;
    height:100%;
    display:flex;
    background-color:0;
    flex-direction:column;
    justify-content:center
`;
export const DashboardItemTitle = styled.div`
    margin-top:0px;
    font-size:18px
`;
export const DashboardItemValue = styled.div`
    margin-top:5px;
    font-size:11.5px
`;
export const DashboardItemImage = styled.img`
    width:40px;
    height:40px
`;
export const Menu = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:40px;
    padding-left: 3%;
    padding-top:20px;
    width: 88%;
    background-color:#ffffff;
    border-radius:5px;

    .menuItemDiv {
        display:flex;
        width:100%;
        margin-top:40px;
        flex-wrap:wrap;
        background-color:0;
    }

    .circleImage {
        width:60px;
        height:60px;
        border-radius:10px;
        background-color:#4D78FF;
        display:flex;
        align-items:center;
        justify-content:center;
    }
`;
export const MenuItem = styled.div`

    border-radius: 5px;
    background-color:#25546c;
    cursor:pointer;
    padding:0 30px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    height:130px;
    margin-right:2%;
    margin-bottom:30px;
    background-position:90% 135%;
    background-repeat:no-repeat;
    background-size:80px 80px;
    opacity:1;
    width:17.7%;
    min-width:250px;
    
    transition:0.25s;
    :hover{
        
        background-color:#13435c;
    }
`;
export const MenuItemImage = styled.img`
    width:35px;
    height:35px;
`;
export const MenuItemValue = styled.div`
    align-items:center;
    color:#ffffff;
    display:flex;
    font-size:18px;
    font-weight:600;
    justify-content:flex-start;
    width:70%;
    height:100%;
    background-color:0;
    text-align:left;
    flex-wrap:wrap;
`;
export const Activity = styled.div`
    justify-content:flex-start;
    margin: 0 auto;
    width: 73vw;
`;
export const ActivityItems = styled.div`
    display:flex;
    flex-wrap:wrap;
    width: 73vw;
`;
export const ActivityItem = styled.div`
    box-shadow: 0 0 10px #fff;
    border-radius: 10px;
    cursor:pointer;
    display:flex;
    height:140px;
    margin-left:1vw;
    margin-right:1vw;
    margin-top:2vw;
    padding:10px;
    width: 34vw;
    background-color:#E8E8E8;

    transition:0.5s;
    :hover{
        background-color:#F7F7F7;
    }
`;
export const ActivityItemImage = styled.img`
    align-items:center;
    border-radius: 50%;
    justify-content:center;
    margin-top:20%;
    margin-left:20%;
    width:80px;
    height:80px;
`;
export const ActivityItemTitle = styled.div`
    align-items:left;
    color:#f48120;
    font-size: 12pt;
    justify-content:center;
    padding-bottom:25px;
    padding-left:5px;
    padding-right:10px;
    text-align:right
`;
export const ActivityItemDescription = styled.div`
    align-items:left;
    color:#131313;
    font-size: 10pt;
    height:65px;
    margin-bottom: 10px;
    margin-top: auto;
    opacity: 0.6;
    overflow:hidden;
    padding-left:25px;
    padding-right:10px;
    text-align:right;
    text-overflow:ellipsis;
`;