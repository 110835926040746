import React, { useEffect, useState } from 'react';
import { Icon, Menu, MenuItem, Popover, Button, Position, Intent } from '@blueprintjs/core';

import { IconeNotificacao, IconeUsuario, LogomarcaCosentinoOriginal as LogomarcaCosentino, LogomarcaEvianOriginal as LogomarcaEvian, LogomarcaGAVResortsOriginal as LogomarcaGAVResorts, LogomarcaHarmoniaUrbanismoOriginal as LogomarcaHarmoniaUrbanismo, LogomarcaOnePlusOriginal as LogomarcaOnePlus, LogomarcaOnMoneyOriginal as LogomarcaOnMoney, LogomarcaPattroOriginal as LogomarcaPattro, LogomarcaSilvaBrancoOriginal as LogomarcaSilvaBranco } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import History from "../../../Routers";
import { NotificacaoInterna } from '../../../Services/Notification';
import * as Component from './style';


const View: React.FC = () => {
    /* #region  Variáveis  */
    const [getExibirSideBar, setExibirSideBar] = useState<boolean>(false);
    /* #endregion */

    /* #region  Funções  */
    useEffect(() => {
        async function componentDidMount() {
            if (History.location.pathname != "/" && ContextSignIn.getContext().pessoa.id == 0) {
                ContextSignIn.setContext(undefined);
                History.push("/");
                NotificacaoInterna.ExibirNotificacao("Usuário não autenticado", "Faça o seu login para acessar o sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
            }
        }
        componentDidMount();
    }, []);

    return (
        <Component.Container>
            <Component.HeaderContent>
                <div style={{ display: "flex", alignItems: "center", width: "10%", height: "100%", backgroundColor: "0" }}>
                    <img style={{ width: "80px", height: ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 ? "80px" : "30px" }} src={window.location.href.includes("onmoney") ? LogomarcaOnMoney :
                        (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 ? LogomarcaGAVResorts :
                            (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 5 ? LogomarcaHarmoniaUrbanismo :
                                (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 6 ? LogomarcaPattro :
                                    (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 8 ? LogomarcaSilvaBranco :
                                        (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 9 ? LogomarcaCosentino :
                                            (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 10 ? LogomarcaEvian : LogomarcaOnePlus))))))} />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "400px", height: "100%", backgroundColor: "0", marginLeft: "auto" }}>
                    <div style={{ display: "flex", width: "60%", alignItems: "center", justifyContent: "space-between" }}>
                        <img style={{ width: "30px", height: "30px", cursor: "not-allowed", margin: "0 35px 0 0" }} src={IconeNotificacao} alt="" />
                        <Popover content={
                            <Menu>
                                <MenuItem icon="comparison" text="De → Para">
                                    <MenuItem icon="comparison" text="Categorias de reserva" href="/Configuracoes/CategoriasDeReserva" />
                                    <MenuItem icon="comparison" text="CNAEs" href="/Configuracoes/CNAEs" />
                                    <MenuItem icon="comparison" text="Controle de sala" href="/Configuracoes/ControleDeSala" />
                                    <MenuItem icon="comparison" text="Empresas" href="/Configuracoes/Empresas" />
                                    <MenuItem icon="comparison" text="Funil de vendas" href="/Configuracoes/FunilDeVendas" />
                                    <MenuItem icon="comparison" text="Gestão de usuários" href="/Configuracoes/GestaoDeUsuarios" />
                                    <MenuItem icon="comparison" text="NFSe" href="/Configuracoes/NFSe" />
                                </MenuItem>
                            </Menu>
                        } position={Position.BOTTOM_RIGHT}>
                            <Button icon="cog" text="" style={{ background: "transparent", boxShadow: "none", border: "none" }} />
                        </Popover>
                        <div style={{ width: "50px", minWidth: "50px", height: "50px", minHeight: "50px", borderRadius: "50%", backgroundColor: "#808080", display: "flex", alignItems: "center", justifyContent: "center", margin: "0 calc(35px)" }}>
                            <Popover content={
                                <Menu>
                                    <MenuItem icon="log-out" text="Sair" onClick={async () => {
                                        await ContextSignIn.setContext(undefined);
                                        History.push("/");
                                    }} />
                                </Menu>
                            } position={Position.BOTTOM_RIGHT}>
                                <img style={{ cursor: "pointer", width: "20px", height: "20px", marginLeft: "2px" }} alt="" src={IconeUsuario} />
                            </Popover>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", color: "#123061", marginRight: '35px' }}>
                            <strong id="id" style={{ fontSize: ContextSignIn.getContext().pessoa.nome.length <= 13 ? "14px" : "12px" }}>{ContextSignIn.getContext().pessoa.nome}</strong>
                        </div>
                        <button style={{}} type="button">
                            <Icon icon={History.location.pathname == "/Menu" ? "log-out" : "layout-grid"} style={{ cursor: "pointer" }} iconSize={10} onClick={async () => {
                                (History.location.pathname == "/Menu") ? History.push("/") : History.push("/Menu");
                                getExibirSideBar == true ? await setExibirSideBar(false) : await setExibirSideBar(true)
                            }} />
                        </button>
                    </div>
                </div>
            </Component.HeaderContent>
        </Component.Container>
    );
    /* #endregion */
};


export default View;