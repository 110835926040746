import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

class TabelaDeVenda {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Obter a tabela de venda da unidade
     * @param {string} Token Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe o código do centro de custo (sigla)
     * @param {number} Local Informe o código do local (id)
     * @param {number} Sublocal Informe o código do sublocal (id)
     **/
    public async Get(Token: string, Empresa: number, CentroDeCusto: string, Local: number, Sublocal: number): Promise<AxiosResponse<Objeto.TabelaDeVenda>> {
        return await Context.get<Objeto.TabelaDeVenda>(`/TabelaDeVenda/${Token}/${Empresa}/${CentroDeCusto}/${Local}/${Sublocal}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new TabelaDeVenda();