import React, { useEffect, useState, memo } from 'react';
import { AiOutlineRocket } from 'react-icons/ai';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from "react-router-dom";
import { Button, Card, FormGroup, H6, InputGroup, MenuItem } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select } from "@blueprintjs/select";
import Lottie from 'react-lottie';
import Logo from '../../Assets/Images/SingIn/Logo.png';
import './style'

import { LogomarcaCosentinoOriginal as LogomarcaCosentino, LogomarcaEvianOriginal as LogomarcaEvian, LogomarcaGAVResortsBranca as LogomarcaGAVResorts, LogomarcaHarmoniaUrbanismoOriginal as LogomarcaHarmoniaUrbanismo, LogomarcaOnePlusOriginal as LogomarcaOnePlus, LogomarcaOnMoneyBranca as LogomarcaOnMoney, LogomarcaPattroOriginal as LogomarcaPattro, LogomarcaSilvaBrancoOriginal as LogomarcaSilvaBranco } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import * as LottieFiles from '../../../Data/Lottie';
import History from "../../../Routers";
import { CentroDeCusto, Empresa, Logon, Pessoa, SoftwareExterno } from '../../../Services/Controllers';
import { NotificacaoInterna } from '../../../Services/Notification';
import { CPF } from '../../../Themes/TextFormat';
import Email from '../../../Themes/TextFormat/Email';
import { Objeto } from "../../../Services/Models";

import { Container, Form } from './style';
import './style.css'
import viewModel from './viewModel';

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const [getFormularioNome, setFormularioNome] = useState<string>("");
    const [getFormularioCPF, setFormularioCPF] = useState<string>("");
    const [getFormularioEmail, setFormularioEmail] = useState<string>("");
    const [getFormularioSenha, setFormularioSenha] = useState<string>("");
    const [getItemPickerGrupoDeEmpresas, setItemPickerGrupoDeEmpresas] = useState<{ id: number, descricao: string, empresas: Array<Objeto.Empresa> }>();
    const [getListaPickerGrupoDeEmpresas, setListaPickerGrupoDeEmpresas] = useState<Array<{ id: number, descricao: string, empresas: Array<Objeto.Empresa> }>>([]);
    const PickerGrupoDeEmpresas = Select.ofType<{ id: number, descricao: string, empresas: Array<Objeto.Empresa> }>();
    const [getCadastrandoUsuario, setCadastrandoUsuario] = useState<boolean>(false);
    /* #endregion */

    /* #region  Funções  */
    /* Sobre esta função *//**
     * Valida os dados digitados e tenta efetuar o login do usuário
     **/
    async function EfetuarCadastro(): Promise<void> {
        await setCadastrandoUsuario(true);
        if (await ValidarFormulario() == true) {
            var ResponsePessoa = await Pessoa.Get("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", CPF.DesformatarTexto(getFormularioCPF));
            if (Math.floor(ResponsePessoa.status / 100) == 2) {
                let ResponseUsuario = await Logon.Post("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", ResponsePessoa.data.id, getFormularioSenha);
                if (Math.floor(ResponseUsuario.status / 100) == 2) {
                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "seja bem vindo ao nosso sistema!", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                }
                else {
                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "Você já se encontra cadastrado em nosso sistema!", NotificacaoInterna.TipoDeNotificacao.Erro);
                }
            }
            else {
                NotificacaoInterna.ExibirNotificacao("Cadastrando usuário...", "Aguarde enquanto cadastramos o seu usuário", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                ResponsePessoa = await Pessoa.Post("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", {
                    nome: getFormularioNome,
                    cpf: CPF.DesformatarTexto(getFormularioCPF),
                    natureza: 5,
                    emails: [{ classificacao: 1, descricao: getFormularioEmail } as Objeto.Email]
                } as Objeto.Pessoa);
                let ResponseUsuario = await Logon.Post("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", ResponsePessoa.data.id, getFormularioSenha);
                if (Math.floor(ResponseUsuario.status / 100) == 2) {
                    NotificacaoInterna.ExibirNotificacao("Usuário cadastrado!", "Aguarde enquanto vinculamos o seu usuário aos sistemas externos", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                    let ResponseSignIn = await Logon.Get(CPF.DesformatarTexto(getFormularioCPF), getFormularioSenha);
                    switch (getItemPickerGrupoDeEmpresas?.id) {
                        case 5:
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 18, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 18, "22VEN", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 18, "22VEN", "U2VydmVyPTQ1LjY1LjIyMi4xMzg7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9T25lcGx1cztQYXNzd29yZD1PbiZQbHVzQDIwMjE7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            break;
                        case 4:
                            while (Math.floor((await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 20, ResponsePessoa.data.id)).status / 100) != 2);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 20, "SALPR", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 20, "SALPR", "U2VydmVyPTEwLjIwLjAuMTUzXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 21, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 21, "SALPK", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 21, "SALPK", "U2VydmVyPTEwLjIwLjAuMTUzXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 22, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 22, "SALEX", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 22, "SALEX", "U2VydmVyPTEwLjIwLjAuMTUzXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 23, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 23, "PORAL", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 23, "PORAL", "U2VydmVyPTEwLjIwLjAuMTUzXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 26, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 26, "PYRIN", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 26, "PYRIN", "U2VydmVyPTEwLjIwLjAuMTUzXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 28, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 28, "SBR", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 28, "SBR", "U2VydmVyPTEwLjIwLjAuMTUzXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 32, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 32, "SBR", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 32, "SBR", "U2VydmVyPTEwLjIwLjAuMTUzXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            break;
                        case 8:
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, "BSBDF", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, "BSBDF", "U2VydmVyPTE5MS4zMy4xNzIuNTk7RGF0YWJhc2U9VUFVX1NCO1VzZXIgSUQ9ZGlnaXRhbGludDtQYXNzd29yZD1zb3MkbmV0MzI7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, "COCGO", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, "COCGO", "U2VydmVyPTE5MS4zMy4xNzIuNTk7RGF0YWJhc2U9VUFVX1NCO1VzZXIgSUQ9ZGlnaXRhbGludDtQYXNzd29yZD1zb3MkbmV0MzI7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, "CRZDF", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, "CRZDF", "U2VydmVyPTE5MS4zMy4xNzIuNTk7RGF0YWJhc2U9VUFVX1NCO1VzZXIgSUQ9ZGlnaXRhbGludDtQYXNzd29yZD1zb3MkbmV0MzI7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, "LSLDF", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, "LSLDF", "U2VydmVyPTE5MS4zMy4xNzIuNTk7RGF0YWJhc2U9VUFVX1NCO1VzZXIgSUQ9ZGlnaXRhbGludDtQYXNzd29yZD1zb3MkbmV0MzI7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, "VALGO", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 19, "VALGO", "U2VydmVyPTE5MS4zMy4xNzIuNTk7RGF0YWJhc2U9VUFVX1NCO1VzZXIgSUQ9ZGlnaXRhbGludDtQYXNzd29yZD1zb3MkbmV0MzI7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            break;
                        case 9:
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 30, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 30, "JRDEU", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 30, "JRDEU", "U2VydmVyPTE5Mi4xNjguMS40MFxTUUxFWFBSRVNTO0RhdGFiYXNlPVVBVVRFU1RFO1VzZXIgSUQ9b25lcGx1cztQYXNzd29yZD11dmdIMUJ5dEhMRDQ1I0A7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            break;
                        case 10:
                            await Empresa.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 33, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 33, "COTAS", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 33, "COTAS", "U2VydmVyPTIwMC45LjE5LjE4NTtEYXRhYmFzZT1VQVVQTDtVc2VyIElEPVNBO1Bhc3N3b3JkPWV2aWFuX2FkbWluITE5O011bHRpcGxlQWN0aXZlUmVzdWx0U2V0cz10cnVl", ResponsePessoa.data.id, "ONEPLUS");
                            await CentroDeCusto.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 33, "APTO", ResponsePessoa.data.id);
                            await SoftwareExterno.AutorizarPermissaoDeAcesso("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", getItemPickerGrupoDeEmpresas?.id, 33, "APTO", "U2VydmVyPTIwMC45LjE5LjE4NTtEYXRhYmFzZT1VQVVQTDtVc2VyIElEPVNBO1Bhc3N3b3JkPWV2aWFuX2FkbWluITE5O011bHRpcGxlQWN0aXZlUmVzdWx0U2V0cz10cnVl", ResponsePessoa.data.id, "ONEPLUS");
                            break;
                    }
                    NotificacaoInterna.ExibirNotificacao("Tudo pronto!", "Espero que tenha uma excelente experiência com nosso sistema", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                    ContextSignIn.setContext({ token: "NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", pessoa: ResponseSignIn.data.pessoa, UltimoGrupoDeEmpresasAcessado: getItemPickerGrupoDeEmpresas?.descricao, IdUltimoGrupoDeEmpresasAcessado: getItemPickerGrupoDeEmpresas?.id, cargosPorGrupoDeEmpresa: ResponseSignIn.data.cargosPorGrupoDeEmpresa });
                    History.push("/");
                }
                else {
                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "Houve uma falha ao tentar cadastrar o seu usuario, por favor, entre em contato com o desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                }
            }
        }
        await setCadastrandoUsuario(false);
    };
    async function ValidarFormulario(): Promise<Boolean> {
        if (!getItemPickerGrupoDeEmpresas) {
            NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Informe a empresa ao qual deseja acesso", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        if (!getFormularioNome) {
            NotificacaoInterna.ExibirNotificacao("Nome não preenchido", "Informe o nome", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        if (!getFormularioCPF) {
            NotificacaoInterna.ExibirNotificacao("CPF não preenchido", "Informe o CPF", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        if (CPF.TextoValido(getFormularioCPF) == false) {
            NotificacaoInterna.ExibirNotificacao("CPF inválido!", "Verifique se o CPF digitado é válido.", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getFormularioEmail) {
            NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        if (!getFormularioSenha) {
            NotificacaoInterna.ExibirNotificacao("Senha não preenchida", "Informe a senha", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        return true
    }
    useEffect(() => {
        async function componentDidMount() {
            await setListaPickerGrupoDeEmpresas((await Empresa.GruposDeEmpresas("", true)).data);
        }
        componentDidMount();
    }, []);
    return (
        <Container>
            <Form>
                <img style={{ width: "200px", height: "150px", objectFit: "contain" }} src={window.location.href.includes("onmoney") ? LogomarcaOnMoney :
                    (getItemPickerGrupoDeEmpresas?.id == 4 ? LogomarcaGAVResorts :
                        (getItemPickerGrupoDeEmpresas?.id == 5 ? LogomarcaHarmoniaUrbanismo :
                            (getItemPickerGrupoDeEmpresas?.id == 6 ? LogomarcaPattro :
                                (getItemPickerGrupoDeEmpresas?.id == 8 ? LogomarcaSilvaBranco :
                                    (getItemPickerGrupoDeEmpresas?.id == 9 ? LogomarcaCosentino :
                                        (getItemPickerGrupoDeEmpresas?.id == 10 ? LogomarcaEvian : LogomarcaOnePlus))))))} />
                <h6>Preencha os campos abaixo</h6>
                <FormGroup labelFor="GrupoDeEmpresas" inline={true}>
                    <PickerGrupoDeEmpresas inputProps={{ style: { background: "#00384a", color: "#ffffff" } }} className="selectmenu" popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerGrupoDeEmpresas} onItemSelect={() => { }} noResults={<MenuItem style={{ width: "276px", background: "#00384a", color: "#ffffff" }} disabled={true} text="Sem resultados disponíveis" />}
                        itemRenderer={(Item: { id: number, descricao: string, empresas: Array<Objeto.Empresa> }) => <MenuItem style={{ width: "276px" }} onClick={async () => {
                            await setItemPickerGrupoDeEmpresas(Item);
                        }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: { id: number, descricao: string, empresas: Array<Objeto.Empresa> }) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                        <Button style={{ margin: "20px 0px 0px 0px", background: "#00384a", color: "#ffffff", width: "286px", height: "40px", outline: "none", border: "#2F855A 2px solid" }} text={getItemPickerGrupoDeEmpresas?.descricao ?? "Selecione a empresa"} rightIcon="double-caret-vertical" color="#ffffff" />
                    </PickerGrupoDeEmpresas>
                </FormGroup>
                <InputGroup id="Nome" placeholder="Nome" value={getFormularioNome}
                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioNome(event.currentTarget.value)}>
                </InputGroup>
                <InputGroup id="CPF" placeholder="CPF" value={getFormularioCPF}
                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioCPF(CPF.FormatarTexto(event.currentTarget.value))}>
                </InputGroup>
                <InputGroup id="Email" placeholder="Email" value={getFormularioEmail}
                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioEmail(event.currentTarget.value)}>
                </InputGroup>
                <InputGroup id="Senha" placeholder="Senha" value={getFormularioSenha} type="password"
                    onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioSenha(event.currentTarget.value)}>
                </InputGroup>
                <div>
                    {getCadastrandoUsuario == false && <Button type="submit" id="Submit" intent="primary" onClick={EfetuarCadastro}>Cadastrar</Button>}
                    {getCadastrandoUsuario == true && <div style={{ width: 100, height: 100 }}>
                        <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                    </div>}
                    <a href="/"><FiArrowLeft />Voltar para tela de Login</a>
                </div>
            </Form>
        </Container>
    );
    /* #endregion */
};

export default View;