import 'react-notifications-component/dist/theme.css'

enum TipoDeNotificacao {
    Sucesso = "success",
    Alerta = "warning",
    Erro = "danger",
    Informacao = "info"
};

class NotificacaoGlobal {
    /* #region  Variáveis  */
    TipoDeNotificacao = TipoDeNotificacao;
    /* #endregion */

    /* #region  Funções  */
    /* #endregion */
};

export default new NotificacaoGlobal();