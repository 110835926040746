import React, { useState, useEffect } from 'react';
import json from '../../Auditoria/index.json';
import api from '../../Services/api';
import { Objeto } from '../../../../../Services/Models';
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { PdfHeaderQueryCellInfoEventArgs, PdfQueryCellInfoEventArgs } from '@syncfusion/ej2-react-grids'
import moment from 'moment';
import {
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  GridComponent,
  Page,
  Inject,
  Filter,
  Group,
  ExcelExport,
  PdfExport,
  Toolbar,
  ToolbarItems,
  Grid,
  FilterSettingsModel,
  Sort
} from '@syncfusion/ej2-react-grids';
import './style.css'

L10n.load(json)

function App({ depositos }: any) {
  const [getProjectData, setProjectData] = useState();
  const [getData, setData] = useState<Array<Objeto.DepositosData>>([]);
  const [getLoading, setLoading] = useState<boolean>(true)
  let preData: Array<Objeto.DepositosData> = [];
  const filterOptions: FilterSettingsModel = {
    ignoreAccent: true,
    immediateModeDelay: 200,
    mode: "Immediate",
    type: 'Excel'
  }
  let grid: Grid | null;
  const toolbarOptions: ToolbarItems[] = ['PdfExport', 'CsvExport']
  const pdfQueryCellInfo = (args: PdfQueryCellInfoEventArgs | any) => {
    args.cell.cellHeight = 10;
  }
  const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs | any) => {
    (args.cell as any).row.pdfGrid.repeatHeader = true;
    args.cell.gridRow.pdfGrid.columns.getColumn(0).width = 200;
    args.cell.gridRow.pdfGrid.columns.getColumn(2).textAlign = "Right";
  }
  const toolbarClick = (args: any) => {
    if (grid) {
      if (args.item.id === 'grid_pdfexport') {
        grid.pdfExport({
          fileName: `Vendas De Cessão ${moment(new Date(), true).format("YYYY-MM-DD")}.pdf`,
          pageOrientation: "Landscape",
          header: {
            fromTop: 0,
            height: 130,
            contents: [
              // {
              //   type: 'Text',
              //   value: `${getItemPickerCentroDeCusto?.descricao}`,
              //   position: { x: 390, y: 50 },
              //   style: { textBrushColor: "#000000", fontSize: 20, hAlign: "Right" },
              // },
              // {
              //   position: { x: 0, y: 45 },
              //   size: { height: 40, width: 80 },
              //   src: image,
              //   type: 'Image',
              // },
              {
                type: 'Text',
                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:mm:ss")}`,
                position: { x: 880, y: 55 },
                style: { textBrushColor: "#000000", fontSize: 13 },
              },
            ]
          },
          footer: {
            contents: [
              {
                type: 'Text',
                value: `Powered by DigitalDEV`,
                position: { x: 0, y: 55 },
                style: { textBrushColor: "#000000", fontSize: 10 },
              },
              {
                format: 'Página {$current} de {$total}',
                pageNumberType: "Numeric",
                position: { x: 910, y: 50 },
                style: {
                  fontSize: 13,
                  hAlign: "Right",
                  textBrushColor: '#000000',
                },
                type: 'PageNumber',
              }
            ],
            fromBottom: 0,
            height: 100,
          },
          theme: {
            header: {
              bold: true,
              fontColor: "#000000",
              fontSize: 8,
              // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
            },
            record: {
              bold: false,
              fontColor: "#000000",
              fontSize: 8,
              // font: new PdfTrueTypeFont(adventProFont, 7)
            },
            caption: {
              bold: true,
              fontColor: "#000000",
              fontSize: 8,
              // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
            }
          },
          allowHorizontalOverflow: false,

        });
      }
      if(args.item.id === 'grid_excelexport') {
        grid.excelExport({
          dataSource: getData
        })
      }
      if(args.item.id === 'grid_csvexport') {
        grid.getColumnByField('ValorPrincipalPrice').format = "N2"
        grid.getColumnByField('ValorDep').format = "N2"
        grid.csvExport ({
          dataSource:getData
        })
      }
    }
  }

  useEffect(() => {
    async function componentDidMount() {
      if(depositos) {
        await depositos.map((objeto: Objeto.DepositosData, index: number) => {
          preData.push(
            {
              KEY_CDEMP_BD: objeto.KEY_CDEMP_BD,
              KEY_CDEMP_CDOBR_BD: objeto.KEY_CDEMP_CDOBR_BD,
              KEY_CDEMP_CDOBR_NUMVEN_BD: objeto.KEY_CDEMP_CDOBR_NUMVEN_BD,//
              KEY_CDEMP_CDOBR_PROD_PER_BD: objeto.KEY_CDEMP_CDOBR_PROD_PER_BD,
              NM_Empresa: objeto.NM_Empresa,
              NM_Obra: objeto.NM_Obra,
              NUMVEN: objeto.NUMVEN,//
              UNIDADE: objeto.UNIDADE,//
              M2: Number(objeto.M2),//
              CDCLI: objeto.CDCLI,
              NM_CLIENTE: objeto.NM_CLIENTE,
              'Data Vencimento': moment(objeto['Data Vencimento'], true).format('DD/MM/YYYY'),
              'Data Deposito': moment(objeto['Data Deposito'], true).format('DD/MM/YYYY'),//
              TIPOPARC: objeto.TIPOPARC,
              'Data Compensacao': moment(objeto['Data Compensacao'], true).format('DD/MM/YYYY'),//
              'Data Recebimento': moment(objeto['Data Recebimento'], true).format('DD/MM/YYYY'),
              BCO: objeto.BCO,
              CONTA: objeto.CONTA,
              CODCATEG: objeto.CODCATEG,
              ValorRecebido: objeto.ValorRecebido,//
              NumDeposito: Number(objeto.NumDeposito),
              ValorRec: Number(objeto.ValorRec),
              ValorPrincipalPrice: Number(objeto.ValorPrincipalPrice),
              ValorDep: Number(objeto.ValorDep)
            }
          )
        })
        await setData(preData)
        await setLoading(false)
      }
    }
    componentDidMount();
  }, [depositos])

  return (
    <>
      <div style={{ maxWidth: '100%' }}>
        <GridComponent
          dataSource={getData}
          id='grid'
          ref={g => grid = g}
          allowPaging={true}
          allowFiltering={true}
          pageSettings={{ pageSizes: [10, 20, 30], pageSize: 10 }}
          filterSettings={filterOptions}
          toolbarClick={toolbarClick}
          toolbar={toolbarOptions}
          allowPdfExport={true}
          allowExcelExport={true}
          pdfQueryCellInfo={pdfQueryCellInfo}
          pdfHeaderQueryCellInfo={pdfHeaderQueryCellInfo}
          allowSorting={true}
          style={{ opacity: getLoading === true ? "0.5" : "1", pointerEvents: getLoading === true ? "none" : "auto" }}>
          <ColumnsDirective>
            <ColumnDirective field='NM_Empresa' headerText='Empresa' width='100' />
            <ColumnDirective field='NM_Obra' headerText='Obra' width='100' />
            <ColumnDirective field='UNIDADE' headerText='Unidade' width='100' />
            <ColumnDirective field='M2' headerText='M²' format="N2" width='100' />
            <ColumnDirective field='NM_CLIENTE' headerText='Cliente' width='100' />
            <ColumnDirective field='Data Deposito' headerText='Data Depósito' width='100' />
            <ColumnDirective field='Data Compensacao' headerText='Data Compensação' width='100' />
            <ColumnDirective field='TIPOPARC' headerText='Tipo' width='100' />
            <ColumnDirective field='NumDeposito' headerText='Depósito' width='100' />
            <ColumnDirective field='ValorPrincipalPrice' headerText='ValorPrincipal' format="C2" width='100' />
            <ColumnDirective field='ValorDep' headerText='Depositado' format="C2" width='100' />
          </ColumnsDirective>
          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective field="ValorRec" type="Sum" format="C2" footerTemplate={
                  (props: any) => <span>{props.Sum}</span>
                } />
                <AggregateColumnDirective field="ValorPrincipalPrice" type="Sum" format="C2" footerTemplate={
                  (props: any) => <span>{props.Sum}</span>
                } />
                <AggregateColumnDirective field="M2" type="Sum" format="N2" footerTemplate={
                  (props: any) => <span>{props.Sum}</span>
                } />
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective>
          <Inject services={[Page, Filter, Group, ExcelExport, PdfExport, ExcelExport, Toolbar, Aggregate, Sort]} />
        </GridComponent>
      </div>
    </>
  );
}

export default App;