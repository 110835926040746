import React from 'react';
import { Icon } from '@blueprintjs/core';

function App({ label, value, icon }: any) {

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon icon={icon}color={"#1c294e"} />
          <span style={{ marginLeft: "15px", color: "#999999" }}>{label}: </span>
          <span style={{ fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{value}</span>
        </div>
    );
}

export default App;