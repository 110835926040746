import moment from "moment";
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, Card, Collapse, FileInput, FormGroup, Icon, Menu, MenuItem, Popover, Position, Switch } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs/datetime";
import { Select } from "@blueprintjs/select";
import { loadCldr, L10n, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { ModeloNF } from '../../../Data/Planilhas';
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Group, Inject, Grid, Filter, Sort } from "@syncfusion/ej2-react-grids";

import * as Component from './style';
import viewModel from './viewModel';
import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import { Localization } from '../../../Data/Listas';
import * as LottieFiles from '../../../Data/Lottie';
import History from "../../../Routers";
import { CentroDeCusto, D4sign, NFSe } from '../../../Services/Controllers';
import { Objeto } from '../../../Services/Models';
import { NotificacaoInterna } from '../../../Services/Notification';

import "moment/locale/pt-br"
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import './style.css';

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const PropriedadesDeCalendario = {
        months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    };
    const [getHeader, setHeader] = useState(true);
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const [getExibirPaginaDeImportarComissoes, setExibirPaginaDeImportarComissoes] = useStateWithCallbackLazy<boolean>(false);

    /* #region  variáveis da pagina de comissões importadas */
    const [getNivelDeVisualizacao, setNivelDeVisualizacao] = useState<{ id: string, descricao: string }>();
    const [getNiveisDeVisualizacoes, setNiveisDeVisualizacoes] = useState<Array<{ id: string, descricao: string }>>();
    const [getListaDeComissoes, setListaDeComissoes] = useState<{ pdf: string, tabelaOrignal: Array<any>, nfs: Array<Objeto.NFSe> }>();
    const [getCarregandoListaDeComissoes, setCarregandoListaDeComissoes] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    let grid: Grid | null;
    /* #endregion */

    /* #region  variáveis da pagina de importação de comissões */
    const [getFormularioAnexoPlanilha, setFormularioAnexoPlanilha] = useState<File>();
    const [getPlanilhaImportada, setPlanilhaImportada] = useState<{ pdf: string, tabelaOrignal: Array<any>, nfs: Array<Objeto.NFSe> }>();
    const [getPlanilhaSendoImportada, setPlanilhaSendoImportada] = useState<boolean | undefined>();
    const [getValidadeBorderoPrevio, setValidadeBorderoPrevio] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() + 1)));
    const [getExibirBorderoDefinitivo, setExibirBorderoDefinitivo] = useStateWithCallbackLazy<boolean>(false);
    const [getAutorizandoBordero, setAutorizandoBordero] = useState<boolean | undefined>();
    /* #endregion */
    /* #endregion */

    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
       **/
    async function SelecionarCentroDeCusto(Item: Objeto.CentroDeCusto | undefined, ExibirPaginaDeImportarComissoes: boolean, NivelDeVisualizacao: { id: string, descricao: string } | undefined) {
        await setItemPickerCentroDeCusto(Item);
        if (ExibirPaginaDeImportarComissoes == true) {
            await setFormularioAnexoPlanilha(undefined)
            await setPlanilhaImportada(undefined);
            await setPlanilhaSendoImportada(undefined);
        }
        else {
            await setCarregandoListaDeComissoes(false, async () => {
                await setNivelDeVisualizacao(!NivelDeVisualizacao ? (getNiveisDeVisualizacoes ? getNiveisDeVisualizacoes[0] : undefined) : NivelDeVisualizacao);
                var Response = await NFSe.ConsultarPorCentroDeCusto(ContextSignIn.getContext().token, Item?.empresa.id ?? 0, Item?.sigla ?? "", (!NivelDeVisualizacao ? (getNiveisDeVisualizacoes ? getNiveisDeVisualizacoes[0].id : "") : NivelDeVisualizacao.id));
                if (Math.floor(Response.status / 100) == 2) {
                    var TabelaOrignal = Array<any>();
                    Response.data.forEach((Item) => {
                        let Registro = {
                            id: Item.id,
                            idVinculado: Item.idVinculado,
                            PrestadorRazaoSocial: Item.prestador.razaoSocial,
                            PrestadorNomeFantasia: Item.prestador.nomeFantasia,
                            PrestadorCNPJ: Item.prestador.cnpj.length == 11 ? (`${Item.prestador.cnpj.substr(0, 3)}.${Item.prestador.cnpj.substr(3, 3)}.${Item.prestador.cnpj.substr(6, 3)}-${Item.prestador.cnpj.substr(9, 2)}`) :
                                (Item.prestador.cnpj.length == 14 ? (`${Item.prestador.cnpj.substr(0, 2)}.${Item.prestador.cnpj.substr(2, 3)}.${Item.prestador.cnpj.substr(5, 3)}\\${Item.prestador.cnpj.substr(8, 4)}-${Item.prestador.cnpj.substr(12, 2)}`) : Item.prestador.cnpj),
                            PrestadorIE: Item.prestador.ie,
                            PrestadorIM: Item.prestador.im,
                            PrestadorTelefone: Item.prestador.telefones ? (`+${Item.prestador.telefones[0].ddi} (${Item.prestador.telefones[0].ddd}) ${Item.prestador.telefones[0].numero}`) : "",
                            PrestadorEmail: Item.prestador.emails ? Item.prestador.emails[0].descricao : "",
                            PrestadorLogradouro: Item.prestador.endereco.logradouro ?? "",
                            PrestadorNumero: Item.prestador.endereco.numero ?? "",
                            PrestadorComplemento: Item.prestador.endereco.complemento ?? "",
                            PrestadorBairro: Item.prestador.endereco.bairro ?? "",
                            PrestadorCidade: Item.prestador.endereco.cidade ?? "",
                            PrestadorUF: Item.prestador.endereco.uf ?? "",
                            PrestadorCEP: `${Item.prestador.endereco.cep.substr(0, 2)}.${Item.prestador.endereco.cep.substr(2, 3)}-${Item.prestador.endereco.cep.substr(5, 3)}` ?? "",
                            TomadorRazaoSocial: Item.tomador.razaoSocial,
                            TomadorNomeFantasia: Item.tomador.nomeFantasia,
                            TomadorCNPJ: Item.tomador.cnpj.length == 11 ? (`${Item.tomador.cnpj.substr(0, 3)}.${Item.tomador.cnpj.substr(3, 3)}.${Item.tomador.cnpj.substr(6, 3)}-${Item.tomador.cnpj.substr(9, 2)}`) :
                                (Item.tomador.cnpj.length == 14 ? (`${Item.tomador.cnpj.substr(0, 2)}.${Item.tomador.cnpj.substr(2, 3)}.${Item.tomador.cnpj.substr(5, 3)}\\${Item.tomador.cnpj.substr(8, 4)}-${Item.tomador.cnpj.substr(12, 2)}`) : Item.tomador.cnpj),
                            TomadorIE: Item.tomador.ie,
                            TomadorIM: Item.tomador.im,
                            TomadorTelefone: Item.tomador.telefones ? (`+${Item.tomador.telefones[0].ddi} (${Item.tomador.telefones[0].ddd}) ${Item.tomador.telefones[0].numero}`) : "",
                            TomadorEmail: Item.tomador.emails ? Item.tomador.emails[0].descricao : "",
                            TomadorLogradouro: Item.tomador.endereco.logradouro ?? "",
                            TomadorNumero: Item.tomador.endereco.numero ?? "",
                            TomadorComplemento: Item.tomador.endereco.complemento ?? "",
                            TomadorBairro: Item.tomador.endereco.bairro ?? "",
                            TomadorCidade: Item.tomador.endereco.cidade ?? "",
                            TomadorUF: Item.tomador.endereco.uf ?? "",
                            TomadorCEP: `${Item.tomador.endereco.cep.substr(0, 2)}.${Item.tomador.endereco.cep.substr(2, 3)}-${Item.tomador.endereco.cep.substr(5, 3)}` ?? "",
                            DataDeEmissao: Item.dataDeEmissao,
                            Vencimento: Item.vencimento,
                            Competencia: Item.competencia,
                            Descricao: Item.descricao ?? "",
                            ValorDoServico: Item.valorDoServico ?? 0,
                            MeioDePagamento: Item.meioDePagamento.descricao ?? "",
                            EnviarNFePrestador: "Sim",
                            ServicoInternacional: Item.servicoInternacional ?? "",
                            CidadeDoServico: Item.cidadeDoServico ?? "",
                            UFServico: Item.ufServico ?? "",
                            COFINS: Item.retencoesFederais.cofins ?? 0,
                            CSLL: Item.retencoesFederais.csll ?? 0,
                            INSS: Item.retencoesFederais.inss ?? 0,
                            IR: Item.retencoesFederais.ir ?? 0,
                            ISS: Item.retencoesFederais.iss ?? 0,
                            PIS: Item.retencoesFederais.pis ?? 0,
                            EmProcessamento: false,
                            Item: {} as any,
                            ListaDeComissoes: {
                                pdf: "",
                                tabelaOrignal: TabelaOrignal,
                                nfs: Response.data
                            }
                        };
                        Registro.Item = Registro;
                        TabelaOrignal.push(Registro);
                    })
                    await setListaDeComissoes({
                        pdf: "",
                        tabelaOrignal: TabelaOrignal,
                        nfs: Response.data
                    });
                }
                else {
                    NotificacaoInterna.ExibirNotificacao("Falha ao buscar pelas comissões cadastradas", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao buscar pelas comissões cadastradas, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                }
                await setCarregandoListaDeComissoes(true, () => { });
            });
        }
    }
    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
       **/
    async function Buscar() {
        if (!getItemPickerCentroDeCusto) {
            NotificacaoInterna.ExibirNotificacao("Empreendimento não selecionada", "Informe o empreendimento", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getFormularioAnexoPlanilha) {
            NotificacaoInterna.ExibirNotificacao("Planilha não selecionada", "Informe a palnilha a ser importada", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return;
        }
        await setPlanilhaSendoImportada(true);
        let Response = await NFSe.InterpretarPlanilha(ContextSignIn.getContext().token, { Nome: "Planilha de comissão.xls", Conteudo: getFormularioAnexoPlanilha });
        if (Math.floor(Response.status / 100) == 2) {
            await setPlanilhaImportada(Response.data);
            await setPlanilhaSendoImportada(false);
        }
        else {
            NotificacaoInterna.ExibirNotificacao("Falha ao interpretar a planilha informada", "😱 Houve uma falha ao interpretar a planilha informada, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
            await setPlanilhaSendoImportada(undefined);
        }
    }
    async function AutorizarBordero(): Promise<void> {
        if (getPlanilhaImportada?.nfs != undefined) {
            await setAutorizandoBordero(true);
            let Response = await NFSe.Post(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", getExibirBorderoDefinitivo, getExibirBorderoDefinitivo == false ? getValidadeBorderoPrevio : undefined, getPlanilhaImportada?.nfs);
            if (Math.floor(Response.status / 100) == 2) {

                if (getExibirBorderoDefinitivo == false) {
                    NotificacaoInterna.ExibirNotificacao("Borderô prévio autorizado", "Cada registro informado na planilha foi disponibiliza para o respectivo tomador! Cabe ao mesmo entrar na plataforma e conferir as conmissões apuradas e levantar os devidos questionamentos, caso houver.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                    await setAutorizandoBordero(false);
                }
                else {
                    NotificacaoInterna.ExibirNotificacao("Borderô definitivo autorizado", "Cada registro informado na planilha foi disponibiliza para o respectivo tomador! Cabe ao mesmo entrar na plataforma e autorizar a emissão da nota!", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                    await setAutorizandoBordero(false);
                }
            }
            else {
                console.log(JSON.stringify(Response))
                NotificacaoInterna.ExibirNotificacao("Falha ao autorizar Borderô", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                await setAutorizandoBordero(undefined);
            }
        }

    };
    useEffect(() => {
        async function componentDidMount() {
            await setNiveisDeVisualizacoes((await (NFSe.NiveisDeVisualizacoes(ContextSignIn.getContext().token))).data);
            await setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? []);
        }
        componentDidMount();
    }, []);
    loadCldr(
        require('../../../../node_modules/cldr-data/main/pt/currencies.json'),
        require('../../../../node_modules/cldr-data/main/pt/numbers.json'),
        require('../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
        require('../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
        require('../../../../node_modules/cldr-data/supplemental/numberingSystems.json')
    );
    setCulture('pt');
    setCurrencyCode("BRL");
    L10n.load(Localization);

    return (
        <Component.Container>
            <Collapse isOpen={getHeader}>
                <div style={{ overflow: "auto", justifyContent: "space-between", background: "0" }}>
                    <div className="endereco" style={{ marginLeft: "1.5%", width: getExibirPaginaDeImportarComissoes == true ? "1400px" : "900px" }}>
                        <img src={IconeHome} style={{ cursor: "pointer" }} alt="" onClick={() => { History.goBack(); }} />
                        <div className="bar">/</div>
                        <div className="place">Gestão de comissão</div>
                        <div className="bar">/</div>
                        <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => { await SelecionarCentroDeCusto(Item, getExibirPaginaDeImportarComissoes, getNivelDeVisualizacao); }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerCentroDeCusto?.descricao.substring(0, 10).concat("...") ?? "Empreendimento"} rightIcon="double-caret-vertical" />
                        </PickerCentroDeCusto>
                        <div className="bar">/</div>
                        <div style={{ fontWeight: (getExibirPaginaDeImportarComissoes == false ? "bolder" : "lighter") }} className="place">NFSe importadas</div>
                        <Switch style={{ marginLeft: "15px", marginTop: "10px" }} checked={getExibirPaginaDeImportarComissoes} onChange={async () => {
                            await setExibirPaginaDeImportarComissoes(!getExibirPaginaDeImportarComissoes, async () => {
                                await setAutorizandoBordero(undefined);
                                if (getItemPickerCentroDeCusto) {
                                    await SelecionarCentroDeCusto(getItemPickerCentroDeCusto, !getExibirPaginaDeImportarComissoes, getNivelDeVisualizacao);
                                }
                            });
                        }} />
                        <div style={{ fontWeight: (getExibirPaginaDeImportarComissoes == true ? "bolder" : "lighter") }} className="place">Importar NFSe</div>
                        {getExibirPaginaDeImportarComissoes == true && <div>
                            <FormGroup className="place" labelFor="DataDeInicio" inline={true} style={{ marginTop: "15px", marginLeft: "10px" }}>
                                <FileInput style={{ width: "400px" }} inputProps={{ accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }} buttonText="Abrir" text={getFormularioAnexoPlanilha == undefined ? "Anexe a planilha de NFSe a ser importada" : getFormularioAnexoPlanilha.name} onInputChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                    if (event.currentTarget.files?.item(0)?.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") == true) {
                                        await setFormularioAnexoPlanilha(event.currentTarget.files?.item(0) || { name: "" } as File);
                                    }
                                    else {
                                        NotificacaoInterna.ExibirNotificacao("Formato de arquivo invalido", `Somente serão aceitos com extensão PDF`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                    }
                                }} onClick={(e: any) => (e.target.value = null)} />
                                {getFormularioAnexoPlanilha && <Button type="submit" intent="none" style={{ marginLeft: "10px", marginTop: "5px", width: "80px" }} onClick={async () => {
                                    await setFormularioAnexoPlanilha(undefined);
                                    await setPlanilhaImportada(undefined);
                                    await setPlanilhaSendoImportada(undefined);
                                    await setAutorizandoBordero(undefined);
                                }}>Limpar</Button>}
                            </FormGroup>
                        </div>}
                        {getExibirPaginaDeImportarComissoes == true && <div className="hoverize" style={{ alignItems: "center", borderRadius: "5px", boxShadow: "0px 0px 0px 1px #d8d8d8", color: "#175372", display: "flex", cursor: "pointer", height: "30px", justifyContent: "space-around", marginLeft: "15px", padding: "0 5px", width: "110px" }}
                            onClick={Buscar}>
                            <Icon color="#175372" icon="import" />
                            <span>Importar</span>
                        </div>}
                    </div >
                </div>
            </Collapse >
            <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getHeader === true ? setHeader(false) : setHeader(true)}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getHeader ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>
            {getExibirPaginaDeImportarComissoes == false && <div>
                {getCarregandoListaDeComissoes != true && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: LottieFiles.Load02,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={400}
                        width={400}
                        style={{ marginTop: "122px" }}
                    />
                    <div className="loading" style={{ margin: "-100px auto 0 auto", marginTop: "50px", fontWeight: "bold" }}>{(getCarregandoListaDeComissoes == undefined) ? "Por favor, selecione um empreendimento para exibir comissões geradas" : ((getCarregandoListaDeComissoes == false) ? "Aguarde, estamos carregando os dados" : "Pronto, as comissões geradas logo serão mostradas!")}</div>
                </div>}
                {getCarregandoListaDeComissoes == true && <Card style={{ backgroundColor: "0", width: "100%", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none" }}>
                    <div style={{ maxWidth: "100%" }}>
                        <div style={{ background: "0", display: "flex", height: "70px", marginRight: "20px", justifyContent: "flex-end" }}>
                            {getNiveisDeVisualizacoes?.map((Item) => <div onClick={async () => { await setNivelDeVisualizacao(Item); await SelecionarCentroDeCusto(getItemPickerCentroDeCusto, getExibirPaginaDeImportarComissoes, Item); }} className="dispIn" style={{ cursor: "pointer", marginLeft: "20px", height: "70px", background: "0", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: getNivelDeVisualizacao?.id == Item.id ? "2px solid black" : "" }}>
                                <span>{Item.descricao}</span>
                            </div>)}
                        </div>
                        <GridComponent allowFiltering={true} allowPaging={true} filterSettings={{ type: "Excel" }} dataSource={getListaDeComissoes ? getListaDeComissoes.tabelaOrignal : []} pageSettings={{ pageSize: 6 }} sortSettings={{
                            columns: [
                                { field: "Cliente", direction: "Ascending" }
                            ]
                        }} style={{ marginTop: "25px", borderColor: "transparent" }}>
                            <ColumnsDirective>
                                <ColumnDirective headerText='' width="100px" textAlign='Center' template={(props: any) => (
                                    <Popover disabled={props.idVinculado == - 1} content={
                                        <Menu>
                                            <MenuItem icon="remove" text="Cancelar NFSe" onClick={async () => {
                                                let ListaDeComissoes = { ...props.ListaDeComissoes };
                                                props.Item.idVinculado = -1;
                                                props.Item.EmProcessamento = true;
                                                await setListaDeComissoes(ListaDeComissoes);

                                                let Response = await NFSe.Delete(ContextSignIn.getContext().token, getListaDeComissoes?.nfs.find(Item => Item.id == props.id)?.id ?? -1);
                                                if (Math.floor(Response.status / 100) == 2) {
                                                    let ListaDeComissoes = { ...props.ListaDeComissoes };
                                                    props.Item.idVinculado = -1;
                                                    props.Item.EmProcessamento = false;
                                                    await setListaDeComissoes(ListaDeComissoes);
                                                    NotificacaoInterna.ExibirNotificacao("NFSe cancelada", "Sua nota já foi cancelada!", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                }
                                                else {
                                                    let ListaDeComissoes = { ...props.ListaDeComissoes };
                                                    props.Item.idVinculado = null;
                                                    props.Item.EmProcessamento = false;
                                                    await setListaDeComissoes(ListaDeComissoes);
                                                    NotificacaoInterna.ExibirNotificacao("Falha ao cancelar NFSe", "😱 Houve uma falha ao cancelar a NFSe, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                }
                                            }} />
                                        </Menu>
                                    } position={Position.BOTTOM_RIGHT}>
                                        <Button disabled={props.idVinculado == - 1} intent={(props.idVinculado == - 1) ? "none" : "danger"} style={{ height: "5px", width: "50px", borderRadius: '5px', cursor: "pointer", outline: "none", display: "flex", padding: "0" }}>
                                            {props.EmProcessamento == false && <div style={{ fontSize: "0.8vw", color: (props.idVinculado == - 1) ? "#000000" : "#FFFFFF" }}>{(props.Item.idVinculado == -1) ? "Canc." : "..."}</div>}
                                            {props.EmProcessamento == true && <Lottie height={30} width={100} options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />}
                                        </Button>
                                    </Popover>
                                )} />
                                <ColumnDirective field="id" headerText="Cód. da nota" width="150px" textAlign="Center" />
                                {getNivelDeVisualizacao && getNivelDeVisualizacao.id != "NotasNaoEmitidas" && <ColumnDirective field="idVinculado" headerText="Cód. da nota (externo)" width="300px" textAlign="Center" />}
                                <ColumnDirective field="PrestadorRazaoSocial" headerText="Prestador: razão social" width="300px" textAlign="Left" />
                                <ColumnDirective field="PrestadorNomeFantasia" headerText="Prestador: nome fantasia" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorRazaoSocial" headerText="Tomador: razão social" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorNomeFantasia" headerText="Tomador: nome fantasia" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorCNPJ" headerText="Tomador: CNPJ" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorIE" headerText="Tomador: inscrição municipal" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorIM" headerText="Tomador: inscrição estadual" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorTelefone" headerText="Tomador: telefone" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorEmail" headerText="Tomador: email" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorLogradouro" headerText="Tomador: endereço" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorNumero" headerText="Tomador: nº end." width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorComplemento" headerText="Tomador: compl. end." width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorBairro" headerText="Tomador: bairro" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorCidade" headerText="Tomador: cidade" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorUF" headerText="Tomador: UF end." width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorCEP" headerText="Tomador: CEP end." width="300px" textAlign="Left" />
                                <ColumnDirective field="DataDeEmissao" headerText="Dt. emissão" type="date" format="dd/MM/yyyy" width="300px" textAlign="Left" />
                                <ColumnDirective field="Vencimento" headerText="Dt. vencimento" type="date" format="dd/MM/yyyy" width="300px" textAlign="Left" />
                                <ColumnDirective field="Competencia" headerText="Dt. competência" type="date" format="dd/MM/yyyy" width="300px" textAlign="Left" />
                                <ColumnDirective field="Descricao" headerText="Descricão" width="300px" textAlign="Left" />
                                <ColumnDirective field="ValorDoServico" headerText="Valor do serviço" format="C2" width="300px" textAlign="Left" />
                                <ColumnDirective field="MeioDePagamento" headerText="Meio de pagamento" width="300px" textAlign="Left" />
                                <ColumnDirective field="MeioDePagamento" headerText="enviarNFePrestador" width="300px" textAlign="Left" />
                                <ColumnDirective field="ServicoInternacional" headerText="Serv. internacional?" width="300px" textAlign="Left" />
                                <ColumnDirective field="CidadeDoServico" headerText="Cidade do serviço" width="300px" textAlign="Left" />
                                <ColumnDirective field="UFServico" headerText="UF do serviço" width="300px" textAlign="Left" />
                                <ColumnDirective field="COFINS" headerText="COFINS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="COFINS" headerText="COFINS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="CSLL" headerText="CSLL" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="INSS" headerText="INSS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="IR" headerText="IR" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="ISS" headerText="ISS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="PIS" headerText="PIS" type="number" format="p" width="300px" textAlign="Left" />
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Filter, Group]} />
                        </GridComponent>
                    </div>
                </Card>}
            </div>}
            {getExibirPaginaDeImportarComissoes == true && <div>
                {getPlanilhaImportada == undefined && <div style={{ width: "100%", display: "flex", flexDirection: "column", marginTop: "70px" }}>
                    <div style={{ margin: "auto" }} onClick={() => {
                        let downloadLink = document.createElement("a");
                        downloadLink.href = "data:application/octet-stream;charset=utf-16le;base64," + encodeURI(ModeloNF.ArquivoBase64);
                        downloadLink.download = "Modelo NF.xlsx";
                        downloadLink.click();
                    }}>
                        <Lottie
                            isClickToPauseDisabled={true}
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: LottieFiles.LoadTable,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            height={450}
                            width={500}
                        />
                    </div>
                    <div className="loading" style={{ margin: "-100px auto 0 auto", marginTop: "40px", fontWeight: "bold" }}>{(getItemPickerCentroDeCusto == undefined) ? "Por favor, selecione um empreendimento" : (getFormularioAnexoPlanilha == undefined ? "Por favor, selecione a planilha a ser importada" : (getPlanilhaSendoImportada == undefined ? "Clique no icone de busca para importar a planilha" : ((getPlanilhaSendoImportada == true) ? "Aguarde, estamos importando a planilha" : "Pronto, sua planilha foi importada com sucesso!")))}</div>
                    <div className="loading" style={{ margin: "-100px auto 0 auto", marginTop: "0px", fontSize: "10px", fontWeight: "bold" }}>Clique sobre a imagem para fazer o download da nossa planilha modelo!</div>
                </div>}
                {getPlanilhaImportada != undefined && <Card style={{ width: "100%", backgroundColor: "#ffffff", margin: "0 auto", borderRadius: "5px", boxShadow: "none", padding: "40px 3% 40px 3% " }} >
                    <Component.ContentCard>
                        <Component.Title>
                            <Icon icon={"join-table"} style={{ cursor: "pointer", marginLeft: "30px" }} iconSize={20} />
                            <h3>Planilha importada</h3>
                        </Component.Title>
                        <GridComponent allowFiltering={true} allowPaging={true} filterSettings={{ type: "Excel" }} dataSource={getPlanilhaImportada ? getPlanilhaImportada.tabelaOrignal : []} pageSettings={{ pageSize: 6 }} sortSettings={{
                            columns: [
                                { field: "Cliente", direction: "Ascending" }
                            ]
                        }} style={{ marginTop: "25px", borderColor: "transparent" }}>
                            <ColumnsDirective>
                                <ColumnDirective field="TomadorRazaoSocial" headerText="Tomador: razão social" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorNomeFantasia" headerText="Tomador: nome fantasia" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorCNPJ" headerText="Tomador: CNPJ" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorIE" headerText="Tomador: inscrição municipal" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorIM" headerText="Tomador: inscrição estadual" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorTelefone" headerText="Tomador: telefone" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorEmail" headerText="Tomador: email" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorLogradouro" headerText="Tomador: endereço" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorNumero" headerText="Tomador: nº end." width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorComplemento" headerText="Tomador: compl. end." width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorBairro" headerText="Tomador: bairro" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorCidade" headerText="Tomador: cidade" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorUF" headerText="Tomador: UF end." width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorCEP" headerText="Tomador: CEP end." width="300px" textAlign="Left" />
                                <ColumnDirective field="DataDeEmissao" headerText="Dt. emissão" type="date" format="dd/MM/yyyy" width="300px" textAlign="Left" />
                                <ColumnDirective field="Vencimento" headerText="Dt. vencimento" type="date" format="dd/MM/yyyy" width="300px" textAlign="Left" />
                                <ColumnDirective field="Competencia" headerText="Dt. competência" type="date" format="dd/MM/yyyy" width="300px" textAlign="Left" />
                                <ColumnDirective field="Descricao" headerText="Descricão" width="300px" textAlign="Left" />
                                <ColumnDirective field="ValorDoServico" headerText="Valor do serviço" format="C2" width="300px" textAlign="Left" />
                                <ColumnDirective field="MeioDePagamento" headerText="Meio de pagamento" width="300px" textAlign="Left" />
                                <ColumnDirective field="MeioDePagamento" headerText="enviarNFePrestador" width="300px" textAlign="Left" />
                                <ColumnDirective field="ServicoInternacional" headerText="Serv. internacional?" width="300px" textAlign="Left" />
                                <ColumnDirective field="CidadeDoServico" headerText="Cidade do serviço" width="300px" textAlign="Left" />
                                <ColumnDirective field="UFServico" headerText="UF do serviço" width="300px" textAlign="Left" />
                                <ColumnDirective field="COFINS" headerText="COFINS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="COFINS" headerText="COFINS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="CSLL" headerText="CSLL" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="INSS" headerText="INSS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="IR" headerText="IR" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="ISS" headerText="ISS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="PIS" headerText="PIS" type="number" format="p" width="300px" textAlign="Left" />
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Filter, Group]} />
                        </GridComponent>
                        <div style={{ display: "flex", marginLeft: "30px", marginTop: "35px", marginBottom: "35px" }}>
                            <div style={{ fontWeight: (getExibirBorderoDefinitivo == false ? "bolder" : "lighter"), marginTop: "5px" }} className="place">Borderô prévio{getExibirBorderoDefinitivo == false ? ", valido até " : ""}</div>
                            {getExibirBorderoDefinitivo == false && <div style={{ width: "100px", marginLeft: "15px" }}>
                                <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                    parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                    placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setValidadeBorderoPrevio(DataSelecionada)}
                                    value={getValidadeBorderoPrevio} minDate={new Date()} maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}>
                                </DateInput>
                            </div>}
                            <Switch style={{ marginLeft: "15px", marginTop: "10px" }} disabled={getAutorizandoBordero == false} checked={getExibirBorderoDefinitivo} onChange={async () => {
                                await setExibirBorderoDefinitivo(!getExibirBorderoDefinitivo, async () => {

                                });
                            }} />
                            <div style={{ fontWeight: (getExibirBorderoDefinitivo == true ? "bolder" : "lighter"), marginTop: "5px" }} className="place">Borderô definitivo</div>
                            <Button type="submit" disabled={getAutorizandoBordero == false} intent={getAutorizandoBordero == false ? "success" : "primary"} style={{ height: "30px", marginLeft: "30px", marginRight: "15px", width: "100px" }} onClick={AutorizarBordero}>{getAutorizandoBordero == false ? "Autorizado" : "Autorizar"}</Button>
                            {getAutorizandoBordero == true && <div style={{ width: 100, height: 100 }}>
                                <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                            </div>}
                        </div>
                    </Component.ContentCard>
                </Card>}
            </div>}
        </Component.Container >
    );
    /* #endregion */
};

export default View;