import React from 'react';
import { WidthCheck as ContextWidthCheck } from '../../../../../../Context';
import { VisualizacaoComponent } from '../../index';

function App({ getTileLayer, setTileLayer, Image }: any) {

    return (
        <div style={{ width: "100%" }}>
            <div onClick={() => {/*setConsumer(true)*/ }} style={{ width: "100%", height: "170px", backgroundColor: "unset", padding: ContextWidthCheck.getContext().width <= 660 ? "30px 0px" : "0px 80px", display: "flex", flexDirection: ContextWidthCheck.getContext().width <= 660 ? "column" : "row", alignItems: "center", flexWrap: "wrap", justifyContent: "space-between", cursor: "pointer" }}>
                <VisualizacaoComponent getTileLayer={getTileLayer} TileLayerShouldBe={0} setTileLayer={setTileLayer} Image={Image.FundoOpenStreet} name={"GPS"} />
                <VisualizacaoComponent getTileLayer={getTileLayer} TileLayerShouldBe={1} setTileLayer={setTileLayer} Image={Image.FundoSatelite} name={"Satélite"} />
            </div>
        </div>
    );
}

export default App;