import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, Card, Collapse, FileInput, FormGroup, InputGroup, Icon, Menu, MenuItem, Popover, Position, Switch } from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";
import { loadCldr, L10n, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Group, Inject, GroupSettingsModel, Aggregate, Toolbar, ToolbarItems, PdfExport, Grid, Filter, FilterSettingsModel, Reorder, Column, Sort, SortSettingsModel, PdfHeaderQueryCellInfoEventArgs } from "@syncfusion/ej2-react-grids";
import "moment/locale/pt-br"
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import { Localization } from '../../../Data/Listas';
import * as LottieFiles from '../../../Data/Lottie';
import History from "../../../Routers";
import { CentroDeCusto, D4sign, NFSe } from '../../../Services/Controllers';
import { Objeto } from '../../../Services/Models';
import { NotificacaoInterna } from '../../../Services/Notification';

import * as Component from './style';
import './style.css';
import viewModel from './viewModel';
import { Moeda } from '../../../Themes/TextFormat';

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const [getHeader, setHeader] = useState(true);
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();

    /* #region  variáveis da pagina de comissões importadas */
    const [getNivelDeVisualizacao, setNivelDeVisualizacao] = useState<{ id: string, descricao: string }>();
    const [getNiveisDeVisualizacoes, setNiveisDeVisualizacoes] = useState<Array<{ id: string, descricao: string }>>();
    const [getListaDeComissoes, setListaDeComissoes] = useState<{ pdf: string, tabelaOrignal: Array<any>, nfs: Array<Objeto.NFSe> }>();
    const [getCarregandoListaDeComissoes, setCarregandoListaDeComissoes] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getAutorizandoFaturamentoGeral, setAutorizandoFaturamentoGeral] = useState<boolean>(false);
    let grid: Grid | null;
    /* #endregion */
    /* #endregion */

    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
       **/
    async function SelecionarCentroDeCusto(Item: Objeto.CentroDeCusto | undefined, NivelDeVisualizacao: { id: string, descricao: string } | undefined) {
        await setItemPickerCentroDeCusto(Item);
        await setCarregandoListaDeComissoes(false, async () => {
            await setNivelDeVisualizacao(!NivelDeVisualizacao ? (getNiveisDeVisualizacoes ? getNiveisDeVisualizacoes[0] : undefined) : NivelDeVisualizacao);
            var Response = await NFSe.ConsultarPorCentroDeCusto(ContextSignIn.getContext().token, Item?.empresa.id ?? 0, Item?.sigla ?? "", (!NivelDeVisualizacao ? (getNiveisDeVisualizacoes ? getNiveisDeVisualizacoes[0].id : "") : NivelDeVisualizacao.id));
            if (Math.floor(Response.status / 100) == 2) {
                var TabelaOrignal = Array<any>();
                Response.data.forEach((Item) => {
                    let Registro = {
                        id: Item.id,
                        idVinculado: Item.idVinculado,
                        PrestadorRazaoSocial: Item.prestador.razaoSocial,
                        PrestadorNomeFantasia: Item.prestador.nomeFantasia,
                        PrestadorCNPJ: Item.prestador.cnpj.length == 11 ? (`${Item.prestador.cnpj.substr(0, 3)}.${Item.prestador.cnpj.substr(3, 3)}.${Item.prestador.cnpj.substr(6, 3)}-${Item.prestador.cnpj.substr(9, 2)}`) :
                            (Item.prestador.cnpj.length == 14 ? (`${Item.prestador.cnpj.substr(0, 2)}.${Item.prestador.cnpj.substr(2, 3)}.${Item.prestador.cnpj.substr(5, 3)}\\${Item.prestador.cnpj.substr(8, 4)}-${Item.prestador.cnpj.substr(12, 2)}`) : Item.prestador.cnpj),
                        PrestadorIE: Item.prestador.ie,
                        PrestadorIM: Item.prestador.im,
                        PrestadorTelefone: Item.prestador.telefones ? (`+${Item.prestador.telefones[0].ddi} (${Item.prestador.telefones[0].ddd}) ${Item.prestador.telefones[0].numero}`) : "",
                        PrestadorEmail: Item.prestador.emails ? Item.prestador.emails[0].descricao : "",
                        PrestadorLogradouro: Item.prestador.endereco.logradouro ?? "",
                        PrestadorNumero: Item.prestador.endereco.numero ?? "",
                        PrestadorComplemento: Item.prestador.endereco.complemento ?? "",
                        PrestadorBairro: Item.prestador.endereco.bairro ?? "",
                        PrestadorCidade: Item.prestador.endereco.cidade ?? "",
                        PrestadorUF: Item.prestador.endereco.uf ?? "",
                        PrestadorCEP: `${Item.prestador.endereco.cep.substr(0, 2)}.${Item.prestador.endereco.cep.substr(2, 3)}-${Item.prestador.endereco.cep.substr(5, 3)}` ?? "",
                        TomadorRazaoSocial: Item.tomador.razaoSocial,
                        TomadorNomeFantasia: Item.tomador.nomeFantasia,
                        TomadorCNPJ: Item.tomador.cnpj.length == 11 ? (`${Item.tomador.cnpj.substr(0, 3)}.${Item.tomador.cnpj.substr(3, 3)}.${Item.tomador.cnpj.substr(6, 3)}-${Item.tomador.cnpj.substr(9, 2)}`) :
                            (Item.tomador.cnpj.length == 14 ? (`${Item.tomador.cnpj.substr(0, 2)}.${Item.tomador.cnpj.substr(2, 3)}.${Item.tomador.cnpj.substr(5, 3)}\\${Item.tomador.cnpj.substr(8, 4)}-${Item.tomador.cnpj.substr(12, 2)}`) : Item.tomador.cnpj),
                        TomadorIE: Item.tomador.ie,
                        TomadorIM: Item.tomador.im,
                        TomadorTelefone: Item.tomador.telefones ? (`+${Item.tomador.telefones[0].ddi} (${Item.tomador.telefones[0].ddd}) ${Item.tomador.telefones[0].numero}`) : "",
                        TomadorEmail: Item.tomador.emails ? Item.tomador.emails[0].descricao : "",
                        TomadorLogradouro: Item.tomador.endereco.logradouro ?? "",
                        TomadorNumero: Item.tomador.endereco.numero ?? "",
                        TomadorComplemento: Item.tomador.endereco.complemento ?? "",
                        TomadorBairro: Item.tomador.endereco.bairro ?? "",
                        TomadorCidade: Item.tomador.endereco.cidade ?? "",
                        TomadorUF: Item.tomador.endereco.uf ?? "",
                        TomadorCEP: `${Item.tomador.endereco.cep.substr(0, 2)}.${Item.tomador.endereco.cep.substr(2, 3)}-${Item.tomador.endereco.cep.substr(5, 3)}` ?? "",
                        DataDeEmissao: Item.dataDeEmissao,
                        Vencimento: Item.vencimento,
                        Competencia: Item.competencia,
                        Descricao: Item.descricao ?? "",
                        ValorDoServico: Item.valorDoServico ?? 0,
                        MeioDePagamento: Item.meioDePagamento.descricao ?? "",
                        EnviarNFePrestador: "Sim",
                        ServicoInternacional: Item.servicoInternacional ?? "",
                        CidadeDoServico: Item.cidadeDoServico ?? "",
                        UFServico: Item.ufServico ?? "",
                        COFINS: Item.retencoesFederais.cofins ?? 0,
                        CSLL: Item.retencoesFederais.csll ?? 0,
                        INSS: Item.retencoesFederais.inss ?? 0,
                        IR: Item.retencoesFederais.ir ?? 0,
                        ISS: Item.retencoesFederais.iss ?? 0,
                        PIS: Item.retencoesFederais.pis ?? 0,
                        EmProcessamento: false,
                        Item: {} as any,
                        ListaDeComissoes: {
                            pdf: "",
                            tabelaOrignal: TabelaOrignal,
                            nfs: Response.data
                        }
                    };
                    Registro.Item = Registro;
                    TabelaOrignal.push(Registro);
                });
                await setListaDeComissoes({
                    pdf: "",
                    tabelaOrignal: TabelaOrignal,
                    nfs: Response.data
                });
            }
            else {
                NotificacaoInterna.ExibirNotificacao("Falha ao buscar pelas comissões cadastradas", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao buscar pelas comissões cadastradas, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
            }
            await setCarregandoListaDeComissoes(true, () => { });
        });
    }
    useEffect(() => {
        async function componentDidMount() {
            await setNiveisDeVisualizacoes((await (NFSe.NiveisDeVisualizacoes(ContextSignIn.getContext().token))).data);
            await setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? []);
        }
        componentDidMount();
    }, []);
    loadCldr(
        require('../../../../node_modules/cldr-data/main/pt/currencies.json'),
        require('../../../../node_modules/cldr-data/main/pt/numbers.json'),
        require('../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
        require('../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
        require('../../../../node_modules/cldr-data/supplemental/numberingSystems.json')
    );
    setCulture('pt');
    setCurrencyCode("BRL");
    L10n.load(Localization);

    return (
        <Component.Container>
            <Collapse isOpen={getHeader}>
                <div style={{ overflow: "auto", justifyContent: "space-between", background: "0" }}>
                    <div className="endereco" style={{ marginLeft: "1.5%" }}>
                        <img src={IconeHome} style={{ cursor: "pointer" }} alt="" onClick={() => { History.goBack(); }} />
                        <div className="bar">/</div>
                        <div className="place">Controle de comissão</div>
                        <div className="bar">/</div>
                        <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => { await SelecionarCentroDeCusto(Item, getNivelDeVisualizacao); }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerCentroDeCusto?.descricao.substring(0, 10).concat("...") ?? "Empreendimento"} rightIcon="double-caret-vertical" />
                        </PickerCentroDeCusto>
                    </div >
                </div>
            </Collapse >
            <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getHeader === true ? setHeader(false) : setHeader(true)}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getHeader ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>
            <div>
                {getCarregandoListaDeComissoes != true && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: LottieFiles.Load02,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={400}
                        width={400}
                        style={{ marginTop: "122px" }}
                    />
                    <div className="loading" style={{ margin: "-100px auto 0 auto", marginTop: "50px", fontWeight: "bold" }}>{(getCarregandoListaDeComissoes == undefined) ? "Por favor, selecione um empreendimento para exibir comissões geradas" : ((getCarregandoListaDeComissoes == false) ? "Aguarde, estamos carregando os dados" : "Pronto, as comissões geradas logo serão mostradas!")}</div>
                </div>}
                {getCarregandoListaDeComissoes == true && <Card style={{ backgroundColor: "0", width: "100%", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none" }}>
                    <div style={{ maxWidth: "100%" }}>
                        <div style={{ background: "0", display: "flex", height: "70px", marginRight: "20px", justifyContent: "flex-end" }}>
                            {getNiveisDeVisualizacoes?.map((Item) => <div onClick={async () => { await setNivelDeVisualizacao(Item); await SelecionarCentroDeCusto(getItemPickerCentroDeCusto, Item); }} className="dispIn" style={{ cursor: "pointer", marginLeft: "20px", height: "70px", background: "0", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: getNivelDeVisualizacao?.id == Item.id ? "2px solid black" : "" }}>
                                <span>{Item.descricao}</span>
                            </div>)}
                        </div>
                        <GridComponent allowFiltering={true} allowPaging={true} filterSettings={{ type: "Excel" }} dataSource={getListaDeComissoes ? getListaDeComissoes.tabelaOrignal : []} pageSettings={{ pageSize: 6 }} sortSettings={{
                            columns: [
                                { field: "Cliente", direction: "Ascending" }
                            ]
                        }} style={{ marginTop: "25px", borderColor: "transparent" }}>
                            <ColumnsDirective>
                                <ColumnDirective headerText='Opções' width="120px" textAlign='Center' template={(props: any) => (
                                    <Popover disabled={(props.Item.idVinculado && getNivelDeVisualizacao?.id == "NotasNaoEmitidas") || props.EmProcessamento} content={
                                        <Menu>
                                            {(!props.idVinculado || props.idVinculado == null) && getNivelDeVisualizacao?.id == "Definitivo" && <MenuItem icon={props.EmProcessamento == true ? "print" : "add"} text="Emitir NFSe" onClick={async () => {
                                                let ListaDeComissoes = { ...props.ListaDeComissoes };
                                                props.Item.EmProcessamento = true;
                                                await setListaDeComissoes(ListaDeComissoes);
                                                let Response = await NFSe.GerarNFSe(ContextSignIn.getContext().token, getListaDeComissoes?.nfs.filter((Item) => Item.id == (props.id ?? 0)) ?? []);
                                                if (Math.floor(Response.status / 100) == 2) {
                                                    ListaDeComissoes = { ...props.ListaDeComissoes };
                                                    props.Item.idVinculado = Response.data[0].idVinculado;
                                                    await setListaDeComissoes(ListaDeComissoes);
                                                    NotificacaoInterna.ExibirNotificacao("NFSe emitida", "Sua nota já foi emitida e encaminhada para o tomador, aguarde enquanto geraremos o processo de pagamento...", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                                    let ResponseGerarPagamento = await NFSe.GerarPagamento(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", Response.data);
                                                    if (Math.floor(ResponseGerarPagamento.status / 100) == 2) {
                                                        ListaDeComissoes = { ...props.ListaDeComissoes };
                                                        props.Item.EmProcessamento = false;
                                                        await setListaDeComissoes(ListaDeComissoes);
                                                        NotificacaoInterna.ExibirNotificacao("Processo de pagamento gerado", `Seu pagamento foi cadastrado agendado para : ${moment(ResponseGerarPagamento.data[0].vencimento, true).format("DD/MM/YYYY")}!`, NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                    }
                                                    else {
                                                        ListaDeComissoes = { ...props.ListaDeComissoes };
                                                        props.Item.EmProcessamento = false;
                                                        await setListaDeComissoes(ListaDeComissoes);
                                                        NotificacaoInterna.ExibirNotificacao("Falha ao gerar o processo de pagamento", (ResponseGerarPagamento.data.toString() != "[object Object]") ? ResponseGerarPagamento.data.toString() : "😱 Houve uma falha ao emitir NFSe, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                    }
                                                }
                                                else {
                                                    ListaDeComissoes = { ...props.ListaDeComissoes };
                                                    props.Item.EmProcessamento = false;
                                                    await setListaDeComissoes(ListaDeComissoes);
                                                    NotificacaoInterna.ExibirNotificacao("Falha ao emitir NFSe", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao emitir NFSe, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                }
                                            }} />}
                                            {(!props.idVinculado || props.idVinculado == null) && getNivelDeVisualizacao?.id == "Previo" && <MenuItem icon="error" text="Questionar informação" onClick={() => {
                                                window.location.href = `mailto:${encodeURI("washington@digitalint.com.br")}?subject=${encodeURI("Questionamento de comissão")}&body=${encodeURI(
                                                    `Prestador: ${props.PrestadorRazaoSocial} (${props.PrestadorCNPJ})\nTomador: ${props.TomadorRazaoSocial} (${props.TomadorCNPJ})\nValor: ${Moeda.FormatarTexto(props.ValorDoServico)}\n\n${props.descricao ?? ""}`)}`;
                                            }} />}
                                            {(props.idVinculado && props.idVinculado != null) && <MenuItem icon="remove" text="Cancelar pagamento" onClick={() => {
                                                NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                            }} />}
                                            {(props.idVinculado && props.idVinculado != null) && <MenuItem icon="send-message" text="Reencaminhar ao tomador" onClick={() => {
                                                NotificacaoInterna.ExibirNotificacao("Função em desenvolvimento", "Aguarde! Estamos desenvolvendo esta funcionalidade!", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                            }} />}
                                            {(props.idVinculado && props.idVinculado != null) && <MenuItem icon="document" text="PDF" onClick={async () => {
                                                let ListaDeComissoes = { ...props.ListaDeComissoes };
                                                props.Item.EmProcessamento = true;
                                                await setListaDeComissoes(ListaDeComissoes);
                                                let Response = await NFSe.Anexos(ContextSignIn.getContext().token, props.Item.id);
                                                if (Math.floor(Response.status / 100) == 2) {
                                                    let Arquivo = Response.data.filter(Item => Item.extensao == "pdf")[0]
                                                    let downloadLink = document.createElement("a");
                                                    downloadLink.href = Arquivo.arquivo;
                                                    downloadLink.download = `${Arquivo.descricao}.${Arquivo.extensao}`;
                                                    downloadLink.click();
                                                    
                                                    ListaDeComissoes = { ...props.ListaDeComissoes };
                                                    props.Item.EmProcessamento = false;
                                                    await setListaDeComissoes(ListaDeComissoes);
                                                    NotificacaoInterna.ExibirNotificacao("Download do pdf concluído", "Sua nota já foi baixada!", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                }
                                                else {
                                                    ListaDeComissoes = { ...props.ListaDeComissoes };
                                                    props.Item.EmProcessamento = false;
                                                    await setListaDeComissoes(ListaDeComissoes);
                                                    NotificacaoInterna.ExibirNotificacao("Falha ao baixar NFSe", "😱 Houve uma falha ao emitir NFSe, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                }
                                            }} />}
                                            {(props.idVinculado && props.idVinculado != null) && <MenuItem icon="code" text="XML" onClick={async () => {
                                                let ListaDeComissoes = { ...props.ListaDeComissoes };
                                                props.Item.EmProcessamento = true;
                                                await setListaDeComissoes(ListaDeComissoes);
                                                let Response = await NFSe.Anexos(ContextSignIn.getContext().token, props.Item.id);
                                                if (Math.floor(Response.status / 100) == 2) {
                                                    let Arquivo = Response.data.filter(Item => Item.extensao == "xml")[0]
                                                    let downloadLink = document.createElement("a");
                                                    downloadLink.href = Arquivo.arquivo;
                                                    downloadLink.download = `${Arquivo.descricao}.${Arquivo.extensao}`;
                                                    downloadLink.click();

                                                    ListaDeComissoes = { ...props.ListaDeComissoes };
                                                    props.Item.EmProcessamento = false;
                                                    await setListaDeComissoes(ListaDeComissoes);
                                                    NotificacaoInterna.ExibirNotificacao("Download do xml concluído", "Sua nota já foi baixada!", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                }
                                                else {
                                                    ListaDeComissoes = { ...props.ListaDeComissoes };
                                                    props.Item.EmProcessamento = false;
                                                    await setListaDeComissoes(ListaDeComissoes);
                                                    NotificacaoInterna.ExibirNotificacao("Falha ao baixar NFSe", "😱 Houve uma falha ao emitir NFSe, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                }
                                            }} />}
                                        </Menu>
                                    } position={Position.BOTTOM_RIGHT}>
                                        <Button disabled={(props.Item.idVinculado && getNivelDeVisualizacao?.id == "NotasNaoEmitidas") || props.EmProcessamento} intent={(((!props.Item.idVinculado && getNivelDeVisualizacao?.id == "NotasNaoEmitidas") || (props.Item.idVinculado && getNivelDeVisualizacao?.id != "NotasNaoEmitidas")) && props.EmProcessamento == false) ? 'danger' : (props.EmProcessamento == true ? "none" : "success")} style={{ height: "5px", width: "50px", borderRadius: '5px', cursor: "pointer", outline: "none", display: "flex", padding: "0" }}>
                                            {props.EmProcessamento == false && <div style={{ fontSize: "0.8vw", color: "#FFFFFF" }}>{(props.Item.idVinculado && getNivelDeVisualizacao?.id == "NotasNaoEmitidas") ? "Emitida" : "..."}</div>}
                                            {props.EmProcessamento == true && <Lottie height={30} width={100} options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />}
                                        </Button>
                                    </Popover>
                                )} />
                                <ColumnDirective field="id" headerText="Cód. da nota" width="150px" textAlign="Center" />
                                {getNivelDeVisualizacao && getNivelDeVisualizacao.id != "NotasNaoEmitidas" && <ColumnDirective field="idVinculado" headerText="Cód. da nota (externo)" width="300px" textAlign="Center" />}
                                <ColumnDirective field="PrestadorRazaoSocial" headerText="Prestador: razão social" width="300px" textAlign="Left" />
                                <ColumnDirective field="PrestadorNomeFantasia" headerText="Prestador: nome fantasia" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorRazaoSocial" headerText="Tomador: razão social" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorNomeFantasia" headerText="Tomador: nome fantasia" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorCNPJ" headerText="Tomador: CNPJ" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorIE" headerText="Tomador: inscrição municipal" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorIM" headerText="Tomador: inscrição estadual" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorTelefone" headerText="Tomador: telefone" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorEmail" headerText="Tomador: email" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorLogradouro" headerText="Tomador: endereço" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorNumero" headerText="Tomador: nº end." width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorComplemento" headerText="Tomador: compl. end." width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorBairro" headerText="Tomador: bairro" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorCidade" headerText="Tomador: cidade" width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorUF" headerText="Tomador: UF end." width="300px" textAlign="Left" />
                                <ColumnDirective field="TomadorCEP" headerText="Tomador: CEP end." width="300px" textAlign="Left" />
                                <ColumnDirective field="DataDeEmissao" headerText="Dt. emissão" type="date" format="dd/MM/yyyy" width="300px" textAlign="Left" />
                                <ColumnDirective field="Vencimento" headerText="Dt. vencimento" type="date" format="dd/MM/yyyy" width="300px" textAlign="Left" />
                                <ColumnDirective field="Competencia" headerText="Dt. competência" type="date" format="dd/MM/yyyy" width="300px" textAlign="Left" />
                                <ColumnDirective field="Descricao" headerText="Descricão" width="300px" textAlign="Left" />
                                <ColumnDirective field="ValorDoServico" headerText="Valor do serviço" format="C2" width="300px" textAlign="Left" />
                                <ColumnDirective field="MeioDePagamento" headerText="Meio de pagamento" width="300px" textAlign="Left" />
                                <ColumnDirective field="MeioDePagamento" headerText="enviarNFePrestador" width="300px" textAlign="Left" />
                                <ColumnDirective field="ServicoInternacional" headerText="Serv. internacional?" width="300px" textAlign="Left" />
                                <ColumnDirective field="CidadeDoServico" headerText="Cidade do serviço" width="300px" textAlign="Left" />
                                <ColumnDirective field="UFServico" headerText="UF do serviço" width="300px" textAlign="Left" />
                                <ColumnDirective field="COFINS" headerText="COFINS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="COFINS" headerText="COFINS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="CSLL" headerText="CSLL" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="INSS" headerText="INSS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="IR" headerText="IR" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="ISS" headerText="ISS" type="number" format="p" width="300px" textAlign="Left" />
                                <ColumnDirective field="PIS" headerText="PIS" type="number" format="p" width="300px" textAlign="Left" />
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Filter, Group]} />
                        </GridComponent>
                    </div>
                </Card>}
            </div>
        </Component.Container >
    );
    /* #endregion */
};

export default View;