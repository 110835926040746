import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

class Lead {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista todos os prospects cadastrados
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async Get(Token: string): Promise<AxiosResponse<Array<Objeto.Prospect>>> {
        return await Context.get(`/Prospect/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo prospect
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.Lead} Lead Informe os dados do lead
     **/
    public async Post(Token: string, Lead: Objeto.Lead): Promise<AxiosResponse<Objeto.Prospect>> {
        return await Context.post(`/Prospect/${Token}`, Lead)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  DELETE Controller  */
    /* #endregion */

    /* #region  PUT Controller  */
    /* #endregion */
};

export default new Lead();