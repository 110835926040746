import { AxiosResponse } from 'axios';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class Menu {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Informa por meio de um JSON um resumo de algumas das ativdades exercidas pelo usuário
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} GrupoDeEmpresas Informe o grupo de empresas (Grupo). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/GruposDeEmpresas/{Token}"
     **/
    public async QuadroResumo(Token: string): Promise<AxiosResponse<any>> {
        return await Context.get<Array<Object>>(`/Menu/QuadroResumo/${Token}/${ContextSignIn.getContext().UltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new Menu();