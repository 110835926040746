import React from 'react';
import { WidthCheck as ContextWidthCheck } from '../../../../../../Context';
import { Moeda } from '../../../../../../Themes/TextFormat';

function App({ statusData, visao, tiposInadimplencia, getListaFaixa, getListaCategoria }: any) {

    return (
        <div style={{ width: ContextWidthCheck.getContext().width <= 660 ? "70%" : "31%", borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", paddingTop: "0px", fontWeight: 600 }}>
            {/* ====================DISPONIBILIDADE==================== */}
            {visao === 0 && statusData.map((status: { id: number, name: string, color: string }, statusIndex: number) =>
                <div style={{ display: "flex", fontSize: status.name.length >= 13 ? "11px" : "12px", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: statusIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: status.color, marginRight: "10px" }}></div>
                    <div>{status.name}</div>
                </div>
            )}
            {/* ====================INADIMPLENCIA==================== */}
            {visao === 1 && tiposInadimplencia.map((Item: any, ItemIndex: number) =>
                <div style={{ display: "flex", fontSize: Item.descricao.length >= 13 ? "9px" : "11px", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: ItemIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div style={{ minWidth: "20px", minHeight: "20px", borderRadius: "50%", backgroundColor: Item.cor, marginRight: "10px" }}></div>
                    <div>{Item.descricao}</div>
                </div>
            )}
            {/* ====================FAIXA DE PREÇO==================== */}
            {visao === 2 && getListaFaixa.map((Item: any, ItemIndex: number) =>
                <div style={{ display: "flex", width: "100%", fontSize: "10px", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: ItemIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div style={{ minWidth: "20px", minHeight: "20px", borderRadius: "50%", backgroundColor: Item.cor, marginRight: "10px" }}></div>
                    <div>{`${Moeda.FormatarTexto(Item.faixa[0])} a ${/*(Item.faixa[1] > 100000) ? "∞" : */Moeda.FormatarTexto(Item.faixa[1])}`}</div>
                </div>
            )}
            {/* ====================CATEGORIA DE PREÇO==================== */}
            {visao === 3 && getListaCategoria.map((Item: any, ItemIndex: number) => {
                return (
                    <div style={{ display: "flex", width: "100%", fontSize: Item.Categoria_de_preco.length >= 13 ? "9px" : "12px", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: ItemIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                        <div style={{ minWidth: "20px", minHeight: "20px", borderRadius: "50%", backgroundColor: Item.corCategoria, marginRight: "10px" }}></div>
                        <div>{Item.Categoria_de_preco}</div>
                    </div>
                )
            }

            )}
        </div>
    );
}

export default App;