import React, { useState } from 'react';
import { Objeto } from '../../../../../../Services/Models';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";

function App({ getListaPickerQuadra,  setConsumer }: any) {
    const PickerQuadra = Select.ofType<Objeto.QuadraPicker>();

    return (
        <div style={{ width: "100%" }}>
            <div style={{ width: "200px", height: "80px", backgroundColor: "unset", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                <PickerQuadra popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerQuadra} onItemSelect={() => { }} noResults={<MenuItem style={{ position: "relative", zIndex: 30000 }} disabled={true} text="Sem resultados disponíveis" />}
                    itemRenderer={(Item: Objeto.QuadraPicker) => <MenuItem style={{ position: "relative", zIndex: 30000 }} onClick={async () => {
                        setConsumer([true, [Item.center.latitude, Item.center.longitude]])
                    }} text={Item.quadra} />} itemPredicate={(Texto: string, Item: Objeto.QuadraPicker) => { return (Item.quadra ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                    <Button className="selector" style={{ background: "#e6e6e6", position: "relative", zIndex: 30000, color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={"Quadra"} rightIcon="double-caret-vertical" />
                </PickerQuadra>
            </div>
        </div>
    );
}

export default App;