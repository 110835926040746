import axios from 'axios';
import moment from "moment";
import "moment/locale/pt-br"
import React, { memo, Fragment, useEffect, useRef, useState } from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import { pv } from 'financial';
import io from "socket.io-client";
import { Collapse } from 'react-collapse';
import Lottie from 'react-lottie';
import { toast as Toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, Card, FileInput, FormGroup, HTMLSelect, InputGroup, Icon, MenuItem, Radio, Switch, Menu, Collapse as CollapseBlueprint } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select } from "@blueprintjs/select";

import { IconeHome } from '../../../../Assets';
import { SignIn as ContextSignIn } from '../../../../Context';
import { Banco, Bandeira, MaquinaDeCartao, MeioDePagamento, Operacao, UF } from '../../../../Data/Listas';
import * as LottieFiles from '../../../../Data/Lottie';
import History from "../../../../Routers";
import { CentroDeCusto, Correios, D4sign, Identificador, Lead, Nacao, Pessoa, Prospect, TabelaDeVenda, Venda } from '../../../../Services/Controllers';
import { Objeto } from '../../../../Services/Models';
import { NotificacaoInterna } from '../../../../Services/Notification';
import { CPF, CEP, CNPJ, Moeda, Numero, Telefone, Percentual } from '../../../../Themes/TextFormat';

function App({listaIdentificadorSintetico, ItemPickerIdentificadorSintetico, ItemPickerCentroDeCusto, ItemRender, ItemPredicate }: {listaIdentificadorSintetico: Array<Objeto.IdentificadorSintetico>, ItemPickerIdentificadorSintetico: Objeto.IdentificadorSintetico | undefined, ItemPickerCentroDeCusto: Objeto.CentroDeCusto | undefined, ItemRender: any, ItemPredicate: any}) {

    const PickerIdentificador = Select.ofType<Objeto.IdentificadorSintetico>();

    return (
        <div>
            <PickerIdentificador popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={listaIdentificadorSintetico} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                itemListRenderer={({ items, itemsParentRef, query, renderItem }) => {
                    let renderedItems = items.filter((Item) => (Item.subLocalDescricao ? Item.subLocalDescricao : "").toUpperCase().includes(query.toUpperCase()) || query == "").map(renderItem).filter((Item) => Item != null);
                    return (
                        <Menu ulRef={itemsParentRef}>
                            <MenuItem
                                disabled={true}
                                text={`${renderedItems.length} ${renderedItems.length > 1 ? "items encontrados" : "item encontrado"}`}
                            />
                            {renderedItems.slice(0, 99)}
                            <MenuItem
                                disabled={true}
                                text={`${renderedItems.length > 100 ? " (apenas os 100 primeiros itens serão exibidos)" : ""}`}
                            />
                        </Menu>
                    );
                }}
                itemRenderer={ItemRender}
                itemPredicate={ItemPredicate}>
                <Button id="unidade" text={ItemPickerIdentificadorSintetico?.subLocalDescricao ?? "Selecione a unidade"} rightIcon="double-caret-vertical" />
                {ItemPickerCentroDeCusto && listaIdentificadorSintetico.length == 0 && <Icon icon="geosearch" />}
            </PickerIdentificador>
            {ItemPickerIdentificadorSintetico && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {  }} />}
        </div>
    );
}

export default memo(App);