import * as Cadastros from './Cadastros/index.json';
import * as Funil from './Funil/index.json';
import * as Fila from './Fila/index.json';
import * as Kanban from './Kanban/index.json';
import * as Load from './Load/index.json';
import * as Load02 from './Load02/index.json';
import * as Load03 from './Load03/index.json';
import * as Load04 from './Load04/index.json';
import * as Load05 from './Load04/index.json';
import * as LoadMaps from './LoadMaps/index.json';
import * as LoadTable from './LoadTable/index.json';
import * as ModulosGestaoDeComissao from './Modulos/GestaoDeComissao/index.json';
import * as SwipeDown from './SwipeDown/index.json';
import * as SwipeWhite from './SwipeWhite/index.json';

export {
    Cadastros,
    Funil,
    Fila,
    Kanban,
    Load,
    Load02,
    Load03,
    Load04,
    Load05,
    LoadMaps,
    LoadTable,
    ModulosGestaoDeComissao,
    SwipeDown,
    SwipeWhite
};