import React from 'react';
import { WidthCheck as ContextWidthCheck } from '../../../../../../Context';

function App({ getTileLayer, setTileLayer, TileLayerShouldBe, Image, name }: any) {

    return (
        <div onClick={() => setTileLayer(TileLayerShouldBe)} style={{ background: getTileLayer === TileLayerShouldBe ? "#f3f3f3" : "unset", cursor: "pointer", borderRadius: "5px", color: "#696969", fontWeight: 600, height: "120px", width: "100px", padding: "10px 0 5px 0", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ height: ContextWidthCheck.getContext().width <= 660 ? "60px" : "80px", borderRadius: "5px", boxShadow: "0 0 10px gray", width: ContextWidthCheck.getContext().width <= 660 ? "60px" : "80px", backgroundImage: `url(${Image})`, backgroundRepeat: "no-repeat", backgroundSize: "250px", backgroundPositionY: "-10px", color: "#001447", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "40px" }}></div>
            <span>{name}</span>
        </div>
    );
}

export default App;