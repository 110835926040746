import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { AiOutlineRocket } from 'react-icons/ai';
import { FiArrowLeft } from 'react-icons/fi';
import { useParams } from "react-router-dom";
import { Button, InputGroup } from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";

import { LogomarcaDigitalDevBranca as LogomarcaDigitalDev, LogomarcaOnePlusOriginal as LogomarcaOnePlus, LogomarcaOnMoneyBranca as LogomarcaOnMoney } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import * as LottieFiles from '../../../Data/Lottie';
import History from "../../../Routers";
import { Logon } from '../../../Services/Controllers';
import { NotificacaoInterna } from '../../../Services/Notification';
import { Objeto } from '../../../Services/Models';

import { Container, Form, Footer } from './style';
import './style.css';
import viewModel from './viewModel';

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const [getAlterandoSenha, setAlterandoSenha] = useState(false);
    const [getFormularioSenha, setFormularioSenha] = useState<string>("");
    const [getFormularioSenhaConfirmada, setFormularioSenhaConfirmada] = useState<string>("");
    const [getItemPickerGrupoDeEmpresas, setItemPickerGrupoDeEmpresas] = useState<{ id: number, descricao: string, empresas: Array<Objeto.Empresa> }>();
    const PickerGrupoDeEmpresas = Select.ofType<{ grupo: string, empresas: Array<Objeto.Empresa> }>();
    const Token = (useParams() as any).Token;
    /* #endregion */

    /* #region  Funções  */
    /* Sobre esta função *//**
     * Valida os dados digitados e tenta efetuar o login do usuário
     **/
    async function EfetuarAlteracaoDeSenha(): Promise<void> {
        await setAlterandoSenha(true);
        if (await ValidarFormulario() == true) {
            var Response = await Logon.Put(Token, getFormularioSenha, getFormularioSenhaConfirmada);
            if (Math.floor(Response.status / 100) == 2) {
                History.push("/");
                await ContextSignIn.setContext(Response.data);
                NotificacaoInterna.ExibirNotificacao("Caro usuário", "Sua senha foi devidamente alterada! Realize novamente o seu login!", NotificacaoInterna.TipoDeNotificacao.Sucesso);
            }
            else {
                NotificacaoInterna.ExibirNotificacao("Caro usuário", "Houve um erro ao tentar alterar a sua senha em nosso sistema, por favor, tente novamente mais tarde!", NotificacaoInterna.TipoDeNotificacao.Erro);
            }
        }
        await setAlterandoSenha(false);
    };
    async function ValidarFormulario(): Promise<Boolean> {
        if (!getFormularioSenha) {
            NotificacaoInterna.ExibirNotificacao("Senha não preenchida", "Informe a senha", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        if (!getFormularioSenhaConfirmada) {
            NotificacaoInterna.ExibirNotificacao("Senha não confirmada", "Confirme a sua senha", NotificacaoInterna.TipoDeNotificacao.Erro);
            return false;
        }
        return true
    }
    useEffect(() => {
        async function componentDidMount() {
        }
        componentDidMount();
    }, []);
    return (
        <Container>
            <Form>
                <img style={{ width: "200px", height: "150px", objectFit: "contain" }} src={window.location.href.includes("onmoney") ? LogomarcaOnMoney : LogomarcaOnePlus} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <InputGroup id="Email" placeholder="Nova senha" value={getFormularioSenha} type="password"
                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioSenha(event.currentTarget.value)}>
                    </InputGroup>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <InputGroup id="Senha" placeholder="Comfirme a senha" value={getFormularioSenhaConfirmada} type="password"
                        onChange={async (event: React.FormEvent<HTMLInputElement>) => await setFormularioSenhaConfirmada(event.currentTarget.value)}>
                    </InputGroup>
                    </div>
                </div>
                <div>
                    {getAlterandoSenha == true && <div style={{ marginLeft: "40px", width: 100, height: 100 }}>
                        <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                    </div>}
                    {getAlterandoSenha == false && <div>
                        <Button id="Submit" type="submit" intent="primary" onClick={EfetuarAlteracaoDeSenha}>Confirmar</Button>
                    </div>}
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <h5>Não tem uma conta?</h5>
                    <a href="/Logon/SignUp">Cadastrar-se</a>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <a href="/"><FiArrowLeft />Voltar para tela de Login</a>
                </div>
                <Footer>
                    <a>
                        <AiOutlineRocket size={20} />Desenvolvido por &nbsp;<img src={LogomarcaDigitalDev} height={30} alt="DigitalDev" />
                    </a>
                </Footer>
            </Form>
        </Container>
    );
    /* #endregion */
};

export default View;