import styled from 'styled-components';
//import StarJedi1 from '../../../Assets/Font/Star_Jedi/starjedi/Starjedi.ttf';


export const Container = styled.div`
    align-items: stretch;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 120%;
    background-color: #00384a;
    @font-face {
    font-family: "StarJedi1"
    }
`;
export const Checkbox1 = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 10px;
`

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    width: 300px;
    height: 100vh;
    overflow: auto;
    h6 {
        margin: 35px 0px 0px 0px;
        font-family: 'Roboto',sans-serif;
        font-weight: 400;
        font-size: 15.8px;
        text-justify: auto;
        color: #ffffff;
        text-align: center;
        width: 286px;
        height: 24px;
    }
    h5{
        font-family: 'Roboto',sans-serif;
        font-weight: 400;
        color: #ffffff;
        font-size: 12px;
    }
    h2{
        font-size: 25px;
        margin-right: -100px;
        font-family: "StarJedi1";
        font-weight: 200;
        color: #ffffff
    }
    #Forgot_Password{
        font-family: 'Roboto',sans-serif;
        font-size: 12px;
        color: #3e9e6a;
        text-decoration: none;
        justify-content: center;
    }
    #Submit{
        margin-top: 24px;
        padding: 0px 16px;
        width: 286px;
        height: 40px;
        background-color: #2F855A;
        border: 0px;
        border-radius: 5px;
        font-family: 'Roboto',sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
    }
    #Submit:hover{
        background-color: #234E52;
        transition-duration: 0.2s;
    }
    #Empresa{
        margin: 50px 0px 0px 0px;
        padding: 0px 12px;
        width: 286px;
        height: 32px;
        background: #065666 ;
        border: 0px;
        border-radius: 5px;
        color: #ffffff;
    }
    #Email{
        margin: 20px 0px;
        padding: 0px 12px;
        width: 286px;
        height: 32px;
        background-color: #065666 ;
        border: 0px;
        border-radius: 5px;
        color: #ffffff;
    }
    #Email:hover{
        background-color: #086F83;
        transition-duration: 0.2s;
    }
    #Email:focus{
        background-color: transparent;
        outline-color: #38A169;
        border: 0px none;
    }
    #Senha{
        padding: 0px 12px;
        width: 286px;
        height: 32px;
        background-color: #065666 ;
        border: 0px;
        border-radius: 5px;
        color: #ffffff;
    }
    #Senha:hover{
        background-color: #086F83;
        transition-duration: 0.2s;
    }
    #Senha:focus{
        background-color: transparent;
        outline-color: #38A169;
        border: 0px none;
    }
    a{
        font-family: 'Roboto',sans-serif;
        font-size: 12px;
        color: #3e9e6a;
        text-decoration: none;
        justify-content: center;
        margin-left: 10px;
    }
    input::-webkit-input-placeholder{
    color: #ffffff !important;
    font-family: "Roboto" sans-serif;
    font-size: 14px;
    }
    input:-webkit-autofill {
        box-shadow: 0 0 0 30px #065666 inset; //-webkit-box-shadow: 0 0 0 30px #065666 inset;
    }   
    input:-webkit-autofill {
        -webkit-text-fill-color: #ffffff !important;
    }
`;
export const Footer = styled.div`
    @media (max-width:1250px) and (min-width:1150px) and (max-height:2050px) and (min-height:1950px) {

    }
    @media (max-width:2050px) and (min-width:1950px) and (max-height:1250px) and (min-height:1150px) {

    }
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
`;