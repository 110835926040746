import axios from "axios";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, Card, FormGroup, MenuItem, Icon, Collapse, Divider } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select } from "@blueprintjs/select";
import { DateInput } from "@blueprintjs/datetime";
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Group, Inject, GroupSettingsModel, Aggregate, AggregateDirective, AggregatesDirective, AggregateColumnsDirective, AggregateColumnDirective, Toolbar, ToolbarItems, PdfExport, Grid, Filter, FilterSettingsModel, Reorder, Column, Sort, SortSettingsModel, PdfHeaderQueryCellInfoEventArgs, ColumnMenu, } from "@syncfusion/ej2-react-grids";

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import { Localization } from '../../../Data/Listas';
import * as LottieFiles from '../../../Data/Lottie';
import History from "../../../Routers";
import { CentroDeCusto, Venda } from '../../../Services/Controllers';
import { Objeto } from '../../../Services/Models';
import { NotificacaoInterna } from '../../../Services/Notification';

import './App.css';
import { image } from './image';
import * as Component from './style';
import viewModel from './viewModel';

loadCldr(
    require('../../../../node_modules/cldr-data/main/pt/currencies.json'),
    require('../../../../node_modules/cldr-data/main/pt/numbers.json'),
    require('../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
    require('../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
    require('../../../../node_modules/cldr-data/supplemental/numberingSystems.json'));
setCulture('pt');
setCurrencyCode("BRL");
L10n.load(Localization);

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const PropriedadesDeCalendario = {
        months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    };
    const [getFormularioDataDeInicio, setFormularioDataDeInicio] = useState<Date>(moment(new Date()).subtract(1, "day").toDate());
    const [getFormularioExibirCalendarioDataDeInicio, setFormularioExibirCalendarioDataDeInicio] = useState<boolean>(false);
    const [getFormularioDataDeTermino, setFormularioDataDeTermino] = useState<Date>(moment(new Date()).subtract(0, "day").toDate());
    const [getFormularioExibirCalendarioDataDeTermino, setFormularioExibirCalendarioDataDeTermino] = useState<boolean>(false);
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const [getItemPickerSala, setItemPickerSala] = useState<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();
    const [getListaPickerSala, setListaPickerSala] = useState<Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>>();
    const PickerSala = Select.ofType<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();
    const [getListaDeContratos, setListaDeContratos] = useState<Array<{ Cliente: string, Operacao: string, Identificador: string, TipoDeParcela: string, Vencimento: string, Boleto: number, Cartao: number, Cheque: number, Deposito: number, Dinheiro: number, PIX: number, Reaproveitamento: number, Transferencia: number, Total: number }>>([]);
    const [getCarregandoListaDeContratos, setCarregandoListaDeContratos] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getCollapse, setCollapse] = useState(false)
    let dataStorage: Array<{ Cliente: string, Operacao: string, Identificador: string, TipoDeParcela: string, Vencimento: string, Boleto: number, Cartao: number, Cheque: number, Deposito: number, Dinheiro: number, PIX: number, Reaproveitamento: number, Transferencia: number, Total: number }>;
    /* #endregion */

    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
       **/
    async function Buscar() {
        await setCarregandoListaDeContratos(false, async () => {
            if (getItemPickerCentroDeCusto && getItemPickerSala) {
                let Response = (await Venda.LogsDeVendas(ContextSignIn.getContext().token, getItemPickerCentroDeCusto.empresa.id ?? 0, getItemPickerCentroDeCusto.sigla ?? "", getFormularioDataDeInicio, getFormularioDataDeTermino));


                if (Math.floor(Response.status / 100) == 2) {
                    dataStorage = [];
                    Response.data.map((PropostaDeVenda) => {
                        if ((getItemPickerSala.descricao == PropostaDeVenda.salaDeVendaNome) || (!PropostaDeVenda.salaDeVendaNome && getItemPickerSala.cidade == PropostaDeVenda.salaDeVenda)) {
                            PropostaDeVenda.titulosDeEntrada.map((Entrada) => {
                                dataStorage.push(
                                    {
                                        Cliente: PropostaDeVenda.prospects[0].nome,
                                        Operacao: (Entrada.nsu ?? Entrada.numeroCheque) ?? (Entrada.numeroDaOperacao || ""),
                                        Identificador: PropostaDeVenda.identificador.subLocal.descricao,
                                        TipoDeParcela: "Entrada",
                                        Vencimento: moment(Entrada.vencimento).format("DD/MM/YYYY"),
                                        Boleto: Entrada.formaDePagamento == "Boleto" ? Entrada.valor : 0,
                                        Cartao: Entrada.formaDePagamento == "Cartao" ? Entrada.valor : 0,
                                        Cheque: Entrada.formaDePagamento == "Cheque" ? Entrada.valor : 0,
                                        Deposito: Entrada.formaDePagamento == "Deposito" ? Entrada.valor : 0,
                                        Dinheiro: Entrada.formaDePagamento == "Dinheiro" ? Entrada.valor : 0,
                                        PIX: Entrada.formaDePagamento == "PIX" ? Entrada.valor : 0,
                                        Reaproveitamento: Entrada.formaDePagamento == "Reaproveitameno" ? Entrada.valor : 0,
                                        Transferencia: Entrada.formaDePagamento == "Transferencia" ? Entrada.valor : 0,
                                        Total: Entrada.valor,
                                    }
                                );
                            });
                            PropostaDeVenda.titulosDeSinal.map((Sinal) => {
                                dataStorage.push(
                                    {
                                        Cliente: PropostaDeVenda.prospects[0].nome,
                                        Operacao: (Sinal.nsu ?? Sinal.numeroCheque) ?? (Sinal.numeroDaOperacao || ""),
                                        Identificador: PropostaDeVenda.identificador.subLocal.descricao,
                                        TipoDeParcela: "Sinal",
                                        Vencimento: moment(Sinal.vencimento).format("DD/MM/YYYY"),
                                        Boleto: Sinal.formaDePagamento == "Boleto" ? Sinal.valor : 0,
                                        Cartao: Sinal.formaDePagamento == "Cartao" ? Sinal.valor : 0,
                                        Cheque: Sinal.formaDePagamento == "Cheque" ? Sinal.valor : 0,
                                        Deposito: Sinal.formaDePagamento == "Deposito" ? Sinal.valor : 0,
                                        Dinheiro: Sinal.formaDePagamento == "Dinheiro" ? Sinal.valor : 0,
                                        PIX: Sinal.formaDePagamento == "PIX" ? Sinal.valor : 0,
                                        Reaproveitamento: Sinal.formaDePagamento == "Reaproveitameno" ? Sinal.valor : 0,
                                        Transferencia: Sinal.formaDePagamento == "Transferencia" ? Sinal.valor : 0,
                                        Total: Sinal.valor,
                                    }
                                );
                            });
                            PropostaDeVenda.titulosDeParcela.map((Parcela) => {
                                dataStorage.push(
                                    {
                                        Cliente: PropostaDeVenda.prospects[0].nome,
                                        Operacao: (Parcela.nsu ?? Parcela.numeroCheque) ?? (Parcela.numeroDaOperacao || ""),
                                        Identificador: PropostaDeVenda.identificador.subLocal.descricao,
                                        TipoDeParcela: "Parcela",
                                        Vencimento: moment(Parcela.vencimento).format("DD/MM/YYYY"),
                                        Boleto: Parcela.formaDePagamento == "Boleto" ? Parcela.valor : 0,
                                        Cartao: Parcela.formaDePagamento == "Cartao" ? Parcela.valor : 0,
                                        Cheque: Parcela.formaDePagamento == "Cheque" ? Parcela.valor : 0,
                                        Deposito: Parcela.formaDePagamento == "Deposito" ? Parcela.valor : 0,
                                        Dinheiro: Parcela.formaDePagamento == "Dinheiro" ? Parcela.valor : 0,
                                        PIX: Parcela.formaDePagamento == "PIX" ? Parcela.valor : 0,
                                        Reaproveitamento: Parcela.formaDePagamento == "Reaproveitameno" ? Parcela.valor : 0,
                                        Transferencia: Parcela.formaDePagamento == "Transferencia" ? Parcela.valor : 0,
                                        Total: Parcela.valor,
                                    }
                                );
                            });
                        }

                    });

                    await setListaDeContratos(dataStorage);
                    await setCarregandoListaDeContratos(true, () => { })
                }
                else {
                    await setCarregandoListaDeContratos(undefined, () => { })
                    await setItemPickerCentroDeCusto(undefined);
                    await setItemPickerSala(undefined);
                    await setListaDeContratos([]);
                }
            }
            else {
                if (!getItemPickerCentroDeCusto) {
                    NotificacaoInterna.ExibirNotificacao("Empreendimento não selecionado", "Informe o empreendimento", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
                if (!getItemPickerSala) {
                    NotificacaoInterna.ExibirNotificacao("Sala não selecionada", "Informe a sala", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
            }
        });
    }
    useEffect(() => {
        async function componentDidMount() {
            await setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? []);
            var Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                .get(`/Identificador/VisaoGAV02/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}`))
                .data as Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>;
            await setListaPickerSala(Response);
        }
        componentDidMount();
    }, []);
    const toolbarOptions: ToolbarItems[] = ['PdfExport']
    const groupOptions: GroupSettingsModel = {
        columns: ['Cliente', 'Operacao'],
        showGroupedColumn: true,
        showUngroupButton: true,
    }
    const filterOptions: FilterSettingsModel = {
        ignoreAccent: true,
        immediateModeDelay: 200,
        mode: "Immediate",
        type: 'Excel'
    }
    const sortOptions: SortSettingsModel = {

    }
    const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs | any) => {
        (args.cell as any).row.pdfGrid.repeatHeader = true;
        args.cell.gridRow.pdfGrid.columns.getColumn(2).width = 90;
        args.cell.gridRow.pdfGrid.columns.getColumn(2).textAlign = "Right";
    }
    let grid: Grid | null;
    const toolbarClick = (args: any) => {
        if (grid) {
            if (args.item.id === 'grid_pdfexport') {
                (grid.columns[0] as Column).visible = false;
                (grid.columns[1] as Column).visible = false;
                grid.pdfExport({
                    fileName: `Borderô ${moment(getFormularioDataDeInicio, true).format("YYYY-MM-DD")} à ${moment(getFormularioDataDeTermino, true).format("YYYY-MM-DD")} - ${((getItemPickerCentroDeCusto?.empresa.id == 20) ? "PRE" : ((getItemPickerCentroDeCusto?.empresa.id == 21) ? "SPR" : ((getItemPickerCentroDeCusto?.empresa.id == 22) ? "SER" : ((getItemPickerCentroDeCusto?.empresa.id == 23) ? "PAR" : (getItemPickerCentroDeCusto?.empresa.id == 26 ? "PYR" : getItemPickerCentroDeCusto?.sigla)))))} (${getItemPickerSala?.descricao}).pdf`,
                    pageOrientation: "Landscape",
                    header: {
                        fromTop: 0,
                        height: 130,
                        contents: [
                            {
                                type: 'Text',
                                value: `${getItemPickerCentroDeCusto?.descricao}`,
                                position: { x: 390, y: 50 },
                                style: { textBrushColor: "#000000", fontSize: 20, hAlign: "Right" },
                            },
                            {
                                position: { x: 0, y: 45 },
                                size: { height: 40, width: 80 },
                                src: image,
                                type: 'Image',
                            },
                            {
                                type: 'Text',
                                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:mm:ss")}`,
                                position: { x: 880, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 13 },
                            },
                        ]
                    },
                    footer: {
                        contents: [
                            {
                                type: 'Text',
                                value: `Powered by DigitalDEV`,
                                position: { x: 0, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 10 },
                            },
                            {
                                format: 'Página {$current} de {$total}',
                                pageNumberType: "Numeric",
                                position: { x: 910, y: 50 },
                                style: {
                                    fontSize: 13,
                                    hAlign: "Right",
                                    textBrushColor: '#000000',
                                },
                                type: 'PageNumber',
                            }
                        ],
                        fromBottom: 0,
                        height: 100,
                    },
                    theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
                        },
                        record: {
                            bold: false,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7)
                        },
                        caption: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
                        }
                    },
                    allowHorizontalOverflow: false,

                });
            }
        }
    }
    const pdfExportComplete = () => {
        if (grid) {
            (grid.columns[0] as Column).visible = true;
            (grid.columns[1] as Column).visible = true;
        }
    }
    return (
        <Component.Container>
            {/* Collapse */}
            <Collapse isOpen={getCollapse}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "0", width: "100%" }}>
                    <div style={{ width: "65%", minWidth: "919px", display: "flex", alignItems: "center", justifyContent: "space-between", background: "0", marginLeft: "2%" }}>
                        <div className="endereco">
                            <img src={IconeHome} style={{cursor: "pointer"}} alt="" onClick={() => {History.goBack();}} />
                            <div className="bar">/</div>
                            <div className="place">Bordero</div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "0", width: "90%", minWidth: "777px" }}>
                            <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                            <PickerSala popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerSala ?? [] as Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: { descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }) => <MenuItem onClick={async () => {
                                    await setItemPickerSala(Item);
                                }} text={Item.descricao} />}
                                itemPredicate={(Texto: string, Item: { descricao: string, cidade: string, closer: Array<{ descricao: string }>, liner: Array<{ descricao: string }>, pep: Array<{ descricao: string }>, subGerenteDeSala: Array<{ descricao: string }>, gerenteDeSala: Array<{ descricao: string }> }) => { return Item.descricao.toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerSala?.descricao.substring(0, 10).concat("...") ?? "Sala"} rightIcon="double-caret-vertical" />
                            </PickerSala>
                            {getItemPickerSala && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerSala(undefined); }} />}

                            <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => { await setItemPickerCentroDeCusto(Item); }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerCentroDeCusto?.descricao.substring(0, 10).concat("...") ?? "Empreendimento"} rightIcon="double-caret-vertical" />
                            </PickerCentroDeCusto>

                            <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                            <FormGroup className="place" label="Data de início:" labelFor="DataDeInicio" inline={true} style={{ marginTop: "15px" }}>
                                <div style={{ width: "100px" }}>
                                    <DateInput inputProps={{ onClick: () => setFormularioExibirCalendarioDataDeInicio(true) }}
                                        dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{ disabled: !getFormularioExibirCalendarioDataDeInicio, onClosed: () => setFormularioExibirCalendarioDataDeInicio(false) }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} value={getFormularioDataDeInicio} maxDate={getFormularioDataDeTermino}
                                        onChange={async (DataSelecionada: Date) => {
                                            if (DataSelecionada != null) {
                                                await setFormularioDataDeInicio(DataSelecionada)
                                            }
                                            else {
                                                await setFormularioDataDeInicio(getFormularioDataDeInicio)
                                            }
                                        }}>
                                    </DateInput>
                                </div>
                            </FormGroup>
                            <FormGroup className="place" label="Data de termino:" labelFor="DataDeTermino" inline={true} style={{ marginTop: "15px" }}>
                                <div style={{ width: "100px" }}>
                                    <DateInput inputProps={{ onClick: () => setFormularioExibirCalendarioDataDeTermino(true) }}
                                        dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                        popoverProps={{ disabled: !getFormularioExibirCalendarioDataDeTermino, onClosed: () => setFormularioExibirCalendarioDataDeTermino(false) }}
                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                        placeholder={"DD/MM/AAAA"} value={getFormularioDataDeTermino} minDate={getFormularioDataDeInicio} maxDate={new Date()}
                                        onChange={async (DataSelecionada: Date) => {
                                            if (DataSelecionada != null && DataSelecionada < getFormularioDataDeInicio) {
                                                await setFormularioDataDeInicio(DataSelecionada)
                                            }
                                            if (DataSelecionada != null) {
                                                await setFormularioDataDeTermino(DataSelecionada)
                                            }
                                            else if (DataSelecionada == null) {
                                                await setFormularioDataDeTermino(getFormularioDataDeTermino)
                                            }
                                        }}>
                                    </DateInput>
                                </div>
                            </FormGroup>
                            <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                        </div>
                    </div>
                    <div className="hoverize" style={{ width: "110px", minWidth: "110px", height: "40px", marginRight: "90px", marginLeft: "10px", color: "#175372", boxShadow: "0px 0px 0px 1px #d8d8d8", padding: "0 5px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer" }}
                        onClick={Buscar}>
                        <Icon color="#175372" style={{/*{ transform: getFilterClicked ? "rotate(0deg)" : "rotate(-360deg)", transition: getFilterClicked ? "transform 2s" : "" }*/ }} icon="search" />
                        <span>Pesquisar</span>
                    </div>
                </div>
            </Collapse>
            {/* Abridor Colapse */}
            <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>

            {getCarregandoListaDeContratos != true && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.LoadMaps,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={500}
                    width={500}
                    style={{ marginTop: "100px" }}
                />
                <div className="loading" style={{ margin: "-100px auto 0 auto", fontWeight: "bold" }}>{(getCarregandoListaDeContratos == undefined) ? (getItemPickerCentroDeCusto == undefined ? "Por favor, selecione um empreendimento" : (getItemPickerSala == undefined ? "Por favor, selecione a sala de vendas" : "Por favor, clique no botão de busca para exibir o borderô")) : ((getCarregandoListaDeContratos == false) ? "Aguarde, estamos carregando seu empreendimento" : "Pronto, seu mapa logo será exibido!")}</div>
            </div>}
            {getCarregandoListaDeContratos == true && <Card style={{ backgroundColor: "0", width: "100%", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none" }}>
                <div style={{ width: "100%", overflowX: 'scroll' }}>
                    <GridComponent dataSource={getListaDeContratos} width={2800} height={"100%"}
                        id='grid'
                        ref={g => grid = g}
                        allowPaging={true}
                        allowGrouping={true}
                        allowPdfExport={true}
                        allowFiltering={true}
                        allowReordering={true}
                        allowSorting={false}
                        showColumnMenu={true}
                        sortSettings={sortOptions}
                        toolbar={toolbarOptions}
                        pdfHeaderQueryCellInfo={pdfHeaderQueryCellInfo}
                        toolbarClick={toolbarClick}
                        groupSettings={groupOptions}
                        filterSettings={filterOptions}
                        pdfExportComplete={pdfExportComplete}
                        pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                        locale={"pt"}
                        currencyCode={"BRL"}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='Cliente' headerText='Cliente' textAlign='Left' isPrimaryKey={true} />
                            <ColumnDirective field='Operacao' headerText='Operacao' textAlign='Left' />
                            <ColumnDirective field='Identificador' headerText='Unidade' textAlign='Left' allowGrouping={true} />
                            <ColumnDirective field='TipoDeParcela' headerText='Tipo' textAlign='Left' allowGrouping={true} />
                            <ColumnDirective field='Vencimento' headerText='Venc.' textAlign='Right' allowGrouping={true} />
                            <ColumnDirective field='Boleto' headerText='Boleto' textAlign='Right' format='C2' allowGrouping={true} />
                            <ColumnDirective field='Cartao' headerText='Cartao' textAlign='Right' format='C2' allowGrouping={true} />
                            <ColumnDirective field='Cheque' headerText='Cheque' textAlign='Right' format='C2' allowGrouping={true} />
                            <ColumnDirective field='Deposito' headerText='Deposito' textAlign='Right' format='C2' allowGrouping={true} />
                            <ColumnDirective field='Dinheiro' headerText='Dinheiro' textAlign='Right' format='C2' allowGrouping={true} />
                            <ColumnDirective field='PIX' headerText='PIX' textAlign='Right' format='C2' allowGrouping={true} />
                            <ColumnDirective field='Reaproveitamento' headerText='Reap.' textAlign='Right' format='C2' allowGrouping={true} />
                            <ColumnDirective field='Transferencia' headerText='Transf.' textAlign='Right' format='C2' allowGrouping={true} />
                            <ColumnDirective field='Total' headerText='Total' textAlign='Right' format='C2' allowGrouping={true} />
                        </ColumnsDirective>
                        <AggregatesDirective>
                            <AggregateDirective>
                                <AggregateColumnsDirective>
                                    <AggregateColumnDirective field='Boleto' type="Sum" format='C2' groupCaptionTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Cartao' type="Sum" format='C2' groupCaptionTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Cheque' type="Sum" format='C2' groupCaptionTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Deposito' type="Sum" format='C2' groupCaptionTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Dinheiro' type="Sum" format='C2' groupCaptionTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='PIX' type="Sum" format='C2' groupCaptionTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Reaproveitamento' type="Sum" format='C2' groupCaptionTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Transferencia' type="Sum" format='C2' groupCaptionTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Total' type="Sum" format='C2' groupCaptionTemplate={(props: any) => <span>{props.Sum}</span>} />
                                </AggregateColumnsDirective>
                            </AggregateDirective>
                            <AggregateDirective>
                                <AggregateColumnsDirective>
                                    <AggregateColumnDirective field='Boleto' type="Sum" format='C2' footerTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Cartao' type="Sum" format='C2' footerTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Cheque' type="Sum" format='C2' footerTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Deposito' type="Sum" format='C2' footerTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Dinheiro' type="Sum" format='C2' footerTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='PIX' type="Sum" format='C2' footerTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Reaproveitamento' type="Sum" format='C2' footerTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Transferencia' type="Sum" format='C2' footerTemplate={(props: any) => <span>{props.Sum}</span>} />
                                    <AggregateColumnDirective field='Total' type="Sum" format='C2' footerTemplate={(props: any) => <span>{props.Sum}</span>} />
                                </AggregateColumnsDirective>
                            </AggregateDirective>
                        </AggregatesDirective>
                        <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu]} />
                    </GridComponent>
                </div>
            </Card>}
        </Component.Container >
    );
    /* #endregion */
};

export default View;