import React, { useState, useEffect } from 'react';
import json from '../../Auditoria/index.json';
import api from '../../Services/api';
import { Objeto } from '../../../../../Services/Models';
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { PdfHeaderQueryCellInfoEventArgs, PdfQueryCellInfoEventArgs } from '@syncfusion/ej2-react-grids'
import { Button, Card, FormGroup, Elevation, H2, InputGroup, Icon, MenuItem, ButtonGroup } from '@blueprintjs/core';
import { Select, MultiSelect } from "@blueprintjs/select";
import moment from 'moment';
import {
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  GridComponent,
  Page,
  Inject,
  Filter,
  Group,
  ExcelExport,
  PdfExport,
  Toolbar,
  ToolbarItems,
  Grid,
  FilterSettingsModel
} from '@syncfusion/ej2-react-grids';
import './style.css'

L10n.load(json)

function App({ visao }: any) {
  const [getProjectData, setProjectData] = useState();
  const [getData, setData] = useState<Array<Objeto.VendaNoTempo>>([]);
  const [getLoading, setLoading] = useState<boolean>(true)
  const [getTextoProcurado, setTextoProcurado] = useState<string>("");
  const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<{ id: number, descricao: string }>();
  const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<{ id: number, descricao: string }>>([
    {
      id: 1,
      descricao: "Vendas sem cessão"
    },
    {
      id: 2,
      descricao: "Vendas de cessão"
    },
    {
      id: 3,
      descricao: "Vendas distratadas"
    },
    {
      id: 4,
      descricao: "Depósitos"
    }
  ]);
  const PickerCentroDeCusto = Select.ofType<{ id: number, descricao: string }>();
  const [getPricipal, setPrincipal] = useState<number>();
  let preTotalValue: number = 0;
  let preData: Array<Objeto.VendaNoTempo> = [];
  const filterOptions: FilterSettingsModel = {
    ignoreAccent: true,
    immediateModeDelay: 200,
    mode: "Immediate",
    type: 'Excel'
  }
  let sum: number = 0;
  let grid: Grid | null;
  const toolbarOptions: ToolbarItems[] = ['PdfExport', 'CsvExport']
  const pdfQueryCellInfo = (args: PdfQueryCellInfoEventArgs | any) => {
    args.cell.cellHeight = 10;
  }
  const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs | any) => {
    (args.cell as any).row.pdfGrid.repeatHeader = true;
    args.cell.gridRow.pdfGrid.columns.getColumn(0).width = 200;
    args.cell.gridRow.pdfGrid.columns.getColumn(2).textAlign = "Right";
  }
  const toolbarClick = (args: any) => {
    if (grid) {
      if (args.item.id === 'grid_pdfexport') {
        grid.pdfExport({
          fileName: `Vendas De Cessão ${moment(new Date(), true).format("YYYY-MM-DD")}.pdf`,
          pageOrientation: "Landscape",
          header: {
            fromTop: 0,
            height: 130,
            contents: [
              // {
              //   type: 'Text',
              //   value: `${getItemPickerCentroDeCusto?.descricao}`,
              //   position: { x: 390, y: 50 },
              //   style: { textBrushColor: "#000000", fontSize: 20, hAlign: "Right" },
              // },
              // {
              //   position: { x: 0, y: 45 },
              //   size: { height: 40, width: 80 },
              //   src: image,
              //   type: 'Image',
              // },
              {
                type: 'Text',
                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:mm:ss")}`,
                position: { x: 880, y: 55 },
                style: { textBrushColor: "#000000", fontSize: 13 },
              },
            ]
          },
          footer: {
            contents: [
              {
                type: 'Text',
                value: `Powered by DigitalDEV`,
                position: { x: 0, y: 55 },
                style: { textBrushColor: "#000000", fontSize: 10 },
              },
              {
                format: 'Página {$current} de {$total}',
                pageNumberType: "Numeric",
                position: { x: 910, y: 50 },
                style: {
                  fontSize: 13,
                  hAlign: "Right",
                  textBrushColor: '#000000',
                },
                type: 'PageNumber',
              }
            ],
            fromBottom: 0,
            height: 100,
          },
          theme: {
            header: {
              bold: true,
              fontColor: "#000000",
              fontSize: 8,
              // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
            },
            record: {
              bold: false,
              fontColor: "#000000",
              fontSize: 8,
              // font: new PdfTrueTypeFont(adventProFont, 7)
            },
            caption: {
              bold: true,
              fontColor: "#000000",
              fontSize: 8,
              // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
            }
          },
          allowHorizontalOverflow: false,

        });
      }
      if(args.item.id === 'grid_excelexport') {
        grid.excelExport({
          dataSource: getData
        })
      }
      if(args.item.id === 'grid_csvexport') {
        grid.getColumnByField('PRINCIPAL').format = "N2"
        grid.getColumnByField('CORRECAO').format = "N2"
        grid.getColumnByField('JUROS').format = "N2"
        grid.getColumnByField('Total').format = "N2"
        grid.csvExport ({
          dataSource:getData
        })
      }
    }
  }

  useEffect(() => {
    async function componentDidMount() {
      setLoading(true)
      if (visao?.id == 1) {
        await api.get('/VendasNoTempo/Nov').then(async response => {
          await response.data.map((objeto: Objeto.VendaNoTempo, index: number) => {
            preData.push(
              {
                KEY_CDEMP_CDOBR_NUMVEN_BD: objeto.KEY_CDEMP_CDOBR_NUMVEN_BD,
                VENDA: Number(objeto.VENDA),
                PARCELA: Number(objeto.PARCELA),
                PRINCIPAL: Number(objeto.PRINCIPAL),
                CORRECAO: Number(objeto.CORRECAO),
                DataVencimento: moment(objeto.DataVencimento, true).format('DD/MM/YYYY'),
                JUROS: Number(objeto.JUROS),
                Total: Number(objeto.PRINCIPAL) + Number(objeto.CORRECAO) + Number(objeto.JUROS)
              }
            )
          })
          await setProjectData(response.data)
        })
      }
      if (visao?.id == 2) {
        await api.get('/VendasNoTempo/Dez').then(async response => {
          await response.data.map((objeto: Objeto.VendaNoTempo, index: number) => {
            preData.push(
              {
                KEY_CDEMP_CDOBR_NUMVEN_BD: objeto.KEY_CDEMP_CDOBR_NUMVEN_BD,
                VENDA: Number(objeto.VENDA),
                PARCELA: Number(objeto.PARCELA),
                PRINCIPAL: Number(objeto.PRINCIPAL),
                CORRECAO: Number(objeto.CORRECAO),
                DataVencimento: moment(objeto.DataVencimento, true).format('DD/MM/YYYY'),
                JUROS: Number(objeto.JUROS),
                Total: Number(objeto.PRINCIPAL) + Number(objeto.CORRECAO) + Number(objeto.JUROS)
              }
            )
          })
          await setProjectData(response.data)
        })
      }

      await setData(preData)
      await setLoading(false)
    }

    componentDidMount();
  }, [visao])

  return (
    <>
      <div style={{ maxWidth: '100%' }}>
        <GridComponent
          dataSource={getData}
          id='grid'
          ref={g => grid = g}
          allowPaging={true}
          allowFiltering={true}
          pageSettings={{ pageSizes: [10, 20, 30], pageSize: 10 }}
          filterSettings={filterOptions}
          toolbarClick={toolbarClick}
          toolbar={toolbarOptions}
          pdfQueryCellInfo={pdfQueryCellInfo}
          pdfHeaderQueryCellInfo={pdfHeaderQueryCellInfo}
          allowPdfExport={true}
          allowExcelExport={true}
          style={{ opacity: getLoading === true ? "0.5" : "1", pointerEvents: getLoading === true ? "none" : "auto" }}>
          <ColumnsDirective>
            <ColumnDirective field='KEY_CDEMP_CDOBR_NUMVEN_BD' headerText='Chave' width='100' />
            <ColumnDirective field='VENDA' headerText='Venda' width='100' />
            <ColumnDirective field='PARCELA' headerText='Parcela' width='100' />
            <ColumnDirective field='DataVencimento' headerText='Vencimento' width='100' />
            <ColumnDirective field='PRINCIPAL' headerText='Principal' format="C2" width='100' />
            <ColumnDirective field='CORRECAO' headerText='Correção' format="C2" width='100' />
            <ColumnDirective field='JUROS' headerText='Juros' format="C2" width='100' />
            <ColumnDirective field='Total' headerText='Total' format="C2" width='100' />
          </ColumnsDirective>
          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective field="PRINCIPAL" type="Sum" format="C2" footerTemplate={
                  (props: any) => <span>{props.Sum}</span>
                } />
                <AggregateColumnDirective field="CORRECAO" type="Sum" format="C2" footerTemplate={
                  (props: any) => <span>{props.Sum}</span>
                } />
                <AggregateColumnDirective field="JUROS" type="Sum" format="C2" footerTemplate={
                  (props: any) => <span>{props.Sum}</span>
                } />
                <AggregateColumnDirective field="Total" type="Sum" format="C2" footerTemplate={
                  (props: any) => <span>{props.Sum}</span>
                } />
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective>
          <Inject services={[Page, Filter, Group, ExcelExport, PdfExport, ExcelExport, Toolbar, Aggregate]} />
        </GridComponent>
      </div>
    </>
  );
}

export default App;