import {createGlobalStyle} from 'styled-components';
import styled from 'styled-components';

export const Container = styled.div`

padding: 0;
display:block;
width:100%;
height:70px;
background-color:0;
background-color: #ffffff;
opacity:1;
font-family: 'Roboto', sans-serif;
`;
export const HeaderContent = styled.div`
    display: flex;
    align-items:center;
    justify-content:none;
    padding:0 2% 0 10px;
    margin: 0 auto;
    background-color:0;
    width:100%;
    height: 100%;
    color:#808080;
    font-size:12px;


    div.element {
        width:100%;
        height:70px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:0 20px;
        cursor:pointer;
        transition:background-color 0.5s;
        :hover {
            background-color:#E6E6E6;
        }
    }
    input {
        width:300px;
        height:35px;
        background-color:#EDEFF5;
        padding:0 40px;
        border:solid black 0px;
        border-radius:20px;
        background-repeat:no-repeat;
        background-size:20px 20px;
        background-position:15px 8px;
        outline:none;
        
    }
    button {
        background: transparent;
        border: 0;
        margin-left: 0px;
    }
    img {
        height: 40px;
        margin-bottom:0px;
    }
    svg {
        color: #808080;
        width: 20px;
        height: 20px;
    }
`;
export const Elements = styled.div`
    width:100%;
    height:30px;
    background-color:0;

`
export const Profile = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    div {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        line-height: 16px;
    }
    span {
        font-size: 12px;
        color: white;
    }
    strong {
        color: white;
    }
`;
export const Title = styled.div`
    align-items:flex-end;
    color:#f48120;
    font-size:4.5vh;
    margin: 0 auto;
    margin-top: 10px;
    justify-content:flex-start;
    width:73vw;
    white-space:nowrap;
`;
export const Dashboard = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    margin: 0 auto;
    width: 100vw;
`;
export const DashboardItem = styled.div`
    border-radius: 10px;
    box-shadow:5px 5px 15px rgba(0, 0, 0, 0.25);
    display:block;
    height:20vh;
    justify-content:space-around;
    margin-right:10px;
    margin-top:10px;
    padding-top:5px;
    padding-right:5px;
    padding-bottom:5px;
    padding-left:5px;
    width:36vw;
`;
export const DashboardItemTitle = styled.div`
    align-items:flex-start;
    color:#1d2327;
    display:flex;
    font-size:1.5vh;
    justify-content:flex-start;
    height:30%;
    width:100%;
    white-space:nowrap;
`;
export const DashboardItemValue = styled.div`
    align-items:flex-end;
    color:black;
    display:flex;
    font-size:4.5vh;
    justify-content:flex-end;
    height:70%;
    width:100%;
    white-space:nowrap;
`;
export const Menu = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:flex-start;
    margin: 0 auto;
    width: 73vw;
`;
export const MenuItem = styled.div`
    box-shadow:5px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor:pointer;
    display:flex;
    height:150px;
    margin-right:20px;
    margin-top:15px;
    opacity:0.6;
    width:21vw;
    
    transition:0.25s;
    :hover{
        margin-top:5px
    }
`;
export const MenuItemImage = styled.img`
    display:block;
    margin-bottom:25px;
    margin-top:25px;
    object-fit:contain;
    width:100%;
`;
export const MenuItemValue = styled.div`
    align-items:center;
    color:#131313;
    display:flex;
    font-size:1.7vh;
    justify-content:center;
    height:20%;
    margin:auto;
    margin-right:20px;
    text-align:center;
    white-space:nowrap;
`;
export const Activity = styled.div`
    justify-content:flex-start;
    margin: 0 auto;
    width: 73vw;
`;
export const ActivityItems = styled.div`
    display:flex;
    flex-wrap:wrap;
    width: 73vw;
`;
export const ActivityItem = styled.div`
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor:pointer;
    display:flex;
    height:140px;
    margin-left:1vw;
    margin-right:1vw;
    margin-top:2vw;
    width: 34vw;

    transition:0.25s;
    :hover{
        margin-top:1vw;
    }
`;
export const ActivityItemImage = styled.img`
    align-items:center;
    border-radius: 50%;
    padding-top:20px;
    padding-bottom:20px;
    justify-content:center;
`;
export const ActivityItemTitle = styled.div`
    align-items:left;
    color:#f48120;
    font-size: 12pt;
    justify-content:center;
    padding-bottom:25px;
    padding-left:5px;
    padding-right:10px;
    text-align:right
`;
export const ActivityItemDescription = styled.div`
    align-items:left;
    color:#131313;
    font-size: 10pt;
    height:65px;
    margin-bottom: 10px;
    margin-top: auto;
    opacity: 0.6;
    overflow:hidden;
    padding-left:25px;
    padding-right:10px;
    text-align:right;
    text-overflow:ellipsis;
`;