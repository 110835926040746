import Axios, { AxiosResponse } from 'axios';
import Context from '../../context';
import { Objeto } from '../../Models';
import moment from "moment";

class NFSe {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista os anexos referente as notas geradas (PDF e XML)
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} NFSe Informe o "Id" que identifica a NFSe. A lista dos níveis de visualizações cadastradas poderá ser obtido ao executar a requisição "/NFSe/{Token}/{NivelDeVisualizacao}
     **/
    public async Anexos(Token: string, NFSe: number): Promise<AxiosResponse<Array<Objeto.Anexo>>> {
        return await Context.get<Array<{id: string, descricao: string}>>(`/NFSe/Anexos/${Token}/${NFSe}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Lista as notas fiscais geradas por uma empresa específica
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     **/
    public async ConsultarPorEmpresa(Token: string, Empresa: number, NivelDeVisualizacao: string): Promise<AxiosResponse<Array<Objeto.NFSe>>> {
        return await Context.get<Array<Objeto.NFSe>>(`/NFSe/${Token}/${Empresa}?NivelDeVisualizacao=${NivelDeVisualizacao}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception.response; });
    };
    /* Sobre esta função *//**
     * Lista as notas fiscais geradas por uma empresa e centro de custo específico
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     **/
    public async ConsultarPorCentroDeCusto(Token: string, Empresa: number, CentroDeCusto: string, NivelDeVisualizacao: string): Promise<AxiosResponse<Array<Objeto.NFSe>>> {
        return await Context.get<Array<Objeto.NFSe>>(`/NFSe/${Token}/${Empresa}/${CentroDeCusto}?NivelDeVisualizacao=${NivelDeVisualizacao}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception.response; });
    };
    /* Sobre esta função *//**
     * Retorna os níveis de visualização
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async NiveisDeVisualizacoes(Token: string): Promise<AxiosResponse<Array<{id: string, descricao: string}>>> {
        return await Context.get<Array<{id: string, descricao: string}>>(`/NFSe/NiveisDeVisualizacoes/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastrar nota(s) fiscal(is)
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {Boolean | undefined} Definitivo Informe se as notas fiscais serão habilitadas para emissão ou apenas conferência prévia
     * @param {Date | undefined} Validade Informe a data de validade destas notas (requisito obrigatório quando estas não forem definitivas)
     * @param {Array<Objeto.NFSe>} NFS Informe as notas fiscais a serem geradas
     **/
    public async Post(Token: string, Empresa: number, CentroDeCusto: string, Definitivo: Boolean | undefined, Validade: Date | undefined, NFS: Array<Objeto.NFSe>): Promise<AxiosResponse<Array<Objeto.NFSe>>> {
        return await Context.post<any>(`/NFSe/${Token}/${Empresa}/${CentroDeCusto}?Definitivo=${Definitivo ?? true}${Validade ? `&Validade=${moment(Validade).format("yyyy-MM-DD")}` : ""}`, NFS)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception.response; });
    };
    /* Sobre esta função *//**
     * Gerar nota(s) fiscal(is)
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Array<Objeto.NFSe>} NFS Informe as notas fiscais a serem geradas
     **/
    public async GerarNFSe(Token: string, NFS: Array<Objeto.NFSe>): Promise<AxiosResponse<Array<Objeto.NFSe>>> {
        return await Context.post<any>(`/NFSe/GerarNFSe/${Token}`, NFS)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Gera titulo para pagamento conforme as NFSe informadas
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {Array<Objeto.NFSe>} NFS Informe as notas fiscais a serem geradas
     **/
    public async GerarPagamento(Token: string, Empresa: number, CentroDeCusto: string, NFS: Array<Objeto.NFSe>): Promise<AxiosResponse<Array<Objeto.NFSe>>> {
        return await Context.post<any>(`/NFSe/GerarPagamento/${Token}/${Empresa}/${CentroDeCusto}`, NFS)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception.response; });
    };
    /* Sobre esta função *//**
     * Gerar objeto a partir da planilha importada (apenas sendo aceito o formato de arquivo xls de acordo com modelo específico)
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param { Nome: string, Conteudo: File | null | undefined } Arquivo Insira um objeto com todos os dados da arquivo
     **/
    public async InterpretarPlanilha(Token: string, Arquivo: { Nome: string, Conteudo: File | null | undefined }): Promise<AxiosResponse<{pdf: string, tabelaOrignal: Array<any>, nfs: Array<Objeto.NFSe> }>> {
        let File = new FormData();
        File.append("Arquivo", new Blob([Arquivo.Conteudo || {} as File], { type: Arquivo.Conteudo?.type }), Arquivo.Nome);
        return await Context.post<any>(`/NFSe/InterpretarPlanilha/${Token}`, File, { headers: { "content-type": "multipart/form-data" } })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar nota(s) fiscal(is)
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} NFS Informe o código da NFS obtido ao executar a requisição "/NFSeController/{Empresa}/{CentroDeCusto}/{NivelDeVisualizacao}"
     **/
    public async Delete(Token: string, NFS: number): Promise<AxiosResponse<Array<Objeto.NFSe>>> {
        return await Context.delete<Array<Objeto.NFSe>>(`/NFSe/${Token}/${NFS}`, )
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception.response; });
    };
    /* #endregion */

    /* #region  PUT Controller  */
    /* #endregion */
};

export default new NFSe();