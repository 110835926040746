import React, { memo, useEffect, useState, useRef } from 'react';
import { Moeda, Numero } from '../../../Themes/TextFormat';
import * as Image from '../../../Assets';
import { MapContainer, Marker, Popup, TileLayer, Polygon, useMapEvents, MapConsumer } from 'react-leaflet';
import { Button, Icon, MenuItem } from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { Objeto } from '../../../Services/Models';
import './leaflet.css';
import {WidthCheck as ContextWidthCheck } from '../../../Context';

function App({ polygons, points, areas, visao, tiposInadimplencia, refresh }: any) {
  const [getExibirLegenda, setExibirLegenda] = useState<boolean>(false);
  const [getLegendaOption, setLegendaOption] = useState<number>(0);
  const [getLocalData, setLocalData] = useState<any>();
  const [getListaFaixa, setListaFaixa] = useState<any>();
  const [getListaCategoria, setListaCategoria] = useState<any>();
  const [getRuaSize, setRuaSize] = useState<Array<number>>([120, 80, 10, 0]);
  const [getMarkersSize, setMarkersSize] = useState<Array<number>>([16, 12, 6, 0.7]);
  const [getCenter, setCenter] = useState<[number, number]>(polygons.center)
  const [getConsumer, setConsumer] = useState<[boolean, [number, number]]>([false, [0, 0]]);
  const [getTileLayer, setTileLayer] = useState<number>(0);
  const [Width, setWidth] = useState<number>()

  const [getItemPickerQuadra, setItemPickerQuadra] = useState<Objeto.QuadraPicker>();
  const [getListaPickerQuadra, setListaPickerQuadra] = useState<Array<Objeto.QuadraPicker>>([]);
  const PickerQuadra = Select.ofType<Objeto.QuadraPicker>();

  window.onresize = function(){
    ContextWidthCheck.setContext({width: document.body.clientWidth}) 
    setWidth(document.body.clientWidth)
  }


  useEffect(() => {
    async function componentDidMount() {
      await setListaFaixa(await getFaixaValor());
      await setListaCategoria(await getCategoria());
      await setListaPickerQuadra(await getQuadras());
    }
    componentDidMount();
  }, [refresh])

  function MyComponent() {
    const map = useMapEvents({
      click: (e:any) => {
        console.log(e.latlng)
      },
      zoomend: (e: any) => {
        setMarkersSize([(21 + 10 * (e.target._zoom - 18)), (15 + 5 * (e.target._zoom - 18)), (8 + 2 * (e.target._zoom - 18)), e.target._zoom <= 16 ? 0 : (1 + 0.2 * (e.target._zoom - 18))])
        setRuaSize([(100 + 40 * (e.target._zoom - 18)), (80 + 20 * (e.target._zoom - 18)), (10 + 2 * (e.target._zoom - 18)), e.target._zoom !== 18 ? 0 : (1 + 0.2 * (e.target._zoom - 18))])
      }
    })
    return null
  }
  function Calcularcenario(status: number | string | undefined, tipo: number) {
    let total = 0;
    switch (tipo) {
      case 0:
        polygons?.lista.map((quadra: any, quadraIndex: any) => {
          quadra.lotes.map((lote: any, loteIndex: any) => {
            if ((visao === 0 ? lote.dadosUau.status : visao === 2 ? lote.faixaValor.faixa : visao === 1 ? lote.dadosUau.statusDaInadimplencia : lote.dadosUau.Categoria_de_preco) === status) {
              total += lote.dadosUau.area;
            }
          })
        })
        break;
      case 1:
        polygons?.lista.map((quadra: any, quadraIndex: any) => {
          quadra.lotes.map((lote: any, loteIndex: any) => {
            if ((visao === 0 ? lote.dadosUau.status : visao === 2 ? lote.faixaValor.faixa : visao === 1 ? lote.dadosUau.statusDaInadimplencia : lote.dadosUau.Categoria_de_preco) === status) {
              total += 1;
            }
          })
        })
        break;
      case 2:
        polygons?.lista.map((quadra: any, quadraIndex: any) => {
          quadra.lotes.map((lote: any, loteIndex: any) => {
            if ((visao === 0 ? lote.dadosUau.status : visao === 2 ? lote.faixaValor.faixa : visao === 1 ? lote.dadosUau.statusDaInadimplencia : lote.dadosUau.Categoria_de_preco) === status) {
              total += lote.dadosUau.valorAVista;
            }
          })
        })
        break;
      case 3:
        polygons?.lista.map((quadra: any, quadraIndex: any) => {
          quadra.lotes.map((lote: any, loteIndex: any) => {
            if ((visao === 0 ? lote.dadosUau.status : visao === 2 ? lote.faixaValor.faixa : visao === 1 ? lote.dadosUau.statusDaInadimplencia : lote.dadosUau.Categoria_de_preco) == status) {
              total += lote.dadosUau.valorAVista;
            }
          })
        })
        break;
    }

    return total;
  }

  async function getFaixaValor() {
    const faixas: any = [[110000, 200000], [105000, 110000], [100000, 105000], [95000, 100000], [90000, 95000], [85000, 90000], [80000, 85000]];
    const colors: any = ["#ff0000", "#FF5721", "#FF9021", "#FFB821", "#FFFC21", "#ABFF21", "#4DFF21", "#21FF5A", "#21FFC2", "#21EBFF", "#2161FF"]
    const faixasValores: any = [];
    await polygons.lista.map(async (quadra: any) => {
      await quadra.lotes.map((lote: any, loteIndex: any) => {
        lote["faixaValor"] = {
          faixa: [],
          cor: "white"
        }
      })
    })
    await faixas.map(async (faixa: any, faixaIndex: number) => {
      faixasValores.push({
        faixa: faixa,
        lotes: [],
        cor: colors[faixaIndex]
      })
      await polygons.lista.map(async (quadra: any) => {
        await quadra.lotes.map((lote: any, loteIndex: any) => {
          if (lote.dadosUau.valorAVista > faixa[0] && lote.dadosUau.valorAVista < faixa[1]) {
            faixasValores[faixaIndex].lotes.push(lote);
            lote.faixaValor = {
              faixa: faixa,
              cor: colors[faixaIndex]
            }
          }
        })
      })
    })
    await setLocalData(polygons)
    return faixasValores
  }
  async function getCategoria() {
    const categoriasDesc: Array<string> = [];
    const categorias: any = [];
    const colors: any = ["#DDA50D", "#FFEEBD", "#EE9026", "#B85C5C", "#FFFC21", "#ABFF21", "#4DFF21", "#21FF5A", "#21FFC2", "#21EBFF", "#2161FF", "#a621ff", "#f821ff", "#8f2a86", "#292253", "#225353", "#22532a", "#4e5322", "#8f4f4f", "#709645"]
    let color = 0;
    await polygons.lista.map((quadra: any, quadraIndex: number) => {
      quadra.lotes.map((lote: any, loteIndex: any) => {
        if (categoriasDesc[0]) {
          if (!categoriasDesc.find((Item: any) => Item === lote.dadosUau.Categoria_de_preco) && lote.dadosUau.Categoria_de_preco !== null) {
            categoriasDesc.push(lote.dadosUau.Categoria_de_preco)
            categorias.push({
              Categoria_de_preco: lote.dadosUau.Categoria_de_preco,
              corCategoria: colors[color]
            })
            color++
          }
        }
        else {
          categoriasDesc.push(lote.dadosUau.Categoria_de_preco)
          categorias.push({
            Categoria_de_preco: lote.dadosUau.Categoria_de_preco,
            corCategoria: colors[0]
          })
          color++
        }
      })
    })
    await polygons.lista.map((quadra: any) => {
      quadra.lotes.map((lote: any) => {
        categorias.map((categoria: any) => {
          if (categoria.Categoria_de_preco === lote.dadosUau.Categoria_de_preco) {
            lote['corCategoria'] = categoria.corCategoria
          }
        })
      })
    })
    await setLocalData(polygons);
    return categorias
  }
  async function getQuadras() {
    const quadras: any = [];
    polygons.lista.map((quadra: any) => {
      quadras.push({
        quadra: quadra.quadra,
        center: quadra.center
      })
    })
    return quadras
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* DISPONIBILIDADE */}
      {polygons &&
        <div style={{ height: "100%" }} id="mapid">
          <MapContainer zoomDelta={0.1} zoomSnap={0} attributionControl={false} center={getCenter} minZoom={15} zoom={17}>
            {getTileLayer === 0 && <TileLayer
              ref={(e: any) => null}
              //url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy;'
            />}
            {getTileLayer === 1 && <TileLayer
              ref={(e: any) => null}
              url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy;'
            />}
            {getConsumer[0] &&
              <MapConsumer>
                {(map) => {
                  map.flyTo([getConsumer[1][0], getConsumer[1][1]], 20, {
                    noMoveStart: true,
                    duration: 0
                  })
                  //map.setZoom(20)
                  setConsumer([false, [0, 0]])
                  return null
                }}
              </MapConsumer>}
            {/* Opções */}
            <div className="cenario" style={getExibirLegenda === true ? { opacity: "1", width: ContextWidthCheck.getContext().width <= 660 ? "6cm" : "12cm", height: "auto", position: 'relative', zIndex: 10000, boxShadow: "0 0 5px gray", borderRadius: "5px", backgroundColor: "#ffffff", left: "1.3cm", top: "0.3cm" } : { width: "4cm", height: "auto", position: "relative", zIndex: 10000, boxShadow: "0 0 5px gray", borderRadius: "5px", backgroundColor: "#ffffff", left: "1.3cm", top: "0.3cm" }}>
              {/* Títulos */}
              <div style={{ display: "flex", alignItems: "center", justifyContent: getExibirLegenda ? "flex-start" : "center", width: "100%", background: "0", margin: "0 auto", height: "40px", borderBottom: getExibirLegenda ? "1px solid #C2C2C2" : "0px" }}>
                {getExibirLegenda && <div onClick={() => setLegendaOption(0)} style={{ cursor: "pointer", background: "0", paddingTop: "12px", width: "70px", height: "100%", borderTopLeftRadius: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                  <span>Legenda</span>
                  <div style={{ background: getLegendaOption === 0 ? "black" : "unset", width: "100%", height: "2px" }}></div>
                </div>}
                {getExibirLegenda && <div onClick={() => setLegendaOption(2)} style={{ cursor: "pointer", background: "0", paddingTop: "12px", width: "70px", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                  <span>Visualização</span>
                  <div style={{ background: getLegendaOption === 2 ? "black" : "unset", width: "100%", height: "2px" }}></div>
                </div>}
                {getExibirLegenda && <div onClick={() => setLegendaOption(1)} style={{ cursor: "pointer", background: "0", paddingTop: "12px", width: "70px", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                  <span>Filtro</span>
                  <div style={{ background: getLegendaOption === 1 ? "black" : "unset", width: "100%", height: "2px" }}></div>
                </div>}
                {!getExibirLegenda && <span style={{ marginLeft: "50px" }}>Opções</span>}
                <Icon style={{ marginLeft: "auto", marginRight: "10px", cursor: "pointer" }} onClick={() => { getExibirLegenda === false ? setExibirLegenda(true) : setExibirLegenda(false) }} icon="send-to-map" iconSize={20} />
              </div>
              {/* Legenda */}

              {/* ====================DISPONIBILIDADE==================== */}
              {getExibirLegenda === true && getLegendaOption === 0 && visao === 0 &&
                <div style={{ display: "flex", flexDirection: "column", width: "calc(100%)", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
                  {/* Colunas */}
                  <div style={{ color: "#555B53", display: "flex", borderBottom: "1px solid #C2C2C2", borderRight: "0px solid black", width: "100%", height: "40px" }}>
                    <div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "calc(31%)", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Status</strong></div>
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "calc(22% - 0.5px)", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Área(m²)</strong></div>
                    <div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "calc(15% + 0px)", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Qtd</strong></div>
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", alignItems: "center", width: "32%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Valor(R$)</strong></div>
                  </div>
                  {/* Info */}
                  <div style={{ display: "flex", width: "calc(100%)", color: "#2E372B" }}>
                    {/* Status */}
                    <div style={{width: ContextWidthCheck.getContext().width <= 660 ? "70%" : "31%", borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", paddingTop: "0px", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "green", marginRight: "10px" }}></div>
                        <div>Disponível</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "red", marginRight: "10px" }}></div>
                        <div>Vendido</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "yellow", marginRight: "10px" }}></div>
                        <div>Reservado</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "darkorange", marginRight: "10px" }}></div>
                        <div>Proposta</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "orange", marginRight: "10px" }}></div>
                        <div>Quitado</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "gray", marginRight: "10px" }}></div>
                        <div>Escriturado</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "black", marginRight: "10px" }}></div>
                        <div>Em Venda</div>
                      </div>
                      <div style={{ display: "flex", fontSize: "11px", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "darkcyan", marginRight: "10px" }}></div>
                        <div>Suspenso Venda</div>
                      </div>
                      <div style={{ display: "flex", fontSize: "11px", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "blue", marginRight: "10px" }}></div>
                        <div>Fora de Venda</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "pink", marginRight: "10px" }}></div>
                        <div>Em Acerto</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "0px solid #E8E8E8" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "darkblue", marginRight: "10px" }}></div>
                        <div>Dação</div>
                      </div>
                    </div>
                    {/* Área */}
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex" ,flexDirection: ContextWidthCheck.getContext().width <= 660 ? "initial" : "column", borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", paddingTop: "0px", width: "22%", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(0, 0)).toFixed(2)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(1, 0)).toFixed(2)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(2, 0)).toFixed(2)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(3, 0)).toFixed(2)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(4, 0)).toFixed(2)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(5, 0)).toFixed(2)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(6, 0)).toFixed(2)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(7, 0)).toFixed(2)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(8, 0)).toFixed(2)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(9, 0)).toFixed(2)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "0px solid #E8E8E8" }}>
                        <div>{(Calcularcenario(10, 0)).toFixed(2)}</div>
                      </div>
                    </div>
                    {/* Quantidade */}
                    <div style={{width: ContextWidthCheck.getContext().width <= 660 ? "30%" : "15%", borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", paddingTop: "0px", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Calcularcenario(0, 1)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Calcularcenario(1, 1)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Calcularcenario(2, 1)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Calcularcenario(3, 1)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Calcularcenario(4, 1)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Calcularcenario(5, 1)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Calcularcenario(6, 1)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Calcularcenario(7, 1)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Calcularcenario(8, 1)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Calcularcenario(9, 1)}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "0px solid #E8E8E8" }}>
                        <div>{Calcularcenario(10, 1)}</div>
                      </div>
                    </div>
                    {/* Valor */}
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex",flexDirection: ContextWidthCheck.getContext().width <= 660 ? "initial" : "column", borderBottom: "0px solid black", borderRight: "0px solid #E1E1E1", paddingTop: "0px", width: "32%", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ display: "flex", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(0, 2))}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "calc(100% + 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(1, 2))}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(2, 2))}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(3, 2))}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(4, 2))}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(5, 2))}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(6, 2))}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(7, 2))}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(8, 2))}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(9, 2))}</div>
                      </div>
                      <div style={{ display: "flex", backgroundColor: "0", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "0px solid #E8E8E8" }}>
                        <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(10, 2))}</div>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {/* ====================INADIMPLÊNCIA==================== */}
              {getExibirLegenda == true && getLegendaOption === 0 && visao == 1 &&
                <div style={{ display: "flex", flexDirection: "column", width: "calc(100%)", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
                  {/* Colunas */}
                  <div style={{ color: "#555B53", display: "flex", borderBottom: "1px solid #C2C2C2", borderRight: "0px solid black", width: "100%", height: "40px" }}>
                    <div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "31%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Status</strong></div>
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "22%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Área(m²)</strong></div>
                    <div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "15%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Qtd</strong></div>
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", alignItems: "center", width: "32%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Valor(R$)</strong></div>
                  </div>
                  {/* Info */}
                  <div style={{ display: "flex", width: "calc(100%)", color: "#2E372B" }}>
                    {/* Status */}
                    <div style={{ width: ContextWidthCheck.getContext().width <= 660 ? "70%" : "31%", borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", fontSize: "10.5px", fontWeight: 600 }}>
                      {polygons?.tiposInadimplencia.map((Item: any) => <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ minWidth: "20px", minHeight: "20px", borderRadius: "50%", backgroundColor: Item.cor, marginRight: "10px" }}></div>
                        <div>{Item.descricao}</div>
                      </div>)}
                    </div>
                    {/* Área */}
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex" ,flexDirection: ContextWidthCheck.getContext().width <= 660 ? "initial" : "column", borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", paddingTop: "0px", width: "22%", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ borderLeft: "1px solid #E1E1E1", borderRight: "1px solid #E1E1E1", paddingTop: "0px", width: "100%", fontSize: "12px", fontWeight: 600 }}>
                        {polygons?.tiposInadimplencia.map((Item: any) => <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                          <div>{Numero.FormatarTextoParaInteiro(Calcularcenario(Item.descricao, 0))}</div>
                        </div>)}
                      </div>
                    </div>
                    {/* Quantidade */}
                    <div style={{ width: ContextWidthCheck.getContext().width <= 660 ? "30%" : "15%", borderBottom: "0px solid black", paddingTop: "0px", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", paddingTop: "0px", width: "100%", fontSize: "12px", fontWeight: 600 }}>
                        {polygons?.tiposInadimplencia.map((Item: any) => <div>
                          <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                            <div>{Calcularcenario(Item.descricao, 1)}</div>
                          </div>
                        </div>)}
                      </div>
                    </div>
                    {/* Valor */}
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex",flexDirection: ContextWidthCheck.getContext().width <= 660 ? "initial" : "column", borderBottom: "0px solid black", borderRight: "0px solid #E1E1E1", paddingTop: "0px", width: "32%", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ borderBottom: "0px solid black", borderRight: "0px solid #E1E1E1", paddingTop: "0px", width: "100%", fontSize: "12px", fontWeight: 600 }}>
                        {polygons?.tiposInadimplencia.map((Item: any) => <div style={{ display: "flex", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                          <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(Item.descricao, 2))}</div>
                        </div>)}
                      </div>
                    </div>
                  </div>
                </div>
              }

              {/* ====================FAIXA DE PREÇOS==================== */}
              {getExibirLegenda == true && getLegendaOption === 0 && visao == 2 &&
                <div style={{ display: "flex", flexDirection: "column", width: "calc(100%)", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
                  {/* Colunas */}
                  <div style={{ color: "#555B53", display: "flex", borderBottom: "1px solid #C2C2C2", borderRight: "0px solid black", width: "100%", height: "40px" }}>
                    <div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "31%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Status</strong></div>
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "22%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Área(m²)</strong></div>
                    <div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "15%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Qtd</strong></div>
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", alignItems: "center", width: "32%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Valor(R$)</strong></div>
                  </div>
                  {/* Info */}
                  <div style={{ display: "flex", width: "calc(100%)", fontSize: "12px", fontWeight: "unset", color: "#2E372B" }}>
                    {/* Status */}
                    <div style={{ width: ContextWidthCheck.getContext().width <= 660 ? "70%" : "31%", borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", fontSize: "12px", fontWeight: 600 }}>
                      {getListaFaixa.map((Item: any) => <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ minWidth: "20px", minHeight: "20px", borderRadius: "50%", backgroundColor: Item.cor, marginRight: "10px" }}></div>
                        <div>{`${Moeda.FormatarTexto(Item.faixa[0])} a ${/*(Item.faixa[1] > 100000) ? "∞" : */Moeda.FormatarTexto(Item.faixa[1])}`}</div>
                      </div>)}
                    </div>
                    {/* Área */}
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex" ,flexDirection: ContextWidthCheck.getContext().width <= 660 ? "initial" : "column", borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", paddingTop: "0px", width: "22%", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ borderLeft: "1px solid #E1E1E1", borderRight: "1px solid #E1E1E1", paddingTop: "0px", width: "100%" }}>
                        {getListaFaixa.map((Item: any) => <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                          <div>{Numero.FormatarTextoParaInteiro(Calcularcenario(Item.faixa, 0))}</div>
                        </div>)}
                      </div>
                    </div>
                    {/* Quantidade */}
                    <div style={{ width: ContextWidthCheck.getContext().width <= 660 ? "30%" : "15%", borderBottom: "0px solid black", paddingTop: "0px", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", paddingTop: "0px", width: "100%" }}>
                        {getListaFaixa.map((Item: any) => <div>
                          <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                            <div>{Calcularcenario(Item.faixa, 1)}</div>
                          </div>
                        </div>)}
                      </div>
                    </div>
                    {/* Valor */}
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex",flexDirection: ContextWidthCheck.getContext().width <= 660 ? "initial" : "column", borderBottom: "0px solid black", borderRight: "0px solid #E1E1E1", paddingTop: "0px", width: "32%", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ borderBottom: "0px solid black", borderRight: "0px solid #E1E1E1", paddingTop: "0px", width: "100%" }}>
                        {getListaFaixa.map((Item: any) => <div style={{ display: "flex", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                          <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(Item.faixa, 2))}</div>
                        </div>)}
                      </div>
                    </div>
                  </div>
                </div>
              }
              {/* ====================CATEGORIAS==================== */}
              {getExibirLegenda == true && getLegendaOption === 0 && visao == 3 &&
                <div style={{ display: "flex", flexDirection: "column", width: "calc(100%)", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
                  {/* Colunas */}
                  <div style={{ color: "#555B53", display: "flex", borderBottom: "1px solid #C2C2C2", borderRight: "0px solid black", width: "100%", height: "40px" }}>
                    <div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "31%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Status</strong></div>
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "22%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Área(m²)</strong></div>
                    <div style={{ display: "flex", alignItems: "center", borderRight: "0px solid #E1E1E1", width: "15%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Qtd</strong></div>
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", alignItems: "center", width: "32%", height: "100%" }}><strong style={{ marginLeft: "10px" }}>Valor(R$)</strong></div>
                  </div>
                  {/* Info */}
                  <div style={{ display: "flex", width: "calc(100%)", fontSize: "12px", fontWeight: "unset", color: "#2E372B" }}>
                    {/* Status */}
                    <div style={{ width: ContextWidthCheck.getContext().width <= 660 ? "70%" : "31%", borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", fontSize: "12px", fontWeight: 600 }}>
                      {getListaCategoria.map((Item: any) => <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                        <div style={{ minWidth: "20px", minHeight: "20px", borderRadius: "50%", backgroundColor: Item.corCategoria, marginRight: "10px" }}></div>
                        <div>{Item.Categoria_de_preco}</div>
                      </div>)}
                    </div>
                    {/* Área */}
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex" ,flexDirection: ContextWidthCheck.getContext().width <= 660 ? "initial" : "column", borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", paddingTop: "0px", width: "22%", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ borderLeft: "1px solid #E1E1E1", borderRight: "1px solid #E1E1E1", paddingTop: "0px", width: "100%" }}>
                        {getListaCategoria.map((Item: any) => <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                          <div>{Numero.FormatarTextoParaInteiro(Calcularcenario(Item.Categoria_de_preco, 0))}</div>
                        </div>)}
                      </div>
                    </div>
                    {/* Quantidade */}
                    <div style={{ width: ContextWidthCheck.getContext().width <= 660 ? "30%" : "15%", borderBottom: "0px solid black", paddingTop: "0px", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ borderBottom: "0px solid black", borderRight: "1px solid #E1E1E1", paddingTop: "0px", width: "100%" }}>
                        {getListaCategoria.map((Item: any) => <div>
                          <div style={{ display: "flex", backgroundColor: "0", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                            <div>{Calcularcenario(Item.Categoria_de_preco, 1)}</div>
                          </div>
                        </div>)}
                      </div>
                    </div>
                    {/* Valor */}
                    <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex",flexDirection: ContextWidthCheck.getContext().width <= 660 ? "initial" : "column", borderBottom: "0px solid black", borderRight: "0px solid #E1E1E1", paddingTop: "0px", width: "32%", fontSize: "12px", fontWeight: 600 }}>
                      <div style={{ borderBottom: "0px solid black", borderRight: "0px solid #E1E1E1", paddingTop: "0px", width: "100%" }}>
                        {getListaCategoria.map((Item: any) => <div style={{ display: "flex", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: "1px solid #E8E8E8" }}>
                          <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(Item.Categoria_de_preco, 2))}</div>
                        </div>)}
                      </div>
                    </div>
                  </div>
                </div>
              }
              {/* Filtro */}
              {getExibirLegenda && getLegendaOption === 1 &&
                <div style={{ width: "100%" }}>
                  <div onClick={() => {/*setConsumer(true)*/ }} style={{ width: "200px", height: "80px", backgroundColor: "unset", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                    <PickerQuadra popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerQuadra} onItemSelect={() => { }} noResults={<MenuItem style={{ position: "relative", zIndex: 30000 }} disabled={true} text="Sem resultados disponíveis" />}
                      itemRenderer={(Item: Objeto.QuadraPicker) => <MenuItem style={{ position: "relative", zIndex: 30000 }} onClick={async () => {
                        setConsumer([true, [Item.center.latitude, Item.center.longitude]])
                      }} text={Item.quadra} />} itemPredicate={(Texto: string, Item: Objeto.QuadraPicker) => { return (Item.quadra ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                      <Button className="selector" style={{ background: "#e6e6e6", position: "relative", zIndex: 30000, color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={"Quadra"} rightIcon="double-caret-vertical" />
                    </PickerQuadra>
                  </div>
                </div>
              }
              {/* Visualização */}
              {getExibirLegenda && getLegendaOption === 2 &&
                <div style={{ width: "100%" }}>
                  <div onClick={() => {/*setConsumer(true)*/ }} style={{ width: "100%", height: "170px", backgroundColor: "unset", padding: ContextWidthCheck.getContext().width <= 660 ? "30px 0px" : "0px 80px", display: "flex",flexDirection: ContextWidthCheck.getContext().width <= 660 ? "column" : "row", alignItems: "center", flexWrap: "wrap", justifyContent: "space-between", cursor: "pointer" }}>
                    <div onClick={() => setTileLayer(0)} style={{ background: getTileLayer === 0 ? "#f3f3f3" : "unset", cursor: "pointer", borderRadius: "5px", color: "#696969", fontWeight: 600, height: "120px", width: "100px", padding: "10px 0 5px 0", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                      <div style={{ height: ContextWidthCheck.getContext().width <= 660 ? "60px" : "80px", borderRadius: "5px", boxShadow: "0 0 10px gray", width: ContextWidthCheck.getContext().width <= 660 ? "60px" : "80px", backgroundImage: `url(${Image.FundoOpenStreet})`, backgroundRepeat: "no-repeat", backgroundSize: "250px", backgroundPositionY: "-10px", color: "#001447", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "40px" }}></div>
                      <span>GPS</span>
                    </div>
                    <div onClick={() => setTileLayer(1)} style={{ background: getTileLayer === 1 ? "#f3f3f3" : "unset", cursor: "pointer", borderRadius: "5px", color: "#696969", fontWeight: 600, height: "120px", width: "100px", padding: "10px 0 5px 0", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                      <div style={{ height: ContextWidthCheck.getContext().width <= 660 ? "60px" : "80px", borderRadius: "5px", boxShadow: "0 0 10px gray", width: ContextWidthCheck.getContext().width <= 660 ? "60px" : "80px", backgroundImage: `url(${Image.FundoSatelite})`, backgroundRepeat: "no-repeat", backgroundSize: "250px", backgroundPositionY: "-10px", color: "white", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "40px" }}></div>
                      <span>Satélite</span>
                    </div>
                    {/* <div style={{ background: "unset", cursor: "pointer", borderRadius: "5px", color: "#696969", fontWeight: 600, height: "120px", width: "100px", padding: "10px 0 5px 0", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                      <div style={{ height: "80px", borderRadius: "5px", boxShadow: "0 0 10px gray", marginBottom: "0%", width: "80px", backgroundImage: `url(${Image.FundoLoteamento})`, backgroundRepeat: "no-repeat", backgroundSize: "250px", backgroundPositionY: "-10px", color: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "40px" }}></div>
                      <span>Loteamento</span>
                    </div> */}
                  </div>
                </div>
              }
            </div>

            {/* POINTS */}
            {/* QUADRA */}
            {polygons && refresh && polygons.lista.map((quadra: any, quadraIndex: any) => {
              return <Marker interactive={false} icon={divIcon({
                html: renderToStaticMarkup(
                  <div style={{ backgroundColor: "#ffc65c", pointerEvents: "none", opacity: `${getMarkersSize[3]}`, display: "flex", alignItems: "center", justifyContent: "center", color: "black", borderRadius: "50%", width: `${getMarkersSize[0]}px`, height: `${getMarkersSize[0]}px`, border: "0.001cm black solid" }}>
                    <span style={{ fontWeight: "bold", pointerEvents: "none", fontSize: `${getMarkersSize[2]}px`, }}>{quadra.quadra}</span>
                  </div>
                )
              })} position={[quadra.center.latitude, quadra.center.longitude]} />
            })}
            {/* LOTE */}
            {polygons && refresh && polygons.lista.map((quadra: any, quadraIndex: any) => {
              return quadra.lotes.map((lote: any, loteIndex: any) => {
                return <Marker interactive={false} icon={divIcon({
                  html: renderToStaticMarkup(
                    <div style={{ backgroundColor: "#FFFFFF", pointerEvents: "none", opacity: `${getMarkersSize[3]}`, display: "flex", alignItems: "center", justifyContent: "center", color: "black", borderRadius: "50%", width: `${getMarkersSize[1]}px`, height: `${getMarkersSize[1]}px`, border: "0.001cm black solid" }}>
                      <span style={{ fontWeight: "bold", pointerEvents: "none", fontSize: `${getMarkersSize[2]}px`, }}>{lote.lote}</span>
                    </div>
                  )
                })} position={[lote.center.latitude, lote.center.longitude]} />
              })
            })}
            {/* Polygons */}
            {/* Disponibilidade */}
            {polygons && refresh && visao === 0 && polygons.lista.map((quadra: any, quadraIndex: any) => {
              return quadra.lotes.map((lote: any, loteIndex: any) => {
                const positions: any = [];
                lote.coordinates.map((array: any) => positions.push([array.longitude, array.latitude]))
                return (
                  <Polygon
                    key={`${quadra.quadra}${lote.lote}`}
                    opacity={1}
                    fillOpacity={0.5}
                    fillColor={lote.dadosUau.status === 0 ? ( (lote.dadosUau.Categoria_de_preco == "62" || lote.dadosUau.Categoria_de_preco == "67") ? "#044b22" : "green") :
                      lote.dadosUau.status === 1 ? "red" :
                        lote.dadosUau.status === 2 ? "yellow" :
                          lote.dadosUau.status === 3 ? "darkorange" :
                            lote.dadosUau.status === 4 ? "red" :
                              lote.dadosUau.status === 5 ? "gray" :
                                lote.dadosUau.status === 6 ? "black" :
                                  lote.dadosUau.status === 7 ? "darkcyan" :
                                    lote.dadosUau.status === 8 ? "transparent" :
                                      lote.dadosUau.status === 9 ? "pink" :
                                        lote.dadosUau.status === 10 ? "darkblue" : "white"}
                    weight={1}
                    color={lote.dadosUau.status === 0 ? ( (lote.dadosUau.Categoria_de_preco == "62" || lote.dadosUau.Categoria_de_preco == "67") ? "#044b22" : "green") :
                    lote.dadosUau.status === 1 ? "red" :
                      lote.dadosUau.status === 2 ? "yellow" :
                        lote.dadosUau.status === 3 ? "darkorange" :
                          lote.dadosUau.status === 4 ? "red" :
                            lote.dadosUau.status === 5 ? "gray" :
                              lote.dadosUau.status === 6 ? "black" :
                                lote.dadosUau.status === 7 ? "darkcyan" :
                                  lote.dadosUau.status === 8 ? "#BCBCBC" :
                                    lote.dadosUau.status === 9 ? "pink" :
                                      lote.dadosUau.status === 10 ? "darkblue" : "white"}
                    positions={positions}
                    eventHandlers={{
                      click: () => { }
                    }}>
                    <Popup minWidth={220}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", background: "0", height: "120px", fontWeight: "bold" }}>
                        <div style={{ display: "flex", alignItems: "center", background: "0", marginBottom: "5px" }}>
                          <Icon icon="map-marker" color={"#001346"} iconSize={20} />
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{lote.dadosUau.descricao}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                          <Icon icon="control" color={"#1c294e"} />
                          <span style={{ marginLeft: "15px", color: "#999999" }}>Área: </span>
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{lote.dadosUau.area} m²</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                          <Icon icon="dollar" color={"#222e50"} />
                          <span style={{ marginLeft: "15px", color: "#999999" }}>Valor: </span>
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>R$ {lote.dadosUau.valorAVista}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                          <Icon icon="segmented-control" color={"#222e50"} />
                          <span style={{ marginLeft: "15px", color: "#999999" }}>Status: </span>
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}> {`${lote.dadosUau.status === 0 ? "Disponível" :
                            lote.dadosUau.status === 1 ? "Vendido" :
                              lote.dadosUau.status === 2 ? "Reservado" :
                                lote.dadosUau.status === 3 ? "Proposta" :
                                  lote.dadosUau.status === 4 ? "Quitado" :
                                    lote.dadosUau.status === 5 ? "Escriturado" :
                                      lote.dadosUau.status === 6 ? "Em Venda" :
                                        lote.dadosUau.status === 7 ? "Suspenso venda" :
                                          lote.dadosUau.status === 8 ? "Fora de venda" :
                                            lote.dadosUau.status === 9 ? "Em acerto" :
                                              lote.dadosUau.status === 10 ? "Dação" : "white"}`
                          }</span>
                        </div>
                      </div>
                    </Popup>
                  </Polygon>
                )
              })
            })}
            {/* INADIMPLÊNCIA */}
            {polygons && refresh && visao === 1 && polygons.lista.map((quadra: any, quadraIndex: any) => {
              return quadra.lotes.map((lote: any, loteIndex: any) => {
                const positions: any = [];
                lote.coordinates.map((array: any) => positions.push([array.longitude, array.latitude]))
                return (
                  <Polygon
                    key={`${quadra.quadra}${lote.lote}`}
                    opacity={1}
                    fillOpacity={0.5}
                    fillColor={lote.dadosUau.colorInadimplencia}
                    weight={1}
                    color={lote.dadosUau.colorInadimplencia}
                    positions={positions}
                    eventHandlers={{
                      click: () => { }
                    }}>
                    <Popup minWidth={220}>
                      {lote.dadosUau.statusDaInadimplencia !== "ADIMPLENTE" && lote.dadosUau.statusDaInadimplencia !== "UNIDADE EM ESTOQUE" &&
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", background: "0", height: "220px", fontWeight: "bold" }}>
                          <div style={{ display: "flex", alignItems: "center", background: "0", marginBottom: "5px" }}>
                            <Icon icon="map-marker" color={"#001346"} iconSize={15} />
                            <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{lote.dadosUau.StatusDaInadimplencia}</span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                            <Icon icon="control" color={"#1c294e"} />
                            <span style={{ marginLeft: "15px", color: "#999999" }}>Área: </span>
                            <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{lote.dadosUau.area} m²</span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                            <Icon icon="dollar" color={"#222e50"} />
                            <span style={{ marginLeft: "15px", color: "#999999" }}>Valor: </span>
                            <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{Moeda.FormatarTexto(lote.dadosUau.valorAVista !== null ? lote.dadosUau.valorAVista : 0)}</span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                            <Icon icon="dollar" color={"#222e50"} />
                            <span style={{ marginLeft: "15px", color: "#999999" }}>Inadimplência: </span>
                            <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{Moeda.FormatarTexto(lote.dadosUau.valorInadimplente)} ({/*lote.qtDeParcelasInadimplente*/} parcela(s))</span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                            <Icon icon="dollar" color={"#222e50"} />
                            <span style={{ marginLeft: "15px", color: "#999999" }}>Carteira futura: </span>
                            <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{Moeda.FormatarTexto(lote.dadosUau.valorAdimplente)}</span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                            <Icon icon="doughnut-chart" color={"#222e50"} />
                            <span style={{ marginLeft: "15px", color: "#999999" }}>Vistoria: </span>
                            <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}> {lote.dadosUau.statusDaVistoria}</span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                            <Icon icon="segmented-control" color={"#222e50"} />
                            <span style={{ marginLeft: "15px", color: "#999999" }}>Status: </span>
                            <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}> {lote.dadosUau.statusDaInadimplencia}</span>
                          </div>
                        </div>
                      }
                      {(lote.dadosUau.statusDaInadimplencia === "ADIMPLENTE" || lote.dadosUau.statusDaInadimplencia === "UNIDADE EM ESTOQUE") &&
                        <div>Nenhum conteúdo referente a inadimplência.</div>
                      }
                    </Popup>
                  </Polygon>
                )
              })
            })}
            {/* FAIXAS */}
            {getLocalData && refresh && visao === 2 && getLocalData.lista.map((quadra: any, quadraIndex: any) => {
              return quadra.lotes.map((lote: any, loteIndex: any) => {
                if (typeof lote.faixaValor != undefined) {
                }
                const positions: any = [];
                lote.coordinates.map((array: any) => positions.push([array.longitude, array.latitude]))
                return (
                  <Polygon
                    key={`${quadra.quadra}${lote.lote}`}
                    opacity={1}
                    fillOpacity={0.5}
                    fillColor={lote.faixaValor.cor}
                    weight={1}
                    color={lote.faixaValor.cor}
                    positions={positions}
                    eventHandlers={{
                      click: () => { }
                    }}>
                    <Popup minWidth={220}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", background: "0", height: "120px", fontWeight: "bold" }}>
                        <div style={{ display: "flex", alignItems: "center", background: "0", marginBottom: "5px" }}>
                          <Icon icon="map-marker" color={"#001346"} iconSize={20} />
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{lote.dadosUau.descricao}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                          <Icon icon="control" color={"#1c294e"} />
                          <span style={{ marginLeft: "15px", color: "#999999" }}>Área: </span>
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{lote.dadosUau.area} m²</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                          <Icon icon="dollar" color={"#222e50"} />
                          <span style={{ marginLeft: "15px", color: "#999999" }}>Valor: </span>
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>R$ {lote.dadosUau.valorAVista}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                          <Icon icon="segmented-control" color={"#222e50"} />
                          <span style={{ marginLeft: "15px", color: "#999999" }}>Status: </span>
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}> {`${lote.dadosUau.status === 0 ? "Disponível" :
                            lote.dadosUau.status === 1 ? "Vendido" :
                              lote.dadosUau.status === 2 ? "Reservado" :
                                lote.dadosUau.status === 3 ? "Proposta" :
                                  lote.dadosUau.status === 4 ? "Quitado" :
                                    lote.dadosUau.status === 5 ? "Escriturado" :
                                      lote.dadosUau.status === 6 ? "Em Venda" :
                                        lote.dadosUau.status === 7 ? "Suspenso venda" :
                                          lote.dadosUau.status === 8 ? "Fora de venda" :
                                            lote.dadosUau.status === 9 ? "Em acerto" :
                                              lote.dadosUau.status === 10 ? "Dação" : "white"}`
                          }</span>
                        </div>
                      </div>
                    </Popup>
                  </Polygon>
                )
              })
            })}
            {/* CATEGORIAS DE PREÇO */}
            {getLocalData && refresh && visao === 3 && getLocalData.lista.map((quadra: any, quadraIndex: any) => {
              return quadra.lotes.map((lote: any, loteIndex: any) => {
                const positions: any = [];
                lote.coordinates.map((array: any) => positions.push([array.longitude, array.latitude]))
                return (
                  <Polygon
                    key={`${quadra.quadra}${lote.lote}`}
                    opacity={1}
                    fillOpacity={0.5}
                    fillColor={(lote.dadosUau.Categoria_de_preco == null) ? "#93E4FE" : lote.corCategoria}
                    weight={1}
                    color={(lote.dadosUau.Categoria_de_preco == null) ? "#93E4FE" : lote.corCategoria}
                    positions={positions}
                    eventHandlers={{
                      click: () => { }
                    }}>
                    <Popup minWidth={220}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", background: "0", height: "120px", fontWeight: "bold" }}>
                        <div style={{ display: "flex", alignItems: "center", background: "0", marginBottom: "5px" }}>
                          <Icon icon="map-marker" color={"#001346"} iconSize={20} />
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{lote.dadosUau.descricao}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                          <Icon icon="control" color={"#1c294e"} />
                          <span style={{ marginLeft: "15px", color: "#999999" }}>Área: </span>
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{lote.dadosUau.area} m²</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                          <Icon icon="dollar" color={"#222e50"} />
                          <span style={{ marginLeft: "15px", color: "#999999" }}>Valor: </span>
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>R$ {lote.dadosUau.valorAVista}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                          <Icon icon="segmented-control" color={"#222e50"} />
                          <span style={{ marginLeft: "15px", color: "#999999" }}>Status: </span>
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}> {`${lote.dadosUau.status === 0 ? "Disponível" :
                            lote.dadosUau.status === 1 ? "Vendido" :
                              lote.dadosUau.status === 2 ? "Reservado" :
                                lote.dadosUau.status === 3 ? "Proposta" :
                                  lote.dadosUau.status === 4 ? "Quitado" :
                                    lote.dadosUau.status === 5 ? "Escriturado" :
                                      lote.dadosUau.status === 6 ? "Em Venda" :
                                        lote.dadosUau.status === 7 ? "Suspenso venda" :
                                          lote.dadosUau.status === 8 ? "Fora de venda" :
                                            lote.dadosUau.status === 9 ? "Em acerto" :
                                              lote.dadosUau.status === 10 ? "Dação" : "white"}`
                          }</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", background: "0" }}>
                          <Icon icon="control" color={"#1c294e"} />
                          <span style={{ marginLeft: "15px", color: "#999999" }}>Categoria: </span>
                          <span style={{ marginBottom: 0, fontSize: 15, color: '#222e50', marginLeft: "5px" }}>{lote.dadosUau.Categoria_de_preco}</span>
                        </div>
                      </div>
                    </Popup>
                  </Polygon>
                )
              })
            })}
            {/* ÁREAS */}
            {/* RUAS */}
            {areas && true && areas.ruas.features.map((rua: any, ruaIndex: any) => {
              return <Marker interactive={false} icon={divIcon({
                iconAnchor: [(rua.geometry.coordinates[1] * 1 + 0), (rua.geometry.coordinates[0] * 1 + (0))],
                html: renderToStaticMarkup(
                  <div style={{ backgroundColor: "0", pointerEvents: "none", transform: `rotate(${rua.rotate}deg)`, opacity: `${getRuaSize[3]}`, fontSize: `${getRuaSize[2]}px`, display: "flex", alignItems: "center", justifyContent: "center", color: getTileLayer === 1 ? "#ffffff" : "#1d1313", borderRadius: "unset", width: `${getRuaSize[1] + 80}px`, height: `${getRuaSize[1] - 70}px`, border: "unset" }}>
                    <span style={{ fontWeight: "bold", pointerEvents: "none" }}>{rua.properties.name}</span>
                  </div>
                )
              })} position={[(rua.geometry.coordinates[1] * 1 + 0.00035), (rua.geometry.coordinates[0] * 1 + (-0.00055))]} />
            })}
            {/* NOMES */}
            {areas && true && areas.centros.features.map((centro: any, centroIndex: any) => {
              return <Marker icon={divIcon({
                html: renderToStaticMarkup(
                  <div style={{ backgroundColor: "0", opacity: `${getMarkersSize[3]}`, color: getTileLayer === 1 ? "#ffffff" : "#1d1313", fontSize: `${getMarkersSize[2]}px`, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "unset", width: `${getMarkersSize[1] + 10}px`, height: `${getMarkersSize[1]}px`, border: "unset" }}>
                    <span style={{ fontWeight: "bold" }}>{centro.properties.name}</span>
                  </div>
                )
              })} position={[(centro.geometry.coordinates[1] * 1 + 0), (centro.geometry.coordinates[0] * 1 + 0)]} />
            })}
            {/* POLYGONS */}
            {areas && true && areas.polygons.features.map((area: any, quadraIndex: any) => {
              const positions: any = [];
              area.geometry.geometries.map((array: any) => positions.push([array.coordinates[0][1], array.coordinates[0][0]]))
              return (
                <Polygon
                  opacity={1}
                  fillOpacity={0.5}
                  fillColor={area.color}
                  weight={1}
                  color={area.color}
                  positions={positions}
                  eventHandlers={{
                    click: () => { }
                  }}>
                  <Popup minWidth={100}>
                    <div style={{ display: "flex", pointerEvents: "none", flexDirection: "row", justifyContent: "center", width: "100%", background: "0", height: "20px", fontWeight: "bold" }}>
                      <span>{area.name}</span>
                    </div>
                  </Popup>
                </Polygon>
              )
            })}
            {/* DELIMITAÇÃO */}
            {areas && true && areas.delimitacao.features.map((area: any, quadraIndex: any) => {
              const positions: any = [];
              area.geometry.geometries.map((array: any) => positions.push([array.coordinates[0][1], array.coordinates[0][0]]))
              return (
                <Polygon
                  opacity={1}
                  fill={false}
                  weight={2}
                  color="yellow"
                  fillColor="white"
                  fillOpacity={1}
                  positions={positions}
                  eventHandlers={{
                    click: () => { }
                  }}>
                  <Popup minWidth={100}>
                    <div style={{ display: "flex", pointerEvents: "none", flexDirection: "row", justifyContent: "center", width: "100%", background: "0", height: "20px", fontWeight: "bold" }}>
                      <span>{area.name}</span>
                    </div>
                  </Popup>
                </Polygon>
              )
            })}
            <MyComponent />
          </MapContainer>
        </div>}
    </div>
  );
}

export default memo(App);