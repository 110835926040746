import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import Lottie from 'react-lottie';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import io from "socket.io-client";
import { Button, Card, Drawer, FormGroup, Icon, MenuItem, Switch, Menu, Collapse, Position, Popover } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select, MultiSelect } from "@blueprintjs/select";
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Group, Inject, GroupSettingsModel, Aggregate, Toolbar, ToolbarItems, PdfExport, Grid, Filter, FilterSettingsModel, Reorder, Column, Sort, SortSettingsModel, PdfHeaderQueryCellInfoEventArgs, Resize } from "@syncfusion/ej2-react-grids";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn, SignIn, WidthCheck as ContextWidthCheck } from '../../../Context';
import { Localization } from '../../../Data/Listas';
import { CentroDeCusto, Identificador } from '../../../Services/Controllers';
import api from '../../../Services/node';
import { Moeda } from '../../../Themes/TextFormat';
import { Objeto } from '../../../Services/Models';
import { NotificacaoInterna } from '../../../Services/Notification';
import * as LottieFiles from '../../../Data/Lottie';

import './App.css';
import Map from './leaflet';
import { image } from './image';
import * as Component from './style';
import './style.css';
import viewModel from './viewModel';
import GridDisponibilidade from './Components';

loadCldr(
    require('../../../../node_modules/cldr-data/main/pt/currencies.json'),
    require('../../../../node_modules/cldr-data/main/pt/numbers.json'),
    require('../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
    require('../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
    require('../../../../node_modules/cldr-data/supplemental/numberingSystems.json')
);
setCulture('pt');
setCurrencyCode("BRL");
L10n.load(Localization);
var socket: any;

const View: React.FC = () => {
    let dataStorage: Array<StoreSintetico>;
    let dataStorageCategorias: any;
    let listaSintetica: Array<Objeto.IdentificadorSintetico>;

    /* #region  Variáveis  */
    const [getExibirMapa, setExibirMapa] = useStateWithCallbackLazy<boolean>(false);
    const [getWidth, setWidth] = useState<number>();

    /* #region  Variáveis da tabela de disponibilidade */
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCustoDisponibilidade, setListaPickerCentroDeCustoDisponibilidade] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const [getListaDeUnidades, setListaDeUnidades] = useState<Array<Objeto.Identificador>>([]);
    const [getListaDeUnidadesSintetico, setListaDeUnidadesSintetico] = useState<Array<Objeto.IdentificadorSintetico>>([]);
    const [getListaDeDisponibilidade, setListaDeDisponibilidade] = useState<Array<Store>>([]);
    const [getListaDeUnidadesMemo, setListaDeUnidadesMemo] = useState<Array<Objeto.IdentificadorSintetico>>([]);
    const [getListaDeDisponibilidadeSintetico, setListaDeDisponibilidadeSintetico] = useState<Array<StoreSintetico>>([]);
    const [getListaDeCategorias, setListaDeCategorias] = useState<Array<any>>([]);
    const timelineLoaded = useRef<boolean>(false);
    const [getCarregandoListaDeUnidades, setCarregandoListaDeUnidades] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    /* #endregion */

    /* #region  Variáveis do mapa */
    const [getListaPickerCentroDeCustoMapa, setListaPickerCentroDeCustoMapa] = useState<Array<Objeto.CentroDeCusto>>([]);
    interface Store {
        empreendimento: string;
        unidade: string;
        valorUnidade: number;
        torre: string;
        apto: number;
        pavimento: string;
        qtdQuartos: number;
        qtdSemanas: number;
        cota: string;
        observacao: string;
        vista: string;
        frente: string;
        frenteConf: string;
        fundo: string;
        fundoConf: string;
        esquerda: string;
        esquerdaConf: string;
        direita: string;
        direitaConf: string;
        id: number;
        status: number;
        unidades: Objeto.Identificador;
        sigla: string;
        lista: Array<Objeto.Identificador>;
        store: Array<Store>;
        categorias: Array<any>;
    };
    interface StoreSintetico {
        empreendimento: string;
        unidade: string;
        valorUnidade: number;
        torre: string;
        apto: number;
        pavimento: string;
        qtdQuartos: number;
        qtdSemanas: number;
        cota: string;
        observacao: string;
        vista: string;
        frente: string;
        frenteConf: string;
        fundo: string;
        fundoConf: string;
        esquerda: string;
        esquerdaConf: string;
        direita: string;
        direitaConf: string;
        id: number;
        status: number;
        unidades: Objeto.IdentificadorSintetico;
        sigla: string;
        lista: Array<Objeto.IdentificadorSintetico>;
        store: Array<StoreSintetico>;
        categorias: Array<any>;
    };
    const [getEmpresaObra, setEmpresaObra] = useState<any>([]);
    const [getRefreshData, setRefreshData] = useState<any>();
    const [getMapRefreshed, setMapRefreshed] = useState<any>(true);
    const [getListaDeMarcacoes, setListaDeMarcacoes] = useState<any>();
    const [getTiposInadimplencia, setTiposInadimplencia] = useState<any>();
    const [getListaPontosCentrais, setListaPontosCentrais] = useState<any>();
    const [getListaAreas, setListaAreas] = useState<any>();
    const [getVisaoMapa, setVisaoMapa] = useState<number>(0);
    const [getCarregandoListaDeMarcacoes, setCarregandoListaDeMarcacoes] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getCollapse, setCollapse] = useState(false);
    const [getFullscreen, setFullscreen] = useState(false);
    const handle = useFullScreenHandle();
    var Cordenadas: Array<number>;
    const toolbarOptions: ToolbarItems[] = ['PdfExport']
    const groupOptions: GroupSettingsModel = {
        showGroupedColumn: true,
        showUngroupButton: true,
    }
    const filterOptions: FilterSettingsModel = {
        ignoreAccent: true,
        immediateModeDelay: 200,
        mode: "Immediate",
        type: 'Excel',
    }
    const sortOptions: SortSettingsModel = {}
    const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs | any) => {
        (args.cell as any).row.pdfGrid.repeatHeader = true;
        args.cell.gridRow.pdfGrid.columns.getColumn(0).width = 100;
        args.cell.gridRow.pdfGrid.columns.getColumn(0).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(1).width = 100;
        args.cell.gridRow.pdfGrid.columns.getColumn(1).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(2).width = 60;
        args.cell.gridRow.pdfGrid.columns.getColumn(2).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(3).width = 40;
        args.cell.gridRow.pdfGrid.columns.getColumn(3).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(4).width = 80;
        args.cell.gridRow.pdfGrid.columns.getColumn(4).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(5).width = 50;
        args.cell.gridRow.pdfGrid.columns.getColumn(5).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(6).width = 50;
        args.cell.gridRow.pdfGrid.columns.getColumn(6).textAlign = "Left";
        args.cell.gridRow.pdfGrid.columns.getColumn(7).width = 90;
        args.cell.gridRow.pdfGrid.columns.getColumn(7).textAlign = "Left";
    }
    let grid: Grid | null;
    const toolbarClick = (args: any) => {
        if (grid) {
            if (args.item.id === 'grid_pdfexport') {
                (grid.columns[9] as Column).visible = false;
                grid.pdfExport({
                    fileName: `Disponibilidade ${moment(new Date(), true).format("YYYY-MM-DD")} - ${((getItemPickerCentroDeCusto?.empresa.id == 20) ? "PRE" : ((getItemPickerCentroDeCusto?.empresa.id == 21) ? "SPR" : ((getItemPickerCentroDeCusto?.empresa.id == 22) ? "SER" : ((getItemPickerCentroDeCusto?.empresa.id == 23) ? "PAR" : (getItemPickerCentroDeCusto?.empresa.id == 26 ? "PYR" : getItemPickerCentroDeCusto?.sigla)))))}.pdf`,
                    pageOrientation: "Landscape",
                    header: {
                        fromTop: 0,
                        height: 100,
                        contents: [
                            {
                                type: 'Text',
                                value: `${getItemPickerCentroDeCusto?.descricao}`,
                                position: { x: 390, y: 50 },
                                style: { textBrushColor: "#000000", fontSize: 20, hAlign: "Right" },
                            },
                            {
                                position: { x: 0, y: 45 },
                                size: { height: 40, width: 80 },
                                src: image,
                                type: 'Image',
                            },
                            {
                                type: 'Text',
                                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:MM:SS")}`,
                                position: { x: 880, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 13 },
                            },
                        ],
                    },
                    footer: {
                        contents: [
                            {
                                type: 'Text',
                                value: `Powered by DigitalDEV`,
                                position: { x: 0, y: 0 },
                                style: { textBrushColor: "#000000", fontSize: 10 },
                            },
                            {
                                format: 'Página {$current} de {$total}',
                                pageNumberType: "Numeric",
                                position: { x: 910, y: 0 },
                                style: {
                                    fontSize: 13,
                                    hAlign: "Right",
                                    textBrushColor: '#000000',
                                },
                                type: 'PageNumber',
                            }
                        ],
                        fromBottom: 0,
                        height: 20,
                    },
                    theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                        },
                        record: {
                            bold: false,
                            fontColor: "#000000",
                            fontSize: 8,
                        },
                        caption: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                        }
                    },
                    allowHorizontalOverflow: false,
                });
            }
        }
    }
    const pdfExportComplete = () => {
        if (grid) {
            (grid.columns[9] as Column).visible = true;
        }
    }
    const templateBotao = (props: any) => (
        <>
            {props.categorias.length > 0 && props.unidades.status == 0 && <Popover content={
                <Menu>
                    <MenuItem icon="list" text="Categorias...">
                        {props.categorias.map((item: any, index: number) => (
                            <>
                                <MenuItem icon="symbol-circle" text={`${item.Descricao}`}
                                    onClick={async () => {
                                        if (props.unidades?.status === 0) {
                                            if (!props.unidades._ReservandoUnidade) {
                                                let ListaDeContratosExibidos = [...props.lista];
                                                props.unidades._ReservandoUnidade = true;
                                                await setListaDeUnidades(ListaDeContratosExibidos);
                                                NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                            }
                                            else {
                                                return;
                                            }
                                            let Response = await Identificador.ReservarUnidadesComTimer(ContextSignIn.getContext().token, item.Tempo, [props.unidades]);
                                            let ListaDeContratosExibidos = [...props.lista];
                                            props.unidades._ReservandoUnidade = ((Math.floor(Response.status / 100) == 2) ? false : undefined);
                                            props.unidades.status = ((Math.floor(Response.status / 100) == 2) ? 2 : 0);
                                            await setListaDeUnidades(ListaDeContratosExibidos);
                                            if (Math.floor(Response.status / 100) == 2) {
                                                sendUnidade(props.unidades, props.sigla, props.lista, props.store);
                                                NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            }
                                            else {
                                                NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            }
                                        }
                                        else if (props.unidades?.status === 2) {
                                            if (!props.unidades._DeletandoReserva) {
                                                let ListaDeContratosExibidos = [...props.lista];
                                                props.unidades._DeletandoReserva = true;
                                                await setListaDeUnidades(ListaDeContratosExibidos);
                                                NotificacaoInterna.ExibirNotificacao("Disponibilizando unidade", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                            }
                                            else {
                                                return;
                                            }
                                            let Response = await Identificador.DeletarReservas(ContextSignIn.getContext().token, [props.unidades]);
                                            let ListaDeContratosExibidos = [...props.lista];
                                            props.unidades._DeletandoReserva = ((Math.floor(Response.status / 100) == 2) ? false : undefined);
                                            props.unidades.status = ((Math.floor(Response.status / 100) == 2) ? 0 : 2);
                                            await setListaDeUnidades(ListaDeContratosExibidos);

                                            if (Math.floor(Response.status / 100) == 2) {
                                                sendUnidade(props.unidades, props.sigla, props.lista, props.store);
                                                NotificacaoInterna.ExibirNotificacao("Unidade disponibilizada com sucesso", "Sua unidade já se encontra disponível em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            }
                                            else {
                                                NotificacaoInterna.ExibirNotificacao("Falha ao diponibilizar a unidade", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            }
                                        }
                                    }} />
                            </>
                        ))}
                    </MenuItem>
                </Menu>
            } position={Position.BOTTOM_RIGHT}>
                <Button
                    disabled={props.unidades.status === 3 ? true : false}
                    intent={props.unidades.status === 2 ? 'success' : 'danger'}
                    style={{ height: "5px", width: "8vw", borderRadius: '5px', cursor: "pointer", outline: "none", display: "flex", padding: "0" }}
                ><Icon iconSize={(document.documentElement.clientWidth / 100) - 4} style={{ marginBottom: "4px" }} icon={(props.unidades.status === 2 || props.unidades.status === 3) ? "unlock" : "lock"} />
                    <strong style={{ fontSize: "0.8vw" }}>{props.unidades.status === 2 ? ' Disponibilizar' : (props.unidades.status === 3 ? 'Reservado' : ' Reservar')}</strong>
                </Button>
            </Popover>}
            {(props.categorias.length == 0 || (props.categorias.length > 0 && (props.unidades.status === 3 || props.unidades.status === 2))) && <Button
                disabled={props.unidades.status === 3 ? true : false}
                intent={props.unidades.status === 2 ? 'success' : 'danger'}
                style={{ height: "5px", width: "8vw", borderRadius: '5px', cursor: "pointer", outline: "none", display: "flex", padding: "0" }}
                onClick={async () => {
                    if (props.unidades?.status === 0) {
                        if (!props.unidades._ReservandoUnidade) {
                            let ListaDeContratosExibidos = [...props.lista];
                            props.unidades._ReservandoUnidade = true;
                            await setListaDeUnidadesSintetico(ListaDeContratosExibidos);
                            NotificacaoInterna.ExibirNotificacao("Reserva sendo gerada", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);
                        }
                        else {
                            return;
                        }

                        let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, props.unidades.empresaId ?? 0, props.unidades.centroDeCustoSigla ?? "", props.unidades.localId, props.unidades.subLocalId)

                        if (Math.floor(ResponseUnidade.status / 100) == 2) {
                            let Response = await Identificador.ReservarUnidades(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);
                            let ListaDeContratosExibidos = [...props.lista];
                            props.unidades._ReservandoUnidade = ((Math.floor(Response.status / 100) == 2) ? false : undefined);
                            props.unidades.status = ((Math.floor(Response.status / 100) == 2) ? 2 : 0);
                            await setListaDeUnidadesSintetico(ListaDeContratosExibidos);
                            if (Math.floor(Response.status / 100) == 2) {
                                sendUnidadeSintetico(props.unidades, props.sigla, props.lista, props.store);
                                NotificacaoInterna.ExibirNotificacao("Reserva realizada com sucesso", "Sua unidade já se encontra reservada em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Falha ao gerar a reserva", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }
                    }
                    else if (props.unidades?.status === 2) {
                        if (!props.unidades._DeletandoReserva) {
                            let ListaDeContratosExibidos = [...props.lista];
                            props.unidades._DeletandoReserva = true;
                            await setListaDeUnidadesSintetico(ListaDeContratosExibidos);
                            NotificacaoInterna.ExibirNotificacao("Disponibilizando unidade", "Por favor, aguarde a finalização do processo, ele pode demorar devido a integração com sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);
                        }
                        else {
                            return;
                        }

                        let ResponseUnidade = await Identificador.GetUnidade(ContextSignIn.getContext().token, props.unidades.empresaId ?? 0, props.unidades.centroDeCustoSigla ?? "", props.unidades.localId, props.unidades.subLocalId)

                        if (Math.floor(ResponseUnidade.status / 100) == 2) {
                            let Response = await Identificador.DeletarReservas(ContextSignIn.getContext().token, [ResponseUnidade.data[0]]);
                            let ListaDeContratosExibidos = [...props.lista];
                            props.unidades._DeletandoReserva = ((Math.floor(Response.status / 100) == 2) ? false : undefined);
                            props.unidades.status = ((Math.floor(Response.status / 100) == 2) ? 0 : 2);
                            await setListaDeUnidadesSintetico(ListaDeContratosExibidos);

                            if (Math.floor(Response.status / 100) == 2) {
                                sendUnidadeSintetico(props.unidades, props.sigla, props.lista, props.store);
                                NotificacaoInterna.ExibirNotificacao("Unidade disponibilizada com sucesso", "Sua unidade já se encontra disponível em todos os sitemas", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                            }
                            else {
                                NotificacaoInterna.ExibirNotificacao("Falha ao diponibilizar a unidade", "Entre em contato com o desenvolvimento do sistema", NotificacaoInterna.TipoDeNotificacao.Erro);
                            }
                        }
                    }
                }}><Icon iconSize={(document.documentElement.clientWidth / 100) - 4} style={{ marginBottom: "4px" }} icon={(props.unidades.status === 2 || props.unidades.status === 3) ? "unlock" : "lock"} />
                <strong style={{ fontSize: "0.8vw" }}>{props.unidades.status === 2 ? ' Disponibilizar' : (props.unidades.status === 3 ? 'Reservado' : ' Reservar')}</strong>
            </Button>}
        </>
    );
    /* #endregion */
    /* #endregion */

    window.onresize = function () {
        ContextWidthCheck.setContext({ width: document.body.clientWidth })
        setWidth(document.body.clientWidth)
    }
    function WidthCheck() {
        if (window.location.pathname == "/Disponibilidade") {
            if (window.matchMedia("(min-width: 1024px && max-width: 2560px)").matches == true) {
                return { display: "flex", alignItems: "flex-end", flexWrap: "wrap", backgroundColor: "0", width: "97%", margin: "20px auto", justifyContent: "space-between" } as React.CSSProperties
            } else if (window.matchMedia("(max-width: 1024px)").matches == true) {
                return { display: "flex", alignItems: "flex-end", flexWrap: "wrap", backgroundColor: "0", width: "97%", margin: "20px auto", justifyContent: "center" } as React.CSSProperties
            }
        }
    }
    useEffect(() => {
        WidthCheck()
    }, [document.body.clientWidth]);

    useEffect(() => {
        async function componentDidMount() {
            socket = io(String(process.env.REACT_APP_CONNECTION_PORT), { transports: ['websocket', 'polling', 'flashsocket'] });
        }
        componentDidMount();
    }, [process.env.REACT_APP_CONNECTION_PORT]);
    useEffect(() => {
        if (!timelineLoaded.current) {
            socket.on("receive_unidade", (data: any) => {
                let IndexUnidades = getListaDeUnidadesSintetico.findIndex((contrato) => contrato.subLocalId == data.unidade.subLocalId);
                let IndexDisponibilidade: any = getListaDeDisponibilidadeSintetico.findIndex((contrato) => contrato.unidades.subLocalId == data.unidade.subLocalId);

                if (IndexUnidades >= 0) {
                    let ListaDeUnidades = [...getListaDeUnidadesSintetico];
                    let ListaDeDisponibilidade = [...getListaDeDisponibilidadeSintetico];

                    ListaDeUnidades[IndexUnidades] = data.unidade;
                    ListaDeDisponibilidade[IndexDisponibilidade].unidades.status = data.status;

                    timelineLoaded.current = true;
                    setListaDeUnidadesSintetico(ListaDeUnidades);
                    timelineLoaded.current = false;

                    socket.off('receive_unidade');
                    socket.on('receive_unidade', (data: any) => {
                        let IndexUnidades = getListaDeUnidadesSintetico.findIndex((contrato) => contrato.subLocalId == data.unidade.subLocalId);
                        let IndexDisponibilidade: any = getListaDeDisponibilidadeSintetico.findIndex((contrato) => contrato.unidades.subLocalId == data.unidade.subLocalId);

                        if (IndexUnidades >= 0) {
                            let ListaDeUnidades = [...getListaDeUnidadesSintetico];
                            let ListaDeDisponibilidade = [...getListaDeDisponibilidadeSintetico];

                            ListaDeUnidades[IndexUnidades] = data.unidade;
                            ListaDeDisponibilidade[IndexDisponibilidade].unidades.status = data.status;

                            timelineLoaded.current = true;
                            setListaDeUnidadesSintetico(ListaDeUnidades);
                            timelineLoaded.current = false;
                        }
                    });
                }
            })
        }
    }, [getListaDeUnidadesSintetico])
    const connectToRoom = (room: string | undefined) => {
        socket.emit('join_room', room)
        console.log(`Conectado a sala: ${room}`)
    }
    const sendUnidade = async (unidade: Objeto.Identificador, sigla: string, lista: Array<Objeto.Identificador>, store: Array<Store>) => {
        let UnidadeContent = {
            room: sigla,
            content: {
                id: unidade.indice,
                unidade: unidade,
                status: unidade.status === 2 ? 3 : unidade.status,
            },
        }
        await socket.emit("send_unidade", UnidadeContent);
        socket.off('receive_unidade');
        socket.on('receive_unidade', (data: any) => {
            let Index = (lista).findIndex((contrato: any) => contrato.subLocal.id == data.unidade.subLocal.id);
            let IndexStore: any = (store).findIndex((contrato) => contrato.unidades.subLocal.id == data.unidade.subLocal.id);
            let IndexStoreExibido: any = (store).findIndex((contrato) => contrato.unidades.subLocal.id == data.unidade.subLocal.id);

            if (Index >= 0) {
                let ListaDeContratosExibidos = [...lista];
                let UnidadeStore = [...store];
                let UnidadeStoreExibidos = [...store];

                ListaDeContratosExibidos[Index] = data.unidade;
                UnidadeStore[IndexStore].unidades.status = data.status;
                UnidadeStoreExibidos[IndexStoreExibido].unidades.status = data.status;

                timelineLoaded.current = true;
                setListaDeUnidades(ListaDeContratosExibidos);
                timelineLoaded.current = false;
            }
        });
    }

    const sendUnidadeSintetico = async (unidade: Objeto.IdentificadorSintetico, sigla: string, lista: Array<Objeto.IdentificadorSintetico>, store: Array<StoreSintetico>) => {
        let UnidadeContent = {
            room: sigla,
            content: {
                id: 0,
                unidade: unidade,
                status: unidade.status === 2 ? 3 : unidade.status,
            },
        }
        await socket.emit("send_unidade", UnidadeContent);
        socket.off('receive_unidade');
        socket.on('receive_unidade', (data: any) => {
            let Index = (lista).findIndex((contrato: any) => contrato.subLocalId == data.unidade.subLocalId);
            let IndexStore: any = (store).findIndex((contrato) => contrato.unidades.subLocalId == data.unidade.subLocalId);
            let IndexStoreExibido: any = (store).findIndex((contrato) => contrato.unidades.subLocalId == data.unidade.subLocalId);

            if (Index >= 0) {
                let ListaDeContratosExibidos = [...lista];
                let UnidadeStore = [...store];
                let UnidadeStoreExibidos = [...store];

                ListaDeContratosExibidos[Index] = data.unidade;
                UnidadeStore[IndexStore].unidades.status = data.status;
                UnidadeStoreExibidos[IndexStoreExibido].unidades.status = data.status;

                timelineLoaded.current = true;
                setListaDeUnidadesSintetico(ListaDeContratosExibidos);
                timelineLoaded.current = false;
            }
        });
    }

    /* Sobre esta função *//**
     * Calcula o cenário a ser exibido na legenda
     * @param {string} status Informe o status da unidade"
     * @param {string} NivelDeVisualizacao Informe o tipo da operação a ser realizada (1: área, 2: Qtd, 3: valor)"
     **/
    /* Sobre esta função *//**
       * Calcula o cenário a ser exibido na legenda
       * @param {number} longitude Informe a longitude do marcador"
       * @param {number} latitude Informe a latitude do marcador"
       **/
    function CalcularCoordenadasDoMarcador(longitude: number, latitude: number) {
        let origem: Array<number> = [38.231544, -122.400049];
        let escalaM: number = 24 / 2;
        let contaLong: number = -1 * ((longitude - origem[0]) * (111.11 * 1000)) / escalaM;
        let contaLat: number = ((latitude - origem[1]) * (111.11 * 1000)) / escalaM;
        Cordenadas = [contaLat, contaLong];
    }
    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
       **/
    async function SelecionarCentroDeCusto(Item: Objeto.CentroDeCusto | undefined, MapaExibido: boolean) {
        if (MapaExibido) {
            if (Item) {
                await setCarregandoListaDeMarcacoes(false, async () => {
                    await setItemPickerCentroDeCusto(Item);
                    if (getRefreshData) {
                        clearInterval(getRefreshData)
                    }
                    await setEmpresaObra([Item.empresa, Item.sigla])
                    await setItemPickerCentroDeCusto(Item)
                    await setCarregandoListaDeMarcacoes(true, async () => { })
                    await setListaDeMarcacoes(undefined);
                    await setTiposInadimplencia(undefined);
                    await setListaAreas(undefined);
                    await api.get(`/allMaps?empresaMae=${Item.empresa}&obra=${Item.sigla}`).then(async results => {
                        await setListaDeMarcacoes(results.data);
                        await setTiposInadimplencia(results.data.tiposInadimplencia);
                        await setListaAreas(results.data.others);
                        await setCarregandoListaDeMarcacoes(false, () => { });
                        setRefreshData(setInterval(async function () {
                            await api.get(`/allMaps?empresaMae=Cosentino&obra=EUROII`).then(async results => {
                                await setMapRefreshed(false)
                                await setListaDeMarcacoes(results.data);
                                await setTiposInadimplencia(results.data.tiposInadimplencia);
                                await setListaAreas(results.data.others);
                                await setMapRefreshed(true)
                            })
                        }, 10000));
                    });
                });
            }
        }
        else {
            await setCarregandoListaDeUnidades(false, async () => { });
            if (Item) {
                setItemPickerCentroDeCusto(Item);
                connectToRoom(Item.sigla)
                let Response = (await Identificador.FastGetSintetico(ContextSignIn.getContext().token, true, Item.empresa.id ?? 0, Item.sigla ?? ""));
                if (Math.floor(Response.status / 100) == 2) {
                    setListaDeUnidadesMemo(Response.data);
                    setCarregandoListaDeUnidades(true, () => { });
                    dataStorage = [];
                }
                else {
                    setItemPickerCentroDeCusto(undefined);
                    setListaDeUnidadesSintetico([]);
                    setListaDeDisponibilidadeSintetico(([]));
                    setCarregandoListaDeUnidades(undefined, () => { });
                }
            }
            else {
                setItemPickerCentroDeCusto(undefined);
                setListaDeUnidadesSintetico([]);
                setListaDeDisponibilidadeSintetico(([]));
                setListaDeCategorias([]);
                setCarregandoListaDeMarcacoes(undefined, () => { });
            }
        }
    }
    useEffect(() => {
        async function componentDidMount() {
            switch (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado) {
                case 1:
                    await api.get(`/Mapas/Empreendimentos?empresaMae=CasaETerra`).then(async results => await setListaPickerCentroDeCustoMapa(results.data.empreendimentos));
                    break;
                case 9:
                    await api.get(`/Mapas/Empreendimentos?empresaMae=Cosentino`).then(async results => await setListaPickerCentroDeCustoMapa(results.data.empreendimentos));
                    break;
                default:

            }
            await setListaPickerCentroDeCustoDisponibilidade((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? []);
        }
        componentDidMount();
    }, []);

    return (
        <Component.Container>
            <FullScreen handle={handle}>
                <Collapse isOpen={getCollapse}>
                    <div className="endereco" style={WidthCheck()}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={IconeHome} alt="" style={{ width: "20px", height: "20px" }} />
                            <div className="bar">/</div>
                            <div className="place">Disponibilidade</div>
                            <div className="bar">/</div>
                            <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getExibirMapa == true ? getListaPickerCentroDeCustoMapa : getListaPickerCentroDeCustoDisponibilidade} onItemSelect={() => { }} noResults={<MenuItem style={{ position: "relative", zIndex: 20000 }} disabled={getCarregandoListaDeMarcacoes} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => { await SelecionarCentroDeCusto(Item, getExibirMapa); }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerCentroDeCusto?.descricao.substring(0, 10).concat("...") ?? "Empreendimento"} rightIcon="double-caret-vertical" />
                            </PickerCentroDeCusto>
                        </div>
                        {/* <div style={{ display: "flex", alignItems: "center", margin: "0px 20px" }}>
                            <div style={{ fontWeight: (getExibirMapa == true ? "lighter" : "bolder") }} className="place">Tabela de disponibilidade</div>
                            <Switch style={{ marginLeft: "15px", marginTop: "10px" }} checked={getExibirMapa} onChange={async () => {

                                if (getExibirMapa == false) {
                                    await setExibirMapa(!getExibirMapa, async () => {
                                        await SelecionarCentroDeCusto(getListaPickerCentroDeCustoMapa[0], !getExibirMapa);
                                    });
                                }
                                else {
                                    clearInterval(getRefreshData)
                                    await setExibirMapa(!getExibirMapa, async () => {
                                        await SelecionarCentroDeCusto(getListaPickerCentroDeCustoDisponibilidade[0], !getExibirMapa);
                                    });
                                }
                            }} />
                            <div style={{ fontWeight: (getExibirMapa == true ? "bolder" : "lighter") }} className="place">Mapa do empreendimento</div>
                        </div> */}
                        <div style={{ display: "flex", background: "0", alignItems: "center", justifyContent: "center" }}>

                            <div style={{ width: "30px", height: "30px", borderRadius: "5px", marginRight: "1vw", cursor: "pointer", backgroundColor: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => {
                                if (getFullscreen) {
                                    setFullscreen(!getFullscreen);
                                    setCollapse(true);
                                    handle.exit();
                                }
                                else {
                                    setFullscreen(!getFullscreen);
                                    setCollapse(false);
                                    handle.enter();
                                }
                            }}>
                                <Icon iconSize={20} icon="presentation" />
                            </div>

                            <div style={{ cursor: "pointer", display: getExibirMapa ? "flex" : "none", fontSize: "14px", width: "600px", height: "30px", background: "0", fontWeight: 500, transition: "width 1.5s", justifyContent: "center", marginTop: "0px" }}>
                                <div onClick={() => setVisaoMapa(0)} style={{ display: "flex", alignItems: "center", justifyContent: "space-around", padding: "0 10px", borderTopLeftRadius: "5px", borderRight: "1px solid #dbdbdb", borderBottomLeftRadius: "5px", width: window.matchMedia("(max-width: 650px)").matches == true ? "10%" : "25%", height: "100%", cursor: "pointer", visibility: "visible", opacity: "1", background: getVisaoMapa === 0 ? "#99acff" : "white", color: getVisaoMapa === 0 ? "#ffffff" : "#000000", transition: "background-color 1s, visibility 2s, opacity 5s, color 1s" }}>
                                    <Icon icon="path-search" />
                                    <span style={{ pointerEvents: "none", display: window.matchMedia("(max-width: 650px)").matches == true ? "none" : "initial" }}>Disponibilidade</span>
                                </div>
                                <div onClick={() => setVisaoMapa(1)} style={{ display: "flex", alignItems: "center", justifyContent: "space-around", padding: "0 10px", width: window.matchMedia("(max-width: 650px)").matches == true ? "10%" : "25%", height: "100%", cursor: "pointer", visibility: "visible", opacity: "1", background: getVisaoMapa === 1 ? "#99acff" : "white", color: getVisaoMapa === 1 ? "#ffffff" : "#000000", transition: "background-color 1s, visibility 2s, opacity 5s, color 1s" }}>
                                    <Icon icon="dollar" />
                                    <span style={{ pointerEvents: "none", display: window.matchMedia("(max-width: 650px)").matches == true ? "none" : "initial" }}>Inadimplência</span>
                                </div>
                                <div onClick={() => setVisaoMapa(2)} style={{ display: "flex", alignItems: "center", justifyContent: "space-around", padding: "0 10px", borderLeft: "1px solid #dbdbdb", width: window.matchMedia("(max-width: 650px)").matches == true ? "10%" : "25%", height: "100%", cursor: "pointer", visibility: "visible", opacity: "1", background: getVisaoMapa === 2 ? "#99acff" : "white", color: getVisaoMapa === 2 ? "#ffffff" : "#000000", transition: "background-color 1s, visibility 2s, opacity 5s, color 1s" }}>
                                    <Icon icon="double-caret-horizontal" />
                                    <span style={{ pointerEvents: "none", display: window.matchMedia("(max-width: 650px)").matches == true ? "none" : "initial" }}>Faixas Preço</span>
                                </div>
                                <div onClick={() => setVisaoMapa(3)} style={{ display: "flex", alignItems: "center", justifyContent: "space-around", padding: "0 10px", borderTopRightRadius: "5px", borderLeft: "1px solid #dbdbdb", borderBottomRightRadius: "5px", width: window.matchMedia("(max-width: 650px)").matches == true ? "10%" : "25%", height: "100%", cursor: "pointer", visibility: "visible", opacity: "1", background: getVisaoMapa === 3 ? "#99acff" : "white", color: getVisaoMapa === 3 ? "#ffffff" : "#000000", transition: "background-color 1s, visibility 2s, opacity 5s, color 1s" }}>
                                    <Icon icon="comparison" />
                                    <span style={{ pointerEvents: "none", display: window.matchMedia("(max-width: 650px)").matches == true ? "none" : "initial" }}>Categorias</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
                {/* Abridor Colapse */}
                <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: LottieFiles.SwipeDown,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={30}
                        width={30}
                        style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                    />
                </div>
                {getExibirMapa == false && <div>
                    {getCarregandoListaDeUnidades != true && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: LottieFiles.LoadMaps,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            height={500}
                            width={500}
                            style={{ marginTop: "122px" }}
                        />
                        <div className="loading" style={{ margin: "-100px auto 0 auto", fontWeight: "bold" }}>{(getCarregandoListaDeUnidades == undefined) ? "Por favor, selecione um empreendimento para exibir a tabela de disponibilidade" : ((getCarregandoListaDeUnidades == false) ? "Aguarde, estamos carregando seu empreendimento" : "Pronto, sua tabela de disponibilidade será exibida!")}</div>
                    </div>}
                    {getCarregandoListaDeUnidades == true && <Card style={{ backgroundColor: "0", width: "100%", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none" }}>
                        {true && <GridDisponibilidade listaunidades={getListaDeUnidadesMemo} ItemEmpreendimento={getItemPickerCentroDeCusto} />}
                    </Card>}
                </div>}
                {getExibirMapa == true && getListaAreas &&
                    <div onClick={() => setCollapse(false)} style={{ height: "calc(100% - 20px)" }}>
                        {getCarregandoListaDeMarcacoes !== false && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.LoadMaps,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height={500}
                                width={500}
                                style={{ marginTop: "200px" }}
                            />
                            <div className="loading" style={{ margin: "-100px auto 0 auto", fontWeight: "bold" }}>{(getCarregandoListaDeMarcacoes == undefined) ? "Por favor, selecione um empreendimento para exibir o mapa" : ((getCarregandoListaDeMarcacoes == true) ? "Aguarde, estamos carregando seu empreendimento" : "Pronto, seu mapa logo será exibido!")}</div>
                        </div>}
                        {false && !getCarregandoListaDeMarcacoes && <Map refresh={getMapRefreshed} visao={getVisaoMapa} polygons={getListaDeMarcacoes} tiposInadimplencia={getTiposInadimplencia} areas={getListaAreas} points={getListaPontosCentrais} />}
                    </div>}
            </FullScreen>
        </Component.Container >
    );
    /* #endregion */
};

// const App: React.FC<viewModel> = (viewModel) => {
//     const queryClient = new QueryClient();

//     return (
//         <QueryClientProvider client = {queryClient}>
//             <View />
//         </QueryClientProvider>
//     );
// }

export default View;