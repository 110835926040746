import React from 'react';
import { WidthCheck as ContextWidthCheck } from '../../../../../../Context';
import { Numero } from '../../../../../../Themes/TextFormat';

function App({ Calcularcenario, statusData, visao, tiposInadimplencia, getListaFaixa, getListaCategoria }: any) {

    return (
        <div style={{ display: ContextWidthCheck.getContext().width <= 660 ? "none" : "flex", flexDirection: ContextWidthCheck.getContext().width <= 660 ? "initial" : "column", width: "32%", fontSize: "12px", fontWeight: 600 }}>
            {/* ====================DISPONIBILIDADE==================== */}
            {visao === 0 && statusData.map((status: { id: number, name: string, color: string }, statusIndex: number) => (
                <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: statusIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(status.id, 2))}</div>
                </div>
            ))}
            {/* ====================INADIMPLENCIA==================== */}
            {visao === 1 && tiposInadimplencia.map((Item: any, ItemIndex: number) =>
                <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: ItemIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(Item.descricao, 2))}</div>
                </div>
            )}
            {/* ====================FAIXA DE PREÇO==================== */}
            {visao === 2 && getListaFaixa.map((Item: any, ItemIndex: number) =>
                <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: ItemIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(Item.faixa, 2))}</div>
                </div>
            )}
            {/* ====================CATEGORIA DE PREÇO==================== */}
            {visao === 3 && getListaCategoria.map((Item: any, ItemIndex: number) =>
                <div style={{ display: "flex", width: "calc(100% - 0cm)", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: ItemIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div>{Numero.FormatarTextoParaDecimal(Calcularcenario(Item.Categoria_de_preco, 2))}</div>
                </div>
            )}
        </div>
    );
}

export default App;