import { AxiosResponse } from 'axios';
import axios from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class Formulario {
    
    /* #region GET Controller  */
    /* Sobre esta função *//**
     * Consulta o(s) formulário(s) vinculado(s) ao grupo de empresa informado.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/

    public async Get(Token: string): Promise<AxiosResponse<Array<{ id: number, descricao: string, perguntas: Array<{ id: number, titulo: string, classificacao: { id: number, descricao: string }, obrigatorio: boolean, resposta: string }>}>>> {
        return await Context.get(`/Formulario/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region POST Controller  */
    /* Sobre esta função *//**
     * Cadastrar o formulário
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async Post(Token: string, Form: { id: number, descricao: string, perguntas: Array<{ id: number, titulo: string, classificacao: { id: number, descricao: string }, obrigatorio: boolean, resposta: string }>}): Promise<AxiosResponse<{ id: number, descricao: string, perguntas: Array<{ id: number, titulo: string, classificacao: { id: number, descricao: string }, obrigatorio: boolean, resposta: string }>}>> {
        return await Context.post(`/Formulario/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Form)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Altera o formulário
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async Put(Token: string, Form: { id: number, descricao: string, perguntas: Array<{ id: number, titulo: string, classificacao: { id: number, descricao: string }, obrigatorio: boolean, resposta: string }>}): Promise<AxiosResponse<{ id: number, descricao: string, perguntas: Array<{ id: number, titulo: string, classificacao: { id: number, descricao: string }, obrigatorio: boolean, resposta: string }>}>> {
        return await Context.put(`/Formulario/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Form)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o formulário
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async Delete(Token: string, Form: { id: number, descricao: string, perguntas: Array<{ id: number, titulo: string, classificacao: { id: number, descricao: string }, obrigatorio: boolean, resposta: string }>}): Promise<any> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Formulario/' + Token + "/" + ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado,
            data: Form,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new Formulario();