import { AxiosResponse } from 'axios';
import axios from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

class Identificador {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista as unidades disponíveis vinculadas a uma empresa e centro de custo específico
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe o código do centro de custo (sigla)
     **/
    public async Get(Token: string, Empresa: Number, CentroDeCusto: string): Promise<AxiosResponse<Array<Objeto.Identificador>>> {
        // /Identificador/Teste/NzAyNjEyMzExNDYkYzI5ekpHNWxkRE15/0%2C2?Empresa=28&CentroDeCusto=SBR&ExibirMapaDoLoteamento=false&EfetuarDownload=false
        return await Context.get(`/Identificador/${Token}?Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}&ExibirMapaDoLoteamento=false&EfetuarDownload=false`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista as unidades disponíveis vinculadas a uma empresa e centro de custo específico
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe o código do centro de custo (sigla)
     **/
    public async GetUnidade(Token: string, Empresa: Number, CentroDeCusto: string, Local: number, SubLocal: number): Promise<AxiosResponse<Array<Objeto.Identificador>>> {
        // /Identificador/Teste/NzAyNjEyMzExNDYkYzI5ekpHNWxkRE15/0%2C2?Empresa=28&CentroDeCusto=SBR&ExibirMapaDoLoteamento=false&EfetuarDownload=false
        return await Context.get(`/Identificador/Teste/${Token}?0%2C2&Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}&Local=${Local}&SubLocal=${SubLocal}&ExibirMapaDoLoteamento=false&EfetuarDownload=false`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista as unidades disponíveis vinculadas a uma empresa e centro de custo específico
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe o código do centro de custo (sigla)
     **/
    public async FastGet(Token: string, Empresa: Number, CentroDeCusto: string): Promise<AxiosResponse<Array<Objeto.Identificador>>> {
        // /Identificador/Teste/NzAyNjEyMzExNDYkYzI5ekpHNWxkRE15/0%2C2?Empresa=28&CentroDeCusto=SBR&ExibirMapaDoLoteamento=false&EfetuarDownload=false
        return await Context.get(`/Identificador/Teste/${Token}?0%2C2&Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}&ExibirMapaDoLoteamento=false&EfetuarDownload=false`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista as unidades disponíveis vinculadas a uma empresa e centro de custo específico
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe o código do centro de custo (sigla)
     **/
    public async FastGetSintetico(Token: string, VisaoSintetica: boolean, Empresa: Number, CentroDeCusto: string): Promise<AxiosResponse<Array<Objeto.IdentificadorSintetico>>> {
        return await Context.get(`/Identificador/Teste2/${Token}?0%2C2&VisaoSintetica=${VisaoSintetica}&Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}&ExibirMapaDoLoteamento=false&EfetuarDownload=false`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };

    /* Sobre esta função *//**
     * Lista as unidades disponíveis vinculadas a uma empresa e centro de custo específico
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe o código do centro de custo (sigla)
     * @param {number} Local Informe o código do local (id)
     * @param {number} Sublocal Informe o código do sublocal (id)
     **/
    public async Get1(Token: string, Empresa: Number, CentroDeCusto: string, Local: number, Sublocal: number): Promise<AxiosResponse<Array<Objeto.Identificador>>> {
        return await Context.get(`/Identificador/${Token}?Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}&Local=${Local}&Sublocal=${Sublocal}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Retorna os filtros aplicaveis a empresa e centro de custo informado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe o código do centro de custo (sigla)
     **/
    public async FiltrosAplicaveis(Token: string, Empresa: Number, CentroDeCusto: string): Promise<AxiosResponse<Array<Objeto.Filtro>>> {
        return await Context.get(`/Identificador/FiltrosAplicaveis/${Token}/${Empresa}/${CentroDeCusto}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Lista as unidades disponíveis vinculadas a uma empresa e centro de custo específico
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe o código do centro de custo (sigla)
     **/
    public async Get3(Token: string, Empresa: Number, CentroDeCusto: string): Promise<AxiosResponse<{ imagemMapaDoLoteamento: { descricao: string, extensao: string, arquivo: string }, lista: Array<{ quadra: string, lotes: Array<{ lote: string, Posicao: Objeto.CoordenadaGeografica, objeto: Objeto.Identificador}>}>, statusDaInadimplencia: Array<{descricao: string, cor: string}>}>> {
        return await Context.get(`/Identificador/${Token}?Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}?ExibirMapaDoLoteamento=true`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Reserva o lote selecionado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async ReservarUnidades(Token: string, Identificador: Array<Objeto.Identificador>): Promise<AxiosResponse<Array<Objeto.Identificador>>> {
        return await Context.post(`/Identificador/ReservarUnidades/${Token}`, Identificador)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Reserva o lote selecionado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Timer Informe o tempo de reserva (em minutos)"
     **/
    public async ReservarUnidadesComTimer(Token: string, Timer: number, Identificador: Array<Objeto.Identificador>): Promise<AxiosResponse<Array<Objeto.Identificador>>> {
        return await Context.post(`/Identificador/ReservarUnidades/${Token}?TempoDeReserva=${Timer}`, Identificador)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  DELETE Controller  */
    /* Sobre esta função *//**
     * Disponibiliza o lote selecionado
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async DeletarReservas(Token: string, Identificador: Array<Objeto.Identificador>): Promise<AxiosResponse<Array<Objeto.Identificador>>> {
        return axios({
            method: 'DELETE',
            baseURL: `https://api.oneplus.gav.coredatacenter.net.br/Identificador/DeletarReservas/${Token}`,
            data: Identificador,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar categorias
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe o código do centro de custo (sigla)
     * @param {string} Sigla Informe a sigla da categoria (sigla)
     **/
    public async DeletarCategoria(Token: string, Empresa: Number, CentroDeCusto: string, Sigla: string): Promise<any> {
        return await Context.delete(`/Identificador/DeletarCategoriaDeReserva/${Token}/${Empresa}/${CentroDeCusto}/${Sigla}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* Sobre esta função *//**
     * Lista todas as categorias de reserva relacionadas a um centro de custo especifíco.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe o código do centro de custo (sigla)
     **/
        public async CategoriasDeReserva(Token: string, Empresa: Number, CentroDeCusto: string): Promise<any> {
            return await Context.get(`/Identificador/CategoriaDeReserva/${Token}/${Empresa}/${CentroDeCusto}`)
                .then((Response) => { return Response; })
                .catch((Exception) => { return Exception; });
        };
    /* #endregion */

    /* Sobre esta função *//**
     * Lista todas as categorias de reserva.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
        public async TodasCategoriasDeReserva(Token: string): Promise<any> {
            return await Context.get(`/Identificador/CategoriaDeReserva/${Token}`)
                .then((Response) => { return Response; })
                .catch((Exception) => { return Exception; });
        };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastrar nova categoria de reserva.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async NovaCategoriaDeReserva(Token: string, Identificador: Array<any>): Promise<any> {
        return await Context.post(`/Identificador/NovaCategoriaDeReserva/${Token}`, Identificador)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new Identificador();