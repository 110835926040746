import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import Lottie from 'react-lottie';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import io from "socket.io-client";
import { Button, Card, FormGroup, Icon, MenuItem, Switch } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select, MultiSelect } from "@blueprintjs/select";
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { 
    GridComponent,
    ColumnDirective, 
    ColumnsDirective, 
    Page, 
    Group, 
    Inject, 
    GroupSettingsModel, 
    Aggregate, 
    AggregateDirective, 
    AggregatesDirective, 
    AggregateColumnsDirective, 
    AggregateColumnDirective, 
    Toolbar, 
    ToolbarItems, 
    PdfExport, 
    Grid, 
    Filter, 
    FilterSettingsModel, 
    Reorder, 
    Column, 
    Sort, 
    SortSettingsModel, 
    PdfHeaderQueryCellInfoEventArgs,
    pdfQueryCellInfo,
    AggregateRowModel,
    Edit,
    EditSettingsModel,
    PdfQueryCellInfoEventArgs
} from "@syncfusion/ej2-react-grids";

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import { Localization } from '../../../Data/Listas';
import { CentroDeCusto, Identificador } from '../../../Services/Controllers';
import { Moeda } from '../../../Themes/TextFormat';
import { Objeto } from '../../../Services/Models';
import { NotificacaoInterna } from '../../../Services/Notification';
import * as LottieFiles from '../../../Data/Lottie';

import './App.css';
import Markers from './component';
import { image } from './image';
import * as Component from './style';
import './style.css';
import viewModel from './viewModel';

interface Store {
    empreendimento: string;
    unidade: string;
    valorUnidade: number;
    torre: string;
    apto: number;
    pavimento: string;
    qtdQuartos: number;
    qtdSemanas: number;
    cota: string;
    observacao: string;
    vista: string;
    id: number;
    status: number;
    unidades: Objeto.Identificador;
    sigla: string;
    lista: Array<Objeto.Identificador>;
    store: Array<Store>
};

loadCldr(
    require('../../../../node_modules/cldr-data/main/pt/currencies.json'),
    require('../../../../node_modules/cldr-data/main/pt/numbers.json'),
    require('../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
    require('../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
    require('../../../../node_modules/cldr-data/supplemental/numberingSystems.json')
);
setCulture('pt');
setCurrencyCode("BRL");
L10n.load(Localization);
var socket: any;
let dataStorage: Array<Store>;

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const [getExibirMapa, setExibirMapa] = useStateWithCallbackLazy<boolean>(false);

    /* #region  Variáveis da tabela de De-Para CNAEs */
    const [getListaCNAEs, setListaCNAEs] = useState<Array<{id: number, numero: string, cnae: string, insumo: string, servico: string}>>([]);

    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();

    const [getListaDeUnidades, setListaDeUnidades] = useState<Array<Objeto.Identificador>>([]);
    const [getListaDeDisponibilidade, setListaDeDisponibilidade] = useState<Array<Store>>([]);
    const timelineLoaded = useRef<boolean>(false);
    const [getCarregandoListaDeUnidades, setCarregandoListaDeUnidades] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    /* #endregion */

    /* #region  Variáveis do mapa */
    const [getListaDeMarcacoes, setListaDeMarcacoes] = useState<{ imagemMapaDoLoteamento: { descricao: string, extensao: string, arquivo: string }, lista: Array<{ quadra: string, lotes: Array<{ lote: string, Posicao: Objeto.CoordenadaGeografica, objeto: Objeto.Identificador }> }>, statusDaInadimplencia: Array<{ descricao: string, cor: string }> }>();
    const [getVisaoMapa, setVisaoMapa] = useState<number>(0)
    const [getExibirLegenda, setExibirLegenda] = useState<boolean>(false);
    const [getZoom, setZoom] = useState<Array<number>>([0.8, 0, 0]);
    const [getCarregandoListaDeMarcacoes, setCarregandoListaDeMarcacoes] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getCarregandoListaDeContratos, setCarregandoListaDeContratos] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    let dataStorage: Array<{id: number, numero: string, cnae: string, insumo: string, servico: string}>;
    /* #endregion */
    /* #endregion */

    useEffect(() => {
        async function componentDidMount() {
            try {

                await setCarregandoListaDeContratos(false, () => {})

                let Response = await axios({
                    method: 'GET',
                    baseURL: 'https://servicodados.ibge.gov.br/api/v2/cnae/classes',
                    headers: {}
                }).then((Response) => {return Response.data})
                .catch((Exception) => {return Exception})

                // let Response = await(await IBGE.AtividadesEconomicas(ContextSignIn.getContext().token)).data
                let CNAEs = [] as Array<{id: number, numero: string, cnae: string, insumo: string, servico: string}>
                await Response.map(async (data: {id: string, descricao: string}, index: number) => {
                    CNAEs.push({
                        id: index,
                        numero: data.id,
                        cnae: data.descricao,
                        insumo: '',
                        servico: '',
                    })
                })
                await setListaCNAEs(CNAEs)
                await setCarregandoListaDeContratos(true, () => {})
            }
            catch {

             }

        }
        componentDidMount();
    }, []);

    const toolbarOptions: ToolbarItems[] = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'PdfExport']
    
    const groupOptions: GroupSettingsModel = {
        // columns: ['Cliente', 'Operacao'],
        showGroupedColumn: true,
        showUngroupButton: true,
    }

    const filterOptions: FilterSettingsModel = {
        ignoreAccent: true,
        immediateModeDelay: 200,
        mode: "Immediate",
        type: 'Excel',
    }

    const sortOptions: SortSettingsModel = {

    }

    const editOptions: EditSettingsModel = {
        allowAdding: true, 
        allowDeleting: true, 
        allowEditing: true, 
        allowEditOnDblClick: true,
        mode: 'Normal',
        showConfirmDialog: true,
        showDeleteConfirmDialog: true
    }

    const actionComplete = async (args: any) => {
    
        if (args.requestType === 'save' && args.form)
        {
            /** cast string to integer value */

            if(args.action === 'add')
            {

            }
            if (args.action === 'edit')
            {
                
            }

        }
        else if (args.requestType === 'delete')
        {

        }

    }

    const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs | any) => {
        (args.cell as any).row.pdfGrid.repeatHeader = true;
        args.cell.gridRow.pdfGrid.columns.getColumn(0).width = 50;
        args.cell.gridRow.pdfGrid.columns.getColumn(0).textAlign = "Left";
    }

    const pdfQueryCellInfo = (args: PdfQueryCellInfoEventArgs | any) => {
        args.cell.height = 10;
    }

    let grid: Grid | null;
    const toolbarClick = (args: any) => {
        if (grid) {
            if (args.item.id === 'grid_pdfexport') {
                grid.pdfExport({
                    fileName: `DeParaCNAEs.pdf`,
                    pageOrientation: "Landscape",
                    header: {
                        fromTop: 0,
                        height: 100,
                        contents: [
                            {
                                position: { x: 0, y: 45 },
                                size: { height: 40, width: 80 },
                                src: image,
                                type: 'Image',
                            },
                            {
                                type: 'Text',
                                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:mm:ss")}`,
                                position: { x: 880, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 13 },
                            },
                        ]
                    },
                    footer: {
                        contents: [
                            {
                                type: 'Text',
                                value: `Powered by DigitalDEV`,
                                position: { x: 0, y: 0 },
                                style: { textBrushColor: "#000000", fontSize: 10 },
                            },
                            {
                                format: 'Página {$current} de {$total}',
                                pageNumberType: "Numeric",
                                position: { x: 910, y: 0 },
                                style: {
                                    fontSize: 13,
                                    hAlign: "Right",
                                    textBrushColor: '#000000',
                                },
                                type: 'PageNumber',
                            }
                        ],
                        fromBottom: 0,
                        height: 20,
                    },
                    theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 7,
                            // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
                        },
                        record: {
                            bold: false,
                            fontColor: "#000000",
                            fontSize: 7,
                            // font: new PdfTrueTypeFont(adventProFont, 7)
                        },
                        caption: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 7,
                            // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
                        }
                    },
                    allowHorizontalOverflow: false,
                });
            }
        }
    }

    const pdfExportComplete = () => {
        // if (grid) {
        //     (grid.columns[0] as Column).visible = true;
        //     (grid.columns[1] as Column).visible = true;
        // }
    }

    return (
        <Component.Container>
            <div className="endereco">
                <img src={IconeHome} alt="" />
                <div className="bar">|</div>
                <div className="place">De-Para CNAEs (NFSe)</div>
            </div>
            {getCarregandoListaDeContratos != true && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.LoadMaps,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height = {500}
                    width = {500}
                    style = {{ marginTop: "100px" }}
                />
                <div className="loading" style={{ margin: "-100px auto 0 auto", fontWeight: "bold" }}>{(getCarregandoListaDeContratos == undefined) ? "Aguarde, estamos carregando a lista de CNAEs" : ((getCarregandoListaDeContratos == false) ? "Aguarde, estamos carregando sua lista de CNAEs" : "Pronto, suas categorias logo serão exibidas!")}</div>
            </div>}
            {getCarregandoListaDeContratos == true && <Card style = {{ backgroundColor: "0", width: "100%", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none" }}>
                <div style = {{ width: "100%", overflowX: 'scroll' }}>
                    <GridComponent
                        dataSource = {getListaCNAEs} 
                        width = {"100%"}
                        height = {"100%"}
                        id = 'grid'
                        ref = {g => grid = g}
                        allowPaging = {true}
                        allowPdfExport = {true}
                        allowFiltering = {true}
                        allowReordering = {true}
                        allowSorting = {false}
                        sortSettings = {sortOptions}
                        editSettings = {editOptions}
                        toolbar = {toolbarOptions}
                        pdfHeaderQueryCellInfo = {pdfHeaderQueryCellInfo}
                        pdfQueryCellInfo = {pdfQueryCellInfo}
                        toolbarClick = {toolbarClick}
                        filterSettings = {filterOptions}
                        actionComplete = {actionComplete}
                        actionBegin = {
                            async (args: any) => {
                                if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin')
                                {
                                    args.filterChoiceCount = 100000;
                                }
                                else if (args.requestType == 'save')
                                {
                                    if(args.action === 'add')
                                    {
                                        
                                    }
                                    else if (args.action === 'edit')
                                    {

                                        let ListaCNAEs = [...getListaCNAEs];
                                        ListaCNAEs[args.rowIndex] = args.data
                                        setListaCNAEs(ListaCNAEs)

                                    }
                                }
                                else if (args.requestType == 'delete')
                                {

                                    

                                }
                            }
                        }
                        pdfExportComplete = {pdfExportComplete}
                        pageSettings = {{ pageSizes: [20, 50, 100], pageSize: 20 }}
                        locale = {"pt"}
                        currencyCode = {"BRL"}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='numero' headerText='Número' textAlign='Left' allowEditing = {false}/>
                            <ColumnDirective field='cnae' headerText='CNAE' textAlign='Left' allowEditing = {false}/>
                            <ColumnDirective field='insumo' headerText='Insumo' textAlign='Left' allowGrouping={true} />
                            <ColumnDirective field='servico' headerText='Serviço' textAlign='Left' allowGrouping={true} />
                        </ColumnsDirective>
                        <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, Edit]} />
                    </GridComponent>
                </div>
            </Card>}
        </Component.Container >
    );
    /* #endregion */
};

export default View;