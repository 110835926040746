import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class CentroDeCusto {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Retorna todos os centros de custos (obra) que o usuário possua permissão de acesso
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o id da empresa
     * @param {string} CentroDeCusto Informe a sigla do centro de custo
     **/
    public async Get(Token: string, Empresa: number | string, CentroDeCusto: string): Promise<AxiosResponse<Array<Objeto.CentroDeCusto>>> {
        return await Context.get(`/CentroDeCusto/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?Empresa=${Empresa}&CentroDeCusto=${CentroDeCusto}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region POST Controller  */
    /* Sobre esta função *//**
     * Autoriza a pessoa, atrelada ao usuário, o acesso a um determinado centro de custo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {number} Pessoa Informe o código da pessoa (Id). Esta pessoa deverá estar cadastrada como um usuário do sistema!
     **/
    public async AutorizarPermissaoDeAcesso(Token: string, GrupoDeEmpresas: number, Empresa: number, CentroDeCusto: string, Pessoa: number): Promise<AxiosResponse<any>> {
        return await Context.post(`/CentroDeCusto/AutorizarPermissaoDeAcesso/${Token}/${GrupoDeEmpresas}/${Empresa}/${CentroDeCusto}/${Pessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Cadastrar um novo centro de custo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o id da empresa
     * @param {string} Sigla Informe a sigla do centro de custo
     * @param {string} Descricao Informe a descricao do centro de custo
     **/
    public async Post(Token: string, Empresa: number, Sigla: string, Descricao: string): Promise<AxiosResponse<Objeto.CentroDeCusto>> {
        return await Context.post(`/CentroDeCusto/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Empresa}/${Sigla}/${Descricao}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar os dados do centro de custo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o id da empresa
     * @param {string} Sigla Informe a sigla do centro de custo
     * @param {string} Descricao Informe a descricao do centro de custo
     **/
    public async Put(Token: string, Empresa: number, Sigla: string, Descricao: string): Promise<AxiosResponse<Objeto.CentroDeCusto>> {
        return await Context.put(`/CentroDeCusto/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Empresa}/${Sigla}/${Descricao}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o centro de custo.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o id da empresa
     * @param {string} Sigla Informe a sigla do centro de custo
     **/
    public async Delete(Token: string, Empresa: number, Sigla: string): Promise<AxiosResponse<Objeto.CentroDeCusto>> {
        return await Context.delete(`/CentroDeCusto/${Token}/${Empresa}/${Sigla}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Remover a pessoa, atrelada ao usuário, o acesso a um determinado centro de custo
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Empresa Informe o código da empresa (id). A lista das empresas cadastradas poderá ser obtida ao executar a requisição "/Empresa/{Token}"
     * @param {string} CentroDeCusto Informe a sigla que identifica o centro de custo. A lista dos centros de custos cadastrados poderá ser obtido ao executar a requisição "/CentroDeCusto/{Token}/{Empresa}"
     * @param {number} Pessoa Informe o código da pessoa (Id). Esta pessoa deverá estar cadastrada como um usuário do sistema!
     **/
    public async RemoverPermissaoDeAcesso(Token: string, Empresa: number, CentroDeCusto: string, Pessoa: number): Promise<AxiosResponse<any>> {
        return await Context.post(`/CentroDeCusto/RemoverPermissaoDeAcesso/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}/${Empresa}/${CentroDeCusto}/${Pessoa}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new CentroDeCusto();