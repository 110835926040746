import { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

class Correios {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Retorna todos os centros de custos (obra) que o usuário possua permissão de acesso
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {string} CEP Informe o CEP (somente números)
     **/
    public async Get(Token: string, CEP: string): Promise<AxiosResponse<{CEP: string, Logradouro: string, Complemento: string, Bairro: string, lLcalidade: string, UF: string, Cidade: string}>> {
        return await Context.get(`/Correios/${Token}/${CEP}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new Correios();