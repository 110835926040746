import axios, { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

class Lead {
    static = {
        source: axios.CancelToken.source()
    }
    
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista todos os leads cadastrados
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
    **/
    public async Get(Token: string): Promise<AxiosResponse<Array<Objeto.Lead>>> {
        return await Context.get(`/Lead/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* Sobre esta função *//**
     * Lista todas as naturalidades
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
    **/
    public async Naturalidades(Token: string): Promise<AxiosResponse<Array<{ id: number, cidade: string, uf: string }>>> {
        return await Context.get(`/Lead/Naturalidades/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista todos os leads cadastrados no formato Kanban
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
    **/
    public async Kanban(Token: string): Promise<AxiosResponse<Array<Objeto.Lead>>> {
        return await Context.get(`/Lead/${Token}?VisaoKanban=true`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };


    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista todos os leads cadastrados no formato Kanban
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Id Informe o Id do lead
     * @param {string} Nome Informe o nome do lead
     * @param {string} Telefone Informe o telefone do lead
     * @param {string} Email Informe o email do lead
     * @param {number} SalaDeVenda Informe o id da sala de venda
     * @param {number} Area Informe o id da area
    **/
    public async BuscaPersonalizada(Token: string, VisaoKanban: boolean, EmSala: boolean, Id: number | string, Nome: string, Telefone: string, Email: string, SalaDeVenda: number  | string, Area: number | string): Promise<AxiosResponse<Array<Objeto.Lead>>> {
        return await Context.get(`/Lead/${Token}?VisaoKanban=${VisaoKanban}&EmSala=${EmSala}&Id=${Id}&Nome=${Nome}&Telefone=${Telefone}&Email=${Email}&SalaDeVenda=${SalaDeVenda}&Area=${Area}`, {
            cancelToken: this.static.source.token
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };

    /** CANCEL REQUEST */
    public async Cancel() {
        this.static.source.cancel();
        this.static.source = axios.CancelToken.source();
    }

    /* //#region GET Controller */
    /* Sobre esta função *//**
     * Lista todos os tipos de dependentes
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
    **/
    public async ClassificacaoDoDependente(Token: string): Promise<AxiosResponse<Array<Objeto.Lead>>> {
        return await Context.get(`/Lead/ClassificacaoDoDependente/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };

    /* //#region GET Controller */
    /* Sobre esta função *//**
     * Lista todos os tipos de locais de captação
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     **/
    public async LocaisDeCaptacao(Token: string): Promise<AxiosResponse<Array<{ empresa: Objeto.Empresa, locaisDeCaptacao: Array<Object> }>>> {
        return await Context.get(`/Lead/LocaisDeCaptacao/${Token}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo lead
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.Lead} Lead Informe os dados do lead
     **/
    public async Post(Token: string, Lead: Objeto.Lead): Promise<AxiosResponse<Objeto.Lead>> {
        return await Context.post(`/Lead/${Token}`, Lead)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo lead em uma sala de vendas e area
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.Lead} Lead Informe os dados do lead
     * @param {number} IdSalaDeVendas Informe o Id da sala
     * @param {number} IdArea Informe o Id da area
     **/
    public async InserirEmSalaDeVendas(Token: string, IdSalaDeVendas: number | string, IdArea: number | string, Lead: Objeto.Lead): Promise<AxiosResponse<Objeto.Lead>> {
        return await Context.post(`/Lead/${Token}?SalaDeVenda=${IdSalaDeVendas}&Area=${IdArea}`, Lead)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o lead
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Id Informe o código do lead (id)
     **/
    public async Delete(Token: string, Id: number): Promise<AxiosResponse<Array<Objeto.Lead>>> {
        return await Context.delete(`/Lead/${Token}/${Id}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  PUT Controller  */
    /* Sobre esta função *//**
     * Altera os dados do lead
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.Lead} Lead Informe os dados do lead
     **/
    public async Put(Token: string, Lead: Objeto.Lead): Promise<AxiosResponse<Objeto.Lead>> {
        return await Context.put(`/Lead/${Token}`, Lead)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastra uma nova tarefa
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.NovasTarefas} Tarefas Informe os dados das tarefas
     **/
    public async NovasTarefas(Token: string, IdSalaDeVenda: number, IdArea: number, IdLead: number, Tarefas: Array<Objeto.NovasTarefas>): Promise<AxiosResponse<Array<Objeto.NovasTarefas>>> {
        return await Context.post(`/Lead/NovasTarefas/${Token}/${IdSalaDeVenda}/${IdArea}/${IdLead}`, Tarefas)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo brinde para o lead
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Id Informe o Id do lead
     * @param {object} Brinde Informe os dados do brinde
    **/
    public async NovoBrinde(Token: string, Id: number, Brinde: { brinde: {id: number, descricao: string}, tipo: {id: number, descricao: string}, fornecedor: Objeto.Fornecedor, validadeInicial: Date, validadeFinal: Date, quantidade: number, valorUnitario: number}): Promise<AxiosResponse<{ brinde: {id: number, descricao: string}, tipo: {id: number, descricao: string}, fornecedor: Objeto.Fornecedor, validadeInicial: Date, validadeFinal: Date, quantidade: number, valorUnitario: number}>> {
        return await Context.post(`/Lead/NovoBrinde/${Token}/${Id}`, Brinde)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };

    /* #region POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo integrante no controle de sala
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Id Informe o Id do lead
     * @param {object} ControleDeSala Informe os dados do integrante a ser inserido no controle de sala
    **/
    public async NovoIntegranteDoControleDeSala(Token: string, Id: number, ControleDeSala: { controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}): Promise<AxiosResponse<{ controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string }, email: { classificacao: number, descricao: string }}}>> {
        return await Context.post(`/Lead/NovoIntegranteDoControleDeSala/${Token}/${Id}`, ControleDeSala)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastra uma nova anotação
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.NovasAnotacoes} Anotacoes Informe os dados das anotacoes
     * @param {number} IdLead Informe o id do lead.
     * @param {number} IdSalaDeVenda Informe o id da sala de venda.
     * @param {number} IdArea Informe o id do da area.
     **/
    public async NovasAnotacoes(Token: string, IdSalaDeVenda: number, IdArea: number, IdLead: number, Anotacoes: Array<Objeto.NovasAnotacoes>): Promise<AxiosResponse<Array<Objeto.NovasAnotacoes>>> {
        return await Context.post(`/Lead/NovasAnotacoes/${Token}/${IdSalaDeVenda}/${IdArea}/${IdLead}`, Anotacoes)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastrar uma nova movimentação do lead no funil de vendas.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}".
     * @param {number} IdLead Informe o id do lead.
     **/
    public async NovaMovimentacaoNoFunil(Token: string, IdLead: number, Movimentacao: {salaDeVenda: {id: number, descricao: string}, area: {id: number, descricao: string}, funil: {id: number, descricao: string}, posicaoDoFunil: {id: number, descricao: string}, datasDeAlteracoes: Array<any>}): Promise<AxiosResponse<any>> {
        return await Context.post(`/Lead/NovaMovimentacaoNoFunil/${Token}/${IdLead}`, Movimentacao)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastra um novo email.
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}".
     * @param {Objeto.NovosEmailsEnviados} EmailsEnviados Informe os dados dos emails enviados.
     * @param {number} IdLead Informe o id do lead.
     * @param {number} IdSalaDeVenda Informe o id da sala de venda.
     * @param {number} IdArea Informe o id do da area.
     **/
    public async NovosEmailsEnviados(Token: string, IdSalaDeVenda: number, IdArea: number, IdLead: number, EmailsEnviados: Array<Objeto.NovosEmailsEnviados>): Promise<AxiosResponse<Array<Objeto.NovosEmailsEnviados>>> {
        return await Context.post(`/Lead/NovosEmailsEnviados/${Token}/${IdSalaDeVenda}/${IdArea}/${IdLead}`, EmailsEnviados)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar status do lead
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} IdLead Informe o id do lead
     * @param {boolean} Ativo Informe o status do lead
     **/
    public async AlterarStatus(Token: string, IdLead: number, Ativo: boolean): Promise<AxiosResponse<any>> {
        return await Context.put(`/Lead/AlterarStatus/${Token}/${IdLead}/${Ativo}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar uma tarefa
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.NovasTarefas} Tarefas Informe os dados das tarefas
     **/
    public async AlterarTarefas(Token: string, IdLead: number, Tarefas: Array<Objeto.NovasTarefas>): Promise<AxiosResponse<Array<Objeto.NovasTarefas>>> {
        return await Context.put(`/Lead/AlterarTarefas/${Token}/${IdLead}`, Tarefas)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar uma anotação
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.NovasAnotacoes} Anotacoes Informe os dados das anotacoes
     * @param {number} IdLead Informe o id do lead.
     * @param {number} IdSalaDeVenda Informe o id da sala de venda.
     * @param {number} IdArea Informe o id do da area.
     **/
    public async AlterarAnotacoes(Token: string, IdLead: number, Anotacoes: Array<Objeto.NovasAnotacoes>): Promise<AxiosResponse<Array<Objeto.NovasAnotacoes>>> {
        return await Context.put(`/Lead/AlterarAnotacoes/${Token}/${IdLead}`, Anotacoes)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar um email
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} IdLead Informe o id do lead.
     * @param {Objeto.NovosEmailsEnviados} EmailsEnviados Informe os dados dos emails enviados
     **/
    public async AlterarEmailsEnviados(Token: string, IdLead: number, EmailsEnviados: Array<Objeto.NovosEmailsEnviados>): Promise<AxiosResponse<Array<Objeto.NovosEmailsEnviados>>> {
        return await Context.put(`/Lead/AlterarEmailsEnviados/${Token}/${IdLead}`, EmailsEnviados)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Altera o integrante do controle de sala
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Id Informe o Id do lead
     * @param {object} ControleDeSala Informe os dados do integrante a ser inserido no controle de sala
    **/
    public async AlterarIntegranteDoControleDeSala(Token: string, Id: number, ControleDeSala: { controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}): Promise<AxiosResponse<{ controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string }, email: { classificacao: number, descricao: string }}}>> {
        return await Context.put(`/Lead/AlterarIntegranteDoControleDeSala/${Token}/${Id}`, ControleDeSala)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };

    /* #region  DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar as anotações atreladas ao lead
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.NovasAnotacoes} Anotacoes Informe os dados das anotacoes
     * @param {number} Id Informe o id do lead
     **/
    public async DeletarAnotacoes(Token: string, Id: number, Anotacoes: Array<Objeto.NovasAnotacoes>): Promise<AxiosResponse<any>> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Lead/DeletarAnotacoes/' + Token + '/' + Id,
            data: Anotacoes,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar ao brinde atrelado ao lead
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.NovasAnotacoes} Anotacoes Informe os dados das anotacoes
     * @param {number} Id Informe o id do lead
     **/
    public async DeletarBrinde(Token: string, Id: number, Brinde: { brinde: {id: number, descricao: string}, tipo: {id: number, descricao: string}, fornecedor: Objeto.Fornecedor, validadeInicial: Date, validadeFinal: Date, quantidade: number, valorUnitario: number}): Promise<AxiosResponse<any>> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Lead/DeletarBrinde/' + Token + '/' + Id,
            data: Brinde,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar as tarefas atreladas ao lead
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.NovasAnotacoes} Anotacoes Informe os dados das anotacoes
     * @param {number} Id Informe o id lead
    **/
    public async DeletarTarefas(Token: string, Id: number, Tarefas: Array<Objeto.NovasTarefas>): Promise<AxiosResponse<any>> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Lead/DeletarTarefas/' + Token + '/' + Id,
            data: Tarefas,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar as tarefas atreladas ao lead
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {Objeto.NovasAnotacoes} Anotacoes Informe os dados das anotacoes
     * @param {number} Id Informe o id do lead
     **/
    public async DeletarEmailsEnviados(Token: string, Id: number, EmailsEnviados: Array<Objeto.NovosEmailsEnviados>): Promise<AxiosResponse<Array<Objeto.NovosEmailsEnviados>>> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Lead/DeletarEmailsEnviados/' + Token + '/' + Id,
            data: EmailsEnviados,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o integrante do controle de sala
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {number} Id Infome o id do lead
     * @param {Objeto.NovasAnotacoes} IntegrateControleDeSala Informe os dados do integrante do controle de sala
     **/
    public async DeletarIntegrateDoControleDeSala(Token: string, Id: number, IntegrateControleDeSala: { controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string } | null, email: { classificacao: number, descricao: string } | null}}): Promise<AxiosResponse<{ controleDeSala: { id: number, descricao: string }, fluxoDeSala: { etapa: number, descricao: string }, salaDeVenda: { id: number, descricao: string }, pessoa: { id: number, nome: string, cargo: { id: number, nome: string }, email: { classificacao: number, descricao: string }}}>> {
        return axios({
            method: 'DELETE',
            baseURL: ((window.location.href.includes("test") || window.location.href.includes("local")) ? "http://testapi.oneplus.dev.br" : "http://api.oneplus.gav.coredatacenter.net.br") + '/Lead/DeletarIntegranteDoControleDeSala/' + Token + '/' + Id,
            data: IntegrateControleDeSala,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */
};

export default new Lead();