import React from 'react';
import { WidthCheck as ContextWidthCheck } from '../../../../../Context';
import { Titulos, Legenda, Filtro, Visualizacao } from '../index';
import '../../leaflet.css';

function App({ getExibirLegenda, setExibirLegenda, getLegendaOption, setLegendaOption, getTileLayer, setTileLayer, getListaPickerQuadra, setConsumer, optionsToChoose, Icon, tiposInadimplencia, getListaFaixa, getListaCategoria, Calcularcenario, columnsData, visao, statusData, Image }: any) {

    return (
        <div className="cenario" style={getExibirLegenda === true ? { opacity: "1", width: false/*ContextWidthCheck.getContext().width <= 660*/ ? "6cm" : "12cm", height: "auto", position: 'absolute', zIndex: 20000, boxShadow: "0 0 5px gray", borderRadius: "5px", backgroundColor: "#ffffff", margin: "10px 0 0 150px" } : { width: "4cm", height: "auto", position: "absolute", zIndex: 20000, boxShadow: "0 0 5px gray", borderRadius: "5px", backgroundColor: "#ffffff", margin: "10px 0 0 150px" }}>
            {/* Títulos */}
            <Titulos getExibirLegenda={getExibirLegenda} setExibirLegenda={setExibirLegenda} getLegendaOption={getLegendaOption} setLegendaOption={setLegendaOption} optionsToChoose={optionsToChoose} Icon={Icon} />
            {/* Legenda */}
            {getExibirLegenda && getLegendaOption === 0 && <Legenda columnsData={columnsData} visao={visao} statusData={statusData} tiposInadimplencia={tiposInadimplencia} getListaFaixa={getListaFaixa} getListaCategoria={getListaCategoria} Calcularcenario={Calcularcenario} />
            }
            {/* Filtro */}
            {getExibirLegenda && getLegendaOption === 1 && <Filtro getListaPickerQuadra={getListaPickerQuadra} setConsumer={setConsumer} />
            }
            {/* Visualização */}
            {getExibirLegenda && getLegendaOption === 2 && <Visualizacao getTileLayer={getTileLayer} setTileLayer={setTileLayer} Image={Image} />
            }
        </div>
    );
}

export default App;