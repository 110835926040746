import React, { memo } from 'react';
import { MapContainer, Marker, Popup, TileLayer, Polygon, useMapEvents, MapConsumer } from 'react-leaflet-rotate/react-leaflet'
import { Opcoes, PointsLote, PointsQuadra, Polygons, OthersRuas, OthersNomes, OthersPolygons, OthersDelimitacao } from '../index';

function App({ getConsumer, MyComponent, setConsumer, setRotate, getRotate, polygons, setPolygons, areas, getTileLayer, componentRef, localRefresh, setLocalRefresh, refresh, setRefresh, visao, getCenter, getMarkersSize, getRuaSize, getLocalData }: any) {

  return (
    <div style={{ height: "100%" }} ref={componentRef} id="mapid">
      <MapContainer rotate={true} zoomDelta={0.1} zoomSnap={0} attributionControl={false} center={getCenter} minZoom={15} zoom={17}>
        {getTileLayer === 0 && <TileLayer
          ref={(e: any) => null}
          //url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy;'
        />}
        {getTileLayer === 1 && <TileLayer
          ref={(e: any) => null}
          url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy;'
        />}
        {getConsumer[0] &&
          <MapConsumer>
            {(map: any) => {
              map.flyTo([getConsumer[1][0], getConsumer[1][1]], 19, {
                noMoveStart: true,
                duration: 0
              })
              //map.setZoom(20)
              setConsumer([false, [0, 0]])
              return null
            }}
          </MapConsumer>}
        {getRotate[0] &&
          <MapConsumer>
            {(map: any) => {
              map.setBearing(getRotate[1]);

              setRotate([false, 0]);
              return null;
            }}
          </MapConsumer>}

        {/* POINTS */}
        {/* QUADRA */}
        {polygons && <PointsQuadra polygons={polygons} getMarkersSize={getMarkersSize} />}
        {/* LOTE */}
        {polygons && <PointsLote polygons={polygons} getMarkersSize={getMarkersSize} />}
        {/* Polygons */}
        {polygons && refresh && localRefresh && visao === 0 && <Polygons refresh={refresh} setRefresh={setRefresh} localRefresh={localRefresh} polygons={polygons} setLocalRefresh={setLocalRefresh} setPolygons={setPolygons} visaoSelecionada={0} getLocalData={getLocalData} />}
        {polygons && refresh && localRefresh && visao === 1 && <Polygons refresh={refresh} setRefresh={setRefresh} localRefresh={localRefresh} polygons={polygons} setLocalRefresh={setLocalRefresh} setPolygons={setPolygons} visaoSelecionada={1} getLocalData={getLocalData} />}
        {polygons && refresh && localRefresh && getLocalData && visao === 2 && <Polygons refresh={refresh} setRefresh={setRefresh} localRefresh={localRefresh} polygons={polygons} setLocalRefresh={setLocalRefresh} setPolygons={setPolygons} visaoSelecionada={2} getLocalData={getLocalData} />}
        {polygons && refresh && localRefresh && getLocalData && visao === 3 && <Polygons refresh={refresh} setRefresh={setRefresh} localRefresh={localRefresh} polygons={polygons} setLocalRefresh={setLocalRefresh} setPolygons={setPolygons} visaoSelecionada={3} getLocalData={getLocalData} />}
        {/* ÁREAS */}
        {/* RUAS */}
        {areas && false && <OthersRuas areas={areas} getRuaSize={getRuaSize} getTileLayer={getTileLayer} />}
        {/* NOMES */}
        {areas && refresh && <OthersNomes areas={areas} getMarkersSize={getMarkersSize} getTileLayer={getTileLayer} />}
        {/* POLYGONS */}
        {areas && refresh && <OthersPolygons areas={areas} />}
        {/* DELIMITAÇÃO */}
        {areas && refresh && <OthersDelimitacao areas={areas} />}
        <MyComponent />
      </MapContainer>
    </div>
  )
}

export default App;