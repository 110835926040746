import { AxiosResponse } from 'axios';
import axios from 'axios';
import { Objeto } from '../../Models';
import Context from '../../context';

import { SignIn as ContextSignIn } from '../../../Context';

class Fornecedor {

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Listar todos os fornecedores que o usuário possua permissão de acesso.
     **/
    public async Get(Token: string): Promise<AxiosResponse<Array<Objeto.Fornecedor>>> {
        return await Context.get(`/Fornecedor/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Busca um fornecedor por um cpf ou cnpj especifico
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} CPFCNPJ Informe o CPF ou CNPJ do fornecedor (somente números)
     **/
    public async CPFCNPJ(Token: string, CPFCNPJ: string): Promise<AxiosResponse<Objeto.Fornecedor>> {
        return await Context.get(`/Fornecedor/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}?CPFCNPJ=${CPFCNPJ}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* Sobre esta função *//**
     * Cadastrar novo fornecedor
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Fornecedor Informe o objeto do fornecedor que será adcionado no banco de dados"
     **/
    public async Post(Token: string, Fornecedor: Objeto.Fornecedor): Promise<AxiosResponse<Objeto.Fornecedor>> {
        return await Context.post(`/Fornecedor/${Token}/${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Fornecedor)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region PUT Controller  */
    /* Sobre esta função *//**
     * Alterar os dados do fornecedor
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Fornecedor Informe o objeto do brinde que será alterado no banco de dados"
     **/
    public async Put(Token: string, Fornecedor: Objeto.Fornecedor): Promise<AxiosResponse<Objeto.Fornecedor>> {
        return await Context.put(`/Fornecedor/${Token}?GrupoDeEmpresa=${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`, Fornecedor)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region DELETE Controller  */
    /* Sobre esta função *//**
     * Deletar o fornecedor 
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}"
     * @param {object} Fornecedor Informe o id do fornecedor que será deletado no banco de dados"
     **/
    public async Delete(Token: string, Fornecedor: number): Promise<any> {
        return await Context.delete(`/Fornecedor/${Token}/${Fornecedor}?GrupoDeEmpresa=${ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado}`)
            .then((Response) => { return Response; })
            .catch((Exception) => { return Exception; });
    };
    /* #endregion */

}

export default new Fornecedor();