import React, { memo } from 'react';
import { Marker } from 'react-leaflet-rotate/react-leaflet';
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';

function App({ polygons, getMarkersSize }: any) {

    return polygons.lista.map((quadra: any, quadraIndex: any) => {
        return quadra.lotes.map((lote: any, loteIndex: any) => {
            return <Marker interactive={false} icon={divIcon({
                html: renderToStaticMarkup(
                    <div style={{ backgroundColor: "#FFFFFF", pointerEvents: "none", opacity: `${getMarkersSize[3]}`, display: "flex", alignItems: "center", justifyContent: "center", color: "black", borderRadius: "50%", width: `${getMarkersSize[1]}px`, height: `${getMarkersSize[1]}px`, border: "0.001cm black solid" }}>
                        <span style={{ fontWeight: "bold", pointerEvents: "none", fontSize: `${getMarkersSize[2]}px`, }}>{lote.lote}</span>
                    </div>
                )
            })} position={[lote.center.latitude, lote.center.longitude]} />
        })
    })
}

export default memo(App);