import React from 'react';
import { WidthCheck as ContextWidthCheck } from '../../../../../../Context';

function App({ Calcularcenario, statusData, visao, tiposInadimplencia, getListaFaixa, getListaCategoria }: any) {

    return (
        <div style={{ width: ContextWidthCheck.getContext().width <= 660 ? "30%" : "15%", borderRight: "1px solid #E1E1E1", fontSize: "12px", fontWeight: 600 }}>
            {/* ====================DISPONIBILIDADE==================== */}
            {visao === 0 && statusData.map((status: { id: number, name: string, color: string }, statusIndex: number) => (
                <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: statusIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div>{Calcularcenario(status.id, 1)}</div>
                </div>
            ))}
            {/* ====================INADIMPLENCIA==================== */}
            {visao === 1 && tiposInadimplencia.map((Item: any, ItemIndex: number) =>
                <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: ItemIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div>{Calcularcenario(Item.descricao, 1)}</div>
                </div>
            )}
            {/* ====================FAIXA DE PREÇO==================== */}
            {visao === 2 && getListaFaixa.map((Item: any, ItemIndex: number) =>
                <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: ItemIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div>{Calcularcenario(Item.faixa, 1)}</div>
                </div>
            )}
            {/* ====================CATEGORIA DE PREÇO==================== */}
            {visao === 3 && getListaCategoria.map((Item: any, ItemIndex: number) =>
                <div style={{ display: "flex", width: "100%", height: "40px", alignItems: "center", padding: "0 0 0 10px", borderBottom: ItemIndex === (statusData.length - 1) ? "unset" : "1px solid #E8E8E8" }}>
                    <div>{Calcularcenario(Item.Categoria_de_preco, 1)}</div>
                </div>
            )}
        </div>
    );
}

export default App;