import { Objeto } from '../../Services/Models';

class SignIn {
    constructor() {
        try {
            var Resultado = localStorage.getItem("ContextSignIn");
        }
        catch {
            localStorage.setItem("ContextSignIn", JSON.stringify(<{ token: string, pessoa: Objeto.Pessoa, UltimoGrupoDeEmpresasAcessado: string|undefined, IdUltimoGrupoDeEmpresasAcessado: number|undefined, cargosPorGrupoDeEmpresa: Array<{grupoDeEmpresas: { id: number, descricao: string, empresas: Objeto.Empresa}, cargo: {id: number, nome: string}}>}>{
                token: "",
                pessoa: {
                    id: 0,
                    cpf: "",
                    nome: "",
                    natureza: 0,
                    dataDeNascimento: new Date(),
                    nacionalidade: null,
                    emails: [],
                    documentoPessoal: null,
                    rg: null,
                    creci: null,
                    estadoCivil: 0,
                    documentoDeEstadoCivil: null,
                    regimeDeBens: null,
                    necessarioAssinaturaDoConjuge: false,
                    conjuge: null,
                    endereco: null,
                    documentoEndereco: null,
                    telefones: [],
                    ocupacao: null
                },
                UltimoGrupoDeEmpresasAcessado: "",
                IdUltimoGrupoDeEmpresasAcessado: 0,
                cargosPorGrupoDeEmpresa: []
            }));
        }
    }
    /* Sobre esta função *//**
     * Armazena um registro no repositório local e no cache da aplicação
     * @param {string} Registro Informe o registro a ser armazenado
     **/
    public setContext(Registro: { token: string, pessoa: Objeto.Pessoa, UltimoGrupoDeEmpresasAcessado: string|undefined, IdUltimoGrupoDeEmpresasAcessado: number|undefined, cargosPorGrupoDeEmpresa: Array<{grupoDeEmpresas: { id: number, descricao: string, empresas: Objeto.Empresa}, cargo: {id: number, nome: string}}>  } | undefined): void {
        if (Registro)
        {
            localStorage.setItem("ContextSignIn", JSON.stringify(Registro));
        }
        else
        {
            localStorage.setItem("ContextSignIn", JSON.stringify(<{ token: string, pessoa: Objeto.Pessoa, UltimoGrupoDeEmpresasAcessado: string|undefined, IdUltimoGrupoDeEmpresasAcessado: number|undefined, cargosPorGrupoDeEmpresa: Array<{grupoDeEmpresas: { id: number, descricao: string, empresas: Objeto.Empresa}, cargo: {id: number, nome: string}}> }>{
                token: "",
                pessoa: {
                    id: 0,
                    cpf: "",
                    nome: "",
                    natureza: 0,
                    dataDeNascimento: new Date(),
                    nacionalidade: null,
                    emails: [],
                    documentoPessoal: null,
                    rg: null,
                    creci: null,
                    estadoCivil: 0,
                    documentoDeEstadoCivil: null,
                    regimeDeBens: null,
                    necessarioAssinaturaDoConjuge: false,
                    conjuge: null,
                    endereco: null,
                    documentoEndereco: null,
                    telefones: [],
                    ocupacao: null
                },
                UltimoGrupoDeEmpresasAcessado: "",
                IdUltimoGrupoDeEmpresasAcessado: 0,
                cargosPorGrupoDeEmpresa: []
            }));
        }
    };
    /* Sobre esta função *//**
     * Retorna o registro armazenado no repositório local
     **/
    public getContext(): { token: string, pessoa: Objeto.Pessoa, UltimoGrupoDeEmpresasAcessado: string|undefined, IdUltimoGrupoDeEmpresasAcessado: number|undefined, cargosPorGrupoDeEmpresa: Array<{grupoDeEmpresas: { id: number, descricao: string, empresas: Objeto.Empresa}, cargo: {id: number, nome: string}}>  } {
        return JSON.parse(localStorage.getItem("ContextSignIn") ?? "") as { token: string, pessoa: Objeto.Pessoa, UltimoGrupoDeEmpresasAcessado: string|undefined, IdUltimoGrupoDeEmpresasAcessado: number|undefined, cargosPorGrupoDeEmpresa: Array<{grupoDeEmpresas: { id: number, descricao: string, empresas: Objeto.Empresa}, cargo: {id: number, nome: string}}>};
    };
};

export default new SignIn();