import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from "axios";
import moment from 'moment';

import { lighten, modularScale } from 'polished';
import Lottie from 'react-lottie';
import { Button, Card, FormGroup, MenuItem, FileInput, Menu, Icon, Collapse, Divider, Popover, Position, Dialog, AnchorButton, Classes, Code, H5, Intent, InputGroup, HTMLSelect, Radio, Drawer, Switch, TextArea } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select, MultiSelect } from "@blueprintjs/select";
import { DateInput, TimePicker, TimePrecision  } from "@blueprintjs/datetime";
import { extend } from '@syncfusion/ej2-base';
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Group, Inject, GroupSettingsModel, Aggregate, AggregateDirective, AggregatesDirective, AggregateColumnsDirective, AggregateColumnDirective, Toolbar, ToolbarItems, PdfExport, Grid, Filter, FilterSettingsModel, Reorder, Column, Sort, SortSettingsModel, PdfHeaderQueryCellInfoEventArgs, ColumnMenu, Resize } from "@syncfusion/ej2-react-grids";
import { ToolbarSettingsModel, FileManager, FileManagerSettingsModel, QuickToolbarSettingsModel } from '@syncfusion/ej2-react-richtexteditor';
import CodeMirror from 'codemirror';
import { createElement } from '@syncfusion/ej2-base';
import ReactTooltip from 'react-tooltip';

import { IconeHome } from '../../../../Assets';
import { SignIn as ContextSignIn } from '../../../../Context';
import { Localization, TiposComponentes } from '../../../../Data/Listas';
import { CentroDeCusto, FunilDeVendas, SalaDeVendas, Areas, Logon, Brinde, Fornecedor, Correios, Pessoa, Cargo, Empresa, SoftwareExterno, ControleDeSala } from '../../../../Services/Controllers';
import { Objeto } from '../../../../Services/Models';
import { CPF, CEP, Moeda, Numero, Telefone, CNPJ, Data } from '../../../../Themes/TextFormat';
import { NotificacaoInterna } from '../../../../Services/Notification';
import * as LottieFiles from '../../../../Data/Lottie';
import { Banco, Bandeira, MaquinaDeCartao, MeioDePagamento, Operacao, UF } from '../../../../Data/Listas';

import './App.css';
import { image } from './image';
import * as Component from './style';
import viewModel from './viewModel';
import ReceitaFederal from '../../../../Services/Controllers/ReceitaFederal';

loadCldr(
    require('../../../../../node_modules/cldr-data/main/pt/currencies.json'),
    require('../../../../../node_modules/cldr-data/main/pt/numbers.json'),
    require('../../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
    require('../../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
    require('../../../../../node_modules/cldr-data/supplemental/numberingSystems.json'));
setCulture('pt');
setCurrencyCode("BRL");

L10n.load({
    'pt': {
        'kanban': {
            'items': 'itens',
            'min': 'Min',
            'max': 'Max',
            'cardsSelected': 'Cards selecionados',
            'addTitle': 'adicionar titulo',
            'editTitle': 'editar titulo',
            'deleteTitle': 'detelar titulo',
            'deleteContent': 'deletar conteudo',
            'save': 'salvar',
            'delete': 'deletar',
            'cancel': 'cancelar',
            'yes': 'Sim',
            'no': 'Não',
            'close': 'Fechar',
            'noCard': 'sem card',
            'unassigned': 'sem assinatura'
        }
    }
});

var fonteDeDados: any; 

let info: Object[];

interface KanbanDataModel {
    Id?: string;
    Title?: string;
    Status?: string;
    Summary?: string;
    Type?: string;
    Priority?: string;
    Tags?: string;
    Estimate?: number;
    Assignee?: string;
    RankId?: number;
    Color?: string;
}

interface IDialogExampleState {
    autoFocus: boolean;
    canEscapeKeyClose: boolean;
    canOutsideClickClose: boolean;
    enforceFocus: boolean;
    isOpen: boolean;
    usePortal: boolean;
}

const View: React.FC<viewModel> = (viewModel) => {

    /* #region  Variáveis  */
    const PropriedadesDeCalendario = {
        months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    };
    const [getFormularioID, setFormularioID] = useState<number>(-1);
    const [getFormularioCPF, setFormularioCPF] = useState<string>("");
    const [getFormularioNome, setFormularioNome] = useState<string>("");

    const PickerFornecedor = Select.ofType<Objeto.Fornecedor>();
    const PickerNomeBrinde = Select.ofType<{id: number, descricao: string}>();
    const PickerTipoDeBrinde = Select.ofType<{ id: number, descricao: string }>();

    const [getTiposDeComponentesFormulario, setTiposDeComponenesFormulario] = useState<Array<{ id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>>(TiposComponentes);

    const [getSelectCustomComponent, setSelectCustomComponent] = useState<{id: number, titulo: string, classificacao: {id: number, descricao: string}, obrigatorio: boolean, resposta: string}>();

    const [getListaDeLeads, setListaDeLeads] = useState<{kanbanData: Array<Objeto.LeadKanban>}>({kanbanData: []});

    const [getListaFasesDoFunil, setListaFasesDoFunil] = useState<Array<{id: number, descricao: string}>>([]);
    const [getItemFunil, setItemFunil] = useState<string>("");
    const [getItemFunilSelecionado, setItemFunilSelecionado] = useState<{id: number, descricao: string}>();

    const [getCarregandoListaDeContratos, setCarregandoListaDeContratos] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    const [getCollapse, setCollapse] = useState(false);

    const [getAlterarItemFunil, setAlterarItemFunil] = useState<boolean>(false);
    const [getDragStart, setDragStart] = useState(false);

    const [getListaDeEmpresas, setListaDeEmpresas] = useState<Array<Objeto.Empresa>>([]);
    const [getItemPickerEmpresas, setItemPickerEmpresas] = useState<Objeto.Empresa | undefined>();
    const [getIdEmpresa, setIdEmpresa] = useState<number>(0);
    const [getNomeDaEmpresa, setNomeDaEmpresa] = useState<string>("");
    const [getRazaoSocialDaEmpresa, setRazaoSocialDaEmpresa] = useState<string>("");
    const [getCnpjDaEmpresa, setCnpjDaEmpresa] = useState<string>("");

    const [getListaDeCentrosDeCusto, setListaDeCentrosDeCusto] = useState<Array<{empresaNome: string, sigla: string, descricao: string, empresa: Objeto.Empresa }>>([]);
    const [getDescriptionCentroDeCusto, setDescriptionCentroDeCusto] = useState<string>("");
    const [getIdCentroDeCusto, setIdCentroDeCusto] = useState<string>("");
    const [getNomeCentroDeCusto, setNomeCentroDeCusto] = useState<string>("");
    const [getSigla, setSigla] = useState<string>("");
    const [getDisable, setDisable] = useState<boolean>(false);

    const [getListaDeSalas, setListaDeSalas] = useState<Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>>([]);
    const [getListaDeAreas, setListaDeAreas] = useState<Array<{id: number, descricao: string}>>([]);
    
    const PickerUsuarios = Select.ofType<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}>();
    const [getPickerListaDeUsuarios, setPickerListaDeUsuarios] = useState<Array<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}>>([]);
    const [getItemPickerUsuarios, setItemPickerUsuarios] = useState<{id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null} | undefined>(undefined);
    const [getIDPickerUsuarios, setIDPickerUsuarios] = useState<number>(0);
    const [getVinculandoCargo, setVinculandoCargo] = useState<boolean>(false);

    const [getListaDeUsuarios, setListaDeUsuarios] = useState<Array<{id: number, nome: string, emailPrincipal: string, cargoDescricao: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}>>([]);
    const [getListaDoAlmoxarifado, setListaDoAlmoxarifado] = useState<Array<{ id: number, descricao: string | undefined, tipoDescricao: string | undefined, quantidade: number, valorUnitario: number, fornecedorDescricao: string | undefined, tipo: {id: number, descricao: string} | undefined, fornecedor: Objeto.Fornecedor | undefined, validadeInicial: Date | undefined | null | string, validadeFinal: Date | undefined | null | string}>>([]);
    
    const [getListaDeNomesDeBrindes, setListaNomesDeBrindes] = useState<Array<{id: number, descricao: string}>>([]);
    const [getItemPickerNomesDeBrindes, setItemPickerNomesDeBrindes] = useState<{id: number, descricao: string} | undefined>(undefined);
    
    const [getListaDeTiposDeBrindes, setListaTiposDeBrindes] = useState<Array<{id: number, descricao: string}>>([]);
    const [getItemPickerTiposDeBrindes, setItemPickerTiposDeBrindes] = useState<{id: number, descricao: string} | undefined>(undefined);

    const PickerCargos = MultiSelect.ofType<{id: number, nome: string}>();
    const [getListaDeCargos, setListaDeCargos] = useState<Array<{id: number, nome: string}>>([]);
    const [getItemPickerCargos, setItemPickerCargos] = useState<{id: number, nome: string} | undefined>(undefined);
    const [getItensSelecionadosPickerCargos, setItensSelecionadosPickerCargos] = useState<Array<{id: number, nome: string}>>([]);
    const [getIDCargos, setIDCargos] = useState<number>(0);
    const [getDescricaoCargos, setDescricaoCargos] = useState<string>("");

    const MultiSelectEmpresas = MultiSelect.ofType<Objeto.Empresa>();
    const [getItensSelecionadosEmpresa, setItensSelecionadosEmpresa] = useState<Array<Objeto.Empresa>>([]);

    const MultiSelectCentroDeCusto = MultiSelect.ofType<Objeto.CentroDeCusto>();
    const [getItensSelecionadosCentroDeCusto, setItensSelecionadosCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const [getListaRetroAlimentativaMultiSelectCentroDeCusto, setListaRetroAlimentativaMultiSelectCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);

    const MultiSelectFunilDeVenda = MultiSelect.ofType<Objeto.FunilDeVendas>();
    const [getItensSelecionadosFunilDeVenda, setItensSelecionadosFunilDeVenda] = useState<Array<Objeto.FunilDeVendas>>([]);

    const MultiSelectControleDeSala = MultiSelect.ofType<Objeto.ControleDeSala>();
    const [getItensSelecionadosControleDeSala, setItensSelecionadosControleDeSala] = useState<Array<Objeto.ControleDeSala>>([]);

    const [getDescricaoBrinde, setDescricaoBrinde] = useState<string>("");
    const [getListaPickerTipoBrinde, setListaPickerTipoBrinde] = useState<Array<{id: number, descricao: string}>>([
        {id: 0, descricao: "Atendimento"},
        {id: 1, descricao: "Venda"},
        {id: 2, descricao: "SPIF"},
        {id: 3, descricao: "Fornecedor"}
    ]);
    const [getItemPickerTipoBrinde, setItemPickerTipoBrinde] = useState<{id: number, descricao: string} | undefined>(undefined);

    const [getListaPickerFornecedor, setListaPickerFornecedor] = useState<Array<Objeto.Fornecedor>>([]);
    const [getItemPickerFornecedorBrinde, setItemPickerFornecedorBrinde] = useState<Objeto.Fornecedor | undefined>(undefined);

    const [getFormularioExibirCalendarioValidadeInicial, setFormularioExibirCalendarioValidadeInicial] = useState<boolean>(false);
    const [getFormularioExibirCalendarioValidadeFinal, setFormularioExibirCalendarioValidadeFinal] = useState<boolean>(false);

    const [getListaDeUsuariosPermissaoDeEmpresa, setListaDeUsuariosPermissaoDeEmpresa] = useState<Array<{id: number, nome: string, cargoDescricao: string, cargo: {id: number, nome: string} | null, emailPrincipal: string, email: {classificacao: number, descricao: string} | null, empresaNome: string, empresa: Objeto.Empresa}>>([]);
    
    const PickerEmpresas = Select.ofType<Objeto.Empresa>();
    const [getListaPickerDeEmpresas, setListaPickerDeEmpresas] = useState<Array<Objeto.Empresa>>([]);
    const [getItemPickerDeEmpresas, setItemPickerDeEmpresas] = useState<Objeto.Empresa | undefined>();
    
    const [getListaDeUsuariosPermissaoDeCentroDeCusto, setListaDeUsuariosPermissaoDeCentroDeCusto] = useState<Array<{id: number, nome: string, cargoDescricao: string, cargo: {id: number, nome: string} | null, emailPrincipal: string, email: {classificacao: number, descricao: string} | null, empresaNome: string, centroDeCustoNome: string, centroDeCustoSigla: string, centroDeCusto: Objeto.CentroDeCusto}>>([]);
    
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto | undefined>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const [getListaRetroAlimentativaPickerCentroDeCusto, setListaRetroAlimentativaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const [getFormularioEmpresaVinculada, setFormularioEmpresaVinculada] = useState<string>("");
    const [getFormularioCentroDeCustoVinculado, setFormularioCentroDeCustoVinculado] = useState<string>("");

    const PickerSalaDeVendas = Select.ofType<{id: number, descricao: string, areas: Array<{id: number, descricao: string}> | null}>();
    const [getItemPickerSalaDeVendas, setItemPickerSalaDeVendas] = useState<{id: number, descricao: string, areas: Array<{id: number, descricao: string}> | null} | undefined>();
    const [getListaPickerSalaDeVendas, setListaPickerSalaDeVendas] = useState<Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}> | null}>>([]);
    const [getListaRetroAlimentativaPickeSalaDeVendas, setListaRetroAlimentativaPickerSalaDeVendas] = useState<Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}> | null}>>([]);
    
    const PickerArea = Select.ofType<{id: number, descricao: string}>();
    const [getItemPickerArea, setItemPickerArea] = useState<{id: number, descricao: string} | undefined>();
    const [getListaPickerArea, setListaPickerArea] = useState<Array<{id: number, descricao: string}>>([]);
    const [getListaRetroAlimentativaPickeArea, setListaRetroAlimentativaPickerArea] = useState<Array<{id: number, descricao: string}>>([]);

    const [getListaDeUsuariosPermissaoDeFunilDeVenda, setListaDeUsuariosPermissaoDeFunilDeVenda] = useState<Array<{id: number, nome: string, cargoDescricao: string, cargo: {id: number, nome: string} | null, emailPrincipal: string, email: {classificacao: number, descricao: string} | null, salaDeVenda: {id: number, descricao: string} | null, area: {id: number, descricao: string}, funil: Objeto.FunilDeVendas | null, salaDeVendaDescricao: string, areaDescricao: string, funilDescricao: string}>>([]);

    const PickerFunilDeVenda = Select.ofType<Objeto.FunilDeVendas>();
    const [getItemPickerFunilDeVenda, setItemPickerFunilDeVenda] = useState<Objeto.FunilDeVendas | undefined>();
    const [getListaPickerFunilDeVenda, setListaPickerFunilDeVenda] = useState<Array<Objeto.FunilDeVendas>>([]);

    const [getListaDeUsuariosPermissaoDeControleDeSala, setListaDeUsuariosPermissaoDeControleDeSala] = useState<Array<{id: number, nome: string, cargoDescricao: string, cargo: {id: number, nome: string} | null, emailPrincipal: string, email: {classificacao: number, descricao: string} | null, salaDeVenda: {id: number, descricao: string} | null, controleDeSala: Objeto.ControleDeSala | null, salaDeVendaDescricao: string, controleDeSalaDescricao: string}>>([]);

    const PickerControleDeSala = Select.ofType<Objeto.ControleDeSala>();
    const [getItemPickerControleDeSala, setItemPickerControleDeSala] = useState<Objeto.ControleDeSala | undefined>();
    const [getListaPickerControleDeSala, setListaPickerControleDeSala] = useState<Array<Objeto.ControleDeSala>>([]);

    const [getListaDeUsuariosPermissaoDeSoftwareExterno, setListaDeUsuariosPermissaoDeSoftwareExterno] = useState<Array<{id: number, nome: string, empresaNome: string, centroDeCustoDescricao: string, centroDeCusto: Objeto.CentroDeCusto, softwareExternoDescricao: string, softwareExterno: { id: number, descricao: string, token: string, urlapi: string, tokenAPI: string }}>>([]);
    const [getIDUsuarioSoftwareExterno, setIDUsuarioSoftwareExterno] = useState<number>(-1);

    const PickerSoftwareExterno = Select.ofType<{id: number, descricao: string, token: string, urlapi: string, tokenAPI: string}>();
    const [getItemPickerSoftwareExterno, setItemPickerSoftwareExterno] = useState<{id: number, descricao: string, token: string, urlapi: string, tokenAPI: string} | undefined>();
    const [getListaPickerSoftwareExterno, setListaPickerSoftwareExterno] = useState<Array<{id: number, descricao: string, token: string, urlapi: string, tokenAPI: string}>>([]);

    const [getListaCentroDeCustoVinculado, setListaCentroDeCustoVinculado] = useState<Array<{centroDeCustoDescricao: string, centroDeCustoVinculadoDescricao: string, empresaVinculadaDescricao: string, empresaDescricao: string, softwareExternoDescricao: string, centroDeCusto: Objeto.CentroDeCusto, empresa: Objeto.Empresa, centroDeCustoVinculado: {empresaVinculada: string, centroDeCustoVinculado: string}, softwareExterno: {id: number, descricao: string, token: string, tokenAPI: string, urlapi: string}}>>([]);
    const [getItemCentroDeCustoVinculado, setItemCentroDeCustoVinculado] = useState<{centroDeCustoDescricao: string, centroDeCustoVinculadoDescricao: string, empresaVinculadaDescricao: string, empresaDescricao: string, softwareExternoDescricao: string, centroDeCusto: Objeto.CentroDeCusto, empresa: Objeto.Empresa, centroDeCustoVinculado: {empresaVinculada: string, centroDeCustoVinculado: string}, softwareExterno: {id: number, descricao: string, token: string, tokenAPI: string, urlapi: string}} | undefined>();

    const [getVinculandoEmpresa, setVinculandoEmpresa] = useState<boolean>(false);
    const [getVinculandoCentroDeCusto, setVinculandoCentroDeCusto] = useState<boolean>(false);
    const [getVinculandoControleDeSala, setVinculandoControleDeSala] = useState<boolean>(false);
    const [getVinculandoFunilDeVenda, setVinculandoFunilDeVenda] = useState<boolean>(false);
    const [getVinculandoUsuarioAoSoftwareExterno, setVinculandoUsuarioAoSoftwareExterno] = useState<boolean>(false);
    const [getVinculandoUsuarioAoCentroDeCustoNoSoftwareExterno, setVinculandoUsuarioAoCentroDeCustoNoSoftwareExterno] = useState<boolean>(false);

    const [getIDFornecedor, setIDFornecedor] = useState<number>(0);
    const [getCPFCNPJFornecedor, setCPFCNPJFornecedor] = useState<string>("");
    const [getNomeRazaoSocial, setNomeRazaoSocial] = useState<string>("");
    const [getCEPFornecedor, setCEPFornecedor] = useState<string>("");
    const [getLogradouroFornecedor, setLogradouroFornecedor] = useState<string>("");
    const [getNumeroFornecedor, setNumeroFornecedor] = useState<string>("");
    const [getComplementoFornecedor, setComplementoFornecedor] = useState<string>("");
    const [getBairroFornecedor, setBairroFornecedor] = useState<string>("");
    const [getCidadeFornecedor, setCidadeFornecedor] = useState<string>("");
    const [getUFFornecedor, setUFFornecedor] = useState<string>("");

    const [getTelefoneFornecedor, setTelefoneFornecedor] = useState<string>("");
    
    const [getQtdeBrinde, setQtdeBrinde] = useState<number>(0);
    const [getValorBrinde, setValorBrinde] = useState<number>(0);
    const [getPrazoValidadeInicioBrinde, setPrazoValidadeInicioBrinde] = useState<Date | null | undefined>(new Date());
    const [getPrazoValidadeTerminoBrinde, setPrazoValidadeTerminoBrinde] = useState<Date | null | undefined>(new Date());

    const [getNomeDoBrinde, setNomeDoBrinde] = useState<string>("");
    const [getNomeTipoDoBrinde, setNomeTipoDoBrinde] = useState<string>("");

    const [getIdDepartamento, setIdDepartamento] = useState<number>(0);
    const [getIdSala, setIdSala] = useState<number>(0);
    const [getIDNomeDoBrinde, setIDNomeDoBrinde] = useState<number>(0);
    const [getIDTipoDeBrinde, setIDTipoDeBrinde] = useState<number>(0);
    const [getIDBrinde, setIDBrinde] = useState<number>(0);
    const [getIndexBrinde, setIndexBrinde] = useState<number>(0);

    const [getFormularioEmail, setFormularioEmail] = useState<string>("");
    const [getFormularioSenha, setFormularioSenha] = useState<string>("");
    const [getFormularioConfirmarSenha, setFormularioConfirmarSenha] = useState<string>("");
    const [getDescriptionSala, setDescriptionSala] = useState<string>("");
    const [getDescriptionDepartamento, setDescriptionDepartamento] = useState<string>("");

    const [getOptionDescription, setOptionDescription] = useState<string>("");
    const [getOptionCadastro, setOptionCadastro] = useState<string>("");
    const [getOptionFunction, setOptionFunction ] = useState(() => () => {});

    const [getEmConsulta, setEmConsulta] = useState<boolean>(false);
    const [getCPFEmConsulta, setCPFEmConsulta] = useState<boolean>(false);
    const [getCEPEmConsulta, setCEPEmConsulta] = useState<boolean>(false);
    const [getCadastrandoUsuario, setCadastrandoUsuario] = useState<boolean>(false);

    const History = useHistory();
    /* #endregion */

    let data: Object[] = extend([], (getListaDeLeads as { [key: string]: Object }).kanbanData, true) as Object[];

    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
    **/

    let kanbanObj: any;

    const [getDialogState, setDialogState] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDialogCreateFase, setDialogCreateFase] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDialogCustomComponent, setDialogCustomComponent] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpen = () => setDialogState({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleClose = () => setDialogState({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleDialogCreateFaseOpen = () => setDialogCreateFase({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCreateFaseClose = () => setDialogCreateFase({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleDialogCustomComponentOpen = () => setDialogCustomComponent({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleDialogCustomComponentClose = () => setDialogCustomComponent({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    var rteObj: any;

    var hostUrl: string = 'https://ej2-aspcore-service.azurewebsites.net/';

    var items: string[] = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', 'SuperScript', 'SubScript', '|',
      'CreateTable', 'CreateLink', 'Image', 'FileManager', '|', 'ClearFormat', 'Print',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
    ];
  
    var fileManagerSettings: FileManagerSettingsModel = {
      enable: true,
      path: '/Pictures/Food',
      ajaxSettings: {
        url: hostUrl + 'api/FileManager/FileOperations',
        getImageUrl: hostUrl + 'api/FileManager/GetImage',
        uploadUrl: hostUrl + 'api/FileManager/Upload',
        downloadUrl: hostUrl + 'api/FileManager/Download'
      }
    }

    var quickToolbarSettings: QuickToolbarSettingsModel = {
      table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles']
    }

    var toolbarSettings: ToolbarSettingsModel = {
      items: items
    };

    var textArea: any;
    var myCodeMirror: any;

    const mirrorConversion = (e?: any) => {
      textArea = rteObj.contentModule.getEditPanel() as HTMLTextAreaElement;
      let id: string = (rteObj as any).getID() + 'mirror-view';
      let mirrorView: HTMLElement = rteObj.element.querySelector('#' + id) as HTMLElement;
      let charCount: HTMLElement = rteObj.element.querySelector('.e-rte-character-count') as HTMLElement;
      if (e.targetItem === 'Preview') {
        textArea.style.display = 'block';
        mirrorView.style.display = 'none';
        textArea.innerHTML = myCodeMirror.getValue();
        charCount.style.display = 'block';
      } else {
        if (!mirrorView) {
          mirrorView = createElement('div', { className: 'e-content' });
          mirrorView.id = id;
          textArea.parentNode.appendChild(mirrorView);
        } else {
          mirrorView.innerHTML = '';
        }
        textArea.style.display = 'none';
        mirrorView.style.display = 'block';
        renderCodeMirror(mirrorView, (rteObj as any).value);
        charCount.style.display = 'none';
      }
    }

    const renderCodeMirror = (mirrorView: HTMLElement, content: string) => {
      myCodeMirror = CodeMirror(mirrorView, {
        value: content,
        lineNumbers: true,
        mode: 'text/html',
        lineWrapping: true,
      });
    }

    const [getDrawerAdicionarSala, setDrawerAdicionarSala] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDrawerAdicionarDepartamento, setDrawerAdicionarDepartamento] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDrawerAdicionarUsuario, setDrawerAdicionarUsuario] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDrawerAdicionarCargos, setDrawerAdicionarCargos] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDrawerAdicionarFornecedor, setDrawerAdicionarFornecedor] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDrawerAdicionarBrinde, setDrawerAdicionarBrinde] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDrawerAdicionarEmpresa, setDrawerAdicionarEmpresa] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDrawerAdicionarCentroDeCusto, setDrawerAdicionarCentroDeCusto] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDrawerAdicionarSala = () => setDrawerAdicionarSala({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerAdicionarSala = () => setDrawerAdicionarSala({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDrawerAdicionarDepartamento = () => setDrawerAdicionarDepartamento({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerAdicionarUsuario = () => {
        setFormularioID(-1);
        setFormularioCPF("");
        setFormularioNome("");
        setFormularioEmail("");
        setFormularioSenha("");
        setItemPickerCargos(undefined);
        setDrawerAdicionarUsuario({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        });
    };

    const handleOpenDrawerAdicionarUsuario = () => setDrawerAdicionarUsuario({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerAdicionarCargos = () => setDrawerAdicionarCargos({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDrawerAdicionarCargos = () => setDrawerAdicionarCargos({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerAdicionarFornecedor = () => setDrawerAdicionarFornecedor({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDrawerAdicionarFornecedor = () => setDrawerAdicionarFornecedor({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerAdicionarDepartamento = () => setDrawerAdicionarDepartamento({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleCloseDrawerAdicionarBrinde = () => setDrawerAdicionarBrinde({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
    });

    const handleOpenDrawerAdicionarBrinde = () => setDrawerAdicionarBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const [getDrawerAdicionarNomeDoBrinde, setDrawerAdicionarNomeDoBrinde] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDrawerAdicionaraNomeTipoDoBrinde, setDrawerAdicionarNomeTipoDoBrinde] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleCloseDrawerAdicionarNomeDoBrinde = () => setDrawerAdicionarNomeDoBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    })

    const handleOpenDrawerAdicionarNomeDoBrinde = () => setDrawerAdicionarNomeDoBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerAdicionarNomeTipoDoBrinde = () => setDrawerAdicionarNomeTipoDoBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    })

    const handleOpenDrawerAdicionarNomeTipoDoBrinde = () => setDrawerAdicionarNomeTipoDoBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    {/** DELETAR DADOS DA SALA  */}
    const [getDialogDeletarSala, setDialogDeletarSala] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarSala = () => setDialogDeletarSala({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarSala = () => setDialogDeletarSala({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** DELETAR DADOS DA AREA  */}
    const [getDialogDeletarArea, setDialogDeletarArea] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarArea = () => setDialogDeletarArea({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarArea = () => setDialogDeletarArea({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** DELETAR DADOS COM DIALOG  */}
    const [getDialogDeletarUsuario, setDialogDeletarUsuario] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarUsuario = () => setDialogDeletarUsuario({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarUsuario = () => setDialogDeletarUsuario({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** VINCULAR USUÁRIO  */}
    const [getDialogVincularUsuarioAoCargo, setDialogVincularUsuarioAoCargo] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogVincularUsuarioAoCargo = () => setDialogVincularUsuarioAoCargo({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogVincularUsuarioAoCargo = () => setDialogVincularUsuarioAoCargo({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** DELETAR DADOS COM DIALOG */}
    const [getDialogDeletarNomeDoBrinde, setDialogDeletarNomeDoBrinde] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarNomeDoBrinde = () => setDialogDeletarNomeDoBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarNomeDoBrinde = () =>  setDialogDeletarNomeDoBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** DELETAR VINCULO DO USUARIO  */}
    const [getDialogDesvincularUsuarioAoCargo, setDialogDesvincularUsuarioAoCargo] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDesvincularUsuarioAoCargo = () => setDialogDesvincularUsuarioAoCargo({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDesvincularUsuarioAoCargo = () => setDialogDesvincularUsuarioAoCargo({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** ATRIBUIR VINCULO DE EMPRESA AO USUÁRIO */}
    const [getDialogAtribuindoPermissaoDeEmpresa, setDialogAtribuindoPermissaoDeEmpresa] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogAtribuindoPermissaoDeEmpresa = () => setDialogAtribuindoPermissaoDeEmpresa({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogAtribuindoPermissaoDeEmpresa = () => setDialogAtribuindoPermissaoDeEmpresa({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** ATRIBUIR VINCULO DE EMPRESA AO USUÁRIO */}
    const [getDialogAtribuindoPermissaoDeCentroDeCusto, setDialogAtribuindoPermissaoDeCentroDeCusto] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogAtribuindoPermissaoDeCentroDeCusto = () => setDialogAtribuindoPermissaoDeCentroDeCusto({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogAtribuindoPermissaoDeCentroDeCusto = () => setDialogAtribuindoPermissaoDeCentroDeCusto({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** ATRIBUIR VINCULO DE CONTROLE DE SALA AO USUÁRIO */}
    const [getDialogAtribuindoPermissaoDeControleDeSala, setDialogAtribuindoPermissaoDeControleDeSala] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogAtribuindoPermissaoDeControleDeSala = () => setDialogAtribuindoPermissaoDeControleDeSala({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogAtribuindoPermissaoDeControleDeSala = () => setDialogAtribuindoPermissaoDeControleDeSala({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** ATRIBUIR VINCULO DE FUNIL DE VENDA AO USUÁRIO */}
    const [getDialogAtribuindoPermissaoDeFunilDeVenda, setDialogAtribuindoPermissaoDeFunilDeVenda] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogAtribuindoPermissaoDeFunilDeVenda = () => setDialogAtribuindoPermissaoDeFunilDeVenda({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogAtribuindoPermissaoDeFunilDeVenda = () => setDialogAtribuindoPermissaoDeFunilDeVenda({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** ATRIBUIR VINCULO DE USUARIO AO SOFTWARE EXTERNO */}
    const [getDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno, setDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno = () => setDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno = () => setDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** ATRIBUIR VINCULO DO USUÁRIO AO CENTRO DE CUSTO NO SOFTWARE EXTERNO */}
    const [getDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno, setDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno = () => setDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno = () => setDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDialogDeletarTipoDeBrinde, setDialogDeletarTipoDeBrinde] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarTipoDeBrinde = () => setDialogDeletarTipoDeBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarTipoDeBrinde = () => setDialogDeletarTipoDeBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    })

    const [getDialogDeletarBrinde, setDialogDeletarBrinde] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarBrinde = () => setDialogDeletarBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarBrinde = () => setDialogDeletarBrinde({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDialogDeletarFornecedor, setDialogDeletarFornecedor] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarFornecedor = () => setDialogDeletarFornecedor({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarFornecedor = () => setDialogDeletarFornecedor({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDialogDeletarCargo, setDialogDeletarCargo] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarCargo = () => setDialogDeletarCargo({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarCargo = () => setDialogDeletarCargo({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDrawerAdicionarEmpresa = () => setDrawerAdicionarEmpresa({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerAdicionarEmpresa = () => {
        setDrawerAdicionarEmpresa({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        })
    }

    const handleOpenDrawerAdicionarCentroDeCusto = () => setDrawerAdicionarCentroDeCusto({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDrawerAdicionarCentroDeCusto = () => {
        setDrawerAdicionarCentroDeCusto({
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true
        })
    };

    {/** DIALOG DELETAR PERMISSAO EMPRESA */}
    const [getDialogDeletarPermissaoEmpresa, setDialogDeletarPermissaoEmpresa] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarPermissaoEmpresa = () => setDialogDeletarPermissaoEmpresa({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarPermissaoEmpresa = () => setDialogDeletarPermissaoEmpresa({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** DIALOG DELETAR PERMISSAO CENTRO DE CUSTO */}
    const [getDialogDeletarPermissaoCentroDeCusto, setDialogDeletarPermissaoCentroDeCusto] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarPermissaoCentroDeCusto = () => setDialogDeletarPermissaoCentroDeCusto({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarPermissaoCentroDeCusto = () => setDialogDeletarPermissaoCentroDeCusto({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** DIALOG DELETAR PERMISSAO CONTROLE DE SALA */}
    const [getDialogDeletarPermissaoControleDeSala, setDialogDeletarPermissaoControleDeSala] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarPermissaoControleDeSala = () => setDialogDeletarPermissaoControleDeSala({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarPermissaoControleDeSala = () => setDialogDeletarPermissaoControleDeSala({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** DIALOG DELETAR PERMISSAO FUNIL DE VENDA */}
    const [getDialogDeletarPermissaoFunilDeVenda, setDialogDeletarPermissaoFunilDeVenda] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarPermissaoFunilDeVenda = () => setDialogDeletarPermissaoFunilDeVenda({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarPermissaoFunilDeVenda = () => setDialogDeletarPermissaoFunilDeVenda({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** DIALOG DELETAR VINCULO DO USUARIO AO SOFTWARE EXTERNO */}
    const [getDialogDeletarVinculoDoUsuarioAoSoftwareExterno, setDialogDeletarVinculoDoUsuarioAoSoftwareExterno] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarVinculoDoUsuarioAoSoftwareExterno = () => setDialogDeletarVinculoDoUsuarioAoSoftwareExterno({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarVinculoDoUsuarioAoSoftwareExterno = () => setDialogDeletarVinculoDoUsuarioAoSoftwareExterno({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    {/** DIALOG DELETAR VINCULO DO USUARIO AO CENTRO DE CUSTO NO SOFTWARE EXTERNO */}
    const [getDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno, setDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const handleOpenDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno = () => setDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true
    });

    const handleCloseDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno = () => setDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true
    });

    const [getDialogDeletarEmpresa, setDialogDeletarEmpresa] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true,
    });

    const handleDialogDeletarEmpresaOpen = () => setDialogDeletarEmpresa({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true,
    });

    const handleDialogDeletarEmpresaClose = () => setDialogDeletarEmpresa({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true,
    });

    const [getDialogDeletarCentroDeCusto, setDialogDeletarCentroDeCusto] = useState<IDialogExampleState>({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true,
    });

    const handleDialogDeletarCentroDeCustoOpen = () => setDialogDeletarCentroDeCusto({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: true,
        usePortal: true,
    });

    const handleDialogDeletarCentroDeCustoClose = () => setDialogDeletarCentroDeCusto({
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        isOpen: false,
        usePortal: true,
    });

    {/** DELETAR DADOS COM DIALOG */}
    const toolbarOptions: ToolbarItems[] = ['PdfExport']
    
    const groupOptionsVinculosCargos: GroupSettingsModel = {
        columns: ['cargoDescricao'],
        showGroupedColumn: true,
        showUngroupButton: true,
    }

    const groupOptionsPermissaoDeEmpresa: GroupSettingsModel = {
        columns: ['empresaNome'],
        showGroupedColumn: true,
        showUngroupButton: true,
    }

    const groupOptionsPermissaoDeCentroDeCusto: GroupSettingsModel = {
        columns: ['centroDeCustoNome'],
        showGroupedColumn: true,
        showUngroupButton: true,
    }

    const filterOptions: FilterSettingsModel = {
        ignoreAccent: true,
        immediateModeDelay: 200,
        mode: "Immediate",
        type: 'Excel'
    }

    const sortOptions: SortSettingsModel = {

    }

    const groupOptions: GroupSettingsModel = {
        columns: ['empresaNome'],
        showGroupedColumn: true,
        showUngroupButton: true,
    }

    const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs | any) => {
        (args.cell as any).row.pdfGrid.repeatHeader = true;
        args.cell.gridRow.pdfGrid.columns.getColumn(2).width = 90;
        args.cell.gridRow.pdfGrid.columns.getColumn(2).textAlign = "Right";
    }

    let grid: Grid | null;
    const toolbarClick = (args: any) => {
        if (grid) {
            if (args.item.id === 'grid_pdfexport') {
                (grid.columns[0] as Column).visible = false;
                (grid.columns[1] as Column).visible = false;
                grid.pdfExport({
                    fileName: `Borderô.pdf`,
                    pageOrientation: "Landscape",
                    header: {
                        fromTop: 0,
                        height: 130,
                        contents: [
                            {
                                type: 'Text',
                                value: `${getItemPickerCentroDeCusto?.descricao}`,
                                position: { x: 390, y: 50 },
                                style: { textBrushColor: "#000000", fontSize: 20, hAlign: "Right" },
                            },
                            {
                                position: { x: 0, y: 45 },
                                size: { height: 40, width: 80 },
                                src: image,
                                type: 'Image',
                            },
                            {
                                type: 'Text',
                                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:MM:SS")}`,
                                position: { x: 880, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 13 },
                            },
                        ]
                    },
                    footer: {
                        contents: [
                            {
                                type: 'Text',
                                value: `Powered by DigitalDEV`,
                                position: { x: 0, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 10 },
                            },
                            {
                                format: 'Página {$current} de {$total}',
                                pageNumberType: "Numeric",
                                position: { x: 910, y: 50 },
                                style: {
                                    fontSize: 13,
                                    hAlign: "Right",
                                    textBrushColor: '#000000',
                                },
                                type: 'PageNumber',
                            }
                        ],
                        fromBottom: 0,
                        height: 100,
                    },
                    theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
                        },
                        record: {
                            bold: false,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7)
                        },
                        caption: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
                        }
                    },
                    allowHorizontalOverflow: false,

                });
            }
        }
    }

    const pdfExportComplete = () => {
        if (grid) {
            (grid.columns[0] as Column).visible = true;
            (grid.columns[1] as Column).visible = true;
        }
    }

    async function ValidarFormulario(): Promise<Boolean> {
        if (!getFormularioNome) {
            NotificacaoInterna.ExibirNotificacao("Nome não preenchido", "Informe o nome", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getFormularioCPF) {
            NotificacaoInterna.ExibirNotificacao("CPF não preenchido", "Informe o CPF", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (CPF.TextoValido(getFormularioCPF) == false) {
            NotificacaoInterna.ExibirNotificacao("CPF inválido!", "Verifique se o CPF digitado é válido.", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getFormularioEmail) {
            NotificacaoInterna.ExibirNotificacao("Email não preenchido", "Informe o email", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getFormularioSenha && getFormularioID == -1) {
            NotificacaoInterna.ExibirNotificacao("Senha não preenchida", "Informe a senha", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItensSelecionadosPickerCargos) {
            NotificacaoInterna.ExibirNotificacao("Cargo não selecionado", "Informe o cargo", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItensSelecionadosEmpresa) {
            NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Informe a empresa", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItensSelecionadosCentroDeCusto) {
            NotificacaoInterna.ExibirNotificacao("Centro de custo não selecionado", "Informe o centro de custo", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItensSelecionadosControleDeSala) {
            NotificacaoInterna.ExibirNotificacao("Controle de sala não selecionado", "Informe o controle de sala", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        if (!getItensSelecionadosFunilDeVenda) {
            NotificacaoInterna.ExibirNotificacao("Funil de venda não selecionado", "Informe o funil de venda", NotificacaoInterna.TipoDeNotificacao.Alerta);
            return false;
        }
        return true
    }

    async function EfetuarCadastro(): Promise<void> {
        await setCadastrandoUsuario(true);
        if (await ValidarFormulario() == true) {
            var ResponsePessoa = await Pessoa.Get("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", CPF.DesformatarTexto(getFormularioCPF));
            if (Math.floor(ResponsePessoa.status / 100) == 2) {
                let ResponseUsuario = await Logon.Post("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", ResponsePessoa.data.id, getFormularioSenha);
                if (Math.floor(ResponseUsuario.status / 100) == 2) {
                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "seja bem vindo ao nosso sistema!", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                    handleCloseDrawerAdicionarUsuario();
                }
                else {
                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "Você já se encontra cadastrado em nosso sistema!", NotificacaoInterna.TipoDeNotificacao.Erro);
                }
            }
            else {
                NotificacaoInterna.ExibirNotificacao("Cadastrando usuário...", "Aguarde enquanto cadastramos o seu usuário", NotificacaoInterna.TipoDeNotificacao.Informacao);
                ResponsePessoa = await Pessoa.Post("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", {
                    nome: getFormularioNome,
                    cpf: CPF.DesformatarTexto(getFormularioCPF),
                    natureza: 5,
                    emails: [{ classificacao: 1, descricao: getFormularioEmail } as Objeto.Email]
                } as Objeto.Pessoa);
                let ResponseUsuario = await Logon.Post("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", ResponsePessoa.data.id, getFormularioSenha);
                if (Math.floor(ResponseUsuario.status / 100) == 2) {
                    NotificacaoInterna.ExibirNotificacao("Usuário cadastrado!", "Aguarde enquanto vinculamos o seu usuário aos sistemas externos", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                    let ResponseSignIn = await Logon.Get(CPF.DesformatarTexto(getFormularioCPF), getFormularioSenha);
                    switch (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado) {
                        case 5:
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 18, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 18, "22VEN", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 18, "22VEN", "U2VydmVyPTQ1LjY1LjIyMi4xMzg7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9T25lcGx1cztQYXNzd29yZD1PbiZQbHVzQDIwMjE7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            break;
                        case 4:
                            while (Math.floor((await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 20, ResponsePessoa.data.id)).status / 100) != 2);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 20, "SALPR", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 20, "SALPR", "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 21, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 21, "SALPK", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 21, "SALPK", "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 22, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 22, "SALEX", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 22, "SALEX", "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 23, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 23, "PORAL", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 23, "PORAL", "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 26, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 26, "PYRIN", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 26, "PYRIN", "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 28, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 28, "SBR", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 28, "SBR", "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 32, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 32, "P2L", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 32, "P2L", "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            break;
                        case 8:
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 19, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 19, "BSBDF", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 19, "BSBDF", "U2VydmVyPTE5MS4zMy4xNzIuNTk7RGF0YWJhc2U9VUFVX1NCO1VzZXIgSUQ9ZGlnaXRhbGludDtQYXNzd29yZD1zb3MkbmV0MzI7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 19, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 19, "COCGO", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 19, "COCGO", "U2VydmVyPTE5MS4zMy4xNzIuNTk7RGF0YWJhc2U9VUFVX1NCO1VzZXIgSUQ9ZGlnaXRhbGludDtQYXNzd29yZD1zb3MkbmV0MzI7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 19, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 19, "CRZDF", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 19, "CRZDF", "U2VydmVyPTE5MS4zMy4xNzIuNTk7RGF0YWJhc2U9VUFVX1NCO1VzZXIgSUQ9ZGlnaXRhbGludDtQYXNzd29yZD1zb3MkbmV0MzI7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 19, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 19, "LSLDF", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 19, "LSLDF", "U2VydmVyPTE5MS4zMy4xNzIuNTk7RGF0YWJhc2U9VUFVX1NCO1VzZXIgSUQ9ZGlnaXRhbGludDtQYXNzd29yZD1zb3MkbmV0MzI7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 19, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 19, "VALGO", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 19, "VALGO", "U2VydmVyPTE5MS4zMy4xNzIuNTk7RGF0YWJhc2U9VUFVX1NCO1VzZXIgSUQ9ZGlnaXRhbGludDtQYXNzd29yZD1zb3MkbmV0MzI7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            break;
                        case 9:
                            await Empresa.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 30, ResponsePessoa.data.id);
                            await CentroDeCusto.AutorizarPermissaoDeAcesso(ResponseSignIn.data.token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, 30, "JRDEU", ResponsePessoa.data.id);
                            await SoftwareExterno.NovoUsuarioExterno(ResponseSignIn.data.token, 30, "JRDEU", "U2VydmVyPTE5Mi4xNjguMS40MFxTUUxFWFBSRVNTO0RhdGFiYXNlPVVBVVRFU1RFO1VzZXIgSUQ9b25lcGx1cztQYXNzd29yZD11dmdIMUJ5dEhMRDQ1I0A7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", ResponsePessoa.data.id, "ONEPLUS");
                            break;
                    }
                    NotificacaoInterna.ExibirNotificacao("Usuário vinculado aos sistemas externos", "Aguarde enquanto vinculamos o usuário ao cargo.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                    let ResponseCargo = await Logon.VincularUsuarioAoCargo("NzAyNjEyMzExNDZjMjl6Skc1bGRETXk=", ResponsePessoa.data.id, getItemPickerCargos?.id ?? 0);
                    if (Math.floor(ResponseCargo.status / 100) == 2)
                    {
                        NotificacaoInterna.ExibirNotificacao("Tudo pronto!", "Espero que tenha uma excelente experiência com nosso sistema", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                        handleCloseDrawerAdicionarUsuario();
                    }
                    else
                    {
                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Houve uma falha ao tentar cadastrar o seu usuario, por favor, entre em contato com o desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                    }
                }
                else {
                    NotificacaoInterna.ExibirNotificacao("Caro usuário", "Houve uma falha ao tentar cadastrar o seu usuario, por favor, entre em contato com o desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                }
            }
        }
        await setCadastrandoUsuario(false);
    };

    useEffect(() => {
        async function componentDidMount() {
            setCarregandoListaDeContratos(false, async () => {

                setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? []);

                setListaPickerDeEmpresas((await Empresa.Get(ContextSignIn.getContext().token, true, "")).data ?? []);

                setPickerListaDeUsuarios((await Logon.Usuarios(ContextSignIn.getContext().token, "")).data ?? []);

                setListaDeCargos((await Cargo.Get(ContextSignIn.getContext().token)).data ?? []);

                setListaDeSalas((await SalaDeVendas.Get(ContextSignIn.getContext().token)).data ?? []);

                setListaDeAreas((await Areas.Get(ContextSignIn.getContext().token)).data ?? []);

                setListaPickerFunilDeVenda((await FunilDeVendas.Get(ContextSignIn.getContext().token)).data ?? []);

                setListaPickerControleDeSala((await ControleDeSala.Get(ContextSignIn.getContext().token, "", false)).data ?? []);

                setOptionDescription("Adicionar sala")
                setOptionFunction(() => () => {
                    setDescriptionSala("");
                    setIdSala(0);
                    handleOpenDrawerAdicionarSala();
                });

                setCarregandoListaDeContratos(true, () => {});

                setCollapse(true);

            });
        }
        componentDidMount();
    }, []);

    async function Buscar() {

        if (getOptionCadastro == 'Adicionar permissão de empresa')
        {

            if(getItemPickerDeEmpresas)
            {

                setEmConsulta(true);
                let Response = await Logon.PermissaoDeAcessoPorEmpresa(ContextSignIn.getContext().token, getItemPickerDeEmpresas?.id ?? "");
    
                if(Math.floor(Response.status / 100) == 2)
                {
    
                    let ListaDeUsuarios = [] as Array<{id: number, nome: string, cargoDescricao: string, cargo: {id: number, nome: string} | null, emailPrincipal: string, email: {classificacao: number, descricao: string} | null, empresaNome: string, empresa: Objeto.Empresa}>
    
                    Response.data.map((item, index) => {
                        ListaDeUsuarios.push({
                            id: item.usuario.id,
                            nome: item.usuario.nome,
                            emailPrincipal: item.usuario.email.descricao,
                            email: item.usuario.email,
                            cargoDescricao: item.usuario.cargo != null ? item.usuario.cargo.nome : "Sem cargo",
                            cargo: item.usuario.cargo,
                            empresaNome: item.empresa.nomeFantasia,
                            empresa: item.empresa
                        })
                    });
    
                    console.log(ListaDeUsuarios);
    
                    setListaDeUsuariosPermissaoDeEmpresa(ListaDeUsuarios);
                    setEmConsulta(false);
                }
                else
                {
                    NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de usuários por permissão de acesso", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                    setEmConsulta(false);
                }

            }
            else
            {
                if(!getItemPickerEmpresas)
                {
                    NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione a empresa", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
            }

        }
        else if (getOptionCadastro == 'Adicionar permissão de centro de custo')
        {

            if(getItemPickerDeEmpresas && getItemPickerCentroDeCusto)
            {

                setEmConsulta(true);
                let Response = await Logon.PermissaoDeAcessoPorCentroDeCusto(ContextSignIn.getContext().token, getItemPickerDeEmpresas?.id ?? "", getItemPickerCentroDeCusto?.sigla ?? "")
                if(Math.floor(Response.status / 100) == 2)
                {
    
                    let ListaDeUsuarios = [] as Array<{id: number, nome: string, cargoDescricao: string, cargo: {id: number, nome: string} | null, emailPrincipal: string, email: {classificacao: number, descricao: string} | null, empresaNome: string, centroDeCustoNome: string, centroDeCustoSigla: string, centroDeCusto: Objeto.CentroDeCusto}>
    
                    Response.data.map((item, index) => {
                        ListaDeUsuarios.push({
                            id: item.usuario.id,
                            nome: item.usuario.nome,
                            emailPrincipal: item.usuario.email.descricao,
                            email: item.usuario.email,
                            cargoDescricao: item.usuario.cargo != null ? item.usuario.cargo.nome : "Sem cargo",
                            cargo: item.usuario.cargo,
                            empresaNome: item.centroDeCusto.empresa.nomeFantasia,
                            centroDeCustoNome: item.centroDeCusto.descricao,
                            centroDeCustoSigla: item.centroDeCusto.sigla,
                            centroDeCusto: item.centroDeCusto,
                        })
                    });
                    setListaDeUsuariosPermissaoDeCentroDeCusto(ListaDeUsuarios);
                    setEmConsulta(false);
                }
                else
                {
                    NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de usuários por permissão de acesso", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                    setEmConsulta(false);
                }

            }
            else
            {
                if(!getItemPickerDeEmpresas)
                {
                    NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione a empresa", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
                if(!getItemPickerCentroDeCusto)
                {
                    NotificacaoInterna.ExibirNotificacao("Centro de custo não selecionado", "Selecione o centro de custo", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
            }

        }
        else if (getOptionCadastro == 'Adicionar permissão de controle de sala')
        {

            if(getItemPickerControleDeSala && getItemPickerSalaDeVendas)
            {
                setEmConsulta(true);
                let Response = await Logon.PermissaoDeAcessoPorControleDeSala(ContextSignIn.getContext().token, getItemPickerSalaDeVendas?.id ?? "", getItemPickerControleDeSala?.id ?? "");
                if(Math.floor(Response.status / 100) == 2)
                {
    
                    let ListaDeUsuarios = [] as Array<{id: number, nome: string, cargoDescricao: string, cargo: {id: number, nome: string} | null, emailPrincipal: string, email: {classificacao: number, descricao: string} | null, salaDeVenda: {id: number, descricao: string} | null, controleDeSala: Objeto.ControleDeSala | null, salaDeVendaDescricao: string, controleDeSalaDescricao: string}>
    
                    Response.data.map((item, index) => {
                        ListaDeUsuarios.push({
                            id: item.usuario.id,
                            nome: item.usuario.nome,
                            emailPrincipal: item.usuario.email.descricao,
                            email: item.usuario.email,
                            cargoDescricao: item.usuario.cargo != null ? item.usuario.cargo.nome : "Sem cargo",
                            cargo: item.usuario.cargo,
                            salaDeVenda: item.salaDeVenda,
                            salaDeVendaDescricao: item.salaDeVenda != null ? item.salaDeVenda.descricao : "Sem sala de venda",
                            controleDeSala: item.controleDeSala,
                            controleDeSalaDescricao: item.controleDeSala != null ? item.controleDeSala.descricao : "Sem controle de sala",
                        })
                    });
                    setListaDeUsuariosPermissaoDeControleDeSala(ListaDeUsuarios);
                    setEmConsulta(false);
                }
                else
                {
                    NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de usuários por permissão de acesso", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                    setEmConsulta(false);
                }
            }
            else
            {
                if(!getItemPickerControleDeSala)
                {
                    NotificacaoInterna.ExibirNotificacao("Controle de sala não selecionada", "Selecione o controle de sala", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
                if(!getItemPickerSalaDeVendas)
                {
                    NotificacaoInterna.ExibirNotificacao("Sala de venda não selecionada", "Selecione a sala de venda", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
            }

        }
        else if (getOptionCadastro == 'Adicionar permissão de funil de venda')
        {

            if(getItemPickerFunilDeVenda && getItemPickerSalaDeVendas && getItemPickerArea)
            {
                setEmConsulta(true);
                let Response = await Logon.PermissaoDeAcessoPorFunilDeVenda(ContextSignIn.getContext().token, getItemPickerSalaDeVendas?.id ?? "", getItemPickerArea?.id ?? "", getItemPickerFunilDeVenda?.id ?? "")
                if(Math.floor(Response.status / 100) == 2)
                {
    
                    let ListaDeUsuarios = [] as Array<{id: number, nome: string, cargoDescricao: string, cargo: {id: number, nome: string} | null, emailPrincipal: string, email: {classificacao: number, descricao: string} | null, salaDeVenda: {id: number, descricao: string} | null, area: {id: number, descricao: string}, funil: Objeto.FunilDeVendas | null, salaDeVendaDescricao: string, areaDescricao: string, funilDescricao: string}>
    
                    Response.data.map((item, index) => {
                        ListaDeUsuarios.push({
                            id: item.usuario.id,
                            nome: item.usuario.nome,
                            emailPrincipal: item.usuario.email.descricao,
                            email: item.usuario.email,
                            cargoDescricao: item.usuario.cargo != null ? item.usuario.cargo.nome : "Sem cargo",
                            cargo: item.usuario.cargo,
                            salaDeVenda: item.salaDeVenda,
                            salaDeVendaDescricao: item.salaDeVenda != null ? item.salaDeVenda.descricao : "Sem sala de venda",
                            area: item.area,
                            areaDescricao: item.area != null ? item.area.descricao : "Sem area",
                            funil: item.funil,
                            funilDescricao: item.funil != null ? item.funil.descricao : "Sem funil de venda"
                        })
                    });
                    setListaDeUsuariosPermissaoDeFunilDeVenda(ListaDeUsuarios);
                    setEmConsulta(false);
                }
                else
                {
                    NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de usuários por permissão de acesso", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                    setEmConsulta(false);
                }
            }
            else
            {
                if(!getItemPickerFunilDeVenda)
                {
                    NotificacaoInterna.ExibirNotificacao("Funil de venda não selecionado", "Selecione o funil de vendas", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
                if(!getItemPickerSalaDeVendas)
                {
                    NotificacaoInterna.ExibirNotificacao("Sala de venda não selecionada", "Selecione a sala de vendas", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
                if(!getItemPickerArea)
                {
                    NotificacaoInterna.ExibirNotificacao("Area não selecionada", "Selecione a area", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
            }

        }
        else if (getOptionCadastro == 'Adicionar vinculo do usuário ao software externo')
        {

            if(getItemPickerDeEmpresas && getItemPickerCentroDeCusto)
            {
                setEmConsulta(true);
                let Response = await Logon.PermissaoDeAcessoPorSoftwareExterno(ContextSignIn.getContext().token, getItemPickerDeEmpresas?.id ?? "", getItemPickerCentroDeCusto?.sigla ?? "", "")

                if(Math.floor(Response.status / 100) == 2)
                {
    
                    let ListaDeUsuarios = [] as Array<{id: number, nome: string, empresaNome: string, centroDeCustoDescricao: string, centroDeCusto: Objeto.CentroDeCusto, softwareExternoDescricao: string, softwareExterno: { id: number, descricao: string, token: string, urlapi: string, tokenAPI: string }}>
    
                    Response.data.map((item, index) => {
                        ListaDeUsuarios.push({
                            id: item.usuario.id,
                            nome: item.usuario.nome,
                            empresaNome: item.centroDeCusto.empresa.nomeFantasia,
                            centroDeCustoDescricao: item.centroDeCusto.sigla,
                            centroDeCusto: item.centroDeCusto,
                            softwareExternoDescricao: item.softwareExterno.descricao,
                            softwareExterno: item.softwareExterno
                        })
                    })
    
                    setListaDeUsuariosPermissaoDeSoftwareExterno(ListaDeUsuarios);
                    setEmConsulta(false);
    
                }
                else
                {
    
                    NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de usuários", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                    setEmConsulta(false);
    
                }
            }
            else
            {
                if(!getItemPickerDeEmpresas)
                {
                    NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione a empresa", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
                if(!getItemPickerCentroDeCusto)
                {
                    NotificacaoInterna.ExibirNotificacao("Centro de custo não selecionado", "Selecione o centro de custo", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
            }
        }
        else if (getOptionCadastro == 'Adicionar vinculo do centro de custo ao software externo')
        {

            if(getItemPickerDeEmpresas && getItemPickerCentroDeCusto)
            {

                setEmConsulta(true);

                let Response = await SoftwareExterno.CentroDeCustoVinculado(ContextSignIn.getContext().token, getItemPickerDeEmpresas?.id ?? "", getItemPickerCentroDeCusto?.sigla ?? "", "", "", "")
    
                if (Math.floor(Response.status / 100) == 2)
                {
    
                    console.log(Response.data);
    
                    let ListaDeCentrosDeCusto = [] as Array<{ centroDeCustoDescricao: string, centroDeCustoVinculadoDescricao: string, empresaVinculadaDescricao: string, empresaDescricao: string, softwareExternoDescricao: string, centroDeCusto: Objeto.CentroDeCusto, empresa: Objeto.Empresa, centroDeCustoVinculado: {empresaVinculada: string, centroDeCustoVinculado: string}, softwareExterno: {id: number, descricao: string, token: string, tokenAPI: string, urlapi: string} }>
    
                    Response.data.map((item, index) => {
                        ListaDeCentrosDeCusto.push({
                            centroDeCustoDescricao: item.centroDeCusto.descricao,
                            centroDeCustoVinculadoDescricao: item.centroDeCustoVinculado.centroDeCustoVinculado,
                            empresaVinculadaDescricao: item.centroDeCustoVinculado.empresaVinculada,
                            empresaDescricao: item.empresa.nomeFantasia,
                            softwareExternoDescricao: item.softwareExterno.descricao,
                            centroDeCusto: item.centroDeCusto,
                            empresa: item.empresa,
                            centroDeCustoVinculado: item.centroDeCustoVinculado,
                            softwareExterno: item.softwareExterno
                        })
                    });
    
                    setListaCentroDeCustoVinculado(ListaDeCentrosDeCusto);
                    setEmConsulta(false);
                }
                else
                {
    
                    NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de centro de custo vinculados", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                    setEmConsulta(false);
    
                }

            }
            else
            {
                if(!getItemPickerDeEmpresas)
                {
                    NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione a empresa", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
                if(!getItemPickerCentroDeCusto)
                {
                    NotificacaoInterna.ExibirNotificacao("Centro de custo não selecionado", "Selecione o centro de custo", NotificacaoInterna.TipoDeNotificacao.Alerta);
                    return;
                }
            }
        }
    }

    return (
        <Component.Container style = {{backgroundColor: "#FFFFFF"}}>

            {/** Dialog criando nova fase */}
            <Dialog
                style = {{width: '40%', backgroundColor: "#FFFFFF"}}
                icon="filter"
                onClose={() => {
                    setItemFunil("");
                    setAlterarItemFunil(false);
                    setItemFunilSelecionado(undefined);
                    handleDialogCreateFaseClose();
                }}
                title={getAlterarItemFunil == false ? "Criando uma nova fase" : "Atualizando a fase"}
                autoFocus = {true}
                isOpen = {getDialogCreateFase.isOpen}
                canEscapeKeyClose = {getDialogCreateFase.canEscapeKeyClose}
                canOutsideClickClose = {getDialogCreateFase.canOutsideClickClose}
                enforceFocus = {getDialogCreateFase.enforceFocus}
                usePortal = {getDialogCreateFase.usePortal} 
            >
                <div style = {{display: "flex", alignItems: "center"}}>
                    <div style = {{marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRight: "0.5px solid #00000050", paddingBottom: "20px"}}>
                        
                        <div style = {{width: "95%", marginTop: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Titulo da fase`}</div>
                            <Component.InputTask style = {{width: "100%"}} id="CustomLabel" placeholder={"Nome da fase..."} value={getItemFunil}
                                onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                    var TextoDigitado = event.currentTarget.value;

                                    setItemFunil(TextoDigitado)

                                }}>
                            </Component.InputTask>
                        </div>

                    </div>
                </div>
                
                <div style = {{ width: "100%", paddingRight: "2.5%", paddingLeft: "2.5%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center", borderTop: "0.5px solid #00000050"}}>
                    <Button
                        style = {{
                            flex: 1,
                            background: "#1098F7", 
                            color: "#FFFFFF",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px",
                            height: "15px",
                            marginTop: "20px"
                        }}
                        text = {getAlterarItemFunil == true ? "Atualizar" :  "Criar"}
                        onClick = {() => {

                            if (getAlterarItemFunil == false) {
                                if (getListaFasesDoFunil.filter((item, index) => item.descricao == getItemFunil).length == 0)
                                {
    
                                    getListaFasesDoFunil.push({
                                        id: getListaFasesDoFunil.length,
                                        descricao: getItemFunil
                                    });
    
                                    getListaDeLeads.kanbanData.push(
                                        {
                                            id: (13 + getListaDeLeads.kanbanData.length),
                                            cpf: "51123762449",
                                            nome: "Fulano A",
                                            statusKanban: `${getItemFunil}`,
                                            summary: "...",
                                            tags: "Contactar",
                                            color: "#E64A19",
                                            className: "e-story, e-low, e-nancy-davloio",
                                            dataDeNascimento: new Date("1970-06-02T00:00:00"),
                                            idade: undefined,
                                            nacionalidade: undefined,
                                            sexo: undefined,
                                            emails: [
                                                {
                                                    classificacao: 1,
                                                    descricao: "josiedson@cardial.br"
                                                },
                                                {
                                                    classificacao: 1,
                                                    descricao: "josiedson@cardial.br"
                                                }
                                            ],
                                            fotoDoLead: null,
                                            documentoPessoal: null,
                                            rg: null,
                                            estadoCivil: undefined,
                                            documentoDeEstadoCivil: null,
                                            regimeDeBens: undefined,
                                            ocupacao: undefined,
                                            renda: undefined,
                                            dadosDosVeiculos: undefined,
                                            dependentes: null,
                                            endereco: null,
                                            documentoEndereco: null,
                                            telefones: [
                                                {
                                                    classificacao: 1,
                                                    ddi: "55",
                                                    ddd: "81",
                                                    numero: "991595874",
                                                    observacao: ""
                                                },
                                                {
                                                    classificacao: 1,
                                                    ddi: "55",
                                                    ddd: "81",
                                                    numero: "991595874",
                                                    observacao: ""
                                                }
                                            ],
                                            localDeCaptacao: "SITE",
                                            status: 0,
                                            observacoes: [
                                                {
                                                    nome: "Washington Souza",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Izacc Moreira",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Calixto",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Hugo Moreira",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Lucas Moreira Assis",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                }
                                            ],
                                            responsavel: undefined,
                                            posicaoDoFunil: {
                                                id: 3,
                                                descricao: "Identificação do interesse"
                                            },
                                            atividades: [
                                                {
                                                  classificacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Email"
                                                  },
                                                  posicaoDoFunil: {
                                                    id: 1,
                                                    descricao: "Sem contato"
                                                  },
                                                  situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                  },
                                                  executor: {
                                                    id: 1,
                                                    cpf: null,
                                                    nome: "Washington dos Santos",
                                                    natureza: 0,
                                                    dataDeNascimento: null,
                                                    emails: null,
                                                    documentoPessoal: null,
                                                    rg: null,
                                                    creci: null,
                                                    estadoCivil: null,
                                                    documentoDeEstadoCivil: null,
                                                    regimeDeBens: null,
                                                    ocupacao: null,
                                                    necessarioAssinaturaDoConjuge: false,
                                                    conjuge: null,
                                                    endereco: null,
                                                    documentoEndereco: null,
                                                    telefones: null,
                                                    observacao: null
                                                  },
                                                  dataDoEvento: "2021-06-11T00:00:00-03:00",
                                                  descricao: "Enviado email de boas vindas"
                                                },
                                                {
                                                  classificacaoDaAtividade: {
                                                    id: 2,
                                                    descricao: "Tarefa"
                                                  },
                                                  posicaoDoFunil: {
                                                    id: 1,
                                                    descricao: "Sem contato"
                                                  },
                                                  situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                  },
                                                  executor: {
                                                    id: 2,
                                                    cpf: null,
                                                    nome: "Izacc Moreira",
                                                    natureza: 0,
                                                    dataDeNascimento: null,
                                                    emails: null,
                                                    documentoPessoal: null,
                                                    rg: null,
                                                    creci: null,
                                                    estadoCivil: null,
                                                    documentoDeEstadoCivil: null,
                                                    regimeDeBens: null,
                                                    ocupacao: null,
                                                    necessarioAssinaturaDoConjuge: false,
                                                    conjuge: null,
                                                    endereco: null,
                                                    documentoEndereco: null,
                                                    telefones: null,
                                                    observacao: null
                                                  },
                                                  dataDoEvento: "2021-07-01T00:00:00-03:00",
                                                  descricao: "Tentativa de contato sem sucesso"
                                                },
                                                {
                                                  classificacaoDaAtividade: {
                                                    id: 2,
                                                    descricao: "Tarefa"
                                                  },
                                                  posicaoDoFunil: {
                                                    id: 2,
                                                    descricao: "Contato feito"
                                                  },
                                                  situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                  },
                                                  executor: {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "dataDoEvento": "2021-07-21T00:00:00-03:00",
                                                  "descricao": "Contato com sucesso, cliente agendado para o dia 11/07/2021"
                                                },
                                                {
                                                  "classificacaoDaAtividade": {
                                                    "id": 2,
                                                    "descricao": "Tarefa"
                                                  },
                                                  "posicaoDoFunil": {
                                                    "id": 3,
                                                    "descricao": "Identificação do interesse"
                                                  },
                                                  "situacaoDaAtividade": {
                                                    "id": 0,
                                                    "descricao": "Aberta"
                                                  },
                                                  "executor": {
                                                    "id": 3,
                                                    "cpf": null,
                                                    "nome": "Hugo Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "dataDoEvento": "2021-07-16T00:00:00-03:00",
                                                  "descricao": "Tentativa de contato sem sucesso"
                                                }
                                            ],
                                            "tarefas": [
                                                {
                                                  "prioridade": {
                                                    "id": 2,
                                                    "descricao": "Média"
                                                  },
                                                  "solicitante": null,
                                                  "executor": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "descricao": "Ligar para o lead",
                                                  "previsaoDeInicio": "2021-07-01T00:00:00-03:00",
                                                  "previsaoDeTermino": "2021-07-01T00:00:00-03:00",
                                                  "dataDeTermino": "2021-07-01T00:00:00-03:00",
                                                  "dataCancelamento": null,
                                                  "posicaoDoFunil": {
                                                    "id": 1,
                                                    "descricao": "Sem contato"
                                                  }
                                                },
                                                {
                                                  "prioridade": {
                                                    "id": 2,
                                                    "descricao": "Média"
                                                  },
                                                  "solicitante": null,
                                                  "executor": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "descricao": "Contato com sucesso, cliente agendado para o dia 21/07/2021",
                                                  "previsaoDeInicio": "2021-07-11T00:00:00-03:00",
                                                  "previsaoDeTermino": "2021-07-11T00:00:00-03:00",
                                                  "dataDeTermino": "2021-07-11T00:00:00-03:00",
                                                  "dataCancelamento": null,
                                                  "posicaoDoFunil": {
                                                    "id": 2,
                                                    "descricao": "Contato feito"
                                                  }
                                                },
                                                {
                                                  "prioridade": {
                                                    "id": 1,
                                                    "descricao": "Alta"
                                                  },
                                                  "solicitante": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "executor": {
                                                    "id": 3,
                                                    "cpf": null,
                                                    "nome": "Hugo Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                  },
                                                  "descricao": "Tentativa de contato sem sucesso",
                                                  "previsaoDeInicio": "2021-07-16T00:00:00-03:00",
                                                  "previsaoDeTermino": "2021-07-16T00:00:00-03:00",
                                                  "dataDeTermino": null,
                                                  "dataCancelamento": null,
                                                  "posicaoDoFunil": {
                                                    "id": 3,
                                                    "descricao": "Identificação do interesse"
                                                  }
                                                }
                                            ],
                                            anotacoes: [],
                                            listaEmails: []
                                        },
                                        {
                                            id: (14 + getListaDeLeads.kanbanData.length),
                                            cpf: "51123762449",
                                            nome: "Fulano B",
                                            statusKanban: `${getItemFunil}`,
                                            summary: "...",
                                            tags: "Contactar",
                                            color: "#E6AAAA",
                                            className: "e-story, e-low, e-nancy-davloio",
                                            dataDeNascimento: new Date("1970-06-02T00:00:00"),
                                            idade: undefined,
                                            nacionalidade: undefined,
                                            sexo: undefined,
                                            emails: [
                                                {
                                                    classificacao: 1,
                                                    descricao: "josiedson@cardial.br"
                                                },
                                                {
                                                    classificacao: 1,
                                                    descricao: "josiedson@cardial.br"
                                                }
                                            ],
                                            fotoDoLead: null,
                                            documentoPessoal: null,
                                            rg: null,
                                            estadoCivil: undefined,
                                            documentoDeEstadoCivil: null,
                                            regimeDeBens: undefined,
                                            ocupacao: undefined,
                                            renda: undefined,
                                            dadosDosVeiculos: undefined,
                                            dependentes: null,
                                            endereco: null,
                                            documentoEndereco: null,
                                            telefones: [
                                                {
                                                    classificacao: 1,
                                                    ddi: "55",
                                                    ddd: "81",
                                                    numero: "991595874",
                                                    observacao: ""
                                                },
                                                {
                                                    classificacao: 1,
                                                    ddi: "55",
                                                    ddd: "81",
                                                    numero: "991595874",
                                                    observacao: ""
                                                }
                                            ],
                                            localDeCaptacao: "SITE",
                                            status: 0,
                                            observacoes: [
                                                {
                                                    nome: "Washington Souza",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Izacc Moreira",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Calixto",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Hugo Moreira",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                },
                                                {
                                                    nome: "Lucas Moreira Assis",
                                                    data: new Date("2021-07-14T00:00:00"),
                                                    descricao: "observacao numero 01"
                                                }
                                            ],
                                            responsavel: undefined,
                                            posicaoDoFunil: {
                                                id: 3,
                                                descricao: "Identificação do interesse"
                                            },
                                            atividades: [
                                                {
                                                classificacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Email"
                                                },
                                                posicaoDoFunil: {
                                                    id: 1,
                                                    descricao: "Sem contato"
                                                },
                                                situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                },
                                                executor: {
                                                    id: 1,
                                                    cpf: null,
                                                    nome: "Washington dos Santos",
                                                    natureza: 0,
                                                    dataDeNascimento: null,
                                                    emails: null,
                                                    documentoPessoal: null,
                                                    rg: null,
                                                    creci: null,
                                                    estadoCivil: null,
                                                    documentoDeEstadoCivil: null,
                                                    regimeDeBens: null,
                                                    ocupacao: null,
                                                    necessarioAssinaturaDoConjuge: false,
                                                    conjuge: null,
                                                    endereco: null,
                                                    documentoEndereco: null,
                                                    telefones: null,
                                                    observacao: null
                                                },
                                                dataDoEvento: "2021-06-11T00:00:00-03:00",
                                                descricao: "Enviado email de boas vindas"
                                                },
                                                {
                                                classificacaoDaAtividade: {
                                                    id: 2,
                                                    descricao: "Tarefa"
                                                },
                                                posicaoDoFunil: {
                                                    id: 1,
                                                    descricao: "Sem contato"
                                                },
                                                situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                },
                                                executor: {
                                                    id: 2,
                                                    cpf: null,
                                                    nome: "Izacc Moreira",
                                                    natureza: 0,
                                                    dataDeNascimento: null,
                                                    emails: null,
                                                    documentoPessoal: null,
                                                    rg: null,
                                                    creci: null,
                                                    estadoCivil: null,
                                                    documentoDeEstadoCivil: null,
                                                    regimeDeBens: null,
                                                    ocupacao: null,
                                                    necessarioAssinaturaDoConjuge: false,
                                                    conjuge: null,
                                                    endereco: null,
                                                    documentoEndereco: null,
                                                    telefones: null,
                                                    observacao: null
                                                },
                                                dataDoEvento: "2021-07-01T00:00:00-03:00",
                                                descricao: "Tentativa de contato sem sucesso"
                                                },
                                                {
                                                classificacaoDaAtividade: {
                                                    id: 2,
                                                    descricao: "Tarefa"
                                                },
                                                posicaoDoFunil: {
                                                    id: 2,
                                                    descricao: "Contato feito"
                                                },
                                                situacaoDaAtividade: {
                                                    id: 1,
                                                    descricao: "Encerrada"
                                                },
                                                executor: {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "dataDoEvento": "2021-07-21T00:00:00-03:00",
                                                "descricao": "Contato com sucesso, cliente agendado para o dia 11/07/2021"
                                                },
                                                {
                                                "classificacaoDaAtividade": {
                                                    "id": 2,
                                                    "descricao": "Tarefa"
                                                },
                                                "posicaoDoFunil": {
                                                    "id": 3,
                                                    "descricao": "Identificação do interesse"
                                                },
                                                "situacaoDaAtividade": {
                                                    "id": 0,
                                                    "descricao": "Aberta"
                                                },
                                                "executor": {
                                                    "id": 3,
                                                    "cpf": null,
                                                    "nome": "Hugo Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "dataDoEvento": "2021-07-16T00:00:00-03:00",
                                                "descricao": "Tentativa de contato sem sucesso"
                                                }
                                            ],
                                            "tarefas": [
                                                {
                                                "prioridade": {
                                                    "id": 2,
                                                    "descricao": "Média"
                                                },
                                                "solicitante": null,
                                                "executor": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "descricao": "Ligar para o lead",
                                                "previsaoDeInicio": "2021-07-01T00:00:00-03:00",
                                                "previsaoDeTermino": "2021-07-01T00:00:00-03:00",
                                                "dataDeTermino": "2021-07-01T00:00:00-03:00",
                                                "dataCancelamento": null,
                                                "posicaoDoFunil": {
                                                    "id": 1,
                                                    "descricao": "Sem contato"
                                                }
                                                },
                                                {
                                                "prioridade": {
                                                    "id": 2,
                                                    "descricao": "Média"
                                                },
                                                "solicitante": null,
                                                "executor": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "descricao": "Contato com sucesso, cliente agendado para o dia 21/07/2021",
                                                "previsaoDeInicio": "2021-07-11T00:00:00-03:00",
                                                "previsaoDeTermino": "2021-07-11T00:00:00-03:00",
                                                "dataDeTermino": "2021-07-11T00:00:00-03:00",
                                                "dataCancelamento": null,
                                                "posicaoDoFunil": {
                                                    "id": 2,
                                                    "descricao": "Contato feito"
                                                }
                                                },
                                                {
                                                "prioridade": {
                                                    "id": 1,
                                                    "descricao": "Alta"
                                                },
                                                "solicitante": {
                                                    "id": 2,
                                                    "cpf": null,
                                                    "nome": "Izacc Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "executor": {
                                                    "id": 3,
                                                    "cpf": null,
                                                    "nome": "Hugo Moreira",
                                                    "natureza": 0,
                                                    "dataDeNascimento": null,
                                                    "emails": null,
                                                    "documentoPessoal": null,
                                                    "rg": null,
                                                    "creci": null,
                                                    "estadoCivil": null,
                                                    "documentoDeEstadoCivil": null,
                                                    "regimeDeBens": null,
                                                    "ocupacao": null,
                                                    "necessarioAssinaturaDoConjuge": false,
                                                    "conjuge": null,
                                                    "endereco": null,
                                                    "documentoEndereco": null,
                                                    "telefones": null,
                                                    "observacao": null
                                                },
                                                "descricao": "Tentativa de contato sem sucesso",
                                                "previsaoDeInicio": "2021-07-16T00:00:00-03:00",
                                                "previsaoDeTermino": "2021-07-16T00:00:00-03:00",
                                                "dataDeTermino": null,
                                                "dataCancelamento": null,
                                                "posicaoDoFunil": {
                                                    "id": 3,
                                                    "descricao": "Identificação do interesse"
                                                }
                                                }
                                            ],
                                            anotacoes: [],
                                            listaEmails: []
                                    })
    
                                    setListaFasesDoFunil(getListaFasesDoFunil);
                                    setItemFunil("");
                                    handleDialogCreateFaseClose();
                                }
                                else {
                                    NotificacaoInterna.ExibirNotificacao("Nome da fase existente", "Preencha com um nome válido", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                }
                            }
                            else {

                                if (getListaFasesDoFunil.filter((item, index) => item.descricao == getItemFunil).length == 0)
                                {

                                    getListaFasesDoFunil.map((item, index) => {
                                        if (item.id == getItemFunilSelecionado?.id)
                                        {
                                            console.log('bbbb')
                                            item.descricao = getItemFunil
                                        }
                                    })

                                    setListaFasesDoFunil(getListaFasesDoFunil)
                                    setItemFunil("");
                                    setAlterarItemFunil(false)
                                    handleDialogCreateFaseClose();
                                }
                                else {
                                    NotificacaoInterna.ExibirNotificacao("Nome da fase existente", "Preencha com um nome válido", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                }
                            }
                        }}
                    />
                </div>

            </Dialog>

            {/** Dialog customizando component formulario start */}
            <Dialog
                style = {{width: '40%', backgroundColor: "#FFFFFF"}}
                icon="annotation"
                onClose={() => {
                    setDragStart(false)
                    handleDialogCustomComponentClose()
                }}
                title="Customizando componente"
                autoFocus = {true}
                isOpen = {getDialogCustomComponent.isOpen}
                canEscapeKeyClose = {getDialogCustomComponent.canEscapeKeyClose}
                canOutsideClickClose = {getDialogCustomComponent.canOutsideClickClose}
                enforceFocus = {getDialogCustomComponent.enforceFocus}
                usePortal = {getDialogCustomComponent.usePortal} 
            >
                <div style = {{display: "flex", alignItems: "center"}}>
                    <div style = {{marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRight: "0.5px solid #00000050", paddingBottom: "20px"}}>

                        <div style = {{fontWeight: "bold", fontSize: "16px", width: "95%"}}>{getSelectCustomComponent?.classificacao.descricao}</div>
                        
                        <div style = {{width: "95%", marginTop: "20px"}}>
                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Titulo`}</div>
                            <Component.InputTask style = {{width: "100%"}} id="CustomLabel" placeholder={"Titulo do campo..."} value={getSelectCustomComponent?.titulo}
                                onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                    var TextoDigitado = event.currentTarget.value

                                    if (getSelectCustomComponent)
                                    {
                                        let selectCustomComponente = {...getSelectCustomComponent}
                                        selectCustomComponente.titulo = TextoDigitado
                                        setSelectCustomComponent(selectCustomComponente);
                                    }
                                }}>
                            </Component.InputTask>
                        </div>

                        <div style = {{display: "flex", flexDirection: "column", width: "95%", marginTop: "20px"}}>
                            <div style = {{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                                <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px", marginRight: "10px"}}>Este campo é obrigatório</div>
                                <Switch/>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div style = {{ width: "100%", paddingRight: "2.5%", paddingLeft: "2.5%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center", borderTop: "0.5px solid #00000050"}}>
                    <Button
                        style = {{
                            flex: 1,
                            background: "#1098F7", 
                            color: "#FFFFFF",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px",
                            height: "15px",
                            marginTop: "20px"
                        }}
                        text = {"Salvar"}
                        onClick = {() => {

                            if (getSelectCustomComponent && getSelectCustomComponent.titulo != "") {

                                getTiposDeComponentesFormulario.push(
                                    {
                                        id: getTiposDeComponentesFormulario.length,
                                        titulo: getSelectCustomComponent.titulo,
                                        classificacao: getSelectCustomComponent.classificacao,
                                        obrigatorio: getSelectCustomComponent.obrigatorio,
                                        resposta: getSelectCustomComponent.resposta
                                    }
                                );
    
                                setTiposDeComponenesFormulario(getTiposDeComponentesFormulario)
                                
                                setDragStart(false) 
                                handleDialogCustomComponentClose()
                            }
                            else {}
                        }}
                    />
                </div>

            </Dialog>

            {/* Drawer adicionar sala */}
            <Drawer
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"people"}
                onClose={handleCloseDrawerAdicionarSala}
                title = {getIdSala == 0 ? "Adicionar sala" : "Alterar sala"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionarSala.isOpen}
                canEscapeKeyClose = {getDrawerAdicionarSala.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionarSala.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionarSala.enforceFocus}
                usePortal = {getDrawerAdicionarSala.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>

                    <div 
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>
                                    <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Sala`}</div>
                                            <Component.Input id = {`Nome`} placeholder="Digite o nome da sala..." value={getDescriptionSala}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setDescriptionSala(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>
                                    </Component.ContentFormHeader>
                                </Component.ContentCard>
                            </div>
                        </div>
                        
                        <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button
                                icon = {<Icon icon = {getIdSala == 0 ? "add" : "edit"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: getIdSala == 0 ? "#1098F7" : lighten(0.1, "#D74E09"), 
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "250px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: '10px',
                                }}
                                onClick = { async () => {

                                    if (getDescriptionSala != "" && getIdSala == 0) {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "A sala está sendo cadastrada.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        var ObjetoSala = {} as {id: number, descricao: string}

                                        ObjetoSala = { 
                                            id: 0, 
                                            descricao: getDescriptionSala 
                                        }

                                        let Response = await SalaDeVendas.Post(ContextSignIn.getContext().token, ObjetoSala);

                                        if (Math.floor(Response.status / 100) == 2)
                                        {
                                            let ResponseDados = await SalaDeVendas.Get(ContextSignIn.getContext().token)
                                            
                                            if(Math.floor(ResponseDados.status / 100) == 2)
                                            {

                                                let ListaDeSalas = [] as Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>

                                                ResponseDados.data.map((item) => {
                                                    ListaDeSalas.push({
                                                        id: item.id,
                                                        descricao: item.descricao,
                                                        areas: item.areas,
                                                    })
                                                });

                                                setListaDeSalas(ListaDeSalas);
                                                setDescriptionSala("");
                                                NotificacaoInterna.ExibirNotificacao("A sala foi cadastrada", "Sala cadastrada com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                handleCloseDrawerAdicionarSala();
                                            }
                                            else
                                            {
                                                NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar a sala de venda", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            }
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar a sala de venda", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else if (getDescriptionSala != "" && getIdSala > 0){
                                        
                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "A sala está sendo alterada.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        var ObjetoSala = {} as {id: number, descricao: string}

                                        ObjetoSala = { 
                                            id: getIdSala, 
                                            descricao: getDescriptionSala 
                                        }

                                        let Response = await SalaDeVendas.Put(ContextSignIn.getContext().token, ObjetoSala);

                                        if (Math.floor(Response.status / 100) == 2) 
                                        {
                                            let ListaDeSalas = [...getListaDeSalas];

                                            let ItemIndex = ListaDeSalas.findIndex(item => item.id == getIdSala);

                                            ListaDeSalas[ItemIndex].descricao = getDescriptionSala;

                                            setListaDeSalas(ListaDeSalas);
                                            setDescriptionSala("");
                                            NotificacaoInterna.ExibirNotificacao("A sala foi alterada", "Sala alterada com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            handleCloseDrawerAdicionarSala()
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao alterar a sala de venda", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else
                                    {
                                        NotificacaoInterna.ExibirNotificacao("A sala não foi preenchida", "Prencha a sala.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    }

                            }}>{getIdSala == 0 ? "Adicionar sala" : "Alterar sala"}</Button>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/* Drawer adicionar área */}
            <Drawer
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"list"}
                onClose={handleCloseDrawerAdicionarDepartamento}
                title = {getIdDepartamento == 0 ? "Adicionar área" : "Alterar área"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionarDepartamento.isOpen}
                canEscapeKeyClose = {getDrawerAdicionarDepartamento.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionarDepartamento.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionarDepartamento.enforceFocus}
                usePortal = {getDrawerAdicionarDepartamento.usePortal}
            >

                <div style = {{display: "flex", width: "100%", height: "100%"}}>

                    <div 
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>
                                    <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Área`}</div>
                                            <Component.Input id = {`Nome`} placeholder="Digite o nome da área..." value={getDescriptionDepartamento}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setDescriptionDepartamento(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>
                                    </Component.ContentFormHeader>
                                </Component.ContentCard>
                            </div>
                        </div>
                        
                        <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button
                                icon = {<Icon icon = {getIdDepartamento == 0 ? "add" : "edit"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: getIdDepartamento == 0 ? "#1098F7" : lighten(0.1, "#D74E09"), 
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "250px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: '10px',
                                }}
                                onClick = { async () => {
                                    if (getDescriptionDepartamento != "" && getIdDepartamento == 0) {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O departamento está sendo cadastrado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        var ObjetoDepartamento = {} as {id: number, descricao: string}

                                        ObjetoDepartamento = { id: 0, descricao: getDescriptionDepartamento }

                                        let Response = await Areas.Post(ContextSignIn.getContext().token, ObjetoDepartamento);

                                        if (Math.floor(Response.status / 100) == 2) 
                                        {
                                            let ResponseDados = await Areas.Get(ContextSignIn.getContext().token)
                                            
                                            if(Math.floor(ResponseDados.status / 100) == 2)
                                            {

                                                let ListaDeDepartamentos = [] as Array<{id: number, descricao: string}>

                                                ResponseDados.data.map((item) => {
                                                    ListaDeDepartamentos.push({
                                                        id: item.id,
                                                        descricao: item.descricao
                                                    })
                                                });

                                                setListaDeAreas(ListaDeDepartamentos);
                                                setDescriptionDepartamento("");
                                                NotificacaoInterna.ExibirNotificacao("O departamento foi cadastrada", "Departamento cadastrado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                handleCloseDrawerAdicionarDepartamento();
                                            }
                                            else
                                            {
                                                NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar o departamento", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            }
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar o departamento", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else if (getDescriptionDepartamento != "" && getIdDepartamento > 0){
                                        
                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O departamento está sendo alterado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        var ObjetoDepartamento = {} as {id: number, descricao: string}

                                        ObjetoDepartamento = { id: getIdDepartamento, descricao: getDescriptionDepartamento }

                                        let Response = await Areas.Put(ContextSignIn.getContext().token, ObjetoDepartamento);

                                        if (Math.floor(Response.status / 100) == 2) 
                                        {
                                            let ListaDeDepartamentos = [...getListaDeAreas];

                                            let ItemIndex = ListaDeDepartamentos.findIndex(item => item.id == getIdDepartamento);

                                            ListaDeDepartamentos[ItemIndex].descricao = getDescriptionDepartamento;

                                            // ListaDeDepartamentos.map((item, index) => {
                                            //     if (item.id == Response.data.id)
                                            //     {
                                            //         item.descricao = Response.data.descricao
                                            //     }
                                            // })
                                            setListaDeAreas(ListaDeDepartamentos);
                                            setDescriptionDepartamento("");
                                            NotificacaoInterna.ExibirNotificacao("A área foi alterada", "Área alterada com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            handleCloseDrawerAdicionarDepartamento();
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao alterar a área", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else
                                    {
                                        NotificacaoInterna.ExibirNotificacao("A área não foi preenchido", "Prencha a área.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    }
                            }}>{getIdDepartamento == 0 ? "Adicionar área" : "Alterar área"}</Button>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/* Drawer adicionar usuário */}
            <Drawer
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"people"}
                onClose={handleCloseDrawerAdicionarUsuario}
                title = {getFormularioID == -1 ? 'Adicionar usuário' : 'Atualizar usuário'}
                autoFocus = {true}
                isOpen = {getDrawerAdicionarUsuario.isOpen}
                canEscapeKeyClose = {getDrawerAdicionarUsuario.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionarUsuario.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionarUsuario.enforceFocus}
                usePortal = {getDrawerAdicionarUsuario.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>

                    <div 
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s",
                            overflowY: 'scroll'
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>
                                    <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Nome`}</div>
                                            <Component.Input id = {`Nome`} placeholder="Digite aqui o nome..." value={getFormularioNome}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setFormularioNome(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`CPF`}</div>
                                            <Component.Input id = {`CPF`} placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getFormularioCPF)}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                                    setFormularioCPF(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Email`}</div>
                                            <Component.Input id = {`Email`} placeholder={"Digite aqui o email..."} value={getFormularioEmail}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setFormularioEmail(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>
                                        {getFormularioID == -1 && 
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Senha`}</div>
                                            <Component.Input id = {`Senha`} placeholder={"*********"} type="password" value={getFormularioSenha}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setFormularioSenha(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>}

                                        {/** CARGOS */}
                                        <div style = {{width: "calc(27vw + 15px)", marginBottom: '10px'}}>
                                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{getItensSelecionadosPickerCargos.length > 1 ? `Cargos` : `Cargo`}</div>
                                            <PickerCargos popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true, fill: true}} items={getListaDeCargos} onItemSelect={() => { }} noResults={<MenuItem style = {{maxWidth: "16vw"}} disabled={true} text="Sem resultados disponíveis" />}
                                            fill={true} tagRenderer={(Item: {id: number, nome: string}) => Item.nome} selectedItems={getItensSelecionadosPickerCargos} placeholder={""}
                                            tagInputProps = {{
                                                inputProps: {style: {padding: "0px", backgroundColor: lighten(0.1, '#D3D3D3')}},
                                                onRemove: (value: React.ReactNode, index: number) => {
                                                    let ListaDeItensSelecionadosCargos = [...getItensSelecionadosPickerCargos];

                                                    let ListaFiltrada = ListaDeItensSelecionadosCargos.filter((Cargos, IndexCargos: number) => IndexCargos != index);

                                                    setItensSelecionadosPickerCargos(ListaFiltrada);
                                                },
                                                rightElement: <Button icon="cross" minimal={true} 
                                                onClick={() => { setItensSelecionadosPickerCargos([]) }}
                                                />
                                            }}
                                            itemRenderer={(Item: {id: number, nome: string}) => <MenuItem style = {{width: "calc(27vw + 5px)"}} text={Item.nome} onClick = {() => {

                                                let ListaDeItensSelecionadosListaCargos = [...getItensSelecionadosPickerCargos];

                                                let ListaFiltrada = ListaDeItensSelecionadosListaCargos.filter(cargo => cargo.id == Item.id);

                                                if (ListaFiltrada.length == 0)
                                                {
                                                    ListaDeItensSelecionadosListaCargos.push(Item);
                                                    setItensSelecionadosPickerCargos(ListaDeItensSelecionadosListaCargos);
                                                }

                                            }}/>} itemPredicate={(Texto: string, Item: {id: number, nome: string}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}/>
                                        </div>

                                        {/** EMPRESAS */}
                                        <div style = {{width: "calc(27vw + 15px)", marginBottom: '10px'}}>
                                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{getItensSelecionadosEmpresa.length > 1 ? `Empresas` : `Empresa`}</div>
                                            <MultiSelectEmpresas popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true, fill: true}} items={getListaPickerDeEmpresas} onItemSelect={() => { }} noResults={<MenuItem style = {{maxWidth: "16vw"}} disabled={true} text="Sem resultados disponíveis" />}
                                            fill={true} tagRenderer={(Item: Objeto.Empresa) => Item.nomeFantasia} selectedItems={getItensSelecionadosEmpresa} placeholder={""}
                                            tagInputProps = {{
                                                inputProps: {style: {padding: "0px", backgroundColor: lighten(0.1, '#D3D3D3')}},
                                                onRemove: (value: React.ReactNode, index: number) => {

                                                    let ListaDeItensSelecionadosEmpresas = [...getItensSelecionadosEmpresa];

                                                    let ItemFiltrado = ListaDeItensSelecionadosEmpresas.filter((Empresas, IndexEmpresas: number) => IndexEmpresas == index);

                                                    let ListaFiltrada = ListaDeItensSelecionadosEmpresas.filter((Empresas, IndexEmpresas: number) => IndexEmpresas != index);

                                                    setItensSelecionadosEmpresa(ListaFiltrada);

                                                    let ListaRetroAlimentativaMultiSelectCentroDeCusto = [...getListaRetroAlimentativaMultiSelectCentroDeCusto];

                                                    let ListaFiltradaRetroAlimentativa = ListaRetroAlimentativaMultiSelectCentroDeCusto.filter(centro => centro.empresa.id != ItemFiltrado[0].id)

                                                    setListaRetroAlimentativaMultiSelectCentroDeCusto(ListaFiltradaRetroAlimentativa);
                                                },
                                                rightElement: <Button icon="cross" minimal={true} 
                                                onClick={() => {
                                                    setItensSelecionadosEmpresa([]);
                                                    setListaRetroAlimentativaMultiSelectCentroDeCusto([]);
                                                    setItensSelecionadosCentroDeCusto([]);
                                                }}
                                                />
                                            }}
                                            itemRenderer={(Item: Objeto.Empresa) => <MenuItem style = {{width: "calc(27vw + 5px)"}} text={Item.nomeFantasia} onClick = {() => {

                                                let ListaDeItensSelecionadosEmpresas = [...getItensSelecionadosEmpresa];

                                                let ListaFiltrada = ListaDeItensSelecionadosEmpresas.filter(empresa => empresa.id == Item.id);

                                                if(ListaFiltrada.length == 0)
                                                {
                                                    ListaDeItensSelecionadosEmpresas.push(Item);
                                                    setItensSelecionadosEmpresa(ListaDeItensSelecionadosEmpresas);

                                                    let ListaPickerDeCentroDeCusto = [...getListaPickerCentroDeCusto];

                                                    let ListaRetroAlimentativaMultiSelectCentroDeCusto = [...getListaRetroAlimentativaMultiSelectCentroDeCusto];

                                                    let ListaFiltradaCentroDeCusto = ListaPickerDeCentroDeCusto.filter(item => item.empresa.id == Item.id);

                                                    ListaRetroAlimentativaMultiSelectCentroDeCusto.push(...ListaFiltradaCentroDeCusto);
                                                    setListaRetroAlimentativaMultiSelectCentroDeCusto(ListaRetroAlimentativaMultiSelectCentroDeCusto);
                                                }

                                            }}/>} itemPredicate={(Texto: string, Item: Objeto.Empresa) => { return (Item.nomeFantasia ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}/>
                                        </div>

                                        {/** CENTRO DE CUSTO */}
                                        <div style = {{width: "calc(27vw + 15px)", marginBottom: '10px'}}>
                                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{getItensSelecionadosCentroDeCusto.length > 1 ? `Centro de custo` : `Centros de custo`}</div>
                                            <MultiSelectCentroDeCusto popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true, fill: true}} items={getListaRetroAlimentativaMultiSelectCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem style = {{maxWidth: "16vw"}} disabled={true} text="Sem resultados disponíveis" />}
                                            fill={true} tagRenderer={(Item: Objeto.CentroDeCusto) => Item.descricao} selectedItems={getItensSelecionadosCentroDeCusto} placeholder={""}
                                            tagInputProps = {{
                                                inputProps: {style: {padding: "0px", backgroundColor: lighten(0.1, '#D3D3D3')}},
                                                onRemove: (value: React.ReactNode, index: number) => {

                                                    let ListaDeItensSelecionadosCentroDeCusto = [...getItensSelecionadosCentroDeCusto];

                                                    let ListaFiltrada = ListaDeItensSelecionadosCentroDeCusto.filter((centro, IndexCentro: number) => IndexCentro != index);

                                                    setItensSelecionadosCentroDeCusto(ListaFiltrada);
                                                },
                                                rightElement: <Button icon="cross" minimal={true} 
                                                onClick={() => { setItensSelecionadosCentroDeCusto([]) }}
                                                />
                                            }}
                                            itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem style = {{width: "calc(27vw + 5px)"}} text={Item.descricao} onClick = {() => {

                                                let ListaDeItensSelecionadosCentroDeCusto = [...getItensSelecionadosCentroDeCusto];

                                                let ListaFiltrada = ListaDeItensSelecionadosCentroDeCusto.filter(centro => centro.sigla == Item.sigla);

                                                if(ListaFiltrada.length == 0)
                                                {
                                                    ListaDeItensSelecionadosCentroDeCusto.push(Item);
                                                    setItensSelecionadosCentroDeCusto(ListaDeItensSelecionadosCentroDeCusto);
                                                }

                                            }}/>} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}/>
                                        </div>

                                        {/** CONTROLE DE SALA */}
                                        <div style = {{width: "calc(27vw + 15px)", marginBottom: '10px'}}>
                                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{getItensSelecionadosControleDeSala.length > 1 ? `Controles de sala` : `Controle de sala`}</div>
                                            <MultiSelectControleDeSala popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true, fill: true}} items={getListaPickerControleDeSala} onItemSelect={() => { }} noResults={<MenuItem style = {{maxWidth: "16vw"}} disabled={true} text="Sem resultados disponíveis" />}
                                            fill={true} tagRenderer={(Item: Objeto.ControleDeSala) => Item.descricao} selectedItems={getItensSelecionadosControleDeSala} placeholder={""}
                                            tagInputProps = {{
                                                inputProps: {style: {padding: "0px", backgroundColor: lighten(0.1, '#D3D3D3')}},
                                                onRemove: (value: React.ReactNode, index: number) => {

                                                    let ListaDeItensSelecionadosControleDeSala = [...getItensSelecionadosControleDeSala];

                                                    let ListaFiltrada = ListaDeItensSelecionadosControleDeSala.filter((controle, IndexControle: number) => IndexControle != index);

                                                    setItensSelecionadosControleDeSala(ListaFiltrada);
                                                },
                                                rightElement: <Button icon="cross" minimal={true} 
                                                onClick={() => { setItensSelecionadosControleDeSala([]) }}
                                                />
                                            }}
                                            itemRenderer={(Item: Objeto.ControleDeSala) => <MenuItem style = {{width: "calc(27vw + 5px)"}} text={Item.descricao} onClick = {() => {

                                                let ListaDeItensSelecionadosControleDeSala = [...getItensSelecionadosControleDeSala];

                                                let ListaFiltrada = ListaDeItensSelecionadosControleDeSala.filter(controle => controle.id == Item.id);

                                                if(ListaFiltrada.length == 0)
                                                {
                                                    ListaDeItensSelecionadosControleDeSala.push(Item);
                                                    setItensSelecionadosControleDeSala(ListaDeItensSelecionadosControleDeSala);
                                                }

                                            }}/>} itemPredicate={(Texto: string, Item: Objeto.ControleDeSala) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}/>
                                        </div>

                                        {/** FUNIL DE VENDA */}
                                        <div style = {{width: "calc(27vw + 15px)", marginBottom: '10px'}}>
                                            <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{getItensSelecionadosFunilDeVenda.length > 1 ? `Funis de venda` : `Funil de venda`}</div>
                                            <MultiSelectFunilDeVenda popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true, fill: true}} items={getListaPickerFunilDeVenda} onItemSelect={() => { }} noResults={<MenuItem style = {{maxWidth: "16vw"}} disabled={true} text="Sem resultados disponíveis" />}
                                            fill={true} tagRenderer={(Item: Objeto.FunilDeVendas) => Item.descricao} selectedItems={getItensSelecionadosFunilDeVenda} placeholder={""}
                                            tagInputProps = {{
                                                inputProps: {style: {padding: "0px", backgroundColor: lighten(0.1, '#D3D3D3')}},
                                                onRemove: (value: React.ReactNode, index: number) => {

                                                    let ListaDeItensSelecionadosFunilDeVenda = [...getItensSelecionadosFunilDeVenda];

                                                    let ListaFiltrada = ListaDeItensSelecionadosFunilDeVenda.filter((funil, IndexFunil: number) => IndexFunil != index);

                                                    setItensSelecionadosFunilDeVenda(ListaFiltrada);
                                                },
                                                rightElement: <Button icon="cross" minimal={true} 
                                                onClick={() => { setItensSelecionadosFunilDeVenda([]) }}
                                                />
                                            }}
                                            itemRenderer={(Item: Objeto.FunilDeVendas) => <MenuItem style = {{width: "calc(27vw + 5px)"}} text={Item.descricao} onClick = {() => {

                                                let ListaDeItensSelecionadosFunilDeVenda = [...getItensSelecionadosFunilDeVenda];

                                                let ListaFiltrada = ListaDeItensSelecionadosFunilDeVenda.filter(controle => controle.id == Item.id);

                                                if(ListaFiltrada.length == 0)
                                                {
                                                    ListaDeItensSelecionadosFunilDeVenda.push(Item);
                                                    setItensSelecionadosFunilDeVenda(ListaDeItensSelecionadosFunilDeVenda);
                                                }

                                            }}/>} itemPredicate={(Texto: string, Item: Objeto.FunilDeVendas) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}/>
                                        </div>

                                    </Component.ContentFormHeader>
                                </Component.ContentCard>
                            </div>
                            <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Button
                                    icon = {<Icon icon = {getFormularioID == -1 ? "add" : "edit"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                    style = {{
                                        background: getFormularioID == -1 ? "#1098F7" : lighten(0.1, "#D74E09"), 
                                        color: "#FFFFFF", 
                                        border: "none", 
                                        boxShadow: "none", 
                                        borderRadius: "5px", 
                                        outline: "none",
                                        width: "250px", 
                                        display: "flex", 
                                        alignItems: "center", 
                                        fontSize: "12px", 
                                        height: "40px", 
                                        marginRight: '10px',
                                        marginBottom: "10px"
                                    }}
                                    onClick = { async () => {

                                        if (getFormularioID == -1)
                                        {
                                            if(await ValidarFormulario() == true)
                                            {
                                                var ResponsePessoa = await Pessoa.Get(ContextSignIn.getContext().token, CPF.DesformatarTexto(getFormularioCPF));
                                                if (Math.floor(ResponsePessoa.status / 100) == 2) {
                                                    NotificacaoInterna.ExibirNotificacao("Usuário encontrado", "Aguarde enquanto vinculamos o seu usuário aos sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                    let ObjetoUsuario = {} as {id: number, nome: string, cargos: Array<{id: number, nome: string}>, email: {classificacao: number, descricao: string}, senha: string, tokenNotificacao: string | null, empresasPermitidas: Array<Objeto.Empresa>, centroDeCustoPermitidos: Array<Objeto.CentroDeCusto>, controlesDeSalaPermitidos: Array<Objeto.ControleDeSala>, funisPermitidos: Array<Objeto.FunilDeVendas>}

                                                    ObjetoUsuario = {
                                                        id: ResponsePessoa.data.id,
                                                        nome: ResponsePessoa.data.nome,
                                                        cargos: getItensSelecionadosPickerCargos,
                                                        email: { classificacao: 1, descricao: getFormularioEmail } as Objeto.Email,
                                                        senha: getFormularioSenha,
                                                        tokenNotificacao: null,
                                                        empresasPermitidas: getItensSelecionadosEmpresa,
                                                        centroDeCustoPermitidos: getItensSelecionadosCentroDeCusto,
                                                        controlesDeSalaPermitidos: getItensSelecionadosControleDeSala,
                                                        funisPermitidos: getItensSelecionadosFunilDeVenda
                                                    }

                                                    console.log(JSON.stringify(ObjetoUsuario))

                                                    let ResponseUsuario = await Logon.NovoUsuario(ContextSignIn.getContext().token, ObjetoUsuario)
                                                    if(Math.floor(ResponseUsuario.status / 100) == 2)
                                                    {
                                                        NotificacaoInterna.ExibirNotificacao("Tudo pronto!", "Espero que tenha uma excelente experiência com nosso sistema", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                        handleCloseDrawerAdicionarUsuario();
                                                    }
                                                    else
                                                    {
                                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Você já se encontra cadastrado em nosso sistema!", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                    }
                                                }
                                                else {
                                                    NotificacaoInterna.ExibirNotificacao("Cadastrando usuário...", "Aguarde enquanto cadastramos o seu usuário", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                                    ResponsePessoa = await Pessoa.Post(ContextSignIn.getContext().token, {
                                                        nome: getFormularioNome,
                                                        cpf: CPF.DesformatarTexto(getFormularioCPF),
                                                        natureza: 5,
                                                        emails: [{ classificacao: 1, descricao: getFormularioEmail } as Objeto.Email]
                                                    } as Objeto.Pessoa);
                                                    NotificacaoInterna.ExibirNotificacao("Usuário cadastrado!", "Aguarde enquanto vinculamos o seu usuário aos sistemas externos", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                                    let ObjetoUsuario = {} as {id: number, nome: string, cargos: Array<{id: number, nome: string}>, email: {classificacao: number, descricao: string}, senha: string, tokenNotificacao: string | null, empresasPermitidas: Array<Objeto.Empresa>, centroDeCustoPermitidos: Array<Objeto.CentroDeCusto>, controlesDeSalaPermitidos: Array<Objeto.ControleDeSala>, funisPermitidos: Array<Objeto.FunilDeVendas>}

                                                    ObjetoUsuario = {
                                                        id: ResponsePessoa.data.id,
                                                        nome: ResponsePessoa.data.nome,
                                                        cargos: getItensSelecionadosPickerCargos,
                                                        email: { classificacao: 1, descricao: getFormularioEmail } as Objeto.Email,
                                                        senha: getFormularioSenha,
                                                        tokenNotificacao: null,
                                                        empresasPermitidas: getItensSelecionadosEmpresa,
                                                        centroDeCustoPermitidos: getItensSelecionadosCentroDeCusto,
                                                        controlesDeSalaPermitidos: getItensSelecionadosControleDeSala,
                                                        funisPermitidos: getItensSelecionadosFunilDeVenda
                                                    }

                                                    console.log(JSON.stringify(ObjetoUsuario))

                                                    let ResponseUsuario = await Logon.NovoUsuario(ContextSignIn.getContext().token, ObjetoUsuario)
                                                    if(Math.floor(ResponseUsuario.status / 100) == 2)
                                                    {
                                                        NotificacaoInterna.ExibirNotificacao("Tudo pronto!", "Espero que tenha uma excelente experiência com nosso sistema", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                        handleCloseDrawerAdicionarUsuario();
                                                    }
                                                    else {
                                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Você já se encontra cadastrado em nosso sistema!", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                    }
                                                }
                                            }

                                        }

                                }}>{getFormularioID == -1 ? 'Adicionar usuário' : 'Atualizar usuário'}</Button>
                            </div>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/* Drawer adicionar Empresa */}
            <Drawer
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"office"}
                onClose={handleCloseDrawerAdicionarEmpresa}
                title = {getIdEmpresa == 0 ? "Adicionar empresa" : "Alterar empresa"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionarEmpresa.isOpen}
                canEscapeKeyClose = {getDrawerAdicionarEmpresa.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionarEmpresa.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionarEmpresa.enforceFocus}
                usePortal = {getDrawerAdicionarEmpresa.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>

                    <div 
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>
                                    <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Nome da empresa`}</div>
                                            <Component.Input id = {`Nome Fantasia`} placeholder="Digite o nome da empresa..." value={getNomeDaEmpresa} style={{margin:"10px 0px"}}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setNomeDaEmpresa((TextoDigitado).toUpperCase())
                                                }}>
                                            </Component.Input>
                                            <div style = {{marginBottom: "5px"}}>{`Razão social`}</div>
                                            <Component.Input id = {`Razão Social`} placeholder="Digite a razão social da empresa..." value={getRazaoSocialDaEmpresa} style={{margin:"10px 0px"}}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setRazaoSocialDaEmpresa((TextoDigitado).toUpperCase())
                                                }}>
                                            </Component.Input>
                                            <div style = {{marginBottom: "5px"}}>{`CNPJ`}</div>
                                            <Component.Input id = {`CNPJ`} placeholder="Digite o CNPJ da empresa..." maxLength={14} value={getCnpjDaEmpresa} style={{margin:"10px 0px"}}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setCnpjDaEmpresa(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>
                                    </Component.ContentFormHeader>
                                </Component.ContentCard>
                            </div>
                        </div>
                        
                        <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button
                                icon = {<Icon icon = {getIdEmpresa == 0 ? "add" : "edit"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: getIdEmpresa == 0 ? "#1098F7" : lighten(0.1, "#D74E09"), 
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "250px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: '10px',
                                }}
                                onClick = { async () => {

                                    if (getNomeDaEmpresa != "" && getRazaoSocialDaEmpresa != "" && getCnpjDaEmpresa != "" && getIdEmpresa == 0) {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "A empresa está sendo cadastrada.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let Response = await Empresa.Post(ContextSignIn.getContext().token, getNomeDaEmpresa, getRazaoSocialDaEmpresa, getCnpjDaEmpresa);

                                        if (Math.floor(Response.status / 100) == 2){

                                            let ListaDeEmpresas = [...getListaDeEmpresas];

                                            ListaDeEmpresas.push(Response.data);

                                            setListaDeEmpresas(ListaDeEmpresas);
                                            setNomeDaEmpresa("");
                                            setRazaoSocialDaEmpresa("");
                                            setCnpjDaEmpresa("");
                                            NotificacaoInterna.ExibirNotificacao("A empresa foi cadastrada", "Empresa cadastrada com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            handleCloseDrawerAdicionarEmpresa()
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar a empresa", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else if (getNomeDaEmpresa != "" && getIdEmpresa > 0 && getRazaoSocialDaEmpresa != "" && getCnpjDaEmpresa != ""){
                                        
                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "A empresa está sendo alterada.", NotificacaoInterna.TipoDeNotificacao.Informacao);


                                        let Response = await Empresa.Put(ContextSignIn.getContext().token, getNomeDaEmpresa, getRazaoSocialDaEmpresa, getCnpjDaEmpresa);

                                        if (Math.floor(Response.status / 100) == 2) 
                                        {
                                            let ListaDeEmpresas = [...getListaDeEmpresas];

                                            let ItemIndex = ListaDeEmpresas.findIndex(item => item.id == getIdEmpresa);

                                            ListaDeEmpresas[ItemIndex] = Response.data;

                                            setListaDeEmpresas(ListaDeEmpresas);
                                            setNomeDaEmpresa("");
                                            setRazaoSocialDaEmpresa("");
                                            setCnpjDaEmpresa("");
                                            NotificacaoInterna.ExibirNotificacao("A empresa foi alterada", "Empresa alterada com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            handleCloseDrawerAdicionarEmpresa()
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao alterar a empresa", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else
                                    {
                                        NotificacaoInterna.ExibirNotificacao("As informações não foram preenchidas", "Prencha as informações.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    }

                            }}>{getIdEmpresa == 0 ? "Adicionar empresa" : "Alterar empresa"}</Button>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/* Drawer adicionar Centro de Custo */}
            <Drawer
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"numbered-list"}
                onClose={handleCloseDrawerAdicionarCentroDeCusto}
                title = {getIdCentroDeCusto == "" ? "Adicionar centro de custo" : "Alterar centro de custo"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionarCentroDeCusto.isOpen}
                canEscapeKeyClose = {getDrawerAdicionarCentroDeCusto.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionarCentroDeCusto.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionarCentroDeCusto.enforceFocus}
                usePortal = {getDrawerAdicionarCentroDeCusto.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>

                    <div 
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>
                                    <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Descrição`}</div>
                                            <Component.Input id = {`Descricao`} placeholder="Digite o nome do centro de custo..." value={getNomeCentroDeCusto} style={{margin:"10px 0px"}}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setNomeCentroDeCusto(TextoDigitado.toUpperCase())
                                                }}>
                                            </Component.Input>
                                            <div style = {{marginBottom: "5px"}}>{`Sigla`}</div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                                <Component.Input disabled={getDisable} id = {`Sigla`} placeholder="Digite a sigla do centro de custo..." value={getSigla} style={{margin:"10px 0px", cursor: getDisable == true ? "not-allowed" : "initial"}}
                                                    onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                        var TextoDigitado = event.currentTarget.value;
                                                        setSigla(TextoDigitado.toUpperCase())
                                                    }}>     
                                                </Component.Input>
                                                {getDisable == true && 
                                                <div>
                                                    <a data-tip data-for='tooltip' style={{display:"flex", justifyContent:"space-around", flexDirection:"column", marginLeft:"5px"}}><Icon icon="issue" iconSize={20} color="#0000009f" style={{cursor:"pointer", opacity:0.7}}/></a>
                                                    <ReactTooltip id='tooltip' effect='solid'>
                                                        <span className="Tooltip">A sigla do centro de custo não pode ser alterada</span>
                                                    </ReactTooltip>
                                                </div>}
                                            </div>
                                            <div style = {{marginBottom: "5px"}}>{`Empresa`}</div>
                                            <PickerEmpresas popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaDeEmpresas} onItemSelect={() => { }} disabled={getDisable} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer={(Item: Objeto.Empresa) => <MenuItem onClick={async () => {
                                                    setItemPickerEmpresas(Item);
                                                }} text={Item.nomeFantasia} />} itemPredicate={(Texto: string, Item: Objeto.Empresa) => { return (Item.nomeFantasia ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <div style={{display:"flex", alignItems:"center"}}>
                                                    <Button id="Empresa" disabled={getDisable} text={getItemPickerEmpresas?.nomeFantasia ?? "Selecione a empresa"} rightIcon="double-caret-vertical" style={{cursor: getDisable == true ? "not-allowed" : "initial"}} />
                                                    {getItemPickerEmpresas && getDisable == false && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { setItemPickerEmpresas(undefined); }} />}
                                                    {getDisable == true &&
                                                    <div>
                                                    <a data-tip data-for='tooltip2' style={{display:"flex", justifyContent:"space-around", flexDirection:"column", marginLeft:"5px"}}><Icon icon="issue" iconSize={20} color="#0000009f" style={{cursor:"pointer", opacity:0.7}}/></a>
                                                    <ReactTooltip id='tooltip2' effect='solid' place='right'>
                                                        <span className="Tooltip2">A empresa vinculada ao centro de custo não pode ser alterada</span>
                                                    </ReactTooltip>
                                                    </div>}
                                                </div>
                                            </PickerEmpresas>
                                        </div>
                                    </Component.ContentFormHeader>
                                </Component.ContentCard>
                            </div>
                        </div>
                        
                        <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button
                                icon = {<Icon icon = {getIdCentroDeCusto == "" ? "add" : "edit"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: getIdCentroDeCusto == "" ? "#1098F7" : lighten(0.1, "#D74E09"), 
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "250px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: '10px',
                                }}
                                onClick = { async () => {

                                    if (getNomeCentroDeCusto != "" && getSigla != "" && getItemPickerEmpresas != undefined && getIdCentroDeCusto == "") {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O centro de custo está sendo cadastrado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let Response = await CentroDeCusto.Post(ContextSignIn.getContext().token, getItemPickerEmpresas.id, getSigla, getNomeCentroDeCusto)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {
                                            let ListaDeCentrosDeCusto = [...getListaDeCentrosDeCusto];

                                        ListaDeCentrosDeCusto.push({
                                            sigla: Response.data.sigla,
                                            descricao: Response.data.descricao,
                                            empresaNome: Response.data.empresa.razaoSocial,
                                            empresa: Response.data.empresa
                                        });

                                        setListaDeCentrosDeCusto(ListaDeCentrosDeCusto);
                                        setNomeCentroDeCusto("");
                                        setSigla("");
                                        setItemPickerEmpresas(undefined);
                                        NotificacaoInterna.ExibirNotificacao("O centro de custo foi cadastrado", "Centro de custo cadastrado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                        handleCloseDrawerAdicionarCentroDeCusto()
                                        setDisable(false)
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar o centro de custo", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }else if (getNomeCentroDeCusto != "" && getIdCentroDeCusto != "" && getItemPickerEmpresas){
                                        
                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O centro de custo está sendo alterado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        var ObjetoCentroDeCusto = {} as {Id: string, Descricao: string, Sigla: string}

                                        ObjetoCentroDeCusto = {Id: getSigla , Descricao: getNomeCentroDeCusto, Sigla: getSigla}
                                    
                                        let Response = await CentroDeCusto.Put(ContextSignIn.getContext().token, getItemPickerEmpresas?.id ?? 0, getSigla, getNomeCentroDeCusto)

                                        if (Math.floor(Response.status / 100) == 2) 
                                        {
                                            let ListaDeCentrosDeCusto = [...getListaDeCentrosDeCusto]

                                            ListaDeCentrosDeCusto.map((item, index) => {
                                                if(item.sigla == Response.data.sigla){
                                                    item.descricao = Response.data.descricao
                                                }
                                            })

                                            setListaDeCentrosDeCusto(ListaDeCentrosDeCusto);
                                            setNomeCentroDeCusto("");
                                            setSigla("");
                                            setItemPickerEmpresas(undefined)
                                            NotificacaoInterna.ExibirNotificacao("O centro de custo foi alterado", "Centro de custo alterado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            handleCloseDrawerAdicionarCentroDeCusto()
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao alterar o centro de custo", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else
                                    {
                                        NotificacaoInterna.ExibirNotificacao("As informações não foram preenchidas", "Prencha as informações.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    }

                            }}>{getIdCentroDeCusto == "" ? "Adicionar centro de custo" : "Alterar centro de custo"}</Button>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/** Dialog vinculando cargo ao usuário */}
            <Dialog
                style = {{width: 'calc(39vw + 15px)', backgroundColor: "#FFFFFF"}}
                icon = {"bookmark"}
                onClose = {handleCloseDialogVincularUsuarioAoCargo}
                title = {"Vinculando cargo ao usuário"}
                autoFocus = {true}
                isOpen = {getDialogVincularUsuarioAoCargo.isOpen}
                canEscapeKeyClose = {getDialogVincularUsuarioAoCargo.canEscapeKeyClose}
                canOutsideClickClose = {getDialogVincularUsuarioAoCargo.canOutsideClickClose}
                enforceFocus = {getDialogVincularUsuarioAoCargo.enforceFocus}
                usePortal = {getDialogVincularUsuarioAoCargo.usePortal} 
            >
            
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center", overflowY: 'scroll', maxHeight: window.screen.height * 0.47}}>
                    
                    <div style = {{width: "95%", marginTop: "20px"}}>
                        <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Usuários`}</div>
                        <PickerUsuarios popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getPickerListaDeUsuarios} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer = {(Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => <MenuItem onClick = { async () => { await setItemPickerUsuarios(Item) }} text={Item.nome} />} itemPredicate={(Texto: string, Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button className="selector" style={{ background: "#f6f7f8", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: getItemPickerUsuarios ? "calc(35vw + 15px)" : "calc(37vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerUsuarios?.nome ?? "Selecione..."} rightIcon="double-caret-vertical" />
                        </PickerUsuarios>
                        {getItemPickerUsuarios && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerUsuarios(undefined); }} />}
                    </div>
                    
                    <div style = {{width: "95%", marginTop: "20px"}}>
                        <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{getItensSelecionadosPickerCargos.length > 1 ? `Cargos` : `Cargo`}</div>
                        <PickerCargos popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true, fill: true}} items={getListaDeCargos} onItemSelect={() => { }} noResults={<MenuItem style = {{maxWidth: "16vw"}} disabled={true} text="Sem resultados disponíveis" />}
                        fill={true} tagRenderer={(Item: {id: number, nome: string}) => Item.nome} selectedItems={getItensSelecionadosPickerCargos} placeholder={""}
                        tagInputProps = {{
                            onRemove: (value: React.ReactNode, index: number) => {
                                let ListaDeItensSelecionadosCargos = [...getItensSelecionadosPickerCargos];

                                let ListaFiltrada = ListaDeItensSelecionadosCargos.filter((Cargos, IndexCargos: number) => IndexCargos != index);

                                setItensSelecionadosPickerCargos(ListaFiltrada);
                            },
                            rightElement: <Button icon="cross" minimal={true} 
                            onClick={() => { setItensSelecionadosPickerCargos([]) }}
                            />
                        }}
                        itemRenderer={(Item: {id: number, nome: string}) => <MenuItem text={Item.nome} onClick = {() => {

                            let ListaDeItensSelecionadosListaCargos = [...getItensSelecionadosPickerCargos];

                            let ListaFiltrada = ListaDeItensSelecionadosListaCargos.filter(cargo => cargo.id == Item.id);

                            if (ListaFiltrada.length == 0)
                            {
                                ListaDeItensSelecionadosListaCargos.push(Item);
                                setItensSelecionadosPickerCargos(ListaDeItensSelecionadosListaCargos);
                            }

                        }}/>} itemPredicate={(Texto: string, Item: {id: number, nome: string}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}/>
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getVinculandoCargo == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getVinculandoCargo == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {"Salvar"}
                            onClick = { async () => {

                                if (getItemPickerUsuarios && getItemPickerCargos)
                                {

                                    setVinculandoCargo(true);

                                    if (getItemPickerUsuarios?.cargo == null)
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Vinculando cargo ao usuário.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let Response = await Logon.VincularUsuarioAoCargo(ContextSignIn.getContext().token, getItemPickerUsuarios.id, getItemPickerCargos.id)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaPickerUsuarios = [...getPickerListaDeUsuarios];

                                            let IndexPickerUsuario = ListaPickerUsuarios.findIndex(item => item.id == getFormularioID);

                                            ListaPickerUsuarios[IndexPickerUsuario].cargo = getItemPickerCargos;

                                            let Response = await Logon.Usuarios(ContextSignIn.getContext().token, "")
                                            if(Math.floor(Response.status / 100) == 2)
                                            {
        
                                                let ListaDeUsuarios = [] as Array<{id: number, nome: string, emailPrincipal: string, cargoDescricao: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>
        
                                                Response.data.map((item, index) => {
                                                    ListaDeUsuarios.push({
                                                        id: item.id,
                                                        nome: item.nome,
                                                        emailPrincipal: item.email.descricao,
                                                        email: item.email,
                                                        cargoDescricao: item.cargo != null ? item.cargo.nome : "Sem cargo",
                                                        cargo: item.cargo
                                                    })
                                                })
        
                                                setListaDeUsuarios(ListaDeUsuarios);
                                                setPickerListaDeUsuarios(ListaPickerUsuarios);
                                                setEmConsulta(false);
                                            }

                                            NotificacaoInterna.ExibirNotificacao("Cargo vinculado", "O cargo foi vinculado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            setVinculandoCargo(false);
                                            handleCloseDialogVincularUsuarioAoCargo();
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao vincular o cargo", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            setVinculandoCargo(false);
                                        }

                                    }
                                    else
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Alterando o cargo vinculado.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                                        
                                        let Response = await Logon.AlterarCargoDoUsuario(ContextSignIn.getContext().token, getItemPickerUsuarios.id, getItemPickerCargos.id)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaPickerUsuarios = [...getPickerListaDeUsuarios];

                                            let IndexPickerUsuario = ListaPickerUsuarios.findIndex(item => item.id == getFormularioID);

                                            ListaPickerUsuarios[IndexPickerUsuario].cargo = getItemPickerCargos;

                                            let Response = await Logon.Usuarios(ContextSignIn.getContext().token, "")
                                            if(Math.floor(Response.status / 100) == 2)
                                            {
        
                                                let ListaDeUsuarios = [] as Array<{id: number, nome: string, emailPrincipal: string, cargoDescricao: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>
        
                                                Response.data.map((item, index) => {
                                                    ListaDeUsuarios.push({
                                                        id: item.id,
                                                        nome: item.nome,
                                                        emailPrincipal: item.email.descricao,
                                                        email: item.email,
                                                        cargoDescricao: item.cargo != null ? item.cargo.nome : "Sem cargo",
                                                        cargo: item.cargo
                                                    })
                                                })
        
                                                setListaDeUsuarios(ListaDeUsuarios);
                                                setPickerListaDeUsuarios(ListaPickerUsuarios);
                                                setEmConsulta(false);
                                            }

                                            NotificacaoInterna.ExibirNotificacao("Vinculo atualizado", "O cargo vinculado foi atualizado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            setVinculandoCargo(false);
                                            handleCloseDialogVincularUsuarioAoCargo();

                                        }
                                        else
                                        {

                                            NotificacaoInterna.ExibirNotificacao("Erro ao atualizar o vinculo do cargo", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            setVinculandoCargo(false);
                                        }
                                    }

                                }
                                else
                                {

                                    if (!getItemPickerUsuarios)
                                    {
                                        setVinculandoCargo(false);
                                        NotificacaoInterna.ExibirNotificacao("Usuario não selecionado", "Selecione um usuário para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerCargos)
                                    {
                                        setVinculandoCargo(false);
                                        NotificacaoInterna.ExibirNotificacao("Cargo não selecionado", "Selecione um cargo para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }

                                }

                            }}
                        />}

                    </div>

                </div>
            
            </Dialog>

            {/** Dialog atribuindo permissao de empresa */}
            <Dialog
                style = {{width: 'calc(39vw + 15px)', backgroundColor: "#FFFFFF"}}
                icon = {"bookmark"}
                onClose = {handleCloseDialogAtribuindoPermissaoDeEmpresa}
                title = {"Atribuindo permissão de empresa ao usuário"}
                autoFocus = {true}
                isOpen = {getDialogAtribuindoPermissaoDeEmpresa.isOpen}
                canEscapeKeyClose = {getDialogAtribuindoPermissaoDeEmpresa.canEscapeKeyClose}
                canOutsideClickClose = {getDialogAtribuindoPermissaoDeEmpresa.canOutsideClickClose}
                enforceFocus = {getDialogAtribuindoPermissaoDeEmpresa.enforceFocus}
                usePortal = {getDialogAtribuindoPermissaoDeEmpresa.usePortal}
            >
            
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center", overflowY: 'scroll', maxHeight: window.screen.height * 0.47}}>
                    
                    <div style = {{width: "95%", marginTop: "20px"}}>
                        <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Usuário`}</div>
                        <PickerUsuarios popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getPickerListaDeUsuarios} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer = {(Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => <MenuItem onClick = { async () => { await setItemPickerUsuarios(Item) }} text={Item.nome} />} itemPredicate={(Texto: string, Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button className="selector" style={{ background: "#f6f7f8", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: getItemPickerUsuarios ? "calc(35vw + 15px)" : "calc(37vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerUsuarios?.nome ?? "Selecione..."} rightIcon="double-caret-vertical" />
                        </PickerUsuarios>
                        {getItemPickerUsuarios && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerUsuarios(undefined); }} />}
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getVinculandoEmpresa == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getVinculandoEmpresa == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {"Salvar"}
                            onClick = { async () => {

                                if (getItemPickerUsuarios && getItemPickerDeEmpresas)
                                {

                                    setVinculandoEmpresa(true);

                                    NotificacaoInterna.ExibirNotificacao("Inserindo permissão de acesso na empresa", "Aguarde enquanto inserimos a permissão de acesso.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    let Response = await Empresa.AutorizarPermissaoDeAcesso(ContextSignIn.getContext().token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, getItemPickerDeEmpresas.id, getItemPickerUsuarios.id)

                                    if (Math.floor(Response.status / 100) == 2)
                                    {
                                        
                                        let ListaDeUsuariosComPermissaoDeEmpresa = [...getListaDeUsuariosPermissaoDeEmpresa];

                                        ListaDeUsuariosComPermissaoDeEmpresa.push({
                                            id: getItemPickerUsuarios.id,
                                            nome: getItemPickerUsuarios.nome,
                                            cargo: getItemPickerUsuarios.cargo,
                                            cargoDescricao: getItemPickerUsuarios.cargo != null ? getItemPickerUsuarios.cargo.nome : "Sem cargo",
                                            email: getItemPickerUsuarios.email,
                                            emailPrincipal: getItemPickerUsuarios.email.descricao,
                                            empresa: getItemPickerDeEmpresas,
                                            empresaNome: getItemPickerDeEmpresas.nomeFantasia
                                        })

                                        setListaDeUsuariosPermissaoDeEmpresa(ListaDeUsuariosComPermissaoDeEmpresa);

                                        NotificacaoInterna.ExibirNotificacao("Permissão de acesso autorizada", "O usuário recebeu a autorização de acesso com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                        setVinculandoEmpresa(false);
                                        handleCloseDialogAtribuindoPermissaoDeEmpresa();
                                    }
                                    else
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Erro ao autorizar permissão de acesso", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        setVinculandoEmpresa(false);
                                    }

                                }
                                else
                                {

                                    if (!getItemPickerUsuarios)
                                    {
                                        setVinculandoEmpresa(false);
                                        NotificacaoInterna.ExibirNotificacao("Usuario não selecionado", "Selecione um usuário para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerDeEmpresas)
                                    {
                                        setVinculandoEmpresa(false);
                                        NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione uma empresa para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }

                                }

                            }}
                        />}

                    </div>

                </div>
            
            </Dialog>

            {/** Dialog atribuindo permissao de centro de custo */}
            <Dialog
                style = {{width: 'calc(39vw + 15px)', backgroundColor: "#FFFFFF"}}
                icon = {"bookmark"}
                onClose = {handleCloseDialogAtribuindoPermissaoDeCentroDeCusto}
                title = {"Atribuindo permissão de centro de custo ao usuário"}
                autoFocus = {true}
                isOpen = {getDialogAtribuindoPermissaoDeCentroDeCusto.isOpen}
                canEscapeKeyClose = {getDialogAtribuindoPermissaoDeCentroDeCusto.canEscapeKeyClose}
                canOutsideClickClose = {getDialogAtribuindoPermissaoDeCentroDeCusto.canOutsideClickClose}
                enforceFocus = {getDialogAtribuindoPermissaoDeCentroDeCusto.enforceFocus}
                usePortal = {getDialogAtribuindoPermissaoDeCentroDeCusto.usePortal}
            >
            
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center", overflowY: 'scroll', maxHeight: window.screen.height * 0.47}}>
                    
                    <div style = {{width: "95%", marginTop: "20px"}}>
                        <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Usuário`}</div>
                        <PickerUsuarios popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getPickerListaDeUsuarios} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer = {(Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => <MenuItem onClick = { async () => { await setItemPickerUsuarios(Item) }} text={Item.nome} />} itemPredicate={(Texto: string, Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button className="selector" style={{ background: "#f6f7f8", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: getItemPickerUsuarios ? "calc(35vw + 15px)" : "calc(37vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerUsuarios?.nome ?? "Selecione..."} rightIcon="double-caret-vertical" />
                        </PickerUsuarios>
                        {getItemPickerUsuarios && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerUsuarios(undefined); }} />}
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getVinculandoCentroDeCusto == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getVinculandoCentroDeCusto == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {"Salvar"}
                            onClick = { async () => {

                                if (getItemPickerUsuarios && getItemPickerDeEmpresas && getItemPickerCentroDeCusto)
                                {

                                    setVinculandoCentroDeCusto(true);

                                    NotificacaoInterna.ExibirNotificacao("Inserindo permissão de acesso no centro de custo", "Aguarde enquanto inserimos a permissão de acesso.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    let Response = await CentroDeCusto.AutorizarPermissaoDeAcesso(ContextSignIn.getContext().token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, getItemPickerDeEmpresas.id, getItemPickerCentroDeCusto.sigla, getItemPickerUsuarios.id);

                                    if (Math.floor(Response.status / 100) == 2)
                                    {

                                        let ListaDeUsuariosPermissaoDeAcessoCentroDeCusto = [...getListaDeUsuariosPermissaoDeCentroDeCusto];

                                        ListaDeUsuariosPermissaoDeAcessoCentroDeCusto.push({
                                            id: getItemPickerUsuarios.id,
                                            nome: getItemPickerUsuarios.nome,
                                            cargo: getItemPickerUsuarios.cargo,
                                            cargoDescricao: getItemPickerUsuarios.cargo != null ? getItemPickerUsuarios.cargo.nome : 'Sem cargo',
                                            email: getItemPickerUsuarios.email,
                                            emailPrincipal: getItemPickerUsuarios.email.descricao,
                                            empresaNome: getItemPickerDeEmpresas.nomeFantasia,
                                            centroDeCusto: getItemPickerCentroDeCusto,
                                            centroDeCustoNome: getItemPickerCentroDeCusto.descricao,
                                            centroDeCustoSigla: getItemPickerCentroDeCusto.sigla
                                        })

                                        setListaDeUsuariosPermissaoDeCentroDeCusto(ListaDeUsuariosPermissaoDeAcessoCentroDeCusto);
                                        
                                        NotificacaoInterna.ExibirNotificacao("Permissão de acesso autorizada", "O usuário recebeu a autorização de acesso com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                        
                                        setVinculandoCentroDeCusto(false);
                                        handleCloseDialogAtribuindoPermissaoDeCentroDeCusto();

                                    }
                                    else
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Erro ao autorizar permissão de acesso", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        setVinculandoCentroDeCusto(false);

                                    }

                                }
                                else
                                {

                                    if (!getItemPickerUsuarios)
                                    {
                                        setVinculandoCentroDeCusto(false);
                                        NotificacaoInterna.ExibirNotificacao("Usuario não selecionado", "Selecione um usuário para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerDeEmpresas)
                                    {
                                        setVinculandoCentroDeCusto(false);
                                        NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione uma empresa para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerCentroDeCusto)
                                    {
                                        setVinculandoCentroDeCusto(false);
                                        NotificacaoInterna.ExibirNotificacao("Centro de custo não selecionado", "Selecione um centro de custo para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }

                                }

                            }}
                        />}

                    </div>

                </div>
            
            </Dialog>

            {/** Dialog atribuindo permissao de controle de sala */}
            <Dialog
                style = {{width: 'calc(39vw + 15px)', backgroundColor: "#FFFFFF"}}
                icon = {"bookmark"}
                onClose = {handleCloseDialogAtribuindoPermissaoDeControleDeSala}
                title = {"Atribuindo permissão de controle de sala ao usuário"}
                autoFocus = {true}
                isOpen = {getDialogAtribuindoPermissaoDeControleDeSala.isOpen}
                canEscapeKeyClose = {getDialogAtribuindoPermissaoDeControleDeSala.canEscapeKeyClose}
                canOutsideClickClose = {getDialogAtribuindoPermissaoDeControleDeSala.canOutsideClickClose}
                enforceFocus = {getDialogAtribuindoPermissaoDeControleDeSala.enforceFocus}
                usePortal = {getDialogAtribuindoPermissaoDeControleDeSala.usePortal}
            >
            
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center", overflowY: 'scroll', maxHeight: window.screen.height * 0.47}}>
                    
                    <div style = {{width: "95%", marginTop: "20px"}}>
                        <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Usuário`}</div>
                        <PickerUsuarios popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getPickerListaDeUsuarios} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer = {(Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => <MenuItem onClick = { async () => { await setItemPickerUsuarios(Item) }} text={Item.nome} />} itemPredicate={(Texto: string, Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button className="selector" style={{ background: "#f6f7f8", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: getItemPickerUsuarios ? "calc(35vw + 15px)" : "calc(37vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerUsuarios?.nome ?? "Selecione..."} rightIcon="double-caret-vertical" />
                        </PickerUsuarios>
                        {getItemPickerUsuarios && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerUsuarios(undefined); }} />}
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getVinculandoControleDeSala == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getVinculandoControleDeSala == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {"Salvar"}
                            onClick = { async () => {

                                if (getItemPickerUsuarios && getItemPickerSalaDeVendas && getItemPickerControleDeSala)
                                {

                                    setVinculandoControleDeSala(true);

                                    NotificacaoInterna.ExibirNotificacao("Inserindo permissão de acesso no controle de sala", "Aguarde enquanto inserimos a permissão de acesso.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    let Response = await ControleDeSala.AutorizarPermissaoDeAcesso(ContextSignIn.getContext().token, getItemPickerSalaDeVendas.id, getItemPickerControleDeSala.id, getItemPickerUsuarios.id)

                                    if (Math.floor(Response.status / 100) == 2)
                                    {

                                        let ListaDeUsuariosPermissaoDeAcessoControleDeSala = [...getListaDeUsuariosPermissaoDeControleDeSala];

                                        ListaDeUsuariosPermissaoDeAcessoControleDeSala.push({
                                            id: getItemPickerUsuarios.id,
                                            nome: getItemPickerUsuarios.nome,
                                            cargo: getItemPickerUsuarios.cargo,
                                            cargoDescricao: getItemPickerUsuarios.cargo != null ? getItemPickerUsuarios.cargo.nome : "Sem cargo",
                                            email: getItemPickerUsuarios.email,
                                            emailPrincipal: getItemPickerUsuarios.email.descricao,
                                            salaDeVenda: getItemPickerSalaDeVendas,
                                            salaDeVendaDescricao: getItemPickerSalaDeVendas.descricao,
                                            controleDeSala: getItemPickerControleDeSala,
                                            controleDeSalaDescricao: getItemPickerControleDeSala.descricao
                                        })

                                        setListaDeUsuariosPermissaoDeControleDeSala(ListaDeUsuariosPermissaoDeAcessoControleDeSala);

                                        setVinculandoControleDeSala(false);
                                        NotificacaoInterna.ExibirNotificacao("Permissão de acesso autorizada", "O usuário recebeu a autorização de acesso com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                        handleCloseDialogAtribuindoPermissaoDeControleDeSala();

                                    }
                                    else
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Erro ao autorizar permissão de acesso", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        setVinculandoControleDeSala(false);

                                    }

                                }
                                else
                                {

                                    if (!getItemPickerUsuarios)
                                    {
                                        setVinculandoControleDeSala(false);
                                        NotificacaoInterna.ExibirNotificacao("Usuario não selecionado", "Selecione um usuário para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerControleDeSala)
                                    {
                                        setVinculandoControleDeSala(false);
                                        NotificacaoInterna.ExibirNotificacao("Controle de sala não selecionado", "Selecione um controle de sala para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerSalaDeVendas)
                                    {
                                        setVinculandoControleDeSala(false);
                                        NotificacaoInterna.ExibirNotificacao("Sala de vendas não selecionada", "Selecione uma sala de venda para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }

                                }

                            }}
                        />}

                    </div>

                </div>
            
            </Dialog>

            {/** Dialog atribuindo permissao de funil de venda */}
            <Dialog
                style = {{width: 'calc(39vw + 15px)', backgroundColor: "#FFFFFF"}}
                icon = {"bookmark"}
                onClose = {handleCloseDialogAtribuindoPermissaoDeFunilDeVenda}
                title = {"Atribuindo permissão de funil de venda ao usuário"}
                autoFocus = {true}
                isOpen = {getDialogAtribuindoPermissaoDeFunilDeVenda.isOpen}
                canEscapeKeyClose = {getDialogAtribuindoPermissaoDeFunilDeVenda.canEscapeKeyClose}
                canOutsideClickClose = {getDialogAtribuindoPermissaoDeFunilDeVenda.canOutsideClickClose}
                enforceFocus = {getDialogAtribuindoPermissaoDeFunilDeVenda.enforceFocus}
                usePortal = {getDialogAtribuindoPermissaoDeFunilDeVenda.usePortal}
            >
            
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center", overflowY: 'scroll', maxHeight: window.screen.height * 0.47}}>
                    
                    <div style = {{width: "95%", marginTop: "20px"}}>
                        <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Usuário`}</div>
                        <PickerUsuarios popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getPickerListaDeUsuarios} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer = {(Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => <MenuItem onClick = { async () => { await setItemPickerUsuarios(Item) }} text={Item.nome} />} itemPredicate={(Texto: string, Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button className="selector" style={{ background: "#f6f7f8", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: getItemPickerUsuarios ? "calc(35vw + 15px)" : "calc(37vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerUsuarios?.nome ?? "Selecione..."} rightIcon="double-caret-vertical" />
                        </PickerUsuarios>
                        {getItemPickerUsuarios && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerUsuarios(undefined); }} />}
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getVinculandoFunilDeVenda == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getVinculandoFunilDeVenda == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {"Salvar"}
                            onClick = { async () => {

                                if (getItemPickerUsuarios && getItemPickerSalaDeVendas && getItemPickerArea && getItemPickerFunilDeVenda)
                                {

                                    setVinculandoFunilDeVenda(true);

                                    NotificacaoInterna.ExibirNotificacao("Inserindo permissão de acesso no funil de venda", "Aguarde enquanto inserimos a permissão de acesso.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    let Response = await FunilDeVendas.AutorizarPermissaoDeAcesso(ContextSignIn.getContext().token, getItemPickerSalaDeVendas.id, getItemPickerArea.id, getItemPickerFunilDeVenda.id, getItemPickerUsuarios.id)

                                    if (Math.floor(Response.status / 100) == 2)
                                    {

                                        let ListaDeUsuariosPermissaoDeAcessoFunilDeVenda = [...getListaDeUsuariosPermissaoDeFunilDeVenda];

                                        ListaDeUsuariosPermissaoDeAcessoFunilDeVenda.push({
                                            id: getItemPickerUsuarios.id,
                                            nome: getItemPickerUsuarios.nome,
                                            email: getItemPickerUsuarios.email,
                                            emailPrincipal: getItemPickerUsuarios.email.descricao,
                                            cargo: getItemPickerUsuarios.cargo,
                                            cargoDescricao: getItemPickerUsuarios.cargo != null ? getItemPickerUsuarios.cargo.nome : "Sem cargo",
                                            salaDeVenda: getItemPickerSalaDeVendas,
                                            salaDeVendaDescricao: getItemPickerSalaDeVendas.descricao,
                                            area: getItemPickerArea,
                                            areaDescricao: getItemPickerArea.descricao,
                                            funil: getItemPickerFunilDeVenda,
                                            funilDescricao: getItemPickerFunilDeVenda.descricao
                                        });

                                        setListaDeUsuariosPermissaoDeFunilDeVenda(ListaDeUsuariosPermissaoDeAcessoFunilDeVenda);

                                        setVinculandoFunilDeVenda(false);
                                        NotificacaoInterna.ExibirNotificacao("Permissão de acesso autorizada", "O usuário recebeu a autorização de acesso com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                        handleCloseDialogAtribuindoPermissaoDeFunilDeVenda();
                                    }

                                }
                                else
                                {

                                    if (!getItemPickerUsuarios)
                                    {
                                        setVinculandoFunilDeVenda(false);
                                        NotificacaoInterna.ExibirNotificacao("Usuario não selecionado", "Selecione um usuário para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerFunilDeVenda)
                                    {
                                        setVinculandoFunilDeVenda(false);
                                        NotificacaoInterna.ExibirNotificacao("Funil de venda não selecionado", "Selecione um funil de venda para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerSalaDeVendas)
                                    {
                                        setVinculandoFunilDeVenda(false);
                                        NotificacaoInterna.ExibirNotificacao("Sala de venda não selecionada", "Selecione uma sala de venda para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerArea)
                                    {
                                        setVinculandoFunilDeVenda(false);
                                        NotificacaoInterna.ExibirNotificacao("Área não selecionada", "Selecione uma área para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }

                                }

                            }}
                        />}

                    </div>

                </div>
            
            </Dialog>

            {/** Dialog vinculando usuario ao software externo */}
            <Dialog
                style = {{width: 'calc(39vw + 15px)', backgroundColor: "#FFFFFF"}}
                icon = {"bookmark"}
                onClose = {handleCloseDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno}
                title = {"Vinculando usuário ao software externo"}
                autoFocus = {true}
                isOpen = {getDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno.isOpen}
                canEscapeKeyClose = {getDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno.canEscapeKeyClose}
                canOutsideClickClose = {getDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno.canOutsideClickClose}
                enforceFocus = {getDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno.enforceFocus}
                usePortal = {getDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno.usePortal}
            >
            
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center", overflowY: 'scroll', maxHeight: window.screen.height * 0.47}}>
                    
                    <div style = {{width: "95%", marginTop: "20px"}}>
                        <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Usuário`}</div>
                        <PickerUsuarios popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getPickerListaDeUsuarios} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                            itemRenderer = {(Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => <MenuItem onClick = { async () => { await setItemPickerUsuarios(Item) }} text={Item.nome} />} itemPredicate={(Texto: string, Item: {id: number, nome: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string} | null}) => { return (Item.nome ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                            <Button className="selector" style={{ background: "#f6f7f8", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: getItemPickerUsuarios ? "calc(35vw + 15px)" : "calc(37vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerUsuarios?.nome ?? "Selecione..."} rightIcon="double-caret-vertical" />
                        </PickerUsuarios>
                        {getItemPickerUsuarios && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { await setItemPickerUsuarios(undefined); }} />}
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                        
                        {getVinculandoUsuarioAoSoftwareExterno == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getVinculandoUsuarioAoSoftwareExterno == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {"Salvar"}
                            onClick = { async () => {

                                if (getItemPickerUsuarios && getItemPickerDeEmpresas && getItemPickerCentroDeCusto)
                                {
                                    setVinculandoUsuarioAoSoftwareExterno(true);

                                    NotificacaoInterna.ExibirNotificacao("Vinculando usuário ao software externo", "Aguarde enquanto vinculamos o usuário.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    let Response = await SoftwareExterno.AutorizarPermissaoDeAcesso(ContextSignIn.getContext().token, ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado ?? 0, getItemPickerEmpresas?.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", getItemPickerUsuarios.id, "ONEPLUS")

                                    if (Math.floor(Response.status / 100) == 2)
                                    {

                                        let ListaDeUsuarios = [] as Array<{id: number, nome: string, empresaNome: string, centroDeCustoDescricao: string, centroDeCusto: Objeto.CentroDeCusto, softwareExternoDescricao: string, softwareExterno: { id: number, descricao: string, token: string, urlapi: string, tokenAPI: string }}>
    
                                        let ResponseUsuarios = await Logon.PermissaoDeAcessoPorSoftwareExterno(ContextSignIn.getContext().token, getItemPickerDeEmpresas?.id ?? "", getItemPickerCentroDeCusto?.sigla ?? "", "")
                                        
                                        ResponseUsuarios.data.map((item, index) => {
                                            ListaDeUsuarios.push({
                                                id: item.usuario.id,
                                                nome: item.usuario.nome,
                                                empresaNome: item.centroDeCusto.empresa.nomeFantasia,
                                                centroDeCustoDescricao: item.centroDeCusto.sigla,
                                                centroDeCusto: item.centroDeCusto,
                                                softwareExternoDescricao: item.softwareExterno.descricao,
                                                softwareExterno: item.softwareExterno
                                            })
                                        })
                        
                                        setListaDeUsuariosPermissaoDeSoftwareExterno(ListaDeUsuarios);

                                        setVinculandoUsuarioAoSoftwareExterno(false);
                                        NotificacaoInterna.ExibirNotificacao("Vinculado ao software externo", "O usuário foi vinculado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                        handleOpenDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno();
                                    }

                                }
                                else
                                {

                                    if (!getItemPickerUsuarios)
                                    {
                                        setVinculandoUsuarioAoSoftwareExterno(false);
                                        NotificacaoInterna.ExibirNotificacao("Usuario não selecionado", "Selecione um usuário para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerDeEmpresas)
                                    {
                                        setVinculandoUsuarioAoSoftwareExterno(false);
                                        NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione uma empresa para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerCentroDeCusto)
                                    {
                                        setVinculandoUsuarioAoSoftwareExterno(false);
                                        NotificacaoInterna.ExibirNotificacao("Centro de custo não selecionado", "Selecione um centro de custo para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }

                                }

                            }}
                        />}

                    </div>

                </div>
            
            </Dialog>

            {/** Dialog vinculando centro de custo do software externo */}
            <Dialog
                style = {{width: 'calc(39vw + 15px)', backgroundColor: "#FFFFFF"}}
                icon = {"bookmark"}
                onClose = {handleCloseDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno}
                title = {"Vinculando centro de custo ao software externo"}
                autoFocus = {true}
                isOpen = {getDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno.isOpen}
                canEscapeKeyClose = {getDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno.canEscapeKeyClose}
                canOutsideClickClose = {getDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno.canOutsideClickClose}
                enforceFocus = {getDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno.enforceFocus}
                usePortal = {getDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno.usePortal}
            >
            
                <div style = {{display: "flex", flexDirection: "column", alignItems: "center", overflowY: 'scroll', maxHeight: window.screen.height * 0.47}}>
                    
                    <div style = {{width: "95%", marginTop: "20px", marginBottom: '10px'}}>
                        <div style = {{fontWeight: "bold", fontSize: "12px", marginBottom: "10px"}}>{`Centro de custo`}</div>
                        <div style = {{display: "flex", alignItems: "center", marginRight: '30px'}}>
                            <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaRetroAlimentativaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => {setItemPickerCentroDeCusto(Item)}} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                <Button className="selector" style={{ background: "#f6f7f8", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: getItemPickerCentroDeCusto ? "calc(35vw + 15px)" : "calc(37vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerCentroDeCusto?.descricao ?? "Selecione..."} rightIcon="double-caret-vertical" />
                            </PickerCentroDeCusto>
                            {getItemPickerCentroDeCusto && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {setItemPickerCentroDeCusto(undefined); }} />}
                        </div>
                    </div>

                    <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                        <div style = {{marginBottom: "5px"}}>{`Empresa vinculada`}</div>
                        <Component.Input style = {{width: "calc(37vw + 15px)"}} id = {`EmpresaVinculada`} placeholder={"Digite a empresa vinculada..."} value={getFormularioEmpresaVinculada}
                            onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                var TextoDigitado = event.currentTarget.value;
                                setFormularioEmpresaVinculada(TextoDigitado);
                            }}>
                        </Component.Input>
                    </div>

                    <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                        <div style = {{marginBottom: "5px"}}>{`Centro de custo vinculado`}</div>
                        <Component.Input style = {{width: "calc(37vw + 15px)"}} id = {`CentroDeCustoVinculado`} placeholder={"Digite o centro de custo vinculado..."} value={getFormularioCentroDeCustoVinculado}
                            onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                var TextoDigitado = event.currentTarget.value;
                                setFormularioCentroDeCustoVinculado(TextoDigitado);
                            }}>
                        </Component.Input>
                    </div>

                    <div style = {{width: "95%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>

                        {getVinculandoUsuarioAoCentroDeCustoNoSoftwareExterno == true &&
                        <div
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "30px",
                                width: "50px",
                                borderRadius: "5px"
                        }}>
                            <Lottie
                                options = {{
                                    loop: true,
                                    autoplay: true,
                                    animationData: LottieFiles.Load04,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height = {30}
                                width = {30}
                            />
                        </div>}
                        
                        {getVinculandoUsuarioAoCentroDeCustoNoSoftwareExterno == false && 
                        <Button
                            style = {{
                                flex: 1,
                                background: "#1098F7", 
                                color: "#FFFFFF",
                                boxShadow: "none",
                                outline: "none",
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px",
                                height: "15px",
                            }}
                            text = {"Salvar"}
                            onClick = { async () => {

                                if (getItemPickerDeEmpresas && getItemPickerCentroDeCusto && getFormularioEmpresaVinculada != "" && getFormularioCentroDeCustoVinculado != "")
                                {

                                    setVinculandoUsuarioAoCentroDeCustoNoSoftwareExterno(true);

                                    NotificacaoInterna.ExibirNotificacao("Vinculando centro de custo ao software externo", "Aguarde enquanto vinculamos o centro de custo.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                    let Response = await SoftwareExterno.NovoCentroDeCustoVinculado(ContextSignIn.getContext().token, getItemPickerDeEmpresas.id, getItemPickerCentroDeCusto.sigla, "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", getFormularioEmpresaVinculada, getFormularioCentroDeCustoVinculado)

                                    if (Math.floor(Response.status / 100) == 2)
                                    {

                                       let ResponseCentroDeCusto = await SoftwareExterno.CentroDeCustoVinculado(ContextSignIn.getContext().token, getItemPickerDeEmpresas.id, getItemPickerCentroDeCusto.sigla, "", "", "");

                                        if (Math.floor(ResponseCentroDeCusto.status / 100) == 2)
                                        {

                                            let ListaDeCentrosDeCusto = [] as Array<{ centroDeCustoDescricao: string, centroDeCustoVinculadoDescricao: string, empresaVinculadaDescricao: string, empresaDescricao: string, softwareExternoDescricao: string, centroDeCusto: Objeto.CentroDeCusto, empresa: Objeto.Empresa, centroDeCustoVinculado: {empresaVinculada: string, centroDeCustoVinculado: string}, softwareExterno: {id: number, descricao: string, token: string, tokenAPI: string, urlapi: string} }>
    
                                            ResponseCentroDeCusto.data.map((item, index) => {
                                                ListaDeCentrosDeCusto.push({
                                                    centroDeCustoDescricao: item.centroDeCusto.descricao,
                                                    centroDeCustoVinculadoDescricao: item.centroDeCustoVinculado.centroDeCustoVinculado,
                                                    empresaVinculadaDescricao: item.centroDeCustoVinculado.empresaVinculada,
                                                    empresaDescricao: item.empresa.nomeFantasia,
                                                    softwareExternoDescricao: item.softwareExterno.descricao,
                                                    centroDeCusto: item.centroDeCusto,
                                                    empresa: item.empresa,
                                                    centroDeCustoVinculado: item.centroDeCustoVinculado,
                                                    softwareExterno: item.softwareExterno
                                                })
                                            });
                            
                                            setListaCentroDeCustoVinculado(ListaDeCentrosDeCusto);
                                            setVinculandoUsuarioAoCentroDeCustoNoSoftwareExterno(false);
                                            NotificacaoInterna.ExibirNotificacao("Permissão de acesso autorizada", "O usuário recebeu a autorização de acesso com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                            handleCloseDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno();

                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de centro de custo vinculados", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }

                                }
                                else
                                {

                                    if (!getItemPickerDeEmpresas)
                                    {
                                        setVinculandoUsuarioAoCentroDeCustoNoSoftwareExterno(false);
                                        NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione uma empresa para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (!getItemPickerCentroDeCusto)
                                    {
                                        setVinculandoUsuarioAoCentroDeCustoNoSoftwareExterno(false);
                                        NotificacaoInterna.ExibirNotificacao("Centro de custo não selecionado", "Selecione um centro de custo para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (getFormularioEmpresaVinculada == "")
                                    {
                                        setVinculandoUsuarioAoCentroDeCustoNoSoftwareExterno(false);
                                        NotificacaoInterna.ExibirNotificacao("Empresa vinculada não preenchida", "Preencha a empresa vinculada para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }
                                    if (getFormularioCentroDeCustoVinculado == "")
                                    {
                                        setVinculandoUsuarioAoCentroDeCustoNoSoftwareExterno(false);
                                        NotificacaoInterna.ExibirNotificacao("Centro de custo vinculado não preenchido", "Preencha o centro de custo vinculado para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                        return;
                                    }

                                }

                            }}
                        />}

                    </div>

                </div>
            
            </Dialog>

            {/* Drawer adicionar cargo */}
            <Drawer
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"people"}
                onClose={handleCloseDrawerAdicionarCargos}
                title = {getIDCargos == 0 ? "Adicionar cargo" : "Alterar cargo"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionarCargos.isOpen}
                canEscapeKeyClose = {getDrawerAdicionarCargos.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionarCargos.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionarCargos.enforceFocus}
                usePortal = {getDrawerAdicionarCargos.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>

                    <div 
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>
                                    <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Cargo`}</div>
                                            <Component.Input id = {`Nome`} placeholder="Digite aqui o cargo..." value={getDescricaoCargos}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setDescricaoCargos(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>
                                    </Component.ContentFormHeader>
                                </Component.ContentCard>
                            </div>
                        </div>
                        
                        <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button
                                icon = {<Icon icon = {getIDCargos == 0 ? "add" : "edit"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: "#1098F7", 
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "250px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: '10px',
                                }}
                                onClick = { async () => {

                                    if (getDescricaoCargos != '' && getIDCargos == 0)
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O cargo está sendo cadastrada.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let ObjetoCargo = {} as {id: number, nome: string}

                                        ObjetoCargo = {
                                            id: 0,
                                            nome: getDescricaoCargos
                                        }

                                        let Response = await Cargo.Post(ContextSignIn.getContext().token, ObjetoCargo)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {
                                            let ListaDeCargos = [...getListaDeCargos];

                                            ListaDeCargos.push({
                                                id: Response.data.id,
                                                nome: Response.data.nome
                                            })
        
                                            setListaDeCargos(ListaDeCargos);

                                            NotificacaoInterna.ExibirNotificacao("O cargo foi cadastrado", "Cargo cadastrado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
    
                                            handleCloseDrawerAdicionarCargos();

                                        }
                                        else
                                        {

                                            NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar o cargo", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);

                                        }
                                    }
                                    else if (getDescricaoCargos != '' && getIDCargos > 0)
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O cargo está sendo alterado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let ObjetoCargo = {} as {id: number, nome: string};

                                        ObjetoCargo = {
                                            id: getIDCargos,
                                            nome: getDescricaoCargos
                                        }

                                        let Response = await Cargo.Put(ContextSignIn.getContext().token, ObjetoCargo);

                                        if (Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaDeCargos = [...getListaDeCargos];

                                            let IndexItem = ListaDeCargos.findIndex(item => item.id == Response.data.id);

                                            ListaDeCargos[IndexItem] = Response.data;

                                            setListaDeCargos(ListaDeCargos);

                                            NotificacaoInterna.ExibirNotificacao("O cargo foi alterado", "Cargo alterado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                            handleCloseDrawerAdicionarCargos();

                                        }
                                        else
                                        {

                                            NotificacaoInterna.ExibirNotificacao("Erro ao alterar o cargo", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);

                                        }
                                    }
                                    else
                                    {

                                        NotificacaoInterna.ExibirNotificacao("O nome do cargo não foi preenchido", "Preencha o nome do cargo para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);

                                    }

                            }}>{getIDCargos == 0 ? "Adicionar cargo" : "Alterar cargo"}</Button>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/* Drawer adicionar fornecedor */}
            <Drawer
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"people"}
                onClose={handleCloseDrawerAdicionarFornecedor}
                title = {getIDFornecedor == 0 ? "Adicionar fornecedor" : "Alterar fornecedor"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionarFornecedor.isOpen}
                canEscapeKeyClose = {getDrawerAdicionarFornecedor.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionarFornecedor.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionarFornecedor.enforceFocus}
                usePortal = {getDrawerAdicionarFornecedor.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>

                    <div 
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <Component.ContentCard style = {{ backgroundColor: "#FFFFFF"}}>
                                
                                <div
                                    style = {{
                                        padding: "5px 10px",
                                        width: "calc(29vw + 50px)"
                                }}>
                                    <div style = {{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", marginRight: "20px", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px", fontSize: "12px"}}>{CPF.DesformatarTexto(getCPFCNPJFornecedor).length <= 11 ? "CPF" : "CNPJ"}</div>
                                            <div style = {{display: "flex", alignItems: "center"}}>
                                                <Component.Input id="CPF" style = {{fontSize: "12px", width: getCPFEmConsulta == false ? "calc(29vw + 20px)" : "calc(27vw + 20px)"}} placeholder={CPF.FormatarTexto("00000000000")} value={CPF.FormatarTexto(getCPFCNPJFornecedor)}
                                                    onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                        var TextoDigitado = CPF.DesformatarTexto(event.currentTarget.value);
                                                        setCPFCNPJFornecedor(TextoDigitado);
                                                        if (CPF.TextoValido(TextoDigitado) || CNPJ.TextoValido(TextoDigitado)) {
                                                            setCPFEmConsulta(true);
                                                            switch (ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado) {
                                                                case 4:
                                                                    try {
                                                                        let Response = await (await axios.create({ baseURL: 'https://vendadigital.gavresorts.com.br:4443' })
                                                                            .get(`/Pessoa/${"NzAyNjEyMzExNDZjMjl6Skc1bGRETXk="}/${TextoDigitado}`))
                                                                            .data ?? [];
                                                                        setNomeRazaoSocial(Response.nome ?? "");

                                                                        try {
                                                                            let Lista: Array<any> = [];
                                                                            Lista = Response.telefones.filter((tel: any) => tel != null).filter((thing: any, index: number) => {
                                                                                let _thing = JSON.stringify(thing);
                                                                                return index === Response.telefones.findIndex((obj: any) => {
                                                                                    return JSON.stringify(obj) === _thing;
                                                                                })
                                                                            });

                                                                            setTelefoneFornecedor(Lista.length > 0 ? (Lista[0].ddd + Lista[0].numero) : "");

                                                                        } catch {}

                                                                        setCEPFornecedor(Response.endereco.cep ?? "");
                                                                        setLogradouroFornecedor(Response.endereco.logradouro ?? "");
                                                                        setComplementoFornecedor(Response.endereco.complemento ?? "");
                                                                        setNumeroFornecedor(Response.endereco.numero ?? "");
                                                                        setBairroFornecedor(Response.endereco.bairro ?? "");
                                                                        setCidadeFornecedor(Response.endereco.cidade ?? "");
                                                                        setUFFornecedor(Response.endereco.uf ?? "");
                                                                    }
                                                                    catch { }
                                                                    finally { setCPFEmConsulta(false); }
                                                                    break;
                                                                case 6:
                                                                    try {
                                                                        let Response = (await Fornecedor.CPFCNPJ(ContextSignIn.getContext().token, TextoDigitado)).data ?? [];
                                                                        setNomeRazaoSocial(Response.nomeRazaoSocial ?? "");
                                                                        try {
                                                                            let TelefoneComercial = Response.telefones?.find((Item: any) => Item.classificacao == 2);
                                                                            setTelefoneFornecedor(Telefone.FormatarTexto((TelefoneComercial?.ddd ?? "") + (TelefoneComercial?.numero ?? "")));
                                                                        }
                                                                        catch { }
                                                                        setCEPFornecedor(Response.endereco?.cep ?? "");
                                                                        setLogradouroFornecedor(Response.endereco?.logradouro ?? "");
                                                                        setComplementoFornecedor(Response.endereco?.complemento ?? "");
                                                                        setNumeroFornecedor(Response.endereco?.numero ?? "");
                                                                        setBairroFornecedor(Response.endereco?.bairro ?? "");
                                                                        setCidadeFornecedor(Response.endereco?.cidade ?? "");
                                                                        setUFFornecedor(Response.endereco?.uf ?? "");
                                                                    }
                                                                    catch { }
                                                                    finally { setCPFEmConsulta(false); }
                                                                    break;
                                                            }
                                                        }
                                                    }}>
                                                </Component.Input>
                                                {getCPFEmConsulta && <Icon icon = "geosearch" style = {{marginLeft: "10px"}} iconSize = {15}/>}
                                            </div>
                                        </div>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{CPF.DesformatarTexto(getFormularioCPF).length <= 11 ? "Nome" : "Razão social"}</div>
                                            <Component.Input id="Nome" placeholder="Nome" value={getNomeRazaoSocial} style={{ width: "calc(29vw + 20px)", fontSize: "12px" }}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setNomeRazaoSocial(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>
                                    </div>
                                </div>

                                <Component.ContentFormHeader
                                    style = {{
                                        padding: "5px 10px",
                                        width: "calc(29vw + 50px)",
                                        marginTop: 0,
                                        marginBottom: '10px'
                                }}>
                                    <div style = {{ marginBottom: "10px", fontWeight: "bold", fontSize: "12px" }}>
                                        <div style = {{marginBottom: "5px"}}>CEP</div>
                                        <div style = {{display: "flex", alignItems: "center"}}>
                                            <Component.Input id="CEP" placeholder="Informe o CEP" value={CEP.FormatarTexto(getCEPFornecedor)} style={{ width: getCEPEmConsulta == false ? "calc(29vw + 20px)" : "calc(27vw + 20px)", fontSize: "12px" }}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var CEPDesformatado = CEP.DesformatarTexto(event.currentTarget.value);
                                                    setCEPFornecedor(CEPDesformatado);
                                                    if (CEPDesformatado.length == 8) {
                                                        try {
                                                            setCEPEmConsulta(true);
                                                            var Response = (await Correios.Get(ContextSignIn.getContext().token, CEPDesformatado)).data;
                                                            setLogradouroFornecedor(Response.Logradouro);
                                                            setComplementoFornecedor(Response.Complemento);
                                                            setBairroFornecedor(Response.Bairro);
                                                            setCidadeFornecedor(Response.Cidade);
                                                            setUFFornecedor(Response.UF);
                                                        }
                                                        catch { }
                                                        finally { setCEPEmConsulta(false); }
                                                    }
                                                }}>
                                            </Component.Input>
                                            {getCEPEmConsulta && <Icon icon = "geosearch" style = {{marginLeft: "10px"}} iconSize = {15}/>}
                                        </div>
                                    </div>
                                    <div style = {{display: "flex", alignItems: "center"}}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px", marginRight: "20px"}}>
                                            <div style = {{marginBottom: "5px"}}>Logradouro</div>
                                            <Component.Input id="Logradouro:" placeholder="Informe o logradouro" value={getLogradouroFornecedor} style={{ width: "calc(17vw + 0px)", cursor: "auto", fontSize: "12px" }}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    setLogradouroFornecedor(event.currentTarget.value);
                                                }}>
                                            </Component.Input>
                                        </div>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>Número</div>
                                            <Component.Input id="NumeroDeEndereco" maxLength={10} placeholder={Numero.FormatarTextoParaInteiro(0)} value={getNumeroFornecedor} style={{ width: "calc(11vw + 15px)", marginLeft: "0", cursor: "auto", fontSize: "12px" }}
                                                onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                    setNumeroFornecedor(event.currentTarget.value);
                                                }}>
                                            </Component.Input>
                                        </div>
                                    </div>
                                    <div style = {{ marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                        <div style = {{marginBottom: "5px"}}>Complemento</div>
                                        <Component.Input id="Complemento" placeholder="Informe o complemento" value={getComplementoFornecedor} style={{ width: "calc(29vw + 20px)", cursor: "auto", fontSize: "12px" }}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                setComplementoFornecedor(event.currentTarget.value);
                                            }}>
                                        </Component.Input>
                                    </div>
                                    <div style = {{ marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                        <div style = {{marginBottom: "5px"}}>Bairro</div>
                                        <Component.Input id="Bairro" placeholder="Informe o bairro" value={getBairroFornecedor} style={{ width: "calc(29vw + 20px)", cursor: "auto", fontSize: "12px" }}
                                            onChange={async (event: React.FormEvent<HTMLInputElement>) => {
                                                setBairroFornecedor(event.currentTarget.value);
                                            }}>
                                        </Component.Input>
                                    </div>
                                    <div style = {{display: "flex", alignItems: "center"}}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>Cidade</div>
                                            <Component.Input id="Cidade" placeholder="Informe o cidade" value={getCidadeFornecedor} style={{ width: "calc(17.5vw + 0px)", marginRight: "0.6vw", cursor: "auto", fontSize: "12px" }}
                                                onChange = {async (event: React.FormEvent<HTMLInputElement>) => {
                                                    setCidadeFornecedor(event.currentTarget.value)
                                                }}>
                                            </Component.Input>
                                        </div>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>UF</div>
                                            <HTMLSelect style={{ fontSize: "12px" }} id="uf" name="uf" value={getUFFornecedor}
                                                onChange = {async (event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    setUFFornecedor(event.currentTarget.value)
                                                }}>
                                                <option selected value="">Selecione a UF</option>
                                                {UF.map((Item) =>
                                                    <option selected value={Item.chave}>{Item.Valor}</option>
                                                )}
                                            </HTMLSelect>
                                            {getUFFornecedor && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {setUFFornecedor("");}} />}
                                        </div>
                                    </div>
                                </Component.ContentFormHeader>

                                <div
                                    style = {{
                                        width: "100%",
                                        alignItems: "center",
                                        borderRadius: "2px",
                                        marginTop: "-20px"
                                }}>
                                    <div 
                                        style = {{
                                            padding: "5px 10px",
                                            width: "calc(29vw + 50px)"
                                    }}>
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{display: 'flex', alignItems: "center", marginBottom: "5px"}}>
                                                <div style = {{marginRight: "5px", fontSize: "12px"}}>Telefone</div>
                                            </div>
                                            <div style = {{marginBottom: "10px"}}>
                                                <Component.Input id="TelefoneComercial" style = {{fontSize: 12, width: "calc(29vw + 20px)"}} placeholder={Telefone.FormatarTexto("00000000000")} value={Telefone.FormatarTexto(getTelefoneFornecedor)}
                                                    onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                        var TextoDigitado = event.currentTarget.value
                                                        setTelefoneFornecedor(TextoDigitado)
                                                    }}>
                                                </Component.Input>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Component.ContentCard>
                        </div>
                        
                        <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button
                                icon = {<Icon icon = {getIDFornecedor == 0 ? "add" : "edit"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: getIDFornecedor == 0 ? "#1098F7" : lighten(0.1, "#D74E09"),
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "250px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: '10px',
                                }}
                                onClick = { async () => {

                                    if (getIDFornecedor == 0)
                                    {
                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Um novo fornecedor está sendo cadastrado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let ObjetoFornecedor: Objeto.Fornecedor;

                                        ObjetoFornecedor = {
                                            id: 0,
                                            cpfcnpj: getCPFCNPJFornecedor,
                                            nomeRazaoSocial: getNomeRazaoSocial,
                                            endereco: {
                                                classificacao: 2,
                                                cep: getCEPFornecedor,
                                                logradouro: getLogradouroFornecedor,
                                                numero: getNumeroFornecedor,
                                                complemento: getComplementoFornecedor,
                                                bairro: getBairroFornecedor,
                                                cidade: getCidadeFornecedor,
                                                uf: getUFFornecedor
                                            },
                                            telefones: [
                                                {
                                                    classificacao: 2,
                                                    ddi: '55',
                                                    ddd: Telefone.ObterDDD(getTelefoneFornecedor),
                                                    numero: Telefone.ObterNumero(getTelefoneFornecedor),
                                                    observacao: 'Telefone Principal'
                                                }
                                            ]
                                        }

                                        let Response = await Fornecedor.Post(ContextSignIn.getContext().token, ObjetoFornecedor)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaDeFornecedores = [...getListaPickerFornecedor];

                                            ListaDeFornecedores.push({
                                                id: Response.data.id,
                                                cpfcnpj: Response.data.cpfcnpj,
                                                nomeRazaoSocial: Response.data.nomeRazaoSocial,
                                                endereco: Response.data.endereco,
                                                telefones: Response.data.telefones
                                            })

                                            setListaPickerFornecedor(ListaDeFornecedores)

                                            setCPFCNPJFornecedor("");
                                            setNomeRazaoSocial("");
                                            setCEPFornecedor("");
                                            setLogradouroFornecedor("");
                                            setNumeroFornecedor("");
                                            setComplementoFornecedor("");
                                            setBairroFornecedor("");
                                            setCidadeFornecedor("");
                                            setUFFornecedor("");
                                            setTelefoneFornecedor("");

                                            NotificacaoInterna.ExibirNotificacao("O fornecedor foi cadastrado", "Cadastrado do sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                            handleCloseDrawerAdicionarFornecedor()

                                        }
                                        else 
                                        {

                                            NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar novo fornecedor", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);

                                        }

                                    }
                                    else
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "Os dados do fornecedor estão sendo alterados.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let ObjetoFornecedor: Objeto.Fornecedor;

                                        ObjetoFornecedor = {
                                            id: getIDFornecedor,
                                            cpfcnpj: getCPFCNPJFornecedor,
                                            nomeRazaoSocial: getNomeRazaoSocial,
                                            endereco: {
                                                classificacao: 2,
                                                cep: getCEPFornecedor,
                                                logradouro: getLogradouroFornecedor,
                                                numero: getNumeroFornecedor,
                                                complemento: getComplementoFornecedor,
                                                bairro: getBairroFornecedor,
                                                cidade: getCidadeFornecedor,
                                                uf: getUFFornecedor
                                            },
                                            telefones: [
                                                {
                                                    classificacao: 2,
                                                    ddi: '55',
                                                    ddd: Telefone.ObterDDD(getTelefoneFornecedor),
                                                    numero: Telefone.ObterNumero(getTelefoneFornecedor),
                                                    observacao: 'Telefone Principal'
                                                }
                                            ]
                                        }

                                        let Response = await Fornecedor.Put(ContextSignIn.getContext().token, ObjetoFornecedor)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaDeFornecedores = [...getListaPickerFornecedor];

                                            let IndexItem = ListaDeFornecedores.findIndex(item => item.id == Response.data.id);

                                            ListaDeFornecedores[IndexItem] = Response.data;

                                            setListaPickerFornecedor(ListaDeFornecedores);

                                            setCPFCNPJFornecedor("");
                                            setNomeRazaoSocial("");
                                            setCEPFornecedor("");
                                            setLogradouroFornecedor("");
                                            setNumeroFornecedor("");
                                            setComplementoFornecedor("");
                                            setBairroFornecedor("");
                                            setCidadeFornecedor("");
                                            setUFFornecedor("");
                                            setTelefoneFornecedor("");

                                            NotificacaoInterna.ExibirNotificacao("Os dados do fornecedor foram alterados", "Alteração feita com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                            handleCloseDrawerAdicionarFornecedor();

                                        }
                                        else 
                                        {

                                            NotificacaoInterna.ExibirNotificacao("Erro ao alterar os dados do fornecedor", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);

                                        }

                                    }

                            }}>{getIDFornecedor == 0 ? "Adicionar fornecedor" : "Alterar fornecedor"}</Button>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/* Drawer adicionar brinde */}
            <Drawer
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"barcode"}
                onClose={handleCloseDrawerAdicionarBrinde}
                title = {getIDBrinde == 0 ? "Adicionar brinde" : "Alterar brinde"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionarBrinde.isOpen}
                canEscapeKeyClose = {getDrawerAdicionarBrinde.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionarBrinde.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionarBrinde.enforceFocus}
                usePortal = {getDrawerAdicionarBrinde.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>

                    <div
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>
                                    <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                        
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{fontWeight: 'bold', fontSize: "12px", marginBottom: "5px"}}>Descrição</div>
                                            <PickerNomeBrinde popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaDeNomesDeBrindes} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer = {(Item: {id: number, descricao: string}) => <MenuItem onClick = { async () => { setItemPickerNomesDeBrindes(Item) }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: {id: number, descricao: string}) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button className="selector" style={{height: "40px", background: lighten(0.1, '#F6F7FF'), color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "calc(27vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerNomesDeBrindes?.descricao ?? "Selecione..."} rightIcon="double-caret-vertical" />
                                            </PickerNomeBrinde>
                                        </div>

                                        <div>
                                            <div style = {{fontWeight: 'bold', fontSize: "12px", marginBottom: "5px"}}>Tipo</div>
                                            <PickerTipoDeBrinde popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaDeTiposDeBrindes} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                itemRenderer = {(Item: {id: number, descricao: string}) => <MenuItem onClick = { async () => { setItemPickerTiposDeBrindes(Item) }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: {id: number, descricao: string}) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                <Button className="selector" style={{height: "40px", background: lighten(0.1, '#F6F7FF'), color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "calc(27vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerTiposDeBrindes?.descricao ?? "Selecione..."} rightIcon="double-caret-vertical" />
                                            </PickerTipoDeBrinde>
                                            <div style = {{padding: "0 10px", width: "calc(27vw + 15px)", height: "1px", background: '#00000040'}}></div>
                                        </div>

                                        <div style = {{marginTop: "15px", marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Quantidade`}</div>
                                            <Component.Input id = {`Quantidade`} placeholder={"Digite aqui a quantidade..."} value={Numero.FormatarTextoParaInteiro(getQtdeBrinde)}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = Numero.DesformatarTextoParaInteiro(event.currentTarget.value);
                                                    setQtdeBrinde(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>
                                        
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Valor`}</div>
                                            <Component.Input id = {`Valor`} placeholder={Moeda.FormatarTexto(0)} value={Moeda.FormatarTexto(getValorBrinde)}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = Moeda.DesformatarTexto(event.currentTarget.value);
                                                    setValorBrinde(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>

                                        <div style = {{marginBottom: '10px', fontWeight: 'bold', fontSize: '12px', width: 'calc(27vw + 15px)'}}>
                                            <div style = {{marginBottom: '5px'}}>{`Prazo de validade`}</div>
                                            <div style = {{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                
                                                <div>
                                                    <div style = {{marginBottom: '5px', fontSize: '11px'}}>{`Inicio`}</div>
                                                    <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                                        popoverProps = {{disabled: !getFormularioExibirCalendarioValidadeInicial, onClosed: () => setFormularioExibirCalendarioValidadeInicial(false)}}
                                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                                        placeholder={"DD/MM/AAAA"} onChange = { async (DataSelecionada: Date) => { setPrazoValidadeInicioBrinde(DataSelecionada) }}
                                                        value = {getPrazoValidadeInicioBrinde != undefined ? new Date(String(getPrazoValidadeInicioBrinde)) : getPrazoValidadeInicioBrinde}
                                                        inputProps = {{style: {fontSize: "12px", backgroundColor: lighten(0.1, '#D3D3D3'), width: 'calc(12vw + 15px)'}, onClick: () => setFormularioExibirCalendarioValidadeInicial(true)}}>
                                                    </DateInput>
                                                </div>

                                                <div>
                                                    <div style = {{marginBottom: '5px', fontSize: '11px'}}>{`Termino`}</div>
                                                    <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                                        popoverProps = {{disabled: !getFormularioExibirCalendarioValidadeFinal, onClosed: () => setFormularioExibirCalendarioValidadeFinal(false)}}
                                                        parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                                        placeholder={"DD/MM/AAAA"} onChange = { async (DataSelecionada: Date) => { setPrazoValidadeTerminoBrinde(DataSelecionada) }}
                                                        value = {getPrazoValidadeTerminoBrinde != undefined ? new Date(String(getPrazoValidadeTerminoBrinde)) : getPrazoValidadeTerminoBrinde}
                                                        inputProps = {{style: {fontSize: "12px", backgroundColor: lighten(0.1, '#D3D3D3'), width: 'calc(12vw + 15px)'}, onClick: () => setFormularioExibirCalendarioValidadeFinal(true)}}>
                                                    </DateInput>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div style = {{fontWeight: 'bold', fontSize: "12px", marginBottom: "5px"}}>Fornecedor</div>
                                                <PickerFornecedor popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerFornecedor} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                                    itemRenderer = {(Item: Objeto.Fornecedor) => <MenuItem onClick = { async () => { setItemPickerFornecedorBrinde(Item) }} text={Item.nomeRazaoSocial} />} itemPredicate={(Texto: string, Item: Objeto.Fornecedor) => { return (Item.nomeRazaoSocial ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                                    <Button className="selector" style={{height: "40px", background: lighten(0.1, '#F6F7FF'), color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "calc(27vw + 15px)", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }} text={getItemPickerFornecedorBrinde?.nomeRazaoSocial ?? "Selecione..."} rightIcon="double-caret-vertical" />
                                                </PickerFornecedor>
                                            <div style = {{padding: "0 10px", width: "calc(27vw + 15px)", height: "1px", background: '#00000040'}}></div>
                                        </div>

                                    </Component.ContentFormHeader>
                                </Component.ContentCard>
                            </div>
                        </div>
                        
                        <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button
                                icon = {<Icon icon = {getIDBrinde == 0 ? "add" : "edit"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: getIDBrinde == 0 ? "#1098F7" : lighten(0.1, "#D74E09"), 
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "250px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: '10px',
                                }}
                                onClick = { async () => {

                                    if (getIDBrinde == 0)
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O item está sendo cadastrado no almoxarifado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let ObjetoBrinde: { brinde?: {id: number, descricao: string}, tipo?: {id: number, descricao: string}, fornecedor?: Objeto.Fornecedor, validadeInicial?: Date | null | string, validadeFinal?: Date | null | string, quantidade: number, valorUnitario: number};

                                        ObjetoBrinde = {
                                            brinde: getItemPickerNomesDeBrindes,
                                            tipo: getItemPickerTiposDeBrindes,
                                            fornecedor: getItemPickerFornecedorBrinde,
                                            validadeInicial: getPrazoValidadeInicioBrinde,
                                            validadeFinal: getPrazoValidadeTerminoBrinde,
                                            quantidade: getQtdeBrinde,
                                            valorUnitario: getValorBrinde
                                        }

                                        let Response = await Brinde.CadastrarItemNoAlmoxarifado(ContextSignIn.getContext().token, ObjetoBrinde)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaDoAlmoxarifado: Array<{ id: number, descricao: string | undefined, tipoDescricao: string | undefined, quantidade: number, valorUnitario: number, fornecedorDescricao: string | undefined, tipo: {id: number, descricao: string} | undefined, fornecedor: Objeto.Fornecedor | undefined, validadeInicial: Date | undefined | null | string, validadeFinal: Date | undefined | null | string}> = [];
    
                                            ListaDoAlmoxarifado = [...getListaDoAlmoxarifado];

                                            ListaDoAlmoxarifado.push({
                                                id: getItemPickerNomesDeBrindes?.id ?? 0,
                                                descricao: getItemPickerNomesDeBrindes?.descricao,
                                                tipoDescricao: getItemPickerTiposDeBrindes?.descricao,
                                                tipo: getItemPickerTiposDeBrindes,
                                                quantidade: getQtdeBrinde,
                                                valorUnitario: getValorBrinde,
                                                fornecedorDescricao: getItemPickerFornecedorBrinde?.nomeRazaoSocial,
                                                fornecedor: getItemPickerFornecedorBrinde,
                                                validadeInicial: moment(getPrazoValidadeInicioBrinde, true).format('DD/MM/YYYY'),
                                                validadeFinal: moment(getPrazoValidadeTerminoBrinde, true).format('DD/MM/YYYY')
                                            });
        
                                            setListaDoAlmoxarifado(ListaDoAlmoxarifado);

                                            NotificacaoInterna.ExibirNotificacao("O item foi cadastrado", "Item cadastrado no almoxarifado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        
                                            handleCloseDrawerAdicionarBrinde();

                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar o item no almoxarifado", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O item está sendo alterado no almoxarifado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let ObjetoBrinde: { brinde?: {id: number, descricao: string}, tipo?: {id: number, descricao: string}, fornecedor?: Objeto.Fornecedor, validadeInicial?: Date | null, validadeFinal?: Date | null, quantidade: number, valorUnitario: number};

                                        ObjetoBrinde = {
                                            brinde: getItemPickerNomesDeBrindes,
                                            tipo: getItemPickerTiposDeBrindes,
                                            fornecedor: getItemPickerFornecedorBrinde,
                                            validadeInicial: getPrazoValidadeInicioBrinde,
                                            validadeFinal: getPrazoValidadeTerminoBrinde,
                                            quantidade: getQtdeBrinde,
                                            valorUnitario: getValorBrinde
                                        }

                                        console.log(JSON.stringify(ObjetoBrinde))

                                        let Response = await Brinde.AlterarItemDoAlmoxarifado(ContextSignIn.getContext().token, ObjetoBrinde)

                                        if (Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaDoAlmoxarifado: Array<{ id: number, descricao: string | undefined, tipoDescricao: string | undefined, quantidade: number, valorUnitario: number, fornecedorDescricao: string | undefined, tipo: {id: number, descricao: string} | undefined, fornecedor: Objeto.Fornecedor | undefined, validadeInicial: Date | undefined | null | string, validadeFinal: Date | undefined | null | string}> = [];
    
                                            ListaDoAlmoxarifado = [...getListaDoAlmoxarifado];

                                            let ItemIndex = ListaDoAlmoxarifado.findIndex(item => item.id == getItemPickerNomesDeBrindes?.id ?? 0);

                                            ListaDoAlmoxarifado[ItemIndex].id = getItemPickerNomesDeBrindes?.id ?? 0;
                                            ListaDoAlmoxarifado[ItemIndex].descricao = getItemPickerNomesDeBrindes?.descricao;
                                            ListaDoAlmoxarifado[ItemIndex].tipoDescricao = getItemPickerTipoBrinde?.descricao;
                                            ListaDoAlmoxarifado[ItemIndex].tipo = getItemPickerTipoBrinde;
                                            ListaDoAlmoxarifado[ItemIndex].quantidade = getQtdeBrinde;
                                            ListaDoAlmoxarifado[ItemIndex].valorUnitario = getValorBrinde;
                                            ListaDoAlmoxarifado[ItemIndex].fornecedorDescricao = getItemPickerFornecedorBrinde?.nomeRazaoSocial;
                                            ListaDoAlmoxarifado[ItemIndex].fornecedor = getItemPickerFornecedorBrinde;
                                            ListaDoAlmoxarifado[ItemIndex].validadeInicial = moment(getPrazoValidadeInicioBrinde, true).format('DD/MM/YYYY');
                                            ListaDoAlmoxarifado[ItemIndex].validadeFinal = moment(getPrazoValidadeTerminoBrinde, true).format('DD/MM/YYYY');

                                            // ListaDoAlmoxarifado.map(item  => {
                                            //     if (item.id == getItemPickerNomesDeBrindes?.id)
                                            //     {
                                            //         item.id = getItemPickerNomesDeBrindes?.id ?? 0;
                                            //         item.descricao = getItemPickerNomesDeBrindes?.descricao;
                                            //         item.tipoDescricao = getItemPickerTipoBrinde?.descricao;
                                            //         item.tipo = getItemPickerTipoBrinde;
                                            //         item.quantidade = getQtdeBrinde;
                                            //         item.valorUnitario = getValorBrinde;
                                            //         item.fornecedorDescricao = getItemPickerFornecedorBrinde?.nomeRazaoSocial;
                                            //         item.fornecedor = getItemPickerFornecedorBrinde;
                                            //         item.validadeInicial = (getPrazoValidadeInicioBrinde);
                                            //         item.validadeFinal = getPrazoValidadeTerminoBrinde
                                            //     }
                                            // })
        
                                            setListaDoAlmoxarifado(ListaDoAlmoxarifado);

                                            NotificacaoInterna.ExibirNotificacao("O item foi alterado", "Item alterado no almoxarifado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        
                                            handleCloseDrawerAdicionarBrinde();

                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao alterar o item no almoxarifado", "Entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }

                                    }

                            }}>{getIDBrinde == 0 ? "Adicionar brinde" : "Alterar brinde"}</Button>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/* Drawer adicionar nome do brinde */}
            <Drawer
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"barcode"}
                onClose={handleCloseDrawerAdicionarNomeDoBrinde}
                title = {getIDNomeDoBrinde == 0 ? "Adicionar nome do brinde" : "Alterar nome do brinde"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionarNomeDoBrinde.isOpen}
                canEscapeKeyClose = {getDrawerAdicionarNomeDoBrinde.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionarNomeDoBrinde.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionarNomeDoBrinde.enforceFocus}
                usePortal = {getDrawerAdicionarNomeDoBrinde.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>

                    <div
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>
                                    <Component.ContentFormHeader style = {{marginLeft: "10px"}}>
                                        
                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Descrição`}</div>
                                            <Component.Input id = {`Descrição`} placeholder="Digite aqui a descricao..." value={getNomeDoBrinde}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setNomeDoBrinde(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>

                                    </Component.ContentFormHeader>
                                </Component.ContentCard>
                            </div>
                        </div>

                        <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button
                                icon = {<Icon icon = {getIDNomeDoBrinde == 0 ? "add" : "edit"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: getIDNomeDoBrinde == 0 ? "#1098F7" : lighten(0.1, "#D74E09"),  
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "250px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: '10px',
                                }}
                                onClick = { async () => {

                                    if(getIDNomeDoBrinde == 0)
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O nome do brinde está sendo cadastrado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let ObjetoNomeBrinde: {id: number, descricao: string};

                                        ObjetoNomeBrinde = {
                                            id: 0,
                                            descricao: getNomeDoBrinde
                                        }

                                        let Response = await Brinde.Post(ContextSignIn.getContext().token, ObjetoNomeBrinde);

                                        if (Math.floor(Response.status / 100) == 2)
                                        {
                                            let ListaDeNomesBrinde = [...getListaDeNomesDeBrindes];

                                            ListaDeNomesBrinde.push({
                                                id: Response.data.id,
                                                descricao: Response.data.descricao
                                            })
        
                                            setListaNomesDeBrindes(ListaDeNomesBrinde);
                                            setNomeDoBrinde("");

                                            NotificacaoInterna.ExibirNotificacao("O brinde foi cadastrado", "Cadastrado do sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        
                                            handleCloseDrawerAdicionarNomeDoBrinde();
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar novo nome de brinde", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O nome do brinde está sendo alterado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let ObjetoNomeBrinde: {id: number, descricao: string};

                                        ObjetoNomeBrinde = {
                                            id: getIDNomeDoBrinde,
                                            descricao: getNomeDoBrinde
                                        }

                                        let Response = await Brinde.Put(ContextSignIn.getContext().token, ObjetoNomeBrinde);

                                        if (Math.floor(Response.status / 100) == 2)
                                        {
                                            let ListaDeNomesBrinde = [...getListaDeNomesDeBrindes];

                                            let ItemIndex = ListaDeNomesBrinde.findIndex(item => item.id == Response.data.id);


                                            ListaDeNomesBrinde[ItemIndex] = Response.data;
        
                                            setListaNomesDeBrindes(ListaDeNomesBrinde);
                                            setNomeDoBrinde("");

                                            NotificacaoInterna.ExibirNotificacao("O brinde foi alterado", "Alterado do sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        
                                            handleCloseDrawerAdicionarNomeDoBrinde();
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao alterar nome de brinde", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }

                            }}>{getIDNomeDoBrinde == 0 ? "Adicionar nome" : 'Alterar nome'}</Button>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/* Drawer adicionar tipo de brinde */}
            <Drawer
                style = {{ width: "35%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {"barcode"}
                onClose={handleCloseDrawerAdicionarNomeTipoDoBrinde}
                title = {getIDTipoDeBrinde == 0 ? "Adicionar tipo de brinde" : "Alterar tipo de brinde"}
                autoFocus = {true}
                isOpen = {getDrawerAdicionaraNomeTipoDoBrinde.isOpen}
                canEscapeKeyClose = {getDrawerAdicionaraNomeTipoDoBrinde.canEscapeKeyClose}
                canOutsideClickClose = {getDrawerAdicionaraNomeTipoDoBrinde.canOutsideClickClose}
                enforceFocus = {getDrawerAdicionaraNomeTipoDoBrinde.enforceFocus}
                usePortal = {getDrawerAdicionaraNomeTipoDoBrinde.usePortal}
            >
                <div style = {{display: "flex", width: "100%", height: "100%"}}>

                    <div
                        style = {{
                            width: "100%", 
                            height: "100%",
                            borderRight: "0px",
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-between",
                            transition: "width 0.5s"
                    }}>
                        <div className={Classes.DIALOG_BODY} style = {{overflowY: "scroll", display: "flex"}}>
                            <div style={{borderRadius: "5px", boxShadow: "none"}}>
                                <Component.ContentCard>
                                    <Component.ContentFormHeader style = {{marginLeft: "10px"}}>

                                        <div style = {{marginBottom: "10px", fontWeight: "bold", fontSize: "12px"}}>
                                            <div style = {{marginBottom: "5px"}}>{`Descrição`}</div>
                                            <Component.Input id = {`Descrição`} placeholder="Digite aqui a descricao..." value={getNomeTipoDoBrinde}
                                                onChange = { async (event: React.FormEvent<HTMLInputElement>) => {
                                                    var TextoDigitado = event.currentTarget.value;
                                                    setNomeTipoDoBrinde(TextoDigitado)
                                                }}>
                                            </Component.Input>
                                        </div>

                                    </Component.ContentFormHeader>
                                </Component.ContentCard>
                            </div>
                        </div>
                        
                        <div style = {{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button
                                icon = {<Icon icon = {getIDTipoDeBrinde == 0 ? "add" : "edit"} iconSize = {14} color = {"#FFFFFF"}/>} 
                                style = {{
                                    background: getIDTipoDeBrinde == 0 ? "#1098F7" : lighten(0.1, "#D74E09"),  
                                    color: "#FFFFFF", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    borderRadius: "5px", 
                                    outline: "none",
                                    width: "250px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    fontSize: "12px", 
                                    height: "40px", 
                                    marginRight: '10px',
                                }}
                                onClick = { async () => {

                                    if(getIDTipoDeBrinde == 0)
                                    {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O tipo de brinde está sendo cadastrado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let ObjetoNomeTipoDeBrinde: {id: number, descricao: string};

                                        ObjetoNomeTipoDeBrinde = {
                                            id: 0,
                                            descricao: getNomeTipoDoBrinde
                                        };

                                        let Response = await Brinde.NovoTipoDeBrinde(ContextSignIn.getContext().token, ObjetoNomeTipoDeBrinde);

                                        if (Math.floor(Response.status / 100) == 2)
                                        {
                                            let ListaDeNomesTiposDeBrinde = [...getListaDeTiposDeBrindes];

                                            ListaDeNomesTiposDeBrinde.push({
                                                id: Response.data.id,
                                                descricao: Response.data.descricao
                                            });
        
                                            setListaTiposDeBrindes(ListaDeNomesTiposDeBrinde);
                                            setNomeTipoDoBrinde("");

                                            NotificacaoInterna.ExibirNotificacao("O tipo de brinde foi cadastrado", "Cadastrado do sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        
                                            handleCloseDrawerAdicionarNomeTipoDoBrinde();
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao cadastrar novo tipo de brinde", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }
                                    else {

                                        NotificacaoInterna.ExibirNotificacao("Caro usuário", "O tipo de brinde está sendo alterado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                        let ObjetoNomeTipoDeBrinde: {id: number, descricao: string};

                                        ObjetoNomeTipoDeBrinde = {
                                            id: getIDTipoDeBrinde,
                                            descricao: getNomeTipoDoBrinde
                                        }

                                        let Response = await Brinde.AlterarTipoDeBrinde(ContextSignIn.getContext().token, ObjetoNomeTipoDeBrinde);

                                        if (Math.floor(Response.status / 100) == 2)
                                        {
                                            let ListaDeNomesTiposDeBrinde = [...getListaDeTiposDeBrindes];

                                            let ItemIndex = ListaDeNomesTiposDeBrinde.findIndex(item => item.id == Response.data.id);

                                            ListaDeNomesTiposDeBrinde[ItemIndex] = Response.data;
        
                                            setListaTiposDeBrindes(ListaDeNomesTiposDeBrinde);
                                            setNomeTipoDoBrinde("");

                                            NotificacaoInterna.ExibirNotificacao("O tipo de brinde foi alterado", "Alterado do sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
        
                                            handleCloseDrawerAdicionarNomeTipoDoBrinde();
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao alterar tipo de brinde", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                        }
                                    }

                            }}>{getIDTipoDeBrinde == 0 ? "Adicionar tipo" : "Alterar tipo"}</Button>
                        </div>

                    </div>

                </div>
            
            </Drawer>

            {/** Dialog deletar usuário */}
            <Dialog
                style = {{ width: "25%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarUsuario}
                title = {"Deseja deletar o usuário?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarUsuario.isOpen}
                canEscapeKeyClose = {getDialogDeletarUsuario.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarUsuario.canOutsideClickClose}
                enforceFocus = {getDialogDeletarUsuario.enforceFocus}
                usePortal = {getDialogDeletarUsuario.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "O usuário está sendo deletado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                            let Response = await Logon.Delete(ContextSignIn.getContext().token, getFormularioID)

                            if (Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeUsuarios = [...getListaDeUsuarios];

                                let ListaFiltrada = ListaDeUsuarios.filter(usuario => usuario.id == getFormularioID);

                                setListaDeUsuarios(ListaFiltrada);

                                setPickerListaDeUsuarios((await Logon.Usuarios(ContextSignIn.getContext().token, "")).data ?? []);

                                NotificacaoInterna.ExibirNotificacao("O usuário foi deletado", "Deletado com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                handleCloseDialogDeletarUsuario();

                            }
                            else
                            {
                                NotificacaoInterna.ExibirNotificacao("Erro ao deletar o usuário", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => { handleCloseDialogDeletarCargo() }}>{"Não"}</Button>
                </div>

            </Dialog>

            {/* Dialog deletar empresa */ }
            <Dialog
                style={{width: "30%", minWidth: '420px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                className="Dialog"
                icon={<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleDialogDeletarEmpresaClose}
                title={"Deseja mesmo excluir essa empresa?"}
                autoFocus={true}
                isOpen={getDialogDeletarEmpresa.isOpen}
                canEscapeKeyClose={getDialogDeletarEmpresa.canEscapeKeyClose}
                canOutsideClickClose={getDialogDeletarEmpresa.canOutsideClickClose}
                enforceFocus={getDialogDeletarEmpresa.enforceFocus}
                usePortal={getDialogDeletarEmpresa.usePortal}>

                    <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                        <Button
                            style = {{
                                background: "#1098F7", 
                                color: "#FFFFFF", 
                                border: "none", 
                                boxShadow: "none", 
                                borderRadius: "5px", 
                                outline: "none",
                                width: "125px", 
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px", 
                                height: "35px",
                            }}
                            onClick = {async () => {

                                NotificacaoInterna.ExibirNotificacao("Caro usuário", "A empresa está sendo deletada.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                let Response = await Empresa.Delete(ContextSignIn.getContext().token, getIdEmpresa)
    
                                if (Math.floor(Response.status / 100) == 2)
                                {
                                    let ListaDeEmpresas = [...getListaDeEmpresas]
                                    
                                    let ListaDeEmpresasFiltrada = getListaDeEmpresas.filter((item, Index) => item.id != getIdEmpresa);
                                    
                                    await setListaDeEmpresas(ListaDeEmpresasFiltrada);
                                    NotificacaoInterna.ExibirNotificacao("A empresas foi deletada", "Empresa deletada com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    setIdEmpresa(0);
                                    handleDialogDeletarEmpresaClose()
                                }
                                else 
                                {
                                    NotificacaoInterna.ExibirNotificacao("Erro ao deletar a empresa", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                }

                            }}>{"Sim"}</Button>
                        <Button
                            style = {{
                                color: "#FFFFFF", 
                                border: "none", 
                                boxShadow: "none", 
                                borderRadius: "5px", 
                                outline: "none",
                                width: "125px", 
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px", 
                                height: "35px",
                            }}
                            intent = {"danger"}
                            onClick = {async () => { handleDialogDeletarEmpresaClose() }}>{"Não"}</Button>
                    </div>

            </Dialog>

            {/* Dialog deletar centro de custo*/ }
            <Dialog
                style={{width: "32%", minWidth: '470px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                className="Dialog"
                icon={<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleDialogDeletarCentroDeCustoClose}
                title={"Deseja mesmo excluir esse centro de custo?"}
                autoFocus={true}
                isOpen={getDialogDeletarCentroDeCusto.isOpen}
                canEscapeKeyClose={getDialogDeletarCentroDeCusto.canEscapeKeyClose}
                canOutsideClickClose={getDialogDeletarCentroDeCusto.canOutsideClickClose}
                enforceFocus={getDialogDeletarCentroDeCusto.enforceFocus}
                usePortal={getDialogDeletarCentroDeCusto.usePortal}>

                    <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                        <Button
                            style = {{
                                background: "#1098F7", 
                                color: "#FFFFFF", 
                                border: "none", 
                                boxShadow: "none", 
                                borderRadius: "5px", 
                                outline: "none",
                                width: "125px", 
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px", 
                                height: "35px",
                            }}
                            onClick = {async () => {

                                NotificacaoInterna.ExibirNotificacao("Caro usuário", "O centro está sendo deletado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                let Response = await CentroDeCusto.Delete(ContextSignIn.getContext().token, getIdEmpresa, getIdCentroDeCusto)
    
                                if (Math.floor(Response.status / 100) == 2)
                                {
                                    let ListaDeCentroDeCustoFiltrada = getListaDeCentrosDeCusto.filter((item, Index) => item.sigla != getIdCentroDeCusto);
                                    
                                    await setListaDeCentrosDeCusto(ListaDeCentroDeCustoFiltrada);
                                    NotificacaoInterna.ExibirNotificacao("O centro de custo foi deletado", "Centro de custo deletada com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    setIdCentroDeCusto("");
                                    handleDialogDeletarCentroDeCustoClose();
                                }
                                else 
                                {
                                    NotificacaoInterna.ExibirNotificacao("Erro ao deletar a empresa", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                }

                            }}>{"Sim"}</Button>
                        <Button
                            style = {{
                                color: "#FFFFFF", 
                                border: "none", 
                                boxShadow: "none", 
                                borderRadius: "5px", 
                                outline: "none",
                                width: "125px", 
                                display: "flex", 
                                alignItems: "center", 
                                fontSize: "12px", 
                                height: "35px",
                            }}
                            intent = {"danger"}
                            onClick = {async () => { handleDialogDeletarCentroDeCustoClose() }}>{"Não"}</Button>
                    </div>

            </Dialog>

            {/** Dialog deletar sala */}
            <Dialog
                style = {{ width: "25%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarSala}
                title = {"Deseja deletar o sala?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarSala.isOpen}
                canEscapeKeyClose = {getDialogDeletarSala.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarSala.canOutsideClickClose}
                enforceFocus = {getDialogDeletarSala.enforceFocus}
                usePortal = {getDialogDeletarSala.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "A sala de vendas está sendo deletada.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                            let Response = await SalaDeVendas.Delete(ContextSignIn.getContext().token, {id: getIdSala, descricao: getDescriptionSala})
                            if (Math.floor(Response.status / 100) == 2)
                            {
                                let ListaDeSalas = [...getListaDeSalas]

                                let ListaFiltrada = ListaDeSalas.filter(item => item.id != getIdSala)

                                setListaDeSalas(ListaFiltrada)
                                NotificacaoInterna.ExibirNotificacao("A sala de vendas foi deletada", "Sala de vendas deletada com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                handleCloseDialogDeletarSala();
                            }
                            else 
                            {
                                NotificacaoInterna.ExibirNotificacao("Erro ao deletar a sala de vendas", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => { handleCloseDialogDeletarSala() }}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog deletar area */}
            <Dialog
                style = {{ width: "25%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleOpenDialogDeletarArea}
                title = {"Deseja deletar o area?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarArea.isOpen}
                canEscapeKeyClose = {getDialogDeletarArea.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarArea.canOutsideClickClose}
                enforceFocus = {getDialogDeletarArea.enforceFocus}
                usePortal = {getDialogDeletarArea.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "A área está sendo deletada.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                            let Response = await Areas.Delete(ContextSignIn.getContext().token, {id: getIdDepartamento, descricao: getDescriptionDepartamento})
                            if (Math.floor(Response.status / 100) == 2)
                            {
                                let ListaDeAreas = [...getListaDeAreas]

                                let ListaFiltrada = ListaDeAreas.filter(item => item.id != getIdDepartamento)

                                setListaDeAreas(ListaFiltrada)
                                NotificacaoInterna.ExibirNotificacao("A área foi deletada", "Área deletada com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                handleCloseDialogDeletarArea();
                            }
                            else 
                            {
                                NotificacaoInterna.ExibirNotificacao("Erro ao deletar a área'", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => { handleCloseDialogDeletarArea() }}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog deletar nome do brinde */}
            <Dialog
                style = {{width: "25%", minWidth: '380px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarNomeDoBrinde}
                title = {"Deseja deletar nome do brinde?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarNomeDoBrinde.isOpen}
                canEscapeKeyClose = {getDialogDeletarNomeDoBrinde.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarNomeDoBrinde.canOutsideClickClose}
                enforceFocus = {getDialogDeletarNomeDoBrinde.enforceFocus}
                usePortal = {getDialogDeletarNomeDoBrinde.usePortal}
            >
                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = { async () => {

                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "O nome do brinde está sendo deletado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                            let ObjetoNomeDoBrinde = {} as {id: number, descricao: string};

                            ObjetoNomeDoBrinde = {
                                id: getIDNomeDoBrinde,
                                descricao: getNomeDoBrinde
                            }

                            let Response = await Brinde.Delete(ContextSignIn.getContext().token, ObjetoNomeDoBrinde);

                            if (Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeNomesDoBrinde = [...getListaDeNomesDeBrindes];

                                let ListaFiltrada = ListaDeNomesDoBrinde.filter(item => item.id != Response.data.id);

                                setListaNomesDeBrindes(ListaFiltrada);

                                NotificacaoInterna.ExibirNotificacao("O nome do brinde foi deletado", "Deletado do sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                handleCloseDialogDeletarNomeDoBrinde();

                            }
                            else 
                            {

                                NotificacaoInterna.ExibirNotificacao("Erro ao deletar o nome do brinde", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);

                                handleCloseDialogDeletarNomeDoBrinde();

                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = { async () => { handleCloseDialogDeletarNomeDoBrinde() }}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog deletar tipo do brinde */}
            <Dialog
                style = {{width: "25%", minWidth: '380px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarTipoDeBrinde}
                title = {"Deseja deletar tipo do brinde?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarTipoDeBrinde.isOpen}
                canEscapeKeyClose = {getDialogDeletarTipoDeBrinde.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarTipoDeBrinde.canOutsideClickClose}
                enforceFocus = {getDialogDeletarTipoDeBrinde.enforceFocus}
                usePortal = {getDialogDeletarTipoDeBrinde.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "O tipo de brinde está sendo deletado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                            let ObjetoTipoDeBrinde = {} as {id: number, descricao: string};

                            ObjetoTipoDeBrinde = {
                                id: getIDTipoDeBrinde,
                                descricao: getNomeTipoDoBrinde
                            }

                            let Response = await Brinde.DeletarTipoDeBrinde(ContextSignIn.getContext().token, ObjetoTipoDeBrinde);

                            if (Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeTiposDeBrinde = [...getListaDeTiposDeBrindes];

                                let ListaFiltrada = ListaDeTiposDeBrinde.filter(item => item.id != Response.data.id);

                                setListaTiposDeBrindes(ListaFiltrada);

                                NotificacaoInterna.ExibirNotificacao("O tipo de brinde foi deletado", "Deletado do sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                handleCloseDialogDeletarTipoDeBrinde();

                            }
                            else 
                            {

                                NotificacaoInterna.ExibirNotificacao("Erro ao deletar o tipo de brinde", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);

                                handleCloseDialogDeletarTipoDeBrinde();
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => {handleCloseDialogDeletarTipoDeBrinde()}}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog deletar brinde */}
            <Dialog
                style = {{width: "20%", minWidth: '300px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarBrinde}
                title = {"Deseja deletar brinde?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarBrinde.isOpen}
                canEscapeKeyClose = {getDialogDeletarBrinde.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarBrinde.canOutsideClickClose}
                enforceFocus = {getDialogDeletarBrinde.enforceFocus}
                usePortal = {getDialogDeletarBrinde.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                            marginRight: "10px"
                        }}
                        onClick = {async () => {

                            let ObjetoBrinde: { brinde?: {id: number, descricao: string}, tipo?: {id: number, descricao: string}, fornecedor?: Objeto.Fornecedor, validadeInicial?: Date | null, validadeFinal?: Date | null, quantidade: number, valorUnitario: number};

                            console.log(getPrazoValidadeTerminoBrinde)

                            ObjetoBrinde = {
                                brinde: getItemPickerNomesDeBrindes,
                                tipo: getItemPickerTiposDeBrindes,
                                fornecedor: getItemPickerFornecedorBrinde,
                                validadeInicial: moment(getPrazoValidadeInicioBrinde, 'DD/MM/YYYY', true).toDate(),
                                validadeFinal: moment(getPrazoValidadeTerminoBrinde, 'DD/MM/YYYY', true).toDate(),
                                quantidade: getQtdeBrinde,
                                valorUnitario: getValorBrinde
                            }

                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "O item do almoxarifado está sendo deletado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                            let Response = await Brinde.DeletarItemDoAlmoxarifado(ContextSignIn.getContext().token, ObjetoBrinde);

                            if (Math.floor(Response.status / 100) == 2)
                            {
                                let ListaDoAlmoxarifado = [...getListaDoAlmoxarifado];

                                let ListaFiltrada = ListaDoAlmoxarifado.filter(item => ((item.id != getIDBrinde) || (item.descricao != getItemPickerNomesDeBrindes?.descricao) || (item.tipo?.id != getItemPickerTiposDeBrindes?.id) || (item.tipo?.descricao != getItemPickerTiposDeBrindes?.descricao) || (item.quantidade != getQtdeBrinde) || (item.valorUnitario != getValorBrinde) || (item.validadeInicial !=  getPrazoValidadeInicioBrinde) || (item.validadeFinal != getPrazoValidadeTerminoBrinde) || (item.fornecedor?.id != getItemPickerFornecedorBrinde?.id)));

                                setListaDoAlmoxarifado(ListaFiltrada);

                                NotificacaoInterna.ExibirNotificacao("O item foi deletado do almoxarifado", "Deletado do sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                handleCloseDialogDeletarBrinde();
                            }
                            else
                            {
                                NotificacaoInterna.ExibirNotificacao("Erro ao deletar item do almoxarifado", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);

                                handleCloseDialogDeletarBrinde();
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => { handleCloseDialogDeletarBrinde() }}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog deletar fornecedor */}
            <Dialog
                style = {{ width: "25%", minWidth: '380px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarFornecedor}
                title = {"Deseja deletar o fornecedor?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarFornecedor.isOpen}
                canEscapeKeyClose = {getDialogDeletarFornecedor.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarFornecedor.canOutsideClickClose}
                enforceFocus = {getDialogDeletarFornecedor.enforceFocus}
                usePortal = {getDialogDeletarFornecedor.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "O fornecedor está sendo deletado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                            let Response = await Fornecedor.Delete(ContextSignIn.getContext().token, getIDFornecedor);

                            if (Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeFornecedores = [...getListaPickerFornecedor];

                                let ListaFiltrada = ListaDeFornecedores.filter(item => item.id != getIDFornecedor);

                                setListaPickerFornecedor(ListaFiltrada);

                                NotificacaoInterna.ExibirNotificacao("O fornecedor foi deletado", "Deletado do sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                handleCloseDialogDeletarFornecedor();

                            }
                            else
                            {

                                NotificacaoInterna.ExibirNotificacao("Erro ao deletar o fornecedor", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);

                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => {handleCloseDialogDeletarFornecedor()}}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog deletar cargo */}
            <Dialog
                style = {{ width: "25%", minWidth: '380px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarCargo}
                title = {"Deseja deletar o cargo?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarCargo.isOpen}
                canEscapeKeyClose = {getDialogDeletarCargo.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarCargo.canOutsideClickClose}
                enforceFocus = {getDialogDeletarCargo.enforceFocus}
                usePortal = {getDialogDeletarCargo.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            let ObjetoCargos = {} as {id: number, nome: string}

                            ObjetoCargos = {
                                id: getIDCargos,
                                nome: getDescricaoCargos
                            }

                            NotificacaoInterna.ExibirNotificacao("Caro usuário", "O cargo está sendo deletado.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                            let Response = await Cargo.Delete(ContextSignIn.getContext().token, ObjetoCargos);

                            if (Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeCargos = [...getListaDeCargos];

                                let ListaFiltrada = ListaDeCargos.filter(cargo => cargo.id != ObjetoCargos.id);

                                setListaDeCargos(ListaFiltrada);

                                NotificacaoInterna.ExibirNotificacao("O cargo foi deletado", "Deletado do sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                handleCloseDialogDeletarCargo();

                            }
                            else
                            {

                                NotificacaoInterna.ExibirNotificacao("Erro ao deletar o cargo", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);

                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => {
                            handleCloseDialogDeletarCargo()
                        }}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog remover permissão de acesso na empresa */}
            <Dialog
                style={{width: "38%", minWidth: '540px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarPermissaoEmpresa}
                title = {"Deseja remover a permissão de acesso na empresa?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarPermissaoEmpresa.isOpen}
                canEscapeKeyClose = {getDialogDeletarPermissaoEmpresa.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarPermissaoEmpresa.canOutsideClickClose}
                enforceFocus = {getDialogDeletarPermissaoEmpresa.enforceFocus}
                usePortal = {getDialogDeletarPermissaoEmpresa.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            if (getItemPickerDeEmpresas)
                            {

                                NotificacaoInterna.ExibirNotificacao("Caro usuário", "A permissão de acesso a empresa está sendo removida.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                            
                                let Response = await Empresa.RemoverPermissaoDeAcesso(ContextSignIn.getContext().token, getItemPickerDeEmpresas.id, getIDPickerUsuarios);

                                if (Math.floor(Response.status / 100) == 2)
                                {

                                    let ListaDeUsuariosPermissaoDeEmpresa = [...getListaDeUsuariosPermissaoDeEmpresa];

                                    let ListaFiltrada = ListaDeUsuariosPermissaoDeEmpresa.filter(item => item.id != getIDPickerUsuarios)

                                    setListaDeUsuariosPermissaoDeEmpresa(ListaFiltrada);
                                    NotificacaoInterna.ExibirNotificacao("Permissão de acesso deletada", "A permissão de acesso a empresa foi removida com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    handleCloseDialogDeletarPermissaoEmpresa();

                                }
                                else
                                {
                                    NotificacaoInterna.ExibirNotificacao("Erro ao deletar a permissão de acesso a empresa", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                }

                            }
                            else
                            {
                                if(!getItemPickerDeEmpresas)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione uma empresa para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => {handleCloseDialogDeletarPermissaoEmpresa()}}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog remover permissão de acesso no centro de custo */}
            <Dialog
                style={{width: "42%", minWidth: '590px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarPermissaoCentroDeCusto}
                title = {"Deseja remover a permissão de acesso no centro de custo?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarPermissaoCentroDeCusto.isOpen}
                canEscapeKeyClose = {getDialogDeletarPermissaoCentroDeCusto.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarPermissaoCentroDeCusto.canOutsideClickClose}
                enforceFocus = {getDialogDeletarPermissaoCentroDeCusto.enforceFocus}
                usePortal = {getDialogDeletarPermissaoCentroDeCusto.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            if (getItemPickerDeEmpresas && getItemPickerCentroDeCusto)
                            {

                                NotificacaoInterna.ExibirNotificacao("Caro usuário", "A permissão de acesso ao centro de custo está sendo removida.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                            
                                let Response = await CentroDeCusto.RemoverPermissaoDeAcesso(ContextSignIn.getContext().token, getItemPickerDeEmpresas.id, getItemPickerCentroDeCusto.sigla, getIDPickerUsuarios);

                                if (Math.floor(Response.status / 100) == 2)
                                {

                                    let ListaDeUsuariosPermissaoDeCentroDeCusto = [...getListaDeUsuariosPermissaoDeCentroDeCusto];

                                    let ListaFiltrada = ListaDeUsuariosPermissaoDeCentroDeCusto.filter(item => item.id != getIDPickerUsuarios)

                                    setListaDeUsuariosPermissaoDeCentroDeCusto(ListaFiltrada);
                                    NotificacaoInterna.ExibirNotificacao("Permissão de acesso removida", "A permissão de acesso do centro de custo foi removida com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    handleCloseDialogDeletarPermissaoCentroDeCusto();

                                }
                                else
                                {
                                    NotificacaoInterna.ExibirNotificacao("Erro ao remover a permissão de acesso do centro de custo", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                }

                            }
                            else
                            {
                                if(!getItemPickerDeEmpresas)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione uma empresa para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                                if(!getItemPickerCentroDeCusto)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Centro de custo não selecionado", "Selecione um centro de custo para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => {handleCloseDialogDeletarPermissaoCentroDeCusto()}}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog remover permissão de acesso no controle de sala */}
            <Dialog
                style={{width: "42%", minWidth: '590px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarPermissaoControleDeSala}
                title = {"Deseja remover a permissão de acesso no controle de sala?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarPermissaoControleDeSala.isOpen}
                canEscapeKeyClose = {getDialogDeletarPermissaoControleDeSala.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarPermissaoControleDeSala.canOutsideClickClose}
                enforceFocus = {getDialogDeletarPermissaoControleDeSala.enforceFocus}
                usePortal = {getDialogDeletarPermissaoControleDeSala.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            if (getItemPickerSalaDeVendas && getItemPickerControleDeSala)
                            {

                                NotificacaoInterna.ExibirNotificacao("Caro usuário", "A permissão de acesso ao centro de custo está sendo removida.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                            
                                let Response = await ControleDeSala.RemoverPermissaoDeAcesso(ContextSignIn.getContext().token, getItemPickerSalaDeVendas.id, getItemPickerControleDeSala.id, getIDPickerUsuarios);

                                if (Math.floor(Response.status / 100) == 2)
                                {

                                    let ListaDeUsuariosPermissaoDeControleDeSala = [...getListaDeUsuariosPermissaoDeControleDeSala];

                                    let ListaFiltrada = ListaDeUsuariosPermissaoDeControleDeSala.filter(item => item.id != getIDPickerUsuarios)

                                    setListaDeUsuariosPermissaoDeControleDeSala(ListaFiltrada);
                                    NotificacaoInterna.ExibirNotificacao("Permissão de acesso removida", "A permissão de acesso ao controle de sala foi removida com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    handleCloseDialogAtribuindoPermissaoDeControleDeSala();

                                }
                                else
                                {
                                    NotificacaoInterna.ExibirNotificacao("Erro ao remover a permissão de acesso ao controle de sala", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                }

                            }
                            else
                            {
                                if(!getItemPickerControleDeSala)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Controle de sala não selecionado", "Selecione um controle de sala para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                                if(!getItemPickerSalaDeVendas)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Sala de venda não selecionada", "Selecione uma sala de venda para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => {handleCloseDialogDeletarPermissaoControleDeSala()}}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog remover permissão de acesso no funil de venda */}
            <Dialog
                style={{width: "42%", minWidth: '590px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarPermissaoFunilDeVenda}
                title = {"Deseja remover a permissão de acesso no funil de venda?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarPermissaoFunilDeVenda.isOpen}
                canEscapeKeyClose = {getDialogDeletarPermissaoFunilDeVenda.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarPermissaoFunilDeVenda.canOutsideClickClose}
                enforceFocus = {getDialogDeletarPermissaoFunilDeVenda.enforceFocus}
                usePortal = {getDialogDeletarPermissaoFunilDeVenda.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            if (getItemPickerSalaDeVendas && getItemPickerArea && getItemPickerFunilDeVenda)
                            {

                                NotificacaoInterna.ExibirNotificacao("Caro usuário", "A permissão de acesso ao funil de venda está sendo removida.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                            
                                let Response = await FunilDeVendas.RemoverPermissaoDeAcesso(ContextSignIn.getContext().token, getItemPickerSalaDeVendas.id, getItemPickerArea.id, getItemPickerFunilDeVenda.id, getIDPickerUsuarios);

                                if (Math.floor(Response.status / 100) == 2)
                                {

                                    let ListaDeUsuariosPermissaoDeFunilDeVenda = [...getListaDeUsuariosPermissaoDeFunilDeVenda];

                                    let ListaFiltrada = ListaDeUsuariosPermissaoDeFunilDeVenda.filter(item => item.id != getIDPickerUsuarios)

                                    setListaDeUsuariosPermissaoDeFunilDeVenda(ListaFiltrada);
                                    NotificacaoInterna.ExibirNotificacao("Permissão de acesso removida", "A permissão de acesso ao funil de venda foi removida com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    handleCloseDialogDeletarPermissaoFunilDeVenda();

                                }
                                else
                                {
                                    NotificacaoInterna.ExibirNotificacao("Erro ao remover a permissão de acesso ao funil de venda", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                }

                            }
                            else
                            {
                                if(!getItemPickerFunilDeVenda)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Funil de venda não selecionado", "Selecione um funil de venda para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                                if(!getItemPickerSalaDeVendas)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Sala de venda não selecionada", "Selecione uma sala de venda para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                                if(!getItemPickerArea)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Área não selecionada", "Selecione uma área para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => {handleCloseDialogAtribuindoPermissaoDeFunilDeVenda()}}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog remover vinculo do usuário no software externo */}
            <Dialog
                style={{width: "42%", minWidth: '590px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarVinculoDoUsuarioAoSoftwareExterno}
                title = {"Deseja remover o vinculo do usuário ao software externo?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarVinculoDoUsuarioAoSoftwareExterno.isOpen}
                canEscapeKeyClose = {getDialogDeletarVinculoDoUsuarioAoSoftwareExterno.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarVinculoDoUsuarioAoSoftwareExterno.canOutsideClickClose}
                enforceFocus = {getDialogDeletarVinculoDoUsuarioAoSoftwareExterno.enforceFocus}
                usePortal = {getDialogDeletarVinculoDoUsuarioAoSoftwareExterno.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            if (getItemPickerDeEmpresas && getItemPickerCentroDeCusto)
                            {

                                NotificacaoInterna.ExibirNotificacao("Caro usuário", "O vinculo do usuário ao software externo está sendo removido.", NotificacaoInterna.TipoDeNotificacao.Informacao);
                            
                                let Response = await SoftwareExterno.RemoverPermissaoDeAcesso(ContextSignIn.getContext().token, getItemPickerDeEmpresas.id ?? 0, getItemPickerCentroDeCusto.sigla ?? "", "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", getIDUsuarioSoftwareExterno);

                                if (Math.floor(Response.status / 100) == 2)
                                {

                                    let ListaDeUsuariosPermissaoSoftwareExterno = [...getListaDeUsuariosPermissaoDeSoftwareExterno];

                                    let ListaFiltrada = ListaDeUsuariosPermissaoSoftwareExterno.filter(item => item.id == getIDUsuarioSoftwareExterno);

                                    setListaDeUsuariosPermissaoDeSoftwareExterno(ListaFiltrada);
                                    NotificacaoInterna.ExibirNotificacao("Vinculo removido", "O vinculo ao software externo foi removida com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    handleCloseDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno();

                                }
                                else
                                {
                                    NotificacaoInterna.ExibirNotificacao("Erro ao remover o vinculo ao software externo", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                }

                            }
                            else
                            {
                                if(!getItemPickerDeEmpresas)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione uma empresa para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                                if(!getItemPickerCentroDeCusto)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Centro de custo não selecionado", "Selecione um centro de custo para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => {handleCloseDialogDeletarVinculoDoUsuarioAoSoftwareExterno()}}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog remover vinculo do usuário no centro de custo do software externo */}
            <Dialog
                style={{width: "42%", minWidth: '590px', backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno}
                title = {"Deseja remover o vinculo do usuário ao centro de custo do software externo?"}
                autoFocus = {true}
                isOpen = {getDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno.isOpen}
                canEscapeKeyClose = {getDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno.canOutsideClickClose}
                enforceFocus = {getDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno.enforceFocus}
                usePortal = {getDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            if (getItemPickerDeEmpresas && getItemPickerCentroDeCusto && getItemCentroDeCustoVinculado)
                            {

                                NotificacaoInterna.ExibirNotificacao("Caro usuário", "O vinculo do centro de custo está sendo removido do software externo", NotificacaoInterna.TipoDeNotificacao.Informacao);
                            
                                let Response = await SoftwareExterno.RemoverCentroDeCustoVinculado(ContextSignIn.getContext().token, getItemPickerDeEmpresas.id, getItemPickerCentroDeCusto.sigla, "U2VydmVyPTEwLjIwLjAuMTUwXEFNRUM7RGF0YWJhc2U9VUFVO1VzZXIgSUQ9RGlnaXRhbF9pbnQ7UGFzc3dvcmQ9QW1lY0RpZ2l0YWxJbnQ7TXVsdGlwbGVBY3RpdmVSZXN1bHRTZXRzPXRydWU=", getItemCentroDeCustoVinculado.centroDeCustoVinculado.empresaVinculada, getItemCentroDeCustoVinculado.centroDeCustoVinculado.centroDeCustoVinculado);

                                if (Math.floor(Response.status / 100) == 2)
                                {

                                    let ListaDeCentroDeCustoVinculado = [...getListaCentroDeCustoVinculado];

                                    let ListaFiltrada = ListaDeCentroDeCustoVinculado.filter(item => item.centroDeCustoVinculado.centroDeCustoVinculado == getItemCentroDeCustoVinculado.centroDeCustoVinculado.centroDeCustoVinculado);

                                    setListaCentroDeCustoVinculado(ListaFiltrada);
                                    NotificacaoInterna.ExibirNotificacao("Vinculo do centro de custo removido do software externo.", "O vinculo do centro de custo foi removido com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                    handleCloseDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno();

                                }
                                else
                                {
                                    NotificacaoInterna.ExibirNotificacao("Erro ao remover o vinculo do centro de custo ao software externo.", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                }

                            }
                            else
                            {
                                if(!getItemPickerDeEmpresas)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Empresa não selecionada", "Selecione uma empresa para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                                if(!getItemPickerCentroDeCusto)
                                {
                                    NotificacaoInterna.ExibirNotificacao("Centro de custo não selecionado", "Selecione um centro de custo para prosseguir.", NotificacaoInterna.TipoDeNotificacao.Alerta);
                                    return;
                                }
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => {handleCloseDialogDeletarVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno()}}>{"Não"}</Button>
                </div>

            </Dialog>

            {/** Dialog desvincular o cargo */}
            <Dialog
                style = {{ width: "25%", backgroundColor: "#FFFFFF", transition: "width 0.5s" }}
                icon = {<Icon icon="warning-sign" iconSize={25} color="#00384a" />}
                onClose={handleCloseDialogDesvincularUsuarioAoCargo}
                title = {"Deseja desvincular o cargo?"}
                autoFocus = {true}
                isOpen = {getDialogDesvincularUsuarioAoCargo.isOpen}
                canEscapeKeyClose = {getDialogDesvincularUsuarioAoCargo.canEscapeKeyClose}
                canOutsideClickClose = {getDialogDesvincularUsuarioAoCargo.canOutsideClickClose}
                enforceFocus = {getDialogDesvincularUsuarioAoCargo.enforceFocus}
                usePortal = {getDialogDesvincularUsuarioAoCargo.usePortal}
            >

                <div style = {{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', width: "100%", height: "40px", paddingRight: "30px", paddingLeft: "30px", paddingTop: "15px"}}>
                    <Button
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        onClick = {async () => {

                            if(getItemPickerCargos)
                            {
                                NotificacaoInterna.ExibirNotificacao("Caro usuário", "O usuário está sendo desvinculado do cargo.", NotificacaoInterna.TipoDeNotificacao.Informacao);

                                let Response = await Logon.DesvincularUsuarioDoCargo(ContextSignIn.getContext().token, getFormularioID, getItemPickerCargos.id);

                                if (Math.floor(Response.status / 100) == 2)
                                {

                                    let ListaPickerUsuarios = [...getPickerListaDeUsuarios];

                                    let IndexPickerUsuario = ListaPickerUsuarios.findIndex(item => item.id == getFormularioID);

                                    ListaPickerUsuarios[IndexPickerUsuario].cargo = null;

                                    let Response = await Logon.Usuarios(ContextSignIn.getContext().token, "")
                                    if(Math.floor(Response.status / 100) == 2)
                                    {

                                        let ListaDeUsuarios = [] as Array<{id: number, nome: string, emailPrincipal: string, cargoDescricao: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>

                                        Response.data.map((item, index) => {
                                            ListaDeUsuarios.push({
                                                id: item.id,
                                                nome: item.nome,
                                                emailPrincipal: item.email.descricao,
                                                email: item.email,
                                                cargoDescricao: item.cargo != null ? item.cargo.nome : "Sem cargo",
                                                cargo: item.cargo
                                            })
                                        })

                                        setListaDeUsuarios(ListaDeUsuarios);
                                        setPickerListaDeUsuarios(ListaPickerUsuarios);
                                        setEmConsulta(false);
                                    }

                                    NotificacaoInterna.ExibirNotificacao("O cargo foi desvinculado", "Desvinculo feito com sucesso.", NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                    handleCloseDialogDesvincularUsuarioAoCargo();

                                }
                                else
                                {
                                    NotificacaoInterna.ExibirNotificacao("Erro ao desvincular o cargo", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                } 
                            }

                        }}>{"Sim"}</Button>
                    <Button
                        style = {{
                            color: "#FFFFFF", 
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px", 
                            outline: "none",
                            width: "125px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "35px",
                        }}
                        intent = {"danger"}
                        onClick = {async () => { handleCloseDialogDesvincularUsuarioAoCargo() }}>{"Não"}</Button>
                </div>

            </Dialog>

            {/* Collapse */}
            <Collapse isOpen={getCollapse}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "0", width: "100%", backgroundColor: "#EDEFF5" }}>
                    <div style={{ width: "65%", minWidth: "919px", display: "flex", alignItems: "center", justifyContent: "space-between", background: "0", marginLeft: "2%" }}>
                        <div className="endereco">
                            <div style = {{display: 'flex', alignItems: 'center'}}>
                                <img src={IconeHome} style={{cursor: "pointer"}} alt="" onClick={() => {History.goBack();}} />
                                <div className="bar">/</div>
                                <div className="place">Geral</div>
                                <div className="bar">/</div>
                                <div className="place">Cadastros</div>
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", background: "0", width: "90%", minWidth: "777px", marginLeft: "-10px"}}>

                            {(getOptionCadastro == "Adicionar permissão de empresa" || getOptionCadastro == 'Adicionar permissão de centro de custo' || getOptionCadastro == 'Adicionar vinculo do centro de custo ao software externo' || getOptionCadastro == 'Adicionar vinculo do usuário ao software externo') &&
                            <div style = {{display: "flex", alignItems: "center", marginRight: '30px'}}>
                                <PickerEmpresas popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerDeEmpresas} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    itemRenderer = {(Item: Objeto.Empresa) => <MenuItem onClick = { async () => { 
                                        setItemPickerDeEmpresas(Item);
                                        let ListaPickerDeCentroDeCusto = [...getListaPickerCentroDeCusto];

                                        let ListaFiltrada = ListaPickerDeCentroDeCusto.filter(item => item.empresa.id == Item.id);

                                        setItemPickerCentroDeCusto(undefined);
                                        setListaRetroAlimentativaPickerCentroDeCusto(ListaFiltrada);
                                    }} text={Item.nomeFantasia} />} itemPredicate={(Texto: string, Item: Objeto.Empresa) => { return (Item.nomeFantasia ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                    <Button className="selector" style={{ background: "#FFFFFF", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerDeEmpresas?.nomeFantasia.slice(0, 10).concat('...') ?? "Empresa..."} rightIcon="double-caret-vertical" />
                                </PickerEmpresas>
                                {getItemPickerDeEmpresas && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} 
                                    onClick = { async () => { 
                                        setItemPickerDeEmpresas(undefined);
                                        setListaRetroAlimentativaPickerCentroDeCusto([]);
                                        setItemPickerCentroDeCusto(undefined); 
                                }} />}
                            </div>}

                            {(getOptionCadastro == 'Adicionar permissão de centro de custo' || getOptionCadastro == 'Adicionar vinculo do centro de custo ao software externo' || getOptionCadastro == 'Adicionar vinculo do usuário ao software externo') &&
                            <div style = {{display: "flex", alignItems: "center", marginRight: '30px'}}>
                                <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaRetroAlimentativaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => {setItemPickerCentroDeCusto(Item)}} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                    <Button className="selector" style={{ background: "#FFFFFF", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "200px", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerCentroDeCusto?.descricao.slice(0, 10).concat('...') ?? "Centro de custo..."} rightIcon="double-caret-vertical" />
                                </PickerCentroDeCusto>
                                {getItemPickerCentroDeCusto && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {setItemPickerCentroDeCusto(undefined); }} />}
                            </div>}

                            {(getOptionCadastro == 'Adicionar permissão de funil de venda') &&
                            <div style = {{display: "flex", alignItems: "center", marginRight: '30px'}}>
                                <PickerFunilDeVenda popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerFunilDeVenda} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    itemRenderer={(Item: Objeto.FunilDeVendas) => <MenuItem onClick = { async () => {
                                        setItemPickerFunilDeVenda(Item);

                                        let ListaPickerSalasDeVenda = [] as Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}> | null}>

                                        Item.hierarquiaDoFunil.map(item => {
                                            ListaPickerSalasDeVenda.push({
                                                id: item.salaDeVenda.id,
                                                descricao: item.salaDeVenda.descricao,
                                                areas: item.areas
                                            })
                                        }) 
                                        setItemPickerSalaDeVendas(undefined);
                                        setItemPickerArea(undefined);
                                        setListaRetroAlimentativaPickerSalaDeVendas(ListaPickerSalasDeVenda);
                                        
                                    }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.FunilDeVendas) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                    <Button className="selector" style={{ background: "#FFFFFF", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "200px", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerFunilDeVenda?.descricao.slice(0, 10).concat('...') ?? "Funil de venda..."} rightIcon="double-caret-vertical" />
                                </PickerFunilDeVenda>
                                {getItemPickerFunilDeVenda && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {
                                    setItemPickerFunilDeVenda(undefined); 
                                    setListaRetroAlimentativaPickerSalaDeVendas([]);
                                    setListaRetroAlimentativaPickerArea([]);
                                    setItemPickerSalaDeVendas(undefined);
                                    setItemPickerArea(undefined);
                                }} />}
                            </div>}

                            {(getOptionCadastro == 'Adicionar permissão de controle de sala') &&
                            <div style = {{display: "flex", alignItems: "center", marginRight: '30px'}}>
                                <PickerControleDeSala popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerControleDeSala} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    itemRenderer={(Item: Objeto.ControleDeSala) => <MenuItem onClick={async () => {
                                        setItemPickerControleDeSala(Item)

                                        let ListaDeSalasDeVenda = [] as Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}> | null}>

                                        Item.salasDeVenda?.map(item => {
                                            ListaDeSalasDeVenda.push({
                                                id: item.id,
                                                descricao: item.descricao,
                                                areas: []
                                            })
                                        })

                                        setItemPickerSalaDeVendas(undefined);
                                        setListaRetroAlimentativaPickerSalaDeVendas(ListaDeSalasDeVenda);

                                    }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.ControleDeSala) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                    <Button className="selector" style={{ background: "#FFFFFF", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "200px", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerControleDeSala?.descricao.slice(0, 10).concat('...') ?? "Controle de sala..."} rightIcon="double-caret-vertical" />
                                </PickerControleDeSala>
                                {getItemPickerControleDeSala && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { 
                                    setItemPickerControleDeSala(undefined); 
                                    setListaRetroAlimentativaPickerSalaDeVendas([]);
                                    setItemPickerSalaDeVendas(undefined);
                                }} />}
                            </div>}

                            {(getOptionCadastro == 'Adicionar permissão de controle de sala' || getOptionCadastro == 'Adicionar permissão de funil de venda') &&
                            <div style = {{display: "flex", alignItems: "center", marginRight: '30px'}}>
                                <PickerSalaDeVendas popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaRetroAlimentativaPickeSalaDeVendas} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    itemRenderer={(Item: {id: number, descricao: string, areas: Array<{id: number, descricao: string}> | null}) => <MenuItem onClick={async () => {
                                        setItemPickerSalaDeVendas(Item);
                                        setItemPickerArea(undefined);
                                        setListaRetroAlimentativaPickerArea(Item.areas != null ? Item.areas : []);
                                    }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: {id: number, descricao: string, areas: Array<{id: number, descricao: string}> | null}) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                    <Button className="selector" style={{ background: "#FFFFFF", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "200px", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerSalaDeVendas?.descricao.slice(0, 10).concat('...') ?? "Sala de venda..."} rightIcon="double-caret-vertical" />
                                </PickerSalaDeVendas>
                                {getItemPickerSalaDeVendas && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => { 
                                    setItemPickerSalaDeVendas(undefined); 
                                    setListaRetroAlimentativaPickerArea([]);
                                    setItemPickerArea(undefined);
                                }} />}
                            </div>}

                            {(getOptionCadastro == 'Adicionar permissão de funil de venda') &&
                            <div style = {{display: "flex", alignItems: "center", marginRight: '30px'}}>
                                <PickerArea popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaRetroAlimentativaPickeArea} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    itemRenderer={(Item: {id: number, descricao: string}) => <MenuItem onClick={async () => {setItemPickerArea(Item)}} text={Item.descricao} />} itemPredicate={(Texto: string, Item: {id: number, descricao: string}) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                    <Button className="selector" style={{ background: "#FFFFFF", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "200px", display: "flex", alignItems: "center", justifyContent: "space-between"}} text={getItemPickerArea?.descricao.slice(0, 10).concat('...') ?? "Area..."} rightIcon="double-caret-vertical" />
                                </PickerArea>
                                {getItemPickerArea && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {setItemPickerArea(undefined); }} />}
                            </div>}

                        </div>
                    </div>
                    <Divider style={{ width: "1px", height: "26px", backgroundColor: "#afafaf" }} />
                    {getOptionDescription != "" && getOptionCadastro != 'Adicionar vinculo' && <Button
                        icon = {<Icon icon = "add" iconSize = {15} color = "#FFFFFF"/>}
                        style = {{
                            background: "#1098F7", 
                            color: "#FFFFFF",
                            border: "none", 
                            boxShadow: "none", 
                            borderRadius: "5px",
                            outline: "none",
                            width: "180px", 
                            display: "flex", 
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "15px",
                            marginRight: "10px"
                        }} 
                        text = {(getOptionDescription == 'Adicionar cadastros' || getOptionDescription == 'Adicionar cadastros cargo' || getOptionDescription == 'Adicionar cadastros usuário'  || getOptionDescription == 'Adicionar cadastros usuário software externo') ? ((getOptionCadastro.includes('Adicionar permissão')) ? 'Adicionar permissão' : (getOptionCadastro.includes('Adicionar vinculo')) ? 'Adicionar vinculo' : getOptionCadastro) : getOptionDescription}
                        onClick = {getOptionFunction}
                    />}
                    {(getOptionCadastro == 'Adicionar permissão de empresa' || getOptionCadastro == 'Adicionar permissão de centro de custo' || getOptionCadastro == 'Adicionar permissão de controle de sala' || getOptionCadastro == 'Adicionar permissão de funil de venda' || getOptionCadastro == 'Adicionar vinculo do centro de custo ao software externo' || getOptionCadastro == 'Adicionar vinculo do usuário ao software externo') &&
                    <div className="hoverize" style={{ width: "110px", minWidth: "110px", height: "30px", marginRight: "90px", color: "#175372", boxShadow: "0px 0px 0px 1px #d8d8d8", padding: "0 5px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer" }}
                        onClick={Buscar}>
                        <Icon color="#175372" style={{/*{ transform: getFilterClicked ? "rotate(0deg)" : "rotate(-360deg)", transition: getFilterClicked ? "transform 2s" : "" }*/ }} icon="search" />
                        <span style = {{fontSize: "12px"}}>Pesquisar</span>
                    </div>}
                </div>
            </Collapse>

            {/* Abridor Colapse */}
            <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>

            {getCarregandoListaDeContratos == false && <div style = {{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.Cadastros,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={500}
                    width={600}
                    style={{ marginTop: "100px" }}
                />
                <div className="loading" style={{ margin: "0 auto 0 auto", fontWeight: "bold"}}>{"Aguarde, estamos carregando os dados para realizar os cadastros."}</div>
            </div>}

            {getCarregandoListaDeContratos == true && <Card style = {{ backgroundColor: "0", width: "100%", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none" }}>
            <div style = {{height: "30px", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center", marginBottom: "5px"}}>
                <div style = {{display: "flex", marginTop: "10px", justifyContent: "flex-start", alignItems: "flex-end", width: "100%", borderBottom: "0.5px solid #00000020", paddingRight: "2%", paddingLeft: "2%"}}>                    
                    {/* ========== Adicionar Sala ==========  */}
                    <Button
                        style = {{
                            background: "0",
                            color: "#000000",
                            borderLeft: getOptionDescription == "Adicionar sala" ? "0.5px dotted #00000050" : "none",
                            borderRight: getOptionDescription == "Adicionar sala" ? "0.5px dotted #00000050" : "none",
                            borderTop: getOptionDescription == "Adicionar sala" ? "0.5px dotted #00000050" : "none",
                            borderBottom: getOptionDescription == "Adicionar sala" ? "3px solid #1098F7" : "0.5px solid #00000000",
                            marginBottom: getOptionDescription == "Adicionar sala" ? "-2px" : "-0.5px",
                            fontWeight: getOptionDescription == "Adicionar sala" ? "bolder" : "unset",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex", 
                            alignItems: "center",
                            fontSize: "12px", 
                            height: "15px",
                            paddingLeft: "20px",
                            paddingRight: "20px"
                        }}
                        text = {"Salas"}
                        onClick = {async () => {
                            setListaDeSalas([]);
                            setOptionDescription("Adicionar sala");
                            setOptionCadastro("");
                            setOptionFunction(() => () => {
                                setDescriptionSala("");
                                setIdSala(0);
                                handleOpenDrawerAdicionarSala();
                            });
                            setEmConsulta(true);
                            setCollapse(true);

                            let Response = await SalaDeVendas.Get(ContextSignIn.getContext().token)
                            if(Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeSalas = [] as Array<{id: number, descricao: string, areas: Array<{id: number, descricao: string}>}>

                                Response.data.map((item) => {
                                    ListaDeSalas.push({
                                        id: item.id,
                                        descricao: item.descricao,
                                        areas: item.areas,
                                    })
                                })

                                setListaDeSalas(ListaDeSalas);
                                setEmConsulta(false);
                            }
                            else
                            {
                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar as salas de venda", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                setEmConsulta(false);
                            }
                        }}
                    />
                    {/* ========== Adicionar Area ==========  */}
                    <Button
                        style = {{
                            background: "0",
                            color: "#000000",
                            borderLeft: getOptionDescription == "Adicionar área" ? "0.5px dotted #00000050" : "none",
                            borderRight: getOptionDescription == "Adicionar área" ? "0.5px dotted #00000050" : "none",
                            borderTop: getOptionDescription == "Adicionar área" ? "0.5px dotted #00000050" : "none",
                            borderBottom: getOptionDescription == "Adicionar área" ? "3px solid #1098F7" : "0.5px solid #00000000",
                            marginBottom: getOptionDescription == "Adicionar área" ? "-2px" : "-0.5px",
                            fontWeight: getOptionDescription == "Adicionar área" ? "bolder" : "unset",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex",
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "15px",
                            paddingLeft: "20px",
                            paddingRight: "20px"
                        }}
                        text = {"Áreas"}
                        onClick = {async () => {
                            setListaDeAreas([]);
                            setOptionDescription("Adicionar área");
                            setOptionCadastro("");
                            setOptionFunction(() => () => {
                                setDescriptionDepartamento("");
                                setIdDepartamento(0);
                                handleOpenDrawerAdicionarDepartamento()
                            })
                            setEmConsulta(true);
                            setCollapse(true);

                            let Response = await Areas.Get(ContextSignIn.getContext().token)
                            if(Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeDepartamentos = [] as Array<{id: number, descricao: string, departamentos: Array<{id: number, descricao: string}>}>

                                Response.data.map((item) => {
                                    ListaDeDepartamentos.push({
                                        id: item.id,
                                        descricao: item.descricao,
                                        departamentos: []
                                    })
                                })

                                ListaDeDepartamentos.map((item) => {
                                    item.departamentos = ListaDeDepartamentos
                                })

                                setListaDeAreas(ListaDeDepartamentos);
                                setEmConsulta(false);
                            }
                            else
                            {
                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar as salas de venda", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                setEmConsulta(false);
                            }
                        }}
                    />
                    {/* ========== Adicionar Usuario ==========  */}
                    <Button
                        style = {{
                            background: "0",
                            color: "#000000",
                            borderLeft: getOptionDescription == "Adicionar cadastros usuário" ? "0.5px dotted #00000050" : "none",
                            borderRight: getOptionDescription == "Adicionar cadastros usuário" ? "0.5px dotted #00000050" : "none",
                            borderTop: getOptionDescription == "Adicionar cadastros usuário" ? "0.5px dotted #00000050" : "none",
                            borderBottom: getOptionDescription == "Adicionar cadastros usuário" ? "3px solid #1098F7" : "0.5px solid #00000000",
                            marginBottom: getOptionDescription == "Adicionar cadastros usuário" ? "-2px" : "-0.5px",
                            fontWeight: getOptionDescription == "Adicionar cadastros usuário" ? "bolder" : "unset",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex",
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "15px",
                            paddingLeft: "20px",
                            paddingRight: "20px"
                        }}
                        text = {"Usuários"}
                        onClick = {async () => {
                            setListaDeUsuarios([]);
                            setListaDeUsuariosPermissaoDeEmpresa([]);
                            setListaDeUsuariosPermissaoDeCentroDeCusto([]);
                            setListaDeUsuariosPermissaoDeControleDeSala([]);
                            setListaDeUsuariosPermissaoDeFunilDeVenda([]);
                            setOptionDescription("Adicionar cadastros usuário");
                            setOptionCadastro("Adicionar usuário");
                            setOptionFunction(() => () => {handleOpenDrawerAdicionarUsuario()});
                            setEmConsulta(true);
                            setCollapse(true);

                            setListaDeCargos((await Cargo.Get(ContextSignIn.getContext().token)).data ?? []);

                            let Response = await Logon.Usuarios(ContextSignIn.getContext().token, "")
                            if(Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeUsuarios = [] as Array<{id: number, nome: string, emailPrincipal: string, cargoDescricao: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>

                                Response.data.map((item, index) => {
                                    ListaDeUsuarios.push({
                                        id: item.id,
                                        nome: item.nome,
                                        emailPrincipal: item.email.descricao,
                                        email: item.email,
                                        cargoDescricao: item.cargo != null ? item.cargo.nome : "Sem cargo",
                                        cargo: item.cargo
                                    })
                                })

                                setListaDeUsuarios(ListaDeUsuarios);
                                setEmConsulta(false);
                            }
                            else
                            {
                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de usuários", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                setEmConsulta(false);
                            }
                        }}
                    />
                    {/* ========== Adicionar Empresa ==========  */}
                    <Button
                        style = {{
                            background: "0",
                            color: "#000000",
                            borderLeft: getOptionDescription == "Adicionar empresa" ? "0.5px dotted #00000050" : "none",
                            borderRight: getOptionDescription == "Adicionar empresa" ? "0.5px dotted #00000050" : "none",
                            borderTop: getOptionDescription == "Adicionar empresa" ? "0.5px dotted #00000050" : "none",
                            borderBottom: getOptionDescription == "Adicionar empresa" ? "3px solid #1098F7" : "0.5px solid #00000000",
                            marginBottom: getOptionDescription == "Adicionar empresa" ? "-2px" : "-0.5px",
                            fontWeight: getOptionDescription == "Adicionar empresa" ? "bolder" : "unset",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex",
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "15px",
                            paddingLeft: "20px",
                            paddingRight: "20px"
                        }}
                        text = {"Empresa"}
                        onClick = {async () => {
                            setListaDeEmpresas([]);
                            setOptionDescription("Adicionar empresa");
                            setOptionFunction(() => () => {
                                handleOpenDrawerAdicionarEmpresa()
                                setIdEmpresa(0)
                            });
                            setEmConsulta(true);
                            setCollapse(true);

                            let Response = await Empresa.GruposDeEmpresasUsuario(ContextSignIn.getContext().token, true)
                            if(Math.floor(Response.status / 100) == 2){
                                setListaDeEmpresas(Response.data[0].empresas);
                                setEmConsulta(false);
                            } else {
                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de empresas", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                setEmConsulta(false);
                            }
                        }}
                    />
                    {/* ========== Adicionar Centro de Custo ==========  */}
                     <Button
                        style = {{
                            background: "0",
                            color: "#000000",
                            borderLeft: getOptionDescription == "Adicionar centro de custo" ? "0.5px dotted #00000050" : "none",
                            borderRight: getOptionDescription == "Adicionar centro de custo" ? "0.5px dotted #00000050" : "none",
                            borderTop: getOptionDescription == "Adicionar centro de custo" ? "0.5px dotted #00000050" : "none",
                            borderBottom: getOptionDescription == "Adicionar centro de custo" ? "3px solid #1098F7" : "0.5px solid #00000000",
                            marginBottom: getOptionDescription == "Adicionar centro de custo" ? "-2px" : "-0.5px",
                            fontWeight: getOptionDescription == "Adicionar centro de custo" ? "bolder" : "unset",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex",
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "15px",
                            paddingLeft: "20px",
                            paddingRight: "20px"
                        }}
                        text = {"Centro de custo"}
                        onClick = {async () => {
                            setListaDeCentrosDeCusto([]);
                            setOptionDescription("Adicionar centro de custo");
                            setOptionFunction(() => () => {
                                setIdCentroDeCusto("");
                                setDisable(false)
                                setNomeCentroDeCusto("")
                                setSigla("")
                                // setItemPickerEmpresas(undefined);
                                handleOpenDrawerAdicionarCentroDeCusto()});
                            setEmConsulta(true);
                            setCollapse(true);

                            let ResponseEmpresas = await (await Empresa.GruposDeEmpresasUsuario(ContextSignIn.getContext().token, true)).data ?? [];
                            
                            setListaDeEmpresas(ResponseEmpresas[0].empresas);

                            let ResponseCentroDeCusto = await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")
                            if(Math.floor(ResponseCentroDeCusto.status / 100) == 2){
                                
                                let ListaDeCentrosDeCusto = [] as Array<{empresaNome: string, sigla: string, descricao: string, empresa: Objeto.Empresa}>

                                let i = {} as {sigla: string, descricao: string, empresaNome: string, empresa: Objeto.Empresa}

                                ResponseCentroDeCusto.data.map((item) =>{
                                    ListaDeCentrosDeCusto.push({
                                        empresaNome: item.empresa.razaoSocial,
                                        sigla: item.sigla,
                                        descricao: item.descricao,
                                        empresa: item.empresa
                                    })
                                })        

                                setListaDeCentrosDeCusto(ListaDeCentrosDeCusto);
                                setEmConsulta(false);
                            } else {
                                NotificacaoInterna.ExibirNotificacao("Erro ao consultar puxar a lista de centro de custo", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                setEmConsulta(false);
                            }
                        }}
                    />
                    {/* ========== Adicionar Software Externo ==========  */}
                    <Button
                        style = {{
                            background: "0",
                            color: "#000000",
                            borderLeft: getOptionDescription == "Adicionar cadastros usuário software externo" ? "0.5px dotted #00000050" : "none",
                            borderRight: getOptionDescription == "Adicionar cadastros usuário software externo"? "0.5px dotted #00000050" : "none",
                            borderTop: getOptionDescription == "Adicionar cadastros usuário software externo" ? "0.5px dotted #00000050" : "none",
                            borderBottom: getOptionDescription == "Adicionar cadastros usuário software externo" ? "3px solid #1098F7" : "0.5px solid #00000000",
                            marginBottom: getOptionDescription == "Adicionar cadastros usuário software externo" ? "-2px" : "-0.5px",
                            fontWeight: getOptionDescription == "Adicionar cadastros usuário software externo" ? "bolder" : "unset",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex",
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "15px",
                            paddingLeft: "20px",
                            paddingRight: "20px"
                        }}
                        text = {"Software Externo"}
                        onClick = {async () => {
                            setListaDeUsuariosPermissaoDeSoftwareExterno([]);
                            setListaDeUsuariosPermissaoDeCentroDeCusto([]);
                            setItemPickerSalaDeVendas(undefined);
                            setItemPickerArea(undefined);
                            setItemPickerDeEmpresas(undefined);
                            setItemPickerCentroDeCusto(undefined);
                            setItemPickerControleDeSala(undefined);
                            setItemPickerFunilDeVenda(undefined);
                            setOptionDescription("Adicionar cadastros usuário software externo");
                            setOptionCadastro("Adicionar vinculo do usuário ao software externo");
                            setOptionFunction(() => () => {handleOpenDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno()});
                            setCollapse(true);
                        }}
                    />
                    {/* ========== Adicionar Cargo ==========  */}
                    <Button
                        style = {{
                            background: "0",
                            color: "#000000",
                            borderLeft: getOptionDescription == "Adicionar cadastros cargo" ? "0.5px dotted #00000050" : "none",
                            borderRight: getOptionDescription == "Adicionar cadastros cargo" ? "0.5px dotted #00000050" : "none",
                            borderTop: getOptionDescription == "Adicionar cadastros cargo" ? "0.5px dotted #00000050" : "none",
                            borderBottom: getOptionDescription == "Adicionar cadastros cargo" ? "3px solid #1098F7" : "0.5px solid #00000000",
                            marginBottom: getOptionDescription == "Adicionar cadastros cargo" ? "-2px" : "-0.5px",
                            fontWeight: getOptionDescription == "Adicionar cadastros cargo" ? "bolder" : "unset",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex",
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "15px",
                            paddingLeft: "20px",
                            paddingRight: "20px"
                        }}
                        text = {"Cargos"}
                        onClick = {async () => {
                            setListaDeCargos([]);
                            setListaDeUsuarios([]);
                            setOptionDescription("Adicionar cadastros cargo");
                            setOptionCadastro("Adicionar cargo");
                            setOptionFunction(() => () => {
                                setIDCargos(0);
                                setDescricaoCargos("");
                                handleOpenDrawerAdicionarCargos();
                            });
                            setEmConsulta(true);
                            setCollapse(true);

                            let Response = await Cargo.Get(ContextSignIn.getContext().token)
                            if(Math.floor(Response.status / 100) == 2)
                            {

                                setListaDeCargos(Response.data);
                                setEmConsulta(false);
                            }
                            else
                            {
                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de cargos", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                setEmConsulta(false);
                            }
                        }}
                    />
                    {/* ========== Adicionar Fornecedor ==========  */}
                    <Button
                        style = {{
                            background: "0",
                            color: "#000000",
                            borderLeft: getOptionDescription == "Adicionar fornecedor" ? "0.5px dotted #00000050" : "none",
                            borderRight: getOptionDescription == "Adicionar fornecedor" ? "0.5px dotted #00000050" : "none",
                            borderTop: getOptionDescription == "Adicionar fornecedor" ? "0.5px dotted #00000050" : "none",
                            borderBottom: getOptionDescription == "Adicionar fornecedor" ? "3px solid #1098F7" : "0.5px solid #00000000",
                            marginBottom: getOptionDescription == "Adicionar fornecedor" ? "-2px" : "-0.5px",
                            fontWeight: getOptionDescription == "Adicionar fornecedor" ? "bolder" : "unset",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex",
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "15px",
                            paddingLeft: "20px",
                            paddingRight: "20px"
                        }}
                        text = {"Fornecedores"}
                        onClick = {async () => {
                            setListaPickerFornecedor([]);
                            setOptionDescription("Adicionar fornecedor");
                            setOptionCadastro("");
                            setOptionFunction(() => () => {handleOpenDrawerAdicionarFornecedor()});
                            setEmConsulta(true);
                            setCollapse(true);

                            let Response = await Fornecedor.Get(ContextSignIn.getContext().token)
                            if(Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeFornecedores = [] as Array<Objeto.Fornecedor>

                                Response.data.map((item, index) => {
                                    ListaDeFornecedores.push({
                                        id: item.id,
                                        cpfcnpj: item.cpfcnpj,
                                        nomeRazaoSocial: item.nomeRazaoSocial,
                                        endereco: item.endereco,
                                        telefones: item.telefones
                                    })
                                })

                                setListaPickerFornecedor(ListaDeFornecedores);
                                setEmConsulta(false);
                            }
                            else
                            {
                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de fornecedores", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                setEmConsulta(false);
                            }
                        }}
                    />
                    {/* ========== Adicionar Brinde no almoxarifado ==========  */}
                    <Button
                        style = {{
                            background: "0",
                            color: "#000000",
                            borderLeft: getOptionDescription == "Adicionar cadastros" ? "0.5px dotted #00000050" : "none",
                            borderRight: getOptionDescription == "Adicionar cadastros"? "0.5px dotted #00000050" : "none",
                            borderTop: getOptionDescription == "Adicionar cadastros" ? "0.5px dotted #00000050" : "none",
                            borderBottom: getOptionDescription == "Adicionar cadastros" ? "3px solid #1098F7" : "0.5px solid #00000000",
                            marginBottom: getOptionDescription == "Adicionar cadastros" ? "-2px" : "-0.5px",
                            fontWeight: getOptionDescription == "Adicionar cadastros" ? "bolder" : "unset",
                            boxShadow: "none",
                            outline: "none",
                            display: "flex",
                            alignItems: "center", 
                            fontSize: "12px", 
                            height: "15px",
                            paddingLeft: "20px",
                            paddingRight: "20px"
                        }}
                        text = {"Brindes"}
                        onClick = {async () => {
                            setListaDoAlmoxarifado([]);
                            setOptionDescription("Adicionar cadastros");
                            setOptionCadastro("Adicionar brinde");
                            setOptionFunction(() => () => {handleOpenDrawerAdicionarNomeDoBrinde()});
                            setEmConsulta(true);
                            setCollapse(true);
           
                            let Response = await Brinde.Get(ContextSignIn.getContext().token)
                            if(Math.floor(Response.status / 100) == 2)
                            {

                                let ListaDeNomesDosBrindes = [] as Array<{id: number, descricao: string}>

                                Response.data.map((item) => {
                                 ListaDeNomesDosBrindes.push({
                                        id: item.id,
                                        descricao: item.descricao
                                    })
                                })

                                setListaNomesDeBrindes(ListaDeNomesDosBrindes);
                                setEmConsulta(false);
                            }
                            else
                            {
                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar os nomes dos brindes", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                setEmConsulta(false);
                            }
                        }}
                    />
                </div>
            </div>

                {(getOptionDescription == "Adicionar sala" || getOptionDescription == "Alterar sala") && <div style = {{width: "100%", opacity: getEmConsulta == false ? 1 : 0.5, transition: "opacity 0.5s"}}>
                    <div style = {{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                        <div style={{ width: "100%", overflowX: 'scroll' }}>
                            <GridComponent dataSource={getListaDeSalas} width={"100%"} height={"100%"}
                                id='grid'
                                ref={g => grid = g}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='descricao' headerText='Descricao' textAlign='Left'/>
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {
                                                setDescriptionSala(props.descricao);
                                                setIdSala(props.id);
                                                handleOpenDrawerAdicionarSala();
                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = {async () => {
                                                setIdSala(props.id);
                                                setDescriptionSala(props.descricao);
                                                handleOpenDialogDeletarSala();
                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>}

                {(getOptionDescription == "Adicionar área" || getOptionDescription == "Alterar área") && <div style = {{width: "100%", opacity: getEmConsulta == false ? 1 : 0.5, transition: "opacity 0.5s"}}>
                    <div style = {{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                        <div style={{ width: "100%", overflowX: 'scroll' }}>
                            <GridComponent dataSource={getListaDeAreas} width={"100%"} height={"100%"}
                                id='grid'
                                ref={g => grid = g}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='descricao' headerText='Descricao' textAlign='Left'/>
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>   
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {
                                                setDescriptionDepartamento(props.descricao);
                                                setIdDepartamento(props.id);
                                                handleOpenDrawerAdicionarDepartamento()
                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {

                                                setDescriptionDepartamento(props.descricao);
                                                setIdDepartamento(props.id);
                                                handleOpenDialogDeletarArea();
                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>}

                {(getOptionDescription == "Adicionar cadastros usuário" || getOptionDescription == "Alterar cadastros usuário") && <div style = {{width: "100%", opacity: getEmConsulta == false ? 1 : 0.5, transition: "opacity 0.5s"}}>
                    <div style = {{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                        <div style = {{backgroundColor: "#FFFFFF", width: "25%", display: "flex", flexDirection: "column", minHeight: "590px", alignItems: "center", padding: "20px", borderRight: "1px solid #00000020", borderTop: "1px solid #00000020"}}>   
                            <div style = {{display: "flex", alignItems: "center", borderBottom: "1px solid #00000020"}}>
                                <div 
                                    style = {{
                                        fontWeight: "bold", 
                                        fontSize: "18px",
                                        padding: "17px",
                                        width: "100%",
                                        textAlign: "center"
                                }}>Opções de cadastro</div>
                            </div>

                            {/** ADICIONAR USUÁRIO */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar usuário" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => {
                                        setListaDeUsuarios([]);
                                        setItemPickerSalaDeVendas(undefined);
                                        setItemPickerArea(undefined);
                                        setItemPickerDeEmpresas(undefined);
                                        setItemPickerCentroDeCusto(undefined);
                                        setItemPickerControleDeSala(undefined);
                                        setItemPickerFunilDeVenda(undefined);
                                        setOptionCadastro("Adicionar usuário");
                                        setOptionFunction(() => () => {handleOpenDrawerAdicionarUsuario()});
                                        setEmConsulta(true);
            
                                        setListaDeCargos((await Cargo.Get(ContextSignIn.getContext().token)).data ?? []);
            
                                        let Response = await Logon.Usuarios(ContextSignIn.getContext().token, "")
                                        if(Math.floor(Response.status / 100) == 2)
                                        {
            
                                            let ListaDeUsuarios = [] as Array<{id: number, nome: string, emailPrincipal: string, cargoDescricao: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>
            
                                            Response.data.map((item, index) => {
                                                ListaDeUsuarios.push({
                                                    id: item.id,
                                                    nome: item.nome,
                                                    emailPrincipal: item.email.descricao,
                                                    email: item.email,
                                                    cargoDescricao: item.cargo != null ? item.cargo.nome : "Sem cargo",
                                                    cargo: item.cargo
                                                })
                                            })
            
                                            setListaDeUsuarios(ListaDeUsuarios);
                                            setEmConsulta(false);
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de usuários", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            setEmConsulta(false);
                                        }
                                    }}>
                                    <Icon 
                                        icon = 'add'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Cadastro geral"}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/** ADICIONAR PERMISSÃO EMPRESA */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar permissão de empresa" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => {
                                        setListaDeUsuariosPermissaoDeEmpresa([]);
                                        setItemPickerSalaDeVendas(undefined);
                                        setItemPickerArea(undefined);
                                        setItemPickerDeEmpresas(undefined);
                                        setItemPickerCentroDeCusto(undefined);
                                        setItemPickerControleDeSala(undefined);
                                        setItemPickerFunilDeVenda(undefined);
                                        setOptionCadastro("Adicionar permissão de empresa");
                                        setOptionFunction(() => () => {handleOpenDialogAtribuindoPermissaoDeEmpresa()});
                                        setCollapse(true);
                                    }}>
                                    <Icon 
                                        icon = 'office'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Permissões de empresa"}
                                            onClick = {async () => {
                                                setListaDeUsuariosPermissaoDeEmpresa([]);
                                                setItemPickerSalaDeVendas(undefined);
                                                setItemPickerArea(undefined);
                                                setItemPickerDeEmpresas(undefined);
                                                setItemPickerCentroDeCusto(undefined);
                                                setItemPickerControleDeSala(undefined);
                                                setItemPickerFunilDeVenda(undefined);
                                                setOptionCadastro("Adicionar permissão de empresa");
                                                setOptionFunction(() => () => {handleOpenDialogAtribuindoPermissaoDeEmpresa()});
                                                setCollapse(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/** ADICIONAR PERMISSÃO CENTRODECUSTO */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar permissão de centro de custo" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => {
                                        setListaDeUsuariosPermissaoDeCentroDeCusto([]);
                                        setItemPickerSalaDeVendas(undefined);
                                        setItemPickerArea(undefined);
                                        setItemPickerCentroDeCusto(undefined);
                                        setItemPickerControleDeSala(undefined);
                                        setItemPickerFunilDeVenda(undefined);
                                        setOptionCadastro("Adicionar permissão de centro de custo");
                                        setOptionFunction(() => () => {handleOpenDialogAtribuindoPermissaoDeCentroDeCusto()});
                                        setCollapse(true);
                                    }}>
                                    <Icon 
                                        icon = 'numbered-list'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Permissões de centro de custo"}
                                            onClick = {async () => {
                                                setListaDeUsuariosPermissaoDeCentroDeCusto([]);
                                                setItemPickerSalaDeVendas(undefined);
                                                setItemPickerArea(undefined);
                                                setItemPickerCentroDeCusto(undefined);
                                                setItemPickerControleDeSala(undefined);
                                                setItemPickerFunilDeVenda(undefined);
                                                setOptionCadastro("Adicionar permissão de centro de custo");
                                                setOptionFunction(() => () => {handleOpenDialogAtribuindoPermissaoDeCentroDeCusto()});
                                                setCollapse(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/** ADICIONAR PERMISSÃO CONTROLE DE SALA */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar permissão de controle de sala" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => {
                                        setListaDeUsuariosPermissaoDeControleDeSala([]);
                                        setItemPickerSalaDeVendas(undefined);
                                        setItemPickerArea(undefined);
                                        setItemPickerDeEmpresas(undefined);
                                        setItemPickerCentroDeCusto(undefined);
                                        setItemPickerControleDeSala(undefined);
                                        setItemPickerFunilDeVenda(undefined);
                                        setOptionCadastro("Adicionar permissão de controle de sala");
                                        setOptionFunction(() => () => {handleOpenDialogAtribuindoPermissaoDeControleDeSala()});
                                        setCollapse(true);
                                    }}>
                                    <Icon 
                                        icon = 'diagram-tree'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Permissões de controle de sala"}
                                            onClick = {async () => {
                                                setListaDeUsuariosPermissaoDeControleDeSala([]);
                                                setItemPickerSalaDeVendas(undefined);
                                                setItemPickerArea(undefined);
                                                setItemPickerDeEmpresas(undefined);
                                                setItemPickerCentroDeCusto(undefined);
                                                setItemPickerControleDeSala(undefined);
                                                setItemPickerFunilDeVenda(undefined);
                                                setOptionCadastro("Adicionar permissão de controle de sala");
                                                setOptionFunction(() => () => {handleOpenDialogAtribuindoPermissaoDeControleDeSala()});
                                                setCollapse(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/** ADICIONAR PERMISSÃO FUNIL DE VENDA */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar permissão de funil de venda" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => {
                                        setListaDeUsuariosPermissaoDeFunilDeVenda([]);
                                        setItemPickerSalaDeVendas(undefined);
                                        setItemPickerArea(undefined);
                                        setItemPickerDeEmpresas(undefined);
                                        setItemPickerCentroDeCusto(undefined);
                                        setItemPickerControleDeSala(undefined);
                                        setItemPickerFunilDeVenda(undefined);
                                        setOptionCadastro("Adicionar permissão de funil de venda");
                                        setOptionFunction(() => () => {handleOpenDialogAtribuindoPermissaoDeFunilDeVenda()});
                                        setCollapse(true);
                                    }}>
                                    <Icon 
                                        icon = 'filter'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Permissões de funil de venda"}
                                            onClick = {async () => {
                                                setListaDeUsuariosPermissaoDeFunilDeVenda([]);
                                                setItemPickerSalaDeVendas(undefined);
                                                setItemPickerArea(undefined);
                                                setItemPickerDeEmpresas(undefined);
                                                setItemPickerCentroDeCusto(undefined);
                                                setItemPickerControleDeSala(undefined);
                                                setItemPickerFunilDeVenda(undefined);
                                                setOptionCadastro("Adicionar permissão de funil de venda");
                                                setOptionFunction(() => () => {handleOpenDialogAtribuindoPermissaoDeFunilDeVenda()});
                                                setCollapse(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        {getOptionCadastro == 'Adicionar usuário' && <div style={{ width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll' }}>
                            <GridComponent dataSource={getListaDeUsuarios} width={"100%"} height={"100%"}
                                id='grid'
                                ref={g => grid = g}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='nome' headerText='Nome' textAlign='Left' />
                                    <ColumnDirective field='emailPrincipal' headerText='Email' textAlign='Left' />
                                    <ColumnDirective field='cargoDescricao' headerText='Cargo' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {

                                                let Response = await Pessoa.BuscaPersonalizada(ContextSignIn.getContext().token, props.id, "");

                                                if (Math.floor(Response.status / 100) == 2)
                                                {

                                                    setFormularioID(props.id);
                                                    setFormularioNome(props.nome);
                                                    setFormularioEmail(props.emailPrincipal);
                                                    setItemPickerCargos(props.cargo ?? undefined);
                                                    setFormularioCPF(Response.data.cpf ?? "");
    
                                                    handleOpenDrawerAdicionarUsuario();

                                                }

                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {

                                                setFormularioID(props.id);

                                                handleOpenDialogDeletarUsuario();

                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                        {getOptionCadastro == 'Adicionar permissão de empresa' && <div style={{ width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll', overflowY: 'scroll' }}>
                            <GridComponent dataSource={getListaDeUsuariosPermissaoDeEmpresa}  height={600}
                                id='grid'
                                ref={g => grid = g}
                                width={"100%"}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='nome' headerText='Nome' textAlign='Left' />
                                    <ColumnDirective field='emailPrincipal' headerText='Email' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {130} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {

                                                setIDPickerUsuarios(props.id);

                                                handleOpenDialogDeletarPermissaoEmpresa();

                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                        {getOptionCadastro == 'Adicionar permissão de centro de custo' && <div style={{ width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll', overflowY: 'scroll' }}>
                            <GridComponent dataSource={getListaDeUsuariosPermissaoDeCentroDeCusto} height={600}
                                id='grid'
                                ref={g => grid = g}
                                width={"100%"}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='nome' headerText='Nome' textAlign='Left' />
                                    <ColumnDirective field='emailPrincipal' headerText='Email' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {130} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {

                                                setIDPickerUsuarios(props.id);

                                                handleOpenDialogDeletarPermissaoCentroDeCusto();

                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                        {getOptionCadastro == 'Adicionar permissão de controle de sala' && <div style={{ width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll', overflowY: 'scroll' }}>
                            <GridComponent dataSource={getListaDeUsuariosPermissaoDeControleDeSala} height={600}
                                id='grid'
                                ref={g => grid = g}
                                width={"100%"}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='nome' headerText='Nome' textAlign='Left' />
                                    <ColumnDirective field='emailPrincipal' headerText='Email' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {130} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {

                                                setIDPickerUsuarios(props.id);

                                                handleOpenDialogDeletarPermissaoControleDeSala();

                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                        {getOptionCadastro == 'Adicionar permissão de funil de venda' && <div style={{ width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll', overflowY: 'scroll' }}>
                            <GridComponent dataSource={getListaDeUsuariosPermissaoDeFunilDeVenda} height={600}
                                id='grid'
                                ref={g => grid = g}
                                width={"100%"}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='nome' headerText='Nome' textAlign='Left' />
                                    <ColumnDirective field='emailPrincipal' headerText='Email' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {130} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {

                                                setIDPickerUsuarios(props.id);

                                                handleOpenDialogDeletarPermissaoFunilDeVenda();

                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                    </div>
                </div>}

                {getOptionDescription == "Adicionar empresa" && <div style = {{width: "100%", opacity: getEmConsulta == false ? 1 : 0.5, transition: "opacity 0.5s"}}>
                    <div style = {{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                        <div style={{ width: "100%", overflowX: 'scroll' }}>
                            <GridComponent dataSource={getListaDeEmpresas} width={"100%"} height={"100%"}
                                id='grid'
                                ref={g => grid = g}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='nomeFantasia' headerText='Nome da empresa' textAlign='Left'/>
                                    <ColumnDirective field='razaoSocial' headerText='Razão Social' textAlign='Left'/>
                                    <ColumnDirective field='cnpj' headerText='CNPJ' textAlign='Left'/>
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>   
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {
                                                setNomeDaEmpresa(props.descricao);
                                                setIdEmpresa(props.id);
                                                handleOpenDrawerAdicionarEmpresa()
                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = {async () => {
                                                setIdEmpresa(props.id);
                                                handleDialogDeletarEmpresaOpen()
                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>}

                {getOptionDescription == "Adicionar centro de custo" && <div style = {{width: "100%", opacity: getEmConsulta == false ? 1 : 0.5, transition: "opacity 0.5s"}}>
                    <div style = {{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                        <div style={{ width: "100%", overflowX: 'scroll' }}>
                            <GridComponent dataSource={getListaDeCentrosDeCusto} width={"100%"} height={"100%"}
                                id='grid'
                                ref={g => grid = g}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowGrouping={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                groupSettings={groupOptions}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='sigla' headerText='Sigla' textAlign='Left' width = {200} isPrimaryKey={true} />
                                    <ColumnDirective field='descricao' headerText='Descrição' textAlign='Left'/>
                                    <ColumnDirective field='empresaNome' headerText='Empresa' textAlign='Left'/>
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: Objeto.CentroDeCusto) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>   
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {
                                                setNomeCentroDeCusto(props.descricao);
                                                setIdCentroDeCusto(props.sigla);
                                                setSigla(props.sigla);
                                                setItemPickerEmpresas(props.empresa)
                                                setIdEmpresa(props.empresa.id);
                                                handleOpenDrawerAdicionarCentroDeCusto();
                                                setDisable(true);
                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = {async () => {
                                                setIdCentroDeCusto(props.sigla)
                                                setIdEmpresa(props.empresa.id);
                                                handleDialogDeletarCentroDeCustoOpen()
                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>}

                {(getOptionDescription == "Adicionar cadastros usuário software externo" || getOptionDescription == "Alterar cadastros usuário software externo") && <div style = {{width: "100%", opacity: getEmConsulta == false ? 1 : 0.5, transition: "opacity 0.5s"}}>
                    <div style = {{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                        <div style = {{backgroundColor: "#FFFFFF", width: "25%", display: "flex", flexDirection: "column", minHeight: "590px", alignItems: "center", padding: "20px", borderRight: "1px solid #00000020", borderTop: "1px solid #00000020"}}>   
                            <div style = {{display: "flex", alignItems: "center", borderBottom: "1px solid #00000020"}}>
                                <div 
                                    style = {{
                                        fontWeight: "bold", 
                                        fontSize: "18px",
                                        padding: "17px",
                                        width: "100%",
                                        textAlign: "center"
                                }}>Opções de cadastro</div>
                            </div>

                            {/** VINCULAR USUÁRIO */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar vinculo do usuário ao software externo" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => {
                                        setListaDeUsuariosPermissaoDeSoftwareExterno([]);
                                        setItemPickerSalaDeVendas(undefined);
                                        setItemPickerArea(undefined);
                                        setItemPickerDeEmpresas(undefined);
                                        setItemPickerCentroDeCusto(undefined);
                                        setItemPickerControleDeSala(undefined);
                                        setItemPickerFunilDeVenda(undefined);
                                        setOptionCadastro("Adicionar vinculo do usuário ao software externo");
                                        setOptionFunction(() => () => {handleOpenDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno()});
                                        setCollapse(true);
                                    }}>
                                    <Icon 
                                        icon = 'add'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Vincular usuário"}
                                            onClick = {() => {
                                                setListaDeUsuariosPermissaoDeSoftwareExterno([]);
                                                setItemPickerSalaDeVendas(undefined);
                                                setItemPickerArea(undefined);
                                                setItemPickerDeEmpresas(undefined);
                                                setItemPickerCentroDeCusto(undefined);
                                                setItemPickerControleDeSala(undefined);
                                                setItemPickerFunilDeVenda(undefined);
                                                setOptionCadastro("Adicionar vinculo do usuário ao software externo");
                                                setOptionFunction(() => () => {handleOpenDialogAtribuindoVinculoDoUsuarioAoSoftwareExterno()});
                                                setCollapse(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/** VINCULAR CENTRODECUSTO */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar vinculo do centro de custo ao software externo" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => {
                                        setListaDeUsuariosPermissaoDeCentroDeCusto([]);
                                        setItemPickerSalaDeVendas(undefined);
                                        setItemPickerArea(undefined);
                                        setItemPickerCentroDeCusto(undefined);
                                        setItemPickerControleDeSala(undefined);
                                        setItemPickerFunilDeVenda(undefined);
                                        setOptionCadastro("Adicionar vinculo do centro de custo ao software externo");
                                        setOptionFunction(() => () => {handleOpenDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno()});
                                        setCollapse(true);
                                    }}>
                                    <Icon 
                                        icon = 'numbered-list'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Vincular centro de custo"}
                                            onClick = {async () => {
                                                setListaDeUsuariosPermissaoDeCentroDeCusto([]);
                                                setItemPickerSalaDeVendas(undefined);
                                                setItemPickerArea(undefined);
                                                setItemPickerCentroDeCusto(undefined);
                                                setItemPickerControleDeSala(undefined);
                                                setItemPickerFunilDeVenda(undefined);
                                                setOptionCadastro("Adicionar vinculo do centro de custo ao software externo");
                                                setOptionFunction(() => () => {handleOpenDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno()});
                                                setCollapse(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        {getOptionCadastro == 'Adicionar vinculo do usuário ao software externo' && <div style={{ width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll', overflowY: 'scroll' }}>
                            <GridComponent 
                                dataSource = {getListaDeUsuariosPermissaoDeSoftwareExterno} 
                                width = {"100%"} 
                                height = {600}
                                id = {'grid'}
                                ref = {g => grid = g}
                                allowPaging = {true}
                                allowFiltering = {true}
                                allowReordering = {true}
                                allowSorting = {false}
                                showColumnMenu = {true}
                                sortSettings = {sortOptions}
                                filterSettings = {filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings = {{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale = {"pt"}
                                currencyCode = {"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='nome' headerText='Nome' textAlign='Left' />
                                    <ColumnDirective field='softwareExternoDescricao' headerText='Software externo' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {130} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {

                                                setIDUsuarioSoftwareExterno(props.id);

                                                handleOpenDialogDeletarVinculoDoUsuarioAoSoftwareExterno();

                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                        {getOptionCadastro == 'Adicionar vinculo do centro de custo ao software externo' && <div style={{ width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll', overflowY: 'scroll' }}>
                            <GridComponent 
                                dataSource={getListaCentroDeCustoVinculado}
                                id= {'grid'}
                                height={600}
                                ref={g => grid = g}
                                width={"100%"}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='centroDeCustoDescricao' headerText='CentroDeCusto' textAlign='Left' width = {220} isPrimaryKey={true} />
                                    <ColumnDirective field='centroDeCustoVinculadoDescricao' headerText='Centro de custo vinculado' textAlign='Left' width = {220} />
                                    <ColumnDirective field='empresaDescricao' headerText='Empresa' textAlign='Left' width = {220} />
                                    <ColumnDirective field='empresaVinculadaDescricao' headerText='Empresa vinculada' textAlign='Left' width = {220} />
                                    <ColumnDirective field='softwareExternoDescricao' headerText='Software externo' textAlign='Left' width = {220} />
                                    <ColumnDirective headerText='' textAlign='Left' width = {130} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {

                                                setItemCentroDeCustoVinculado(props);

                                                handleOpenDialogAtribuindoVinculoDoUsuarioAoCentroDeCustoNoSoftwareExterno();

                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                    </div>
                </div>}

                {(getOptionDescription == "Adicionar cadastros cargo") && <div style = {{width: "100%", opacity: getEmConsulta == false ? 1 : 0.5, transition: "opacity 0.5s"}}>
                    <div style = {{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                        <div style = {{backgroundColor: "#FFFFFF", width: "25%", display: "flex", flexDirection: "column", minHeight: "590px", alignItems: "center", padding: "20px", borderRight: "1px solid #00000020", borderTop: "1px solid #00000020"}}>   
                            <div style = {{display: "flex", alignItems: "center", borderBottom: "1px solid #00000020"}}>
                                <div 
                                    style = {{
                                        fontWeight: "bold", 
                                        fontSize: "18px",
                                        padding: "17px",
                                        width: "100%",
                                        textAlign: "center"
                                }}>Opções de cadastro</div>
                            </div>

                            {/** ADICIONAR CARGO  */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar cargo" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => {
                                        setOptionCadastro("Adicionar cargo");
                                        setOptionFunction(() => () => {
                                            setIDCargos(0);
                                            setDescricaoCargos("");
                                            handleOpenDrawerAdicionarCargos();
                                        });
                                        setEmConsulta(true);
            
                                        let Response = await Cargo.Get(ContextSignIn.getContext().token)
                                        if(Math.floor(Response.status / 100) == 2)
                                        {
            
                                            let ListaDeCargos = [] as Array<{id: number, nome: string}>
            
                                            Response.data.map((item, index) => {
                                                ListaDeCargos.push({
                                                    id: item.id,
                                                    nome: item.nome,
                                                })
                                            })
                                            setListaDeCargos(ListaDeCargos);
                                            setEmConsulta(false);
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de cargos", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            setEmConsulta(false);
                                        }
                                    }}>
                                    <Icon 
                                        icon = 'add'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Adicionar cargo"}
                                        />
                                    </div>
                                </div>
                            </div>
            
                            {/** VINCULAR CARGO  */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar vinculo" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = {async () => {
                                        setListaDeUsuarios([]);
                                        setOptionCadastro("Adicionar vinculo");
                                        setOptionFunction(() => () => {
                                            setItemPickerUsuarios(undefined);
                                            setItemPickerCargos(undefined);
                                            setItensSelecionadosPickerCargos([]);
                                            handleOpenDialogVincularUsuarioAoCargo()
                                        });
                                        setEmConsulta(true);

                                        let Response = await Logon.Usuarios(ContextSignIn.getContext().token, "")
                                        if(Math.floor(Response.status / 100) == 2)
                                        {

                                            let ListaDeUsuarios = [] as Array<{id: number, nome: string, emailPrincipal: string, cargoDescricao: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>

                                            Response.data.map((item, index) => {
                                                ListaDeUsuarios.push({
                                                    id: item.id,
                                                    nome: item.nome,
                                                    emailPrincipal: item.email.descricao,
                                                    email: item.email,
                                                    cargoDescricao: item.cargo != null ? item.cargo.nome : "Sem cargo",
                                                    cargo: item.cargo
                                                })
                                            })

                                            setListaDeUsuarios(ListaDeUsuarios);
                                            setEmConsulta(false);
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de usuários", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            setEmConsulta(false);
                                        }
                                    }}>
                                    <Icon 
                                        icon = 'annotation'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "calc(20vw + 7px)",
                                        }}
                                        onClick = {async () => { 
                                            setListaDeUsuarios([]);
                                            setOptionCadastro("Adicionar vinculo");
                                            setOptionFunction(() => () => {
                                                setItemPickerUsuarios(undefined);
                                                setItemPickerCargos(undefined);
                                                setItensSelecionadosPickerCargos([]);
                                                handleOpenDialogVincularUsuarioAoCargo()
                                            });
                                            setEmConsulta(true);
    
                                            let Response = await Logon.Usuarios(ContextSignIn.getContext().token, "")
                                            if(Math.floor(Response.status / 100) == 2)
                                            {

                                                console.log(Response.data);
    
                                                let ListaDeUsuarios = [] as Array<{id: number, nome: string, emailPrincipal: string, cargoDescricao: string, email: {classificacao: number, descricao: string}, cargo: {id: number, nome: string}}>

                                                Response.data.map((item, index) => {
                                                    ListaDeUsuarios.push({
                                                        id: item.id,
                                                        nome: item.nome,
                                                        emailPrincipal: item.email.descricao,
                                                        email: item.email,
                                                        cargoDescricao: item.cargo != null ? item.cargo.nome : "Sem cargo",
                                                        cargo: item.cargo
                                                    })
                                                })
    
                                                setListaDeUsuarios(ListaDeUsuarios);
                                                setEmConsulta(false);
                                            }
                                            else
                                            {
                                                NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de usuários", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                setEmConsulta(false);
                                            }
                                        }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Vincular cargo"}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        {getOptionCadastro == "Adicionar cargo" && <div style={{width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll' }}>
                            <GridComponent dataSource={getListaDeCargos} width={"100%"} height={"100%"}
                                id='grid'
                                ref={g => grid = g}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='nome' headerText='Nome' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {
                                                setIDCargos(props.id);
                                                setDescricaoCargos(props.nome);

                                                handleOpenDrawerAdicionarCargos();
                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {
                                                setIDCargos(props.id);
                                                setDescricaoCargos(props.nome);

                                                handleOpenDialogDeletarCargo();
                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                        {getOptionCadastro == "Adicionar vinculo" && <div style={{width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll' }}>
                            <GridComponent dataSource={getListaDeUsuarios} width={"100%"} height={"100%"}
                                id = {'grid'}
                                ref = {g => grid = g}
                                allowPaging = {true}
                                allowFiltering = {true}
                                allowReordering = {true}
                                allowSorting = {false}
                                allowGrouping = {true}
                                showColumnMenu = {true}
                                sortSettings = {sortOptions}
                                filterSettings = {filterOptions}
                                groupSettings = {groupOptionsVinculosCargos}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings = {{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='nome' headerText='Nome' textAlign='Left' />
                                    <ColumnDirective field='emailPrincipal' headerText='Email' textAlign='Left' />
                                    <ColumnDirective field='cargoDescricao' headerText='Cargo' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {

                                                setVinculandoCargo(false);

                                                let ListaDeUsuarios = [...getPickerListaDeUsuarios];

                                                let Usuario = ListaDeUsuarios.filter(item => item.id == props.id);

                                                setFormularioID(props.id);
                                                setItemPickerUsuarios(Usuario[0]);
                                                setItemPickerCargos(props.cargo ?? undefined);
                                                setItensSelecionadosPickerCargos(props.cargo ? [props.cargo] : []);
                                                
                                                handleOpenDialogVincularUsuarioAoCargo();

                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {

                                                setVinculandoCargo(false);

                                                setFormularioID(props.id);
                                                setItemPickerCargos(props.cargo ?? undefined);
                                                setItensSelecionadosPickerCargos(props.cargo ? [props.cargo] : []);

                                                handleOpenDialogDesvincularUsuarioAoCargo();

                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                    </div>
                </div>}

                {(getOptionDescription == "Adicionar fornecedor" || getOptionDescription == "Alterar fornecedor") && <div style = {{width: "100%", opacity: getEmConsulta == false ? 1 : 0.5, transition: "opacity 0.5s"}}>
                    <div style = {{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                        <div style={{ width: "100%", overflowX: 'scroll' }}>
                            <GridComponent dataSource={getListaPickerFornecedor} width={"100%"} height={"100%"}
                                id='grid'
                                ref={g => grid = g}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='cpfcnpj' headerText='CPF/CNPJ' textAlign='Left' />
                                    <ColumnDirective field='nomeRazaoSocial' headerText='Nome ou razão social' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {
                                                setIDFornecedor(props.id)
                                                setCPFCNPJFornecedor(props.cpfcnpj)
                                                setNomeRazaoSocial(props.nomeRazaoSocial)
                                                setCEPFornecedor(props.endereco.cep ?? "");
                                                setLogradouroFornecedor(props.endereco.logradouro ?? "");
                                                setNumeroFornecedor(props.endereco.numero ?? "");
                                                setComplementoFornecedor(props.endereco.complemento ?? '');
                                                setBairroFornecedor(props.endereco.bairro ?? "");
                                                setCidadeFornecedor(props.endereco.cidade ?? "");
                                                setUFFornecedor(props.endereco.uf ?? "");
                                                
                                                try {
                                                    let TelefoneFornecedor = props.telefones.find((Item: any) => Item.classificacao == 2)
                                                    setTelefoneFornecedor(Telefone.FormatarTexto((TelefoneFornecedor?.ddd ?? "") + (TelefoneFornecedor?.numero ?? "")))
                                                }
                                                catch { }

                                                handleOpenDrawerAdicionarFornecedor();
                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => { 
                                                setIDFornecedor(props.id)
                                                setCPFCNPJFornecedor(props.cpfcnpj)
                                                setNomeRazaoSocial(props.nomeRazaoSocial)
                                                setCEPFornecedor(props.endereco.cep ?? "");
                                                setLogradouroFornecedor(props.endereco.logradouro ?? "");
                                                setNumeroFornecedor(props.endereco.numero ?? "");
                                                setComplementoFornecedor(props.endereco.complemento ?? '');
                                                setBairroFornecedor(props.endereco.bairro ?? "");
                                                setCidadeFornecedor(props.endereco.cidade ?? "");
                                                setUFFornecedor(props.endereco.uf ?? "");
                                                
                                                try {
                                                    let TelefoneFornecedor = props.telefones.find((Item: any) => Item.classificacao == 2)
                                                    setTelefoneFornecedor(Telefone.FormatarTexto((TelefoneFornecedor?.ddd ?? "") + (TelefoneFornecedor?.numero ?? "")))
                                                }
                                                catch { }

                                                handleOpenDialogDeletarFornecedor();
                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>}

                {(getOptionDescription == "Adicionar cadastros") && <div style = {{width: "100%", transition: "opacity 0.5s"}}>
                    <div style = {{display: 'flex', flexDirection: 'row', width: "100%", overflowX: 'scroll'}}>
                        <div style = {{backgroundColor: "#FFFFFF", width: "25%", display: "flex", flexDirection: "column", minHeight: "590px", alignItems: "center", padding: "20px", borderRight: "1px solid #00000020", borderTop: "1px solid #00000020"}}>   
                            <div style = {{display: "flex", alignItems: "center", borderBottom: "1px solid #00000020"}}>
                                <div 
                                    style = {{
                                        fontWeight: "bold", 
                                        fontSize: "18px",
                                        padding: "17px",
                                        width: "100%",
                                        textAlign: "center"
                                }}>Opções de cadastro</div>
                            </div>

                            {/** ADICIONAR BRINDE  */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar brinde" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => {
                                       setListaNomesDeBrindes([]);
                                       setOptionCadastro("Adicionar brinde");
                                       setOptionFunction(() => () => {
                                           setIDNomeDoBrinde(0);
                                           setNomeDoBrinde("");
                                           handleOpenDrawerAdicionarNomeDoBrinde()
                                       });
                                       setEmConsulta(true);
           
                                       let Response = await Brinde.Get(ContextSignIn.getContext().token)
                                       if(Math.floor(Response.status / 100) == 2)
                                       {
           
                                           let ListaDeNomesDosBrindes = [] as Array<{id: number, descricao: string}>
           
                                           Response.data.map((item) => {
                                            ListaDeNomesDosBrindes.push({
                                                   id: item.id,
                                                   descricao: item.descricao
                                               })
                                           })
           
                                           setListaNomesDeBrindes(ListaDeNomesDosBrindes);
                                           setEmConsulta(false);
                                       }
                                       else
                                       {
                                           NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar os nomes dos brindes", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                           setEmConsulta(false);
                                       }
                                    }}>
                                    <Icon 
                                        icon = 'add'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Cadastro geral"}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            {/** ADICIONAR TIPO  */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar tipo" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => { 
                                        setListaTiposDeBrindes([]);
                                        setOptionCadastro("Adicionar tipo");
                                        setOptionFunction(() => () => {
                                            setIDTipoDeBrinde(0);
                                            setNomeTipoDoBrinde("");
                                            handleOpenDrawerAdicionarNomeTipoDoBrinde()
                                        });
                                        setEmConsulta(true);
           
                                        let Response = await Brinde.TiposDeBrinde(ContextSignIn.getContext().token)
                                        if(Math.floor(Response.status / 100) == 2)
                                        {
            
                                            let ListaDeTiposDeBrinde = [] as Array<{id: number, descricao: string}>
            
                                            Response.data.map((item) => {
                                                ListaDeTiposDeBrinde.push({
                                                    id: item.id,
                                                    descricao: item.descricao
                                                })
                                            })
            
                                            setListaTiposDeBrindes(ListaDeTiposDeBrinde);
                                            setEmConsulta(false);
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar os tipos de brindes", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            setEmConsulta(false);
                                        }
                                    }}>
                                    <Icon 
                                        icon = 'annotation'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Tipos"}
                                            onClick = {() => { handleCloseDrawerAdicionarNomeTipoDoBrinde() }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/** ADICIONAR REGISTRO  */}
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <div
                                    style = {{
                                        borderBottom: '1px solid #00000020',
                                        borderTop: '1px solid #00000020', 
                                        borderLeft: '1px solid #00000020', 
                                        borderRight: '1px solid #00000020',
                                        borderBottomLeftRadius: "5px", 
                                        borderBottomRightRadius: "5px", 
                                        borderTopLeftRadius: "5px", 
                                        borderTopRightRadius: "5px",
                                        backgroundColor: getOptionCadastro == "Adicionar registro" ? lighten(0.15, "#1098F730") : "#FFFFFF",
                                        height: "42px",
                                        marginTop: "10px", 
                                        display: "flex", 
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "calc(20vw + 7px)",
                                        cursor: "pointer"
                                    }}
                                    onClick = { async () => {
                                        setListaDoAlmoxarifado([]);
                                        setOptionCadastro("Adicionar registro");
                                        setOptionFunction(() => () => {
                                            setIDBrinde(0);
                                            handleOpenDrawerAdicionarBrinde()
                                        });
                                        setEmConsulta(true);

                                        await setListaNomesDeBrindes(await (await Brinde.Get(ContextSignIn.getContext().token)).data ?? []);
                                        
                                        await setListaTiposDeBrindes(await (await Brinde.TiposDeBrinde(ContextSignIn.getContext().token)).data ?? []);

                                        await setListaPickerFornecedor(await (await Fornecedor.Get(ContextSignIn.getContext().token)).data ?? []);
           
                                        let Response = await Brinde.Almoxarifado(ContextSignIn.getContext().token)
                                        if(Math.floor(Response.status / 100) == 2)
                                        {
            
                                            let ListaDeAlmoxarifado = [] as Array<{ id: number, descricao: string | undefined, tipoDescricao: string | undefined, quantidade: number, valorUnitario: number, fornecedorDescricao: string | undefined, tipo: {id: number, descricao: string} | undefined, fornecedor: Objeto.Fornecedor | undefined, validadeInicial: Date | undefined | null | string, validadeFinal: Date | undefined | null | string}>
            
                                            Response.data.map(item => {
                                                ListaDeAlmoxarifado.push({
                                                    id: item.brinde.id,
                                                    descricao: item.brinde.descricao,
                                                    tipoDescricao: item.tipo.descricao,
                                                    tipo: item.tipo,
                                                    fornecedorDescricao: item.fornecedor.nomeRazaoSocial,
                                                    fornecedor: item.fornecedor,
                                                    validadeInicial: moment(item.validadeInicial, true).format('DD/MM/YYYY'),
                                                    validadeFinal: moment(item.validadeFinal, true).format('DD/MM/YYYY'),
                                                    quantidade: item.quantidade,
                                                    valorUnitario: item.valorUnitario
                                                })
                                            });

                                            setListaDoAlmoxarifado(ListaDeAlmoxarifado);
                                            setEmConsulta(false);
                                        }
                                        else
                                        {
                                            NotificacaoInterna.ExibirNotificacao("Erro ao tentar puxar a lista de brindes", "Entre em contato com a equipe de desenvolvimento.", NotificacaoInterna.TipoDeNotificacao.Erro);
                                            setEmConsulta(false);
                                        }
                                    }}>
                                    <Icon 
                                        icon = 'inherited-group'
                                        iconSize = {15} 
                                        color = {"#000"} 
                                        style = {{cursor: "pointer", marginLeft: "15px"}}
                                    />
                                    <div
                                        style = {{
                                            display: "flex",
                                            alignItems: "center", 
                                            width: "100%"
                                    }}>
                                        <Button
                                            style = {{
                                                outline: "none",
                                                boxShadow: "none", 
                                                border: "none", 
                                                verticalAlign: "center", 
                                                fontSize: "12px",
                                                background: "0",
                                                borderRadius: "5px",
                                                display: "flex", 
                                                alignItems: "center",
                                                height: "15px",
                                            }}
                                            text = {"Qtde, valores e fornecedores"}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        {getOptionCadastro == "Adicionar brinde" && <div style={{ width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll' }}>
                            <GridComponent 
                                id='grid'
                                ref={g => grid = g}
                                dataSource={getListaDeNomesDeBrindes} 
                                width={"100%"} 
                                height={"100%"}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}>
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='descricao' headerText='Descricao' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {
                                                setNomeDoBrinde(props.descricao);
                                                setIDNomeDoBrinde(props.id);
                                                handleOpenDrawerAdicionarNomeDoBrinde();
                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {
                                                setIDNomeDoBrinde(props.id);
                                                setNomeDoBrinde(props.descricao);
                                                handleOpenDialogDeletarNomeDoBrinde();
                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                        {getOptionCadastro == "Adicionar tipo" && <div style={{ width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll' }}>
                            <GridComponent 
                                id='grid'
                                ref={g => grid = g}
                                dataSource={getListaDeTiposDeBrindes} 
                                width={"100%"} 
                                height={"100%"}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}>
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='descricao' headerText='Descricao' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {
                                                setNomeTipoDoBrinde(props.descricao);
                                                setIDTipoDeBrinde(props.id);
                                                handleOpenDrawerAdicionarNomeTipoDoBrinde();
                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {
                                                setIDTipoDeBrinde(props.id)
                                                setNomeTipoDoBrinde(props.descricao);
                                                handleOpenDialogDeletarTipoDeBrinde();
                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                        {getOptionCadastro == "Adicionar registro" && <div style={{ width: "75%", opacity: getEmConsulta == false ? 1 : 0.5, overflowX: 'scroll' }}>
                            <GridComponent 
                                id='grid'
                                ref={g => grid = g}
                                dataSource={getListaDoAlmoxarifado} 
                                width={"100%"} 
                                height={"100%"}
                                allowPaging={true}
                                allowFiltering={true}
                                allowReordering={true}
                                allowSorting={false}
                                showColumnMenu={true}
                                sortSettings={sortOptions}
                                filterSettings={filterOptions}
                                actionBegin={                                    
                                    (args: any) => {
                                        if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin') {
                                            args.filterChoiceCount = 100000;
                                        }
                                }}
                                pageSettings={{ pageSizes: [20, 50, 100], pageSize: 20 }}
                                locale={"pt"}
                                currencyCode={"BRL"}>
                                <ColumnsDirective>
                                    <ColumnDirective field='id' headerText='ID' textAlign='Left' width = {100} isPrimaryKey={true} />
                                    <ColumnDirective field='descricao' headerText='Descricao' textAlign='Left' />
                                    <ColumnDirective field='quantidade' headerText='Quantidade' textAlign='Left' />
                                    <ColumnDirective field='valorUnitario' headerText='Valor' textAlign='Left' format = 'C2' />
                                    <ColumnDirective field='tipoDescricao' headerText='Tipo' textAlign='Left' />
                                    <ColumnDirective field='fornecedorDescricao' headerText='Fornecedor' textAlign='Left' />
                                    <ColumnDirective field='validadeInicial' headerText='Validade inicial' textAlign='Left' />
                                    <ColumnDirective field='validadeFinal' headerText='Validade final' textAlign='Left' />
                                    <ColumnDirective headerText='' textAlign='Left' width = {220} template = {(props: any) => <div style = {{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button
                                            icon = {<Icon icon = "edit" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                background: lighten(0.1, "#D74E09"), 
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            text = {'Alterar'}
                                            onClick = { async () => {

                                                setIDBrinde(props.id);
                                                setItemPickerNomesDeBrindes({id: props.id, descricao: props.descricao});
                                                setItemPickerTiposDeBrindes(props.tipo);
                                                setQtdeBrinde(props.quantidade);
                                                setValorBrinde(props.valorUnitario);
                                                setPrazoValidadeInicioBrinde(moment(props.validadeInicial, 'DD/MM/YYYY', true).toDate());
                                                setPrazoValidadeTerminoBrinde(moment(props.validadeFinal, 'DD/MM/YYYY', true).toDate());
                                                setItemPickerFornecedorBrinde(props.fornecedor);

                                                setListaNomesDeBrindes(await (await Brinde.Get(ContextSignIn.getContext().token)).data ?? []);
                                                setListaTiposDeBrindes(await (await Brinde.TiposDeBrinde(ContextSignIn.getContext().token)).data ?? []);
                                                setListaPickerFornecedor(await (await Fornecedor.Get(ContextSignIn.getContext().token)).data ?? []);

                                                handleOpenDrawerAdicionarBrinde();
                                            }}
                                        />
                                        <Button
                                            icon = {<Icon icon = "remove" iconSize = {15} color = "#FFFFFF"/>}
                                            style = {{
                                                color: "#FFFFFF",
                                                border: "none", 
                                                boxShadow: "none", 
                                                borderRadius: "5px",
                                                outline: "none", 
                                                display: "flex", 
                                                alignItems: "center", 
                                                justifyContent: 'flex-end',
                                                fontSize: "12px", 
                                                height: "15px",
                                                marginRight: "10px"
                                            }}
                                            intent = {"danger"}
                                            text = {'Deletar'}
                                            onClick = { async () => {
                                                setIDBrinde(props.id);
                                                setItemPickerNomesDeBrindes({id: props.id, descricao: props.descricao});
                                                setItemPickerTiposDeBrindes(props.tipo);
                                                setQtdeBrinde(props.quantidade);
                                                setValorBrinde(props.valorUnitario);
                                                setPrazoValidadeInicioBrinde(props.validadeInicial);
                                                setPrazoValidadeTerminoBrinde(props.validadeFinal);
                                                setItemPickerFornecedorBrinde(props.fornecedor);
                                                handleOpenDialogDeletarBrinde();
                                            }}
                                        />
                                    </div>}/>
                                </ColumnsDirective>
                                <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, ColumnMenu, Resize]} />
                            </GridComponent>
                        </div>}
                    </div>
                </div>}

            </Card>}

        </Component.Container>
    );
    /* #endregion */
};

export default View;