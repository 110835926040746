import styled from 'styled-components';

export const Container = styled.div`
    display:block;
    height:100%;
    overflow:auto;
    width:100%;
    min-width:450px;
    background-color:#EDEFF5;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius:0px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .endereco {
        display:flex;
        align-items:center;
        background-color:0;
        height:30px;
        img {
            width:20px;
            height:20px;
        }
        .bar {
            font-size:18px;
            color:#bdbdbd;
            margin:0 15px;
        }
        .place {
            color:#175372;
        }
    }
    .selector {
    opacity: 0.7;
    transition: opacity 0.2s;
    }
    .selector:hover {
    opacity: 0.9;
    }
`;
export const Content = styled.body`
    width: 100%;
    margin: 0 auto;
    display: flex;
    margin-top: 10px;
    padding-left: 13vw;
    align-items: center;

    svg {
        margin-left: 16px;
        margin-right: 16px;
        width: 30px;
        height: 30px;
    }
`;
export const Title = styled.div`
    
    
    display: flex;
    align-items: center;

    svg {
    
        margin-right: 10px;
        width: 25px;
        height: 25px;
    }
`;
export const ContentCard = styled.div`
    background-color:0;
    flex-direction: column;
    margin: 0 auto 20px auto;
    background-color:0;
    display: flex;
    align-items: left;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15);
    border-radius: 5px;
    padding-left: 0px;
    & + div {
        padding:0 3%;
    }

    label {
        display: flex;
        padding-right: 16px;
    }


`;
export const ContentFormHeader = styled.div`
    width:100%;
    margin-top: 16px;
    padding-top: 5px;

    flex-direction: column;

    display: flex;
    align-items: left;

    border-radius: 2px;
`;
export const FormContent = styled.div`
    display: flex;
    align-items: center;
    width:100%;
    border-radius: 2px;
`;
export const ButtonModal = styled.button`
`;
export const TextInformation = styled.text`
`;
export const CardInformation = styled.div`
    margin-bottom: 15px;
    font-family: Aktifo A;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #262825;
    align-self: flex-start;
`;
export const CardInformationHeader = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
`;
export const CardTitleInformation = styled.div`
    margin-bottom: 15px;
    font-family: Aktifo A;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #677367;
    align-self: flex-start;
    margin-vertical: 4px;
`;
export const AlertButton = styled.div`
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    padding: 5px;
    width: 30px;
    color: #FFFFFF;
    border-radius: 20px;
    font-weight: bold;
`;