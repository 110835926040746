import moment from "moment";
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { Button, Card, FormGroup, Elevation, H2, InputGroup, Icon, MenuItem, ButtonGroup, Collapse, Divider } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs/datetime";
import { Select, MultiSelect } from "@blueprintjs/select";
import "moment/locale/pt-br"
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

import { IconeHome } from "../../../Assets";
import { SignIn as ContextSignIn } from '../../../Context';
import History from '../../../Routers';
import { CentroDeCusto, D4sign, Venda } from '../../../Services/Controllers';
import { Moeda } from '../../../Themes/TextFormat';
import { Objeto } from '../../../Services/Models';
import { NotificacaoInterna } from '../../../Services/Notification';
import * as LottieFiles from '../../../Data/Lottie';

import * as Component from './style';
import viewModel from './viewModel';
import './style.css';

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const PropriedadesDeCalendario = {
        months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    };
    const [getTextoProcurado, setTextoProcurado] = useState<string>("");
    const [getDataInicialMinima, setDataInicialMinima] = useState<Date>(new Date());
    const [getDataInicialProcurada, setDataInicialProcurada] = useState<Date | null>(new Date());
    const [getFormularioExibirCalendarioDataInicialProcurada, setFormularioExibirCalendarioDataInicialProcurada] = useState<boolean>(false);
    const [getDataFinalMaxima, setDataFinalMaxima] = useState<Date>(new Date());
    const [getDataFinalProcurada, setDataFinalProcurada] = useState<Date | null>(new Date());
    const [getFormularioExibirCalendarioDataFinalProcurada, setFormularioExibirCalendarioDataFinalProcurada] = useState<boolean>(false);
    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();
    const [getListaSelecionadaPickerSala, setListaSelecionadaPickerSala] = useState<Array<{ id: string, descricao: string }>>([]);
    const [getListaPickerSala, setListaPickerSala] = useState<Array<{ id: string, descricao: string }>>([]);
    const PickerSala = MultiSelect.ofType<{ id: string, descricao: string }>();
    const [getListaSelecionadaPickerCompradorNome, setListaSelecionadaPickerCompradorNome] = useState<Array<{ id: string, descricao: string }>>([]);
    const [getListaPickerCompradorNome, setListaPickerCompradorNome] = useState<Array<{ id: string, descricao: string }>>([]);
    const PickerCompradorNome = MultiSelect.ofType<{ id: string, descricao: string }>();
    const [getListaSelecionadaPickerIdentificador, setListaSelecionadaPickerIdentificador] = useState<Array<{ id: string, descricao: string }>>([]);
    const [getListaPickerIdentificador, setListaPickerIdentificador] = useState<Array<{ id: string, descricao: string }>>([]);
    const PickerIdentificador = MultiSelect.ofType<{ id: string, descricao: string }>();
    const [getListaSelecionadaPickerRespCadastro, setListaSelecionadaPickerRespCadastro] = useState<Array<{ id: string, descricao: string }>>([]);
    const [getListaPickerRespCadastro, setListaPickerRespCadastro] = useState<Array<{ id: string, descricao: string }>>([]);
    const PickerRespCadastro = MultiSelect.ofType<{ id: string, descricao: string }>();
    const [getListaDeContratosEmConsulta, setListaDeContratosEmConsulta] = useState<boolean | undefined>(undefined);
    const [getFormularioListaDeContratos, setFormularioListaDeContratos] = useState<Array<Objeto.PropostaDeVenda>>([]);
    const [getFormularioListaDeContratosExibidos, setFormularioListaDeContratosExibidos] = useState<Array<Objeto.PropostaDeVenda>>([]);
    const [getFormularioListaDeContratosFiltrados, setFormularioListaDeContratosFiltrados] = useState<Array<Objeto.PropostaDeVenda>>([]);
    const [getCarregandoListaDeContratos, setCarregandoListaDeContratos] = useState<boolean>(false);
    const [getEnviandoContratoParaAssinatura, setEnviandoContratoParaAssinatura] = useState<boolean>(false);
    const [getCollapse, setCollapse] = useState(false);
    const QtdeDeContratosExibidos = 20;
    /* #endregion */

    /* #region  Funções  */
    /** Função de atualização da lista de contratos */
    async function filtrandoContratos(Texto: string): Promise<void> {
        if (!getItemPickerCentroDeCusto) {
            return;
        }

        await setListaDeContratosEmConsulta(true);
        await setCarregandoListaDeContratos(true);

        let contratos = getFormularioListaDeContratos;
        var Response = (await Venda.MeusContratos(ContextSignIn.getContext().token, getItemPickerCentroDeCusto?.empresa.id ?? 0, getItemPickerCentroDeCusto?.sigla ?? "", getDataInicialProcurada ?? new Date(1900, 1, 1), getDataFinalProcurada ?? new Date())).data;
            contratos = Response.dados;
            await setListaPickerSala(Response.filtros.filter((Item) => Item.nome == "Sala")[0].regraGeral.opcoes);
            await setListaPickerCompradorNome(Response.filtros.filter((Item) => Item.nome == "Cliente")[0].regraGeral.opcoes);
            await setListaPickerIdentificador(Response.filtros.filter((Item) => Item.nome == "Unidade")[0].regraGeral.opcoes);
            await setListaPickerRespCadastro(Response.filtros.filter((Item) => Item.nome == "Resp. pelo cadastro")[0].regraGeral.opcoes);

        let contratosFiltrados = contratos.filter((item) =>
            (getListaSelecionadaPickerSala.length == 0 || getListaSelecionadaPickerSala.filter((_item) => _item.descricao == item.salaDeVenda).length > 0)
            && (getListaSelecionadaPickerCompradorNome.length == 0 || getListaSelecionadaPickerCompradorNome.filter((_item) => _item.descricao == item.prospects[0].nome).length > 0)
            && (getListaSelecionadaPickerIdentificador.length == 0 || getListaSelecionadaPickerIdentificador.filter((_item) => _item.descricao == item.identificador.subLocal.descricao).length > 0)
            && (getListaSelecionadaPickerRespCadastro.length == 0 || getListaSelecionadaPickerRespCadastro.filter((_item) => _item.descricao == item.respCadastroNome).length > 0)
            && (getDataInicialProcurada == null || new Date(item.dataDaVenda) >= new Date(getDataInicialProcurada.getFullYear(), getDataInicialProcurada.getMonth(), getDataInicialProcurada.getDate()))
            && (getDataFinalProcurada == null || new Date(item.dataDaVenda) <= new Date(getDataFinalProcurada.getFullYear(), getDataFinalProcurada.getMonth(), getDataFinalProcurada.getDate()))
        );

        await setFormularioListaDeContratosExibidos((contratosFiltrados.length - getFormularioListaDeContratosExibidos.length >= 100) ? contratosFiltrados.slice(0, getFormularioListaDeContratosExibidos.length + QtdeDeContratosExibidos) : contratosFiltrados);
        await setFormularioListaDeContratosFiltrados(contratosFiltrados);
        await setFormularioListaDeContratos(contratos);

        await setListaDeContratosEmConsulta(false);
        await setCarregandoListaDeContratos(false);
    };
    /** Função de atualização da lista de unidades */
    useEffect(() => {
        async function componentDidMount() {
            try {
                await setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data);
            }
            catch { }
        }
        componentDidMount();
    }, []);
    return (
        <Component.Container style={{ overflow: "auto" }} onScroll={async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
            if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) {
                await setCarregandoListaDeContratos(true);
                let contratosFiltrados = getFormularioListaDeContratosFiltrados;
                await setFormularioListaDeContratosExibidos((getFormularioListaDeContratosFiltrados.length - getFormularioListaDeContratosExibidos.length >= 100) ? contratosFiltrados.slice(0, getFormularioListaDeContratosExibidos.length + QtdeDeContratosExibidos) : contratosFiltrados);
                await setCarregandoListaDeContratos(false);
            }
        }}>
            <Collapse isOpen={getCollapse}>
                <div style={{ display: "flex", height: "70px", alignItems: "center", justifyContent: "space-between", background: "0" }}>
                    <div className="endereco" style={{ minWidth: "130px", marginLeft: "1.5%" }}>
                        <img src={IconeHome} style={{ cursor: "pointer" }} alt="" onClick={() => { History.goBack(); }} />
                        <div className="bar">/</div>
                        <div className="place">Contratos</div>
                    </div>
                    <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                    {getListaPickerCentroDeCusto.length == 0 && <Icon icon="geosearch" style={{ marginRight: "10px", marginLeft: "-10px" }} />}
                    <PickerCentroDeCusto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerCentroDeCusto} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                        itemRenderer={(Item: Objeto.CentroDeCusto) => <MenuItem onClick={async () => {
                            await setItemPickerCentroDeCusto(Item);
                            await setFormularioListaDeContratos([]);
                            await setListaPickerSala([]);
                            await setListaPickerCompradorNome([]);
                            await setListaPickerIdentificador([]);
                            await setListaPickerRespCadastro([]);
                        }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: Objeto.CentroDeCusto) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                        <Button style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerCentroDeCusto?.descricao.substring(0, 10).concat("...") ?? "Empreendimento"} rightIcon="double-caret-vertical" />
                    </PickerCentroDeCusto>
                    {getItemPickerCentroDeCusto && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {
                        await setItemPickerCentroDeCusto(undefined);
                        await setFormularioListaDeContratos([]);
                        await setFormularioListaDeContratosFiltrados([]);
                        await setFormularioListaDeContratosExibidos([]);
                    }} />}
                    <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                    <div style={{ display: "flex", background: "0", alignItems: "center", width: "60%", minWidth: "793px" }}>
                        {(getFormularioListaDeContratos.length > 0 && <>
                            <div style={{ width: "150px", marginRight: "1%" }}>
                                <PickerSala popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true }} items={getListaPickerSala} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    fill={true} tagRenderer={(Item: { id: string, descricao: string }) => Item.descricao} selectedItems={getListaSelecionadaPickerSala} placeholder="Sala"
                                    tagInputProps={{
                                        onRemove: async (value: React.ReactNode, index: number) => { await setListaSelecionadaPickerSala(getListaSelecionadaPickerSala.filter((Item, Index) => Index !== index)); },
                                        rightElement: <Button icon="cross" minimal={true} onClick={async () => { await setListaSelecionadaPickerSala([]) }} />
                                    }}
                                    itemRenderer={(Item: { id: string, descricao: string }) => <MenuItem onClick={async () => {
                                        var ListaSelecionada = [...getListaSelecionadaPickerSala];
                                        ListaSelecionada.push(Item);
                                        await setListaSelecionadaPickerSala(ListaSelecionada);
                                    }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: { id: string, descricao: string }) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                </PickerSala>
                            </div>
                            <div style={{ width: "150px", marginRight: "1%" }}>
                                <PickerCompradorNome popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true }} items={getListaPickerCompradorNome} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    fill={true} tagRenderer={(Item: { id: string, descricao: string }) => Item.descricao} selectedItems={getListaSelecionadaPickerCompradorNome} placeholder="Cliente"
                                    tagInputProps={{
                                        onRemove: async (value: React.ReactNode, index: number) => { await setListaSelecionadaPickerCompradorNome(getListaSelecionadaPickerCompradorNome.filter((Item, Index) => Index !== index)); },
                                        rightElement: <Button icon="cross" minimal={true} onClick={async () => { await setListaSelecionadaPickerCompradorNome([]) }} />
                                    }}
                                    itemRenderer={(Item: { id: string, descricao: string }) => <MenuItem onClick={async () => {
                                        var ListaSelecionada = [...getListaSelecionadaPickerCompradorNome];
                                        ListaSelecionada.push(Item);
                                        await setListaSelecionadaPickerCompradorNome(ListaSelecionada);
                                    }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: { id: string, descricao: string }) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                </PickerCompradorNome>
                            </div>
                            <div style={{ width: "150px", marginRight: "1%" }}>
                                <PickerIdentificador popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true }} items={getListaPickerIdentificador} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    fill={true} tagRenderer={(Item: { id: string, descricao: string }) => Item.descricao} selectedItems={getListaSelecionadaPickerIdentificador} placeholder="Unidade"
                                    tagInputProps={{
                                        onRemove: async (value: React.ReactNode, index: number) => { await setListaSelecionadaPickerIdentificador(getListaSelecionadaPickerIdentificador.filter((Item, Index) => Index !== index)); },
                                        rightElement: <Button icon="cross" minimal={true} onClick={async () => { await setListaSelecionadaPickerIdentificador([]) }} />
                                    }}
                                    itemRenderer={(Item: { id: string, descricao: string }) => <MenuItem onClick={async () => {
                                        var ListaSelecionada = [...getListaSelecionadaPickerIdentificador];
                                        ListaSelecionada.push(Item);
                                        await setListaSelecionadaPickerIdentificador(ListaSelecionada);
                                    }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: { id: string, descricao: string }) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                </PickerIdentificador>
                            </div>
                            <div style={{ width: "150px", marginRight: "1%" }}>
                                <PickerRespCadastro popoverProps={{ usePortal: false, popoverClassName: "bp3-multi-select-popover", minimal: true }} items={getListaPickerRespCadastro} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                                    fill={true} tagRenderer={(Item: { id: string, descricao: string }) => Item.descricao} selectedItems={getListaSelecionadaPickerRespCadastro} placeholder="Resp. Cadastro"
                                    tagInputProps={{
                                        onRemove: async (value: React.ReactNode, index: number) => { await setListaSelecionadaPickerRespCadastro(getListaSelecionadaPickerRespCadastro.filter((Item, Index) => Index !== index)); },
                                        rightElement: <Button icon="cross" minimal={true} onClick={async () => { await setListaSelecionadaPickerRespCadastro([]) }} />
                                    }}
                                    itemRenderer={(Item: { id: string, descricao: string }) => <MenuItem onClick={async () => {
                                        var ListaSelecionada = [...getListaSelecionadaPickerRespCadastro];
                                        ListaSelecionada.push(Item);
                                        await setListaSelecionadaPickerRespCadastro(ListaSelecionada);
                                    }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: { id: string, descricao: string }) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                                </PickerRespCadastro>
                            </div>
                            <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                        </>)}
                        <span>Data Inicial</span>
                        <div style={{ width: "100px", marginRight: "1%" }}>
                            <DateInput inputProps={{ onClick: () => setFormularioExibirCalendarioDataInicialProcurada(true) }}
                                dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                popoverProps={{ disabled: !getFormularioExibirCalendarioDataInicialProcurada, onClosed: () => setFormularioExibirCalendarioDataInicialProcurada(false) }}
                                parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setDataInicialProcurada(DataSelecionada)}
                                value={getDataInicialProcurada} maxDate={getDataFinalProcurada || new Date()}>
                            </DateInput>
                        </div>
                        <span>Data Final</span>
                        <div style={{ width: "100px", marginRight: "1%" }}>
                            <DateInput inputProps={{ onClick: () => setFormularioExibirCalendarioDataFinalProcurada(true) }}
                                dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                                popoverProps={{ disabled: !getFormularioExibirCalendarioDataFinalProcurada, onClosed: () => setFormularioExibirCalendarioDataFinalProcurada(false) }}
                                parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                                placeholder={"DD/MM/AAAA"} onChange={async (DataSelecionada: Date) => await setDataFinalProcurada(DataSelecionada)}
                                value={getDataFinalProcurada} minDate={getDataInicialProcurada || new Date(1900, 1, 1)} maxDate={new Date()}>
                            </DateInput>
                        </div>
                    </div>
                    <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                    <div className="hoverize" style={{ width: "110px", marginRight: "100px", height: "40px", color: "#175372", boxShadow: "0px 0px 0px 1px #d8d8d8", padding: "0 5px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer" }}
                        onClick={async () => await filtrandoContratos(getTextoProcurado)}>
                        <Icon color="#175372" style={{/* transform: getFilterClicked ? "rotate(0deg)" : "rotate(-360deg)", transition: getFilterClicked ? "transform 2s" : "" */ }} icon="search" />
                        <span>Filtrar</span>
                    </div>
                </div>
            </Collapse>
            <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.SwipeDown,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={30}
                    width={30}
                    style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
                />
            </div>
            {(getListaDeContratosEmConsulta == true || getListaDeContratosEmConsulta == undefined) && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.LoadMaps,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={500}
                    width={500}
                    style={{ marginTop: "122px" }}
                />
                <div className="loading" style={{ margin: "-100px auto 0 auto", fontWeight: "bold" }}>{(getItemPickerCentroDeCusto == undefined) ? "Por favor, selecione um empreendimento para exibir a a lista de contratos" : ((getListaDeContratosEmConsulta == undefined) ? "Clique no botão filtrar efeturar a busca pelos contratos" : ((getListaDeContratosEmConsulta == true) ? "Aguarde, estamos carregando seu empreendimento" : "Pronto, sua tabela de contratos será exibida!"))}</div>
            </div>}
            {getListaDeContratosEmConsulta == false && <Card style={{ backgroundColor: "#ffffff", width: "88%", margin: "40px auto", padding: "0 3% 40px 3%", borderRadius: "5px", border: "none", boxShadow: "none" }}>
                <Component.ContentCard>
                    <h3 style={{ fontSize: "20px" }}>Lista de contratos</h3>
                    <Component.FormContent>
                        <Component.ContentFormHeader>
                            <div style={{ width: "100%" }}>
                                {getFormularioListaDeContratosExibidos.map((contrato, Index) => {
                                    let ValorTotalTitulos = 0, ValorTotalTitulosDeCorretagem = 0, ValorTotalTitulosDeIntermediacao = 0, ValorTotalTitulosDeFinanciamento = 0, ValorTotalTitulosDeEntrada = 0, ValorTotalTitulosDeSinal = 0, ValorTotalTitulosDeParcela = 0, ValorTotalTitulosDeParcelaObra = 0, ValorTotalTitulosDeIntermediaria = 0;
                                    let QtdeTitulos = 0, QtdeTitulosDeCorretagem = 0, QtdeTitulosDeIntermediacao = 0, QtdeTitulosDeFinanciamento = 0, QtdeTitulosDeEntrada = 0, QtdeTitulosDeSinal = 0, QtdeTitulosDeParcela = 0, QtdeTitulosDeParcelaObra = 0, QtdeTitulosDeIntermediaria = 0;
                                    contrato.titulosConsolidados.map((Item) => {
                                        switch (Item.classificacao.id) {
                                            case 1:
                                                ValorTotalTitulosDeIntermediacao += Item.principal;
                                                QtdeTitulosDeIntermediacao += 1;
                                                break;
                                            case 2:
                                                ValorTotalTitulosDeSinal += Item.principal;
                                                QtdeTitulosDeSinal += 1;
                                                break;
                                            case 3:
                                                ValorTotalTitulosDeEntrada += Item.principal;
                                                QtdeTitulosDeEntrada += 1;
                                                break;
                                            case 4:
                                                ValorTotalTitulosDeParcela += Item.principal;
                                                QtdeTitulosDeParcela += 1;
                                                break;
                                            case 5:
                                                break;
                                            case 6:
                                                ValorTotalTitulosDeFinanciamento += Item.principal;
                                                QtdeTitulosDeFinanciamento += 1;
                                                break;
                                            case 7:
                                                break;
                                            case 8:
                                                break;
                                            case 9:
                                                break;
                                            case 10:
                                                break;
                                            case 11:
                                                break;
                                            case 12:
                                                ValorTotalTitulosDeCorretagem += Item.principal;
                                                QtdeTitulosDeCorretagem += 1;
                                                break;
                                            case 13:
                                                ValorTotalTitulosDeParcelaObra += Item.principal;
                                                QtdeTitulosDeParcelaObra += 1;
                                                break;
                                            case 14:
                                                ValorTotalTitulosDeIntermediaria += Item.principal;
                                                QtdeTitulosDeIntermediaria += 1;
                                                break;
                                        }
                                    });
                                    ValorTotalTitulos = ValorTotalTitulosDeCorretagem + ValorTotalTitulosDeIntermediacao + ValorTotalTitulosDeFinanciamento + ValorTotalTitulosDeEntrada + ValorTotalTitulosDeSinal + ValorTotalTitulosDeIntermediaria + ValorTotalTitulosDeParcelaObra + ValorTotalTitulosDeParcela;


                                    return (<Card style={{ display: "flex", alignItems: "center", paddingBottom: "0", justifyContent: "space-between", background: "#ffffff", width: "100%", marginBottom: "10px", borderRadius: "5px", flexWrap: "wrap" }}>

                                        <ButtonGroup style={{ paddingBottom: "0", width: "100%", backgroundColor: "#ffffff", flexWrap: "wrap" }} fill={true}>
                                            <div style={{ backgroundColor: "0", width: "70%" }}>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <FormGroup style={{ fontSize: 20, color: "#222e50", fontWeight: "bold" }} label={`${contrato.prospects[0].nome}`} inline={true} />
                                                </div>
                                                {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado != 4) && <div style={{ display: "flex" }}>
                                                    <div style={{ display: "block", width: "20vw" }}>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Data da proposta</strong>{` ${moment(contrato.dataDaVenda).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Vendedor</strong>{` ${contrato.respCadastroNome ?? "Não identificado"}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Valor a vista</strong>{` ${Moeda.FormatarTexto(contrato.identificador.valorAVista)}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Total gerado</strong>{` ${Moeda.FormatarTexto(ValorTotalTitulos)}`}</div>
                                                    </div>
                                                    <div style={{ display: "block", width: "40vw" }}>

                                                    </div>
                                                </div>}
                                                {(ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4) && <div style={{ display: "flex" }}>
                                                    <div style={{ display: "block", width: "20vw" }}>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Identificador: </strong>{`${contrato.identificador.subLocal.descricao}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Data da proposta: </strong>{` ${moment(contrato.dataDaVenda).locale(moment.locale('pt-BR')).format("DD/MM/YYYY")}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Valor a vista: </strong>{` ${Moeda.FormatarTexto(contrato.identificador.valorAVista)}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Total gerado: </strong>{` ${Moeda.FormatarTexto(ValorTotalTitulos)}`}</div>
                                                    </div>
                                                    <div style={{ display: "block", width: "40vw" }}>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Liner: </strong>{` ${contrato.estruturaDeComissao.filter((Item) => Item.cargo == "Liner")[0].nome.toUpperCase() ?? "Não identificado"}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Closer: </strong>{` ${contrato.estruturaDeComissao.filter((Item) => Item.cargo == "Closer")[0].nome.toUpperCase() ?? "Não identificado"}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Resp. cadastro: </strong>{` ${contrato.respCadastroNome?.toUpperCase() ?? "Não identificado"}`}</div>
                                                        <div style={{ margin: "5px", color: "#222e50" }}><strong style={{ color: "#9da9b3" }}>Sala de venda: </strong>{` ${contrato.salaDeVenda.toUpperCase() ?? "Não identificado"}`}</div>
                                                    </div>
                                                </div>}
                                                <h2></h2>
                                            </div>
                                            <div style={{ display: "flex", marginLeft: "auto", marginTop: "108px", marginBottom: "20px", backgroundColor: "0" }}>
                                                {(contrato._PropostaDeVendaImpressa == false || contrato._ContratoImpresso == false) && <div style={{ width: 100, height: 100, marginTop: "-20px" }}>
                                                    <Lottie options={{ animationData: LottieFiles.Load, loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} />
                                                </div>}
                                                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && (contrato._PropostaDeVendaImpressa == undefined || contrato._PropostaDeVendaImpressa == true) && <Button type="submit" intent={(contrato._PropostaDeVendaImpressa == true) ? "success" : "primary"} style={{ height: "30px", marginRight: "10px", fontSize: "12px" }}
                                                    onClick={async () => {
                                                        let ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                        ListaDeContratosExibidos[Index]._PropostaDeVendaImpressa = false;
                                                        await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);

                                                        let Response = await Venda.ConsultarDocumentoDoContrato(ContextSignIn.getContext().token, contrato.empresa.id, contrato.centroDeCusto.sigla, contrato.numero, "02. Proposta de compra e venda");
                                                        if (Response.data && Math.floor(Response.status / 100) == 2) {
                                                            let downloadLink = document.createElement("a");
                                                            downloadLink.href = "data:application/octet-stream;charset=utf-16le;base64," + encodeURI(Response.data.arquivo);
                                                            downloadLink.download = Response.data.descricao + "." + Response.data.extensao;
                                                            downloadLink.click();
                                                            NotificacaoInterna.ExibirNotificacao("Sua proposta de compra e venda foi baixada com sucesso", `🖨️ Acesse a sua pasta de downloads e procure pelo o arquivo ˜${Response.data.descricao}.${Response.data.extensao}`, NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                                            ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                            ListaDeContratosExibidos[Index]._PropostaDeVendaImpressa = true;
                                                        }
                                                        else {
                                                            ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                            ListaDeContratosExibidos[Index]._PropostaDeVendaImpressa = undefined;
                                                            NotificacaoInterna.ExibirNotificacao("Erro ao baixar a proposta de compra e venda", `Entre em contato com o suporte`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        }
                                                        await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);
                                                    }}>{"Proposta"}</Button>}


                                                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && (contrato._EspelhoDeVendaImpresso == undefined || contrato._EspelhoDeVendaImpresso == true) && <Button type="submit" intent={(contrato._EspelhoDeVendaImpresso == true) ? "success" : "primary"} style={{ height: "30px", marginRight: "10px", fontSize: "12px" }}
                                                    onClick={async () => {
                                                        let ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                        ListaDeContratosExibidos[Index]._EspelhoDeVendaImpresso = false;
                                                        await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);

                                                        let Response = await Venda.ConsultarDocumentoDoContrato(ContextSignIn.getContext().token, contrato.empresa.id, contrato.centroDeCusto.sigla, contrato.numero, "21. Espelho da Venda");
                                                        if (Response.data && Math.floor(Response.status / 100) == 2) {
                                                            let downloadLink = document.createElement("a");
                                                            downloadLink.href = "data:application/octet-stream;charset=utf-16le;base64," + encodeURI(Response.data.arquivo);
                                                            downloadLink.download = Response.data.descricao + "." + Response.data.extensao;
                                                            downloadLink.click();
                                                            NotificacaoInterna.ExibirNotificacao("Seu espelho de venda foi baixado com sucesso", `🖨️ Acesse a sua pasta de downloads e procure pelo o arquivo ˜${Response.data.descricao}.${Response.data.extensao}`, NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                                            ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                            ListaDeContratosExibidos[Index]._EspelhoDeVendaImpresso = true;
                                                        }
                                                        else {
                                                            ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                            ListaDeContratosExibidos[Index]._EspelhoDeVendaImpresso = undefined;
                                                            NotificacaoInterna.ExibirNotificacao("Erro ao baixar o espelho de venda", `Entre em contato com o suporte`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        }
                                                        await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);
                                                    }}>{"Espelho de vendas"}</Button>}
                                                {(contrato._ContratoImpresso == undefined || contrato._ContratoImpresso == true) && <Button type="submit" intent={(contrato._ContratoImpresso == true) ? "success" : "primary"} icon="cloud-download" style={{ height: "30px", fontSize: "12px", marginRight: "10px", display: "flex" }}
                                                    onClick={async () => {
                                                        let ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                        ListaDeContratosExibidos[Index]._ContratoImpresso = false;
                                                        await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);

                                                        let Response = await Venda.ConsultarECompactarContrato(ContextSignIn.getContext().token, contrato.empresa.id, contrato.centroDeCusto.sigla, contrato.numero, false);
                                                        if (Response.data && Math.floor(Response.status / 100) == 2) {
                                                            let downloadLink = document.createElement("a");
                                                            downloadLink.href = "data:application/octet-stream;charset=utf-16le;base64," + encodeURI(Response.data.arquivo);
                                                            downloadLink.download = Response.data.descricao + "." + Response.data.extensao;
                                                            downloadLink.click();
                                                            NotificacaoInterna.ExibirNotificacao("Seu contrato foi baixado com sucesso", `🖨️ Acesse a sua pasta de downloads e procure pelo o arquivo ˜${contrato.centroDeCusto.sigla}_${contrato.identificador.subLocal.descricao}_${contrato.prospects[0].nome}.${Response.data.extensao}`, NotificacaoInterna.TipoDeNotificacao.Sucesso);

                                                            ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                            ListaDeContratosExibidos[Index]._ContratoImpresso = true;
                                                        }
                                                        else {
                                                            ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                            ListaDeContratosExibidos[Index]._ContratoImpresso = undefined;
                                                            NotificacaoInterna.ExibirNotificacao("Erro ao baixar o contrato", `Entre em contato com o suporte`, NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        }
                                                        await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);
                                                    }}>{(contrato._ContratoImpresso == true) ? "Arquivo baixado" : "Download"}</Button>}
                                                {ContextSignIn.getContext().IdUltimoGrupoDeEmpresasAcessado == 4 && (contrato._ContratoSubmetidoAAssinatura == undefined || contrato._ContratoSubmetidoAAssinatura == true) && <Button type="submit" intent={(contrato._ContratoSubmetidoAAssinatura == true) ? "success" : "primary"} icon="manually-entered-data" style={{ height: "30px", fontSize: "12px" }}
                                                    onClick={async () => {
                                                        let ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                        ListaDeContratosExibidos[Index].salaDeVendaNome = "AMAZONAS SHOPPING";
                                                        await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);

                                                        if (contrato.salaDeVendaNome) {
                                                            let ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                            ListaDeContratosExibidos[Index]._ContratoSubmetidoAAssinatura = false;
                                                            await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);

                                                            NotificacaoInterna.ExibirNotificacao("Enviando contrato para assinatura", "Aguarde enquanto o documento é cadastrado no D4sign'", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                            let Response = await D4sign.CammelPost(ContextSignIn.getContext().token, contrato.salaDeVendaNome ?? "undefined", contrato);
                                                            if (Math.floor(Response.status / 100) == 2) {
                                                                NotificacaoInterna.ExibirNotificacao("Contrato devidamente enviado para assinatura", "O(s) documento(s) se encontra no D4sign para assinatura'", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                                                                ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                                ListaDeContratosExibidos[Index]._ContratoSubmetidoAAssinatura = true;
                                                            }
                                                            else {
                                                                ListaDeContratosExibidos = [...getFormularioListaDeContratosExibidos];
                                                                ListaDeContratosExibidos[Index]._ContratoSubmetidoAAssinatura = undefined;
                                                                NotificacaoInterna.ExibirNotificacao("Falha ao enviar o contrato para assinatura", (Response.data.toString() != "[object Object]") ? Response.data.toString() : "😱 Houve uma falha ao enviar o contrato para assinatura, entre em contato com a equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                            }
                                                            await setFormularioListaDeContratosExibidos(ListaDeContratosExibidos);
                                                        }
                                                        else {
                                                            NotificacaoInterna.ExibirNotificacao("Falha ao enviar o contrato para assinatura", "Sala de venda não definida! Isso acontece devido a contratos antigos ainda não possuirem esta informação cadastrada em nosso acervo de informações", NotificacaoInterna.TipoDeNotificacao.Erro);
                                                        }
                                                    }}>{"Submeter a assinatura"}</Button>}
                                            </div>
                                        </ButtonGroup>
                                    </Card>);
                                }
                                )}
                            </div>
                        </Component.ContentFormHeader>
                    </Component.FormContent>
                </Component.ContentCard>
            </Card>}
        </Component.Container >
    );
    /* #endregion */
};

export default View;