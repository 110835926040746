import React from 'react';
import { WidthCheck as ContextWidthCheck } from '../../../../../../Context';
import { Columns, Status, Area, Quantidade, Valor } from '../../index';

function App({ columnsData, visao, statusData, tiposInadimplencia, getListaFaixa, getListaCategoria, Calcularcenario }: any) {

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
            {/* Colunas */}
            <Columns columns={columnsData} />
            {/* Info */}
            <div style={{ display: "flex", width: "100%", color: "#2E372B" }}>
                {/* Status */}
                <Status visao={visao} statusData={statusData} tiposInadimplencia={tiposInadimplencia} getListaFaixa={getListaFaixa} getListaCategoria={getListaCategoria} />
                {/* Área */}
                <Area visao={visao} statusData={statusData} tiposInadimplencia={tiposInadimplencia} getListaFaixa={getListaFaixa} getListaCategoria={getListaCategoria} Calcularcenario={Calcularcenario} />
                {/* Quantidade */}
                <Quantidade visao={visao} statusData={statusData} tiposInadimplencia={tiposInadimplencia} getListaFaixa={getListaFaixa} getListaCategoria={getListaCategoria} Calcularcenario={Calcularcenario} />
                {/* Valor */}
                <Valor visao={visao} statusData={statusData} tiposInadimplencia={tiposInadimplencia} getListaFaixa={getListaFaixa} getListaCategoria={getListaCategoria} Calcularcenario={Calcularcenario} />
            </div>
        </div>
    );
}

export default App;