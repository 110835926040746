import styled from 'styled-components';


export const Container = styled.div`
 
    display:block;
    height:100%;
    overflow:auto;
    width:100%;
    min-width:450px;
    background-color:#EDEFF5;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius:0px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    ::-webkit-border-radius{
        border-radius:10px;
    }
    ::-webkit-moz-border-radius{
        border-radius:10px;
    }
    ::-webkit-ms-border-radius{
        border-radius:10px;
    }

    .endereco {
        display:flex;
        align-items:center;
        background-color:0;
        width:88%;
        margin:20px auto;
        img {
            width:20px;
            height:20px;
        }
        .bar {
            font-size:18px;
            color:#bdbdbd;
            margin:0 15px;
        }
        .place {
            color:#175372;
        }
    }
`;
export const Content = styled.body`
    width: 100%;
    margin: 0 auto;
    display: flex;
    margin-top: 10px;
    padding-left: 13vw;
    align-items: center;
    background-color:0;

    svg {
        margin-left: 16px;
        margin-right: 16px;
        width: 30px;
        height: 30px;
    }
`;
export const Title = styled.div`
    display: flex;
    width:100%;
    background-color:0;
    align-items: center;
    flex-wrap: wrap;

    svg {
    
        margin-right: 10px;
        width: 25px;
        height: 25px;
    }
`;
export const ContentCard = styled.div`
    background-color:0;
    flex-direction: column;
    margin: 0 auto 20px auto;
    background-color:0;
    display: flex;
    align-items: left;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15);
    border-radius: 5px;
    padding-left: 0px;
    & + div {
        padding:1% 3%;
    }

/* ==================================TAMNHOS DE TELAS================================================  */

    /*=========== 4K - 2560px ======== */
    @media screen and (max-width: 4000px){
        .Conteiner{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        #Nome{
            width: 350px;
            margin-left: 50px;
        }
        #CPF{
            width: 125px;
            margin-left: 64px;
        }
        #Nacionalidade{
            width: 222px;
        }
        #Email{
            width: 350px;
            margin-left: 52px;
        }
        #TelefoneCelular{
            width: 243px;
            margin-left: 19px;
        }
        #TelefoneComercial{
            width: 241px;
        }
        #profissão{
            width: 248px;
            margin-left: 28px;
        }
        #Pai{
            width: 275px;
            margin-left: 43px;
        }
        #Mae{
            width: 275px;
            margin-left: 37px;
        }
        #RG{
            width: 259px;
            margin-left: 74px;
        }
        #OrgaoEmissor{
            width: 350px;
        }
        #RGUF{
            width: 240px;
            margin-left: 74px;
        }
        #CNH{
            width: 240px;
            margin-left: 74px;
        }
        #CNH{
            width: 240px;
            margin-left: 80px;
        }
        #CNHUF{
            width: 240px;
            margin-left: 75px;
        }
    }
    /*===== Laptop Large - 1440px ===== */
    @media screen and (max-width: 1920px){
        .Conteiner{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        #Nome{
            width: 250px;
            margin-left: 53px;
        }
        #CPF{
            width: 250px;
            margin-left: 65px;
        }
        #CPF{
            width: 250px;
            margin-left: 65px;
        }
        #Nacionalidade{
            width: 222px;
        }
        #Email{
            width: 250px;
            margin-left: 53px;
        }
        #TelefoneCelular{
            width: 243px;
            margin-left: 19px;
        }
        #TelefoneComercial{
            width: 241px;
        }
        #profissão{
            width: 248px;
            margin-left: 29px;
        }
        #Pai{
            width: 275px;
            margin-left: 43px;
        }
        #Mae{
            width: 275px;
            margin-left: 37px;
        }
        #RG{
            width: 259px;
            margin-left: 75px;
        }
        #OrgaoEmissor{
            width: 250px;
            min-width: 125px;
        }
        #RGUF{
            width: 240px;
            margin-left: 76px;
        }
        #CNH{
            width: 240px;
            margin-left: 80px;
        }
        #CNHUF{
            width: 240px;
            margin-left: 75px;
        }
        /* ===================== */
        #CEP{
            width: 270px;
            margin-left: 45px;
        }
        #Logradouro{
            width: 262px;
        }
        #NumeroDeEndereco{
            width: 266px;
            margin-left: 22px;
        }
        #Bairro{
            width: 290px;
            margin-left: 6px;
        }
        #Cidade{
            width: 290px;
        }
        #uf{
            width: 276px;
            margin-left: 26px;
        }
        #Complemento{
            width: 258px;
        }
        #Empreendimento{
            width: 225px;
            margin-right: 5px;
        }
        #unidade{
            width: 287px;
            margin-right: 5px;
        }
        .CondiçãoDeVendas{
            width: 223px;
        }
    }


    /*========= Tablet - 1200px ======= */
    @media screen and (max-width: 1200px){
        .Conteiner{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        #Nome{
            width: 275px;
            margin-left: 52px;
        }
        #Nacionalidade{
            width: 275px;
        }
        #CPF{
            width: 125px;
            margin-left: 65px;
        }
        #Email{
            width: 275px;
            margin-left: 56px;
        }
        #TelefoneCelular{
            width: 125px;
            margin-left: 22px;
        }
        #TelefoneComercial{
            width: calc(125px + 2px);
            margin-left: 3px;
        }
        #profissão{
            width: 275px;
            margin-left: 32px;
        }
        #Pai{
            width: 275px;
            margin-left: 43px;
        }
        #Mae{
            width: 275px;
            margin-left: 37px;
        }
        #RG{
            width: 125px;
            margin-left: 73px;
        }
        #RGUF{
            width: 125px;
            margin-left: 74px;
        }
        #OrgaoEmissor{
            width: 275px;
        }
        #CNH{
            width: 275px;
        }
        #CNHUF{
            width: 275px;
        }
        /* ===================== */
        #CEP{
            width: 270px;
            margin-left: 60px;
        }
        #Logradouro{
            width: 262px;
        }
        #NumeroDeEndereco{
            width: 266px;
            margin-left: 22px;
        }
        #Bairro{
            width: 290px;
            margin-left: 50px;
        }
        #Cidade{
            width: 290px;
            margin-left: 44px;
        }
        #uf{
            width: 276px;
            margin-left: 70px;
        }
        #Complemento{
            width: 258px;
        }
        #Empreendimento{
            margin-left: 72px;
            margin-right: 5px;
        }
        #unidade{
            margin-left: 129px;
            margin-right: 5px;
        }
        #Empreendimento{
            width: 225px;
            margin: 0px;
            flex: wrap;
        }
        #unidade{
            width: 225px;
            margin: 0px;
        }
        .CondiçãoDeVendas{
            width: 225px;
        }
    }

    /*========= Mobile - 485px ======== */
    @media screen and (max-width: 485px){
        .Conteiner{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        #Nome{
            width: 225px;
            margin-left: 52px;
        }
        #CPF{
            width: 125px;
            margin-left: 65px;
        }
        #Nacionalidade{
            width: 225px;
        }
        #Email{
            width: 225px;
            margin-left: 56px;
        }
        #TelefoneCelular{
            width: 125px;
            margin-left: 22px;
        }
        #TelefoneComercial{
            width: calc(125px + 2px);
            margin-left: 3px;            
        }
        #profissão{
            width: 225px;
            margin-left: 32px;
        }
        #Pai{
            width: 275px;
            margin-left: 43px;
        }
        #Mae{
            width: 275px;
            margin-left: 37px;
        }
        #RG{
            width: 125px;
            margin-left: 73px;
        }
        #RGUF{
            width: 125px;
            margin-left: 74px;
        }
        #OrgaoEmissor{
            width: 225px;
        }
        #CNH{
            width: 225px;
        }
        #CNHUF{
            width: 225px;
        }
        #Empreendimento{
            width: 225px;
            margin: 0px;
            flex: wrap;
        }
        #unidade{
            width: 225px;
            margin: 0px;
        }
        .CondiçãoDeVendas{
            width: 225px;
        }
    }
    #UnidadeValorAVista{
        width: 225px;
    }
    #ParcelaValor{
        margin-left: 46px;
    }
    #ParcelaValorTotal{
        margin-left: 83px;
    }
    #flex{
        display: flex;
        flex-direction: row;
    }


    .máquina{
        margin-left: 45px;
    }
    .bandeira{
        margin-left: 44px;
    }
    .operação{
        margin-left: 40px;
    }
    .nsu{
        margin-left: 72px;
    }
    .titular{
        margin-left: 44px;
    }
    .conta{
        margin-left: 47px;
    }
    .agência{
        margin-left: 32px;
    }
    .banco{
        margin-left: 42px;
    }
`;
export const ContentFormHeader = styled.div`
background-color:0;
margin-top: 16px;
padding-top: 0px;
padding-left: 0px;

flex-direction: column;

display: flex;
align-items: left;

border-radius: 2px;

`;
export const FormContent = styled.div`
display: flex;
width:100%;
align-items: center;
vertical-align: middle;
flex-wrap: wrap;
border-radius: 2px;
background-color:0;
`;
export const DescriptionContent = styled.div`
    box-shadow: none;
    cursor: pointer;
    font-size:8pt;
    max-height:30px;
    margin-left:10px;
    overflow:hidden;
    opacity:0.6;
    text-overflow:ellipsis;

    transition:0.25s;
    :hover{
        box-shadow: 0px 0.4px 0px rgba(0, 0, 0, 0.25);
        margin-left:20px;
        opacity:1;
    }
`;