import React, { useState, useEffect, memo } from 'react';
import json from '../../Auditoria/locale.json';
import { Objeto } from '../../../../../Services/Models';
import { L10n } from '@syncfusion/ej2-base';
import { PivotViewComponent, IDataOptions, Inject, CalculatedField, FieldList, Toolbar, PDFExport, ExcelExport } from '@syncfusion/ej2-react-pivotview';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import './style.css'
import { PdfHeaderQueryCellInfoEventArgs, PdfQueryCellInfoEventArgs } from '@syncfusion/ej2-react-grids'
L10n.load(json)

function App({ planejamento }: any) {
  const [getProjectData, setProjectData] = useState();
  const [getData, setData] = useState(planejamento);
  const [getLoading, setLoading] = useState<boolean>(true)
  let pivotObj: PivotViewComponent;

  let dataSourceSettings: IDataOptions = {
    enableSorting: true,
    columns: [{ name: 'Ano' }, { name: 'MesAno' }],
    valueSortSettings: { headerDelimiter: ' - ' },
    values: [{ name: 'Comprometido', caption: 'Valor Comp' } /*, { name: 'Amount', caption: 'Sold Amount' }*/],
    dataSource: getData,
    rows: [{ name: 'Divisao' }, { name: "Status do Comprometido" }, { name: 'NM_Obra' }],
    formatSettings: [{ name: 'Comprometido', format: 'C2' }, {name: 'MesAno', type:"date", format:"MM/yyyy"}],
    expandAll: false
  };

  useEffect(() => {
    async function componentDidMount() {
      await setData(planejamento)
    }
    componentDidMount();
  }, [planejamento])
  const pdfQueryCellInfo = (args: PdfQueryCellInfoEventArgs | any) => {
    args.cell.cellHeight = 10;
  }
  const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs | any) => {
    (args.cell as any).row.pdfGrid.repeatHeader = true;
    args.cell.gridRow.pdfGrid.columns.getColumn(0).width = 200;
    args.cell.gridRow.pdfGrid.columns.getColumn(2).textAlign = "Right";
  }
  function btnClick() {
    pivotObj.pdfExport({
      pageOrientation: "Landscape",
      exportType: 'AllPages',
      theme: {
        header: { fontSize: 5, bold: true },
        record: { fontSize: 5, }
      },
      header: {
        fromTop: 0,
        height: 20,
        contents: [
          {
            type: 'Text',
            value: "Relatório",
            position: { x: 0, y: 0 },
            style: { textBrushColor: '#000000', fontSize: 13, dashStyle: 'Solid', hAlign: 'Center' }
          }
        ]
      },
      footer: {
        fromBottom: 0,
        height: 40,
        contents: [
          {
            type: 'PageNumber',
            pageNumberType: 'Arabic',
            format: 'Page {$current} of {$total}',
            position: { x: 0, y: 0 },
            style: { textBrushColor: '#000000', fontSize: 15 }
          }
        ]
      },
      allowHorizontalOverflow: false
    });
  }
  return (
    <>
      <div className='control-pane' style={{ width: '100%' }}>
        <div className='control-section' style={{ overflow: 'auto', width:"100%" }}>
          <PivotViewComponent
            ref={(d) => (d != null ? pivotObj = d : null)}
            id='PivotView'
            dataSourceSettings={dataSourceSettings}
            showToolbar={true}
            gridSettings={{ columnWidth: 140, allowTextWrap: false }}
            allowExcelExport={true}
            allowPdfExport={true}
            toolbar={["FieldList"]}
            pdfHeaderQueryCellInfo={pdfHeaderQueryCellInfo}
            pdfQueryCellInfo={pdfQueryCellInfo}
            height={700}
            locale='pt-BR'
            style={{ maxWidth: "100%", overflow: "auto" }}
            allowCalculatedField={true}
            showFieldList={true}
          >
            <Inject services={[FieldList, CalculatedField, Toolbar]} />
          </PivotViewComponent>
        </div>
        <div>
          <ButtonComponent
            cssClass="e-primary"
            onClick={() => { btnClick() }}
          >
            Export
          </ButtonComponent>
        </div>
      </div>
    </>
  );
}

export default memo(App);