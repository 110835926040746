import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, FormGroup, Icon, MenuItem, Checkbox, Collapse, Divider } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs/datetime";
import { Select, MultiSelect } from "@blueprintjs/select";

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import * as LottieFiles from '../../../Data/Lottie/index'
import History from "../../../Routers";
import { CentroDeCusto, Identificador } from '../../../Services/Controllers';
import { Objeto } from '../../../Services/Models';

import * as ComponentTable from './Components';
import api from './Services/api';
import './style.css';

const View: React.FC = (viewModel) => {
  const PropriedadesDeCalendario = {
    months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
  };
  //Controles
  const [getCollapse, setCollapse] = useState(false)
  const [getFilterClicked, setFilterClicked] = useState(false)
  //DRE
  const [getFormularioDataDeInicio, setFormularioDataDeInicio] = useState<any>();
  const [getFormularioDataDeTermino, setFormularioDataDeTermino] = useState<any>();
  const [getSearch, setSearch] = useState<boolean>(false);

  const [getCodEmp, setCodEmp] = useState<string>();
  const [getCodEmpObra, setCodEmpObra] = useState<string>();
  const [getProduto, setProduto] = useState<string>();
  const [getStatus, setStatus] = useState<string>();
  const [getFiltro, setFiltro] = useState<string>();

  const [getDivisao, setDivisao] = useStateWithCallbackLazy<string>('');
  const [getCheckEmpresa, setCheckEmpresa] = useState<boolean>(false);
  const [getCheckPessoais, setCheckPessoais] = useState<boolean>(false);
  const [getCheckEmpreendimentos, setCheckEmpreendimentos] = useState<boolean>(false);

  const [getFluxoData, setFluxoData] = useState<Objeto.DREData>();
  const [getPlanejamentoData, setPlanejamentoData] = useState<Objeto.Planejamento>();
  const [getVendasSemCessaoData, setVendasSemCessaoData] = useState<any>();
  const [getVendasDeCessaoData, setVendasDeCessaoData] = useState<any>();
  const [getVendasDistratadasData, setVendasDistratadasData] = useState<any>();
  const [getDepositosData, setDepositosData] = useState<any>();
  //Tables
  const [getTableSelected, setTableSelected] = useState<number>(0);
  const [getOpacity, setOpacity] = useState<boolean>(false);
  const [getVisaoTempo, setVisaoTempo] = useState<number>(0);

  /*CasaEterra*/
  const [getItemPickerVisao, setItemPickerVisao] = useState<{ id: number, descricao: string }>();
  const [getListaPickerVisao, setListaPickerVisao] = useState<Array<{ id: number, descricao: string }>>([
    {
      id: 1,
      descricao: "11/2020"
    },
    {
      id: 2,
      descricao: "12/2020"
    }



  ]);
  const PickerVisao = Select.ofType<{ id: number, descricao: string }>();

  const [getItemPickerEmpresas, setItemPickerEmpresas] = useState<Objeto.EmpresasModel>();
  const [getListaPickerEmpresas, setListaPickerEmpresas] = useState<Array<Objeto.EmpresasModel>>([]);
  const PickerEmpresas = Select.ofType<Objeto.EmpresasModel>();
  const [getEmpresa, setEmpresa] = useState<string>();

  const [getItemPickerObras, setItemPickerObras] = useState<Objeto.ObrasModel>();
  const [getListaPickerObras, setListaPickerObras] = useState<Array<Objeto.ObrasModel>>([]);
  const PickerObras = Select.ofType<Objeto.ObrasModel>();
  const [getObra, setObra] = useState<string>();
  /* # */
  const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
  const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
  const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();

  const [getItemPickerProduto, setItemPickerProduto] = useState<Objeto.ProdutoModel>();
  const [getListaPickerProduto, setListaPickerProduto] = useState<Array<Objeto.ProdutoModel>>([]);
  const PickerProduto = Select.ofType<Objeto.ProdutoModel>();

  const [getItemPickerEmpreendimento, setItemPickerEmpreendimento] = useState<Objeto.EmpreendimentoPicker>();
  const [getListaPickerEmpreendimento, setListaPickerEmpreendimento] = useState<Array<Objeto.EmpreendimentoPicker>>([]);
  const PickerEmpreendimento = Select.ofType<Objeto.EmpreendimentoPicker>();

  const [getItemPickerStatus, setItemPickerStatus] = useState<Objeto.StatusPicker>();
  const [getListaPickerStatus, setListaPickerStatus] = useState<Array<Objeto.StatusPicker>>([]);
  const PickerStatus = Select.ofType<Objeto.StatusPicker>();

  const [getItemPickerGrupo, setItemPickerGrupo] = useState<Objeto.GrupoModel>();
  const [getListaPickerGrupo, setListaPickerGrupo] = useState<Array<Objeto.GrupoModel>>([]);
  const PickerGrupo = Select.ofType<Objeto.GrupoModel>();

  useEffect(() => {
    async function componentDidMount() {
      switch (History.location.pathname) {
        case "/Menu/Analytics/Resumo":
          await setTableSelected(0);
          break;
        case "/Menu/Analytics/Vendas":
          await setTableSelected(1);
          api.get('/getFiltroVendas').then(async res => {
            await setListaPickerEmpresas(res.data.filtroEmpresas);
            await setListaPickerObras(res.data.filtroObras);
          })
          api.get('/VendasSemCessao?empresa=undefined&obra=undefined&dataInicial=undefined&dataFinal=undefined').then(async res => {
            await setVendasSemCessaoData(res.data)
          })
          break;
        case "/Menu/Analytics/Cessao":
          await setTableSelected(2);
          api.get('/getFiltroVendas').then(async res => {
            await setListaPickerEmpresas(res.data.filtroEmpresas);
            await setListaPickerObras(res.data.filtroObras);
          })
          api.get('/VendasDeCessao?empresa=undefined&obra=undefined&dataInicial=undefined&dataFinal=undefined').then(async res => {
            await setVendasDeCessaoData(res.data)
          })
          break;
        case "/Menu/Analytics/Distratos":
          await setTableSelected(3);
          api.get('/getFiltroVendas').then(async res => {
            await setListaPickerEmpresas(res.data.filtroEmpresas);
            await setListaPickerObras(res.data.filtroObras);
          })
          api.get('/VendasDistratadas?empresa=undefined&obra=undefined&dataInicial=undefined&dataFinal=undefined').then(async res => {
            await setVendasDistratadasData(res.data)
          })
          break;
        case "/Menu/Analytics/Deposito":
          await setTableSelected(4);
          api.get('/getFiltroVendas').then(async res => {
            await setListaPickerEmpresas(res.data.filtroEmpresas);
            await setListaPickerObras(res.data.filtroObras);
          })
          api.get('/Depositos?empresa=undefined&obra=undefined&dataInicial=undefined&dataFinal=undefined').then(async res => {
            await setDepositosData(res.data)
          })
          break;
        case "/Menu/Analytics/Provisao":
          await setTableSelected(5);
          break;
        case "/Menu/Analytics/VendaNoTempo":
          await setTableSelected(6);
          break;
        case "/Menu/Analytics/Fluxo":
          await setFilterClicked(false);
          await setTableSelected(7);
          api.get('/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined').then(res => setListaPickerGrupo(res.data.filtroEmpresas));
          await setListaPickerEmpreendimento(await (await (api.get('/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined'))).data.filtroObras);
          await setListaPickerProduto(await (await (api.get('/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined'))).data.filtroProdutos);
          break;
        case "/Menu/Analytics/Planejamento":
          await setFilterClicked(false);
          await setTableSelected(8);
          api.get('/getFiltroPlan?codEmp=undefined&codEmpObra=undefined').then(res => setListaPickerGrupo(res.data.filtroEmpresas))
          await setListaPickerEmpreendimento(await (await (api.get('/getFiltroPlan?codEmp=undefined&codEmpObra=undefined&divisao=undefined'))).data.filtroObras);
          await setListaPickerProduto(await (await (api.get('/getFiltroPlan?codEmp=undefined&codEmpObra=undefined&divisao=undefined'))).data.filtroProdutos);
          await setListaPickerStatus(await (await (api.get('/getFiltroPlan?codEmp=undefined&codEmpObra=undefined&status=undefined'))).data.filtroStatus);
          break;
      }
      await setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? [])
    }
    componentDidMount();
  }, [])
  return (
    <div style={{ backgroundColor: "#EDEFF5", width: "100%", overflow: "auto" }}>
      {/* Collapse => Endereco + Filtros */}
      <Collapse isOpen={getCollapse}>
        <div style={{ backgroundColor: "#f0f0ff", width: "100%", height: "95px", display: "flex", alignItems: "center", paddingLeft: "2%" }}>
          <img style={{ width: "20px", height: "20px" }} src={IconeHome} alt="" />
          <div className="bar">/</div>
          <div className="place">Analytics</div>
          <div className="bar">/</div>
          <div className="place">
            {
              getTableSelected == 0 ? "Resumo" :
                getTableSelected == 1 ? "Vendas" :
                  getTableSelected == 2 ? "Cessão" :
                    getTableSelected == 3 ? "Distratos" :
                      getTableSelected == 4 ? "Depósitos" :
                        getTableSelected == 5 ? "Provisão" :
                          getTableSelected == 6 ? "Venda(Tempo)" :
                            getTableSelected == 7 ? "Fluxo" : "Planejamento"
            }
          </div>
          {(getTableSelected == 6) &&
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "0", width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "0", width: "45%", marginLeft: "2%" }}>
                <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />

                <PickerEmpresas popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerEmpresas} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: Objeto.EmpresasModel) => <MenuItem onClick={async () => {
                    await setItemPickerEmpresas(Item);
                  }} text={Item.KEY_CDEMP_BD} />} itemPredicate={(Texto: string, Item: Objeto.EmpresasModel) => { return (Item.KEY_CDEMP_BD ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerEmpresas?.KEY_CDEMP_BD ?? "Empresa"} rightIcon="double-caret-vertical" />
                </PickerEmpresas>

                <PickerObras popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerObras} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: Objeto.ObrasModel) => <MenuItem onClick={async () => {
                    await setItemPickerObras(Item);
                  }} text={Item.KEY_CDEMP_BD} />} itemPredicate={(Texto: string, Item: Objeto.ObrasModel) => { return (Item.KEY_CDEMP_BD ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerObras?.KEY_CDEMP_BD ?? "Obra"} rightIcon="double-caret-vertical" />
                </PickerObras>

                <PickerVisao popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerVisao} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: { id: number, descricao: string }) => <MenuItem onClick={async () => {
                    await setItemPickerVisao(Item);
                  }} text={Item.descricao} />} itemPredicate={(Texto: string, Item: { id: number, descricao: string }) => { return (Item.descricao ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerVisao?.descricao ?? "Data da Visão"} rightIcon="double-caret-vertical" />
                </PickerVisao>

              </div>
              <div className="hoverize" style={{ width: "110px", marginRight: "110px", height: "40px", color: "#175372", boxShadow: "0px 0px 0px 1px #d8d8d8", padding: "0 5px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer" }}
                onClick={async () => { }}>
                <Icon color="#175372" style={{ transform: getFilterClicked ? "rotate(0deg)" : "rotate(-360deg)", transition: getFilterClicked ? "transform 2s" : "" }} icon="search" />
                <span>Pesquisar</span>
              </div>
            </div>}
          {(getTableSelected != 7 && getTableSelected != 8 && getTableSelected != 6) &&
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "0", width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "0", width: "75%", marginLeft: "2%" }}>
                <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />

                <PickerEmpresas popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerEmpresas} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: Objeto.EmpresasModel) => <MenuItem onClick={async () => {
                    await setItemPickerEmpresas(Item);
                    await setEmpresa(Item.KEY_CDEMP_BD);
                  }} text={Item.NM_Empresa} />} itemPredicate={(Texto: string, Item: Objeto.EmpresasModel) => { return (Item.NM_Empresa ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerEmpresas?.NM_Empresa.substring(0, 10).concat("...") ?? "Empresa"} rightIcon="double-caret-vertical" />
                </PickerEmpresas>
                {getItemPickerEmpresas && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {
                  await setItemPickerEmpresas(undefined);
                  await setItemPickerObras(undefined);
                  await setEmpresa(undefined);
                  await setObra(undefined);

                  //await setListaPickerGrupo(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroEmpresas);
                  //await setListaPickerEmpreendimento(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroObras);
                  //await setListaPickerProduto(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroProdutos);
                }} />}

                <PickerObras popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerObras} onItemSelect={() => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: Objeto.ObrasModel) => <MenuItem onClick={async () => {
                    await setItemPickerObras(Item);
                    await setObra(Item.KEY_CDEMP_CDOBR_BD);
                  }} text={Item.NM_Obra} />} itemPredicate={(Texto: string, Item: Objeto.ObrasModel) => { return (Item.NM_Obra ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerObras?.NM_Obra.substring(0, 10).concat("...") ?? "Obra"} rightIcon="double-caret-vertical" />
                </PickerObras>
                {getItemPickerObras && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {
                  await setItemPickerEmpresas(undefined);
                  await setItemPickerObras(undefined);
                  await setEmpresa(undefined);
                  await setObra(undefined);

                  //await setListaPickerGrupo(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroEmpresas);
                  //await setListaPickerEmpreendimento(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroObras);
                  //await setListaPickerProduto(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroProdutos);
                }} />}

                <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                {/* Data Picker */}
                <FormGroup className="place" label="Data de início:" labelFor="DataDeInicio" inline={true} style={{ marginTop: "15px" }}>
                  <div style={{ width: "100px" }}>
                    <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                      parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                      placeholder={"DD/MM/AAAA"} value={getFormularioDataDeInicio} maxDate={getFormularioDataDeTermino}
                      onChange={async (DataSelecionada: Date) => {
                        if (DataSelecionada != null) {
                          await setFormularioDataDeInicio(DataSelecionada);
                        }
                        else {
                          await setFormularioDataDeInicio(getFormularioDataDeInicio);
                        }
                      }}>
                    </DateInput>
                  </div>
                </FormGroup>
                <FormGroup className="place" label="Data de termino:" labelFor="DataDeTermino" inline={true} style={{ marginTop: "15px" }}>
                  <div style={{ width: "100px" }}>
                    <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                      parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                      placeholder={"DD/MM/AAAA"} value={getFormularioDataDeTermino} minDate={getFormularioDataDeInicio} maxDate={new Date()}
                      onChange={async (DataSelecionada: Date) => {
                        if (DataSelecionada != null && DataSelecionada < getFormularioDataDeInicio) {
                          await setFormularioDataDeInicio(DataSelecionada)
                        }
                        if (DataSelecionada != null) {
                          await setFormularioDataDeTermino(DataSelecionada)
                        }
                        else if (DataSelecionada == null) {
                          await setFormularioDataDeTermino(getFormularioDataDeTermino)
                        }
                      }}>
                    </DateInput>
                  </div>
                </FormGroup>
              </div>
              <div className="hoverize" style={{ width: "110px", marginRight: "110px", height: "40px", color: "#175372", boxShadow: "0px 0px 0px 1px #d8d8d8", padding: "0 5px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer" }}
                onClick={async () => {
                  switch (getTableSelected) {
                    case 0:
                      break
                    case 1:
                      api.get(`VendasSemCessao?empresa=${getEmpresa}&obra=${getObra}&dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}`).then(res => {
                        setVendasSemCessaoData(res.data)
                      })
                      break
                    case 2:
                      api.get(`VendasDeCessao?empresa=${getEmpresa}&obra=${getObra}&dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}`).then(res => {
                        setVendasDeCessaoData(res.data)
                      })
                      break
                    case 3:
                      api.get(`VendasDistratadas?empresa=${getEmpresa}&obra=${getObra}&dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}`).then(res => {
                        setVendasDistratadasData(res.data)
                      })
                      break
                    case 4:
                      api.get(`Depositos?empresa=${getEmpresa}&obra=${getObra}&dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}`).then(res => {
                        setDepositosData(res.data)
                      })
                      break
                    case 5:
                      break
                    case 6:
                      break
                  }
                }}>
                <Icon color="#175372" style={{ transform: getFilterClicked ? "rotate(0deg)" : "rotate(-360deg)", transition: getFilterClicked ? "transform 2s" : "" }} icon="search" />
                <span>Pesquisar</span>
              </div>
            </div>}
          {getTableSelected == 7 &&
            <div style={{ width: "100%", backgroundColor: "0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", width: "70%", minWidth: "1005px", background: "0", alignItems: "center", justifyContent: "space-between", paddingLeft: "2%" }}>
                <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                {/* Grupo Picker */}
                <PickerGrupo popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerGrupo} onItemSelect={async () => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: Objeto.GrupoModel) => <MenuItem onClick={async () => {
                    await setItemPickerGrupo(Item);
                    await setCodEmp(Item.KEY_CDEMP_BD);
                    api.get(`/getFiltro?codEmp=${Item.KEY_CDEMP_BD}&codEmpObra=${getCodEmpObra}&produto=${getProduto}`).then(async response => {
                      await setListaPickerEmpreendimento(response.data.filtroObras)
                      await setListaPickerProduto(response.data.filtroProdutos)
                    })
                    await setListaPickerProduto(await (await (api.get(`/getProduto?codEmpObra=${getCodEmpObra}&codEmp=${getCodEmp}`))).data);
                  }} text={Item.NM_Empresa} />} itemPredicate={(Texto: string, Item: Objeto.GrupoModel) => { return (Item.NM_Empresa ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerGrupo?.NM_Empresa.substring(0, 10).concat("...") ?? "Grupo"} rightIcon="double-caret-vertical" />
                </PickerGrupo>
                {getItemPickerGrupo && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {
                  await setItemPickerGrupo(undefined);
                  await setItemPickerEmpreendimento(undefined);
                  await setItemPickerProduto(undefined);
                  await setCodEmp(undefined)
                  await setCodEmpObra(undefined)
                  await setProduto(undefined)

                  await setListaPickerGrupo(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroEmpresas);
                  await setListaPickerEmpreendimento(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroObras);
                  await setListaPickerProduto(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroProdutos);
                }} />}
                {/* Empreendimento Picker */}
                <PickerEmpreendimento popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerEmpreendimento} onItemSelect={async () => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: Objeto.EmpreendimentoPicker) => <MenuItem onClick={async () => {
                    await setItemPickerEmpreendimento(Item);
                    await setCodEmpObra(Item.KEY_CDEMP_CDOBR_BD)

                    api.get(`/getFiltro?codEmp=${getCodEmp}&codEmpObra=${Item.KEY_CDEMP_CDOBR_BD}&produto=${getProduto}`).then(async response => {
                      await setListaPickerGrupo(response.data.filtroEmpresas)
                      await setListaPickerProduto(response.data.filtroProdutos)
                    })
                  }} text={Item.NM_Obra} />} itemPredicate={(Texto: string, Item: Objeto.EmpreendimentoPicker) => { return (Item.NM_Obra ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerEmpreendimento?.NM_Obra.substring(0, 10).concat("...") ?? "Empreendimento"} rightIcon="double-caret-vertical" />
                </PickerEmpreendimento>
                {getItemPickerEmpreendimento && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {
                  await setItemPickerGrupo(undefined);
                  await setItemPickerEmpreendimento(undefined);
                  await setItemPickerProduto(undefined);
                  await setCodEmp(undefined)
                  await setCodEmpObra(undefined)
                  await setProduto(undefined)

                  await setListaPickerGrupo(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroEmpresas);
                  await setListaPickerEmpreendimento(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroObras);
                  await setListaPickerProduto(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroProdutos);
                }} />}
                {/* Produto Picker */}
                <PickerProduto popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerProduto} onItemSelect={async () => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: Objeto.ProdutoModel) => <MenuItem onClick={async () => {
                    await setItemPickerProduto(Item);
                    await setProduto(Item.NumProd_psc);

                    api.get(`/getFiltro?codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&produto=${Item.NumProd_psc}`).then(async response => {
                      await setListaPickerGrupo(response.data.filtroEmpresas)
                      await setListaPickerEmpreendimento(response.data.filtroObras)
                    })
                  }} text={Item.PRODUTO} />} itemPredicate={(Texto: string, Item: Objeto.ProdutoModel) => { return (Item.PRODUTO ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerProduto?.PRODUTO.substring(0, 10).concat("...") ?? "Produto"} rightIcon="double-caret-vertical" />
                </PickerProduto>
                {getItemPickerProduto && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {
                  await setItemPickerGrupo(undefined);
                  await setItemPickerEmpreendimento(undefined);
                  await setItemPickerProduto(undefined);
                  await setCodEmp(undefined)
                  await setCodEmpObra(undefined)
                  await setProduto(undefined)

                  await setListaPickerGrupo(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroEmpresas);
                  await setListaPickerEmpreendimento(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroObras);
                  await setListaPickerProduto(await (await (api.get(`/getFiltro?codEmp=undefined&codEmpObra=undefined&produto=undefined`))).data.filtroProdutos);
                }} />}
                <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                {/* Data Picker */}
                <FormGroup className="place" label="Data de início:" labelFor="DataDeInicio" inline={true} style={{ marginTop: "15px" }}>
                  <div style={{ width: "100px" }}>
                    <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                      parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                      placeholder={"DD/MM/AAAA"} value={getFormularioDataDeInicio} maxDate={getFormularioDataDeTermino}
                      onChange={async (DataSelecionada: Date) => {
                        if (DataSelecionada != null) {
                          await setFormularioDataDeInicio(DataSelecionada)
                        }
                        else {
                          await setFormularioDataDeInicio(getFormularioDataDeInicio)
                        }
                      }}>
                    </DateInput>
                  </div>
                </FormGroup>
                <FormGroup className="place" label="Data de termino:" labelFor="DataDeTermino" inline={true} style={{ marginTop: "15px" }}>
                  <div style={{ width: "100px" }}>
                    <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                      parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                      placeholder={"DD/MM/AAAA"} value={getFormularioDataDeTermino} minDate={getFormularioDataDeInicio}
                      onChange={async (DataSelecionada: Date) => {
                        if (DataSelecionada != null && DataSelecionada < getFormularioDataDeInicio) {
                          await setFormularioDataDeInicio(DataSelecionada)
                        }
                        if (DataSelecionada != null) {
                          await setFormularioDataDeTermino(DataSelecionada)
                        }
                        else if (DataSelecionada == null) {
                          await setFormularioDataDeTermino(getFormularioDataDeTermino)
                        }
                      }}>
                    </DateInput>
                  </div>
                </FormGroup>
                <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
              </div>
              <div style={{ marginRight: "110px", width: "325px", marginLeft: "20px", display: "flex", alignItems: "center", justifyContent: "space-between", background: "0" }}>
                <div style={{ display: "flex", flexDirection: "column", fontSize: "12px", marginBottom: "-10px" }}>
                  <Checkbox onClick={async () => { getCheckEmpresa === false ? await setCheckEmpresa(true) : await setCheckEmpresa(false) }} label="Empresa" defaultIndeterminate={false} checked={getCheckEmpresa} />
                  <Checkbox onClick={async () => { getCheckPessoais === false ? await setCheckPessoais(true) : await setCheckPessoais(false) }} label="Pessoais" defaultIndeterminate={false} checked={getCheckPessoais} />
                  <Checkbox onClick={async () => { getCheckEmpreendimentos === false ? await setCheckEmpreendimentos(true) : await setCheckEmpreendimentos(false) }} label="Empreendimentos" defaultIndeterminate={false} checked={getCheckEmpreendimentos} />
                </div>
                <div className="hoverize" style={{ width: "110px", height: "40px", color: "#175372", boxShadow: "0px 0px 0px 1px #d8d8d8", padding: "0 5px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer" }}
                  onClick={async () => {
                    await setFilterClicked(false)
                    if (getCheckEmpresa || getCheckPessoais || getCheckEmpreendimentos) {
                      if (getCheckEmpresa && !getCheckPessoais && !getCheckEmpreendimentos) {
                        await setDivisao("'Empresa'", async (Item: string) => {
                          await api.get(`/DRE?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&Produto=${getProduto}&divisao=${Item}`).then(async res => {
                            await setFluxoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (!getCheckEmpresa && getCheckPessoais && !getCheckEmpreendimentos) {
                        await setDivisao("'Pessoais'", async (Item: string) => {
                          api.get(`/DRE?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&Produto=${getProduto}&divisao=${Item}`).then(async res => {
                            await setFluxoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (!getCheckEmpresa && !getCheckPessoais && getCheckEmpreendimentos) {
                        await setDivisao("'Empreendimentos'", async (Item: string) => {
                          api.get(`/DRE?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&Produto=${getProduto}&divisao=${Item}`).then(async res => {
                            await setFluxoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (getCheckEmpresa && getCheckPessoais && !getCheckEmpreendimentos) {
                        await setDivisao("'Empresa', 'Pessoais'", async (Item: string) => {
                          api.get(`/DRE?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&Produto=${getProduto}&divisao=${Item}`).then(async res => {
                            await setFluxoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (getCheckEmpresa && !getCheckPessoais && getCheckEmpreendimentos) {
                        await setDivisao("'Empresa', 'Empreendimentos'", async (Item: string) => {
                          api.get(`/DRE?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&Produto=${getProduto}&divisao=${Item}`).then(async res => {
                            await setFluxoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (!getCheckEmpresa && getCheckPessoais && getCheckEmpreendimentos) {
                        await setDivisao("'Pessoais', 'Empreendimentos'", async (Item: string) => {
                          api.get(`/DRE?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&Produto=${getProduto}&divisao=${Item}`).then(async res => {
                            await setFluxoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (getCheckEmpresa && getCheckPessoais && getCheckEmpreendimentos) {
                        await setDivisao("'Empresa', 'Pessoais', 'Empreendimentos'", async (Item: string) => {
                          api.get(`/DRE?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&Produto=${getProduto}&divisao=${Item}`).then(async res => {
                            await setFluxoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                    }
                    else {
                      alert("Selecione pelo menos uma divisão")
                    }
                  }}>
                  <Icon color="#175372" style={{ transform: getFilterClicked ? "rotate(0deg)" : "rotate(-360deg)", transition: getFilterClicked ? "transform 2s" : "" }} icon="search" />
                  <span>Pesquisar</span>
                </div>
              </div>
            </div>}
          {getTableSelected == 8 &&
            <div style={{ width: "100%", backgroundColor: "0", display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", width: "70%", minWidth: "1005px", background: "0", alignItems: "center", justifyContent: "space-between", paddingLeft: "2%" }}>
                <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                {/* Grupo Picker */}
                <PickerGrupo popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerGrupo} onItemSelect={async () => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: Objeto.GrupoModel) => <MenuItem onClick={async () => {
                    await setItemPickerGrupo(Item);
                    await setCodEmp(Item.KEY_CDEMP_BD);
                    api.get(`/getFiltroPlan?codEmp=${Item.KEY_CDEMP_BD}&codEmpObra=${getCodEmpObra}`).then(async response => {
                      await setListaPickerEmpreendimento(response.data.filtroObras)
                    })
                  }} text={Item.NM_Empresa} />} itemPredicate={(Texto: string, Item: Objeto.GrupoModel) => { return (Item.NM_Empresa ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerGrupo?.NM_Empresa.substring(0, 10).concat("...") ?? "Grupo"} rightIcon="double-caret-vertical" />
                </PickerGrupo>
                {getItemPickerGrupo && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {
                  await setItemPickerGrupo(undefined);
                  await setItemPickerEmpreendimento(undefined);
                  await setItemPickerStatus(undefined);
                  await setCodEmp(undefined)
                  await setCodEmpObra(undefined)
                  await setStatus(undefined)

                  await setListaPickerGrupo(await (await (api.get(`/getFiltroPlan?codEmp=undefined&codEmpObra=undefined`))).data.filtroEmpresas);
                  await setListaPickerEmpreendimento(await (await (api.get(`/getFiltroPlan?codEmp=undefined&codEmpObra=undefined`))).data.filtroObras);
                }} />}
                {/* Empreendimento Picker */}
                <PickerEmpreendimento popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerEmpreendimento} onItemSelect={async () => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: Objeto.EmpreendimentoPicker) => <MenuItem onClick={async () => {
                    await setItemPickerEmpreendimento(Item);
                    await setCodEmpObra(Item.KEY_CDEMP_CDOBR_BD)

                    api.get(`/getFiltroPlan?codEmp=${getCodEmp}&codEmpObra=${Item.KEY_CDEMP_CDOBR_BD}`).then(async response => {
                      await setListaPickerGrupo(response.data.filtroEmpresas)
                    })
                  }} text={Item.KEY_CDEMP_CDOBR_BD} />} itemPredicate={(Texto: string, Item: Objeto.EmpreendimentoPicker) => { return (Item.KEY_CDEMP_CDOBR_BD ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerEmpreendimento?.KEY_CDEMP_CDOBR_BD.substring(0, 15) ?? "Empreendimento"} rightIcon="double-caret-vertical" />
                </PickerEmpreendimento>
                {getItemPickerEmpreendimento && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {
                  await setItemPickerGrupo(undefined);
                  await setItemPickerEmpreendimento(undefined);
                  await setItemPickerStatus(undefined);
                  await setCodEmp(undefined)
                  await setCodEmpObra(undefined)
                  await setStatus(undefined)

                  await setListaPickerGrupo(await (await (api.get(`/getFiltroPlan?codEmp=undefined&codEmpObra=undefined`))).data.filtroEmpresas);
                  await setListaPickerEmpreendimento(await (await (api.get(`/getFiltroPlan?codEmp=undefined&codEmpObra=undefined`))).data.filtroObras);
                }} />}
                {/* Status Picker */}
                <PickerStatus popoverProps={{ popoverClassName: "bp3-multi-select-popover" }} items={getListaPickerStatus} onItemSelect={async () => { }} noResults={<MenuItem disabled={true} text="Sem resultados disponíveis" />}
                  itemRenderer={(Item: Objeto.StatusPicker) => <MenuItem onClick={async () => {
                    await setItemPickerStatus(Item);
                    await setStatus(Item.statusflx);
                  }} text={Item.statusflx} />} itemPredicate={(Texto: string, Item: Objeto.StatusPicker) => { return (Item.statusflx ?? "").toUpperCase().includes(Texto.toUpperCase()) || Texto == "" }}>
                  <Button className="selector" style={{ background: "#ffffff", color: "#13455e", border: "none", boxShadow: "none", borderRadius: "5px", outline: "none", width: "150px", display: "flex", alignItems: "center", justifyContent: "space-between" }} text={getItemPickerStatus?.statusflx.substring(0, 10).concat("...") ?? "Status"} rightIcon="double-caret-vertical" />
                </PickerStatus>
                {getItemPickerStatus && <Icon icon="filter-remove" style={{ marginLeft: "5px", cursor: "pointer" }} iconSize={15} onClick={async () => {
                  await setItemPickerGrupo(undefined);
                  await setItemPickerEmpreendimento(undefined);
                  await setItemPickerStatus(undefined);
                  await setCodEmp(undefined)
                  await setCodEmpObra(undefined)
                  await setStatus(undefined)

                  await setListaPickerGrupo(await (await (api.get(`/getFiltroPlan?codEmp=undefined&codEmpObra=undefined`))).data.filtroEmpresas);
                  await setListaPickerEmpreendimento(await (await (api.get(`/getFiltroPlan?codEmp=undefined&codEmpObra=undefined`))).data.filtroObras);
                }} />}
                <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
                {/* Data Picker */}
                <FormGroup className="place" label="Data de início:" labelFor="DataDeInicio" inline={true} style={{ marginTop: "15px" }}>
                  <div style={{ width: "100px" }}>
                    <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                      parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                      placeholder={"DD/MM/AAAA"} value={getFormularioDataDeInicio} maxDate={getFormularioDataDeTermino}
                      onChange={async (DataSelecionada: Date) => {
                        if (DataSelecionada != null) {
                          await setFormularioDataDeInicio(DataSelecionada)
                        }
                        else {
                          await setFormularioDataDeInicio(getFormularioDataDeInicio)
                        }
                      }}>
                    </DateInput>
                  </div>
                </FormGroup>
                <FormGroup className="place" label="Data de termino:" labelFor="DataDeTermino" inline={true} style={{ marginTop: "15px" }}>
                  <div style={{ width: "100px" }}>
                    <DateInput dayPickerProps={PropriedadesDeCalendario} formatDate={(date, locale) => moment(date).locale(moment.locale(locale)).format("DD/MM/YYYY")}
                      parseDate={(str, locale) => moment(str, "DD/MM/YYYY").locale(moment.locale(locale)).toDate()}
                      placeholder={"DD/MM/AAAA"} value={getFormularioDataDeTermino} minDate={getFormularioDataDeInicio} maxDate={new Date()}
                      onChange={async (DataSelecionada: Date) => {
                        if (DataSelecionada != null && DataSelecionada < getFormularioDataDeInicio) {
                          await setFormularioDataDeInicio(DataSelecionada)
                        }
                        if (DataSelecionada != null) {
                          await setFormularioDataDeTermino(DataSelecionada)
                        }
                        else if (DataSelecionada == null) {
                          await setFormularioDataDeTermino(getFormularioDataDeTermino)
                        }
                      }}>
                    </DateInput>
                  </div>
                </FormGroup>
                <Divider style={{ width: "1px", height: "30px", backgroundColor: "#afafaf" }} />
              </div>
              <div style={{ marginRight: "110px", width: "325px", marginLeft: "20px", display: "flex", alignItems: "center", justifyContent: "space-between", background: "0" }}>
                <div style={{ display: "flex", flexDirection: "column", fontSize: "12px", marginBottom: "-10px" }}>
                  <Checkbox style={{ height: "10px" }} onClick={async () => { getCheckEmpresa === false ? await setCheckEmpresa(true) : await setCheckEmpresa(false) }} label="Empresa" defaultIndeterminate={false} checked={getCheckEmpresa} />
                  <Checkbox style={{ height: "10px" }} onClick={async () => { getCheckPessoais === false ? await setCheckPessoais(true) : await setCheckPessoais(false) }} label="Pessoais" defaultIndeterminate={false} checked={getCheckPessoais} />
                  <Checkbox style={{ height: "10px" }} onClick={async () => { getCheckEmpreendimentos === false ? await setCheckEmpreendimentos(true) : await setCheckEmpreendimentos(false) }} label="Empreendimentos" defaultIndeterminate={false} checked={getCheckEmpreendimentos} />
                </div>
                <div className="hoverize" style={{ width: "110px", height: "40px", color: "#175372", boxShadow: "0px 0px 0px 1px #d8d8d8", padding: "0 5px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer" }}
                  onClick={async () => {
                    await setFilterClicked(false)
                    if (getCheckEmpresa || getCheckPessoais || getCheckEmpreendimentos) {
                      if (getCheckEmpresa && !getCheckPessoais && !getCheckEmpreendimentos) {
                        await setDivisao("'Empresa'", async (Item: string) => {
                          api.get(`/Planejamento?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&divisao=${Item}&status=${getStatus}`).then(async res => {
                            await setPlanejamentoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (!getCheckEmpresa && getCheckPessoais && !getCheckEmpreendimentos) {
                        await setDivisao("'Pessoais'", async (Item: string) => {
                          api.get(`/Planejamento?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&divisao=${Item}&status=${getStatus}`).then(async res => {
                            await setPlanejamentoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (!getCheckEmpresa && !getCheckPessoais && getCheckEmpreendimentos) {
                        await setDivisao("'Empreendimentos'", async (Item: string) => {
                          api.get(`/Planejamento?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&divisao=${Item}&status=${getStatus}`).then(async res => {
                            await setPlanejamentoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (getCheckEmpresa && getCheckPessoais && !getCheckEmpreendimentos) {
                        await setDivisao("'Empresa', 'Pessoais'", async (Item: string) => {
                          api.get(`/Planejamento?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&divisao=${Item}&status=${getStatus}`).then(async res => {
                            await setPlanejamentoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (getCheckEmpresa && !getCheckPessoais && getCheckEmpreendimentos) {
                        await setDivisao("'Empresa', 'Empreendimentos'", async (Item: string) => {
                          api.get(`/Planejamento?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&divisao=${Item}&status=${getStatus}`).then(async res => {
                            await setPlanejamentoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (!getCheckEmpresa && getCheckPessoais && getCheckEmpreendimentos) {
                        await setDivisao("'Pessoais', 'Empreendimentos'", async (Item: string) => {
                          api.get(`/Planejamento?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&divisao=${Item}&status=${getStatus}`).then(async res => {
                            await setPlanejamentoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                      else if (getCheckEmpresa && getCheckPessoais && getCheckEmpreendimentos) {
                        await setDivisao("'Empresa', 'Pessoais', 'Empreendimentos'", async (Item: string) => {
                          api.get(`/Planejamento?dataInicial=${moment(getFormularioDataDeInicio, true).format('YYYY-MM-DD')}&dataFinal=${moment(getFormularioDataDeTermino, true).format('YYYY-MM-DD')}&codEmp=${getCodEmp}&codEmpObra=${getCodEmpObra}&divisao=${Item}&status=${getStatus}`).then(async res => {
                            await setPlanejamentoData(res.data)
                            await setFilterClicked(true)
                            await setDivisao("", async (item: string) => null)
                          })
                        })
                      }
                    }
                    else {
                      alert("Selecione pelo menos uma divisão")
                    }
                  }}>
                  <Icon color="#175372" style={{ transform: getFilterClicked ? "rotate(0deg)" : "rotate(-360deg)", transition: getFilterClicked ? "transform 2s" : "" }} icon="search" />
                  <span>Pesquisar</span>
                </div>
              </div>
            </div>
          }
        </div>
      </Collapse>
      <div style={{ width: "100%", height: "20px", backgroundColor: "#ffffff", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => getCollapse === true ? setCollapse(false) : setCollapse(true)}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: LottieFiles.SwipeDown,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
          height={30}
          width={30}
          style={getCollapse ? { transform: `rotate(180deg)`, transition: "transform 0.5s" } : { transform: `rotate(0deg)`, transition: "transform 0.5s" }}
        />
      </div>
      {/* Vizualizações */}
      {getTableSelected == 0 && <ComponentTable.Resumo></ComponentTable.Resumo>}
      {getTableSelected == 1 && <ComponentTable.VendasSemCessao vendasSemCessao={getVendasSemCessaoData}></ComponentTable.VendasSemCessao>}
      {getTableSelected == 2 && <ComponentTable.VendasDeCessao vendasDeCessao={getVendasDeCessaoData}></ComponentTable.VendasDeCessao>}
      {getTableSelected == 3 && <ComponentTable.VendasDistratadas vendasDistratadas={getVendasDistratadasData}></ComponentTable.VendasDistratadas>}
      {getTableSelected == 4 && <ComponentTable.Depositos depositos={getDepositosData}></ComponentTable.Depositos>}
      {getTableSelected == 5 && <ComponentTable.Provisao></ComponentTable.Provisao>}
      {getTableSelected == 6 && <ComponentTable.VendaNoTempo visao={getItemPickerVisao}></ComponentTable.VendaNoTempo>}
      {getTableSelected == 7 && <ComponentTable.Fluxo dataInicial={getFormularioDataDeInicio} dataFinal={getFormularioDataDeTermino} dados={getFluxoData} filter={getSearch}></ComponentTable.Fluxo>}
      {getTableSelected == 8 && <ComponentTable.Planejamento planejamento={getPlanejamentoData} ></ComponentTable.Planejamento>}
    </div>
  );
}

export default View;