import React, { useState, useEffect } from 'react';
import { Objeto } from '../../../Services/Models';
import * as ComponentTable from './components';
import { IconeHome } from '../../../Assets';
import { Select } from "@blueprintjs/select";
import { CentroDeCusto, Identificador } from '../../../Services/Controllers';
import { SignIn as ContextSignIn } from '../../../Context';
import './style.css'

const View: React.FC = (viewModel) => {
  const [getTableSelected, setTableSelected] = useState<number>(0);
  const [getOpacity, setOpacity] = useState<boolean>(false)
  const [getVisaoTempo, setVisaoTempo] = useState<number>(0)

  const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
  const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
  const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();

  const [getItemPickerVisao, setItemPickerVisao] = useState<{ id: number, descricao: string }>();
  const [getListaPickerVisao, setListaPickerVisao] = useState<Array<{ id: number, descricao: string }>>([
    {
      id: 1,
      descricao: "11/2020"
    },
    {
      id: 2,
      descricao: "12/2020"
    }
  ]);
  const PickerVisao = Select.ofType<{ id: number, descricao: string }>();

  useEffect(() => {
    async function componentDidMount() {
      await setListaPickerCentroDeCusto((await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? []);

    }
    componentDidMount();
  }, [])
  return (

    <div style={{ backgroundColor: "#EDEFF5", width: "100%" }}>
      {/* Endereco */}
      <div style={{ backgroundColor: "#f0f0ff", width: "100%", height: "75px", display: "flex", alignItems: "center", paddingLeft: "2%" }}>
        <img style={{ width: "20px", height: "20px" }} src={IconeHome} alt="" />
        <div className="bar">/</div>
        <div className="place">De-Para</div>
      </div>
      <ComponentTable.DePara></ComponentTable.DePara>
    </div>
  );
}

export default View;