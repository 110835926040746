import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

enum TipoDeNotificacao {
    Sucesso = "success",
    Alerta = "warning",
    Erro = "danger",
    Informacao = "info"
};

class NotificacaoInterna {
    /* #region  Variáveis  */
    TipoDeNotificacao = TipoDeNotificacao;
    /* #endregion */

    /* #region  Funções  */
    /* Sobre esta função *//**
     * Exibe uma notificação para o usuário
     * @param {string} Titulo Defina um título para a notificação
     * @param {string} Mensagem Defina uma mensagem para a notificação
     * @param {string} TipoDeNotificacao Tipifique a notificação
     * @param {void} FuncaoAposANotificacao Defina uma função a ser executada após a exibição da notificação
     **/
    public async ExibirNotificacao(Titulo: string, Mensagem: string, TipoDeNotificacao: TipoDeNotificacao, FuncaoAposANotificacao?: Function, Duracao?: number) {
        store.addNotification({
            title: Titulo,
            message: Mensagem,
            type: TipoDeNotificacao,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: Duracao ? Duracao : (Mensagem.length * 100), //(TipoDeNotificacao == "success" ? 4000 : 18000),
                onScreen: true,
                showIcon: true
            },
            onRemoval: () => { if (FuncaoAposANotificacao) FuncaoAposANotificacao(); }
        });
    };
    /* #endregion */
};

export default new NotificacaoInterna();