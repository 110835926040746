import React, { memo } from 'react';
import { Marker } from 'react-leaflet-rotate/react-leaflet';
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';

function App({ polygons, getMarkersSize }: any) {

    return polygons.lista.map((quadra: any, quadraIndex: any) => {
        return <Marker interactive={false} icon={divIcon({
          html: renderToStaticMarkup(
            <div style={{ backgroundColor: "#ffc65c", pointerEvents: "none", opacity: `${getMarkersSize[3]}`, display: "flex", alignItems: "center", justifyContent: "center", color: "black", borderRadius: "50%", width: `${getMarkersSize[0]}px`, height: `${getMarkersSize[0]}px`, border: "0.001cm black solid" }}>
              <span style={{ fontWeight: "bold", pointerEvents: "none", fontSize: `${getMarkersSize[2]}px`, }}>{quadra.quadra}</span>
            </div>
          )
        })} position={[quadra.center.latitude, quadra.center.longitude]} />
      })
}

export default App;