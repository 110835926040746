import axios, { AxiosResponse } from 'axios';
import { Objeto } from '../../Models';

class NewsLetter {
    /* #region  GET Controller  */
    /* Sobre esta função *//**
     * Lista os estados civis existentes
     * @param {string} Token Informe o token de acesso do usuário obtido ao executar a requisição "/Logon/{CPF}/{Senha}
     * @param {string} CPF Informe o CPF (somente números)
     **/
    public async Get(): Promise<AxiosResponse<Array<Objeto.Newsletter>>> {
        return await axios.get('https://api.bing.microsoft.com/v7.0/news/search?q=mercado imobiliario', {
            headers: {
                "Ocp-Apim-Subscription-Key": "e5cb9cc214b34758939c5210fa3a25ef"
            }
        }).then((Response) => {
            var Registros = [] as Array<Objeto.Newsletter>
            (Response.data.value as Array<any>).map((Item: any) => {
                Registros.push({
                    Titulo: Item.name,
                    URLImagem: Item.image ? Item.image.thumbnail.contentUrl : "",
                    Descricao: Item.description,
                    URL: Item.url
                } as Objeto.Newsletter);
            });
            Response.data = Registros;
            return Response;
        }).catch((Exception) => { return Exception; });
    };
    /* #endregion */

    /* #region  POST Controller  */
    /* #endregion */

    /* #region  DELETE Controller  */
    /* #endregion */

    /* #region  PUT Controller  */
    /* #endregion */
};

export default new NewsLetter();