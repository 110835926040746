import axios from "axios";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, Card, FormGroup, MenuItem, Menu, Icon, Popover, Position } from '@blueprintjs/core';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Select } from "@blueprintjs/select";
import { L10n, loadCldr, setCulture, setCurrencyCode, enableRipple, setValue } from '@syncfusion/ej2-base';
import { MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent, DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { TimePickerComponent, TimePicker, DateTimePicker } from '@syncfusion/ej2-react-calendars';
import { 
    GridComponent, 
    ColumnDirective, 
    ColumnsDirective, 
    Page, 
    Group, 
    Inject, 
    GroupSettingsModel, 
    Aggregate, 
    Toolbar, 
    ToolbarItems, 
    PdfExport, 
    Grid, 
    Filter, 
    FilterSettingsModel, 
    Reorder,
    Sort,
    SortSettingsModel, 
    PdfHeaderQueryCellInfoEventArgs, 
    Edit,
    EditSettingsModel,
    IEditCell,
    ActionEventArgs
} from "@syncfusion/ej2-react-grids";

import { IconeHome } from '../../../Assets';
import { SignIn as ContextSignIn } from '../../../Context';
import { Localization } from '../../../Data/Listas';
import { CentroDeCusto, Identificador, Venda } from '../../../Services/Controllers';
import { Objeto } from '../../../Services/Models';
import { NotificacaoInterna } from '../../../Services/Notification';
import * as LottieFiles from '../../../Data/Lottie';

import './App.css';
import { image } from './image';
import * as Component from './style';
import viewModel from './viewModel';
import { type } from "os";
import { opacify } from "polished";

loadCldr(
    require('../../../../node_modules/cldr-data/main/pt/currencies.json'),
    require('../../../../node_modules/cldr-data/main/pt/numbers.json'),
    require('../../../../node_modules/cldr-data/main/pt/ca-gregorian.json'),
    require('../../../../node_modules/cldr-data/main/pt/timeZoneNames.json'),
    require('../../../../node_modules/cldr-data/supplemental/numberingSystems.json'));
setCulture('pt');
setCurrencyCode("BRL");
enableRipple(true);
L10n.load(Localization);

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    const PropriedadesDeCalendario = {
        months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    };
    const [getFormularioDataDeInicio, setFormularioDataDeInicio] = useState<Date>(moment(new Date()).subtract(1, "day").toDate());
    const [getFormularioDataDeTermino, setFormularioDataDeTermino] = useState<Date>(moment(new Date()).subtract(0, "day").toDate());

    const [getLoadingInserindoCategoria, setLoadingInserindoCategoria] = useState<boolean>(false);
    const [getIDCategoria, setIDCategoria] = useState<{sigla: string, descricao: string}>({sigla: "", descricao: ""});

    const [getItemPickerCentroDeCusto, setItemPickerCentroDeCusto] = useState<Objeto.CentroDeCusto>();
    const [getListaPickerCentroDeCusto, setListaPickerCentroDeCusto] = useState<Array<Objeto.CentroDeCusto>>([]);
    const [getListaEmpreendimentos, setListaEmpreendimentos] = useState([]);
    const PickerCentroDeCusto = Select.ofType<Objeto.CentroDeCusto>();

    const [getItemPickerSala, setItemPickerSala] = useState<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();
    const [getListaPickerSala, setListaPickerSala] = useState<Array<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>>();
    const PickerSala = Select.ofType<{ descricao: string, cidade: string, closer: Array<{ descricao: string, cpf: string }>, promotor: Array<{ descricao: string, cpf: string }>, assessorTlmkt: Array<{ descricao: string, cpf: string }>, liner: Array<{ descricao: string, cpf: string }>, pep: Array<{ descricao: string, cpf: string }>, subGerenteDeSala: Array<{ descricao: string, cpf: string }>, gerenteDeSala: Array<{ descricao: string, cpf: string }> }>();

    const [getListaDeContratos, setListaDeContratos] = useState<Array<{Empreendimento: string, Descricao: string, Sigla: string, Tempo: Date}>>([]);
    const [getIndexItemListaDeContratos, setIndexItemListaDeContratos] = useState<number>();
    const [getCarregandoListaDeContratos, setCarregandoListaDeContratos] = useStateWithCallbackLazy<boolean | undefined>(undefined);
    let dataStorage: Array<{Empreendimento: string, Descricao: string, Sigla: string, Tempo: Date}>;

    let dataEmp: Array<any>;
    /* #endregion */

    /* Sobre esta função *//**
       * Executa todas as rotinas atrealadas a alteração do centro de custo exibido na página
       * @param {Objeto.CentroDeCusto | undefined} Item Informe o objeto referente ao centro de custo
       * @param {MapaExibido} MapaExibido Informe se o mapa está exibido (condição necessária por causa do delay do usestate)
       **/
    async function Buscar() {
        if (getItemPickerCentroDeCusto) {
            
            await setCarregandoListaDeContratos(false, () => {})
            
            let Response = (await Identificador.CategoriasDeReserva(ContextSignIn.getContext().token, getItemPickerCentroDeCusto.empresa.id ?? 0, getItemPickerCentroDeCusto.sigla ?? ""));

            if (Math.floor(Response.status / 100) == 2) {
                dataStorage = [];

                let timer: Date;
                let hours: Date;
                let minutes: Date;

                (Response.data).map((item: any, index: number) => {

                    timer = new Date();

                    hours = new Date(timer.setHours(0))

                    minutes = new Date(hours.setMinutes(0));

                    minutes.setMinutes(item.tempo);

                    dataStorage.push({
                        Empreendimento: item.centroDeCusto.descricao,
                        Descricao: item.descricao,
                        Sigla: item.sigla,
                        Tempo: minutes
                    })

                })

                await setListaDeContratos(dataStorage);
                await setCarregandoListaDeContratos(true, () => {})
            }
            else {
                await setCarregandoListaDeContratos(undefined, () => {})
                await setItemPickerCentroDeCusto(undefined);
                await setItemPickerSala(undefined);
                await setListaDeContratos([]);
            }
        }
        else {
            if (!getItemPickerCentroDeCusto) {
                NotificacaoInterna.ExibirNotificacao("Empreendimento não selecionado", "Informe o empreendimento", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return;
            }
            if (!getItemPickerSala) {
                NotificacaoInterna.ExibirNotificacao("Sala não selecionada", "Informe a sala", NotificacaoInterna.TipoDeNotificacao.Alerta);
                return;
            }
        }
    }
    useEffect(() => {
        async function componentDidMount() {
            let ResponseCentroDeCusto = (await CentroDeCusto.Get(ContextSignIn.getContext().token, "", "")).data ?? []
            await setListaPickerCentroDeCusto(ResponseCentroDeCusto);

            await setCarregandoListaDeContratos(false, () => {})
            
            let Response = (await Identificador.TodasCategoriasDeReserva(ContextSignIn.getContext().token));

            if (Math.floor(Response.status / 100) == 2) {
                dataStorage = [];

                let timer: Date;
                let hours: Date;
                let minutes: Date;

                (Response.data).map((item: any, index: number) => {

                    timer = new Date();

                    hours = new Date(timer.setHours(0))

                    minutes = new Date(hours.setMinutes(0));

                    minutes.setMinutes(item.tempo);

                    dataStorage.push({
                        Empreendimento: item.centroDeCusto.descricao,
                        Descricao: item.descricao,
                        Sigla: item.sigla,
                        Tempo: minutes
                    })
                })

                console.log(dataStorage)

                await setListaDeContratos(dataStorage);
                await setCarregandoListaDeContratos(true, () => {})
            }
            else {
                await setCarregandoListaDeContratos(undefined, () => {})
                await setItemPickerCentroDeCusto(undefined);
                await setItemPickerSala(undefined);
                await setListaDeContratos([]);
            }
        }
        componentDidMount();
    }, []);
    const toolbarOptions: ToolbarItems[] = ['Add', 'Edit', 'Delete', 'Update', 'Cancel']
    const groupOptions: GroupSettingsModel = {
        // columns: ['Cliente', 'Operacao'],
        showGroupedColumn: true,
        showUngroupButton: true,
    }
    const filterOptions: FilterSettingsModel = {
        ignoreAccent: true,
        immediateModeDelay: 200,
        mode: "Immediate",
        type: 'Excel',
    }
    const sortOptions: SortSettingsModel = {

    }

    const editOptions: EditSettingsModel = {
        allowAdding: true, 
        allowDeleting: true, 
        allowEditing: true, 
        allowEditOnDblClick: true,
        mode: 'Normal',
        showConfirmDialog: true,
        showDeleteConfirmDialog: true
    }

    const editTemplateEmpreendimento = (args: any) => {
        return (
            <DropDownListComponent id = "Empreendimento" dataSource = {dataEmp} placeholder = 'Selecione um empreendimento' value = {args.Empreendimento}/>
        )
    }

    const editTemplateTempo = (args: any) => {
        return (
            <MaskedTextBoxComponent mask = "000:00" id = "Tempo" value = {args.Tempo}/>
        )
    }

    let EmpreendimentoElem : HTMLElement;
    let EmpreendimentoObj : DropDownList;
  
    const Empreendimento: object[] = getListaPickerCentroDeCusto

    const EmpreendimentoParams : IEditCell = {
        create:()=> {
        EmpreendimentoElem = document.createElement('input');
          return EmpreendimentoElem;
        },
        destroy:()=> {
            EmpreendimentoObj.destroy();
        },
        read:()=> {
            return EmpreendimentoObj.text;
        },
        write:(args: any)=> {
          EmpreendimentoObj = new DropDownList({
            value: args.rowData[args.column.field],
            actionComplete: () => false,
            allowFiltering: true,
            dataSource: new DataManager(Empreendimento),
            fields: { value: 'descricao', text: 'descricao' },
            floatLabelType: 'Never',
            placeholder: 'Selecione um empreendimento',
            ignoreAccent: true,
          });
          EmpreendimentoObj.appendTo(EmpreendimentoElem);
        }
    };

    let TimerElem : HTMLElement;
    let TimerObj : TimePicker;

    const TimerParams : IEditCell = {
        create:()=> {
          TimerElem = document.createElement('input');
          return TimerElem;
        },
        destroy:()=> {
            TimerObj.destroy();
        },
        read:()=> {
            return TimerObj.value;
        },
        write:(args: any)=> {
            TimerObj = new TimePicker({
                value: args.rowData[args.column.field],
                floatLabelType: 'Never',
                format: 'HH:mm',
                step: 15,
                placeholder: 'Selecione um tempo',
                locale: 'pt-BR'
            });
            TimerObj.appendTo(TimerElem);
        }
    };

    const actionComplete = async (args: any) => {
    
        if (args.requestType === 'save' && args.form)
        {
            /** cast string to integer value */

            if(args.action === 'add')
            {

                if(grid)
                {

                    let data: Array<{ Empreendimento: string, Descricao: string, Sigla: string, Tempo: string }> | any

                    data = grid.dataSource

                    let Buffer: Array<any>;
                    let BufferEmpreendimento: Objeto.CentroDeCusto;
                    let BufferTempo: any;

                    Buffer = [];
                    BufferTempo = null;

                    await data.map((item: { Empreendimento: string, Descricao: string, Sigla: string, Tempo: string }) => {

                        getListaPickerCentroDeCusto.map(Item => {
                            if (Item.descricao == item.Empreendimento)
                            {
                                BufferEmpreendimento = Item;
                            }
                        })

                        Buffer.push({
                            "empresa": BufferEmpreendimento.empresa,
                            "centroDeCusto": {
                                "empresa": BufferEmpreendimento.empresa,
                                "sigla": BufferEmpreendimento.sigla,
                                "descricao": BufferEmpreendimento.descricao
                            },
                            "sigla": item.Sigla,
                            "descricao": item.Descricao,
                            "tempo": ((new Date(item.Tempo).getHours() * 60) + new Date(item.Tempo).getMinutes()),
                        })
                    })

                    setLoadingInserindoCategoria(true)
                    NotificacaoInterna.ExibirNotificacao("Inserindo nova categoria", "Aguarde...", NotificacaoInterna.TipoDeNotificacao.Informacao);

                    let Response = await Identificador.NovaCategoriaDeReserva(ContextSignIn.getContext().token, Buffer)

                    if (Math.floor(Response.status / 100) == 2)
                    {
                        setLoadingInserindoCategoria(false)
                        NotificacaoInterna.ExibirNotificacao("Categoria inserida", "Sucesso ao inserir categoria", NotificacaoInterna.TipoDeNotificacao.Sucesso);
                    }
                    else
                    {
                        setLoadingInserindoCategoria(false)
                        NotificacaoInterna.ExibirNotificacao("Error ao inserir categoria", "Entre em contato com a nossa equipe de desenvolvimento", NotificacaoInterna.TipoDeNotificacao.Erro);
                    }

                }

            }
            if (args.action === 'edit')
            {
                
            }

        }
        else if (args.requestType === 'delete')
        {

        }

    }

    const templateAnimation = (props: any) => (
        <>
            {getIDCategoria.sigla == props.Sigla && getIDCategoria.descricao == props.Descricao && <Lottie
                options = {{
                    loop: true,
                    autoplay: true,
                    animationData: LottieFiles.Load,
                    rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice"
                    }
                }}
                height = {20}
                width = {50}
                style = {{marginLeft: '-10px'}}
            />}
        </>
    )

    const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs | any) => {
        // (args.cell as any).row.pdfGrid.repeatHeader = true;
        // args.cell.gridRow.pdfGrid.columns.getColumn(2).width = 90;
        // args.cell.gridRow.pdfGrid.columns.getColumn(2).textAlign = "Left";
    }

    let grid: Grid | null;
    const toolbarClick = (args: any) => {
        if (grid) {
            if (args.item.id === 'grid_pdfexport') {
                // (grid.columns[0] as Column).visible = false;
                // (grid.columns[1] as Column).visible = false;
                grid.pdfExport({
                    fileName: `Borderô.pdf`,
                    pageOrientation: "Landscape",
                    header: {
                        fromTop: 0,
                        height: 100,
                        contents: [
                            {
                                type: 'Text',
                                value: `${getItemPickerCentroDeCusto?.descricao}`,
                                position: { x: 390, y: 50 },
                                style: { textBrushColor: "#000000", fontSize: 20, hAlign: "Right" },
                            },
                            {
                                position: { x: 0, y: 45 },
                                size: { height: 40, width: 80 },
                                src: image,
                                type: 'Image',
                            },
                            {
                                type: 'Text',
                                value: `${moment(new Date(), true).format("DD/MM/YYYY HH:mm:ss")}`,
                                position: { x: 880, y: 55 },
                                style: { textBrushColor: "#000000", fontSize: 13 },
                            },
                        ]
                    },
                    footer: {
                        contents: [
                            {
                                type: 'Text',
                                value: `Powered by DigitalDEV`,
                                position: { x: 0, y: 0 },
                                style: { textBrushColor: "#000000", fontSize: 10 },
                            },
                            {
                                format: 'Página {$current} de {$total}',
                                pageNumberType: "Numeric",
                                position: { x: 910, y: 0 },
                                style: {
                                    fontSize: 13,
                                    hAlign: "Right",
                                    textBrushColor: '#000000',
                                },
                                type: 'PageNumber',
                            }
                        ],
                        fromBottom: 0,
                        height: 20,
                    },
                    theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
                        },
                        record: {
                            bold: false,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7)
                        },
                        caption: {
                            bold: true,
                            fontColor: "#000000",
                            fontSize: 8,
                            // font: new PdfTrueTypeFont(adventProFont, 7, PdfFontStyle.Bold)
                        }
                    },
                    allowHorizontalOverflow: false,

                });
            }
        }
    }
    const pdfExportComplete = () => {
        // if (grid) {
        //     (grid.columns[0] as Column).visible = true;
        //     (grid.columns[1] as Column).visible = true;
        // }
    }

    return (
        <Component.Container>
            <div className="endereco">
                <img width = {"1vw"} src={IconeHome} alt="" />
                <div className="bar">|</div>
                <div className="place" style = {{fontSize: "1vw"}}>Categorias de reserva</div>
            </div>
            {getCarregandoListaDeContratos != true && <div style={{ width: "100%", background: "0", display: "flex", flexDirection: "column" }}>
                <Lottie
                    options = {{
                        loop: true,
                        autoplay: true,
                        animationData: LottieFiles.LoadMaps,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height = {500}
                    width = {500}
                    style = {{ marginTop: "100px" }}
                />
                <div className="loading" style={{ margin: "-100px auto 0 auto", fontWeight: "bold" }}>{(getCarregandoListaDeContratos == undefined) ? "Aguarde, estamos carregando suas categorias de reserva" : ((getCarregandoListaDeContratos == false) ? "Aguarde, estamos carregando suas categorias de reserva" : "Pronto, suas categorias logo serão exibidas!")}</div>
            </div>}
            {getCarregandoListaDeContratos == true && <Card style = {{ backgroundColor: "0", width: "100%", margin: "0px auto 40px auto", padding: "0", borderRadius: "5px", border: "none", boxShadow: "none" }}>
                <div style = {{ width: "100%", overflowX: 'scroll'}}>
                    <GridComponent 
                        dataSource = {getListaDeContratos} 
                        width = {"100%"}
                        height = {"100%"}
                        id = 'grid'
                        ref = {g => grid = g}
                        allowPaging = {true}
                        allowPdfExport = {true}
                        allowFiltering = {true}
                        allowReordering = {true}
                        editSettings = {editOptions}
                        allowSorting = {false}
                        sortSettings = {sortOptions}
                        toolbar = {toolbarOptions}
                        pdfHeaderQueryCellInfo = {pdfHeaderQueryCellInfo}
                        toolbarClick = {toolbarClick}
                        filterSettings = {filterOptions}
                        actionComplete = {actionComplete}
                        actionBegin = {
                            async (args: any) => {
                                if (args.requestType === "filterchoicerequest" || args.requestType === 'filtersearchbegin')
                                {
                                    args.filterChoiceCount = 100000;
                                }
                                else if (args.requestType == 'save')
                                {
                                    if(args.action === 'add')
                                    {
                                        setIDCategoria({sigla: args.data.Sigla, descricao: args.data.Descricao})
                                    }
                                    else if (args.action === 'edit')
                                    {
                                        

                                        let ListaDeContratos = [...getListaDeContratos];
                                        ListaDeContratos[args.rowIndex] = args.data
                                        setListaDeContratos(ListaDeContratos)

                                        let BufferEmpreendimento: Objeto.CentroDeCusto | any;
                                        let BufferItem: any;
                                        
                                        BufferItem = {};

                                        getListaPickerCentroDeCusto.map(Item => {
                                            if (Item.descricao == args.previousData.Empreendimento)
                                            {
                                                BufferEmpreendimento = Item;
                                            }
                                        })

                                        let Response = await Identificador.DeletarCategoria(ContextSignIn.getContext().token, BufferEmpreendimento.empresa.id, BufferEmpreendimento.sigla, args.previousData.Sigla);

                                        if (Math.floor(Response.status / 100) == 2)
                                        {
                            
                                            let data: Array<{ Empreendimento: string, Descricao: string, Sigla: string, Tempo: string }> | any
                        
                                            data = ListaDeContratos;
                        
                                            let Buffer: Array<any>;
                                            let BufferEmpreendimento: Objeto.CentroDeCusto;
                                            let BufferTempo: any;
                        
                                            Buffer = [];
                                            BufferTempo = null;
                        
                                            await data.map((item: { Empreendimento: string, Descricao: string, Sigla: string, Tempo: string }) => {
                        
                                                getListaPickerCentroDeCusto.map(Item => {
                                                    if (Item.descricao == item.Empreendimento)
                                                    {
                                                        BufferEmpreendimento = Item;
                                                    }
                                                })
                        
                                                Buffer.push({
                                                    "empresa": BufferEmpreendimento.empresa,
                                                    "centroDeCusto": {
                                                        "empresa": BufferEmpreendimento.empresa,
                                                        "sigla": BufferEmpreendimento.sigla,
                                                        "descricao": BufferEmpreendimento.descricao
                                                    },
                                                    "sigla": item.Sigla,
                                                    "descricao": item.Descricao,
                                                    "tempo": ((new Date(item.Tempo).getHours() * 60) + new Date(item.Tempo).getMinutes()),
                                                })
                                            })
                        
                                            let ResponseNovaCategoria = await Identificador.NovaCategoriaDeReserva(ContextSignIn.getContext().token, Buffer)
                        
                                            if (Math.floor(ResponseNovaCategoria.status / 100) == 2)
                                            {
                                                
                                            }
                                            else
                                            {
                                                
                                            }
                                        }
                                        else
                                        {
                                            
                                        }
                                    }
                                }
                                else if (args.requestType == 'delete')
                                {
                                    let BufferEmpreendimento: Objeto.CentroDeCusto | any;
                                    let BufferItem: any;
                                    
                                    BufferItem = {};

                                    getListaPickerCentroDeCusto.map(Item => {
                                        if (Item.descricao == args.data[0].Empreendimento)
                                        {
                                            BufferEmpreendimento = Item;
                                        }
                                    })

                                    let Response = await Identificador.DeletarCategoria(ContextSignIn.getContext().token, BufferEmpreendimento.empresa.id, BufferEmpreendimento.sigla, args.data[0].Sigla);

                                    if (Math.floor(Response.status / 100) == 2)
                                    {
                                        
                                    }
                                    else
                                    {
                                        
                                    }

                                }
                            }
                        }
                        pdfExportComplete = {pdfExportComplete}
                        pageSettings = {{ pageSizes: [20, 50, 100], pageSize: 20 }}
                        locale = {"pt"}
                        currencyCode = {"BRL"}
                    >
                        <ColumnsDirective>
                            <ColumnDirective headerText='' width = {50} template = {templateAnimation}/>
                            <ColumnDirective field='Empreendimento' headerText='Empreendimento' textAlign='Left' editType = 'dropdownedit' edit = {EmpreendimentoParams}/>
                            <ColumnDirective field='Sigla' headerText='Código' textAlign='Left' width = {150} />
                            <ColumnDirective field='Descricao' headerText='Descrição' textAlign='Left' />
                            <ColumnDirective field='Tempo' headerText='Tempo' textAlign='Left' format = 'HH:mm' editType = 'dropdownedit' edit = {TimerParams} width = {150} />
                        </ColumnsDirective>
                        <Inject services={[Page, Group, Toolbar, PdfExport, Aggregate, Filter, Reorder, Sort, Edit]} />
                    </GridComponent>
                </div>
            </Card>}
        </Component.Container>
    );
    /* #endregion */
};

export default View;