import React, { useEffect } from 'react';
import { Card, Elevation, H2, Icon } from '@blueprintjs/core';
import * as ReactDOM from 'react-dom';
import { DocumentEditorContainerComponent, Toolbar, BookmarkDialog } from '@syncfusion/ej2-react-documenteditor';
import { L10n } from '@syncfusion/ej2-base';
import * as Component from './style';
import viewModel from './viewModel';
import { TitleBar } from './title-bar';

import './App.css';
import './index.css';
import json from './testando.json';
import translate from './locale.json';

import History from "../../../Routers";

DocumentEditorContainerComponent.Inject(Toolbar, BookmarkDialog);

const View: React.FC<viewModel> = (viewModel) => {
    /* #region  Variáveis  */
    /* #endregion */

    /* #region  Funções  */
    /** Função de atualização da lista de unidades */

    L10n.load(translate)

    let container: DocumentEditorContainerComponent;
    let titleBar: any = TitleBar;

    const rendereComplete = () => {
        container.documentEditor.pageOutline = '#E0E0E0';
        container.documentEditor.acceptTab = false;
        container.documentEditor.resize();
        titleBar = new TitleBar(document.getElementById('documenteditor_titlebar'), container.documentEditor, true);
        onLoadDefault();
    }

    const onLoadDefault = () => {
        container.documentEditor.documentName = ' ';
        titleBar.updateDocumentTitle();
        container.documentChange = () => {
            titleBar.updateDocumentTitle();
            container.documentEditor.focusIn();
        };
    }

    const onContentChange = () => {

        container.documentEditor.getBookmarks()

        // container.documentEditor.getBookmarks().map(item => {
            // container.documentEditor.searchModule.findAll("01º comprador: ")
            // if (container.documentEditor.searchModule.searchResults.length > 0) {
            //     // Replace all the occurences of given text
            //     container.documentEditor.searchModule.searchResults.replaceAll("ABC");
            // }
        // })
    }

    const onDocumentChange = () => {
        
    };

    useEffect(() => {
        async function componentDidMount() {
            rendereComplete();
        }
        componentDidMount();
    }, []);

    return (
        <Component.Container style={{ overflowX: "scroll"}}>
            <button onClick={onContentChange}>Dialog</button>
            <div id='documenteditor_titlebar' className="e-de-ctn-title"></div>
            <div id="documenteditor_container_body">
                <DocumentEditorContainerComponent 
                    id = "container"
                    ref = {(scope: any) => { container = scope; }}
                    serviceUrl = "https://ej2services.syncfusion.com/production/web-services/api/documenteditor/" 
                    style = {{ display: 'block', 'height': '1000px'}}
                    locale = 'pt-BR'
                    enableLocalPaste = {true}
                    // customContextMenuBeforeOpen = {() => {
                    //     onDocumentChange()
                    //     onContentChange()
                    // }}
                    // documentChange={onDocumentChange}
                    // contentChange={onContentChange}
                    enableToolbar={true}
                />
            </div>
        </Component.Container>
    );
    /* #endregion */
};

export default View;